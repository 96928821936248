/*
export const qzDigitalCertificate = "-----BEGIN CERTIFICATE-----\n" +
"MIIFJjCCAw6gAwIBAgIQNzkyMDI0MDEyOTE5MDA0NzANBgkqhkiG9w0BAQsFADCB\n" +
"mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry\n" +
"aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ\n" +
"cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz\n" +
"dHJpZXMuY29tMB4XDTI0MDEyOTE5MDA0N1oXDTI1MDEyOTEzMTAxOFowgeExCzAJ\n" +
"BgNVBAYMAlVTMRMwEQYDVQQIDApDYWxpZm9ybmlhMRUwEwYDVQQHDAxMYSBDcmVz\n" +
"Y2VudGExFDASBgNVBAoMC0FjY2VsZXJMaXN0MRQwEgYDVQQLDAtBY2NlbGVyTGlz\n" +
"dDEUMBIGA1UEAwwLQWNjZWxlckxpc3QxJjAkBgkqhkiG9w0BCQEMF2dldGhlbHBA\n" +
"YWNjZWxlcmxpc3QuY29tMTwwOgYDVQQNDDNyZW5ld2FsLW9mLWQwNGM4Mzk3MTU5\n" +
"MDg0NGVkMDNiODVjMzliNDY0ZDI2YWJmNzQxM2IwggEgMAsGCSqGSIb3DQEBAQOC\n" +
"AQ8AMIIBCgKCAQEAkudcIfU199j51AJLX2TjsQQ568qFQaJ7Nm0ZwY3y/R5OtzQQ\n" +
"qZqHVHueksyx5Mht/WedQZmXyGWKWvgRVzflWTHIimRpSUha/AITq4jMGDoWMN+L\n" +
"0I1XHLSbQYLU+XmWiEfKx7gwFi8SxnMJmQv5MKNZ4SCPgiLFsS2gSTQ6bam4tRi2\n" +
"6sYKakLObSnur33REZLr+AwW+q49BB9QYzRZ3jLFiuYXJRbRMNQE7Qd61Y/twS+N\n" +
"gBLwSZafv24WNUeeclAfq6cz4Dj+pZESD5Xl1S7Qwtey1VxXnKUOz/Nqp0dcM24d\n" +
"MGslk3B3KOCKIlrOhz7OuDT5TkLvZQUA6n8gswIDAQABoyMwITAfBgNVHSMEGDAW\n" +
"gBSQplC3hNS56l/yBYQTeEXoqXVUXDANBgkqhkiG9w0BAQsFAAOCAgEAZmmF2716\n" +
"08QmurhJg/rXXZc8egoADvEKS3U7mFpMqQS+L5kY7adAzz3bhbwM9KbhErW7Xm4W\n" +
"w9uDwVl3u3bATLOlcxdfJhMEh5Inxsah2Suluq2FfvGSwFiMM/WUUwMNy0XQfGb+\n" +
"nv+eSl5pzLOhLKp0jScqbRvpgj+gsygYFPSFNM1uB/GbyqCL/hIImFDwVAaoP2sU\n" +
"ZFqWul3p5cw/tAEyv8RZU0k5M51fA6OIfX16WmhFnX83ZBGnZU4ffvN+5RYg2HWf\n" +
"F9IcrVgrTF1wp7Buo5BS0/D3ONv6Vj6fjkzPxMGBUTrAhnx7xtUaMLkD8oTp2w7R\n" +
"H3SN1SugREX3Zz9Jg+8bVJGfJ5E96qMCMm+YFLoMylwGDL8IKG1Zp6aIM1KOU2KF\n" +
"JunRfaqA3XicT9LJkHaDs2KAU23NiC2TPP4QD/sI4FtGIXXBfUHz2ivgk11fj9Xb\n" +
"XGii8CfkNG59xzefnZuTr4yvxLdHhOkXvWn8pTiKmeYjLwX1JTVyeHgX1cSl8bvs\n" +
"SQr5AGEkIWC19eOlhLvDJQm820d4ixCzucT7D02AdSWQc3A86RYw7u3c83WhEHhv\n" +
"J6UiRH9kBnKgOgRgDjqDzgZNQbSCm3puatF97pdftpvlrLHjbIXUUc7qEsr5gnqE\n" +
"XD63udtLWH1aK2FhwhCKfA8dao1beiKiN7o=\n" +
"-----END CERTIFICATE-----\n" +
"--START INTERMEDIATE CERT--\n" +
"-----BEGIN CERTIFICATE-----\n" +
"MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n" +
"MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n" +
"bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n" +
"A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n" +
"emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n" +
"gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n" +
"cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n" +
"EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n" +
"c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n" +
"iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n" +
"YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n" +
"GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n" +
"EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n" +
"0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n" +
"xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n" +
"9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n" +
"ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n" +
"2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n" +
"6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n" +
"5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n" +
"plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n" +
"BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n" +
"eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n" +
"gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n" +
"x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n" +
"pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n" +
"2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n" +
"rbO7BnjW\n" +
"-----END CERTIFICATE-----\n";
*/

export const qzDigitalCertificate = "-----BEGIN CERTIFICATE-----\n" +
"MIIFJjCCAw6gAwIBAgIQNzkyMDI1MDEyMzAyNDAyNDANBgkqhkiG9w0BAQsFADCB\n" +
"mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry\n" +
"aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ\n" +
"cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz\n" +
"dHJpZXMuY29tMB4XDTI1MDEyMzAyNDAyNFoXDTI2MDEyOTEzMTAxOFowgeExCzAJ\n" +
"BgNVBAYMAlVTMRMwEQYDVQQIDApDYWxpZm9ybmlhMRUwEwYDVQQHDAxMYSBDcmVz\n" +
"Y2VudGExFDASBgNVBAoMC0FjY2VsZXJMaXN0MRQwEgYDVQQLDAtBY2NlbGVyTGlz\n" +
"dDEUMBIGA1UEAwwLQWNjZWxlckxpc3QxJjAkBgkqhkiG9w0BCQEMF2dldGhlbHBA\n" +
"YWNjZWxlcmxpc3QuY29tMTwwOgYDVQQNDDNyZW5ld2FsLW9mLWU4NTc0MDU4ZWRm\n" +
"ODg2M2EyYWZlMDJlNjdhZGEzMTNhNmYzMjBiODcwggEgMAsGCSqGSIb3DQEBAQOC\n" +
"AQ8AMIIBCgKCAQEAkudcIfU199j51AJLX2TjsQQ568qFQaJ7Nm0ZwY3y/R5OtzQQ\n" +
"qZqHVHueksyx5Mht/WedQZmXyGWKWvgRVzflWTHIimRpSUha/AITq4jMGDoWMN+L\n" +
"0I1XHLSbQYLU+XmWiEfKx7gwFi8SxnMJmQv5MKNZ4SCPgiLFsS2gSTQ6bam4tRi2\n" +
"6sYKakLObSnur33REZLr+AwW+q49BB9QYzRZ3jLFiuYXJRbRMNQE7Qd61Y/twS+N\n" +
"gBLwSZafv24WNUeeclAfq6cz4Dj+pZESD5Xl1S7Qwtey1VxXnKUOz/Nqp0dcM24d\n" +
"MGslk3B3KOCKIlrOhz7OuDT5TkLvZQUA6n8gswIDAQABoyMwITAfBgNVHSMEGDAW\n" +
"gBSQplC3hNS56l/yBYQTeEXoqXVUXDANBgkqhkiG9w0BAQsFAAOCAgEAvZiexAYv\n" +
"eRCQv7mqoqybbuChgdqMZuR3pchFeJmOA2mn+EB34NLqx0hvLBA8ZS3CZ4+UiJKV\n" +
"8qQvawp2rMTEYR8F5M74SSkih3PYMxOYxSbG7YdOmtF4VNkFPRt7kmlXkLQtbPkB\n" +
"Mc4FeL6r6Z/GbxrlVPa47Zl6r/q+E53K7AXjzehxRR7quOkaFQ/M/afamHUZ+tbv\n" +
"2lChGI1/3KWqZP6WswPs7nev/N3WnDf7cWuouP9BKCJiWaW46Ti/fOv1gTnEw76D\n" +
"PDZi4NvzksVL6I/Qr047583QbUa/u2dv6rF/VPh3KfxSZSKWhnusQocjycntqPYN\n" +
"fxPMAJQ3clft12TUkJOkK97mqUlOGwi7k1GexL6BgI1GyWSYRb1QfkL7ypfzh/mo\n" +
"8t7+l3dyugiLCkSlHW4ljMII4xSzZt77eG7PVQBANnTJ2WzJMN8stuFigzMmt8Ks\n" +
"O5BuosgLvBS+zZfHhD6mlp+5di9ZZWhcEXmH9w3vIXpgE4fx0vuMQIJbNjkZj+pF\n" +
"bYD/v1vGTX+OoZEOtTl6ZouCcF499PhHaI/NJUJqiUmVjdNlC3DkUR94pNnaU5BJ\n" +
"MZjRA3lb38lr3ZRl3erioBqAD9jMQFgZmjRo8qzoXusluTg0i6lwTUwqaQvKW3g2\n" +
"13VvVV+K95JFXOra5zxEYS2PaBkyVME4zb4=\n" +
"-----END CERTIFICATE-----\n" +
"--START INTERMEDIATE CERT--\n" +
"-----BEGIN CERTIFICATE-----\n" +
"MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n" +
"MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n" +
"bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n" +
"A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n" +
"emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n" +
"gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n" +
"cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n" +
"EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n" +
"c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n" +
"iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n" +
"YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n" +
"GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n" +
"EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n" +
"0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n" +
"xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n" +
"9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n" +
"ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n" +
"2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n" +
"6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n" +
"5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n" +
"plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n" +
"BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n" +
"eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n" +
"gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n" +
"x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n" +
"pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n" +
"2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n" +
"rbO7BnjW\n" +
"-----END CERTIFICATE-----\n";

export const qzPrivateKey = "-----BEGIN PRIVATE KEY-----\n" +
"MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCS51wh9TX32PnU\n" +
"AktfZOOxBDnryoVBons2bRnBjfL9Hk63NBCpmodUe56SzLHkyG39Z51BmZfIZYpa\n" +
"+BFXN+VZMciKZGlJSFr8AhOriMwYOhYw34vQjVcctJtBgtT5eZaIR8rHuDAWLxLG\n" +
"cwmZC/kwo1nhII+CIsWxLaBJNDptqbi1GLbqxgpqQs5tKe6vfdERkuv4DBb6rj0E\n" +
"H1BjNFneMsWK5hclFtEw1ATtB3rVj+3BL42AEvBJlp+/bhY1R55yUB+rpzPgOP6l\n" +
"kRIPleXVLtDC17LVXFecpQ7P82qnR1wzbh0wayWTcHco4IoiWs6HPs64NPlOQu9l\n" +
"BQDqfyCzAgMBAAECggEAOQaf0LzQEODFBmRHnwXaj25ND7ejp/lrbBLJA0ZXs6Nu\n" +
"uhZ3e2c1rnl4cpMMYXvempZ8yKjXb17faPXQQXO8z7WQHAzjhCOW0in6OcXWOHdg\n" +
"AuPccf5T+RwxAqrHgvqMkWWRFZtt60aluqyQZr1ro9JdgxNxMfrOlDtac+BW6Bd6\n" +
"JHKegxpnDl1CI56bnXjJLn9Uy1ikw1RaIa7TAGeoXJg2IYTdLSXnf/4TOWtyHvhf\n" +
"GYuABVMmX14C+uKHnv3EkJd7ICUfmUk/nLKhg4dpURJrjHuwfrlGL9sEbOIN2emb\n" +
"MeQfAOcwBufL0Kp1/Uzo/howSslwCnfbOWMOjiWZ9QKBgQDJjIi5yalVnacKoR1e\n" +
"hLsCoWGtANKQ8JiFuHu3Rs/FV2vk2v9UIVRU+1KzIhUffvKaO2gxgbdYwAt8r4ET\n" +
"0avHeNIFftwuAkcUX6yws0mm8xQR2feeQFmjA+SYZOfW5MPZU5iWL6lBTOwS0LuU\n" +
"ZvXeiaB/7hYB4kCV+iu4KvE8PwKBgQC6l3iJv4zVUG01sWKur7cfP7hhRxkUkp1E\n" +
"E8bFN+r0aU66EbdE1+0A7UtkLrEDpyGcGHd4kDb/OR5bETLavKEm/AcmINDhycAt\n" +
"HrsORCc01z/dpatRHsBFvqag+Wrfo04Ac6gtBF4zhz9KCnKRHhXOd3nOcKqT+7nC\n" +
"lc8BdkqOjQKBgQCffMmk2uNYkIxiaOPoq3eguTulvwThFMJc7oNXoH1BV9BVJMbv\n" +
"OACXzti+T7YPWlF3opLsugzv+egdHnQxzrTz/7OSDbUF41+Dy8HHgdhCeA/loGnF\n" +
"nH5R9P6raQ3ciuI/01rnXYe92LTmnq4Q3PiqxJVxJmHb08Hv7Cs0bSa/2QKBgEMN\n" +
"cayWoEZ7y3MTFrJNxNG+9URH53RkJ42ySajPqmhAe6EUArcDnVDK9V/AA66bXdGZ\n" +
"XT1/HbRj00vIh2zhgkvfH7aM7mNlO+E6/N9okGpFu9ar1T+mfFPT6k9IsiZBqiMx\n" +
"bMsYmb2MqaqyzG7nz9BNUQFlrXbWa+/IJAaTesF1AoGAZ33GmsQoUhD/GE/WitXb\n" +
"m/mPD6xmogWBQz2hbNEWKDvXG9Gvyk/FWTnf5e3tceINP+TCSKSyzhv6b7u4phyo\n" +
"gjtJq+T5ytPZKsuRzprMFJI/fauhRYRsv7RbfsU33eRhzkheKm7uYdCeDHrjVjZo\n" +
"zKrp1QPTF+dcTb7qxJBfqO4=\n" +
"-----END PRIVATE KEY-----";
