import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Input, Button, FormGroup, CardTitle, Label } from 'reactstrap';
import adminActions from "../../../../redux/admin/actions";
import { FaSpinner } from "react-icons/lib/fa";

const {
  userAdminAIAnalyzeAnswer,
} = adminActions;

class AdminAIAnalyze extends Component {

  state = {
    username: "travisrenn",
    question: "What are my 3 best selling products?",
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle>AI Analyze</CardTitle>
            <FormGroup>
              <Label>Username</Label>
              <Input
                value={this.state.username}
                onChange={(e) => this.setState({username: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Question</Label>
              <Input
                value={this.state.question}
                onChange={(e) => this.setState({question: e.target.value})}
              />
            </FormGroup>
            <Button
              color="primary"
              disabled={!this.state.question || this.props.ai_analyze_answer_working}
              onClick={() => this.props.userAdminAIAnalyzeAnswer(
                {
                  question: this.state.question,
                  username: this.state.username,
                }
              )}
            >
              Answer Question {this.props.ai_analyze_answer_working ? <FaSpinner className="fa-spin ml-1" /> : null}
            </Button>
            {this.props.ai_analyze_answer &&
            <React.Fragment>
              <hr />
              <h6>Answer:</h6>
              <pre className="mt-3 p-3" style={{ textWrap: 'wrap', background: '#f6f7f9' }}>
                {this.props.ai_analyze_answer}
              </pre>
            </React.Fragment>
            }
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
    return {
      ai_analyze_answer: state.Admin.get("ai_analyze_answer"),
      ai_analyze_answer_working: state.Admin.get("ai_analyze_answer_working"),
    };
  },
  {
    userAdminAIAnalyzeAnswer,
  }
)(AdminAIAnalyze);
