import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Input, Card, CardGroup, CardBody, CardTitle } from "reactstrap";
import {
	momentDateToLocalFormatConversion,
	digitСonversion
} from "../../../../helpers/utility";
import LoadingIndicator from "../../../../shared/components/LoadingIndicator";
import statsActions from "../../../../redux/stats/actions";
import moment from "moment";

const { fetchAggregateData } = statsActions;

const statsRangeOptions = [
  { label: "all-time", value: "0"},
  { label: "7", value: "7" },
  { label: "30", value: "30" },
  { label: "90", value: "90" },
];

/**
 * Component to disalay aggregated data
 */
class AggregatedData extends Component {
  state = {
    startStatsRange: null,
    endStatsRange: null,
    statsRange: statsRangeOptions[0]
  };

  UNSAFE_componentWillMount() {
      this.props.fetchAggregateData();
  }

  handleChangeStatsRange = (e) => {
    const range = Number(statsRangeOptions.find(el => el.label === e.target.value).value);
    const statsRange = statsRangeOptions.find(el => Number(el.value) === range);

    if (range && range > 0) {
      const endStatsRange = moment().toISOString();
      const startStatsRange = moment().subtract(range, 'days').toISOString();
      this.setState({
        startStatsRange: momentDateToLocalFormatConversion(endStatsRange),
        endStatsRange: momentDateToLocalFormatConversion(startStatsRange),
        statsRange: statsRange
      });
      this.props.fetchAggregateData(startStatsRange, endStatsRange);
    } else {
      this.props.fetchAggregateData();
      this.setState({
        startStatsRange: null,
        endStatsRange: null,
        statsRange: statsRange
      });
    }
  }
	render(){
		const { startStatsRange, endStatsRange, statsRange } = this.state;
		const {
			isFetchingAggregateData,
			aggregateData,
		} = this.props;

		return (
			<Card>
				<CardBody>
				{
				  !!aggregateData &&
				  <div>
					{
					  statsRange && statsRange.value > 0
					  ? (
						<CardTitle>Stats for last {statsRange.value} days: {endStatsRange} - {startStatsRange}</CardTitle>
					  ) : (
						<CardTitle>Stats for {`${statsRange.label}`}</CardTitle>
					  )
					}

					<Col xs="4" style={{ paddingLeft: "0" }}>
					  <FormGroup className="form-inline gap-2">
						<Label for="exampleSelect">Stats range:</Label>
						<Input
						  type="select"
						  name="select"
						  id="exampleSelect"
						  value={statsRange.label}
						  onChange={ this.handleChangeStatsRange }
						>
						  {
							  statsRangeOptions.map((option, key) => (
								<option key={key}>{option.label}</option>
							  ))
						  }
						</Input>
					  </FormGroup>
					</Col>
					<br />
					{
						isFetchingAggregateData ?
							<LoadingIndicator title="Fetching Aggregate Data..."/>
							: <AggregatedTable aggregateData={aggregateData} />
					}
				  </div>
				}
				</CardBody>
			</Card>
		)
	}
}


const AggregatedTable = ({ aggregateData }) => {
	return (
		<Fragment>
		  <CardGroup className="card-group-metric mb-4">
			<Card>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">Box Contents</h6>
						<span className="value">{!!aggregateData.box_content ? digitСonversion(aggregateData.box_content, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">FBA Batches</h6>
						<span className="value">{!!aggregateData.fba_batches ? digitСonversion(aggregateData.fba_batches, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">MF Batches</h6>
						<span className="value">{!!aggregateData.mf_batches ? digitСonversion(aggregateData.mf_batches, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			</Card>
			<Card>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">Seller Labeled Items</h6>
						<span className="value">{!!aggregateData.labels.SELLER_LABEL ? digitСonversion(aggregateData.labels.SELLER_LABEL, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">Amazon Labeled Items</h6>
						<span className="value">{!!aggregateData.labels.AMAZON_LABEL_ONLY ? digitСonversion(aggregateData.labels.AMAZON_LABEL_ONLY, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">Items Listed Using Live Mode</h6>
						<span className="value">{!!aggregateData.workflow.live ? digitСonversion(aggregateData.workflow.live, 'decimal') : 0 }</span>
					</div>
			  </CardBody>
			</Card>
			<Card>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title">Items Listed Using Private Mode</h6>
						<span className="value">{!!aggregateData.workflow.private ? digitСonversion(aggregateData.workflow.private, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title"># Holding Area Batches Created</h6>
						<span className="value">{!!aggregateData.holding_area_batches ? digitСonversion(aggregateData.holding_area_batches, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			  <CardBody>
					<div className="metric-item blue">
						<h6 className="title"># Batches Using Auto Price Rule</h6>
						<span className="value">{!!aggregateData.batches_using_auto_price_rule ? digitСonversion(aggregateData.batches_using_auto_price_rule, 'decimal') : 0}</span>
					</div>
			  </CardBody>
			</Card>
		  </CardGroup>
		</Fragment>
	)
}

export default connect(
  state => {
    return {
      aggregateData: state.Stats.get('aggregateData'),
      isFetchingAggregateData: state.Stats.get('isFetchingAggregateData'),
    };
  },
  {
    fetchAggregateData
  }
)(AggregatedData);
