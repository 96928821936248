import React, { Component } from 'react';
import { FormText, Button} from 'reactstrap';
import Dropzone from 'react-dropzone';
import CollapsibleFormGroup from './CollapsibleFormGroup';
import { FaFileImageO, FaClose } from 'react-icons/lib/fa';

const IMG_MAX_COUNT = 5;

class UploadImages extends Component {
  state = {
    file_maxed: false,
    length_maxed: false
  }

  generateImage = (image, idx) => {
    return (
      <div className="image-item" key={`image_${idx}`}>
        {image ?
        <React.Fragment>
          <img src={image} alt="" className="img-fluid" />
          <Button
            size="sm"
            color="danger"
            className="btn-remove-image"
            onClick={(e) => this.removeImage(e, idx)}
          ><FaClose /></Button>
        </React.Fragment>
        :
        <FaFileImageO size="20" />
        }
      </div>
    )
  }

  generateImages = (maxCount) => {
    const { ebay_direct_listing_item } = this.props;
    const imagePreviews = ebay_direct_listing_item.image_previews ? ebay_direct_listing_item.image_previews : [];
    let images = [];
    for (let i = 0; i < maxCount; i++) {
      images.push(this.generateImage(imagePreviews[i], i));
    }
    return images;
  }

  onDropImages = (acceptedFiles, fileRejections) => {
    let existingImagesCount = 0;
    this.setState({ file_maxed: false });
    this.setState({length_maxed: false});
    //rejected file [over max size]
    if(fileRejections.length > 0){
      this.setState({ file_maxed: true });
      return null;
    }
    const { ebay_direct_listing_item } = this.props;
    if(ebay_direct_listing_item.image_previews){
      existingImagesCount = ebay_direct_listing_item.image_previews.length;
    }
    if (existingImagesCount + acceptedFiles.length > IMG_MAX_COUNT) {
      this.setState({length_maxed: true});
      return null;
    }
    this.props.ebayDirectListingUploadImage(acceptedFiles);
  }

  removeImage = (e, idx) => {
    e.stopPropagation();
    const { ebay_direct_listing_item } = this.props;
    let previews = ebay_direct_listing_item.image_previews ? ebay_direct_listing_item.image_previews : [];
    let images = ebay_direct_listing_item.images ? ebay_direct_listing_item.images : [];
    previews.splice(idx, 1);
    images.splice(idx, 1);
    this.props.editData("image_previews", previews);
    this.props.editData("images", images);
  }

  render() {
    const {
      collapse,
      toggleCollapse,
      maxSize,
      maxSizeText,
      maxCount,
    } = this.props;

    return (
      <CollapsibleFormGroup
        title="Upload Images"
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      >
        <p>Click or drag & drop images to upload.</p>
        <Dropzone
          className="drag-drop-upload-images mb-2 d-inline-flex"
          onDrop={this.onDropImages}
          multiple={true}
          maxSize={maxSize}
          accept="image/jpeg, image/png"
          style={{ cursor: "pointer" }}
        >
          {this.generateImages(maxCount)}
        </Dropzone>
        <FormText>Supported file: .png, .jpg. Max size: {maxSizeText} MB</FormText>
        {this.state.file_maxed ?
            <FormText><span style={{ color: "red", fontWeight: "bold" }}>File uploaded not valid</span></FormText>
          : null
        }
        {this.state.length_maxed ?
            <FormText><span style={{ color: "red", "font-weight": "bold" }}>Max {IMG_MAX_COUNT} images are allowed</span></FormText>
          : null
        }
      </CollapsibleFormGroup>
    )
  }
}

export default UploadImages;
