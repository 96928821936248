import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import AdminEbayDashboard from './AdminEbayDashboard';
import AdminEbayLogs from './AdminEbayLogs';
import AdminUserSales from './AdminUserSales';
import './style.css';
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";

const { getEbayAveragesTotal } = adminActions;

class AdminEbay extends Component {
  state = {
    activeTab: "1"
  }

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  componentDidMount(){
    this.props.getEbayAveragesTotal();
  }

  render() {
    const { admin_ebay_total_averages } = this.props;
    const {
      activeTab
    } = this.state;

    return (
      <React.Fragment>
        <Card className="card-tab">
          <CardHeader>
            {admin_ebay_total_averages ?
              <div className="ebay-stat-metrics">
                <div className="metric-item">
                  <h6>
                      {admin_ebay_total_averages.percentage_sales
                      ? digitСonversion(admin_ebay_total_averages.percentage_sales, "decimal", 2)
                      : 0
                    }%
                  </h6>
                  <span>Percentage Sales</span>
                </div>
                <div className="metric-item">
                  <h6>
                    ${admin_ebay_total_averages.total_sales
                      ? digitСonversion(admin_ebay_total_averages.total_sales, "decimal")
                      : 0
                    }
                  </h6>
                  <span>Total Sales</span>
                </div>
                <div className="metric-item">
                  <h6>
                    {admin_ebay_total_averages.average_sales
                      ? digitСonversion(admin_ebay_total_averages.average_sales, "decimal")
                      : 0
                    }
                  </h6>
                  <span>Avg. Sales</span>
                </div>
                <div className="metric-item">
                  <h6>
                    {admin_ebay_total_averages.sales_count
                      ? digitСonversion(admin_ebay_total_averages.sales_count, "decimal")
                      : 0
                    }
                  </h6>
                  <span>Sales Count</span>
                </div>
                <div className="metric-item">
                  <h6>
                    {admin_ebay_total_averages.average_rank
                      ? digitСonversion(admin_ebay_total_averages.average_rank, "decimal")
                      : 0
                    }
                  </h6>
                  <span>Avg. Rank</span>
                </div>
                <div className="metric-item">
                  <h6>
                    ${admin_ebay_total_averages.average_sales_price
                      ? digitСonversion(admin_ebay_total_averages.average_sales_price, "decimal")
                      : 0
                    }
                  </h6>
                  <span>Avg. Sales Price</span>
                </div>
              </div>
              : null
            }
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => this.toggleTab("1")}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => this.toggleTab("2")}
                >
                  User Sales
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "3"}
                  onClick={() => this.toggleTab("3")}
                >
                  User Logs
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {activeTab === "1" && <AdminEbayDashboard /> }
              </TabPane>
              <TabPane tabId="2">
                {activeTab === "2" && <AdminUserSales /> }
              </TabPane>
              <TabPane tabId="3">
                {activeTab === "3" && <AdminEbayLogs /> }
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
		return {
			admin_ebay_total_averages: state.Admin.get("admin_ebay_total_averages"),
    };
  },
  {
    getEbayAveragesTotal,
  }
)(AdminEbay);
