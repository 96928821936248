import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";

const { userTokenUpdate } = adminActions;

/**
 * Component to update user token
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUpdateUserToken extends Component {
    constructor (props) {
        super(props);
		this.state = {
			userUN: "",
			userEmail: "",
			userToken: "",
			sellerId: "",
		};
    }

	handleInputChange = (event, input) => {
		this.setState({[input]: event.target.value});
	}

	processUpdate(){
		const { userUN, userEmail, userToken, sellerId } = this.state;
		const data = {
			username: userUN,
			email: userEmail,
			token: userToken,
			sellerid: sellerId,
		}
		this.props.userTokenUpdate(data);
	}

	render() {
    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>User Auth Token And Seller ID Upadate</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={e => this.handleInputChange(e, "userUN")}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="token">email:</Label>
							<Input
								type="text"
								name="email"
								id="email"
								value={this.state.userEmail}
								onChange={e => this.handleInputChange(e, "userEmail")}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="token">Token:</Label>
							<Input
								type="text"
								name="token"
								id="token"
								value={this.state.userToken}
								onChange={e => this.handleInputChange(e, "userToken")}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="sellerid">Seller ID:</Label>
							<Input
								type="text"
								name="sellerid"
								id="sellerid"
								value={this.state.sellerId}
								onChange={e => this.handleInputChange(e, "sellerId")}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								color="success"
								onClick={this.processUpdate.bind(this)}
							>
								UPDATE USER TOKEN AND SELLER ID
							</Button>
						</FormGroup>
						{this.props.userTokenUpdateCompleted ? (
							<div style={{color: "green"}}>
								<b>User auth token and seller ID update success!</b>
							</div>
						) : (null)
						}
						{this.props.userTokenUpdateFailed ? (
							<div style={{color: "red"}}>
								<b>User auth token and seller ID update failed!</b>
							</div>
						) : (null)
						}
					</Col>
				</Row>
			</CardBody>
		</Card>
	</div>
    );
  }
}

export default connect(
  state => {
    return {
		userTokenUpdateCompleted: state.Admin.get('userTokenUpdateCompleted'),
		userTokenUpdateFailed: state.Admin.get('userTokenUpdateFailed'),
    };
  },
  {
	  userTokenUpdate,
  }
)(AdminUpdateUserToken);
