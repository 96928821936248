import React, { Component } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { digitСonversion } from "../../../../helpers/utility";
import PropTypes from 'prop-types';
import chart_config from "../../main_dashboard_v3/chart_config";

class CustomizedLabel extends Component {
  render() {
	  const {x, y, value, internationalizationConfig} = this.props;
    return (
        <text
          x={x}
          y={y}
          dx={17}
          dy={value > 0 ? -5 : 14}
          fontSize="12"
          fill="#3a3a3a"
          textAnchor="middle"
        >
          {digitСonversion(value, 'currency', internationalizationConfig.currency_code)}
        </text>
    );
  }
};

CustomizedLabel.propTypes = {
  internationalizationConfig: PropTypes.object.isRequired,
}

class SBYBChart extends Component {
  moneyFormat = (val) => {
    return digitСonversion(val, "currency", this.props.internationalizationConfig.currency_code);
  }

  tooltipFormatter = (value, name, props) => {
    return (name !== 'requests') ? this.moneyFormat(value) : value;
  }

  render() {
    return (
      <ResponsiveContainer
        width={"100%"}
        height={250}
      >
        <ComposedChart
          data={this.props.data}
          margin={{
            top: 15, left: 30
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke={chart_config.cartesianStrokeColor}
          />
          <XAxis
            dataKey="day"
            tickLine={false}
            axisLine={false}
            stroke={chart_config.axisFontColor}
            style={{
              fontSize: chart_config.axisFontSize
            }}
          />
          <YAxis
            yAxisId="left"
            axisLine={false}
            tickLine={false}
            tickFormatter={this.moneyFormat}
            stroke={chart_config.axisFontColor}
            style={{
              fontSize: chart_config.axisFontSize
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickLine={false}
            stroke={chart_config.axisFontColor}
            style={{
              fontSize: chart_config.axisFontSize
            }}
          />
          <Tooltip formatter={this.tooltipFormatter} cursor={false} />
          <Legend />
          <defs>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="sbybGradient">
              <stop stopColor="#7cdfff" offset="0%"></stop>
              <stop stopColor="#00b4ef" offset="100%"></stop>
            </linearGradient>
          </defs>
          <defs>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="amazonGradient">
              <stop stopColor="#ffd391" offset="0%"></stop>
              <stop stopColor="#ff9900" offset="100%"></stop>
            </linearGradient>
          </defs>
          <Bar
            yAxisId="left"
            dataKey="avg_sbyb_price"
            barSize={35}
            fill="url(#sbybGradient)"
            label={<CustomizedLabel internationalizationConfig={this.props.internationalizationConfig}/>}
          />
          <Bar
            yAxisId="left"
            dataKey="avg_amazon_price"
            barSize={35}
            fill="url(#amazonGradient)"
          />
          <Line
            yAxisId="right"
            type="natural"
            dataKey="requests"
            stroke="#FF8080"
            strokeWidth={chart_config.strokeWidth}
            dot={chart_config.dot}
          />
          <Line
            yAxisId="right"
            type="natural"
            dataKey="avg_amazon_fees"
            stroke="#42f542"
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }
}

SBYBChart.propTypes = {
  internationalizationConfig: PropTypes.object.isRequired,
	data: PropTypes.array,
}

export default SBYBChart;
