import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import chart_config from "../../main_dashboard_v3/chart_config";

class AccelerlistRepricerChart extends Component {
	legendFormatter = (value, entry) => {
		return value === "repricer" ? 'Profit Reprice™️' : 'AccelerList'
	}

	render () {
		const { data } = this.props;
		const CustomTooltip = ({ active, payload, label }) => {
			if (active) {
				return (
					<div className="recharts-tooltip-wrapper">
						<div className="recharts-default-tooltip">
							<p className="label">{label}</p>
							{payload.map((item, idx) => (
								<p
									key={'item_' + idx}
									className="item mb-0"
									style={{ color: item.color }}
								>
									{`${item.name === "repricer" ? "Profit Reprice™️" : "AccelerList" } : ${item.value}`}
								</p>
							))}
						</div>
					</div>
				);
			}
			return null;
		};

		return (
			<ResponsiveContainer
				width={"100%"}
				height={300}
			>
				<BarChart
					data={data}
					margin={{
						top: 30, left: 0, right: 30
					}}
				>
					<CartesianGrid
						vertical={false}
						stroke={chart_config.cartesianStrokeColor}
					/>
					<XAxis
						dataKey="month"
						tickLine={false}
						axisLine={false}
						stroke={chart_config.axisFontColor}
						style={{
							fontSize: chart_config.axisFontSize
						}}
					/>
					<YAxis
						axisLine={false}
						tickLine={false}
						stroke={chart_config.axisFontColor}
						style={{
							fontSize: chart_config.axisFontSize
						}}
					/>
					<Tooltip
						cursor={false}
						content={<CustomTooltip />}
					/>
					<Legend formatter={this.legendFormatter} />
					<Bar
						dataKey="accelerlist"
						maxBarSize={chart_config.barSize}
						fill="#00943E"
						stackId="a"
					/>
					<Bar
						dataKey="repricer"
						maxBarSize={chart_config.barSize}
						fill="#3D5FC0"
						stackId="a"
					/>
				</BarChart>
			</ResponsiveContainer>
		);
	}
}

export default AccelerlistRepricerChart;

