import React, { Component } from "react";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,
  Label, Input, Row, Col, Media, InputGroup, InputGroupAddon,
  FormFeedback
} from "reactstrap";
//import { digitСonversion } from "../../../../helpers/utility";
import { ebayLogoShadowed } from "../../../../assets/images";
import Select from 'react-select';
import "./style.css";
import {
  ebay_condition_mapping_for_select,
  ebay_item_data_validation,
} from '../../../../helpers/ebay/utility';
import EbayImagesUploadModal from "../../inventory/components/EbayImagesUploadModal";

class EbayListingEditModal extends Component {
  state = {
    mount: false,
    imageUrl: "",
    selectedAdditionalFiled: null,
    showUploadModal: false
  }

  componentDidMount = () => {
    const { ebay_list_item_data } = this.props;
    this.props.ebayGetItemEditAspects({ category_id: this.props.ebay_list_item_data.final_item_json.category_ebay.value })
    if(ebay_list_item_data.final_item_json
      && ebay_list_item_data.final_item_json.ebay_additional_fields){
      let af = [];
      if(!ebay_list_item_data.is_manually_listed){
        af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
      }
      ebay_list_item_data.final_item_json.ebay_additional_fields.forEach(item => {
        const af_item_find = af.find(af_item => item.value === af_item.value);
        if(af_item_find){
          if(af_item_find.desc.label !== item.desc.label){
            af.push(item);
          }
        } else {
          af.push(item);
        }
      });
      this.updateEbayByValue('ebay_additional_fields', 'object', af);
    }
  }

  additionalFieldsList = () => {
    const { ebay_list_item_data } = this.props;
    var fields = [];
    var selected = [];
    if(ebay_list_item_data.ebay_additional_fields){
      selected = ebay_list_item_data.ebay_additional_fields.map(
        item => {return item.desc.value} );
    }
    this.props.ebay_item_aspects.forEach(item => {
      if(!selected.includes(item.desc.value)){
        item.desc.values = item.values;
        item.desc.required = item.required;
        fields.push(item.desc);
      }
    });
    return fields;
  }

  selectAdditionalField = (field) => {
    this.updateState('selectedAdditionalFiled', field);
  }

  addAdditionalField = () => {
    const { ebay_list_item_data } = this.props;
    this.updateState('selectedAdditionalFiled', null);
    const field = this.state.selectedAdditionalFiled;
    if(!field){
      return;
    }
    var selected = [];
    if(ebay_list_item_data.ebay_additional_fields){
      selected = ebay_list_item_data.ebay_additional_fields.map(
        item => {return item.desc.value} );
    }
    if(selected.includes(field.value)){
      return;
    }
    const new_field = this.props.ebay_item_aspects.find(
      item => item.desc.value === field.value);
    if(new_field){
      let af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
      af.push(new_field)
      this.updateEbayByValue('ebay_additional_fields', 'object', af);
    }
  }

  deleteAdditionalField = (field) => {
    const { ebay_list_item_data } = this.props;
    var selected = [];
    if(ebay_list_item_data.ebay_additional_fields){
      selected = ebay_list_item_data.ebay_additional_fields.map(
        item => {return item.desc.value} );
    }
    if(!selected.includes(field.value)){
      return;
    }
    let af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
    af = af.filter(item => item.desc.value !== field.value);
    this.updateEbayByValue('ebay_additional_fields', 'object', af);
  }

  updateAdditionalFieldStr = (field, value) => {
    const { ebay_list_item_data } = this.props;
    const af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
    let new_af = af.map(item => {
      if(item.desc.value === field){
        item.value = value;
      }
      return item;
    })
    this.updateEbayByValue('ebay_additional_fields', 'object', new_af);
  }

  imgUrlChanged = (idx, value) => {
    let imgUrls = this.state.img_urls;
    imgUrls[idx] = value;

    this.setState({
      imgUrls: imgUrls
    })
  }

  updateState = (key, value) => {
    this.setState({ [key]: value });
  }

  checkNullValues = (data) => {
    if (data.final_item_json && data.final_item_json.img_urls.length == 0) {
      return true;
    }
    const keys = ["title", "price"]
    return keys.some(function(e) {
      return !Boolean(data[e]);
    });
    /*
    if(!data.qty && data.qty !==0){
      return true;
    }
    */
  }

  addImage = () => {
    let final_item_json = this.props.ebay_list_item_data.final_item_json;
    if(!final_item_json.img_urls){
      final_item_json.img_urls = [];
    }
    if (this.state.imageUrl) {
      final_item_json.img_urls.push(this.state.imageUrl);
      const data = [{
        key: "final_item_json",
        value: final_item_json,
        type: "object",
      }]
      this.props.ebayEditItemUpdateData(data);
      this.updateState('imageUrl', '');
      this.updateAdditionalDataEbayItem('images');
    }
  }

  deleteImage = (val) => {
    let final_item_json = this.props.ebay_list_item_data.final_item_json;
    final_item_json.img_urls = final_item_json.img_urls.filter(item => item !== val);
    const data = [{
      key: "final_item_json",
      value: final_item_json,
      type: "object",
    }]
    this.props.ebayEditItemUpdateData(data);
    this.updateAdditionalDataEbayItem('images');
  }

  updateEbayString = (key, value) => {
    if(key.includes('.')){
      // try to handle sub levels
      const path = key.split('.');
      let update_data = this.props.ebay_list_item_data[path[0]];
      update_data[path[1]] = value;
      value = update_data;
      key = path[0];
    }
    const data = [{
      key: key,
      value: value,
      type: "str",
    }]
    this.props.ebayEditItemUpdateData(data);
    this.updateAdditionalDataEbayItem(key);
  }

  updateEbayFloat = (key, value) => {
    let val = parseFloat(value);
    if(!val){ val = 0 };
    const data = [{
      key: key,
      value: val,
      type: "float",
    }]
    this.props.ebayEditItemUpdateData(data);
    this.updateAdditionalDataEbayItem(key);
  }

  updateEbayInt = (key, value) => {
    let val = parseInt(value, 10);
    if(!val){ val = 0 };
    const data = [{
      key: key,
      value: val,
      type: 'int',
    }]
    this.props.ebayEditItemUpdateData(data);
    this.updateAdditionalDataEbayItem(key);
  }

  updateAdditionalDataEbayItem = (key) => {
    let update_additional_data = Object.assign([], this.props.ebay_list_item_data.update_additional_data);
    if(!update_additional_data){
      update_additional_data = [];
    }
    if(!update_additional_data.includes(key)){
      const data = [{
        key: "update_additional_data",
        value: key,
        type: 'array',
        action: "add",
      }]
      this.props.ebayEditItemUpdateData(data);
    }
  }

  updateEbayByValue = (key, type, value) => {
    const data = [{	key, type, value }]
    this.props.ebayEditItemDataItem(data);
  }

  ebayUpdateItem = () => {
    const { ebay_list_item_data } = this.props;
    let data = {
      price: ebay_list_item_data.price,
      qty: ebay_list_item_data.qty,
      offer_id: ebay_list_item_data.offer_id,
      sku: ebay_list_item_data.sku,
      aspects: ebay_list_item_data.ebay_additional_fields,
    }
    if(ebay_list_item_data.update_additional_data){
      if(ebay_list_item_data.update_additional_data.includes('title')){
        data['title'] = ebay_list_item_data.title;
      }
      if(ebay_list_item_data.update_additional_data.includes('ebay_description')){
        data['ebay_description'] = ebay_list_item_data.ebay_description;
        this.updateEbayString('final_item_json.ebay_description', ebay_list_item_data.ebay_description);
      }
      if(ebay_list_item_data.update_additional_data.includes('images')){
        data['images'] = ebay_list_item_data.final_item_json.img_urls;
      }
    }
    if(ebay_list_item_data.ebay_condition){
      data['condition'] = ebay_list_item_data.ebay_condition;
    }
    this.props.ebayEditItemUpdateDataEbay({
      item: data,
    });
  }


  renderAdditionalItem = (item) => {
    var field = null;

    var selectOptions = [];
    if(item.desc.values){
      selectOptions = item.desc.values.map(val => {
        return { label: val, value: val }
      });
    }

    if(item.type === 'str' || !item.type){
      field = (
        <Col sm={12} key={`additional-item-${item.desc.label}`}>
          <FormGroup>
            <Label style={{ width: "10rem", justifyContent: "left" }}>{item.desc.label}</Label>
            <InputGroup className="w-50 ml-2">
              {item.desc.values && item.desc.values.length > 0
                  && (item.aspect_constraint && item.aspect_constraint.aspectMode !== "FREE_TEXT") ?
                <Select
                  options={selectOptions}
                  value={selectOptions.find(val => val.value === item.value)}
                  onChange={e => this.updateAdditionalFieldStr(item.desc.value, e ? e.value : "")}
                  placeholder="Search and select..."
                />
                :
                <Input
                  value={ item.value }
                  onChange={e => this.updateAdditionalFieldStr(item.desc.value, e.target.value)}
                  placeholder="Provide value"
                />
              }
              <InputGroupAddon addonType="append">
                <Button
                  color="link"
                  onClick={e => this.deleteAdditionalField(item.desc)}
                  className="ml-2"
                  title="Remove Field"
                ><span className="icon icon-trash bg-danger"></span></Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
      );
    }
    return field;
  }

  ebayConditionMappingCaluclate = (ebay_list_item_data) => {
    var allowed = [];
    if(ebay_list_item_data.final_item_json
      && ebay_list_item_data.final_item_json.ebay_allowed_conditions){
      if(Array.isArray(ebay_list_item_data.final_item_json.ebay_allowed_conditions)){
        allowed = ebay_condition_mapping_for_select(
          ebay_list_item_data.final_item_json.ebay_allowed_conditions);
      } else {
        allowed = ebay_condition_mapping_for_select(
          ebay_list_item_data.final_item_json.ebay_allowed_conditions[ebay_list_item_data.final_item_json.category_ebay.value])
      }
    }
    return allowed;
  }

  displayRequiredFields = () => {
    var required = []
    if(this.props.ebay_item_aspects){
      this.props.ebay_item_aspects.forEach(item => {
        if(item.aspect_constraint && item.aspect_constraint.aspectRequired){
          required.push(item.desc.label);
        }
      })
    }
    if(required.length > 0){
      return "Required fields: " + required.join(', ');
    }
    return null;
  }

  handleBrFix = (text) => {
    text = text.replaceAll("<br />", "\n");
    return text;
  }

  render() {
    const {
      isOpen,
      toggle,
      ebay_list_item_data,
    } = this.props;

    const amazon_url = `https://www.amazon.com/dp/${ebay_list_item_data.asin}`;

    return (
      <Modal isOpen={isOpen} toggle={e => toggle(false)} modalClassName="ebay-listing-modal">
        <ModalHeader
          toggle={e => toggle(false)}
        >Edit eBay listing for <em>{ebay_list_item_data.title}</em></ModalHeader>
        <ModalBody>
        <div className="ebay-listing-header">
            <div>
              SKU:&nbsp;
              {ebay_list_item_data.sku}
              <br />
              ASIN:&nbsp;
              <a
                href={amazon_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {ebay_list_item_data.asin}
              </a>
            </div>
            <div>
              <ActionButtons
                toggle={toggle}
                ebayUpdateItem={this.ebayUpdateItem}
                ebay_item_updating={this.props.ebay_item_updating}
                ebay_list_item_data={ebay_list_item_data}
                disableButton={this.checkNullValues(ebay_list_item_data)}
              />
            </div>
          </div>
          <div className="ebay-listing-general">
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    value={ebay_list_item_data.title}
                    onChange={e => this.updateEbayString('title', e.target.value)}
                    style={!ebay_item_data_validation('title', ebay_list_item_data.title) ? {border: "1px solid #ff0000"} : null}
                  />
                  {!ebay_list_item_data.title && (
                    <FormFeedback>Title is required</FormFeedback>
                  )}
                  <div>Length: {ebay_list_item_data.title ? ebay_list_item_data.title.length : 0}</div>
                </FormGroup>
                <FormGroup>
                  <Label>Image URLs</Label>
                  {ebay_list_item_data.final_item_json
                      && ebay_list_item_data.final_item_json.img_urls ?
                    ebay_list_item_data.final_item_json.img_urls.map((url, idx) => (
                      <Media
                        className={`ebay-listing-media ${!url ? "add" : ""}`}
                        key={`image_url_${idx}`}
                      >
                        <Media left>
                          <Media object src={url} alt="" />
                        </Media>
                        <Media body>
                          <InputGroup>
                            <Input
                              type="url"
                              value={url}
                              disabled={true}
                            />
                            <InputGroupAddon addonType="prepend">
                              <Button
                                color="danger"
                                outline
                                onClick={e => this.deleteImage(url)}
                              >DELETE</Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </Media>
                      </Media>
                    )) : null
                  }
                  <Media className={`ebay-listing-media add`}>
                    <Media body>
                      <InputGroup>
                        <Input
                          type="url"
                          placeholder="paste image url..."
                          value={this.state.imageUrl}
                          onChange={e => this.updateState('imageUrl', e.target.value)}
                        />
                        <InputGroupAddon addonType="prepend">
                          <Button
                            onClick={e => this.addImage()}
                          >ADD</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Media>
                  </Media>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => this.setState({ showUploadModal: true })}
                  >Upload my own images</Button>
                  {this.state.showUploadModal &&
                  <EbayImagesUploadModal
                    isOpen={true}
                    toggle={() => this.setState({ showUploadModal: !this.state.showUploadModal })}
                  />
                  }
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Description</Label>
                  <Input type="textarea" rows="11"
                    value={
                      ebay_list_item_data.ebay_description
                        ? this.handleBrFix(ebay_list_item_data.ebay_description)
                        : ebay_list_item_data.final_item_json && ebay_list_item_data.final_item_json.ebay_description
                          ? this.handleBrFix(ebay_list_item_data.final_item_json.ebay_description)
                          : ""
                      }
                    onChange={e => this.updateEbayString('ebay_description', e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            </div>
          <div className="ebay-listing-details">
            <FormGroup row>
              <Label sm={2}>Required fields</Label>
              <Col sm={10}>
                <Row>
                  <Col sm={4}>
                    <div>
                      <Label>Category</Label>
                      <Select
                        options={ebay_list_item_data.final_item_json
                          && ebay_list_item_data.final_item_json.category_ebay
                            ? [ebay_list_item_data.final_item_json.category_ebay]
                            : []
                        }
                        value={ebay_list_item_data.final_item_json
                          && ebay_list_item_data.final_item_json.category_ebay
                            ? ebay_list_item_data.final_item_json.category_ebay
                            : null
                        }
                        //onChange={e => this.selectEbayCategory(e)}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div>
                      <Label>Condition</Label>
                      <Select
                        options={ebay_list_item_data.final_item_json
                          && ebay_list_item_data.final_item_json.ebay_allowed_conditions
                            ? this.ebayConditionMappingCaluclate(ebay_list_item_data)
                            : []
                        }
                        value={ebay_list_item_data.final_item_json
                          && ebay_list_item_data.final_item_json.ebay_condition
                            ? ebay_list_item_data.ebay_condition
                              ? ebay_list_item_data.ebay_condition
                              : ebay_list_item_data.final_item_json.ebay_condition
                            : null
                        }
                        onChange={e => this.updateEbayByValue('ebay_condition', 'object', e)}
                      />
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div>
                      <Label>Quantity</Label>
                      <Input
                        value={ebay_list_item_data.qty ? ebay_list_item_data.qty: 0 }
                        onChange={e => this.updateEbayInt('qty', e.target.value)}
                        className="ml-2"
                      />
                      {/*
                      {!ebay_list_item_data.qty && (
                      <FormFeedback>Quantity cannot be zero.</FormFeedback>
                      )}
                      */}
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div>
                      <Label>Price</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <Input
                          type="number"
                          min="0"
                          value={ebay_list_item_data.price ? ebay_list_item_data.price : null}
                          onChange={e => this.updateEbayFloat('price', e.target.value)}
                        />
                      </InputGroup>
                      {!ebay_list_item_data.price && (
                          <FormFeedback>Price cannot be zero.</FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </div>
          <div className="ebay-listing-details">
            <FormGroup row>
              <Col sm={4}>
                <div className="d-flex align-items-center">
                  <Select
                    options={this.additionalFieldsList()}
                    value={this.state.selectedAdditionalFiled}
                    onChange={e => this.selectAdditionalField(e)}
                    placeholder="Select field..."
                  />
                  <Button
                    disabled={!this.state.selectedAdditionalFiled}
                    onClick={e => this.addAdditionalField()}
                    className="ml-2"
                  >ADD FIELD</Button>
                </div>
              </Col>
              <Col sm={4}>
                <div className="d-flex align-items-center">
                  <b>{this.displayRequiredFields()}</b>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Additional fields</Label>
              <Col sm={10}>
                {ebay_list_item_data.ebay_additional_fields ?
                  ebay_list_item_data.ebay_additional_fields.map(item => {
                    return (
                      <React.Fragment key={`ad-field-${item.desc.value}`}>
                        <hr />
                        <Row className="form-inline">
                          {this.renderAdditionalItem(item)}
                        </Row>
                      </React.Fragment>
                    )
                  }) : null
                }
              </Col>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <ActionButtons
            toggle={e => toggle(false)}
            ebayUpdateItem={this.ebayUpdateItem}
            ebay_item_updating={this.props.ebay_item_updating}
            ebay_list_item_data={ebay_list_item_data}
            disableButton={this.checkNullValues(ebay_list_item_data)}
          />
        </ModalFooter>
      </Modal>
    )
  }
}

export const ActionButtons = props => {
  return (
    <div className="ebay-action-buttons">
      <Button
        onClick={e => props.toggle(false)}
        disabled={props.ebay_item_updating}
      >Close</Button>
      {/*
      <Button
        color="success"
        disabled={props.ebay_created_item_data_executing}
      >Save as Draft</Button>
      */}
      <button
        className="btn btn-list-ebay"
        onClick={e => props.ebayUpdateItem()}
        disabled={props.ebay_item_updating || props.disableButton}
      >
        Update on <span className="sr-only">eBay</span> <img src={ebayLogoShadowed} alt="" className="ebay-logo" />
    </button>
    </div>
  )
}

export default EbayListingEditModal;
