import React from "react";
import { Input } from "reactstrap";
import TooltipAtCustomElement from "../../../../../shared/components/TooltipAtCustomElement";
import { connect } from "react-redux";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

class BatchSearchRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		searchText: "",
    };
    this.clearSearch = this.clearSearch.bind(this);
  }

  clearSearch(){
	this.setState({searchText: ""});
	this.props.searchBatch("");
  }

  handleSearchChange(event) {
	this.props.searchBatch(event.target.value);
	this.setState({searchText: event.target.value});
  }

  render() {
	let { isLoading } = this.props;
    return (
      <div className="d-flex align-items-center" style={{ position: 'relative' }}>
        <TooltipAtCustomElement
          tooltipId="BatchSearchBar"
          tooltipText="Enter search term to search in the batch.
          Click broom  button if you want to reset your search."
          CustomElement={IconTooltip}
          styles={{ maxWidth: "20rem" }}
        />
        &nbsp;
		<Input
			value={this.state.searchText}
            autoComplete="off"
            type="text"
			placeholder="SEARCH TITLE / ASIN / SKU / PRICE..."
            className="mr-2"
            style={isLoading ? { cursor: "progress" } : {}}
            onChange={this.handleSearchChange.bind(this)}
            id="batch-search"
            ref={el => this.inputSearch = el}
            disabled={isLoading} // please set disabled true for completed batch
          />
          {isLoading ? <span className="toggle-dot" /> : ""}
      {this.state.searchText !== '' &&
      <span
        alt="Clear search"
        className="icon icon-12 icon-close"
        role="button"
        style={{ position: 'absolute', right: '1rem' }}
				onClick={this.clearSearch}
      />
      }
	</div>

    );
  }
}

export default connect(
  state => ({
  }),
  {
  }
)(BatchSearchRow);
