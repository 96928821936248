import React, { Component } from 'react';
import { connect } from "react-redux";
import ExpenseTable from './ExpenseTable';
import ManageExpenseCategory from './ManageExpenseCategory';
import { Button } from "reactstrap";
import appActions from "../../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const ViewHeader = (props) => (
  <header className="view-header">
    <h1 className="page-title">{props.title}</h1>
    <p className="page-subtitle">{props.subtitle}</p>
  </header>
);

const ViewContent = ({children}) => (
  <div className="view-content view-components">
      {children}
  </div>
);

class Expenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'expenses',
      title: 'Manage Expenses',
      subtitle: 'Manage all of your business expenses'
    }
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Accounting/Expenses"})
  }

  toggleView = (view) => {
    this.setState({
      view: view,
      title: view === 'expenses' ? 'Manage Expenses' : 'Manage Expense Category',
      subtitle: view === 'expenses' ? 'Manage all of your business expenses' : 'Add, edit and delete expense category',
    })
  }

  render() {
    const {
      view
    } = this.state;

    return (
      <div className="view">
        <div className="d-flex justify-content-between">
          <ViewHeader
            title={this.state.title}
            subtitle={this.state.subtitle}
          />
          <div>
            {view === 'expenses' ?
            <Button color="primary" onClick={() => this.toggleView('category')}>Manage Expense Category</Button>
            :
            <Button color="primary" onClick={() => this.toggleView('expenses')}>Manage Expenses</Button>
            }
          </div>
        </div>
        <ViewContent className="mt-1">
          {view === 'expenses' && <ExpenseTable />}
          {view === 'category' && <ManageExpenseCategory />}
        </ViewContent>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  {
    userPageviewCapture,
  }
)(Expenses);
