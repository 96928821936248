import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import createHistory from 'history/createBrowserHistory'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './sagas'
//import LogRocket from 'logrocket';

const history = createHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
let middlewares = [sagaMiddleware, routeMiddleware]
//if(process.env.REACT_APP_LOG_ROCKET_ENV === 'production') middlewares.push(LogRocket.reduxMiddleware());

const store = createStore(
  combineReducers({
    ...reducers,
    router: connectRouter(history)
  }),
  process.env.NODE_ENV !== 'production'
    ? compose(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
)

sagaMiddleware.run(rootSaga)
export { store, history }
