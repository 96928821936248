import React, { Component } from 'react'
import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { digitСonversion } from "../../../helpers/utility";
import moment from "moment";

class OrderDetailsModal extends Component {

  calculatePrice = () => {
    var total = 0;
    this.props.order?.items?.OrderItems?.forEach(item => {
      total = total + item?.ItemPrice?.Amount;
    })
    return total;
  }

  calculateShipping = () => {
    var total = 0;
    this.props.order?.items?.OrderItems?.forEach(item => {
      total = total + item?.ShippingPrice?.Amount;
    })
    return total;
  }

  render() {
    const {
      isOpen,
      toggle,
      order,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered scrollable size="lg" className="order-details-modal">
        <ModalHeader toggle={toggle}>Order ID: {order?.AmazonOrderId}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={5}>
              <Table className="table-order-details table-order-info">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <td>{moment(order?.PurchaseDate).format("LLL")}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td><Badge color="info">{order?.OrderStatus}</Badge></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col sm={7}>
              <Table className="table-order-details table-order-info">
                <tbody>
                  <tr>
                    <th>Customer</th>
                    <td>{order?.BuyerInfo?.BuyerEmail}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <hr />

          <h6>Order Items</h6>
          <Table className="table-order-details table-order-items">
            <thead>
              <tr>
                <th>Title</th>
                <th className="text-right">Qty</th>
                <th className="text-right">Price</th>
                <th className="text-right">Shipping</th>
              </tr>
            </thead>
            <tbody>
              {order?.items?.OrderItems?.map(item => (
              <tr key={`order-items-${item?.ASIN}`}>
                <td>
                  <div className="d-flex">
                    <div><img src={`https://www.amazon.com/images/P/${item?.ASIN}.01._THUMBZZZ_.jpg`} width={20} alt="" /></div>
                    <div className="ml-2">
                      <div style={{ maxWidth: 500, lineHeight: 1 }} className="text-truncate">{item?.Title}</div>
                      <small>ASIN: {item?.ASIN}</small>
                    </div>
                  </div>
                </td>
                <td className="text-right">{item?.QuantityOrdered}</td>
                <td className="text-right">{digitСonversion(item?.ItemPrice?.Amount ? item?.ItemPrice?.Amount : 0, "currency")}</td>
                <td className="text-right">{digitСonversion(item?.ShippingPrice?.Amount ? item?.ShippingPrice?.Amount : 0, "currency")}</td>
              </tr>
              ))}
            </tbody>
          </Table>

          <hr />

          <h6>Payment</h6>
          <p>
            <span>Method <strong>{order?.PaymentMethod}</strong></span>
          </p>
          <Table className="table-order-details table-order-info">
            <tr>
              <th>Subtotal</th>
              <td className="text-right">{digitСonversion(this.calculatePrice(), "currency")}</td>
            </tr>
            <tr>
              <th>Shipping</th>
              <td className="text-right">{digitСonversion(this.calculateShipping(), "currency")}</td>
            </tr>
            <tr>
              <th><strong>Total</strong></th>
              <td className="h6 text-right">{digitСonversion(order?.OrderTotal?.Amount ? order?.OrderTotal?.Amount : 0, "currency")}</td>
            </tr>
          </Table>

          {/*
          <hr />

          <h6 className="mb-3">Feedback</h6>
          <p>Rating: <strong>4.7</strong></p>
          <em>"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</em>
          */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default OrderDetailsModal;
