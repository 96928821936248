import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'reactstrap';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { digitСonversion } from "../../../helpers/utility";
import './style.css';
import { Link } from "react-router-dom";
import ebayActions from '../../../redux/ebay/actions';
import moment from "moment";
import { imgGenericEbayConversion } from '../../../assets/images';
import chart_config from "../main_dashboard_v3/chart_config";
import getGradient from "../main_dashboard_v3/chart_gradients";
import CustomizedLabel from '../main_dashboard_v3/CustomizedLabel';

const {
  ebayMarketingConversionGet,
} = ebayActions;

class EbayMarketingConvert extends Component {
  moneyFormat = (val) => {
    return digitСonversion(val, "currency", "USD");
  }

  tooltipFormatter = (value, name, props) => {
    return this.moneyFormat(value);
  }

  componentDidMount = () => {
    this.props.ebayMarketingConversionGet();
  }

  sellersDisplay = () => {
    const {
      ebay_marketing_conversion,
      internationalization_config,
    } = this.props;

    return (
      <div className="right">
        <h4 className="title">REAL RESULTS FROM OUR SELLERS</h4>
        { ebay_marketing_conversion
            && ebay_marketing_conversion.current_users ?
              ebay_marketing_conversion.current_users.map(item => {
                return (
                  <div className="ebay-result" key={`ebay_result_${item.username}`}>
                    <div className="seller-info">
                      <span className="username">{item.username}</span>
                      <span className="started">Started {moment(item.registered_at).format("MMMM YYYY")}</span>
                    </div>
                    <span className="total">{item.sales ? digitСonversion(item.sales, "currency", internationalization_config.currency_code) : digitСonversion(0, "currency", internationalization_config.currency_code) }</span>
                  </div>
                )
              })
            : null
        }
      </div>
    )
  }

  render() {
    const {
      userData,
      ebay_marketing_conversion,
      internationalization_config,
    } = this.props;
    return (
      <div className="marketing-main">
        <div className="marketing-section section-top">
          <div className="left">
            <h3 className="title">{ userData.userName ? userData.userName.toUpperCase() : ""}, YOU COULD BE MAKING {ebay_marketing_conversion && ebay_marketing_conversion.user_sales_month ? digitСonversion(ebay_marketing_conversion.user_sales_month, "currency", internationalization_config.currency_code) : digitСonversion(0, "currency", internationalization_config.currency_code)} MORE IN SALES A MONTH WITH OUR EBAY CROSS LISTING FEATURE.</h3>
            <p>
              For just $0/month you could be earning {ebay_marketing_conversion && ebay_marketing_conversion.user_sales_month ? digitСonversion(ebay_marketing_conversion.user_sales_month, 'currency', internationalization_config.currency_code) : digitСonversion(0, "currency", internationalization_config.currency_code)} more a month in sales. Multi-Channel Fullfillment is approved by Amazon and
              they encourage it. What are you waiting for?
            </p>
            <Link
              className="btn btn-success btn-lg"
              to="/dashboard/settings"
            >
              Auth AccelerList on eBay and start cross listing
            </Link>
          </div>
          {this.sellersDisplay()}
        </div>
        <div className="marketing-section section-bottom">
          <Row>
            <Col md={3}>
              <h3 className="title">
                { userData.userName ? userData.userName.toUpperCase() : ""}, YOU ARE LOSING ROUGHLY <span className="text-danger">{ebay_marketing_conversion && ebay_marketing_conversion.user_sales_month ? digitСonversion(ebay_marketing_conversion.user_sales_month, 'currency', internationalization_config.currency_code) : digitСonversion(0, 'currency', internationalization_config.currency_code) }</span> IN NEW MONTHLY SALES REVENUE BY NOT USING OUR EBAY INTEGRATION
              </h3>
              <div className="testimonial">
                <div className="picture">
                  <img src={imgGenericEbayConversion} alt="" className="img-fluid" />
                </div>
                <div className="content">
                  <em>"This eBay integration is working so well I'm starting to buy gaylords of books"</em>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div style={{ height: "300px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={ebay_marketing_conversion?.chart_data ? ebay_marketing_conversion?.chart_data : []}
                  margin={{
                    top: 20,
                    left: 30
                  }}
                >
                  <defs>
                    {getGradient("gradientGreenWith", "green")}
                    {getGradient("gradientBlueWithout", "blue")}
                  </defs>
                  <CartesianGrid vertical={false} stroke={chart_config.cartesianStrokeColor} />
                  <XAxis
                    tickLine={false}
                    dataKey="year"
                    axisLine={false}
                    stroke={chart_config.axisFontColor}
                    style={{
                      fontSize: chart_config.axisFontSize,
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    stroke={chart_config.axisFontColor}
                    style={{
                      fontSize: chart_config.axisFontSize,
                    }}
                    tickFormatter={this.moneyFormat}
                  />
                  <Tooltip formatter={this.tooltipFormatter} cursor={false} />
                  <Legend />

                  <Bar
                    name="With eBay"
                    dataKey="potential_revenue"
                    fill="url(#gradientGreenWith)"
                    radius={chart_config.barRadius}
                    label={<CustomizedLabel internationalizationConfig={internationalization_config} dx={24} />}
                  />
                  <Bar
                    name="Without eBay"
                    dataKey="revenue"
                    fill="url(#gradientBlueWithout)"
                    radius={chart_config.barRadius}
                    label={<CustomizedLabel internationalizationConfig={internationalization_config} dx={24} />}
                  />
                </BarChart>
              </ResponsiveContainer>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.Auth.get('userData'),
    ebay_marketing_conversion: state.Ebay.get('ebay_marketing_conversion'),
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
    ebayMarketingConversionGet,
  }
)(EbayMarketingConvert)
