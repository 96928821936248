import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, Input, Button, ButtonGroup } from 'reactstrap';
import PropTypes from 'prop-types';

class InputAmountType extends Component {
	setAmount = (e) => {
		var amount = e.target.value;
		if(this.props.amountType === "%"){
			amount = parseInt(e.target.value, 10);
			if((parseInt(e.target.value, 10) > 100 && !this.props.allowPercenatgeOver100) || parseInt(e.target.value, 10) < 1){
				amount = 0;
			}
		}
		if(this.props.amountType === "$"){
			amount = parseFloat(e.target.value, 10);
		}
		this.props.updateAmount(amount);
		this.props.updateTemplateJson( {data: {[this.props.dataInTemplateJson]: amount } })
	}

  toggleRepriceAmountType = () => {
    const currentType = this.props.amountType;
	const curAmount = this.props.value;
	var amount = curAmount;
	if(currentType === "$"){
		amount = parseInt(curAmount, 10);
		if((curAmount > 100 && !this.props.allowPercenatgeOver100) || curAmount < 1){
			amount = 0;
		}
	}
	if(this.props.amountType === "%"){
		amount = parseFloat(curAmount, 10);
	}
	this.props.toggle();
	this.props.updateAmount(amount);
	this.props.updateTemplateJson(
		{
			data: {
				[this.props.dataInTemplateJson]: amount,
				[this.props.typeDataInTemplateJson]: currentType === "%" ? true : false,
			}
		})
  }

  render() {
    const {
      value,
      amountType,
	  className,
		disabled
    } = this.props;


    return (
      <InputGroup className={`input-group-inline ${className !== undefined ? className : ''}`}>
		  <Input
			  type="number"
				step={amountType === '%' ? "1" : "any"}
			  value={value}
			  onChange={e => this.setAmount(e)}
				disabled={disabled}
		  />
        <InputGroupAddon addonType="append">
          <ButtonGroup>
			{(this.props.displayOnlyOneType && this.props.displayOnlyOneTypeOption === '$') ||
				!this.props.displayOnlyOneType ?
	          <Button
		        active={amountType === '$'}
						disabled={disabled}
				onClick={e => this.toggleRepriceAmountType()}
	          >$</Button>
			: null
			}
			{(this.props.displayOnlyOneType && this.props.displayOnlyOneTypeOption === '%') ||
				!this.props.displayOnlyOneType ?
          <Button
            active={amountType === '%'}
						disabled={disabled}
			onClick={e => this.toggleRepriceAmountType()}
          >%</Button>
			: null
			}
          </ButtonGroup>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

InputAmountType.propTypes = {
	amountType: PropTypes.string,
	toggle: PropTypes.func.isRequired,
	updateAmount: PropTypes.func.isRequired,
	updateTemplateJson: PropTypes.func.isRequired,
	dataInTemplateJson: PropTypes.string.isRequired,
	typeDataInTemplateJson: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	displayOnlyOneType: PropTypes.bool,
	displayOnlyOneTypeOption: PropTypes.string,
	allowPercenatgeOver100: PropTypes.bool,
};

export default InputAmountType;
