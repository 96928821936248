import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { CardTitle, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import accountingActions from "../../../../../redux/accounting/actions";
import LoadingIndicator from '../../../../../shared/components/LoadingIndicator';
import { incomeReportUrl } from "../../../../../config/mediaLinks";
import './style.css';
import DeleteDialog from "../../../../../shared/components/dialogs/DeleteDialog";

const {
	getAvailableReports,
	getAvailableReportsByLink,
	clearUploadedTransactionReportData,
	deleteReport,
	updateReport,
} = accountingActions;

const paginationOptions = [
  1, 5, 10, 30, 50
];

class AvailableReports extends Component {
  state = {
    paginationValue: 5,
    currentPage: 1,
    editId: -1,
    deleteId: -1,
    deleteName: ""
  }
  componentDidMount() {
    const { paginationValue } = this.state;
    this.props.getAvailableReports(1, paginationValue);
  }

  getAvailableReportsList = () => {
    const { availableReports, clearUploadedTransactionReportData } = this.props;
    const { editId } = this.state;
    let availableReportsList = null;

    if (availableReports && availableReports.results && availableReports.results.length > 0) {
      availableReportsList = availableReports.results.map(el => (
        <div
          key={`availableReportsListoption-${el.id}`}
          className="report-item"
        >
          <span
            className="d-inline-block m-auto"
            onClick={ () => {
              clearUploadedTransactionReportData();
              this.props.history.push(
                `${incomeReportUrl}/${el.id}`
              );
            }}
          >
            <div className="icon-report">
              <span className="icon icon-accounting"></span>
              <span className="icon icon-close bg-danger icon-delete" onClick={(e) => this.confirmDelete(e, el)} />
            </div>
            {editId !== el.id &&
              <Fragment>
                <span className="title">
                  {el.name}
                </span>
                <span className="icon icon-edit" onClick={(e) => this.toggleEdit(e, el)}></span>
              </Fragment>
            }
          </span>
				{/* defaultValue={el.id} */}
				{/* onKeyDown={(e) => {if(e.keyCode === 13) this.toggleEdit(e, el.id)}} */}
          {editId === el.id &&
            <InputGroup size="sm" className="mt-1">
              <Input
				  type="text"
				  defaultValue={el.name}
				  onChange={(e) => this.updateState(e, "report_name_"+el.id.toString())}
              />
              <InputGroupAddon addonType="append">
				  <Button
					  onClick={(e) => this.renameReport(el)}
				  >
                  <span className="icon icon-12 icon-check bg-success"></span>
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
				  <Button
					  onClick={(e) => this.toggleEdit(e, el)}
				  >
                  <span className="icon icon-12 icon-close"></span>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          }
        </div>
      ))
    }

    if (!availableReportsList) {
      availableReportsList = (
        <div>
          No available reports
        </div>
      )
    }

    return availableReportsList;
  }

	updateState = (e, state) => {
		this.setState({[state]: e.target.value});
	}

	renameReport = (item) => {
		const data = {
			generatedReportId: item.id,
			name: this.state['report_name_'+item.id.toString()]
		}
		this.props.updateReport(data);

	    let prevId = this.state.editId;

		this.setState({
			editId: item.id !== prevId ? item.id : -1,
		})
	}

	deleteReportAction = () => {
		this.props.deleteReport(
			{id: this.state.deleteId}
		);
	}

  changePaginationValue = (e) => {
    const newPaginationValue = e.target.value || null;
    if (newPaginationValue) {
      this.setState({
        paginationValue: newPaginationValue
      })
    }
    this.props.getAvailableReports(1, newPaginationValue);
  }

  goToPage = (direction, pageLink) => {
    const { getAvailableReportsByLink } = this.props;
    if (pageLink) {
      getAvailableReportsByLink(pageLink);
    }

    if(direction === "next") {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
    } else {
      this.setState({
        currentPage: this.state.currentPage - 1
      })
    }
  }

  toggleEdit = (e, item) => {
    e.stopPropagation();

    let prevId = this.state.editId;

    this.setState({
		editId: item.id !== prevId ? item.id : -1,
		['report_name_'+item.id.toString()]: item.name,
    })
  }

  confirmDelete = (e, report) => {
    e.stopPropagation();
    this.setState({
      deleteId: report.id,
      deleteName: report.name
    });
  }

  deleteReport = () => {
	  this.deleteReportAction();
    this.setState({ deleteId: -1 });
  }

  render() {
    const { availableReportsStatus, availableReports } = this.props;
    const { paginationValue } = this.state;

    return (
      <Fragment>
        <CardTitle>Recently Uploaded Reports</CardTitle>
        {
          availableReportsStatus ? (
            <LoadingIndicator title="Loading Available Reports..." />
          ) : (
            <Fragment>
              <div className="reports-group mt-4">
                {this.getAvailableReportsList()}
              </div>

              <hr />

              <div className="d-flex justify-content-between">
                <Input
                  className="w-auto"
                  type="select"
                  placeholder="Select Size per Page"
                  value={paginationValue}
                  name="limit"
                  onChange={this.changePaginationValue}
                >
                  {
                    paginationOptions.map(el => <option key={`paginationOptions-${el}`} value={el}>{el}</option>)
                  }
                </Input>
                <div className="report-pagination-nav">
                  <Button
                    color="link"
                    onClick={() => this.goToPage("prev", availableReports.prev)}
                    disabled={this.state.currentPage === 1}
                  >
                    <span className="icon icon-12 icon-chevron-left bg-primary"></span>
                  </Button>
                  <span>{`${this.state.currentPage} / ${availableReports && availableReports.pages ? availableReports.pages : "N/A"}`}</span>
                  <Button
                    color="link"
                    onClick={() => this.goToPage("next", availableReports.next)}
                    disabled={availableReports && (Number(availableReports.pages) === this.state.currentPage)}
                  >
                    <span className="icon icon-12 icon-chevron-right bg-primary"></span>
                  </Button>
                </div>
              </div>
            </Fragment>
          )
        }
        <DeleteDialog
          isOpen={this.state.deleteId > 0}
          close={(e) => {
            e.stopPropagation()
            this.setState({ deleteId: -1 })
          }}
          deleteItem={this.deleteReport}
          dialogTitle="Are you sure want to delete this report?"
          dialogText={`You will loose report "${this.state.deleteName}" data and can't access it later.`}
          confirmText="Yes, delete report"
        />
      </Fragment>
    )
  }
}

AvailableReports.propTypes = {
  availableReports: PropTypes.object,
  getAvailableReports: PropTypes.func,
  getAvailableReportsByLink: PropTypes.func,
  clearUploadedTransactionReportData: PropTypes.func,
};

export default withRouter(connect(
  state => ({
    availableReports: state.Accounting.get("availableReports"),
    availableReportsStatus: state.Accounting.get("availableReportsStatus"),
  }),
  {
    getAvailableReports,
    getAvailableReportsByLink,
	  clearUploadedTransactionReportData,
	  deleteReport,
	  updateReport,
  }
)(AvailableReports));
