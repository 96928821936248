import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody } from 'reactstrap';
import StatCard from './StatCard';
import TabbedCard from './TabbedCard';
import './style.css';
import { digitСonversion } from '../../../../helpers/utility';
import ebayActions from '../../../../redux/ebay/actions';
import appActions from "../../../../redux/app/actions";

const { ebayUserGetSettings } = ebayActions;

const { userPageviewCapture } = appActions;

class EBayDashboard extends Component {
  state = {
    show: false
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "eBay/Dashboard"})
    this.props.ebayUserGetSettings();
  }

  extractData = (key) => {
    const { ebay_user_settings } = this.props;
    if(!ebay_user_settings?.ebay_limits_aggregate){ return null }

    if(key === 'total_amount'){
      return ebay_user_settings.ebay_limits_aggregate?.privileges?.sellingLimit?.amount?.value;
    } else if(key === 'total_qty'){
      return ebay_user_settings.ebay_limits_aggregate?.privileges?.sellingLimit?.quantity;
    } else {
      return ebay_user_settings.ebay_limits_aggregate[key];
    }
  }

  checkValues = () => {
    if(this.extractData("total_amount") === undefined
      || this.extractData("total_qty") === undefined
      || this.extractData("items_amount") === undefined
      || this.extractData("items_count") === undefined
    ) { return false; }
    return true;
  }

  calcPercentage = (total, perc) => {
    if(total <= 0) { return 0 }
    if(parseInt(perc/total * 100, 10) > 100) { return 100; }
    return parseInt(perc/total * 100, 10)
  }

  render() {
    const { ebay_user_settings, internationalization_config } = this.props;

    return (
      <div className="view">
        <header className="view-header">
          {this.checkValues() ?
            <React.Fragment>
              <div>
                <h1 className="page-title">eBay Dashboard</h1>
                <p>You can sell up to {digitСonversion(this.extractData("total_qty"))} items or up to {digitСonversion(this.extractData("total_amount"), "currency", internationalization_config.currency_code)} per month, whichever comes first.</p>
              </div>
              <div className="info">
                QNTY still to list: {digitСonversion(this.extractData("total_qty") - this.extractData("items_count"))} &nbsp;&nbsp;&nbsp; AMOUNT allowed to sell: {digitСonversion(this.extractData("total_amount") - this.extractData("items_amount"), "currency", internationalization_config.currency_code)}
              </div>
            </React.Fragment>
            :
            <h1 className="page-title">eBay Dashboard</h1>
          }
        </header>
        <div className="view-content ebay-app">
          <StatCard
            internationalization_config={internationalization_config}
            ebay_user_settings={ebay_user_settings}
          />
          <TabbedCard />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
    internationalization_config: state.Auth.get("internationalization_config"),
		ebay_user_settings: state.Ebay.get("ebay_user_settings"),
  }),
  {
	  ebayUserGetSettings,
    userPageviewCapture,
  }
)(EBayDashboard);
