const exportActions = {
	EXPORT_LABEL_PDF: "EXPORT_LABEL_PDF",
	EXPORT_LABEL_PDF_SUCCESS: "EXPORT_LABEL_PDF_SUCCESS",
	EXPORT_LABEL_PDF_FAILED: "EXPORT_LABEL_PDF_FAILED",

	EXPORT_LABEL_30UP: "EXPORT_LABEL_30UP",
	EXPORT_LABEL_30UP_SUCCESS: "EXPORT_LABEL_30UP_SUCCESS",
	EXPORT_LABEL_30UP_FAILED: "EXPORT_LABEL_30UP_FAILED",

	EXPORT_EXPENSES: 'EXPORT_EXPENSES',
	EXPORT_EXPENSES_SUCCESS: 'EXPORT_EXPENSES_SUCCESS',
	EXPORT_EXPENSES_FAILED: 'EXPORT_EXPENSES_FAILED',

	EXPORT_PL: 'EXPORT_PL',
	EXPORT_PL_SUCCESS: 'EXPORT_PL_SUCCESS',
	EXPORT_PL_FAILED: 'EXPORT_PL_FAILED',

	exportPL: (data) => ({
		type: exportActions.EXPORT_PL,
		data,
	}),
	exportPLSuccess: (data) => ({
		type: exportActions.EXPORT_PL_SUCCESS,
		data,
	}),
	exportPLFailed: (data) => ({
		type: exportActions.EXPORT_PL_FAILED,
		data,
	}),

	exportExpenses: (data) => ({
		type: exportActions.EXPORT_EXPENSES,
		data,
	}),
	exportExpensesSuccess: (data) => ({
		type: exportActions.EXPORT_EXPENSES_SUCCESS,
		data,
	}),
	exportExpensesFailed: (data) => ({
		type: exportActions.EXPORT_EXPENSES_FAILED,
		data,
	}),

	exportLabel30up: (data) => ({
		type: exportActions.EXPORT_LABEL_30UP,
		data
	}),
	exportLabel30upSuccess: (data) => ({
		type: exportActions.EXPORT_LABEL_30UP_SUCCESS,
		data
	}),
	exportLabel30upFailed: (data) => ({
		type: exportActions.EXPORT_LABEL_30UP_FAILED,
		data
	}),
	exportLabelPdf: (data) => ({
		type: exportActions.EXPORT_LABEL_PDF,
		data
	}),
	exportLabelPdfSuccess: (data) => ({
		type: exportActions.EXPORT_LABEL_PDF_SUCCESS,
		data
	}),
	exportLabelPdfFailed: (data) => ({
		type: exportActions.EXPORT_LABEL_PDF_FAILED,
		data
	}),


};

export default exportActions;
