export const templateValidationMessages = {
    1: "Please enter template name",
    2: "Please enter a salesrank minimum",
    3: "Please enter salesrank maximum",
    4: "Please enter a minimum inventory age",
    5: "Please enter a maximum inventory age",
    6: "Please enter a minimum product price",
    7: "Please enter a maximum product price",
    8: "Please enter inventory channel target",
    9: "Salesrank min needs to be lower than salesrank max",
    10: "Inventory age min needs to be lower than inventory age max",
    11: "Product price min needs to be lower than product price max",
    12: "Please enter minimum offers number",
    13: "Please enter exclude sellers with feedback count below",
    14: "Please enter exclude sellers with positive seller rating below",
    15: "Please enter exclude sellers with positive seller rating below need to be in 1-100 range",
    16: "Please select a fulfillment channel",
    17: "Please select a pricing strategy when your listing is not in buy box",
    18: "Please set an amount for pricing strategy when your listing is not in buy box",
    19: "Please select a pricing strategy when listing is in buy box",
    20: "Please set an amount for pricing strategy when your listing is in buy box",
    21: "Please select a pricing strategy when the competing offer is Amazon",
    22: "Please select a pricing strategy when no competing offers exist for this listing",
    23: "Please select a pricing strategy when some competing offers are found, but not within filter criteria",
    24: "Please select a pricing strategy when competing within your criteria found, but the new suggested price was rejected",
    25: "Please enter min price",
    26: "Please enter max price",
    27: "Please enter a value for price drop percentage",
    28: "Please enter value for price increase percentage",
    29: "Please enter a value for your ROI amount",
    30: "Please select min price when no competing offers exist for this listing",
    31: "Please select min price when some competing offers are found, but not within filter criteria",
    32: "Please select min price when competing within your criteria found, but the new suggested price was rejected",
    33: "Min price needs to be lower than max price",
    34: "Please set reprice against value",
    35: "Please set target channel value",
    36: "Please set reprice amount when your inventory already in Buy Box",
    37: "Please set reprice amount when your inventory is not already in Buy Box",
    38: "Please set reprice against value",
    39: "Please set target channel value",
    40: "Please set reprice amount when your inventory already in Buy Box",
    41: "Please select a pricing strategy when listing is in buy box",
    42: "Value need to be zero when match selected",
    43: "Please set reprice amount when your inventory not in Buy Box",
    44: "Please select a pricing strategy when listing is not in buy box",
    45: "Value need to be zero when match selected",
    46: "Please select prime target",
}

