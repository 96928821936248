const fulfillmentActions = {

  INBOUND_PLAN_CREATE: "INBOUND_PLAN_CREATE",
  INBOUND_PLAN_CREATE_SUCCESS: "INBOUND_PLAN_CREATE_SUCCESS",
  INBOUND_PLAN_CREATE_FAILED: "INBOUND_PLAN_CREATE_FAILED",
  GET_INBOUND_OPERATION_STATUS: "GET_INBOUND_OPERATION_STATUS",
  GET_INBOUND_OPERATION_STATUS_SUCCESS: "GET_INBOUND_OPERATION_STATUS_SUCCESS",
  GET_INBOUND_OPERATION_STATUS_FAILED: "GET_INBOUND_OPERATION_STATUS_FAILED",
  GENERATE_PACKING_OPTIONS: "GENERATE_PACKING_OPTIONS",
  GENERATE_PACKING_OPTIONS_SUCCESS: "GENERATE_PACKING_OPTIONS_SUCCESS",
  GENERATE_PACKING_OPTIONS_FAILED: "GENERATE_PACKING_OPTIONS_FAILED",
  CONFIRM_PACKING_OPTION: "CONFIRM_PACKING_OPTION",
  CONFIRM_PACKING_OPTION_SUCCESS: "CONFIRM_PACKING_OPTION_SUCCESS",
  CONFIRM_PACKING_OPTION_FAILED: "CONFIRM_PACKING_OPTION_FAILED",
  INBOUND_PLAN_CANCEL: "INBOUND_PLAN_CANCEL",
  INBOUND_PLAN_CANCEL_SUCCESS: "INBOUND_PLAN_CANCEL_SUCCESS",
  INBOUND_PLAN_CANCEL_FAILED: "INBOUND_PLAN_CANCEL_FAILED",
  BOX_CREATE: "BOX_CREATE",
  BOX_CREATE_SUCCESS: "BOX_CREATE_SUCCESS",
  BOX_CREATE_FAILED: "BOX_CREATE_FAILED",
  BOXES_LOAD_ALL: "BOXES_LOAD_ALL",
  BOXES_LOAD_ALL_SUCCESS: "BOXES_LOAD_ALL_SUCCESS",
  BOXES_LOAD_ALL_FAILED: "BOXES_LOAD_ALL_FAILED",
  BOX_ADD_ITEM: "BOX_ADD_ITEM",
  BOX_ADD_ITEM_SUCCESS: "BOX_ADD_ITEM_SUCCESS",
  BOX_ADD_ITEM_FAILED: "BOX_ADD_ITEM_FAILED",
  BOX_PACKING_SET: "BOX_PACKING_SET",
  BOX_PACKING_SET_SUCCESS: "BOX_PACKING_SET_SUCCESS",
  BOX_PACKING_SET_FAILED: "BOX_PACKING_SET_FAILED",
  GENERATE_PLACEMENT_OPTIONS: "GENERATE_PLACEMENT_OPTIONS",
  GENERATE_PLACEMENT_OPTIONS_SUCCESS: "GENERATE_PLACEMENT_OPTIONS_SUCCESS",
  GENERATE_PLACEMENT_OPTIONS_FAILED: "GENERATE_PLACEMENT_OPTIONS_FAILED",
  GENERATE_TRANSPORTAION_OPTIONS: "GENERATE_TRANSPORTAION_OPTIONS",
  GENERATE_TRANSPORTAION_OPTIONS_SUCCESS: "GENERATE_TRANSPORTAION_OPTIONS_SUCCESS",
  GENERATE_TRANSPORTAION_OPTIONS_FAILED: "GENERATE_TRANSPORTAION_OPTIONS_FAILED",
  GENERATE_TRANSPORTAION_OPTIONS_GET: "GENERATE_TRANSPORTAION_OPTIONS_GET",
  GENERATE_TRANSPORTAION_OPTIONS_GET_SUCCESS: "GENERATE_TRANSPORTAION_OPTIONS_GET_SUCCESS",
  GENERATE_TRANSPORTAION_OPTIONS_GET_FAILED: "GENERATE_TRANSPORTAION_OPTIONS_GET_FAILED",
  CONFIRM_PLACEMENT_OPTION: "CONFIRM_PLACEMENT_OPTION",
  CONFIRM_PLACEMENT_OPTION_SUCCESS: "CONFIRM_PLACEMENT_OPTION_SUCCESS",
  CONFIRM_PLACEMENT_OPTION_FAILED: "CONFIRM_PLACEMENT_OPTION_FAILED",
  CONFIRM_DELIVERY_OPTIONS: "CONFIRM_DELIVERY_OPTIONS",
  CONFIRM_DELIVERY_OPTIONS_SUCCESS: "CONFIRM_DELIVERY_OPTIONS_SUCCESS",
  CONFIRM_DELIVERY_OPTIONS_FAILED: "CONFIRM_DELIVERY_OPTIONS_FAILED",
  CONFIRM_TRANSPORTATION_OPTIONS: "CONFIRM_TRANSPORTATION_OPTIONS",
  CONFIRM_TRANSPORTATION_OPTIONS_SUCCESS: "CONFIRM_TRANSPORTATION_OPTIONS_SUCCESS",
  CONFIRM_TRANSPORTATION_OPTIONS_FAILED: "CONFIRM_TRANSPORTATION_OPTIONS_FAILED",
  BOX_DELETE: "BOX_DELETE",
  BOX_DELETE_SUCCESS: "BOX_DELETE_SUCCESS",
  BOX_DELETE_FAILED: "BOX_DELETE_FAILED",
  SHIPMENTS_LIST_GET: "SHIPMENTS_LIST_GET",
  SHIPMENTS_LIST_GET_SUCCESS: "SHIPMENTS_LIST_GET_SUCCESS",
  SHIPMENTS_LIST_GET_FAILED: "SHIPMENTS_LIST_GET_FAILED",
  SHIPMENT_LABELS_CREATE: "SHIPMENT_LABELS_CREATE",
  SHIPMENT_LABELS_CREATE_SUCCESS: "SHIPMENT_LABELS_CREATE_SUCCESS",
  SHIPMENT_LABELS_CREATE_FAILED: "SHIPMENT_LABELS_CREATE_FAILED",
  BOX_DELETE_ITEM: "BOX_DELETE_ITEM",
  BOX_DELETE_ITEM_SUCCESS: "BOX_DELETE_ITEM_SUCCESS",
  BOX_DELETE_ITEM_FAILED: "BOX_DELETE_ITEM_FAILED",
  SHIPMENTS_TRACKING_UPDATE: "SHIPMENTS_TRACKING_UPDATE",
  SHIPMENTS_TRACKING_UPDATE_SUCCESS: "SHIPMENTS_TRACKING_UPDATE_SUCCESS",
  SHIPMENTS_TRACKING_UPDATE_FAILED: "SHIPMENTS_TRACKING_UPDATE_FAILED",
  SHIPMENTS_TRACKING_GET: "SHIPMENTS_TRACKING_GET",
  SHIPMENTS_TRACKING_GET_SUCCESS: "SHIPMENTS_TRACKING_GET_SUCCESS",
  SHIPMENTS_TRACKING_GET_FAILED: "SHIPMENTS_TRACKING_GET_FAILED",
  BOX_UPDATE: "BOX_UPDATE",
  BOX_UPDATE_SUCCESS: "BOX_UPDATE_SUCCESS",
  BOX_UPDATE_FAILED: "BOX_UPDATE_FAILED",
  BOX_CLEAR_BOX_ITEM_ADDED: "BOX_CLEAR_BOX_ITEM_ADDED",
  GET_ITEMS_AVAILABLE_FOR_INBOUND: "GET_ITEMS_AVAILABLE_FOR_INBOUND",
  GET_ITEMS_AVAILABLE_FOR_INBOUND_SUCCESS: "GET_ITEMS_AVAILABLE_FOR_INBOUND_SUCCESS",
  GET_ITEMS_AVAILABLE_FOR_INBOUND_FAILED: "GET_ITEMS_AVAILABLE_FOR_INBOUND_FAILED",
  BOX_ADD_ITEM_ALL: "BOX_ADD_ITEM_ALL",
  BOX_ADD_ITEM_ALL_SUCCESS: "BOX_ADD_ITEM_ALL_SUCCESS",
  BOX_ADD_ITEM_ALL_FAILED: "BOX_ADD_ITEM_ALL_FAILED",
  BOX_CLEAR_BOX_ITEM_ADDED_ALL: "BOX_CLEAR_BOX_ITEM_ADDED_ALL",

  fulfillmentClearBoxItemAddedAll: (data) => ({
    type: fulfillmentActions.BOX_CLEAR_BOX_ITEM_ADDED_ALL,
    data
  }),

  fulfillmentBoxAddItemAll: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM_ALL,
    data
  }),
  fulfillmentBoxAddItemAllSuccess: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM_ALL_SUCCESS,
    data
  }),
  fulfillmentBoxAddItemAllFailed: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM_ALL_FAILED,
    data
  }),

  fulfillmentGetItemsAvailableForInbound: (data) => ({
    type: fulfillmentActions.GET_ITEMS_AVAILABLE_FOR_INBOUND,
    data
  }),
  fulfillmentGetItemsAvailableForInboundSuccess: (data) => ({
    type: fulfillmentActions.GET_ITEMS_AVAILABLE_FOR_INBOUND_SUCCESS,
    data
  }),
  fulfillmentGetItemsAvailableForInboundFailed: (data) => ({
    type: fulfillmentActions.GET_ITEMS_AVAILABLE_FOR_INBOUND_FAILED,
    data
  }),

  fulfillmentClearBoxItemAdded: (data) => ({
    type: fulfillmentActions.BOX_CLEAR_BOX_ITEM_ADDED,
    data
  }),

  fulfillmentBoxUpdate: (data) => ({
    type: fulfillmentActions.BOX_UPDATE,
    data
  }),
  fulfillmentBoxUpdateSuccess: (data) => ({
    type: fulfillmentActions.BOX_UPDATE_SUCCESS,
    data
  }),
  fulfillmentBoxUpdateFailed: (data) => ({
    type: fulfillmentActions.BOX_UPDATE_FAILED,
    data
  }),

  fulfillmentShipmentsTrackingGet: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_GET,
    data
  }),
  fulfillmentShipmentsTrackingGetSuccess: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_GET_SUCCESS,
    data
  }),
  fulfillmentShipmentsTrackingGetFailed: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_GET_FAILED,
    data
  }),

  fulfillmentShipmentsTrackingUpdate: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_UPDATE,
    data
  }),
  fulfillmentShipmentsTrackingUpdateSuccess: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_UPDATE_SUCCESS,
    data
  }),
  fulfillmentShipmentsTrackingUpdateFailed: (data) => ({
    type: fulfillmentActions.SHIPMENTS_TRACKING_UPDATE_FAILED,
    data
  }),

  fulfillmentBoxDeleteItem: (data) => ({
    type: fulfillmentActions.BOX_DELETE_ITEM,
    data
  }),
  fulfillmentBoxDeleteItemSuccess: (data) => ({
    type: fulfillmentActions.BOX_DELETE_ITEM_SUCCESS,
    data
  }),
  fulfillmentBoxDeleteItemFailed: (data) => ({
    type: fulfillmentActions.BOX_DELETE_ITEM_FAILED,
    data
  }),

  fulfillmentGetLabels: (data) => ({
    type: fulfillmentActions.SHIPMENT_LABELS_CREATE,
    data
  }),
  fulfillmentGetLabelsSuccess: (data) => ({
    type: fulfillmentActions.SHIPMENT_LABELS_CREATE_SUCCESS,
    data
  }),
  fulfillmentGetLabelsFailed: (data) => ({
    type: fulfillmentActions.SHIPMENT_LABELS_CREATE_FAILED,
    data
  }),

  fulfillmentShipmentsListGet: (data) => ({
    type: fulfillmentActions.SHIPMENTS_LIST_GET,
    data
  }),
  fulfillmentShipmentsListGetSuccess: (data) => ({
    type: fulfillmentActions.SHIPMENTS_LIST_GET_SUCCESS,
    data
  }),
  fulfillmentShipmentsListGetFailed: (data) => ({
    type: fulfillmentActions.SHIPMENTS_LIST_GET_FAILED,
    data
  }),

  fulfillmentBoxDelete: (data) => ({
    type: fulfillmentActions.BOX_DELETE,
    data
  }),
  fulfillmentBoxDeleteSuccess: (data) => ({
    type: fulfillmentActions.BOX_DELETE_SUCCESS,
    data
  }),
  fulfillmentBoxDeleteFailed: (data) => ({
    type: fulfillmentActions.BOX_DELETE_FAILED,
    data
  }),

  fulfillmentConfirmTransportationOptions: (data) => ({
    type: fulfillmentActions.CONFIRM_TRANSPORTATION_OPTIONS,
    data
  }),
  fulfillmentConfirmTransportationOptionsSuccess: (data) => ({
    type: fulfillmentActions.CONFIRM_TRANSPORTATION_OPTIONS_SUCCESS,
    data
  }),
  fulfillmentConfirmTransportationOptionsFailed: (data) => ({
    type: fulfillmentActions.CONFIRM_TRANSPORTATION_OPTIONS_FAILED,
    data
  }),

  fulfillmentConfirmDeliveryOptions: (data) => ({
    type: fulfillmentActions.CONFIRM_DELIVERY_OPTIONS,
    data
  }),
  fulfillmentConfirmDeliveryOptionsSuccess: (data) => ({
    type: fulfillmentActions.CONFIRM_DELIVERY_OPTIONS_SUCCESS,
    data
  }),
  fulfillmentConfirmDeliveryOptionsFailed: (data) => ({
    type: fulfillmentActions.CONFIRM_DELIVERY_OPTIONS_FAILED,
    data
  }),

  fulfillmentConfirmPlacementOption: (data) => ({
    type: fulfillmentActions.CONFIRM_PLACEMENT_OPTION,
    data
  }),
  fulfillmentConfirmPlacementOptionSuccess: (data) => ({
    type: fulfillmentActions.CONFIRM_PLACEMENT_OPTION_SUCCESS,
    data
  }),
  fulfillmentConfirmPlacementOptionFailed: (data) => ({
    type: fulfillmentActions.CONFIRM_PLACEMENT_OPTION_FAILED,
    data
  }),

  fulfillmentGenerateTransportationOptions: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS,
    data
  }),
  fulfillmentGenerateTransportationOptionsSuccess: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS_SUCCESS,
    data
  }),
  fulfillmentGenerateTransportationOptionsFailed: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS_FAILED,
    data
  }),

  fulfillmentGenerateTransportationOptionsGet: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS_GET,
    data
  }),
  fulfillmentGenerateTransportationOptionsGetSuccess: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS_GET_SUCCESS,
    data
  }),
  fulfillmentGenerateTransportationOptionsGetFailed: (data) => ({
    type: fulfillmentActions.GENERATE_TRANSPORTAION_OPTIONS_GET_FAILED,
    data
  }),

  fulfillmentGeneratePlacementOptions: (data) => ({
    type: fulfillmentActions.GENERATE_PLACEMENT_OPTIONS,
    data
  }),
  fulfillmentGeneratePlacementOptionsSuccess: (data) => ({
    type: fulfillmentActions.GENERATE_PLACEMENT_OPTIONS_SUCCESS,
    data
  }),
  fulfillmentGeneratePlacementOptionsFailed: (data) => ({
    type: fulfillmentActions.GENERATE_PLACEMENT_OPTIONS_FAILED,
    data
  }),

  fulfillmentBoxPackingSet: (data) => ({
    type: fulfillmentActions.BOX_PACKING_SET,
    data
  }),
  fulfillmentBoxPackingSetSuccess: (data) => ({
    type: fulfillmentActions.BOX_PACKING_SET_SUCCESS,
    data
  }),
  fulfillmentBoxPackingSetFailed: (data) => ({
    type: fulfillmentActions.BOX_PACKING_SET_FAILED,
    data
  }),

  fulfillmentBoxAddItem: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM,
    data
  }),
  fulfillmentBoxAddItemSuccess: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM_SUCCESS,
    data
  }),
  fulfillmentBoxAddItemFailed: (data) => ({
    type: fulfillmentActions.BOX_ADD_ITEM_FAILED,
    data
  }),

  fulfillmentBoxesLoadAll: (data) => ({
    type: fulfillmentActions.BOXES_LOAD_ALL,
    data
  }),
  fulfillmentBoxesLoadAllSuccess: (data) => ({
    type: fulfillmentActions.BOXES_LOAD_ALL_SUCCESS,
    data
  }),
  fulfillmentBoxesLoadAllFailed: (data) => ({
    type: fulfillmentActions.BOXES_LOAD_ALL_FAILED,
    data
  }),

  fulfillmentBoxCreate: (data) => ({
    type: fulfillmentActions.BOX_CREATE,
    data
  }),
  fulfillmentBoxCreateSuccess: (data) => ({
    type: fulfillmentActions.BOX_CREATE_SUCCESS,
    data
  }),
  fulfillmentBoxCreateFailed: (data) => ({
    type: fulfillmentActions.BOX_CREATE_FAILED,
    data
  }),

  fulfillmentInoundPlanCancel: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CANCEL,
    data
  }),
  fulfillmentInoundPlanCancelSuccess: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CANCEL_SUCCESS,
    data
  }),
  fulfillmentInoundPlanCancelFailed: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CANCEL_FAILED,
    data
  }),

  confirmPackingOption: (data) => ({
    type: fulfillmentActions.CONFIRM_PACKING_OPTION,
    data
  }),
  confirmPackingOptionSuccess: (data) => ({
    type: fulfillmentActions.CONFIRM_PACKING_OPTION_SUCCESS,
    data
  }),
  confirmPackingOptionFailed: (data) => ({
    type: fulfillmentActions.CONFIRM_PACKING_OPTION_FAILED,
    data
  }),

  generatePackingOptions: (data) => ({
    type: fulfillmentActions.GENERATE_PACKING_OPTIONS,
    data
  }),
  generatePackingOptionsSuccess: (data) => ({
    type: fulfillmentActions.GENERATE_PACKING_OPTIONS_SUCCESS,
    data
  }),
  generatePackingOptionsFailed: (data) => ({
    type: fulfillmentActions.GENERATE_PACKING_OPTIONS_FAILED,
    data
  }),

  getFulfillmentInboundOperationStatus: (data) => ({
    type: fulfillmentActions.GET_INBOUND_OPERATION_STATUS,
    data
  }),
  getFulfillmentInboundOperationStatusSuccess: (data) => ({
    type: fulfillmentActions.GET_INBOUND_OPERATION_STATUS_SUCCESS,
    data
  }),
  getFulfillmentInboundOperationStatusFailed: (data) => ({
    type: fulfillmentActions.GET_INBOUND_OPERATION_STATUS_FAILED,
    data
  }),

  fulfillmentInoundPlanCreate: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CREATE,
    data
  }),
  fulfillmentInoundPlanCreateSuccess: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CREATE_SUCCESS,
    data
  }),
  fulfillmentInoundPlanCreateFailed: (data) => ({
    type: fulfillmentActions.INBOUND_PLAN_CREATE_FAILED,
    data
  }),

}
export default fulfillmentActions;

