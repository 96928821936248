import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewCustomSKUSelector from '../../../shared/components/NewCustomSKUSelector';
import './index.css';
import Select from 'react-select';
import ebayActions from '../../../redux/ebay/actions';
import { EbayCustomSKUSelectorInitialTags } from "../../../helpers/settings/custom_sku_selector_initial_tags";

const {
	ebayUserGetSettings,
	ebayUserUpdateSettings
} = ebayActions;

const dropdownSelect = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

class EbaySKUSettings extends Component {
  constructor () {
    super();
    this.state = {
    }
  }

  UNSAFE_componentDidMount(){
    this.props.ebayUserGetSettings();
  }

  ebayUserUpdateSettings = (e) => {
    if(e){
      const data = {
        use_custom_sku: e.value,
      }
      this.props.ebayUserUpdateSettings(data);
    }
  }

  saveSKU = (sku) => {
    const data = { custom_sku: sku }
    this.props.ebayUserUpdateSettings(data);
  }

  render() {
    const {
      ebay_user_settings,
      ebay_user_settings_updating,
    } = this.props;

		return (
      <div id="SKU_Settings">
        <div className="d-flex align-items-center">
          <strong>Would you like to use a default custom SKU when listing?</strong>
          <div style={{ width: '100px', marginLeft: '20px'}}>
            <Select
              options={dropdownSelect}
              value={ebay_user_settings && ebay_user_settings.use_custom_sku
                  ? dropdownSelect.find(item => item.value === true)
                  : dropdownSelect.find(item => item.value === false)}
              onChange={e => this.ebayUserUpdateSettings(e)}
              disabled={ebay_user_settings_updating}
              clearable={false}
            />
          </div>
        </div>

        <NewCustomSKUSelector
          isOpen={ebay_user_settings && ebay_user_settings.use_custom_sku}
          customSKUSelectorInitialTags={EbayCustomSKUSelectorInitialTags}
          saveSKUInSettings={this.saveSKU}
          sku={ebay_user_settings && ebay_user_settings.custom_sku ? ebay_user_settings.custom_sku : ""}
          showAdditionalSettings={false}
        />
      </div>
		);
	}
}

export default connect(state => {
  return {
		ebay_user_settings: state.Ebay.get("ebay_user_settings"),
		ebay_user_settings_updating: state.Ebay.get("ebay_user_settings_updating"),
  };
}, {
	ebayUserGetSettings,
	ebayUserUpdateSettings,
})(EbaySKUSettings);
