import React, { Component } from "react";
import { Card, CardBody, Col, Row, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";

const { userFBCCompare } = adminActions;

/**
 * Get DBC AL diff
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminFBCUserEmails extends Component {

	getData = () => {
		this.props.userFBCCompare();
	}

  render() {

    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>Get FBC-AL diff</CardTitle>
				<Row>
					<Col>
							<Button
								color="success"
								onClick={this.getData}
								disabled={this.props.is_getting_diff}
							>
							 GET DIFF EMAILS
							</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						{this.props.fbc_al_diff
								? this.props.fbc_al_diff.map((item, idx) => {
									return (
										<React.Fragment key={`fbc_al_diff_${idx}`}>
											{item}<br />
										</React.Fragment>
									)
								}) : null
						}
					</Col>
				</Row>
			</CardBody>
		</Card>
	</div>
    );
  }
}

export default connect(
  state => {
		return {
			is_getting_diff: state.Admin.get("is_getting_diff"),
			fbc_al_diff: state.Admin.get("fbc_al_diff"),
    };
  },
	{
		userFBCCompare,
  }
)(AdminFBCUserEmails);
