import React, { Component } from 'react';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button,
	FormGroup, Label, Row, Col, ButtonGroup, Input, FormText
} from 'reactstrap';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import './style.css';
import AlertPill from "../../../../../shared/components/AlertPill";
import repricerActions from '../../../../../redux/repricer/actions';

const {
	updateScheduleWarningText,
} = repricerActions;

const days = [ 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT' ];
const bootstrapOptions = [
	{ value: 'true', label: "Reprice using a subset of data and limited repricing options"},
	{ value: 'false', label: "Don’t reprice that SKU and wait until the full notification is available"},
];
const scheduleTypes = [
	{ value: "hourly", label: "Hourly"},
	{ value: "daily", label: "Daily"},
];
const liveOptions = [
	{ value: 'false', label: "Test reprice strategy first before turning live"},
	{ value: 'true', label: "Turn reprice schedule live"},
];

class TemplateScheduleModal2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: {
        template: null,
        days: [],
        time: null
      },
      dayListSentence: ''
    }
  }

	onSelectChange = (val, name) => {
		this.props.workingScheduleUpdateProp(
			{
				prop: name,
				data: val,
			}
		);
	};

	updateTime = (e) => {
		this.props.workingScheduleUpdateProp(
			{
				prop: 'scheduleTime',
				data: e.target.value,
			}
		);
	}

	saveSchedule = () => {
		const {
			selectedTemplate,
			excludedTemplates,
			bootstrapOption,
			scheduleType,
			liveOption,
			scheduleTime,
			daysToExecute,
		} = this.props.workingSchedule;

		var excluded_templates = [];
		excludedTemplates.forEach(template => {
			excluded_templates.push(template.value);
		});

		var days_to_execute = [];
		daysToExecute.forEach(day => {
			days_to_execute.push(day);
		});

		this.props.userSaveWorkingSchedule({
			scheduler_json: {
				timestr: moment(scheduleTime).utc().format("H:mm"),
				interval: scheduleType.value === 'hourly' ? 60 : 1440,
				templateId: selectedTemplate.value,
				excludeTemplates: excluded_templates,
				isActive: liveOption.value === 'true',
				shouldUseBootstrap: bootstrapOption.value === 'true',
				daysToExecute: days_to_execute,
			}
		});
		this.props.toggleEditor();
	}

	updateSchedule = () => {
		const {
			selectedTemplate,
			excludedTemplates,
			bootstrapOption,
			scheduleType,
			liveOption,
			scheduleTime,
			id,
			daysToExecute,
		} = this.props.workingSchedule;

		var excluded_templates = [];
		excludedTemplates.forEach(template => {
			excluded_templates.push(template.value);
		});

		var days_to_execute = [];
		daysToExecute.forEach(day => {
			days_to_execute.push(day);
		});

		this.props.userUpdateWorkingSchedule({
			scheduler_json: {
				timestr: moment(scheduleTime).format("H:mm"),
				interval: scheduleType.value === 'hourly' ? 60 : 1440,
				templateId: selectedTemplate.value,
				excludeTemplates: excluded_templates,
				isActive: liveOption.value === 'true',
				shouldUseBootstrap: bootstrapOption.value === 'true',
				id: id,
				daysToExecute: days_to_execute,
			}
		});
		this.props.toggleEditor();
	}

  onSelectDay = (val) => {
    let schedule = this.props.workingSchedule;

    if(schedule.daysToExecute.includes(val)) {
      schedule.daysToExecute = schedule.daysToExecute.filter(item => item !== val);
    } else {
      schedule.daysToExecute.push(val);
    }

	this.props.workingScheduleUpdateProp(
		{
			prop: 'daysToExecute',
			data: schedule.daysToExecute,
		}
	);

    this.formatDayList(schedule.daysToExecute);
  }

  formatDayList = (days) => {
    let sentence = '';
    let longDayName = '';
    days.forEach((item, idx) => {

      switch(item) {
        case 'MON':
          longDayName = 'Monday';
          break;
        case 'TUE':
          longDayName = 'Tuesday';
          break;
        case 'WED':
          longDayName = 'Wednesday';
          break;
        case 'THU':
          longDayName = 'Thursday';
          break;
        case 'FRI':
          longDayName = 'Friday';
          break;
        case 'SAT':
          longDayName = 'Saturday';
          break;
        case 'SUN':
          longDayName = 'Sunday';
          break;
        default:
      }

      if( idx === 0) {
        sentence += longDayName;
      } else {
        sentence += `, ${longDayName}`;
      }
    });

    this.setState({
      dayListSentence: sentence.replace(/,([^,]*)$/,' and$1')
    })
  }

	testScheduleOK = () => {
		const {
			selectedTemplate,
			bootstrapOption,
			scheduleType,
			liveOption,
			scheduleTime,
		} = this.props.workingSchedule;

    //ignore and default bootstrap to false
    if(!bootstrapOption){
		  this.props.workingScheduleUpdateProp(
			  {
				  prop: "bootstrapOption",
  				data: { value: "false" },
	  		}
		  );
    }

		if(selectedTemplate
			&& bootstrapOption
			&& scheduleType
			&& liveOption
			&& scheduleTime){
				if(selectedTemplate.value
					&& bootstrapOption.value
					&& scheduleType.value
					&& liveOption.value
					&& scheduleTime._d){
					return true;
				}
		}
		return false;
	}

  render() {
    const {
      schedule,
      dayListSentence
    } = this.state;

    return (
      <Modal
        isOpen={this.props.editorOpen}
        className="modal-template-scheduler"
        centered
      >
        <ModalHeader toggle={this.props.toggleEditor} />
        <ModalBody>
            {this.props.schedulerWarningText ?
              <AlertPill
                icon="warning"
                color="danger"
                outline
                size="sm"
                isOpen={this.props.schedulerWarningText}
                onDismiss={null}
              >
                {this.props.schedulerWarningText}
              </AlertPill>
              : null
            }
          <h3 className="title">Let's create a schedule for your templates</h3>
          <Row>
            <Col>
              <FormGroup>
                <Label>Template to schedule</Label>
                <Select
                  options={ this.props.userTemplates }
                  value={this.props.workingSchedule.selectedTemplate}
                  onChange={e => this.onSelectChange(e, "selectedTemplate")}
                />
              </FormGroup>

				{this.props.workingSchedule.selectedTemplate && this.props.workingSchedule.selectedTemplate.value === "all_templates" ?
				  <FormGroup>
					<Label>Exclude templates</Label>
					<FormGroup>
						<Select
							options={ this.props.userTemplates.slice(1) }
							value={this.props.workingSchedule.excludedTemplates}
							onChange={e => this.onSelectChange(e, "excludedTemplates")}
							multi={true}
						/>
					</FormGroup>
				  </FormGroup>
				: null }

              <FormGroup>
                <Label>Select days</Label>
                <ButtonGroup className="d-block">
                  { days.map((item, idx) => (
                    <Button
											key={idx}
											color="secondary"
											active={this.props.workingSchedule.daysToExecute.includes(item)}
										  onClick={() => this.onSelectDay(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </ButtonGroup>
								<FormText>By default, your template will run 7 days a week.</FormText>
              </FormGroup>

				  <FormGroup>
					<Label>Schedule type</Label>
					<FormGroup>
						<Select
							options={ scheduleTypes }
							value={this.props.workingSchedule.scheduleType}
							onChange={e => this.onSelectChange(e, "scheduleType")}
						/>
					</FormGroup>
				  </FormGroup>
			  </Col>

				<Col>
          <FormGroup>
            <Label>Time</Label>
            <TimePicker
              showSecond={false}
              format={'h:mm a'}
              defaultValue={this.props.workingSchedule.scheduleTime ? this.props.workingSchedule.scheduleTime : null}
              onChange={e => this.onSelectChange(e, "scheduleTime")}
              use12Hours
              minuteStep={15}
              placement={"bottomLeft"}
              className="form-control"
              clearText
              inputReadOnly
              disabledMinutes={this.props.getDisabledMinutes}
            />
						<FormText>We'll run your template at this time, based on schedule type chosen</FormText>
          </FormGroup>

          <FormGroup>
            <Label>Delay time start (in days)</Label>
            <Input type="number" />
          </FormGroup>
          {/*
				  <FormGroup>
					<Label>When data for a SKU is only partially available?</Label>
					<FormGroup>
						<Select
							options={ bootstrapOptions }
							value={this.props.workingSchedule.bootstrapOption}
							onChange={e => this.onSelectChange(e, "bootstrapOption")}
						/>
					</FormGroup>
          </FormGroup>
          */}

				  <FormGroup>
					<Label>Turn Live</Label>
					<FormGroup>
						<Select
							options={ liveOptions }
							value={this.props.workingSchedule.liveOption}
							onChange={e => this.onSelectChange(e, "liveOption")}
						/>
					</FormGroup>
				  </FormGroup>
            </Col>
          </Row>

          {schedule.template && schedule.days.length > 0 && schedule.time &&
            <p className="mb-0">
              OK, based on what you've entered above, we'll run <strong className="text-primary">{schedule.template.label}</strong> every
              hour starting at <strong className="text-primary">{moment(schedule.time).format("h:mm a")}</strong> each day
              on <strong className="text-primary">{dayListSentence}</strong>
            </p>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.props.toggleEditor}>Close</Button>
          <Button
            color="success"
			disabled={!this.testScheduleOK()}
			onClick={!this.props.workingSchedule.editing ? this.saveSchedule: this.updateSchedule}
          >
			{this.props.workingSchedule.editing ?
				"Update Schedule"
				: "Apply schedule to template"
			}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TemplateScheduleModal2;
