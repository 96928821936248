import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Button, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { digitСonversion, momentDateTimeToLocalFormatConversion } from '../../../../../helpers/utility';
import { MdArrowBack as IconBack } from 'react-icons/lib/md';
import repricerActions from '../../../../../redux/repricer/actions';
import LoadingIndicator from '../../../../../shared/components/LoadingIndicator';

const {
	templateQueryBySkus
} = repricerActions;

class TemplateBreakdownTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
		data: [],
		template: 'all',
		filterOptions: [],
		skus: [],
    }
  }

  onSelectChange = (selected, data=null) => {
	const {
		templateToSkuMappingData,
		templateBreakdownData,
	} = this.props;
	if(!data){
		data = templateBreakdownData;
	}
	var newData = [];
	data.forEach(item => {
		templateToSkuMappingData.template_id_to_sku_list_map[selected.value].forEach(sku => {
			if(item.seller_sku === sku){
				item['template_name'] = templateToSkuMappingData.template_id_to_name_mapping[selected.value];
				item['template_id'] = selected.value;
				newData.push(item);
			}
		});
	});


    this.setState({
      data: newData,
      template: selected
    });
  }

	UNSAFE_componentWillMount() {
		const {
			templateToSkuMappingData,
		} = this.props;

		var filterOptions = [];
		var skus = [];

		if(this.props.template_id === 'all_templates'){
			Object.keys(templateToSkuMappingData.template_id_to_name_mapping).forEach(key => {
				filterOptions.push({
					label: templateToSkuMappingData.template_id_to_name_mapping[key],
					value: key,
				});
			})
		} else {
			filterOptions = [{
				label: templateToSkuMappingData.template_id_to_name_mapping[this.props.template_id],
				value: this.props.template_id,
			}]
		}
		//get data for all skus
		Object.keys(templateToSkuMappingData.template_id_to_name_mapping).forEach(key => {
			templateToSkuMappingData.template_id_to_sku_list_map[key].forEach(item => {
				skus.push(item)
			})
		})
		this.props.templateQueryBySkus({ 'skus': skus });

		this.setState({
			filterOptions: filterOptions,
			template: filterOptions[0],
			skus: skus,
		});
	}

	UNSAFE_componentWillReceiveProps(np){
		if(np.templateBreakdownData && np.templateBreakdownData.length > 0){
			if(this.props.templateBreakdownData && this.props.templateBreakdownData.length === 0){
				this.onSelectChange(this.state.template, np.templateBreakdownData);
			}
		}
	}

  render() {
    const {
      toggleView
    } = this.props;

    let tableColumns = [
      {
        id: 'product',
        Header: 'Title',
        accessor: 'product',
				headerClassName: 'text-left',
        Cell: row => (
          <div className="d-flex">
            <span className="mr-2"><img src={row.original.image_url} alt={row.original.item_name} width="25" /></span>
            <span>{row.original.item_name}</span>
          </div>
        )
      },
      {
        id: 'seller_sku',
        Header: 'SKU',
        accessor: 'seller_sku',
				headerClassName: 'text-left'
      },
      {
        id: 'quantity',
        Header: 'Qty',
        accessor: 'quantity',
				headerClassName: 'text-right',
        className: 'text-right',
      },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
				headerClassName: 'text-right',
        className: 'text-right',
        Cell: row => digitСonversion(row.original.price, "currency", "USD")
      },
      {
        id: 'buy_cost',
        Header: 'Buy Cost',
        accessor: 'buy_cost',
				headerClassName: 'text-right',
        className: 'text-right',
        Cell: row => digitСonversion(row.original.buy_cost, "currency", "USD")
      },
      {
        id: 'open_date',
        Header: 'Open Date',
        accessor: 'open_date',
        headerClassName: 'text-left',
        Cell: row => momentDateTimeToLocalFormatConversion(row.original.open_date)
      },
      {
        id: 'salesrank',
        Header: 'Sales Rank',
        accessor: 'salesrank',
				headerClassName: 'text-right',
        className: 'text-right',
      },
      {
        id: 'template_name',
        Header: 'Applied Template',
        accessor: 'template_name',
				headerClassName: 'text-right'
      }
    ]

    return (
      <Fragment>
        <h5 className="mb-0 mr-2 card-title d-inline-block mb-2">Template Breakdown for Schedule</h5> <Button color="link" onClick={toggleView} ><IconBack /> Back</Button>
        <p className="mb-5">This is the breakdown of how your templates will be applied to different SKUs in the next reprice of this schedule.</p>

		{!this.props.templateBreakdownData ?
			<LoadingIndicator className="mt-0" title="Fetching Reprice Reports Summary..." />
		:
		<Fragment>
			<FormGroup className="d-flex align-items-center ml-auto" style={{ width: '50%' }}>
			  <div>
				<Label className="mb-0">Choose a Template</Label>
			  </div>
			  <div style={{ flex: 1, paddingLeft: '1rem' }}>
				<Select
				  options={this.state.filterOptions}
				  value={this.state.template}
				  onChange={this.onSelectChange}
				  clearable={false}
				/>
			  </div>
			</FormGroup>
			<ReactTable
			  columns={tableColumns}
			  data={this.state.data}
				defaultPageSize={10}
				minRows={0}
			/>
		</Fragment>
		}
      </Fragment>
    )
  }
}

export default connect(
	state => ({
		templateToSkuMappingData: state.Repricer.get("templateToSkuMappingData"),
		userTemplates: state.Repricer.get("userTemplates"),
		templateBreakdownData: state.Repricer.get("templateBreakdownData"),
  }),
	{
		templateQueryBySkus,
	}
)(TemplateBreakdownTable);
