import React from "react";
import {
  CardTitle,
  Button,
  Modal,
	ModalBody
} from "reactstrap";

/**
 * Confirmation modal [maybe need some more style :)]
 *
 * @version 1.0.0
 * @author [bojan]
 */
class BConfirmationModal extends React.Component {
	render() {
	return (
	  <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.closeModal}
					centered
        >
					<ModalBody>
						<div className="text-center">
							<CardTitle>{ this.props.modalTitle }</CardTitle>
							<p>
								{ this.props.modalText }
							</p>
							<div className="d-flex justify-content-center gap-2 mt-4">
								{this.props.buttonCloseText &&
								<Button onClick={ this.props.closeModal }>
									{this.props.buttonCloseText}
								</Button>
								}
								<Button
									color={ this.props.buttonColor }
									onClick={ this.props.buttonAction }
								>
									{ this.props.buttonText }
								</Button>
							</div>
						</div>
					</ModalBody>
        </Modal>
      </div>
		);
	}
}

export default BConfirmationModal;
