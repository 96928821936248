import {
	boolToString,
	templateSetAdjustmentRules,
	stringToFloat,
	stringToInt,
} from '../../template_utils';

import {
	mapALConditionsToRepricerBackend,
} from '../../utility';

export function templateConvertFrontendToBackend(template){
	var backendTemplate = {};
	backendTemplate = generateMeta(backendTemplate, template);
	backendTemplate = generateItemTargeting(backendTemplate, template);
	backendTemplate = generateRepriceRuleForFailedReprice(backendTemplate, template);
	backendTemplate = generatePricingRules(backendTemplate, template);
	backendTemplate = generateOffersTargeting(backendTemplate, template);
	backendTemplate = generateOfferCount(backendTemplate, template);
	backendTemplate = generateRepriceUsedAginstNew(backendTemplate, template);
	return backendTemplate;
}

function generateMeta(backendTemplate, template){
	const meta = {
		template_id: template.template_id,
		name: template.name,
		type: "custom_template_v8",
	}
	//could do some error checking sometime
	backendTemplate['meta'] = meta;
	return backendTemplate;
}

function generateItemTargeting(backendTemplate, template){
	const item_targeting = {
		channel: template.template_json.user_inventory_channel_target ?
			template.template_json.user_inventory_channel_target : "both",
		skus_to_exclude: template.template_json.user_inventory_skus_to_exclude,
		skus_to_include: template.template_json.user_inventory_skus_to_include,
		exclude_items_with_sales_rank_above: stringToInt(template.template_json.user_inventory_product_salesrank_max),
		conditions_to_include: mapALConditionsToRepricerBackend(template.template_json.user_inventory_condition_to_reprice),
		max_salesrank: stringToInt(template.template_json.user_inventory_product_salesrank_max),
		min_salesrank: stringToInt(template.template_json.user_inventory_product_salesrank_min),
		max_price_to_target: stringToFloat(template.template_json.user_inventory_product_price_max),
		min_price_to_target: stringToFloat(template.template_json.user_inventory_product_price_min),
		max_inventory_age: stringToInt(template.template_json.user_inventory_inventory_age_max),
		min_inventory_age: stringToInt(template.template_json.user_inventory_inventory_age_min),
		sku_list_to_exclude_id: template.template_json.user_inventory_sku_list_to_exclude_id,
		sku_list_to_include_id: template.template_json.user_inventory_sku_list_to_include_id,
	}
	backendTemplate['item_targeting'] = item_targeting;
	return backendTemplate;
}

function generateRepriceRuleForFailedReprice(backendTemplate, template){
	var data  = {
		"no_competing_offer_exist_activate": boolToString(template.template_json.reprice_rule_for_failed_reprice_no_competing_offer_exist_activate),
		"none_of_competing_offer_meet_requirement_activate": boolToString(template.template_json.reprice_rule_for_failed_reprice_none_of_competing_offer_meet_requirement_activate),
		"general_failed_activate": boolToString(template.template_json.reprice_rule_for_failed_reprice_general_failed_activate),
	}
	data = templateSetAdjustmentRules(
		data,
		"no_competing_offer_exist_rule",
		"no_competing_offer_exist_activate",
		data.no_competing_offer_exist_activate,
		template.template_json.no_competing_offer_exist_rule_use_adjustment_if_true_use_default_price_if_false,
		template.template_json.no_competing_offer_exist_rule_adjustment_amount_on_your_own_offer,
		template.template_json.no_competing_offer_exist_rule_fix_price_adjustment_if_true_percentage_if_false,
		template.template_json.no_competing_offer_exist_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false,
		template.template_json.no_competing_offer_exist_rule_price_guard_activate,
		template.template_json.no_competing_offer_exist_rule_price_guard_rule_min_price,
		template.template_json.no_competing_offer_exist_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee,
	);
	data = templateSetAdjustmentRules(
		data,
		"none_of_competing_offer_meet_requirement_rule",
		"none_of_competing_offer_meet_requirement_activate",
		data.none_of_competing_offer_meet_requirement_activate,
		template.template_json.none_of_competing_offer_meet_requirement_rule_use_adjustment_if_true_use_default_price_if_false,
		template.template_json.none_of_competing_offer_meet_requirement_rule_adjustment_amount_on_your_own_offer,
		template.template_json.none_of_competing_offer_meet_requirement_rule_fix_price_adjustment_if_true_percentage_if_false,
		template.template_json.none_of_competing_offer_meet_requirement_rule_fix_price_adjustment_if_true_percentage_if_false,
		template.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_activate,
		template.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_min_price,
		template.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee,
	);
	data = templateSetAdjustmentRules(
		data,
		"general_failed_rule",
		"general_failed_activate",
		data.general_failed_activate,
		template.template_json.general_failed_rule_use_adjustment_if_true_use_default_price_if_false,
		template.template_json.general_failed_rule_adjustment_amount_on_your_own_offer,
		template.template_json.general_failed_rule_fix_price_adjustment_if_true_percentage_if_false,
		template.template_json.general_failed_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false,
		template.template_json.general_failed_rule_price_guard_activate,
		template.template_json.general_failed_rule_price_guard_rule_min_price,
		template.template_json.general_failed_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee,
	);
	//could do some error checking sometime
	backendTemplate['reprice_rule_for_failed_reprice'] = data;
	return backendTemplate;
}

function generatePricingRules(backendTemplate, template){
	var data = {
		"use_regular_repricing_strategy_against_amazon": boolToString(template.template_json.pricing_rules_use_regular_repricing_strategy_against_amazon),
		"use_if_you_are_the_buy_box_repricing_strategy": boolToString(template.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy),
		"is_roi_guard_activate": boolToString(template.template_json.guards_setMinROI),
		"percentage_price_decrease_guard_activate": boolToString(template.template_json.guards_drop_percentage),
		"percentage_price_increase_guard_activate": boolToString(template.template_json.guards_increase_price),
		"dont_price_above_featured_merchants": boolToString(template.template_json.pricing_rules_dont_price_above_featured_merchants),
		"dont_price_above_amazon": boolToString(template.template_json.guards_if_amazon_lower_not_reprice),
		"only_reprice_if_suggested_price_goes_up": boolToString(template.template_json.guards_reprice_if_suggested_up),
		"only_reprice_if_suggested_price_goes_down": boolToString(template.template_json.guards_reprice_if_suggested_down),
		"default_to_min_if_price_below_min": boolToString(template.template_json.guards_lower_than_price),
		"default_to_max_if_price_above_max": boolToString(template.template_json.guards_higher_than_price),
	}
	//use_regular_repricing_strategy_against_amazon
	var amazon_adjustment = {}
	if(data.use_regular_repricing_strategy_against_amazon === "false"){
		amazon_adjustment = {
			adjustment_amount_on_competing_offer: stringToFloat(template.template_json.pricing_rules_amazon_adjustment_adjustment_amount_on_competing_offer),
			static_method_adjust_up_if_true_down_if_false: boolToString(template.template_json.pricing_rules_amazon_adjustment_static_method_adjust_up_if_true_down_if_false),
			fix_price_adjustment_if_true_percentage_if_false: boolToString(template.template_json.pricing_rules_amazon_adjustment_fix_price_adjustment_if_true_percentage_if_false),
		}
	}
	data["amazon_adjustment"] = amazon_adjustment;
	//use_if_you_are_the_buy_box_repricing_strategy
	var if_you_are_the_buy_box_adjustment = {}
	if(data.use_if_you_are_the_buy_box_repricing_strategy === "true"){
		if_you_are_the_buy_box_adjustment = {
			dont_reprice: boolToString(template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice),
			adjustment: {},
		}
		if(!template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice){
			if_you_are_the_buy_box_adjustment.adjustment = {
				adjustment_amount_on_competing_offer: stringToFloat(template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_adjustment_amount_on_competing_offer),
				fix_price_adjustment_if_true_percentage_if_false: boolToString(template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_fix_price_adjustment_if_true_percentage_if_false),
				static_method_adjust_up_if_true_down_if_false: boolToString(template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_static_method_adjust_up_if_true_down_if_false),
			}
		}
	}
	data["if_you_are_the_buy_box_adjustment"] = if_you_are_the_buy_box_adjustment;
	//adjustment
	data["adjustment"] = {
		adjustment_amount_on_competing_offer: stringToFloat(template.template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer),
		static_method_adjust_up_if_true_down_if_false: boolToString(template.template_json.pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false),
		fix_price_adjustment_if_true_percentage_if_false: boolToString(template.template_json.pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false),
	}
	var min_inventory_price = {
		"activate": "true",
		default_min_inventory_price_to_buy_cost_plus_fee: "false"
	}
	if(template.template_json.guards_price_min){
		min_inventory_price["price"] = stringToFloat(template.template_json.guards_price_min);
	}
	if(!min_inventory_price["price"]){
		min_inventory_price["price"] = 0.01;
	}
	data["min_inventory_price"] = min_inventory_price;
	//max_inventory_price
	var max_inventory_price = {
		"activate": "true",
	}
	if(template.template_json.guards_price_max){
		max_inventory_price["price"] = stringToFloat(template.template_json.guards_price_max);
	}
	if(!max_inventory_price["price"]){
		max_inventory_price["price"] = 9999999;
	}
	data["max_inventory_price"] = max_inventory_price;
	//roi_guard
	var roi_guard = {};
	if(template.template_json.guards_setMinROI){
		roi_guard = {
			roi_amount: stringToFloat(template.template_json.guards_roi_amount),
			fix_price_roi_if_true_percentage_if_false: boolToString(template.template_json.guards_fix_price_roi_if_true_percentage_if_false),
			//ceheck out whet is this one
			//*****************************************************
			disregard_roi_when_either_fee_or_buycost_is_missing: "true",
			default_to_roi_if_price_below_roi: boolToString(template.template_json.guards_lower_than_roi),
		}
	}
	data["roi_guard"] = roi_guard;
	//percentage_price_decrease_guard
	var percentage_price_decrease_guard = {}
	if(data.percentage_price_decrease_guard_activate === "true"){
		percentage_price_decrease_guard = {
			price_drop_percentage_cannot_exceed_amount: stringToInt(template.template_json.guards_drop_percentage),
		}
	}
	data["percentage_price_decrease_guard"] = percentage_price_decrease_guard;
	//percentage_price_increase_guard
	var percentage_price_increase_guard = {}
	if(data.percentage_price_increase_guard_activate === "true"){
		percentage_price_increase_guard = {
			price_increase_percentage_cannot_exceed_amount: stringToInt(template.template_json.guards_increase_price),
		}
	}
	data["percentage_price_increase_guard"] = percentage_price_increase_guard;
	//could do some error checking sometime
	backendTemplate['pricing_rules'] = data;
	return backendTemplate;
}

function generateOffersTargeting(backendTemplate, template){
	var data = {
		"own_inventory_in_buy_box_offer_targeting": {
			"competing_offer_to_reprice_against": "use_own_inventory_not_in_buy_box_offer_targeting",
		},
		"own_inventory_not_in_the_buy_box_offer_targeting": {
			"competing_offer_to_reprice_against": "lowest_offer_with_same_main_and_sub_condition",
		},
		"exclude_offers_with_seller_rating_below": stringToInt(template.template_json.offers_targeting_exclude_offers_with_seller_rating_below),
		"exclude_offers_with_seller_feed_back_count_below": stringToInt(template.template_json.offers_targeting_exclude_offers_with_seller_feed_back_count_below),
		"minimum_offer_to_trigger_reprice": stringToInt(template.template_json.offers_targeting_minimum_offer_to_trigger_reprice),
		"sellers_to_exclude": [],
		"exclude_offer_not_available_now": boolToString(template.template_json.pricing_rules_exclude_offer_not_available_now),
		"exclude_offer_if_sub_condition_acceptable": boolToString(template.template_json.pricing_rules_exclude_offer_if_sub_condition_acceptable),
	}
	//use_if_you_are_the_buy_box_repricing_strategy
	if(template.template_json.offers_targeting_competing_offer_to_reprice_against){
		data.own_inventory_in_buy_box_offer_targeting.competing_offer_to_reprice_against = template.template_json.offers_targeting_competing_offer_to_reprice_against;
	}
	//
	if(template.template_json.offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting){
		data.own_inventory_not_in_the_buy_box_offer_targeting.competing_offer_to_reprice_against = template.template_json.offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting;
	}
	//channel_target
	if(template.template_json.user_inventory_channel_target_fba){
		data["channel_target"] = "amazon_fulfill";
	}
	if(template.template_json.user_inventory_channel_target_mf){
		data["channel_target"] = "merchant_fulfill";
	}
	if(template.template_json.user_inventory_channel_target_fba
		&& template.template_json.user_inventory_channel_target_mf){
		data["channel_target"] = "both";
	}
	data['seller_to_exclude_id'] = template.template_json.offers_targeting_seller_to_exclude_id;
	data['sellers_to_exclude'] = template.template_json.offers_targeting_sellers_to_exclude || [];
	if(template.template_json.offers_targeting_prime_filter){
		data['prime_filter'] = template.template_json.offers_targeting_prime_filter;
	} else {
		data['prime_filter'] = "target_both";
	}

	backendTemplate["offers_targeting"] = data;
	return backendTemplate;
}

function generateOfferCount(backendTemplate, template){
	var data = {
		"strategy": "dont_reprice",
		"reprice_against_lowest_or_buybox_strat": {},
	}
	if(template.template_json.offers_count_strategy){
		data.strategy = template.template_json.offers_count_strategy;
	}
	if(data.strategy === 'reprice_against_lowest_or_buybox'){
		var strat = {};
		strat['target_lowest_or_buybox'] = template.template_json.offers_count_target_lowest_or_buybox;
		if(strat['target_lowest_or_buybox'] !== 'buybox'){
			strat['target_channel'] = template.template_json.offers_count_target_channel;
		}
		strat['min_num_offer'] = template.template_json.offers_count_min_num_offer;
		strat['own_inventory_in_buybox'] = { dont_reprice: "true", adjustment: {}};
		strat['own_inventory_not_in_buybox'] = { adjustment: {}};
		if(!template.template_json.offers_count_own_inventory_in_buybox_dont_reprice){
			strat.own_inventory_in_buybox.dont_reprice = 'false';
			strat.own_inventory_in_buybox.adjustment['adjustment_amount_on_competing_offer'] = template.template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer;
			strat.own_inventory_in_buybox.adjustment['fix_price_adjustment_if_true_percentage_if_false'] = boolToString(template.template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false);
			strat.own_inventory_in_buybox.adjustment['static_method_adjust_up_if_true_down_if_false'] = boolToString(template.template_json.offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false);
		}
		strat.own_inventory_not_in_buybox.adjustment['adjustment_amount_on_competing_offer'] = template.template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer;
		strat.own_inventory_not_in_buybox.adjustment['fix_price_adjustment_if_true_percentage_if_false'] = boolToString(template.template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false);
		strat.own_inventory_not_in_buybox.adjustment['static_method_adjust_up_if_true_down_if_false'] = boolToString(template.template_json.offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false);
		data['reprice_against_lowest_or_buybox_strat'] = strat;
	}
	backendTemplate["over_20_offer_strategy"] = data;
	return backendTemplate;
}

function generateRepriceUsedAginstNew(backendTemplate, template){
	var data = {
		"is_activated": "false",
	}

	var strat = {};

	if(template.template_json.reprice_used_aginst_new_uan_is_activated){
		data["is_activated"] = "true";
	}

	if(data["is_activated"] === "true"){
		strat["target_lowest_or_buybox"] = template.template_json.reprice_used_aginst_new_reprice_against;
		if(strat["target_lowest_or_buybox"] !== "buybox"){
			strat["target_channel"] = template.template_json.reprice_used_aginst_new_target_channel;
		}
		strat["min_num_offer"] = stringToInt(template.template_json.reprice_used_aginst_new_uan_min_num_offer);
		if(!strat["min_num_offer"]){
			strat["min_num_offer"] = 0;
		}

		var in_box = {"dont_reprice": "true"}
		var in_box_adjustment = {}
		if(template.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_dont_reprice === false){
			in_box["dont_reprice"] = "false";
			in_box_adjustment["adjustment_amount_on_competing_offer"] = stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer);
			in_box_adjustment["fix_price_adjustment_if_true_percentage_if_false"] = boolToString(template.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false);
			in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = template.template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false;
			if(in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] === '0'){
				in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = 'true';
			} else {
        //in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = boolToString(in_box_adjustment["static_method_adjust_up_if_true_down_if_false"]);
			}
		}

		var not_in_box_adjustment = {}
		not_in_box_adjustment["adjustment_amount_on_competing_offer"] = stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer);
		not_in_box_adjustment["fix_price_adjustment_if_true_percentage_if_false"] = boolToString(template.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false);
		not_in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = template.template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false;
		if(not_in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] === '0'){
			not_in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = 'true';
		} else {
      //not_in_box_adjustment["static_method_adjust_up_if_true_down_if_false"] = boolToString(not_in_box_adjustment["static_method_adjust_up_if_true_down_if_false"]);
		}
		not_in_box_adjustment = {"adjustment": not_in_box_adjustment}

		in_box["adjustment"] = in_box_adjustment;
		strat["own_inventory_in_buybox"] = in_box;
		strat["own_inventory_not_in_buybox"] = not_in_box_adjustment;
	}
	data["reprice_against_lowest_or_buybox_strat"] = strat;
	backendTemplate["reprice_used_aginst_new"] = data;
	return backendTemplate;
}
