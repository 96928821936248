import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { digitСonversion } from "../../../helpers/utility";

class CustomizedLabel extends Component {
  render() {
	  const {x, y, value, internationalizationConfig, type, dx} = this.props;
    return (
        <text
          x={x}
          y={y}
          dx={dx !== undefined ? dx : 17}
          dy={value > 0 ? -5 : 14}
          fontSize="10"
          fill="#3a3a3a"
          textAnchor="middle"
        >
          {type ?
          value !== 0 ? digitСonversion(value, type) : ""
          :
          value !== 0 ? digitСonversion(value, 'currency', internationalizationConfig.currency_code) : ""
          }
        </text>
    );
  }
};

CustomizedLabel.propTypes = {
  internationalizationConfig: PropTypes.object,
}

export default CustomizedLabel;