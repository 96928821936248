import React, {Component} from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Tooltip } from "reactstrap";
import ShipmentBox from './ShipmentBox';
import PropTypes from "prop-types";
import ButtonExportShipmentToTsv from "./components/ButtonExportShipmentToTsv";
import IconCheckCircle from 'react-icons/lib/md/check-circle';
import ButtonPrintItems from "./components/ButtonPrintItems";
import { lbsToKg } from '../../../helpers/utility';
import IconMissing from "react-icons/lib/md/assignment-late";
//import IconComplete from "react-icons/lib/md/assignment-turned-in";
import MissingItemsPopover from "./components/MissingItemsPopover";
import IconSize from "react-icons/lib/io/arrow-resize";
import DimensionModal from "./modals/DimensionModal";
import boxContentActions from "../../../redux/box_contents/actions";
import IconManifest from 'react-icons/lib/fa/clipboard';
import IconProducts from 'react-icons/lib/fa/list';
import ProductsModal from "./modals/ProductsModal";
import batchActions from "../../../redux/batch/actions";

const { uploadFileShipment, boxPrintManifest } = boxContentActions
const { loadBatch } = batchActions;

const createddata = {
  shipment:{
  total_box_count: 0,
  boxes: {},
  }
};

class ShipmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBoxDetails: false,
      missingPopoverOpen: false,
      showAllBoxDimensionModal: false,
      dimension: "",
      dimensionTooltip: false,
      alreadyEntered: false,
      checkedBox: [],
      showProductsModal: false,
      // products: this.props.products
    };
  }

  toggleMissingPopover = () => {
    this.setState({ missingPopoverOpen: !this.state.missingPopoverOpen });
  };

  onChangeFile = (e, data, currShipmentId) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFile = e.target.files[0];
    var formdata = new FormData();
    formdata.append("file", uploadedFile, "send_to_amazon.xlsx");
    formdata.append("data", JSON.stringify(data[currShipmentId]));
    if (this.state.alreadyEntered) {
      this.props.uploadFileShipment(formdata);
    } else {
      if (!this.state.alreadyEntered) {
        this.setState({ dimensionTooltip: true });
      }
    }
    if (e.target.files[0]) {
      this.uploadFile.value = "";
    }
  };

  createBoxData = (boxes, shipmentID) => {
    if (boxes.length > 0) {
      var nonEmptyBox = boxes.filter(function (el) {
        return el.box.items.length !== 0;
      });
      if (nonEmptyBox.length > 0) {
        if (createddata["shipment"]) {
          Object.defineProperty(
            createddata,
            shipmentID,
            Object.getOwnPropertyDescriptor(createddata, "shipment")
          );

          delete createddata["shipment"];
        } else {
          if (createddata[shipmentID] == undefined)
            createddata[shipmentID] = {
              total_box_count: 0,
              boxes: {},
            };
        }
        createddata[shipmentID].total_box_count = nonEmptyBox.length;
        for (var i = 0; i < nonEmptyBox.length; i++) {
          let boxIndex = i + 1;

          if (createddata[shipmentID].boxes[boxIndex] == undefined) {
            createddata[shipmentID].boxes[boxIndex] = {
              weight: parseFloat(nonEmptyBox[i].weight.toFixed(2)),
              width: 0,
              length: 0,
              height: 0,
              items: {},
            };
          }
          if (
            this.state.dimension.for &&
            this.state.dimension.for == "forAllboxes"
          ) {
            createddata[shipmentID].boxes[boxIndex] = {
              weight: parseFloat(nonEmptyBox[i].weight.toFixed(2)),
              width: parseFloat(this.state.dimension.width),
              length: parseFloat(this.state.dimension.Length),
              height: parseFloat(this.state.dimension.Height),
              items: {},
            };
          } else if (
            this.state.dimension.for &&
            this.state.dimension.for == "single"
          ) {
            if (boxes[i].id == this.state.dimension.boxId) {
              createddata[shipmentID].boxes[boxIndex] = {
                weight: parseFloat(nonEmptyBox[i].weight.toFixed(2)),
                width: parseFloat(this.state.dimension.width),
                length: parseFloat(this.state.dimension.Length),
                height: parseFloat(this.state.dimension.Height),
                items: {},
              };
            }
          }

          for (var j = 0; j < nonEmptyBox[i].box.items.length; j++) {
            var indexKey = nonEmptyBox[i].box.items[j].FulfillmentNetworkSKU;
            createddata[shipmentID].boxes[boxIndex].items[indexKey] = {
              count: nonEmptyBox[i].box.items[j].QuantityShippedInBox,
            };
          }
        }
      }
    }
    return createddata;
  };

  handleSetDimentions = (dimensionData) => {
    let allBoxesId = [];
    this.setState({ dimension: dimensionData });
    if (dimensionData.for && dimensionData.for == "forAllboxes") {
      this.setState({ dimensionTooltip: false });
      this.setState({ alreadyEntered: true });
      let datakeys = Object.keys(this.props.selectedShipmentsData);
      datakeys.map((key) => {
        this.props.selectedShipmentsData[key].boxes.filter(function (el) {
          return allBoxesId.push(el.id);
        });
        this.setState({ checkedBox: allBoxesId });
      });
    }
    if (dimensionData.for && dimensionData.for == "single") {
      let checkboxPrev = this.state.checkedBox;
      checkboxPrev.push(dimensionData.boxId);
      this.setState({ checkedBox: checkboxPrev });
      let SelectedBoxIds = this.props.selectedShipment.boxes.map((s) => s.id);
      let checkAllCheked = SelectedBoxIds.every((elem) =>
        this.state.checkedBox.includes(elem)
      );
      if (checkAllCheked) {
        this.setState({ alreadyEntered: true });
      }
    }
  };

  printManifest = (boxes) => {
    const shipments = boxes.map(b => b.box.shipment_id);
    const data = boxes.filter(b => b.box.items.length > 0)
                      .map((bx, i) => {
                        return {
                          asin: bx.box.items[0].ASIN,
                          sku: bx.box.items[0].SellerSKU,
                          fnsku: bx.box.items[0].FulfillmentNetworkSKU,
                          shipment: shipments[i],
                          qty: bx.box.items[0].QuantityShippedInBox,
                          box: bx.box.box_number
                        }
                      })
    this.props.boxPrintManifest(data);
  }

  selectShipmentData = (shipment, checked) => {
    const batchID = shipment.batch_id
    this.props.loadBatch(batchID);
    this.props.selectShipment(shipment, checked)
  }

  displayShipmentDestinationAddress = (id) => {
    const data = {
      ABE8: "Allentown, PA",
      ATL6: "East Point, GA",
      AVP1: "Pittston, PA",
      BDL1: "Windsor, CT",
      BHM1: "Bessemer, AL",
      BNA3: "Nashville, TN",
      BOI2: "Nampa, ID",
      BWI2: "Baltimore, MD",
      CHA1: "Chattanooga, TN",
      CLT2: "Charlotte, NC",
      CVG3: "Hebron, KY",
      DAL1: "Coppell, TX",
      DEN2: "Aurora, CO",
      DFW6: "Haslet, TX",
      GSO1: "Greensboro, NC",
      HOU2: "Katy, TX",
      IAD2: "Sterling, VA",
      IND2: "Indianapolis, IN",
      JAX2: "Jacksonville, FL",
      LAS1: "North Las Vegas, NV",
      LAX9: "Moreno Valley, CA",
      LGB3: "Eastvale, CA",
      MEM1: "Memphis, TN",
      MDT2: "Middletown, PA",
      MDW2: "Joliet, IL",
      MIA1: "Miami, FL",
      MKE1: "Kenosha, WI",
      MSP1: "Shakopee, MN",
      MSY1: "New Orleans, LA",
      OAK4: "Tracy, CA",
      ONT2: "Eastvale, CA",
      PDX9: "Troutdale, OR",
      PHL5: "New Castle, DE",
      PHX5: "Phoenix, AZ",
      RIC2: "Richmond, VA",
      SDF1: "Shepherdsville, KY",
      SLC1: "Salt Lake City, UT",
      SMF3: "Sacramento, CA",
      SNA4: "San Bernardino, CA",
      TPA1: "Ruskin, FL",
    }
    return data[id] ? data[id] : null;
  }

  checkFlashShipment = (boxes) => {
    if(boxes.map(item => item.id).includes(this.props.adding_product_to_box_fuccess)){
      return true;
    };
    return false;
  }


  render() {
    const {
      updateCurrentBox,
      shipment,
      isSelected,
      selectShipment,
      selectedShipment,
      addBox,
      printQr,
      changeLimit,
      changePage,
      page,
      limit,
      userData,
      selectedShipmentsData,
      highlightItems,
    } = this.props;

    //console.log("shipment:", shipment)
    //console.log("selectedShipmentsData:", selectedShipmentsData)

    let selectedShipmentsAdditionalData = {};

    selectedShipmentsData.forEach(item => {
      selectedShipmentsAdditionalData = {
        ...selectedShipmentsAdditionalData,
        ...item?.additionalData,
      }
    });

    let displayData = null;

    if (isSelected) {
      let boxes = [];
      let totalWeight = 0;
      let totalQtyPrepped = 0;
      let prepped_skus = {};
      let boxesData = {};
      selectedShipment.boxes.forEach((box) => {
        let weight = 0;
        box.items.forEach((item) => {
          totalQtyPrepped += Number(item.QuantityShippedInBox);
          if (
            !!item.ProductSearchResult &&
            !!item.ProductSearchResult.itemDimensions &&
            !!item.ProductSearchResult.itemDimensions.Weight
          ) {
            weight +=
              Number(
                item.ProductSearchResult.itemDimensions.Weight *
                  item.QuantityShippedInBox
              ) || 0;
          }
          if (!Object.keys(prepped_skus).includes(item.SellerSKU)) {
            prepped_skus[item.SellerSKU] = Number(item.QuantityShippedInBox);
          } else {
            prepped_skus[item.SellerSKU] += Number(item.QuantityShippedInBox);
          }
        });
        boxes.push({
          box: box,
          boxNumber: box.box_number,
          id: box.id,
          weight: weight,
        });
        totalWeight += weight;
      });

      boxesData = this.createBoxData(
        boxes,
        selectedShipment.selectedShipment.ShipmentId
      );

      let totalQtyInShipment = 0;
      let missing_data = [];
      selectedShipment.inboundShipmentItems.forEach((item) => {
        totalQtyInShipment += Number(item.QuantityShipped);
        if (
          !Object.keys(prepped_skus).includes(item.SellerSKU) ||
          (Object.keys(prepped_skus).includes(item.SellerSKU) &&
            prepped_skus[item.SellerSKU] < Number(item.QuantityShipped))
        ) {
          let qty = Number(item.QuantityShipped);
          if (Object.keys(prepped_skus).includes(item.SellerSKU)) {
            qty = Number(item.QuantityShipped) - prepped_skus[item.SellerSKU];
          }
          let item_data = {
            img_url: "",
            title: selectedShipmentsAdditionalData[item.SellerSKU]?.name,
            asin: item.ASIN,
            sku: item.SellerSKU,
            condition: "",
            qty: qty,
          };
          if (item.ASIN in selectedShipment.asinToSearchResultDataMapping) {
            item_data.img_url =
              selectedShipment.asinToSearchResultDataMapping[
                item.ASIN
              ]?.imageUrl;
            item_data.title =
              selectedShipment.asinToSearchResultDataMapping[item.ASIN].name;
          }
          if (item_data.img_url === "") {
            item_data.img_url =
              "http://images.amazon.com/images/P/" +
              item.ASIN +
              ".01._SCMZZZZZZZ_.jpg";
          }
          missing_data.push(item_data);
        }
      });
      let missing_count = totalQtyInShipment - totalQtyPrepped;

      let boxNodes;
      let actionButtons;
      if (boxes.length > 0) {
        boxNodes = boxes.map((box, i) => {
          return (
            <ShipmentBox
              key={shipment.ShipmentId + "-box-" + i}
              updateCurrentBox={updateCurrentBox}
              boxData={box}
              checkedBox={this.state.checkedBox}
              ShipmentId={shipment.ShipmentId}
              isSelected={selectedShipment.currentBox.id === box.id}
              printQr={printQr}
              shipment={shipment}
              changeLimit={changeLimit}
              changePage={changePage}
              page={page}
              limit={limit}
              selectedShipmentsData={selectedShipmentsData}
              highlightItems={highlightItems}
              onSetDimentions={this.handleSetDimentions}
            ></ShipmentBox>
          );
        });

        actionButtons = (
          <Col md="4" className="text-right">
            <div className="d-flex justify-content-end">
              <ButtonExportShipmentToTsv shipment={selectedShipment} />
              &nbsp;&nbsp;
              <div id="dimensionTooltip">
                <Button
                  size="sm"
                  color="secondary"
                  className="mx-2"
                  onClick={() =>
                    this.setState({ showAllBoxDimensionModal: true })
                  }
                >
                  <IconSize size="16" />
                  {this.state.dimensionTooltip ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.dimensionTooltip}
                      target="dimensionTooltip"
                    >
                      Please add dimensions for all boxes.
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Button>
                <DimensionModal
                  isOpen={this.state.showAllBoxDimensionModal}
                  toggle={() =>
                    this.setState({
                      showAllBoxDimensionModal:
                        !this.state.showAllBoxDimensionModal,
                    })
                  }
                  forAllBoxes
                  boxes={boxes}
                  onSetDimentions={this.handleSetDimentions}
                />
              </div>
              <div>
                <ButtonPrintItems shipment={selectedShipment} />
              </div>
              <div>
                <input
                  type="file"
                  className="d-none"
                  ref={(ref) => (this.uploadFile = ref)}
                  onChange={(e) =>
                    this.onChangeFile(
                      e,
                      boxesData,
                      selectedShipment.selectedShipment.ShipmentId
                    )
                  }
                />
                <Button
                  size="sm"
                  color="secondary"
                  className="ml-2"
                  disabled={!this.state.alreadyEntered}
                  onClick={() => {
                    this.uploadFile.click();
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Col>
        );
      }
      displayData = (
        <div className="d-flex align-items-center mb-4">
          <div className="check mr-2">
            <label className="container-check">
              <input
                type="checkbox"
                onChange={(e) => {
                  this.selectShipmentData(shipment, e.target.checked);
                }}
                checked={isSelected}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className={`card shipment-item ${isSelected ? "active" : ""} ${this.checkFlashShipment(boxes) ? 'animate-flashing' : ''}`}>
            <div className="shipment-header">
              <Row className="align-items-center">
                <Col md="8">
                  <h4 className="shipment-title">{shipment.ShipmentName}</h4>
                </Col>
                {actionButtons}
              </Row>
              <Row className="align-items-center mt-2">
                <Col md="6">
                  <span>Shipment ID: {shipment.ShipmentId}</span>
                </Col>
                <Col md="6" className="text-right">
                  <IconManifest
                    role="button"
                    size="14"
                    color="#90a1b3"
                    className="ml-2"
                    onClick={e => this.printManifest(boxes)}
                  />
                  <IconProducts
                    role="button"
                    size="14"
                    color="#90a1b3"
                    className="ml-2"
                    onClick={() => this.setState({ showProductsModal: true })}
                  />
                  {this.state.showProductsModal &&
                  <ProductsModal
                    isOpen={true}
                    toggle={() => this.setState({ showProductsModal: !this.state.showProductsModal})}
                    products={this.props.products}
                    container={'batch'}
                  />
                  }
                  <span
                    className={`badge warehouse-badge mt-0 ml-2 ${shipment.DestinationFulfillmentCenterId}`}
                  >
                    {shipment.DestinationFulfillmentCenterId}
                  </span>
                </Col>
              </Row>
            </div>
            <div className="shipment-body">
              <div className="d-flex justify-content-between align-items-center">
              <div>
              <div className="shipment-info">
                <span>
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 2 13 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5555556 3.44444444H8.53666667C8.23333333 2.60666667 7.43888889 2 6.5 2c-.93888889 0-1.73333333.60666667-2.03666667 1.44444444H1.44444444C.65 3.44444444 0 4.09444444 0 4.88888889V15c0 .7944444.65 1.4444444 1.44444444 1.4444444H11.5555556C12.35 16.4444444 13 15.7944444 13 15V4.88888889c0-.79444445-.65-1.44444445-1.4444444-1.44444445zm-5.0555556 0c.39722222 0 .72222222.325.72222222.72222223 0 .39722222-.325.72222222-.72222222.72222222s-.72222222-.325-.72222222-.72222222c0-.39722223.325-.72222223.72222222-.72222223zM7.94444444 13.5555556H2.88888889v-1.4444445h5.05555555v1.4444445zm2.16666666-2.8888889H2.88888889V9.22222222h7.22222221v1.44444448zm0-2.88888892H2.88888889V6.33333333h7.22222221v1.44444445z"
                      fill="#BEC6BA"
                      fillRule="evenodd"
                    />
                  </svg>
                  {`${totalQtyPrepped} / ${totalQtyInShipment} total Items`}
                </span>
                <span className={totalWeight <= 0 ? 'text-danger' : 'text-success'}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 2 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M13.125 3.875h-.7617188c.4804688.82910156.7617188 1.78710938.7617188 2.8125 0 3.10253906-2.5224609 5.625-5.625 5.625-3.10253906 0-5.625-2.52246094-5.625-5.625 0-1.02539062.28125-1.98339844.76171875-2.8125H1.875C.84082031 3.875 0 4.71582031 0 5.75v9.375C0 16.1591797.84082031 17 1.875 17h11.25C14.1591797 17 15 16.1591797 15 15.125V5.75c0-1.03417969-.8408203-1.875-1.875-1.875zm-5.625 7.5c2.5898438 0 4.6875-2.09765625 4.6875-4.6875S10.0898438 2 7.5 2C4.91015625 2 2.8125 4.09765625 2.8125 6.6875S4.91015625 11.375 7.5 11.375zm-.00878906-4.45019531l.984375-2.296875c.10253906-.24023438.37792969-.34863281.61523437-.24609375.23730469.10253906.34863282.37792968.24609375.61523437l-.98730468 2.30273438c.19628906.20800781.31933593.48632812.31933593.79394531 0 .64746094-.52441406 1.171875-1.171875 1.171875-.64746093 0-1.171875-.52441406-1.171875-1.171875.00292969-.64453125.52441407-1.16601562 1.16601563-1.16894531z"
                    />
                  </svg>
                  {userData &&
                  ["A2EUQ1WTGCTBG2", "A1F83G8C2ARO7P"].includes(
                    userData.marketplaceId
                  ) ? (
                    <span>{lbsToKg(totalWeight).toFixed(2)}kg</span>
                  ) : (
                    <span>{totalWeight.toFixed(2)}lbs</span>
                  )}
                </span>
                <span>
                  <Button
                    id={`MissingPopover-${shipment.ShipmentId}`}
                    color="link"
                    className={`btn-missing-items ${missing_count === 0 ? "no-missing" : ""}`}
                    onClick={this.toggleMissingPopover}
                  >
                    <IconMissing className="icon" size="18" />
                    <span>{missing_count} items missing</span>
                  </Button>
                  <MissingItemsPopover
                    isOpen={this.state.missingPopoverOpen}
                    target={`MissingPopover-${shipment.ShipmentId}`}
                    toggle={this.toggleMissingPopover}
                    data={missing_data}
                  />
                  {/* <span className="no-missing-items"><IconComplete className="icon" size="18" /><span>Complete</span></span> */}
                </span>
              </div>
              <div className="shipment-boxes">
                {boxNodes}
                <div
                  className="shipment-box box-add"
                  onClick={() => addBox(shipment.ShipmentId)}
                >
                  <svg
                    width="32"
                    height="31"
                    viewBox="-1 0 32 31"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.8535156 10.8164063L26.8886719 1.921875C26.5078125.7734375 25.4355469 0 24.2226562 0H15.9375v11.25h13.9863281c-.0234375-.1464844-.0234375-.2929687-.0703125-.4335937zM14.0625 0H5.77734375C4.56445312 0 3.4921875.7734375 3.11132812 1.921875L.14648437 10.8164063c-.046875.140625-.046875.2871093-.0703125.4335937H14.0625V0zM0 13.125v14.0625C0 28.7402344 1.25976562 30 2.8125 30h24.375C28.7402344 30 30 28.7402344 30 27.1875V13.125H0z"
                      fill="#858C82"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span className="box-number">+</span>
                  <span className="box-weight">Add box</span>
                </div>
              </div>
              </div>
              {/*
              <div className="shipment-status">
                <span>0/145 left to scan</span>
                {totalQtyPrepped === totalQtyInShipment && (
                  <span className="icon-shipment-done ml-2">
                    <IconCheckCircle size="26" />
                  </span>
                )}
              </div>
              */}
              <span className="warehouse-big">{shipment.DestinationFulfillmentCenterId}</span>
            </div>
            </div>
          </div>
        </div>
      );
    } else {
      displayData = (
        <div className="d-flex align-items-center mb-4">
          <div className="check mr-2">
            <label className="container-check">
              <input
                type="checkbox"
                onChange={(e) => {
                  this.selectShipmentData(shipment, e.target.checked);
                }}
                checked={isSelected}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div
            onClick={() => {
              selectShipment(shipment, !isSelected);
            }}
            className={`card shipment-item ${isSelected ? "active" : ""}`}
          >
            <div className="shipment-header">
              <Row className="align-items-center">
                <Col md="9">
                  <h4 className="shipment-title">{shipment.ShipmentName}</h4>
                </Col>
                <Col md="3" className="text-right">
                  <button
                    type="button"
                    className="btn btn-link btn-manage"
                  >
                    Manage
                  </button>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="9">
                  <span>Shipment ID: <strong>{shipment.ShipmentId}</strong></span>
                </Col>
                <Col md="3" className="text-right">
                  <span
                    className={`badge warehouse-badge ${shipment.DestinationFulfillmentCenterId}`}
                  >
                    {shipment.DestinationFulfillmentCenterId}
                  </span>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="7">
                </Col>
                <Col md="5" className="text-right pt-1">
                  <span>{this.displayShipmentDestinationAddress(shipment.DestinationFulfillmentCenterId)}</span>
                </Col>
              </Row>
            </div>
            <div className="shipment-body">
              <div className="d-flex justify-content-between align-items-center">
              <div className="shipment-boxes">
                <div className="shipment-box box-add">
                  <svg
                    width="32"
                    height="31"
                    viewBox="-1 0 32 31"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.8535156 10.8164063L26.8886719 1.921875C26.5078125.7734375 25.4355469 0 24.2226562 0H15.9375v11.25h13.9863281c-.0234375-.1464844-.0234375-.2929687-.0703125-.4335937zM14.0625 0H5.77734375C4.56445312 0 3.4921875.7734375 3.11132812 1.921875L.14648437 10.8164063c-.046875.140625-.046875.2871093-.0703125.4335937H14.0625V0zM0 13.125v14.0625C0 28.7402344 1.25976562 30 2.8125 30h24.375C28.7402344 30 30 28.7402344 30 27.1875V13.125H0z"
                      fill="#858C82"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span className="box-number">+</span>
                  <span className="box-weight">Work on Shipment</span>
                </div>
              </div>
              <div>
                <span className="warehouse-big">{shipment.DestinationFulfillmentCenterId}</span>
                <div className="shipment-status">
                  <span>{shipment?.EstimatedBoxContentsFee?.TotalUnits} {shipment?.EstimatedBoxContentsFee?.TotalUnits > 1 ? "ITEMS" : "ITEM"}</span>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return displayData;
  }
}

ShipmentCard.propTypes = {
  boxData: PropTypes.object,
  shipment: PropTypes.object,
  isSelected: PropTypes.bool,
  selectShipment: PropTypes.func,
  selectedShipment: PropTypes.object,
  addBox: PropTypes.func,
  updateCurrentBox: PropTypes.func,
  printQr: PropTypes.func,
  changeLimit: PropTypes.func,
  changePage: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number
};

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
    is_printing_manifest: state.BoxContents.get("is_printing_manifest"),
    adding_product_to_box_fuccess: state.BoxContents.get("adding_product_to_box_fuccess"),
    products: state.Batch.get("products")
  }),
  { loadBatch,
    boxPrintManifest,
    uploadFileShipment
  }
)(ShipmentCard);
