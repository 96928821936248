import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  employees_tracking: [],
  employees_tracking_loading: false,
});

export default function employeesReducer(state = initState, action) {
	switch (action.type) {

	  case actions.GET_EMPLOYEES_TRACKING:
			return state
        .set("employees_tracking", [])
        .set("employees_tracking_loading", true)
    case actions.GET_EMPLOYEES_TRACKING_SUCCESS:
      let employees_tracking = [];
      Object.keys(action.data).forEach(user_id => {
        let d = {
          employee_id: user_id,
          name: action.data[user_id].user_data.username,
          city: action.data[user_id].user_data.city,
          state: action.data[user_id].user_data.state,
          entries: [],
        }
        if(action.data[user_id].batches){
          Object.keys(action.data[user_id].batches).forEach(batch_id => {
            d.entries.push({
              id: batch_id,
              date: action.data[user_id].batches[batch_id].created_at,
              status: action.data[user_id].batches[batch_id].status,
              items_count: action.data[user_id].batches[batch_id].items_count,
              batch_name: action.data[user_id].batches[batch_id].batch_name,
              channel: action.data[user_id].batches[batch_id].channel,
            })
          })
        }
        employees_tracking.push(d);
      })
			return state
        .set("employees_tracking", Object.assign([], employees_tracking))
        .set("employees_tracking_loading", false)
	  case actions.GET_EMPLOYEES_TRACKING_FAILED:
			return state
        .set("employees_tracking", [])
        .set("employees_tracking_loading", false)

	default:
		return state;
	}
}
