import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import AccelerlistRepricerChart from "./AccelerlistRepricerChart";
import RepricerCostRevenueChart from "./RepricerCostRevenueChart";

const { getRepricerStats } = adminActions;

/**
 * Repricer stats display
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminRepricerStats extends Component {

	componentDidMount(){
		this.props.getRepricerStats();
	}

  render() {
    return (
			<React.Fragment>
				<Row className="mb-4">
					<Col lg="12">
						<Card>
							<CardBody>
								{this.props.repricerStats ? (
									<div className="d-flex justify-content-center" style={{ gap: "3rem"}}>
										<div className="metric-item blue">
											<h6 className="title">Repricer Active Users #</h6>
											<span className="value">{ this.props.repricerStats.total_repricer_active }</span>
										</div>
										<div className="metric-item blue">
											<h6 className="title">Repricer Active Users %</h6>
											<span className="value">{ this.props.repricerStats.total_repricer_active_percentage }%</span>
										</div>
									</div>
								) : (null)
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					{this.props.repricerStats && this.props.repricerStats.al_vs_pr ?
					<Col lg="6">
						<Card>
							<CardBody>
								<CardTitle>AccelerList vs. Profit Reprice&trade;</CardTitle>
								<AccelerlistRepricerChart
									data={this.props.repricerStats.al_vs_pr.al_vs_pr}
								/>
							</CardBody>
						</Card>
					</Col>
					: null
					}
					{this.props.repricerStats && this.props.repricerStats.pr_vs_cost ?
					<Col lg="6">
						<Card>
							<CardBody>
								<CardTitle>Profit Reprice&trade; Cost Revenue</CardTitle>
								<RepricerCostRevenueChart
									data={this.props.repricerStats.pr_vs_cost.pr_vs_cost}
								/>
							</CardBody>
						</Card>
					</Col>
					: null
					}
				</Row>
			</React.Fragment>
    );
  }
}

export default connect(
  state => {
    return {
			repricerStats: state.Admin.get('repricerStats')
		};
	},
		{
			getRepricerStats,
		}

)(AdminRepricerStats);
