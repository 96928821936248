import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Label, Input, Collapse
} from 'reactstrap';
import InputAmountType from '../../../../../../../shared/components/Form/InputAmountType';
import repricerActions from '../../../../../../../redux/repricer/actions';
import AlertTooltip from '../../../components/AlertTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class Scenario2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repriceAmountType: '$',
      repriceType: 'regular',
		repriceAmount: 0,
		repriceUpDown: "below",
    }
  }

	UNSAFE_componentWillMount(){
		var repriceType;
		if(this.props.workingTemplate.template_json.pricing_rules_use_regular_repricing_strategy_against_amazon){
			repriceType = 'regular';
		}
		if(!this.props.workingTemplate.template_json.pricing_rules_use_regular_repricing_strategy_against_amazon){
			repriceType = 'custom';
		}
		this.setState({
			repriceAmountType: this.props.workingTemplate.template_json.pricing_rules_amazon_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			repriceAmount: this.props.workingTemplate.template_json.pricing_rules_amazon_adjustment_adjustment_amount_on_competing_offer,
			repriceType: repriceType,
			repriceUpDown: this.props.workingTemplate.template_json.pricing_rules_amazon_adjustment_static_method_adjust_up_if_true_down_if_false ? "above" : "below",
		});
	}

  toggleRepriceAmountType = () => {
    const currentType = this.state.repriceAmountType;
    this.setState({
      repriceAmountType: currentType === '$' ? '%' : '$'
    });
  }

  toggleRepriceType = (type) => {
	  var data = {};
	  this.setState({ repriceType: type });
	  if(type === 'regular'){
		data = {
			pricing_rules_use_regular_repricing_strategy_against_amazon: true,
		}
	  }
	  if(type === 'custom'){
		data = {
			pricing_rules_use_regular_repricing_strategy_against_amazon: false,
		}
	  }
	  this.props.workingTemplateUpdateJson({ data: data })
  }

	updateRepriceUpDown = (e) => {
		this.setState({ repriceUpDown: e.target.value });
		this.props.workingTemplateUpdateJson(
			{
				data: {
					pricing_rules_amazon_adjustment_static_method_adjust_up_if_true_down_if_false: e.target.value === "above" ? true : false,
				}
			})
	}

	updateRepriceAmount = (amount) => {
		this.setState({ repriceAmount: amount });
	}

  toggleRepriceAmountType = () => {
    const currentType = this.state.repriceAmountType;
    this.setState({
      repriceAmountType: currentType === '$' ? '%' : '$'
    });
  }

  render() {
    return (
      <Fragment>
        <FormGroup>
          <FormGroup check className="custom-check radio">
            <Label check>
				<Input
					type="radio"
					name="sc2_repriceType"
					checked={this.state.repriceType === 'regular'}
					onChange={() => this.toggleRepriceType('regular')}
				/> <span>Use regular repricing strategy</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="sc2_repriceType"
					checked={this.state.repriceType === 'custom'}
					onChange={() => this.toggleRepriceType('custom')}
				/> <span>Use custom repricing strategy</span>
            </Label>
          </FormGroup>
        </FormGroup>

        <Collapse isOpen={this.state.repriceType === 'custom'}>
          <div className="custom-repricing">
            <FormGroup className="d-flex align-items-center mb-0">
              <span>Againts Amazon offer, reprice </span>
              <InputAmountType
                amountType={this.state.repriceAmountType}
                toggle={this.toggleRepriceAmountType}
                className="ml-2"
				updateAmount={this.updateRepriceAmount}
				updateTemplateJson={this.props.workingTemplateUpdateJson}
				dataInTemplateJson="pricing_rules_amazon_adjustment_adjustment_amount_on_competing_offer"
				typeDataInTemplateJson="pricing_rules_amazon_adjustment_fix_price_adjustment_if_true_percentage_if_false"
				value={this.state.repriceAmount ? this.state.repriceAmount.toString() : ""}
				min={0}
              />
			  <Input
				  type="select"
				  className="mx-2 w-auto"
				  onChange={e => this.updateRepriceUpDown(e)}
				  value={this.state.repriceUpDown}
			  >
				<option value="above">above</option>
				<option value="below">below</option>
              </Input>
              <span>the competing offer.</span>
							<AlertTooltip
								target="alert_sc2_repriceType"
								message={this.props.templateWarning}
								range={[21]}
							/>
            </FormGroup>
          </div>
        </Collapse>

      </Fragment>
    )
  }
}

export default connect(
  state => ({
	  //workingTemplate: state.Repricer.get("workingTemplate"),
  }),
  {
	workingTemplateUpdateJson,
  }
)(Scenario2);
