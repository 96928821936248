import { all, takeEvery, takeLatest, put, fork, call, select } from "redux-saga/effects";

import {
  getShipmentsListAPI,
  getShipmentAPI,
  addShipmentBoxAPI,
  updateCurrentShipmentBoxAPI,
  moveItemToBox,
  searchProductAPI,
  addItemToBox,
  updateItemCount,
  delItemFromBox,
  shipmentFileProcess,
  shipmentBoxItemWeightUpdate
} from "../../helpers/box_contents_apis";
import actions from "./actions";
import appActions from "../app/actions";
import printerActions from "../../redux/print_service/actions";
import {
  printerDefaultsSelector
} from "../settings/selector";
import { logError } from "../../helpers/utility";
import { setFocusToAmazonSearchBar } from "../../helpers/batch/utility";
import { suggestShipment } from "../../helpers/box_contents/shipmentSelectorAlgorithm";
import { getASINForInput, getCachedSearchResult } from "../../helpers/box_contents/utility";
import moment from "moment";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';
import { openInNewTab } from '../../helpers/utility';

export function* shipmentListRequest() {
  yield takeLatest(actions.LOAD_SHIPMENT_LIST_REQUEST, function*() {
    try {
      const shipmentListResponse = yield call(getShipmentsListAPI);
      yield put(actions.fetchShipmentListSuccess(shipmentListResponse.data));
    } catch (error) {
      yield put(actions.fetchShipmentListError());
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "LOAD_SHIPMENT_LIST_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* selectShipmentRequest() {
  yield takeEvery(actions.SELECT_SHIPMENT_REQUEST, function*({
    selectedShipment,
  }) {
    try {
      const shipmentResponse = yield call(
        getShipmentAPI,
        selectedShipment.ShipmentId
      );
      if (shipmentResponse.data.error) {
        // do something
      } else {
        yield put(actions.selectShipmentSuccess(
          shipmentResponse.data,
          selectedShipment,
          selectedShipment.ShipmentId
        ));
        if (!shipmentResponse.data.boxes || shipmentResponse.data.boxes.length === 0) {
          yield put(actions.addShipmentBox(
            selectedShipment.ShipmentId
          ));
        }
      }
    } catch (error) {
      yield put(actions.selectShipmentError(selectedShipment.ShipmentId));
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "SELECT_SHIPMENT_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* addShipmentBoxRequest() {
  yield takeLatest(actions.ADD_SHIPMENT_BOX_REQUEST, function*({ shipmentId }) {
    try {
      const shipmentBoxResponse = yield call(addShipmentBoxAPI, { shipmentId });
      if(!shipmentBoxResponse.data.error) {
        yield put(actions.addShipmentBoxSuccess(shipmentBoxResponse.data.box, shipmentId));
        yield call(setFocusToAmazonSearchBar);
      }
      else {
        yield put(actions.addShipmentBoxError());
        yield put(appActions.apiCallFailed("Error! Fetching api error"));
      }
    } catch (error) {
      yield put(actions.addShipmentBoxError());
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "ADD_SHIPMENT_BOX_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* updateCurrentShipmentBoxRequest() {
  yield takeLatest(actions.UPDATE_SELECTED_SHIPMENT_BOX, function*({ shipmentId, box }) {
    try {
      const shipmentCurrentBoxResponse = yield call(updateCurrentShipmentBoxAPI, { shipmentId, boxId: box.id });
      if(shipmentCurrentBoxResponse.data.error) {
        yield put(appActions.apiCallFailed("Error! Fetching api error"));
      } else {
        yield call(setFocusToAmazonSearchBar);
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "UPDATE_SELECTED_SHIPMENT_BOX_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* moveItemRequest() {
  yield takeLatest(actions.MOVE_ITEM_REQUEST, function*({
    itemId,
    boxId,
    qty,
    selectedShipment,
  }) {
    try {
      let response = yield call(moveItemToBox, boxId, itemId, qty);
      let {boxes} = response.data;
      yield put(actions.moveItemSuccess(selectedShipment.ShipmentId, boxes));
    } catch (error) {
      yield put(actions.moveItemError());
      yield put(appActions.apiCallFailed("Error! Failed to move item."));
      logError(error, {
        tags: {
          exceptionType: "MOVE_ITEM_REQUEST_ERROR"
        }
      });
    }
  });
}


export function* searchProductRequest() {
  yield takeLatest(actions.SEARCH_PRODUCT_REQUEST, function*({
    query,
    selectedShipmentsData,
  }) {
    try {
      let modifiedQueryForAPI = getASINForInput(query, selectedShipmentsData) || query;
      let searchResults = null;
      let cachedSearch = getCachedSearchResult(modifiedQueryForAPI, selectedShipmentsData);
      if (!cachedSearch) {
        const searchResultResponse = yield call(searchProductAPI, modifiedQueryForAPI);
        searchResults = searchResultResponse.data.results
      } else {
        searchResults = [cachedSearch];
      }
      const algorithmOutput = suggestShipment(query, selectedShipmentsData, searchResults);
      if(algorithmOutput.shipmentId) {
        let index = selectedShipmentsData.findIndex((shipment) => {
          return shipment.selectedShipment.ShipmentId === algorithmOutput.shipmentId;
        })
		  let selectedShipmentData = selectedShipmentsData[index];
        yield put(
          actions.addProductRequest(
            selectedShipmentData,
            algorithmOutput.selectedSKU,
            algorithmOutput.selectedSearchResult,
          )
        );
        yield put(actions.searchProductSuccess());
        yield put(actions.clearQuery());
      }
      else if (algorithmOutput.error) {
        // yield put(appActions.apiCallFailed(algorithmOutput.error));
        let items = [];
        let type = null;
        if(algorithmOutput.matchingASINs) {
          let results = searchResults;
          type = "ASIN";
          results.forEach((result) => {
            if(algorithmOutput.matchingASINs.filter(asin => result.ASIN === asin).length > 0) {
              items.push(result);
            }
          })
        }
        else if (algorithmOutput.matchingSKUs) {
          type = "SKU";
          items = algorithmOutput.matchingSKUs;
          yield put(actions.chooseSearchItem(algorithmOutput.selectedSearchResult));
        } else {
          yield put(appActions.apiCallFailed(algorithmOutput.error));
        }
        yield put(actions.searchProductError(items, type));
        yield put(actions.clearQuery());
      } else if (!searchResults) {
        yield put(appActions.apiCallFailed("No product found"));
        yield put(actions.searchProductError());
        yield put(actions.clearQuery());
      }
    } catch (error) {
      yield put(actions.searchProductError());
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "SEARCH_PRODUCT_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* addProductRequest() {
  yield takeLatest(actions.ADD_PRODUCT_REQUEST, function*({
    selectedShipmentData,
    sku,
    result,
  }) {
    try {
      yield put(actions.addingProductToBoxReset());
      const itemInInbound = selectedShipmentData.inboundShipmentItems.find(item => item.SellerSKU === sku);

      if (!itemInInbound) {
        yield put(actions.addProductError());
        yield put(appActions.apiCallFailed("Error! Failed to find SKU in inbound shipment items."));
        logError(
          new Error("Failed to find SKU in selectedShipment even though our algorithm suggested this shipment."), {
          tags: {
            exceptionType: "ADD_PRODUCT_REQUEST_ERROR"
          }
        });
        return;
      }

      let itemId = null;
      let itemCountInCurrentBox = 0;
      selectedShipmentData.boxes.forEach(box => {
        box.items.forEach(item => {
          if (item.SellerSKU === sku && box.id === selectedShipmentData.currentBox.id) {
            if (box.id === selectedShipmentData.currentBox.id) {
              itemId = item.BoxContentItemId;
              itemCountInCurrentBox = item.QuantityShippedInBox;
            }
          }
        });
      });

      let updatedQty = itemCountInCurrentBox + 1;
      if (itemId !== null) {
        const { data } = yield call(updateItemCount, itemId, updatedQty);
        if (!data.error) {
          yield put(actions.updateProductSuccess(selectedShipmentData.currentBox.id, itemId, {
            QuantityShippedInBox: updatedQty,
            UpdatedAt: moment.utc().format("YYYY-MM-DDTHH:mm:ssssss")
          }));
        } else {
          throw new Error()
        }
      } else {
        const { data } = yield call(addItemToBox, selectedShipmentData.currentBox.id, {
          SellerSKU: itemInInbound.SellerSKU,
          FulfillmentNetworkSKU: itemInInbound.FulfillmentNetworkSKU,
          ASIN: itemInInbound.ASIN,
          QuantityShippedInBox: 1,
          ProductSearchResult: result,
          UpdatedAt: moment.utc().format("YYYY-MM-DDTHH:mm:ssssss")
        });
        if (!data.error) {
          yield put(actions.addProductSuccess(selectedShipmentData.currentBox.id, data.item));
        } else {
          throw new Error()
        }
      }
      const printerDefaults = yield select(printerDefaultsSelector);
      if (printerDefaults && printerDefaults.print_while_scanning_box_contents) {
        yield put(printerActions.print({
          fnsku: itemInInbound.FulfillmentNetworkSKU,
          name: result.name
        }, 1))
      }
      yield put(appActions.apiCallUserSoundNotificationSuccess());

    } catch (error) {
      yield put(actions.addProductError());
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "ADD_PRODUCT_REQUEST_ERROR"
        }
      });
    }
  });
}

export function* delProductRequest() {
  yield takeLatest(actions.DEL_PRODUCT_REQUEST, function*({
    selectedShipmentData,
    sku,
    boxId,
    qty
  }) {
    try {
      let itemId = null;
      let itemCountInCurrentBox = 0;
      selectedShipmentData.boxes.forEach(box => {
        box.items.forEach(item => {
          if (item.SellerSKU === sku && box.id === boxId) {
            itemId = item.BoxContentItemId;
            itemCountInCurrentBox = item.QuantityShippedInBox;
          }
        });
      });

      let updatedQty = itemCountInCurrentBox - qty;
      if (itemId !== null && itemCountInCurrentBox > 0) {
        if (updatedQty === 0) {
          const { data } = yield call(delItemFromBox, boxId, itemId);

          if (!data.error) {
            yield put(actions.delItemFromBoxSuccess(boxId, itemId));
          }
        } else {
          const { data } = yield call(updateItemCount, itemId, updatedQty);

          if (!data.error) {
            yield put(actions.updateProductSuccess(boxId, itemId, {
              QuantityShippedInBox: updatedQty,
              UpdatedAt: moment.utc().format("YYYY-MM-DDTHH:mm:ssssss")
            }));
          }
        }
      } else {
        throw new Error()
      }
      yield put(appActions.apiCallUserSoundNotificationSuccess());
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(error, {
        tags: {
          exceptionType: "DEL_PRODUCT_REQUEST"
        }
      });
    }
  });
}

export function* printShipmentItem() {
  yield takeLatest(actions.PRINT_SHIPMENT_ITEM, function*({
    item,
  }) {
    try {
      yield put(printerActions.print({
        fnsku: item.FulfillmentNetworkSKU,
        name: item.ProductSearchResult.name
      }, 1))
      yield put(appActions.apiCallUserSoundNotificationSuccess());
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Failed to print item."));
      logError(error, {
        tags: {
          exceptionType: "PRINT_SHIPMENT_ITEM_ERROR"
        }
      });
    }
  });
}

export function* boxPrintManifest() {
    yield takeLatest('BOX_PRINT_MANIFEST', function* (payload) {
			const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.box_contents.manifest_print, data);
					if (response.status === 200 && !response.data.error) {
						openInNewTab(response.data.pdf);
						yield put(actions.boxPrintManifestSuccess(response.data))
					} else {
						yield put(actions.boxPrintManifestFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Box contents api error"));
					yield put(actions.boxPrintManifestFailed());
          logError(error, {
            tags: {
              exceptionType: "BOX_PRINT_MANIFEST_ERROR"
            }
          });
        }
    })
}

export function* selectShipmentJob() {
    yield takeLatest('SELECT_SHIPMENT_REQUEST_JOB', function* (payload) {
			const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.box_contents.shipment_job, data);
					if (response.status === 200 && !response.data.error) {
            yield put(actions.selectShipmentJobSuccess(response.data))
					} else {
						yield put(actions.selectShipmentJobError());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Box contents api error"));
					yield put(actions.selectShipmentJobError());
          logError(error, {
            tags: {
              exceptionType: "SELECT_SHIPMENT_REQUEST_JOB"
            }
          });
        }
    })
}

export function* trackShipmentJob() {
  yield takeLatest('TRACK_SHIPMENT_REQUEST_JOB', function* (payload) {
    const { data } = payload;
    let params = { job_id: data.ShipmentId }
    let finished = false;
    try {
        let response = yield call(backendAPICall, 'get', apiEndpoints.box_contents.shipment_job_progress, params);
        if (response.status === 200 && !response.data.error) {
          if(!response.data.finished){
            yield put(actions.trackShipmentJobSuccess(response.data))
          } else {
            response.data.job_id = "";
            yield put(actions.trackShipmentJobSuccess(response.data))
            finished = true;
          }
        }
    } catch (error) {
        logError(error, {
          tags: {
            exceptionType: "TRACK_SHIPMENT_REQUEST_JOB"
          }
        });
    }
    //process if it is finished
    if(finished){
      params = { shipmentId: data.ShipmentId }
      try {
          let response = yield call(backendAPICall, 'get', apiEndpoints.box_contents.shipment_job, params);
          if (response.status === 200 && !response.data.error) {
            yield put(actions.selectShipmentSuccess(
              response.data,
              data,
              data.ShipmentId
            ));
            if (!response.data.boxes || response.data.boxes.length === 0) {
              yield put(actions.addShipmentBox(data.ShipmentId));
            }
          } else {
            yield put(actions.trackShipmentJobError());
          }
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Box contents api error"));
          yield put(actions.selectShipmentError(data.ShipmentId));
          yield put(actions.trackShipmentJobError());
          logError(error, {
            tags: {
              exceptionType: "TRACK_SHIPMENT_REQUEST_JOB"
            }
          });
        }
    }
  })
}

export function* updateItemCountShipment() {
    yield takeLatest('UPDATE_ITEM_COUNT_SHIPMENT', function* (payload) {
			const { data } = payload;
      try {
					const response = yield call(updateItemCount, data.itemId, data.updatedQty);
					if (response.status === 200 && !response.error) {
            yield put(actions.updateProductSuccess(
              data.boxId,
              data.itemId,
              {
                QuantityShippedInBox: data.updatedQty,
                UpdatedAt: moment.utc().format("YYYY-MM-DDTHH:mm:ssssss")
              }
            ));
					} else {
						yield put(actions.updateItemCountShipmentError());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Box contents api error"));
					yield put(actions.updateItemCountShipmentError());
          logError(error, {
            tags: {
              exceptionType: "UPDATE_ITEM_COUNT_SHIPMENT"
            }
          });
        }
    })
}

export function* uploadFileShipment() {
  yield takeLatest('UPLOAD_FILE_SHIPMENT', function* (payload) {
    const data  = payload.data;
    try {
        const response = yield call(shipmentFileProcess, data);
        if (response.status === 200 && !response.error) {
          if(response.data.error){
            yield put(appActions.apiCallFailed(response.data.error));
          }
          if(response.data.data){
            window.location.href = response.data.data;
          }
          yield put(actions.uploadFileShipmentSuccess(
            data,
            {
              data: data,
            }
          ));
        } else {
          yield put(actions.uploadFileShipmentError());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! shipment file process api error"));
        yield put(actions.uploadFileShipmentError());
        logError(error, {
          tags: {
            exceptionType: "UPLOAD_FILE_SHIPMENT"
          }
        });
      }
  })
}

export function* updateBoxItemWeight() {
  yield takeLatest('SHIPMENT_BOX_ITEM_WEIGHT', function* (payload) {
    const data  = payload.data;
    try {
        const response = yield call(shipmentBoxItemWeightUpdate, data);
        if (response.status === 200 && !response.error) {
          yield put(actions.shipmentBoxItemWeightSuccess(response.data));
        } else {
          yield put(appActions.apiCallFailed(response.data.error));
          yield put(actions.shipmentBoxItemWeightError());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed(`Error! ${error}`));
        yield put(actions.shipmentBoxItemWeightError());
        logError(error, {
          tags: {
            exceptionType: "SHIPMENT_BOX_ITEM_WEIGHT_ERROR"
          }
        });
      }
  })
}

export function* addingProductToBoxReset() {
  yield takeLatest(actions.ADDING_PRODUCT_TO_BOX_SUCCESS_RESET, function*(data) {
    yield put(actions.addingProductToBoxResetSuccess());
  });
}

export default function* rootSaga() {
  yield all([
    fork(shipmentListRequest),
    fork(selectShipmentRequest),
    fork(addShipmentBoxRequest),
    fork(moveItemRequest),
    fork(searchProductRequest),
    fork(addProductRequest),
    fork(updateCurrentShipmentBoxRequest),
    fork(printShipmentItem),
    fork(delProductRequest),
		fork(boxPrintManifest),
    fork(selectShipmentJob),
    fork(trackShipmentJob),
    fork(updateItemCountShipment),
    fork(uploadFileShipment),
    fork(updateBoxItemWeight),
    fork(addingProductToBoxReset),
  ]);
}
