const salesActions = {

	GET_SUMMARY: 'GET_SUMMARY',
	GET_SUMMARY_SUCCESS: 'GET_SUMMARY_SUCCESS',
	GET_SUMMARY_FAILED: 'GET_SUMMARY_FAILED',
	GET_CATEGORY_SALES: 'GET_CATEGORY_SALES',
	GET_CATEGORY_SALES_SUCCESS: 'GET_CATEGORY_SALES_SUCCESS',
	GET_CATEGORY_SALES_FAILED: 'GET_CATEGORY_SALES_FAILED',
	GET_PRODUCT_SALES: 'GET_PRODUCT_SALES',
	GET_PRODUCT_SALES_SUCCESS: 'GET_PRODUCT_SALES_SUCCESS',
	GET_PRODUCT_SALES_FAILED: 'GET_PRODUCT_SALES_FAILED',
	GET_SALES_CHART: 'GET_SALES_CHART',
	GET_SALES_CHART_SUCCESS: 'GET_SALES_CHART_SUCCESS',
	GET_SALES_CHART_FAILED: 'GET_SALES_CHART_FAILED',
	GET_SUMMARY_EXPORT: 'GET_SUMMARY_EXPORT',
	GET_SUMMARY_EXPORT_SUCCESS: 'GET_SUMMARY_EXPORT_SUCCESS',
	GET_SUMMARY_EXPORT_FAILED: 'GET_SUMMARY_EXPORT_FAILED',
	GET_PRODUCT_EXPORT: 'GET_PRODUCT_EXPORT',
	GET_PRODUCT_EXPORT_SUCCESS: 'GET_PRODUCT_EXPORT_SUCCESS',
	GET_PRODUCT_EXPORT_FAILED: 'GET_PRODUCT_EXPORT_FAILED',

	getProductExport: (data) => ({
		type: salesActions.GET_PRODUCT_EXPORT,
		data
	}),
	getProductExportSuccess: (data) => ({
		type: salesActions.GET_PRODUCT_EXPORT_SUCCESS,
		data
	}),
	getProductExportFailed: (data) => ({
		type: salesActions.GET_PRODUCT_EXPORT_FAILED,
		data
	}),

	getSummaryExport: (data) => ({
		type: salesActions.GET_SUMMARY_EXPORT,
		data
	}),
	getSummaryExportSuccess: (data) => ({
		type: salesActions.GET_SUMMARY_EXPORT_SUCCESS,
		data
	}),
	getSummaryExportFailed: (data) => ({
		type: salesActions.GET_SUMMARY_EXPORT_FAILED,
		data
	}),

	getSalesChart: (data) => ({
		type: salesActions.GET_SALES_CHART,
		data
	}),
	getSalesChartSuccess: (data) => ({
		type: salesActions.GET_SALES_CHART_SUCCESS,
		data
	}),
	getSalesChartFailed: (data) => ({
		type: salesActions.GET_SALES_CHART_FAILED,
		data
	}),

	getProductSales: (data) => ({
		type: salesActions.GET_PRODUCT_SALES,
		data
	}),
	getProductSalesSuccess: (data) => ({
		type: salesActions.GET_PRODUCT_SALES_SUCCESS,
		data
	}),
	getProductSalesFailed: (data) => ({
		type: salesActions.GET_PRODUCT_SALES_FAILED,
		data
	}),

	getCategorySales: (data) => ({
		type: salesActions.GET_CATEGORY_SALES,
		data
	}),
	getCategorySalesSuccess: (data) => ({
		type: salesActions.GET_CATEGORY_SALES_SUCCESS,
		data
	}),
	getCategorySalesFailed: (data) => ({
		type: salesActions.GET_CATEGORY_SALES_FAILED,
		data
	}),

	getSummary: (data) => ({
		type: salesActions.GET_SUMMARY,
		data
	}),
	getSummarySuccess: (data) => ({
		type: salesActions.GET_SUMMARY_SUCCESS,
		data
	}),
	getSummaryFailed: (data) => ({
		type: salesActions.GET_SUMMARY_FAILED,
		data
	}),

};

export default salesActions;
