import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import IconArrowRight from 'react-icons/lib/md/chevron-right';
import { digitСonversion } from '../../../../../../../helpers/utility';
import './style.css';
import moment from 'moment';
import PropTypes from "prop-types";
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import IconPrint from 'react-icons/lib/md/print';
import accountingActions from "../../../../../../../redux/accounting/actions";
import exportActions from '../../../../../../../redux/export/actions';

const { reportExportPLEmail } = accountingActions;
const { exportPL } = exportActions;

const init_categories = {
  'Amazon Orders': {
		'Units Sold (FBA)': {	as_unit: true, data_point: 'fbaOrderCount',	},
		'Order Revenue (FBA)': { as_unit: false, data_point: 'fbaOrderRevenue',	},
		'Units Sold (MF)': { as_unit: true,	data_point: 'mfOrderCount',	},
		'Order Revenue (MF)': {	as_unit: false,	data_point: 'mfOrderRevenue',	},
	},
  'eBay Orders': {
		'Units Sold': {	as_unit: true, data_point: 'ebayOrderCount',	},
		'Order Revenue': { as_unit: false, data_point: 'ebayOrderRevenue',	},
	},
	'FBA Inventory Credits': {
		'Lost - Warehouse': { as_unit: false,	data_point: 'lostWarehouseTotal',	},
		'Lost - Inbound': {	as_unit: false,	data_point: 'lostInboundTotal', },
		'Damaged - Warehouse': { as_unit: false, data_point: 'damagedWarehouseTotal',	},
		'Customer Returns': {	as_unit: false,	data_point: 'customerReturnTotal', },
		'Other Adjustments': { as_unit: false, data_point: 'otherTotal', },
	},
	'Refunds': {
		'Refund Cost': {	as_unit: false, data_point: 'totalRefundCost', },
		'Refunded Units': {	as_unit: true, data_point: 'totalRefunds', },
	},
	'Miscellaneous': {
		'Shipping Credits': { as_unit: false,	data_point: 'shippingCredits',	},
		'Shipping Credit Refunds': { as_unit: false,	data_point: 'shippingCreditRefunds',	},
		'Gift Wrap Credits': { as_unit: false,	data_point: 'giftWrapCredits',	},
		'Gift Wrap Credits Refunds': { as_unit: false,	data_point: 'giftWrapCreditRefunds',	},
		'Promotional Rebates': { as_unit: false,	data_point: 'promotionalRebates',	},
		'Promotional Rebates Refunds': { as_unit: false,	data_point: 'promotionalRebateRefunds',	},
		'A-to-Z Guarantee Claims': { as_unit: false,	data_point: 'guaranteeClaimsTotal',	},
		'Chargebacks': { as_unit: false,	data_point: 'chargebackTotal',	},
	},
	'Cost of Goods Sold': {
		'Total cost of goods sold': { as_unit: false,	data_point: 'cogs',	},
		//'Misc expenses': { as_unit: false,	data_point: 'miscExpenses',	},
	},
	'Selling Fees': {
		'FBA Selling Fees': { as_unit: false,	data_point: 'fbaSellingFees',	},
		'Misc expenses': { as_unit: false,	data_point: 'mfSellingFees',	},
		'Selling Fee Refunds': { as_unit: false,	data_point: 'sellingFeeRefunds',	},
	},
	'Transaction Fees': {
		'FBA Transaction Fees': { as_unit: false,	data_point: 'fbaTransactionFees',	},
		'FBA Transaction Fees Refunds': { as_unit: false,	data_point: 'fbaTransactionFeeRefunds',	},
		'Other Transaction Fees': { as_unit: false,	data_point: 'otherTransactionFees',	},
		'Other Transaction Fees Refunds': { as_unit: false,	data_point: 'otherTransactionFeeRefunds',	},
	},
	'Shipping Fees': {
		'FBA Inbound Shipping': { as_unit: false,	data_point: 'inboundShippingFees',	},
		'Merchant Fulfilled Label Purchases': { as_unit: false,	data_point: 'mfLabelPurchases',	},
		'Merchant Fulfilled Label Refunds': { as_unit: false,	data_point: 'mfLabelRefunds',	},
	},
	'Inventory Fees': {
		'Inventory Storage Fees': { as_unit: false,	data_point: 'inventoryStorageFees',	},
		'Long-Term Storage Fees': { as_unit: false,	data_point: 'longTermStorageFees',	},
		'FBA Disposal Fees': { as_unit: false, data_point: 'fbaDisposalFees',	},
		'FBA Return Fees': { as_unit: false, data_point: 'fbaReturnFees',	},
		'FBA Labeling Prep Fees': { as_unit: false,	data_point: 'fbaLabelingPrepFees',	},
		'Other Inventory Fees': { as_unit: false,	data_point: 'otherInventoryFees',	},
	},
	'Service Fees': {
		'Subscription Fees': { as_unit: false,	data_point: 'subscriptionFees',	},
		'Other Service Fees': { as_unit: false,	data_point: 'otherServiceFees',	},
	},
};

class ProfitLossTable extends Component {
  state = {
		data: [],
		emailPL: "",
		reportYear: null
  }

	prepareData = (data) => {
    let res = [];

		let categories = init_categories;
		let misc_categories = {};
		let ebay_categories = {};
		Object.keys(this.props.misc_expenses_mapping).forEach(misc => {
			misc_categories[this.props.misc_expenses_mapping[misc].name] = { as_unit: false,  data_point: misc, };
		});
		Object.keys(this.props.ebay_expenses_mapping).forEach(ebay => {
			ebay_categories[this.props.ebay_expenses_mapping[ebay].name] = { as_unit: false,  data_point: ebay, };
		});
		categories['Misc expenses'] = misc_categories;
		categories['eBay expenses'] = ebay_categories;

		let categories_data = {};
		Object.keys(data).forEach(date => {
			Object.keys(data[date]).forEach(data_key => {
				categories_data[data_key] = {
					january: 0,
					february: 0,
					march: 0,
					april: 0,
					may: 0,
					june: 0,
					july: 0,
					august: 0,
					september: 0,
					october: 0,
					november: 0,
					december: 0
				}
			})
		})
		const months = ['january', 'february', 'march', 'april', 'may', 'june',
			'july', 'august', 'september', 'october', 'november', 'december'];
		let row = {}
		let child = {};

		//get month data
		Object.keys(data).forEach(date => {
			Object.keys(data[date]).forEach(data_key => {
				if((data_key in categories_data) && (data_key in data[date])){
					let date_check = moment(date, 'YYYY-MM-DD');
					let date_month = date_check.format('MMMM').toLowerCase();
					categories_data[data_key][date_month] = categories_data[data_key][date_month] + data[date][data_key];
				}
			});
		});

		Object.keys(categories).forEach(category => {
			row = {category: category, children: []};
			months.forEach(month => {row[month] = 0});
			Object.keys(categories[category]).forEach(child_cat => {
				child = {name: child_cat, as_unit: categories[category][child_cat].as_unit}
				months.forEach(month => {
					let data_point = categories[category][child_cat].data_point;
					if(data_point in categories_data){
						child[month] = categories_data[data_point][month];
						if(categories[category][child_cat].as_unit === false){
							row[month] = row[month] + categories_data[data_point][month];
						}
					}
				});
				row.children.push(child);
			});
			res.push(row);
		});



		return res;
	}

  componentDidMount() {
    this.setState({
      data: this.prepareData(this.props.data)
    })
    if(!this.state.reportYear) {
      const dates = Object.keys(this.props.data)
      this.setState({ reportYear: moment(dates[0]).year() })
    }
  }

	sendPLEmail = () => {
		const data = {
			data: this.state.data,
			email: this.state.emailPL,
		}
		this.props.reportExportPLEmail(data);
	}

	updateState = (key, val) => {
		this.setState({ [key]: val });
	}

	printPL = () => {
		this.props.exportPL({data: this.state.data});
	}

  render() {
    const {
      data
    } = this.state;

    const {
			no_data,
			pl_email_sending,
			printPLExpenses,
    } = this.props;

    const currency_code = this.props.internationalization_config.currency_code;

    let columns = [
      {
        dataField: 'category',
        text: 'Category',
        footer: 'Profit or Loss?',
        classes: 'col-category text-left',
        formatter: (cell, row) => {
          if(row.children !== undefined) {
            return <React.Fragment><IconArrowRight size="20" className="icon-arrow-right" /> <span>{cell}</span></React.Fragment>
          }
          return cell;
        }
      }
    ];

    /* Originally intended for dynamic months shown, not fixed 12-months.
    /* Create dynamic columns for months as we can show 3, 6 or 12 montsh
     */
    if(data.length > 0) {
      Object.keys(data[0]).map(property => {
        if(property !== 'category' && property !== 'children') {
          columns.push({
            dataField: property,
            text: property,
            footer: ( columnData ) => {
              let total = columnData.reduce( (acc, item) => acc + item, 0 );
              let totalMoney = digitСonversion(total, "currency", currency_code);
              return (
                <span
                  className={total < 0 ? "text-danger" : total > 0 ? "text-success" : ""}
                >
                  {totalMoney}
                </span>);
            },
            headerStyle: { textTransform: 'capitalize' },
            formatter: (cell, row) => (
              digitСonversion(cell, "currency", currency_code)
            ),
            classes: (cell, row) => {
              const reportDate = moment(`${property}, ${this.state.reportYear}`)
              const colFuture = moment().isSameOrBefore(reportDate, 'month')
              return colFuture ? 'col-future' : ''
            },
            headerClasses: (col) => {
              const reportDate = moment(`${property}, ${this.state.reportYear}`)
              const colFuture = moment().isSameOrBefore(reportDate, 'month')
              return colFuture ? 'col-future' : ''
            },
            footerClasses: (col) => {
              const reportDate = moment(`${property}, ${this.state.reportYear}`)
              const colFuture = moment().isSameOrBefore(reportDate, 'month')
              return colFuture ? 'col-future' : ''
            }
          });
        }
        return null;
      });
    }

    /* Originally intended for conditional expandable rows.
     * Need to go through this way for conditional expandable rows because nonExpandable prop on expandRow isn't working
     * All rows are actually expanded, but conditonal class make them invisible for row without children
     */
    const expandRow = {
      renderer: row => (
        row.children !== undefined && (
          <table className="table table-subrow">
            <tbody>
              {
                row.children.map((item, idx) => (
                  <tr key={`expand-row-${idx}`}>
                    {Object.keys(item).map(property => {
                      const reportDate = moment(`${property}, ${this.state.reportYear}`)
                      const colFuture = moment().isSameOrBefore(reportDate, 'month')
                      if(property !== "as_unit") {
                        return (
                          <td key={property} className={colFuture ? 'col-future' : ''}>
                            {
                              property === "name" ? item[property] :
                              item["as_unit"] ? item[property] : digitСonversion(item[property], "currency", currency_code)
                            }
                          </td>
                        )
                      }
                      return null;
                    }
                    )}
                  </tr>
                ))
              }
            </tbody>
          </table>
        )
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
        if(isExpand) {
          e.currentTarget.classList.add('expanded');
        } else {
          e.currentTarget.classList.remove('expanded');
        }
      }
    }

    const rowClasses = (row, rowIndex) => {
      let classes = null;

      if (row.children === undefined) {
        classes = 'no-children';
      } else {
        classes = 'has-children'
      }

      if(rowIndex <= 2) {
        classes += " row-credit";
      } else {
        classes += " row-cost";
      }

      return classes;
    };

    return (
      <div className={`demoable-content-wrapper ${no_data ? "demo" : ""}`}>
        <div className="d-flex justify-content-end mb-3">
					<Button
						color="primary"
						className="mr-2"
						disabled={pl_email_sending || printPLExpenses}
						onClick={e => this.printPL()}
					>
            <IconPrint /> Print
          </Button>
          <InputGroup style={{ width: "20rem" }}>
						<Input
							type="email"
							placeholder="Email P&amp;L to CPA"
							value={this.state.emailPL}
							onChange={e => {this.updateState('emailPL', e.target.value)}}
							disabled={pl_email_sending || printPLExpenses}
						/>
            <InputGroupAddon addonType="append">
							<Button
								color="primary"
								onClick={e => this.sendPLEmail()}
								disabled={pl_email_sending || printPLExpenses}
							>Send</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="demoable-content">
          <div className="pl-table-quarters">
            <div><span>Q1</span></div>
            <div><span>Q2</span></div>
            <div><span>Q3</span></div>
            <div><span>Q4</span></div>
          </div>
          <BootstrapTable
            keyField="category"
            columns={columns}
            data={data}
            expandRow={expandRow}
            rowClasses={rowClasses}
            classes="table-profit-loss mb-5"
            hover
          />
        </div>
        <div className="demo-overlay">
          <p>Currently we don't have enough data to show</p>
        </div>
      </div>
    );
  }
}

ProfitLossTable.propTypes = {
	data: PropTypes.array.isRequired,
	misc_expenses_mapping: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
		pl_email_sending: state.Accounting.get("pl_email_sending"),
		printPLExpenses: state.Export.get("printPLExpenses"),
  }),
	{
		reportExportPLEmail,
		exportPL,
	}
)(ProfitLossTable);
