import React from "react";
import ViewContent from "./view-content";

export default () => (
  <div className="view history">
    <header className="view-header">
      <h1 className="page-title">Batch History</h1>
    </header>
    <div className="view-content">
      <ViewContent />
    </div>
  </div>
);
