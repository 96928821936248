import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Card, CardBody, CardHeader, Badge
} from 'reactstrap';
import Repricing from './Repricing';
//import Sales from './Sales';
//import BuyBox from './BuyBox';
import { IoIosGear, IoSocialYoutube } from 'react-icons/lib/io';
import "./style.css";
import repricerActions from '../../../../redux/repricer/actions';

const { getRepricerDashboardRepricing } = repricerActions;

class RepricerDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1
    };
  }

  tabChanged = (tabId) => {
    this.setState({
      activeTab: tabId
    });
  }

  render() {
    return (
      <Card className="card-tab">
        <CardHeader className="d-flex justify-content-between">
          <Nav tabs>
            <NavItem>
							<NavLink
								className={this.state.activeTab === 1 ? 'active' : ''}
								onClick={() => this.tabChanged(1)}
							>
								Repricing&nbsp;
							</NavLink>
            </NavItem>
            <NavItem>
							<NavLink
								className={this.state.activeTab === 2 ? 'active' : ''}
								onClick={() => this.tabChanged(2)}
							>
								Sales&nbsp;
								<Badge color="warning" pill>SOON</Badge>
							</NavLink>
            </NavItem>
            <NavItem>
							<NavLink
								className={this.state.activeTab === 3 ? 'active' : ''}
								onClick={() => this.tabChanged(3)}
							>
								BuyBox&nbsp;
								<Badge color="warning" pill>SOON</Badge>
							</NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={1}>
							<Repricing
								getRepricerDashboardRepricing={this.props.getRepricerDashboardRepricing}
								dashboard_repricing={this.props.dashboard_repricing}
							/>
            </TabPane>
            <TabPane tabId={2}>
							{/*<Sales />*/}
							COMING SOON
            </TabPane>
            <TabPane tabId={3}>
							{/*<BuyBox />*/}
							COMING SOON
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  state => ({
	 dashboard_repricing : state.Repricer.get("dashboard_repricing"),
  }),
  {
		getRepricerDashboardRepricing,
  }
)(RepricerDashboard);
