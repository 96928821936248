import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
// import { stateToHTML } from "draft-js-export-html";
import { EditorState, convertFromRaw, convertToRaw, } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from "moment";

class SopDetails extends Component {
  render() {
    const {
      id,
      procedure_data,
    } = this.props;

    var description = EditorState.createEmpty();
    if(procedure_data.description){
      description = EditorState.createWithContent(convertFromRaw(JSON.parse(procedure_data.description)));
    }

    return (
      <div className="sop-details">
        <NavLink
          className="d-inline-block mb-4"
          to="/dashboard/sop"
        >
          <span className="icon icon-arrow-left bg-primary"></span> Back to all procedures
        </NavLink>
        <h2 className="title">
          <span>{procedure_data.title}</span>
          {/*
          <Button color="light" size="sm"><FaFileWordO /> Download SOP</Button>
          */}
        </h2>
        <div
          className="sop-fulltext"
          dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(description.getCurrentContent()))}}
        >
        </div>
        <div className="sop-info">
          <span className="text-muted">Created: <strong>{moment(procedure_data.created_at * 1000).format("MM/DD/YYYY")}</strong></span>
          <span className="text-muted">Updated: <strong>{moment(procedure_data.updated_at * 1000).format("MM/DD/YYYY")}</strong></span>
          <span className="text-muted">Category: <strong>{procedure_data?.category_data?.name}</strong></span>
          <span>
            <span className="text-muted mr-1">Tags:</span>
            <ul className="sop-tag-list">
              {
                procedure_data.tags_data
                  ? procedure_data.tags_data.map(item => {
                      return <li key={`tag-${item.id}`}><Badge className="sop-tag">#{item.name}</Badge></li>
                    })
                  : null
              }
            </ul>
          </span>
        </div>
        {/*
        <h3 className="h5 mt-4">Related Procedures</h3>
        <Nav vertical>
          <NavItem>
            <NavLink to="/">Creating Shipping Templates</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/">Creating Custom SKUs</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/">Adding or Editing Suppliers</NavLink>
          </NavItem>
        </Nav>
        */}
        <NavLink
          className="d-inline-block mt-4"
          to="/dashboard/sop"
        >
          <span className="icon icon-arrow-left bg-primary"></span> Back to all procedures
        </NavLink>
      </div>
    )
  }
}

export default SopDetails;
