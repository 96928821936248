import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";

const { userEnableSMS } = adminActions;

/**
 * Update user SMS activated
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUserSmsActivate extends Component {
	state = {
		userUN: '',
	}

	handleChange = (key, value) => {
		this.setState({	[key]: value });
	}

	activateSMS = () => {
    const data = {
      username: this.state.userUN,
      is_sms_confirmed: true,
		}
    this.setState({ userUN: ""})
		this.props.userEnableSMS(data);
	}

  render() {
    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>User SMS manual activation</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={e => this.handleChange('userUN', e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								color="success"
                onClick={() => this.activateSMS()}
								disabled={!this.state.userUN || this.props.user_enabling_sms}
							>
							  SMS Activate
							</Button>
							<br />
							<Label for="un" style={{ color: "red" }}>
								{this.props.user_enabling_sms_message ? this.props.user_enabling_sms_message : null}
							</Label>
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	</div>
    );
  }
}

export default connect(
  state => {
		return {
			user_enabling_sms: state.Admin.get("user_enabling_sms"),
			user_enabling_sms_message: state.Admin.get("user_enabling_sms_message"),
    };
  },
  {
    userEnableSMS,
  }
)(AdminUserSmsActivate);
