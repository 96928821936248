import styled from 'styled-components';
import { Modal } from 'reactstrap';

export const StyledModal = styled(Modal)`
  .modal-header {
    position: absolute;
    right: 0;
    border-bottom: 0;
    z-index: 9;
  }

  .modal-body {
    padding: 0;
  }

  .message-group {
    padding-bottom: .3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid rgba(133, 140, 130, .15);
  }

  .message-label {
    display: inline-block;
    border-bottom-right-radius: 25px;
    margin-bottom: 1.3rem;
    margin-left: -1rem;
    padding: .6rem 1rem .6rem .6rem;
    font-weight: 600;
    color: #858C82;
    background-color: rgba(206, 206, 206, .22);
  }

  .message {
    font-weight: 500;
  }

  .articles {
    margin-top: 2.5rem;

    .heading {
      font-size: 1rem;
      color: #858C82;
    }
  }

  .modal-footer {
    border-top: 0;
  }
`;
