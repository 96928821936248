import { Map } from 'immutable'
import actions from './actions'

const initState = new Map({
  apiCallSuccess: false,
  apiCallError: false,
  apiCallUserError: false,
  apiCallUserWarning: false,
  apiCallUserSuccess: false,
  apiCallUserSoundNotificationSuccess: false,
  apiErrorMessage: '',
  apiSuccessMessage: '',
  userErrorMessage: '',
  userSuccessMessage: '',
  userWarningMessage: '',
  user_pageview_capture_working: false,
  error_message_display: false,
  error_mesages_list: null,
})

export default function appReducer(state = initState, action) {
  switch (action.type) {

    case actions.APP_PUSH_ERROR_MESSAGE_LIST:
      return state
        .set('error_message_display', true)
        .set('error_mesages_list', null)
    case actions.APP_PUSH_ERROR_MESSAGE_LIST_SUCCESS:
      return state
        .set('error_message_display', true)
        .set('error_mesages_list', [...action.data?.data])
    case actions.APP_PUSH_ERROR_MESSAGE_LIST_ERROR:
      return state
        .set('error_message_display', false)
        .set('error_mesages_list', null)

    case actions.APP_ERROR_MESSAGE_LIST_CLOSE:
      return state
        .set('error_message_display', false)
        .set('error_mesages_list', null)
    case actions.APP_ERROR_MESSAGE_LIST_CLOSE_SUCCESS:
      return state
        .set('error_message_display', false)
        .set('error_mesages_list', null)

    case actions.APP_USER_PAGEVIEW_CAPTURE:
      return state
        .set('user_pageview_capture_working', true)
    case actions.APP_USER_PAGEVIEW_CAPTURE_SUCESS:
      return state
        .set('user_pageview_capture_working', false)
    case actions.APP_USER_PAGEVIEW_CAPTURE_ERROR:
      return state
        .set('user_pageview_capture_working', false)

    case actions.API_CALL_SUCCESS:
      return state
        .set('apiCallError', false)
        .set('apiCallSuccess', true)
        .set('apiSuccessMessage', action.successMessage)

    case actions.API_CALL_FAILED:
      return state
        .set('apiCallSuccess', false)
        .set('apiCallError', true)
        .set('apiErrorMessage', action.errorMessage)

    case actions.API_CALL_USER_ERROR:
      return state
        .set('apiCallUserError', true)
        .set('userErrorMessage', action.userErrorMessage)

    case actions.API_CALL_USER_WARNING:
      return state
        .set('apiCallUserMessage', true)
        .set('userWarningMessage', action.userWarningMessage)

    case actions.API_CALL_USER_SUCCESS:
      return state
        .set('apiCallUserSuccess', true)
        .set('userSuccessMessage', action.userSuccessMessage)

    case actions.API_CALL_USER_SOUND_NOTIFICATION_SUCCESS:
      return state
        .set('apiCallUserSoundNotificationSuccess', true)

    case actions.RESET_API_CALL_STATUS:
      return state.merge(initState)

    default:
      return state
  }
}
