import React, { Component } from "react";
import { Col, Row, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import ReactTable from "react-table";
import { digitСonversion } from "../../../../helpers/utility";
import {ebay_generate_item_url} from '../../../../helpers/ebay/utility';

const {
  getEbayUserSales,
  getEbayUserSalesDetails,
} = adminActions;

class AdminUserSales extends Component {
	state = {
		usernameOpen: null
	}

	detailsModalOpen = (username) => {
    this.props.getEbayUserSalesDetails({ username: username });
    this.setState({ usernameOpen: username })
  }

  detailsModalClose = () => {
    this.setState({ usernameOpen: null })
  }

	generateColumns = () => {
		let tableColumns = [
			{
				id: "username",
				accessor: "username",
				Header: <span>Username</span>,
				headerClassName: "text-left",
			},
			{
				id: "email",
				accessor: "email",
				Header: <span>Email</span>,
				headerClassName: "text-left",
				width: 260
			},
			{
				id: "manually_listed_count",
				accessor: "manually_listed_count",
				Header: <span>Direct Listed</span>,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.manually_listed_count, "decimal")
			},
			{
				id: "cross_listed_count",
				accessor: "cross_listed_count",
				Header: <span>Cross Listed</span>,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.cross_listed_count, "decimal")
			},
			{
				id: "items_sold_count",
				accessor: "items_sold_count",
				Header: <span>Sales Count</span>,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.items_sold_count, "decimal")
			},
			{
				id: "average_rank",
				accessor: "average_rank",
				Header: <span>Avg. Rank</span>,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.average_rank, "decimal")
			},
			{
				id: "sales",
				accessor: "sales",
				Header: <span>Sales</span>,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.sales, "currency")
			},
			{
        id: "details",
        accessor: "username",
        Header: <span>Details</span>,
        headerClassName: "text-left",
				width: 160,
        Cell: props => (
          <Button
            color="primary"
						size="sm"
            onClick={e => this.detailsModalOpen(props.original.username)}
          >View Products Sold</Button>
        )
      },
		]
		return tableColumns;
	}

	generateDetailColumns = () => {
		let detailsColumns = [
			{
				id: "title",
				accessor: "title",
				Header: "Product",
			},
			{
				id: "sku",
				accessor: "sku",
				Header: "SKU",
			},
			{
				id: "listing_id",
				accessor: "listing_url",
				Header: "Ebay Item ID",
				Cell: props => (
					<a
						href={ebay_generate_item_url(props.original.listing_id)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.original.listing_id}
					</a>
				)
			},
			{
				id: "price",
				accessor: "price",
				Header: "Price",
				Cell: props => digitСonversion(props.original.price, "currency")
			},
			{
				id: "qty",
				accessor: "qty",
				Header: "Qty",
			},
		]
		return detailsColumns;
	}

  componentDidMount(){
    this.props.getEbayUserSales();
  }

  render() {
		const usernameOpen = this.state.usernameOpen;
		let emailOpen = "";
		if(usernameOpen !== null) {
			emailOpen = this.props.admin_ebay_user_sales.find(item => item.username === usernameOpen).email;
		}

    return (
		<React.Fragment>
			<Row className="mt-4">
				<Col xs="12">
					<ReactTable
						columns={this.generateColumns()}
						data={this.props.admin_ebay_user_sales}
						disabled={this.props.admin_ebay_user_sales_loading}
						minRows={0}
            defaultPageSize={100}
					/>
				</Col>
			</Row>
			<Modal isOpen={usernameOpen} style={{ maxWidth: "90rem" }}>
				<ModalHeader toggle={this.detailsModalClose}>
					Sales details <span className="text-primary">[ {usernameOpen}, {emailOpen} ]</span>
				</ModalHeader>
				<ModalBody>
					<ReactTable
						columns={this.generateDetailColumns()}
						data={this.props.admin_user_sales_details}
						minRows={0}
					/>
					</ModalBody>
				</Modal>
		</React.Fragment>
    );
  }
}

export default connect(
  state => {
		return {
			admin_ebay_user_sales: state.Admin.get("admin_ebay_user_sales"),
			admin_ebay_user_sales_loading: state.Admin.get("admin_ebay_user_sales_loading"),
			admin_user_sales_details: state.Admin.get("admin_user_sales_details"),
    };
  },
  {
		getEbayUserSales,
    getEbayUserSalesDetails,
  }
)(AdminUserSales);
