import styled from "styled-components";

const TooltipBody = styled.span`
  margin-left: 10px;
  display: inline-flex;
  align-items: center;

  .help-icon {
    cursor: pointer;
  }
`;
 export {
  TooltipBody
}; 