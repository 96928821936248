import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardTitle, Row, Col, Button
} from 'reactstrap';
import IconPrinter from 'react-icons/lib/md/print';
import { digitСonversion } from '../../../../../../helpers/utility';
import ReactToPrint from "react-to-print-advanced";
import PrintTemplate from "react-print";
import "./style.css";

class IncomeStatementTable extends Component {

  formatNumber = (value) => {
    return digitСonversion(value, "currency", this.props.internationalization_config.currency_code);
  }

  render() {
    const {
      data,
  		printPreview,
      incurred_expenses,
    } = this.props;

    let misc_expenses = {};
    if(data.miscExpenses && incurred_expenses){
      incurred_expenses.forEach(item => {
        if(!misc_expenses[item?.expense_type]){
          misc_expenses[item?.expense_type] = 0;
        }
        misc_expenses[item?.expense_type] += item.amount;
      })
    }

    const firstReportPart = (
      <table className="table table-striped table-income-statement">
        <tbody>
          <tr className="row-credit">
            <td colSpan="2"><h4 className="title">Orders</h4></td>
          </tr>
          <tr className="row-credit">
            <td>Units Sold (FBA)</td>
            <td>{data.fbaOrderCount} Units</td>
          </tr>
          <tr className="row-credit">
            <td>Order Revenue (FBA)</td>
            <td>{this.formatNumber(data.fbaOrderRevenue)}</td>
          </tr>
          <tr className="row-credit">
            <td>Units Sold (MF)</td>
            <td>{data.mfOrderCount} Units</td>
          </tr>
          <tr className="row-credit">
            <td>Order Revenue (MF)</td>
            <td>{this.formatNumber(data.mfOrderRevenue)}</td>
          </tr>
          <tr className="row-credit">
            <td>eBay Revenue</td>
            <td>{this.formatNumber(data.ebayOrders)}</td>
          </tr>
          <tr className="row-credit">
            <td>eBay Units Sold</td>
            <td>{data.ebayOrdersCount} Units</td>
          </tr>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Refunds</h4></td>
          </tr>
          <tr className="row-cost">
            <td>Refunded Units</td>
            <td>{data.totalRefunds} Units</td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total Refund Amount</td>
            <td>{this.formatNumber(data.totalRefundCost)}</td>
          </tr>
          <tr className="row-credit">
            <td colSpan="2"><h4 className="title">FBA Inventory Credits</h4></td>
          </tr>
          <tr className="row-credit">
            <td>Lost - Warehouse</td>
            <td>{this.formatNumber(data.lostWarehouseTotal)} <small>({data.lostWarehouseQty} Units)</small></td>
          </tr>
          <tr className="row-credit">
            <td>Lost - Inbound</td>
            <td>{this.formatNumber(data.lostInboundTotal)} <small>({data.lostInboundQty} Units)</small></td>
          </tr>
          <tr className="row-credit">
            <td>Damaged - Warehouse</td>
            <td>{this.formatNumber(data.damagedWarehouseTotal)} <small>({data.damagedWarehouseQty} Units)</small></td>
          </tr>
          <tr className="row-credit">
            <td>Customer Returns</td>
            <td>{this.formatNumber(data.customerReturnTotal)} <small>({data.customerReturnQty} Units)</small></td>
          </tr>
          <tr className="row-credit">
            <td>Other Adjustments</td>
            <td>{this.formatNumber(data.otherTotal)} <small>({data.otherQty} Units)</small></td>
          </tr>
          <tr className="font-weight-bold row-credit">
            <td>Total FBA Inventory Credits</td>
            <td>{this.formatNumber(data.totalInventoryCredits)} <small>({data.otherQty} Units)</small></td>
          </tr>
          <tr className="row-credit">
            <td colSpan="2"><h4 className="title">Miscellaneous</h4></td>
          </tr>
          <tr className="row-credit">
            <td>Shipping Credits</td>
            <td>{this.formatNumber(data.shippingCredits)}</td>
          </tr>
          <tr className="row-credit">
            <td>Shipping Credit Refunds</td>
            <td>{this.formatNumber(data.shippingCreditRefunds)}</td>
          </tr>
          <tr className="row-credit">
            <td>Gift Wrap Credits</td>
            <td>{this.formatNumber(data.giftWrapCredits)}</td>
          </tr>
          <tr className="row-credit">
            <td>Gift Wrap Credit Refunds</td>
            <td>{this.formatNumber(data.giftWrapCreditRefunds)}</td>
          </tr>
          <tr className="row-credit">
            <td>Promotional Rebates</td>
            <td>{this.formatNumber(data.promotionalRebates)}</td>
          </tr>
          <tr className="row-credit">
            <td>Promotional Rebate Refunds</td>
            <td>{this.formatNumber(data.promotionalRebateRefunds)}</td>
          </tr>
          <tr className="row-credit">
            <td>A-to-z Guarantee Claims</td>
            <td>{this.formatNumber(data.guaranteeClaimsTotal)}</td>
          </tr>
          <tr className="row-credit">
            <td>Chargebacks</td>
            <td>{this.formatNumber(data.chargebackTotal)}</td>
          </tr>
          <tr className="font-weight-bold row-credit">
            <td>Total Miscellaneous</td>
            <td>{this.formatNumber(data.totalMiscellaneous)}</td>
          </tr>
          <tr className="total row-credit">
            <td>GROSS INCOME</td>
            <td className="text-success">{this.formatNumber(data.grossIncome + data.ebayOrders)}</td>
          </tr>
        </tbody>
      </table>
    );

    const secondReportPart = (
      <table className="table table-striped table-income-statement">
        <tbody>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Cost of Goods Sold</h4></td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total cost of goods sold</td>
            <td>{this.formatNumber(data.cogs)}</td>
          </tr>
					{data.miscExpenses ? (
            <React.Fragment>
              <tr className="row-cost">
                <td colSpan="2"><h4 className="title">Misc expenses</h4></td>
              </tr>
              {Object.keys(misc_expenses).map(key => {
                return (
                  <tr className="row-cost" key={`misc-key-row-${key}`}>
                    <td>{key}</td>
                    <td>{this.formatNumber(misc_expenses[key])}</td>
                  </tr>
                )
              })}
              <tr className="font-weight-bold row-cost">
                <td>Total misc expenses</td>
                <td>{this.formatNumber(data.miscExpenses)}</td>
              </tr>
            </React.Fragment>
					) : (null)}
					{data.ebayExpenses ? (
							<tr className="font-weight-bold row-cost">
								<td>eBay expenses</td>
								<td>{this.formatNumber(data.ebayExpenses)}</td>
					</tr>
					) : (null)}
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Selling Fees</h4></td>
          </tr>
          <tr className="row-cost">
            <td>FBA Selling Fees</td>
            <td>{this.formatNumber(data.fbaSellingFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>MF Selling Fees</td>
            <td>{this.formatNumber(data.mfSellingFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Selling Fee Refunds</td>
            <td>{this.formatNumber(data.sellingFeeRefunds)}</td>
          </tr>

          <tr className="font-weight-bold row-cost">
            <td>Total Selling Fees</td>
            <td>{this.formatNumber(data.totalSellingFees)}</td>
          </tr>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Transaction Fees</h4></td>
          </tr>
          <tr className="row-cost">
            <td>FBA Transaction Fees</td>
            <td>{this.formatNumber(data.fbaTransactionFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>FBA Transaction Fees Refunds</td>
            <td>{this.formatNumber(data.fbaTransactionFeeRefunds)}</td>
          </tr>
          <tr className="row-cost">
            <td>Other Transaction Fees</td>
            <td>{this.formatNumber(data.otherTransactionFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Other Transaction Fees Refunds</td>
            <td>{this.formatNumber(data.otherTransactionFeeRefunds)}</td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total Transaction Fees</td>
            <td>{this.formatNumber(data.totalTransactionFees)}</td>
          </tr>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Shipping Fees</h4></td>
          </tr>
          <tr className="row-cost">
            <td>FBA Inbound Shipping</td>
            <td>{this.formatNumber(data.inboundShippingFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Merchant Fulfilled Label Purchases</td>
            <td>{this.formatNumber(data.mfLabelPurchases)}</td>
          </tr>
          <tr className="row-cost">
            <td>Merchant Fulfilled Label Refunds</td>
            <td>{this.formatNumber(data.mfLabelRefunds)}</td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total Shipping Fees</td>
            <td>{this.formatNumber(data.totalShippingFees)}</td>
          </tr>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Inventory Fees</h4></td>
          </tr>
          <tr className="row-cost">
            <td>Inventory Storage Fees</td>
            <td>{this.formatNumber(data.inventoryStorageFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Long-Term Storage Fees</td>
            <td>{this.formatNumber(data.longTermStorageFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>FBA Disposal Fees</td>
            <td>{this.formatNumber(data.fbaDisposalFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>FBA Return Fees</td>
            <td>{this.formatNumber(data.fbaReturnFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>FBA Labeling Prep Fees</td>
            <td>{this.formatNumber(data.fbaLabelingPrepFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Other Inventory Fees</td>
            <td>{this.formatNumber(data.otherInventoryFees)}</td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total Inventory Fees</td>
            <td>{this.formatNumber(data.totalInventoryFees)}</td>
          </tr>
          <tr className="row-cost">
            <td colSpan="2"><h4 className="title">Service Fees</h4></td>
          </tr>
          <tr className="row-cost">
            <td>Subscription Fees</td>
            <td>{this.formatNumber(data.subscriptionFees)}</td>
          </tr>
          <tr className="row-cost">
            <td>Other Service Fees</td>
            <td>{this.formatNumber(data.otherServiceFees)}</td>
          </tr>
          <tr className="font-weight-bold row-cost">
            <td>Total Service Fees</td>
            <td>{this.formatNumber(data.totalServiceFees)}</td>
          </tr>
          <tr className="total row-cost">
            <td>TOTAL EXPENSES</td>
			<td className="text-danger">
				{this.formatNumber(data.totalExpenses + data.miscExpenses + data.ebayExpenses )}
			</td>
          </tr>
          <tr className="total">
            <td>NET PROFIT</td>
						<td className={(data.netProfit + data.miscExpenses + data.ebayExpenses + data.ebayOrders) > 0 ? "text-success" : "text-danger"} >
							{this.formatNumber(data.netProfit + data.miscExpenses + data.ebayExpenses + data.ebayOrders)}
						</td>
          </tr>
        </tbody>
      </table>
    );

    if (printPreview) {
      return (
        <Row className="income-statement-print">
          <Col xs="6">
            {firstReportPart}
          </Col>
          <Col xs="6">
            {secondReportPart}
          </Col>
        </Row>
      )
    }
    return (
      <Row>
        <Col lg="6">
          {firstReportPart}
        </Col>
        <Col lg="6">
          {secondReportPart}
        </Col>
      </Row>
    );
  }
}

class IncomeStatement extends Component {

	UNSAFE_componentWillMount(){
		/*
		if(this.props.data){
			//var startDate = new Date(this.props.data.dateStart).toISOString();
			//var endDate = new Date(this.props.data.dateEnd).toISOString();
			/*
			const data = {
				startDate: startDate,
				endDate: endDate,
			}
			this.props.expensesGetForPeriod(data);
		}
		*/
	}

  render() {
    const {
  		data,
      incurred_expenses,
    } = this.props;

    return (
      <Fragment>
        <Card>
          <CardBody>
            <CardTitle className="text-center mb-5" style={{ lineHeight: 2 }}>
              Profit & Loss Statement ({data.dateStart} - {data.dateEnd})
              <ReactToPrint
                trigger={() => (
                  <a>
                    <Button color="secondary" className="float-right"><IconPrinter /> PRINT</Button>
                  </a>
                )}
                content={() => this.componentRef}
                />
            </CardTitle>
			<IncomeStatementTable
				data={data}
        incurred_expenses={incurred_expenses}
				internationalization_config={this.props.internationalization_config}
			/>
          </CardBody>
        </Card>
        <PrintTemplate>
          <div
            className="printable-accounting"
            ref={el => (this.componentRef = el)}
          >
            <h5 className="text-center card-title">
              Profit & Loss Statement ({data.dateStart} - {data.dateEnd})
            </h5>
			<IncomeStatementTable
				data={data}
				printPreview={true}
				internationalization_config={this.props.internationalization_config}
			/>
          </div>
        </PrintTemplate>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
  }
)(IncomeStatement);
