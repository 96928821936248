import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "../../../style.css";
import repricerActions from '../../../../../redux/repricer/actions';
import DeleteDialog from '../../../../../shared/components/dialogs/DeleteDialog';
import {
    momentDateTimeToLocalFormatConversion
} from '../../../../../helpers/utility';
import TemplateBreakdownSummary from './TemplateBreakdownSummary';
import IconCheck from "react-icons/lib/io/checkmark";
import './style.css';
import moment from "moment";

const {
	userLoadWorkingSchedule,
	userDeleteWorkingSchedule,
} = repricerActions;

class SchedulesTable extends Component {

	state = {
		deleteModalOpen: false,
		scheduleToDelete: null,
		templateModalOpenIdx: null
	}

	deleteModalClose = () => {
		this.setState({ deleteModalOpen: false });
	}

	editClick = (schedule_id) => {
		this.props.userLoadWorkingSchedule(schedule_id);
		this.props.toggleEditor();
	}

	deleteClick = (schedule_id) => {
		this.setState({
			deleteModalOpen: true,
			scheduleToDelete: schedule_id,
		});
	}

	deleteScheduleAction = () => {
		this.props.userDeleteWorkingSchedule(
			{
				data: { schedule_id: this.state.scheduleToDelete }
			}
		);
		this.deleteModalClose();
	}

	formatReadyTime = (tm) => {
		const m = moment.unix(tm).format("mm");
		let h = parseInt(tm / 60 / 60, 10).toString();
		if(h.length === 1){
			h = '0' + h;
		}
		return h + ':' + m;
	}

	showTemplateModal = (idx) => {
    this.setState({ templateModalOpenIdx: idx })
  }

  hideTemplateModal = () => {
    this.setState({ templateModalOpenIdx: null })
  }

  render() {
    let tableColumns = [
      {
        id: "template_name",
        Header: "Template",
        accessor: "template_name",
				headerClassName: 'text-left',
		  	Cell: props => (
					<React.Fragment>
						<Button color="link" onClick={() => this.showTemplateModal(props.index)}>{props.original.template_name}</Button>
						<Modal isOpen={this.state.templateModalOpenIdx === props.index} size="lg" toggle={this.hideTemplateModal}>
							<ModalHeader toggle={this.hideTemplateModal} className="py-0" style={{ border: 0 }}>&nbsp;</ModalHeader>
							<ModalBody>
							<TemplateBreakdownSummary
								viewDetail={this.props.toggleView}
								id={props.original.id}
								templateId={props.original.template_id}
							/>
							</ModalBody>
						</Modal>
					</React.Fragment>
		  	),
			},
			{
				id: "status",
				Header: "Status",
				accessor: "status",
				headerClassName: 'text-left',
				Cell: props => (
					<div className={props.original.time_to_execute === 0 ? "status-countdown ready" : "status-countdown"}>
						<span className="text">{props.original.time_to_execute === 0 ? "READY" : this.formatReadyTime(props.original.time_to_execute)}</span>
						<span className="addon"><span className={`icon ${props.original.time_to_execute === 0 ? 'icon-check' : 'icon-clock'}`}></span></span>
					</div>
				)
			},
      {
        id: "start_timestamp",
        Header: "Created",
        accessor: "start_timestamp",
				headerClassName: 'text-left',
				className: "template-breakdown-title",
		  	Cell: props => (
				momentDateTimeToLocalFormatConversion(props.original.start_timestamp, true)
		  	),
      },
      {
        id: "interval",
        Header: "Interval",
        accessor: "interval",
        headerClassName: 'text-left',
		  	Cell: props => (
					props.original.interval === 60 ? "Hourly" : "Daily"
		  	),
      },
      {
        id: "is_active",
        Header: "Active",
        accessor: "is_active",
        headerClassName: 'text-left',
		  	Cell: props => (
					props.original.is_active ? <span className="badge badge-success">YES</span> : <span className="badge badge-default">NO</span>
		  	),
        sortable: false,
      },
      /*
      {
        id: "should_use_bootstrap",
        Header: "Use bootstrap",
        accessor: "should_use_bootstrap",
        className: "text-center",
        style: { alignSelf: "center" },
		  	Cell: props => (
					props.original.should_use_bootstrap ? "YES" : "NO"
		  	),
      },
      */
      {
        id: "actions",
        Header: "Actions",
        headerClassName: 'text-left',
  		  accessor: "id",
        Cell: props => (
			  <React.Fragment>
				  <Button
					  color="link"
					  onClick={e => this.editClick(props.original.id)}
				  ><span className="icon icon-edit"></span></Button>
				  &nbsp;&nbsp;
				  <Button
					  color="link"
					  onClick={e => this.deleteClick(props.original.id)}
				  ><span className="icon icon-trash bg-danger"></span></Button>
			  </React.Fragment>
        ),
        sortable: false,
      },
    ];


    return (
      <React.Fragment>
        <ReactTable
					data={this.props.schedules} // should default to []
					columns={tableColumns}
					minRows={0}
        />
				<DeleteDialog
					isOpen={this.state.deleteModalOpen}
					close={this.deleteModalClose}
					deleteItem={this.deleteScheduleAction}
					dialogTitle="Are you sure you want to delete this schedule?"
					dialogText="You will loose reprice schedule and future reprice events on this schedule will be stopped."
					confirmText="Yes, delete schedule"
				/>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
  }),
	{
		userLoadWorkingSchedule,
		userDeleteWorkingSchedule,
	}
)(SchedulesTable);
