import React, { Component } from "react";
import {
	Input, InputGroup, InputGroupAddon, InputGroupText,
	Modal, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Button, FormText, ModalHeader
} from "reactstrap";

class PreuploadModal extends Component {
  render() {
    const {
      isOpen,
      onClose,
			onUpload,
			pricingType,
			onPricingChange,
			onBuyCostChange,
			buyCostValue,
    } = this.props;

    return (
      <Modal isOpen={isOpen} centered>
        <ModalHeader>Before you upload</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Average Buy Cost</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
									<Input
										type="number"
										onChange={(e) => onBuyCostChange(e.target.value)}
										value={buyCostValue}
									/>
                </InputGroup>
                <FormText>Enter average buy cost of products in upload</FormText>
              </FormGroup>
            </Col>
            <Col md={12}>
              <Label>Pricing display</Label>
              <FormGroup check className="custom-check radio">
                <Label check>
                  <Input
                    type="radio"
										name="showPricing"
										checked={pricingType === 'FBA' ? true : false}
										onChange={() => onPricingChange('FBA')}
                  /> <span>Show only FBA Pricing</span>
                </Label>
              </FormGroup>
              <FormGroup check className="custom-check radio">
                <Label check>
                  <Input
                    type="radio"
                    name="showPricing"
										checked={pricingType === 'MF' ? true : false}
										onChange={() => onPricingChange('MF')}
                  /> <span>Show only MF Pricing</span>
                </Label>
              </FormGroup>
              <FormGroup check className="custom-check radio">
                <Label check>
                  <Input
                    type="radio"
                    name="showPricing"
										checked={pricingType !== 'MF' && pricingType !== 'FBA' ? true : false}
										onChange={() => onPricingChange('BOTH')}
                  /> <span>Show both FBA/MF Pricing</span>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="success" onClick={onUpload}>Upload your product list (.csv)</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PreuploadModal;
