import moment from "moment";
import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  getChartDataAPI,
	getDashboardDataAPI,
	getDashboardSalesExpensesAPI
} from '../../helpers/dashboard_apis'

import actions from './actions'
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* FetchChartDataRequest() {
  yield takeEvery('FETCH_CHART_DATA', function* (payload) {
    try {
      let {
        chartType,
        dateTime,
        period
      } = payload;

      dateTime = dateTime ? dateTime : moment.utc().toISOString()

      let data = {
        stat: chartType,
        timestamp: dateTime,
        period
      }
      yield put(actions.fetchChartDataStatus(chartType, false));
      const chart_data = yield call(getChartDataAPI, data);
      if (chart_data.status === 200 && chart_data.data) {
        yield put(actions.fetchChartDataSuccess(chartType, chart_data.data.result));
        yield put(actions.fetchChartDataStatus(chartType, true));
      }
    } catch (error) {
      const { chartType } = payload;
      yield put(appActions.apiCallFailed("Error! Fetch chart data api error"));
      yield put(actions.fetchChartDataStatus(chartType, false));
      logError(error, {
        tags: {
          exceptionType: "FETCH_CHART_DATA_ERROR"
        }
      });
    }
  })
}

export function* FetchDashboardDataRequest() {
  yield takeEvery('FETCH_DASHBOARD_DATA', function* (payload) {
    try {
      let {
        stat,
        key,
        minTimestamp,
        maxTimestamp
      } = payload;
      let data = {
        stat,
        minTimestamp,
        maxTimestamp
      }
      yield put(actions.fetchDashboardDataStatus(key, false));
      const dashboard_data = yield call(getDashboardDataAPI, data);
      if (dashboard_data.status === 200 && dashboard_data.data) {
        yield put(actions.fetchDashboardDataSuccess(key, dashboard_data.data[stat]));
        yield put(actions.fetchDashboardDataStatus(key, true));
      }
    } catch (error) {
      const { key } = payload;
      yield put(appActions.apiCallFailed("Error! Fetch dashboard data api error"));
      yield put(actions.fetchDashboardDataStatus(key, false));
      logError(error, {
        tags: {
          exceptionType: "FETCH_DASHBOARD_DATA_ERROR"
        }
      });
    }
  })
}

export function* getSalesExpensesData() {
  yield takeLatest('GET_SALES_STATS', function* (payload) {
    try {
      const response = yield call(getDashboardSalesExpensesAPI)
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getSalesStatsSuccess(response.data))
      } else {
        yield put(actions.getSalesStatsFailure());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetch dashboard sales data api error"));
      yield put(actions.getSalesStatsFailure());
      logError(error, {
        tags: {
          exceptionType: "FETCH_DASHBOARD_SALES_DATA_ERROR"
        }
      });
    }
  })
}

export function* getDashboardPeriodData() {
    yield takeLatest('GET_DASHBOARD_PERIOD_DATA', function* (payload) {
      const { data } = payload;
      try {
  			const response = yield call(backendAPICall, 'get', apiEndpoints.dashboard.data_period, data);
        if(response.status === 200 && !response.data.error) {
          yield put(actions.getDashboardPeriodDataSuccess(response.data.data))
			  } else {
				  yield put(actions.getDashboardPeriodDataFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch dashboard data period api error" + error));
		    yield put(actions.getDashboardPeriodDataFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_DASHBOARD_PERIOD_DATA_ERROR"
          }
        });
      }
    })
}

export function* searchHelp() {
    yield takeLatest('SEARCH_HELP', function* (payload) {
      const { data } = payload;
      try {
  			const response = yield call(backendAPICall, 'get', apiEndpoints.search.help, data);
        if(response.status === 200 && !response.data.error) {
          yield put(actions.searchHelpSuccess(response.data.data))
			  } else {
				  yield put(actions.searchHelpFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch search help data api error" + error));
		    yield put(actions.searchHelpFailed());
        logError(error, {
          tags: {
            exceptionType: "SEARCH_HELP_ERROR"
          }
        });
      }
    })
}

export function* searchHelpSingle() {
    yield takeLatest('SEARCH_HELP_SINGLE', function* (payload) {
      const { data } = payload;
      try {
  			const response = yield call(backendAPICall, 'get', apiEndpoints.search.help_single, data);
        if(response.status === 200 && !response.data.error) {
          yield put(actions.searchHelpSingleSuccess(response.data.data))
			  } else {
				  yield put(actions.searchHelpSingleFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch search help data api error" + error));
		    yield put(actions.searchHelpSingleFailed());
        logError(error, {
          tags: {
            exceptionType: "SEARCH_HELP_SINGLE_ERROR"
          }
        });
      }
    })
}

export default function* rootSaga() {
    yield all([
      fork(FetchChartDataRequest),
      fork(FetchDashboardDataRequest),
		  fork(getSalesExpensesData),
      fork(getDashboardPeriodData),
      fork(searchHelp),
      fork(searchHelpSingle),
    ])
}
