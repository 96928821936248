import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";
import SBYBChart from "./SBYBChart";
import CategoryChart from "./CategoryChart";

const {
	getSBYBStats,
	getCategoriesStatsBooks,
	getCategoriesStatsOther,
} = adminActions;

/*
const bookCategories = [
  {name: "Psychology", num_listed: 400},
  {name: "Art", num_listed: 200},
  {name: "Architecture", num_listed: 500, subCategories: [
    {name: "Building", num_listed: 100},
    {name: "Landscape", num_listed: 50},
    {name: "Reference", num_listed: 120},
    {name: "General", num_listed: 30},
    {name: "Interior Design", num_listed: 200},
  ]},
  {name: "History", num_listed: 750, subCategories: [
    {name: "General", num_listed: 150},
    {name: "Ancient", num_listed: 350},
    {name: "Military", num_listed: 250},
  ]},
  {name: "Business & Economics", num_listed: 350},
  {name: "Reference", num_listed: 220},
  {name: "Science", num_listed: 400}
];
*/

/*
const otherCategories = [
	{name: "Sports & Outdoors", num_listed: 100, subCategories: [
		{name: "Outdoor Recreation", num_listed: 30},
		{name: "Sports & Fitness", num_listed: 60},
		{name: "Fan Shop", num_listed: 10},
	]},
	{name: "Automotive", num_listed: 200, subCategories: [
		{name: "Car Care", num_listed: 125},
		{name: "Exterior Accessories", num_listed: 25},
		{name: "Interior Accessories", num_listed: 15},
		{name: "Tools & Equipment", num_listed: 35}
	]}
]
*/

/**
 * SBYB stats display
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminSBYBStats extends Component {

	componentDidMount(){
		this.props.getSBYBStats();
		this.props.getCategoriesStatsBooks();
		this.props.getCategoriesStatsOther();
	}

  render() {
		const {
			internationalization_config
		} = this.props;

    return (
			<React.Fragment>
				<Row className="mb-4">
					<Col lg="4">
						<Card>
							<CardBody>
								<CardTitle>SBYB Stats</CardTitle>
								{this.props.sbybStats ? (
									<Table striped className="acc-table-minimal acc-table-left">
										<tbody>
										<tr>
											<td>SBYB Requests #</td>
											<td className="text-right">{ digitСonversion(this.props.sbybStats["total_records"]) }</td>
										</tr>
										<tr>
											<td>Average SBYB Price</td>
											<td className="text-right">{ digitСonversion(this.props.sbybStats["avg_sbyb_price"], "currency", internationalization_config.currency_code) }</td>
										</tr>
										<tr>
											<td>Average Amazon price for used</td>
											<td className="text-right">{ digitСonversion(this.props.sbybStats["avg_amazon_price"], "currency", internationalization_config.currency_code) }</td>
										</tr>
										<tr>
											<td>Average Amazon fees</td>
											<td className="text-right">{ digitСonversion(this.props.sbybStats["avg_amazon_fees"], "currency", internationalization_config.currency_code) }</td>
										</tr>
										</tbody>
									</Table>
								) : (null)
								}
							</CardBody>
						</Card>
					</Col>
					<Col lg="8">
						<Card>
							<CardBody>
								{this.props.sbybStats ?
									<SBYBChart
										internationalizationConfig={internationalization_config}
										data={this.props.sbybStats.days}
									/>
									: null
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Card>
					<CardBody>
						<CardTitle>Books Category Listed</CardTitle>
						{this.props.bookCategories.length > 0 ?
							<CategoryChart type="bar" data={this.props.bookCategories} rootNav="Books" />
							: null
						}
					</CardBody>
				</Card>
				<Card className="mt-4">
					<CardBody>
						<CardTitle>Other Category Listed</CardTitle>
						{this.props.otherCategories.length > 0 ?
							<CategoryChart type="pie" data={this.props.otherCategories} rootNav="Other" className="chart-grid-legend" height={380} />
							: null
						}
					</CardBody>
				</Card>
			</React.Fragment>
    );
  }
}

export default connect(
  state => {
    return {
			sbybStats: state.Admin.get('sbybStats'),
			internationalization_config: state.Auth.get("internationalization_config"),
			bookCategories: state.Admin.get("bookCategories"),
			otherCategories: state.Admin.get("otherCategories"),
    };
  },
  {
		getSBYBStats,
		getCategoriesStatsBooks,
		getCategoriesStatsOther,
  }
)(AdminSBYBStats);
