import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../../redux/admin/actions";

const { userAdminSpApiActivate } = adminActions;

/**
 * Activate user for SP
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUserSpApiActivate extends Component {
	state = {
		userUN: '',
		updating: false,
	}

	handleChange = (key, value) => {
		this.setState(
			{
				[key]: value,
				updating: false,
			}
		);
	}

  activateUser = () => {
    this.props.userAdminSpApiActivate({username: this.state.userUN});
  }

  render() {
    return (
		<div>
				<CardTitle>Activate User For SPAPI</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={e => this.handleChange('userUN', e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								color="success"
                onClick={() => this.activateUser()}
								disabled={!this.state.userUN || this.props.user_updating}
							>
							  Activate
							</Button>
							<br />
							<Label for="un" style={{ color: "red" }}>
								{this.props.user_spapi_activate_message}
							</Label>

						</FormGroup>
					</Col>
				</Row>
	</div>
    );
  }
}

export default connect(
  state => {
		return {
			user_updating: state.Admin.get("user_updating"),
			user_spapi_activate_message: state.Admin.get("user_spapi_activate_message"),
    };
  },
  {
    userAdminSpApiActivate,
  }
)(AdminUserSpApiActivate);
