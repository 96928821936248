import { client } from "./apiConfig";

function getRepricerUserTemplatesAPI() {
  return client
	.get("/api/v1/template/all")
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserTemplateAPI(data) {
  return client
	.get("/api/v1/template/?templateId="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function saveRepricerUserTemplateAPI(data) {
  return client
	.post("/api/v1/template/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function updateRepricerUserTemplateAPI(data) {
  return client
	.put("/api/v1/template/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function deleteRepricerUserTemplateAPI(data) {
  return client
	.delete("/api/v1/template/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function saveRepricerUserScheduleAPI(data) {
  return client
	.post("/api/v1/schedule/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserSchedulesAPI() {
  return client
	.get("/api/v1/schedule/all")
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserScheduleAPI(data) {
  return client
	.get("/api/v1/schedule/?scheduleId="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function updateRepricerUserScheduleAPI(data) {
  return client
	.put("/api/v1/schedule/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function deleteRepricerUserScheduleAPI(data) {
  return client
	.delete("/api/v1/schedule/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserGlobalListAPI(data) {
  return client
	.get("/api/v1/global_list/?type="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function saveRepricerUserGlobalListAPI(data) {
  return client
	.post("/api/v1/global_list/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function deleteRepricerUserGlobalListAPI(data) {
  return client
	.delete("/api/v1/global_list/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function updateRepricerUserGlobalListAPI(data) {
  return client
	.put("/api/v1/global_list/", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserRepriceEventsAPI() {
  return client
	.get("/api/v1/schedule/report/reprice")
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getRepricerUserRepriceSummaryAPI(data){
  return client
		.get("/api/v1/schedule/report/reprice/summary?scheduleId="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getTemplateToSKUMappingAPI(data){
  return client
		.get("/api/v1/schedule/template_to_sku_mapping?scheduleId="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getTemplateQueryBySkusAPI(data){
  return client
		.post("/api/v1/schedule/query_by_skus", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getTemplateCheckNameAPI(data){
  return client
		.get("/api/v1/template/check/name?name="+data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function getReportInsightAPI(data){
  return client
		.post("/api/v1/schedule/report/reprice/details", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function userEnableRepricerAPI(data) {
  return client
		.post("api/v1/membership/repricer", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

function userEnableEbayAPI(data) {
  return client
		.post("api/v1/membership/ebay", data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err;
    });
}

export {
	getRepricerUserTemplatesAPI,
	getRepricerUserTemplateAPI,
	saveRepricerUserTemplateAPI,
	updateRepricerUserTemplateAPI,
	deleteRepricerUserTemplateAPI,
	saveRepricerUserScheduleAPI,
	getRepricerUserSchedulesAPI,
	getRepricerUserScheduleAPI,
	updateRepricerUserScheduleAPI,
	deleteRepricerUserScheduleAPI,
	getRepricerUserGlobalListAPI,
	saveRepricerUserGlobalListAPI,
	deleteRepricerUserGlobalListAPI,
	updateRepricerUserGlobalListAPI,
	getRepricerUserRepriceEventsAPI,
	getRepricerUserRepriceSummaryAPI,
	getTemplateToSKUMappingAPI,
	getTemplateQueryBySkusAPI,
	getTemplateCheckNameAPI,
	getReportInsightAPI,
	userEnableRepricerAPI,
	userEnableEbayAPI,
};
