import React, { Component } from "react";
import { connect } from "react-redux";
import appActions from "../../../redux/app/actions";
import {
	Card, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
	ListGroup, ListGroupItem, Button, DropdownMenu
} from "reactstrap";
import Dropzone from "react-dropzone";
import ProductList from "./ProductList";
import IconLoading from "react-icons/lib/md/cached";
import "./style.css";
import researchActions from '../../../redux/research/actions';
import PreuploadModal from "./PreuploadModal";
import { openInNewTabExtended } from "../../../helpers/utility";
import Papa from 'papaparse'
import fileDownload from 'react-file-download'

const {
	uploadResearchData,
	loadResearchData,
	loadBatchData,
	moveToBatch,
	deleteSelectedResearch,
	researchManualSearch,
	researchManualSearchResultsDisplayClose,
	researchManualSearchSelect,
	researchDataUpdate,
	researchMoveToBatchDataSet,
	researchExport,
  researchGetStatus,
} = researchActions;

const {
  apiCallFailed,
  userPageviewCapture,
} = appActions;

class Research extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showFailureMessage: false,
			error_message: "",
			preuploadModalOpen: false,
			acceptedFiles: [],
			pricingType: "BOTH",
			buyCostValue: 0,
			display_items: [],
			manual_search_string: '',
		}
	}

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Product Research"})
  }

	UNSAFE_componentWillMount(){
		this.props.researchManualSearchResultsDisplayClose();
		this.props.loadResearchData();
		if(this.props.research_data){
			this.setState({display_items: this.props.research_data});
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		this.setState({display_items: newProps.research_data});
	}

	searchManualExecute = (e) => {
		if(e.which === 13){
			const data = { query: this.state.manual_search_string	}
			this.props.researchManualSearch(data);
		}
	}

	searchManualSelectExecute = (asin) => {
		const data = { asin: asin }
		this.props.researchManualSearchSelect(data);
		this.setState({manual_search_string: ""});
	}

	updateValue = (key, val) => {
		this.setState({[key]: val});
	}

  searchItems = (search) => {
		if(!search){
			this.setState({display_items: this.props.research_data});
			return;
		}
			let products_to_display = [];
			search = search.toUpperCase();
			this.props.research_data.forEach((row) => {
				let key = [row.title, row.asin, row.category, row.price.toString()]
				key = key.join(' ').toUpperCase();
				if(key.includes(search)){
					products_to_display.push(row);
				}
			}
		);
		this.setState({display_items: products_to_display});
  }

	onDrop = (acceptedFiles) => {
		if(acceptedFiles.length > 0) {
			this.setState({
				preuploadModalOpen: true,
				acceptedFiles: acceptedFiles
			});
		} else {
			this.props.apiCallFailed("Please upload .csv file");
		}
	}

	upload = (file) => {
		const { pricingType, buyCostValue } = this.state;
		this.props.uploadResearchData(file, pricingType, buyCostValue);
		this.closeModal();
	}

	generateFileView = (file) => (
		<React.Fragment>
			<div className="py-5 my-5 text-center upload-info">
				<p className="text-muted">
					<IconLoading className="animate-spin animate-reverse" /> Uploading <strong>{file.name}</strong>...
				</p>
			</div>
		</React.Fragment>
	);

	closeModal = () => {
		this.setState({
			preuploadModalOpen: false
		});
	}

	pricingChange = (type) => {
		if(type === 'FBA'){
			this.setState({pricingType: type});
		} else if(type === 'MF'){
			this.setState({pricingType: type});
		} else {
			this.setState({pricingType: "BOTH"});
		}
	}

	buyCostChange = (val) => {
		this.setState({buyCostValue: parseFloat(val)});
	}

	onSearchEnter = (e) => {
		if(e.key === "Enter") {
			this.setState({
				searchStatus: "loading",
				searchQuery: e.target.value
			});

			setTimeout(() => {
				this.setState({
					searchStatus: "complete"
				});
			}, 1500);
		}
	}

	selectSearchResult = () => {
		this.setState({
			searchStatus: ""
		});
	}

	downloadTemplate = () => {
		const availavle_headers = ["asin", "price", "qty", "condition"];
		const data = (
			{
				fields: availavle_headers,
        data: [
          ["*** Next few rows are sample data and you would need to remove all but headers in order to produce valid file for upload"],
          ["B086PRLDCB",17.99,50,"New"],
          ["B08LZGPPBH",21.24,25,"New"],
          ["B0C5MCTPN8",49.99,95,"New"],
          ["B0824XNJSW",17.99,100,"New"],
        ]
			}
		);
		const csv = Papa.unparse(JSON.stringify(data) , {
			delimiter: ",",
			header: true,
			skipEmptyLines: false
		});
		let fn = 'ALResearchSpreadsheetTemplate.csv'
		fileDownload(csv, fn);
	}

	displayView = () => {
		const {
			preuploadModalOpen,
			acceptedFiles,
		} = this.state;

		return (
			<div className="view">
				<header className="view-header">
					<h1 className="page-title">Product Research</h1>
				</header>
				<div className="view-content view-research">
					<div className="d-flex justify-content-between align-items-center mb-3">
						<div>
							<div className="d-flex">
								<div className="btn btn-primary dropzone-container mr-3">
									<Dropzone
										className="d-inline-block"
										activeClassName="dropzone-component-active"
										onDrop={this.onDrop}
										multiple={false}
										accept="text/csv,application/vnd.ms-excel,.csv"
									>
										<span className="icon icon-16 mr-1 icon-plus bg-white" role="button"></span> Upload file
									</Dropzone>
								</div>
								<Button onClick={this.downloadTemplate}><span className="icon icon-16 mr-1 icon-csv"></span> Download template file</Button>
							</div>
						</div>
						<div style={{ minWidth: "30rem" }}>
							<div className="input-has-dropdown">
								<InputGroup style={{ minWidth: "18rem" }} className="input-timer-loading">
									<InputGroupAddon addonType="prepend">
										<InputGroupText><span className="icon icon-search"></span></InputGroupText>
									</InputGroupAddon>
									<Input
										onChange={e => this.updateValue('manual_search_string', e.target.value)}
										onKeyDown={e => this.searchManualExecute(e)}
										value={this.state.manual_search_string}
										placeholder="Scan or hand type a product to research it"
									/>
									{this.props.is_loading_manual_search && <AnimatedTimer />}
									{this.props.is_showing_selection_items &&
									<Button
										color="light"
										className="btn-clear py-2"
										onClick={e => {
											this.props.researchManualSearchResultsDisplayClose();
											this.setState({ manual_search_string: '' })
										}}
									>
										<span className="icon icon-10 icon-close icon-clear"></span>
									</Button>
									}
								</InputGroup>

								<div className={`dropdown ${this.props.is_showing_selection_items && this.props.search_results.length > 0 ? "show" : ""}`}>
									<DropdownMenu right  style={{ overflow: 'auto', maxHeight: 400 }}>
										<ListGroup className="product-list-group">
											{this.props.search_results.map(item => {
												return (
													<ListGroupItem
														tag="button"
														action onClick={e => this.searchManualSelectExecute(item.ASIN)}
														key={item.ASIN}
													>
														<div className="product-list-item">
															<img src={item.imageUrl} className="picture" alt="" />
															<strong className="title">{item.name}</strong>
														</div>
													</ListGroupItem>
												)
											})}
										</ListGroup>
									</DropdownMenu>
								</div>
							</div>
						</div>
					</div>
					<Card>
						<CardBody>
								<ProductList
									products={this.props.research_data}
									is_loading_research_data={this.props.is_loading_research_data}
									loadResearchData={this.props.loadResearchData}
									loadBatchData={this.props.loadBatchData}
									research_batch_data={this.props.research_batch_data}
									is_moving_to_batch={this.props.is_moving_to_batch}
									moveToBatch={this.props.moveToBatch}
									is_deleting_research={this.props.is_deleting_research}
									deleteSelectedResearch={this.props.deleteSelectedResearch}
									openInNewTabExtended={openInNewTabExtended}
									researchMoveToBatchDataSet={this.props.researchMoveToBatchDataSet}
									moveToBatchData={this.props.moveToBatchData}
									researchExport={this.props.researchExport}
									research_progress={this.props.research_progress}
                  researchGetStatus={this.props.researchGetStatus}
								/>
								{/*
								<div className="py-5 my-5 text-center">
									<Alert color="danger" className="mb-3 d-inline-block">This is an example of error message</Alert>
									<h4>Let's Add Product</h4>
									<p>Add your product for research by uploading file, scan or manually search the product</p>
								</div>
								*/}

								<PreuploadModal
									isOpen={preuploadModalOpen}
									onClose={this.closeModal}
									onUpload={() => this.upload(acceptedFiles[0])}
									onPricingChange={this.pricingChange}
									pricingType={this.state.pricingType}
									onBuyCostChange={this.buyCostChange}
									buyCostValue={this.state.buyCostValue}
								/>
						</CardBody>
					</Card>
				</div>
			</div>
		)
	}

	render() {

		if(this.props.is_loading_manual_search){
			return (
				<fieldset disabled>
					{this.displayView()}
				</fieldset>
			)
		} else {
			return (this.displayView())
		}

	}
}

const AnimatedTimer = () => (
	<span className="animated-timer timer-small">
		<svg width="36px" height="38px" viewBox="659 385 36 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<path d="M683,385 L671,385 L671,389 L683,389 L683,385 Z M691.05,397.77 L693.9,394.92 C693.04,393.89 692.1,392.95 691.07,392.09 L688.22,394.94 C685.15,392.48 681.24,391 676.99,391 C667.04,391 659,399.06 659,409 C659,418.94 667.04,427 676.99,427 C686.94,427 695,418.94 695,409 C695,404.75 693.52,400.85 691.05,397.77 Z M677,423 C669.27,423 663,416.73 663,409 C663,401.27 669.27,395 677,395 C684.73,395 691,401.27 691,409 C691,416.73 684.73,423 677,423 Z" id="Shape" stroke="none" fill="#000000" fillRule="nonzero"></path>
		</svg>
	</span>
);

export default connect(
  state => {
    return {
			research_data: state.Research.get('research_data'),
			is_loading_research_data: state.Research.get('is_loading_research_data'),
			research_batch_data: state.Research.get('research_batch_data'),
			is_moving_to_batch: state.Research.get('is_moving_to_batch'),
			is_deleting_research: state.Research.get('is_deleting_research'),
			is_loading_manual_search: state.Research.get('is_loading_manual_search'),
			is_showing_selection_items: state.Research.get('is_showing_selection_items'),
			search_results: state.Research.get('search_results'),
			moveToBatchData: state.Research.get('moveToBatchData'),
			research_progress: state.Research.get('research_progress'),
		};
	},
		{
			uploadResearchData,
			loadResearchData,
			loadBatchData,
			moveToBatch,
			deleteSelectedResearch,
			researchManualSearch,
			researchManualSearchResultsDisplayClose,
			researchManualSearchSelect,
			researchDataUpdate,
			researchMoveToBatchDataSet,
			researchExport,
			researchGetStatus,
      apiCallFailed,
      userPageviewCapture,
		}
)(Research);
