import React, { Component } from 'react';
import { Button, FormFeedback, Input, Table } from "reactstrap";
import { connect } from "react-redux";
import settingsActions from "../../../redux/settings/actions";

const {getListingSalesRankRange, updateListingSalesRankRange, deleteListingSalesRankRange} = settingsActions;
class Listing_Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
    };
  }

  componentDidMount() {
    this.props.getListingSalesRankRange();
  }

  // addAlert = () => {
  //   let alerts = this.state.alerts
  //   let id = 0;
  //   if(alerts.length === 0) {
  //     id = 1
  //   } else {
  //     id = Math.max(...alerts.map(item => item.id)) + 1
  //   }

  //   alerts.push({
  //     id: id,
  //     category: null,
  //     min: null,
  //     max: null
  //   })
  //   this.setState({ alerts })
  // }

  removeAlert = (id) => {
    let data = {
      id: id,
    };
    this.props.deleteListingSalesRankRange(data);
  };

  saveAlert = (item) => {
    this.props.updateListingSalesRankRange(item)
  };

  // selectCategory = (id, selected) => {
  //   let alerts = this.state.alerts
  //   let currentAlert = alerts.find(item => item.id === id)
  //   currentAlert.category = selected.value
  //   this.setState({ alerts })
  // }

  updateValue = (id, field, value) => {
    let alerts = this.props.all_sales_rank_range.find((item) => item.id === id );
    alerts[field] = value ? parseInt(value) : 0;
    this.setState({alerts})
  };

  render() {
    let {
      all_sales_rank_range
    } = this.props
    return (
      <div>
        {all_sales_rank_range.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Min. Rank</th>
                <th>Max. Rank</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {all_sales_rank_range.map((item) => (
                <tr key={`alert-${item.id}`}>
                  <td style={{ width: "20rem" }}>
                    <Input defaultValue={item.category} readOnly disabled />
                  </td>
                  <td style={{ width: "15rem" }}>
                    <Input
                      type="number"
                      name="min_rank"
                      readOnly
                      placeholder="Min rank value"
                      defaultValue={item.min_rank}
                      onChange={(e) => this.updateValue(item.id, "min_rank", e.target.value)}
                    />
                  </td>
                  <td style={{ width: "15rem" }}>
                    <Input
                      type="number"
                      placeholder="Max rank value"
                      name="max_rank"
                      defaultValue={item.max_rank}
                      min={item.min_rank}
                      invalid={item.max_rank < item.min_rank}
                      onChange={(e) => this.updateValue(item.id, "max_rank", e.target.value)}
                    />
                    {item.max_rank < item.min_rank ? (
                      <FormFeedback className="small">
                        Must be higher than min. rank
                      </FormFeedback>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <Button 
                       className="mr-2" 
                       color="success" 
                       onClick={() => this.saveAlert(item)}
                       disabled={item.max_rank < item.min_rank}>
                      Save changes
                    </Button>
                    <Button
                       onClick={() => this.removeAlert(item.id)}>
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* <div>
          <Button
            className="ml-2"
            color="primary"
          >
            Save settings
          </Button>
        </div> */}
      </div>
    );
  }
}

// export default Listing_Alerts;
const mapStateToProps = state => ({
  all_sales_rank_range: state.Settings.get("all_sales_rank_range"),
});

export default connect(
  mapStateToProps,
  {
    getListingSalesRankRange,
    updateListingSalesRankRange,
    deleteListingSalesRankRange
  }
)(Listing_Alerts);