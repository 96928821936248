import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Navbar, Nav, NavItem, NavLink,
} from 'reactstrap';
import RepricerDashboard from './RepricerDashboard';
import Templates from './Templates';
import Scheduler from './Scheduler';
import GlobalList from './GlobalList';
import Reports from './Reports';
import RepricerLanding from './Landing';
import './style.css';
import { withRouter } from "react-router-dom";
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

class Repricer extends Component {
  constructor(props) {
    super(props);

		this.state = {
			activeMenu: 1,
    }
  }

  menuChanged = (menuId) => {
    this.setState({ activeMenu: menuId });
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Profit Reprice"})
    if(this.props.match?.params?.option === "lists"){
      this.setState({ activeMenu: 6 });
    }
  }

  showRepricer(){
	return (
		<React.Fragment>
          <Navbar className="navbar-page mt-4 mb-5" expand="md">
            <Nav navbar>
              <NavItem><NavLink href="#" active={this.state.activeMenu === 1} onClick={() => this.menuChanged(1)}>DASHBOARD</NavLink></NavItem>
              <NavItem><NavLink href="#" active={this.state.activeMenu === 2} onClick={() => this.menuChanged(2)}>TEMPLATES</NavLink></NavItem>
						  {/*<NavItem><NavLink href="#" active={this.state.activeMenu === 3} onClick={() => this.menuChanged(3)}>MY INVENTORY</NavLink></NavItem>*/}
              <NavItem><NavLink href="#" active={this.state.activeMenu === 4} onClick={() => this.menuChanged(4)}>REPORTING</NavLink></NavItem>
              <NavItem><NavLink href="#" active={this.state.activeMenu === 5} onClick={() => this.menuChanged(5)}>SCHEDULER</NavLink></NavItem>
              <NavItem><NavLink href="#" active={this.state.activeMenu === 6} onClick={() => this.menuChanged(6)}>SKUS &amp; SELLERS</NavLink></NavItem>
							<NavItem><NavLink href="#" active={this.state.activeMenu === 7} onClick={() => this.menuChanged(7)}>PRICING</NavLink></NavItem>
            </Nav>
          </Navbar>
			{ this.state.activeMenu === 1 ?
				<RepricerDashboard />
			: null}
			{ this.state.activeMenu === 2 ?
				<Templates
					updateActiveRepricerTab={this.menuChanged}
				/>
			: null}
			{/*
			{ this.state.activeMenu === 3 ?
				<MyInventory />
			: null}
			*/}
			{ this.state.activeMenu === 4 ?
				<Reports />
			: null}
			{ this.state.activeMenu === 5 ?
				<Scheduler />
			: null}
			{ this.state.activeMenu === 6 ?
				<GlobalList />
			: null}
			{ this.state.activeMenu === 7 ?
				<RepricerLanding />
			: null}
		</React.Fragment>
	);
  }

  render() {
    return (
      <div className="view">
        <div className="view-content repricer-app">
		  {this.props.userData && this.props.userData.repricerEnabled ? (
        <React.Fragment>
					<h1 className="page-title">Profit Reprice&trade; &nbsp;
						<font color="red">
							{this.props.userData.repricerTrialRemaining ? (
								this.props.userData
									&& this.props.userData.repricerTrialRemaining === 1
									? `FREE TRIAL ENDS IN ${this.props.userData.repricerTrialRemaining} DAY`
									: `FREE TRIAL ENDS IN ${this.props.userData.repricerTrialRemaining} DAYS`
								) : (null)
							}
						</font>
					</h1>
          {
            this.showRepricer()
          }
        </React.Fragment>
		  ) : (
			  <RepricerLanding />
		  )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  state => {
    return {
		userData: state.Auth.get('userData'),
    };
  },
  {
    userPageviewCapture,
  }
)(Repricer));
