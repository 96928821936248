import React, { Component } from "react";
import {
	Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,
	Label, Input, Row, Col, Media, InputGroup, InputGroupAddon, Badge,
	Table, Card, CardBody, CardHeader, UncontrolledCollapse, FormText, InputGroupText
} from "reactstrap";
import { digitСonversion } from "../../../../helpers/utility";
import { ebayLogoShadowed } from "../../../../assets/images";
import Select, {Creatable} from 'react-select';
import "./style.css";
import {
	ebay_condition_mapping_for_select,
	ebay_item_data_validation,
  ebay_LengthUnitOfMeasureEnum,
  ebay_PackageTypeEnum,
  ebay_WeightUnitOfMeasureEnum,
} from '../../../../helpers/ebay/utility';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FaSpinner from "react-icons/lib/fa/spinner";
import TooltipAtCustomElement from '../../../../shared/components/TooltipAtCustomElement';
import EbayImagesUploadModal from "../../inventory/components/EbayImagesUploadModal";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

/*
 if we are to offer some context to users
const ebay_PackageTypeEnum_Help = {
  "LETTER": "This enumeration value indicates that the package type used to ship the inventory item is a letter.",
  "BULKY_GOODS": "This enumeration value indicates that the inventory item is considered a \"bulky good\".",
  "CARAVAN": "This enumeration value indicates that the package type used to ship the inventory item is a caravan.",
  "CARS": "This enumeration value indicates that the inventory item is a car",
  "EUROPALLET": "This enumeration value indicates that the package type used to ship the inventory item is a Euro pallet.",
  "EXPANDABLE_TOUGH_BAGS": "This enumeration value indicates that the package type used to ship the inventory item is an expandable tough bag.",
  "EXTRA_LARGE_PACK": "This enumeration value indicates that the package type used to ship the inventory item is an extra large pack.",
  "FURNITURE": "This enumeration value indicates that the inventory item is a furniture item.",
  "INDUSTRY_VEHICLES": "This enumeration value indicates that the inventory item is an industry vehicle.",
  "LARGE_CANADA_POSTBOX": "This enumeration value indicates that the package type used to ship the inventory item is a Canada Post large box.",
  "LARGE_CANADA_POST_BUBBLE_MAILER": "This enumeration value indicates that the package type used to ship the inventory item is a Canada Post large bubble mailer.",
  "LARGE_ENVELOPE": "This enumeration value indicates that the package type used to ship the inventory item is a large envelope.",
  "MAILING_BOX": "This enumeration value indicates that the package type used to ship the inventory item is a standard mailing box.",
  "MEDIUM_CANADA_POST_BOX": "This enumeration value indicates that the package type used to ship the inventory item is a medium Canada Post box.",
  "MEDIUM_CANADA_POST_BUBBLE_MAILER": "This enumeration value indicates that the package type used to ship the inventory item is a medium Canada Post bubble mailer.",
  "MOTORBIKES": "This enumeration value indicates that the inventory item is a motorcycle.",
  "ONE_WAY_PALLET": "This enumeration value indicates that the package type used to ship the inventory item is a one-way pallet.",
  "PACKAGE_THICK_ENVELOPE": "This enumeration value indicates that the package type used to ship the inventory item is an thick envelope.",
  "PADDED_BAGS": "This enumeration value indicates that the package type used to ship the inventory item is a padded bag.",
  "PARCEL_OR_PADDED_ENVELOPE": "This enumeration value indicates that the package type used to ship the inventory item is a parcel or a padded envelope.",
  "ROLL": "This enumeration value indicates that the package type used to ship the inventory item is a roll.",
  "SMALL_CANADA_POST_BOX": "This enumeration value indicates that the package type used to ship the inventory item is a small Canada Post box.",
  "SMALL_CANADA_POST_BUBBLE_MAILER": "This enumeration value indicates that the package type used to ship the inventory item is a small Canada Post bubble mailer.",
  "TOUGH_BAGS": "This enumeration value indicates that the package type used to ship the inventory item is a a tough bag.",
  "UPS_LETTER": "This enumeration value indicates that the package type used to ship the inventory item is a UPS letter.",
  "USPS_FLAT_RATE_ENVELOPE": "This enumeration value indicates that the package type used to ship the inventory item is a USPS flat-rate envelope.",
  "USPS_LARGE_PACK": "This enumeration value indicates that the package type used to ship the inventory item is a USPS large pack.",
  "VERY_LARGE_PACK": "This enumeration value indicates that the package type used to ship the inventory item is a USPS very large pack.",
  "WINE_PAK": "This enumeration value indicates that the package type used to ship the inventory item is a wine pak.",
}
*/

class EbayListingModal extends Component {

	state = {
		mount: false,
		imageUrl: "",
		selectedAdditionalFiled: null,
    display_dimensions: false,
    best_offer: false,
    show_required_fields: true,
		showUploadModal: false
	}

  fullDataValidation = () => {
    if(!ebay_item_data_validation('title', this.props.ebay_list_item_data.item_name)){
      return false;
    }
    if(this.state.display_dimensions && !ebay_item_data_validation('dimensions', this.props.ebay_list_item_data.ebay_dimensions)){
      return false;
    }
    if(!ebay_item_data_validation('price', this.props.ebay_list_item_data.ebay_price)){
      return false;
    }
    if(!ebay_item_data_validation('description', this.props.ebay_list_item_data.ebay_description)){
      return false;
    }
    if(!ebay_item_data_validation('category', this.props.ebay_list_item_data.category_ebay)){
      return false;
    }
    if(!ebay_item_data_validation('condition', this.props.ebay_list_item_data.ebay_condition)){
      return false;
    }
    if(!ebay_item_data_validation('quantity', this.props.ebay_list_item_data.ebay_qty)){
      return false;
    }
    if(!ebay_item_data_validation('images', this.props.ebay_list_item_data.img_urls)){
      return false;
    }
    return true;
  }

  generateSelectData = (data) => {
    return data.map(item => { return { label: item, value: item } });
  }

	UNSAFE_componentWillReceiveProps(np){
		//try to handle onToggle
		if(np.isOpen){
			this.updateState('mount', true);
			if(!this.state.mount){
				this.updateState('imageUrl', '');
				this.updateState('selectedAdditionalFiled', null);
				this.updateState('display_dimensions', false);
        this.updateEbayByValue('ebay_dimensions', 'object', null);
			}
		} else {
			this.updateState('mount', false);
		}
    if(
      np.ebay_list_item_data.category_ebay &&
      np.ebay_list_item_data.category_ebay !== this.props.ebay_list_item_data.category_ebay){
      this.props.ebayGetItemAspects({ category_id: np.ebay_list_item_data.category_ebay.value })
    }
	}

	additionalFieldsList = () => {
		const { ebay_list_item_data } = this.props;
		var fields = [];
		if(!ebay_list_item_data.ebay_additional_fields){
			return fields;
		}
		const selected = ebay_list_item_data.ebay_additional_fields.map(
			item => {return item.desc.value} );
		this.props.ebay_item_aspects.forEach(item => {
			if(!selected.includes(item.desc.value)){
        item.desc.values = item.values;
        item.desc.required = item.required;
				fields.push(item.desc);
			}
		});
		return fields;
	}

	selectAdditionalField = (field) => {
		this.updateState('selectedAdditionalFiled', field);
	}

	addAdditionalField = () => {
		const { ebay_list_item_data } = this.props;
		const field = this.state.selectedAdditionalFiled;
		if(!field){
			return;
		}
		const selected = ebay_list_item_data.ebay_additional_fields.map(
			item => {return item.desc.value} );
		if(selected.includes(field.value)){
			return;
		}
		const new_field = this.props.ebay_item_aspects.find(
			item => item.desc.value === field.value);
		if(new_field){
			let af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
			af.push(new_field)
			this.updateEbayByValue('ebay_additional_fields', 'object', af);
		}
	}

  addAdditionalFieldsForDimensions = () => {
    const { ebay_list_item_data } = this.props;
    if(ebay_list_item_data.amazon_dimensions
        && ebay_list_item_data.amazon_dimensions.item){
      let item_dimensions = ebay_list_item_data.amazon_dimensions.item;
      item_dimensions["unit"] = 'INCH';
      let item_weight = {
        "unit": 'POUND',
      }
      if(item_dimensions.weight){
        item_weight['value'] = parseFloat(item_dimensions.weight);
      }
      const dimensions = {
        dimensions: item_dimensions,
        weight: item_weight,
      }
			this.updateEbayByValue('ebay_dimensions', 'object', dimensions);
    }
  }

	deleteAdditionalField = (field) => {
		const { ebay_list_item_data } = this.props;
		const selected = ebay_list_item_data.ebay_additional_fields.map(
			item => {return item.desc.value} );
		if(!selected.includes(field.value)){
			return;
		}
		let af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
		af = af.filter(item => item.desc.value !== field.value);
		this.updateEbayByValue('ebay_additional_fields', 'object', af);
	}

	updateAdditionalFieldStr = (field, value) => {
		const { ebay_list_item_data } = this.props;
		const af = Object.assign([], ebay_list_item_data.ebay_additional_fields);
		let new_af = af.map(item => {
			if(item.desc.value === field){
				item.value = value;
			}
			return item;
		})
		this.updateEbayByValue('ebay_additional_fields', 'object', new_af);
	}

	imgUrlChanged = (idx, value) => {
		let imgUrls = this.state.img_urls;
		imgUrls[idx] = value;

		this.setState({
			imgUrls: imgUrls
		})
	}

	toMoney = (value) => {
		const currency_code = this.props.internationalizationConfig.currency_code;
		const currency_code_symbol = [];
		currency_code_symbol["USD"] = "$";

		return value !== 0 ? digitСonversion(value, "currency", currency_code) : `${currency_code_symbol[currency_code]} ---`;
	}

	updateEbayFloat = (key, value) => {
		let val = parseFloat(value);
		if(!val){ val = 0 };
		const data = [{
			key: key,
			value: val,
			type: "float",
		}]
		this.props.ebayEditItemData(data);
	}

	updateState = (key, value) => {
		this.setState({ [key]: value });
	}

	addImage = () => {
    if(!this.state.imageUrl){ return; }
		const data = [{
			key: "img_urls",
			value: this.state.imageUrl,
			type: "array",
			action: 'add',
		}]
		this.updateState('imageUrl', '');
		this.props.ebayEditItemData(data);
	}

	deleteImage = (val) => {
		const data = [{
			key: "img_urls",
			value: val,
			type: "array",
			action: 'delete',
		}]
		this.props.ebayEditItemData(data);
	}

	updateEbayInt = (key, value) => {
		let val = parseInt(value, 10);
		if(!val){ val = 0 };
		const data = [{
			key: key,
			value: val,
			type: 'int',
		}]
		this.props.ebayEditItemData(data);
	}

	updateEbayByValue = (key, type, value) => {
		const data = [{	key, type, value }]
		this.props.ebayEditItemData(data);
	}

  updateEbayBestOffer = (key, value) => {
    var bo = this.props.ebay_list_item_data.best_offer;
    if(!bo) { bo = {}; }
    bo[key] = value;
    if(key === 'best_offer_auto_accept' && value){
      bo['best_offer_auto_decline'] = parseFloat(value).toFixed(2) - 0.01;
    }
    this.updateEbayByValue('best_offer', 'object', bo);
  }

	renderAdditionalItem = (item) => {
		var field = null;
    var selectOptions = [];
    if(item.desc.values){
      selectOptions = item.desc.values.map(val => {
        return { label: val, value: val }
      });
      if(item.value && !selectOptions.find(sel => sel.value === item.value)){
        selectOptions.push({ label: item.value, value: item.value });
      }
    }

		if(item.type === 'str'){
			field = (
				<Col sm={12}>
					<FormGroup>
						<Label style={{ width: "10rem", justifyContent: "left" }}>{item.desc.label}</Label>
						<InputGroup className="w-50 ml-2">
              {item.desc.values && item.desc.values.length > 0
                  /*&& (item.aspect_constraint && item.aspect_constraint.aspectMode !== "FREE_TEXT")*/ ?
                <Creatable
                  options={selectOptions}
                  value={selectOptions.find(val => val.value === item.value)}
		  						onChange={e => this.updateAdditionalFieldStr(item.desc.value, e ? e.value : "")}
									placeholder="Search and select..."
                  style={item.value && item.value.length > 65 ? {border: "1px solid red" } : {}}
                />
                :
  							<Input
	  							value={ item.value }
		  						onChange={e => this.updateAdditionalFieldStr(item.desc.value, e.target.value)}
									placeholder="Provide value"
                  style={item.value && item.value.length > 65 ? {border: "1px solid red" } : {}}
				  			/>
              }
							<InputGroupAddon addonType="append">
								<Button
									color="link"
									onClick={e => this.deleteAdditionalField(item.desc)}
									className="ml-2"
									title="Remove Field"
								><span className="icon icon-trash bg-danger" /></Button>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
				</Col>
			);
		}
		return field;
	}

	listItem = () => {
		this.props.ebayCreateItemData(this.props.ebay_list_item_data)
	}

	updateEbayPrice = (price) => {
		if(!price){ price = 0 }
		this.updateEbayFloat('ebay_price', price);
		const data = {
			asin: this.props.ebay_list_item_data.asin1,
			price: price,
			is_fba: this.props.ebay_list_item_data.fulfillment_channel.includes('AMAZON_') === true ? true : false,
			ebay_categories: this.props.ebay_list_item_data.category_select_ebay_suggestion,
		}
		if(this.props.ebay_list_item_data.category_ebay){
			data["ebay_selected_category"] = this.props.ebay_list_item_data.category_ebay.value;
		}
		this.props.userRecalculateEbayFees(data);
	}

	selectEbayCategory = (e) => {
		if(!e){
			this.updateEbayByValue('ebay_allowed_conditions', 'object', []);
			this.updateEbayByValue('ebay_condition', 'object', null);
		  this.updateEbayByValue('category_ebay', 'object', null);
			return
		}
		this.updateEbayByValue('category_ebay', 'object', e);
    this.props.ebayGetItemAspects({ category_id: e.value })
		if(e.allowed_conditions){
			this.updateEbayByValue('ebay_allowed_conditions', 'object', e.allowed_conditions);
			if(this.props.ebay_list_item_data.ebay_condition
				&& !e.allowed_conditions.includes(this.props.ebay_list_item_data.ebay_condition.label)){
				this.updateEbayByValue('ebay_condition', 'object', null);
			}
		} else {
			this.updateEbayByValue('ebay_allowed_conditions', 'object', []);
			this.updateEbayByValue('ebay_condition', 'object', null);
		}
	}

	updateEbayByBase = (field, value, base) => {
		const { ebay_list_item_data } = this.props;
    let ed = {
      "dimensions": {},
      "weight": {},
      "packageType": null,
    };
    if(ebay_list_item_data.ebay_dimensions){
	    ed = Object.assign({}, ebay_list_item_data.ebay_dimensions);
    }
    if(base === "packageType"){
      ed[field] = value;
    } else {
      ed[base][field] = value;
      if(!ed[base][field]){
        ed[base][field] = 0;
      }
    }
		this.updateEbayByValue('ebay_dimensions', 'object', ed);
	}

  displayPackageTypes = () => {
    return (
      <React.Fragment>
        <strong>Recommended package types:</strong>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
            minScrollbarLength: 40
          }}
        >
          <div style={{ maxHeight: "10rem" }}>
          {this.props.ebay_package_types_valid.map((item, idx) => {
            return (
              <span key={`package_type_${idx}`}> {item}, </span>
            )
          })}
        </div>
        </PerfectScrollbar>
      </React.Fragment>
    )
  }

  showRecommendedFieldsEbay = () => {
    if(!this.props.ebay_item_aspects){
      return null;
    }
    var aspects = [];
    this.props.ebay_item_aspects.forEach(item => {
      if(item.required){
        aspects.push(item.desc.label);
      }
    })
    if(aspects.length === 0){
      return null;
    }
    return (
      <span>* Required fields by eBay for this category are {aspects.map((item, idx) => <b key={`item-aspect-list-${idx}-${item}`}>{item}</b>).reduce((prev, curr) => [prev, ', ', curr])}</span>
    );
  }

	reloadCategores = () => {
		const { ebay_list_item_data } = this.props;
		if(ebay_list_item_data.item_name){
			this.props.ebayListingLoadCategories({
				query: ebay_list_item_data.item_name,
        select_default_category: true,
			});
		}
	}

	render() {
		const {
			isOpen,
			toggle,
			ebay_list_item_data,
		} = this.props;

		const amazonEarning = parseFloat(ebay_list_item_data.price)
			- parseFloat(ebay_list_item_data.amazon_onsite_fba_fee);
			//- parseFloat(ebay_list_item_data.ebay_paypal_fee);
		const ebayEarning = parseFloat(ebay_list_item_data.ebay_price)
			- parseFloat(ebay_list_item_data.amazon_offsite_fba_fee)
			- parseFloat(ebay_list_item_data.ebay_paypal_fee);

    //console.log('ebay_list_item_data', ebay_list_item_data);
    //console.log('ebay_additional_fields', ebay_list_item_data.ebay_additional_fields);
		//style={{border: "1px solid red"}}

		const amazon_url = `https://www.amazon.com/dp/${ebay_list_item_data.asin1}`;

		return (
			<Modal isOpen={isOpen} toggle={toggle} modalClassName="ebay-listing-modal">
				<ModalHeader toggle={toggle}>Create eBay listing for: <em>{ebay_list_item_data.item_name}</em></ModalHeader>
				<ModalBody>
					<div className="ebay-listing-header">
						<div>
							SKU:&nbsp;
							{ebay_list_item_data.seller_sku}
							<br />
							ASIN:&nbsp;
							<a
								href={amazon_url}
								target="_blank"
								rel="noopener noreferrer"
							>{ebay_list_item_data.asin1}</a>
						</div>
						<div>
							<ActionButtons
								toggle={toggle}
								listItem={this.listItem}
								ebay_created_item_data_executing={this.props.ebay_created_item_data_executing}
                data_valid={this.fullDataValidation()}
							/>
						</div>
					</div>

					<hr />

					<div className="ebay-listing-general">
						<Row>
							<Col sm={6}>
								<h4>PRODUCT</h4>
								<FormGroup>
									<div className="d-flex">
										<Label>Title</Label>&nbsp;
										<TooltipAtCustomElement
											tooltipId="product_title"
											tooltipText="This is the product title which we have transferred over from your Amazon listing.
											Please ensure it's short enough to be successfully transferred to your eBay product listing."
											CustomElement={IconTooltip}
											styles={{ maxWidth: "20rem" }}
										/>
									</div>
									<Input
										value={ebay_list_item_data.item_name ? ebay_list_item_data.item_name : ""}
										onChange={e => this.updateEbayByValue('item_name', 'str', e.target.value)}
										style={!ebay_item_data_validation('title', ebay_list_item_data.item_name) ? {border: "1px solid #ff0000"} : null}
									/>
									<div>Length: {ebay_list_item_data.item_name ? ebay_list_item_data.item_name.length : 0}</div>
								</FormGroup>
								<FormGroup>
									<Label>Image URLs</Label>
									{ebay_list_item_data.img_urls ?
										ebay_list_item_data.img_urls.map((url, idx) => (
                      <Media
                        key={`image_url_${idx}`}
                        className={`ebay-listing-media ${!url ? "add" : ""}`}
                      >
												<Media left>
													<Media object src={url} alt="" />
												</Media>
												<Media body>
													<InputGroup>
                            <Input
                              type="url"
                              value={url}
                              disabled={true}
                            />
														<InputGroupAddon addonType="prepend">
															<Button
																color="danger"
																outline
																onClick={e => this.deleteImage(url)}
															>DELETE</Button>
														</InputGroupAddon>
													</InputGroup>
												</Media>
											</Media>
										)) : null
									}
									<Media className={`ebay-listing-media add`}>
										<Media body>
											<InputGroup>
												<Input
													type="url"
													placeholder="paste image url..."
													value={this.state.imageUrl}
													onChange={e => this.updateState('imageUrl', e.target.value)}
										      style={!ebay_item_data_validation('images', ebay_list_item_data.img_urls) ? {border: "1px solid #ff0000"} : null}
												/>
												<InputGroupAddon addonType="prepend">
													<Button
														onClick={e => this.addImage()}
                            disabled={!this.state.imageUrl}
													>ADD</Button>
												</InputGroupAddon>
											</InputGroup>
										</Media>
									</Media>
									<Button
										size="sm"
										color="primary"
										onClick={() => this.setState({ showUploadModal: true })}
									>Upload my own images</Button>
									{this.state.showUploadModal &&
									<EbayImagesUploadModal
										isOpen={true}
										toggle={() => this.setState({ showUploadModal: !this.state.showUploadModal })}
									/>
									}
								</FormGroup>
								<FormGroup>
									{ebay_list_item_data?.ebay_description &&
										<Label className="float-right">Length {ebay_list_item_data?.ebay_description?.length}</Label>
									}
									<div className="d-flex">
										<Label>Description</Label>&nbsp;
										<TooltipAtCustomElement
											tooltipId="product_description"
											tooltipText="This is the description box which contains your Amazon product condition notes AND the product description from the actual Amazon listing.
											Combination of those cannot be longer than 4,000 characters when cross listing to eBay"
											CustomElement={IconTooltip}
											styles={{ maxWidth: "20rem" }}
										/>
									</div>
									<Input type="textarea" rows="8"
										value={ebay_list_item_data.ebay_description}
										onChange={e => this.updateEbayByValue('ebay_description', 'str', e.target.value)}
										style={!ebay_item_data_validation('description', ebay_list_item_data.ebay_description) ? {border: "1px solid #ff0000"} : null}
									/>
								</FormGroup>
							</Col>
							<Col sm={6}>
								<div className="ebay-listing-pricing">
									<h4>PRICING</h4>
									<Table className="pricing-table">
										<thead>
											<tr>
												<th className="amazon">
													<h3 className="pricing-title">AMAZON</h3>
												</th>
												<th className="ebay">
													<h3 className="pricing-title">eBAY</h3>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="pricing-group amazon">
													<FormGroup>
														<Label className="pricing-label">Your Amazon Price:</Label>
														<span className="pricing-value">{ebay_list_item_data.price ? this.toMoney(ebay_list_item_data.price) : <span className="skeleton">Loading</span>}</span>
													</FormGroup>
												</td>
												<td className="pricing-group ebay">
													<div className="d-flex justify-content-between">
														<FormGroup style={{ width: "8rem" }}>
															<Label className="pricing-label">Your eBay Price:</Label>
															<InputGroup>
																<InputGroupAddon addonType="prepend">$</InputGroupAddon>
																	<Input
																		type="number"
																		min="0"
																		value={ebay_list_item_data.ebay_price ? ebay_list_item_data.ebay_price : ""}
																		className="value"
																		onChange={e => this.updateEbayPrice(e.target.value)}
																		style={!ebay_item_data_validation('price', ebay_list_item_data.ebay_price) ? {border: "1px solid #ff0000"} : null}
																	/>
																</InputGroup>
														</FormGroup>
														<div className="recent-prices">
															<Label className="pricing-label">Recent Price:</Label>
															{ebay_list_item_data.sales_data && ebay_list_item_data.sales_data.ebay ?
															<React.Fragment>
															<div>
																<Badge color="success">New</Badge> &nbsp;: <span>
																	{ebay_list_item_data.sales_data.ebay.ebay_new_shipping ?
																			this.toMoney(ebay_list_item_data.sales_data.ebay.ebay_new_shipping) : this.toMoney(0)
																	}</span>
															</div>
															<div className="mt-1">
																<Badge color="warning">Used</Badge> : <span>
																	{ebay_list_item_data.sales_data.ebay.ebay_used_shipping ?
																			this.toMoney(ebay_list_item_data.sales_data.ebay.ebay_used_shipping) : this.toMoney(0)
																	}</span>
															</div>
															</React.Fragment>
															:
																!ebay_list_item_data.sales_data ? <span className="skeleton">Loading</span> :
																!ebay_list_item_data.sales_data.ebay ? <Badge color="info">Not found</Badge> : ""
															}
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td className="pricing-group amazon">
													<FormGroup>
														<Label className="pricing-label">Amazon FBA Fees:</Label>
														<span className="pricing-value">{ebay_list_item_data.amazon_onsite_fba_fee ? this.toMoney(ebay_list_item_data.amazon_onsite_fba_fee) : <span className="skeleton">Loading</span>}</span>
													</FormGroup>
												</td>
												<td className="pricing-group ebay">
													{ebay_list_item_data.fulfillment_channel && ebay_list_item_data.fulfillment_channel.includes('AMAZON_') ?
														<FormGroup>
															<Label className="pricing-label">Amazon MCF/FBA Fees:</Label>
															<span className="pricing-value">{ebay_list_item_data.amazon_offsite_fba_fee ? this.toMoney(ebay_list_item_data.amazon_offsite_fba_fee) : <span className="skeleton">Loading</span>}</span>
														</FormGroup>
														: null
													}
												</td>
											</tr>
											<tr>
												<td className="pricing-group amazon">&nbsp;</td>
												<td className="pricing-group ebay">
													<FormGroup>
														<Label className="pricing-label">eBay &amp; PayPal Fees:</Label>
														<span className="pricing-value">{ebay_list_item_data.ebay_paypal_fee ? this.toMoney(ebay_list_item_data.ebay_paypal_fee) : <span className="skeleton">Loading</span>}</span>
													</FormGroup>
												</td>
											</tr>
											<tr>
												<td className="pricing-group amazon">
													<FormGroup className="mb-0" id="amazon-net-profit">
														<Label className="pricing-label">Amazon Net Profit:</Label>
														<span className={`pricing-value-nett ${amazonEarning >=0 ? "text-success" : "text-danger"}`}>
															{amazonEarning ? this.toMoney(amazonEarning) : <span className="skeleton">Loading</span>}
														</span>
													</FormGroup>
												</td>
												<td className="pricing-group ebay">
													<FormGroup className="mb-0">
														<Label className="pricing-label">eBay Net Profit:</Label>
														<span className={`pricing-value-nett ${ebayEarning >=0 ? "text-success" : "text-danger"}`}>
															{ ebayEarning ? this.toMoney(ebayEarning) : <span className="skeleton">Loading</span>}
														</span>
													</FormGroup>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>
					</div>
					<div className="ebay-listing-details">
						<Card>
              <CardHeader
                id="requiredFields"
                onClick={ e => {this.setState({ show_required_fields: !this.state.show_required_fields})}}
              ><span className="icon icon-12 icon-caret"></span> Required Fields <Badge color="danger">REQUIRED</Badge></CardHeader>
              <UncontrolledCollapse
                toggler="#requiredFields"
                isOpen={this.state.show_required_fields}
              >
								<CardBody>
									<FormGroup row>
										<Col sm={3}>
											<Label>Category</Label>
											<Select
												options={ebay_list_item_data.category_select}
												value={ebay_list_item_data.category_ebay ? ebay_list_item_data.category_ebay: null }
												onChange={e => this.selectEbayCategory(e)}
										    style={!ebay_item_data_validation('title', ebay_list_item_data.category_ebay) ? {border: "1px solid #ff0000"} : null}
											/>
											<div className="d-flex align-items-center mt-2">
												<Button
													color="secondary"
													size="sm"
													onClick={e => this.reloadCategores()}
													disabled={
														!ebay_item_data_validation('item_name', ebay_list_item_data.item_name)
															|| this.props.ebay_listing_categories_loading
													}
												>Refresh Categories</Button>
												{this.props.ebay_listing_categories_loading && <FaSpinner className="fa-spin ml-2" />}
											</div>
										</Col>
										<Col sm={2}>
											<Label>Condition</Label>
											<Select
												options={ebay_condition_mapping_for_select(ebay_list_item_data.ebay_allowed_conditions)}
												value={ebay_list_item_data.ebay_condition}
												onChange={e => this.updateEbayByValue('ebay_condition', 'object', e)}
										    style={!ebay_item_data_validation('condition', ebay_list_item_data.ebay_condition) ? {border: "1px solid #ff0000"} : null}
											/>
										</Col>
										<Col sm={1}>
											<Label>Quantity</Label>
											<Input
												value={ebay_list_item_data.ebay_qty ? ebay_list_item_data.ebay_qty: "" }
											  onChange={e => this.updateEbayInt('ebay_qty', e.target.value)}
												className="ml-2"
										    style={!ebay_item_data_validation('quantity', ebay_list_item_data.ebay_qty) ? {border: "1px solid #ff0000"} : null}
											/>
										</Col>
									</FormGroup>
								</CardBody>
							</UncontrolledCollapse>
						</Card>
						<Card>
						<CardHeader id="additionalFields"><span className="icon icon-12 icon-caret"></span> Additional Fields <Badge color="info">OPTIONAL</Badge></CardHeader>
							<UncontrolledCollapse toggler="#additionalFields">
								<CardBody>
									<FormGroup row>
										<Col sm={4}>
											<div className="d-flex align-items-center">
												<Select
													options={this.additionalFieldsList()}
													value={this.state.selectedAdditionalFiled}
													onChange={e => this.selectAdditionalField(e)}
													placeholder="Select field..."
												/>
												<Button
													disabled={!this.state.selectedAdditionalFiled}
													onClick={e => this.addAdditionalField()}
													className="ml-2"
												>ADD FIELD</Button>
											</div>
											{this.showRecommendedFieldsEbay()}
										</Col>
									</FormGroup>
									<hr />
									{ebay_list_item_data.ebay_additional_fields ?
										ebay_list_item_data.ebay_additional_fields.map(item => {
											return (
												<React.Fragment key={`additional-field-fragment-${item.desc.value}`}>
													<Row key={`additional-field-${item.desc.value}`} className="form-inline mb-1">
														{this.renderAdditionalItem(item)}
													</Row>
												</React.Fragment>
											)
										}) : null
									}
								</CardBody>
							</UncontrolledCollapse>
						</Card>
						<Card>
              <CardHeader
                id="productDimensions"
                onClick={e => {
                  this.setState({ display_dimensions: !this.state.display_dimensions });
                  if(this.state.display_dimensions){
                    this.updateEbayByValue('ebay_dimensions', 'object', null);
                  }
                }}
              >
								<span className="icon icon-12 icon-caret"></span> Update Product Dimensions &nbsp;<Badge color="info">OPTIONAL</Badge>
							</CardHeader>
              <UncontrolledCollapse
                toggler="#productDimensions"
                isOpen={this.state.display_dimensions}
              >
								<CardBody>
									<p className="alert alert-warning d-flex justify-content-between align-items-center">
										<span>Update product dimensions <strong>ONLY</strong> if you get an error asking for it.</span>
										{ebay_list_item_data
											&& ebay_list_item_data.amazon_dimensions
											&& ebay_list_item_data.amazon_dimensions.item ?
											<Button
												size="sm"
												className="ml-2"
												color="secondary"
												onClick={e => this.addAdditionalFieldsForDimensions()}
											>Use Amazon default dimensions</Button>
											: null
										}
									</p>
									<Row>
										<Col sm={6}>
											<Label>Dimensions</Label>
											<FormGroup row>
												<Col sm={3}>
													<Select
														options={this.generateSelectData(ebay_LengthUnitOfMeasureEnum)}
														value={ebay_list_item_data.ebay_dimensions
														&& ebay_list_item_data.ebay_dimensions.dimensions
														&& ebay_list_item_data.ebay_dimensions.dimensions.unit ?
															{ label: ebay_list_item_data.ebay_dimensions.dimensions.unit, value: ebay_list_item_data.ebay_dimensions.dimensions.unit}
															: null
														}
														onChange={e => this.updateEbayByBase("unit", e ? e.value : null, "dimensions")}
														placeholder="Select unit..."
													/>
												</Col>
												<Col sm={2}>
													<Input
														type="number"
														value={ebay_list_item_data.ebay_dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions.width
															? ebay_list_item_data.ebay_dimensions.dimensions.width : ""}
														className="value"
														onChange={e => this.updateEbayByBase("width", parseFloat(e.target.value), "dimensions")}
														placeholder="Width"
													/>
												</Col>
												<Col sm={2}>
													<Input
														type="number"
														value={ebay_list_item_data.ebay_dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions.height
															? ebay_list_item_data.ebay_dimensions.dimensions.height : ""}
														className="value"
														onChange={e => this.updateEbayByBase("height", parseFloat(e.target.value), "dimensions")}
														placeholder="Height"
													/>
												</Col>
												<Col sm={2}>
													<Input
														type="number"
														value={ebay_list_item_data.ebay_dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions
															&& ebay_list_item_data.ebay_dimensions.dimensions.length
															? ebay_list_item_data.ebay_dimensions.dimensions.length : ""}
														className="value"
														onChange={e => this.updateEbayByBase("length", parseFloat(e.target.value), "dimensions")}
														placeholder="Length"
													/>
												</Col>
											</FormGroup>
											<Label>Weight</Label>
											<FormGroup row>
											<Col sm={3}>
												<Select
													options={this.generateSelectData(ebay_WeightUnitOfMeasureEnum)}
													value={ebay_list_item_data.ebay_dimensions
													&& ebay_list_item_data.ebay_dimensions.weight
													&& ebay_list_item_data.ebay_dimensions.weight.unit ?
														{ label: ebay_list_item_data.ebay_dimensions.weight.unit, value: ebay_list_item_data.ebay_dimensions.weight.unit}
														: null
													}
													onChange={e => this.updateEbayByBase("unit", e ? e.value : null, "weight")}
													placeholder="Select unit..."
												/>
											</Col>
											<Col sm={2}>
												<Input
													type="number"
													value={ebay_list_item_data.ebay_dimensions
														&& ebay_list_item_data.ebay_dimensions.dimensions
														&& ebay_list_item_data.ebay_dimensions.weight.value
														? ebay_list_item_data.ebay_dimensions.weight.value : ""}
													className="value"
													onChange={e => this.updateEbayByBase("value", parseFloat(e.target.value), "weight")}
													placeholder="Value"
												/>
											</Col>
										</FormGroup>
										</Col>
										<Col sm={3}>
											<Label>Package Type</Label>
											<Select
												options={this.generateSelectData(ebay_PackageTypeEnum)}
												value={ebay_list_item_data.ebay_dimensions
												&& ebay_list_item_data.ebay_dimensions.packageType ?
													{ label: ebay_list_item_data.ebay_dimensions.packageType, value: ebay_list_item_data.ebay_dimensions.packageType}
													: null
												}
												onChange={e => this.updateEbayByBase("packageType", e ? e.value : null, "packageType")}
												placeholder="Select package type..."
											/>
											<div style={{ border: "1px solid #f5a5a5", padding: ".2rem .5rem", borderRadius: "2px", marginTop: "1rem" }}>
												{this.props.ebay_package_types_valid
													? this.displayPackageTypes()
													: null
												}
											</div>
										</Col>
									</Row>
								</CardBody>
							</UncontrolledCollapse>
						</Card>
						<Card>
							<CardHeader id="bestOffer"><span className="icon icon-12 icon-caret"></span> Best Offer <Badge color="info">OPTIONAL</Badge></CardHeader>
							<UncontrolledCollapse toggler="bestOffer">
								<CardBody>
									<FormGroup>
										<span className="custom-check checkbox">
											<Label check>
												<Input
													type="checkbox"
													value={this.state.best_offer}
													onChange={e => {
														this.setState({ best_offer: !this.state.best_offer });
														this.updateEbayBestOffer('best_offer_enabled', !this.state.best_offer);
													}}
												/>
												<span>Enable best offer</span>
											</Label>
										</span>
									</FormGroup>
									<FormGroup row>
										<Col sm={6}>
											<Label>Minimum Price Auto Accept</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          min="0"
                          step="0.01"
                          value={
                            ebay_list_item_data.best_offer
                              && ebay_list_item_data.best_offer.best_offer_auto_accept
                              ? ebay_list_item_data.best_offer.best_offer_auto_accept
                              : ""
                          }
                          onChange={e => this.updateEbayBestOffer("best_offer_auto_accept", parseFloat(e.target.value))}
                          disabled={
                            ebay_list_item_data.best_offer
                              && ebay_list_item_data.best_offer.best_offer_enabled
                              ? false
                              : true
                          }
                        />
                      </InputGroup>
                      <FormText>This is the price at which Best Offers are automatically accepted. If a buyer submits a Best Offer that is equal to or above this value, the offer is automatically accepted on behalf of the seller.</FormText>
										</Col>
										<Col sm={6}>
											<Label>Maximum Price Auto Decline</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          min="0"
                          step="0.01"
                          value={
                            ebay_list_item_data.best_offer
                              && ebay_list_item_data.best_offer.best_offer_auto_decline
                              ? ebay_list_item_data.best_offer.best_offer_auto_decline
                              : ""
                          }
                          onChange={e => this.updateEbayBestOffer("best_offer_auto_decline", parseFloat(e.target.value))}
                          disabled={
                            ebay_list_item_data.best_offer
                              && ebay_list_item_data.best_offer.best_offer_enabled
                              ? false
                              : true
                          }
                        />
                      </InputGroup>
                      <FormText>This is the price at which Best Offers are automatically declined. If a buyer submits a Best Offer that is equal to or below this value, the offer is automatically declined on behalf of the seller.</FormText>
										</Col>
									</FormGroup>
								</CardBody>
							</UncontrolledCollapse>
						</Card>
					</div>
					{/* <div className="ebay-listing-details">
            <FormGroup row className="align-items-center">
              <Label sm={2}>Update product dimensions</Label>
              <Col sm={10}>
								<span className="custom-check checkbox">
									<Label check>
										<Input
											type="checkbox"
											value={this.state.display_dimensions}
											onChange={e => {
												this.setState({ display_dimensions: !this.state.display_dimensions });
												if(this.state.display_dimensions){
													this.updateEbayByValue('ebay_dimensions', 'object', null);
												}
											}}
										/>
										<span>Click here to add dimensions, ONLY if you get an error asking for it</span>
									</Label>
								</span>
              </Col>
            </FormGroup>
						<Collapse isOpen={this.state.display_dimensions}>
							<FormGroup row>
								<Label sm={4}>Dimensions</Label>
								<Label sm={4}>Weight</Label>
								<Label sm={4}>Package Type</Label>
							</FormGroup>
							<FormGroup row>
								<Label sm={1}>Unit</Label>
								<Col sm={3}>
									<Select
										options={this.generateSelectData(ebay_LengthUnitOfMeasureEnum)}
										value={ebay_list_item_data.ebay_dimensions
										&& ebay_list_item_data.ebay_dimensions.dimensions
										&& ebay_list_item_data.ebay_dimensions.dimensions.unit ?
											{ label: ebay_list_item_data.ebay_dimensions.dimensions.unit, value: ebay_list_item_data.ebay_dimensions.dimensions.unit}
											: null
										}
										onChange={e => this.updateEbayByBase("unit", e ? e.value : null, "dimensions")}
									/>
								</Col>
								<Label sm={1}>Unit</Label>
								<Col sm={3}>
									<Select
										options={this.generateSelectData(ebay_WeightUnitOfMeasureEnum)}
										value={ebay_list_item_data.ebay_dimensions
										&& ebay_list_item_data.ebay_dimensions.weight
										&& ebay_list_item_data.ebay_dimensions.weight.unit ?
											{ label: ebay_list_item_data.ebay_dimensions.weight.unit, value: ebay_list_item_data.ebay_dimensions.weight.unit}
											: null
										}
										onChange={e => this.updateEbayByBase("unit", e ? e.value : null, "weight")}
									/>
								</Col>
								<Label sm={1}>Package Type</Label>
								<Col sm={3}>
									<Select
										options={this.generateSelectData(ebay_PackageTypeEnum)}
										value={ebay_list_item_data.ebay_dimensions
										&& ebay_list_item_data.ebay_dimensions.packageType ?
											{ label: ebay_list_item_data.ebay_dimensions.packageType, value: ebay_list_item_data.ebay_dimensions.packageType}
											: null
										}
										onChange={e => this.updateEbayByBase("packageType", e ? e.value : null, "packageType")}
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={1}>Width</Label>
								<Col sm={3}>
									<Input
										type="number"
										value={ebay_list_item_data.ebay_dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions.width
											? ebay_list_item_data.ebay_dimensions.dimensions.width : ""}
										className="value"
										onChange={e => this.updateEbayByBase("width", parseFloat(e.target.value), "dimensions")}
									/>
								</Col>
								<Label sm={1}>Weight Value</Label>
								<Col sm={3}>
									<Input
										type="number"
										value={ebay_list_item_data.ebay_dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions
											&& ebay_list_item_data.ebay_dimensions.weight.value
											? ebay_list_item_data.ebay_dimensions.weight.value : ""}
										className="value"
										onChange={e => this.updateEbayByBase("value", parseFloat(e.target.value), "weight")}
									/>
								</Col>
								<Col sm={1}></Col>
								<Col sm={3}>
									<div style={{ border: "1px solid #f5a5a5", padding: ".2rem .5rem", borderRadius: "2px" }}>
                  {this.props.ebay_package_types_valid
                    ? this.displayPackageTypes()
                    : null
                  }
									</div>
                </Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={1}>Height</Label>
								<Col sm={3}>
									<Input
										type="number"
										value={ebay_list_item_data.ebay_dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions.height
											? ebay_list_item_data.ebay_dimensions.dimensions.height : ""}
										className="value"
										onChange={e => this.updateEbayByBase("height", parseFloat(e.target.value), "dimensions")}
									/>
								</Col>
								<Col sm={3}>
									{ebay_list_item_data
										&& ebay_list_item_data.amazon_dimensions
										&& ebay_list_item_data.amazon_dimensions.item ?
										<Button
											color="primary"
											onClick={e => this.addAdditionalFieldsForDimensions()}
										>Use Amazon default dimensions</Button>
										: null
                  }
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={1}>Length</Label>
								<Col sm={3}>
									<Input
										type="number"
										value={ebay_list_item_data.ebay_dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions
											&& ebay_list_item_data.ebay_dimensions.dimensions.length
											? ebay_list_item_data.ebay_dimensions.dimensions.length : ""}
										className="value"
										onChange={e => this.updateEbayByBase("length", parseFloat(e.target.value), "dimensions")}
									/>
								</Col>
							</FormGroup>
						</Collapse>
          </div> */}
				</ModalBody>
				<ModalFooter>
					<ActionButtons
						toggle={toggle}
						listItem={this.listItem}
						ebay_created_item_data_executing={this.props.ebay_created_item_data_executing}
            data_valid={this.fullDataValidation()}
					/>
				</ModalFooter>
			</Modal>
		)
	}
}

export const ActionButtons = props => {
	return (
		<div className="ebay-action-buttons">
			<Button
				onClick={e => props.toggle()}
				disabled={props.ebay_created_item_data_executing}
			>Cancel</Button>
			{/*
			<Button
				color="success"
				disabled={props.ebay_created_item_data_executing}
			>Save as Draft</Button>
			*/}
			{props.ebay_created_item_data_executing || !props.data_valid
        ?
          <Button
            type="button"
            color={props.ebay_created_item_data_executing ? "secondary" : "danger"}
            style={{ cursor: "not-allowed" }}
          >
            {props.ebay_created_item_data_executing ? <span><FaSpinner className="fa-spin" /> Listing to</span> : "Not ready to list on"}
            <span className="sr-only">eBay</span> <img src={ebayLogoShadowed} alt="" className="ebay-logo" />
          </Button>
        :
          <Button
            color="success"
            onClick={e => props.listItem()}
            disabled={props.ebay_created_item_data_executing || !props.data_valid}
          >
            Publish to <span className="sr-only">eBay</span> <img src={ebayLogoShadowed} alt="" className="ebay-logo" />
        </Button>
      }
		</div>
	)
}

export default EbayListingModal;
