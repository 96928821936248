import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { openInNewTab } from '../../helpers/utility';
import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* getOrders() {
  yield takeLatest('GET_ORDERS', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.orders.orders, payload.data.payload);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getOrdersSuccess(response.data))
      } else {
        yield put(actions.getOrdersFailed());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Orders get"));
      yield put(actions.getOrdersFailed());
      logError(error, {
        tags: {
          exceptionType: "GET_ORDERS_ERROR"
        }
      });
    }
  })
}

export function* getOrder() {
  yield takeLatest('GET_ORDER', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.orders.order, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getOrderSuccess(response.data))
      } else {
        yield put(actions.getOrderFailed());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Orders get"));
      yield put(actions.getOrderFailed());
      logError(error, {
        tags: {
          exceptionType: "GET_ORDER_ERROR"
        }
      });
    }
  })
}

export default function* orderSaga() {
	yield all([
		fork(getOrders),
		fork(getOrder),
	]);
}
