import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, Input, Button, Label, FormGroup, Alert, CardGroup } from 'reactstrap';
import {
  Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer,
  Tooltip, Legend, Line, ComposedChart
} from 'recharts';
import { digitСonversion, momentDateToISOFormatConversioniWithFormat } from "../../../../helpers/utility";
import adminActions from "../../../../redux/admin/actions";
import Select from "react-select";
import MetricItem from '../../main_dashboard/MetricItem';
import chart_config from "../../main_dashboard_v3/chart_config";

const { getEbayStats, updateUser, getEbayStatsSalesAverages } = adminActions;

const dropdownOptions = [
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
];

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, selected_period, payload } = this.props;
    if(selected_period === "month") {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={10} textAnchor="middle" fill="#666" fontSize="12">
            {momentDateToISOFormatConversioniWithFormat(payload.value, "YYYY")}
          </text>
          <text x={0} y={0} dy={24} textAnchor="middle" fill="#666" fontSize="12">
            {momentDateToISOFormatConversioniWithFormat(payload.value, "MMM")}
          </text>
        </g>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="middle" fill="#666" fontSize="12">
          {momentDateToISOFormatConversioniWithFormat(payload.value, "ddd")}
        </text>
        <text x={0} y={0} dy={24} textAnchor="middle" fill="#666" fontSize="12">
          {momentDateToISOFormatConversioniWithFormat(payload.value, "MMM DD")}
        </text>
      </g>
    );
  }
}

class AdminEbayDashboard extends Component {
  state = {
    selected_period: { label: "Daily", value: "day" },
    ebay_auth_username: "",
  }

  moneyFormatter = (value) => {
    return digitСonversion(value, "currency", "USD");
  }

  componentDidMount() {
    this.props.getEbayStats({ period: "day"});
    this.props.getEbayStatsSalesAverages();
  }

  updatePeriod = (e) => {
    if(e){
      this.props.getEbayStats({ period: e.value});
    }
    this.setState({ selected_period: e });
  }

	enableEbayAuth = () => {
		if(!this.state.ebay_auth_username) { return }
		const data = {
			username: this.state.ebay_auth_username,
			key: "ebay_auth",
			value: false,
		}
		this.props.updateUser(data);
	}

  tooltipFormatter = (value, name, props) => {
    if(props.dataKey === 'sales_amount') {
      return this.moneyFormatter(value)
    }

    return value;
  }

  render() {
    const { ebay_stats } = this.props;

    let sales_timeline = [];
    if(ebay_stats.sales_timeline){
      sales_timeline = ebay_stats.sales_timeline.sort((a, b) => a.period < b.period ? -1 : 1);
      let nd = new Date();
      if(sales_timeline.length > 0 && this.state.selected_period?.value === "month"){
        sales_timeline[sales_timeline.length - 1].projected_amount = sales_timeline[sales_timeline.length - 1].sales_amount * (31/nd.getDate() -1);
        sales_timeline[sales_timeline.length - 1].projected_amount = sales_timeline[sales_timeline.length - 1].projected_amount.toFixed(2);
      }
    }

    return (
      <React.Fragment>
        <Row>
          <Col md={10}>
            <div className="mb-4">
              <h3 className="h6 mb-4">Total eBay Sales across all sellers</h3>
              <div className="d-flex">
                <div style={{ minWidth: "10rem" }}>
                  <Select
                    options={dropdownOptions}
                    value={this.state.selected_period}
                    onChange={e => this.updatePeriod(e)}
                  />
                </div>
                {ebay_stats.sales_averages && ebay_stats.sales_averages.week ?
                  <MetricItem
                    key="avg7"
                    metricKey="avg7"
                    metricName="7 day avg."
                    value={ebay_stats.sales_averages.week}
                    numberType="currency"
                    currencyCode="USD"
                    className="green ml-5"
                    tooltipText="Average sales in 7 days"
                  />
                  : null
                }
                {ebay_stats.sales_averages && ebay_stats.sales_averages.month ?
                  <MetricItem
                    key="avg30"
                    metricKey="avg30"
                    metricName="30 day avg."
                    value={ebay_stats.sales_averages.month}
                    numberType="currency"
                    currencyCode="USD"
                    className="green ml-5"
                    tooltipText="Average sales in 30 days"
                  />
                  : null
                }
                {ebay_stats.sales_averages && ebay_stats.sales_averages.six_months ?
                  <MetricItem
                    key="avg6mo"
                    metricKey="avg6mo"
                    metricName="6 MO avg."
                    value={ebay_stats.sales_averages.six_months}
                    numberType="currency"
                    currencyCode="USD"
                    className="green ml-5"
                    tooltipText="Average sales in 6 months"
                  />
                  : null
                }
              </div>
            </div>
            <ResponsiveContainer
              width="100%"
              height={300}
            >
              <ComposedChart
                data={sales_timeline}
                margin={{top: 20, right: 30, left: 10, bottom: 5}}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={chart_config.cartesianStrokeColor}
                />
                <XAxis
                  dataKey="period"
                  tick={<CustomizedAxisTick
                  selected_period={this.state.selected_period.value}/>}
                  height={40}
                  tickLine={false}
                  axisLine={false}
                  stroke={chart_config.axisFontColor}
                  style={{
                    fontSize: chart_config.axisFontSize
                  }}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  stroke={chart_config.axisFontColor}
                  style={{
                    fontSize: chart_config.axisFontSize
                  }}
                  tickFormatter={(val) => this.moneyFormatter(val) }
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tickLine={false}
                  axisLine={false}
                  stroke={chart_config.axisFontColor}
                  style={{
                    fontSize: chart_config.axisFontSize
                  }}
                />
                <Tooltip cursor={false} formatter={this.tooltipFormatter}/>
                <Legend />
                <Bar
                  name="Sales Amount"
                  stackId="a"
                  barSize={20}
                  dataKey="sales_amount"
                  fill="#82ca9d"
                  label={{ fontSize: 11, position: "top", formatter: this.moneyFormatter }}
                />
                <Bar
                  name="Projected Amount"
                  stackId="a" 
                  barSize={20}
                  dataKey="projected_amount"
                  fill="rgba(130, 202, 157, .3)"
                  label={{ fontSize: 11, position: "top", formatter: this.moneyFormatter }}
                />
                <Line
                  yAxisId="right"
                  isAnimationActive={false}
                  type="monotone"
                  name="Sales Number"
                  dataKey="sales_count"
                  stroke="#8884d8"
                  strokeWidth={chart_config.strokeWidth}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Col>
          <Col md={2}>
            <CardGroup className="card-group-metric mt-5">
              <Card>
                <CardBody>
                  <div className="metric-item blue">
                    <h6 className="title">Active eBay Subscriptions</h6>
                    <span className="value">{ebay_stats.ebay_users ? ebay_stats.ebay_users : 0} / {ebay_stats.ebay_users_allowed ? ebay_stats.ebay_users_allowed : 0}</span>
                  </div>
                </CardBody>
                <CardBody>
                  <div className="metric-item blue">
                    <h6 className="title">eBay Subscriptions Ratio</h6>
                    <span className="value">{ebay_stats.ebay_sub_ratio ? ebay_stats.ebay_sub_ratio : 0}%</span>
                  </div>
                </CardBody>
                <CardBody>
                  <div className="metric-item blue">
                    <h6 className="title">Subcriptions over 3mos</h6>
                    <span className="value">{ebay_stats.ebay_users_subscribed_3mo ? ebay_stats.ebay_users_subscribed_3mo : 0} / {ebay_stats.ebay_users ? ebay_stats.ebay_users : 0}</span>
                  </div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h3 className="h6 mb-12">Enable eBay reauth</h3>
            <FormGroup row className="align-items-end">
              <Col xs={6}>
                <Label>Username</Label>
                <Input
                  onChange={e => this.setState({"ebay_auth_username": e.target.value})}
                  value={this.state.ebay_auth_username}
                />
              </Col>
              <Col xs="6">
                <Button
                  color="success"
                  onClick={e => this.enableEbayAuth()}
                  disabled={!this.state.ebay_auth_username || this.props.user_updating}
                >Enable eBay reauth</Button>
              </Col>
            </FormGroup>
            <p className="text-danger">
              {!this.props.user_updating ? this.props.user_update_message : null}
            </p>
            <Alert color="warning">
              Please keep in mind that when we enable eBay reauth for user, some parts of AL eBay automation will not work untill this process is finished by user
            </Alert>
          </Col>
          <Col md={4}>
          </Col>
          <Col md={4}>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
		return {
			ebay_stats: state.Admin.get("ebay_stats"),
			user_updating: state.Admin.get("user_updating"),
			user_update_message: state.Admin.get("user_update_message"),
    };
  },
  {
		getEbayStats,
		updateUser,
    getEbayStatsSalesAverages,
  }
)(AdminEbayDashboard);
