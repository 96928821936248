export const barCodeTypesOptions = [
  "CODE39",
  "CODE128",
];

export const fontSizeCoefficientOptions = [
  0.5,
  0.6,
  0.7,
  0.8,
  0.9,
  1,
  1.1,
  1.2,
];
