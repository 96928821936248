import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import DeleteDialog from "../../../../shared/components/dialogs/DeleteDialog";

class AdminImportantAlerts extends Component {
  state = {
    alerts: null,
    mode: null,
    edit_id: null,
    delete_id: null,
    text: null,
    url: null,
  }

  handleSubmit = () => {
    const { mode, edit_id, url, text, delete_id} = this.state;
    var alerts = this.props.app_settings?.alerts?.data
      ? [...this.props.app_settings?.alerts?.data]
      : [];
    if(mode === "add"){
      if(!alerts.find(item => item.id === edit_id)){
        alerts.push({
          id: edit_id,
          url: url,
          text: text,
        })
      }
    } else if (mode === "edit") {
      alerts = alerts.map(item => {
        if(item.id === edit_id){
          item.url = url === null ? item.url : url;
          item.text = text === null ? item.text : text;
        }
        return item;
      })
    } else if (delete_id !== null && alerts.find(item => item.id === delete_id)) {
      alerts = alerts.filter(item => item.id !== delete_id);
    }
    this.props.updateAppSettings({
      key: "alerts",
      data: {"data": alerts},
    })
  }

  render() {
    const { text, url, mode, delete_id, edit_id } = this.state;
    const { app_settings, admin_app_settings_loading } = this.props;

    return (
      <div className="container-fluid">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle className="mb-0">Important Alerts</CardTitle>
              <Button
                color="primary"
                onClick={() => this.setState({
                  mode: 'add',
                  edit_id: app_settings?.alerts?.data?.length ? app_settings?.alerts?.data?.length + 1 : 1,
                  url: null,
                  text: null,
                })}
              >Add New Alert</Button>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Text</th>
                  <th>URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {app_settings?.alerts?.data?.map(alert => (
                  <tr key={alert.id}>
                    <td>{alert.text}</td>
                    <td>
                      <a href={alert.url} target="_blank" rel="noopener noreferrer">
                        {alert.url}
                      </a>
                    </td>
                    <td>
                      <div className="d-flex justify-content-start align-items-center">
                        <Button
                          color="info"
                          size="sm"
                          className="mr-2"
                          onClick={() => this.setState({
                            mode: 'edit',
                            edit_id: alert.id,
                            url: null,
                            text: null,
                          })}
                        >
                          Edit
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => this.setState({ delete_id: alert.id })}
                        >
                          Delete
                        </Button>
                      </div>
                      <DeleteDialog
                        isOpen={delete_id === alert.id}
                        toggle={() => this.setState({ delete_id: null })}
                        deleteItem={() => {
                          this.handleSubmit();
                          this.setState({ delete_id: null });
                        }}
                        close={() => {
                          this.setState({ delete_id: null });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Modal isOpen={mode !== null} toggle={() => this.setState({ mode: null })}>
          <ModalHeader toggle={() => this.setState({ mode: null })}>
            {mode === 'edit' ? 'Edit Link' : 'Add New Link'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="text">Link Text</Label>
                <Input
                  type="text"
                  placeholder="Enter link text"
                  value={
                    mode === "add"
                      ? text || ""
                      : text === null
                        ? app_settings?.alerts?.data?.find(item => item.id === edit_id)
                          ? app_settings?.alerts?.data?.find(item => item.id === edit_id).text
                          : ""
                        : text
                  }
                  onChange={(e) => this.setState({text: e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label for="url">URL</Label>
                <Input
                  type="url"
                  placeholder="Enter URL"
                  value={
                    mode === "add"
                      ? url || ""
                      : url === null
                        ? app_settings?.alerts?.data?.find(item => item.id === edit_id)
                          ? app_settings?.alerts?.data?.find(item => item.id === edit_id).url
                          : ""
                        : url
                  }
                  onChange={(e) => this.setState({url: e.target.value})}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.handleSubmit();
                this.setState({
                  mode: null,
                  edit_id: null,
                  text: null,
                  url: null,
                })
              }}
            >
              {mode === 'edit' ? 'Update' : 'Add'}
            </Button>
            <Button color="secondary" onClick={() => this.setState({ mode: null })}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AdminImportantAlerts;

