const repricerActions = {
	WORKING_TEMPLATE_INIT: "WORKING_TEMPLATE_INIT",

	WORKING_TEMPLATE_UPDATE_NAME: "WORKING_TEMPLATE_UPDATE_NAME",
	WORKING_TEMPLATE_UPDATE_UPDATED_AT: "WORKING_TEMPLATE_UPDATE_UPDATED_AT",
	WORKING_TEMPLATE_UPDATE_JSON: "WORKING_TEMPLATE_UPDATE_JSON",
	WORKING_TEMPLATE_UPDATE_PROP: "WORKING_TEMPLATE_UPDATE_PROP",

	USER_LOAD_TEMPLATES: "USER_LOAD_TEMPLATES",
	USER_LOAD_TEMPLATES_SUCCESS: "USER_LOAD_TEMPLATES_SUCCESS",
	USER_LOAD_TEMPLATES_FAILED: "USER_LOAD_TEMPLATES_FAILED",

	USER_LOAD_WORKING_TEMPLATE: "USER_LOAD_WORKING_TEMPLATE",
	USER_LOAD_WORKING_TEMPLATE_SUCCESS: "USER_LOAD_WORKING_TEMPLATE_SUCCESS",
	USER_LOAD_WORKING_TEMPLATE_FAILED: "USER_LOAD_WORKING_TEMPLATE_FAILED",

	USER_SAVE_WORKING_TEMPLATE: "USER_SAVE_WORKING_TEMPLATE",
	USER_SAVE_WORKING_TEMPLATE_SUCCESS: "USER_SAVE_WORKING_TEMPLATE_SUCCESS",
	USER_SAVE_WORKING_TEMPLATE_FAILED: "USER_SAVE_WORKING_TEMPLATE_FAILED",

	USER_UPDATE_WORKING_TEMPLATE: "USER_UPDATE_WORKING_TEMPLATE",
	USER_UPDATE_WORKING_TEMPLATE_SUCCESS: "USER_UPDATE_WORKING_TEMPLATE_SUCCESS",
	USER_UPDATE_WORKING_TEMPLATE_FAILED: "USER_UPDATE_WORKING_TEMPLATE_FAILED",

	USER_DELETE_WORKING_TEMPLATE: "USER_DELETE_WORKING_TEMPLATE",
	USER_DELETE_WORKING_TEMPLATE_SUCCESS: "USER_DELETE_WORKING_TEMPLATE_SUCCESS",
	USER_DELETE_WORKING_TEMPLATE_FAILED: "USER_DELETE_WORKING_TEMPLATE_FAILED",

	UPDATE_WARNING_TEXT: "UPDATE_WARNING_TEXT",
	UPDATE_WARNING_TEXT_EXECUTE: "UPDATE_WARNING_TEXT_EXECUTE",

	WORKING_SCHEDULE_INIT: "WORKING_SCHEDULE_INIT",

	WORKING_SCHEDULE_UPDATE_PROP: "WORKING_SCHEDULE_UPDATE_PROP",

	USER_LOAD_SCHEDULES: "USER_LOAD_SCHEDULES",
	USER_LOAD_SCHEDULES_SUCCESS: "USER_LOAD_SCHEDULES_SUCCESS",
	USER_LOAD_SCHEDULES_FAILED: "USER_LOAD_SCHEDULES_FAILED",

	USER_LOAD_WORKING_SCHEDULE: "USER_LOAD_WORKING_SCHEDULE",
	USER_LOAD_WORKING_SCHEDULE_SUCCESS: "USER_LOAD_WORKING_SCHEDULE_SUCCESS",
	USER_LOAD_WORKING_SCHEDULE_FAILED: "USER_LOAD_WORKING_SCHEDULE_FAILED",

	USER_SAVE_WORKING_SCHEDULE: "USER_SAVE_WORKING_SCHEDULE",
	USER_SAVE_WORKING_SCHEDULE_SUCCESS: "USER_SAVE_WORKING_SCHEDULE_SUCCESS",
	USER_SAVE_WORKING_SCHEDULE_FAILED: "USER_SAVE_WORKING_SCHEDULE_FAILED",

	USER_UPDATE_WORKING_SCHEDULE: "USER_UPDATE_WORKING_SCHEDULE",
	USER_UPDATE_WORKING_SCHEDULE_SUCCESS: "USER_UPDATE_WORKING_SCHEDULE_SUCCESS",
	USER_UPDATE_WORKING_SCHEDULE_FAILED: "USER_UPDATE_WORKING_SCHEDULE_FAILED",

	USER_DELETE_WORKING_SCHEDULE: "USER_DELETE_WORKING_SCHEDULE",
	USER_DELETE_WORKING_SCHEDULE_SUCCESS: "USER_DELETE_WORKING_SCHEDULE_SUCCESS",
	USER_DELETE_WORKING_SCHEDULE_FAILED: "USER_DELETE_WORKING_SCHEDULE_FAILED",

	UPDATE_SCHEDULE_WARNING_TEXT: "UPDATE_SCHEDULE_WARNING_TEXT",
	UPDATE_SCHEDULE_WARNING_TEXT_EXECUTE: "UPDATE_SCHEDULE_WARNING_TEXT_EXECUTE",

	USER_GET_GLOBAL_LIST: "USER_GET_GLOBAL_LIST",
	USER_GET_GLOBAL_LIST_SUCCESS: "USER_GET_GLOBAL_LIST_SUCCESS",
	USER_GET_GLOBAL_LIST_FAILED: "USER_GET_GLOBAL_LIST_FAILED",

	USER_ADD_GLOBAL_LIST: "USER_ADD_GLOBAL_LIST",
	USER_ADD_GLOBAL_LIST_SUCCESS: "USER_ADD_GLOBAL_LIST_SUCCESS",
	USER_ADD_GLOBAL_LIST_FAILED: "USER_ADD_GLOBAL_LIST_FAILED",

	USER_DELETE_GLOBAL_LIST: "USER_DELETE_GLOBAL_LIST",
	USER_DELETE_GLOBAL_LIST_SUCCESS: "USER_DELETE_GLOBAL_LIST_SUCCESS",
	USER_DELETE_GLOBAL_LIST_FAILED: "USER_DELETE_GLOBAL_LIST_FAILED",

	USER_UPDATE_GLOBAL_LIST: "USER_UPDATE_GLOBAL_LIST",
	USER_UPDATE_GLOBAL_LIST_SUCCESS: "USER_UPDATE_GLOBAL_LIST_SUCCESS",
	USER_UPDATE_GLOBAL_LIST_FAILED: "USER_UPDATE_GLOBAL_LIST_FAILED",

	USER_LOAD_REPORTS: "USER_LOAD_REPORTS",
	USER_LOAD_REPORTS_SUCCESS: "USER_LOAD_REPORTS_SUCCESS",
	USER_LOAD_REPORTS_FAILED: "USER_LOAD_REPORTS_FAILED",

	USER_REPRICER_REPORT_SUMMARY: "USER_REPRICER_REPORT_SUMMARY",
	USER_REPRICER_REPORT_SUMMARY_SUCCESS: "USER_REPRICER_REPORT_SUMMARY_SUCCESS",
	USER_REPRICER_REPORT_SUMMARY_FAILED: "USER_REPRICER_REPORT_SUMMARY_FAILED",

	TEMPLATE_TO_SKU_MAPPING: 'TEMPLATE_TO_SKU_MAPPING',
	TEMPLATE_TO_SKU_MAPPING_SUCCESS: 'TEMPLATE_TO_SKU_MAPPING_SUCCESS',
	TEMPLATE_TO_SKU_MAPPING_FAILED: 'TEMPLATE_TO_SKU_MAPPING_FAILED',

	TEMPLATE_QUERY_BY_SKUS: 'TEMPLATE_QUERY_BY_SKUS',
	TEMPLATE_QUERY_BY_SKUS_SUCCESS: 'TEMPLATE_QUERY_BY_SKUS_SUCCESS',
	TEMPLATE_QUERY_BY_SKUS_FAILED: 'TEMPLATE_QUERY_BY_SKUS_FAILED',

	UPDATE_WARNING: "UPDATE_SCHEDULE_WARNING",
	UPDATE_WARNING_EXECUTE: "UPDATE_WARNING_EXECUTE",

	TEMPLATE_CHECK_NAME: "TEMPLATE_CHECK_NAME",
	TEMPLATE_CHECK_NAME_SUCCESS: "TEMPLATE_CHECK_NAME_SUCCESS",
	TEMPLATE_CHECK_NAME_FAILED: "TEMPLATE_CHECK_NAME_FAILED",

	REPORT_INSIGHT_RUN: "REPORT_INSIGHT_RUN",
	REPORT_INSIGHT_RUN_SUCCESS: "REPORT_INSIGHT_RUN_SUCCESS",
	REPORT_INSIGHT_RUN_FAILED: "REPORT_INSIGHT_RUN_FAILED",

	USER_SELF_ENABLE_REPRICER: 'USER_SELF_ENABLE_REPRICER',
	USER_SELF_ENABLE_REPRICER_SUCCESS: 'USER_SELF_ENABLE_REPRICER_SUCCESS',
	USER_SELF_ENABLE_REPRICER_FAILED: 'USER_SELF_ENABLE_REPRICER_FAILED',

	GET_REPRICER_DASHBOARD_REPRICING: "GET_REPRICER_DASHBOARD_REPRICING",
	GET_REPRICER_DASHBOARD_REPRICING_SUCCESS: "GET_REPRICER_DASHBOARD_REPRICING_SUCCESS",
	GET_REPRICER_DASHBOARD_REPRICING_FAILED: "GET_REPRICER_DASHBOARD_REPRICING_FAILED",

	TEMPLATE_UPDATE_NAME: "TEMPLATE_UPDATE_NAME",
	TEMPLATE_UPDATE_NAME_SUCCESS: "TEMPLATE_UPDATE_NAME_SUCCESS",
	TEMPLATE_UPDATE_NAME_FAILED: "TEMPLATE_UPDATE_NAME_FAILED",

	TEMPLATE_ADMIN_TEST: "TEMPLATE_ADMIN_TEST",
	TEMPLATE_ADMIN_TEST_SUCCESS: "TEMPLATE_ADMIN_TEST_SUCCESS",
	TEMPLATE_ADMIN_TEST_FAILED: "TEMPLATE_ADMIN_TEST_FAILED",

	GET_REPORT_REPRICE_MASTER: "GET_REPORT_REPRICE_MASTER",
	GET_REPORT_REPRICE_MASTER_SUCCESS: "GET_REPORT_REPRICE_MASTER_SUCCESS",
	GET_REPORT_REPRICE_MASTER_FAILED: "GET_REPORT_REPRICE_MASTER_FAILED",

	getReportRepriceMaster: (data) => ({
		type: repricerActions.GET_REPORT_REPRICE_MASTER,
		data
	}),
	getReportRepriceMasterSuccess: (data) => ({
		type: repricerActions.GET_REPORT_REPRICE_MASTER_SUCCESS,
		data
	}),
	getReportRepriceMasterFailed: (data) => ({
		type: repricerActions.GET_REPORT_REPRICE_MASTER_FAILED,
		data
	}),

	templateAdminTest: (data) => ({
		type: repricerActions.TEMPLATE_ADMIN_TEST,
		data
	}),
	templateAdminTestSuccess: (data) => ({
		type: repricerActions.TEMPLATE_ADMIN_TEST_SUCCESS,
		data
	}),
	templateAdminTestFailed: (data) => ({
		type: repricerActions.TEMPLATE_ADMIN_TEST_FAILED,
		data
	}),

	templateUpdateName: (data) => ({
		type: repricerActions.TEMPLATE_UPDATE_NAME,
		data
	}),
	templateUpdateNameSuccess: (data) => ({
		type: repricerActions.TEMPLATE_UPDATE_NAME_SUCCESS,
		data
	}),
	templateUpdateNameFailed: (data) => ({
		type: repricerActions.TEMPLATE_UPDATE_NAME_FAILED,
		data
	}),

	getRepricerDashboardRepricing: (data) => ({
		type: repricerActions.GET_REPRICER_DASHBOARD_REPRICING,
		data
	}),
	getRepricerDashboardRepricingSuccess: (data) => ({
		type: repricerActions.GET_REPRICER_DASHBOARD_REPRICING_SUCCESS,
		data
	}),
	getRepricerDashboardRepricingFailed: (data) => ({
		type: repricerActions.GET_REPRICER_DASHBOARD_REPRICING_FAILED,
		data
	}),

	userEnableRepricer: (data) => ({
		type: repricerActions.USER_SELF_ENABLE_REPRICER,
		data
	}),
	userEnableRepricerSuccess: (data) => ({
		type: repricerActions.USER_SELF_ENABLE_REPRICER_SUCCESS,
		data
	}),
	userEnableRepricerFailed: (data) => ({
		type: repricerActions.USER_SELF_ENABLE_REPRICER_FAILED,
		data
	}),

	reportInsightRun: (data) => ({
		type: repricerActions.REPORT_INSIGHT_RUN,
		data
	}),
	reportInsightRunSuccess: (data) => ({
		type: repricerActions.REPORT_INSIGHT_RUN_SUCCESS,
		data
	}),
	reportInsightRunFailed: (data) => ({
		type: repricerActions.REPORT_INSIGHT_RUN_FAILED,
		data
	}),

	templateCheckName: (data) => ({
		type: repricerActions.TEMPLATE_CHECK_NAME,
		data
	}),
	templateCheckNameSuccess: (data) => ({
		type: repricerActions.TEMPLATE_CHECK_NAME_SUCCESS,
		data
	}),
	templateCheckNameFailed: (data) => ({
		type: repricerActions.TEMPLATE_CHECK_NAME_FAILED,
		data
	}),

	updateWarning: (data) => ({
		type: repricerActions.UPDATE_WARNING,
		data
	}),
	updateWarningExecute: (data) => ({
		type: repricerActions.UPDATE_WARNING_EXECUTE,
		data
	}),

	templateQueryBySkus: (data) => ({
		type: repricerActions.TEMPLATE_QUERY_BY_SKUS,
		data
	}),
	templateQueryBySkusSuccess: (data) => ({
		type: repricerActions.TEMPLATE_QUERY_BY_SKUS_SUCCESS,
		data
	}),
	templateQueryBySkusFailed: (data) => ({
		type: repricerActions.TEMPLATE_QUERY_BY_SKUS_FAILED,
		data
	}),

	templateToSkuMapping: (data) => ({
		type: repricerActions.TEMPLATE_TO_SKU_MAPPING,
		data
	}),
	templateToSkuMappingSuccess: (data) => ({
		type: repricerActions.TEMPLATE_TO_SKU_MAPPING_SUCCESS,
		data
	}),
	templateToSkuMappingFailed: (data) => ({
		type: repricerActions.TEMPLATE_TO_SKU_MAPPING_FAILED,
		data
	}),

	userRepricerReportSummary: (data) => ({
		type: repricerActions.USER_REPRICER_REPORT_SUMMARY,
		data
	}),
	userRepricerReportSummarySuccess: (data) => ({
		type: repricerActions.USER_REPRICER_REPORT_SUMMARY_SUCCESS,
		data
	}),
	userRepricerReportSummaryFailed: (data) => ({
		type: repricerActions.USER_REPRICER_REPORT_SUMMARY_FAILED,
		data
	}),

	userLoadReports: (data) => ({
		type: repricerActions.USER_LOAD_REPORTS,
		data
	}),
	userLoadReportsSuccess: (data) => ({
		type: repricerActions.USER_LOAD_REPORTS_SUCCESS,
		data
	}),
	userLoadReportsFailed: (data) => ({
		type: repricerActions.USER_LOAD_REPORTS_FAILED,
		data
	}),

	userUpdateGlobalList: (data) => ({
		type: repricerActions.USER_UPDATE_GLOBAL_LIST,
		data
	}),
	userUpdateGlobalListSuccess: (data) => ({
		type: repricerActions.USER_UPDATE_GLOBAL_LIST_SUCCESS,
		data
	}),
	userUpdateGlobalListFailed: (data) => ({
		type: repricerActions.USER_UPDATE_GLOBAL_LIST_FAILED,
		data
	}),

	userDeleteGlobalList: (data) => ({
		type: repricerActions.USER_DELETE_GLOBAL_LIST,
		data
	}),
	userDeleteGlobalListSuccess: (data) => ({
		type: repricerActions.USER_DELETE_GLOBAL_LIST_SUCCESS,
		data
	}),
	userDeleteGlobalListFailed: (data) => ({
		type: repricerActions.USER_DELETE_GLOBAL_LIST_FAILED,
		data
	}),

	userAddGlobalList: (data) => ({
		type: repricerActions.USER_ADD_GLOBAL_LIST,
		data
	}),
	userAddGlobalListSuccess: (data) => ({
		type: repricerActions.USER_ADD_GLOBAL_LIST_SUCCESS,
		data
	}),
	userAddGlobalListFailed: (data) => ({
		type: repricerActions.USER_ADD_GLOBAL_LIST_FAILED,
		data
	}),

	userGetGlobalList: (data) => ({
		type: repricerActions.USER_GET_GLOBAL_LIST,
		data
	}),
	userGetGlobalListSuccess: (data) => ({
		type: repricerActions.USER_GET_GLOBAL_LIST_SUCCESS,
		data
	}),
	userGetGlobalListFailed: (data) => ({
		type: repricerActions.USER_GET_GLOBAL_LIST_FAILED,
		data
	}),

	userDeleteWorkingSchedule: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_SCHEDULE,
		data
	}),
	userDeleteWorkingScheduleSuccess: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_SCHEDULE_SUCCESS,
		data
	}),
	userDeleteWorkingScheduleFailed: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_SCHEDULE_FAILED,
		data
	}),

	userUpdateWorkingSchedule: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_SCHEDULE,
		data
	}),
	userUpdateWorkingScheduleSuccess: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_SCHEDULE_SUCCESS,
		data
	}),
	userUpdateWorkingScheduleFailed: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_SCHEDULE_FAILED,
		data
	}),

	userSaveWorkingSchedule: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_SCHEDULE,
		data
	}),
	userSaveWorkingScheduleSuccess: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_SCHEDULE_SUCCESS,
		data
	}),
	userSaveWorkingScheduleFailed: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_SCHEDULE_FAILED,
		data
	}),

	userLoadWorkingSchedule: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_SCHEDULE,
		data
	}),
	userLoadWorkingScheduleSuccess: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_SCHEDULE_SUCCESS,
		data
	}),
	userLoadWorkingScheduleFailed: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_SCHEDULE_FAILED,
		data
	}),

	userLoadSchedules: (data) => ({
		type: repricerActions.USER_LOAD_SCHEDULES,
		data
	}),
	userLoadSchedulesSuccess: (data) => ({
		type: repricerActions.USER_LOAD_SCHEDULES_SUCCESS,
		data
	}),
	userLoadSchedulesFailed: (data) => ({
		type: repricerActions.USER_LOAD_SCHEDULES_FAILED,
		data
	}),

	workingScheduleInit: (data) => ({
		type: repricerActions.WORKING_SCHEDULE_INIT,
		data
	}),
	workingScheduleUpdateProp: (data) => ({
		type: repricerActions.WORKING_SCHEDULE_UPDATE_PROP,
		data
	}),

	updateScheduleWarningText: (data) => ({
		type: repricerActions.UPDATE_SCHEDULE_WARNING_TEXT,
		data
	}),
	updateScheduleWarningTextExecute: (data) => ({
		type: repricerActions.UPDATE_SCHEDULE_WARNING_TEXT_EXECUTE,
		data
	}),

	updateWarningText: (data) => ({
		type: repricerActions.UPDATE_WARNING_TEXT,
		data
	}),
	updateWarningTextExecute: (data) => ({
		type: repricerActions.UPDATE_WARNING_TEXT_EXECUTE,
		data
	}),

	userDeleteWorkingTemplate: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_TEMPLATE,
		data
	}),
	userDeleteWorkingTemplateSuccess: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_TEMPLATE_SUCCESS,
		data
	}),
	userDeleteWorkingTemplateFailed: (data) => ({
		type: repricerActions.USER_DELETE_WORKING_TEMPLATE_FAILED,
		data
	}),

	userUpdateWorkingTemplate: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_TEMPLATE,
		data
	}),
	userUpdateWorkingTemplateSuccess: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_TEMPLATE_SUCCESS,
		data
	}),
	userUpdateWorkingTemplateFailed: (data) => ({
		type: repricerActions.USER_UPDATE_WORKING_TEMPLATE_FAILED,
		data
	}),

	userSaveWorkingTemplate: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_TEMPLATE,
		data
	}),
	userSaveWorkingTemplateSuccess: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_TEMPLATE_SUCCESS,
		data
	}),
	userSaveWorkingTemplateFailed: (data) => ({
		type: repricerActions.USER_SAVE_WORKING_TEMPLATE_FAILED,
		data
	}),

	userLoadWorkingTemplate: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_TEMPLATE,
		data
	}),
	userLoadWorkingTemplateSuccess: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_TEMPLATE_SUCCESS,
		data
	}),
	userLoadWorkingTemplateFailed: (data) => ({
		type: repricerActions.USER_LOAD_WORKING_TEMPLATE_FAILED,
		data
	}),

	userLoadTemplates: (data) => ({
		type: repricerActions.USER_LOAD_TEMPLATES,
		data
	}),
	userLoadTemplatesSuccess: (data) => ({
		type: repricerActions.USER_LOAD_TEMPLATES_SUCCESS,
		data
	}),
	userLoadTemplatesFailed: (data) => ({
		type: repricerActions.USER_LOAD_TEMPLATES_FAILED,
		data
	}),

	workingTemplateInit: (data) => ({
		type: repricerActions.WORKING_TEMPLATE_INIT,
		data
	}),
	workingTemplateUpdateName: (data) => ({
		type: repricerActions.WORKING_TEMPLATE_UPDATE_NAME,
		data
	}),
	workingTemplateUpdateUpdatedAt: (data) => ({
		type: repricerActions.WORKING_TEMPLATE_UPDATE_UPDATED_AT,
		data
	}),
	workingTemplateUpdateJson: (data) => ({
		type: repricerActions.WORKING_TEMPLATE_UPDATE_JSON,
		data
	}),
	workingTemplateUpdateProp: (data) => ({
		type: repricerActions.WORKING_TEMPLATE_UPDATE_PROP,
		data
	}),
};

export default repricerActions;
