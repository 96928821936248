import React, { Component } from 'react';
import { Button, Nav, NavItem } from 'reactstrap';
import { IoArrowExpand as IconFullscreen } from "react-icons/lib/io";
import screenfull from "screenfull";
import { Link as LocalLink, scroller } from 'react-scroll';
import TutorialCard from "./TutorialCard";
import './style.css';

const content = [
  { title: 'Settings and Application Walkthrough', video_id: '8ocl6anjds' },
  { title: 'Creating your first batch in AccelerList', video_id: 'obvwknv1n1' },
  { title: 'Creating Condition Notes', video_id: 'clcx86mjqc' },
  { title: 'Using the Box Content Manager', video_id: '49nadwuuj9' },
  { title: 'Congratulations!' }
]

const content2 = [
  { title: 'Seller Central Introduction', video_id: 'clcx86mjqc' },
  { title: 'Deep Dive into Seller Central', video_id: '49nadwuuj9' },
  { title: 'Congratulations!' }
]

class Guide extends Component {
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  render() {
    return(
      <div className="view">
        <div className="view-content guide mt-0">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex">
                <h2 className="h4">Beginner's Guide</h2>
                <Button
                  color="link"
                  className="ml-3"
                  onClick={() => screenfull.toggle()}
                  >
                    Toggle fullscreen <IconFullscreen />
                </Button>
              </div>
              <p className="mb-0">Let’s learn everything you need to know about AccelerList</p>
            </div>
            <div>
              <p className="mb-1" id="tutorial-categories">View other category tutorials</p>
              <Nav pills className="nav-pills-boxed">
                <NavItem>
                  <LocalLink href="#" className="btn btn-link" to="tutorial-seller-central" smooth={true}>Seller Central</LocalLink>
                </NavItem>
                {/* <NavItem>
                  <LocalLink href="#" className="nav-link" to="tutorial-printing" smooth={true}>Printing</LocalLink>
                </NavItem>
                <NavItem>
                  <LocalLink href="#" className="nav-link" to="tutorial-shipment" smooth={true}>Shipment</LocalLink>
                </NavItem> */}
              </Nav>
            </div>
          </div>

          <TutorialCard content={content} />

          <div className="mt-5" id="tutorial-seller-central">
						<h3 className="h4 pt-2">Seller Central</h3>
            <TutorialCard content={content2} />
          </div>

          {/* <div className="mt-5" id="tutorial-printing">
            <h3 className="h4 pt-2">Printing</h3>

          </div>

          <div className="mt-5" id="tutorial-shipment">
            <h3 className="h4 pt-2">Shipment</h3>

          </div> */}
        </div>
      </div>
    );
  }
}

export default Guide;
