import React, { Component } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

class MissingItemsPopover extends Component {
	render() {
		const {
			isOpen,
			target,
			toggle,
		} = this.props;

		const tableColumns = [
			{
				dataField: "title", text: "Title", sort: true,
				formatter: (cell, row) => (
					<div className="d-flex gap-2 align-items-center">
						<div className="picture">
							<img src={row.img_url} alt="" />
						</div>
						<p
							className="mb-0"
						>
                {row?.title}
						</p>
					</div>
				)
			},
			{ dataField: "asin", text: "ASIN", sort: true, classes: "text-nowrap" },
			{ dataField: "sku", text: "SKU", sort: true, classes: "text-nowrap" },
			{ dataField: "qty", text: "QTY", sort: true }
		];

		return(
			<Popover isOpen={isOpen} target={target} toggle={toggle} placement="top" style={{ maxWidth: "50rem" }}>
				<PopoverHeader>Missing Items</PopoverHeader>
				<PopoverBody>
					<BootstrapTable
						data={this.props.data}
						keyField="asin"
						columns={tableColumns}
						bordered={false}
						wrapperClasses="table-responsive"
						classes="table-missing-items"
						pagination={paginationFactory({
							paginationSize: 10,
							sizePerPage: 10,
							sizePerPageList: [10, 20, 30, 40, 50]
						})}
					/>
				</PopoverBody>
			</Popover>
		);
	}
}

export default MissingItemsPopover;
