import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Button } from "reactstrap";
import IconArrowRight from 'react-icons/lib/md/chevron-right';
import IconPrint from 'react-icons/lib/md/print';
import { digitСonversion } from '../../../../../../../helpers/utility';
import './../ProfitLossTable/style.css';
import moment from 'moment';
import exportActions from '../../../../../../../redux/export/actions';
import PropTypes from "prop-types";

const { exportExpenses } = exportActions;

const init_categories = {
	'Refunds': {
		'Refund Cost': {	as_unit: false, data_point: 'totalRefundCost', },
		'Refunded Units': {	as_unit: true, data_point: 'totalRefunds', },
	},
	'Miscellaneous': {
		'Shipping Credits': { as_unit: false,	data_point: 'shippingCredits',	},
		'Shipping Credit Refunds': { as_unit: false,	data_point: 'shippingCreditRefunds',	},
		'Gift Wrap Credits': { as_unit: false,	data_point: 'giftWrapCredits',	},
		'Gift Wrap Credits Refunds': { as_unit: false,	data_point: 'giftWrapCreditRefunds',	},
		'Promotional Rebates': { as_unit: false,	data_point: 'promotionalRebates',	},
		'Promotional Rebates Refunds': { as_unit: false,	data_point: 'promotionalRebateRefunds',	},
		'A-to-Z Guarantee Claims': { as_unit: false,	data_point: 'guaranteeClaimsTotal',	},
		'Chargebacks': { as_unit: false,	data_point: 'chargebackTotal',	},
	},
	'Cost of Goods Sold': {
		'Total cost of goods sold': { as_unit: false,	data_point: 'cogs',	},
		//'Misc expenses': { as_unit: false,	data_point: 'miscExpenses',	},
	},
	'Selling Fees': {
		'FBA Selling Fees': { as_unit: false,	data_point: 'fbaSellingFees',	},
		'Misc expenses': { as_unit: false,	data_point: 'mfSellingFees',	},
		'Selling Fee Refunds': { as_unit: false,	data_point: 'sellingFeeRefunds',	},
	},
	'Transaction Fees': {
		'FBA Transaction Fees': { as_unit: false,	data_point: 'fbaTransactionFees',	},
		'FBA Transaction Fees Refunds': { as_unit: false,	data_point: 'fbaTransactionFeeRefunds',	},
		'Other Transaction Fees': { as_unit: false,	data_point: 'otherTransactionFees',	},
		'Other Transaction Fees Refunds': { as_unit: false,	data_point: 'otherTransactionFeeRefunds',	},
	},
	'Shipping Fees': {
		'FBA Inbound Shipping': { as_unit: false,	data_point: 'inboundShippingFees',	},
		'Merchant Fulfilled Label Purchases': { as_unit: false,	data_point: 'mfLabelPurchases',	},
		'Merchant Fulfilled Label Refunds': { as_unit: false,	data_point: 'mfLabelRefunds',	},
	},
	'Inventory Fees': {
		'Inventory Storage Fees': { as_unit: false,	data_point: 'inventoryStorageFees',	},
		'Long-Term Storage Fees': { as_unit: false,	data_point: 'longTermStorageFees',	},
		'FBA Disposal Fees': { as_unit: false, data_point: 'fbaDisposalFees',	},
		'FBA Return Fees': { as_unit: false, data_point: 'fbaReturnFees',	},
		'FBA Labeling Prep Fees': { as_unit: false,	data_point: 'fbaLabelingPrepFees',	},
		'Other Inventory Fees': { as_unit: false,	data_point: 'otherInventoryFees',	},
	},
	'Service Fees': {
		'Subscription Fees': { as_unit: false,	data_point: 'subscriptionFees',	},
		'Other Service Fees': { as_unit: false,	data_point: 'otherServiceFees',	},
	},
};

class ExpenseAnalysis extends Component {
	state = {
		data: [],
		shown_columns: ["jan", "feb", "mar", "apr", "may", "june",
		"july", "aug", "sept", "oct", "nov", "dec"],
		table_key: "table-" + Math.random() * 100
	}

	prepareData = (data) => {
		let res = [];
		let categories = init_categories;
		let misc_categories = {};
		let ebay_categories = {};
		Object.keys(this.props.misc_expenses_mapping).forEach(misc => {
			misc_categories[this.props.misc_expenses_mapping[misc].name] = { as_unit: false,  data_point: misc, };
		});
		Object.keys(this.props.ebay_expenses_mapping).forEach(ebay => {
			ebay_categories[this.props.ebay_expenses_mapping[ebay].name] = { as_unit: false,  data_point: ebay, };
		});
		categories['Misc expenses'] = misc_categories;
		categories['eBay expenses'] = ebay_categories;

		let categories_data = {};
		Object.keys(data).forEach(date => {
			Object.keys(data[date]).forEach(data_key => {
				categories_data[data_key] = {
					jan: 0,
					feb: 0,
					mar: 0,
					apr: 0,
					may: 0,
					jun: 0,
					jul: 0,
					aug: 0,
					sep: 0,
					oct: 0,
					nov: 0,
					dec: 0
				}
			})
		})
		const months = ["jan", "feb", "mar", "apr", "may", "jun",
			"jul", "aug", "sep", "oct", "nov", "dec"];
		let row = {}
		let child = {};

		//get month data
		Object.keys(data).forEach(date => {
			Object.keys(data[date]).forEach(data_key => {
				if((Object.keys(categories_data).includes(data_key)) && (Object.keys(data[date]).includes(data_key))){
					let date_check = moment(date, 'YYYY-MM-DD');
					let date_month = date_check.format('MMM').toLowerCase();
          if(data[date][data_key]){
  					categories_data[data_key][date_month] = categories_data[data_key][date_month] + data[date][data_key];
          }
				}
			});
		});

		Object.keys(categories).forEach(category => {
			row = {category: category, children: []};
			months.forEach(month => {row[month] = 0});
			Object.keys(categories[category]).forEach(child_cat => {
				child = {name: child_cat, as_unit: categories[category][child_cat].as_unit}
				months.forEach(month => {
					let data_point = categories[category][child_cat].data_point;
					if(data_point in categories_data){
						child[month] = categories_data[data_point][month];
						if(categories[category][child_cat].as_unit === false){
							row[month] = row[month] + categories_data[data_point][month];
						}
					}
				});
				row.children.push(child);
			});
			res.push(row);
		});

		return res;
	}

	componentDidMount() {
		//const currentMonth = moment().format('MMMM').toLocaleLowerCase();

		this.setState({
			data: this.prepareData(this.props.data),
			//shown_columns: [currentMonth],
			shown_columns: ["jan", "feb", "mar", "apr", "may", "jun",
			"jul", "aug", "sep", "oct", "nov", "dec"],
			table_key: "table-" + Math.random() * 100
		})
	}

	toggleColumn = (field) => {
		let shown_columns = this.state.shown_columns;

		if(shown_columns.includes(field)) {
			shown_columns = shown_columns.filter(item => item !== field);
		} else {
			shown_columns.push(field);
		}

		this.setState({
			shown_columns: shown_columns,
			table_key: "table-" + Math.random() * 100
		})
	}

	printExpenses = () => {
		const months_list = ["january", "february", "march", "april", "may", "june",
			"july", "august", "september", "october", "november", "december"]
		var months = [];
		months_list.forEach((item, i) => {
			if(this.state.shown_columns.includes(item)){
				months.push(i);
			}
		});
		this.props.exportExpenses(
			{
				data: this.state.data,
				months: months,
			}
		);
	}

	render() {
		const {
			data,
			shown_columns,
			table_key
		} = this.state;

		const currency_code = this.props.internationalization_config.currency_code;
		let showHideOptions = [];

		let columns = [
			{
				dataField: "category",
				text: "Expense",
				footer: "Total Expense",
				classes: 'col-category text-left',
				sort: true,
				formatter: (cell, row) => {
					if(row.children !== undefined) {
						return <React.Fragment><IconArrowRight size="20" className="icon-arrow-right" /> <span>{cell}</span></React.Fragment>
					}
					return cell;
				}
			}
		];

		if(data.length > 0) {
			Object.keys(data[0]).map(property => {
				if(property !== 'category' && property !== 'children') {
					columns.push({
						dataField: property,
						text: property,
						sort: true,
						hidden: !shown_columns.includes(property),
						footer: ( columnData ) => {
							let total = columnData.reduce( (acc, item) => acc + item, 0 );
							let totalMoney = digitСonversion(total, "currency", currency_code);
							return (
								<span
									className={total < 0 ? "text-danger" : total > 0 ? "text-success" : ""}
								>
									{totalMoney}
								</span>);
						},
						headerStyle: { textTransform: 'uppercase' },
						formatter: (cell, row) => (
							digitСonversion(cell, "currency", currency_code)
						),
					});
				}
				return null;
			});

			// generate show/hide options
			columns.map((item, idx) => {
				if(item.dataField !== "category") {
					showHideOptions.push(
						<DropdownItem key={`toggle-option-${idx}`} toggle={false}>
							<Label className="text-capitalize">
								<Input
									type="checkbox"
									checked={shown_columns.includes(item.dataField)}
									onChange={() => this.toggleColumn(item.dataField)}
								/> {item.text}
							</Label>
						</DropdownItem>
					)
				}

				return null;
			});
		}

		const expandRow = {
			renderer: row => (
				row.children !== undefined && (
					<table className="table table-subrow">
						<tbody>
							{
								row.children.map((item,idx) => (
									<tr key={`row-child-${idx}`}>
										{Object.keys(item).map(property => {
											if(property !== "as_unit") {
												if(property === "name") {
													return (
														<td key={`row-${idx}-${property}`}>
															{item[property]}
														</td>
													)
												} else if(shown_columns.includes(property)) {
													return (
														<td key={`row-${idx}-${property}`}>
															{
																item["as_unit"] ? item[property] : digitСonversion(item[property], "currency", currency_code)
															}
														</td>
													)
												}
											}
											return null;
										}
										)}
									</tr>
								))
							}
						</tbody>
					</table>
				)
			),
			onExpand: (row, isExpand, rowIndex, e) => {
				if(isExpand) {
					e.currentTarget.classList.add('expanded');
				} else {
					e.currentTarget.classList.remove('expanded');
				}
			}
		}

		const rowClasses = (row, rowIndex) => {
			let classes = null;

			if (row.children === undefined) {
				classes = 'no-children';
			} else {
				classes = 'has-children'
			}

			return classes;
		};

		return (
			<React.Fragment>
				<div className="d-flex justify-content-end mb-3">
					<Button
						color="primary"
						className="mr-2"
						disabled={this.props.printAccountingExpenses}
						onClick={this.printExpenses}
					><IconPrint /> Print</Button>
					<UncontrolledDropdown className="dropdown-checkbox" direction="down">
						<DropdownToggle>Show/Hide Months</DropdownToggle>
						<DropdownMenu right>
							{showHideOptions}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<div className="pl-table-quarters">
					<div><span>Amount</span></div>
				</div>
				<BootstrapTable
					key={table_key}
					keyField="category"
					columns={columns}
					data={data}
					expandRow={expandRow}
					rowClasses={rowClasses}
					classes="table-profit-loss mb-5"
					hover
				/>
			</React.Fragment>
		);
		}
}

ExpenseAnalysis.propTypes = {
	data: PropTypes.array.isRequired,
	misc_expenses_mapping: PropTypes.object.isRequired,
};

export default connect(
	state => ({
		internationalization_config: state.Auth.get("internationalization_config"),
		printAccountingExpenses: state.Export.get("printAccountingExpenses"),
	}),
	{
		exportExpenses,
	}
)(ExpenseAnalysis);
