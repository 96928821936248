import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Badge, Button } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { EditorState, convertFromRaw, convertToRaw, } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

class SopItem extends Component {

  displayCategory = (category) => {
    if(category){
      return {label: category.name, value: category.id}
    }
    return null;
  }

  updateCategory = (preocedure_id, category) => {
    const data = {
      procedure_id: preocedure_id,
      category: category.value,
    }
    this.props.updateSopProcedure(data);
  }

  exportProcedure = (data) => {
    const description = EditorState.createWithContent(convertFromRaw(JSON.parse(data.description)));
    let html = draftToHtml(convertToRaw(description.getCurrentContent()))
    html = `<span style="font-size: 48px;"><strong>${data.title}</strong></span><br /><b>Category: ${data.category_data.name}</b><br /><div>Tags: ${data.tags_data.map(item => (item.name)).join(' | ')}</div><hr /><br />${html}`
    const req = {
      html: html,
      name: data.title,
    }
    this.props.exportSopProcedurePdf(req);
  }

	render () {
		const {
			data,
      onDelete,
      categories,
      export_procedure_working,
		} = this.props;

		return (
			<div className="sop-item">
				<div className="sop-left">
					<h4 className="sop-title">
						<NavLink
							className="title-text"
							to={`/dashboard/sop/detail/${data.id}`}
						>
							{data.title}
						</NavLink>
						<span className="controls">
              {/*
							{data.favorite ? <FaStar className="icon icon-favorite active" /> : <FaStarO className="icon icon-favorite" />}
              */}
              <Button
                className="icon icon-doc"
                disabled={export_procedure_working}
                onClick={() => this.exportProcedure(data)}
              ><span className="icon icon-16 icon-pdf"></span></Button>
              <NavLink className="icon" target="_blank" to={`/sop/view/${data.id}`}><span className="icon icon-link-external"></span></NavLink>
						</span>
					</h4>
					<ul className="sop-tag-list">
            {data.tags_data.map(item => { return <li key={`sop-item-${item.id}`}><Badge className="sop-tag">#{item.name}</Badge></li> })}
					</ul>
				</div>
        <div className="sop-right">
          <div className="sop-last-updated">
            {moment(data.updated_at * 1000).format("MM/DD/YYYY")}
          </div>
          <div className="sop-category" style={{ minWidth: '11rem' }}>
            <Select
              options={
                categories.map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                    item: item,
                  }
                })
              }
              onChange={e => this.updateCategory(data.id, e)}
              value={this.displayCategory(categories.find(item => item.id === data.category))}
              className="Select-sm"
              clearable={false}
            />
          </div>
          <div className="sop-actions">
            <NavLink to={`/dashboard/sop/edit/${data.id}`}><span className="icon icon-edit"></span></NavLink>
            <Button color="link" onClick={onDelete}><span className="icon icon-trash bg-danger"></span></Button>
          </div>
        </div>
			</div>
		)
	}
}

export default SopItem;
