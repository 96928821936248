import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  shipmentList: null,
  shipmentListError: null,
  loadingList: false,
  loadingShipment: false,
  loadingAddBox: false,
  selectedShipments: [],
  selectedShipmentsData: [],
  selectedShipment: null,
  selectedShipmentError: false,
  selectedShipmentBox: null,
  currentAlert: null,
  query: "",
  searchingProduct: false,
  searchErrorType: null,
  searchErrorData: [],
  addingProduct: false,
  selectedSearchItem: null,
  page: 1,
  limit: 50,
  boxDialogPage: 1,
	boxDialogLimit: 5,
	is_printing_manifest: false,
  select_shipment_request_job_id: "",
  select_shipment_request_job_started: false,
  select_shipment_request_job_finished: true,
  select_shipment_request_job_total: 0,
  select_shipment_request_job_progress: 0,
  upload_file_shipment_response: "",
  shipment_box_item_weight_response: null,
  adding_product_to_box_fuccess: null,
});


export default function boxContentReducer(state = initState, action) {
  switch (action.type) {

    case actions.ADDING_PRODUCT_TO_BOX_SUCCESS_RESET_SUCESS:
      return state
        .set("adding_product_to_box_fuccess", null)

    case actions.TRACK_SHIPMENT_REQUEST_JOB_SUCCESS:
      const track_query = state.get("query");
      return state
        .set("select_shipment_request_job_id", !action.data.finished ? action.data.job_id : "")
        .set("select_shipment_request_job_total", action.data.total)
        .set("select_shipment_request_job_progress", action.data.completed)
        .set("select_shipment_request_job_finished", action.data.finished)
        .set("loadingShipment", action.data.processing)
        .set("addingProduct", action.data.processing)
        .set("query", action.data.processing ? track_query : "");
    case actions.TRACK_SHIPMENT_REQUEST_JOB_ERROR:
      return state
        .set("select_shipment_request_job_id", "")
        .set("select_shipment_request_job_started", false)
        .set("select_shipment_request_job_finished", true)
        .set("select_shipment_request_job_total", 0)
        .set("select_shipment_request_job_progress", 0)

    case actions.SELECT_SHIPMENT_REQUEST_JOB:
      return state
        .set("select_shipment_request_job_id", "")
        .set("select_shipment_request_job_started", true)
        .set("select_shipment_request_job_finished", false)
        .set("select_shipment_request_job_total", 0)
        .set("select_shipment_request_job_progress", 0)
        .update('selectedShipments', arr => arr.push(fromJS(action.data)))
        .set("loadingShipment", true)
        .set("selectedShipmentError", false);
    case actions.SELECT_SHIPMENT_REQUEST_JOB_SUCCESS:
      return state
        .set("select_shipment_request_job_id", action.data.job_id)
        .set("select_shipment_request_job_started", false)
    case actions.SELECT_SHIPMENT_REQUEST_JOB_ERROR:
      return state
        .set("select_shipment_request_job_id", "")
        .set("select_shipment_request_job_started", false)
        .set("select_shipment_request_job_finished", true)

    case actions.CHANGE_BOX_DIALOG_PAGINATION_LIMIT:
      return state
        .set('boxDialogPage', action.page)
        .set('boxDialogLimit', action.limit);
    case actions.CHANGE_PAGINATION_LIMIT:
      return state
        .set('page', action.page)
        .set('limit', action.limit);
    case actions.CHOOSE_SEARCH_ITEM:
      return state
        .set("selectedSearchItem", action.item)
        .set("searchErrorType", null)
        .set("searchErrorData", []);
    case actions.LOAD_SHIPMENT_LIST_REQUEST:
      return state
        .set("shipmentListError", null)
        .set("loadingList", true);
    case actions.LOAD_SHIPMENT_LIST_SUCCESS:
      return state
        .set("shipmentList", fromJS(action.shipmentList))
        .set("shipmentListError", false)
        .set("loadingList", false);
    case actions.LOAD_SHIPMENT_LIST_ERROR:
      return state
        .set("shipmentListError", true)
        .set("loadingList", false);
    case actions.UPDATE_SELECTED_SHIPMENT_READY_FLAG:
      return state
        .updateIn(["selectedShipmentsData", action.shipmentIndex, 'isReady'], value => action.flag);
    case actions.UPDATE_SELECTED_SHIPMENT_BOX:
      return state
        .updateIn(["selectedShipmentsData", action.shipmentIndex, 'currentBox'], value => action.box);
    case actions.REMOVE_SELECTED_SHIPMENT:
      return state
        .deleteIn(['selectedShipmentsData', action.dataIndex])
        .deleteIn(['selectedShipments', action.shipmentIndex])
    case actions.SELECT_SHIPMENT_REQUEST:
      return state
        .update('selectedShipments', arr => arr.push(fromJS(action.selectedShipment)))
        .set("loadingShipment", true)
        .set("selectedShipmentError", false);
    case actions.SELECT_SHIPMENT_SUCCESS: {
      let currentBox;
      let {selectedShipmentData, selectedShipment} = action;
      if(!!selectedShipmentData.current_box_id) {
        let index = selectedShipmentData.boxes.findIndex((box) => {
          if (selectedShipmentData && selectedShipmentData.current_box_id) {
            return box.id === Number(selectedShipmentData.current_box_id);
          }
          return false;
        })
        currentBox = selectedShipmentData.boxes[index];
      }
      return state
        .update("selectedShipmentsData", arr => arr.push(fromJS({
          boxes: selectedShipmentData.boxes,
          inboundShipmentItems:
            selectedShipmentData.inbound_shipment_items,
          selectedShipment: selectedShipment,
          currentBox: currentBox,
          isReady: false,
          inputToAsinListMapping: selectedShipmentData.input_to_asin_list_mapping,
          asinToSearchResultDataMapping: selectedShipmentData.asin_to_search_result_data_mapping,
          additionalData: selectedShipmentData.sku_additional_data_mapping,
          warning: selectedShipmentData.warning
        })))
        .set("loadingShipment", false)
        .set("addingProduct", false)
        .set("query", "");
    }

    case actions.MOVE_ITEM_SUCCESS: {
      let selectedShipmentsData = state.get("selectedShipmentsData").toJS();
      let index = selectedShipmentsData.findIndex((shipment) => {
        return shipment.selectedShipment.ShipmentId === action.shipmentId;
      })
      return state
        .updateIn(["selectedShipmentsData", index], (shipment) => {
          let output = Object.assign({}, shipment.toJS(), {
            boxes: action.boxes
          });
          return fromJS(output);
        })
        .set("loadingShipment", false)
        .set("addingProduct", false)
        .set("query", "");
    }

    case actions.SELECT_SHIPMENT_ERROR:
      return state
        .set("loadingShipment", false)
        .set("selectedShipmentError", true)
        .set("addingProduct", false)
    case actions.SELECT_SHIPMENT_BOX:
      return state.set("selectedShipmentBox", action.selectedShipmentBox);
    case actions.ADD_SHIPMENT_BOX_REQUEST:
      return state.set("loadingAddBox", true);
    case actions.ADD_SHIPMENT_BOX_ERROR:
      return state.set("loadingAddBox", false);
    case actions.ADD_SHIPMENT_BOX_SUCCESS: {
      let selectedShipmentsData = state.get("selectedShipmentsData").toJS();
      let index = selectedShipmentsData.findIndex((shipment) => {
        return shipment.selectedShipment.ShipmentId === action.shipmentId;
      })
      let newShipment = selectedShipmentsData[index];
      newShipment.boxes.push(action.shipmentBox);
      newShipment.currentBox = action.shipmentBox;
      return state
        .updateIn(["selectedShipmentsData", index], () => fromJS(newShipment))
        .set("loadingAddBox", false);
    }
    case actions.CLOSE_ALERT:
      return state.set("currentAlert", null);
    case actions.SHOW_ALERT:
      return state.set("currentAlert", action.alert);
    case actions.UPDATE_QUERY:
      return state.set("query", action.query);
    case actions.SEARCH_PRODUCT_REQUEST:
      return state.set("searchingProduct", true)
        .set("searchErrorType", null)
        .set("searchErrorData", [])
        .set("selectedSearchItem", null)
    case actions.SEARCH_PRODUCT_SUCCESS:
      return state.set("searchingProduct", false)
      .set("searchErrorType", null)
      .set("searchErrorData", [])
    case actions.SEARCH_PRODUCT_ERROR:
      return state
        .set("searchingProduct", false)
        .set("searchErrorType", action.searchErrorType)
        .set("searchErrorData", action.searchErrorData);
    case actions.ADD_PRODUCT_REQUEST:
      return state.set("addingProduct", true);
    case actions.ADD_PRODUCT_SUCCESS:
      return state
        .set("addingProduct", false)
        .set("adding_product_to_box_fuccess", action.boxId)
        .update("selectedShipmentsData", selectedShipmentsData => {
          return selectedShipmentsData.map(selectedShipmentData => {
            let selectedShipmentDataJS = selectedShipmentData.toJS();
            return fromJS({
              ...selectedShipmentDataJS,
              boxes: selectedShipmentDataJS.boxes.map(box => {
                box = Object.assign({}, box);
                if (box.id === action.boxId) {
                  box.items.unshift(action.product);
                }
                return box;
              })
            })
          })
        });

    case actions.ADD_PRODUCT_ERROR:
      return state
        .set("addingProduct", false);

    case actions.UPDATE_PRODUCT_SUCCESS:
      return state
        .set("addingProduct", false)
        .set("adding_product_to_box_fuccess", action.boxId)
        .update("selectedShipmentsData", selectedShipmentsData => {
          return selectedShipmentsData.map(selectedShipmentData => {
            let selectedShipmentDataJS = selectedShipmentData.toJS();
            return fromJS({
              ...selectedShipmentDataJS,
              boxes: selectedShipmentDataJS.boxes.map(box => {
                if (box.id === action.boxId) {
                  box.items = box.items.map(product => {
                    if (product.BoxContentItemId === action.itemId) {
                      product = {
                        ...product,
                        ...action.updatedData,
                      }
                    }
                    return product;
                  })
                }
                return box;
              })
            });
          })
        });

    case actions.CLEAR_ADD_PRODUCT_ERROR:
      return state
        .set("searchErrorType", null)
        .set("searchErrorData", []);

    case actions.DEL_PRODUCT_REQUEST_SUCCESS:
      return state
        .update("selectedShipmentsData", selectedShipmentsData => {
          return selectedShipmentsData.map(selectedShipmentData => {
            let selectedShipmentDataJS = selectedShipmentData.toJS();
            return fromJS({
              ...selectedShipmentDataJS,
              boxes: selectedShipmentDataJS.boxes.map(box => {
                if (box.id === action.boxId) {
                  const itemsArray = box.items.filter(el => el.BoxContentItemId !== action.itemId);
                  let buffBox = box;
                  buffBox.items = itemsArray;
                  return buffBox;
                } else {
                  return box;
                }
              })
            });
          })
        });

    case actions.BOX_PRINT_MANIFEST:
      return state
        .set("is_printing_manifest", true)
    case actions.BOX_PRINT_MANIFEST_SUCCESS:
      return state
        .set("is_printing_manifest", false)
    case actions.BOX_PRINT_MANIFEST_FAILED:
      return state
        .set("is_printing_manifest", false)
    case actions.BOX_REFRESH:
      state = initState
      return state

      case actions.UPLOAD_FILE_SHIPMENT:
        return state
          .set("upload_file_shipment_response", action.data)
      case actions.UPLOAD_FILE_SHIPMENT_SUCCESS:
        return state
          .set("upload_file_shipment_response", action.data)
      case actions.UPLOAD_FILE_SHIPMENT_ERROR:
        return state
          .set("upload_file_shipment_response", action.data)

      case actions.SHIPMENT_BOX_ITEM_WEIGHT:
        return state
          .set("shipment_box_item_weight_response", action.data)
      case actions.SHIPMENT_BOX_ITEM_WEIGHT_SUCCESS:
        var shipment_update_weight = [...state.get("selectedShipmentsData").toJS()];
        shipment_update_weight = shipment_update_weight.map(shipment => {
          if(shipment.boxes.map(item => {return item.id}).includes(action.data.data.BoxId)){
            shipment.boxes = shipment.boxes.map(box => {
              if(box.id === action.data.data.BoxId){
                box.items = box.items.map(item => {
                  if(item.id === action.data.BoxContentItemId){
                    return action.data.data;
                  }
                  return item;
                })
              }
              return box;
            })
          }
          return shipment;
        })
        return state
          .set("shipment_box_item_weight_response", action.data)
          .set("selectedShipmentsData", fromJS(shipment_update_weight))
      case actions.SHIPMENT_BOX_ITEM_WEIGHT_ERROR:
        return state
          .set("shipment_box_item_weight_response", action.data)

    default:
      return state;
  }
}
