import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import BoxContentModal from "../BoxContentModal"
import IconBox from "react-icons/lib/io/ios-box";
import fulfillmentActions from "../../../redux/fulfillment/actions";
import CreateNewBoxModal from "../CreateNewBoxModal";
import { secureProtocolImgURL } from "../../../helpers/utility";
const {
  fulfillmentBoxesLoadAll,
} = fulfillmentActions;

class PackGroupModal extends Component {
  state = {
    showCreateNewBoxModal: false,
    showBoxContentModal: false,
    box_packing_group_id: null,
    active_box: null,
    active_box_idx: null,
  }

  componentDidMount = () => {
    this.props.fulfillmentBoxesLoadAll({ batch: this.props.batchMetadata.id })
  }

  calculateHeaderGroup = (data) => {
    const units = data?.items.reduce((accumulator, current) => accumulator + current.quantity, 0);
    return `${data.items.length} SKUs (${units} unit${units < 1 ? "" : "s"})`;
  }

  calculateBoxItemsCount = (data) => {
    const units = data?.items?.reduce((accumulator, current) => accumulator + current.quantity, 0);
    return units;
  }

  render() {
    const {
      isOpen,
      toggle,
      box_data,
      batchMetadata,
      boxes,
    } = this.props

    if(!batchMetadata?.inbound_packing_groups_data){ return null; }

    return (
      <Modal isOpen={isOpen} centered size="lg" style={{ maxWidth:'1200px' }} scrollable>
        <ModalHeader toggle={toggle}>Manage Boxes</ModalHeader>
        <ModalBody>
          <p className="mb-4">Add your items to the boxes for each packing group.</p>
          <div className="d-flex flex-column gap-4">
            {Object.keys(batchMetadata?.inbound_packing_groups_data).map((key, idx) => {
              return (
                <Card key={key}>
                  <CardHeader className="d-flex align-items-center gap-4 px-3 py-2">
                    <CardTitle className="mb-0">Pack Group {`${idx + 1}`}</CardTitle>
                    <span>{this.calculateHeaderGroup(batchMetadata?.inbound_packing_groups_data[key])}</span>
                  </CardHeader>
                  <CardBody className="px-3 py-0 d-flex">
                    <div className="d-flex flex-wrap gap-4 py-2" style={{ flexBasis: '50%' }}>
                      {batchMetadata?.inbound_packing_groups_data[key]?.items?.map(item => {
                        return (
                          <div key={item.asin}className="text-center">
                            <img
                              src={item.image_url
                                ? secureProtocolImgURL(item.image_url)
                                : `https://www.amazon.com/images/P/${item.asin}.01._THUMBZZZ_.jpg`
                              }
                              alt="" height={40} className="mb-1"
                            />
                            <span className="d-block">ASIN: {item.asin}</span>
                            <span className="d-block">({item.quantity})</span>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex align-items-center gap-3 flex-wrap border-left pl-3 py-2" style={{ flexBasis: '50%' }} >
                      {boxes.length === 0
                        ? <div>No box(es) for this group yet.</div>
                        : boxes.map((item, idx) => {
                            return (
                              <div
                                key={`box-packing-${idx}`}
                                className="mb-2 text-center"
                                role="button"
                                onClick={() => this.setState({
                                  showBoxContentModal: true,
                                  active_box: item,
                                  active_box_idx: idx,
                                })}
                              >
                                <IconBox size="30" />
                                <span className="d-block">Box {`${idx + 1}`} ({this.calculateBoxItemsCount(item)})</span>
                              </div>
                            )
                          })
                      }
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.setState({
                          showCreateNewBoxModal: true,
                          box_packing_group_id: key,
                          active_box: null,
                        })}
                      >Add Box</Button>
                    </div>
                  </CardBody>
                </Card>
              )
            })}
          </div>
          {this.state.showCreateNewBoxModal &&
          <CreateNewBoxModal
            isOpen={true}
            toggle={() => this.setState({ showCreateNewBoxModal: false })}
            batchMetadata={batchMetadata}
            packing_group_id={this.state.box_packing_group_id}
          />
        }
          {this.state.showBoxContentModal &&
          <BoxContentModal
            isOpen={true}
            toggle={() => this.setState({
              showBoxContentModal: false,
              active_box: null,
            })}
            batchMetadata={batchMetadata}
            packing_group_id={this.state.box_packing_group_id}
            active_box={this.state.active_box}
            active_box_idx={this.state.active_box_idx}
            boxes={boxes}
          />
          }
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end gap-3">
          <Button color="primary" onClick={toggle}>Done</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect(
  state => ({
		userData: state.Auth.get('userData'),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    boxes: state.Fulfillment.get("boxes"),
  }),
  {
    fulfillmentBoxesLoadAll,
  }
)(PackGroupModal);
