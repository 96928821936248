import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import './style.css';
import { PillIcon } from "./components";

/**
 * Custom alert component
 *
 * @version 1.0.0
 * @author [Tedi Kurniadi]
 * @author [bojan]
 */
class AlertPill extends Component {

  render() {
    const {
		color,
		icon,
		children,
		isOpen,
		onDismiss,
		outline,
		size,
		soft
    } = this.props;

    return(
		<Alert
			color={color}
			className={`alert-pill ${outline !== undefined ? 'alert-outline' : '' } ${size !== undefined ? `alert-${size}` : '' } ${soft !== undefined ? 'alert-soft' : ''}`}
			isOpen={isOpen !== undefined ? isOpen : true}
			toggle={onDismiss}
		>
			<span className="icon"><PillIcon icon={icon} /></span>
			<span className="text">{children}</span>
		</Alert>
    );
  }
}

AlertPill.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.string
}

export default AlertPill;
