import React, { Component } from 'react';
import './style.css';
import { digitСonversion } from "../../../../helpers/utility";
import TooltipAtCustomElement from '../../../../shared/components/TooltipAtCustomElement';

const IconTooltip = () => <span className="icon icon-help-circle"></span>

class MetricBoxItem extends Component {
  render() {
    const {
      id,
      tooltipText,
      title,
      data,
      className,
      internationalization_config,
    } = this.props;

    const amazon_sales = digitСonversion(data.amazon.sales, "currency", internationalization_config.currency_code);
    const ebay_sales = digitСonversion(data.ebay.sales, "currency", internationalization_config.currency_code);
    const orders_count = data.amazon.orders_count + data.ebay.orders_count;
    const units_count = data.amazon.units_count + data.ebay.units_count;
    const refunds_count = data.amazon.refunds_count + data.ebay.refunds_count;
    const other = <React.Fragment>
      <td><span className="metric-value">{digitСonversion(orders_count, 'decimal')}</span></td>
      <td><span className="metric-value">{digitСonversion(units_count, 'decimal')}</span></td>
      <td><span className="metric-value">{digitСonversion(refunds_count, 'decimal')}</span></td>
      </React.Fragment>

    return (
      <div className={`metric-box-item ${className}`}>
        <div className="header">
          <h3 className="title">{title}</h3>
          <span className="help" data-toggle="tooltip">
            <TooltipAtCustomElement
              tooltipId={id}
              tooltipText={tooltipText}
              CustomElement={IconTooltip}
              styles={{ maxWidth: "20rem" }}
            />
          </span>
        </div>
        <div className="content">
          <div className="d-flex justify-content-between">
            <div>
              <div className="metric-mini">
                <h4 className="metric-title">Amazon Sales</h4>
                <span className="metric-value">{amazon_sales}</span>
              </div>
            </div>
            <div>
              <div className="metric-mini">
                <h4 className="metric-title">eBay Sales</h4>
                <span className="metric-value">{ebay_sales}</span>
              </div>
            </div>
            <div>
              <div className="metric-mini">
                <table className="table table-metrics">
                  <thead>
                    <tr>
                      <th><h4 className="metric-title">Orders</h4></th>
                      <th><h4 className="metric-title">Units</h4></th>
                      <th><h4 className="metric-title">Refund</h4></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>{other}</tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MetricBoxItem;
