import {
	stringToBool,
	stringToFloat,
} from '../../template_utils';

import {
	mapRepricerBackendConditionsToAL,
} from '../../utility';

export function templateConvertBackendToFrontend(template){
	var frontendTemplate = {};
	template.template_json = JSON.parse(template.template_json);
	frontendTemplate = generateMeta(frontendTemplate, template);
	frontendTemplate = generateItemTargeting(frontendTemplate, template);
	frontendTemplate = generateRepriceRuleForFailedReprice(frontendTemplate, template);
	frontendTemplate = generatePricingRules(frontendTemplate, template);
	frontendTemplate = generateOffersTargeting(frontendTemplate, template);
	frontendTemplate = generateOfferCount(frontendTemplate, template);
	frontendTemplate = generateRepriceUsedAginstNew(frontendTemplate, template);
	return frontendTemplate;
}

function generateMeta(frontendTemplate, template){
	frontendTemplate.name = template.template_json.meta.name;
	frontendTemplate.user_id = template.user_id;
	frontendTemplate.created_at = template.created_at;
	frontendTemplate.updated_at = template.created_at;
	frontendTemplate.priority = template.priority;
	frontendTemplate.type = "tc";
	frontendTemplate.template_json = {
		template_id: template.id,
		name: template.template_json.meta.name,
		type: "custom_template_v8",
	}
	return frontendTemplate;
}

function generateItemTargeting(frontendTemplate, template){
	if(template.template_json.item_targeting){
		const item_targeting = template.template_json.item_targeting;
		frontendTemplate.template_json.user_inventory_channel_target = item_targeting.channel;
		frontendTemplate.template_json.user_inventory_skus_to_exclude = item_targeting.skus_to_exclude;
		frontendTemplate.template_json.user_inventory_skus_to_include = item_targeting.skus_to_include;
		frontendTemplate.template_json.exclude_items_with_sales_rank_above = item_targeting.exclude_items_with_sales_rank_above;
		frontendTemplate.template_json.user_inventory_product_salesrank_max = item_targeting.max_salesrank;
		frontendTemplate.template_json.user_inventory_product_salesrank_min = item_targeting.min_salesrank;
		frontendTemplate.template_json.user_inventory_product_price_max = stringToFloat(item_targeting.max_price_to_target);
		frontendTemplate.template_json.user_inventory_product_price_min = stringToFloat(item_targeting.min_price_to_target);
		frontendTemplate.template_json.user_inventory_inventory_age_max = item_targeting.max_inventory_age;
		frontendTemplate.template_json.user_inventory_inventory_age_min = item_targeting.min_inventory_age;
		frontendTemplate.template_json.user_inventory_condition_to_reprice = mapRepricerBackendConditionsToAL(item_targeting.conditions_to_include);
		frontendTemplate.template_json.user_inventory_sku_list_to_exclude_id = item_targeting.sku_list_to_exclude_id
		frontendTemplate.template_json.user_inventory_sku_list_to_include_id = item_targeting.sku_list_to_include_id;
	}
	return frontendTemplate;
}

function generateRepriceRuleForFailedReprice(frontendTemplate, template){
	if(!template.template_json.reprice_rule_for_failed_reprice){
		return frontendTemplate;
	}
	const reprice_rule_for_failed_reprice = template.template_json.reprice_rule_for_failed_reprice;
	frontendTemplate.template_json.reprice_rule_for_failed_reprice_no_competing_offer_exist_activate = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_activate);
	frontendTemplate.template_json.reprice_rule_for_failed_reprice_none_of_competing_offer_meet_requirement_activate = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_activate);
	frontendTemplate.template_json.reprice_rule_for_failed_reprice_general_failed_activate = stringToBool(reprice_rule_for_failed_reprice.general_failed_activate);

	if(reprice_rule_for_failed_reprice.no_competing_offer_exist_activate === "true"){
		frontendTemplate.template_json.no_competing_offer_exist_rule_use_adjustment_if_true_use_default_price_if_false = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.use_adjustment_if_true_use_default_price_if_false);
		frontendTemplate.template_json.no_competing_offer_exist_rule_price_guard_activate = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.price_guard_activate);
		if(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.adjustment_rule.adjustment_amount_on_your_own_offer){
			frontendTemplate.template_json.no_competing_offer_exist_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.adjustment_rule.adjustment_amount_on_your_own_offer);
			frontendTemplate.template_json.no_competing_offer_exist_rule_fix_price_adjustment_if_true_percentage_if_false = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.adjustment_rule.fix_price_adjustment_if_true_percentage_if_false);
			frontendTemplate.template_json.no_competing_offer_exist_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.adjustment_rule.static_method_adjust_up_if_true_down_if_false);
		}
		if(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.default_price_rule.price){
			frontendTemplate.template_json.no_competing_offer_exist_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.default_price_rule.price);
			frontendTemplate.template_json.no_competing_offer_exist_rule_fix_price_adjustment_if_true_percentage_if_false = true;
		}
		if(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.price_guard_activate){
			if(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.price_guard_rule){
				frontendTemplate.template_json.no_competing_offer_exist_rule_price_guard_rule_min_price = stringToFloat(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.price_guard_rule.min_price);
				frontendTemplate.template_json.no_competing_offer_exist_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.price_guard_rule.default_min_inventory_price_to_buy_cost_plus_fee);
			} else {
				frontendTemplate.template_json.no_competing_offer_exist_rule_price_guard_rule_min_price = 1;
				frontendTemplate.template_json.no_competing_offer_exist_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = false;
			}
		}
	} else {
		frontendTemplate.template_json.no_competing_offer_exist_rule = {};
	}

	if(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_activate === "true"){
		frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_use_adjustment_if_true_use_default_price_if_false = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.use_adjustment_if_true_use_default_price_if_false);
		frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_activate = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.price_guard_activate);
		if(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.adjustment_rule.adjustment_amount_on_your_own_offer){
			frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.adjustment_rule.adjustment_amount_on_your_own_offer);
			frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_fix_price_adjustment_if_true_percentage_if_false = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.adjustment_rule.fix_price_adjustment_if_true_percentage_if_false);
			frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.adjustment_rule.static_method_adjust_up_if_true_down_if_false);
		}
		if(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.default_price_rule.price){
			frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.default_price_rule.price);
			frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_fix_price_adjustment_if_true_percentage_if_false = true;
		}
		if(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.price_guard_activate){
			if(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.price_guard_rule){
				frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_min_price = stringToFloat(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.price_guard_rule.min_price);
				frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = stringToBool(reprice_rule_for_failed_reprice.none_of_competing_offer_meet_requirement_rule.price_guard_rule.default_min_inventory_price_to_buy_cost_plus_fee);
			} else {
				frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_min_price = 1;
				frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = false;
			}
		}
	} else {
		frontendTemplate.template_json.none_of_competing_offer_meet_requirement_rule = {};
	}

	if(reprice_rule_for_failed_reprice.general_failed_activate === "true"){
		frontendTemplate.template_json.general_failed_rule_use_adjustment_if_true_use_default_price_if_false = stringToBool(reprice_rule_for_failed_reprice.no_competing_offer_exist_rule.use_adjustment_if_true_use_default_price_if_false);
		frontendTemplate.template_json.general_failed_rule_price_guard_activate = stringToBool(reprice_rule_for_failed_reprice.general_failed_rule.price_guard_activate);
		if(reprice_rule_for_failed_reprice.general_failed_rule.adjustment_rule.adjustment_amount_on_your_own_offer){
			frontendTemplate.template_json.general_failed_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.general_failed_rule.adjustment_rule.adjustment_amount_on_your_own_offer);
			frontendTemplate.template_json.general_failed_rule_fix_price_adjustment_if_true_percentage_if_false = stringToBool(reprice_rule_for_failed_reprice.general_failed_rule.adjustment_rule.fix_price_adjustment_if_true_percentage_if_false);
			frontendTemplate.template_json.general_failed_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false = stringToBool(reprice_rule_for_failed_reprice.general_failed_rule.adjustment_rule.static_method_adjust_up_if_true_down_if_false);
		}
		if(reprice_rule_for_failed_reprice.general_failed_rule.default_price_rule.price){
			frontendTemplate.template_json.general_failed_rule_adjustment_amount_on_your_own_offer = stringToFloat(reprice_rule_for_failed_reprice.general_failed_rule.default_price_rule.price);
			frontendTemplate.template_json.general_failed_rule_fix_price_adjustment_if_true_percentage_if_false = true;
		}
		if(reprice_rule_for_failed_reprice.general_failed_rule.price_guard_activate){
			if(reprice_rule_for_failed_reprice.general_failed_rule.price_guard_rule){
				frontendTemplate.template_json.general_failed_rule_price_guard_rule_min_price = stringToFloat(reprice_rule_for_failed_reprice.general_failed_rule.price_guard_rule.min_price);
				frontendTemplate.template_json.general_failed_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = stringToBool(reprice_rule_for_failed_reprice.general_failed_rule.price_guard_rule.default_min_inventory_price_to_buy_cost_plus_fee);
			} else {
				frontendTemplate.template_json.general_failed_rule_price_guard_rule_min_price = 1;
				frontendTemplate.template_json.general_failed_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee = false;
			}
		}
	} else {
		frontendTemplate.template_json.general_failed_rule = {};
	}

	return frontendTemplate;
}

function generatePricingRules(frontendTemplate, template){
	if(!template.template_json.pricing_rules){
		return frontendTemplate;
	}
	const pricing_rules = template.template_json.pricing_rules;
	frontendTemplate.template_json.pricing_rules_use_regular_repricing_strategy_against_amazon = stringToBool(pricing_rules.use_regular_repricing_strategy_against_amazon)
	frontendTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy = stringToBool(pricing_rules.use_if_you_are_the_buy_box_repricing_strategy);
	frontendTemplate.template_json.guards_setMinROI = stringToBool(pricing_rules.is_roi_guard_activate);
	frontendTemplate.template_json.guards_drop_percentage = stringToBool(pricing_rules.percentage_price_decrease_guard_activate);
	frontendTemplate.template_json.guards_increase_price = stringToBool(pricing_rules.percentage_price_increase_guard_activate);
	frontendTemplate.template_json.pricing_rules_dont_price_above_featured_merchants = stringToBool(pricing_rules.dont_price_above_featured_merchants);

	if(pricing_rules.amazon_adjustment){
		frontendTemplate.template_json.pricing_rules_amazon_adjustment_adjustment_amount_on_competing_offer = stringToFloat(pricing_rules.amazon_adjustment.adjustment_amount_on_competing_offer);
		frontendTemplate.template_json.pricing_rules_amazon_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(pricing_rules.amazon_adjustment.static_method_adjust_up_if_true_down_if_false);
		frontendTemplate.template_json.pricing_rules_amazon_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(pricing_rules.amazon_adjustment.fix_price_adjustment_if_true_percentage_if_false);
	}

	if(pricing_rules.use_if_you_are_the_buy_box_repricing_strategy === "true"){
		template.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice = stringToBool(pricing_rules.if_you_are_the_buy_box_adjustment.dont_reprice);
		if(pricing_rules.if_you_are_the_buy_box_adjustment.adjustment){
			frontendTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_adjustment_amount_on_competing_offer = stringToFloat(pricing_rules.if_you_are_the_buy_box_adjustment.adjustment.adjustment_amount_on_competing_offer);
			frontendTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(pricing_rules.if_you_are_the_buy_box_adjustment.adjustment.fix_price_adjustment_if_true_percentage_if_false);
			frontendTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(pricing_rules.if_you_are_the_buy_box_adjustment.adjustment.static_method_adjust_up_if_true_down_if_false);
		}
	}

	if(pricing_rules.adjustment){
		frontendTemplate.template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer = stringToFloat(pricing_rules.adjustment.adjustment_amount_on_competing_offer);
		frontendTemplate.template_json.pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(pricing_rules.adjustment.static_method_adjust_up_if_true_down_if_false);
		frontendTemplate.template_json.pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(pricing_rules.adjustment.fix_price_adjustment_if_true_percentage_if_false);
	}

	if(pricing_rules.min_inventory_price){
		frontendTemplate.template_json.guards_price_min = pricing_rules.min_inventory_price.price;
	}

	if(pricing_rules.max_inventory_price){
		frontendTemplate.template_json.guards_price_max = pricing_rules.max_inventory_price.price;
	}

	if(pricing_rules.is_roi_guard_activate === "true" && pricing_rules.roi_guard){
		frontendTemplate.template_json.guards_roi_amount = pricing_rules.roi_guard.roi_amount;
		frontendTemplate.template_json.guards_fix_price_roi_if_true_percentage_if_false = stringToBool(pricing_rules.roi_guard.fix_price_roi_if_true_percentage_if_false);
		frontendTemplate.template_json.guards_lower_than_roi = stringToBool(pricing_rules.roi_guard.default_to_roi_if_price_below_roi);
	}

	if(pricing_rules.percentage_price_decrease_guard_activate === "true" && pricing_rules.percentage_price_decrease_guard){
		frontendTemplate.template_json.guards_drop_percentage = pricing_rules.percentage_price_decrease_guard.price_drop_percentage_cannot_exceed_amount;
	}

	if(pricing_rules.percentage_price_increase_guard_activate === "true" && pricing_rules.percentage_price_increase_guard){
		frontendTemplate.template_json.guards_increase_price = pricing_rules.percentage_price_increase_guard.price_increase_percentage_cannot_exceed_amount;
	}

	frontendTemplate.template_json.guards_if_amazon_lower_not_reprice = stringToBool(pricing_rules.dont_price_above_amazon);
	frontendTemplate.template_json.guards_reprice_if_suggested_up = stringToBool(pricing_rules.only_reprice_if_suggested_price_goes_up);
	frontendTemplate.template_json.guards_reprice_if_suggested_down = stringToBool(pricing_rules.only_reprice_if_suggested_price_goes_down);
	frontendTemplate.template_json.guards_lower_than_price = stringToBool(pricing_rules.default_to_min_if_price_below_min);
	frontendTemplate.template_json.guards_higher_than_price = stringToBool(pricing_rules.default_to_max_if_price_above_max);

	return frontendTemplate;
}

function generateOffersTargeting(frontendTemplate, template){
	if(!template.template_json.offers_targeting){
		return frontendTemplate;
	}
	const offers_targeting = template.template_json.offers_targeting;

	frontendTemplate.template_json.offers_targeting_competing_offer_to_reprice_against = offers_targeting.own_inventory_in_buy_box_offer_targeting.competing_offer_to_reprice_against;
	frontendTemplate.template_json.offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting = offers_targeting.own_inventory_not_in_the_buy_box_offer_targeting.competing_offer_to_reprice_against;
	frontendTemplate.template_json.offers_targeting_exclude_offers_with_seller_rating_below = offers_targeting.exclude_offers_with_seller_rating_below;
	frontendTemplate.template_json.offers_targeting_exclude_offers_with_seller_feed_back_count_below = offers_targeting.exclude_offers_with_seller_feed_back_count_below;
	frontendTemplate.template_json.pricing_rules_exclude_offer_if_sub_condition_acceptable = stringToBool(offers_targeting.exclude_offer_if_sub_condition_acceptable);
	frontendTemplate.template_json.offers_targeting_minimum_offer_to_trigger_reprice = offers_targeting.minimum_offer_to_trigger_reprice;
	switch(offers_targeting.channel_target){
		case "amazon_fulfill":
			frontendTemplate.template_json.user_inventory_channel_target_fba = true;
			break;
		case "merchant_fulfill":
			frontendTemplate.template_json.user_inventory_channel_target_mf = true;
			break;
		case "both":
			frontendTemplate.template_json.user_inventory_channel_target_fba = true;
			frontendTemplate.template_json.user_inventory_channel_target_mf = true;
			break;
		default:
			frontendTemplate.template_json.user_inventory_channel_target_fba = true;
			frontendTemplate.template_json.user_inventory_channel_target_mf = true;
	}
	frontendTemplate.template_json.pricing_rules_exclude_offer_not_available_now = stringToBool(offers_targeting.exclude_offer_not_available_now);
	frontendTemplate.template_json.pricing_rules_exclude_offer_if_sub_condition_acceptable = stringToBool(offers_targeting.exclude_offer_if_sub_condition_acceptable);
	frontendTemplate.template_json.offers_targeting_seller_to_exclude_id = offers_targeting.seller_to_exclude_id;
	frontendTemplate.template_json.offers_targeting_sellers_to_exclude = offers_targeting.sellers_to_exclude;

	if(offers_targeting.prime_filter){
		frontendTemplate.template_json.offers_targeting_prime_filter = offers_targeting.prime_filter;
	} else {
		frontendTemplate.template_json.offers_targeting_prime_filter = "target_both";
	}

	return frontendTemplate;
}

function generateOfferCount(frontendTemplate, template){
	frontendTemplate.template_json.offers_count_strategy = 'dont_reprice';
	frontendTemplate.template_json.offers_count_own_inventory_in_buybox_dont_reprice = true;
	frontendTemplate.template_json.offers_count_min_num_offer = 0;

	if(!template.template_json.over_20_offer_strategy){
		return frontendTemplate;
	}
	const offer_count = template.template_json.over_20_offer_strategy;
	frontendTemplate.template_json.offers_count_strategy = offer_count.strategy;

	if(offer_count.strategy === 'reprice_against_lowest_or_buybox'){
		if(offer_count.reprice_against_lowest_or_buybox_strat){
			const boxData = offer_count.reprice_against_lowest_or_buybox_strat;
			frontendTemplate.template_json.offers_count_min_num_offer = boxData.min_num_offer;
			frontendTemplate.template_json.offers_count_target_lowest_or_buybox = boxData.target_lowest_or_buybox;
			if(frontendTemplate.template_json.offers_count_target_lowest_or_buybox === "lowest"){
				frontendTemplate.template_json.offers_count_target_channel = boxData.target_channel;
			}
			if(boxData.own_inventory_in_buybox){
				const in_buybox = boxData.own_inventory_in_buybox;
				frontendTemplate.template_json.offers_count_own_inventory_in_buybox_dont_reprice = stringToBool(in_buybox.dont_reprice);
				if(in_buybox.adjustment){
					frontendTemplate.template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer = in_buybox.adjustment.adjustment_amount_on_competing_offer;
					frontendTemplate.template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(in_buybox.adjustment.fix_price_adjustment_if_true_percentage_if_false);
					frontendTemplate.template_json.offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(in_buybox.adjustment.static_method_adjust_up_if_true_down_if_false);
				}
			}
			if(boxData.own_inventory_not_in_buybox){
				const in_buybox = boxData.own_inventory_not_in_buybox;
				if(in_buybox.adjustment){
					frontendTemplate.template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer = in_buybox.adjustment.adjustment_amount_on_competing_offer;
					frontendTemplate.template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(in_buybox.adjustment.fix_price_adjustment_if_true_percentage_if_false);
					frontendTemplate.template_json.offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(in_buybox.adjustment.static_method_adjust_up_if_true_down_if_false);
				}
			}
		}
	}
	return frontendTemplate;
}

function generateRepriceUsedAginstNew(frontendTemplate, template){
	frontendTemplate.template_json.reprice_used_aginst_new_uan_is_activated = false;

	if(!template.template_json.reprice_used_aginst_new){
		return frontendTemplate;
	}
	const reprice_used_aginst_new = template.template_json.reprice_used_aginst_new;
	if(reprice_used_aginst_new.is_activated === 'true'){
		const strat = reprice_used_aginst_new.reprice_against_lowest_or_buybox_strat;
		frontendTemplate.template_json.reprice_used_aginst_new_uan_is_activated = true;
		frontendTemplate.template_json.reprice_used_aginst_new_reprice_against = strat.target_lowest_or_buybox;
		frontendTemplate.template_json.reprice_used_aginst_new_target_channel = strat.target_channel;
		frontendTemplate.template_json.reprice_used_aginst_new_uan_min_num_offer = strat.min_num_offer;
		frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_dont_reprice = stringToBool(strat.own_inventory_in_buybox.dont_reprice);
		if(!frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_dont_reprice){
			const adjustment = strat.own_inventory_in_buybox.adjustment;
			frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer = adjustment.adjustment_amount_on_competing_offer;
			frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(adjustment.fix_price_adjustment_if_true_percentage_if_false);
			frontendTemplate.template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(adjustment.static_method_adjust_up_if_true_down_if_false);
		}
		const adjustment = strat.own_inventory_not_in_buybox.adjustment;
		frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer = adjustment.adjustment_amount_on_competing_offer;
		frontendTemplate.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false = stringToBool(adjustment.fix_price_adjustment_if_true_percentage_if_false);
		frontendTemplate.template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false = stringToBool(adjustment.static_method_adjust_up_if_true_down_if_false);
	} else {
		return frontendTemplate;
	}

	return frontendTemplate;
}
