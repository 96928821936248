import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, FormGroup, Input, UncontrolledCollapse, Row, Col, Label, FormText, Badge, Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import DeleteDialog from '../../../../shared/components/dialogs/DeleteDialog';
import messageActions from "../../../../redux/message/actions";
import {
	momentDateToLocalFormatConversion,
} from "../../../../helpers/utility";
import EditMessageModal from './EditMessageModal';
import MessagePreviewModal from '../../../../shared/components/RichMessageBox/MessagePreviewModal';

const {
  messageInAppGetDelete,
  messageInAppGet,
  messageInAppGetCreate,
  messageInAppGetUpdate,
  messageInAppUploadImage,
  messageInAppUploadImageClear,
} = messageActions;

class AdminMessages extends Component {
  state = {
    showReadMore: false,
    deleteMessageId: null,
    title: "",
    description: "",
    try_button: "",
    more_button: "",
    media_type: 'image', // or 'youtube',
    video_id: "",
    edit_data: null,
    preview_data: null
  }

  generateColumns = () => {
    let tableColumns = [
      {
        id: "title",
        accessor: "title",
        Header: <span>Title</span>,
        headerClassName: "text-left"
      },
      {
        id: "creatd_at",
        accessor: "creatd_at",
        Header: <span>Date</span>,
        headerClassName: "text-left",
        width: 120,
        Cell: props => (
          <div>{momentDateToLocalFormatConversion(props.value * 1000)}</div>
        ),
      },
      {
        id: "try_button",
        accessor: "try_button",
        Header: <span>Primary App Route</span>,
        headerClassName: "text-left"
      },
      {
        id: "more_button",
        accessor: "more_button",
        Header: <span>Secondary URL</span>,
        headerClassName: "text-left",
        Cell: props => props.value !== "" ? props.value : "N/A"
      },
      { 
        id: "active",
        accessor: "active",
        Header: <span>Status</span>,
        headerClassName: "text-left",
        width: 120,
        Cell: props => props.value ? <Badge color="success">Published</Badge> : <Badge color="info">Unpublished</Badge>
      },
      {
        id: "df1",
        accessor: "id",
        Header: <span>Actions</span>,
        headerClassName: "text-left",
        Cell: props => (
          <div>
            <Button
              size="sm"
              className="mr-2"
              onClick={() => 
                this.setState({
                  showPreviewModal: true,
                  preview_data: {
                    title: props.original.title,
                    description: props.original.description,
                    try_button: props.original.try_button,
                    more_button: props.original.showReadMore ? props.orignal.more_button : "",
                    image: props.original.image
                  }
                })
              }
            >Preview</Button>
            {props.original.active ?
              <Button
                size="sm"
                color="warning"
                style={{ minWidth: "6rem" }}
                disabled={this.props.message_creating}
                onClick={e => this.publishMessage(props.original)}
              >Unpublish</Button> :
              <Button
                size="sm"
                color="success"
                style={{ minWidth: "6rem" }}
                disabled={this.props.message_creating}
                onClick={e => this.publishMessage(props.original)}
              >Publish</Button>
            }
            <Button
              color="primary"
              size="sm"
              className="ml-2"
              disabled={this.props.message_creating}
              onClick={s => this.setState({ edit_data: props.original })}
            >Edit</Button>
            <Button
              color="danger"
              size="sm"
              className="ml-2"
              onClick={() => this.toggleConfirmDelete(props.original.id)}
              disabled={this.props.message_creating}
            >Delete</Button>
          </div>
        )
      },
    ]

    return tableColumns;
  }

  toggleConfirmDelete = (id) => {
    this.setState({ deleteMessageId: id })
  }

  deleteMessage = (id) => {
    const data = {
      message_id: id,
    }
    this.props.messageInAppGetDelete(data);
    this.toggleConfirmDelete(null);
  }

  createMessage = (publish=false) => {
    const data = {
      title: this.state.title,
      description: this.state.description,
      try_button: this.state.try_button,
      more_button: this.state.showReadMore ? this.state.more_button : "",
      active: publish,
      image: this.state.media_type === 'image'
        ? this.props.uploaded_image
        : this.convertEmbedYoutube(this.state.video_id)
    }
    this.props.messageInAppGetCreate(data);
    this.clearForm();
  }

  validateForm = () => {
    const {
      title,
      description,
      try_button,
      more_button,
      showReadMore,
      media_type,
      video_id,
    } = this.state;
    if(
      !title.trim()
      || !description.trim()
      || !try_button.trim()
      || (!more_button.trim() && showReadMore)
      || (this.state.media_type === 'image' && !this.props.uploaded_image)
      || (this.state.media_type !== 'image' && !video_id.trim())
    ){
      return false;
    }
    return true;
  }

  clearForm = () => {
    this.setState({
      showReadMore: false,
      deleteMessageId: null,
      title: "",
      description: "",
      try_button: "",
      more_button: "",
      media_type: "image",
      video_id: "",
    });
    this.props.messageInAppUploadImageClear();
  }

  componentDidMount = () => {
    this.props.messageInAppGet({ filter: "all" });
  }

  publishMessage = (message) => {
    this.props.messageInAppGetUpdate({
      id: message.id,
      active: !message.active,
    });
  }

  convertEmbedYoutube = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const code = (match && match[2].length === 11) ? match[2] : null;
    return 'https://www.youtube.com/embed/' + code;
  }

  getVideoId = (url) => {
    this.setState({ video_id: url });
  }

  closeEditModal = () => {
    this.setState({ edit_data: null })
  }

  editData = ( value ) => {
    this.setState({ edit_data: value })
  }


  render() {
    return (
      <Card className="admin-messages">
        <CardBody>
        <div>
          <h3 className="h4">
            <Button
              size="sm"
              color="link"
              id="add-message"
              onClick={e => this.clearForm()}
            >Click to add new message</Button>
          </h3>
          <UncontrolledCollapse toggler="add-message">
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    placeholder="Title"
                    value={this.state.title}
                    onChange={e => this.setState({ title: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="textarea"
                    placeholder="Message"
                    rows={7}
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    placeholder={`App route for "Try now" button. Ex: /dashboard/sop/create`}
                    value={this.state.try_button}
                    onChange={e => this.setState({ try_button: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label check>
                    <Input type="checkbox" onClick={() => this.setState({ showReadMore: !this.state.showReadMore })} /> Show "Read more" button
                  </Label>
                  <Input
                    placeholder={`External URL for "Read more" button. Ex: https://blog.accelerlist.com/`}
                    disabled={!this.state.showReadMore}
                    value={this.state.more_button}
                    onChange={e => this.setState({ more_button: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <div>
                    <Label check>
                      <Input
                        type="radio"
                        name="media-type"
                        checked={this.state.media_type === 'image'}
                        onChange={() => this.setState({ media_type: 'image' })}
                      /> Upload Image
                    </Label>
                    <Label check className="ml-4">
                      <Input
                        type="radio"
                        name="media-type"
                        checked={this.state.media_type === 'youtube'}
                        onChange={() => this.setState({ media_type: 'youtube' })}
                      /> Show YouTube Video
                    </Label>
                  </div>
                  {this.state.media_type === 'image' ?
                  <React.Fragment>
                    <Input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={e => this.props.messageInAppUploadImage({ file: e.target.files[0] })}
                    />
                    <FormText>Dimension 800x300 px, .png, .jpg</FormText>
                    <FormText>{this.props.uploaded_image}</FormText>
                  </React.Fragment>
                  :
                  <Input
                    type="url"
                    placeholder="Provide YouTube video URL"
                    onChange={e => this.getVideoId(e.target.value)}
                  />
                  }
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center mt-2">
              <Button
                color="success"
                onClick={e => this.createMessage(true)}
                disabled={this.props.message_creating || !this.validateForm()}
              >Save &amp; Publish</Button>
              <Button
                className="ml-2"
                color="primary"
                onClick={e => this.createMessage()}
                disabled={this.props.message_creating || !this.validateForm()}
              >Save</Button>
              <Button
                className="ml-2"
                onClick={() => this.setState({ showPreviewModal: true })}
              >Preview</Button>
            </div>
          </UncontrolledCollapse>
        </div>
        <div className="mt-5">
          <h3 className="h4">Messages</h3>
          <ReactTable
            columns={this.generateColumns()}
            data={this.props.messages}
            defaultPageSize={10}
            minRows={0}
          />
        </div>
        <DeleteDialog
          isOpen={this.state.deleteMessageId !== null}
          close={() => this.toggleConfirmDelete(null)}
          deleteItem={() => this.deleteMessage(this.state.deleteMessageId)}
          dialogTitle="Are you sure want to delete this message?"
          dialogText="Deleted message can't be published again later."
          confirmText="Yes, delete message"
        />
        <EditMessageModal
          isOpen={this.state.edit_data ? true : false}
          data={this.state.edit_data}
          dataUpdate={this.editData}
          toggle={this.closeEditModal}
          uploaded_image={this.props.uploaded_image}
          messageInAppUploadImage={this.props.messageInAppUploadImage}
          message_creating={this.props.message_creating}
          messageInAppGetUpdate={this.props.messageInAppGetUpdate}
        />
        <MessagePreviewModal 
          isOpen={this.state.showPreviewModal}
          toggle={() => 
            this.setState({ 
              showPreviewModal: !this.state.showPreviewModal,
              preview_data: null
          })}
          data={this.state.preview_data !== null ? this.state.preview_data : {
            title: this.state.title,
            description: this.state.description,
            try_button: this.state.try_button,
            more_button: this.state.showReadMore ? this.state.more_button : "",
            image: this.state.media_type === 'image'
              ? this.props.uploaded_image
              : this.convertEmbedYoutube(this.state.video_id)
          }}
        />
      </CardBody>
      </Card>
    )
  }
}
export default connect(
  state => {
    return {
      messages: state.Message.get("messages"),
      message_creating: state.Message.get("message_creating"),
      uploaded_image: state.Message.get("uploaded_image"),
    };
  },
  {
    messageInAppGetDelete,
    messageInAppGet,
    messageInAppGetCreate,
    messageInAppGetUpdate,
    messageInAppUploadImage,
    messageInAppUploadImageClear,
  }
)(AdminMessages);
