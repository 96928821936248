import React, { Component } from 'react';
import { Tooltip, Cell, PieChart, Pie, Legend } from 'recharts';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#CCCCCC', '#FFA500', '#FF0000'];

class ReasonCategoryChart extends Component {
  state = {
    startDate: moment().subtract(365, 'days'),
    endDate: moment()
  }

  generateChartData(data) {
    let results = {}
    let chartData = []
    if (!(data === undefined) && data.length > 0) { 
      data.forEach(item => {
        if (results[item['cancel_reason_type']]) {
          results[item['cancel_reason_type']] += 1
        } else {
          results[item['cancel_reason_type']] = 1
        }
      })
      Object.keys(results).forEach(key => {
        chartData.push({name: key, value: results[key]})
      })
    }
    // sort desc by value for legend
    chartData.sort((a, b) => {
      return b.value - a.value
    })
    return chartData;
  }

  pieLegendFormatter = (value, entry) => {
    return <span>{value} ({entry.payload.value})</span>;
  }

  render() {
    const {cancel_reason} = this.props;

    return (
          <PieChart
            width={600}
            height={300}
          >
            <Pie 
              data={this.generateChartData(cancel_reason)}
              dataKey="value" 
              outerRadius={120}
            >
              {this.generateChartData(cancel_reason).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" verticalAlign="middle" align="right" formatter={this.pieLegendFormatter} />
          </PieChart>
    )
  }
}

export default ReasonCategoryChart;