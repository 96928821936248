export class User {
  constructor(userName, businessName, email, phone, sellerId, authToken,
    sp_api_token, sp_api_state, marketplaceId, permissions, role, trialRemaining,
    user_settings, plan, lifetime_user, is_repricer_enabled,
    has_active_subscription, repricerTrialRemaining, plan_repricer,
    payment_failed, ebay_auth, is_ebay_enabled, ebay_trial_remaining,
    created_at, user_came_from) {
      this.userName = userName;
			this.businessName = businessName;
      this.email = email;
      this.phone = phone;
      this.sellerId = sellerId;
      this.authToken = authToken;
      this.marketplaceId = marketplaceId;
      this.role = role;
			this.plan = plan;
			this.lifetime_user = lifetime_user;
			this.trialRemaining = trialRemaining;
			this.settings = user_settings;
			this.repricerEnabled = is_repricer_enabled;
			this.has_active_subscription = has_active_subscription;
			this.repricerTrialRemaining = repricerTrialRemaining;
			this.repricerPlan = plan_repricer;
			this.payment_failed = payment_failed;
			this.ebay_auth = ebay_auth;
			this.is_ebay_enabled = is_ebay_enabled;
			this.ebay_trial_remaining = ebay_trial_remaining;
      this.permissions = permissions;
      this.created_at = created_at;
      this.sp_api_token = sp_api_token;
      this.sp_api_state = sp_api_state;
      this.user_came_from = user_came_from;
    }
}
