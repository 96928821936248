import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Button } from 'reactstrap';
// import TemplateScheduleModal from './TemplateScheduleModal';
import TemplateScheduleModal2 from './TemplateScheduleModal2';
import SchedulesTable from './components/SchedulesTable';
import TemplateBreakdownTable from './components/TemplateBreakdownTable';
import repricerActions from '../../../../redux/repricer/actions';

const {
	userLoadSchedules,
	workingScheduleInit,
	workingScheduleUpdateProp,
	userSaveWorkingSchedule,
	userUpdateWorkingSchedule,
  updateScheduleWarningText,
} = repricerActions;

class Scheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
			editorOpen: false,
			viewDetail: false,
			userTemplatesData: [],
			schedule_id_transfer: null,
		}
  }

	UNSAFE_componentWillMount(){
		this.props.userLoadSchedules();
		this.props.workingScheduleInit();
	}

	toggleEditor = () => {
		var user_templates = [{ value: "all_templates", label: "All templates" }]
		this.props.userTemplates.forEach(template => {
			user_templates.push({ value: template.id, label: template.name });
		});
		this.setState({ userTemplatesData: user_templates });
		this.setState({ editorOpen: !this.state.editorOpen });
	}

	addSchedule = () => {
		this.props.workingScheduleInit();
		this.toggleEditor();
	}

	toggleView = (schedule_id, template_id) => {
	    this.setState({
			viewDetail: !this.state.viewDetail,
			schedule_id_transfer: schedule_id,
			template_id_transfer: template_id,
		})
  }

  getDisabledMinutes = (selectedHour) => {
    var minutes= [];
    if(this.props.userSchedules){
      this.props.userSchedules.forEach(item => {
        if(item.is_active && item.interval === 60 && item.start_timestamp){
          minutes.push(parseInt(item.start_timestamp.split(':')[1], 10))
        }
      })
    }
    return minutes;
  }


  render() {

    return(
      <Card>
        <CardBody>
					{!this.state.viewDetail ?
						<Fragment>
							<div className="mb-4 text-right">
								<Button
									color="primary"
									onClick={this.addSchedule}
								>ADD SCHEDULE</Button>
								{this.props.workingSchedule ?
								<TemplateScheduleModal2
									editorOpen={this.props.workingSchedule && this.state.editorOpen}
									toggleEditor={this.toggleEditor}
									userTemplates={this.state.userTemplatesData}
									workingScheduleUpdateProp={this.props.workingScheduleUpdateProp}
									userSaveWorkingSchedule={this.props.userSaveWorkingSchedule}
									userUpdateWorkingSchedule={this.props.userUpdateWorkingSchedule}
                  workingSchedule={this.props.workingSchedule}
                  updateScheduleWarningText={this.props.updateScheduleWarningText}
                  getDisabledMinutes={this.getDisabledMinutes}
								/>
								: null }
							</div>

							<SchedulesTable
								schedules={this.props.userSchedules}
								toggleEditor={this.toggleEditor}
								toggleView={this.toggleView}
							/>
						</Fragment>
					:
					<TemplateBreakdownTable
						toggleView={this.toggleView}
						schedule_id={this.state.schedule_id_transfer}
						template_id={this.state.template_id_transfer}
					/>
					}
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  state => ({
	  userSchedules: state.Repricer.get("userSchedules"),
	  userTemplates: state.Repricer.get("userTemplates"),
	  workingSchedule: state.Repricer.get("workingSchedule"),
  }),
  {
	userLoadSchedules,
	workingScheduleInit,
	workingScheduleUpdateProp,
	userSaveWorkingSchedule,
	userUpdateWorkingSchedule,
  }
)(Scheduler);
