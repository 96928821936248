import moment from "moment";
import QRious from "qrious";

import React, { useEffect, useRef } from 'react';

const BarcodePreview = ({ shipment, boxData }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let qrCodeText = "AMZN,PO:" + shipment.ShipmentId;
    let expireDate = '';
    boxData.items.forEach(item => {
      let date = item.expirationDate;
      expireDate = date ? moment(date).format('YYMMDD') : "";
      qrCodeText += ",ASIN:" + item.ASIN + ",QTY:" + item.QuantityShippedInBox+ (expireDate ? ",EXP:" + expireDate : "");
    });

    const qr = new QRious({
      level: 'H',
      size: 500,
      element: canvasRef.current,
      value: qrCodeText
    });

    return () => {
      // Cleanup code if needed
    };
  }, [shipment, boxData]);

  const boxWeight = boxData.items.reduce((acc, item) => {
    if (!!item.ProductSearchResult && !!item.ProductSearchResult.itemDimensions && !!item.ProductSearchResult.itemDimensions.Weight) {
      acc = acc + (Number(item.ProductSearchResult.itemDimensions.Weight * item.QuantityShippedInBox));
    }
    return acc;
  }, 0);


  const shipmentName = `Shipment name: ${shipment.ShipmentName}`;
  const boxNumber = boxData.box_number;
  const unitsCount = boxData.items.reduce((acc, item) => {
    return acc + Number(item.QuantityShippedInBox);
  }, 0);
  const warehouseName = shipment.DestinationFulfillmentCenterId;

  return (
    <div>
      <div style={{ display: 'flex', margin: '20px' }}>
        <div style={{ width: '30%', textAlign: 'left', fontSize: '15px', fontWeight: 'bold' }}>
          Box {boxNumber}
        </div>
        <div style={{ width: '40%', textAlign: 'center', fontSize: '15px', fontWeight: 'bold' }}>
          Warehouse: {warehouseName}
        </div>
        <div style={{ width: '30%', textAlign: 'right', fontSize: '15px', fontWeight: 'bold' }}>
          {unitsCount} units
        </div>
      </div>

      <div style={{ display: 'flex', margin: '20px', justifyContent: 'center' }}>
        <canvas ref={canvasRef} style={{ display: 'block', width: '250px' }} />
      </div>

      <div style={{ display: 'flex', margin: '20px' }}>
        <div style={{ width: '70%', textAlign: 'left', fontSize: '15px', fontWeight: 'bold' }}>
          {shipmentName}
        </div>
        <div style={{ width: '30%', textAlign: 'right', fontSize: '15px', fontWeight: 'bold' }}>
          Est Weight: {boxWeight}
        </div>
      </div>
    </div>
  );
};

export default BarcodePreview;