import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as NavLinkRouter } from 'react-router-dom';
import {
	Card, CardHeader, CardBody,
	Nav, NavItem, NavLink, TabContent, TabPane,
	FormGroup, Input, Col, InputGroup, InputGroupAddon, Button, InputGroupText
} from "reactstrap";
import SopItem from "./SopItem";
import SopDetails from "./SopDetails";
import SopCreate from "./SopCreate";
import SopEdit from "./SopEdit";
import SopManage from "./SopManage";
//import Select from "react-select";
import DeleteDialog from "../../../shared/components/dialogs/DeleteDialog";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import "./style.css";
import sopActions from '../../../redux/sop/actions';
const {
  getSopProcedures,
  getSopCategories,
  getSopTags,
  updateSopProcedure,
  deleteSopProcedure,
  exportSopProcedurePdf,
} = sopActions;

const sortOptions = [
  {value: 'favorite', label: 'Favorite'},
	{value: 'title', label: 'Title'},
  {value: 'category', label: 'Category'},
  {value: 'last_updated', label: 'Last Updated'},
];

const sortOrderOptions = [
  {value: 'asc', label: 'Ascending'},
  {value: 'desc', label: 'Descending'}
]

class SOP extends Component {
	state = {
		sort_by: sortOptions[0],
    sort_order: sortOrderOptions[0],
		activeTab: '1',
		showDeleteDialog: false,
    deleteItemID: null,
    search: "",
	}

	componentDidMount = () => {
    this.props.getSopProcedures();
    this.props.getSopCategories();
    this.props.getSopTags();
    //this.sortChanged("sort_by", this.state.sort_by);
	}

  toggleTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  getProcedureById = (id) => {
    const procedure_data = this.props.procedures.find(item => item.id === id);
    if(!procedure_data){
      return {}
    }
    return procedure_data;
  }

  confirmDeleteSop = (item_id) => {
    this.setState({
      showDeleteDialog: true,
      deleteItemID: item_id,
    });
  }

  deleteSopDialog = () => {
    this.setState({
      showDeleteDialog: false,
      deleteItemID: null,
    });
    const data = {
      procedure_id: this.state.deleteItemID,
    }
    this.props.deleteSopProcedure(data);
  }

  cancelSopDialog = () => {
    this.setState({
      showDeleteDialog: false,
      deleteItemID: null,
    });
  }

  searchProcedures = (query) => {
    const { procedures } = this.props;
    if(!query){
      return procedures;
    }
    var res = [];
    procedures.forEach(procedure => {
      const text = procedure.title.toLowerCase();
      if(text.includes(query.toLowerCase())){
        res.push(procedure);
      }
    });
    return res;
  }

	clearSearch = () => {
		this.setState({ search: "" })
	}

	render() {
		const {
			activeTab,
			showDeleteDialog,
			search
		} = this.state;

    const procedures_filter = this.searchProcedures(search);

		return (
			<div className="view">
				<header className="view-header">
					<h1 className="page-title">Standard Operating Procedures (SOP)</h1>
				</header>
				<div className="view-content sop">
					{this.props.match.url === '/dashboard/sop/create' ?
					<SopCreate />
					: this.props.match.url.includes('/dashboard/sop/edit') && this.props.match.params.id ?
					<SopEdit
						id={this.props.match.params.id}
            procedure_data={this.getProcedureById(parseInt(this.props.match.params.id, 10))}
            categories={this.props.categories}
            tags={this.props.tags}
            updateSopProcedure={this.props.updateSopProcedure}
            update_procedure_working={this.props.update_procedure_working}
					/>
					:
					<Card className="card-tab">
						<CardHeader className="d-flex">
							<Nav tabs style={{ flex: "0 0 auto" }}>
								<NavItem>
									<NavLink
										active={this.state.activeTab === "1"}
										onClick={() => this.toggleTab("1")}
									>Procedures</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										active={this.state.activeTab === "2"}
										onClick={() => this.toggleTab("2")}
									>Categories and Tags</NavLink>
								</NavItem>
							</Nav>
							<div>
								<NavLinkRouter
									to="/dashboard/sop/create"
									className="btn btn-success mx-2"
									style={{ marginTop: '2px' }}
								>
									<span className="icon icon-plus bg-white"></span> Create a new procedure
								</NavLinkRouter>
							</div>

						</CardHeader>
						<CardBody>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									{!this.props.match.params.id ?
									<React.Fragment>
										<div className="sop-filter">
											<FormGroup row>
												<Col sm="3">
													<InputGroup className="transparent-addon">
														<Input
															type="text"
															placeholder="Search procedures"
															onChange={e => this.setState({search: e.target.value})}
															value={search}
														/>
														<InputGroupAddon addonType="append">
														{search !== "" ? <Button onClick={this.clearSearch}><span className="icon icon-10 icon-close"></span></Button>
														: <InputGroupText><span className="icon icon-search"></span></InputGroupText>
														}
														</InputGroupAddon>
													</InputGroup>
												</Col>
                        {/*
												<Col sm="3" className="d-flex">
													<Select
														options={sortOptions}
														value={this.state.sort_by}
														onChange={(e) => this.sortChanged("sort_by", e)}
														clearable={false}
														placeholder="Sort procedures by..."
													/>
													<div style={{ width: '14rem' }}>
														<Select
															className="ml-2"
															options={sortOrderOptions}
															value={this.state.sort_order}
															onChange={(e) => this.sortChanged("sort_order", e)}
															clearable={false}
														/>
													</div>
                        </Col>
                        */}
											</FormGroup>
										</div>
										<div className="sop-list-header">
											<div className="sop-item">
												<div className="sop-left">Title</div>
												<div className="sop-right">
													<div className="sop-last-updated">Last Updated</div>
													<div className="sop-category">Category</div>
													<div className="sop-actions">Actions</div>
												</div>
											</div>
										</div>
										<div className="sop-list">
											{ procedures_filter && procedures_filter.length > 0 ?
												procedures_filter.map((item) =>
												<SopItem
													key={`procedure-${item.id}`}
													data={item}
													onDelete={() => this.confirmDeleteSop(item.id)}
                          categories={this.props.categories ? this.props.categories : []}
                          updateSopProcedure={this.props.updateSopProcedure}
                          exportSopProcedurePdf={this.props.exportSopProcedurePdf}
                          export_procedure_working={this.props.export_procedure_working}
												/>
												) : <span className="text-muted">No Results Found. Keep searching</span>
											}
										</div>
									</React.Fragment>
									: this.props.match.url.includes('/dashboard/sop/detail') && this.props.match.params.id ?
									<SopDetails
										id={this.props.match.params.id}
                    procedure_data={this.getProcedureById(parseInt(this.props.match.params.id, 10))}
									/>
									: null
									}
								</TabPane>
								<TabPane tabId="2">
                  {this.state.activeTab === "2" && <SopManage />}
								</TabPane>
							</TabContent>
						</CardBody>
					</Card>
					}
				</div>
				<DeleteDialog
					isOpen={showDeleteDialog}
					dialogTitle="Are you sure want to delete this SOP article?"
					dialogText="You will loose the article."
					confirmText="Yes, delete SOP article"
          close={() => this.cancelSopDialog()}
          deleteItem={() => this.deleteSopDialog()}
				/>
				{this.props.export_procedure_working &&
				<LoadingIndicator
					title="Exporting to PDF..."
					fullOverlay
				/>
				}
			</div>
		);
	}
}

export default connect(
  state => {
    return {
  		userData: state.Auth.get('userData'),
  		procedures: state.Sop.get('procedures'),
  		categories: state.Sop.get('categories'),
  		tags: state.Sop.get('tags'),
      update_procedure_working: state.Sop.get('update_procedure_working'),
      export_procedure_working: state.Sop.get('export_procedure_working'),
    };
  },
  {
    getSopProcedures,
    getSopCategories,
    getSopTags,
    updateSopProcedure,
    deleteSopProcedure,
    exportSopProcedurePdf,
  }
)(SOP);
