import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { Link as LocalLink } from 'react-scroll';

class TutorialCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			current: 0,
			completed: false
		}

		this.content = this.props.content;
	}

	componentDidMount() {
		if (!document.getElementById("wistia_script")) {
			var wistiaScript = document.createElement("script");
			wistiaScript.id = "wistia_script"
			wistiaScript.type = "text/javascript"
			wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
			wistiaScript.async = true
			document.body.appendChild(wistiaScript);
		}
	}

	next = () => {
		const current = this.state.current;
		const maxIdxBeforeCompleted = this.content.length - 2;

		if(current < maxIdxBeforeCompleted) {
			this.setState({
				current: this.state.current + 1
			});
		} else {
			this.setState({
				current: this.state.current + 1,
				completed: true
			})
		}
	}

	go = (idx) => {
		const maxIdxBeforeCompleted = this.content.length - 2;

		if(idx <= maxIdxBeforeCompleted) {
			this.setState({
				current: idx,
				completed: false
			});
		}
	}

	render() {
		const {
			current,
			completed
		} = this.state;

		return (
			<Card className="mt-4">
				<Row>
					<Col md={8} className="video-container">
						<CardBody>
							{completed === false ?
							<Fragment>
								<div>
									<iframe
										src={`//fast.wistia.net/embed/iframe/${this.content[current].video_id}?videoFoam=true&playerColor=00c853`}
										allowtransparency="true"
										frameborder="0"
										scrolling="no"
										class="wistia_embed"
										title="wistia embed"
										name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="720" height="449">
									</iframe>
								</div>
								<Button color="primary" className="mt-4 cursor-pointer" onClick={this.next}>Skip this video</Button>
								<a
									className="btn mt-4 cursor-pointer btn-secondary ml-2"
									target="_blank"
									rel="noopener noreferrer"
									href={`//fast.wistia.net/embed/iframe/${this.content[current].video_id}?videoFoam=true&playerColor=00c853`}
								>Full screen video</a>
							</Fragment>
							:
								<div className="complete">
									<div>
										<h3 className="h4">Congratulations!</h3>
										<p>You've completed this guide</p>
										<Link to="/dashboard/home" className="btn btn-primary">Close this guide and go to Dashboard</Link>
									</div>
								</div>
							}
						</CardBody>
					</Col>
					<Col md={4}>
						<CardBody>
							<h3 className="h5 mb-3">
								Table of Content 
								<span className="back-to-top">[
									<LocalLink
										href="#"
										to="tutorial-categories"
										smooth={true}
									>Back to top</LocalLink>
									]
									</span>
							</h3>
							<ListGroup>
								{
									this.content.map((item, idx) => (
										<ListGroupItem
											key={idx}
											tag="button"
											action
											active={current === idx}
											onClick={() => this.go(idx)}
											disabled={idx === this.content.length-1}
											className="cursor-pointer"
											>
											{idx+1}. {item.title}
										</ListGroupItem>
									))
								}
							</ListGroup>
						</CardBody>
					</Col>
				</Row>
			</Card>
		)
	}
}

export default TutorialCard;
