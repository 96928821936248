import React, { Component } from 'react';
import './style.css';
import PropTypes from "prop-types";
import { digitСonversion } from '../../../../helpers/utility';

class InventoryMetrics extends Component {
	render() {
		const {active_count, inactive_count, buyout, buycost,
			   cross_listed_count, cross_listed_active_inventory} = this.props.inventoryStats;
    return (
      <div className="inventory-metrics">
				<div className="inventory-metric-item active">
						<span className="value">{digitСonversion(active_count, 'decimal')}</span>
						<span className="title">Active</span>
        </div>
        {this.props.is_ebay_user ?
          <React.Fragment>
            <div className="inventory-metric-item active">
              <span className="value">{cross_listed_active_inventory ? digitСonversion(cross_listed_active_inventory, 'decimal', 2) : 0}%</span>
              <span className="title">Active inv. cross listed on eBay</span>
            </div>
            <div className="inventory-metric-item">
                <span className="value">{cross_listed_count ? digitСonversion(cross_listed_count, 'decimal') : 0}</span>
                <span className="title">Cross listed on eBay</span>
            </div>
          </React.Fragment> : null
        }
				<div className="inventory-metric-item inactive">
						<span className="value">{digitСonversion(inactive_count, 'decimal')}</span>
						<span className="title">Inactive</span>
				</div>
				<div className="inventory-metric-item buyout">
					<span className="value">{digitСonversion(
						buyout,
						'currency',
						this.props.internationalization_config.currency_code,
					0)}</span>
					<span className="title">Total Buyout</span>
				</div>
				<div className="inventory-metric-item buycosts">
					<span className="value">
					{digitСonversion(
						buycost,
						'currency',
						this.props.internationalization_config.currency_code,
						0)}
					</span>
					<span className="title">Total Buycosts</span>
				</div>
      </div>
    )
  }
}

InventoryMetrics.propTypes = {
  inventoryStats: PropTypes.object.isRequired,
  internationalization_config: PropTypes.object.isRequired,
  is_ebay_user: PropTypes.bool,
};

export default InventoryMetrics;
