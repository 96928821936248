import React from "react";
import SearchResultsVisualizer from "./search_results_display/SearchResultsVisualizer";
import BatchToolBoxRow from "./shared/BatchToolBoxRow";
import SideBar from "./sidebar/SideBar";
import PropTypes from "prop-types";

const SearchResultsDisplay = props => {
  let {
    handleSearchSubmit,
    handleSearchChange,
    search,
    onResultSelected,
    batchListingDefaults,
    updateListingDefaultsData,
    sidebarTabId,
    setSidebarTabId,
    createNewSupplier,
    suppliers,
		scouts,
    userData,
  } = props;

  return (
    <React.Fragment>
      <div className="batch-core">
        <BatchToolBoxRow
          handleSearchSubmit={handleSearchSubmit}
          handleSearchChange={handleSearchChange}
        />
        <div className="search-results-visualizer medium-top-margin">
          <SearchResultsVisualizer
            onResultSelected={onResultSelected}
            search={search}
            userData={userData}
          />
        </div>
      </div>
      <div className="batch-sidebar">
        <SideBar
          batchListingDefaults={batchListingDefaults}
          updateListingDefaultsData={updateListingDefaultsData}
          sidebarTabId={sidebarTabId}
          setSidebarTabId={setSidebarTabId}
          createNewSupplier={createNewSupplier}
					suppliers={suppliers}
					scouts={scouts}
        />
      </div>
    </React.Fragment>
  );
};

SearchResultsDisplay.propTypes = {
  handleSearchSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  batchListingDefaults: PropTypes.object.isRequired,
  updateListingDefaultsData: PropTypes.func.isRequired,
  sidebarTabId: PropTypes.string.isRequired,
  setSidebarTabId: PropTypes.func.isRequired,
  createNewSupplier: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  scouts: PropTypes.array,
};

export default SearchResultsDisplay;
