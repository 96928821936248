import { templateValidationMessages } from './template_validator_messages';
import { stringToInt, stringToFloat } from '../template_utils';

export function validateTemplateDataExecute(template, step){
	switch(step){
		case 1:
			return validateTemplateName(template);
		case 2:
			return validateTemplateInventory(template);
		case 3:
			return validateTemplateOfferCount(template);
		case 4:
			return validateTemplateOffer(template);
		case 5:
			return validateTemplatePricing(template);
		case 6:
			return validateTemplateGuard(template);
		default:
			return false;
	}
}

function validateTemplateOfferCount(template){
	const template_json = template.template_json;

	if(template_json.reprice_used_aginst_new_uan_is_activated === true){
		if(['lowest', 'buybox'].includes(template_json.reprice_used_aginst_new_reprice_against) === false){
			return { validated: false, text: templateValidationMessages[38], message_id: 38 };
		}
		if(template_json.reprice_used_aginst_new_reprice_against !== 'buybox'){
			if(['mf', 'fba'].includes(template_json.reprice_used_aginst_new_target_channel) === false){
				return { validated: false, text: templateValidationMessages[39], message_id: 39 };
			}
		}

		if(template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_dont_reprice === false){
			if(!stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer)
					&& template.template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false !== '0'){
				return { validated: false, text: templateValidationMessages[40], message_id: 40 };
			}
			if(template.template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false === null){
				return { validated: false, text: templateValidationMessages[41], message_id: 41 };
			}
			if(template.template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false === '0'
					&& stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer) !== 0){
				return { validated: false, text: templateValidationMessages[42], message_id: 42 };
			}
		}
		if(!stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer)
				&& template.template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false !== '0'){
			return { validated: false, text: templateValidationMessages[43], message_id: 43 };
		}
		if(template.template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false === null){
			return { validated: false, text: templateValidationMessages[44], message_id: 44 };
    }
		if(template.template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false === '0'
				&& stringToFloat(template.template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer) !== 0){
			return { validated: false, text: templateValidationMessages[45], message_id: 45 };
		}
	}

	if(template_json.offers_count_strategy === 'reprice_against_lowest_or_buybox'){
		if(['lowest', 'buybox'].includes(template_json.offers_count_target_lowest_or_buybox) === false){
			return { validated: false, text: templateValidationMessages[34], message_id: 34 };
		}
		if(template_json.offers_count_target_lowest_or_buybox !== 'buybox'){
			if(['mf', 'fba'].includes(template_json.offers_count_target_channel) === false){
				return { validated: false, text: templateValidationMessages[35], message_id: 35 };
			}
		}
		if(template_json.offers_count_own_inventory_in_buybox_dont_reprice === false
				&& (!template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer
					&& template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer !== 0)){
			return { validated: false, text: templateValidationMessages[36], message_id: 36 };
		}
		if(!template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer
				&& template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer !== 0){
			return { validated: false, text: templateValidationMessages[37], message_id: 37 };
		}
	}
	return { validated: true, text: null, message_id: 0 };
}

function validateTemplateName(template){
  if(template.name){
		return { validated: true, text: null, message_id: 0 };
	}
	return { validated: false, text: templateValidationMessages[1], message_id: 1 };
}

function validateTemplateInventory(template){
	const template_json = template.template_json;
	if(!template_json.user_inventory_product_salesrank_min){
		return { validated: false, text: templateValidationMessages[2], message_id: 2 };
	}
	if(!template_json.user_inventory_product_salesrank_max){
		return { validated: false, text: templateValidationMessages[3], message_id: 3 };
	}
	if(!template_json.user_inventory_inventory_age_min){
		return { validated: false, text: templateValidationMessages[4], message_id: 5 };
	}
	if(!template_json.user_inventory_inventory_age_max){
		return { validated: false, text: templateValidationMessages[5], message_id: 5 };
	}
	if(!template_json.user_inventory_product_price_min){
		return { validated: false, text: templateValidationMessages[6], message_id: 6 };
	}
	if(!template_json.user_inventory_product_price_max){
		return { validated: false, text: templateValidationMessages[7], message_id: 7 };
	}
	if(!template_json.user_inventory_channel_target){
		return { validated: false, text: templateValidationMessages[8], message_id: 8 };
	}
	if(stringToInt(template_json.user_inventory_product_salesrank_min)
		>= stringToInt(template_json.user_inventory_product_salesrank_max)){
		return { validated: false, text: templateValidationMessages[9], message_id: 9 };
	}
	if(stringToInt(template_json.user_inventory_inventory_age_min)
		>= stringToInt(template_json.user_inventory_inventory_age_max)){
		return { validated: false, text: templateValidationMessages[10], message_id: 10 };
	}
	if(stringToFloat(template_json.user_inventory_product_price_min)
		>= stringToFloat(template_json.user_inventory_product_price_max)){
		return { validated: false, text: templateValidationMessages[11], message_id: 11 };
	}
	return { validated: true, text: null, message_id: 0 };
}

function validateTemplateOffer(template){
	const template_json = template.template_json;
	if(!template_json.offers_targeting_minimum_offer_to_trigger_reprice){
		return { validated: false, text: templateValidationMessages[12], message_id: 12 };
	}
	if(!template_json.offers_targeting_exclude_offers_with_seller_feed_back_count_below){
		return { validated: false, text: templateValidationMessages[13], message_id: 13 };
	}
	if(!template_json.offers_targeting_exclude_offers_with_seller_rating_below){
		return { validated: false, text: templateValidationMessages[14], message_id: 14 };
	}
	if(stringToInt(template_json.offers_targeting_exclude_offers_with_seller_rating_below) < 1
		|| stringToInt(template_json.offers_targeting_exclude_offers_with_seller_rating_below) > 100){
		return { validated: false, text: templateValidationMessages[15], message_id: 15 };
	}
	if(!template_json.user_inventory_channel_target_fba
		&& !template_json.user_inventory_channel_target_mf){
		return { validated: false, text: templateValidationMessages[16], message_id: 16 };
	}
	if(!template_json.offers_targeting_prime_filter){
		return { validated: false, text: templateValidationMessages[46], message_id: 46 };
	}

	return { validated: true, text: null, message_id: 0 };
}

function validateTemplatePricing(template){
	const template_json = template.template_json;
	//not buy box
	if(!template_json.offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting
		//|| (!template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer)
		){
		return { validated: false, text: templateValidationMessages[17], message_id: 17 };
	}
	if(!template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer){
		if(template_json.pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false){
			return { validated: false, text: templateValidationMessages[18], message_id: 18 };
		}
	}
	//in buy box
	if(template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy){
		if(!template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice){
			if(!template_json.offers_targeting_competing_offer_to_reprice_against){
				return { validated: false, text: templateValidationMessages[19], message_id: 19 };
			} else {
				if(!template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_adjustment_amount_on_competing_offer){
					return { validated: false, text: templateValidationMessages[20], message_id: 20 };
				}
			}
		}
	}
	//Competing Offer is Amazon
	if(!template_json.pricing_rules_use_regular_repricing_strategy_against_amazon
		&& !template_json.pricing_rules_amazon_adjustment_adjustment_amount_on_competing_offer){
		return { validated: false, text: templateValidationMessages[21], message_id: 21 };
	}
	//No Competing Offers Exist for This Listing
	if(template_json.reprice_rule_for_failed_reprice_no_competing_offer_exist_activate
		&& !template_json.no_competing_offer_exist_rule_adjustment_amount_on_your_own_offer){
		return { validated: false, text: templateValidationMessages[22], message_id: 22 };
	}
	if(template_json.reprice_rule_for_failed_reprice_no_competing_offer_exist_activate
		&& template_json.no_competing_offer_exist_rule_price_guard_activate){
		if(!template_json.no_competing_offer_exist_rule_price_guard_rule_min_price){
			return { validated: false, text: templateValidationMessages[30], message_id: 30 };
		}
	}
	//Some Competing Offers Found, But Not Within Filter Criteria
	if(template_json.reprice_rule_for_failed_reprice_none_of_competing_offer_meet_requirement_activate
		&& !template_json.none_of_competing_offer_meet_requirement_rule_adjustment_amount_on_your_own_offer){
		return { validated: false, text: templateValidationMessages[23], message_id: 23 };
	}
	if(template_json.reprice_rule_for_failed_reprice_none_of_competing_offer_meet_requirement_activate
		&& template_json.general_failed_rule_price_guard_activate){
		if(!template_json.general_failed_rule_price_guard_rule_min_price){
			return { validated: false, text: templateValidationMessages[31], message_id: 31 };
		}
	}
	//Competing Within Your Criteria Found, But the New Suggested Price was Rejected
	if(template_json.reprice_rule_for_failed_reprice_general_failed_activate
		&& !template_json.general_failed_rule_adjustment_amount_on_your_own_offer){
		return { validated: false, text: templateValidationMessages[24], message_id: 24 };
	}
	if(template_json.reprice_rule_for_failed_reprice_general_failed_activate
		&& template_json.none_of_competing_offer_meet_requirement_rule_price_guard_activate){
		if(!template_json.none_of_competing_offer_meet_requirement_rule_price_guard_rule_min_price){
			return { validated: false, text: templateValidationMessages[32], message_id: 32 };
		}
	}
	return { validated: true, text: null, message_id: 0 };
}

function validateTemplateGuard(template){
	const template_json = template.template_json;
	if(!template_json.guards_price_min){
		return { validated: false, text: templateValidationMessages[25], message_id: 25 };
	}
	if(!template_json.guards_price_max){
		return { validated: false, text: templateValidationMessages[26], message_id: 26 };
	}
	if(stringToInt(template_json.guards_price_max)
		< stringToInt(template_json.guards_price_min)){
		return { validated: false, text: templateValidationMessages[33], message_id: 33 };
	}
	if(!template_json.guards_drop_percentage){
		return { validated: false, text: templateValidationMessages[27], message_id: 27 };
	}
	if(!template_json.guards_increase_price){
		return { validated: false, text: templateValidationMessages[28], message_id: 28 };
	}
	if(template_json.guards_setMinROI
		&& !template_json.guards_roi_amount){
		return { validated: false, text: templateValidationMessages[29], message_id: 29 };
	}
	return { validated: true, text: null, message_id: 0 };
}
