import React, { Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import FaSpinner from "react-icons/lib/fa/spinner";
import SearchBar from './SearchBar';
import TableGenerator from '../../../../shared/components/TableGenerator';
import {
  momentDateTimeToLocalFormatConversion,
  digitСonversion,
} from '../../../../helpers/utility';
import StatusBadge from '../../../../shared/components/StatusBadge';
import { Link } from 'react-router-dom';
import Metrics from '../Metrics';
import { Button, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import batchActions from '../../../../redux/history/actions';
import { checkIfEmployee, setRouteForUserRole } from "../../../../helpers/utility";
import DeleteDialog from '../../../../shared/components/dialogs/DeleteDialog';
import IconBox from "react-icons/lib/io/ios-box";
import TooltipAtCustomElement from '../../../../shared/components/TooltipAtCustomElement';
import IconWarning from "react-icons/lib/fa/exclamation-triangle";
const { adminGetFeedStatus } = batchActions;

class HistoryTable extends React.Component {

  state = {
    parentPageCheck: true,
    parentPageResetCheck: false,
    showDeleteDialog: false
  }

  changeHistoryStatus = (status) => {
    this.props.changeState("status", status);
    this.setState({ parentPageResetCheck: true });
    this.props.selectAllHistoryBatches({target: "cheked"}, []);
  }

  changeHistoryTableState = (name, value) => this.setState({ [name]: value });

  historyBatchMapper = (option) => {
    const parsedJSON = JSON.parse(option.batch_json);
    let fulfillmentCenterBadges;
    if (option.fulfillment_center_count) {
        fulfillmentCenterBadges = Object.keys(option.fulfillment_center_count)
        .map(destinationId => {
            return (
                <span
                    key={`fulfillmentCenterId_${destinationId}`}
                    className={`badge warehouse-badge ${destinationId}`}>
                    {destinationId} ({option.fulfillment_center_count[destinationId]})
                </span>
            )
        })
    }
    return (
      <Fragment>
        <td>{momentDateTimeToLocalFormatConversion(option.updated_at)}</td>
        <td>
          <Link to={setRouteForUserRole("/dashboard/batch/", this.props.userData) + option.id}>
            {parsedJSON.batchName.slice(0, 25)}
            {option.inbound_plan_id && (
              <TooltipAtCustomElement
                tooltipId={`inbound-plan-tooltip-${option.id}`}
                tooltipText="Inbound shipment plan created"
                CustomElement={() => (
                  <IconBox
                    size={18}
                    className="text-warning ml-2"
                  />
                )}
                placement="right"
              />
            )}
            {option.inbound_transportaion_options_option_confirmed && (
              <TooltipAtCustomElement
                tooltipId={`transportation-window-tooltip-${option.id}`}
                tooltipText="Transportation option has been confirmed for this inbound plan. You have 24 hours to cancel shipment after confirming transportation option."
                CustomElement={() => (
                  <IconWarning
                    size={13}
                    className="text-danger ml-1"
                  />
                )}
                placement="right"
                styles={{maxWidth: 300}}
              />
            )}
          </Link>
        </td>
        <td>
            <Fragment>
            { fulfillmentCenterBadges && fulfillmentCenterBadges.length > 0
                ? fulfillmentCenterBadges
                : option.workflow_type === "private"
                    ? "Private Batch"
                    : "N/A" }
            </Fragment>
        </td>
        <td style={{ minWidth: '4rem' }}>{option.type}</td>
        <td style={{ minWidth: '8rem' }}><StatusBadge status={option.status} /></td>
        <td style={{ minWidth: '4rem' }}>{digitСonversion(option.est_profit || 0, 'currency', this.props.internationalization_config?.currency_code || "USD")}</td>
        <td style={{ minWidth: '4rem' }}>{option.num_mskus || 0}</td>
        <td style={{ minWidth: '4rem' }}>{option.total_items || 0}</td>
      </Fragment>
    )
}

  getAdminMWSResult = () => {
    if(!this.props.selectedHistoryBatches || this.props.selectedHistoryBatches.length === 0){
      return;
    }
    const data = {
      batch_id: this.props.selectedHistoryBatches[0],
    }
    this.props.adminGetFeedStatus(data);
  }


  render() {
    var historyTableHeaders = [
      { name: "DATE", value: "date" },
      { name: "NAME", value: "name" },
      { name: "DESTINATION", value: "destination" },
      { name: "TYPE", value: "type" },
      { name: "STATUS", value: "status" },
      { name: "EST. PROFIT", value: "est_profit" },
      { name: "TOTAL MSKUs", value: "total_mskus" },
      { name: "TOTAL ITEMS", value: "total_items" },
    ];

    const {
        batches,
        status,
        batchDeleteLoading,
        filter_method,
        filter_query,
        loadingList,
        totalHistoryBatches,
        selectedHistoryBatches,
        clearSearch,
        defaultTableRowsLimit
    } = this.props;
    const historyBatchAdditionalFilter = {
        status,
        filter_method,
        filter_query
    };
    const {
        parentPageCheck,
        parentPageResetCheck
    } = this.state;

    return (
        <React.Fragment>
            {!checkIfEmployee(this.props.userData) &&
              <Metrics />
            }
            <Card>
            <CardBody>
            <div className="table-top-controls d-flex justify-content-between align-items-center">
                <div className="btn-link-group">
                  {status !== "deleted" && !checkIfEmployee(this.props.userData)?
                    <button
                      onClick={() => {
                        if(this.props.selectedHistoryBatches && this.props.selectedHistoryBatches.length > 0) {
                          this.setState({ showDeleteDialog: true })
                        }
                      }}
                      type="button"
                      disabled={
                        !this.props.selectedHistoryBatches
                        || this.props.selectedHistoryBatches.length === 0
                        || batchDeleteLoading}
                      className="btn btn-link text-danger"
                    >
                        <span className="icon icon-trash bg-danger mr-1"></span>
                        <span>Delete Selected</span>
                    </button> : null
                    }
                    {this.props.userData && this.props.admin_login ?
                      <React.Fragment>
                        <Button
                          color="primary"
                          onClick={e => this.getAdminMWSResult()}
                          disabled={
                            !this.props.selectedHistoryBatches
                              || this.props.selectedHistoryBatches.length === 0
                              || this.props.admin_feed_loading}
                        >Show MWS Result</Button>
                      </React.Fragment> : null
                    }
                </div>
                {!checkIfEmployee(this.props.userData) &&
                <SearchBar
                  filter_method={filter_method}
                  filter_query={filter_query}
                  changeState={this.props.changeState}
                  changeHistoryTableState={this.changeHistoryTableState}
                  clearSearch={clearSearch}
                />
                }
                <Nav pills>
                  <NavItem>
                    <NavLink role="button" active={status === 'in_progress'} onClick={() => this.changeHistoryStatus('in_progress')}>In Progress</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink role="button" active={status === 'completed'} onClick={() => this.changeHistoryStatus('completed')}>Completed</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink role="button" active={status === 'deleted'} onClick={() => this.changeHistoryStatus('deleted')}>Deleted</NavLink>
                  </NavItem>
                </Nav>
            </div>
            {this.props.admin_feed_status ?
              <React.Fragment>
                <div>
                  <pre>
                    {this.props.admin_feed_status}
                  </pre>
                </div>
              </React.Fragment> : null
            }
            {!batchDeleteLoading ? <TableGenerator
                    rootClassName={`table table-striped acc-table acc-table-left batch-history-table
                                  ${status !== 'deleted' && !checkIfEmployee(this.props.userData) ? 'checkable' : ''}`}
                    isCheckable={status !== "deleted" && !checkIfEmployee(this.props.userData)}
                    headerTitles={historyTableHeaders}
                    sortable={false}
                    options={batches}
                    optionMapper={this.historyBatchMapper}
                    loadItems={this.props.fetchBatchList}
                    additionalFilters={historyBatchAdditionalFilter}
                    totalCount={totalHistoryBatches}
                    isLoading={loadingList}
                    selectedRows={selectedHistoryBatches}
                    selectRow={this.props.selectHistoryBatches}
                    selectAllRows={this.props.selectAllHistoryBatches}
                    checkedRowName="id"
                    changeState={this.changeHistoryTableState}
                    parentPageCheck={parentPageCheck}
                    parentPageResetCheck={parentPageResetCheck}
                    defaultLimit={defaultTableRowsLimit}
                    isAsync
                />
            :
                <div className="text-center"><FaSpinner className="fa-spin fa-2x mt-3 mb-3" /></div>
            }
            </CardBody>
            </Card>
            <DeleteDialog
              isOpen={this.state.showDeleteDialog}
              close={() => this.setState({ showDeleteDialog: false })}
              deleteItem={() => {
                this.props.onDeleteSelectedBatches()
                this.setState({ showDeleteDialog: false })
              }}
              dialogTitle="Are you sure want to delete selected batch(es)?"
              dialogText="Selected batch(es) will be deleted."
              cancelText="No, dismiss"
              confirmText="Yes, delete selected batch(es)"
            />
      </React.Fragment>
    );
  }
}

HistoryTable.propTypes = {
  changeState: PropTypes.func,
  fetchBatchList: PropTypes.func,
  selectAllHistoryBatches: PropTypes.func,
  selectHistoryBatches: PropTypes.func,
  onDeleteSelectedBatches: PropTypes.func,
  batches: PropTypes.array,
  status: PropTypes.string,
  batchDeleteLoading: PropTypes.bool,
  filter_method: PropTypes.string,
  filter_query: PropTypes.string,
  loadingList: PropTypes.bool,
  totalHistoryBatches: PropTypes.number,
  selectedHistoryBatches: PropTypes.array,
  clearSearch: PropTypes.func.isRequired,
  defaultTableRowsLimit:  PropTypes.number,
}

export default connect(
  state => ({
    userData: state.Auth.get('userData'),
    internationalization_config: state.Auth.get("internationalization_config"),
    admin_login: state.Auth.get("admin_login"),
    admin_user: state.Auth.get("admin_user"),
    admin_feed_status: state.History.get("admin_feed_status"),
    admin_feed_loading: state.History.get("admin_feed_loading"),
  }),
  {
    adminGetFeedStatus,
  }
)(HistoryTable);
