import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Badge
} from "reactstrap";
import PropTypes from "prop-types";
import "./style.css";

import TemplateErrorsView from "../displays/shared/TemplateErrorsView";
import SKUErrorsView from "../displays/shared/SKUErrorsView";
import { 
  momentDateIsValid,
  momentDateTimeToLocalFormatConversion
} from "../../../../helpers/utility";

class FeedStatusModal extends Component {
  render() {
    const { isOpen, close, currentFeedStatusData, products } = this.props;
    let feedErrors = [];

    if (currentFeedStatusData) {
      var {
        parsed_feed,
        feed_id,
        fulfillment_center,
        shipment_name,
        created_at
      } = currentFeedStatusData;
      
      if (parsed_feed && !parsed_feed.is_fallback_report) {
        var { num_processed, num_successful, headers, rows } = parsed_feed;
        
        if (rows !== null) {
          rows.forEach((row) => {
            let obj = {};
            row.forEach((element, index) => {
              obj[headers[index]] = element
            });
            feedErrors.push(obj);
          });
        }
      }
    }

    return (
      <Modal isOpen={isOpen} toggle={close} size="lg" centered>
        <ModalHeader>
          <strong>Feed status</strong>
        </ModalHeader>
        {currentFeedStatusData ? (
          <ModalBody>
            <Row>
              <Col xs={8}>
                <Row>
                  <Col xs="4">Shipment Name:</Col>
                  <Col xs="8">
                    <strong>{shipment_name ? shipment_name : "N/A"}</strong>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="4">Fulfillment Center:</Col>
                  <Col xs="8">
                    <h4>
                      <Badge color="info">
                        {fulfillment_center ? fulfillment_center : "N/A"}
                      </Badge>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">Feed ID:</Col>
                  <Col xs="8">
                    <strong>{feed_id ? feed_id : "N/A"}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">Create Time:</Col>
                  <Col xs="8">
                    <strong>
                      {momentDateIsValid(created_at)
                        ? momentDateTimeToLocalFormatConversion(created_at)
                        : "N/A"}
                    </strong>
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <Col xs="6">Processed:</Col>
                  <Col xs="6"><strong>{num_processed ? num_processed : "N/A"}</strong></Col>
                </Row>
                <Row>
                  <Col xs="6">Successful:</Col>
                  <Col xs="6"><strong>{num_successful ? num_successful : "N/A"}</strong></Col>
                </Row>
                <Row>
                  <Col xs="6">Failed:</Col>
                  <Col xs="6"><strong className="text-danger">
                    {num_successful && num_processed
                    ? Number(num_processed) - Number(num_successful)
                    : "N/A"}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
            <TemplateErrorsView feedErrors={feedErrors} />
            <br />
            <SKUErrorsView products={products} feedErrors={feedErrors} />
          </ModalBody>
        ) : (
          ""
        )}
        <ModalFooter>
          <Button onClick={close}>Ok</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FeedStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  currentFeedStatusData: PropTypes.object,
  close: PropTypes.func.isRequired
};

export default FeedStatusModal;
