import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { secureProtocolImgURL } from "../../../helpers/utility";
import PopoverCustomElementAndContent from "../../../shared/components/PopoverCustomElementAndContent";
import DeleteItemsPopover from "./components/DeleteItemsPopover";
import { Badge, Dropdown, Label } from "reactstrap";
import { FaCheck, FaTrashO } from "react-icons/lib/fa";
import { Button, ButtonDropdown, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon } from "reactstrap";
import boxContentActions from "../../../redux/box_contents/actions";
import { connect } from "react-redux";
import { lbsToKgWithId, lbsOrKg } from '../../../helpers/utility';
import { MdArrowForward } from "react-icons/lib/md";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`${fadeIn}`;
const FadeIn = styled.div`
	animation: 1s ${fadeAnimation};
`;

const { shipmentBoxItemWeight } = boxContentActions
class ShipmentSearchResultItem extends Component {
  state = {
    moveItemsFlag: false,
    showQtyInput: false,
    disabledAdjustQty: false,
    adjustQty: 0,
    showChangeWeight: false,
    changedWeight: {value:0, BoxContentItemId:0, BoxId:0},
  };

  handleSelect = (event, BoxContentItemId) => {
    let qty = parseInt($(".select-qty").val(), 10);
    let toBoxId = parseInt(event.target.value, 10);
    if (qty && toBoxId) {
      this.props.moveItem(
        BoxContentItemId,
        toBoxId,
        qty,
        this.props.selectedShipment,
        this.props.currentBox
      );
      this.setState({ moveItemsFlag: false });
    }
  };

  toggleQtyInput = (qty) => {
    this.setState({
      showQtyInput: !this.state.showQtyInput,
      disabledAdjustQty: false,
      adjustQty: qty ? parseInt(qty, 10) : 0,
    })
  }

  adjustQty = (quantityShipped, val) => {
    const { shipmentBoxItem } = this.props;
    const qty = shipmentBoxItem.QuantityShippedInBox + parseInt(val, 10);
    if(qty > quantityShipped){
      this.setState({disabledAdjustQty: true});
    } else {
      this.setState({
        disabledAdjustQty: false,
        adjustQty: parseInt(val, 10),
      });
    }
  }

  adjustQtyExecute = () => {
    const { shipmentBoxItem } = this.props;
    const data = {
      itemId: shipmentBoxItem.BoxContentItemId,
      updatedQty: shipmentBoxItem.QuantityShippedInBox + this.state.adjustQty,
      boxId: shipmentBoxItem.BoxId,
    }
    this.props.updateItemCountShipment(data);
    this.toggleQtyInput();
  }

  handleWeightInput =(e , BoxContentItemId, BoxId) => {
    let value = e.target.value
    let weightObj = {
      value : value,
      BoxContentItemId : BoxContentItemId,
      BoxId: BoxId
    }
    this.setState({changedWeight:weightObj})
    
  }

  handleChangeWeight = (shipmentBox) => {
    let data ={
       BoxId: shipmentBox.BoxId,
       BoxContentItemId: this.state.changedWeight.BoxContentItemId,
       Weight: this.state.changedWeight.value
    } 
    this.props.shipmentBoxItemWeight(data);
  }

  render() {
    const {
      boxes,
      shipmentBoxItem,
      shipmentId,
      boxNumber,
      DestinationFulfillmentCenterId,
      printItem,
      delProductRequest,
      shipment,
      highlightedItems,
      userData
    } = this.props;
    let quantityOptions = [];
    for (var i = 1; i <= shipmentBoxItem.QuantityShippedInBox; i++) {
      quantityOptions.push(
        <option key={`qty_option_${i}`} value={i}>
          {i}
        </option>
      );
    }
    let quantityShipped = this.props.inboundShipmentItems.reduce(
      (acc, item) => {
        let val = 0;
        if (item.SellerSKU === shipmentBoxItem.SellerSKU) {
          val = Number(item.QuantityShipped) || 0;
        }
        return acc + val;
      },
      0
    );
    let productSearchResult = shipmentBoxItem.ProductSearchResult;
    let imageUrl = "";
    let prepInstructions = "Not Found";
    if (!!productSearchResult) {
  		imageUrl = productSearchResult?.imageUrl;
      prepInstructions = productSearchResult.prepInstructions;
	}

  let isHighlighted = false;
  if(highlightedItems.length > 0) {
    const filtered = highlightedItems.filter(item => item.ASIN === shipmentBoxItem.ASIN);
    isHighlighted = filtered.length > 0 ? true : false;
  }

  let weight = 0;
  if (
    shipmentBoxItem?.ProductSearchResult?.itemDimensions?.Weight
  ) {
    weight =
      Number(
        shipmentBoxItem.ProductSearchResult.itemDimensions.Weight
      ) || 0;
  }

    return (
      <FadeIn className={`result-item ${isHighlighted ? "highlight" : ""}`}>
        <div className="media d-block text-center">
          <img
            src={imageUrl ? secureProtocolImgURL(imageUrl.replace("_SL75_", "_SL200_")) : null}
            className="img-fluid"
            alt=""
          />
          {shipmentBoxItem.QuantityShippedInBox >= quantityShipped &&
            <Badge color="success" className="badge-done"><FaCheck /> DONE</Badge>
          }
        </div>
        <div className="info">
          <div className="info-row">
            <span>
              ASIN: <strong>{shipmentBoxItem.ASIN}</strong>
            </span>
            <span>
              FNSKU: <strong>{shipmentBoxItem.FulfillmentNetworkSKU}</strong>
            </span>
            <span>
              Box Qty:{" "}
              <strong>
                {shipmentBoxItem.QuantityShippedInBox}/{quantityShipped}
              </strong>
            </span>
          </div>
          <div className="info-row">
            {prepInstructions &&
            <span>
              <strong>
                {prepInstructions}
              </strong>
            </span>
            }
            <span>
              Shipment: <strong>{shipmentId}</strong>
            </span>
            <span>
              <span className="dropdown dropdown-move show">
                <button
                  onClick={() => {
                    this.setState({ moveItemsFlag: !this.state.moveItemsFlag });
                  }}
                  type="button"
                  className="btn btn-link btn-move"
                >
                  <MdArrowForward className="text-primary mr-1" size={13} />
                  <span className="text-primary">Move Boxes</span>
                </button>
                {this.state.moveItemsFlag && (
                  <div className="dropdown-menu dropdown-menu-right">
                    <div>
                    <Label>Qty</Label>
                    <select className="form-control form-control-sm select-qty">
                      {quantityOptions}
                    </select>
                    </div>
                    <div className="ml-2">
                      <Label>Box Number</Label>
                    <select
                      onChange={e =>
                        this.handleSelect(e, shipmentBoxItem.BoxContentItemId)
                      }
                      className="form-control form-control-sm select-box"
                    >
                      <option value="">To Box...</option>
                      {boxes.map((box, i) => {
                        return (
                          <option key={`box_option_${i}`} value={box.id}>
                            {box.box_number}
                          </option>
                        );
                      })}
                    </select>
                    </div>
                  </div>
                )}
              </span>
            </span>

              <PopoverCustomElementAndContent
                userStyle={{ display: "inline-flex", alignItems: "center" }}
                tooltipId={`DeleteIcon${shipmentBoxItem.BoxContentItemId}`}
                TooltipContent={(props) => (
                  <DeleteItemsPopover
                    shipment={shipment}
                    shipmentBoxItem={shipmentBoxItem}
                    delProductRequest={delProductRequest}
                    togglePopover={props.togglePopover}
                  />
                )}
                customElement={
                  <span>
                    <div
                      className="btn btn-link btn-move"
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <FaTrashO className="text-danger mr-1" size={13} />
                      <span className="text-danger">Delete item from box</span>
                    </div>
                  </span>
                }
              />

          </div>
          <div className="info-row">
            <span>
              Item weight: <strong className={weight <= 0 ? 'text-danger' : 'text-success'}>                  {userData?.marketplaceId ? (
                    <span>{lbsToKgWithId(userData.marketplaceId, weight)}</span>
                  ) : (
                    <span>{weight.toFixed(2)}lbs</span>
                  )}</strong>
              <Dropdown
                className="d-inline ml-2"
                isOpen={this.state.showChangeWeight} 
                toggle={() => this.setState({ showChangeWeight: !this.state.showChangeWeight })}
              >
                <DropdownToggle tag="span" role="button" className="btn btn-link btn-sm"><span>Change</span></DropdownToggle>
                <DropdownMenu className="px-2">
                  <InputGroup size="sm">
                    <Input type="number" defaultValue={(weight).toFixed(2)} onChange={(e) => this.handleWeightInput(e, shipmentBoxItem.BoxContentItemId, shipmentBoxItem.BoxId)}/>
                    <InputGroupAddon addonType="append">{userData?.marketplaceId ? (
                    lbsOrKg(userData.marketplaceId)
                  ) : (
                    "lbs"
                  )}</InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({ showChangeWeight: false })
                          this.handleChangeWeight(shipmentBoxItem)
                        }}
                      >Save</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </DropdownMenu>
              </Dropdown>
            </span>
          </div>
        </div>
        <div className="right">
          <span
            className={`badge warehouse-badge ${DestinationFulfillmentCenterId}`}
          >
            {DestinationFulfillmentCenterId}
          </span>
          <br/>
          <span className="badge badge-primary">Box: {boxNumber}</span>
          { quantityShipped - shipmentBoxItem.QuantityShippedInBox > 0 ?
            <React.Fragment>
              <br />
              <div className="d-flex align-items-center">
                <ButtonDropdown
                  isOpen={this.state.showQtyInput}
                  size="sm"
                  className="mr-2"
                  toggle={e => this.toggleQtyInput(quantityShipped - shipmentBoxItem.QuantityShippedInBox)}
                >
                  <DropdownToggle color="secondary">Adjust Qty</DropdownToggle>
                  <DropdownMenu right className="p-0" style={{ minWidth: "8rem" }}>
                    <div className="p-2">
                      <InputGroup size="sm">
                        <Input
                          type="number"
                          defaultValue={this.state.adjustQty}
                          onChange={e => this.adjustQty(quantityShipped, e.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            color="primary"
                            onClick={() => { this.adjustQtyExecute(); }}
                            disabled={this.state.disabledAdjustQty}
                          >Save</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                  </DropdownMenu>
                </ButtonDropdown>
                <button onClick={() => printItem(shipmentBoxItem)} type="button" className="btn btn-link">
                  <svg
                    width="15"
                    height="14"
                    viewBox="451 2 15 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M463.75 5.75h-10.5C452.005 5.75 451 6.755 451 8v4.5h3v3h9v-3h3V8c0-1.245-1.005-2.25-2.25-2.25zM461.5 14h-6v-3.75h6V14zm2.25-5.25c-.4125 0-.75-.3375-.75-.75s.3375-.75.75-.75.75.3375.75.75-.3375.75-.75.75zM463 2h-9v3h9V2z"
                      fill="#BAC5B4"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </React.Fragment>
            : null
          }
        </div>
      </FadeIn>
    );
  }
}

ShipmentSearchResultItem.propTypes = {
  shipmentBoxItem: PropTypes.object,
  boxId: PropTypes.number,
  boxNumber: PropTypes.number,
  moveItem: PropTypes.func,
  shipmentId: PropTypes.string,
  DestinationFulfillmentCenterId: PropTypes.string,
  boxes: PropTypes.array,
  selectedShipment: PropTypes.object,
  currentBox: PropTypes.object,
  printItem: PropTypes.func,
  delProductRequest: PropTypes.func,
  shipment: PropTypes.object,
  updateItemCountShipment: PropTypes.func,
};

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
  }),
  {
    shipmentBoxItemWeight
  }
)(ShipmentSearchResultItem);
