import React, { Component } from 'react';
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Button,
  FormGroup, Input, Label, FormText
 } from 'reactstrap';

class EditMessageModal extends Component {
  state = {
    showReadMore: false,
    media_type: '', // or 'youtube',
    new_image: "",
    new_youtube: "",
  }

  youtubeUrlChanged = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const code = (match && match[2].length === 11) ? match[2] : null;
    const video_url = 'https://www.youtube.com/embed/' + code;
    this.setState({new_youtube: video_url});
  }

  updateData = (key, value) => {
    let d = Object.assign({}, this.props.data);
    d[key] = value;
    this.props.dataUpdate(d);
  }

  UNSAFE_componentWillReceiveProps(np){
    if(!this.state.media_type && np && np.data){
      this.setState({
        media_type: np.data.image.includes("youtube.com") ? "youtube" : "image",
        showReadMore: np.data.more_button ? true : false,
      });
    }
  }

  validateForm = () => {
    if(!this.props.data){ return }
    const {
      title,
      description,
      try_button,
      more_button,
      image,
    } = this.props.data;
    if(
      !title.trim()
      || !description.trim()
      || !try_button.trim()
      || (!more_button.trim() && this.state.showReadMore)
    ){
      return false;
    }
    return true;
  }

  updateMessage = () => {
    let data = Object.assign({}, this.props.data);
    if(!this.state.showReadMore){ data.more_button = ""}
    if(this.state.new_youtube && this.state.media_type === 'youtube'){ data.image = this.state.new_youtube }
    if(this.props.uploaded_image && this.state.media_type === 'image'){ data.image = this.props.uploaded_image }
    this.props.messageInAppGetUpdate(data);
    this.props.toggle();
  }

  render() {
    const {
      isOpen,
      toggle,
      data,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Edit Message</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholder="Title"
                  value={data?.title}
                  onChange={e => this.updateData("title", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  placeholder="Message"
                  rows={7}
                  value={data?.description}
                  onChange={e => this.updateData("description", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder={`App route for "Try now" button. Ex: /dashboard/sop/create`}
                  value={data?.try_button}
                  onChange={e => this.updateData("try_button", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() =>
                        this.setState({
                          showReadMore: !this.state.showReadMore,
                          more_button: "",
                        })
                    }
                  /> Show "Read more" button
                </Label>
                <Input
                  placeholder={`External URL for "Read more" button. Ex: https://blog.accelerlist.com/`}
                  disabled={!this.state.showReadMore}
                  value={data?.more_button}
                  onChange={e => this.updateData("more_button", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <div>
                  <Label check onClick={() => this.setState({ media_type: 'image' })}>
                    <Input
                      type="radio"
                      name="media-type"
                      checked={this.state.media_type === 'image'}
                      onChange={() => { this.setState({ media_type: 'image' }) }}
                    /> Image
                  </Label>
                  <Label check className="ml-4" onClick={() => this.setState({ media_type: 'youtube' })}>
                    <Input
                      type="radio"
                      name="media-type"
                      checked={this.state.media_type === 'youtube'}
                      onChange={() => { this.setState({ media_type: 'youtube' }) }}
                    /> YouTube Video
                  </Label>
                </div>
                {this.state.media_type === 'image' ?
                  <React.Fragment>
                    <img
                      src={this.props.uploaded_image ? this.props.uploaded_image : data?.image && data?.image.includes("images") ? data?.image : null}
                      alt=""
                      className="img-fluid mb-2"
                    />
                    <Input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={e => this.props.messageInAppUploadImage({ file: e.target.files[0] })}
                    />
                    <FormText>Dimension 800x300 px, .png, .jpg</FormText>
                    <FormText>{this.props.uploaded_image}</FormText>
                  </React.Fragment>
                  : null
                }
                {this.state.media_type === 'youtube' ?
                  <React.Fragment>
                    <div className="embed-responsive embed-responsive-21by9 mb-2">
                      <iframe
                        className="embed-responsive-item"
                        src={data?.image && data?.image.includes("youtube.com") ? data?.image : this.state.new_youtube}
                        allowFullScreen
                      ></iframe>
                    </div>
                    <Input
                      type="url"
                      placeholder="Provide YouTube video URL"
                      value={data?.image && data?.image.includes("youtube.com") ? data?.image : this.state.new_youtube}
                      onChange={e => this.youtubeUrlChanged(e.target.value)}
                    />
                  </React.Fragment>
                  : null
                }
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button
            color="success"
            disabled={this.props.message_creating || !this.validateForm()}
            onClick={e => this.updateMessage()}
          >Update</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default EditMessageModal;
