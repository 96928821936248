const adminActions = {

    CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
    CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_PASSWORD_FAILURE: 'CHANGE_USER_PASSWORD_FAILURE',
    CHANGE_USER_PASSWORD_FAILURE_MODAL_CLOSE: 'CHANGE_USER_PASSWORD_FAILURE_MODAL_CLOSE',
    CHANGE_USER_PASSWORD_SUCCESS_MODAL_CLOSE: 'CHANGE_USER_PASSWORD_SUCCESS_MODAL_CLOSE',
    SEARCH_USER: 'SEARCH_USER',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE: 'SEARCH_USER_FAILURE',
	SEARCH_USER_FAILURE_MODAL_CLOSE: 'SEARCH_USER_FAILURE_MODAL_CLOSE',

    SEARCH_USERS_PER_MARKETPLACE: 'SEARCH_USERS_PER_MARKETPLACE',
    SEARCH_USERS_PER_MARKETPLACE_SUCESS: 'SEARCH_USERS_PER_MARKETPLACE_SUCCESS',
    SEARCH_USERS_PER_MARKETPLACE_FAILURE: 'SEARCH_USERS_PER_MARKETPLACE_FAILURE',
    SEARCH_USERS_ERRORLOGS: 'SEARCH_USERS_ERRORLOGS',
    SEARCH_USERS_ERRORLOGS_SUCESS: 'SEARCH_USERS_ERRORLOGS_SUCCESS',
    SEARCH_USERS_ERRORLOGS_FAILURE: 'SEARCH_USERS_ERRORLOGS_FAILURE',

    USER_AUTH_TOKEN_UPDATE: 'USER_AUTH_TOKEN_UPDATE',
    USER_AUTH_TOKEN_UPDATE_SUCCESS: 'USER_AUTH_TOKEN_UPDATE_SUCCESS',
    USER_AUTH_TOKEN_UPDATE_FAILURE: 'USER_AUTH_TOKEN_UPDATE_FAILURE',

	BATCH_STATS: 'BATCH_STATS',
	BATCH_STATS_SUCCESS: 'BATCH_STATS_SUCCESS',
	BATCH_STATS_FAILURE: 'BATCH_STATS_FAILURE',

	USER_ENABLE_REPRICER: "USER_ENABLE_REPRICER",
	USER_ENABLE_REPRICER_SUCCESS: "USER_ENABLE_REPRICER_SUCCESS",
	USER_ENABLE_REPRICER_FAILURE: "USER_ENABLE_REPRICER_FAILURE",

	GET_SBYB_STATS: 'GET_SBYB_STATS',
	GET_SBYB_STATS_SUCCESS: 'GET_SBYB_STATS_SUCCESS',
	GET_SBYB_STATS_FAILED: 'GET_SBYB_STATS_FAILED',

	GET_REPRICER_STATS: 'GET_REPRICER_STATS',
	GET_REPRICER_STATS_SUCCESS: 'GET_REPRICER_STATS_SUCCESS',
	GET_REPRICER_STATS_FAILED: 'GET_REPRICER_STATS_FAILED',

	GET_CATEGORIES_STATS_BOOKS: "GET_CATEGORIES_STATS_BOOKS",
	GET_CATEGORIES_STATS_BOOKS_SUCCESS: "GET_CATEGORIES_STATS_BOOKS_SUCCESS",
	GET_CATEGORIES_STATS_BOOKS_FAILED: "GET_CATEGORIES_STATS_BOOKS_FAILED",

	GET_CATEGORIES_STATS_OTHER: "GET_CATEGORIES_STATS_OTHER",
	GET_CATEGORIES_STATS_OTHER_SUCCESS: "GET_CATEGORIES_STATS_OTHER_SUCCESS",
	GET_CATEGORIES_STATS_OTHER_FAILED: "GET_CATEGORIES_STATS_OTHER_FAILED",

	UPDATE_USER: "UPDATE_USER",
	UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
	UPDATE_USER_FAILED: "UPDATE_USER_FAILED",

	USER_WHITELIST: "USER_WHITELIST",
	USER_WHITELIST_SUCCESS: "USER_WHITELIST_SUCCESS",
	USER_WHITELIST_FAILED: "USER_WHITELIST_FAILED",

	USER_FBC_COMPARE: "USER_FBC_COMPARE",
	USER_FBC_COMPARE_SUCCESS: "USER_FBC_COMPARE_SUCCESS",
	USER_FBC_COMPARE_FAILED: "USER_FBC_COMPARE_FAILED",

	USER_ENABLE_EBAY: "USER_ENABLE_EBAY",
	USER_ENABLE_EBAY_SUCCESS: "USER_ENABLE_EBAY_SUCCESS",
	USER_ENABLE_EBAY_FAILURE: "USER_ENABLE_EBAY_FAILURE",

	USER_GET_EBAY_LOGS: "USER_GET_EBAY_LOGS",
	USER_GET_EBAY_LOGS_SUCCESS: "USER_GET_EBAY_LOGS_SUCCESS",
	USER_GET_EBAY_LOGS_FAILED: "USER_GET_EBAY_LOGS_FAILED",

  GET_USER_SUCCESS_ADMIN: "GET_USER_SUCCESS_ADMIN",
  GET_USER_SUCCESS_ADMIN_SUCCESS: "GET_USER_SUCCESS_ADMIN_SUCCESS",
  GET_USER_SUCCESS_ADMIN_FAILED: "GET_USER_SUCCESS_ADMIN_FAILED",

  GET_CANCEL_REASON_ADMIN: "GET_CANCEL_REASON_ADMIN",
  GET_CANCEL_REASON_ADMIN_SUCCESS: "GET_CANCEL_REASON_ADMIN_SUCCESS",
  GET_CANCEL_REASON_ADMIN_FAILED: "GET_CANCEL_REASON_ADMIN_FAILED",

  GET_USERS_SEGMENTATION: "GET_USERS_SEGMENTATION",
  GET_USERS_SEGMENTATION_SUCCESS: "GET_USERS_SEGMENTATION_SUCCESS",
  GET_USERS_SEGMENTATION_FAILED: "GET_USERS_SEGMENTATION_FAILED",

  GET_EBAY_STATS: "GET_EBAY_STATS",
  GET_EBAY_STATS_SUCCESS: "GET_EBAY_STATS_SUCCESS",
  GET_EBAY_STATS_FAILED: "GET_EBAY_STATS_FAILED",

  GET_EBAY_USER_SALES: "GET_EBAY_USER_SALES",
  GET_EBAY_USER_SALES_SUCCESS: "GET_EBAY_USER_SALES_SUCCESS",
  GET_EBAY_USER_SALES_FAILED: "GET_EBAY_USER_SALES_FAILED",

  GET_EBAY_USER_SALE_DETAILS: "GET_EBAY_USER_SALE_DETAILS",
  GET_EBAY_USER_SALE_DETAILS_SUCCESS: "GET_EBAY_USER_SALE_DETAILS_SUCCESS",
  GET_EBAY_USER_SALE_DETAILS_FAILED: "GET_EBAY_USER_SALE_DETAILS_FAILED",

  GET_FAQ_TOPICS: "GET_FAQ_TOPICS",
  GET_FAQ_TOPICS_SUCCESS: "GET_FAQ_TOPICS_SUCCESS",
  GET_FAQ_TOPICS_FAILED: "GET_FAQ_TOPICS_FAILED",

  ADD_FAQ_TOPIC: "ADD_FAQ_TOPIC",
  ADD_FAQ_TOPIC_SUCCESS: "ADD_FAQ_TOPIC_SUCCESS",
  ADD_FAQ_TOPIC_FAILED: "ADD_FAQ_TOPIC_FAILED",

  UPDATE_FAQ_TOPIC: "UPDATE_FAQ_TOPIC",
  UPDATE_FAQ_TOPIC_SUCCESS: "UPDATE_FAQ_TOPIC_SUCCESS",
  UPDATE_FAQ_TOPIC_FAILED: "UPDATE_FAQ_TOPIC_FAILED",

  GET_FAQ_ARTICLES: "GET_FAQ_ARTICLES",
  GET_FAQ_ARTICLES_SUCCESS: "GET_FAQ_ARTICLES_SUCCESS",
  GET_FAQ_ARTICLES_FAILED: "GET_FAQ_ARTICLES_FAILED",

  ADD_FAQ_ARTICLE: "ADD_FAQ_ARTICLE",
  ADD_FAQ_ARTICLE_SUCCESS: "ADD_FAQ_ARTICLE_SUCCESS",
  ADD_FAQ_ARTICLE_FAILED: "ADD_FAQ_ARTICLE_FAILED",

  UPDATE_FAQ_ARTICLE: "UPDATE_FAQ_ARTICLE",
  UPDATE_FAQ_ARTICLE_SUCCESS: "UPDATE_FAQ_ARTICLE_SUCCESS",
  UPDATE_FAQ_ARTICLE_FAILED: "UPDATE_FAQ_ARTICLE_FAILED",

  DELETE_FAQ_ARTICLE: "DELETE_FAQ_ARTICLE",
  DELETE_FAQ_ARTICLE_SUCCESS: "DELETE_FAQ_ARTICLE_SUCCESS",
  DELETE_FAQ_ARTICLE_FAILED: "DELETE_FAQ_ARTICLE_FAILED",

  DELETE_FAQ_TOPIC: "DELETE_FAQ_TOPIC",
  DELETE_FAQ_TOPIC_SUCCESS: "DELETE_FAQ_TOPIC_SUCCESS",
  DELETE_FAQ_TOPIC_FAILED: "DELETE_FAQ_TOPIC_FAILED",

  REORDER_FAQ_TOPICS: "REORDER_FAQ_TOPICS",
  REORDER_FAQ_TOPICS_SUCCESS: "REORDER_FAQ_TOPICS_SUCCESS",
  REORDER_FAQ_TOPICS_FAILED: "REORDER_FAQ_TOPICS_FAILED",

  GET_EBAY_STATS_SALES_AVERAGES: "GET_EBAY_STATS_SALES_AVERAGES",
  GET_EBAY_STATS_SALES_AVERAGES_SUCCESS: "GET_EBAY_STATS_SALES_AVERAGES_SUCCESS",
  GET_EBAY_STATS_SALES_AVERAGES_FAILED: "GET_EBAY_STATS_SALES_AVERAGES_FAILED",

  GET_APP_SETTINGS: "GET_APP_SETTINGS",
  GET_APP_SETTINGS_SUCCESS: "GET_APP_SETTINGS_SUCCESS",
  GET_APP_SETTINGS_FAILED: "GET_APP_SETTINGS_FAILED",

  UPDATE_APP_SETTINGS: "UPDATE_APP_SETTINGS",
  UPDATE_APP_SETTINGS_SUCCESS: "UPDATE_APP_SETTINGS_SUCCESS",
  UPDATE_APP_SETTINGS_FAILED: "UPDATE_APP_SETTINGS_FAILED",

  GET_EBAY_AVERAGES_TOTAL: "GET_EBAY_AVERAGES_TOTAL",
  GET_EBAY_AVERAGES_TOTAL_SUCCESS: "GET_EBAY_AVERAGES_TOTAL_SUCCESS",
  GET_EBAY_AVERAGES_TOTAL_FAILED: "GET_EBAY_AVERAGES_TOTAL_FAILED",

	USER_ENABLE_SMS: "USER_ENABLE_SMS",
	USER_ENABLE_SMS_SUCCESS: "USER_ENABLE_SMS_SUCCESS",
	USER_ENABLE_SMS_FAILURE: "USER_ENABLE_SMS_FAILURE",

	SPAPI_USER_COUNT_GET: "SPAPI_USER_COUNT_GET",
	SPAPI_USER_COUNT_GET_SUCCESS: "SPAPI_USER_COUNT_GET_SUCCESS",
	SPAPI_USER_COUNT_GET_FAILURE: "SPAPI_USER_COUNT_GET_FAILURE",

	USER_GET_SPAPI_LOGS: "USER_GET_SPAPI_LOGS",
	USER_GET_SPAPI_LOGS_SUCCESS: "USER_GET_SPAPI_LOGS_SUCCESS",
	USER_GET_SPAPI_LOGS_FAILED: "USER_GET_SPAPI_LOGS_FAILED",

	USER_ACTIVATE_SPAPI: "USER_ACTIVATE_SPAPI",
	USER_ACTIVATE_SPAPI_SUCCESS: "USER_ACTIVATE_SPAPI_SUCCESS",
	USER_ACTIVATE_SPAPI_FAILED: "USER_ACTIVATE_SPAPI_FAILED",

	USER_LOG_ACTION_EXPORT: "USER_LOG_ACTION_EXPORT",
	USER_LOG_ACTION_EXPORT_SUCCESS: "USER_LOG_ACTION_EXPORT_SUCCESS",
	USER_LOG_ACTION_EXPORT_FAILED: "USER_LOG_ACTION_EXPORT_FAILED",

	AI_ANALYZE_ANSWER: "AI_ANALYZE_ANSWER",
	AI_ANALYZE_ANSWER_SUCCESS: "AI_ANALYZE_ANSWER_SUCCESS",
	AI_ANALYZE_ANSWER_FAILED: "AI_ANALYZE_ANSWER_FAILED",

	ADMIN_USER_SPLITS_AGREGATE_GET: "ADMIN_USER_SPLITS_AGREGATE_GET",
	ADMIN_USER_SPLITS_AGREGATE_GET_SUCCESS: "ADMIN_USER_SPLITS_AGREGATE_GET_SUCCESS",
	ADMIN_USER_SPLITS_AGREGATE_GET_FAILED: "ADMIN_USER_SPLITS_AGREGATE_GET_FAILED",

  adminUserSplitsAggregateGet: (data) => ({
		type: adminActions.ADMIN_USER_SPLITS_AGREGATE_GET,
		data,
  }),
  adminUserSplitsAggregateGetSuccess: (data) => ({
		type: adminActions.ADMIN_USER_SPLITS_AGREGATE_GET_SUCCESS,
		data,
  }),
  adminUserSplitsAggregateGetFailed: (data) => ({
		type: adminActions.ADMIN_USER_SPLITS_AGREGATE_GET_FAILED,
		data,
  }),

  userAdminAIAnalyzeAnswer: (data) => ({
		type: adminActions.AI_ANALYZE_ANSWER,
		data,
  }),
  userAdminAIAnalyzeAnswerSuccess: (data) => ({
		type: adminActions.AI_ANALYZE_ANSWER_SUCCESS,
		data,
  }),
  userAdminAIAnalyzeAnswerFailed: (data) => ({
		type: adminActions.AI_ANALYZE_ANSWER_FAILED,
		data,
  }),

  userAdminLogActionExport: (data) => ({
		type: adminActions.USER_LOG_ACTION_EXPORT,
		data,
  }),
  userAdminLogActionExportSuccess: (data) => ({
		type: adminActions.USER_LOG_ACTION_EXPORT_SUCCESS,
		data,
  }),
  userAdminLogActionExportFailed: (data) => ({
		type: adminActions.USER_LOG_ACTION_EXPORT_FAILED,
		data,
  }),

  userAdminSpApiActivate: (data) => ({
		type: adminActions.USER_ACTIVATE_SPAPI,
		data,
  }),
  userAdminSpApiActivateSuccess: (data) => ({
		type: adminActions.USER_ACTIVATE_SPAPI_SUCCESS,
		data,
  }),
  userAdminSpApiActivateFailed: (data) => ({
		type: adminActions.USER_ACTIVATE_SPAPI_FAILED,
		data,
  }),

  getAdminSpApiLogs: (data) => ({
		type: adminActions.USER_GET_SPAPI_LOGS,
		data,
  }),
  getAdminSpApiLogsSuccess: (data) => ({
		type: adminActions.USER_GET_SPAPI_LOGS_SUCCESS,
		data,
  }),
  getAdminSpApiLogsFailed: (data) => ({
		type: adminActions.USER_GET_SPAPI_LOGS_FAILED,
		data,
  }),

  userSpApiUserCountGet: (data) => ({
		type: adminActions.SPAPI_USER_COUNT_GET,
		data,
  }),
  userSpApiUserCountGetSuccess: (data) => ({
		type: adminActions.SPAPI_USER_COUNT_GET_SUCCESS,
		data
  }),
  userSpApiUserCountGetFailure: () => ({
		type: adminActions.SPAPI_USER_COUNT_GET_FAILURE,
  }),

  userEnableSMS: (data) => ({
		type: adminActions.USER_ENABLE_SMS,
		data,
  }),
  userEnableSMSSuccess: (data) => ({
		type: adminActions.USER_ENABLE_SMS_SUCCESS,
		data
  }),
  userEnableSMSFailure: () => ({
		type: adminActions.USER_ENABLE_SMS_FAILURE,
  }),

  getEbayAveragesTotal: (data) => ({
		type: adminActions.GET_EBAY_AVERAGES_TOTAL,
		data
  }),
  getEbayAveragesTotalSuccess: (data) => ({
		type: adminActions.GET_EBAY_AVERAGES_TOTAL_SUCCESS,
		data
  }),
  getEbayAveragesTotalFailed: (data) => ({
		type: adminActions.GET_EBAY_AVERAGES_TOTAL_FAILED,
		data
  }),

  getEbayStatsSalesAverages: (data) => ({
		type: adminActions.GET_EBAY_STATS_SALES_AVERAGES,
		data
  }),
  getEbayStatsSalesAveragesSuccess: (data) => ({
		type: adminActions.GET_EBAY_STATS_SALES_AVERAGES_SUCCESS,
		data
  }),
  getEbayStatsSalesAveragesFailed: (data) => ({
		type: adminActions.GET_EBAY_STATS_SALES_AVERAGES_FAILED,
		data
  }),

  getAppSettings: (data) => ({
		type: adminActions.GET_APP_SETTINGS,
		data
  }),
  getAppSettingsSuccess: (data) => ({
		type: adminActions.GET_APP_SETTINGS_SUCCESS,
		data
  }),
  getAppSettingsFailed: (data) => ({
		type: adminActions.GET_APP_SETTINGS_FAILED,
		data
  }),

  updateAppSettings: (data) => ({
		type: adminActions.UPDATE_APP_SETTINGS,
		data
  }),
  updateAppSettingsSuccess: (data) => ({
		type: adminActions.UPDATE_APP_SETTINGS_SUCCESS,
		data
  }),
  updateAppSettingsFailed: (data) => ({
		type: adminActions.UPDATE_APP_SETTINGS_FAILED,
		data
  }),

  reorderFaqTopics: (data) => ({
		type: adminActions.REORDER_FAQ_TOPICS,
		data
  }),
  reorderFaqTopicsSuccess: (data) => ({
		type: adminActions.REORDER_FAQ_TOPICS_SUCCESS,
		data
  }),
  reorderFaqTopicsFailed: (data) => ({
		type: adminActions.REORDER_FAQ_TOPICS_FAILED,
		data
  }),

  deleteFaqTopic: (data) => ({
		type: adminActions.DELETE_FAQ_TOPIC,
		data
  }),
  deleteFaqTopicSuccess: (data) => ({
		type: adminActions.DELETE_FAQ_TOPIC_SUCCESS,
		data
  }),
  deleteFaqTopicFailed: (data) => ({
		type: adminActions.DELETE_FAQ_TOPIC_FAILED,
		data
  }),

  deleteFaqArticle: (data) => ({
		type: adminActions.DELETE_FAQ_ARTICLE,
		data
  }),
  deleteFaqArticleSuccess: (data) => ({
		type: adminActions.DELETE_FAQ_ARTICLE_SUCCESS,
		data
  }),
  deleteFaqArticleFailed: (data) => ({
		type: adminActions.DELETE_FAQ_ARTICLE_FAILED,
		data
  }),

  updateFaqArticle: (data) => ({
		type: adminActions.UPDATE_FAQ_ARTICLE,
		data
  }),
  updateFaqArticleSuccess: (data) => ({
		type: adminActions.UPDATE_FAQ_ARTICLE_SUCCESS,
		data
  }),
  updateFaqArticleFailed: (data) => ({
		type: adminActions.UPDATE_FAQ_ARTICLE_FAILED,
		data
  }),

  addFaqArticle: (data) => ({
		type: adminActions.ADD_FAQ_ARTICLE,
		data
  }),
  addFaqArticleSuccess: (data) => ({
		type: adminActions.ADD_FAQ_ARTICLE_SUCCESS,
		data
  }),
  addFaqArticleFailed: (data) => ({
		type: adminActions.ADD_FAQ_ARTICLE_FAILED,
		data
  }),

  getFaqArticles: (data) => ({
		type: adminActions.GET_FAQ_ARTICLES,
		data
  }),
  getFaqArticlesSuccess: (data) => ({
		type: adminActions.GET_FAQ_ARTICLES_SUCCESS,
		data
  }),
  getFaqArticlesFailed: (data) => ({
		type: adminActions.GET_FAQ_ARTICLES_FAILED,
		data
  }),

  updateFaqTopic: (data) => ({
		type: adminActions.UPDATE_FAQ_TOPIC,
		data
  }),
  updateFaqTopicSuccess: (data) => ({
		type: adminActions.UPDATE_FAQ_TOPIC_SUCCESS,
		data
  }),
  updateFaqTopicFailed: (data) => ({
		type: adminActions.UPDATE_FAQ_TOPIC_FAILED,
		data
  }),

  addFaqTopic: (data) => ({
		type: adminActions.ADD_FAQ_TOPIC,
		data
  }),
  addFaqTopicSuccess: (data) => ({
		type: adminActions.ADD_FAQ_TOPIC_SUCCESS,
		data
  }),
  addFaqTopicFailed: (data) => ({
		type: adminActions.ADD_FAQ_TOPIC_FAILED,
		data
  }),

  getFaqTopics: (data) => ({
		type: adminActions.GET_FAQ_TOPICS,
		data
  }),
  getFaqTopicsSuccess: (data) => ({
		type: adminActions.GET_FAQ_TOPICS_SUCCESS,
		data
  }),
  getFaqTopicsFailed: (data) => ({
		type: adminActions.GET_FAQ_TOPICS_FAILED,
		data
  }),

  getEbayUserSalesDetails: (data) => ({
		type: adminActions.GET_EBAY_USER_SALE_DETAILS,
		data
  }),
  getEbayUserSalesDetailsSuccess: (data) => ({
		type: adminActions.GET_EBAY_USER_SALE_DETAILS_SUCCESS,
		data
  }),
  getEbayUserSalesDetailsFailed: (data) => ({
		type: adminActions.GET_EBAY_USER_SALE_DETAILS_FAILED,
		data
  }),

  getEbayUserSales: (data) => ({
		type: adminActions.GET_EBAY_USER_SALES,
		data
  }),
  getEbayUserSalesSuccess: (data) => ({
		type: adminActions.GET_EBAY_USER_SALES_SUCCESS,
		data
  }),
  getEbayUserSalesFailed: (data) => ({
		type: adminActions.GET_EBAY_USER_SALES_FAILED,
		data
  }),

  getEbayStats: (data) => ({
		type: adminActions.GET_EBAY_STATS,
		data
  }),
  getEbayStatsSuccess: (data) => ({
		type: adminActions.GET_EBAY_STATS_SUCCESS,
		data
  }),
  getEbayStatsFailed: (data) => ({
		type: adminActions.GET_EBAY_STATS_FAILED,
		data
  }),

  getAdminUsersSegmentation: (data) => ({
		type: adminActions.GET_USERS_SEGMENTATION,
		data
  }),
  getAdminUsersSegmentationSuccess: (data) => ({
		type: adminActions.GET_USERS_SEGMENTATION_SUCCESS,
		data
  }),
  getAdminUsersSegmentationFailed: (data) => ({
		type: adminActions.GET_USERS_SEGMENTATION_FAILED,
		data
  }),

  getUserSuccessAdmin: (data) => ({
		type: adminActions.GET_USER_SUCCESS_ADMIN,
		data
  }),
  getUserSuccessAdminSuccess: (data) => ({
		type: adminActions.GET_USER_SUCCESS_ADMIN_SUCCESS,
		data
  }),
  getUserSuccessAdminFailed: (data) => ({
		type: adminActions.GET_USER_SUCCESS_ADMIN_FAILED,
		data
  }),

  getCancelReasonAdmin: (data) => ({
		type: adminActions.GET_CANCEL_REASON_ADMIN,
		data
  }),
  getCancelReasonAdminSuccess: (data) => ({
		type: adminActions.GET_CANCEL_REASON_ADMIN_SUCCESS,
		data
  }),
  getCancelReasonAdminFailed: (data) => ({
		type: adminActions.GET_CANCEL_REASON_ADMIN_FAILED,
		data
  }),

  getAdminEbayLogs: (data) => ({
		type: adminActions.USER_GET_EBAY_LOGS,
		data,
  }),
  getAdminEbayLogsSuccess: (data) => ({
		type: adminActions.USER_GET_EBAY_LOGS_SUCCESS,
		data,
  }),
  getAdminEbayLogsFailed: (data) => ({
		type: adminActions.USER_GET_EBAY_LOGS_FAILED,
		data,
  }),

  userEnableEbay: (data) => ({
		type: adminActions.USER_ENABLE_EBAY,
		data,
  }),
  userEnableEbaySuccess: (data) => ({
		type: adminActions.USER_ENABLE_EBAY_SUCCESS,
		data
  }),
  userEnableEbayFailure: () => ({
		type: adminActions.USER_ENABLE_EBAY_FAILURE,
  }),

  userFBCCompare: (data) => ({
		type: adminActions.USER_FBC_COMPARE,
		data
  }),
  userFBCCompareSuccess: (data) => ({
		type: adminActions.USER_FBC_COMPARE_SUCCESS,
		data
  }),
  userFBCCompareFailed: (data) => ({
		type: adminActions.USER_FBC_COMPARE_FAILED,
		data
  }),

  userWhitelist: (data) => ({
		type: adminActions.USER_WHITELIST,
		data
  }),
  userWhitelistSuccess: (data) => ({
		type: adminActions.USER_WHITELIST_SUCCESS,
		data
  }),
  userWhitelistFailed: (data) => ({
		type: adminActions.USER_WHITELIST_FAILED,
		data
  }),

  updateUser: (data) => ({
		type: adminActions.UPDATE_USER,
		data
  }),
  updateUserSuccess: (data) => ({
		type: adminActions.UPDATE_USER_SUCCESS,
		data
  }),
  updateUserFailed: (data) => ({
		type: adminActions.UPDATE_USER_FAILED,
		data
  }),

  getCategoriesStatsOther: () => ({
		type: adminActions.GET_CATEGORIES_STATS_OTHER,
  }),
  getCategoriesStatsOtherSuccess: (data) => ({
		type: adminActions.GET_CATEGORIES_STATS_OTHER_SUCCESS,
		data
  }),
  getCategoriesStatsOtherFailed: () => ({
		type: adminActions.GET_CATEGORIES_STATS_OTHER_FAILED,
  }),

  getCategoriesStatsBooks: () => ({
		type: adminActions.GET_CATEGORIES_STATS_BOOKS,
  }),
  getCategoriesStatsBooksSuccess: (data) => ({
		type: adminActions.GET_CATEGORIES_STATS_BOOKS_SUCCESS,
		data
  }),
  getCategoriesStatsBooksFailed: () => ({
		type: adminActions.GET_CATEGORIES_STATS_BOOKS_FAILED,
  }),

  getRepricerStats: () => ({
		type: adminActions.GET_REPRICER_STATS,
  }),
  getRepricerStatsSuccess: (data) => ({
		type: adminActions.GET_REPRICER_STATS_SUCCESS,
		data
  }),
  getRepricerStatsFailed: () => ({
		type: adminActions.GET_REPRICER_STATS_FAILED,
  }),

  getSBYBStats: () => ({
		type: adminActions.GET_SBYB_STATS,
  }),
  getSBYBStatsSuccess: (data) => ({
		type: adminActions.GET_SBYB_STATS_SUCCESS,
		data
  }),
  getSBYBStatsFailed: () => ({
		type: adminActions.GET_SBYB_STATS_FAILED,
  }),

  userEnableRepricer: (data) => ({
		type: adminActions.USER_ENABLE_REPRICER,
		data,
  }),
  userEnableRepricerSuccess: (data) => ({
		type: adminActions.USER_ENABLE_REPRICER_SUCCESS,
		data
  }),
  userEnableRepricerFailure: () => ({
		type: adminActions.USER_ENABLE_REPRICER_FAILURE,
  }),

  batchStats: () => ({
		type: adminActions.BATCH_STATS,
  }),
  batchStatsSuccess: (data) => ({
		type: adminActions.BATCH_STATS_SUCCESS,
		data
  }),
  batchStatsFailure: () => ({
		type: adminActions.BATCH_STATS_FAILURE,
  }),

  userTokenUpdate: (data) => ({
		type: adminActions.USER_AUTH_TOKEN_UPDATE,
		data
  }),
  userTokenUpdateSuccess: () => ({
		type: adminActions.USER_AUTH_TOKEN_UPDATE_SUCCESS,
  }),
  userTokenUpdateFailure: () => ({
		type: adminActions.USER_AUTH_TOKEN_UPDATE_FAILURE,
  }),

  searchUsersErrorLogs: () => ({
    type: adminActions.SEARCH_USERS_ERRORLOGS,
  }),
  searchUsersErrorlogsSuccess: (data) => ({
		type: adminActions.SEARCH_USERS_ERRORLOGS_SUCESS,
		data
  }),
  searchUsersErrorlogsFailure: () => ({
      type: adminActions.SEARCH_USERS_ERRORLOGS_FAILURE,
  }),

  searchUsersPerMarketplace: () => ({
      type: adminActions.SEARCH_USERS_PER_MARKETPLACE,
  }),
  searchUsersPerMarketplaceSuccess: (data) => ({
		type: adminActions.SEARCH_USERS_PER_MARKETPLACE_SUCESS,
		data
  }),
  searchUsersPerMarketplaceFailure: () => ({
      type: adminActions.SEARCH_USERS_PER_MARKETPLACE_FAILURE,
  }),

  changeUserPassword: (data) => ({
      type: adminActions.CHANGE_USER_PASSWORD,
	    data
  }),

  changeUserPasswordSuccess: () => ({
      type: adminActions.CHANGE_USER_PASSWORD_SUCCESS,
  }),

  changeUserPasswordFailure: () => ({
      type: adminActions.CHANGE_USER_PASSWORD_FAILURE,
  }),

  changeUserPasswordFailureModalClose: () => ({
      type: adminActions.CHANGE_USER_PASSWORD_FAILURE_MODAL_CLOSE,
  }),

  changeUserPasswordSuccessModalClose: () => ({
      type: adminActions.CHANGE_USER_PASSWORD_SUCCESS_MODAL_CLOSE,
  }),

  searchUser: (data) => ({
      type: adminActions.SEARCH_USER,
	    data
  }),

  searchUserSuccess: (data) => ({
		type: adminActions.SEARCH_USER_SUCCESS,
		data
  }),

  searchUserFailureModalClose: () => ({
        type: adminActions.SEARCH_USER_FAILURE_MODAL_CLOSE
  }),

  searchUserFailure: () => ({
        type: adminActions.SEARCH_USER_FAILURE
  }),

}

export default adminActions;

