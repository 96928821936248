import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";

const { getAdminUsersSegmentation } = adminActions;

/**
 * Show stats about user segmentation
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUsersSegmentation extends Component {
	componentDidMount(){
		this.props.getAdminUsersSegmentation();
	}

	displayRow(data, key){
		return (
			<tr key={key}>
				<td>
					{ data['role'] }
				</td>
				<td className="text-right">
					{ digitСonversion(data['count']) }
				</td>
			</tr>
		)
	}

  render() {
		// sort data desc
		let sorted = this.props.usersSegmentationByRole
		sorted.sort((a, b) => {
			return b['count'] - a['count']
		})

    return (
		<Card>
			<CardBody>
				<CardTitle>Users segmentation by role</CardTitle>
				<Table striped className="acc-table-minimal acc-table-left">
					<thead>
						<tr>
							<th>User Role</th>
							<th className="text-right">Users Count</th>
						</tr>
					</thead>
					<tbody>
					{sorted ? (
						sorted.map((row, key) => (
							this.displayRow(row, key)))
						) : (null)
					}
					</tbody>
				</Table>
			</CardBody>
		</Card>
    );
  }
}

export default connect(
  state => {
    return {
		  usersSegmentationByRole: state.Admin.get('usersSegmentationByRole'),
    };
  },
  {
	  getAdminUsersSegmentation
  }
)(AdminUsersSegmentation);
