import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../../../../shared/components/LoadingIndicator';
import { Row, Col, Button } from 'reactstrap';
import { templateConvertToBackend } from '../../../../../../helpers/repricer/converter/template_converter';

class TestTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
		showDebugInfo: false,
    }
  }

	toggleDebugInfo = () => {
		this.setState({ showDebugInfo: !this.state.showDebugInfo});
	}

  render() {
    return (
      <Fragment>
        { this.props.savingTemplate ?
          <LoadingIndicator title="Generating reprice reports..." className="m-0"  />
          :
          <div className="text-center mb-4">
			{this.props.savingTemplateResponse.error ?
				<p>
				  <span className="text-warning h5">Template not saved</span><br/>
				  Please read more details below
				</p>
				: null
			}
          </div>
		}
		{this.props.userData && this.props.userData.role === 'admin' ?
			<Button
				color="success"
				onClick={() => this.toggleDebugInfo()}
				visible={this.props.userData && this.props.userData.role === 'admin'}
			>{this.state.showDebugInfo ? "Close" : "Show"} Debug Info</Button>
				: null
		}
		{this.state.showDebugInfo && this.props.userData && this.props.userData.role === 'admin' ?
			<Fragment>
				<Row>
					<Col>
						<pre>
							{JSON.stringify(this.props.savingTemplateResponse, null, 2) }
						</pre>
					</Col>
				</Row>
				<Row>
					<Col>
						<pre>
							{JSON.stringify(this.props.workingTemplate.template_json, null, 2) }
						</pre>
					</Col>
					<Col>
						<pre>
							{JSON.stringify(templateConvertToBackend(this.props.workingTemplate), null, 2) }
						</pre>
					</Col>
				</Row>
			</Fragment>
			: null }
      </Fragment>
    );
  }
}

export default connect(
  state => ({
	  //workingTemplate: state.Repricer.get("workingTemplate"),
	savingTemplateResponse: state.Repricer.get("savingTemplateResponse"),
	savingTemplate: state.Repricer.get("savingTemplate"),
	userData: state.Auth.get('userData'),
  }),
  {
  }
)(TestTemplate);
