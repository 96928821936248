import React, { Component } from 'react';
import {
  CardDeck, Card, CardBody, CardImg, CardTitle, CardText,
  Modal, ModalBody, Button
} from 'reactstrap';
import { logoColor } from '../../../assets/images';
import { welcomeSettings, welcomeInventory, welcomeStartBatch, welcomeSupport, welcomeAccounting, welcomeConditionNotes } from './images';
import './style.css';
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { FaSpinner } from "react-icons/lib/fa";

const contentGroup = [
  {title: 'Settings', img_url: welcomeSettings, description: "Settings is where you'll adjust things that affect your account with us.  Some examples are billing credentials, Amazon and eBay credentials, MSKU building, adding Scouts or Suppliers, and other important inputs."},
  {title: 'Start a Batch', img_url: welcomeStartBatch, description: "This is where the money starts.  AFTER you have all of your settings ready and prepared, your condition notes setup, printer setup etc, it's now time to press this button and start your first batch."},
  {title: 'Inventory', img_url: welcomeInventory, description: "It will take a few hours to sync your inventory depending on its size but we'll sync all of your inventory so you can view it within AccelerList and also start to cross list it over to eBay."},
]

const contentGroup2 = [
  {title: 'Support', img_url: welcomeSupport, description: "This is where you'll interact with the AccelerList team.  We have a great team of seller success reps that are ready to take your questions and help you win on Amazon."},
  {title: 'Accounting', img_url: welcomeAccounting, description: "Our accounting section is designed to organize your Amazon and eBay revenue and expenses.  You'll want to visit this each month and upload your appropriate Amazon files to stay on top of your business."},
  {title: 'Condition Notes', img_url: welcomeConditionNotes, description: "We are not big believers in the importance of condition notes but we've built a robust section for you to enter in condition notes that can be applied to all listings."}
]

const source_channels = ['Facebook Group', 'YouTube Video', 'Internet Article or Blog', 'Google Search']

const fadeAnimation = keyframes`${fadeIn}`;
const FadeIn = styled.div`
	animation: .3s ${fadeAnimation};
`;

class WelcomeModal extends Component {
  state = {
    survey_source_channel: null,
    survey_loading: false,
    survey_submitted: false,
  }

  toggle = () =>  {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["welcome_screen_displayed"] = true;
			this.props.updateUserData(ud);
      let data = {
        welcome_screen_displayed: ud.settings["welcome_screen_displayed"]
      };
      this.props.updateUserSettings(data);
		}
  }

  render() {
    const {
      isOpen,
    } = this.props;

    return (
      <Modal isOpen={true} className="welcome-modal" centered>
        <ModalBody>
          <div className="header">
            <img src={logoColor} alt="" height={20} />
            <h4 className="title">Welcome to AccelerList</h4>
          </div>
          {!this.props.userData?.user_came_from ?
          <div className="text-center">
            <p className="lead">Where did you hear about us?</p>
            <div className="d-flex gap-3 flex-column mx-auto mb-4" style={{ maxWidth: '20rem' }}>
              {source_channels.map((item, idx) => (
              <Button
                key={`source-channel-${idx}`}
                className="text-left"
                color={this.state.survey_source_channel === item ? 'primary' : 'secondary'}
                onClick={() => this.setState({ survey_source_channel: item})}
              >
                {this.state.survey_source_channel === item && <span className="icon icon-check bg-white mr-2"></span>}
                {item}
              </Button>
              ))}
            </div>
            <Button
              color="success"
              disabled={!this.state.survey_source_channel}
              onClick={() => {
                this.props.updateUserDataSettings({
                  user_came_from: this.state.survey_source_channel.replaceAll(" ", "_").toLowerCase(),
                });
              }}
            >
              Submit
            </Button>
          </div>
          :
          <FadeIn style={{maxHeight: 700, overflowY: 'auto', paddingRight: 16 }}>
          <p className="text-center text-success">Thanks for your answer.</p>
          <p className="lead text-center">Before you get started, here are a few tips.</p>
          <CardDeck>
            {contentGroup.map((item, idx) =>
            <Card key={idx}>
              <CardImg top width="100%" src={item.img_url} alt="" />
              <CardBody>
                <CardTitle>{item.title}</CardTitle>
                <CardText>{item.description}</CardText>
              </CardBody>
            </Card>
            )}
          </CardDeck>
          <CardDeck>
            {contentGroup2.map((item, idx) =>
            <Card key={idx}>
              <CardImg top width="100%" src={item.img_url} alt="" />
              <CardBody>
                <CardTitle>{item.title}</CardTitle>
                <CardText>{item.description}</CardText>
              </CardBody>
            </Card>
            )}
          </CardDeck>
          <div className="footer">
            <Button
              color="success"
              onClick={e => this.toggle()}
            >Start Listing</Button>
          </div>
          </FadeIn>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default WelcomeModal;
