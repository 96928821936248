import React, { Component } from "react";
import { TabPane, FormGroup, Badge, Label, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";

class RepricerTabPane extends Component {
	state = {
		repricer_roi_type: null,
		repricer_roi_number: null,
	}

	updateROI = () => {
		var repricer_roi_type = this.state.repricer_roi_type;
		var repricer_roi_number = this.state.repricer_roi_number;
		if(!repricer_roi_number){ repricer_roi_number = "0" }
		if(!repricer_roi_type){ repricer_roi_type = "$" }

		repricer_roi_number = parseFloat(repricer_roi_number.replace(/,/gi, "")).toFixed(2);
		this.props.updateListingDefaultsData("repricer_roi_number", { target: { value: repricer_roi_number }})
		this.props.updateListingDefaultsData("repricer_roi_type", { target: { value: repricer_roi_type }})
		this.props.updateCurrentWorkingListingData(
			'repricer_roi_number',
			repricer_roi_number,
			false
		);
		this.props.updateCurrentWorkingListingData(
			'repricer_roi_type',
			repricer_roi_type,
			false
		);
	}

	changeValue = (key, e) => {
		this.setState({ [key]: e.target.value });
	}


	UNSAFE_componentWillReceiveProps(np){
		if(np && np.batchListingDefaults){
			if(np.batchListingDefaults.repricer_roi_number !== this.state.repricer_roi_number){
				this.setState({ repricer_roi_number: np.batchListingDefaults.repricer_roi_number });
			}
			if(np.batchListingDefaults.repricer_roi_type !== this.state.repricer_roi_type){
				this.setState({ repricer_roi_type: np.batchListingDefaults.repricer_roi_type });
			}
		}
	}

	render(){
	  const { tabId, batchListingDefaults } = this.props;
		return (
			<TabPane tabId={tabId}>
				<p>Protect your profit margins with <strong>Profit Reprice&trade;</strong></p>
				<FormGroup>
					<Label>
						Protect Minimum Profit&nbsp;
						<Badge color="danger" pill id="min-profit">NEW</Badge>
					</Label>
					<InputGroup>
						<Input
							value={this.state.repricer_roi_number ? this.state.repricer_roi_number : 0}
							onChange={e => this.changeValue("repricer_roi_number", e)}
						/>
						<InputGroupAddon addonType="append">
							<Button
								active={this.state.repricer_roi_type === '$'}
								onClick={e => this.changeValue("repricer_roi_type", {target :{value :"$"}})}
							>$</Button>
							<Button
								active={this.state.repricer_roi_type === '%'}
								onClick={e => this.changeValue("repricer_roi_type", {target :{value :"%"}})}
							>%</Button>
						</InputGroupAddon>
					</InputGroup>
					<Button
						className="mt-3"
						color="success"
						disabled={this.state.repricer_roi_type === batchListingDefaults.repricer_roi_type
								&& this.state.repricer_roi_number === batchListingDefaults.repricer_roi_number}
						onClick={e => this.updateROI()}
					>SAVE</Button>
				</FormGroup>
			</TabPane>
		);
	}
}

export default RepricerTabPane;
