import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import queryString from "query-string";
import authActions from "../../../redux/auth/actions";

const {
  userSPAPIAuthLWA,
} = authActions;

import {
    logoColorMd,
} from '../../../assets/images';

class AmazonLWA extends Component {

    componentDidMount = () => {
      const parsed_query = queryString.parse(this.props.location.search);
      const data = {
        selling_partner_id: parsed_query?.selling_partner_id,
        spapi_oauth_code: parsed_query?.spapi_oauth_code,
        state: parsed_query?.state,
      }
      this.props.userSPAPIAuthLWA(data);
    }

    render() {
        return (
            <div className="view">
                <form className="main-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 offset-md-3">
                                <div className="logo"><img src={logoColorMd} alt="logo" className="img-fluid" /></div>
                                <div className="f-card">
                                    <p className="title">Thank You</p>
                  									<p className="subtitle">THANK YOU - We are setting up access for Amazon...</p>
                                    <div className="f-container">
									                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            {moment().format('YYYY')} Copyright <span>AccelerList</span>.
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default connect(
  state => {
    return {
      user_spapi_auth_lwa_loading: state.Auth.get("user_spapi_auth_lwa_loading"),
    };
  },
  {
    userSPAPIAuthLWA,
  }
)(AmazonLWA);
