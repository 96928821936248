import React, { Component, Fragment } from 'react';
import {
  Button,
  Row
} from 'reactstrap'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import fileDownload from 'react-file-download'

import UploadIcon from "react-icons/lib/ti/upload-outline";
import './style.css';

const FileTemplate = [
  'seller_sku',
  'buy_cost',
  'supplier',
  'date_purchased',
]

class InventoryUploader extends Component {
  state = {
    error_text: "",
  }

  gettemplateFile = () => {
    fileDownload(FileTemplate, 'Inventory_MSKU_Information_Template.csv')
  }

  onDrop = (files) => {
    this.setState({ error_text: "" });
    const { uploadInventoryItemsFile } = this.props;
    files.forEach(file => {
      uploadInventoryItemsFile(file);
    });
  }

  dropRejected = () => {
    this.setState({ error_text: "Invalid file upload" });
  }

  render() {
    const { close } = this.props;
    return (
      <Fragment>
          <div className="text-center">Set a buy cost and supplier for each MSKU here easily. Please upload a CSV file with three columns: SellerSKU, Cost/Unit, and Supplier.</div>
          <div className="text-center">New values will override values already in the system.</div>
          <br />
          <Row style={{justifyContent: 'center'}}>
            <Button color="info" onClick={this.gettemplateFile}>
              GET THE TEMPLATE
            </Button>
          </Row>
          <br />
          <Row>
            <div className="dropzone-area">
              <Dropzone
              	className="dropzone-component"
                activeClassName="dropzone-component-active"
                onDrop={this.onDrop}
                onDropRejected={e => this.dropRejected()}
                accept={'.csv'}
                multiple={false}
              >
                <UploadIcon style={{width:"400px", height: "100px"}}/>
                <h1
                  style={{marginBottom: "0px"}}
                  className="text-center"
                >
                  {`Drag & Drop`}
                </h1>
                <br />
                <p
                  style={{fontWeight: 600, color: "#aaa"}}
                  className="text-center"
                >
                  YOUR FILES HERE TO UPLOAD
                </p>
                <p
                  style={{fontWeight: 600, color: "#21323b", fontStyle: 'italic'}}
                  className="text-center"
                >
                  (Compatible with InventoryLab's Inventory Report)
                </p>
                {this.state.error_text ?
                    <h3
                      style={{marginBottom: "0px", color: "red"}}
                      className="text-center"
                    >{this.state.error_text}</h3>
                    : null
                }
              </Dropzone>
            </div>
          </Row>
          <Row style={{justifyContent: 'center'}}>
            <Button className="text-center" color="secondary" onClick={close}>Cancel</Button>
          </Row>
        </Fragment>
    );
  }
}

InventoryUploader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  uploadInventoryItemsFile: PropTypes.func.isRequired
}

export default InventoryUploader;
