import React, { Component } from 'react';
import { 
  Card, CardBody, Input, InputGroup, InputGroupAddon, Button, Nav, NavItem, NavLink, 
  TabContent, TabPane 
} from 'reactstrap';
import { FaSearch, FaEnvelope } from 'react-icons/lib/fa';

const ViewHeader = () => (
  <header className="view-header">
    <h1 className="page-title">eBay Customer Messages</h1>
  </header >
);

const ViewContent = ({ children }) => (
  <div className="view-content view-components">
    <Card>
      <CardBody>{children}</CardBody>
    </Card>
  </div>
);

class EbayMessages extends Component {
  render() {
    return (
      <div className="view ebay-messages">
        <ViewHeader />
        <ViewContent>
          <Nav tabs>
            <NavItem>
              <NavLink active>All</NavLink>
            </NavItem>
          </Nav>
          <TabContent className="py-4">
            <TabPane>
              <div className="search-box">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Input type="select">
                      <option>Inbox</option>
                    </Input>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="prepend">
                    <Input type="select">
                      <option>Filter messages</option>
                    </Input>
                  </InputGroupAddon>
                  <Input placeholder="Search for a message..." />
                  <InputGroupAddon addonType="append">
                    <Button color="primary"><FaSearch /></Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div className="empty-message text-center py-4">
                <FaEnvelope size="48" color="#ccc" />
                <p className="lead mb-1 mt-2">Could not find any messages</p>
                <p className="text-muted">Try changing filters or search term</p>
              </div>
            </TabPane>
          </TabContent>
        </ViewContent>
      </div>
    )
  }
}

export default EbayMessages;