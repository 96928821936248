import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormText } from "reactstrap";
import Dropzone from 'react-dropzone';
import { FaFileImageO, FaClose, FaRefresh } from 'react-icons/lib/fa';
import ebayActions from "../../../../redux/ebay/actions";
import { connect } from "react-redux";

const IMG_MAX_COUNT = 5;
const IMG_MAX_SIZE_TEXT = 1; // MB
const IMG_MAX_SIZE = 1 * 1048576;

const { ebayCrossListImageUpload } = ebayActions;
class EbayImagesUploadModal extends Component {
  state = {
    file_invalid: false,
    length_maxed: false,
  };

  generateImage = (image, idx) => {
    return (
      <div className="image-item" key={`image_${idx}`}>
        {image ? (
          <React.Fragment>
            <img src={image} alt="" className="img-fluid" />
            <Button
              size="sm"
              color="danger"
              className="btn-remove-image"
              onClick={(e) => this.removeImage(e,idx,image)}
            >
              <FaClose />
            </Button>
          </React.Fragment>
        ) : (
          <FaFileImageO size="20" />
        )}
      </div>
    );
  };

  generateImages = (data, maxCount) => {
    let images = [];
    for (let i = 0; i < maxCount; i++) {
      if (data[i]) {
        images.push(this.generateImage(data[i], i));
      } else {
        images.push(this.generateImage(null, i));
      }
    }
    return images;
  };

  onDropImages = (acceptedFiles, fileRejections) => {
    this.setState({ file_invalid: false });
    this.setState({ length_maxed: false });
    //rejected file [over max size]
    if (fileRejections.length > 0) {
      this.setState({ file_invalid: true });
      return null;
    }
    let uploading_img =[];
    var img_urls = [];
    if (this.props.ebay_list_item_data.img_urls) {
      img_urls = this.props.ebay_list_item_data.img_urls;
    }
    if (acceptedFiles.length + img_urls.length <= IMG_MAX_COUNT) {
      for (let j = 0; j < acceptedFiles.length; j++) {
        if (acceptedFiles[j].size > IMG_MAX_SIZE) {
          this.setState({ file_invalid: true });
        } else {
          uploading_img.push(acceptedFiles[j]);
        }
      }
      if(uploading_img.length > 0){
        this.props.ebayCrossListImageUpload(uploading_img);
      }
    } else {
      this.setState({ length_maxed: true });
    }
  };

  removeImage = (e, idx, val) => {
    e.stopPropagation();
    const data = [{
			key: "img_urls",
			value: val,
			type: "array",
			action: 'delete',
		}]
    this.props.deleteCrossListingImage(data);
  };

  render() {
    const { isOpen, toggle, ebay_list_item_data, ebay_cross_list_images_upload_working} =
      this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Upload your images</ModalHeader>
        <ModalBody>
          Drag and drop your images to area below. Max {IMG_MAX_COUNT} images.
          {/* IF uploading */}
          {ebay_cross_list_images_upload_working ? (
            <div className="mt-1 text-muted">
              <FaRefresh className="animate-spin" /> Uploading images...
            </div>
          ) : (
            ""
          )}
          <Dropzone
            className="drag-drop-upload-images mb-2 d-inline-flex"
            onDrop={this.onDropImages}
            multiple={true}
            accept="image/jpeg, image/png"
            style={{ cursor: "pointer" }}
          >
            {ebay_list_item_data?.img_urls
              ? this.generateImages(
                   ebay_list_item_data.img_urls,
                  IMG_MAX_COUNT
                )
              : this.generateImages([], IMG_MAX_COUNT)}
          </Dropzone>
          <FormText>
            Supported file: .png, .jpg. Max size: {IMG_MAX_SIZE_TEXT} MB
          </FormText>
          {this.state.file_invalid ? (
            <FormText className="text-danger">
              <b>File uploaded not valid</b>
            </FormText>
          ) : null}
          {this.state.length_maxed ? (
            <FormText className="text-danger">
              <b>Max {IMG_MAX_COUNT} images are allowed</b>
            </FormText>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    ebay_list_item_data: state.Ebay.get("ebay_list_item_data"),
    ebay_cross_list_images_upload_working: state.Ebay.get("ebay_cross_list_images_upload_working"),
	}),
	{
		ebayCrossListImageUpload,
	}
)(EbayImagesUploadModal);