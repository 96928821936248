import React, { Component } from 'react';
import {
  Form, FormGroup, Label, Button, Input, Alert
} from 'reactstrap';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';
import './style.css';
import { get_marketplace_mapping } from '../../../../helpers/utility';
import "react-awesome-popover/build/index.css";

const labelFlag = (data) => (
	<span>{data.label} <FlagIcon code={data.flag} /></span>
);

class AmazonAuthorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketplace: null,
      amazon_seller_id: "",
	    amazon_seller_id_ok: false,
    }
  }

  check_seller_id = (seller_id) => {
    var amazon_seller_id_ok = /^[A-Z0-9]*$/.test(seller_id);
    if(!seller_id){ amazon_seller_id_ok = false; }
		this.setState({ amazon_seller_id_ok: amazon_seller_id_ok });
	}

	UNSAFE_componentWillReceiveProps(np){
		this.setState({
			marketplace: get_marketplace_mapping(false, np.amazon_marketplace),
			amazon_seller_id: np.amazon_seller_id,
		});
		this.check_seller_id(np.amazon_seller_id);
	}

  marketplaceChanged = (selected) => {
    this.setState({ marketplace: selected });
  }

	handleInput = (e, source) => {
		this.setState({
	      [source]: e.target.value
		});
		if(source === "amazon_seller_id"){
			this.check_seller_id(e.target.value);
		}
	}

	linkAccount = () => {
		let ud = this.props.userData;
		ud.sellerId = this.state.amazon_seller_id;
		ud.marketplaceId = this.state.marketplace.marketplaceID;
		this.props.updateUserData(ud);
    /*
		this.props.updateUserDataSettings({
      seller_id: this.state.amazon_seller_id,
      marketplace_id: this.state.marketplace.marketplaceID,
    });
    */
    this.props.userSPAPIAuthStart({
      source: "onboarding",
      marketplace: this.state.marketplace.marketplaceID,
      seller: this.state.amazon_seller_id,
    });
	}

  render() {
    return(
      <Form className="form-link-account">
        <FormGroup>
          <Label>Pick your marketplace carefully</Label>
          <div style={
            !this.state.marketplace ?
            {
                borderColor: "red",
                borderStyle: "solid",
                borderWidth: "1px"
            } : null
          }>
            <Select
              options={ get_marketplace_mapping(true) }
              optionRenderer={labelFlag}
              valueRenderer={labelFlag}
              value={this.state.marketplace}
              onChange={this.marketplaceChanged}
              clearable={false}
            />
          </div>
        </FormGroup>
        <FormGroup id="seller-id-input">
          <Label>Link your Amazon {this.state.marketplace !== null && this.state.marketplace.value} account to AccelerList</Label>
          <Label className="label-secondary">Seller ID (Merchant Number)<span className="text-danger">*</span></Label>
          <Input
            value={this.state.amazon_seller_id}
            onChange={e => this.handleInput(e, "amazon_seller_id")}
            style={this.state.amazon_seller_id_ok ? {} : { borderColor: "red" }}
          />
          <div className="popover show bs-popover-right popover-help-bubble">
            <div className="popover-body">
              Need help finding your Seller ID?
              Feel free to chat us by clicking picture on
              the bottom right of your screen.
            </div>
          </div>
        </FormGroup>
        <Button
          type="button"
          color="success"
          block
          onClick={this.linkAccount}
          disabled={
            !this.state.amazon_seller_id_ok
              || !this.state.marketplace
          }
        >LINK ACCOUNT</Button>
        <FormGroup className="mt-2">
          <Alert color="warning">
            Upon clicking the “Link Account” button you will be transferred to Amazon Seller Central, accept the Terms and Conditions. Then confirm and you should get back on this screen.
          </Alert>
        </FormGroup>
      </Form>
    );
  }
}

export default AmazonAuthorization;
