import React, { Component } from 'react';
import {
  Input,
} from 'reactstrap';

import CustomSKUSelector from '../../../shared/components/customSKUSelector';
import './index.css';
import Select from 'react-select';

const dropdownSelect = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

class SKUSettings extends Component {
  constructor () {
    super();
    this.state = {
      loadedForFirstTime: true,
    }
  }

  handleChangeUseDefaultCustomSKU = (e) => {
    if (e) {
      const updatedData = {
        should_use_custom_sku_template: e.value,
        sku_prefix: ""
      };
      this.props.onSaveListingDefaults(updatedData);
    }
  }
	render() {
    const {
      UseDefaultCustomSKU,
    } = this.state;
		return (
      <div id="SKU_Settings">
        <div className="d-flex align-items-center mb-4">
          <strong>Would you like to use a default custom MSKU when listing?</strong>
          <div style={{ width: '100px', marginLeft: '20px'}}>
            <Select
              options={dropdownSelect}
              value={this.props.UseDefaultCustomSKU
                  ? dropdownSelect.find(item => item.value === true)
                  : dropdownSelect.find(item => item.value === false)}
              onChange={e => this.handleChangeUseDefaultCustomSKU(e)}
              clearable={false}
            />
          </div>
        </div>

        <CustomSKUSelector
          isOpen={this.props.UseDefaultCustomSKU}
          listingDefaults={this.props.listingDefaults}
          onSaveListingDefaults={this.props.onSaveListingDefaults}
          userData={this.props.userData}
          show_save_template={true}
        />
      </div>
		);
	}
}

export default SKUSettings;
