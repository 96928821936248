import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  EditableCurrencyField,
  EditableDateField,
  EditableQtyField,
  EditableStringField
} from "../../../../../shared/components/editableReactTableCells";
import { getConditionOptions } from "../../../../../helpers/batch/utility";
import { Button, FormGroup, Input, Label } from "reactstrap";
import moment from "moment";

const condEum = getConditionOptions();

class ReplenishmentWarningModalTable extends Component {
  state = {
    showClosed: false,
  }

  render() {

    const {
      replenishableListings,
      onChangeItemValue,
      addItemToBatch
    } = this.props;

    let tableColumns = [
      {
        id: "seller_sku",
        Header: "Merchant SKU",
        accessor: "seller_sku",
        minWidth: 200,
        headerClassName: "text-left",
        Cell: props => (
          <span className={props.original.closed ? "text-muted" : ""}>
            {props.value}
          </span>
        )
      },
      {
        id: "item_condition",
        Header: "Item Condition",
        accessor: "item_condition",
        className: "nowrap",
        Cell: props => (
          <span className={props.original.closed ? "text-muted" : ""}>
            {(condEum[props.value] || {}).value}
          </span>
        )
      },
      {
        id: "item_note",
        Header: "Item Note",
        accessor: "item_note",
        Cell: props => (
          <span className="editable-cell-input">
            <EditableStringField
              cellInfo={props}
              onChangeValue={(e) => onChangeItemValue(e)}
              disabled={props.original.closed}
            />
          </span>
        ),
      },
      {
        id: "channel",
        Header: "Channel",
        accessor: "fulfillment_channel",
        className: "nowrap",
        Cell: props => (
          <span className={props.original.closed ? "text-muted" : ""}>
            {props.value}
          </span>
        )
      },
      {
        id: "active_quantity",
        Header: "Active Qty.",
        accessor: "active_quantity",
        className: "text-center",
        Cell: props => (
          <span className={props.original.closed ? "text-muted" : ""}>
            {props.value}
          </span>
        )
      },
      {
        id: "quantity",
        Header: "Add Qty.",
        accessor: "quantity",
        Cell: props => (
          <span className="editable-cell-input">
            <EditableQtyField
              cellInfo={props}
              onChangeValue={(e) => onChangeItemValue(e)}
              disabled={props.original.closed}
            />
          </span>
        ),
      },
      {
        id: "price",
        Header: "Price",
        minWidth: 150,
        accessor: "price",
        Cell: props => (
          <span className="editable-cell-input">
            <EditableCurrencyField
              cellInfo={props}
              onChangeValue={(e) => onChangeItemValue(e)}
              disabled={props.original.closed}
            />
          </span>
        ),
      },
      {
        id: "expDate",
        Header: "Expiration date",
        minWidth: 150,
        accessor:  d => moment(d.expDate),
        Cell: props => (
          <span className="editable-cell-input">
            <EditableDateField
              cellInfo={props}
              onChangeValue={(e) => onChangeItemValue(e)}
              disabled={props.original.closed}
            />
          </span>
        ),
      },
      {
        Header: "",
        minWidth: 150,
        accessor:  "seller_sku",
        Cell: props => (
          <Button
            color="success"
            onClick={() => addItemToBatch(props.original)}
            disabled={props.original.closed}
          >
            Replenish
          </Button>
        ),
      }
    ];

    return (
      <React.Fragment>
        {replenishableListings ? (
          <div className="mt-3">
          <FormGroup check className="custom-check checkbox">
            <Label check>
              <Input
                type="checkbox"
                checked={!this.state.showClosed}
                onChange={e => {this.setState({showClosed: !this.state.showClosed})}}
              /> <span>Hide closed items</span>
            </Label>
          </FormGroup>
          <ReactTable
            style={{ width: "100%"}}
            data={
              this.state.showClosed
                ? replenishableListings
                : replenishableListings.filter(item => item.closed != true)
            }
            columns={tableColumns}
            defaultPageSize={replenishableListings.length}
            showPagination={false}
            sortable={false}
            minRows={0}
          />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
ReplenishmentWarningModalTable.propTypes = {
  replenishableListings: PropTypes.array,
  onChangeItemValue: PropTypes.func,
  addItemToBatch: PropTypes.func
};

export default ReplenishmentWarningModalTable;
