import set from 'lodash/set';
import { getNewConditionOptions } from '../batch/utility';

export function utilRepricerTemplateUpdate(template, value){
	Object.keys(value).forEach(key => {
		set(template.template_json, key, value[key]);
	});
	return template;
}

export function getNewConditionOptionsForRepricer(){
	var export_data = [];
	getNewConditionOptions().forEach(item => {
		export_data.push({
			label: item.title,
			value: item.value,
			repricer: item_condition_mapping_repricer_backend[item.value],
		});
	});
	//add one that we do not have in AL
	export_data.push({
		label: "Not Used",
		value: "NotUsed",
		repricer: "9"
	});
	return export_data;
}

const item_condition_mapping_repricer_backend = {
	"UsedLikeNew": "1",
	"UsedVeryGood": "2",
	"UsedGood": "3",
	"UsedAcceptable": "4",
	"CollectibleLikeNew": "5",
	"CollectibleVeryGood": "6",
	"CollectibleGood": "7",
	"CollectibleAcceptable": "8",
	"NotUsed": "9",
	"Refurbished": "10",
	"NewItem": "11",
}

export function mapALConditionsToRepricerBackend(conditions){
	var export_data = [];
	if(!conditions){ return export_data }
	conditions.forEach(item => {
		export_data.push(item.repricer);
	});
	return export_data;
}

export function mapRepricerBackendConditionsToAL(conditions){
	var export_data = [];
	if(!conditions){ return export_data }
	const mapping = getNewConditionOptionsForRepricer();
	mapping.forEach(item => {
		if(conditions.includes(item.repricer)){
			export_data.push({
				label: item.label,
				value: item.value,
				repricer: item.repricer,
			});
		}
	});
	return export_data;
}

export function generateRepricerReportSpreadsheetData(data) {
  const templates = data.reprice_report.template_id_to_name_mapping;

  let headers = [
    'template_name',
    'asin_product_id',
    'seller_sku',
    'title',
    'new_price',
    'old_price',
    'adjustment',
    'inventory_age',
    'price',
    'reject_string',
  ];

  let rows = [];
  data.reprice_report.approve_report.forEach(product => {
    let row = {
      'template_name': product.template_name,
      'asin_product_id': product.asin_product_id,
      'seller_sku': product.seller_sku,
      'title': product.title,
      'new_price': product.new_price,
      'old_price': product.old_price,
      'adjustment': product.adjustment,
	  }

    headers.forEach(header => {
      if (!row[header]) {
        row[header] = "";
      }
    });
    rows.push(row);
  });
  data.reprice_report.reject_report.forEach(product => {
    let row = {
      'template_name': templates[product.template_id],
      'asin_product_id': product.asin_product_id,
      'seller_sku': product.seller_sku,
      'title': product.title,
      'inventory_age': product.inventory_age,
      'price': product.price,
      'reject_string': product.reject_string,
	  }

    headers.forEach(header => {
      if (!row[header]) {
        row[header] = "";
      }
    });
    rows.push(row);
  });
  return rows;
}
