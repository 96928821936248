import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import BConfirmationModal from "../../../../shared/components/BConfirmationModal";

const {
	searchUser,
	searchUserFailureModalClose,
	userEnableEbay,
  userAdminLogActionExport,
} = adminActions;

/**
 * Display user data
 */
const DisplayUserData = ({
  data,
  buttonsDisabled,
  enableEbay,
  user_export_action_log_working,
  userAdminLogActionExport,
}) => {
	const login_url = '../admin_login/' + data.id;
	return (
		<div><br />
		<b>Username:</b> {data.username}<br />
		<b>Email:</b> {data.email}<br />
		<b>Business Name:</b> {data.business_name}<br />
		<b>Seller ID:</b> {data.seller_id}<br />
		<b>Customer ID:</b> {data.customer_id}<br />
		<b>Auth Token:</b> {data.auth_token}<br />
		<b>Marketplace ID:</b> {data.marketplace_id}<br />
		<b>Active Subscription:</b> {data.has_active_subscription}<br />
		<b>In Trial:</b> {data.is_trialing}<br />
		<b>Plan:</b> {data.paln}<br />
		<b>Phone:</b> {data.phone}<br />
		<b>Created:</b> {data.created_at}<br />
		<b>Repricer enabled:</b> {data.is_repricer_enabled ? data.is_repricer_enabled.toString() || "false" : "false"}<br />
    <b>eBay enabled:</b> {data.is_ebay_enabled ? data.is_ebay_enabled.toString() || "false" : "false"}<br />
    <b>SP-API enabled:</b> {data.sp_api_token.toString()}<br /><br />
		<a className="btn btn-success" href={login_url}>LOGIN AS USER</a>
		&nbsp;
		{data.is_ebay_enabled ? (
			<Button
				color="danger"
				onClick={() => enableEbay(false)}
				disabled={buttonsDisabled}
			>
				DISABLE EBAY
			</Button>
		) : (
			<Button
				color="success"
				onClick={() => enableEbay(true)}
				disabled={buttonsDisabled}
			>
				ENABLE EBAY
			</Button>
		)}
		&nbsp;
    <Button
      color="success"
      onClick={() => userAdminLogActionExport({username: data.username})}
      disabled={user_export_action_log_working}
    >
      EXPORT LOGS
    </Button>
		</div>
	)
}

/**
 * Component to search users and display data
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminSearchUser extends Component {
	constructor (props) {
		super(props);
		this.state = {
			userUN: "",
			showUserData: false,
			showUserDataFailed: false,
		};
	}

  UNSAFE_componentWillReceiveProps(newProps){
		const { showUserData, showUserDataFailed } = newProps;
		if(showUserData !== this.props.showUserData){
			this.setState({showUserData: showUserData});
		}
		if(showUserDataFailed !== this.props.showUserDataFailed){
			this.setState({showUserDataFailed: showUserDataFailed});
		}
  }

  handleUnChange = (event) => {
		this.setState({userUN: event.target.value});
  }

  processSearchUser = () => {
		const data = { username_or_email: this.state.userUN }
		this.props.searchUser(data);
  }

  toggleUserData = () =>{
		this.setState({showUserData: false});
  }

  toggleUserDataFailed = () => {
		this.setState({showUserData: false, showUserDataFailed: false});
		this.props.searchUserFailureModalClose();
  }

  enableEbay = (is_ebay_enabled) => {
		const data = {
			username: this.props.userData.username,
			is_ebay_enabled: is_ebay_enabled,
		}
		this.props.userEnableEbay(data);
  }

  render() {
    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>User search</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username or email:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={this.handleUnChange}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								color="success"
								onClick={this.processSearchUser}
							>
								SEARCH USER
							</Button>
							{this.props.showUserData ?
								<DisplayUserData
									data={this.props.userData}
									buttonsDisabled={this.props.user_enabling_repricer}
									enableEbay={this.enableEbay}
                  user_export_action_log_working={this.props.user_export_action_log_working}
                  userAdminLogActionExport={this.props.userAdminLogActionExport}
								/>
								: null
							}
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
			<BConfirmationModal
				isOpen={this.state.showUserDataFailed}
				closeModal={this.toggleUserDataFailed}
				modalTitle="User Not Found"
				modalText="User is not found!"
				buttonColor="danger"
				buttonAction={this.toggleUserDataFailed}
				buttonText="OK"
			/>
	</div>
    );
  }
}

export default connect(
  state => {
    return {
		showUserData: state.Admin.get('showUserData'),
		showUserDataFailed: state.Admin.get('showUserDataFailed'),
		userData: state.Admin.get("userData"),
		user_enabling_repricer: state.Admin.get("user_enabling_ebay"),
		user_export_action_log_working: state.Admin.get("user_export_action_log_working"),
    };
  },
  {
	  searchUser,
    searchUserFailureModalClose,
    userEnableEbay,
    userAdminLogActionExport,
  }
)(AdminSearchUser);
