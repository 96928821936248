import React, { Component } from 'react';
import VerifyPhoneComponent from '../../../shared/components/VerifyPhoneComponent';
import './style.css';

class VerifyPhone extends Component {
  render() {
    return (
      <div className="view view-verify-phone">
        <VerifyPhoneComponent />
      </div>
    )
  }
}

export default VerifyPhone;