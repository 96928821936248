import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Button, Input, Label, Alert } from 'reactstrap';
import { MdWarning  } from "react-icons/lib/md";

const reasons = [
  { reason_type: "Cost", response: "how much is the reasonable cost should be?" },
  { reason_type: "Difficulty of Use", response: "the difficulty you experience while using AccelerList." },
  { reason_type: "Missing Functionality", response: "what functionality you didn't find at AccelerList?" },
  { reason_type: "Using Other Product", response: "what other product(s) are you using?" },
  { reason_type: "Not Using It", response: "why are you not using it?" },
  { reason_type: "Something Else", response: "something that make you cancel subscription." },
]

class CancelReasonModal extends Component {
  state = {
    selected_type: "",
    given_response: ""
  }

  selectReason = (type) => {
    this.setState({ selected_type: type })
  }

  processCancellation = () => {
    this.props.buttonAction(this.state);
  }

  render() {
    const {
      isOpen,
      toggle,
      modalTitle,
      buttonText,
      closeModal
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={closeModal} size="lg" modalClassName="cancel-reason-modal" centered>
        <ModalHeader toggle={closeModal} className="pb-0">&nbsp;</ModalHeader>
        <ModalBody className="pt-0">
          <div className="mb-5">
            <h5 className="title">Are you sure?</h5>
            <Alert color="danger" className="d-flex align-items-center">
              <MdWarning size="24" style={{ flex: "1 0 auto" }} className="mr-2" />
              <span>
                Once you cancel your subscription, your account and associated data will be <strong>PERMANENTLY DELETED</strong> and <strong>CANNOT BE RESTORED</strong>.
              </span>
            </Alert>
          </div>
          <h5 className="title">Please indicate the reason for deleting {modalTitle}:</h5>
          <FormGroup className="reason-selection">
            {reasons.map((item, idx) => (
              <label
                className={`reason-item ${this.state.selected_type === item.reason_type ? "selected" : ""}`}
                key={idx}
                onClick={() => this.selectReason(item.reason_type)}
              >
                <input type="radio" name="reason" />
                <span>{item.reason_type}</span>
              </label>
            ))}
          </FormGroup>
          <FormGroup>
            <Label>
              Before you click <strong>{buttonText}</strong>,&nbsp;
              {this.state.selected_type === "" ?
              "please choose on of the reason above."
              :
              <span>Please let us know <strong>{reasons.find(item => item.reason_type === this.state.selected_type).response}</strong></span>
              }
            </Label>
            <Input type="textarea" rows="3" value={this.state.given_response} onChange={e => this.setState({ given_response: e.target.value })} />
          </FormGroup>
          <div className="d-flex justify-content-between">
            <Button
              color="success"
              onClick={closeModal}
            >
                <a
                  style={{color:"white"}}
                  href="mailto:gethelp@accelerlist.com"
                >Contact Support</a>
            </Button>
            <Button
              color="danger"
              onClick={this.processCancellation}
              disabled={!this.state.selected_type || !this.state.given_response}
            >{buttonText}</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default CancelReasonModal;
