import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Label, Input
} from 'reactstrap';
import InputAmountType from '../../../../../../../shared/components/Form/InputAmountType';
import repricerActions from '../../../../../../../redux/repricer/actions';
import AlertTooltip from '../../../components/AlertTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class DefaultScenario extends Component {
  constructor(props) {
    super(props);

    this.state = {
		repriceAmountType: '$',
		repriceAmount: 0,
		own_inventory_not_in_the_buy_box_offer_targeting: "lowest_offer_with_same_main_condition",
		repriceUpDown: "below",
    }
  }

	UNSAFE_componentWillMount(){
		var repriceAmount = this.props.workingTemplate.template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer;
		var	repriceUpDown = this.props.workingTemplate.template_json.pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false ? 'above' : 'below';
		this.setState({
			repriceAmountType: this.props.workingTemplate.template_json.pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			own_inventory_not_in_the_buy_box_offer_targeting: this.props.workingTemplate.template_json.offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting,
			repriceAmount: repriceAmount,
			repriceUpDown: repriceAmount !== 0 ? repriceUpDown : 'match',
		});
	}

	radioSetOutBuyBox = (e) => {
		this.setState({
			own_inventory_not_in_the_buy_box_offer_targeting: e.target.value,
		});
		this.props.workingTemplateUpdateJson(
				{data: {offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting: e.target.value } })
	}

	updateRepriceUpDown = (e) => {
		this.setState({ repriceUpDown: e.target.value });
		var data = {
			pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false: e.target.value === "above" ? true : false,
		}
		if(e.target.value === 'match'){
			this.updateRepriceAmount(0);
			data['pricing_rules_adjustment_adjustment_amount_on_competing_offer'] = 0;
		}
		this.props.workingTemplateUpdateJson({ data: data	})
	}

	updateRepriceAmount = (amount) => {
		this.setState({ repriceAmount: amount });
	}

  toggleRepriceAmountType = () => {
    this.setState({ repriceAmountType: this.state.repriceAmountType === '$' ? '%' : '$' });
  }

  render() {
    return(
      <Fragment>
        <FormGroup>
          <p>If my listing is <strong>NOT in the Buy Box</strong>, than do this:</p>
          <FormGroup check className="custom-check custom-check-block radio">
            <Label check>
				<Input
					type="radio"
					name="defaultReprice"
					value="lowest_offer_with_same_main_condition"
					checked={this.state.own_inventory_not_in_the_buy_box_offer_targeting === "lowest_offer_with_same_main_condition"}
					onChange={e => this.radioSetOutBuyBox(e)}
				/> <span>Reprice my products against the lowest possible competing offer</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="defaultReprice"
					value="lowest_offer_with_same_main_condition_and_same_or_better_subcondition"
					checked={this.state.own_inventory_not_in_the_buy_box_offer_targeting === "lowest_offer_with_same_main_condition_and_same_or_better_subcondition"}
					onChange={e => this.radioSetOutBuyBox(e)}
				/> <span>Reprice my products against the lowest possible competing offer with the same condition or sub condition or better</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="defaultReprice"
					value="buy_box_offer"
					checked={this.state.own_inventory_not_in_the_buy_box_offer_targeting === "buy_box_offer"}
					onChange={e => this.radioSetOutBuyBox(e)}
				/> <span>Reprice against the current Buy Box offer</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="defaultReprice"
					value="lowest_offer_with_same_main_and_sub_condition"
					checked={this.state.own_inventory_not_in_the_buy_box_offer_targeting === "lowest_offer_with_same_main_and_sub_condition"}
					onChange={e => this.radioSetOutBuyBox(e)}
				/> <span>Reprice my products against the lowest competing offer with the same condition</span>
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup className="d-flex align-items-center">
          <span>Againts that offer, reprice </span>
				<InputAmountType
					amountType={this.state.repriceAmountType}
					toggle={this.toggleRepriceAmountType}
					updateAmount={this.updateRepriceAmount}
					updateTemplateJson={this.props.workingTemplateUpdateJson}
					dataInTemplateJson="pricing_rules_adjustment_adjustment_amount_on_competing_offer"
					typeDataInTemplateJson="pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false"
					className="ml-2"
					value={this.state.repriceAmount}
					min={0}
				/>
		  <Input
			  type="select"
			  className="mx-2 w-auto"
			  onChange={e => this.updateRepriceUpDown(e)}
			  value={this.state.repriceUpDown}
		  >
      <option value="above">above</option>
      <option value="match">match</option>
			<option value="below">below</option>
          </Input>
          <span>the competing offer.</span>
					<AlertTooltip
						target="alert_defaultReprice"
						message={this.props.templateWarning}
						range={[17, 18]}
					/>
        </FormGroup>
				{/*
				<FormGroup check inline className="custom-check radio">
					<Label className="mr-4">When competing against the Buy Box, match:</Label>
					<Label check>
						<Input type="radio" name="againtsBBMatch" /> <span>Used</span>
					</Label>
					<Label check>
						<Input type="radio" name="againtsBBMatch" /> <span>New</span>
					</Label>
					<Label check>
						<Input type="radio" name="againtsBBMatch" /> <span>Both</span>
					</Label>
				</FormGroup>
				*/}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
  }),
  {
	workingTemplateUpdateJson,
  }
)(DefaultScenario);
