import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

/**
 * Subscriptio Error Modal
 *
 * @version 1.0.0
 * @author [bojan]
 */
class SubscriptioErrorModal extends Component {
	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				centered
				size="lg"
				backdropClassName="blurred-backdrop"
				modalClassName="modal-centered"
			>
				<ModalBody>
					<div className="text-center">
						<h2>Subscription issue</h2>
						<p>It looks like there is a problem with your subscription.</p>
					</div>
					<div className="d-flex justify-content-between my-5">
						<div style={{ flex: "0 0 100%" }} className="mr-1">
							<h3 className="text-success h5">Don’t worry, your account is safe.</h3>
							<p>Please click on the blue button below to navigate to the settings area where you can resolve the billing discrepancy.  Either enter in a new card if it has expired or restart your subscription with the existing card so we can re-activate your account again.  Let's get you making money on Amazon again!</p>
						</div>
					</div>
					<div className="text-center">
            <Link
              className="btn btn-primary"
              to="/dashboard/settings?tab=7"
            >
              To Settings
            </Link>
					</div>
					<p className="text-center text-muted mt-5 ">Having problem? Please contact us via chat on the bottom right of the page</p>
				</ModalBody>
			</Modal>
		);
	}
}

SubscriptioErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default SubscriptioErrorModal;
