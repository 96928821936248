import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import repricerActions from '../../../../../../redux/repricer/actions';
import AlertTooltip from '../../components/AlertTooltip';
import TemplateTooltip from '../../components/TemplateTooltip';

const {
	workingTemplateUpdateJson,
	userGetGlobalList,
} = repricerActions;

class OfferFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sellerList: '',
		additionalSellers: [],
		minimum_offer_to_trigger_reprice: 1,
		channel_target: "both",
		amazon_fulfill: false,
		merchant_fulfill: false,
		exclude_offers_with_seller_rating_below: 90,
		exclude_offers_with_seller_feed_back_count_below: 100,
		exclude_offer_not_available_now: false,
		exclude_offer_if_sub_condition_acceptable: false,
		seller_to_exclude_id: null,
		sellers_to_exclude: [],
		is_prime_filter: ""
    }
  }

	UNSAFE_componentWillMount(){
		this.props.userGetGlobalList('seller');
		this.setState({
			minimum_offer_to_trigger_reprice: this.props.workingTemplate.template_json.offers_targeting_minimum_offer_to_trigger_reprice,
			amazon_fulfill: this.props.workingTemplate.template_json.user_inventory_channel_target_fba,
			merchant_fulfill: this.props.workingTemplate.template_json.user_inventory_channel_target_mf,
			exclude_offers_with_seller_rating_below: this.props.workingTemplate.template_json.offers_targeting_exclude_offers_with_seller_rating_below,
			exclude_offers_with_seller_feed_back_count_below: this.props.workingTemplate.template_json.offers_targeting_exclude_offers_with_seller_feed_back_count_below,
			exclude_offer_not_available_now: this.props.workingTemplate.template_json.pricing_rules_exclude_offer_not_available_now,
			exclude_offer_if_sub_condition_acceptable: this.props.workingTemplate.template_json.pricing_rules_exclude_offer_if_sub_condition_acceptable,
			seller_to_exclude_id: this.props.workingTemplate.template_json.offers_targeting_seller_to_exclude_id,
			sellers_to_exclude: this.props.workingTemplate.template_json.offers_targeting_sellers_to_exclude,
			is_prime_filter: this.props.workingTemplate.template_json.offers_targeting_prime_filter,
		});
		if(!this.props.workingTemplate.template_json.offers_targeting_minimum_offer_to_trigger_reprice){
			this.setState({ minimum_offer_to_trigger_reprice: 1 });
			this.props.workingTemplateUpdateJson(
				{data: {offers_targeting_minimum_offer_to_trigger_reprice: 1}})
		}

	}

  onInputSellerList = (value) => {
	  this.setState({ seller_to_exclude_id: value ? value.value : null});
		this.props.workingTemplateUpdateJson(
			{data: {offers_targeting_seller_to_exclude_id: value ? value.value : null }})
		this.props.workingTemplateUpdateJson(
			{data: {offers_targeting_sellers_to_exclude: value ? value.datas : null }})
  }

  onInputAdditionalSellers = (value) => {
    this.setState({
      additionalSellers: value
    });
  }

	changeIntegerValue = (e, root, key) => {
		var val = e;
		if(e.target && e.target.value){
			val = e.target.value;
		}
		val = val.toString();
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_int = parseInt(val.replace(/,/gi, ""), 10);
			if(!val_int){
				val = "";
				val_int = 0;
			} else {
				val = val_int.toString()
			}
			this.setState({ [key]: val_int });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeCheck = (e) => {
		this.setState({ channel_target: e.target.value });
		if(e.target.value === "merchant_fulfill" || e.target.value === "amazon_fulfill"){
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_channel_target: e}})
		}
	}

	changeChannelFBA = (e) => {
		this.setState({
			amazon_fulfill: !this.state.amazon_fulfill,
		});
		this.props.workingTemplateUpdateJson(
				{data: {user_inventory_channel_target_fba: !this.state.amazon_fulfill } })
	}

	changeChannelMF = (e) => {
		this.setState({
			merchant_fulfill: !this.state.merchant_fulfill,
		});
		this.props.workingTemplateUpdateJson(
				{data: {user_inventory_channel_target_mf: !this.state.merchant_fulfill } })
	}

	changeNotAvailable = (e) => {
		this.setState({
			exclude_offer_not_available_now: !this.state.exclude_offer_not_available_now,
		});
		this.props.workingTemplateUpdateJson(
				{data: {pricing_rules_exclude_offer_not_available_now: !this.state.exclude_offer_not_available_now } })
	}

	changeAcceptable = (e) => {
		this.setState({
			exclude_offer_if_sub_condition_acceptable: !this.state.exclude_offer_if_sub_condition_acceptable,
		});
		this.props.workingTemplateUpdateJson(
				{data: {pricing_rules_exclude_offer_if_sub_condition_acceptable: !this.state.exclude_offer_if_sub_condition_acceptable } })
	}

	changeOnlyPrime = () => {
		let val;
		switch (this.state.is_prime_filter) {
			case "only_target_prime":
				val="";
				break;
			case "only_target_non_prime":
				val="target_both";
				break;
			case "target_both":
				val="only_target_non_prime";
				break;
			default:
				val="only_target_prime";
		}

		this.setState({
			is_prime_filter: val
		});
		this.props.workingTemplateUpdateJson(
				{data: { offers_targeting_prime_filter: val } })
	}

	changeOnlyNonPrime = () => {
		let val;
		switch (this.state.is_prime_filter) {
			case "only_target_prime":
				val="target_both";
				break;
			case "only_target_non_prime":
				val="";
				break;
			case "target_both":
				val="only_target_prime";
				break;
			default:
				val="only_target_non_prime";
		}

		this.setState({
			is_prime_filter: val
		});
		this.props.workingTemplateUpdateJson(
				{data: { offers_targeting_prime_filter: val } })
	}

  render() {
		const sellerListToExclude = this.props.predefinedSellerList.map(item => {
			return {
				value: item.id,
				label: item.list_name,
				datas: item.datas,
			}
		});

		var sellerListToExcludeData = null;
		this.props.predefinedSellerList.forEach(item => {
			if(item.id === this.props.workingTemplate.template_json.offers_targeting_seller_to_exclude_id){
				sellerListToExcludeData = {
					value: item.id,
					label: item.list_name,
					datas: item.datas,
				}
			}
		});

    return (
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>
							Minimum Offers Number
							<TemplateTooltip
								id="t-min-offers-number"
								text="Minimum offers number"
							/>
						</Label>
            <p>Minimum number of offers needed on the listing, for us to reprice.</p>
            <Slider
              min={1}
              max={10}
              marks={{1:1, 2:2, 3:3, 4:4, 5:5, 6:6, 7:7, 8:8, 9:9, 10:10}}
			  onChange={(e) => this.changeIntegerValue(e, "offers_targeting", "minimum_offer_to_trigger_reprice")}
			  value={this.state.minimum_offer_to_trigger_reprice}
            />
            <span className="input-help">Use this setting to filter out listings with few offers, where a particular seller might be pricing at an outlier price.</span>
			<AlertTooltip
				target="alert_minimum_offer"
				message={this.props.templateWarning}
				range={[12]}
			/>
          </FormGroup>

          <FormGroup>
            <Label>
							Fulfillment Channels
							<TemplateTooltip
								id="t-fullfilment-channels"
								text="Fulfillment channels"
							/>
						</Label>
			<AlertTooltip
				target="alert_fulfillmant_chanell"
				message={this.props.templateWarning}
				range={[16]}
			/>
			<p>
				Fulfillment Channel(s) that we should consider when looking at competing offers.
			</p>
            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  checked={this.state.amazon_fulfill}
					  onChange={e => this.changeChannelFBA(e)}
					  value="amazon_fulfill"
				  /> <span>FBA</span>
              </Label>
              <Label check>
				  <Input
					  type="checkbox"
					  checked={this.state.merchant_fulfill}
					  onChange={e => this.changeChannelMF(e)}
					  value="merchant_fulfill"
				  /> <span>MF</span>
              </Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>
							Exclude Offers
							<TemplateTooltip
								id="t-exclude-offers"
								text="Offers excluded"
							/>
						</Label>
            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  value={this.state.exclude_offer_if_sub_condition_acceptable}
					  onChange={this.changeAcceptable}
					  checked={this.state.exclude_offer_if_sub_condition_acceptable}
				  /> <span>Exclude acceptable offers.</span>
              </Label>
            </FormGroup>

            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  value={this.state.exclude_offer_not_available_now}
					  onChange={this.changeNotAvailable}
					  checked={this.state.exclude_offer_not_available_now}
				  /> <span>Exclude backordered offers (there are no available qty for that offer).</span>
              </Label>
            </FormGroup>
          </FormGroup>

					<FormGroup>
						<Label>
							Prime
							<TemplateTooltip
								id="t-price"
								text="Target prime or non-prime offers"
							/>
						</Label>
						<FormGroup check className="custom-check checkbox">
							<Label check>
								<Input
									type="checkbox"
									value={this.state.is_prime_filter === "only_target_prime" || this.state.is_prime_filter === "target_both"}
									onChange={this.changeOnlyPrime}
									checked={this.state.is_prime_filter === "only_target_prime" || this.state.is_prime_filter === "target_both"}
								/>
								<span>Target prime offer</span>
							</Label>
						</FormGroup>
						<FormGroup check className="custom-check checkbox">
							<Label check>
								<Input
									type="checkbox"
									value={this.state.is_prime_filter === "only_target_non_prime" || this.state.is_prime_filter === "target_both"}
									onChange={this.changeOnlyNonPrime}
									checked={this.state.is_prime_filter === "only_target_non_prime" || this.state.is_prime_filter === "target_both"}
								/>
								<span>Target non prime offer</span>
							</Label>
							<AlertTooltip
								target="alert_seller_rating"
								message={this.props.templateWarning}
								range={[46]}
							/>
						</FormGroup>
					</FormGroup>
        </Col>

        <Col md={{ size: 5, offset: 2}}>
          <FormGroup>
            <Label>
							Exclude Sellers
							<TemplateTooltip
								id="t-exclude-sellers"
								text="Sellers excluded"
							/>
						</Label>
            <FormGroup>
              <p>Exclude Sellers with <u>positive seller rating below</u></p>
              <InputGroup>
				  <Input
					  type="text"
					  min={1}
					  max={100}
					  value={this.state.exclude_offers_with_seller_rating_below.toLocaleString()}
					  onChange={e => this.changeIntegerValue(e, "offers_targeting", "exclude_offers_with_seller_rating_below")}
				  />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
				<AlertTooltip
					target="alert_seller_rating"
					message={this.props.templateWarning}
					range={[14, 15]}
				/>
            </FormGroup>

            <FormGroup>
              <p>Exclude Sellers with <u>fewer than this number of reviews</u></p>
              <InputGroup>
				  <Input
					  type="text"
					  min={1}
					  value={this.state.exclude_offers_with_seller_feed_back_count_below.toLocaleString()}
					  onChange={e => this.changeIntegerValue(e, "offers_targeting", "exclude_offers_with_seller_feed_back_count_below")}
				  />
                <InputGroupAddon addonType="append">reviews</InputGroupAddon>
              </InputGroup>
				<AlertTooltip
					target="alert_fedback_count"
					message={this.props.templateWarning}
					range={[13]}
				/>
            </FormGroup>

            <FormGroup>
              <p>Seller List to Exclude</p>
              <Select
                options={sellerListToExclude}
                value={sellerListToExcludeData}
                onChange={this.onInputSellerList}
              />
            </FormGroup>

            <FormGroup>
              <Label>
								Additonal Sellers to Exclude
								<TemplateTooltip
									id="t-exclude-sellers-additional"
									text="Additional sellers to exclude"
								/>
							</Label>
              <TagsInput
                value={this.state.additionalSellers}
                onChange={this.onInputAdditionalSellers}
              />
              <span className="input-help">Use an asterisk (*) to match any character. For example, ABC*DEF will match any MSKU starting in ABC and ending in DEF. You can use an asterisk in the beginning, middle, or end of your wildcard match.)</span>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => ({
	predefinedSellerList: state.Repricer.get("predefinedSellerList"),
	templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
	workingTemplateUpdateJson,
	userGetGlobalList,
  }
)(OfferFilters);
