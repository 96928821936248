import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle
} from "reactstrap";
import { secureProtocolImgURL, momentDateToLocalFormatConversion } from "../../../../../helpers/utility";

const BatchProductCard = (props) => {
  const { products, additionalContent, sku } = props;

  const getDataFromProducts = (sku, field) => {
    let item = null;
    if (products) {
      products.forEach(element => {
        if (element.sku === sku) {
          item = element[field];
        }
      })
      if ( field === "FulfillmentNetworkSKU") {
        products.forEach(element => {
          if (element.sku === sku) {
            // private batch case
            if (element.fnsku) {
              item = element.fnsku
            } else if (element.fulfillmentCenters && element.fulfillmentCenters[0]) {
              item = element.fulfillmentCenters[0][field];
            } else {
              item = "N/A"
            }
          }
        })
      }
    };
    if (item) {
      return item;
    }
    return "N/A"
  }

  return (
      <Card style={{ boxShadow: 'none', border: '1px solid #ececec', borderRadius: 8, marginBottom: '1.5rem' }}>
        <CardBody>
          <CardTitle>{getDataFromProducts(sku, "name")}</CardTitle>
          <Row>
            <Col xs="1" style={{display: "flex", alignItems: "center"}}>
              <img
                src={
                  secureProtocolImgURL(getDataFromProducts(sku, "imageUrl"))
                  }
                alt="item_pic"
                style={{ width: "48px" }}
              />
            </Col>
            <Col xs="11">
              <Row>
                <Col xs="4">
                  ASIN: <strong>{getDataFromProducts(sku, "asin")} </strong>
                </Col>
                <Col xs="4">
                  UPC: <strong>{getDataFromProducts(sku, "asin")} </strong>
                </Col>
                <Col xs="4">
                  Category: <strong>{getDataFromProducts(sku, "category")} </strong>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  Sales Rank: <strong>{getDataFromProducts(sku, "salesrank")} </strong>
                </Col>
                <Col xs="4">
                  FNSKU: <strong>{getDataFromProducts(sku, "FulfillmentNetworkSKU")} </strong>
                </Col>
                <Col xs="4">
                  Condition: <strong>{getDataFromProducts(sku, "condition")} </strong>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  Expire date: <strong>{momentDateToLocalFormatConversion(getDataFromProducts(sku, "expDate"))} </strong>
                </Col>
                <Col xs="4">
                </Col>
                <Col xs="4">
                </Col>
              </Row>
              {additionalContent ? additionalContent : ""}
            </Col>
          </Row>
        </CardBody>
      </Card>
  )
}

BatchProductCard.propTypes = {
  additionalContent: PropTypes.object,
  products: PropTypes.array.isRequired,
  sku: PropTypes.string.isRequired
}

export default BatchProductCard;
