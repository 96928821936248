import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  orders: {},
  orders_working: false,
  order: {},
});

export default function orderReducer(state = initState, action) {
	switch (action.type) {

    case actions.GET_ORDER:
      return state
        .set("order", {})
        .set("orders_working", true)
    case actions.GET_ORDER_SUCCESS:
      return state
        .set("order", {...action.data.data})
        .set("orders_working", false)
    case actions.GET_ORDER_FAILED:
      return state
        .set("order", {})
        .set("orders_working", false)

    case actions.GET_ORDERS:
      return state
        .set("orders", action?.data?.reset ? {} : {...state.get("orders")})
        .set("orders_working", true)
    case actions.GET_ORDERS_SUCCESS:
      var get_orders_orders = {...state.get("orders")};
      if(get_orders_orders?.Orders && action.data?.data?.Orders){
        get_orders_orders.Orders = [
          ...get_orders_orders.Orders,
          ...action.data.data.Orders
        ];
        get_orders_orders.NextToken = action.data.data?.NextToken;
      } else {
        get_orders_orders = {...action.data.data};
      }
      return state
        .set("orders", {...get_orders_orders})
        .set("orders_working", false)
    case actions.GET_ORDERS_FAILED:
      return state
        .set("orders_working", false)

	default:
		return state;
	}
}
