import React, { Component } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import chart_config from "../../../main_dashboard_v3/chart_config";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import getGradient from "../../../main_dashboard_v3/chart_gradients";

class RepricingChart extends Component {
	state = {
		no_data: false
	}

	render() {
		return (
			<React.Fragment>
				<div className="d-flex">
					<h3 className="h5">Last 30 days</h3>
				</div>

				<div className={`reprice-chart ${this.state.no_data ? "demo" : ""}`}>
					<ResponsiveContainer
						width={"100%"}
						height={300}
					>
						<BarChart
							data={
								this.props.dashboard_repricing.sort(
									(a, b) => a.day < b.day ? -1 : 1)}
							margin={{
								top: 30, left: -30, right: 20
							}}
							barCategoryGap='25%'
						>
							<CartesianGrid
								vertical={false}
								stroke={chart_config.cartesianStrokeColor}
							/>
							<XAxis
								dataKey="day"
								tickLine={false}
								axisLine={false}
								stroke={chart_config.axisFontColor}
								style={{
									fontSize: chart_config.axisFontSize
								}}
							/>
							<YAxis
								axisLine={false}
								tickLine={false}
								stroke={chart_config.axisFontColor}
								style={{
									fontSize: chart_config.axisFontSize
								}}
							/>
							<Tooltip cursor={{ fill: 'transparent' }} />
							<Legend />
							<defs>
								{getGradient("gradientBlueEvents", "blue")}
								{getGradient("gradientGreenSales", "green")}
							</defs>
							<Bar
								dataKey="events"
								fill="url(#gradientBlueEvents)"
								radius={[3,3,0,0]}
								name="Events"
							/>
							<Bar
								dataKey="sales"
								fill="url(#gradientGreenSales)"
								radius={[3,3,0,0]}
								name="Sales"
							/>
						</BarChart>
					</ResponsiveContainer>
					<div className="chart-overlay">
						<p>Currently we don't have enough data to show</p>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default RepricingChart;
