import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle, FormText } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import Select from 'react-select';

const { updateUser } = adminActions;

const selectOptions = [
	{
		value: "has_active_subscription",
		label: "Subscription activation",
		converison: "bool",
		action: "activate",
		desc: "activate or deactivate"
	},
	{
		value: "payment_failed",
		label: "Payment failed",
		converison: "bool",
		action: "true",
		desc: "true or false"
	},
];

/**
 * Update user password
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUserUpdate extends Component {
	state = {
		userUN: '',
		selectedOption: null,
		optionValue: '',
		updating: false,
	}

	handleChange = (key, value) => {
		this.setState(
			{
				[key]: value,
				updating: false,
			}
		);
	}

	processUpdate = () => {
		if(!this.state.selectedOption
				|| !this.state.optionValue
				|| !this.state.userUN) { return }
		this.setState({ updating: true });
		const key = this.state.selectedOption.value;
		var value = this.state.optionValue;
		const username = this.state.userUN;
		if(this.state.selectedOption.converison === 'bool'){
			if(value === this.state.selectedOption.action){
				value = true;
			} else {
				value = false;
			}
		}
		const data = {
			username,
			key,
			value
		}
		this.props.updateUser(data);
	}

  render() {
    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>Update user data</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={e => this.handleChange('userUN', e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="un">Property:</Label>
							<Select
								options={selectOptions}
								onChange={e => this.handleChange('selectedOption', e)}
								value={this.state.selectedOption}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="un">Value:</Label>
							<Input
								type="text"
								name="value"
								id="value"
								value={this.state.optionValue}
								onChange={e => this.handleChange('optionValue', e.target.value)}
							/>
							<FormText>
								{this.state.selectedOption ? this.state.selectedOption.desc :  null}
							</FormText>
						</FormGroup>
						<div>
							<Button
								color="success"
								onClick={this.processUpdate}
								disabled={
									!this.state.selectedOption
										|| !this.state.optionValue
										|| !this.state.userUN
								}
							>
								UPDATE
							</Button>
							<br />
							<Label for="un" className="text-danger">
								{this.state.updating ? this.props.user_update_message : null}
							</Label>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	</div>
    );
  }
}

export default connect(
  state => {
		return {
			user_updating: state.Admin.get("user_updating"),
			user_update_message: state.Admin.get("user_update_message"),
    };
  },
  {
		updateUser,
  }
)(AdminUserUpdate);
