import React from "react";
import SearchResult from "./shared/SearchResult";
import GhostResult from "./listing_creator_display/GhostResult";
import PricingPanel from "./listing_creator_display/PricingPanel";
import SideBar from "./sidebar/SideBar";
import PropTypes from "prop-types";

/*
This display is shown when they have not started scanning an item yet, and after the
previous item has been added successfully to the batch and you're ready to start
listing a new item.
*/

const ListingCreatorDisplay = props => {
  let {
    currentSelectedSearchResult,
    currentWorkingListingData,
    displayCustomSKUTemplateModal,
    cancelListingCreationflow,
    batchListingDefaults,
    updateListingDefaultsData,
    updateCurrentWorkingListingData,
    setSidebarTabId,
    sidebarTabId,
    currentModal,
    closeModal,
    createNewSupplier,
    suppliers,
		scouts,
    internationalConfig,
    conditionNotes,
    addToBatchStatus,
		currentListingWorkflowOptions,
		batchMetadata,
		userData,
		addItemToBatchExtended,
  } = props;
  return (
    <React.Fragment>
      <div className="batch-core">
        <div className="medium-bottom-margin listing-details">
          <SearchResult
            onResultSelected={result => console.log(result)}
            result={currentSelectedSearchResult}
            listing={currentWorkingListingData}
            batchMetadata={batchMetadata}
            userData={userData}
          />
          <PricingPanel
            currentWorkingListingData={currentWorkingListingData}
            updateCurrentWorkingListingData={updateCurrentWorkingListingData}
            internationalConfig={internationalConfig}
            conditionNotes={conditionNotes}
            batchListingDefaults={batchListingDefaults}
            currentListingWorkflowOptions={currentListingWorkflowOptions}
            addToBatchStatus={addToBatchStatus}
            cancel={cancelListingCreationflow}
            batchMetadata={batchMetadata}
            addItemToBatchExtended={addItemToBatchExtended}
            result={currentSelectedSearchResult}
          />
          <GhostResult
            listing={currentWorkingListingData}
            cancel={cancelListingCreationflow}
            updateCurrentWorkingListingData={updateCurrentWorkingListingData}
            suppliers={suppliers}
            internationalConfig={internationalConfig}
            addToBatchStatus={addToBatchStatus}
            currentListingWorkflowOptions={currentListingWorkflowOptions}
            userData={userData}
            batchMetadata={batchMetadata}
            addItemToBatchExtended={addItemToBatchExtended}
            scouts={scouts}
          />
        </div>
      </div>

      <div className="batch-sidebar">
        <SideBar
          batchListingDefaults={batchListingDefaults}
          updateListingDefaultsData={updateListingDefaultsData}
          displayCustomSKUTemplateModal={displayCustomSKUTemplateModal}
          currentModal={currentModal}
          setSidebarTabId={setSidebarTabId}
          sidebarTabId={sidebarTabId}
          createNewSupplier={createNewSupplier}
          suppliers={suppliers}
          closeModal={closeModal}
					scouts={scouts}
        />
      </div>
    </React.Fragment>
  );
};

ListingCreatorDisplay.propTypes = {
  currentSelectedSearchResult: PropTypes.object.isRequired,
  currentWorkingListingData: PropTypes.object.isRequired,
  currentListingWorkflowOptions: PropTypes.object.isRequired,
  cancelListingCreationflow: PropTypes.func.isRequired,
  batchListingDefaults: PropTypes.object.isRequired,
  updateListingDefaultsData: PropTypes.func.isRequired,
  updateCurrentWorkingListingData: PropTypes.func.isRequired,
  setSidebarTabId: PropTypes.func.isRequired,
  sidebarTabId: PropTypes.string.isRequired,
  printerDefaults: PropTypes.object,
  createNewSupplier: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  scouts: PropTypes.array,
  internationalConfig: PropTypes.object.isRequired,
  addToBatchStatus: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	batchMetadata: PropTypes.object.isRequired,
};

export default ListingCreatorDisplay;
