import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

class PeriodTypeSelect extends Component {
  selectPeriodType = (e) => {
    const { chartData, chartType } = this.props
    const selectedPeriodType = e.target.value
    this.props.setPeriodType(selectedPeriodType, this.props.chartType);
    this.props.fetchChartData(chartType, chartData.date, selectedPeriodType)
  }

  render() {
    const { chartData } = this.props
    return (
      <Input type="select" value={chartData.period_type} onChange={e => this.selectPeriodType(e)} selected>
        {/*<option value="">Pick a time interval...</option>*/}
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
      </Input>
    )
  }
}

PeriodTypeSelect.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartData: PropTypes.object.isRequired,
  setPeriodType: PropTypes.func.isRequired,
  fetchChartData: PropTypes.func.isRequired
}

export default PeriodTypeSelect;
