import React, { Component } from "react";
import { Button, UncontrolledTooltip, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";
import { digitСonversion } from "../../../helpers/utility";
import Select from 'react-select'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Papa from 'papaparse'
import fileDownload from 'react-file-download'
import IncompleteForBatchModal from "./IncompleteForBatchModal";
import DeleteDialog from "../../../shared/components/dialogs/DeleteDialog";

class ProductList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: [],
			selectedAll: false,
			currentPage: 1,
			pageLength: 10,
			pageCount: 0,
			batch_selected: null,
			non_selectable_rows: [],
			hidden_columns: [],
			table_key: "table-" + Math.random() * 100,
			incompleteModalOpen: false,
      researchCheckInterval: null,
			confirmClearAll: false
		}
  }

  componentWillUnmount(){
    clearInterval(this.state.researchCheckInterval);
    this.setState( {researchCheckInterval: null })
  }

  checkResearchStatus = () => {
		const { research_progress } = this.props;
    if(research_progress && research_progress.processed !==research_progress.total){
      this.props.researchGetStatus();
    }
  }

	downloadTemplate = () => {
		const availavle_headers = ["asin", "price", "qty", "condition"];
		const data = (
			{
				fields: availavle_headers,
				data: []
			}
		);
		const csv = Papa.unparse(JSON.stringify(data) , {
			delimiter: ",",
			header: true,
			skipEmptyLines: false
		});
		let fn = 'ALResearchSpreadsheetTemplate.csv'
		fileDownload(csv, fn);
	}

	changeBatch = (e) => {
		this.setState({batch_selected: e});
	}

	componentDidMount() {
		this.setState({
			pageCount: Math.ceil(this.state.dataCount/this.state.pageLength)
		})
		this.props.loadBatchData();
    this.setState({
      researchCheckInterval: setInterval(this.checkResearchStatus, 5000)
    })
	}

	refreshData = () => {
    this.props.loadResearchData();
	}

	toggleSelect = (row, isSelect) => {
		if(row.title && row.processed){
			if(isSelect) {
				this.setState(() => ({
					selected: [...this.state.selected, row.id]
				}));
			} else {
				this.setState(() => ({
					selected: this.state.selected.filter(x => x !== row.id)
				}));
			}
		}
	}

	toggleSelectAll = () => {
		let selected = [];
		let complete = this.state.selected.length === this.props.products.length;
		complete = !this.state.selectedAll;
		this.setState({ selectedAll: complete });

		if(complete) {
			this.props.products.forEach((item, idx) => {
				if(item.title && item.processed){
					selected.push(item.id)
				}
			})
		}

		this.setState({	selected: selected });
	}

	insertToBatch = () => {
		const data = {
			batch_id: this.state.batch_selected.value,
			ids: this.props.products.filter(item => this.state.selected.includes(item.id)),
		}
		this.props.researchMoveToBatchDataSet(data.ids)
		this.toggleIncompleteModal();
	}

	removeSelected = () => {
		const data = {
			ids: this.state.selected,
		}
		this.props.deleteSelectedResearch(data);
		this.setState({ selected: [] });
	}

	removeAll = () => {
		const data = {
			ids: this.props.products.map(item => { return item.id }),
		}
		this.props.deleteSelectedResearch(data);
		this.setState({ selected: [] });
	}

	formatMoney = (value) => {
		return digitСonversion(value, "currency", "USD");
	}

	formatRank = (value) => {
		return digitСonversion(value, "rank");
	}

	toggleColumn = (field, tableColumns) => {
		let hidden_columns = this.state.hidden_columns;

		if(hidden_columns.includes(field)) {
			hidden_columns = hidden_columns.filter(item => item !== field);
		} else {
      if(tableColumns.filter(item => item.hidden === false).length <= 1){
        return;
      }

			hidden_columns.push(field);
		}

		this.setState({
			hidden_columns: hidden_columns,
			table_key: "table-" + Math.random() * 100
		})
	}

	openSbyb = (row) => {
		this.props.openInNewTabExtended(row.sbyb_url, 'sbyb')
	}

	toggleIncompleteModal = () => {
		this.setState({
			incompleteModalOpen: !this.state.incompleteModalOpen
		})
	}

	submitProduct = () => {
		const data = {
			batch_id: this.state.batch_selected.value,
			ids: this.props.moveToBatchData.map(item => {return item.id}),
			update_data: this.props.moveToBatchData,
		}
		this.toggleIncompleteModal();
		this.props.moveToBatch(data);
		this.setState({
			selected: [],
		});
		this.props.researchMoveToBatchDataSet([]);
	}

	exportData = () => {
		const data = {
			type: "csv",
			ids: this.state.selected,
		}
		this.props.researchExport(data);
	}

	calculatePercentage = (total, progress) => {
		if(total && progress){
			let perc = parseInt(progress/total*100, 10)
			if(perc > 100){
				perc = 100;
			}
			return `${perc}%`
		} else {
			return "0%";
		}
	}

	render() {
		const {
			products,
		} = this.props;

		const {
			hidden_columns,
			table_key,
			incompleteModalOpen
		} = this.state;

		const isSelectedAll = this.state.selected.length === products.length;

		// table columns
		const tableColumns = [
			{
				dataField: "title",
				text: "Title",
				sort: true,
				classes: "col-title",
				headerStyle: { minWidth: '12rem'},
				hidden: hidden_columns.includes("title"),
				formatter: (cell, row, rowIndex) => {
					return (
						<div className="d-flex gap-1">
              {
                row.img_url
                  ? <div className="picture"><img src={row.img_url} alt="" /></div>
                  : row.processed
                    ? <div className="picture"></div>
	  						    : <div className="placeholder-glow placeholder-wave" style={{ width: '18%' }}>
		  						     <div className="placeholder" style={{ width: '100%', minHeight: 42 }}></div>
 			  				      </div>
							}
              {
                cell
                  ? <h2 className="product-title" title={cell}>{cell !== null ? cell.substring(0, 50) : ""}...</h2>
                  : row.processed
                  ? <h2 className="product-title mb-0" title="N/A">N/A</h2>
                    : <div className="placeholder-glow placeholder-wave d-flex flex-column" style={{ gap: 6, width: '77%' }}>
                        <div className="placeholder" style={{ width: '100%', minHeight: 6 }}></div>
                        <div className="placeholder" style={{ width: '75%', minHeight: 6 }}></div>
                        <div className="placeholder" style={{ width: '90%', minHeight: 6 }}></div>
                        <div className="placeholder" style={{ width: '50%', minHeight: 6 }}></div>
                      </div>
							}
						</div>
					)
				},
			},
			{
				dataField: "processed",
				text: "Proc.",
				sort: true,
				hidden: hidden_columns.includes("processed"),
				headerStyle: { minWidth: '6.5rem'},
				formatter: (cell, row) => (
					cell ? <span className="icon icon-16 icon-check-square bg-success"></span> : <span className="icon icon-16 icon-timer bg-warning"></span>
				),
				headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
					<div>
						<span id="head-procd">{col.text} <span id="tooltip-procd" className="icon icon-12 icon-help-circle"></span></span>
						{ sortElement }
						<UncontrolledTooltip placement="top" target="tooltip-procd">Processed</UncontrolledTooltip>
					</div>
				)
			},
			{
				dataField: "pricing_type_to_display",
				text: "Pricing",
				sort: true,
				hidden: hidden_columns.includes("pricing_type_to_display"),
				headerStyle: { minWidth: '7rem'},
				formatter: (cell, row) => cell ? cell : 'N/A'
			},
			{
				dataField: "asin",
				text: "ASIN",
				sort: true,
				hidden: hidden_columns.includes("asin"),
			},
			{
				dataField: "condition",
				text: "Condition",
				sort: true,
				hidden: hidden_columns.includes("condition"),
				headerStyle: { minWidth: '8rem'},
				formatter: (cell, row) => cell ? cell : 'N/A'
			},
			{
				dataField: "price",
				text: "Price",
				sort: true,
				hidden: hidden_columns.includes("price"),
				headerStyle: { minWidth: '5rem'},
				formatter: (cell, row) => this.formatMoney(cell)
			},
			{
				dataField: "category",
				text: "Category",
				sort: true,
				hidden: hidden_columns.includes("category"),
				classes: "col-category",
				headerStyle: { minWidth: '8rem'},
				formatter: (cell, row) => cell ? cell : "N/A"
			},
			{
				dataField: "rank",
				text: "Rank",
				sort: true,
				hidden: hidden_columns.includes("rank"),
				headerStyle: { minWidth: '6rem'},
				formatter: (cell, row) => cell ? this.formatRank(cell) : "N/A"
			},
			{ dataField: "sales", text: "Sales", sort: true,
				hidden: hidden_columns.includes("sales"),
				headerStyle: { minWidth: '7rem'},
				headerFormatter: (col, colIndex, { sortElement, filterElement }) => (
					<div>
						<span id="head-sales">{col.text} <span id="tooltip-sales" className="icon icon-12 icon-help-circle"></span></span>
						{ sortElement }
						<UncontrolledTooltip placement="top" target="tooltip-sales">Last 3 months</UncontrolledTooltip>
					</div>
				),
				formatter: (cell, row) => cell ? cell : "N/A"
			},
			{
				dataField: "offers",
				text: "Offers",
				sort: true,
				hidden: hidden_columns.includes("offers"),
				headerStyle: { minWidth: '6rem'},
				formatter: (cell, row) => cell ? cell : "N/A"
			},
			{
				dataField: "used_bb",
				text: "U-BB",
				sort: true,
				hidden: hidden_columns.includes("used_bb"),
				headerStyle: { minWidth: '6rem'},
				formatter: (cell, row) => cell ? this.formatMoney(cell) : "N/A"
			},
			{
				dataField: "new_bb",
				text: "N-BB",
				sort: true,
				hidden: hidden_columns.includes("new_bb"),
				headerStyle: { minWidth: '6rem'},
				formatter: (cell, row) => cell ? this.formatMoney(cell) : "N/A"
			},
			{
				dataField: "fba_fees",
				text: "FBA Fee",
				sort: true,
				hidden: hidden_columns.includes("fba_fees"),
				headerStyle: { minWidth: '7rem'},
				formatter: (cell, row) => cell ? this.formatMoney(cell) : "N/A"
			},
			{
				dataField: "mf_fees",
				text: "MF Fee",
				sort: true,
				hidden: hidden_columns.includes("mf_fees"),
				headerStyle: { minWidth: '7rem'},
				formatter: (cell, row) => cell ? this.formatMoney(cell) : "N/A"
			},
			{
				dataField: "buy_cost",
				text: "Buy Cost",
				sort: true,
				hidden: hidden_columns.includes("buy_cost"),
				headerStyle: { minWidth: '7rem'},
				formatter: (cell, row) => cell ? this.formatMoney(cell) : "N/A"
			},
			{
				dataField: "sbyb",
				text: "SBYB",
				sort: true,
				hidden: hidden_columns.includes("sbyb"),
				formatter: (cell, row) => cell ?
					<Button
						color="success"
						size="sm"
						onClick={() => this.openSbyb(row)}
					>SELL {this.formatMoney(cell)}</Button>
					: "NO OFFER"
			},
			{
				dataField: "est_profit",
				text: "Est. Profit",
				sort: true,
				hidden: hidden_columns.includes("est_profit"),
				headerStyle: { minWidth: '7.5rem'},
				formatter: (cell, row) => cell ? <strong className={cell > 0 ? "text-success" : "text-danger"}>{this.formatMoney(cell)}</strong> : "N/A"
			}
		];

		// generate show/hide options
		let showHideOptions = [];
		tableColumns.map((item, idx) => (
			showHideOptions.push(
				<DropdownItem key={`toggle-option-${idx}`} toggle={false}>
					<Label>
						<Input
							type="checkbox"
							checked={!hidden_columns.includes(item.dataField)}
							onChange={() => this.toggleColumn(item.dataField, tableColumns)}
						/> {item.text}
					</Label>
				</DropdownItem>
			)
		))

		// row selection
		const selectRow = {
			mode: 'checkbox',
			clickToSelect: true,
			classes: 'selected',
			selected: this.state.selected,
			onSelect: this.toggleSelect,
			onSelectAll: this.toggleSelectAll,
			nonSelectable: this.props.products.filter(
				item => !item.title && !item.processed).map(item => { return item.id }),
			selectionHeaderRenderer:({ checked, disabled }) => {
				return (
					<label
						className={`container-check
						${this.state.selected.length > 0 && this.state.selected.length < products.length ? 'indeterminate' : ''}`}
						for="check-head"
					>
						<Input
							type="checkbox"
							checked={checked}
							disabled={disabled}
							id="check-head"
							onClick={e => e.stopPropagation()}
							readOnly
						/>
						<span className="checkmark"></span>
					</label>
				)
			},
			selectionRenderer: ({ mode, checked, disabled, rowIndex }) => {
				return (
					<label class="container-check" for={`check-row-${rowIndex}`} >
						<Input
							type={mode}
							checked={checked}
							disabled={disabled}
							id={`check-row-${rowIndex}`}
							onClick={e => e.stopPropagation()}
							readOnly
						/>
						<span class="checkmark"></span>
					</label>
				)}
		};

		return (
			<React.Fragment>
				<div className="research-actions mb-4">
					<div>
						<div className="mr-3">
							<Button
								color="link"
								onClick={this.refreshData}
								disabled={this.props.is_loading_research_data}
							><span className="icon icon-reload bg-primary"></span> Refresh data</Button>
						</div>
						<div className="mr-3">
							<Button
								color="link"
								className="text-danger"
								onClick={this.removeSelected}
								disabled={this.props.is_deleting_research || !this.state.selected || this.state.selected.length === 0}
							><span className="icon icon-trash bg-danger"></span> Remove selected</Button>
						</div>
						<div>
							<Button
								color="link"
								className="text-danger"
								onClick={() => { this.setState({ confirmClearAll: true }) }}
								disabled={this.props.is_deleting_research || !this.props.products || this.props.products.length === 0}
							><span className="icon icon-trash bg-danger"></span> Remove all</Button>
						</div>
						<div className="ml-5 research-complete-status">
							<span className="text-muted complete">
								<span>{this.props.research_progress.processed ? this.props.research_progress.processed : 0}</span> of <span>{this.props.research_progress.total ? this.props.research_progress.total : 0}</span> products completed
							</span>
							<span className="ml-2 timer">
								<span className="value">{this.calculatePercentage(this.props.research_progress.total, this.props.research_progress.processed)}</span>
							</span>
						</div>
					</div>
					<div>
						<div className="mr-2" style={{ minWidth: "12rem" }}>
							<Select
								options={this.props.research_batch_data}
								value={this.state.batch_selected}
								onChange={(e) => this.changeBatch(e)}
								placeholder="Select batch..."
							/>
						</div>
						<Button
							color="success"
							onClick={e => this.insertToBatch()}
							disabled={this.state.selected.length === 0 || this.state.batch_selected === null || this.props.is_moving_to_batch}
						>Insert to selected batch</Button>
					</div>
				</div>
				<hr />
				<div className="research-product-list">
					<div className="top-actions">
						<div>
						<span>There are <strong>{this.props.products.length}</strong> products listed for research.</span><br/>
						<span className="text-danger">Please wait a few minutes, then click "Refresh data"</span>
						</div>
						<div className="d-flex align-items-center">
							<Button
								onClick={e => this.exportData()}
								className="mr-2"
								disabled={!this.state.selected || this.state.selected.length === 0}
							>
								Export
							</Button>
							<UncontrolledDropdown className="dropdown-checkbox mr-2">
								<DropdownToggle caret>Show/Hide Column</DropdownToggle>
								<DropdownMenu right>
									{showHideOptions}
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</div>

					<BootstrapTable
						key={table_key}
						data={products}
						keyField="id"
						columns={tableColumns}
						bordered={false}
						wrapperClasses="table-responsive"
						classes="table-product-research"
						selectRow={selectRow}
						pagination={paginationFactory({
							paginationSize: 10,
							sizePerPage: 10,
							sizePerPageList: [10, 20, 30, 40, 50]
						})}
						noDataIndication="Once you add products to be researched, they will be listed here."
					/>
				</div>

				<IncompleteForBatchModal
					isOpen={incompleteModalOpen}
					toggle={this.toggleIncompleteModal}
					submit={this.submitProduct}
				/>

				<DeleteDialog
					isOpen={this.state.confirmClearAll}
					dialogTitle="Are you sure want to remove all products on research?"
					confirmText="Yes, remove all"
					close={() => this.setState({ confirmClearAll: false })}
					deleteItem={() => {
						this.removeAll();
						this.setState({ confirmClearAll: false })
					}}
				/>
			</React.Fragment>
		)
	}
}

export default ProductList;
