import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

const exampleShipmentData = [
  {
    shipment_id: "FBA15ABCD123",
    inbound_id: "FBA1234567",
    spdTrackingItems: [
      {
        boxId: "Box1",
        trackingId: ""
      },
      {
        boxId: "Box2", 
        trackingId: ""
      },
      {
        boxId: "Box3",
        trackingId: ""
      }
    ]
  },
  {
    shipment_id: "FBA15WXYZ789",
    inbound_id: "FBA1234567", 
    spdTrackingItems: [
      {
        boxId: "Box1",
        trackingId: ""
      },
      {
        boxId: "Box2",
        trackingId: ""
      }
    ]
  }
];

class ShipmentTrackingModal extends Component {
  state = {
    selectedShipment: null
  }

  render() {
    const { isOpen, toggle, onSave } = this.props;
    const { selectedShipment } = this.state;

    const activeShipment = exampleShipmentData.find(s => s.shipment_id === selectedShipment?.value);

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Enter Tracking Information <span className="small">(Inbound Plan ID: FBA1234567)</span>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="mb-4">
            <Label>Select Shipment</Label>
            <Select
              options={[
                {
                  value: "FBA15ABCD123",
                  label: "FBA15ABCD123"
                },
                {
                  value: "FBA15WXYZ789",
                  label: "FBA15WXYZ789"
                }
              ]}
              value={this.state.selectedShipment}
              onChange={selected => this.setState({ selectedShipment: selected })}
              placeholder="Select a shipment..."
              clearable={false}
            />
          </FormGroup>

          {this.state.selectedShipment && (
            <div className="mb-4">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>Box ID</th>
                    <th>Tracking ID</th>
                  </tr>
                </thead>
                <tbody>
                  {activeShipment.spdTrackingItems.map(item => (
                    <tr key={`${selectedShipment.value}-${item.boxId}`}>
                      <td style={{verticalAlign: 'middle'}}>{item.boxId}</td>
                      <td style={{verticalAlign: 'middle'}}>
                        <Input
                          type="text"
                          placeholder="Enter tracking ID"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ShipmentTrackingModal;
