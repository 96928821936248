import React, { Component } from 'react';
import { Row, Col, Progress } from 'reactstrap';
import {
  FaShield as IconShield,
  FaQuestionCircle as IconQuestion,
  FaExclamation as IconExlamation
} from 'react-icons/lib/fa';


class RejectionStat extends Component {
  render() {
    var display_data = {};
    var total_data = 0;
    if(this.props.data?.reprice_report?.reject_report){
      total_data = this.props.data?.reprice_report?.reject_report.length;
      this.props.data?.reprice_report?.reject_report.forEach(item => {
        if(display_data[item.reject_string]){
          display_data[item.reject_string] = {
            count: display_data[item.reject_string].count + 1,
          }

        } else {
          display_data[item.reject_string] = {
            count: 1,
          }
        }
      })
      Object.keys(display_data).forEach(key => {
        display_data[key].percentage = (display_data[key].count / total_data) * 100;
      })
    }
    return (
      <Row>
        <Col md={6}>
          <div className="report-rejection-stat">
            {Object.keys(display_data).map(key =>{
              return (
                <RejectionStatItem
                  key={key}
                  type="ok"
                  reason={key}
                  bar_value={parseInt(display_data[key].percentage, 10)}
                  skus_count={display_data[key].count}
                />
              )
            })}
          </div>
        </Col>
      </Row>
    )
  }
}

const RejectionStatItem = props => (
  <div className={`rejection-stat-item ${props.type}`}>
    {props.type === "ok" ? <IconShield size="20" /> : props.type === "warning" ? <IconQuestion size="20" /> : <IconExlamation size="20" />}
    <div className="rejection-bar">
      <Progress value={props.bar_value} />
      <span className="reason">{props.reason}</span>
    </div>
    <div className="value">
      <span>{props.skus_count} SKUS</span>
      <span>{props.bar_value}%</span>
    </div>
  </div>
)

export default RejectionStat;
