import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import './style.css';
import repricerActions from "../../../../redux/repricer/actions";
import BConfirmationModal from "../../../../shared/components/BConfirmationModal";
import { shake } from "react-animations";
import { keyframes } from "styled-components";
import LoadingIndicator from '../../../../shared/components/LoadingIndicator';

const shakeAnimation = keyframes`${shake}`;

const { userEnableRepricer } = repricerActions;

const RepricingItem = props => (
  <div className="pricing-item">
    <h3 className="price">{props.price}/mo</h3>
    <div className="pricing-item-inner">
      <p className="description">{props.active_inventory} <span className="secondary">ACTIVE INVENTORY</span></p>
		</div>
		{props.userData && !props.userData.repricerEnabled && props.userData.hasActiveSubscription ?
			<Button
				color="success"
				block
				disabled={props.user_enabling_repricer || props.isUserSettingsUpdating}
				onClick={props.closeModal}
			>START FREE TRIAL</Button>
		: null}
  </div>
);

class RepricerLanding extends Component {
	state = {
		showPopupEnableRepricerSubscription: false,
		animate: true
	}

	componentDidMount = () => {
		setInterval(() => {
			this.setState({
				animate: !this.state.animate
			})
		}, 3000);
	}

	togglePopupEnableRepricerSubscription = () => {
		this.setState({showPopupEnableRepricerSubscription: !this.state.showPopupEnableRepricerSubscription});
	}

	enableRepricerSubscription = () => {
		const data = {
			is_repricer_enabled: true,
		}
		this.props.userEnableRepricer(data);
		this.setState({showPopupEnableRepricerSubscription: false});
	}

  render() {
		if(this.props.user_enabling_repricer){
			return (
				<div className="repricer-landing">
					<LoadingIndicator
						title={"Repricer is activating, please wait. This can take up to one minute"}
					/>
				</div>
			)
		}
    return (
      <div className="repricer-landing">
				{this.props.userData && !this.props.userData.repricerEnabled ?
					<React.Fragment>
						<h2 className="page-title">Profit Reprice&trade;</h2>
						<Navbar className="navbar-page mt-4 mb-5" expand="md">
							<Nav navbar>
								<NavItem
									className="start-free-trial"
									style={{
										animationName: this.state.animate ? shakeAnimation : "none",
										animationDuration: "800ms",
									}}
								>
									<NavLink href="#">START FREE TRIAL NOW</NavLink>
								</NavItem>
								<NavItem><NavLink href="#">DASHBOARD</NavLink></NavItem>
								<NavItem><NavLink href="#">TEMPLATES</NavLink></NavItem>
								<NavItem><NavLink href="#">REPORTING</NavLink></NavItem>
								<NavItem><NavLink href="#">SCHEDULER</NavLink></NavItem>
								<NavItem><NavLink href="#">SKUS &amp; SELLERS</NavLink></NavItem>
								<NavItem><NavLink href="#">PRICING</NavLink></NavItem>
							</Nav>
						</Navbar>
					</React.Fragment>
					: null }
        <h3 className="page-subtitle">Protect your profit margins with <strong>Profit Reprice&trade;</strong></h3>
				<div className="d-flex align-items-center justify-content-center">
					<div className="repricer-benefits card">
						<h3 className="heading">Benefits of Using Profit Reprice</h3>
						<div className="benefits-box">
							<ul>
								<li><span role="img" aria-label="automatic_business">🏪</span> Set your business and profits on automatic.</li>
								<li><span role="img" aria-label="focus">🎯</span> Focus on sourcing new products and building relationships rather than manually repricing hundreds or thousands of products.</li>
								<li><span role="img" aria-label="trophy">🏆</span> Win the buy box more often leading to more sales.</li>
								<li><span role="img" aria-label="money_bag">💰</span> Never lose money again on a book or product you list with us.</li>
								<li><span role="img" aria-label="confiture">🛠️</span> Configure repricing templates to match your business goals.</li>
							</ul>
						</div>
					</div>
					<div className="pricing-area">
						<div className="pricing-group">
							<RepricingItem
								price="$99"
								active_inventory="10,000+"
								userData={this.props.userData}
								closeModal={this.togglePopupEnableRepricerSubscription}
								user_enabling_repricer={this.props.user_enabling_repricer}
								isUserSettingsUpdating={this.props.isUserSettingsUpdating}
							/>
							<RepricingItem
								price="$54"
								active_inventory="5,000 - 10,000"
								userData={this.props.userData}
								closeModal={this.togglePopupEnableRepricerSubscription}
								user_enabling_repricer={this.props.user_enabling_repricer}
								isUserSettingsUpdating={this.props.isUserSettingsUpdating}
							/>
							<RepricingItem
								price="$39"
								active_inventory="2,000 - 5,000"
								userData={this.props.userData}
								closeModal={this.togglePopupEnableRepricerSubscription}
								user_enabling_repricer={this.props.user_enabling_repricer}
								isUserSettingsUpdating={this.props.isUserSettingsUpdating}
							/>
							<RepricingItem
								price="$24"
								active_inventory="0 - 2,000"
								userData={this.props.userData}
								closeModal={this.togglePopupEnableRepricerSubscription}
								user_enabling_repricer={this.props.user_enabling_repricer}
								isUserSettingsUpdating={this.props.isUserSettingsUpdating}
							/>
						</div>
						{this.props.userData && !this.props.userData.repricerEnabled ?
							<React.Fragment>
								<hr />
								<Button
									className="btn-cta"
									onClick={this.togglePopupEnableRepricerSubscription}
									disabled={this.props.userData && this.props.userData.has_active_subscription !== 'true'}
								>{this.props.userData.repricerPlan ?
										"REACTIVATE REPRICER" :
										"TRY OUT FOR FREE FOR 7 DAYS!"
									}
								</Button>
							</React.Fragment>
								: null
						}
					</div>
				</div>
				{ this.state.showPopupEnableRepricerSubscription && this.props.userData && this.props.userData.has_active_subscription === 'true' ? (
					<BConfirmationModal
						isOpen={this.state.showPopupEnableRepricerSubscription}
						closeModal={this.togglePopupEnableRepricerSubscription}
						modalTitle="Confirm Enable Repricer Subscription"
						modalText="Please confirm enable repricer subscription"
						buttonColor="success"
						buttonAction={this.enableRepricerSubscription}
						buttonText="ENABLE REPRICER SUBSCRIPTION"
					/> ) : (null)
				}
      </div>
    );
  }
}

export default connect(
	state => {
		return {
			userData: state.Auth.get('userData'),
			user_enabling_repricer: state.Repricer.get("user_enabling_repricer"),
			isUserSettingsUpdating: state.Settings.get("isUserSettingsUpdating"),
		};
	}, {
		userEnableRepricer,
	}
)(RepricerLanding);
