import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import PrintMFTicketSettings from "./PrintMFTicketSettings";
import PrintMFSettingsView from "./PrintMFSettingsView";
import PrintMFSettingsEditForm from "./PrintMFSettingsEditForm";
import PropTypes from "prop-types";
import { mf_ticketing } from "../../../../../helpers/print_service/print_systems";
import settingsActions from "../../../../../redux/settings/actions";
import { connect } from "react-redux";
const { settingsGetMFTicketing } = settingsActions;

class MFTicketing extends Component {
	state = {
		editMode: null,
		afterTabChanging: true,
		row: "A",
		level: "1",
		sequence_length: 4,
		start_at: "1",
		zeros: ""
	};


	componentDidMount() {
		this.props.settingsGetMFTicketing()

		setTimeout(() => {
			if(this.props.mf_ticketing && this.props.mf_ticketing?.shelf) {
				var shelf = this.props.mf_ticketing.shelf;
				let sequence_length = ( shelf && shelf.includes('-') ) ? shelf.split('-')[1].length : this.state.sequence_length
				this.adjustZeros(sequence_length);
			}
		}, 2000)
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const { printer_system } = nextProps.printerDefaults;
		if (!printer_system) return {editMode: null};
		if (printer_system !== mf_ticketing && prevState.editMode === null) {
			return {
				editMode: true
			}
		} else if (printer_system === mf_ticketing && prevState.editMode === null) {
			return {
				editMode: false
			}
		}
		return null;
	};

	shouldComponentUpdate(nextProps, nextState) {
		const { editMode } = nextState;
		const { printerDefaults, setALPrintSystemEditMode } = nextProps;
		if (editMode && printerDefaults.printer_system !== mf_ticketing) {
			setALPrintSystemEditMode(true);
		} else {
			setALPrintSystemEditMode(false);
		}
		return true;
	};

	toggleEditMode = () => {
		const { editMode } = this.state;
		this.setState({
			editMode: !editMode,
			afterTabChanging: false,
		});
	};


	adjustZeros = (sequence_length) => {
		const start_at_length = this.state.start_at.length;
		const remaining_slot = sequence_length-start_at_length;
		let zeros = "";
		for(let i=1; i<=remaining_slot; i++) {
			zeros += "0";
		}

		this.setState({
			zeros: zeros
		});
	};

	onSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});

		// adjust zeros and start_at
		if(name === "sequence_length") {
			let start_at = this.state.start_at;
			let zeros = this.state.zeros;

			if(value <= (start_at.length + zeros.length)) {
				let removed_zeros_length = value - start_at.length;

				zeros = zeros.substr(0, removed_zeros_length);

				if(removed_zeros_length < 0) {
					start_at = start_at.substr(0, value);
				}

				this.setState({
					start_at: start_at,
					zeros: zeros
				})
			} else {
				if(this.props.mf_ticketing && this.props.mf_ticketing?.shelf) {
					var shelf = this.props.mf_ticketing.shelf;
					let sequence_length = ( shelf && shelf.includes('-') ) ? shelf.split('-')[1].length : this.state.sequence_length
					this.adjustZeros(sequence_length);
				}
			}
		}
	};

	onStartChange = (value) => {
		let zeros_length = this.state.sequence_length - value.length;
		let zeros = "";

		for(let i=0; i<zeros_length; i++) {
			zeros += "0";
		}

		this.setState({
			start_at: value,
			zeros: zeros
		})
	};

	// filterPrinterSettings = () => {
	// 	const { printerSettings } = this.props;
	// 	const filteredPrinterSettings = {};
	// 	Object.entries(printerSettings).filter(([key, setting]) => setting.printer_system === mf_ticketing).map(([key, setting]) => {
	// 		const name = printerSettings[key];
	// 		filteredPrinterSettings[key] = setting
	// 	})
	// 	return filteredPrinterSettings;
	// };

	render() {
		const {
			afterTabChanging,
			row,
			level,
			sequence_length,
			start_at,
			zeros
		} = this.state;

		const {
			printerDefaults,
			printerSettings,
			savePrinterDefaults,
			print,
			qzTrayConnectionStatus,
			qzAvailablePrinters,
			setPrinterServiceIsProcessing
		} = this.props;

		return (
			<Row>
				{this.state.editMode
					? (
						<PrintMFSettingsEditForm
							printerDefaults={printerDefaults}
						print={print}
							savePrinterDefaults={savePrinterDefaults}
							qzAvailablePrinters={qzAvailablePrinters}
							qzTrayConnectionStatus={qzTrayConnectionStatus}
							afterTabChanging={afterTabChanging}
							toggleEditMode={this.toggleEditMode}
							setPrinterServiceIsProcessing={setPrinterServiceIsProcessing}
							row={row}
							level={level}
							sequenceLength={sequence_length}
							startAt={start_at}
							zeros={zeros}
						/>
					)
					: (
						<PrintMFSettingsView
							fnsku={`${row}${level}-${zeros}${start_at}`}
							printerSettings={printerSettings}
							printerDefaults={printerDefaults}
							savePrinterDefaults={savePrinterDefaults}
							print={print}
							qzTrayConnectionStatus={qzTrayConnectionStatus}
							toggleEditMode={this.toggleEditMode}
							row={row}
							level={level}
							sequenceLength={sequence_length}
							startAt={start_at}
							zeros={zeros}
						/>
					)
				}
				<Col xl="2">
					{qzTrayConnectionStatus && 
						<PrintMFTicketSettings 
							onStartChange={this.onStartChange}
							onSelectChange={this.onSelectChange}
						/>
					}
				</Col>
			</Row>
		);
	}
}

MFTicketing.propTypes = {
	printerDefaults: PropTypes.object.isRequired,
	savePrinterDefaults: PropTypes.func.isRequired,
	print: PropTypes.func.isRequired,
	qzAvailablePrinters: PropTypes.array.isRequired,
	setALPrintSystemEditMode: PropTypes.func.isRequired,
	setPrinterServiceIsProcessing: PropTypes.func.isRequired,
};

// export default MFTicketing;

export default connect(
	state => {
		return {
			mf_ticketing: state.Settings.get("mf_ticketing"),
		}
	},
	{
		settingsGetMFTicketing,
	}
)(MFTicketing);

