import React, { Component } from 'react'
import { connect } from "react-redux"
import { Card, CardBody, CardHeader, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import SalesChart from "./SalesChart"
import Select from 'react-select'
import SummaryTable from "./SummaryTable"
import SalesCategoryTable from "./SalesCategoryTable"
import SalesProductTable from "./SalesProductTable"
import moment from "moment";

class SalesReport extends Component {
  state = {
    activeTab: "1",
    year: {value: `${moment().year()}0101-${moment().year()}1231`, label: `${moment().year()}`}
  }

  generateYearsDropdown = () => {
    let data = [];
    for (let year = 2015; year <= moment().year(); year++){
      data.unshift({
        value: `${year}0101-${year}1231`,
        label: `${year}`
      })
    }
    return data;
  }

  render() {
    const { activeTab } = this.state

    return (
      <div className="view view-sales-report">
        <header className="view-header d-flex align-items-center justify-content-between">
          <h1 className="page-title">Sales Reports</h1>
          <div className="d-flex align-items-center justify-content-end gap-4">
            <div className="d-flex align-items-center gap-2">
              <Label>Year:</Label>
              <div style={{ minWidth: 90 }}>
                <Select
                  options={this.generateYearsDropdown()}
                  value={this.state.year}
                  onChange={selected => this.setState({year: selected})}
                  clearable={false}
                />
              </div>
            </div>
            {/*
            <div className="d-flex align-items-center gap-2">
              <Label>Category</Label>
              <div style={{ minWidth: 130 }}>
                <Select
                  options={[
                    {value: 'All', label: 'All Category'},
                    {value: 'Beauty', label: 'Beauty'},
                    {value: 'Book', label: 'Book'},
                    {value: 'DVD', label: 'DVD'},
                    {value: 'Video Games', label: 'Video Games'}
                  ]}
                  value={{value: 'all', label: 'All Category'}}
                  onChange={selected => {}}
                  clearable={false}
                />
              </div>
            </div>
            */}
          </div>
        </header>
        <div className="view-content">
          <Card>
            <CardBody>
              <SalesChart
                internationalizationConfig={this.props.internationalization_config}
                time_period={this.state.year}
              />
            </CardBody>
          </Card>
          <Card className="mt-5 card-tab">
            <CardHeader className="d-flex justify-content-between">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    role="button"
                    active={activeTab === "1"}
                    onClick={() => this.setState({ activeTab: "1" })}
                  >
                    Summary
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    role="button"
                    active={activeTab === "2"}
                    onClick={() => this.setState({ activeTab: "2" })}
                  >
                    Sales by category
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    role="button"
                    active={activeTab === "3"}
                    onClick={() => this.setState({ activeTab: "3" })}
                  >
                    Sales by product
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                {this.state.activeTab === "1" &&
                  <SummaryTable
                    time_period={this.state.year}
                  />
                }
                </TabPane>
                <TabPane tabId="2">
                {this.state.activeTab === "2" &&
                  <SalesCategoryTable
                    time_period={this.state.year}
                  />
                }
                </TabPane>
                <TabPane tabId="3">
                {this.state.activeTab === "3" &&
                  <SalesProductTable
                    time_period={this.state.year}
                  />
                }
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
  }
)(SalesReport)
