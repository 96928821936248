import React, { Component } from 'react';
import { connect } from "react-redux";
import {
	Nav, NavItem, NavLink, TabContent, TabPane,
	Card, CardBody, Navbar
} from 'reactstrap';
import ProfitabilityTableMetrics from "./ProfitabilityTableMetrics";
import accountingActions from "../../../redux/accounting/actions";
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const {
  suppliersLoad,
  scoutsLoad,
  categoriesLoad,
} = accountingActions;

class Profitability extends Component {
	state = {
		activeTab: '1'
	}

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Accounting/Profitability"})
  }

	UNSAFE_componentWillMount(){
		this.props.suppliersLoad();
		this.props.scoutsLoad();
    this.props.categoriesLoad();
	}

	toggle = (tabId) => {
		this.setState({
			activeTab: tabId
		})
	}

	getMetricValue = (type, key) => {
		let result = 0;

		if(type === "suppliers") {
			this.props.dataSuppliers.forEach(item => {
				result += item[key];
			});
		} else if(type === "scouts") {
			this.props.dataScouts.forEach(item => {
				result += item[key];
			});
		} else if(type === "categories") {
			this.props.categories_data.forEach(item => {
				result += item[key];
			});
		}

		return result;
	}

	render() {
		const {
			activeTab
		} = this.state;

		const metricsSuppliers = {
			units_sold: this.getMetricValue("suppliers", "units_sold"),
			units_refunded: this.getMetricValue("suppliers", "units_refunded"),
			revenue: this.getMetricValue("suppliers", "revenue"),
			profit: this.getMetricValue("suppliers", "profit"),
			on_hand: this.getMetricValue("suppliers", "on_hand")
		}

		const metricsScouts = {
			units_sold: this.getMetricValue("scouts", "units_sold"),
			units_refunded: this.getMetricValue("scouts", "units_refunded"),
			revenue: this.getMetricValue("scouts", "revenue"),
			profit: this.getMetricValue("scouts", "profit"),
			on_hand: this.getMetricValue("scouts", "on_hand")
		}

		const metricsCategories = {
			units_sold: this.getMetricValue("categories", "units_sold"),
			units_refunded: this.getMetricValue("categories", "units_refunded"),
			revenue: this.getMetricValue("categories", "revenue"),
			profit: this.getMetricValue("categories", "profit"),
			on_hand: this.getMetricValue("categories", "on_hand")
		}


		return (
			<div className="view">
				<header className="view-header">
					<h1 className="page-title">Profitability</h1>
				</header>
				<div className="view-content">
					<Navbar color="dark" expand="md">
						<Nav navbar>
							<NavItem>
								<NavLink
									href="#"
									className={activeTab === '1' ? 'active' : ''}
									onClick={() => this.toggle('1')}
								>Suppliers</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									href="#"
									className={activeTab === '2' ? 'active' : ''}
									onClick={() => this.toggle('2')}
								>Scouts</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									href="#"
									className={activeTab === '3' ? 'active' : ''}
									onClick={() => this.toggle('3')}
								>Category</NavLink>
							</NavItem>
						</Nav>
					</Navbar>
					<TabContent activeTab={activeTab}>
						<TabPane tabId='1'>
							<Card>
								<CardBody>
									{activeTab === '1' &&
										<ProfitabilityTableMetrics
											data={this.props.dataSuppliers}
											metrics={metricsSuppliers}
											type="suppliers"
											internationalization_config={this.props.internationalization_config}
										/>
									}
								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId='2'>
							<Card>
								<CardBody>
									{activeTab === '2' &&
										<ProfitabilityTableMetrics
											data={this.props.dataScouts}
											metrics={metricsScouts}
											type="scouts"
											internationalization_config={this.props.internationalization_config}
										/>
									}
								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId='3'>
							<Card>
								<CardBody>
									{activeTab === '3' &&
										<ProfitabilityTableMetrics
											data={this.props.categories_data}
											metrics={metricsCategories}
											type="category"
											internationalization_config={this.props.internationalization_config}
										/>
									}
								</CardBody>
							</Card>
						</TabPane>
					</TabContent>
				</div>
			</div>
		)
	}
}

export default connect(
  state => {
    return {
			dataSuppliers: state.Accounting.get('suppliers_data'),
			dataScouts: state.Accounting.get('scouts_data'),
      categories_data: state.Accounting.get('categories_data'),
			internationalization_config: state.Auth.get("internationalization_config"),
    }
  },
  {
		suppliersLoad,
		scoutsLoad,
		categoriesLoad,
    userPageviewCapture,
  }
)(Profitability);
