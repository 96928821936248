import React from "react";
import { Card, CardBody, Button, Input } from "reactstrap";
import PropTypes from 'prop-types';
import categories from "../../../helpers/batch/amazon_categories";
import TooltipAtCustomElement from "../../../shared/components/TooltipAtCustomElement";
import "./style.css";
import Select from "react-select";
import { getNewConditionOptions } from "../../../helpers/batch/utility";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const ConditionNoteForm = props => (
    <Card className="mb-4">
        <CardBody>
            <h6>
                {props.editingId ? 'Edit' : 'Create'} Condition Note
            </h6>
            <form id="form" className="condition-form mt-4">
                <ol>
                    <li>
                        <span>Pick Amazon Category</span>
                        <div className={props.hasError("category")} style={{ display: "flex"}}>
                            <Input
                                type="select"
                                disabled={props.disableForm}
                                name="category"
                                id="category"
                                onClick={props.handleChange}
                                onChange={props.handleChange} >
                                <option value="" />
                                {categories.map((category, idx) => {
                                    return (
                                        <option key={'cat-' + idx} value={category}>{category}</option>
                                    )
                                })}
                            </Input>&nbsp;
                            <span className="py-2">
                                <TooltipAtCustomElement
                                    tooltipId="AmazonCategory"
                                    tooltipText="Amazon Category"
                                    CustomElement={IconTooltip}
                                    styles={{ maxWidth: "20rem" }}
                                />
                            </span>
                        </div>
                    </li>
                    <li>
                        <span>Create unique sub-category</span>
                        <div className={props.hasError("subcategory")} style={{ display: "flex"}}>
                            <Input
                                name="subcategory"
                                disabled={props.disableForm}
                                id="subcategory"
                                onBlur={props.handleChange}
                                maxLength={50}
                            />&nbsp;
                            <span className="py-2">
                                <TooltipAtCustomElement
                                    tooltipId="uniqueSubCategory"
                                    tooltipText="Unique sub-category"
                                    CustomElement={IconTooltip}
                                    styles={{ maxWidth: "20rem" }}
                                />
                            </span>
                        </div>
                    </li>
                    <li>
                        <span>Assign comment nickname</span>
                        <div className={props.hasError("nickname")} style={{ display: "flex"}}>
                            <Input
                                name="nickname"
                                disabled={props.disableForm}
                                id="nickname"
                                onBlur={props.handleChange}
                                maxLength={30}
                            />&nbsp;
                            <span className="py-2">
                                <TooltipAtCustomElement
                                    tooltipId="commentNickname"
                                    tooltipText="Comment nickname"
                                    CustomElement={IconTooltip}
                                    styles={{ maxWidth: "20rem" }}
                                />
                            </span>
                        </div>
                    </li>
                    <li>
                        <span>ADD or EDIT condition note</span>
                        <div className={props.hasError("note_text")} style={{ display: "flex"}}>
                            <Input
                                type="textarea"
                                name="note_text"
                                disabled={props.disableForm}
                                id="note_text"
                                onBlur={props.handleChange}
                            />&nbsp;
                            <span className="py-2">
                                <TooltipAtCustomElement
                                    tooltipId="conditionNote"
                                    tooltipText="condition Note"
                                    CustomElement={IconTooltip}
                                    styles={{ maxWidth: "20rem" }}
                                />
                            </span>
                        </div>
                    </li>
                    <li>
                        <span>Link note to condition grade (optional)</span>
												<Select
													className="text-left"
													name="grade"
													value={ getNewConditionOptions(true).find(item => { return item.value === props.formValues.grade }) }
													clearable={true}
													options={getNewConditionOptions(true)}
													onChange={
														option => {
															if(option){
																option.target = {
																	name: "grade",
																	value: option.value
																};
															} else {
																option = {
																	target: {
																		name: "grade",
																		value: ""
																	}
																}
															}
															props.handleChange(option);
														}
													}
												/>
												{/*
                        <div className={props.hasError("lingking_text")} style={{ display: "flex"}}>
                            <Input
                                type="select"
                                name="link_note"
                                disabled={props.disableForm}
                                id="link_note"
                                onBlur={props.handleChange}
                            >
                                <option>Used - Very Good</option>
                                <option>Used - Good</option>
                                <option>Used - Acceptable</option>
                            </Input>
                             <Tooltip
                              tooltipId="noteLingking"
                              tooltipText="Link a condition note to specific conditon grade"
                            />
												</div>
												*/}
                    </li>
                </ol>
                <div>
                    <Button
                        type="button"
                        disabled={props.disableForm || props.savingConditionNote}
                        color="success"
                        onClick={props.submitForm}
                    >
                        Apply to Favorites
                    </Button>
                    <Button
                        className="float-right"
                        type="button"
                        disabled={props.disableForm}
                        onClick={props.cancelUpdate}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </CardBody>
    </Card>
)

ConditionNoteForm.propTypes = {
    submitForm: PropTypes.func,
    handleChange: PropTypes.func,
    cancelUpdate: PropTypes.func,
    hasError: PropTypes.func,
    editingId: PropTypes.number
};

export default ConditionNoteForm;
