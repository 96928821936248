import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import repricerActions from '../../../../redux/repricer/actions';
import ReportsTable from './components/ReportsTable';
import ReportDetail from './components/ReportDetail';
import MasterRepriceReport from './components/MasterRepriceReport';

const {
	userLoadReports,
  reportInsightRun,
} = repricerActions;

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorOpen : false,
			viewDetail: false,
			viewDetailId: '',
			viewDetailLastRun: '',
			activeTab: "1"
    }
  }

	UNSAFE_componentWillMount(){
		this.props.userLoadReports();
  }

  toggleView = (viewDetailId, viewDetailLastRun) => {
    this.setState({
      viewDetail: !this.state.viewDetail,
	  viewDetailId: viewDetailId,
	  viewDetailLastRun: viewDetailLastRun,
    })
	}

	toggleTab = (tabId) => {
		this.setState({
			activeTab: tabId
		});
	}

  render() {
		const {
			activeTab
		} = this.state;

    return(
			<Card className="card-tab">
        <CardHeader className="d-flex justify-content-between">
				<Nav tabs>
					<NavItem>
						<NavLink
							active={activeTab === "1" ? "active" : "" }
							onClick={() => this.toggleTab("1")}
						>
							Template &amp; Detail Reporting
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							active={activeTab === "2" ? "active" : "" }
							onClick={() => this.toggleTab("2")}
						>
							Master Reprice Report
						</NavLink>
					</NavItem>
				</Nav>
				</CardHeader>
				<CardBody>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							{!this.state.viewDetail ?
								<ReportsTable
									reports={this.props.repriceReports}
									toggleView={this.toggleView}
								/>
							:
                <ReportDetail
                  toggleView={this.toggleView}
                  id={this.state.viewDetailId}
                  lastRun={this.state.viewDetailLastRun}
                  insightTemplate={this.props.insightTemplate}
                  insightTemplateLoading={this.props.insightTemplateLoading}
                  reportInsightRun={this.props.reportInsightRun}
                />
							}
						</TabPane>
						<TabPane tabId="2">
							<MasterRepriceReport />
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
    );
  }
}

export default connect(
  state => ({
	  userData: state.Auth.get("userData"),
	  repriceReports: state.Repricer.get("repriceReports"),
    insightTemplate: state.Repricer.get("insightTemplate"),
    insightTemplateLoading: state.Repricer.get("insightTemplateLoading"),
  }),
  {
  	userLoadReports,
    reportInsightRun,
  }
)(Reports);
