export const templateDefaultDataSetTC = {
  "name": "",
  "type": "custom_template_v8",
  "user_inventory_channel_target": "amazon_fulfill",
  "exclude_items_with_sales_rank_above": 2,
  "user_inventory_product_salesrank_max": 15000000,
  "user_inventory_product_salesrank_min": 1,
  "user_inventory_product_price_max": 1000,
  "user_inventory_product_price_min": 1,
  "user_inventory_inventory_age_max": 365,
  "user_inventory_inventory_age_min": 1,
  "user_inventory_condition_to_reprice": [
    {"label": "New", "value": "NewItem", "repricer": "11"},
    {"label": "Used / Like New", "value": "UsedLikeNew", "repricer": "1"},
    {"label": "Used / Very Good", "value": "UsedVeryGood", "repricer": "2"},
    {"label": "Used / Good", "value": "UsedGood", "repricer": "3"},
    {"label": "Used / Acceptable", "value": "UsedAcceptable", "repricer": "4"},
    {"label": "Refurbished", "value": "Refurbished", "repricer": "10"},
    {"label": "Collectible / Like New", "value": "CollectibleLikeNew", "repricer": "5"},
    {"label": "Collectible / Very Good", "value": "CollectibleVeryGood", "repricer": "6"},
    {"label": "Collectible / Good", "value": "CollectibleGood", "repricer": "7"},
    {"label": "Collectible / Acceptable", "value": "CollectibleAcceptable", "repricer": "8"},
    {"label": "Not Used", "value": "NotUsed", "repricer": "9"}
  ],
  "reprice_rule_for_failed_reprice_no_competing_offer_exist_activate": false,
  "reprice_rule_for_failed_reprice_none_of_competing_offer_meet_requirement_activate": false,
  "reprice_rule_for_failed_reprice_general_failed_activate": false,
  "no_competing_offer_exist_rule": {},
  "none_of_competing_offer_meet_requirement_rule": {},
  "general_failed_rule": {},
  "pricing_rules_use_regular_repricing_strategy_against_amazon": true,
  "pricing_rules_use_if_you_are_the_buy_box_repricing_strategy": false,
  "guards_setMinROI": false,
  "guards_drop_percentage": 10,
  "guards_increase_price": 10,
  "pricing_rules_dont_price_above_featured_merchants": false,
  "pricing_rules_amazon_adjustment_static_method_adjust_up_if_true_down_if_false": false,
  "pricing_rules_amazon_adjustment_fix_price_adjustment_if_true_percentage_if_false": false,
  "pricing_rules_adjustment_adjustment_amount_on_competing_offer": 10,
  "pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false": false,
  "pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false": false,
  "guards_price_min": 1,
  "guards_price_max": 1000000,
  "guards_if_amazon_lower_not_reprice": false,
  "guards_reprice_if_suggested_up": false,
  "guards_reprice_if_suggested_down": false,
  "guards_lower_than_price": false,
  "guards_higher_than_price": false,
  "offers_targeting_competing_offer_to_reprice_against": "use_own_inventory_not_in_buy_box_offer_targeting",
  "offers_targeting_own_inventory_not_in_the_buy_box_offer_targeting": "lowest_offer_with_same_main_and_sub_condition",
  "offers_targeting_exclude_offers_with_seller_rating_below": 90,
  "offers_targeting_exclude_offers_with_seller_feed_back_count_below": 10,
  "offers_targeting_prime_filter": "",
  "pricing_rules_exclude_offer_if_sub_condition_acceptable": false,
  "offers_targeting_minimum_offer_to_trigger_reprice": 1,
  "user_inventory_channel_target_fba": true,
  "pricing_rules_exclude_offer_not_available_now": false,
  "offers_targeting_seller_to_exclude_id": null,
  "offers_targeting_sellers_to_exclude": null,
	"offers_count_strategy": "dont_reprice",
	"offers_count_min_num_offer": 0,
	"offers_count_own_inventory_in_buybox_dont_reprice": true,
  "offers_count_target_channel": null,
	"offers_count_target_lowest_or_buybox": null,
  "offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer": null,
  "offers_count_own_inventory_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false": true,
  "offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false": true,
  "offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer": null,
  "offers_count_own_inventory_not_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false": true,
  "offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false": true,
	"reprice_used_aginst_new_uan_is_activated": false,
}
