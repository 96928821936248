import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import './index.css';
import settingsActions from "../../../redux/settings/actions";

const {
  skuTemplateLoad,
  skuTemplateDelete,
  skuTemplateUse,
} = settingsActions

class SKUSettingsSaved extends Component {

  componentDidMount = () => {
    this.props.skuTemplateLoad();
  }

  displayTemplateField = (field) => {
    return (
      <div className="field">{field}</div>
    )
  }

  deleteTemplate = (id) => {
    const data = { template_id: id }
    this.props.skuTemplateDelete(data);
  }

  displayTemplate = (template) => {
    let sku_data = template.sku_prefix.replace().split('-');
    sku_data = sku_data.map(sku => {
      if(sku.length > 2) {
        if(sku[0] === "{" && sku[sku.length -1 ] === "}") {
          sku = sku.substring(1, sku.length -1);
        } else {
          return sku;
        }
      } else {
        return sku;
      }
      return sku;
    })
    return (
        <div className="saved-template">
          <h6 className="name">{template.name}{template.active ? " [Active Template]" : null}</h6>
          <div className="inner">
            <div className="template">
              {sku_data.map(item => {return this.displayTemplateField(item)})}
            </div>
            <div className="controls">
              {!template.active &&
                <Button
                  color="primary"
                  onClick={() => this.props.skuTemplateUse({id: template.id})}
                  disabled={this.props.sku_templates_saved_loading}
                >Use</Button>
              }
              {template.active &&
                <Button
                  color="secondary"
                  onClick={() => this.props.onEditTemplate()}
                  disabled={this.props.sku_templates_saved_loading}
                >Edit</Button>
              }
              <Button
                color="link text-danger"
                onClick={() => this.deleteTemplate(template.id)}
                disabled={this.props.sku_templates_saved_loading}
              >Delete</Button>
            </div>
          </div>
        </div>
    )
  }

  render() {
    const {
      onUseTemplate,
      onEditTemplate
    } = this.props;

    return (
      <div className="saved-sku-templates">
        <p>List of saved SKU templates</p>

        <div className="saved-sku-templates-group">
          {this.props.sku_templates_saved ?
            this.props.sku_templates_saved.map(item => {
              return this.displayTemplate(item);
            })
            : null
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
		userTags: state.UserTags.get("userTags"),
		userData: state.Auth.get("userData"),
    sku_templates_saved: state.Settings.get("sku_templates_saved"),
    sku_templates_saved_loading: state.Settings.get("sku_templates_saved_loading"),
  }),
  {
    skuTemplateLoad,
    skuTemplateDelete,
    skuTemplateUse,
  }
)(SKUSettingsSaved);
