import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'reactstrap';
import { digitСonversion } from '../../../../../helpers/utility';
import { configuration } from "./config";
import MetricItem from "../../../main_dashboard/MetricItem";
import RepricingChart from "./RepricingChart";

class Repricing extends Component {
  moneyFormat = (val) => {
    return digitСonversion(val, "currency", "USD");
  }

  tooltipFormatter = (value, name, props) => {
    return name === 'value' ? this.moneyFormat(value) : value;
  }

	componentDidMount = () => {
		this.props.getRepricerDashboardRepricing();
	}

  render() {
    const {
      internationalization_config
    } = this.props;

    return (
      <Row>
        <Col lg="3">
					{this.props.dashboard_repricing && this.props.dashboard_repricing.aggregate_data ?
						<div className="metrics-vertical mt-5">
						{ configuration.map(config => {
							let {key, className, metricName, numberType, tooltipText} = config;
							let value = this.props.dashboard_repricing.aggregate_data[key];
							return (
								<MetricItem
									key={key}
									metricKey={key}
									metricName={metricName}
									value={value}
									numberType={numberType}
									currencyCode={internationalization_config.currency_code}
									className={className}
									tooltipText={tooltipText}
								/>
							)
						})}
						</div>
						: null
					}
        </Col>
        <Col lg="9">
					<RepricingChart
						dashboard_repricing={
							this.props.dashboard_repricing && this.props.dashboard_repricing.span_data ?
								this.props.dashboard_repricing.span_data : []
						}
					/>
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => {
    return {
      internationalization_config: state.Auth.get("internationalization_config")
    };
  },
  {

  }
)(Repricing);
