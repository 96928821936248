import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import styled from 'styled-components';
import { digitСonversion } from '../../../../../../../helpers/utility';
import moment from 'moment';
import { ButtonGroup, Button } from 'reactstrap';
import chart_config from "../../../../../main_dashboard_v3/chart_config";
import getGradient from "../../../../../main_dashboard_v3/chart_gradients";

const StyledSummary = styled.p`
  margin-top: 2em;
  font-size: 18px;
  text-align: center;
`;

class ProfitAnalyticsChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.period === null) {
      if (nextProps.data) {
        const weekArray = Object.keys(nextProps.data).reduce((acc, data) => {
          let grouping = moment(data).year() + '-' + (moment(data).week());
          if (acc.findIndex(el => el === grouping) === -1) {
            acc.push(grouping);
          }
          return acc;
        }, []);

        const daysCount = Object.keys(nextProps.data).length;
        const weeksCount = weekArray.length;

        if (daysCount > 90) {
          if (weeksCount > 90) {
            return { period: 'monthly'}
          } else {
            return { period: 'weekly'}
          }
        }
        return { period: 'daily'};
      } else {
        return { period: 'daily'};
      }
    }
    return null;
  }

  customTickFormatter = (value) => {
    return digitСonversion(value, "currency", this.props.internationalization_config.currency_code);
  };

  getDailyData = (data) => {
    let dailyData = [];

    for(let key of Object.keys(data)) {
      let item = data[key];
      item.totalExpenses = Math.abs(item.totalExpenses || 0)
      dailyData.push(item);
    }

    return dailyData;
  }

  getWeeklyData = (data) => {
    let weeklyData = [];
    let weekGroups = this.getDateGroups(Object.keys(data), 'year-week');

    for(let key of Object.keys(weekGroups)) {
      let group = weekGroups[key];
      let weeklyNetProfit = 0, weeklyGrossIncome = 0, weeklyExpenses = 0;

      group.forEach( date => {
        let dateKey = moment(date).format("YYYY-MM-DD");

        weeklyGrossIncome += data[dateKey].grossIncome || 0;
        weeklyExpenses += data[dateKey].totalExpenses || 0;
        weeklyNetProfit += data[dateKey].netProfit || 0;
      });

      weeklyData.push({
        "grossIncome": weeklyGrossIncome,
        "totalExpenses": Math.abs(weeklyExpenses),
        "netProfit": weeklyNetProfit,
        "label": key
      });
    }

    return weeklyData;
  }

  getMonthlyData = (data) => {
    let monthlyData = [];
    let monthGroups = this.getDateGroups(Object.keys(data), 'year-month');

    for(let key of Object.keys(monthGroups)) {
      let group = monthGroups[key];
      let weeklyNetProfit = 0, weeklyGrossIncome = 0, weeklyExpenses = 0;

      group.forEach( date => {
        let dateKey = moment(date).format("YYYY-MM-DD");

        weeklyGrossIncome += data[dateKey].grossIncome || 0;
        weeklyExpenses += data[dateKey].totalExpenses || 0;
        weeklyNetProfit += data[dateKey].netProfit || 0;
      });

      monthlyData.push({
        "grossIncome": weeklyGrossIncome,
        "totalExpenses": Math.abs(weeklyExpenses),
        "netProfit": weeklyNetProfit,
        "label": key
      });
    }

    return monthlyData;
  }

  getDateGroups = (dates, groupType) => {
    let dateGroups = dates.reduce( function (acc, date) {
      let grouping = '';

      if(groupType === 'year-week') {
        grouping = moment(date).year() + '-' + moment(date).week();
      } else if(groupType === 'year-month') {
        grouping = moment(date).year() + '-' + (moment(date).month()+1);
      }

      if (typeof acc[grouping] === 'undefined') {
        acc[grouping] = [];
      }

      acc[grouping].push(date);

      return acc;
    }, {});

    return dateGroups;
  }

  getData = (data) => {
    if(this.state.period === 'daily') {
      return this.getDailyData(data);
    } else if(this.state.period === 'weekly') {
      return this.getWeeklyData(data);
    } else if(this.state.period === 'monthly') {
      return this.getMonthlyData(data);
    }
  }

  togglePeriod = (period) => {
    this.setState({
      period: period
    });
  }

  render() {
    const {
      data,
      roi,
      dailyNetProfit,
    } = this.props

    let chartData, displayed_roi, displayed_dailyNetProfit;

    chartData = this.getData(data);
    displayed_roi = roi;
    displayed_dailyNetProfit = dailyNetProfit;

    return (
      <div className={`demoable-content-wrapper`}>
        <ButtonGroup className="mb-3 d-flex justify-content-end demoable-content">
          <Button active={this.state.period === 'daily'} onClick={() => this.togglePeriod('daily')}>Daily</Button>
          <Button active={this.state.period === 'weekly'} onClick={() => this.togglePeriod('weekly')}>Weekly</Button>
          <Button active={this.state.period === 'monthly'} onClick={() => this.togglePeriod('monthly')}>Monthly</Button>
        </ButtonGroup>

        <ResponsiveContainer width="100%" height={400} className="demoable-content">
          <ComposedChart data={chartData} margin={{ left: 20}}>
            <defs>
              {getGradient("gradientGreenGross","blue")}
              {getGradient("gradientRedExp","red")}
            </defs>
            <CartesianGrid
              stroke={chart_config.cartesianStrokeColor}
              vertical={false}
            />
            <XAxis
              dataKey="label"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize
              }}
              tickFormatter={this.customTickFormatter}
            />
            <Tooltip formatter={this.customTickFormatter} cursor={false} />
            <Legend />
            <Bar dataKey="grossIncome" fill="url(#gradientGreenGross)" radius={chart_config.barRadius} />
            <Bar dataKey="totalExpenses" fill="url(#gradientRedExp)" radius={chart_config.barRadius} />
            <Line dataKey="netProfit" type="monotone" stroke={chart_config.greenColor} dot={chart_config.dot} strokeWidth={chart_config.strokeWidth} isAnimationActive={false} />
          </ComposedChart>
        </ResponsiveContainer>
        <StyledSummary className="demoable-content">
          During this time period, your average ROI was <strong
            className={ displayed_roi > 0 ? "text-success" : "text-danger"}
          >
            {digitСonversion(displayed_roi, "percent")}
          </strong> and a net profit of <strong
            className={ displayed_dailyNetProfit > 0 ? "text-success" : "text-danger"}
          >
            {digitСonversion(displayed_dailyNetProfit, "currency", this.props.internationalization_config.currency_code )}/day
          </strong>
        </StyledSummary>
        <div className="demo-overlay">
          <p>Currently we don't have enough data to show</p>
        </div>
      </div>
    );
  }
}

ProfitAnalyticsChart.propTypes = {
  data: PropTypes.object.isRequired,
  roi: PropTypes.number.isRequired,
  dailyNetProfit: PropTypes.number.isRequired,
	internationalization_config: PropTypes.object.isRequired,
};

export default ProfitAnalyticsChart;
