export default [
  "All Categories",
    "Common",
    "Others",
    "Amazon Device Accessories",
    "Amazon Kindle",
    "Automotive & Powersports",
    "Baby Products",
    "Beauty",
    "Books",
    "Business Products",
    "Camera & Photo",
    "Cell Phones",
    "Clothing & Accessories",
    "Collectible Coins",
    "Electronics (Accessories)",
    "Electronics (Consumer)",
    "Fashion Jewelry",
    "Fine Jewelry",
    "Fine Art",
    "Grocery & Gourmet Food",
    "Handmade",
    "Health & Personal Care",
    "Historical & Advertising Collectibles",
    "Home & Garden",
    "Industrial & Scientific",
    "Luggage & Travel Accessories",
    "Music",
    "Musical Instruments",
    "Office Products",
    "Outdoors",
    "Personal Computers",
    "Professional Services",
    "Shoes, Handbags & Sunglasses",
    "Software & Computer Games",
    "Sports",
    "Sports Collectibles",
    "Tools & Home Improvement",
    "Toys & Games",
    "Video, DVD & Blu-Ray",
    "Video Games & Video Game Consoles",
    "Watches"
]
