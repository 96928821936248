import React from "react"

const getGradient = (id, color) => {
  const colorsMap = {
    "orange"      : { sc1: '#ff9900', sc2: '#fda93b', sc3: '#fcb859', sc4: '#fbd38d' },
    "blue"        : { sc1: '#1f73d1', sc2: '#2f83d7', sc3: '#3e94df', sc4: '#63b3ed' },
    "green"       : { sc1: '#00943e', sc2: '#24a453', sc3: '#3cb467', sc4: '#68d391' },
    "red"         : { sc1: '#e75454', sc2: '#ed5f5f', sc3: '#f36b6b', sc4: '#fc8181' },
    "redNegative" : { sc4: '#e75454', sc3: '#ed5f5f', sc2: '#f36b6b', sc1: '#fc8181' },
  }

  return (
  <linearGradient id={id} x1="0.5" y1="1" x2="0.5" y2="0">
    <stop offset="0%" stopColor={colorsMap[color].sc1} />
    <stop offset="25%" stopColor={colorsMap[color].sc2} />
    <stop offset="50%" stopColor={colorsMap[color].sc3} />
    <stop offset="100%" stopColor={colorsMap[color].sc4} />
  </linearGradient>
  )
}
export default getGradient