import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col, Label, Input, Button, FormGroup } from 'reactstrap';
import adminActions from "../../../../redux/admin/actions";
import AdminImportantAlerts from "../AdminImportantAlerts";

const {
  getAppSettings,
  updateAppSettings,
} = adminActions;

class AdminAPP extends Component {

  state = {
    desktop_version: "",
    desktop_win_url: "",
    desktop_mac_url: "",
  }

  componentDidMount(){
    this.props.getAppSettings();
  }

  updateState = (key, value) => {
    this.setState({ [key]: value });
  }

  updateDesktopDownload = () => {
    const { app_settings } = this.props;
    const data = {
      key: "desktop_app",
      data: {
        version: this.state.desktop_version ? this.state.desktop_version : app_settings.desktop_download_settings.version,
        win_url: this.state.desktop_win_url ? this.state.desktop_win_url : app_settings.desktop_download_settings.win_url,
        mac_url: this.state.desktop_mac_url ? this.state.desktop_mac_url : app_settings.desktop_download_settings.mac_url,
      }
    }
    this.props.updateAppSettings(data);
    this.setState({
      desktop_version: "",
      desktop_win_url: "",
      desktop_mac_url: "",
    });
  }

  render() {

    const {
      app_settings,
    } = this.props;

    return (
      <React.Fragment>
        <Card className="card-tab">
          <CardHeader>
            <h1 className="h6 mb-4">APP Settings</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <h3 className="h6 mb-12">APP landing destop download settings</h3>
                <FormGroup>
                  <Label>Version</Label>
                  <Input
                    defaultValue={
                      app_settings
                        && app_settings.desktop_download_settings
                        && app_settings.desktop_download_settings.version
                          ? app_settings.desktop_download_settings.version
                          : ""
                    }
                    onChange={e => this.updateState("desktop_version", e.target.value)}
                    disabled={this.props.admin_app_settings_loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>WIN download URL</Label>
                  <Input
                    defaultValue={
                      app_settings
                        && app_settings.desktop_download_settings
                        && app_settings.desktop_download_settings.win_url
                          ? app_settings.desktop_download_settings.win_url
                          : ""
                    }
                    onChange={e => this.updateState("desktop_win_url", e.target.value)}
                    disabled={this.props.admin_app_settings_loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>MAC download URL</Label>
                  <Input
                    defaultValue={
                      app_settings
                        && app_settings.desktop_download_settings
                        && app_settings.desktop_download_settings.mac_url
                          ? app_settings.desktop_download_settings.mac_url
                          : ""
                    }
                    onChange={e => this.updateState("desktop_mac_url", e.target.value)}
                    disabled={this.props.admin_app_settings_loading}
                  />
                </FormGroup>
                <br />
                <Button
                  color="primary"
                  onClick={e => this.updateDesktopDownload()}
                  disabled={this.props.admin_app_settings_loading}
                >
                  Update
                </Button>
                <div>
                  <b>{this.props.admin_app_settings_message}</b>
                </div>
              </Col>
              <Col md={8}>
                <AdminImportantAlerts
                  updateAppSettings={this.props.updateAppSettings}
                  app_settings={this.props.app_settings}
                  admin_app_settings_loading={this.props.admin_app_settings_loading}
                  admin_app_settings_message={this.props.admin_app_settings_message}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
    return {
      app_settings: state.Admin.get("admin_app_settings"),
      admin_app_settings_loading: state.Admin.get("admin_app_settings_loading"),
      admin_app_settings_message: state.Admin.get("admin_app_settings_message"),
    };
  },
  {
    getAppSettings,
    updateAppSettings,
  }
)(AdminAPP);
