import React, { Component } from "react";

import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Col,
	Row,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Navbar,
} from "reactstrap";
import './style.css';
import UpdateUserPassword from './UpdateUserPassword';
import AdminSearchUser from './AdminSearchUser';
import AdminUsersPerMarketplace from './AdminUsersPerMarketplace';
import AdminUsersErrorLogs from './AdminUsersErrorLogs';
import AdminUpdateUserToken from './AdminUpdateUserToken';
import AdminBatchCreatedChart from './AdminBatchCreatedChart';
import AdminMisc from './AdminMisc';
import AggregatedData from './AggregatedData';
import classnames from 'classnames';
import AdminRepricerStats from './AdminRepricerStats';
import AdminUserUpdate from './AdminUserUpdate';
import AdminUserWhitelist from './AdminUserWhitelist';
import AdminFBCUserEmails from './AdminFBCUserEmails';
//import AdminEbayPullInOrders from './AdminEbayPullInOrders';
//import AdminEbayCompleteOrder from './AdminEbayCompleteOrder';
import AdminUserSuccess from './AdminUserSuccess';
import AdminUsersSegmentation from './AdminUsersSegmentation';
import AdminEbay from "./AdminEbay";
import AdminFAQ from "./AdminFAQ";
import AdminAPP from "./AdminAPP";
import AdminCancelReason from "./AdminCancelReason";
import AdminMessages from "./AdminMessages";
import AdminUserSmsActivate from "./AdminUserSmsActivate";
import AdminSpApi from "./AdminSpApi";
import AdminAIAnalyze from "./AdminAIAnalyze";
import AdminUserSplitShipments from "./AdminUserSplitShipments";

/**
 * Admin area component
 */
class Admin extends Component {

	state = {
		activeTab: '1',
	}

	toggleTabs = (tabID) => {
		this.setState({ activeTab: tabID });
	}

  render() {

    return (
      <div className="view">
				<header className="view-header">
					<h1 className="page-title">Admin Dashboard</h1>
				</header>
	  <div className="view-content view-dashboard">
		<Navbar color="dark" expand="md">
			<Nav navbar>
			  <NavItem>
				<NavLink
					href="#"
				  className={classnames({ active: this.state.activeTab === '1' })}
				  onClick={() => { this.toggleTabs('1'); }}
				>
				  Stats
				</NavLink>
			  </NavItem>
			  <NavItem>
				<NavLink
					href="#"
				  className={classnames({ active: this.state.activeTab === '2' })}
				  onClick={() => { this.toggleTabs('2'); }}
				>
				  User
				</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '3' })}
					  onClick={() => { this.toggleTabs('3'); }}
					>
					  Repricer
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '23' })}
					  onClick={() => { this.toggleTabs('23'); }}
					>
					  MISC
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '4' })}
					  onClick={() => { this.toggleTabs('4'); }}
					>
					  User Success
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '5' })}
					  onClick={() => { this.toggleTabs('5'); }}
					>
					  eBay
					</NavLink>
			  </NavItem>
				<NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '6' })}
					  onClick={() => { this.toggleTabs('6'); }}
					>
					  FAQ
					</NavLink>
			  </NavItem>
				<NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '7' })}
					  onClick={() => { this.toggleTabs('7'); }}
					>
					  APP
					</NavLink>
			  </NavItem>
				<NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '8' })}
					  onClick={() => { this.toggleTabs('8'); }}
					>
					  Cancel Reason
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '9' })}
					  onClick={() => { this.toggleTabs('9'); }}
					>
					  Messages
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '10' })}
					  onClick={() => { this.toggleTabs('10'); }}
					>
					  SP-API
					</NavLink>
			  </NavItem>
			  <NavItem>
					<NavLink
						href="#"
					  className={classnames({ active: this.state.activeTab === '11' })}
					  onClick={() => { this.toggleTabs('11'); }}
					>
            AI Analyze
					</NavLink>
			  </NavItem>
			</Nav>
		</Navbar>
		<TabContent activeTab={this.state.activeTab}>
			<TabPane tabId="1">
				<Row>
					<Col xs="12">
						<AdminBatchCreatedChart />
					</Col>
				</Row>
				<Row className="mt-4">
          <Col xs="12">
						<AdminUserSplitShipments />
					</Col>
				</Row>
				<hr />
				<Row className="mt-4">
          <Col xs="6">
						<AdminUsersErrorLogs />
					</Col>
					<Col xs="3">
						<AdminUsersPerMarketplace />
					</Col>
					<Col xs="3">
						<AdminUsersSegmentation />
					</Col>
				</Row>
				<hr />
				<AggregatedData />
			</TabPane>
			<TabPane tabId="2">
				<Row>
					<Col xs="4">
						<UpdateUserPassword />
						<div className="mt-4">
							<AdminUserUpdate />
						</div>
					</Col>
					<Col xs="4">
						<AdminSearchUser />
						<div className="mt-4">
							<AdminUserWhitelist />
						</div>
						<div className="mt-4">
							<AdminUserSmsActivate />
						</div>
					</Col>
					<Col xs="4">
						<AdminUpdateUserToken />
						<div className="mt-4">
							<AdminFBCUserEmails />
						</div>
					</Col>
				</Row>
			</TabPane>
			<TabPane tabId="3">
				{this.state.activeTab === '3' && <AdminRepricerStats />}
			</TabPane>
			<TabPane tabId="23">
				{this.state.activeTab === '23' && <AdminMisc />}
			</TabPane>
			<TabPane tabId="4">
				{this.state.activeTab === '4' && <AdminUserSuccess />}
			</TabPane>
			<TabPane tabId="5">
						{/*
						<Row className="mb-4">
							<Col xs="4">
								<AdminEbayPullInOrders />
							</Col>
							<Col xs="8">
								<AdminEbayCompleteOrder />
							</Col>
							<Col xs="4">
							</Col>
						</Row>
						*/}
				{this.state.activeTab === '5' && <AdminEbay />}
			</TabPane>
			<TabPane tabId="6">
				{this.state.activeTab === '6' && <AdminFAQ />}
			</TabPane>
			<TabPane tabId="7">
				{this.state.activeTab === '7' && <AdminAPP />}
			</TabPane>
			<TabPane tabId="8">
				{this.state.activeTab === '8' && <AdminCancelReason />}
			</TabPane>
			<TabPane tabId="9">
				{this.state.activeTab === '9' && <AdminMessages />}
			</TabPane>
			<TabPane tabId="10">
				{this.state.activeTab === '10' && <AdminSpApi />}
			</TabPane>
			<TabPane tabId="11">
				{this.state.activeTab === '11' && <AdminAIAnalyze />}
			</TabPane>
		</TabContent>
	  </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      isFetchingAggregateData: state.Stats.get('isFetchingAggregateData'),
    };
  },
  {
  }
)(Admin);
