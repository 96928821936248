import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardTitle, CardBody, FormGroup, Label, Col } from "reactstrap";
import Select from 'react-select';
import ebayActions from '../../../../../../redux/ebay/actions';


const {
	ebayUserGetLocation,
	ebayUserGetSettings,
	ebayUserUpdateSettings
} = ebayActions;

class EbayLocations extends Component {

	componentDidMount(){
		this.props.ebayUserGetLocation();
		this.props.ebayUserGetSettings();
	}

	updateLocation = (location) => {
		const data = {
			"merchant_location_name": location ? location.label : "",
			"merchant_location_id": location ? location.value : "",
		}
		this.props.ebayUserUpdateSettings(data);
	}

	render() {
		return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Locations</CardTitle>
					<FormGroup row>
						<Label sm="5">Merchant Location</Label>
						<Col sm="7">
							<Select
								options={this.props.ebay_locations}
								value={
									this.props.ebay_user_settings
										&& this.props.ebay_user_settings.location_select
										?	this.props.ebay_user_settings.location_select.merchant_location
										: null
								}
								onChange={e => this.updateLocation(e)}
								disabled={this.props.ebay_user_settings_updating}
							/>
						</Col>
					</FormGroup>
				</CardBody>
			</Card>
		</React.Fragment>
		);
	}
}

export default connect(state => {
  return {
		ebay_user_settings: state.Ebay.get("ebay_user_settings"),
		ebay_user_settings_updating: state.Ebay.get("ebay_user_settings_updating"),
		ebay_locations: state.Ebay.get("ebay_locations"),
  };
}, {
	ebayUserGetLocation,
	ebayUserGetSettings,
	ebayUserUpdateSettings,
})(EbayLocations);
