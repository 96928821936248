import { Map } from "immutable/dist/immutable";
import actions from "./actions";

const initState = new Map({
  listingDefaults: {
    list_price: null,
    buy_cost: null,
    quantity: null,
    min_price: null,
    max_price: null,
    tax_code: null,
    list_price_rule_type: "",
    list_price_rule_amount: "",
    price_rule_type: false,
    price_rule_direction: "",
    default_list_price: null,
    keepa_date_range: null,
    sku_prefx: null,
    condition: '',
  },
  printerDefaults: {
    printer_name: null,
    manufacturer: null,
    label_type: null,
    label_width: null,
    label_height: null,
    orientation: null,
    print_while_scanning: null,
    print_while_scanning_box_contents: null,
    printer_system: null,
  },
  currentAlert: null,
  updatePasswordRequestStatus: null,
  supplierList: null,
  scoutList: null,
  printerDefaultsAreProcessing: false,
  profileImage: require("../../assets/images/users/generic-profile-image.png"),
  profileBillingDataSet: {
	'user_data': {
		'username': '',
		'businessname': '',
		'created': ''
	},
	'billing_data': []
  },
  is_member: false,
  isUserSettingsUpdating: false,
  isUserSettingsDataUpdating: false,
  labelsData: {
	  "dymo": [],
	  "zebra": [],
	  "rollo": [],
	  "brother": [],
	},
	employees: [],
	employees_loading: false,
	employee_loaded: {},
  mf_ticketing: {},
  mf_ticketing_loading: false,
  sku_templates_saved: [],
  sku_templates_saved_loading: false,
  sales_rank_range: {},
  addRankRange: {},
  all_sales_rank_range:[],
  updated_sales_rank:{},
  deleted_sales_rank:{},
});

export default function settingsReducer(state = initState, action) {
	switch (action.type) {

    case actions.SETTINGS_UPDATE_MF_TICKETING:
      return state
        .set("mf_ticketing_loading", true)
    case actions.SETTINGS_UPDATE_MF_TICKETING_SUCCESS:
      var update_mf_ticketing = state.get("mf_ticketing");
      return state
        .set("mf_ticketing", Object.assign(update_mf_ticketing, action.data.printer_settings))
        .set("mf_ticketing_loading", false)
    case actions.SETTINGS_UPDATE_MF_TICKETING_FAILED:
      return state
        .set("mf_ticketing_loading", false)

    case actions.SETTINGS_GET_MF_TICKETING:
      return state
        .set("mf_ticketing", {})
        .set("mf_ticketing_loading", true)
    case actions.SETTINGS_GET_MF_TICKETING_SUCCESS:
      return state
        .set("mf_ticketing", Object.assign({}, action.data.printer_settings))
        .set("mf_ticketing_loading", false)
    case actions.SETTINGS_GET_MF_TICKETING_FAILED:
      return state
        .set("mf_ticketing", {})
        .set("mf_ticketing_loading", false)
    case actions.SKU_TEMPLATE_USE:
      return state
        .set("sku_templates_saved_loading", true)
    case actions.SKU_TEMPLATE_USE_SUCCESS:
      let use_sku_templates_saved = state.get("sku_templates_saved");
      let use_sku_listing_defaults = state.get("listingDefaults");
      use_sku_templates_saved = use_sku_templates_saved.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        item.active = false;
        return item;
      })
      use_sku_listing_defaults.sku_prefix = action.data.data.sku_prefix;
      use_sku_listing_defaults.template_nickname = action.data.data.name;
      return state
        .set("sku_templates_saved", Object.assign([], use_sku_templates_saved))
        .set("listingDefaults", Object.assign({}, use_sku_listing_defaults))
        .set("sku_templates_saved_loading", false)
    case actions.SKU_TEMPLATE_USE_FAILED:
      return state
        .set("sku_templates_saved_loading", false)

    case actions.SKU_TEMPLATE_DELETE:
      return state
        .set("sku_templates_saved_loading", true)
    case actions.SKU_TEMPLATE_DELETE_SUCCESS:
      let delete_sku_templates_saved = state.get("sku_templates_saved");
      delete_sku_templates_saved = delete_sku_templates_saved.filter(
        item => item.id !== action.data.data.id)
      return state
        .set("sku_templates_saved", Object.assign([], delete_sku_templates_saved))
        .set("sku_templates_saved_loading", false)
    case actions.SKU_TEMPLATE_DELETE_FAILED:
      return state
        .set("sku_templates_saved_loading", false)

    case actions.SKU_TEMPLATE_UPDATE:
      return state
        .set("sku_templates_saved_loading", true)
    case actions.SKU_TEMPLATE_UPDATE_SUCCESS:
      let update_sku_templates_saved = state.get("sku_templates_saved");
      update_sku_templates_saved = update_sku_templates_saved.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      })
      return state
        .set("sku_templates_saved", Object.assign([], update_sku_templates_saved))
        .set("sku_templates_saved_loading", false)
    case actions.SKU_TEMPLATE_UPDATE_FAILED:
      return state
        .set("sku_templates_saved_loading", false)

    case actions.SKU_TEMPLATE_LOAD:
      return state
        .set("sku_templates_saved", [])
        .set("sku_templates_saved_loading", true)
    case actions.SKU_TEMPLATE_LOAD_SUCCESS:
      return state
        .set("sku_templates_saved", Object.assign([], action.data.data))
        .set("sku_templates_saved_loading", false)
    case actions.SKU_TEMPLATE_LOAD_FAILED:
      return state
        .set("sku_templates_saved", [])
        .set("sku_templates_saved_loading", false)

    case actions.EMPLOYEE_UPDATE:
      return state
        .set("employees_loading", true)
    case actions.EMPLOYEE_UPDATE_SUCCESS:
			let employees_update = state.get("employees");
      let employees_update_new = [];
      employees_update.forEach(e => {
				if(e.id === action.data.employee.id){
          if(!action.data.employee.is_user_deleted){
				  	employees_update_new.push(action.data.employee);
          }
				} else {
				  employees_update_new.push(e);
        }
			});
      return state
				.set("employees_loading", false)
				.set("employees", Object.assign([], employees_update_new))
    case actions.EMPLOYEE_UPDATE_FAILED:
      return state
        .set("employees_loading", false)

    case actions.EMPLOYEE_GET:
      return state
        .set("employees_loading", true)
    case actions.EMPLOYEE_GET_SUCCESS:
			let employees_get = state.get("employees");
			let employee_loaded_get = state.get("employee_loaded");
			if(action.data.employee){
				employee_loaded_get = action.data.employee;
			}
			if(action.data.employees){
				employees_get = action.data.employees;
			}
      return state
				.set("employees_loading", false)
				.set("employees", Object.assign([], employees_get))
				.set("employee_loaded", Object.assign({}, employee_loaded_get))
    case actions.EMPLOYEE_GET_FAILED:
      return state
        .set("employees_loading", false)

    case actions.EMPLOYEE_ADD:
      return state
        .set("employees_loading", true)
    case actions.EMPLOYEE_ADD_SUCCESS:
			let employees_add = state.get("employees");
			employees_add.push(action.data.user)
      return state
				.set("employees_loading", false)
				.set("employees", Object.assign([], employees_add))
    case actions.EMPLOYEE_ADD_FAILED:
      return state
        .set("employees_loading", false)

    case actions.FETCH_LISTING_DEFAULTS_SUCCESS:
      return state
        .set("listingDefaults", action.listingDefaults)

    case actions.FETCH_PRINTER_DEFAULTS:
      return state
          .set("printerDefaultsAreProcessing", true)

    case actions.FETCH_PRINTER_DEFAULTS_SUCCESS:
      const printerSettings = action.printerDefaults
      let activePrinterDefaults
      let printerDefaults

      Object.values(printerSettings).forEach((item) => {
        if(item.is_active) {
            activePrinterDefaults = item
        }

        printerDefaults = item
      })

      if(!activePrinterDefaults) {
        activePrinterDefaults = printerDefaults
      }

      return state
        .set("printerSettings", printerSettings)
        .set("printerDefaults", activePrinterDefaults)
        .set("printerDefaultsAreProcessing", false)

    case actions.SAVE_PRINTER_DEFAULTS:
      return state
        .set("printerDefaultsAreProcessing", true)

    case actions.SAVE_PRINTER_DEFAULTS_ERROR:
      return state
        .set("printerDefaultsAreProcessing", false)

    case actions.CLOSE_SETTINGS_ALERT:
        return state.set("currentAlert", null)

    case actions.SHOW_SETTINGS_ALERT:
        return state.set("currentAlert", action.alert)

    case actions.UPDATE_USER_PASSWORD_REQUEST_STATUS:
        return state.set("updatePasswordRequestStatus", action.status)

    case actions.FETCH_SUPPLIER_LIST_SUCCESS:
        return state.set("supplierList", action.supplierList)

    case actions.ADD_SUPPLIER_TO_LIST_SUCCESS:
      let newSupplierList = state.get("supplierList");
      return state.set("supplierList", [...newSupplierList, action.supplierList])

    case actions.DEL_SUPPLIER_FROM_LIST_SUCCESS:
      let supplierList = state.get("supplierList");
      supplierList.forEach((element, index) => {
        if (element.id === action.supplierId) {
          supplierList.splice(index, 1);
          return false;
        }
      })
      return state.set("supplierList", [...supplierList])

    case actions.EDIT_SUPPLIER_FROM_LIST_SUCCESS:{
      return state.update("supplierList", (supplierList) => {
        const supplierIndex = supplierList.findIndex(element => {
          return element.id === action.supplierId;
        })
        if (supplierIndex > -1) {
          supplierList[supplierIndex].supplier_name = action.newSupplierName;
          return [...supplierList];
        }
        return [...supplierList];
      });
    }

    case actions.UPLOAD_PROFILE_IMAGE_SUCCESS:
		  return state.set("profileImage", action.data['url'])

    case actions.GET_PROFILE_IMAGE_SUCCESS:
		  return state.set("profileImage", action.data['url'])

    case actions.GET_PROFILE_BILLING_DATA_SUCCESS:
		  let billing_data = action.data;
		  return state
				.set("is_member", (billing_data.user_data.active === 'true'))
				.set("profileBillingDataSet", billing_data)

    case actions.SETTINGS_UPDATE_USER_DATA:
		  return state
				.set("isUserSettingsUpdating", true)

    case actions.SETTINGS_UPDATE_USER_DATA_SUCCESS:
		  return state
				.set("isUserSettingsUpdating", false)

    case actions.SETTINGS_UPDATE_USER_DATA_ERROR:
		  return state
				.set("isUserSettingsUpdating", false)

    case actions.SETTINGS_UPDATE_USER_SETTINGS:
		  return state
				.set("isUserSettingsDataUpdating", true)

    case actions.SETTINGS_UPDATE_USER_SETTINGS_SUCCESS:
		  return state
				.set("isUserSettingsDataUpdating", false)

    case actions.SETTINGS_UPDATE_USER_SETTINGS_ERROR:
		  return state
				.set("isUserSettingsDataUpdating", false)

    case actions.FETCH_SCOUT_LIST_SUCCESS:
        return state.set("scoutList", action.scoutList)

    case actions.ADD_SCOUT_TO_LIST_SUCCESS:
      let newScoutList = state.get("scoutList");
      return state.set("scoutList", [...newScoutList, action.scoutList])

    case actions.DEL_SCOUT_FROM_LIST_SUCCESS:
      let scoutList = state.get("scoutList");
      scoutList.forEach((element, index) => {
        if (element.id === action.scoutId) {
          scoutList.splice(index, 1);
          return false;
        }
      })
      return state.set("scoutList", [...scoutList])

    case actions.EDIT_SCOUT_FROM_LIST_SUCCESS:{
      return state.update("scoutList", (scoutList) => {
        const scoutIndex = scoutList.findIndex(element => {
          return element.id === action.scoutId;
        })
        if (scoutIndex > -1) {
          scoutList[scoutIndex].scout_name = action.newScoutName;
          return [...scoutList];
        }
        return [...scoutList];
      });
    }

	case actions.LABELS_DATA_GET:
		return state.set("labelsData", Object.assign({}, initState.get("labelsData")));
	case actions.LABELS_DATA_GET_SUCCESS:
		const labels_data = action.data;
		var labels = initState.get("labelsData");
		  labels_data.forEach(item => {
			  item.label = item.name;
			  item.value = item.id;
			  labels[item.printer].push(item);
		  });
		return state.set("labelsData", Object.assign({}, labels));
	case actions.LABELS_DATA_GET_FAILED:
		return state.set("labelsData", Object.assign({}, initState.get("labelsData")));

  case actions.GET_LISTING_SALES_RANK_RANGE_SUCCESS:
    return state
    .set("all_sales_rank_range", Object.assign(action.data.data))
  case actions.ADD_LISTING_SALES_RANK_RANGE_SUCCESS:
    let allSalesRankRange = state.get("all_sales_rank_range");
    allSalesRankRange.push(action.data.data)
	  return state
    .set("addRankRange", Object.assign(action.data.data))
    .set("all_sales_rank_range",Object.assign(allSalesRankRange))

  case actions.UPDATE_LISTING_SALES_RANK_RANGE_SUCCESS:
	  return state
    .set("updated_sales_rank", Object.assign(action.data.data))

  case actions.DELETE_LISTING_SALES_RANK_RANGE_SUCCESS:
    let rangeList = state.get("all_sales_rank_range");
    let deletedRange = action.data.data
    rangeList =  rangeList.filter(range => range.id !== deletedRange.id)
	  return state
    .set("deleted_sales_rank", Object.assign(action.data.data))
    .set("all_sales_rank_range", Object.assign(rangeList))

    default:
      return state
  }
}
