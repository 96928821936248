import React, { Component } from "react";
import moment from 'moment';
import { Col, Row, Button, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import Select from "react-select";
import ReactTable from "react-table";
import ModalShowJson from "../../../../shared/components/ModalShowJson";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { momentDateTimeToLocalFormatConversion } from "../../../../helpers/utility";

const { getAdminSpApiLogs } = adminActions;

const logTypes = [
	{label: "All", value: "all"},
	{label: "Success", value: "success"},
	{label: "Error", value: "error"},
];

const predefinedRanges = {
	'Today': [moment(), moment()],
	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	'This Month': [moment().startOf('month'), moment().endOf('month')],
	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}

const directionOptions = [
  { label: "up", value: "up" },
  { label: "down", value: "down" },
  { label: "equal", value: "equal" },
];

/**
 * Pull in user logs (for active admin)
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminSpApiLogs extends Component {
	state = {
		search_username: "travisrenn",
		json_modal_open: false,
		json_modal_header: "",
		json_modal_data: [],
		startDate: moment().subtract(365, 'days'),
		endDate: moment(),
    call: "",
	}

	generateColumns = () => {
		let tableColumns = [
			{
				id: "endpoint",
				accessor: "endpoint",
				Header: <span>Call</span>,
				headerClassName: "text-left",
				width: 200,
			},
			{
				id: "method",
				accessor: "method",
				Header: <span>Method</span>,
				headerClassName: "text-left",
				width: 200,
			},
			{
				id: "request_data",
				accessor: "request_data",
				Header: <span>Request Data</span>,
				headerClassName: "text-left",
				Cell: props => (
					<Button
						color="primary"
						onClick={e => this.jsonModalOpen(props.original.request_data, "Request Data")}
					>View</Button>
				)
			},
			{
				id: "request_headers",
				accessor: "request_headers",
				Header: <span>Request Headers</span>,
				headerClassName: "text-left",
				Cell: props => (
					<Button
						color="primary"
						onClick={e => this.jsonModalOpen(props.original.request_headers, "Request Headers")}
					>View</Button>
				)
			},
			{
				id: "response_data",
				accessor: "response_data",
				Header: <span>Response Data</span>,
				headerClassName: "text-left",
				Cell: props => (
					<Button
						color="primary"
						onClick={e => this.jsonModalOpen(props.original.response_data, "Response Data")}
					>View</Button>
				)
			},
			{
				id: "response_headers",
				accessor: "response_headers",
				Header: <span>Response Headers</span>,
				headerClassName: "text-left",
				Cell: props => (
					<Button
						color="primary"
						onClick={e => this.jsonModalOpen(props.original.response_headers, "Response Headers")}
					>View</Button>
				)
			},
			{
				id: "created_at",
				accessor: "created_at_str",
				Header: <span>Date</span>,
				headerClassName: "text-left",
				Cell: props => momentDateTimeToLocalFormatConversion(props.original.created_at_str)
			},
		]
		return tableColumns;
	}

	jsonModalOpen = (data, header="") => {
		this.setState({
			json_modal_open: true,
			json_modal_header: header,
			json_modal_data: data,
		});
	}

	jsonModalClose = () => {
		this.setState({
			json_modal_open: false,
		});
	}

	updateInput = (key, value)=> {
		this.setState({[key]: value});
	}

  getNextPage = (state) => {
		var sort = "id";
		var sort_order = "desc";
    if(state.sorted.length > 0) {
      sort = state.sorted[0].id;
      sort_order = state.sorted[0].desc ? 'desc' : 'asc';
    }
		const data = {
			page: parseInt(state.page, 10) + 1,
			page_size: state.pageSize,
			sort: sort,
			sort_order: sort_order,
			search_username: this.state.search_username,
		}
		this.props.getAdminSpApiLogs(data);
  }

	logsSearch = () => {
		let data = {
			search_username: this.state.search_username,
      path: this.state.call ? this.state.call : null,
      start_time: this.state.startDate.toISOString(),
      end_time: this.state.endDate.toISOString(),
		}
		this.props.getAdminSpApiLogs(data);
	}

	toggleModalOpen = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		})
	}

	selectDateRange = (event, picker) => {
		this.setState({
			startDate: picker.startDate,
			endDate: picker.endDate,
		});
	}

  render() {
		let start = this.state.startDate.format('MMM D, YYYY');
		let end = this.state.endDate.format('MMM D, YYYY');
		let label = start + ' - ' + end;
		if (start === end) {
			label = start;
		}

    return (
		<React.Fragment>
			<Row className="align-items-end">
				<Col xs="2">
					<Label>Username</Label>
					<Input
						onChange={e => this.updateInput("search_username", e.target.value)}
						value={this.state.search_username}
					/>
				</Col>
				<Col xs="2">
					<Label>Date Range</Label>
					<DateRangePicker
						startDate={this.state.startDate}
						endDate={this.state.endDate}
						ranges = {predefinedRanges}
						onEvent={this.selectDateRange}
						alwaysShowCalendars
					>
						<input type="text" value={label} className="form-control" />
					</DateRangePicker>
				</Col>
				<Col xs="3">
					<Label>Endpoint</Label>
          <Input
						onChange={e => this.updateInput("call", e.target.value)}
						value={this.state.call}
          />
				</Col>
				<Col xs="1">
					<Button
						color="success"
						onClick={e => this.logsSearch()}
						disabled={!this.state.search_username}
					>SEARCH LOGS</Button>
				</Col>
			</Row>
			<Row>
				{this.props.admin_spapi_complete_order_message}
			</Row>
			<Row className="mt-4">
				<Col xs="12">
					<ReactTable
						columns={this.generateColumns()}
						data={this.props.admin_spapi_logs.data}
						pages={this.props.admin_spapi_logs.pages}
						defaultPageSize={10}
						disabled={this.props.admin_spapi_logs_loading}
						manual
						onFetchData={(state, instance) => { this.getNextPage(state) }}
						getTrProps={(state, rowInfo, column) => {
							if(rowInfo){
								return {
									style: {
										background: rowInfo.original.log_type === "error" ? "#fcdede" : null,
									}
								};
							}
							return {};
						}}
					/>
				</Col>
			</Row>

			<ModalShowJson
				isOpen={this.state.json_modal_open}
				close={this.jsonModalClose}
				header={this.state.json_modal_header}
				data={this.state.json_modal_data}
			/>
		</React.Fragment>
    );
  }
}

export default connect(
  state => {
		return {
			admin_spapi_logs: state.Admin.get("admin_spapi_logs"),
			admin_spapi_logs_loading: state.Admin.get("admin_spapi_logs_loading"),
    };
  },
  {
		getAdminSpApiLogs,
  }
)(AdminSpApiLogs);
