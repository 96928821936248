import React, { Component } from 'react';

import {
  ResponsiveContainer, XAxis, YAxis, CartesianGrid,
  Tooltip, Bar, ComposedChart, Legend, Line
} from 'recharts';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import PeriodTypeSelect from './PeriodTypeSelect';
import chart_config from "../chart_config";
import CustomizedLabel from "../CustomizedLabel";
import getGradient from "../chart_gradients";

class TotalInventoryChart extends Component {
  state = {
    date: null
  }

  componentDidMount() {
    const { chartData } = this.props;
    const chartDate = chartData.date ? moment(chartData.date) : moment();
    this.props.fetchChartData('total_inventory', chartDate.toISOString(), this.props.chartData.period_type);
    this.setState({ date: chartDate })
  }

  handleSelectDate = (date) => {
    this.setState({ date: date });
    this.props.setChartEndDate(date.toISOString(), "total_inventory");
    this.props.fetchChartData("total_inventory", date.toISOString(), this.props.chartData.period_type);
  }

  render() {
    const { chartData, setPeriodType, fetchChartData } = this.props

    return (
      <React.Fragment>
        <div className="mb-4 row" style={{ width: "25rem" }}>
          <div className="col-6">
            <PeriodTypeSelect
              chartData={chartData}
              chartType="total_inventory"
              setPeriodType={setPeriodType}
              fetchChartData={fetchChartData}
            />
          </div>
          <div className="col-6">
            <DatePicker selected={this.state.date} onChange={date => this.handleSelectDate(date)} />
          </div>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={250}
        >
          <ComposedChart
            data={chartData.data}
            margin={{
              top: 35, left: 30
            }}
          >
            <defs>
              {getGradient("gradientGreenInv", "green")}
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={chart_config.cartesianStrokeColor}
            />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
            />
            <Tooltip
              labelFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
              cursor={false}
            />
            <Legend />
            <Bar
              name="Total Inventory"
              dataKey="total_inventory"
              fill="url(#gradientGreenInv)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel type="decimal" />}
            />
            <Line
              name="Total Inventory"
              type="monotone"
              dataKey="total_inventory"
              stroke={chart_config.greenColor}
              strokeOpacity={.3}
              strokeWidth={chart_config.strokeWidth}
              legendType="none"
              dot={chart_config.dot}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

TotalInventoryChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  setPeriodType: PropTypes.func.isRequired,
  setChartEndDate: PropTypes.func.isRequired,
  fetchChartData: PropTypes.func.isRequired
}

export default TotalInventoryChart;
