import React, { Component } from 'react';
import { FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, FormText, Col } from 'reactstrap';
import CollapsibleFormGroup from './CollapsibleFormGroup';

class BestOffer extends Component {
  state = {
  }

  editFloat = (key, value) => {
    if(value){
      value = parseFloat(value)
    }
    this.props.editData(key, value);
  }

	updateEbayBestOffer = (field, value, base) => {
		const { ebay_direct_listing_item } = this.props;
    let bo = {
      "best_offer_enabled": false,
      "best_offer_auto_accept": null,
      "best_offer_auto_decline": null,
    };
    if(ebay_direct_listing_item.best_offer){
	    bo = Object.assign({}, ebay_direct_listing_item.best_offer);
    }
    bo[field] = value;
    if(field === 'best_offer_auto_accept' && value){
      bo['best_offer_auto_decline'] = parseFloat(value).toFixed(2) - 0.01;
    }
		this.props.editData('best_offer', bo, "object");
	}

  render() {
    const {
      collapse,
      toggleCollapse,
      ebay_direct_listing_item,
    } = this.props;

    return (
      <CollapsibleFormGroup
        title="Best Offer"
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      >
        <FormGroup check className="custom-check checkbox">
          <Label check>
            <Input
              type="checkbox"
              checked={ebay_direct_listing_item.best_offer
                && ebay_direct_listing_item.best_offer.best_offer_enabled
                ? true
                : false}
                onChange={e => this.updateEbayBestOffer("best_offer_enabled", ebay_direct_listing_item.best_offer ? !ebay_direct_listing_item.best_offer.best_offer_enabled : true)}
            /> <span>Enable best offer</span>
          </Label>
        </FormGroup>
        <FormGroup row className="d-flex">
          <Col xl={10} className="d-flex">
            <div className="d-flex gap-5">
              <div>
                <Label>Minimum Price Auto Accept</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min="0"
                    step="0.01"
                    value={
                      ebay_direct_listing_item.best_offer
                        && ebay_direct_listing_item.best_offer.best_offer_auto_accept
                        ? ebay_direct_listing_item.best_offer.best_offer_auto_accept
                        : ""
                    }
                    onChange={e => this.updateEbayBestOffer("best_offer_auto_accept", parseFloat(e.target.value))}
                    disabled={
                      ebay_direct_listing_item.best_offer
                        && ebay_direct_listing_item.best_offer.best_offer_enabled
                        ? false
                        : true
                    }
                  />
                </InputGroup>
                <FormText>This is the price at which Best Offers are automatically accepted. If a buyer submits a Best Offer that is equal to or above this value, the offer is automatically accepted on behalf of the seller.</FormText>
              </div>
              <div className="ml-2">
                <Label>Maximum Price Auto Decline</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min="0"
                    step="0.01"
                    value={
                      ebay_direct_listing_item.best_offer
                        && ebay_direct_listing_item.best_offer.best_offer_auto_decline
                        ? ebay_direct_listing_item.best_offer.best_offer_auto_decline
                        : ""
                    }
                    onChange={e => this.updateEbayBestOffer("best_offer_auto_decline", parseFloat(e.target.value))}
                    disabled={
                      ebay_direct_listing_item.best_offer
                        && ebay_direct_listing_item.best_offer.best_offer_enabled
                        ? false
                        : true
                    }
                  />
                </InputGroup>
                <FormText>This is the price at which Best Offers are automatically declined. If a buyer submits a Best Offer that is equal to or below this value, the offer is automatically declined on behalf of the seller.</FormText>
              </div>
            </div>
          </Col>
        </FormGroup>
      </CollapsibleFormGroup>
    )
  }
}

export default BestOffer;
