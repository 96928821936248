import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  Card, CardBody, Table
} from 'reactstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { digitСonversion } from '../../../../../../../helpers/utility';
import IconHand from 'react-icons/lib/fa/hand-pointer-o';

const StyledPopover = styled(Popover)`
  min-width: 300px;

  .arrow {
    margin: 0 -0.76rem;
  }

  .table {
    td {
      padding-top: 8px;
      padding-bottom: 8px;

      &:last-child {
        text-align: right;
      }
    }

    tr:first-child td {
      border-top: 0;
    }
`;

class CardBreakdownPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false
    }
  }

  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

	formatNumber = (value) => {
		if(this.props.internationalization_config && this.props.internationalization_config.currency_code){
	    return digitСonversion(value, "currency", this.props.internationalization_config.currency_code);
		} else {
			return "$0.00";
		}
  }

  render() {
    const {
      data,
      type,
    } = this.props;

    let title, value, popoverContent;

    const GrossIncome = () => (
      <Table striped borderless>
        <tbody>
          <tr>
            <td>Total FBA Sales</td>
            <td className="text-success">{this.formatNumber(data.fbaOrderRevenue)}</td>
          </tr>
          <tr>
            <td className="text-success">Total MF Sales</td>
            <td>{this.formatNumber(data.mfOrderRevenue)}</td>
          </tr>
          <tr>
            <td className="text-success">eBay Sales</td>
            <td>{this.formatNumber(data.ebayOrders)}</td>
          </tr>
          <tr>
            <td>Total Refund</td>
            <td className="text-danger">{this.formatNumber(data.totalRefundCost)}</td>
          </tr>
          <tr>
            <td>Total Inventory Credits</td>
            <td className="text-danger">{this.formatNumber(data.totalInventoryCredits)}</td>
          </tr>
          <tr>
            <td>Total Misc. Income</td>
            <td className="text-success">{this.formatNumber(data.totalMiscellaneous)}</td>
          </tr>
        </tbody>
      </Table>
    );

    const Cogs = () => (
      <Table striped borderless>
        <tbody>
          <tr>
            <td>Cost of Good Delivered</td>
            <td>{this.formatNumber(data.costOfGoodsDelivered)}</td>
          </tr>
          <tr>
            <td>Cost of Good Adjusted</td>
            <td>{this.formatNumber(data.costOfGoodsAdjusted)}</td>
          </tr>
          <tr>
            <td>Cost of Good Refunded</td>
            <td>{this.formatNumber(data.costOfGoodsReturned)}</td>
          </tr>
        </tbody>
      </Table>
    );

    const Expenses = () => (
      <Table striped borderless>
        <tbody>
          <tr>
            <td>Total Transaction Fees</td>
            <td className="text-danger">{this.formatNumber(data.totalTransactionFees)}</td>
          </tr>
          <tr>
            <td>Total Selling Fees</td>
            <td className="text-danger">{this.formatNumber(data.totalSellingFees)}</td>
          </tr>
          <tr>
            <td>Total Services Fees</td>
            <td className="text-danger">{this.formatNumber(data.totalServiceFees)}</td>
          </tr>
          <tr>
            <td>Total Shipping Fees</td>
            <td className="text-danger">{this.formatNumber(data.totalShippingFees)}</td>
          </tr>
          <tr>
            <td>Total Inventory Fees</td>
            <td className="text-danger">{this.formatNumber(data.totalInventoryFees)}</td>
          </tr>
          <tr>
            <td>Guarantee Claims Total</td>
            <td>{this.formatNumber(data.guaranteeClaimsTotal)}</td>
          </tr>
          <tr>
            <td>Chargebacks Total</td>
            <td>{this.formatNumber(data.chargebackTotal)}</td>
          </tr>
          <tr>
            <td>Misc Expenses</td>
            <td className="text-danger">{data.miscExpenses ? this.formatNumber(data.miscExpenses) : this.formatNumber(data.miscExpenses)}</td>
          </tr>
          <tr>
            <td>eBay Expenses</td>
            <td className="text-danger">{data.ebayExpenses ? this.formatNumber(data.ebayExpenses) : this.formatNumber(data.ebayExpenses)}</td>
          </tr>
        </tbody>
      </Table>
    );

    if(type === 'grossIncome' ) {
      title = 'Income';
      value = this.formatNumber(data.grossIncome + data.ebayOrders);
      popoverContent = <GrossIncome />;
    } else if(type === 'cogs') {
      title = 'COGS';
      value = this.formatNumber(data.cogs);
      popoverContent = <Cogs />;
    } else if(type === 'expenses') {
      title = 'Fees & Expenses';
      value = this.formatNumber(data.totalExpensesWithoutCogs + data.miscExpenses + data.ebayExpenses);
      popoverContent = <Expenses />;
    }

    return (
      <Fragment>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <StyledPopover title={`${title} Breakdown`} id="breakdown-popover">
              {popoverContent}
            </StyledPopover>
          }
        >
          <Card className="card-profit-breakdown">
            <CardBody>
              <span className={`value ${(type === 'cogs' || type === 'expenses') ? 'text-danger' : 'text-success'}`}>{value}</span>
              <h6 className="title">{title}</h6>
              <IconHand className="icon" />
            </CardBody>
          </Card>
        </OverlayTrigger>

      </Fragment>
    );
  }

}

CardBreakdownPopover.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
  }
)(CardBreakdownPopover);
