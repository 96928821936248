import { convertInToPixels } from "../../../../helpers/utility";
import { transformDataForPrinting } from "../../../../helpers/batch/utility";
import { getDestination, getAdditionalInfo } from "../../../../helpers/print_service/utility";
import JsBarcode from "jsbarcode";
import { DYMO_30270, DYMO_30334 } from "../../../../helpers/print_service/label_name_constants";

export const getLabelContent = (listing, labelConf) => {
  let container = document.createElement("div");
  const transformListing = transformDataForPrinting(listing);
  const isMac = !!navigator.platform.toUpperCase().match("MAC");
  const isWIN = !!navigator.platform.toUpperCase().match("WIN");

  if(isWIN || isMac) {
    var labelWidth = labelConf && labelConf.width ? Number(labelConf.width) : 2;
    var labelHeight = labelConf && labelConf.height ? Number(labelConf.height) : 1;
    var transformScale = labelConf.printerDriverConfig ? labelConf.printerDriverConfig.transform_scale : "1";
    
    var labelFontSizeCoefficient = labelConf && labelConf.fontSizeCoefficient
    ? (((1 - Number(labelConf.fontSizeCoefficient)) * 2) + Number(labelConf.fontSizeCoefficient)) : 1;
    var labelBarCodeType = labelConf && labelConf.barCodeType ? labelConf.barCodeType : "CODE39";
    var labelBarcodeWidthCoof = labelBarCodeType === "CODE128" ? 150 : 200;
    var labelBarCodeFontSize = (labelWidth * 72) / (6 * labelFontSizeCoefficient);
    var labelBarcodeHeight = convertInToPixels(labelHeight / 3);
    var labelBarcodeWidth = Math.trunc(convertInToPixels(labelWidth) / labelBarcodeWidthCoof) * 1.4  || 1;
    var labelType = labelConf && labelConf.labelType;
  } else {
    labelWidth = labelConf && labelConf.width ? Number(labelConf.width) : 4;
    labelHeight = labelConf && labelConf.height ? Number(labelConf.height) : 1.5;
    transformScale = labelConf.printerDriverConfig ? labelConf.printerDriverConfig.transform_scale : "1";
    labelFontSizeCoefficient = labelConf && labelConf.fontSizeCoefficient
      ? (((1 - Number(labelConf.fontSizeCoefficient)) * 2) + Number(labelConf.fontSizeCoefficient)) : 1;
    labelBarCodeType = labelConf && labelConf.barCodeType ? labelConf.barCodeType : "CODE39";
    labelBarcodeWidthCoof = labelBarCodeType === "CODE128" ? 150 : 200;
    labelBarCodeFontSize = (labelHeight * 72) / (6 * labelFontSizeCoefficient);
    labelBarcodeHeight = convertInToPixels(labelHeight / 5);
    labelBarcodeWidth = Math.trunc(convertInToPixels(labelWidth) / labelBarcodeWidthCoof) || 1;
    labelType = labelConf && labelConf.labelType;
  }

  let leftMarginCoefficient = 5;
  var leftMargin = convertInToPixels(labelWidth / (leftMarginCoefficient * labelFontSizeCoefficient));

  if (labelType === DYMO_30270) {
    leftMargin = labelBarCodeType === "CODE39"
      ? leftMargin - (8 * transformListing.fnsku.length)
      : leftMargin - 10 - (3 * transformListing.fnsku.length)
  }

  const transform = labelConf.orientation === "portrait" ? transformScale + ",1" : "1," + transformScale;

  const sku = transformListing.hasOwnProperty('asin') ? transformListing.asin : transformListing.fnsku 

  const labelBody = transformListing.fnsku ? 
    `<div class="label-container">
      <div class="label-fnsku">
        ${textToBarcode(sku, transformListing.fnsku, labelBarCodeType, labelBarCodeFontSize, labelBarcodeHeight, labelBarcodeWidth, leftMargin)}
        <div class="rotate-text">
          ${transformListing.fnsku}
        </div>
      </div>
      <div class="label-name-container">
        <div class="label-name">
          ${transformListing.name}
        </div>
      </div>
    </div>` : 
    `<div class="label-empty-container">
    </div>`
    ;

  container = `
    <html>
      <head>
        <style>
          .label-body {
            margin: 0;
          }
          .label-container {
            width: ${convertInToPixels(labelWidth)}px;
            height: ${convertInToPixels(labelHeight)}px;
            position: relative;
            display: flex;
            flex-direction: column;
            transform-origin: ${labelConf.orientation === "portrait" ? "left" : "bottom"};
            transform: scale(${transform});
            -webkit-transform: scale(${transform});
            -moz-transform: scale(${transform});
            -o-transform: scale(${transform});
            -ms-transform: scale(${transform});
          }
          .label-empty-container {
            display: block;
            width: ${convertInToPixels(labelWidth)}px;;
            height: ${convertInToPixels(labelHeight/2)}px;
            padding: 5px;
            border: 1px solid white;    
            background-color: white;
          }
          .label-name-container {
            height: ${convertInToPixels(labelHeight) - (labelBarcodeHeight + 5 + labelBarCodeFontSize) - (convertInToPixels(labelHeight / (10 * labelFontSizeCoefficient)) + 6)}px;
            display: flex;
            align-items: start;
          }
          .label-fnsku {
          display: flex;
          align-items: center;
            border-top: 5px solid transparent;
            font-family: Arial, sans-serif;
            font-size: ${convertInToPixels(labelHeight / (40 * labelFontSizeCoefficient))}px;
            text-align: center;
          }
          .label-name {
            font-family: monospace;
            font-size: ${convertInToPixels(labelWidth / (15 * labelFontSizeCoefficient))}px;
            font-weight: bold;
            text-align: center;
            display: -webkit-box;
            line-height: 1.5em;
            max-height: 3em;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-left: 6mm;
            margin-right: 6mm;
          }
          .label-additionalInfo {
            border-top: 1px solid transparent;
            font-weight: bold;
            font-family: Arial, sans-serif;
            font-size: ${convertInToPixels(labelHeight / (20 * labelFontSizeCoefficient))}px;
            line-height: ${convertInToPixels(labelHeight / (20 * labelFontSizeCoefficient))}px;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 5px;
          }
          .rotate-text {
            font-size: ${convertInToPixels(((labelHeight / 14) + (labelWidth / 14)) / (2 * labelFontSizeCoefficient))}px;
            font-weight: bold;
            width: ${convertInToPixels(labelWidth / (6 * labelFontSizeCoefficient))}px;
            height: ${convertInToPixels(labelWidth / (8 * labelFontSizeCoefficient))}px;
            white-space: nowrap;
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
          } 
        </style>
      </head>
      <body class="label-body">
        ${labelBody}
      </body>
    </html>`;
  return container;
};

export const getLabelContentZPL = (listing, labelConf) => {
  const transformListing = transformDataForPrinting(listing);
  const density = labelConf.density;
  const orientation = labelConf.orientation ? labelConf.orientation : "portrait";
  const widthInDots =  labelConf.height && density ? Math.round(Number(labelConf.width) * Number(density)) : 0;
  const heightInDots = labelConf.height && density ? Math.round(Number(labelConf.height) * Number(density)) : 0;
  const barcodeType = labelConf.barCodeType ? labelConf.barCodeType : "CODE39";
  const fontHeight = Math.round((widthInDots / 10) * Number(labelConf.fontSizeCoefficient));
  const fontWidth = Math.round((fontHeight / 5) * 4.5);
  const barcodeTopMargin = Math.round(heightInDots / 15 + 5);
  const name = transformListing.name && transformListing.name.length > 120 ? `${transformListing.name.slice(0, 120)}...` : transformListing.name;

  let barcodeSystemSymbolsWidth;
  let barcodeCommandType;
  let barcodeInfo;
  let barcodeWidth;
  let barcodeSymbolWidth;
  let barcodeSymbolCount;
  let moduleWidth;
  let labelOrientation = 'N';

  if (orientation === 'landscape') {
    labelOrientation = 'B'
  }

  switch(barcodeType) {
    case "CODE39":
      barcodeSystemSymbolsWidth = 40;
      barcodeCommandType = "^B3";
      barcodeInfo = `${barcodeCommandType}${labelOrientation},N,${Math.round(heightInDots / 6)},Y,Y,N\n`;
      barcodeSymbolWidth = 11;
      barcodeSymbolCount = transformListing.fnsku.length;
      moduleWidth = Math.trunc(((widthInDots - fontHeight) / (barcodeSymbolCount + 4) ) / (barcodeSymbolWidth));
      barcodeWidth = String(transformListing.fnsku)
        ? ((barcodeSymbolCount * barcodeSymbolWidth) + barcodeSystemSymbolsWidth) * moduleWidth - ((7 - barcodeSymbolCount) * moduleWidth * 2.5)
        : 0;
      break;

    case "CODE128":
      barcodeSystemSymbolsWidth = 36;
      barcodeCommandType = "^BC";
      barcodeInfo = `${barcodeCommandType}${labelOrientation},${Math.round(heightInDots / 6)},Y,Y,N\n`;
      barcodeSymbolWidth = 11;
      barcodeSymbolCount = transformListing.fnsku.length;
      moduleWidth = Math.trunc(((widthInDots - fontHeight) / (barcodeSymbolCount + 4) ) / (barcodeSymbolWidth));
      barcodeWidth = String(transformListing.fnsku)
        ? ((barcodeSymbolCount * barcodeSymbolWidth) + barcodeSystemSymbolsWidth) * moduleWidth
        : 0;
      break;

    default:
      barcodeSystemSymbolsWidth = 40;
      barcodeCommandType = "^B3";
      barcodeInfo = `${barcodeCommandType}${labelOrientation},N,${Math.round(heightInDots / 6)},Y,Y,N\n`;
      barcodeSymbolWidth = 11;
      barcodeSymbolCount = transformListing.fnsku.length;
      moduleWidth = Math.trunc(((widthInDots - fontHeight) / (barcodeSymbolCount + 4) ) / (barcodeSymbolWidth));
      barcodeWidth = String(transformListing.fnsku)
        ? ((barcodeSymbolCount * barcodeSymbolWidth) + barcodeSystemSymbolsWidth) * moduleWidth - ((7 - barcodeSymbolCount) * moduleWidth * 2.5)
        : 0;
      break;
  }

  let labelZPL = [];
  const margin = Math.round(((widthInDots - barcodeWidth) / 2));

  switch(orientation) {
    case "portrait":
      labelZPL = [
        '^XA\n',
        '^PR3,3,3\n',
        '^FS\n',
        `^PW${widthInDots}\n`,

        `^FO${margin},${barcodeTopMargin}\n`,
        `^BY${moduleWidth},2\n`,
        barcodeInfo,
        `^FD${transformListing.fnsku}\n`,
        `^FS\n`,

        `^FO${Math.round(widthInDots - (((margin + fontHeight) * 1.2) / 2))},${Math.round(barcodeTopMargin * 0.8)}\n`,
        `^FB${Math.round(heightInDots / 5)},1,,C\n`,
        `^A0R,${fontHeight * 1.2},${fontWidth * 1.2}\n`,
        `^FD${transformListing.fnsku}\n`,
        '^FS\n',

        `^FO0,${Math.round(heightInDots / 6 ) + barcodeTopMargin + 10}\n`,
        `^FB${widthInDots},3,,C\n`,
        `^A0N,${fontHeight},${fontWidth}\n`,
        `^FD${name}\n`,
        '^FS\n',

        '^XZ\n',
      ];
      break;

    case "landscape":
      labelZPL = [
        '^XA\n',
        '^LT5\n',
        '^PR1,2,2\n',
        `^PW${heightInDots}\n`,

        '^FWB\n',
        `^FO${barcodeTopMargin},${margin}\n`,
        `^BY${moduleWidth},2,${Math.round(heightInDots / 6)}\n`,
        `${barcodeInfo}`,
        `^FD${transformListing.fnsku}\n`,
        '^FS\n',

        `^FO${Math.round(barcodeTopMargin * 0.8)},${Math.round(((margin - fontHeight) * 1.2) / 2)}\n`,
        `^FB${Math.round(heightInDots / 5)},1,,C\n`,
        `^A0N,${fontHeight * 1.2},${fontWidth * 1.2}\n`,
        `^FD${transformListing.fnsku}\n`,
        '^FS\n',

        `^FO${Math.round((heightInDots / 6 ) + barcodeTopMargin + 10)},0\n`,
        `^FB${widthInDots},3,,C\n`,
        `^A0,${fontHeight},${fontWidth}\n`,
        `^FD${name}\n`,
        '^FS\n',
        '^XZ\n',
      ];
      break;

    default:
      labelZPL = [
        '^XA\n',
        '^PR3,3,3\n',
        '^FS\n',
        `^PW${widthInDots}\n`,

        `^FO${margin},${barcodeTopMargin}\n`,
        `^BY${moduleWidth},2\n`,
        barcodeInfo,
        `^FD${transformListing.fnsku}\n`,
        `^FS\n`,

        `^FO${Math.round(widthInDots - (((margin + fontHeight) * 1.2) / 2))},${barcodeTopMargin}\n`,
        `^FB${Math.round(heightInDots / 6)},1,,C\n`,
        `^A0R,${fontHeight * 1.2},${fontWidth * 1.2}\n`,
        `^FD${transformListing.fnsku}\n`,
        '^FS\n',

        `^FO0,${Math.round(heightInDots / 6 ) + barcodeTopMargin + 10}\n`,
        `^FB${widthInDots},3,,C\n`,
        `^A0N,${fontHeight},${fontWidth}\n`,
        `^FD${name}\n`,
        '^FS\n',

        '^XZ\n',
      ];
      break;
  }

  return labelZPL;
};

export const getLabelContentEPL = (listing, labelConf) => {
  const transformListing = transformDataForPrinting(listing);
  const density = labelConf.density;
  const orientation = labelConf.orientation ? labelConf.orientation : "portrait";
  const widthInPt = labelConf.width && labelConf.height ? (orientation === 'portrait' ? labelConf.height : labelConf.width) * 72 : 0;
  const widthInDots = labelConf.width && labelConf.height && density ? Math.round(Number(orientation === 'portrait' ? labelConf.height : labelConf.width) * Number(density)) : 0;
  const heightInDots = labelConf.width && labelConf.height && density ? Math.round(Number(orientation === 'portrait' ? labelConf.width : labelConf.height) * Number(density)) : 0;
  const barcodeType = labelConf.barCodeType ? labelConf.barCodeType : "CODE39";

  let barcodeCommandType = 3;
	//let barcodeWidth = 0;
  let barcodeSymbolWidth = 0;
  let barcodeSymbolCount = 0;
  let barcodeWidthCoof = 0;

  switch(barcodeType) {
    case "CODE128":
      const barcodeSystemSymbolsWidth = 18;
      barcodeCommandType = 1;
      barcodeSymbolWidth = 11;
      barcodeSymbolCount = transformListing.fnsku.length;
			barcodeWidthCoof = Math.trunc(widthInDots / (((barcodeSymbolCount + 2) * barcodeSymbolWidth) + (barcodeSystemSymbolsWidth * 2)));
			/*
      barcodeWidth = String(transformListing.fnsku)
        ? ((barcodeSymbolCount * barcodeSymbolWidth) * barcodeWidthCoof) + ((barcodeSystemSymbolsWidth * 2) * barcodeWidthCoof)
				: 0;
			*/
      break;

    default:
      barcodeCommandType = 3;
      barcodeSymbolWidth = 18;
      barcodeSymbolCount = transformListing.fnsku.length + 2;
      barcodeWidthCoof = Math.trunc((widthInDots / barcodeSymbolCount + 2) / barcodeSymbolWidth);
			//barcodeWidth = String(transformListing.fnsku) ? (barcodeSymbolCount * barcodeSymbolWidth) : 0;
      break;
  }

  const additionalInfo = getAdditionalInfo([
    transformListing.condition,
    getDestination(transformListing.fulfillmentCenters),
    transformListing.expDate ? `EXP : ${transformListing.expDate}` : ""
  ]);

  const barcodeHeight = Math.round(heightInDots / 5);

  const labelEPL = [
    `\n`,
    `N\n`,
    `q${widthInDots}\n`,
    `A${Math.round(((widthInDots - ((transformListing.fnsku.length * 4) * (density / 72))) / 2))},10,0,2,1,1,N,"${transformListing.fnsku}"\n`,
    `B${Math.round(((widthInDots ) / 4) - 10)},40,0,${barcodeCommandType},1,${Math.floor(widthInDots / 70)},${barcodeHeight},N,"${transformListing.fnsku}"\n`,
    `A${Math.round(((widthInDots)))},45,1,2,1,1,N,"${transformListing.fnsku}"\n`,
  ];

  const nameTextLen = transformListing.name.length;
  const maxNameTextLen = Math.floor((widthInPt - 10) / 4);
  const nameTextY = Math.round((heightInDots / 5) + (10 * barcodeWidthCoof) + 20) + 20;

  if(nameTextLen > maxNameTextLen) {
    let cutoffIdx = 0;

    for(let i = 0; i < nameTextLen; i++) {
      if(transformListing.name.charAt(i) === ' ') {
        if(i > maxNameTextLen) {
          break;
        }

        cutoffIdx = i;
      }
    }

    labelEPL.push(`A10,${nameTextY},0,1,1,1,N,"${transformListing.name.substring(0, cutoffIdx)}"\n`);
    labelEPL.push(`A10,${nameTextY + 20},0,1,1,1,N,"${transformListing.name.substr(cutoffIdx + 1, maxNameTextLen)}"\n`);
  }
  else {
    labelEPL.push(`A10,${nameTextY},0,1,1,1,N,"${transformListing.name}"\n`);
  }

  labelEPL.push(
    `A10,${heightInDots - 40},0,1,1,1,N,"${additionalInfo}"\n`,
    `P1,1`
  );

  return labelEPL;
};

const textToBarcode = (text, fnsku, barCodeType, fontSize, barcodeHeight, barcodeWidth, leftMargin = 0) => {
  let canvas = document.createElementNS("http://www.w3.org/2000/svg","svg");

  JsBarcode(canvas, text, {
    format: barCodeType || "CODE39",
    width: barcodeWidth,
    height: barcodeHeight,
    fontSize: fontSize,
    text: fnsku,
    textPosition: "top",
    fontOptions: "bold",
    textMargin: 1,
    margin: 1,
    marginLeft: leftMargin,
  });

  return canvas.outerHTML;
};
