import React, { Component } from 'react';
import { string, number, bool } from 'prop-types';
import TooltipAtCustomElement from "../../../../shared/components/TooltipAtCustomElement";
import { digitСonversion } from "../../../../helpers/utility";
import './style.css';

const IconTooltip = () => <span className="icon icon-help-circle"></span>

/**
 * Metric item component
 *
 * @version 1.0.0
 * @author [Tedi Kurniadi]
 */
class MetricItem extends Component {
  static propTypes = {
    metricKey: string.isRequired,
    numberType: string.isRequired,
    metricName: string.isRequired,
    value: number,
    tooltipText: string.isRequired,
    currencyCode: string.isRequired,
    isPercentage: bool,
  };

  render() {
    const {
      metricKey,
      metricName,
      value,
      numberType,
      currencyCode,
      className,
      tooltipText,
      isPercentage,
    } = this.props;

    return (
      <div className={`metric-item ${className}`}>
        <span className="title">
          {metricName}
          <span className="help" data-toggle="tooltip" data-title={tooltipText}>
            <TooltipAtCustomElement tooltipId={metricKey} tooltipText={tooltipText} CustomElement={IconTooltip}/>
          </span>
        </span>
        <span className="value">{!!value || value === 0 ? digitСonversion(value, numberType, currencyCode) : "-"} {isPercentage ? '%': ""}</span>
      </div>
    );
  }
}

export default MetricItem;
