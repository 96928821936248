import React, { Component } from 'react'
import Select from 'react-select'

export const options = [
  {value: 'SELLER', label: 'Seller'},
  {value: 'AMAZON', label: 'Amazon'},
  {value: 'NONE', label: 'None'},
]


class SelectWhoLabelsPreps extends Component {
  render() {
    const { onChange, value } = this.props

    return (
      <Select
        options={options}
        onChange={selected => onChange(selected)}
        value={options.find(item => item.value === value)}
      />
    )
  }
}

export default SelectWhoLabelsPreps
