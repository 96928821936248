import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardTitle, CardBody, FormGroup, Label, Col } from "reactstrap";
import Select from 'react-select';
import ebayActions from '../../../../../../redux/ebay/actions';


const {
	ebayUserGetPolicy,
	ebayUserGetSettings,
	ebayUserUpdateSettings
} = ebayActions;

class EbayPolicies extends Component {

	componentDidMount(){
		this.props.ebayUserGetPolicy({policy: "all"});
		this.props.ebayUserGetSettings();
	}

	updatePolicy = (type, policy) => {
		let data = {}
		if(type === 'fulfillment_policy'){
			data = {
				"fulfillment_policy_name": policy ? policy.label : "",
				"fulfillment_policy_id": policy ? policy.value : "",
			}
		} else if (type === 'payment_policy'){
			data = {
				"payment_policy_name": policy ? policy.label : "",
				"payment_policy_id": policy ? policy.value : "",
			}
		} else {
			data = {
				"return_policy_name": policy ? policy.label : "",
				"return_policy_id": policy ? policy.value : "",
			}
		}
		this.props.ebayUserUpdateSettings(data);
	}

	render() {
		return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Policies</CardTitle>
					<FormGroup row>
						<Label sm="3">Fulfillment</Label>
						<Col sm="8">
							<Select
								options={this.props.ebay_fulfillment_policies}
								value={
									this.props.ebay_user_settings
										&& this.props.ebay_user_settings.policies_select
										?	this.props.ebay_user_settings.policies_select.fulfillment_policy
										: null
								}
								onChange={e => this.updatePolicy('fulfillment_policy', e)}
								disabled={this.props.ebay_user_settings_updating}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm="3">Payment</Label>
						<Col sm="8">
							<Select
								options={this.props.ebay_payment_policies}
								value={
									this.props.ebay_user_settings
										&& this.props.ebay_user_settings.policies_select
										?	this.props.ebay_user_settings.policies_select.payment_policy
										: null
								}
								onChange={e => this.updatePolicy('payment_policy', e)}
								disabled={this.props.ebay_user_settings_updating}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm="3">Return</Label>
						<Col sm="8">
							<Select
								options={this.props.ebay_return_policies}
								value={
									this.props.ebay_user_settings
										&& this.props.ebay_user_settings.policies_select
										?	this.props.ebay_user_settings.policies_select.return_policy
										: null
								}
								onChange={e => this.updatePolicy('return_policy', e)}
								disabled={this.props.ebay_user_settings_updating}
							/>
						</Col>
					</FormGroup>
				</CardBody>
			</Card>
		</React.Fragment>
		);
	}
}

export default connect(state => {
  return {
    ebay_fulfillment_policies: state.Ebay.get("ebay_fulfillment_policies"),
    ebay_payment_policies: state.Ebay.get("ebay_payment_policies"),
		ebay_return_policies: state.Ebay.get("ebay_return_policies"),
		ebay_user_settings: state.Ebay.get("ebay_user_settings"),
		ebay_user_settings_updating: state.Ebay.get("ebay_user_settings_updating"),
  };
}, {
	ebayUserGetPolicy,
	ebayUserGetSettings,
	ebayUserUpdateSettings,
})(EbayPolicies);
