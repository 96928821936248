import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
	TabPane,
	Badge,
} from "reactstrap";
import { withRouter } from "react-router";
import classnames from "classnames";
import FaSpinner from "react-icons/lib/fa/spinner";
import ALPrintSettingPage from "./al_print";
import NativePrintSettingPage from "./native_print";
import SinglePrintSettingPage from "./single_print";
import MFTicketing from "./mf_ticketing";
import settingsActions from "../../../../redux/settings/actions";
import printerActions from "../../../../redux/print_service/actions";
import { native_print, al_print, single_print, mf_ticketing } from "../../../../helpers/print_service/print_systems";

const {
  fetchPrinterDefaults,
  savePrinterDefaults,
} = settingsActions;

const {
  print,
  setALPrintSystemEditMode,
  setPrinterServiceIsProcessing
} = printerActions;

class PrinterDefaultsQz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
    };
  }
  componentDidMount() {
    this.props.fetchPrinterDefaults();
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const tab = urlParams.get('subtab')
    this.setState({ activeTab: tab });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let activeTab = prevState.activeTab;

    if (nextProps.printerDefaults
        && nextProps.printerDefaults.hasOwnProperty("printer_system")
        && nextProps.printerDefaults.printer_system
        && !prevState.activeTab) {
          activeTab = nextProps.printerDefaults.printer_system;
    }
    return {
      activeTab
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if ( tab === activeTab) return;
    this.setState({
      activeTab: tab
    });

    const { history } = this.props;
    history.push({ search: `&tab=3&subtab=${tab}`});
    if (tab === native_print) {
      window.location.reload();
    }
  }
  loadingSpinner = () => {
    return (
      <div className="spinner-container"><span><FaSpinner className="fa-spin" size="50px"/>&nbsp;</span></div>
    );
  }
  render() {
    const {
      savePrinterDefaults,
      print,
      setALPrintSystemEditMode,
      setPrinterServiceIsProcessing,

      printerSettings,
      printerDefaults,
      qzAvaliblePrinters,
      printerDefaultsAreProcessing,
      qzTrayConnectionStatus,
      npPrinterConnectionStatus,
      printerServiceIsProcessing,
    } = this.props;
    const { activeTab } = this.state;
    return (
      <Card className="printer-settings">
        {(printerDefaultsAreProcessing || printerServiceIsProcessing) && this.loadingSpinner()}
        <CardBody>
          <CardTitle>Printer Settings</CardTitle>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === native_print })}
                onClick={() => { this.toggle(native_print); }}
              >
                Native Print
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === al_print })}
                onClick={() => { this.toggle(al_print); }}
              >
                Acceler Print
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === single_print })}
                onClick={() => { this.toggle(single_print); }}
              >
                Single Print <Badge color="danger" pill>New</Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === mf_ticketing })}
                onClick={() => { this.toggle(mf_ticketing); }}
              >
                MF Ticketing <Badge color="warning" pill>SOON</Badge>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={native_print}>
              <br />
              {activeTab === native_print && (
                <NativePrintSettingPage
                  printerDefaults={printerDefaults}
                  savePrinterDefaults={savePrinterDefaults}
                  print={print}
                  npPrinterConnectionStatus={npPrinterConnectionStatus}
                />
              )}
            </TabPane>
            <TabPane tabId={al_print}>
              <br />
              {activeTab === al_print && (
                <ALPrintSettingPage
                  printerSettings={printerSettings}
                  printerDefaults={printerDefaults}
                  savePrinterDefaults={savePrinterDefaults}
                  print={print}
                  qzAvaliblePrinters={qzAvaliblePrinters}
                  qzTrayConnectionStatus={qzTrayConnectionStatus}
                  setALPrintSystemEditMode={setALPrintSystemEditMode}
                  setPrinterServiceIsProcessing={setPrinterServiceIsProcessing}
                />
              )}
            </TabPane>
            <TabPane tabId={single_print}>
              <br />
              {
                activeTab === single_print && (
                  <SinglePrintSettingPage />
                )
              }
            </TabPane>
            <TabPane tabId={mf_ticketing}>
              <br />
              {
                activeTab === mf_ticketing && (
                  <MFTicketing
                    printerSettings={printerSettings}
                    printerDefaults={printerDefaults}
                    savePrinterDefaults={savePrinterDefaults}
                    print={print}
                    qzAvailablePrinters={qzAvaliblePrinters}
                    qzTrayConnectionStatus={qzTrayConnectionStatus}
                    setALPrintSystemEditMode={setALPrintSystemEditMode}
                    setPrinterServiceIsProcessing={setPrinterServiceIsProcessing}
                  />
                )
              }
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    );
  }
}

PrinterDefaultsQz.propTypes = {
  fetchPrinterDefaults: PropTypes.func.isRequired,
  printerDefaults: PropTypes.object.isRequired,
  savePrinterDefaults: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  qzAvaliblePrinters: PropTypes.array.isRequired,
  printerDefaultsAreProcessing: PropTypes.bool.isRequired,
  npPrinterConnectionStatus: PropTypes.bool.isRequired,
  setALPrintSystemEditMode: PropTypes.func.isRequired,
  printerServiceIsProcessing: PropTypes.bool.isRequired,
  setPrinterServiceIsProcessing: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  printerSettings: state.Settings.get("printerSettings"),
  printerDefaults: state.Settings.get("printerDefaults"),
  printerDefaultsAreProcessing: state.Settings.get("printerDefaultsAreProcessing"),
  qzAvaliblePrinters: state.PrintService.get("qzAvaliblePrinters"),
  qzTrayConnectionStatus: state.PrintService.get("qzTrayConnectionStatus"),
  npPrinterConnectionStatus: state.PrintService.get("npPrinterConnectionStatus"),
  printerServiceIsProcessing: state.PrintService.get("printerServiceIsProcessing"),
});

export default connect(
  mapStateToProps,
  {
    fetchPrinterDefaults,
    savePrinterDefaults,
    print,
    setALPrintSystemEditMode,
    setPrinterServiceIsProcessing
  }

)(withRouter(PrinterDefaultsQz));
