const appActions = {
  API_CALL_SUCCESS: "API_CALL_SUCCESS",
  API_CALL_FAILED: "API_CALL_FAILED",
  API_CALL_USER_ERROR: "API_CALL_USER_ERROR",
  API_CALL_USER_WARNING: "API_CALL_USER_WARNING",

  API_CALL_USER_SUCCESS: "API_CALL_USER_SUCCESS",
  API_CALL_USER_SOUND_NOTIFICATION_SUCCESS: "API_CALL_USER_SOUND_NOTIFICATION_SUCCESS",
  RESET_API_CALL_STATUS: "RESET_API_CALL_STATUS",

  APP_USER_PAGEVIEW_CAPTURE: "APP_USER_PAGEVIEW_CAPTURE",
  APP_USER_PAGEVIEW_CAPTURE_SUCCESS: "APP_USER_PAGEVIEW_CAPTURE_SUCCESS",
  APP_USER_PAGEVIEW_CAPTURE_ERROR: "APP_USER_PAGEVIEW_CAPTURE_ERROR",

  APP_PUSH_ERROR_MESSAGE_LIST: "APP_PUSH_ERROR_MESSAGE_LIST",
  APP_PUSH_ERROR_MESSAGE_LIST_SUCCESS: "APP_PUSH_ERROR_MESSAGE_LIST_SUCCESS",
  APP_PUSH_ERROR_MESSAGE_LIST_ERROR: "APP_PUSH_ERROR_MESSAGE_LIST_ERROR",

  APP_ERROR_MESSAGE_LIST_CLOSE: "APP_ERROR_MESSAGE_LIST_CLOSE",
  APP_ERROR_MESSAGE_LIST_CLOSE_SUCCESS: "APP_ERROR_MESSAGE_LIST_CLOSE_SUCCESS",
  APP_ERROR_MESSAGE_LIST_CLOSE_ERROR: "APP_ERROR_MESSAGE_LIST_CLOSE_ERROR",

  appPushErrorMessageList: (data) => ({
    type: appActions.APP_PUSH_ERROR_MESSAGE_LIST,
    data
  }),
  appPushErrorMessageListSuccess: (data) => ({
    type: appActions.APP_PUSH_ERROR_MESSAGE_LIST_SUCCESS,
    data
  }),
  appPushErrorMessageListError: (data) => ({
    type: appActions.APP_PUSH_ERROR_MESSAGE_LIST_ERROR,
    data
  }),

  appErrorMessageClose: (data) => ({
    type: appActions.APP_ERROR_MESSAGE_LIST_CLOSE,
    data
  }),
  appErrorMessageCloseSuccess: (data) => ({
    type: appActions.APP_ERROR_MESSAGE_LIST_CLOSE_SUCCESS,
    data
  }),
  appErrorMessageCloseError: (data) => ({
    type: appActions.APP_ERROR_MESSAGE_LIST_CLOSE_ERROR,
    data
  }),

  userPageviewCapture: (data) => ({
    type: appActions.APP_USER_PAGEVIEW_CAPTURE,
    data
  }),
  userPageviewCaptureSuccess: (data) => ({
    type: appActions.APP_USER_PAGEVIEW_CAPTURE_SUCCESS,
    data
  }),
  userPageviewCaptureError: (data) => ({
    type: appActions.APP_USER_PAGEVIEW_CAPTURE_ERROR,
    data
  }),

  userError: (errorMessage) => ({
    type: appActions.API_CALL_FAILED,
    errorMessage
  }),

  apiCallFailed: (errorMessage) => ({
    type: appActions.API_CALL_FAILED,
    errorMessage
  }),
  apiCallSuccess: (successMessage) => ({
    type: appActions.API_CALL_SUCCESS,
    successMessage
  }),
  apiCallUserError: (userErrorMessage) => ({
    type: appActions.API_CALL_USER_ERROR,
    userErrorMessage
  }),
  apiCallUserWarning: (userWarningMessage) => ({
    type: appActions.API_CALL_USER_WARNING,
    userWarningMessage
  }),
  confirWarningModal: () => ({
    type: appActions.CONFIRM_WARNING_MODAL
  }),
  apiCallUserSuccess: (userSuccessMessage) => ({
    type: appActions.API_CALL_USER_SUCCESS,
    userSuccessMessage
  }),
  apiCallUserSoundNotificationSuccess: () => ({
    type: appActions.API_CALL_USER_SOUND_NOTIFICATION_SUCCESS
  }),
  resetApiCallStatus: () => ({
    type: appActions.RESET_API_CALL_STATUS
  })
}

export default appActions
