import React, { Component } from "react";
import { Card, CardBody, Col, Row, Input, FormGroup, Label, Button, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";

const { userWhitelist } = adminActions;

/**
 * Update user password
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUserWhitelist extends Component {
	state = {
		userUN: '',
		updating: false,
	}

	handleChange = (key, value) => {
		this.setState(
			{
				[key]: value,
				updating: false,
			}
		);
	}

	processWhitelist = () => {
		if(!this.state.userUN) { return }
		this.setState({ updating: true });
		const username = this.state.userUN;
		const data = {
			username,
		}
		this.props.userWhitelist(data);
	}

  render() {
    return (
		<div>
		<Card>
			<CardBody>
				<CardTitle>User whitelist for registration</CardTitle>
				<Row>
					<Col>
						<FormGroup>
							<Label for="un">Username:</Label>
							<Input
								type="text"
								name="un"
								id="un"
								value={this.state.userUN}
								onChange={e => this.handleChange('userUN', e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								color="success"
								onClick={this.processWhitelist}
								disabled={
										!this.state.userUN
								}
							>
							  WHITELIST
							</Button>
							<br />
							<Label for="un" style={{ color: "red" }}>
								{this.state.updating ? this.props.user_whitelist_message : null}
							</Label>

						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	</div>
    );
  }
}

export default connect(
  state => {
		return {
			user_updating: state.Admin.get("user_updating"),
			user_whitelist_message: state.Admin.get("user_whitelist_message"),
    };
  },
  {
		userWhitelist,
  }
)(AdminUserWhitelist);
