import React, { Component, PureComponent } from 'react';
import { 
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, 
  Tooltip, Cell, PieChart, Pie, Legend 
} from 'recharts';
import IconArrowRight from 'react-icons/lib/md/chevron-right';
import "./style.css";
import chart_config from "../../main_dashboard_v3/chart_config";

class CustomizedLabel extends Component {
  render() {
	  const {x, y, value} = this.props;
    return (
        <text
          x={x}
          y={y}
          dx={17}
          dy={value > 0 ? -5 : 14}
          fontSize="12"
          fill="#3a3a3a"
          textAnchor="middle"
        >
          {value}
        </text>
    );
  }
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-5} dy={5} textAnchor="end" fill="#666" transform="rotate(-25)" fontSize="12">{payload.value}</text>
      </g>
    );
  }
}

class CategoryChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeData: this.props.data,
      activeCategory: "",
      randomColors: this.generateRandomColors(this.props.data)
    }
  }

  generateRandomColors = (data) => {
    let colors = [];
    data.map(item => colors.push(`#${Math.random().toString(16).substr(-6)}`));

    return colors;
  }

  onCellClick = (selected, index) => {
    const activeData = this.state.activeData;
    const selectedDataArr = activeData.filter(item => item.name === selected.name);
    let newActiveData;

    if(selectedDataArr.length > 0) {
      const selectedData = selectedDataArr[0];    
      newActiveData = selectedData.subCategories;
    } else {
      newActiveData = null;
    }

    this.setState({
      activeData: newActiveData,
      activeCategory: selected.name,
      randomColors: newActiveData ? this.generateRandomColors(newActiveData) : null
    });
  }

  onRootCategory = () => {
    this.setState({
      activeData: this.props.data,
      activeCategory: "",
      randomColors: this.generateRandomColors(this.props.data)
    });
  }

  pieLegendFormatter = (value, entry) => {
    return <span>{value} ({entry.payload.value})</span>;
  }

  render () {
    const {
      activeData,
      activeCategory,
      randomColors
    } = this.state;

    const CustomTooltip = ({ active, payload, label }) => {
      if (active) {
        return (
          <React.Fragment>
            <p className="label">{`Category : ${label}`}</p>
            <p className="label">{`# Listed : ${payload[0].value}`}</p>
          </React.Fragment>
        );
      }
    
      return null;
    }

    return (
      <div className={`category-chart ${this.props.className ? this.props.className : ""}`}>
        <p className="text-muted">Click the {this.props.type === "bar" ? "bar" : "pie sector"} to see the sub category chart</p>
        <div className="category-nav">
          <div className="d-flex">
            <span>Category:</span>
            <nav>
              <ul>
                <li><button type="button" className="btn btn-link" onClick={this.onRootCategory}>{this.props.rootNav}</button></li>
                {activeCategory !== "" && <li><IconArrowRight size="20" className="icon-separator" /> {activeCategory}</li>}
              </ul>
            </nav>
          </div>
        </div>

        {activeData ?
          <ResponsiveContainer
            width={"100%"}
            height={this.props.height ? this.props.height : 300}
          >
            {this.props.type === "bar" ?
            <BarChart
              data={activeData}
              margin={{
                top: 15, left: 70, bottom: 100
              }}
            >
              <CartesianGrid
                vertical={false}
                stroke={chart_config.cartesianStrokeColor}
              />
              <XAxis
                dataKey="name"
                tickLine={false}
                tick={<CustomizedAxisTick />}
                interval={0}
                axisLine={false}
                stroke={chart_config.axisFontColor}
                style={{
                  fontSize: chart_config.axisFontSize
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                stroke={chart_config.axisFontColor}
                style={{
                  fontSize: chart_config.axisFontSize
                }}
              />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <defs>
                <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="barGradient">
                  <stop stopColor="#B4ED50" offset="0%"></stop>
                  <stop stopColor="#429321" offset="100%"></stop>
                </linearGradient>
              </defs>
              <Bar
                dataKey="num_listed"
                barSize={35}
                fill="url(#barGradient)"
                label={<CustomizedLabel />}
                onClick={this.onCellClick}
              >
                {
                  activeData !== undefined ?
                    activeData.map((entry, index) => (
                      <Cell cursor="pointer" key={`cell-${index}`} />
                    ))
                  : null
                }
              </Bar>
            </BarChart>
            :
            <PieChart>
              <Pie 
                data={activeData}
                dataKey="num_listed" 
                outerRadius={120}
                onClick={this.onCellClick}
              >
              {
                activeData !== undefined ?
                  activeData.map((entry, index) => (
                    <Cell cursor="pointer" key={`cell-${index}`} fill={randomColors[index]} />
                  ))
                : null
              }
              </Pie>
              <Tooltip />
              <Legend layout="vertical" verticalAlign="top" align="right" formatter={this.pieLegendFormatter} width={600} height={300} />
            </PieChart>
            }
          </ResponsiveContainer>
          : <p className="empty-data-message"><span>There's no data for this category, click <button type="button" className="btn btn-link" onClick={this.onRootCategory}>here</button> to go back.</span></p>
        }
      </div>
    )
  }
}

export default CategoryChart;