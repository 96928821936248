import App from './app/reducer'
import Auth from './auth/reducer'
import Address from './address/reducer'
import History from './history/reducer'
import Batch from './batch/reducer'
import ConditionNotes from './condition_notes/reducer'
import Settings from './settings/reducer'
import BoxContents from './box_contents/reducer'
import Inventory from './inventory/reducer'
import Membership from './membership/reducer'
import Stats from './stats/reducer'
import BugReporting from './bug_reporting/reducer'
import Dashboard from './main_dashboard/reducer'
import Accounting from './accounting/reducer'
import PrintService from './print_service/reducer'
import Landing from './landing/reducer'
import UserTags from './settings/user_tags/reducer'
import Admin from './admin/reducer'
import HoldingArea from './holding_area/reducer'
import Leaderboard from './leaderboard/reducer'
import Repricer from './repricer/reducer'
import Export from './export/reducer'
import Sbyb from './sbyb/reducer'
import Research from './research/reducer'
import Ebay from './ebay/reducer'
import Employees from './employees/reducer'
import Feedback from './feedback/reducer'
import Sop from './sop/reducer'
import Message from './message/reducer';
import Order from './order/reducer';
import Sales from './sales/reducer';
import Fulfillment from './fulfillment/reducer';

export default {
  App,
  Auth,
  Address,
  History,
  Batch,
  ConditionNotes,
  Settings,
  BoxContents,
  Inventory,
  Membership,
  BugReporting,
  Stats,
  Dashboard,
  Accounting,
  PrintService,
  Landing,
  UserTags,
	Admin,
	HoldingArea,
	Leaderboard,
	Repricer,
	Export,
	Sbyb,
	Research,
	Ebay,
  Employees,
  Feedback,
  Sop,
  Message,
  Order,
  Sales,
  Fulfillment,
}
