import { all, takeLatest, put, fork, call, takeEvery } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import inventoryActions from "../inventory/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';
import { openInNewTabExtended } from '../../helpers/utility';
import { userEnableEbayAPI } from "../../helpers/templates_apis";
import settingsActions from "../settings/actions";
import { ebay_direct_list_mapping_for_send } from "../../helpers/ebay/utility";

export function* ebayUserAuth() {
    yield takeLatest('EBAY_USER_AUTH', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.user_creds, {});
					if (response.status === 200 && !response.data.error) {
						yield put(actions.ebayUserAuthSuccess(response.data));
						openInNewTabExtended(response.data.url, 'ebay-auth', true);
					} else {
						yield put(actions.ebayUserAuthFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Ebay api error [user auth]"));
					yield put(actions.ebayUserAuthFailed());
          logError(error, {
            tags: {
              exceptionType: "EBAY_USER_AUTH_ERROR"
            }
          });
        }
    })
}

export function* ebayGetItemData() {
	yield takeLatest('EBAY_GET_ITEM_DATA', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.list_item, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetItemDataSuccess(response.data));
				} else {
					yield put(actions.ebayGetItemDataFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get item data]"));
				yield put(actions.ebayGetItemDataFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_GET_ITEM_DATA_ERROR"
					}
				});
			}
	})
}

export function* ebayEditItemData() {
	yield takeLatest('EBAY_EDIT_ITEM_DATA', function* (payload) {
		const { data } = payload;
		try {
			yield put(actions.ebayEditItemDataSuccess(data));
		} catch (error) {
			//yield put(appActions.apiCallFailed("Error! Ebay api error"));
			logError(error, {
				tags: {
					exceptionType: "EBAY_EDIT_ITEM_DATA_ERROR"
				}
			});
		}
	})
}

export function* ebayUserGetPolicy() {
	yield takeLatest('EBAY_USER_GET_POLICY', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.user_policy, data);
				if (response.status === 200 && !response.data.error) {
					response.data['policy'] = data.policy;
					yield put(actions.ebayUserGetPolicySuccess(response.data));
				} else {
					yield put(actions.ebayUserGetPolicyFailed(data));
					if(response.data.show_error){
						yield put(appActions.apiCallFailed(response.data.error));
					}
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get policies]"));
				yield put(actions.ebayUserGetPolicyFailed(data));
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_POLICY_ERROR"
					}
				});
			}
	})
}

export function* ebayUserGetSettings() {
	yield takeLatest('EBAY_USER_GET_SETTINGS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.user_settings, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayUserGetSettingsSuccess(response.data));
				} else {
					yield put(actions.ebayUserGetSettingsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user settings load]"));
				yield put(actions.ebayUserGetSettingsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_SETTINGS_ERROR"
					}
				});
			}
	})
}

export function* ebayUserUpdateSettings() {
	yield takeLatest('EBAY_USER_UPDATE_SETTINGS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'put', apiEndpoints.ebay.user_settings, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayUserUpdateSettingsSuccess(response.data));
				} else {
					yield put(actions.ebayUserUpdateSettingsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user settings update]"));
				yield put(actions.ebayUserUpdateSettingsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_UPDATE_SETTINGS_ERROR"
					}
				});
			}
	})
}

export function* ebayCreateItemData() {
	yield takeLatest('EBAY_CREATE_ITEM_DATA', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.list_item, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayCreateItemDataSuccess(response.data));
					const update_data = {
						sku: data.seller_sku,
						key: "ebay_listing_id",
						value: response.data.item.listingId,
					}
					yield put(inventoryActions.updateInventoryField(update_data));
					yield put(actions.toggleEbayModal());
				} else {
			 	  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayCreateItemDataFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user create item data]"));
				yield put(actions.ebayCreateItemDataFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_CREATE_ITEM_DATA_ERROR"
					}
				});
			}
	})
}

export function* ebayUserGetLocation() {
	yield takeLatest('EBAY_USER_GET_LOCATION', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.user_location, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayUserGetLocationSuccess(response.data));
				} else {
					yield put(actions.ebayUserGetLocationFailed(data));
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get locations]"));
				yield put(actions.ebayUserGetLocationFailed(data));
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_LOCATION_ERROR"
					}
				});
			}
	})
}

export function* ebayGetItems() {
	yield takeLatest('EBAY_USER_GET_ITEMS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.items, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetItemsSuccess(response.data));
				} else {
					yield put(actions.ebayGetItemsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get items]"));
				yield put(actions.ebayGetItemsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_ITEMS_ERROR"
					}
				});
			}
	})
}

export function* toggleEbayModal() {
	yield takeLatest('EBAY_CREATE_MODAL_DISPLAY', function* (payload) {
		yield put(actions.toggleEbayModalSuccess());
	})
}

export function* ebayGetUserOrderItems() {
	yield takeLatest('EBAY_USER_GET_ORDER_ITEMS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.orders, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetUserOrderItemsSuccess(response.data));
				} else {
					yield put(actions.ebayGetUserOrderItemsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get orders]"));
				yield put(actions.ebayGetUserOrderItemsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_ORDER_ITEMS_ERROR"
					}
				});
			}
	})
}

export function* adminEbayPullOrders() {
	yield takeLatest('ADMIN_EBAY_PULL_ORDERS', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.schedule_orders_pull, {});
				if (response.status === 200 && !response.data.error) {
					yield put(actions.adminEbayPullOrdersSuccess(response.data));
				} else {
					yield put(actions.adminEbayPullOrdersFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [admin pull in orders]"));
				yield put(actions.adminEbayPullOrdersFailed());
				logError(error, {
					tags: {
						exceptionType: "ADMIN_EBAY_PULL_ORDERS_ERROR"
					}
				});
			}
	})
}

export function* adminEbayGetActiveOrders() {
	yield takeLatest('ADMIN_EBAY_GET_ACTIVE_ORDERS', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.schedule_orders_active_get, {});
				if (response.status === 200 && !response.data.error) {
					yield put(actions.adminEbayGetActiveOrdersSuccess(response.data));
				} else {
					yield put(actions.adminEbayGetActiveOrdersFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [admin get active orders]"));
				yield put(actions.adminEbayGetActiveOrdersFailed());
				logError(error, {
					tags: {
						exceptionType: "ADMIN_EBAY_GET_ACTIVE_ORDERS_ERROR"
					}
				});
			}
	})
}

export function* adminEbayActivateOrders() {
	yield takeLatest('ADMIN_EBAY_ACTIVATE_ORDERS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.schedule_order_activate, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.adminEbayActivateOrdersSuccess(response.data));
					yield put(actions.adminEbayGetActiveOrders(response.data));
				} else {
					yield put(actions.adminEbayActivateOrdersFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [admin activate orders]"));
				yield put(actions.adminEbayActivateOrdersFailed());
				logError(error, {
					tags: {
						exceptionType: "ADMIN_EBAY_ACTIVATE_ORDERS_ERROR"
					}
				});
			}
	})
}

export function* ebayEditOrderItemData() {
	yield takeLatest('EBAY_EDIT_ORDER_ITEM_DATA', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'put', apiEndpoints.ebay.order_item, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayEditOrderItemDataSuccess(response.data));
				} else {
					yield put(actions.ebayEditOrderItemDataFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [update order item]"));
				yield put(actions.ebayEditOrderItemDataFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_EDIT_ORDER_ITEM_DATA_ERROR"
					}
				});
			}
	})
}

export function* userEnableEbay() {
    yield takeLatest('USER_SELF_ENABLE_EBAY', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(userEnableEbayAPI, data)
					if (response.status === 200 && !response.data.error) {
						yield put(settingsActions.updateUserDataSettings(
							{ is_ebay_enabled: data.is_ebay_enabled	}));
						yield put(actions.userEnableEbaySuccess(data))
					} else {
						yield put(actions.userEnableEbayFailed());
						if(response.data.type && response.data.type === 'limit'){
							yield put(appActions.apiCallFailed(response.data.error));
						} else {
						  //as below, but just failsafe
              window.location.reload();
            }
					}
     } catch (error) {
          yield put(appActions.apiCallFailed("Error! Ebay api error"));
          yield put(actions.userEnableEbayFailed());
          logError(error, {
            tags: {
              exceptionType: "USER_SELF_ENABLE_EBAY_ERROR"
            }
          });
					window.location.reload();
		 }
    })
}

export function* userOrderEbayMarkFulfilled() {
    yield takeLatest('USER_SELF_ORDER_EBAY_MARK_FULFILLED', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'put', apiEndpoints.ebay.order_item_mark_fulfilled, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userOrderEbayMarkFulfilledSuccess({ order_item_id: data.order_item_id	}));
				} else {
					yield put(actions.userOrderEbayMarkFulfilledFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.userOrderEbayMarkFulfilledFailed());
				logError(error, {
					tags: {
						exceptionType: "USER_SELF_ORDER_EBAY_MARK_FULFILLED"
					}
				});
		 }
    })
}

export function* userRecalculateEbayFees() {
    yield takeLatest('USER_RECALCULATE_EBAY_FEES', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.calculate_fees, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userRecalculateEbayFeesSuccess(response.data));
				} else {
					yield put(actions.userRecalculateEbayFeesFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.userRecalculateEbayFeesFailed());
				logError(error, {
					tags: {
						exceptionType: "USER_RECALCULATE_EBAY_FEES_ERROR"
					}
				});
		 }
    })
}

export function* ebayItemEditToggle() {
    yield takeLatest('EBAY_ITEM_EDIT_TOGGLE', function* (payload) {
			yield put(actions.ebayItemEditToggleSuccess(payload.data));
    })
}

export function* ebayItemEditLoadData() {
    yield takeLatest('EBAY_ITEM_EDIT_LOAD_DATA', function* (payload) {
			yield put(actions.ebayItemEditLoadDataSuccess(payload.data));
    })
}

export function* ebayEditItemUpdateData() {
	yield takeLatest('EBAY_EDIT_ITEM_UPDATE_DATA', function* (payload) {
		const { data } = payload;
		try {
			yield put(actions.ebayEditItemUpdateDataSuccess(data));
		} catch (error) {
			//yield put(appActions.apiCallFailed("Error! Ebay api error"));
			logError(error, {
				tags: {
					exceptionType: "EBAY_EDIT_ITEM_UPDATE_DATA_ERROR"
				}
			});
		}
	})
}

export function* ebayEditItemUpdateDataEbay() {
    yield takeLatest('EBAY_EDIT_ITEM_UPDATE_DATA_EBAY', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'put', apiEndpoints.ebay.item, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayEditItemUpdateDataEbaySuccess(response.data));
          yield put(actions.ebayItemEditToggleSuccess({ebay_item_edit_model_display: false}));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayEditItemUpdateDataEbayFailed());
          //yield put(actions.ebayItemEditToggleSuccess({ebay_item_edit_model_display: false}));
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayEditItemUpdateDataEbayFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_ERROR"
					}
				});
		 }
    })
}

export function* ebayGetItemAspects() {
    yield takeLatest('EBAY_GET_ITEM_ASPECTS', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.category_aspects, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetItemAspectsSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayGetItemAspectsFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayGetItemAspectsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_GET_ITEM_ASPECTS_ERROR"
					}
				});
		 }
    })
}

export function* ebayGetPackageTypes() {
    yield takeLatest('EBAY_GET_PACKAGE_TYPES', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.package_type_available, {});
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetPackageTypesSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayGetPackageTypesFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayGetPackageTypesFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_GET_PACKAGE_TYPES_ERROR"
					}
				});
		 }
    })
}

export function* ebayDirectListingItemInit() {
    yield takeLatest('EBAY_DIRECT_LISTING_ITEM_INIT', function* (payload) {
			yield put(actions.ebayDirectListingItemInitSuccess(payload.data));
    })
}

export function* ebayDirectListingItemEdit() {
    yield takeLatest('EBAY_DIRECT_LISTING_ITEM_EDIT', function* (payload) {
			yield put(actions.ebayDirectListingItemEditSuccess(payload.data));
    })
}

export function* ebayDirectListingUploadImage() {
    yield takeLatest('EBAY_DIRECT_LISTING_UPLOAD_IMAGE', function* (payload) {
      try {
        const data = new FormData();
        const file = payload.data.shift();
        data.append('file', file, file.name);
				const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.direct_image_upload, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayDirectListingUploadImageSuccess(response.data, file));
          if(payload.data.length > 0){
					  yield put(actions.ebayDirectListingUploadImage(payload.data));
          }
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayDirectListingUploadImageFailed());
          if(payload.data.length > 0){
					  yield put(actions.ebayDirectListingUploadImage(payload.data));
          }
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error" + error));
				yield put(actions.ebayDirectListingUploadImageFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_DIRECT_LISTING_UPLOAD_IMAGE_ERROR"
					}
				});
		 }
    })
}

export function* ebayDirectListingLoadCategories() {
    yield takeLatest('EBAY_DIRECT_LISTING_LOAD_CATEGORIES', function* (payload) {
      const select_default_category = payload.data.select_default_category;
      if(Object.keys(payload.data).includes("select_default_category")){
        delete payload.data["select_default_category"];
      }
      try {
				let response = yield call(backendAPICall, 'get', apiEndpoints.ebay.direct_category_suggestions, payload.data);
				if (response.status === 200 && !response.data.error) {
          response.data.select_default_category = select_default_category;
					yield put(actions.ebayDirectListingLoadCategoriesSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayDirectListingLoadCategoriesFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayDirectListingLoadCategoriesFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_DIRECT_LISTING_LOAD_CATEGORIES_ERROR"
					}
				});
		 }
    })
}

export function* ebayListingLoadCategories() {
    yield takeLatest('EBAY_LISTING_LOAD_CATEGORIES', function* (payload) {
      const select_default_category = payload.data.select_default_category;
      if(Object.keys(payload.data).includes("select_default_category")){
        delete payload.data["select_default_category"];
      }
      try {
				let response = yield call(backendAPICall, 'get', apiEndpoints.ebay.direct_category_suggestions, payload.data);
				if (response.status === 200 && !response.data.error) {
          response.data.select_default_category = select_default_category;
					yield put(actions.ebayListingLoadCategoriesSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayListingLoadCategoriesFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayListingLoadCategoriesFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_LISTING_LOAD_CATEGORIES_ERROR"
					}
				});
		 }
    })
}

export function* ebayDirectCreateItemData() {
	yield takeLatest('EBAY_DIRECT_CREATE_ITEM_DATA', function* (payload) {
    const data = ebay_direct_list_mapping_for_send(payload.data);
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.ebay.list_item, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayDirectCreateItemDataSuccess(response.data));
					yield put(actions.ebayDirectSearchQueryUpdateSuccess({ebay_direct_search_query: ""}));
				} else {
			 	  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayDirectCreateItemDataFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user create item directly data]"));
				yield put(actions.ebayDirectCreateItemDataFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_DIRECT_CREATE_ITEM_DATA_ERROR"
					}
				});
			}
	})
}

export function* ebayDirectSearchProducts() {
	yield takeLatest('EBAY_DIRECT_SEARCH_PRODUCTS', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.search.products, payload.data);
				if (response.status === 200 && !response.data.error) {
          if(response.data.results.length === 1){
            const item_data = Object.assign({}, response.data.results[0]);
					  yield put(actions.ebayDirectSearchProductsClear());
					  yield put(actions.ebayDirectSearchProductsSelect(item_data));
					  yield put(actions.ebayDirectAdditionalData({ asin: item_data.ASIN }));
          } else {
					  yield put(actions.ebayDirectSearchProductsSuccess(response.data.results));
          }
				} else {
			 	  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayDirectSearchProductsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user create item directly search]"));
				yield put(actions.ebayDirectSearchProductsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_DIRECT_SEARCH_PRODUCTS_ERROR"
					}
				});
			}
	})
}

export function* ebayDirectSearchProductsClear() {
	yield takeLatest('EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR', function* (payload) {
		yield put(actions.ebayDirectSearchProductsClearSuccess());
	})
}

export function* ebayDirectSearchProductsSelect() {
	yield takeLatest('EBAY_DIRECT_SEARCH_PRODUCTS_SELECT', function* (payload) {
		yield put(actions.ebayDirectSearchProductsSelectSuccess(payload.data));
    yield put(actions.ebayDirectListingLoadCategories(
      {
        query: payload.data.name,
        select_default_category: true,
      }
    ));
		yield put(actions.ebayDirectAdditionalData({ asin: payload.data.ASIN }));
	})
}

export function* ebayDirectAdditionalData() {
	yield takeLatest('EBAY_DIRECT_ADDITIONAL_DATA', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.direct_pricing, payload.data);
				if (response.status === 200 && !response.data.error) {
					  yield put(actions.ebayDirectAdditionalDataSuccess(response.data.data));
				} else {
			 	  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayDirectAdditionalDataFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user create item directly search]"));
				yield put(actions.ebayDirectSearchProductsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_DIRECT_ADDITIONAL_DATA_ERROR"
					}
				});
			}
	})
}

export function* checkMFSalesUnfinished() {
	yield takeLatest('EBAY_CHECK_MF_SALES_UNFINISHED', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.order_check_mf_unfinished, payload.data);
				if (response.status === 200 && !response.data.error) {
					  yield put(actions.checkMFSalesUnfinishedSuccess(response.data));
				} else {
			 	  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.checkMFSalesUnfinishedFailed());
				}
			} catch (error) {
				yield put(actions.ebayDirectSearchProductsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_CHECK_MF_SALES_UNFINISHED_ERROR"
					}
				});
			}
	})
}

export function* ebayDirectListingClosePopup() {
	yield takeLatest('EBAY_DIRECT_LISTING_CLOSE_POPUP', function* (payload) {
		yield put(actions.ebayDirectListingClosePopupSuccess());
	})
}

export function* ebayGetItemEditAspects() {
    yield takeLatest('EBAY_GET_ITEM_EDIT_ASPECTS', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.category_aspects, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetItemEditAspectsSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayGetItemEditAspectsFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayGetItemEditAspectsFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_GET_ITEM_EDIT_ASPECTS_ERROR"
					}
				});
		 }
    })
}

export function* ebayMarketingConversionGet() {
    yield takeLatest('EBAY_MARKETING_CONVERSION', function* (payload) {
      try {
				let response = yield call(backendAPICall, 'get', apiEndpoints.ebay.marketing_conversion, {});
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayMarketingConversionGetSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.ebayMarketingConversionGetFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error"));
				yield put(actions.ebayMarketingConversionGetFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_MARKETING_CONVERSION_ERROR"
					}
				});
		 }
    })
}

export function* ebayEditItemDataItem() {
	yield takeLatest('EBAY_EDIT_ITEM_DATA_EDIT', function* (payload) {
		const { data } = payload;
		try {
			yield put(actions.ebayEditItemDataItemSuccess(data));
		} catch (error) {
			//yield put(appActions.apiCallFailed("Error! Ebay api error"));
			logError(error, {
				tags: {
					exceptionType: "EBAY_EDIT_ITEM_DATA_EDIT_ERROR"
				}
			});
		}
	})
}

export function* ebayDirectSearchQueryUpdate() {
	yield takeLatest('EBAY_DIRECT_SEARCH_QUERY_UPDATE', function* (payload) {
		yield put(actions.ebayDirectSearchQueryUpdateSuccess(payload.data));
	})
}

export function* ebayGetItemsCount() {
	yield takeEvery('EBAY_USER_GET_ITEMS_COUNT', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.ebay.items_count, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.ebayGetItemsCountSuccess(response.data));
				} else {
					yield put(actions.ebayGetItemsCountFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Ebay api error [user get items]"));
				yield put(actions.ebayGetItemsCountFailed());
				logError(error, {
					tags: {
						exceptionType: "EBAY_USER_GET_ITEMS_ERROR"
					}
				});
			}
	})
}


export function* ebayInventoryImageUpload() {
  yield takeLatest("EBAY_CROSS_LIST_IMAGE_UPLOAD", function* (payload) {
    try {
      const data = new FormData();
      const file = payload.data.shift();
      data.append("file", file, file.name);
      const response = yield call(
        backendAPICall,
        "post",
        apiEndpoints.ebay.direct_image_upload,
        data
      );
      if (response.status === 200 && !response.data.error) {
        yield put(actions.ebayCrossListImageUploadSuccess(response.data, file));
        if (payload.data.length > 0) {
          yield put(actions.ebayCrossListImageUpload(payload.data));
        }
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
        yield put(actions.ebayCrossListImageUploadFailed());
        if (payload.data.length > 0) {
          yield put(actions.ebayCrossListImageUpload(payload.data));
        }
      }
    } catch (error) {
      yield put(
        appActions.apiCallFailed(
          "Error! Ebay cross list image upload api error" + error
        )
      );
      yield put(actions.ebayCrossListImageUploadFailed());
      logError(error, {
        tags: {
          exceptionType: "EBAY_CROSS_LIST_IMAGE_UPLOAD_IMAGE_ERROR",
        },
      });
    }
  });
}


export default function* ebaySaga() {
	yield all([
		fork(ebayUserAuth),
		fork(ebayGetItemData),
		fork(ebayEditItemData),
		fork(ebayUserGetPolicy),
		fork(ebayUserGetSettings),
		fork(ebayUserUpdateSettings),
		fork(ebayCreateItemData),
		fork(ebayUserGetLocation),
		fork(ebayGetItems),
		fork(toggleEbayModal),
		fork(ebayGetUserOrderItems),
		fork(adminEbayPullOrders),
		fork(adminEbayGetActiveOrders),
		fork(adminEbayActivateOrders),
		fork(ebayEditOrderItemData),
		fork(userEnableEbay),
		fork(userOrderEbayMarkFulfilled),
    fork(userRecalculateEbayFees),
    fork(ebayItemEditToggle),
    fork(ebayItemEditLoadData),
    fork(ebayEditItemUpdateData),
    fork(ebayEditItemUpdateDataEbay),
    fork(ebayGetItemAspects),
    fork(ebayGetPackageTypes),
    fork(ebayDirectListingItemInit),
    fork(ebayDirectListingItemEdit),
    fork(ebayDirectListingUploadImage),
    fork(ebayDirectListingLoadCategories),
    fork(ebayListingLoadCategories),
    fork(ebayDirectCreateItemData),
    fork(ebayDirectSearchProducts),
    fork(ebayDirectSearchProductsClear),
    fork(ebayDirectSearchProductsSelect),
    fork(ebayDirectAdditionalData),
    fork(checkMFSalesUnfinished),
    fork(ebayDirectListingClosePopup),
    fork(ebayGetItemEditAspects),
    fork(ebayEditItemDataItem),
    fork(ebayMarketingConversionGet),
    fork(ebayDirectSearchQueryUpdate),
	fork(ebayGetItemsCount),
	fork(ebayInventoryImageUpload)
	]);
}
