import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
} from 'reactstrap';
import EbayLiveListingsTable from './EbayLiveListingsTable';
import EbaySalesTable from './EbaySalesTable';
import ebayActions from '../../../../redux/ebay/actions';

const { ebayGetItems, ebayGetUserOrderItems } = ebayActions;

class TabbedCard extends Component {
  state = {
    activeTab: "1",
    searchText: "",
  }

  tabChanged = (id) => {
    this.setState({ activeTab: id })
  }

  handleSearch = (e) => {
  this.setState({searchText: e.target.value});
    if(this.state.activeTab === '1'){
      this.searchEbay(e.target.value);
    }
    if(this.state.activeTab === '2'){
      this.searchEbayOrders(e.target.value);
    }
  }

	searchEbayOrders(search){
		const data = {
			page: 1,
			page_size: 10,
			sort: 'id',
			sort_order: 'desc',
			search_text: search,
		}
		this.props.ebayGetUserOrderItems(data);
  }

	searchEbay(search){
		const data = {
			page: 1,
			page_size: 10,
			sort: 'id',
			sort_order: 'desc',
			search_text: search,
		}
		this.props.ebayGetItems(data);
  }

  render() {
    return (
      <Card className="card-tab">
        <CardHeader className="d-flex justify-content-between">
          <Nav tabs>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => this.tabChanged("1")}
              >
                Live eBay Listings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => this.tabChanged("2")}
              >
                eBay Sales
              </NavLink>
            </NavItem>
            <NavItem className="ml-auto" style={{ background: 'transparent' }}>
              <Input
                value={this.state.searchText}
                onChange={e => this.handleSearch(e)}
                autoComplete="off"
                type="text"
                placeholder="SEARCH TITLE / ASIN / SKU..."
                id="inventory-search"
                style={{ width: "25rem" }}
              />
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
            {this.state.activeTab === "1"
                && this.props.userData
                && this.props.userData.is_ebay_enabled ?
                <EbayLiveListingsTable
                  searchText={this.state.searchText}
                /> : null
            }
            </TabPane>
            <TabPane tabId="2">
            {this.state.activeTab === "2"
                && this.props.userData
                && this.props.userData.is_ebay_enabled ?
                <EbaySalesTable
                  searchText={this.state.searchText}
                /> : null
            }
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    )
  }
}

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
  }),
  {
    ebayGetItems,
    ebayGetUserOrderItems,
  }
)(TabbedCard);
