import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	FormGroup, Input, InputGroup,
	InputGroupAddon,
	Label, Row, Col,
	Alert,
	Collapse
} from 'reactstrap';
import InputAmountType from '../../../../../../shared/components/Form/InputAmountType';
import repricerActions from '../../../../../../redux/repricer/actions';
import AlertTooltip from '../../components/AlertTooltip';
import TemplateTooltip from '../../components/TemplateTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class GuardRails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repriceAmountType: '$',
			setMinROI: false,
			price_min: 0,
			price_max: 9000000,
			drop_percentage: 0,
			increase_price: 0,
			lower_than_price: false,
			higher_than_price: false,
			reprice_if_suggested_up: false,
			reprice_if_suggested_down: false,
			if_amazon_lower_not_reprice: false,
			lower_than_roi: false,
			roi_amount: 0,
			dont_price_above_featured_merchants: false,
    }
  }

	UNSAFE_componentWillMount(){
		this.setState({
			repriceAmountType: this.props.workingTemplate.template_json.guards_fix_price_roi_if_true_percentage_if_false ? "$" : "%",
			setMinROI: this.props.workingTemplate.template_json.guards_setMinROI,
			price_min: this.props.workingTemplate.template_json.guards_price_min,
			price_max: this.props.workingTemplate.template_json.guards_price_max,
			drop_percentage: this.props.workingTemplate.template_json.guards_drop_percentage,
			increase_price: this.props.workingTemplate.template_json.guards_increase_price,
			lower_than_price: this.props.workingTemplate.template_json.guards_lower_than_price,
			higher_than_price: this.props.workingTemplate.template_json.guards_higher_than_price,
			reprice_if_suggested_up: this.props.workingTemplate.template_json.guards_reprice_if_suggested_up,
			reprice_if_suggested_down: this.props.workingTemplate.template_json.guards_reprice_if_suggested_down,
			if_amazon_lower_not_reprice: this.props.workingTemplate.template_json.guards_if_amazon_lower_not_reprice,
			lower_than_roi: this.props.workingTemplate.template_json.guards_lower_than_roi,
			roi_amount: this.props.workingTemplate.template_json.guards_roi_amount,
			dont_price_above_featured_merchants: this.props.workingTemplate.template_json.pricing_rules_dont_price_above_featured_merchants,
		});
	}

	changePriceAboveDeaturedMerchants = (e) => {
		this.setState({
			dont_price_above_featured_merchants: !this.state.dont_price_above_featured_merchants,
		});
		this.props.workingTemplateUpdateJson(
				{data: {pricing_rules_dont_price_above_featured_merchants: !this.state.dont_price_above_featured_merchants } })
	}

	updateRepriceAmount = (amount) => {
		this.setState({ roi_amount: amount });
	}

  toggleRepriceAmountType = () => {
    const currentType = this.state.repriceAmountType;
    this.setState({
      repriceAmountType: currentType === '$' ? '%' : '$'
    });
  }

  toggleSetMinROI = () => {
    this.setState({ setMinROI: !this.state.setMinROI });
  }

	changeBoolean = (root, key) => {
		this.setState({ [key]: !this.state[key] });
		const json_key = root + "_" + key;
		this.props.workingTemplateUpdateJson(
			{data: {[json_key]:  !this.state[key]}});
	}

	changeIntegerValue = (e, root, key) => {
		var val = e.target.value;
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_int = parseInt(val.replace(/,/gi, ""), 10);
			if(!val_int){
				val = "";
				val_int = 0;
			} else {
				val = val_int.toString()
			}
			this.setState({ [key]: val_int });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeFloatValue = (e, root, key) => {
		var val = e.target.value;
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_float = parseFloat(val.replace(/,/gi, "")).toFixed(2);
			if(!val_float){
				val = "";
				val_float = 0;
			} else {
				val = val_float.toString()
			}
			this.setState({ [key]: val_float });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeSuggestedPrice = (root, key) => {
		this.changeBoolean(root, key);
		if(key === 'reprice_if_suggested_up' && !this.state.reprice_if_suggested_up){
			this.setState({ reprice_if_suggested_down: false });
			const json_key = root + "_reprice_if_suggested_down";
			this.props.workingTemplateUpdateJson(
				{data: {[json_key]: false}});
		}
		if(key === 'reprice_if_suggested_down' && !this.state.reprice_if_suggested_down){
			this.setState({ reprice_if_suggested_up: false });
			const json_key = root + "_reprice_if_suggested_up";
			this.props.workingTemplateUpdateJson(
				{data: {[json_key]: false}});
		}
	}

	changeValue = (key, e) => {
		this.setState({ [key]: e.target.value });
	}

  render() {
    return (
      <Row>
        <Col md={5}>
          <FormGroup className="mb-5">
            <Label>
							Min/Max Price
							<TemplateTooltip
								id="t-guard-minmax"
								text="Minimum and maximum price"
							/>
						</Label>
            <div className="input-number-range">
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
				<Input
					type="string"
					placeholder="Min Price"
					min={0}
					value={this.state.price_min.toLocaleString()}
					onBlur={e => this.changeFloatValue(e, "guards", "price_min")}
					onChange={e => this.changeValue('price_min', e)}
				/>
              </InputGroup>
              <hr />
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
				<Input
					type="string"
					placeholder="Max Price"
					min={this.state.price_min}
					value={this.state.price_max.toLocaleString()}
					onBlur={e => this.changeFloatValue(e, "guards", "price_max")}
					onChange={e => this.changeValue('price_max', e)}
				/>
              </InputGroup>
			<AlertTooltip
				target="alert_min_max_price"
				message={this.props.templateWarning}
				range={[25, 26, 33]}
			/>
            </div>
          </FormGroup>

          <FormGroup className="mb-5 d-inline-block">
            <Label>
							Price Drops/Increases
							<TemplateTooltip
								id="t-guard-price-drop-increase"
								text="Condition if price drops or increases"
							/>
						</Label>
            <FormGroup>
              <span>Don't reprice if price <strong><span className="text-danger">drops</span> by more than </strong></span>
              <InputGroup className="input-group-inline ml-2" style={{ width: '10rem' }}>
				  <Input
					  type="number"
					  min={0}
					  max={100}
					  value={this.state.drop_percentage.toLocaleString()}
					  onChange={e => this.changeIntegerValue(e, "guards", "drop_percentage")}
				  />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
							<AlertTooltip
								target="alert_drop_percentage"
								message={this.props.templateWarning}
								range={[27]}
							/>
            </FormGroup>
            <FormGroup className="d-inline-block">
              <span>Don't reprice if price <strong><span className="text-success">increases</span> by more than </strong></span>
              <InputGroup className="input-group-inline ml-2" style={{ width: '10rem' }}>
				  <Input
					  type="number"
					  min="0"
					  value={this.state.increase_price.toLocaleString()}
					  onChange={e => this.changeIntegerValue(e, "guards", "increase_price")}
				  />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
							<AlertTooltip
								target="alert_drop_percentage"
								message={this.props.templateWarning}
								range={[28]}
							/>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>
							Minimum ROI
							<TemplateTooltip
								id="t-guard-minroi"
								text="Minimum Return on Investment"
							/>
						</Label>
            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  onChange={e => this.changeBoolean("guards", "setMinROI")}
					  checked={this.state.setMinROI}
				  /> <span>Set Min ROI</span>
              </Label>
			</FormGroup>

            <Collapse isOpen={this.state.setMinROI}>
              <FormGroup>
                <Alert color="warning" className="mt-2">
                  Note: If the minimum ROI for a particular SKU is lower than the fixed min price that you set above, we will ignore your min ROI.
                  If buy cost or fees is missing, we cannot compute an accurate ROI and default to your fixed min price.
                </Alert>
                <FormGroup className="d-inline-block">
                  <InputAmountType
                    amountType={this.state.repriceAmountType}
                    toggle={this.toggleRepriceAmountType}
										updateAmount={this.updateRepriceAmount}
										updateTemplateJson={this.props.workingTemplateUpdateJson}
										dataInTemplateJson="guards_roi_amount"
										typeDataInTemplateJson="guards_fix_price_roi_if_true_percentage_if_false"
										value={this.state.roi_amount ? this.state.roi_amount.toString() : ""}
										min={0}
										allowPercenatgeOver100={true}
                  />
									<AlertTooltip
										target="alert_ROI"
										message={this.props.templateWarning}
										range={[29]}
									/>
                </FormGroup>
                <FormGroup check className="custom-check radio">
                  <p>If the suggested price is <strong>lower than the min ROI</strong></p>
                  <Label check>
					  <Input
						  type="radio"
						  name="gr_suggestedPriceLowerROI"
						  onChange={e => this.changeBoolean("guards", "lower_than_roi")}
						  checked={!this.state.lower_than_roi}
					  /> <span>Do not reprice</span>
                  </Label>
                  <Label check>
					  <Input
						  type="radio"
						  name="gr_suggestedPriceLowerROI"
						  onChange={e => this.changeBoolean("guards", "lower_than_roi")}
						  checked={this.state.lower_than_roi}
					  /> <span>Default to the min ROI</span>
                  </Label>
                </FormGroup>
              </FormGroup>
            </Collapse>
          </FormGroup>
        </Col>

        <Col md={{ size: 5, offset: 2 }}>
          <FormGroup className="mb-5">
            <Label>
							Suggested Price
							<TemplateTooltip
								id="t-guard-suggested"
								text="Condition of suggested price"
							/>
						</Label>
            <FormGroup>
              <FormGroup check className="custom-check radio">
                <p>If the suggested price is <strong>lower than the min price</strong></p>
                <Label check>
					<Input
						type="radio"
						name="gr_suggestedPriceLower"
						onChange={e => this.changeBoolean("guards", "lower_than_price")}
						checked={!this.state.lower_than_price}
					/> <span>Do not reprice</span>
                </Label>
                <Label check>
					<Input
						type="radio"
						name="gr_suggestedPriceLower"
						onChange={e => this.changeBoolean("guards", "lower_than_price")}
						checked={this.state.lower_than_price}
					/> <span>Default to the min price</span>
                </Label>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <FormGroup check className="custom-check radio">
                <p>If the suggested price is <strong>higher than the max price</strong></p>
                <Label check>
					<Input
						type="radio"
						name="gr_suggestedPriceHigher"
						onChange={e => this.changeBoolean("guards", "higher_than_price")}
						checked={!this.state.higher_than_price}
					/> <span>Do not reprice</span>
                </Label>
                <Label check>
					<Input
						type="radio"
						name="gr_suggestedPriceHigher"
						onChange={e => this.changeBoolean("guards", "higher_than_price")}
						checked={this.state.higher_than_price}
					/> <span>Default to the max price</span>
                </Label>
              </FormGroup>
            </FormGroup>

            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  onChange={e => this.changeSuggestedPrice("guards", "reprice_if_suggested_up")}
					  checked={this.state.reprice_if_suggested_up}
				  /> <span>Only reprice if the suggested price goes up</span>
              </Label>
              <Label check>
				  <Input
					  type="checkbox"
					  onChange={e => this.changeSuggestedPrice("guards", "reprice_if_suggested_down")}
					  checked={this.state.reprice_if_suggested_down}
				  /> <span>Only reprice if the suggested price goes down</span>
              </Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>
							Extra guards
							<TemplateTooltip
								id="t-guard-extra"
								text="Extra guards"
							/>
						</Label>
            <FormGroup check className="custom-check checkbox">
              <Label check>
								<Input
								  type="checkbox"
								  onChange={e => this.changeBoolean("guards", "if_amazon_lower_not_reprice")}
								  checked={this.state.if_amazon_lower_not_reprice}
								/>
									<span>Don’t price above amazon</span>
							  </Label>
            </FormGroup>
	          <FormGroup>
			        <FormGroup check className="custom-check checkbox">
				        <Label check>
							  <Input
								  type="checkbox"
								  checked={this.state.dont_price_above_featured_merchants}
								  onChange={this.changePriceAboveDeaturedMerchants}
								  value="amazon_fulfill"
								/>
									<span>Don't price above featured merchants</span>
								</Label>
	            </FormGroup>
		        </FormGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => ({
		templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
		workingTemplateUpdateJson,
  }
)(GuardRails)
