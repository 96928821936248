import React, { Component } from "react";
import qz from "qz-tray";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Col,
  CardBody,
  Label,
  Form,
  FormGroup,
  Button,
  Card,
  Alert,
  Row
} from "reactstrap";
import { labelsTypeList } from "../../../../../../helpers/print_service/labelsTypeList";
import { testPrintLabel } from "../../../../../../helpers/print_service/utility";
import { accelerPrintIcon } from "../../../../../../assets/images";
import printerActions from "../../../../../../redux/print_service/actions";
import PrintALPreview from "../PrintALPreview";
import ALPrintOnboarding from "../ALPrintOnboarding";

const { setQzTrayConnectionStatus } = printerActions;

class PrintALSettingsView extends Component {
  state = {
    showALPrintOnboarding: false
  }

  getTextFromBoolean = value => {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    }
  };

  handleChange = (e) => {
    const { setQzTrayConnectionStatus } = this.props;
    const { value } = e.target;

    if(value) {
      this.props.savePrinterDefaults({
        ...this.props.printerSettings[value]
      });
    }
    setQzTrayConnectionStatus(false);

    setTimeout(qz.websocket.disconnect, 1000);
  };

  renderNoConnection = () => {
    return (
      <div className="mt-3">
        <Alert color="danger">There is no connection to AccelerPrint, you need to setup AccelerPrint by clicking button below.</Alert>
        <Button 
          color="primary"
          onClick={() => this.setState({ showALPrintOnboarding: true })}
        >Setup AccelerPrint</Button>
        <ALPrintOnboarding 
          isOpen={this.state.showALPrintOnboarding}
          toggle={() => this.setState({ showALPrintOnboarding: false })}
          qzTrayConnectionStatus={this.props.qzTrayConnectionStatus}
        />
      </div>
    )
  }

  renderPostConnection = () => {
    return (
      <div className="row text-center">
        <div className="col-sm-12">
          <Button 
            color="primary"
            onClick={() => this.setState({ showALPrintOnboarding: true })}
          >Printer Settings Guide</Button>
          <ALPrintOnboarding 
            isOpen={this.state.showALPrintOnboarding}
            toggle={() => this.setState({ showALPrintOnboarding: false })}
            qzTrayConnectionStatus={this.props.qzTrayConnectionStatus}
          />
        </div>
      </div>
    )
  }

  render() {
    const { printerSettings, printerDefaults, toggleEditMode, qzTrayConnectionStatus, print } = this.props;
    const labelFindIndex = labelsTypeList.findIndex(el => printerDefaults
      && printerDefaults.hasOwnProperty("label_type")
      && el.lableName === printerDefaults.label_type
		);

    return (
      <>
      <Col xs="6">
      <Card>
      <CardBody>
        <Form>
          <FormGroup row>
            <Col sm={12}>
              <Button
                className="mb-2"
                onClick={toggleEditMode}
              >
                Edit Print Settings
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup row>
            <Label for="printerSelect" sm={3}>
              Active Printer
            </Label>
            <Col sm={9}>
                <select
                    className="form-control m-b"
                    name="printer_name"
                    id="printer_name"
                    value={printerDefaults.printer_name}
                    onChange={this.handleChange}
                >
                    <option value="">
                      Choose a Printer
                    </option>
                    {printerSettings && Object.values(printerSettings).map(({printer_name}) => {
                        return (
                            <option key={"p-" + printer_name} value={printer_name}>
                            {printer_name}
                            </option>
                        );
                    })}
                </select>
            </Col>
          </FormGroup>
            <React.Fragment>
              <FormGroup row>
                <Label for="orientation" sm={3}>
                  Orientation:
                </Label>
                <Col sm={9}>
                  {printerDefaults.orientation || "-"}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="suggestedLabels" sm={3}>
                  Suggested Labels:
                </Label>
                <Col sm={9}>{printerDefaults.label_type || "-"}</Col>
              </FormGroup>
              { labelFindIndex === -1 && (
                  <React.Fragment>
                    <FormGroup row>
                      <Label for="label_width" sm={3}>
                        Label Width:
                      </Label>
                      <Col sm={9}>
                        {printerDefaults.label_width || "-"}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="label_height" sm={3}>
                        Label Height:
                      </Label>
                      <Col sm={9}>
                        {printerDefaults.label_height || "-"}
                      </Col>
                    </FormGroup>
                  </React.Fragment>
                )
              }
            </React.Fragment>
          <FormGroup row>
            <Label for="printOption" sm={3}>
              Print while scanning in batch:
            </Label>
            <Col sm={9}>
              {this.getTextFromBoolean(
                printerDefaults.print_while_scanning
              ) || "-"}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="printOption" sm={3}>
              Print while scanning in box contents:
            </Label>
            <Col sm={9}>
              {this.getTextFromBoolean(
                printerDefaults.print_while_scanning_box_contents
              ) || "-"}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="printOption" sm={3}>
              Barcode type:
            </Label>
            <Col sm={9}>
              {printerDefaults.barcode_type || "-"}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="printOption" sm={3}>
              Label font size coefficient:
            </Label>
            <Col sm={9}>
              {printerDefaults.font_size_coefficient || "-"}
            </Col>
          </FormGroup>
          <hr />
          <Row className="align-items-center text-center">
            <Col className="text-left">
              {/* Here showing the Printer Connection Status */}
              <strong>AccelerPrint connection <br/>status</strong>
            </Col>
            <Col>
              <img src={accelerPrintIcon} alt="accelerPrintIcon" width="50" />
              {qzTrayConnectionStatus ? (
                <div className="mb-2">
                  <div className="toggle-dot" />
                  <div>DETECTED</div>
                  <div>
                    {this.renderPostConnection()}
                  </div>
                </div>
              ) : (
                <div>Not Connected</div>
              )}
            </Col>
            <Col className="text-right">
                <React.Fragment>
                  <Button
                    className="mb-2"
                    onClick={() => {
                      print(testPrintLabel, 1);
                    }}
                    disabled={!qzTrayConnectionStatus}
                  >
                    Print test label
                  </Button>
                </React.Fragment>
            </Col>
          </Row>
          {!qzTrayConnectionStatus &&
            this.renderNoConnection()
          }
        </Form>
      </CardBody>
      </Card>
      </Col>
      <Col xs="6">
        {this.props.qzTrayConnectionStatus && <PrintALPreview printerDefaults={this.props.printerDefaults}/>}
      </Col>
      </>
    );
  }
}

PrintALSettingsView.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  printerDefaults: PropTypes.object.isRequired,
  qzTrayConnectionStatus:  PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    qzTrayConnectionStatus: state.PrintService.get("qzTrayConnectionStatus"),
  }),
  { setQzTrayConnectionStatus }
)(PrintALSettingsView);
