import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";

const { searchUsersErrorLogs } = adminActions;

/**
 * Displa user error logs data
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUsersErrorLogs extends Component {

	componentDidMount(){
		this.props.searchUsersErrorLogs();
	}

	displayRow(data, key){
		return (
			<tr key={key}>
				<td>
					{ data['error_id'] }
				</td>
				<td className="text-right">
					{ digitСonversion(data['count']) }
				</td>
			</tr>
		)
	}

  render() {
    return (
		<Card>
			<CardBody>
				<div className="d-flex justify-content-between mb-3">
					<CardTitle className="mb-0">
						User error message by type
					</CardTitle>
					<span className="text-muted">Last 30 days</span>
				</div>
				<Table striped className="acc-table-minimal acc-table-left">
					<thead>
						<tr>
							<th>Error Type</th>
							<th className="text-right">Error Count</th>
						</tr>
					</thead>
					<tbody>
					{this.props.usersErrorLogs ? (
						this.props.usersErrorLogs.map((row, key) => (
							this.displayRow(row, key)))
						) : (null)
					}
					</tbody>
				</Table>
			</CardBody>
		</Card>
    );
  }
}

export default connect(
  state => {
    return {
		usersErrorLogs: state.Admin.get('usersErrorLogs'),
    };
  },
  {
	  searchUsersErrorLogs
  }
)(AdminUsersErrorLogs);
