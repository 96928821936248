import React, { Component } from 'react';
import { connect } from "react-redux";
import { Badge, Button } from "reactstrap";
import ReactTable from "react-table";
import orderActions from "../../../redux/order/actions";
import moment from "moment";
import { digitСonversion } from "../../../helpers/utility";
import appActions from "../../../redux/app/actions";
import salesActions from "../../../redux/sales/actions";

const {
  getCategorySales,
  getCategoryExport,
} = salesActions;

class SalesCategoryTable extends Component {
  state = {
    page: 1,
    page_size: 10,
  }

  generateTableColumns = () => {
    let tableColumns = [
      {
        accessor: "product_group",
        Header: "Category",
        headerClassName: "text-left",
      },
      {
        accessor: "items_sold",
        Header: "Items Sold",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.items_sold)}</span>
      },
      {
        accessor: "net_sales",
        Header: "Net Sales",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.net_sales, "currency")}</span>
      },
      {
        accessor: "cogs",
        Header: "COGS",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.cogs, "currency")}</span>
      },
      {
        accessor: "gross_profit",
        Header: "Gross Profit",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.gross_profit, "currency")}</span>
      },
      {
        accessor: "margin",
        Header: "Margin",
        headerClassName: "text-left",
        Cell: props => <span>{props.original.margin}%</span>
      },
    ]
    return tableColumns
  }

  componentDidMount = () => {
    this.props.getCategorySales({
      time: this.props.time_period?.value,
    });
  }

  componentDidUpdate = (pp) => {
    if(pp.time_period != this.props.time_period){
      this.props.getCategorySales({
        page: 1,
        page_size: this.state.page_size,
        time: this.props.time_period?.value,
      });
      this.setState({
        page: 1,
      })
    }
  }

  render() {
    const {
      category_sales_data,
      category_sales_data_working,
      category_data_export_working,
    } = this.props;

    return (
      <div className="sales-report-summary">
        <div className="d-flex gap-3 justify-content-end mb-3">
          <Button
            disabled={category_data_export_working}
            onClick={() => this.props.getCategoryExport({
              time: this.props.time_period?.value})
            }
          >Export data</Button>
        </div>
        <ReactTable
          data={category_sales_data?.items ? category_sales_data.items : []}
          pages={category_sales_data?.pages ? category_sales_data.pages : 1}
          columns={this.generateTableColumns()}
          pageSize={this.state.page_size}
          page={this.state.page - 1}
          manual
          loading={category_sales_data_working}
          showPagination={true}
          showPageSizeOptions= {true}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultPageSize={10}
          onPageChange={page => {
            this.setState({page: page + 1})
            this.props.getCategorySales({
              page: page + 1,
              page_size: this.state.page_size,
              time: this.props.time_period?.value,
            })
          }}
          onPageSizeChange={(pageSize, page) => {
            this.setState({
              page_size: pageSize,
            })
            this.props.getCategorySales({
              page: page + 1,
              page_size: pageSize,
              time: this.props.time_period?.value,
            })
          }}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    category_sales_data: state.Sales.get("category_sales_data"),
    category_sales_data_working: state.Sales.get("category_sales_data_working"),
    cateogry_data_export_working: state.Sales.get("cateogry_data_export_working"),
  }),
  {
    getCategorySales,
    getCategoryExport,
  }
)(SalesCategoryTable);
