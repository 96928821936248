import React, { Component, Fragment } from "react";
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from "prop-types";

/**
 * Tooltip for template
 *
 * @version 1.0.0
 * @author [bojan]
 */
class TemplateTooltip extends Component {
	render() {
		return (
			<Fragment>
				<span id={'template-tooltip-'+this.props.id} className="icon icon-help-circle ml-1"></span>
				<UncontrolledTooltip placement="right" target={'template-tooltip-'+this.props.id}>
					{this.props.text}
				</UncontrolledTooltip>
			</Fragment>
		)
	}
}

TemplateTooltip.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default TemplateTooltip;
