import React, {Component} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ShipmentBoxDialog from './ShipmentBoxDialog';
import { Button } from 'reactstrap';
import IconBarcode from "react-icons/lib/fa/qrcode";
import { lbsToKg } from '../../../helpers/utility';
import boxContentActions from "../../../redux/box_contents/actions";
import IconSize from "react-icons/lib/io/arrow-resize";
import DimensionModal from "./modals/DimensionModal";
import BarcodePopover from "./components/BarcodePopover";
import IconProducts from 'react-icons/lib/fa/list';
import ProductsModal from "./modals/ProductsModal";

const { boxPrintManifest, } = boxContentActions;

class ShipmentBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBarcodePopover: false,
            showDimensionModal: false,
            boxId: NaN,
            showProductsModal: false
        }
    }


    togglePopover = () => {
        this.setState({ showBarcodePopover: !this.state.showBarcodePopover });
    }

		printManifest = () => {
			const shipment = this.props.boxData.box.shipment_id;
			const data = this.props.boxData.box.items.map(item => {
				return {
					asin: item.ASIN,
					sku: item.SellerSKU,
					fnsku: item.FulfillmentNetworkSKU,
					shipment: shipment,
					qty: item.QuantityShippedInBox,
				}
			});
			this.props.boxPrintManifest(data);
		}

    handleSetDimentions = (dimensionData) => {
        this.props.onSetDimentions(dimensionData); 
    }
    
    render() {
			const {
				boxData,
				ShipmentId,
				updateCurrentBox,
				isSelected,
				printQr,
				shipment,
				changeLimit,
				changePage,
				page,
				limit,
        highlightItems,
        onSetDimentions,
        checkedBox,
			} = this.props;
        return (
          <div
            onClick={() => {
              updateCurrentBox(ShipmentId, boxData);
              highlightItems(boxData);
            }}
            id={`shipment-box-${boxData.id}`}
            className={`shipment-box ${isSelected ? "box-selected" : ""} ${
              boxData.weight === 0
                ? ""
                : boxData.weight > 50
                ? "box-danger"
                : ""
            }`}
          >
            <div className="mb-1">
              <span
                role="button"
                id={`view-box-${boxData.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  this.togglePopover();
                }}
              >
                <IconBarcode size="14" color="#90a1b3" />
              </span>
              <span
                role="button"
                className="ml-2"
                onClick={() => console.log('show products modal on current box')}
              >
                <IconProducts 
                  size="14" 
                  color="#90a1b3" 
                  onClick={() => this.setState({ showProductsModal: true })}
                />
                {this.state.showProductsModal && <ProductsModal
                    isOpen={true}
                    toggle={() => this.setState({ showProductsModal: !this.state.showProductsModal})}
                    products={this.props.boxData.box.items}
                    container={`box ${boxData.boxNumber}`}
                  />
                }
              </span>
              <span
                className="ml-2"
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ showDimensionModal: true });
                  this.setState({ boxId: boxData.id });
                }}
              >
                <IconSize
                  color={ checkedBox.find(e => e == boxData.id ) ? "#06b44e" : "#90a1b3"}
                  size={17}
                />
              </span>
            </div>
            <svg
              width="32"
              height="31"
              viewBox="-1 0 32 31"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.8535156 10.8164063L26.8886719 1.921875C26.5078125.7734375 25.4355469 0 24.2226562 0H15.9375v11.25h13.9863281c-.0234375-.1464844-.0234375-.2929687-.0703125-.4335937zM14.0625 0H5.77734375C4.56445312 0 3.4921875.7734375 3.11132812 1.921875L.14648437 10.8164063c-.046875.140625-.046875.2871093-.0703125.4335937H14.0625V0zM0 13.125v14.0625C0 28.7402344 1.25976562 30 2.8125 30h24.375C28.7402344 30 30 28.7402344 30 27.1875V13.125H0z"
                fill="#858C82"
                fillRule="evenodd"
              />
            </svg>
            <span className="box-number">{boxData.boxNumber}</span>
            <span className="box-weight">
              {this.props.userData &&
              ["A2EUQ1WTGCTBG2", "A1F83G8C2ARO7P"].includes(
                this.props.userData.marketplaceId
              ) ? (
                <div>{lbsToKg(boxData.weight).toFixed(2)}kg</div>
              ) : (
                <div>{boxData.weight.toFixed(2)}lbs</div>
              )}
            </span>
            <BarcodePopover
              isOpen={this.state.showBarcodePopover}
              target={`view-box-${boxData.id}`}
              toggle={() => this.setState({ showBarcodePopover: !this.state.showBarcodePopover })}
              boxData={boxData.box}
              shipment={shipment}
              printQr={printQr}
            />
            {/* <ShipmentBoxDialog
              key={`shipment-box-${boxData.id}-dialog`}
              shipment={shipment}
              printQr={printQr}
              boxData={boxData.box}
              isOpen={this.state.showBoxDetails}
              target={`view-box-${boxData.id}`}
              isSelected={isSelected}
              changeLimit={changeLimit}
              changePage={changePage}
              page={page}
              limit={limit}
              toggle={this.togglePopover}
              printManifest={this.printManifest}
              is_printing_manifest={this.props.is_printing_manifest}
            ></ShipmentBoxDialog> */}

            <DimensionModal
              isOpen={this.state.showDimensionModal}
              toggle={() =>
                this.setState({
                  showDimensionModal: !this.state.showDimensionModal,
                })
              }
              boxId={this.state.boxId}
              onSetDimentions={this.handleSetDimentions}
            />
          </div>
        );
    }
}

ShipmentBox.propTypes = {
  boxData: PropTypes.object,
  ShipmentId: PropTypes.string,
  updateCurrentBox: PropTypes.func,
  isSelected: PropTypes.bool,
  printQr: PropTypes.func,
  shipment: PropTypes.object,
  changeLimit: PropTypes.func,
  changePage: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number
};

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
		is_printing_manifest: state.BoxContents.get("is_printing_manifest"),
  }),
  {
		boxPrintManifest,
  }
)(ShipmentBox);
