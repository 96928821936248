import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import ReactTable from 'react-table';
import repricerActions from '../../../../../redux/repricer/actions';
import LoadingIndicator from '../../../../../shared/components/LoadingIndicator';

const {
	templateToSkuMapping
} = repricerActions;

class TemplateBreakdownSummary extends Component {

	UNSAFE_componentWillMount(){
		this.props.templateToSkuMapping(this.props.id);
	}

  render() {
    const { viewDetail } = this.props;

	const tableColumns = [
      {
        id: 'template',
        Header: 'Template Name',
        accessor: 'template',
				headerClassName: 'text-left'
      },
      {
        id: 'priority',
        Header: 'Priority',
        accessor: 'priority',
				headerClassName: 'text-right',
				className: 'text-right'
      },
      {
        id: 'number_skus',
        Header: '# SKUs',
        accessor: 'number_skus',
				headerClassName: 'text-right',
				className: 'text-right'
      }
    ]

	  var templatesData = [];
	  if(this.props.templateToSkuMappingData
		  && this.props.templateToSkuMappingData.template_id_to_sku_list_map){
			this.props.userTemplates.forEach(template => {
				if(template.id === this.props.templateId || this.props.templateId === 'all_templates'){
					const id_template = this.props.templateId === 'all_templates' ? template.id : this.props.templateId;
					const number_skus = this.props.templateToSkuMappingData.template_id_to_sku_list_map[id_template];
					templatesData.push({
						template: template.name,
						priority: template.priority,
						number_skus: number_skus ? number_skus.length : 0
					})
				}
			})
		}

	  return (
		<React.Fragment>
		{!this.props.templateToSkuMappingData ?
			<LoadingIndicator className="mt-0" title="Fetching Reprice Reports Summary..." />
		:
		<React.Fragment>
		  <div className="template-breakdown-summary text-left">
			<div className="d-flex justify-content-between align-items-center">
			  <h5>Template Breakdown Summary</h5>
        {templatesData.length ?
          <Button
            size="sm"
            color="link"
            onClick={() => viewDetail(this.props.id, this.props.templateId)}
          >View Detail</Button>
          : null
        }
			</div>

			<div className="mb-3">
			  <p>Here's the breakdown of what how these templates will be applied to your inventory.<br/>
				<strong>NOTE: If data is only partially available for a SKU, we will wait until the full notification is available before we adjust that SKU.</strong>
			  </p>
			</div>

			<ReactTable
			  columns={tableColumns}
			  data={templatesData}
				defaultPageSize={5}
				minRows={0}
			/>
		  </div>
		</React.Fragment>
		}
		</React.Fragment>
    );
  }
}

export default connect(
	state => ({
		templateToSkuMappingData: state.Repricer.get("templateToSkuMappingData"),
		userTemplates: state.Repricer.get("userTemplates"),
  }),
	{
		templateToSkuMapping,
	}
)(TemplateBreakdownSummary);
