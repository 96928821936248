import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Actions from '../../../../../redux/history/actions';
import './index.css';

const filterMethodMap = {
  "asin": "ASIN",
  "sku": "SKU",
  "name": "Name"
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      isFilterOpen: false
    }
  }

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  selectFilter = (e) => {
    e.preventDefault();
    const value = e.target.innerHTML.toLowerCase();
    this.setState({
      isFilterOpen: false
    }, () => {
      this.props.changeState("filter_method", value, false);
    });
  }

  handleChangeFilterQuery= (e) => {
    e.preventDefault();
    this.props.changeState("filter_query", e.target.value, false);
  }

  searchBatch = () => {
    const {
      filter_query
    } = this.props;
    this.props.changeHistoryTableState("parentPageResetCheck", true)
    this.props.changeState("filter_query", filter_query);
  }

  handleKeyDownSearch = (e) => {
    if (e.key === 'Enter') {
      this.searchBatch();
    }
  }

  render(){
    const { isFilterOpen } = this.state;
    const {
      filter_method,
      filter_query,
      clearSearch
    } = this.props;
    return(
      
        <div className="input-group batch-searchbar">
            <div className={`input-group-prepend ${isFilterOpen && 'show'}`}>
                <button onClick={() => this.setState({ isFilterOpen: !isFilterOpen })} type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                    Filter by: <strong className="filter-by">{filterMethodMap[filter_method]}</strong>
                </button>
                <div className="dropdown-menu">
                    <a tabIndex={0} role="button" onClick={this.selectFilter} className="dropdown-item">{filterMethodMap['name']}</a>
                    <a tabIndex={0} role="button" onClick={this.selectFilter} className="dropdown-item">{filterMethodMap['sku']}</a>
                    <a tabIndex={0} role="button" onClick={this.selectFilter} className="dropdown-item">{filterMethodMap['asin']}</a>
                </div>
            </div>
            <input
              type="text"
              onChange={this.handleChangeFilterQuery}
              value={filter_query}
              className="form-control"
              onKeyDown={this.handleKeyDownSearch}
            />
            <div className="input-group-append">
                {filter_query &&
                <button onClick={clearSearch} className="btn btn-link">
                  <span className="icon icon-10 m-2 icon-close"></span>
                </button>
                }
                <button onClick={this.searchBatch} className="btn btn-secondary">
                  Search
                </button>
            </div>
        </div>
      
    )
  };
}

SearchBar.propTypes = {
  fetchBatchList: PropTypes.func.isRequired,
  changeHistoryTableState: PropTypes.func,
  changeState: PropTypes.func,
  filter_method: PropTypes.string,
  filter_query:  PropTypes.string,
  clearSearch: PropTypes.func.isRequired
};

export default connect(null, Actions)(SearchBar);
