import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";

const { searchUsersPerMarketplace } = adminActions;

/**
 * Show stats about user count per marketplace
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUsersPerMarketplace extends Component {
	componentDidMount(){
		this.props.searchUsersPerMarketplace();
	}

	displayRow(data, key){
		return (
			<tr key={key}>
				<td>
					{ data['marketplace_id'] }
				</td>
				<td className="text-right">
					{ digitСonversion(data['count']) }
				</td>
			</tr>
		)
	}

  render() {
		// sort data desc
		let sorted  = this.props.usersPerMarketplace
		sorted.sort((a, b) => {
			return b['count'] - a['count']
		})

    return (
		<Card>
			<CardBody>
				<CardTitle>Users per marketplace</CardTitle>
				<Table striped className="acc-table-minimal acc-table-left">
					<thead>
						<tr>
							<th>Marketplace ID</th>
							<th className="text-right">Users Count</th>
						</tr>
					</thead>
					<tbody>
					{sorted ? (
							sorted.map((row, key) => (
								this.displayRow(row, key)))
						) : (null)
					}
					</tbody>
				</Table>
			</CardBody>
		</Card>
    );
  }
}

export default connect(
  state => {
    return {
		usersPerMarketplace: state.Admin.get('usersPerMarketplace'),
    };
  },
  {
	  searchUsersPerMarketplace
  }
)(AdminUsersPerMarketplace);
