import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Button, InputGroup, Input, InputGroupAddon, FormText } from 'reactstrap';
import CollapsibleFormGroup from './CollapsibleFormGroup';
import Select from "react-select";
import {
	ebay_condition_mapping_for_select,
} from '../../../helpers/ebay/utility';
import PerfectScrollbar from 'react-perfect-scrollbar';

class ListingProfile extends Component {

	reloadCategores = () => {
		const { ebay_direct_listing_item } = this.props;
		if(ebay_direct_listing_item.title){
			this.props.ebayDirectListingLoadCategories({
				query: ebay_direct_listing_item.title
			});
		}
	}

	changeCategory = (e) => {
		this.props.editData('ebay_condition', null);
		if(!e){
			this.props.editData('ebay_category', null);
		} else {
			this.props.editData('ebay_category', e);
			this.props.editData(
				'ebay_allowed_conditions_select',
				this.props.ebay_direct_listing_item.ebay_allowed_conditions[parseInt(e.value, 10)],
			);
      this.props.ebayGetItemAspects({
        category_id: e.value,
        asin: this.props.ebay_direct_listing_item.asin1,
      })
		}
	}

	additionalFieldsList = () => {
		const { ebay_direct_listing_item } = this.props;
		var fields = [];
		var selected = [];
		if(ebay_direct_listing_item.ebay_additional_fields){
			selected = ebay_direct_listing_item.ebay_additional_fields.map(
				item => {return item.desc.value} );
		}
		this.props.ebay_item_aspects.forEach(item => {
			if(!selected.includes(item.desc.value)){
				item.desc.values = item.values;
				item.desc.required = item.required;
				fields.push(item.desc);
			}
		});
		return fields;
	}

	selectAdditionalField = (field) => {
		this.props.editData('selectedAdditionalFiled', field);
	}

	addAdditionalField = () => {
		const { ebay_direct_listing_item } = this.props;
		if(!ebay_direct_listing_item.selectedAdditionalFiled){
			return;
		}
		var selected = [];
		if(ebay_direct_listing_item.ebay_additional_fields){
			selected = ebay_direct_listing_item.ebay_additional_fields.map(
				item => {return item.desc.value} );
		}
		if(selected.includes(ebay_direct_listing_item.selectedAdditionalFiled.value)){
			return;
		}
		const new_field = this.props.ebay_item_aspects.find(
			item => item.desc.value === ebay_direct_listing_item.selectedAdditionalFiled.value);
		if(new_field){
			let af = Object.assign([], ebay_direct_listing_item.ebay_additional_fields);
			af.push(new_field)
			this.props.editData('ebay_additional_fields', af);
		}
	}

	deleteAdditionalField = (field) => {
		const { ebay_direct_listing_item } = this.props;
		var selected = [];
		if(ebay_direct_listing_item.ebay_additional_fields){
			selected = ebay_direct_listing_item.ebay_additional_fields.map(
				item => {return item.desc.value} );
		}
		if(!selected.includes(field.value)){
			return;
		}
		let af = Object.assign([], ebay_direct_listing_item.ebay_additional_fields);
		af = af.filter(item => item.desc.value !== field.value);
		this.props.editData('ebay_additional_fields', af);
	}

	updateAdditionalFieldStr = (field, value) => {
		const { ebay_direct_listing_item } = this.props;
		const af = Object.assign([], ebay_direct_listing_item.ebay_additional_fields);
		let new_af = af.map(item => {
			if(item.desc.value === field){
				item.value = value;
			}
			return item;
		})
		this.props.editData('ebay_additional_fields', new_af);
	}

	showRecommendedFieldsEbay = () => {
		if(!this.props.ebay_item_aspects){
			return null;
		}
		var aspects = [];
		this.props.ebay_item_aspects.forEach(item => {
			if(item.required){
				aspects.push(item.desc.label);
			}
		})
		if(aspects.length === 0){
			return null;
		}
		return (
			<FormText>* Required fields by eBay for this category are {aspects.map((item, idx) => <b key={`item-required-aspect-${idx}-${item}`}>{item}</b>).reduce((prev, curr) => [prev, ', ', curr])}</FormText>
		);
	}

	displayAvailableOptionsAspect = () => {
		const { ebay_direct_listing_item } = this.props;
		return (
			<React.Fragment>
				{ebay_direct_listing_item.selectedAdditionalFiled.required
					?<strong>Hints [Recommended]:</strong>
					: <strong>Hints:</strong>
				}
				<PerfectScrollbar
					options={{
						wheelPropagation: false,
						minScrollbarLength: 40
					}}
				>
					<div style={{ maxHeight: "10rem" }}>
					{ebay_direct_listing_item.selectedAdditionalFiled.values.map(item => {
						return (
							<span key={item}> {item}, </span>
						)
					})}
				</div>
				</PerfectScrollbar>
			</React.Fragment>
		)
	}

	renderAdditionalItem = (item) => {
		var field = null;
		if(item.type === 'str'){
			field = (
				<FormGroup row>
					<Label md={3}>{item.desc.label}</Label>
					<Col md={9}>
						<InputGroup size="sm">
							<Input
								value={ item.value }
								onChange={e => this.updateAdditionalFieldStr(item.desc.value, e.target.value)}
								className="ml-2"
							/>
							<InputGroupAddon addonType="prepend">
								<Button
									color="danger"
									onClick={e => this.deleteAdditionalField(item.desc)}
								>REMOVE</Button>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
			);
		}
		return field;
	}

  direct_ebay_condition_mapping_for_select = (conditions) => {
    if(!conditions){ return []; }
    return conditions.map(item => {
      return { label: item.conditionDescription, value: item.conditionId }
    })
  }

	render() {
		const {
			collapse,
			toggleCollapse,
			ebay_direct_listing_item,
			editData,
			validateData,
		} = this.props;

		return (
			<CollapsibleFormGroup
				title="Listing Profile"
				collapse={collapse}
				toggleCollapse={toggleCollapse}
			>
				<FormGroup row className="mb-0">
					<Col md={3}>
						<Label>eBay Category</Label>
						<Select
							options={ebay_direct_listing_item.ebay_categories_select}
							value={ebay_direct_listing_item.ebay_category}
							onChange={e => this.changeCategory(e)}
							style={!validateData('ebay_category', ebay_direct_listing_item.ebay_category) ? {border: "1px solid #dc3545"} : null}
						/>
						<Button
							color="secondary"
							className="mt-2"
							size="sm"
							onClick={e => this.reloadCategores()}
							disabled={!validateData('title', ebay_direct_listing_item.title)}
						>Refresh Categories</Button>
					</Col>
					<Col md={3}>
						<Label>Condition</Label>
						<Select
							options={this.direct_ebay_condition_mapping_for_select(ebay_direct_listing_item.ebay_allowed_conditions_select)}
							value={ebay_direct_listing_item.ebay_condition}
							onChange={e => editData('ebay_condition', e)}
							style={!validateData('ebay_condition', ebay_direct_listing_item.ebay_condition) ? {border: "1px solid #dc3545"} : null}
						/>
					</Col>
					{/*
					<Col md={3}>
					<Label>eBay 2nd Category</Label>
						<Input type="select">
							<option>Books</option>
							<option>DVDs</option>
							<option>CDs</option>
						</Input>
					</Col>
					<Col md={3}>
					<Label>Listing Format</Label>
						<Input type="select">
							<option>Fixed Price</option>
							<option>Auction</option>
						</Input>
					</Col>
					<Col md={3}>
						<Label>Best Offer</Label>
						<Input type="select">
							<option>Option 1</option>
							<option>Option 2</option>
						</Input>
					</Col>
					*/}
				</FormGroup>
				<br />
				<FormGroup>
					<Label>Additional fields</Label>
					<Row>
						<Col md={5}>
							<FormGroup>
								<InputGroup className="mb-2">
									<Select
										options={this.additionalFieldsList()}
										value={ebay_direct_listing_item.selectedAdditionalFiled}
										onChange={e => this.selectAdditionalField(e)}
									/>
									<InputGroupAddon addonType="append">
										<Button
											disabled={!ebay_direct_listing_item.selectedAdditionalFiled}
											onClick={e => this.addAdditionalField()}
										>ADD FIELD</Button>
									</InputGroupAddon>
								</InputGroup>
								{this.showRecommendedFieldsEbay()}
							</FormGroup>
							{ebay_direct_listing_item.ebay_additional_fields ?
							ebay_direct_listing_item.ebay_additional_fields.map((item, idx) => {
								return (
									<React.Fragment key={`fragment-${idx}`}>
										<div key={`additional-field-${item.desc.value}`}>
											{this.renderAdditionalItem(item)}
										</div>
									</React.Fragment>
								)
							}) : null
						}
						</Col>
						<Col md={7}>
							{ebay_direct_listing_item.selectedAdditionalFiled ?
								this.displayAvailableOptionsAspect()
								: null
							}
						</Col>
					</Row>
				</FormGroup>
			</CollapsibleFormGroup>
		)
	}
}

export default ListingProfile;
