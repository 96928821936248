import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Badge
} from "reactstrap";
import PropTypes from "prop-types";
import CustomSKUSelector from '../../../../shared/components/customSKUSelector';


class CustomSKUTemplateModal extends Component {
  render() {
    const { isOpen, close } = this.props;
    const listingDefaults = {
      should_use_custom_sku_template: isOpen,
      sku_prefix: this.props.skuPrefix
    }
    return (
      <Modal isOpen={isOpen} toggle={close} style={{ maxWidth: 1200 }}>
        <ModalHeader toggle={close}>Custom SKU Creator</ModalHeader>
        <ModalBody>
          <Badge color="warning">Note: Any changes made here will not apply to your next batch. More permanent custom skus can be made in the settings area.</Badge>
          {!!isOpen && <CustomSKUSelector
            isOpen={isOpen}
            listingDefaults={listingDefaults}
            onSaveListingDefaults={this.props.onSaveListingDefaults}
          />}
        </ModalBody>
      </Modal>
    );
  }
}

CustomSKUTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default CustomSKUTemplateModal;
