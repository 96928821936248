import React, { Component } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import { connect } from "react-redux";
import './style.css';

class EmployeeDashboard extends Component {
  render() {
    return (
      <div className="view">
				<div className="view-header">
					<header className="text-white"></header>
			  </div>
			  <div className="view-content view-dashboard view-fullwidth">
          <Card>
            <CardBody>
              <div className="page-header">
                <h2 className="page-title">
                  YOUR BUSINESS DASHBOARD (EMPLOYEE)
                </h2>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
    };
  },
  {
  }
)(EmployeeDashboard);
