import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Col, FormGroup, Label, Badge } from 'reactstrap';
import CollapsibleFormGroup from './CollapsibleFormGroup';
import { digitСonversion } from "../../../helpers/utility";
import { generateEbayCustomSKU } from "../../../helpers/ebay/utility";

class PricingSKU extends Component {

  editFloat = (key, value) => {
    if(value){
      value = parseFloat(value)
    }
    this.props.editData(key, value);
  }

  UNSAFE_componentWillReceiveProps(np, op){
    const { ebay_direct_listing_item } = this.props;
    //handle sku update for price change
    if(ebay_direct_listing_item
        && np.ebay_user_settings
        && np.ebay_user_settings.use_custom_sku){
      if(
        ebay_direct_listing_item.price !== np.ebay_direct_listing_item.price
          || ebay_direct_listing_item.identifier !== np.ebay_direct_listing_item.identifier
          || ebay_direct_listing_item.ebay_condition !== np.ebay_direct_listing_item.ebay_condition
      ){
        const sku = generateEbayCustomSKU(
          np.ebay_user_settings.custom_sku, np.ebay_direct_listing_item)
        this.props.editData("sku", sku);
      }
    }
  }

  editInt = (key, value) => {
    if(value){
      value = parseInt(value, 10)
    }
    this.props.editData(key, value);
  }

  render() {
    const {
      collapse,
      toggleCollapse,
      ebay_direct_listing_item,
      validateData,
      internationalization_config,
    } = this.props;

    //this.props.editData("sku", this.props.ebay_user_settings ? this.props.ebay_user_settings.custom_sku : "");

    return (
      <CollapsibleFormGroup
        title="Pricing &amp; SKU"
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      >
        <FormGroup row className="d-flex">
          <Col xl={10} className="d-flex">
            <div className="d-flex">
              <div>
                <Label>Price</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min="0"
                    step="0.01"
                    value={ebay_direct_listing_item.price ? ebay_direct_listing_item.price : ""}
                    onChange={e => this.editFloat("price", e.target.value)}
                    invalid={!validateData('price', ebay_direct_listing_item.price)}
                  />
                </InputGroup>
              </div>
              <div className="ml-2">
                <Label>Qty</Label>
                <Input
                  type="number"
                  min="0"
                  value={ebay_direct_listing_item.qty ? ebay_direct_listing_item.qty : ""}
                  onChange={e => this.editInt("qty", e.target.value)}
                  invalid={!validateData('qty', ebay_direct_listing_item.qty)}
                />
              </div>
            </div>
            {/*
            <Col md={2}>
              <Label>Cost</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  min="0"
                  step="0.01"
                  value={ebay_direct_listing_item.cost ? ebay_direct_listing_item.cost : ""}
                  onChange={e => this.editFloat("cost", e.target.value)}
                />
              </InputGroup>
            </Col>
            */}
            {ebay_direct_listing_item.pricing_data ?
            <div className="d-flex ml-2">
              {ebay_direct_listing_item.pricing_data.amazon && ebay_direct_listing_item.pricing_data.amazon.amazon_new ?
                <div>
                  <Label>Amazon New</Label>
                  <Badge className="d-block" style={{ background: "#ff9900" }}><h4 className="mb-0">{digitСonversion(ebay_direct_listing_item.pricing_data.amazon.amazon_new, "currency", internationalization_config.currency_code)}</h4></Badge>
                </div>
                : null
              }
              {ebay_direct_listing_item.pricing_data.amazon && ebay_direct_listing_item.pricing_data.amazon.amazon_used ?
                <div className="ml-2">
                  <Label>Amazon Used</Label>
                  <Badge className="d-block" style={{ background: "#ff9900" }}><h4 className="mb-0">{digitСonversion(ebay_direct_listing_item.pricing_data.amazon.amazon_used, "currency", internationalization_config.currency_code)}</h4></Badge>
                </div>
                : null
              }
              {ebay_direct_listing_item.pricing_data.ebay && ebay_direct_listing_item.pricing_data.ebay.ebay_new_shipping ?
                <div className="ml-2">
                  <Label>eBay New</Label>
                  <Badge className="d-block" style={{ background: "#0064d2" }}><h4 className="mb-0">{digitСonversion(ebay_direct_listing_item.pricing_data.ebay.ebay_new_shipping, "currency", internationalization_config.currency_code)}</h4></Badge>
                </div>
                : null
              }
              {ebay_direct_listing_item.pricing_data.ebay && ebay_direct_listing_item.pricing_data.ebay.ebay_used_shipping ?
                <div className="ml-2">
                  <Label>eBay Used</Label>
                  <Badge className="d-block" style={{ background: "#0064d2" }}><h4 className="mb-0">{digitСonversion(ebay_direct_listing_item.pricing_data.ebay.ebay_used_shipping, "currency", internationalization_config.currency_code)}</h4></Badge>
                </div>
                : null
              }
            </div>
            : null
            }
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Col lg={6} xl={4}>
            <Label>SKU</Label>
            <Input
              value={ebay_direct_listing_item.sku ? ebay_direct_listing_item.sku : ""}
              onChange={e => this.props.editData("sku", e.target.value)}
						  invalid={!validateData('sku', ebay_direct_listing_item.sku)}
            />
          </Col>
          {/*
          <Col md={4}>
            <Label>eBay Custom Label (optional)</Label>
            <Input />
          </Col>
          */}
        </FormGroup>
      </CollapsibleFormGroup>
    )
  }
}

export default PricingSKU;
