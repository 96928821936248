import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { digitСonversion, numberFormatterToK } from '../../../../helpers/utility';
import { connect } from "react-redux";
import ebayActions from '../../../../redux/ebay/actions';

const { ebayGetItemsCount, ebayGetItemsCountWeek } = ebayActions;

class StatCard extends Component {
  constructor(props) {
    super(props);
  }

	componentDidMount(){
    const data = {
      period:'week'
    }
    this.props.ebayGetItemsCount();
    this.props.ebayGetItemsCount(data);
  }

  showNumber = (data) => {
    if(!data){ data = 0; }
    return digitСonversion(data)
  }

  showNumberMoney = (data) => {
    if(!data){ data = 0; }
    return digitСonversion(data, "currency", this.props.internationalization_config.currency_code)
  }

  render() {
    const { ebay_sales_aggregate_dashboard } = this.props.ebay_user_settings;
    return (
      <Card className="ebay-stat-card">
        <CardBody>
          <div className="metric-group">
            <div className="item metric-big-box">
              <span className="title">Last Month Sales</span>
              <div className="box">
                <span className="value-top">{this.showNumber(ebay_sales_aggregate_dashboard?.previous_month?.sales_total)}</span>
                <span className="value-bottom">{this.showNumberMoney(ebay_sales_aggregate_dashboard?.previous_month?.sales_amount)}</span>
              </div>
            </div>
            <div className="item metric-big-box">
              <span className="title">This Month Sales</span>
              <div className="box">
                <span className="value-top">{this.showNumber(ebay_sales_aggregate_dashboard?.current_month?.sales_total)}</span>
                <span className="value-bottom">{this.showNumberMoney(ebay_sales_aggregate_dashboard?.current_month?.sales_amount)}</span>
              </div>
            </div>
            <div className="item metric-big-box">
              <span className="title">Fulfill</span>
              <div className="box">
                <span className="value-top">{this.showNumber(ebay_sales_aggregate_dashboard?.fulfillment_count)}</span>
              </div>
              {/*
              <div className="metric-small-box">
                <span className="title">Returns</span>
                <span className="value">1</span>
              </div>
              */}
            </div>
            <div className="item metric-big-box">
                <span className="title">Daily Avg. Cross Listed</span>
                <div className="box">
                  <span className="value-top">
                    {
                      this.props.ebay_items_count                      
                      ? numberFormatterToK(((this.props.ebay_items_count.week ? this.props.ebay_items_count.week : 0 ) / 7), 2)
                      : ''
                    }
                  </span>
                </div>
              </div>
              <div className="item metric-big-box">
                <span className="title">This Week Listed</span>
                <div className="box">
                  <span className="value-top">
                    {
                      this.props.ebay_items_count.week ? this.props.ebay_items_count.week : 0
                    }
                  </span>
                </div>
              </div>
              <div className="item metric-big-box">
                <span className="title">This Month Listed</span>
                <div className="box">
                  <span className="value-top">
                    {
                      this.props.ebay_items_count.month ? this.props.ebay_items_count.month : 0
                    }
                  </span>
                </div>
              </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default connect(
	state => ({
		ebay_items_count: state.Ebay.get("ebay_items_count"),
	}),
	{
    ebayGetItemsCount,
  }
)(StatCard);
