import { Map } from "immutable/dist/immutable";
import actions from "./actions";

const initState = new Map({
  inventoryItems: {},
  uploadJobId: null,
  strandedItems: [],
  strandedItemsExport: [],
  showUpdateWarning: false,
	inventoryStats: null,
	is_inventory_updating: false,
  inventory_export_data: [],
  inventory_export_data_loading: false,
  item_delete_working: false,
  expire_loading: false,
  item_activate_working: false,
  export_inventory_job: "",
});

export default function inventoryReducer(state = initState, action) {
  switch (action.type) {

	  case actions.INVENTORY_DOWNLOAD_CHECK_SUCCESS:
		  return state
			  .set("inventory_export_data", Object.assign([], action.data))
			  .set("inventory_export_data_loading", false)
	  case actions.INVENTORY_DOWNLOAD_CHECK_FAILED:
		  return state
			  .set("inventory_export_data", [])
			  .set("inventory_export_data_loading", false)
			  .set("export_inventory_job", "")

	  case actions.INVENTORY_ITEM_ACTIVATE:
		  return state
			  .set("item_activate_working", true)
	  case actions.INVENTORY_ITEM_ACTIVATE_SUCCESS:
      var activate_inventory_items = state.get("inventoryItems");
      activate_inventory_items.results = Object.assign([], activate_inventory_items.results.filter(item => item.seller_sku !== action.data.sku));
		  return state
        .set("inventoryItems", Object.assign({}, activate_inventory_items))
			  .set("item_activate_working", false)
	  case actions.INVENTORY_ITEM_ACTIVATE_FAILED:
		  return state
			  .set("item_activate_working", false)

	  case actions.INVENTORY_ITEM_EXPIRE_GET:
		  return state
			  .set("expire_loadinf", true)
	  case actions.INVENTORY_ITEM_EXPIRE_GET_SUCCESS:
		  return state
        .set("expireItems", Object.assign({}, action.data))
			  .set("expire_loadinf", false)
	  case actions.INVENTORY_ITEM_EXPIRE_GET_FAILED:
		  return state
			  .set("expire_loadinf", false)

	  case actions.INVENTORY_ITEM_DELETE:
		  return state
			  .set("item_delete_working", true)
	  case actions.INVENTORY_ITEM_DELETE_SUCCESS:
      var delete_inventory_items = state.get("inventoryItems");
      delete_inventory_items.results = Object.assign([], delete_inventory_items.results.filter(item => item.seller_sku !== action.data.sku));
		  return state
        .set("inventoryItems", Object.assign({}, delete_inventory_items))
			  .set("item_delete_working", false)
	  case actions.INVENTORY_ITEM_DELETE_FAILED:
		  return state
			  .set("item_delete_working", false)

	  case actions.INVENTORY_DOWNLOAD:
		  return state
			  .set("inventory_export_data", [])
			  .set("inventory_export_data_loading", true)
			  .set("export_inventory_job", "")
	  case actions.INVENTORY_DOWNLOAD_SUCCESS:
		  return state
			  .set("export_inventory_job", action.data.job_id)
	  case actions.INVENTORY_DOWNLOAD_FAILED:
		  return state
			  .set("inventory_export_data", [])
			  .set("inventory_export_data_loading", false)
			  .set("export_inventory_job", "")

		case actions.UPDATE_INVENTORY_FIELD_SUCCESS:
			let inventoryItemsFieldTmp = Object.assign({}, state.get("inventoryItems"));
			inventoryItemsFieldTmp.results = inventoryItemsFieldTmp.results.map(item => {
				if(item.seller_sku === action.data.sku){
					item[action.data.key] = action.data.value;
				}
				return item;
			})
			return state
				.set("inventoryItems", Object.assign({}, inventoryItemsFieldTmp))

	case actions.FETCH_INVENTORY_ITEMS_SUCCESS:
		return state
			.set("inventoryItems", action.inventoryItems)

    case actions.SET_UPLOAD_INVENTORY_ITEMS_FILE_JOB_ID:
      return state.set("uploadJobId", action.jobId);

    case actions.CHANGE_INVENTORY_TABLE_FIELD_DATA_SUCCESS:{
      let inventoryItems = state.get("inventoryItems").slice();
      const newTableRowData = action.newTableRowData;
      let newInventoryItems = [];

      inventoryItems.forEach( row => {
        if (row.seller_sku === newTableRowData.seller_sku) {
          row = Object.assign({}, row, newTableRowData);
        }
        newInventoryItems.push(row);
      });

      return state.set("inventoryItems", newInventoryItems);
    }

    case actions.FETCH_STRANDED_ITEMS_SUCCESS:
      return state.set("strandedItems", action.strandedItems);

    case actions.FETCH_STRANDED_ITEMS_EXPORT_SUCCESS:
      return state.set("strandedItemsExport", action.strandedItems);

    case actions.GET_INVENTORY_STATS:
      return state.set("inventoryStats", null);
    case actions.GET_INVENTORY_STATS_SUCCESS:
      return state.set("inventoryStats", action.data);
    case actions.GET_INVENTORY_STATS_FAILED:
      return state.set("inventoryStats", null);

	case actions.UPDATE_INVENTORY:
		return state
			.set("is_inventory_updating", true)
	case actions.UPDATE_INVENTORY_SUCCESS:
		let inventoryItemsTmp = Object.assign({}, state.get("inventoryItems"));
		inventoryItemsTmp.results = inventoryItemsTmp.results.map(item => {
      if(item.seller_sku === action.data.sku){
        if(action.data.repricer_roi_number){
  				item.repricer_roi_number = action.data.repricer_roi_number;
	  			item.repricer_roi_type = action.data.repricer_roi_type;
        } else if(action.data.buy_cost){
          item.buy_cost = action.data.buy_cost;
        }
			}
			return item;
		})
		return state
			.set("inventoryItems", Object.assign({}, inventoryItemsTmp))
			.set("is_inventory_updating", false)
	case actions.UPDATE_INVENTORY_FAILED:
		return state
			.set("is_inventory_updating", false)

    default:
      return state;
  }
}
