import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PaginatorFooter from '../../../../shared/components/PaginatorFooter';

class ProductsModal extends Component {
  state = {
    limit: 5,
    currentPage: 1,
    currentLoadedData: []
  }

  componentDidMount = () => {
    this.fetchData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState.currentPage !== this.state.currentPage) {
      this.fetchData()
    }

    if(prevState.limit !== this.state.limit) {
      this.setState({ 
        currentPage: 1,
        currentLoadedData: []
      })
      this.fetchData()
    }
  }

  getProductsInBatchData = () => {
    return this.props.products.map(item => { 
            return { 
                    fnsku: item.fnsku ?? item.FulfillmentNetworkSKU,
                    qnty: item.qty ?? item.QuantityShippedInBox,
                    img_url: item.imageUrl ?? item.ProductSearchResult?.imageUrl,
                    title: item.name ?? item.ProductSearchResult?.name
                  } 
           })
  }

  fetchData = () => {
    const { currentPage, limit } = this.state
    const start = (currentPage - 1) * limit
    const end = start + limit
    this.setState({
      currentLoadedData: this.getProductsInBatchData()
    })
  }

  render() {
    const { isOpen, toggle, container } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>Products in {container}</ModalHeader>
        <ModalBody>
          {this.state.currentLoadedData.length > 0 ?
          <React.Fragment>
          <table className="table acc-table acc-table-left">
            <thead>
              <tr>
                <th></th>
                <th>FNSKU</th>
                <th>Qnty</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {this.state.currentLoadedData.length > 0 && this.state.currentLoadedData.map((item, idx) => (
              <tr key={`product-${idx}`}>
                <td style={{ maxWidth: 30 }}>
                  <div className="media" style={{ maxWidth: 30 }}>
                    <img src={item.img_url} className="img-fluid mr-0" alt="" style={{ width: 30 }} />
                  </div>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.fnsku}</td>
                <td>{item.qnty}</td>
                <td>{item.title}</td>
              </tr>
              ))}
            </tbody>
          </table>
          <PaginatorFooter
            changeLimit={(value) => this.setState({ limit: value })}
            limit={this.state.limit}
            totalCount={this.props.products.length}
            page={this.state.currentPage}
            changePage={(value) => this.setState({ currentPage: value })}
          />
          </React.Fragment>
          : `There are no products in ${container}`
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ProductsModal;