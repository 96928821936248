import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Select from "react-select";
import IconEdit from "react-icons/lib/md/edit";
import IconEditDone from "react-icons/lib/md/done";
import IconEditCancel from "react-icons/lib/ti/cancel";
import { generateAlphabet, createOption } from "../../../../../helpers/utility";
import settingsActions from "../../../../../redux/settings/actions";
import CreatableSelect from 'react-select/lib/Creatable';

const {
  settingsGetMFTicketing,
  settingsUpdateMFTicketing,
} = settingsActions;

const generateRowOptions = () => {
  return generateAlphabet(true, true).map(item => {
    return { label: item, value: item }
  })
}

const generateLevelOptions = () => {
  return "123456789".split('').map(item => {
    return { label: item, value: item }
  })
}

const generateLengthOptions = () => {
  return "1234567".split('').map(item => {
    return { label: item, value: item }
  })
}

class PrintMFTicketSettings extends Component {

  state = {
    rowName: this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_row_name : '',
    rowEditMode: false,
    levelName: this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_level_name : '',
    levelEditMode: false,
    editableRowOption: false,
    editableLevelOption: false,
    editableSequenceOption: false,
    editableStartOption: false,
    row_options: generateRowOptions(),
    level_options: generateLevelOptions(),
    length: generateLengthOptions(),

    isLoading: false,
    rowValue: this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_row_value : '',
    levelValue: this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_level_value : '',
    shelf: this.props.mf_ticketing ? this.props.mf_ticketing.shelf : ''
  }

  componentDidMount = () => {
    this.props.settingsGetMFTicketing()
  };

  componentWillReceiveProps = (nextProps) => {
    let printData = nextProps.mf_ticketing.printer_metadata;
    if(printData) {
      if(this.state.rowName !== printData.shelf_row_name) {
        this.setState({...this.state, rowName: printData.shelf_row_name})
      }
      if(this.state.levelName !== printData.shelf_level_name) {
        this.setState({...this.state, levelName: printData.shelf_level_name})
      }
      if(this.state.rowValue !== printData.shelf_row_value) {
        this.setState({...this.state, rowValue: printData.shelf_row_value})
      }
      if(this.state.levelValue !== printData.shelf_level_value) {
        this.setState({...this.state, levelValue: printData.shelf_level_value})
      }
      if(nextProps.mf_ticketing.shelf && this.state.shelf !== nextProps.mf_ticketing.shelf) {
        this.setState({...this.state, shelf: nextProps.mf_ticketing.shelf})
      }
    }
  };

  processShelfSettings = () => {
    const { onSelectChange, onStartChange } = this.props;

    var shelf = this.props.mf_ticketing.shelf ? this.props.mf_ticketing.shelf : "A1-0001";
    shelf = shelf.split('-');
    if(shelf.length < 2){
      shelf = ['A1', '0001'];
    }
    const num = parseInt(shelf[1]);
    const len = shelf[1].length;

    shelf = shelf[0].split('');

    let row = this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_row_value : shelf[0];
    let level = this.props.mf_ticketing.printer_metadata ? this.props.mf_ticketing.printer_metadata.shelf_level_value : shelf[1];
    let length = len.toString();
    let start = (num == undefined || num == '' || isNaN(num)) ? '' : num.toString();

    onSelectChange('row', row)
    onSelectChange('level', level)
    onSelectChange('length', length)
    onStartChange(start)

    return {
      row: row,
      level: level,
      length: length,
      start: start,
    }
  }

  setCharAt = (str, index, chr) => {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
  }

  updateLabel = (key, val) => {
    var shelf = this.props.mf_ticketing.shelf ? this.props.mf_ticketing.shelf : "A1-0001";
    var shelfMetaData = this.props.mf_ticketing.printer_metadata; 
    if(key === 'row'){
      shelf = this.setCharAt(shelf, 0, val.value);
      this.props.onSelectChange(key, val.value)
      this.props.settingsUpdateMFTicketing({ shelf: shelf, printer_metadata: { shelf_row_value: val.value, shelf_row_name: shelfMetaData.shelf_row_name, shelf_level_name: shelfMetaData.shelf_level_name, shelf_level_value: shelfMetaData.shelf_level_value } });
    }
    if(key === 'level'){
      shelf = this.setCharAt(shelf, 1, val.value);
      this.props.onSelectChange(key, val.value)
      let newShelf = shelf.split('-')[0].replace(shelf.split('-')[0].slice(1), val.value) + '-' +shelf.split('-')[1]
      this.props.settingsUpdateMFTicketing({ shelf: newShelf, printer_metadata: { shelf_level_value: val.value, shelf_row_name: shelfMetaData.shelf_row_name, shelf_level_name: shelfMetaData.shelf_level_name, shelf_row_value: shelfMetaData.shelf_row_value } });
    }
    if(key === 'length'){
      shelf = shelf.split('-');
      var num = parseInt(shelf[1]);
      var padding = parseInt(val.value);
      shelf = `${shelf[0]}-${num.toString().padStart(padding, '0')}`
      this.props.onSelectChange("sequence_length", val.value)
      this.props.settingsUpdateMFTicketing({ shelf: shelf });
    }
    if(key === 'start'){
      if(val.length <= 7){
        shelf = shelf.split('-');
        padding = parseInt(shelf[1].length);
        num = val == '' ? '' : parseInt(val);
        shelf = `${shelf[0]}-${num.toString().padStart(padding, '0')}`
        this.props.onStartChange(val)
        this.props.settingsUpdateMFTicketing({ shelf: shelf });
      }
    }
  }

  updateFieldLabel = (field) => {
    if(field == 'rowName') {
      this.setState({rowEditMode: !this.state.rowEditMode})
    }
    if(field == 'levelName') {
      this.setState({levelEditMode: !this.state.levelEditMode})
    }
    this.props.settingsUpdateMFTicketing({ printer_metadata: { shelf_row_name: this.state.rowName, shelf_level_name: this.state.levelName, shelf_row_value: this.state.rowValue, shelf_level_value: this.state.levelValue } })
  }
  
  handleRowChange = (newValue, actionMeta) => {
    this.setState({ rowValue: newValue });
  };

  handleRowCreate = (inputValue) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { row_options } = this.state;
      const newOption = createOption(inputValue);
      this.setState({
        isLoading: false,
        row_options: [...row_options, newOption],
        value: newOption,
      });
    }, 1000);
  };

  handleLevelChange = (newValue, actionMeta) => {
    this.setState({ levelValue: newValue });
  };

  handleLevelCreate = (inputValue) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { level_options } = this.state;
      const newOption = createOption(inputValue);
      this.setState({
        isLoading: false,
        level_options: [...level_options, newOption],
        value: newOption,
      });
    }, 1000);
  };

  render() {
    const label_data = this.processShelfSettings();

    let { row_options, level_options, length, rowValue, levelValue, isLoading } = this.state;

    if(this.props.mf_ticketing.printer_metadata) {
      var { shelf_row_name, shelf_level_name } = this.props.mf_ticketing.printer_metadata;
    }

    // const level_options = this.generateLevelOptions();
    // const length = this.generateLengthOptions();

    return (
      <Card>
        <CardBody>
          <CardTitle>MF Ticket Label Settings</CardTitle>
          <FormGroup>
              {/* <Label>Aisle/Row</Label> */}
                <InputGroup className="mb-1">
                  <Input
                    disabled={!this.state.rowEditMode}
                    value={ this.state.rowName ? this.state.rowName : shelf_row_name }
                    placeholder="Enter label for Aisle/Row"
                    onChange={(e) => this.setState({ ...this.state, rowName: e.target.value})}
                  />
                  <InputGroupAddon addonType="append">
                    {this.state.rowEditMode ? (
                      <React.Fragment>
                        <Button
                          color="success"
                          disabled={!this.state.rowName}
                          onClick={() => this.updateFieldLabel('rowName')}
                        >
                          <IconEditDone size="20px" />
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => this.setState({ rowName: '' })}
                        >
                          <IconEditCancel size="20px" />
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Button
                        color="success"
                        onClick={() => this.setState({rowEditMode: !this.state.rowEditMode})}
                        >
                          <IconEdit size="20" />
                      </Button>
                    )}
                  </InputGroupAddon>
                </InputGroup>
              <InputGroup className="mb-1">
                {this.state.editableRowOption ? 
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={this.handleRowChange}
                    onCreateOption={this.handleCreate}
                    options={row_options}
                    value={rowValue}
                  /> :
                  <Select
                    options={row_options}
                    value={label_data.row}
                    clearable={false}
                    onChange={e => this.updateLabel('row', e)}
                    disabled={this.props.mf_ticketing_loading}
                  />
                }
                <InputGroupAddon addonType="append">
                  {this.state.editableRowOption ? (
                    <React.Fragment>
                      <Button
                        color="success"
                        onClick={() => this.setState({...this.state, editableRowOption: false})}
                      >
                        <IconEditDone size="20px" />
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      color="success"
                      onClick={() => this.setState({...this.state, editableRowOption: true})}
                      >
                        <IconEdit size="20" />
                    </Button>
                  )}
                </InputGroupAddon>
              </InputGroup>
          </FormGroup>
          <FormGroup>
              <InputGroup className="mb-1">
                  <Input
                    disabled={!this.state.levelEditMode}
                    value={this.state.levelName ? this.state.levelName : shelf_level_name }
                    placeholder="Enter label for Level"
                    onChange={(e) => this.setState({ ...this.state, levelName: e.target.value })}
                  />
                  <InputGroupAddon addonType="append">
                    {this.state.levelEditMode ? (
                      <React.Fragment>
                        <Button
                          color="success"
                          disabled={!this.state.levelName}
                          onClick={() => this.updateFieldLabel('levelName') }
                        >
                          <IconEditDone size="20px" />
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => this.setState({ levelName: '' })}
                        >
                          <IconEditCancel size="20px" />
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Button
                        color="success"
                        onClick={() => this.setState({levelEditMode: !this.state.levelEditMode})}
                        >
                          <IconEdit size="20" />
                      </Button>
                    )}
                  </InputGroupAddon>
                </InputGroup>
                <InputGroup className="mb-1">
                {this.state.editableLevelOption ? 
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={this.handleLevelChange}
                    onCreateOption={this.handleCreate}
                    options={level_options}
                    value={levelValue}
                  /> : 
                  (<Select
                    options={level_options}
                    value={label_data.level}
                    clearable={false}
                    onChange={e => this.updateLabel('level', e)}
                    disabled={this.props.mf_ticketing_loading}
                  />)
                }
                <InputGroupAddon addonType="append">
                  {this.state.editableLevelOption ? (
                    <React.Fragment>
                      <Button
                        color="success"
                        onClick={() => this.setState({...this.state, editableLevelOption: false})}
                      >
                        <IconEditDone size="20px" />
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      color="success"
                      onClick={() => this.setState({...this.state, editableLevelOption: true})}
                      >
                        <IconEdit size="20" />
                    </Button>
                  )}
                </InputGroupAddon>
              </InputGroup>
          </FormGroup>
          <FormGroup>
              <Label>Sequence Length</Label>
              <InputGroup className="mb-1">
                <Select
                  options={length}
                  value={length.find(item => item.value === label_data.length)}
                  clearable={false}
                  onChange={e => this.updateLabel('length', e)}
                  disabled={this.props.mf_ticketing_loading}
                />
              </InputGroup>
          </FormGroup>
          <FormGroup>
              <Label>Start at</Label>
              <Input
                value={label_data.start}
                onChange={e => this.updateLabel('start', e.target.value)}
                disabled={this.props.mf_ticketing_loading}
              />
          </FormGroup>
          <FormGroup>
              <Label>Sample: {this.state.shelf ? this.state.shelf : "A1-0001"}</Label>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
	state => {
		return {
			userData: state.Auth.get("userData"),
      mf_ticketing: state.Settings.get("mf_ticketing"),
      mf_ticketing_loading: state.Settings.get("mf_ticketing_loading"),
		}
	},
	{
    settingsGetMFTicketing,
    settingsUpdateMFTicketing,
	}
)(PrintMFTicketSettings);
