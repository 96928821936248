import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  categories: [],
  tags: [],
  save_category_working: false,
  delete_category_working: false,
  delete_category_working: false,
  save_tag_working: false,
  delete_tag_working: false,
  delete_tag_working: false,
  procedures: [],
  save_procedure_working: false,
  delete_procedure_working: false,
  update_procedure_working: false,
  export_procedure_working: false,
  sop_procedure: {},
});

export default function sopReducer(state = initState, action) {
	switch (action.type) {

    case actions.GET_SOP_PROCEDURE_SINGLE:
      return state
        .set("sop_procedure", {})
    case actions.GET_SOP_PROCEDURE_SINGLE_SUCCESS:
      return state
        .set("sop_procedure", action.data.data)
    case actions.GET_SOP_PROCEDURE_SINGLE_FAILED:
      return state
        .set("sop_procedure", {})

    case actions.EXPORT_SOP_PROCEDURE_PDF:
      return state
        .set("export_procedure_working", true)
    case actions.EXPORT_SOP_PROCEDURE_PDF_SUCCESS:
      return state
        .set("export_procedure_working", false)
    case actions.EXPORT_SOP_PROCEDURE_PDF_FAILED:
      return state
        .set("export_procedure_working", false)

    case actions.UPDATE_SOP_PROCEDURE:
      return state
        .set("update_procedure_working", true)
    case actions.UPDATE_SOP_PROCEDURE_SUCCESS:
      var update_procedures = state.get("procedures");
      update_procedures = update_procedures.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      });
      return state
        .set("procedures", Object.assign([], update_procedures))
        .set("update_procedure_working", false)
    case actions.UPDATE_SOP_PROCEDURE_FAILED:
      return state
        .set("update_procedure_working", false)

    case actions.DELETE_SOP_PROCEDURE:
      return state
        .set("delete_procedure_working", true)
    case actions.DELETE_SOP_PROCEDURE_SUCCESS:
      var delete_procedures = state.get("procedures");
      delete_procedures = delete_procedures.filter(item => item.id !== action.data.data.id);
      return state
        .set("procedures", Object.assign([], delete_procedures))
        .set("delete_procedure_working", false)
    case actions.DELETE_SOP_PROCEDURE_FAILED:
      return state
        .set("delete_procedure_working", false)

    case actions.SAVE_SOP_PROCEDURE:
      return state
        .set("save_procedure_working", true)
    case actions.SAVE_SOP_PROCEDURE_SUCCESS:
      var save_procedures = state.get("procedures");
      save_procedures.push(action.data.data);
      return state
        .set("procedures", Object.assign([], save_procedures))
        .set("save_procedure_working", false)
    case actions.SAVE_SOP_PROCEDURE_FAILED:
      return state
        .set("save_procedure_working", false)

	  case actions.GET_SOP_PROCEDURES_SUCCESS:
			return state
				.set("procedures", Object.assign([], action.data.data))

    case actions.UPDATE_SOP_TAG:
      return state
        .set("update_tag_working", true)
    case actions.UPDATE_SOP_TAG_SUCCESS:
      var update_tags = state.get("tags");
      update_tags = update_tags.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      });
      return state
        .set("tags", Object.assign([], update_tags))
        .set("update_tag_working", false)
    case actions.UPDATE_SOP_TAG_FAILED:
      return state
        .set("update_tag_working", false)

    case actions.DELETE_SOP_TAG:
      return state
        .set("delete_tag_working", true)
    case actions.DELETE_SOP_TAG_SUCCESS:
      var delete_tags = state.get("tags");
      delete_tags = delete_tags.filter(item => item.id !== action.data.data.id);
      return state
        .set("tags", Object.assign([], delete_tags))
        .set("delete_tag_working", false)
    case actions.DELETE_SOP_TAG_FAILED:
      return state
        .set("delete_tag_working", false)

    case actions.SAVE_SOP_TAG:
      return state
        .set("save_tag_working", true)
    case actions.SAVE_SOP_TAG_SUCCESS:
      var save_tags = state.get("tags");
      save_tags.push(action.data.data);
      return state
        .set("tags", Object.assign([], save_tags))
        .set("save_tag_working", false)
    case actions.SAVE_SOP_TAG_FAILED:
      return state
        .set("save_tag_working", false)

	  case actions.GET_SOP_TAGS_SUCCESS:
			return state
				.set("tags", Object.assign([], action.data.data))

    case actions.UPDATE_SOP_CATEGORY:
      return state
        .set("update_category_working", true)
    case actions.UPDATE_SOP_CATEGORY_SUCCESS:
      var update_categories = state.get("categories");
      update_categories = update_categories.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      });
      return state
        .set("categories", Object.assign([], update_categories))
        .set("update_category_working", false)
    case actions.UPDATE_SOP_CATEGORY_FAILED:
      return state
        .set("update_category_working", false)

    case actions.DELETE_SOP_CATEGORY:
      return state
        .set("delete_category_working", true)
    case actions.DELETE_SOP_CATEGORY_SUCCESS:
      var delete_categories = state.get("categories");
      delete_categories = delete_categories.filter(item => item.id !== action.data.data.id);
      return state
        .set("categories", Object.assign([], delete_categories))
        .set("delete_category_working", false)
    case actions.DELETE_SOP_CATEGORY_FAILED:
      return state
        .set("delete_category_working", false)

    case actions.SAVE_SOP_CATEGORY:
      return state
        .set("save_category_working", true)
    case actions.SAVE_SOP_CATEGORY_SUCCESS:
      var save_categories = state.get("categories");
      save_categories.push(action.data.data);
      return state
        .set("categories", Object.assign([], save_categories))
        .set("save_category_working", false)
    case actions.SAVE_SOP_CATEGORY_FAILED:
      return state
        .set("save_category_working", false)

	  case actions.GET_SOP_CATEGORIES_SUCCESS:
			return state
				.set("categories", Object.assign([], action.data.data))

	default:
		return state;
	}
}
