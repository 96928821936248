import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Button, Col, Popover, PopoverBody, Row } from 'reactstrap';
import BarcodePreview from './BarcodePreview';

class BarcodePopover extends Component {
  render() {
    const {
      isOpen,
      target,
      toggle,
      boxData,
      shipment,
      printQr
    } = this.props;

    return (
      <Popover placement="top" isOpen={isOpen} target={target} toggle={toggle} style={{ maxWidth: 800 }}>
        <PopoverBody>
          <Row>
            <Col xl={6}>
              <h6 className="text-center py-5">
                <BarcodePreview 
                  boxData={boxData} 
                  shipment={shipment} 
                />
              </h6>
              <div className="text-center">
                <Button 
                  color="success" 
                  size="sm"
                  onClick={() => printQr(boxData, shipment)}
                >
                  Print Barcode
                </Button>
              </div>
            </Col>
            <Col xl={6}>
              <h3>What is Box Content?</h3>
                <p>
                  What is Amazon FBA Box Content?
                  Amazon box content is the requirement to add inventory manifests (or lists) of product skus and quantities to each box in an inbound shipment that has multiple boxes. The box content requirement can be avoided if you send in one box per shipment to the Amazon FBA warehouse.
                </p>
                <h5>Do I need to worry about box content?</h5>
                  <p>
                    Yes and no.
                  </p>

                  <b>1 box / 1 shipment method</b>
                  <p>
                    If you only send in one box per shipment and keep that box to 50lbs then Amazon doesn’t need box content manifests because all of your inbound product SKUs are contained in one box.
                  </p>
                  <p>
                    Therefore it’s easy for Amazon to sort your SKUs once received to the warehouse.
                  </p>

                  <b>Multiple boxes / 1 shipment method</b>
                  <p>
                    In order to save on shipping charges and process numerous items per sitting, you may want to send in multiple boxes in your shipment. Because your items are spread out amongst the boxes, all Amazon to know needs what boxes contain what product SKUs. This is where box content requirements come into play.
                  </p>
            </Col>
          </Row>
        </PopoverBody>
      </Popover>
    )
  }
}

BarcodePopover.propTypes = {
  boxData: PropTypes.object,
  isOpen: PropTypes.bool,
  target: PropTypes.string,
  printQr: PropTypes.func,
  shipment: PropTypes.object
};

export default BarcodePopover;