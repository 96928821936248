import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Button
} from 'reactstrap';
import TemplateEditorModal from './TemplateEditorModal';
import repricerActions from '../../../../redux/repricer/actions';
import TemplatesTable from './components/TemplatesTable';
import { templateDefaultDataSetTC } from '../../../../helpers/repricer/templates/defaults/tc';

const {
  workingTemplateInit,
  userLoadTemplates,
  workingTemplateUpdateJson,
} = repricerActions;

class Templates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorOpen : false,
      editMode: false
    }
  }

  UNSAFE_componentWillMount(){
    this.props.userLoadTemplates();
  }

  toggleEditor = () => {
    this.setState({
      editorOpen: !this.state.editorOpen
    });
  }

  addTemplate = () => {
    var data = {
      name: "",
      user_id: this.props.userData.userName,
      priority: 1,
    }
    this.props.workingTemplateInit(data);
    this.toggleEditor();
    this.setState({ editMode: false })
  }

  startOver = () => {
    var data = {
      name: "",
      user_id: this.props.workingTemplate.userName,
      priority: 1,
      type: this.props.workingTemplate.type,
    }
    this.props.workingTemplateInit(data);
    if(data.type === 'tc'){
      this.props.workingTemplateUpdateJson({data: templateDefaultDataSetTC});
    } else {
      this.props.workingTemplateUpdateJson({data: templateDefaultDataSetTC});
    }
  }

  render() {
    return(
      <Card>
        <CardBody>
          <div className="mb-4 text-right">
            <Button
              color="primary"
              onClick={this.addTemplate}
            >ADD A NEW TEMPLATE</Button>
            <TemplateEditorModal
              editorOpen={this.props.workingTemplate ? this.state.editorOpen : false}
              toggleEditor={this.toggleEditor}
              workingTemplate={this.props.workingTemplate || {}}
              startOver={this.startOver}
              updateActiveRepricerTab={this.props.updateActiveRepricerTab}
              editMode={this.state.editMode}
            />
          </div>
          <TemplatesTable
          templates={this.props.userTemplates}
          toggleEditor={this.toggleEditor}
          workingTemplate={this.props.workingTemplate}
          onEdit={(val) => this.setState({ editMode: val })}
          />
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
    userTemplates: state.Repricer.get("userTemplates"),
    workingTemplate: state.Repricer.get("workingTemplate"),
  }),
  {
    workingTemplateInit,
    userLoadTemplates,
    workingTemplateUpdateJson,
  }
)(Templates);
