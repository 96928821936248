import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Row,
  Badge
} from "reactstrap";
import PropTypes from "prop-types";
import {
  momentDateIsValid,
  momentDateToISOFormatConversion,
  secureProtocolImgURL,
  digitСonversion,
} from "../../../../helpers/utility";
import ReplenishmentWarningModalTable from "../displays/shared/ReplenishmentWarningModalTable";
import { getConditionOptions } from "../../../../helpers/batch/utility";

const condEum = getConditionOptions();

class ReplenishmentWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addQty: 1,
      newPrice: null,
      replenishableListings: []
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { currentSelectedSearchResult } = newProps;
    const { batchMetadata } = this.props;

    if (currentSelectedSearchResult){
      const { replenishableListings } = currentSelectedSearchResult;
      let parsedReplenishableListings = [];

      replenishableListings.forEach(element => {
        let buff = JSON.parse(element);
				if(batchMetadata && buff.fulfillment_channel === batchMetadata.channel){
	        buff.expDate = '';
					buff.active_quantity = buff.quantity;
					buff.quantity = 1
				  parsedReplenishableListings.push(buff);
				}
      });

      if (parsedReplenishableListings.length > 0){
        this.setState({
          replenishableListings: parsedReplenishableListings
        })
      }
    }
  }

  listWithNewMSKU = () => {
    const {
      addItemToBatch,
      currentListingWorkflowOptions,
    } = this.props;
    this.props.setCurrentFlow("listing_creator_display");
    this.props.close();
    if (currentListingWorkflowOptions.speedMode) {
      addItemToBatch(null, false);
    }
  };

  addItemToBatch = (replenishableListing) => {
    const {
      currentSelectedSearchResult,
      addItemToBatch,
      close,
      batchListingDefaults,
      batchMetadata,
    } = this.props;

    let expDateToString = momentDateIsValid(replenishableListing.expDate) ? momentDateToISOFormatConversion(replenishableListing.expDate) : '';
    const currentSelectedSearchResultWithBatchListingDefaults = Object.assign({}, batchListingDefaults, currentSelectedSearchResult)
    const payload = {
      ...currentSelectedSearchResultWithBatchListingDefaults,
      searchResultData: Object.assign({}, currentSelectedSearchResult),
      condition: (condEum[replenishableListing.item_condition] || condEum[0]).value,
      maxPrice: replenishableListing.max_price || batchListingDefaults.maxPrice,
      minPrice: replenishableListing.min_price || batchListingDefaults.minPrice,
      price: replenishableListing.price || batchListingDefaults.price,
      buyCost: replenishableListing.buy_cost || batchListingDefaults.buyCost,
      asin: currentSelectedSearchResult.ASIN,
      sku: replenishableListing.seller_sku,
      expDate: expDateToString || batchListingDefaults.expDate,
      datePurchased: replenishableListing.date_purchased || batchListingDefaults.datePurchased,
      qty: replenishableListing.quantity || batchListingDefaults.qty,
      note: replenishableListing.item_note || batchListingDefaults.note,
      batchChannel: batchMetadata.channel,
    };
    //addItemToBatch(payload, false);
    const profit = {
      asin: payload.asin,
      price: payload.price.toString(),
    }
    this.props.batchAddToBatchReplenish({
      listing: payload,
      profit: profit
    })
    close();
  }

  onChangeItemValue = (item) => {
    let replenishableListings = [...this.state.replenishableListings];

    Object.keys(replenishableListings).forEach(element => {
      if (replenishableListings[element].seller_sku === item.seller_sku) {
        replenishableListings[element] = Object.assign({}, item);
      }
    })

    this.setState({
      replenishableListings
    })
  }

  displayBuyBox = (data) => {
    let new_bb = data.new_buy_box?.landed_price;
    let used = data.used_buy_box?.landed_price;
    if(new_bb){
      new_bb = digitСonversion(new_bb, "currency", data.new_buy_box.landed_price_currency);
    } else {
      new_bb = '-';
    }
    if(used){
      used = digitСonversion(used, "currency", data.used_buy_box.landed_price_currency);
    } else {
      used = '-';
    }
    return `${new_bb} | ${used}`
  }

  render() {
    const {
      isOpen,
      close,
      currentSelectedSearchResult,
      currentWorkingListingData,
    } = this.props;
    const { replenishableListings } = this.state;
    if (!currentSelectedSearchResult || !currentSelectedSearchResult.replenishableListings || currentSelectedSearchResult.replenishableListings.length === 0) {
      return null;
    }
    const {
      imageUrl,
      ASIN,
      name,
      salesrank
    } = currentSelectedSearchResult;

    return (
      <div>
        {currentSelectedSearchResult ? (
          <Modal isOpen={isOpen} size="lg" className="replenish-modal">
            <ModalHeader>
              <strong>Existing MSKU</strong>
              <br />
              <small>
                You currently have MSKU(s) matching this ASIN in your inventory
              </small>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="6">
                  <div className="d-flex gap-3">
                    <img src={secureProtocolImgURL(imageUrl)} alt="Product Listing" />
                    <div>
                      <h6>{name || ""}</h6>
                      <Badge color="info">{`Rank: ${salesrank || ""}`}</Badge>
                      <Badge color="info" className="ml-2">{`ASIN: ${ASIN || ""}`}</Badge>
                    </div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="sales-rank-stats">
                    <div className="badge-stat-group justify-content-end">
                      <div>
                        <strong className="d-block">Est. Sales</strong>
                        {currentWorkingListingData && currentWorkingListingData.profithunt_data_sales ?
                        <span className="badge-stat orange">
                          {currentWorkingListingData.profithunt_data_sales.qtr_sales_count}/qtr
                        </span>
                        : <span className="badge-stat skeleton">Loading</span>
                        }
                      </div>
                      <div className="ml-4">
                        <strong className="d-block">Buy Box (New | Used)</strong>
                        {currentWorkingListingData && currentWorkingListingData.pricingData
                            && currentWorkingListingData.pricingData.competitive_pricing
                            && currentWorkingListingData.pricingData.competitive_pricing[ASIN]
                              ? <span className="badge-stat purple">
                                {this.displayBuyBox(currentWorkingListingData.pricingData.competitive_pricing[ASIN])}
                                </span>
                              : <span className="badge-stat skeleton">Loading</span>
                        }
                      </div>
                      <div className="ml-4">
                        <strong className="d-block">eBay Price</strong>
                        {currentWorkingListingData && currentWorkingListingData.profithunt_data_sales ?
                            currentWorkingListingData.profithunt_data_sales.ebay ?
                            <span className="badge-stat blue">
                              {currentWorkingListingData.profithunt_data_sales.ebay.ebay_new_shipping}/{currentWorkingListingData.profithunt_data_sales.ebay.ebay_used_shipping}
                            </span>
                            : <span className="badge-stat blue">N/A</span>
                        : <span className="badge-stat skeleton">Loading</span>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <ReplenishmentWarningModalTable
                replenishableListings={replenishableListings}
                onChangeItemValue={this.onChangeItemValue}
                addItemToBatch={this.addItemToBatch}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={close}>
                Cancel
              </Button>
              <Button color="success" onClick={this.listWithNewMSKU}>
                List With New MSKU
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}

ReplenishmentWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentSelectedSearchResult: PropTypes.object,
  currentListingWorkflowOptions: PropTypes.object,
  close: PropTypes.func.isRequired,
  addItemToBatch: PropTypes.func.isRequired,
  setCurrentFlow: PropTypes.func.isRequired,
  batchListingDefaults: PropTypes.object,
  batchMetadata: PropTypes.object,
  batchAddToBatchReplenish: PropTypes.func.isRequired,
  currentWorkingListingData: PropTypes.object,
};

export default ReplenishmentWarningModal;
