import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* getEmployeesTrackingData() {
    yield takeLatest('GET_EMPLOYEES_TRACKING', function* (payload) {
      const { data } = payload;
      try {
        const response = yield call(backendAPICall, 'get', apiEndpoints.employees.tracking, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getEmployeesTrackingDataSuccess(response.data.employees))
					} else {
						yield put(actions.getEmployeesTrackingDataFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Employees api error"));
					yield put(actions.getEmployeesTrackingDataFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_EMPLOYEES_TRACKING_ERROR"
            }
          });
        }
    })
}

export default function* employeesSagas() {
	yield all([
		fork(getEmployeesTrackingData),
	]);
}
