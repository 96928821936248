import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

class CollapsibleFormGroup extends Component {
  render() {
    const {
      title,
      collapse,
      toggleCollapse,
      children
    } = this.props;

    return (
      <div className="section">
        <div
          className={`section-header ${collapse ? 'collapsed' : ''}`}
          onClick={toggleCollapse}
        >
          <span className="icon icon-caret mr-1" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="collapse-wrapper">
          <Collapse isOpen={!collapse}>
            {children}
          </Collapse>
        </div>
      </div>
    )
  }
}

export default CollapsibleFormGroup;