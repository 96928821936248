import React, { Component } from 'react';
import { Button, Input, Label, ButtonGroup, FormGroup } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import DeleteDialog from "../../../../../shared/components/dialogs/DeleteDialog";

class PredefinedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      alertShown: false,
      list_name: "",
    }
  }

	UNSAFE_componentWillMount(){
        this.setState({ list_name: this.props.data.list_name });
	}

  toggleMode = () => {
    this.setState({
      isEditing: !this.state.isEditing,
      list_name: "",
    });
  }

  confirmToDelete = () => {
    this.setState({
      alertShown: true,
      list_name: "",
    });
  }

  confirmDeleteList = () => {
    this.setState({
      alertShown: false,
      list_name: "",
    });
	  this.props.onDeleteList(this.props.data.id);
  }

  keyDown = (e) => {
    if(e.keyCode === 13){
      if(this.state.list_name.trim()) {
        this.props.onNameChanged(this.props.data, this.state.list_name.trim());
      }
      this.toggleMode();
    }
  }

  setPreviousListName = () => {
    this.setState({
      list_name: this.props.data.list_name
    }, () => this.toggleMode());
  }

  render() {
    const {
      type,
      data,
      onListChanged
    } = this.props;

    const {
      isEditing,
      alertShown
    } = this.state;
    let inputPlaceholder;

    if(type === 'seller') {
      inputPlaceholder = 'Add seller'
    //} else if (type === 'seller') {
      //inputPlaceholder = 'Add SKU'
    }

    return (
      <FormGroup className="predefined-input">
        {isEditing ?
          <Input
            type="text"
            className="mb-2"
            defaultValue={data.list_name}
            onChange={(e) => this.setState({ list_name: e.target.value })}
            onKeyDown={this.keyDown}
            maxLength="70"
            placeholder="List name (70 chars max)"
          /> :
          <div className="d-flex align-items-center justify-content-between">
            <Label className="mb-0">{data.list_name}</Label>
            <ButtonGroup size="lg">
              <Button color="link" className="p-0" onClick={this.toggleMode}><span className="icon icon-edit"></span></Button>
              <Button color="link" className="text-danger p-0 ml-2" onClick={this.confirmToDelete}><span className="icon icon-trash bg-danger"></span></Button>
            </ButtonGroup>
          </div>
        }

        <TagsInput
          value={data.datas}
          onChange={onListChanged}
          inputProps={{
            placeholder: inputPlaceholder
          }}
        />
        <DeleteDialog
          isOpen={alertShown}
          close={() => this.setState({ alertShown: false })}
          deleteItem={this.confirmDeleteList}
          dialogTitle="Are you sure want to delete this list?"
          dialogText={`You will loose "${data?.list_name}".`}
          confirmText="Yes, delete list"
        />
      </FormGroup>
    )
  }
}

export default PredefinedList;
