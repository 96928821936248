import React, { Component } from 'react';
import {
  ResponsiveContainer, ComposedChart, Bar, XAxis,
  YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { digitСonversion } from "../../../../helpers/utility";
import PeriodTypeSelect from './PeriodTypeSelect';
import chart_config from "../chart_config";
import CustomizedLabel from "../CustomizedLabel";
import getGradient from "../chart_gradients";

class UnitListedSoldChart extends Component {
  state = {
    date: null
  }

  componentDidMount() {
    const { chartData } = this.props;
    const chartDate = chartData.date ? moment(chartData.date) : moment();
    this.props.fetchChartData('unit_listed', chartDate.toISOString(), this.props.chartData.period_type);
    this.setState({ date: chartDate })
  }

  handleSelectDate = (date) => {
    this.setState({ date: date });
    this.props.setChartEndDate(date.toISOString(), "unit_listed");
    this.props.fetchChartData("unit_listed", date.toISOString(), this.props.chartData.period_type);
  }

  moneyFormat = (val) => {
    return digitСonversion(val, "currency", this.props.internationalizationConfig.currency_code);
  }

  render() {
    const { chartData, setPeriodType, fetchChartData } = this.props
    return (
      <React.Fragment>
        <div className="mb-4 row" style={{ width: "25rem" }}>
          <div className="col-6">
            <PeriodTypeSelect
              chartData={chartData}
              chartType="unit_listed"
              setPeriodType={setPeriodType}
              fetchChartData={fetchChartData}
            />
          </div>
          <div className="col-6">
            <DatePicker selected={this.state.date} onChange={date => this.handleSelectDate(date)} />
          </div>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={250}
        >
          <ComposedChart
            data={chartData.data}
            margin={{
              top: 35, left: 30
            }}
          >
            <defs>
              {getGradient("gradientOrangeSold","orange")}
              {getGradient("gradientBlueListed","blue")}
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={chart_config.cartesianStrokeColor}
            />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
            />
            <Tooltip
              labelFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
              cursor={false}
            />
            <Legend />
            <Bar
              name="Units Listed"
              dataKey="unit_listed"
              fill="url(#gradientBlueListed)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel type="decimal" />}
            />
            <Bar
              name="Units Sold"
              dataKey="units_sold"
              fill="url(#gradientOrangeSold)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel type="decimal" />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

UnitListedSoldChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  internationalizationConfig: PropTypes.object.isRequired,
  setPeriodType: PropTypes.func.isRequired,
  setChartEndDate: PropTypes.func.isRequired,
  fetchChartData: PropTypes.func.isRequired
}

export default UnitListedSoldChart;
