import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* searchSbybData() {
    yield takeLatest('SEARCH_SBYB_DATA', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.search.sbyb, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.searchSbybDataSuccess(response.data.data))
					} else {
						yield put(actions.searchSbybDataFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Inventory api error"));
					yield put(actions.searchSbybDataFailed());
          logError(error, {
            tags: {
              exceptionType: "SEARCH_SBYB_DATA_ERROR"
            }
          });
        }
    })
}

export default function* sbybSaga() {
	yield all([
		fork(searchSbybData),
	]);
}
