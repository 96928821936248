import React from "react";
import screenfull from "screenfull";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip, Badge, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
// icons
import IconFullScreen from "react-icons/lib/md/crop-free";
import IconMenu from "react-icons/lib/md/menu";
import IconNotification from "react-icons/lib/md/notifications-none";
import IconBulb from "react-icons/lib/fa/lightbulb-o";
import IconManual from "react-icons/lib/md/help";
import IconSearch from "react-icons/lib/fa/search";
import { MdVolumeOff as IconMute, MdVolumeUp as IconVolume } from "react-icons/lib/md";
import { HeadwayAccount, accelerlistHelpDocsURL, featureRequestsURL } from "../../../config/mediaLinks";
// style
import "./style.css";
import SearchModal from "../SearchModal";
import Toggle from "../Toggle";

class Header extends React.Component {
  state = {
    unseenNotificationCount: 0,
    showWidget: false,
    showSearchModal: false,
    darkMode: false // default
  }

  componentDidMount() {
	  const { userData } = this.props;
    /*
    const self = this;
    self.initNotificationsWidget();
    setInterval(() => {
      self.initNotificationsWidget();
    }, 1 * 60 * 1000);
    */
    this.checkMFSalesUnfinished();
    setInterval(() => {
      this.checkMFSalesUnfinished();
    }, 5 * 60 * 1000);

    // check if dark mode options has already set on localstorage
    const local_darkmode = window.localStorage.getItem("al_darkmode")
    if(local_darkmode !== null) {
      this.toggleDarkModeClass(local_darkmode === "true")
      this.setState({ darkMode: local_darkmode === "true" })
    } else {
      // if not found on localstorage, fetch it from db
      const db_darkmode = userData?.settings?.darkmode ? true : false
      this.toggleDarkModeClass(db_darkmode)
      this.setState({ darkMode: db_darkmode })
      window.localStorage.setItem("al_darkmode", db_darkmode)
    }
  }

  toggleDarkMode = () => {
	  const { userData } = this.props;

    const newDarkMode = !this.state.darkMode
    this.toggleDarkModeClass(newDarkMode)
    this.setState({ darkMode: newDarkMode })
    // save it to localstorage and db too
    window.localStorage.setItem("al_darkmode", newDarkMode)
    //==> code saving to db here
    let ud = userData;
    ud.settings["darkmode"] = newDarkMode;
    this.props.updateUserData(ud);
    let data = {darkmode: ud.settings["darkmode"]};
    this.props.updateUserSettings(data);
  }

  toggleDarkModeClass = (isDarkMode) => {
    if(isDarkMode) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
  }

  checkMFSalesUnfinished = () => {
    const { userData, checkMFSalesUnfinished } = this.props;
    if(userData && userData.is_ebay_enabled){
      checkMFSalesUnfinished();
    }
  }

  initNotificationsWidget = () => {
    const self = this;
    const { showWidget } = this.state;
    const HW_config = {
      selector: ".iconNotification",
      account: HeadwayAccount,
      trigger: ".triggerNotificationPopover",
      callbacks: {
        onWidgetReady: () => self.getNotifications(),
        onShowWidget: () => self.setShowWidgetValue(true),
        onHideWidget: () => {
          self.setShowWidgetValue(false);
          self.getNotifications();
        }
      }
    }
    if (!showWidget) {
      window.Headway.init(HW_config);
    }
  }
  setShowWidgetValue = (value) => {
    if (value) {
      this.setState({
        showWidget: true
      })
    } else {
      this.setState({
        showWidget: false
      })
    }
  }

  getNotifications = () => {
    const { unseenNotificationCount } = this.state;
    const currentUnseenNotificationCount = window.Headway.getUnseenCount();
    if (unseenNotificationCount !== currentUnseenNotificationCount) {
        this.setState({
          unseenNotificationCount: currentUnseenNotificationCount
        })
    }
  }

	updatePlaySound = () => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["sound_active"] = !ud.settings["sound_active"];
			this.props.updateUserData(ud);
			let data = {sound_active: ud.settings["sound_active"]};
			this.props.updateUserSettings(data);
		}
	}

  render() {
    const { userData } = this.props;
    const { unseenNotificationCount, darkMode } = this.state;

    return (
      <header className="site-head d-flex align-items-center justify-content-between">
        <div className="wrap">
          <span className="icon icon-robot icon-20"></span>
        </div>
        <div className="app-searchbar">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <span className="icon icon-search"></span>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search here for help..."
              onClick={() => this.setState({ showSearchModal: true })}
              readOnly
            />
          </InputGroup>
        </div>
        {/* {userData && userData.is_ebay_enabled && this.props.ebay_mf_sales_unfinished ?
          <Badge
            style={{ background: "#ffe000", color: "#524904", whiteSpace: "pre-wrap", maxWidth: "40%", textAlign: "left" }}
          >You have MF sales that need to be shipped for eBay</Badge>
          : null
        } */}
        <div className="ml-auto">
          <div className="right-elems d-flex align-items-center">
            <div className="wrap">
              <div>
                <a
                  href="https://www.accelerlist.com/blog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text">Blog</span>
                </a>
              </div>
            </div>
            {/*
            <div className="wrap">
              <div>
                <NavLink
                  to="/dashboard/guide"
                >
                  <span className="text">Video Tutorials</span>
                </NavLink>
              </div>
            </div>
            */}
            <div className="wrap">
              <div>
                <NavLink
                  to="/dashboard/sop"
                >
                  <span className="text">SOP</span>
                </NavLink>
              </div>
            </div>
            <div className="wrap hidden-sm-down">
              <div className="color-mode-switch">
                <Toggle
                  checked={darkMode}
                  onText="&#9789;"
                  offText="&#9788;"
                  onChange={this.toggleDarkMode}
                />
              </div>
            </div>
            <div className="wrap hidden-sm-down">
              <a
                onClick={() => this.updatePlaySound()}
              >
                {userData && userData.settings && userData.settings.sound_active ? <span className="icon icon-speaker"></span> : <span className="icon icon-mute"></span>}
              </a>
            </div>
            <div className="wrap hidden-sm-down">
              <a href={accelerlistHelpDocsURL} target="_blank" rel="noopener noreferrer" id="manual">
                <span className="icon icon-help"></span>
              </a>
              <UncontrolledTooltip placement="left" target="manual">Go to Manual</UncontrolledTooltip>
            </div>
            <div className="wrap hidden-sm-down">
              <a href={featureRequestsURL} target="_blank" rel="noopener noreferrer" id="feature-request">
                <span className="icon icon-bulb"></span>
              </a>
              <UncontrolledTooltip placement="left" delay={{hide: 0}} target="feature-request">Suggest a Feature</UncontrolledTooltip>
            </div>
            <div className="wrap notify hidden-sm-down">
              <div>
                <span className="icon icon-bell triggerNotificationPopover" id="notificationToggle"></span>
                {
                  unseenNotificationCount && unseenNotificationCount > 0
                  ? (
                    <span className="badge badge-danger">{unseenNotificationCount}</span>
                  )
                  : ""
                }
                <div className="iconNotification" />
              </div>
              <UncontrolledTooltip placement="top" delay={{hide: 0}} target="notificationToggle">Show Notification</UncontrolledTooltip>
            </div>
            <div className="wrap hidden-sm-down">
              <span className="icon icon-fullscreen" onClick={() => screenfull.toggle()} id="toggleFullscreen"></span>
              <UncontrolledTooltip placement="left" delay={{hide: 0}} target="toggleFullscreen">Toggle Fullscreen</UncontrolledTooltip>
            </div>
          </div>
        </div>
        <SearchModal
          isOpen={this.state.showSearchModal}
          toggle={() => this.setState({ showSearchModal: !this.state.showSearchModal })}
        />
      </header>
    );
  }
}

export default Header;

