import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* messageInAppGet() {
    yield takeLatest('MESSAGE_IN_APP_GET', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.message.in_app, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppGetSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppGetFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppGetFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_GET_ERROR"
            }
          });
        }
    })
}

export function* messageInAppGetCreate() {
    yield takeLatest('MESSAGE_IN_APP_CREATE', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.message.in_app, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppGetCreateSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppGetCreateFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppGetCreateFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_CREATE_ERROR"
            }
          });
        }
    })
}

export function* messageInAppGetUpdate() {
    yield takeLatest('MESSAGE_IN_APP_UPDATE', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'put', apiEndpoints.message.in_app, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppGetUpdateSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppGetUpdateFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppGetUpdateFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_UPDATE_ERROR"
            }
          });
        }
    })
}

export function* messageInAppGetDelete() {
    yield takeLatest('MESSAGE_IN_APP_DELETE', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'delete', apiEndpoints.message.in_app, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppGetDeleteSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppGetDeleteFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppGetDeleteFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_DELETE_ERROR"
            }
          });
        }
    })
}

export function* messageInAppUploadImage() {
    yield takeLatest('MESSAGE_IN_APP_UPLOAD_IMAGE', function* (payload) {
      try {
        const { file } = payload.data;
        const data = new FormData();
        data.append('file', file, file.name);
				const response = yield call(backendAPICall, 'post', apiEndpoints.message.image_upload, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.messageInAppUploadImageSuccess(response.data));
				} else {
				  yield put(appActions.apiCallFailed(response.data.error));
					yield put(actions.messageInAppUploadImageFailed());
				}
     } catch (error) {
				yield put(appActions.apiCallFailed("Error! Messages api error"));
				yield put(actions.messageInAppUploadImageFailed());
				logError(error, {
					tags: {
						exceptionType: "MESSAGE_IN_APP_UPLOAD_IMAGE"
					}
				});
		 }
    })
}

export function* messageInAppUploadImageClear() {
    yield takeLatest('MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR', function* (payload) {
			yield put(actions.messageInAppUploadImageClearSuccess());
    })
}

export function* messageInAppGetUser() {
    yield takeLatest('MESSAGE_IN_APP_GET_USER', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.message.in_app_user, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppGetUserSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppGetUserFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppGetUserFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_GET_USER_ERROR"
            }
          });
        }
    })
}

export function* messageInAppSetTracking() {
    yield takeLatest('MESSAGE_IN_APP_SET_TRACKING', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.message.in_app_user_tracking, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.messageInAppSetTrackingSuccess(response.data.data))
					} else {
						yield put(actions.messageInAppSetTrackingFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Messages api error"));
					yield put(actions.messageInAppSetTrackingFailed());
          logError(error, {
            tags: {
              exceptionType: "MESSAGE_IN_APP_SET_TRACKING_ERROR"
            }
          });
        }
    })
}

export default function* messageSaga() {
	yield all([
		fork(messageInAppGet),
		fork(messageInAppGetCreate),
		fork(messageInAppGetUpdate),
		fork(messageInAppGetDelete),
    fork(messageInAppUploadImage),
    fork(messageInAppUploadImageClear),
		fork(messageInAppGetUser),
		fork(messageInAppSetTracking),
	]);
}
