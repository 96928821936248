import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MetricItem extends Component {
  render() {
    const { value, title, className } = this.props;

    return (
      <div className="card metric-item">
        <span className="title">{title}</span>
        <span className={`value ${className}`}>{value}</span>
      </div>
    );
  }
}

MetricItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
}

export default MetricItem;