import React, { Component } from 'react';
import { FormGroup, UncontrolledCollapse, Button, Input, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import ReactTable from "react-table";
import DeleteDialog from '../../../../shared/components/dialogs/DeleteDialog';
import Select from 'react-select';

class ManageFAQ extends Component {
  state = {
    editFaq: null,
    deleteFaqId: null,
    new_topic_selected: null,
    new_title: "",
    new_answer: "",
    editFaqModalShow: false,
    editTopicValue: null,
  }

  executeEditArticle = () => {
    const data = {
      article_id: this.state.editFaq.id,
      topic_id: this.state.editTopicValue.value,
      title: this.state.editFaq.title,
      answer: this.state.editFaq.answer,
    }
    this.props.updateFaqArticle(data);
    this.toggleModalEdit();
  }

  uppdateEditFaq = (key, value) => {
    let faq = this.state.editFaq;
    faq[key] = value;
    this.setState({ editFaq: faq });
  }

  updateEditTopicValue = (e) => {
    this.setState({ editTopicValue: e });
  }

  toggleModalEdit = (faq) => {
    this.setState({
      editFaq: Object.assign({}, faq),
      editFaqModalShow: !this.state.editFaqModalShow,
    })
    if(!this.state.editFaqModalShow){
      this.setState({
        editTopicValue: this.props.topics.find(item => item.value === faq.topic_id),
      });
    }
  }

  toggleConfirmDelete = (id) => {
    let faqId = this.state.deleteFaqId;
    this.setState({ deleteFaqId: faqId ? null : id })
  }

  deleteFaq = (id) => {
    const data = {
      article_id: id,
    }
    this.props.deleteFaqArticle(data);
    this.toggleConfirmDelete();
  }

  getTopicName = (topic_id) => {
    if(this.props.topics){
      const data = this.props.topics.find(item => item.value === topic_id);
      if(data?.label){
        return data.label;
      }
    }
    return ""
  }

  generateColumns = () => {
    let tableColumns = [
      {
        id: "topic",
        accessor: "topic",
        Header: <span>Topic</span>,
        headerClassName: "text-left",
        Cell: props => (
          <div>
            {this.getTopicName(props.original.topic_id)}
          </div>
        )
      },
      {
        id: "title",
        accessor: "title",
        Header: <span>Title</span>,
        headerClassName: "text-left"
      },
      {
        id: "answer",
        accessor: "answer",
        Header: <span>Answer</span>,
        headerClassName: "text-left"
      },
      {
        id: "df1",
        accessor: "id",
        Header: <span>Actions</span>,
        headerClassName: "text-left",
        width: 150,
        Cell: props => (
          <div>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.toggleModalEdit(props.original)}
            >Edit</Button>
            <Button
              color="danger"
              size="sm"
              className="ml-2"
              onClick={() => this.toggleConfirmDelete(props.original.id)}
            >Delete</Button>
          </div>
        )
      },
    ]

    return tableColumns;
  }

  generateDataSet = (topic_type) => {
    const topic_ids = this.props.topics.map(item => {
      return item.value;
    });
    return this.props.data.filter(item => topic_ids.includes(item.topic_id));
  }

  addNewArticle = () => {
    const data = {
      title: this.state.new_title,
      answer: this.state.new_answer,
      topic_id: this.state.new_topic_selected.value,
    }
    this.props.addFaqArticle(data);
    this.setState({
      new_topic_selected: null,
      new_title: "",
      new_answer: "",
    });
  }

  render() {
    return (
      <div className="manage-faq">
        <div>
          <h3 className="h4"><Button size="sm" color="link" id="add-faq">Click to add new FAQ</Button></h3>
          <UncontrolledCollapse toggler="add-faq">
            <FormGroup row>
              <Col md={6}>
                <Select
                  placeholder="Choose topic..."
                  options={this.props.topics}
                  value={this.state.new_topic_selected}
                  onChange={e => this.setState({ new_topic_selected: e })}
                />
              </Col>
              <Col md={6}>
                <Input
                  placeholder="FAQ Title"
                  value={this.state.new_title}
                  onChange={e => this.setState({ new_title: e.target.value })}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                placeholder="FAQ Answer"
                value={this.state.new_answer}
                onChange={e => this.setState({ new_answer: e.target.value })}
              />
            </FormGroup>
            <Button
              color="primary"
              onClick={e => this.addNewArticle()}
              disabled={
                !this.state.new_topic_selected
                  || !this.state.new_title
                  || !this.state.new_answer
              }
            >Save</Button>
          </UncontrolledCollapse>
        </div>
        <div className="mt-5">
          <h3 className="h4">FAQs</h3>
          <ReactTable
            columns={this.generateColumns()}
            data={this.generateDataSet(this.props.category)}
            defaultPageSize={10}
            minRows={0}
          />
        </div>
        <Modal isOpen={this.state.editFaqModalShow} toggle={() => this.toggleModalEdit()}>
          <ModalHeader toggle={() => this.toggleModalEdit()}>Edit FAQ</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Select
                placeholder="Choose topic..."
                options={this.props.topics}
                value={this.state.editTopicValue}
                onChange={e => this.updateEditTopicValue(e)}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="FAQ Title"
                value={this.state.editFaq ? this.state.editFaq.title : ""}
                onChange={e => this.uppdateEditFaq("title", e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                placeholder="FAQ Answer"
                value={this.state.editFaq ? this.state.editFaq.answer : ""}
                onChange={e => this.uppdateEditFaq("answer", e.target.value)}
              />
            </FormGroup>
            <Button
              color="primary"
              onClick={e => this.executeEditArticle()}
              disabled={
                !this.state.editTopicValue
                  || (this.state.editFaq && !this.state.editFaq.title)
                  || (this.state.editFaq && !this.state.editFaq.answer)
              }
            >Save</Button>
          </ModalBody>
        </Modal>
        <DeleteDialog
          isOpen={this.state.deleteFaqId !== null}
          close={() => this.toggleConfirmDelete(null)}
          deleteItem={() => this.deleteFaq(this.state.deleteFaqId)}
          dialogText="Are you sure want to delete this FAQ?"
        />
      </div>
    )
  }
}

export default ManageFAQ;
