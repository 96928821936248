import React, { Component } from 'react';
import moment from 'moment';
import {DropdownToggle, DropdownMenu, DropdownItem, Dropdown, FormGroup, Label } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './style.css';

const predefinedRanges = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}

class IntervalDropdown extends Component {
  state = {
    calendarOpen: false,
    startDate: moment().subtract(7, 'days'),
    endDate: moment()
  }

  closeCalendar = (e) => {
    e.stopPropagation();
    this.setState({ calendarOpen: false })
  }

  render() {
    const {
      options,
      selected,
      isOpen,
      toggle,
      onSelect,
      currentRange,
      customRangeChange,
      className,
    } = this.props;

    let start,end,labelCustom;
    if(selected.value === "custom") {
      start = moment(currentRange.startDate).format('MMM D, YYYY');
      end = moment(currentRange.endDate).format('MMM D, YYYY');
      labelCustom = start + ' - ' + end;
      if (start === end) {
        labelCustom = start;
      }
    }

    return (
      <FormGroup>
        <Label>Select Period:</Label>
        <div className={`interval-dropdown ${className ? className : ""}`}>
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle caret>
              {selected.label}
            </DropdownToggle>
            <DropdownMenu>
              {options.map((item, idx) => (
                <DropdownItem 
                  active={item.value === selected.value} 
                  key={idx} 
                  tag="a" 
                  onClick={() => onSelect({value: item.value, label: item.label})}
                  >{item.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {selected.value === "custom" &&
          <div className="custom-range">
            <DateRangePicker
              startDate={currentRange.startDate}
              endDate={currentRange.endDate}
              ranges={predefinedRanges}
              onEvent={customRangeChange}
            >
              <input 
                type="text" 
                value={labelCustom !== null ? labelCustom : null}
                readOnly
                className="form-control" 
                placeholder="Select custom range"
              />
            </DateRangePicker>
          </div>
          }
        </div>
      </FormGroup>
    )
  }
}

export default IntervalDropdown;