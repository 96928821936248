import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col, ModalFooter, Button, FormText } from 'reactstrap';
import TooltipAtCustomElement from "../TooltipAtCustomElement";
import IconTooltip from 'react-icons/lib/md/help-outline';
import Select from 'react-select';

const usStates = [
  {value: "AL", label: "AL"},
  {value: "AK", label: "AK"},
  {value: "AZ", label: "AZ"},
  {value: "AR", label: "AR"},
  {value: "AA", label: "AA"},
  {value: "AE", label: "AE"},
  {value: "AP", label: "AP"},
  {value: "CA", label: "CA"},
  {value: "CO", label: "CO"},
  {value: "CT", label: "CT"},
  {value: "DE", label: "DE"},
  {value: "DC", label: "DC"},
  {value: "FL", label: "FL"},
  {value: "GA", label: "GA"},
  {value: "HI", label: "HI"},
  {value: "ID", label: "ID"},
  {value: "IL", label: "IL"},
  {value: "IN", label: "IN"},
  {value: "IA", label: "IA"},
  {value: "KS", label: "KS"},
  {value: "KY", label: "KY"},
  {value: "LA", label: "LA"},
  {value: "ME", label: "ME"},
  {value: "MD", label: "MD"},
  {value: "MA", label: "MA"},
  {value: "MI", label: "MI"},
  {value: "MN", label: "MN"},
  {value: "MS", label: "MS"},
  {value: "MO", label: "MO"},
  {value: "MT", label: "MT"},
  {value: "NE", label: "NE"},
  {value: "NV", label: "NV"},
  {value: "NH", label: "NH"},
  {value: "NJ", label: "NJ"},
  {value: "NM", label: "NM"},
  {value: "NY", label: "NY"},
  {value: "NC", label: "NC"},
  {value: "ND", label: "ND"},
  {value: "OH", label: "OH"},
  {value: "OK", label: "OK"},
  {value: "OR", label: "OR"},
  {value: "PA", label: "PA"},
  {value: "RI", label: "RI"},
  {value: "SC", label: "SC"},
  {value: "SD", label: "SD"},
  {value: "TN", label: "TN"},
  {value: "TX", label: "TX"},
  {value: "UT", label: "UT"},
  {value: "VT", label: "VT"},
  {value: "VA", label: "VA"},
  {value: "WA", label: "WA"},
  {value: "WV", label: "WV"},
  {value: "WI", label: "WI"},
  {value: "WY", label: "WY"}
]

const caProvinces = [
  {value: "AB", label: "AB"},
  {value: "BC", label: "BC"},
  {value: "MB", label: "MB"},
  {value: "NB", label: "NB"},
  {value: "NL", label: "NL"},
  {value: "NS", label: "NL"},
  {value: "NT", label: "NT"},
  {value: "NU", label: "NU"},
  {value: "ON", label: "ON"},
  {value: "PE", label: "PE"},
  {value: "QC", label: "QC"},
  {value: "SK", label: "SK"},
  {value: "YT", label: "YT"}
];

const ukCounties = [
  {value: "Aberdeenshire", label: "Aberdeenshire"},
  {value: "Anglesey", label: "Anglesey"},
  {value: "Angus (Forfarshire)", label: "Angus (Forfarshire)"},
  {value: "Antrim", label: "Antrim"},
  {value: "Argyll", label: "Argyll"},
  {value: "Armagh", label: "Armagh"},
  {value: "Avon", label: "Avon"},
  {value: "Ayrshire", label: "Ayrshire"},
  {value: "Banffshire", label: "Banffshire"},
  {value: "Bedfordshire", label: "Bedfordshire"},
  {value: "Berkshire", label: "Berkshire"},
  {value: "Berwickshire", label: "Berwickshire"},
  {value: "Brecknockshire", label: "Brecknockshire"},
  {value: "Buckinghamshire", label: "Buckinghamshire"},
  {value: "Bute", label: "Bute"},
  {value: "Caernarfonshire", label: "Caernarfonshire"},
  {value: "Caithness", label: "Caithness"},
  {value: "Cambridgeshire", label: "Cambridgeshire"},
  {value: "Cambridgeshire and Isle of Ely", label: "Cambridgeshire and Isle of Ely"},
  {value: "Cardiganshire", label: "Cardiganshire"},
  {value: "Carmarthenshire", label: "Carmarthenshire"},
  {value: "Cheshire", label: "Cheshire"},
  {value: "City of Aberdeen", label: "City of Aberdeen"},
  {value: "City of Belfast", label: "City of Belfast"},
  {value: "City of Bristol", label: "City of Bristol"},
  {value: "City of Derry", label: "City of Derry"},
  {value: "City of Dundee", label: "City of Dundee"},
  {value: "City of Edinburgh", label: "City of Edinburgh"},
  {value: "City of Glasgow", label: "City of Glasgow"},
  {value: "City of London", label: "City of London"},
  {value: "Clackmannanshire", label: "Clackmannanshire"},
  {value: "Cleveland", label: "Cleveland"},
  {value: "Clwyd", label: "Clwyd"},
  {value: "Cornwall", label: "Cornwall"},
  {value: "Cromartyshire", label: "Cromartyshire"},
  {value: "Cumberland", label: "Cumberland"},
  {value: "Cumbria", label: "Cumbria"},
  {value: "Denbighshire", label: "Denbighshire"},
  {value: "Derbyshire", label: "Derbyshire"},
  {value: "Devon", label: "Devon"},
  {value: "Dorset", label: "Dorset"},
  {value: "Down", label: "Down"},
  {value: "Dumfriesshire", label: "Dumfriesshire"},
  {value: "Dunbartonshire (Dumbarton)", label: "Dunbartonshire (Dumbarton)"},
  {value: "Durham (County Durham)", label: "Durham (County Durham)"},
  {value: "Dyfed", label: "Dyfed"},
  {value: "East Lothian (Haddingtonshire)", label: "East Lothian (Haddingtonshire)"},
  {value: "East Suffolk", label: "East Suffolk"},
  {value: "East Sussex", label: "East Sussex"},
  {value: "Essex", label: "Essex"},
  {value: "Fermanagh", label: "Fermanagh"},
  {value: "Fife", label: "Fife"},
  {value: "Flintshire", label: "Flintshire"},
  {value: "Glamorgan", label: "Glamorgan"},
  {value: "Gloucestershire", label: "Gloucestershire"},
  {value: "Greater London", label: "Greater London"},
  {value: "Greater Manchester", label: "Greater Manchester"},
  {value: "Gwent", label: "Gwent"},
  {value: "Gwynedd", label: "Gwynedd"},
  {value: "Hampshire", label: "Hampshire"},
  {value: "Hereford and Worcester", label: "Hereford and Worcester"},
  {value: "Herefordshire", label: "Herefordshire"},
  {value: "Hertfordshire", label: "Hertfordshire"},
  {value: "Humberside", label: "Humberside"},
  {value: "Huntingdon and Peterborough", label: "Huntingdon and Peterborough"},
  {value: "Huntingdonshire", label: "Huntingdonshire"},
  {value: "Inverness-shire", label: "Inverness-shire"},
  {value: "Isle of Ely", label: "Isle of Ely"},
  {value: "Isle of Wight", label: "Isle of Wight"},
  {value: "Kent", label: "Kent"},
  {value: "Kincardineshire", label: "Kincardineshire"},
  {value: "Kinross-shire", label: "Kinross-shire"},
  {value: "Kirkcudbrightshire", label: "Kirkcudbrightshire"},
  {value: "Lanarkshire", label: "Lanarkshire"},
  {value: "Lancashire", label: "Lancashire"},
  {value: "Leicestershire", label: "Leicestershire"},
  {value: "Lincolnshire", label: "Lincolnshire"},
  {value: "Lincolnshire, Parts of Holland", label: "Lincolnshire, Parts of Holland"},
  {value: "Lincolnshire, Parts of Kesteven", label: "Lincolnshire, Parts of Kesteven"},
  {value: "Lincolnshire, Parts of Lindsey", label: "Lincolnshire, Parts of Lindsey"},
  {value: "London", label: "London"},
  {value: "Londonderry", label: "Londonderry"},
  {value: "Merionethshire", label: "Merionethshire"},
  {value: "Merseyside", label: "Merseyside"},
  {value: "Mid Glamorgan", label: "Mid Glamorgan"},
  {value: "Middlesex", label: "Middlesex"},
  {value: "Midlothian (County of Edinburgh)", label: "Midlothian (County of Edinburgh)"},
  {value: "Monmouthshire", label: "Monmouthshire"},
  {value: "Montgomeryshire", label: "Montgomeryshire"},
  {value: "Moray (Elginshire)", label: "Moray (Elginshire)"},
  {value: "Nairnshire", label: "Nairnshire"},
  {value: "Norfolk", label: "Norfolk"},
  {value: "North Humberside", label: "North Humberside"},
  {value: "North Yorkshire", label: "North Yorkshire"},
  {value: "Northamptonshire", label: "Northamptonshire"},
  {value: "Northumberland", label: "Northumberland"},
  {value: "Nottinghamshire", label: "Nottinghamshire"},
  {value: "Orkney", label: "Orkney"},
  {value: "Oxfordshire", label: "Oxfordshire"},
  {value: "Peeblesshire", label: "Peeblesshire"},
  {value: "Pembrokeshire", label: "Pembrokeshire"},
  {value: "Perthshire", label: "Perthshire"},
  {value: "Powys", label: "Powys"},
  {value: "Radnorshire", label: "Radnorshire"},
  {value: "Renfrewshire", label: "Renfrewshire"},
  {value: "Ross and Cromarty", label: "Ross and Cromarty"},
  {value: "Ross-shire", label: "Ross-shire"},
  {value: "Roxburghshire", label: "Roxburghshire"},
  {value: "Rutland", label: "Rutland"},
  {value: "Selkirkshire", label: "Selkirkshire"},
  {value: "Shetland (Zetland)", label: "Shetland (Zetland)"},
  {value: "Shropshire", label: "Shropshire"},
  {value: "Soke of Peterborough", label: "Soke of Peterborough"},
  {value: "Somerset", label: "Somerset"},
  {value: "South Glamorgan", label: "South Glamorgan"},
  {value: "South Humberside", label: "South Humberside"},
  {value: "South Yorkshire", label: "South Yorkshire"},
  {value: "Staffordshire", label: "Staffordshire"},
  {value: "Stirlingshire", label: "Stirlingshire"},
  {value: "Suffolk", label: "Suffolk"},
  {value: "Surrey", label: "Surrey"},
  {value: "Sussex", label: "Sussex"},
  {value: "Sutherland", label: "Sutherland"},
  {value: "Tyne and Wear", label: "Tyne and Wear"},
  {value: "Tyrone", label: "Tyrone"},
  {value: "Warwickshire", label: "Warwickshire"},
  {value: "West Glamorgan", label: "West Glamorgan"},
  {value: "West Lothian (Linlithgowshire)", label: "West Lothian (Linlithgowshire)"},
  {value: "West Midlands", label: "West Midlands"},
  {value: "West Suffolk", label: "West Suffolk"},
  {value: "West Sussex", label: "West Sussex"},
  {value: "West Yorkshire", label: "West Yorkshire"},
  {value: "Westmorland", label: "Westmorland"},
  {value: "Wigtownshire", label: "Wigtownshire"},
  {value: "Wiltshire", label: "Wiltshire"},
  {value: "Worcestershire", label: "Worcestershire"},
  {value: "Wrexham", label: "Wrexham"},
  {value: "Yorkshire", label: "Yorkshire"},
  {value: "Yorkshire, East Riding", label: "Yorkshire, East Riding"},
  {value: "Yorkshire, North Riding", label: "Yorkshire, North Riding"},
  {value: "Yorkshire, West Riding",  label: "Yorkshire, West Riding"}
];

class EditAddressModal extends Component {

  validateForm = () => {
    const { data } = this.props;
    const empty_check = ["addressName", "city", "postalCode"];
    var valid = true;
    empty_check.forEach(item => {
      if(!data[item]){ valid = false; }
    });
    if(this.props.country === "US"){
      if(!data.state){
        valid = false;
      }
    }
    if(this.props.country === "GB"){
      if(!data.districtOrCountyCode){
        valid = false;
      }
    }
    if(this.props.country === "CA"){
      if(!data.districtOrCountyCode){
        valid = false;
      }
    }
    if(!data.addressLine1 && !data.addressLine2){
      valid = false;
    }
    return valid;
  }

  render() {
    const {
      isOpen,
      close,
      data,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={close} size="lg">
        <ModalHeader toggle={close}>Edit Address</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              Address Name
              <span className="help ml-1" data-toggle="tooltip">
                <TooltipAtCustomElement
                  tooltipId="tooltip-name"
                  tooltipText={`This is a nickname for the address in the case you use multiple addresses to ship from`}
                  CustomElement={IconTooltip}
                />
              </span>
            </Label>
            <Input
              placeholder="Give a label to this address so you can remember it later"
              defaultValue={data.addressName}
              required
              invalid={!data.addressName ? true : false}
              onChange={ (e) => this.props.updateAddressEditData("addressName", e.target.value)}
            />
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>
                Address Line 1
                <span className="help ml-1" data-toggle="tooltip">
                  <TooltipAtCustomElement
                    tooltipId="tooltip-address-1"
                    tooltipText={`Give us your best formatted SHIP FROM address`}
                    CustomElement={IconTooltip}
                  />
                </span>
              </Label>
              <Input
                type="text"
                placeholder="Address line 1"
                defaultValue={data.addressLine1}
                required
                invalid={!data.addressLine1 && !data.addressLine2 ? true : false}
                onChange={ (e) => this.props.updateAddressEditData("addressLine1", e.target.value)}
              />
            </Col>
            <Col sm={6}>
              <Label>Address Line 2</Label>
              <Input
                placeholder="Address line 2"
                defaultValue={data.addressLine2}
                required
                invalid={!data.addressLine1 && !data.addressLine2 ? true : false}
                onChange={ (e) => this.props.updateAddressEditData("addressLine2", e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label>City</Label>
              <Input
                placeholder="City"
                defaultValue={data.city}
                required
                invalid={!data.city ? true : false}
                onChange={ (e) => this.props.updateAddressEditData("city", e.target.value)}
              />
            </Col>
            <Col sm={4}>
              {this.props.country === "US" &&
              <React.Fragment>
                <Label>State</Label>
                <Select
                  options={usStates}
                  value={data.state}
                  onChange={ (e) => this.props.updateAddressEditData("state", e.value)}
                  clearable={false}
                  invalid={!data.state ? true : false}
                />
              </React.Fragment>
              }
              {this.props.country === "CA" &&
              <React.Fragment>
                <Label>Province</Label>
                <Select
                  options={caProvinces}
                  value={data.districtOrCountyCode}
                  onChange={ (e) => this.props.updateAddressEditData("districtOrCountyCode", e.value)}
                  invalid={!data.districtOrCountyCode ? true : false}
                  clearable={false}
                />
              </React.Fragment>
              }
              {this.props.country === "GB" &&
              <React.Fragment>
                <Label>County</Label>
                <Select
                  options={ukCounties}
                  value={data.districtOrCountyCode}
                  onChange={ (e) => this.props.updateAddressEditData("districtOrCountyCode", e.value)}
                  invalid={!data.districtOrCountyCode ? true : false}
                  clearable={false}
                />
              </React.Fragment>
              }
            </Col>
            <Col sm={4}>
              <Label>ZIP / Postal Code</Label>
              <Input
                placeholder="ZIP or postal code"
                defaultValue={data.postalCode}
                required
                invalid={!data.postalCode ? true : false}
                onChange={ (e) => this.props.updateAddressEditData("postalCode", e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Cancel</Button>
          <Button
            color="success"
            onClick={() => this.props.updateAddress()}
            disabled={!this.validateForm()}
          >Save changes</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default EditAddressModal;
