import React, { Component } from 'react';
import { string, object, func } from 'prop-types';
import { Popover, PopoverBody, Button } from "reactstrap";
import IconClose from 'react-icons/lib/io/close';

class PopoverCustomElementAndContent extends Component {
  static propTypes = {
    TooltipContent: func.isRequired,
    tooltipId: string.isRequired,
    customElement: object.isRequired,
    userStyle: object
  };
  state = {
    showPopover: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tooltipId !== this.props.tooltipId) {
      this.setState({
        showPopover: false
      })
    }
  }

  togglePopover = () => {
    const { showPopover } = this.state;
    this.setState({
      showPopover: !showPopover
    })
  }
  
  render() {
    const { TooltipContent, tooltipId, customElement, userStyle, placement, className, closeButton } = this.props;
    const { showPopover } = this.state;
    const bodyStyle = { display: "inline-flex"};
    return (
      <div
        id={tooltipId}
        style={ userStyle ? Object.assign(bodyStyle, userStyle) : bodyStyle }
        onClick={this.togglePopover}>
        {customElement}
        <Popover 
          isOpen={showPopover}
          placement={placement || "top"}
          target={tooltipId}
          style={{ textAlign: "center" }}
          toggle={this.togglePopover}
          className={className}          
        >
          <PopoverBody>
            {closeButton && 
            <Button 
              className="btn btn-link" 
              onClick={this.togglePopover}
              style={{ position: "absolute", right: "1rem", border: 0 }}
            >
              <IconClose size="20" />
            </Button>
            }
            <TooltipContent togglePopover={this.togglePopover}/>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}
export default PopoverCustomElementAndContent
