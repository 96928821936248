import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";
import ReactTable from "react-table";

const { adminUserSplitsAggregateGet } = adminActions;

/**
 * Displa user error logs data
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminUsersErrorLogs extends Component {

	componentDidMount(){
    this.props.adminUserSplitsAggregateGet();
	}
  
  generateTableColums = () => {
    let tableColumns = [
      {
        id: 'category',
        accessor: "category",
        Header: "Category",
        headerClassName: "text-left",
        className: 'text-left',
      },
      {
        id: 'total_items',
        accessor: "total_items",
        Header: "Total Items",
        headerClassName: "text-right",
        className: 'text-right',
        Cell: props => digitСonversion(props.value)
      },
      {
        id: 'total_shipments',
        accessor: "total_shipments",
        Header: "Total Warehouses",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.value)
      },
      {
        id: 'total_batches',
        accessor: "total_batches",
        Header: "Total Batches",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.value)
      },
      {
        id: 'avg_items_per_wh',
        accessor: "avg_items_per_wh",
        Header: "Avg. Items per Warehouse",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.value)
      },
      {
        id: 'avg_items_per_batch',
        accessor: "avg_items_per_batch",
        Header: "Avg. Items per Batch",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.value)
      },
      {
        id: 'avg_wh_per_batch',
        accessor: "avg_wh_per_batch",
        Header: "Avg. Warehouses per Batch",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => digitСonversion(props.value)
      },
    ]
    return tableColumns
  }

  render() {
    const { admin_user_split_shipments } = this.props;

    const tableData = []
    Object.keys(admin_user_split_shipments?.splits?.categories || []).map(category =>
      tableData.push({
        category: category,
        total_items: admin_user_split_shipments.splits.categories[category]?.total_items || 0,
        total_shipments: admin_user_split_shipments.splits.categories[category]?.total_shipments || 0,
        total_batches: admin_user_split_shipments.splits.categories[category]?.total_batches || 0,
        avg_items_per_wh: (admin_user_split_shipments.splits.categories[category]?.total_items || 0) / (admin_user_split_shipments.splits.categories[category]?.total_shipments || 1),
        avg_items_per_batch: (admin_user_split_shipments.splits.categories[category]?.total_items || 0) / (admin_user_split_shipments.splits.categories[category]?.total_batches || 1),
        avg_wh_per_batch: (admin_user_split_shipments.splits.categories[category]?.total_shipments || 0) / (admin_user_split_shipments.splits.categories[category]?.total_batches || 1)
      })
    )

    return (
    <React.Fragment>
		<Card>
			<CardBody>
				<div className="d-flex justify-content-between mb-3">
					<CardTitle className="mb-0">
            User split shipments stats cumulative
					</CardTitle>
				</div>
				<Table striped className="acc-table-minimal acc-table-left">
					<thead>
						<tr>
							<th>Description</th>
							<th>Total Batches</th>
							<th>Total Warehouses</th>
							<th>Avg Warehouses Per Batch</th>
						</tr>
					</thead>
					<tbody>
            <tr>
              <td><b>CUMULATIVE</b></td>
              <td>{digitСonversion(admin_user_split_shipments?.total_batches || 0)}</td>
              <td>{digitСonversion(admin_user_split_shipments?.total_shipments || 0)}</td>
              <td>{digitСonversion((admin_user_split_shipments?.total_shipments || 0) / (admin_user_split_shipments?.total_batches || 1))}</td>
            </tr>
            <tr>
              <td><b>SPLITS</b></td>
              <td>{digitСonversion(admin_user_split_shipments?.splits?.total_batches || 0)}</td>
              <td>{digitСonversion(admin_user_split_shipments?.splits?.total_shipments || 0)}</td>
              <td>{digitСonversion((admin_user_split_shipments?.splits?.total_shipments || 0) / (admin_user_split_shipments?.splits?.total_batches || 1))}</td>
            </tr>
            <tr>
              <td><b>NO SPLITS</b></td>
              <td>{digitСonversion(admin_user_split_shipments?.no_split?.total_batches || 0)}</td>
              <td>{digitСonversion(admin_user_split_shipments?.no_split?.total_shipments || 0)}</td>
              <td>{digitСonversion((admin_user_split_shipments?.no_split?.total_shipments || 0) / (admin_user_split_shipments?.no_split?.total_batches || 1))}</td>
            </tr>
					</tbody>
        </Table>
      </CardBody>
		</Card>
		<Card className="mt-4">
			<CardBody>
				<div className="d-flex justify-content-between mb-3">
					<CardTitle className="mb-0">
            User split shipments stats for batches with more than one warehouse in shipment
					</CardTitle>
				</div>
        <hr />
        <ReactTable
          data={tableData}
          columns={this.generateTableColums()}
          className="table-striped"
          minRows={1}
        />
			</CardBody>
		</Card>
    </React.Fragment>
    );
  }
}

export default connect(
  state => {
    return {
      admin_user_split_shipments: state.Admin.get('admin_user_split_shipments'),
    };
  },
  {
    adminUserSplitsAggregateGet,
  }
)(AdminUsersErrorLogs);
