import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from '../../../../../helpers/utility';
import repricerActions from '../../../../../redux/repricer/actions';
import {
  getReverseConditionMapping,
  getNewConditionOptions
} from "../../../../../helpers/batch/utility";
import FaSpinner from "react-icons/lib/fa/spinner";

const { getReportRepriceMaster }  = repricerActions;

class MasterRepriceReport extends Component {

  UNSAFE_componentWillMount = () => {
    this.props.getReportRepriceMaster();
  }

  generateTableColumns = () => {
		const internationalizationConfig = this.props.internationalization_config;

		let tableColumns = [
			{
				id: "asin",
				Header: "ASIN",
				accessor: "asin",
				headerClassName: "text-left"
			},
			{
				id: "sku",
				Header: "SKU",
				accessor: "sku",
				headerClassName: "text-left"
			},
			{
				id: "title",
				Header: "Title",
				accessor: "title",
				headerClassName: "text-left"
			},
			{
				id: "qty",
				Header: "Qty",
				accessor: "qty",
				width: 60,
				headerClassName: "text-right",
				className: 'text-right',
			},
			{
				id: "condition",
				Header: "Condition",
				accessor: "condition",
				headerClassName: "text-left"
			},
      /*
			{
				id: "listing_date",
				Header: <span>Listing Date <IconSort className="icon-sort" /></span>,
				accessor: "listing_date",
			},
			{
				id: "rank",
				Header: <span>Rank <IconSort className="icon-sort" /></span>,
				accessor: "rank",
				width: 85,
        Cell: props => props.original.rank ? digitСonversion(props.original.rank, "rank") : "N/A"
      },
      */
			{
				id: "old_price",
				Header: "Old Price",
				accessor: "old_price",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.old_price, "currency", internationalizationConfig.currency_code )
			},
			{
				id: "new_price",
				Header: "New Price",
				accessor: "new_price",
				headerClassName: "text-right",
				className: "text-right text-primary font-weight-bold",
				Cell: props => digitСonversion(props.original.new_price, "currency", internationalizationConfig.currency_code )
			},
			{
				id: "price_change",
				Header: "Change",
				accessor: "price_change",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => digitСonversion(props.original.price_change, "currency", internationalizationConfig.currency_code )
			},
		];
    return tableColumns;
  }

  calculateMasterTableData = () => {
    const { report_reprice_master_data } = this.props;
    let master_table_data = [];
    if(report_reprice_master_data?.items){
      master_table_data = report_reprice_master_data.items.map(item => {
        return {
          asin: item?.asin_product_id,
          sku: item?.seller_sku,
          title: item?.title,
          qty: item?.qty,
          condition: getNewConditionOptions()
            .find(condition => condition.value === getReverseConditionMapping()[item?.condition])?.title,
          old_price: item?.old_price,
          new_price: item?.new_price,
          price_change: item?.old_price && item?.old_price
            ? item.old_price - item.new_price : 0,
        }
      });
    }
    return master_table_data;
  }

	render() {
		return (
      this.props.report_reprice_master_data_loading
        ? <div className="text-center"><FaSpinner className="fa-spin fa-2x mt-3 mb-3" /></div>
        :
    			<ReactTable
		    		data={this.calculateMasterTableData()}
    				columns={this.generateTableColumns()}
    				className="react-table-custom-sort-icons"
						defaultPageSize={10}
		    	/>
		)
	}
}

export default connect(
	state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
    report_reprice_master_data: state.Repricer.get("report_reprice_master_data"),
    report_reprice_master_data_loading: state.Repricer.get("report_reprice_master_data_loading"),
	}),
  {
    getReportRepriceMaster,
  }
)(MasterRepriceReport);
