import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col, Nav, NavItem, NavLink,
  TabContent, TabPane } from 'reactstrap';
import './style.css';
import adminActions from "../../../../redux/admin/actions";
import { digitСonversion } from "../../../../helpers/utility";
import AdminSpApiLogs from './AdminSpApiLogs';
import AdminUserSpApiActivate from "./AdminUserSpApiActivate";

const { userSpApiUserCountGet } = adminActions;

class AdminSpApi extends Component {
  state = {
    activeTab: "1",
  }

  componentDidMount(){
    this.props.userSpApiUserCountGet();
  }

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  render() {
    const { activeTab } = this.state;

    return (
      <Card className="card-tab">
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                onClick={() => this.toggleTab("1")}
              >
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "2"}
                onClick={() => this.toggleTab("2")}
              >
                User Logs
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col xs="4">
                  <AdminUserSpApiActivate />
                </Col>
                <Col xs="8">
                  <Row>
                    <Col xs="4">
                      <div className="metric-item blue">
                        <h6 className="title">Active SP-API users count</h6>
                        <span className="value">{digitСonversion(this.props.spapi_user_count?.user_count)}</span>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="metric-item blue">
                        <h6 className="title">Active MWS users count</h6>
                        <span className="value">{digitСonversion(this.props.spapi_user_count?.mws_users_count)}</span>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="metric-item blue">
                        <h6 className="title">Users moved to SPAPI</h6>
                        <span className="value">{digitСonversion(this.props.spapi_user_count?.users_moved_count)}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" && <AdminSpApiLogs /> }
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    )
  }
}

export default connect(
  state => {
		return {
			spapi_user_count: state.Admin.get("spapi_user_count"),
    };
  },
  {
    userSpApiUserCountGet,
  }
)(AdminSpApi);
