import React, { Component, Fragment } from 'react';
import {
  Popover,
  PopoverHeader,
	PopoverBody,
} from "reactstrap";
import { digitСonversion } from "../../../../../helpers/utility";
import { FlagIcon } from "react-flag-kit";
//import { FaCheckCircle as IconCheck } from "react-icons/lib/fa";

const mappingConditionName = {
  new_buy_box: "New",
  used_buy_box: "Used"
}

class BBPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: [],
      preferredMarketplace: ""
    }
  }

  toggle = (idx) => {
    let open = Object.assign({}, this.state.open);
    open[idx] = !this.state.open[idx]
    this.setState({
      open: open
    })
  }

  mouseOut = (idx) => {
    if(idx in this.state.open){
      if(this.state.open[idx]){
        this.toggle(idx);
      }
    }
  }

  formatNumber(amount, currencyCode) {
    return digitСonversion(amount, "currency", currencyCode);
  }

  toggleMarketplace = (code) => {
    this.setState({
      preferredMarketplace: code
    })
  }

  render() {
    const {
      bbPricingData,
      updateCurrentWorkingListingData,
			internationalConfig,
			profithunt_data_sales,
    } = this.props;

    return (
      <Fragment>
        <div className={`listing-marketplace-option ml-0 ${this.state.preferredMarketplace === 'US' ? 'selected' : ''}`} onClick={() => this.toggleMarketplace('US')}>
					{/* {this.state.preferredMarketplace === 'US' && <IconCheck size="22" className="checkmark-icon text-success" />} */}
					<div className="left">
						{internationalConfig.ship_to_country_code ?
	            <FlagIcon code={internationalConfig.ship_to_country_code} />
								: null
						}
          </div>
          <div className="right">
            {
              Object.keys(bbPricingData).map((item,idx) => {
                const itemConditon = mappingConditionName[item];

                const landedPrice = bbPricingData[item].landed_price;
                const listingPrice = bbPricingData[item].listing_price;
                const shippingPrice = bbPricingData[item].shipping_price;

                const landedPriceCurrencyCode = bbPricingData[item].landed_price_currency;
                const listingPriceCurrencyCode = bbPricingData[item].listing_price_currency;
                const shippingPriceCurrencyCode = bbPricingData[item].shipping_price_currency;

                return (
                <div className="bbprice-item" key={`bbprice-key-${idx}`}>
                  <span
                    className={`ml-2 price price-${itemConditon}`}
                    id={itemConditon}
                    onMouseOver={() => this.toggle(idx)}
                    onMouseOut={() => this.mouseOut(idx)}
                    onClick={updateCurrentWorkingListingData.bind(null, "price", landedPrice, true)}>
                    {this.formatNumber(landedPrice, landedPriceCurrencyCode)}
                  </span>

                  <Popover className="popover-bbprice" isOpen={this.state.open[idx]} target={itemConditon} toggle={() => this.toggle(idx)}>
                  <PopoverHeader>Price Breakdown ({itemConditon} BB)</PopoverHeader>
                    <PopoverBody>
                      <p className="row-item">
                        <strong>Landed Price:</strong>
                        <strong className={`price-${item.condition}`}>
                          {this.formatNumber(landedPrice, landedPriceCurrencyCode)}
                        </strong>
                      </p>
                      <p className="row-item">
                        <strong>Listing Price:</strong>
                        <span>
                          {this.formatNumber(listingPrice, listingPriceCurrencyCode)}
                        </span>
                      </p>
                      <p className="row-item">
                        <strong>Shipping Price:</strong>
                        <span>
                          {this.formatNumber(shippingPrice, shippingPriceCurrencyCode)}
                        </span>
                      </p>
                    </PopoverBody>
                  </Popover>
                </div>
                )
              })
            }
          </div>
        </div>
				<div
					key={`bbprice-marketplace-key`}
					style={{ opacity: 0.5, pointerEvents: 'none' }}
					className={`listing-marketplace-option ${this.state.preferredMarketplace === 'CA' ? 'selected' : ''}`}
				>
					{/* {this.state.preferredMarketplace === 'CA' && <IconCheck size="22" className="checkmark-icon text-success" />} */}
          <div className="left">
						{internationalConfig.ship_to_country_code && internationalConfig.ship_to_country_code === 'US' ?
								<FlagIcon code='CA'/>
								: <FlagIcon code='US'/>
						}
          </div>
          <div className="right ml-2">
						<div className="bbprice-item" key="bbprice-pricein-data-key-other-box">
							{profithunt_data_sales
								&& profithunt_data_sales.other_buybox
								&& profithunt_data_sales.other_buybox.data
								&& profithunt_data_sales.other_buybox.data.buybox ?
								<span
									className="price price-New"
									id="New2"
								>
									{internationalConfig.ship_to_country_code && internationalConfig.ship_to_country_code === 'US' ?
										this.formatNumber(profithunt_data_sales.other_buybox.data.buybox, 'CAD')
										: this.formatNumber(profithunt_data_sales.other_buybox.data.buybox, 'USD')
									}
								</span>
								: null
							}
						</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BBPrice;
