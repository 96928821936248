const sbybActions = {

	SEARCH_SBYB_DATA: 'SEARCH_SBYB_DATA',
	SEARCH_SBYB_DATA_SUCCESS: 'SEARCH_SBYB_DATA_SUCCESS',
	SEARCH_SBYB_DATA_FAILED: 'SEARCH_SBYB_DATA_FAILED',

	searchSbybData: (data) => ({
		type: sbybActions.SEARCH_SBYB_DATA,
		data
	}),
	searchSbybDataSuccess: (data) => ({
		type: sbybActions.SEARCH_SBYB_DATA_SUCCESS,
		data
	}),
	searchSbybDataFailed: (data) => ({
		type: sbybActions.SEARCH_SBYB_DATA_FAILED,
		data
	}),

};

export default sbybActions;
