import React, { Component } from 'react';
import {
  ResponsiveContainer, ComposedChart, Area, Line, XAxis,
  YAxis, CartesianGrid, Tooltip, Legend, Bar
} from 'recharts';
import chart_config from "../../main_dashboard_v3/chart_config";
import CustomizedLabel from "../../main_dashboard_v3/CustomizedLabel";
import moment from "moment";
import { digitСonversion } from "../../../../helpers/utility";
import getGradient from "../../main_dashboard_v3/chart_gradients";

class SKUChart extends Component {
  moneyFormat = (val) => {
    return digitСonversion(val, "currency", "USD");
  }

  tooltipFormatter = (value, name, props) => {
    return this.moneyFormat(value);
  }

  render() {
    return (
      this.props.data.length === 0 ?
      <div className="sku-chart-empty">
        There's no data to be displayed on the chart.
      </div>
      :
      <ResponsiveContainer
        width={"100%"}
        height={430}
      >
        <ComposedChart
          data={this.props.data || []}
          margin={{
            top: 20, left: 0
          }}
        >
          <defs>
            {getGradient("gradientGreenSales", "green")}
            {getGradient("gradientRedCost", "red")}
          </defs>
          <CartesianGrid
            vertical={false}
            stroke={chart_config.cartesianStrokeColor}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            stroke={chart_config.axisFontColor}
            style={{
              fontSize: chart_config.axisFontSize,
            }}
            tickFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
          />
          <YAxis
            yAxisId="left"
            axisLine={false}
            tickLine={false}
            domain={['auto', 'auto']}
            stroke={chart_config.axisFontColor}
            style={{
              fontSize: chart_config.axisFontSize,
            }}
            tickFormatter={this.moneyFormat}
          />
          <Tooltip
            formatter={this.tooltipFormatter}
            cursor={false}
          />
          <Legend />
          <Bar
            name="Total Cost"
            yAxisId="left"
            dataKey="total_cost"
            fill="url(#gradientRedCost)"
            barSize={chart_config.barSize}
            radius={chart_config.barRadius}
          />
          <Bar
            name="Sales"
            yAxisId="left"
            dataKey="sales"
            fill="url(#gradientGreenSales)"
            barSize={chart_config.barSize}
            radius={chart_config.barRadius}
          />
          <Line
            name="Net Proceeds"
            yAxisId="left"
            type="natural"
            dataKey="net"
            stroke="#3D5FC0"
            strokeWidth={chart_config.strokeWidth}
            dot={chart_config.dot}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }
}

export default SKUChart;
