import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
  UncontrolledTooltip, Input, Label, FormGroup, Col
} from 'reactstrap';
import { MdHelpOutline as IconHelp } from 'react-icons/lib/md';
import { iconArrowHit, iconPodium, iconChartTrophy, iconTools } from '../../../../../../assets/images';
import './style.css';
import repricerActions from '../../../../../../redux/repricer/actions';
import { templateDefaultDataSetTC } from '../../../../../../helpers/repricer/templates/defaults/tc';
import AlertTooltip from '../../components/AlertTooltip';

const {
	workingTemplateUpdateName,
	workingTemplateUpdateProp,
	workingTemplateUpdateJson,
	templateCheckName,
} = repricerActions;

const templateType = [
	{
		id: 'tc',
		icon: iconTools,
		title: 'Custom',
		description: 'Build your own template and define settings.',
		longDescription: 'Build your own template and define settings.',
		disabled: false
	},
	{
		id: 't1',
		icon: iconArrowHit,
		title: 'Buy Box Targeting',
		description: 'Target and follow the Buy Box within your min/max thresholds',
		longDescription: 'Target and follow the Buy Box within your min/max thresholds',
		disabled: true
	},
	{
		id: 't2',
		icon: iconPodium,
		title: 'Template 2',
		description: 'Win the BuyBox, maximize profit. For RA/OA/PL Sellers',
		longDescription: 'Win the BuyBox, maximize profit. For RA/OA/PL Sellers',
		disabled: true
	},
	{
		id: 't3',
		icon: iconChartTrophy,
		title: 'Template 3',
		description: 'Win the BuyBox, stay competitive. For RA/OA/PL Sellers',
		longDescription: 'Win the BuyBox, maximize profit. For RA/OA/PL Sellers',
		disabled: true
	},
];

class TemplateType extends Component {
  constructor(props) {
    super(props);

    this.state = {
		selectedTemplate: '',
		templateName: '',
    }
  }


	UNSAFE_componentWillMount(){
		this.setState({
			selectedTemplate: this.props.workingTemplate.type,
			templateName: this.props.workingTemplate.name,
		});
		//default to custom template
		if(!this.props.workingTemplate.name){
			this.onSelected(templateType[0].id);
		}
	}


  onSelected = (id) => {
    this.setState({ selectedTemplate: id });
		this.props.workingTemplateUpdateProp({prop: "type", data: id});
		//custom template selected
		if(id === 'tc'){
			this.props.workingTemplateUpdateJson({data: templateDefaultDataSetTC});
		}
		if(id !== 'tc'){
			this.setState({ templateName: "" });
			this.props.workingTemplateUpdateName({name: ""});
		}
  }

  handleInput = (e, target) => {
    this.setState({ [target]: e.target.value });
	  this.props.workingTemplateUpdateName({name: e.target.value});
	  this.props.templateCheckName(e.target.value);
  }

  render() {
    return (
      <Fragment>
        <div className="template-type-group">
          <span className="step-number">1</span>
          {templateType.map((item, idx) => (
            <TemplateBox
            key={idx}
            id={item.id}
            icon={item.icon}
            title={item.title}
            description={item.description}
            longDescription={item.longDescription}
            onClick={() => {
              if(item.disabled) return false; else this.onSelected(item.id);
            }}
            selected={item.id === this.props.workingTemplate.type}
            disabled={item.disabled}
          />
          ))}
        </div>
        <FormGroup row className="mt-4">
          <Col md={{ size:4, offset:4 }}>
            <div className="d-flex align-items-end">
              <span className="step-number mr-3">2</span>
              <div style={{ flex: 1 }}>
                <Label>Template name</Label>
                <Input
                  placeholder="Give your template a name"
                  // disabled={this.state.selectedTemplate !== 'tc' || this.props.workingTemplate.template_json.template_id}
                  onChange={(e) => this.handleInput(e, "templateName")}
                  value={this.props.workingTemplate.name}
                  maxLength={30}
                />
              </div>
              <AlertTooltip
                target="alert_template_name"
                message={this.props.templateWarning}
                range={[1]}
              />
            </div>
          </Col>
        </FormGroup>
      </Fragment>
    );
  }
}

const TemplateBox = props => (
  <div className={`template-box ${props.selected ? 'selected' : ''} ${props.disabled ? 'disabled' : ''}`} onClick={props.onClick}>
    <img src={props.icon} alt="" />
    <h3 className="template-title">{props.title}</h3>
    <p className="template-description">{props.description}</p>
    <span className="icon icon-help-circle" id={'tooltip-'+props.id}></span>
    {/* <IconHelp size="20" id={'tooltip-'+props.id} className="icon-help" color="#999EAE" /> */}
    <UncontrolledTooltip placement="right" target={'tooltip-'+props.id}>
      {props.longDescription}
    </UncontrolledTooltip>
  </div>
)

export default connect(
  state => ({
    //workingTemplate: state.Repricer.get("workingTemplate"),
    templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
	  workingTemplateUpdateName,
	  workingTemplateUpdateProp,
	  workingTemplateUpdateJson,
	  templateCheckName,
  }
)(TemplateType);
