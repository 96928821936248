import React, { Component } from 'react';
import { connect } from "react-redux";
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'sweetalert2-react';
import EmployeeAddModal from './EmployeeAddModal';
import settingsActions from "../../../redux/settings/actions";
import DeleteDialog from "../../../shared/components/dialogs/DeleteDialog";

const {
	addEmployee,
	getEmployee,
	updateEmployee,
} = settingsActions;

class EmployeeAccounts extends Component {
	columns = [
		{
			dataField: 'business_name',
			text: 'Name',
		},
		{
			dataField: 'username',
			text: 'Username',
		},
		{
			dataField: 'email',
			text: 'Email',
		},
		{
			dataField: 'city',
			text: 'City',
		},
		{
			dataField: 'state',
			text: 'State',
		},
		{
			dataField: 'phone',
			text: 'Mobile Phone',
		},
		{
			dataField: 'permissions',
			text: 'Permissions',
			headerStyle: {
				width: '12rem'
			},
			editable: false,
			formatter: (cell, row) => (
				cell.map((item, idx) => (
					<Badge color="primary" key={idx} className="mr-1" style={{ fontSize: '11px' }}>{item}</Badge>
				))
			)
		},
		{
			dataField: 'has_active_subscription',
			text: "Status",
			classes: 'text-center',
			headerStyle: {
				minWidth: '4.5rem'
			},
			editable: false,
			formatter: (cell, row) => (
				cell === "true" ? <Badge color="success" style={{ fontSize: '11px' }}>Active</Badge>
				: <Badge color="danger" style={{ fontSize: '11px' }}>Deactivated</Badge>
			)
		},
		{
			dataField: 'actions',
			text: 'Actions',
			classes: 'text-center',
			formatter: (cell, row) => {
				return (
					<div className="d-flex" style={{ gap: '1rem' }}>
						{row.has_active_subscription === 'true'
							? <Button
									color="link"
									size="sm"
									title="Deactivate User"
									onClick={e => this.props.updateEmployee(
										{
											employee_id: row.id,
											update_data: {
												has_active_subscription: 'false',
											}
										}
									)}
								><span className="icon icon-user-inactive bg-warning"></span></Button>
							: <Button
									color="link"
									size="sm"
									title="Activate User"
									onClick={e => this.props.updateEmployee(
										{
											employee_id: row.id,
											update_data: {
												has_active_subscription: 'true',
											}
										}
									)}
								><span className="icon icon-user-active bg-success"></span></Button>
						}
            <Button
              type="button"
              color="link"
              size="sm"
              title="Edit User"
              onClick={e => this.displayEditEmployeeModal(row)}
            ><span className="icon icon-edit"></span></Button>
            <Button
              type="button"
              color="link"
              size="sm"
              title="Delete User"
              onClick={e => this.displayDeleteEmployeeModal(row)}
            ><span className="icon icon-trash bg-danger"></span></Button>
					</div>
				)
			}
		}
	];

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showConfirmDelete: false,
      showAddModal: false,
      employee_edit: false,
      showDeleteModal: false,
    }
  }

	componentDidMount(){
		this.props.getEmployee();
	}

  confirmDelete = () => {
    if(this.state.selected.length > 0) {
      this.setState({
        showConfirmDelete: true
      });
    }
  }

  deleteSelected = () => {
    let data = this.state.data;
    const selected = this.state.selected;

    selected.map(selectedEmail =>
      data = data.filter(item => item.email !== selectedEmail)
    );

    this.setState({
      data: data,
      selected: [],
      showConfirmDelete: false
    });
  }

  toggleAddModal = () => {
    this.setState({
      showAddModal: !this.state.showAddModal,
      employee_edit: false,
      employee_data: null,
    })
  }

  addEmployee = (popup_data) => {
		this.setState({ showAddModal: false })
		const data = {
			username: popup_data.username,
			business_name: popup_data.name,
			email: popup_data.email,
			password: popup_data.password,
			phone: popup_data.phone,
			city: popup_data.city,
			state: popup_data.selectedState ? popup_data.selectedState.value : "",
			permissions: popup_data.privileges,
		}
		this.props.addEmployee(data);
  }

  updateEmployeeOnPopup = (popup_data) => {
		this.setState({ showAddModal: false })
		const data = {
			employee_id: popup_data.id,
			update_data: {
			  business_name: popup_data.name,
        password: popup_data.password,
	  		phone: popup_data.phone,
		  	city: popup_data.city,
			  state: popup_data.selectedState ? popup_data.selectedState.value : "",
  			permissions: popup_data.privileges,
	  	}
    }
    this.props.updateEmployee(data);
  }

  displayEditEmployeeModal = (employee_data) => {
    this.setState({
      showAddModal: true,
      employee_edit: true,
      employee_data: employee_data,
    })
  }

  displayDeleteEmployeeModal = (employee_data) => {
    this.setState({
      showDeleteModal: true,
      employee_data: employee_data,
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      employee_data: null,
    })
  }

  deleteEmployee = (id) => {
		this.setState({ showDeleteModal: false })
		const data = {
			employee_id: id,
			update_data: {
        is_user_deleted: true,
        has_active_subscription: 'false',
	  	}
    }
    this.props.updateEmployee(data);
  }

  render() {

    return (
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items mb-4">
            <div>
              <CardTitle className="mb-0 h6">Add Employees / Control Entry and Access</CardTitle>
            </div>
            <div>
              <Button color="success" onClick={this.toggleAddModal}><span className="icon icon-plus bg-white"></span> Add a New Employee</Button>
            </div>
          </div>

          <BootstrapTable
            keyField='email'
            data={this.props.employees}
            columns={this.columns}
            wrapperClasses="table-responsive"
						classes="acc-table acc-table-left"
            pagination={paginationFactory()}
            noDataIndication='No employee displayed. Add new employee by clicking "Add a New Employee" button above'
          />

          <SweetAlert
            show={this.state.showConfirmDelete}
            title="Confirm Delete"
            type="warning"
            text="Are you sure want to delete selected employee?"
            showCancelButton
            buttonsStyling={false}
            reverseButtons={true}
            confirmButtonText="Yes, delete"
            confirmButtonClass="btn btn-danger ml-3"
            cancelButtonClass="btn btn-secondary"
            onConfirm={this.deleteSelected}
            onCancel={() => {
              this.setState({ showConfirmDelete: false });
            }}
          />

          <EmployeeAddModal
            isOpen={this.state.showAddModal}
            toggle={this.toggleAddModal}
            onSave={this.addEmployee}
						employees_loading={this.props.employees_loading}
            employee_edit={this.state.employee_edit}
            employee_data={this.state.employee_data}
            updateEmployee={this.updateEmployeeOnPopup}
          />
					<DeleteDialog
						isOpen={this.state.showDeleteModal}
						close={this.toggleDeleteModal}
						deleteItem={() => this.deleteEmployee(this.state.employee_data?.id)}
						dialogTitle="Are you sure want to delete this employee?"
						dialogText={`Employee "${this.state.employee_data?.username} (${this.state.employee_data?.business_name})" will be deleted and won't have access to AccelerList later.`}
						confirmText="Yes, delete employee"
					/>
        </CardBody>
      </Card>
    )
  }
}

export default connect(
	state => {
		return {
			userData: state.Auth.get("userData"),
			employees: state.Settings.get("employees"),
			employees_loading: state.Settings.get("employees_loading"),
			employee_loaded: state.Settings.get("employee_loaded"),
		}
	},
	{
		addEmployee,
		getEmployee,
		updateEmployee,
	}
)(EmployeeAccounts);
