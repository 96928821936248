import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Collapse, Card, CardHeader, CardBody,
	//FormGroup, Label, Input,
} from 'reactstrap';
import DefaultScenario from './DefaultScenario';
import Scenario1 from './Scenario1';
import Scenario2 from './Scenario2';
import BackupScenario1 from './BackupScenario1';
import BackupScenario2 from './BackupScenario2';
import BackupScenario3 from './BackupScenario3';
import repricerActions from '../../../../../../redux/repricer/actions';
import TemplateTooltip from '../../components/TemplateTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class PricingStrategy extends Component {
  constructor(props) {
    super(props);

    this.state = {
			collapseOpen: "1",
    }
  }

  toggleCollapse = (id) => {
    const currentId = this.state.collapseOpen;

    let nextId = (id === currentId) ? "0" : id;
    this.setState({
      collapseOpen: nextId
    });
  }


  render() {
    return (
      <div className="scrollable">
        <div className="scroll-shadow">
          <div>
            <span className="text-muted">Scroll to see all the scenarios</span>
          </div>
        </div>


        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("1")}
            className={this.state.collapseOpen === "1" ? 'active' : ''}
            >
              Default
              <TemplateTooltip
								id="t-strategy-default"
								text="Default strategy"
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "1"}>
            <CardBody>
              <DefaultScenario
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("2")}
            className={this.state.collapseOpen === "2" ? 'active' : ''}
            >
              Scenario 1 - Listing In Buy Box
              <TemplateTooltip
								id="t-strategy-sc1"
								text="Listing in Buy Box"
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "2"}>
            <CardBody>
              <Scenario1
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("3")}
            className={this.state.collapseOpen === "3" ? 'active' : ''}
            >
              Scenario 2 - Competing Offer is Amazon
              <TemplateTooltip
								id="t-strategy-sc2"
								text="If competing offer is Amazon"
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "3"}>
            <CardBody>
              <Scenario2
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("4")}
            className={this.state.collapseOpen === "4" ? 'active' : ''}
            >
              Backup Scenario 1 - No Competing Offers Exist for This Listing
              <TemplateTooltip
								id="t-strategy-backup-sc1"
								text="If no competing offers exist for this listing"
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "4"}>
            <CardBody>
              <BackupScenario1
                id="bs1"
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("5")}
            className={this.state.collapseOpen === "5" ? 'active' : ''}
            >
              Backup Scenario 2 - Some Competing Offers Found, But Not Within Filter Criteria
              <TemplateTooltip
								id="t-strategy-backup-sc2"
								text="If some competing offers found, but not within filter criteria"
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "5"}>
            <CardBody>
              <BackupScenario2
                id="bs2"
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

        <Card className="card-toggle">
          <CardHeader
            onClick={() => this.toggleCollapse("6")}
            className={this.state.collapseOpen === "6" ? 'active' : ''}
            >
              Backup Scenario 3 - Competing Within Your Criteria Found, But the New Suggested Price was Rejected
              <TemplateTooltip
								id="t-strategy-backup-sc3"
								text="If competing within your criteria found, but the new sugessted price was rejected."
							/>
          </CardHeader>
          <Collapse isOpen={this.state.collapseOpen === "6"}>
            <CardBody>
              <BackupScenario3
                id="bs3"
                workingTemplate={this.props.workingTemplate}
                templateWarning={this.props.templateWarning}
              />
            </CardBody>
          </Collapse>
        </Card>

      </div>
    );
  }
}

export default connect(
  state => ({
	templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
	workingTemplateUpdateJson,
  }
)(PricingStrategy);
