import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from "../../../../helpers/utility";
import ebayActions from '../../../../redux/ebay/actions';
import { MdEdit as EditIcon } from "react-icons/lib/md";
import { Button } from "reactstrap";
import EbayListingEditModal from "./EbayListingEditModal";
import EbayListingModal from "./EbayListingModal";

const {
  ebayGetItems,
  ebayItemEditToggle,
  ebayItemEditLoadData,
  ebayEditItemUpdateData,
  ebayEditItemUpdateDataEbay,
	toggleEbayModal,
	ebayGetItemData,
	ebayEditItemData,
  ebayGetItemAspects,
  ebayGetPackageTypes,
  ebayCreateItemData,
  userRecalculateEbayFees,
  ebayGetItemEditAspects,
  ebayEditItemDataItem,
  ebayListingLoadCategories,
} = ebayActions;


class EbayLiveListingsTable extends Component {

	generateColumns = () => {
		let tableColumns = [
			{
				id: "title",
				accessor: "title",
				Header: "Product",
				headerClassName: "text-left",
				width: 300,
			},
			{
				id: "is_manually_listed",
				accessor: "is_manually_listed",
				Header: <span>Manually Listed</span>,
				className: "text-center",
        sortable: false,
				Cell: props => (
          props.original.is_manually_listed ? "YES" : "NO"
				)
			},
			{
				id: "is_best_offer",
				accessor: "is_best_offer",
				Header: <span>Best Offer</span>,
				className: "text-center",
        sortable: false,
				Cell: props => (
          props.original.is_best_offer ? "YES" : "NO"
				)
			},
			{
				id: "is_active",
				accessor: "is_active",
				Header: <span>Active</span>,
				className: "text-center",
        sortable: false,
				Cell: props => (
          props.original.is_active ? "YES" : "ENDED"
				)
			},
			{
				id: "sku",
				accessor: "sku",
				Header: "SKU",
				headerClassName: "text-left",
				width: 150
			},
			{
				id: "fulfillment_channel",
				accessor: "fulfillment_channel",
				Header: "Channel",
				className: "text-center",
				getProps: (state, row, column) => {
					if(row) {
							return {
								style: row.original.fulfillment_channel
								&& row.original.fulfillment_channel.includes('AMAZON') ? null : { background: "#f5f7f9" }
							}
					} else {
						return{
							style: { className: ""}
						}
					}
				},
        Cell: props => (
          <div>
            {
              props.original.fulfillment_channel
                && props.original.fulfillment_channel.includes('AMAZON_')
              ? 'FBA' : 'MF'
            }
          </div>
        ),
			},
			{
				id: "listing_id",
				accessor: "listing_url",
				Header: <span>Ebay Item ID</span>,
				className: "text-center",
				width: 130,
        Cell: props => (
          <React.Fragment>
            {!props.original.is_active && !props.original.is_replaced ?
              <Button
                color="success"
								size="sm"
                onClick={e => this.toggleEbayModalLocal(props.original.sku)}
                disabled={this.props.ebay_list_item_data_loading}
              >Edit and Relist</Button>
              :
              <a
                href={props.original.listing_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.original.listing_id}
              </a>
            }
          </React.Fragment>
				)
			},
			{
				id: "price",
				accessor: "price",
				Header: "Price",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					<span>{digitСonversion(props.value, "currency",
					this.props.internationalization_config.currency_code)}</span>
				)
			},
			{
				id: "qty",
				accessor: "qty",
				Header: "Qty",
				headerClassName: "text-right",
				className: "text-right"
			},
			{
				id: "sales",
				accessor: "sales",
				Header: "Sales",
				headerClassName: "text-right",
				className: "text-right"
			},
			{
				id: "sales_updated_at",
				accessor: "updated_at_str",
				Header: "Updated At",
				headerClassName: "text-left",
				width: 160,
			},
			{
				id: "df1",
				Header: "Edit",
				className: "text-center",
				sortable: false,
        Cell: props => (
          <React.Fragment>
            {props.original.is_active || !props.original.qty ?
              <Button
                size="sm"
                color="link"
                onClick={e => this.toggleItemEditEbayModalLocal(
                  !this.props.ebay_item_edit_model_display ,props.original)}
              ><span className="icon icon-edit bg-primary"></span></Button>
              : null
            }
          </React.Fragment>
        )
			}
		];
		return tableColumns;
	}

  toggleEbayModalLocal = (sku) => {
		if(!this.props.ebay_create_modal_display){
			const data = {
				sku: sku,
        is_relist: "yes",
			}
			this.props.ebayGetItemData(data);
			this.props.toggleEbayModal();
		} else {
			this.props.toggleEbayModal();
		}
  }

	componentDidMount(){
		const data = {
			page: 1,
			page_size: 10,
		}
		this.props.ebayGetItems(data);
	}

  getNextPage = (state) => {
		var sort = "id";
		var sort_order = "desc";
    if(state.sorted.length > 0) {
      sort = state.sorted[0].id;
      sort_order = state.sorted[0].desc ? 'desc' : 'asc';
    }
		const data = {
			page: parseInt(state.page, 10) + 1,
			page_size: state.pageSize,
			sort: sort,
			sort_order: sort_order,
			search_text: this.props.searchText,
		}
		this.props.ebayGetItems(data);
  }

  toggleItemEditEbayModalLocal = (state, data) => {
    if(state){
      this.props.ebayItemEditLoadData(data);
    }
    this.props.ebayItemEditToggle({ ebay_item_edit_model_display: state });
  }

	render() {
		return (
      <React.Fragment>
        <ReactTable
          columns={this.generateColumns()}
          data={this.props.ebay_items.data}
          pages={this.props.ebay_items.pages}
          defaultPageSize={10}
          disabled={this.props.ebay_items_loading}
          manual
          onFetchData={(state, instance) => { this.getNextPage(state) }}
          className="table-ebay-listing"
        />
        {this.props.ebay_item_edit_model_display
          ? <EbayListingEditModal
              isOpen={this.props.ebay_item_edit_model_display}
              toggle={this.toggleItemEditEbayModalLocal}
              ebay_item_edit_model_display={this.props.ebay_item_edit_model_display}
              ebay_list_item_data={this.props.ebay_list_item_edit_data ? this.props.ebay_list_item_edit_data : {}}
              ebayEditItemUpdateData={this.props.ebayEditItemUpdateData}
              ebay_item_updating={this.props.ebay_item_updating}
              ebayEditItemUpdateDataEbay={this.props.ebayEditItemUpdateDataEbay}
              ebayGetItemEditAspects={this.props.ebayGetItemEditAspects}
              ebayEditItemDataItem={this.props.ebayEditItemDataItem}
              ebay_item_aspects={this.props.ebay_item_aspects}
            />
          : null
        }
        <EbayListingModal
          isOpen={this.props.ebay_create_modal_display}
          toggle={this.toggleEbayModalLocal}
          internationalizationConfig={this.props.internationalization_config}
          ebay_list_item_data={this.props.ebay_list_item_data}
          ebay_list_item_data_loading={this.props.ebay_list_item_data_loading}
          ebay_created_item_data_executing={this.props.ebay_created_item_data_executing}
          ebayEditItemData={this.props.ebayEditItemData}
          ebayCreateItemData={this.props.ebayCreateItemData}
          userRecalculateEbayFees={this.props.userRecalculateEbayFees}
          ebayGetItemAspects={this.props.ebayGetItemAspects}
          ebay_item_aspects={this.props.ebay_item_aspects}
          ebay_item_aspects_loading={this.props.ebay_item_aspects_loading}
          ebay_package_types_valid={this.props.ebay_package_types_valid}
          ebay_package_types_valid_loding={this.props.ebay_package_types_valid_loding}
          ebayListingLoadCategories={this.props.ebayListingLoadCategories}
        />
      </React.Fragment>
		);
	}
}

export default connect(
	state => ({
		internationalization_config: state.Auth.get("internationalization_config"),
		ebay_items: state.Ebay.get("ebay_items"),
		ebay_items_loading: state.Ebay.get("ebay_items_loading"),
    ebay_item_edit_model_display: state.Ebay.get("ebay_item_edit_model_display"),
    ebay_list_item_edit_data: state.Ebay.get("ebay_list_item_edit_data"),
    ebay_item_updating: state.Ebay.get("ebay_item_updating"),
		ebay_list_item_data: state.Ebay.get("ebay_list_item_data"),
		ebay_list_item_data_loading: state.Ebay.get("ebay_list_item_data_loading"),
		ebay_create_modal_display: state.Ebay.get("ebay_create_modal_display"),
		ebay_created_item_data_executing: state.Ebay.get("ebay_created_item_data_executing"),
		ebay_item_aspects_loading: state.Ebay.get("ebay_item_aspects_loading"),
		ebay_item_aspects: state.Ebay.get("ebay_item_aspects"),
		ebay_package_types_valid: state.Ebay.get("ebay_package_types_valid"),
		ebay_package_types_valid_loding: state.Ebay.get("ebay_package_types_valid_loding"),
	}),
	{
		ebayGetItems,
    ebayItemEditToggle,
    ebayItemEditLoadData,
    ebayEditItemUpdateData,
    ebayEditItemUpdateDataEbay,
	  toggleEbayModal,
  	ebayGetItemData,
		ebayEditItemData,
    ebayGetItemAspects,
    ebayGetPackageTypes,
		ebayCreateItemData,
    userRecalculateEbayFees,
    ebayGetItemEditAspects,
    ebayEditItemDataItem,
    ebayListingLoadCategories,
	}
)(EbayLiveListingsTable);
