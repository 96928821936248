import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print-advanced";
import PrintViewItems from "../displays/shared/PrintViewItems";
import PrintTemplate from "react-print";
import "./style.css";

import BatchProductCard from "../displays/shared/BatchProductCard";

import { generateShipmentItemsExportDataFileAndExport } from "../exportCSV";

class ShipViewItems extends Component {

  exportData = () => {
    const { currentShipmentPlans, products } = this.props;
    generateShipmentItemsExportDataFileAndExport(currentShipmentPlans, products);
  }

  render() {
    const { isOpen, close, currentShipmentPlans, products } = this.props;
    let shipingItemsSKUList = [];

    if (currentShipmentPlans) {
      let plans = currentShipmentPlans;
      plans.forEach(plan => {
        var { Items } = plan;
        Items.forEach(element => {
          const additionalContent = (
            <React.Fragment>
              <Row>
                <Col xs="12">
                  <Row>
                    <Col xs="4">
                      Quantity: <strong>{element.Quantity} </strong>
                    </Col>
                    <Col xs="8">
                      SKU: <strong>{element.SellerSKU} </strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          )
          shipingItemsSKUList.push(
            <BatchProductCard
              key={Math.random()}
              products={products}
              sku={element.SellerSKU}
              additionalContent={additionalContent}
            />
          )
        })
      })
    }
    return (
      <Modal isOpen={isOpen} toggle={close} size="lg" centered>
        <ModalHeader>
          <strong>View Items</strong>
        </ModalHeader>
        <div class="text-right">
          <ModalFooter>
            <ReactToPrint
             trigger={() => (
               <a>
                 <Button
                  color="primary"
                 >
                   Print Items
                 </Button>
               </a>
             )}
             content={() => this.componentRef}
            />
            <Button
              color="primary"
              onClick={e => this.exportData()}
            >Export to Excel</Button>
          </ModalFooter>
        </div>
        {currentShipmentPlans && (shipingItemsSKUList.length > 0) ? (
          <ModalBody>
            {shipingItemsSKUList}
          </ModalBody>
        ) : (
          ""
        )}
        <ModalFooter>
          <Button onClick={close}>Close</Button>
        </ModalFooter>
        <PrintTemplate ref={el => (this.componentRef = el)}>
          <PrintViewItems
            className="printable"
            ref={el => (this.componentRef = el)}
          />
        </PrintTemplate>
      </Modal>
    );
  }
}

ShipViewItems.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  currentShipmentPlans: PropTypes.array,
  close: PropTypes.func.isRequired
};

export default ShipViewItems;
