import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  digitСonversion,
  momentDateToLocalFormatConversion,
} from "../../../../helpers/utility";
import ebayActions from "../../../../redux/ebay/actions";
import { Button, Modal, ModalBody, Form, FormGroup, Input, Label, Col } from "reactstrap";
import EbayUpdateAddressModal from "./EbayUpdateAddressModal";
import Tooltip from "../../../../shared/components/Tooltip";
import ModalShowJson from "../../../../shared/components/ModalShowJson";
import ModalShowXML from "../../../../shared/components/ModalShowXML";
import { openInNewTab } from "../../../../helpers/utility";
import IconEmail from "react-icons/lib/md/email";

const {
	ebayGetUserOrderItems,
	ebayEditOrderItemData,
	userOrderEbayMarkFulfilled,
} = ebayActions;

class EbaySalesTable extends Component {
	state = {
		addressModalOpen: false,
		address_data: {},
		order_item_id: 0,
		ebay_email: "",
		json_modal_open: false,
		json_modal_header: "",
		json_modal_data: [],
		xml_modal_open: false,
		xml_modal_header: "",
		xml_modal_data: [],
		address_modal_open: false,
		address_modal_data: {},
	}

	updateEmail = (val) => {
		this.setState({ ebay_email: val	});
	}

	toggleAddressModal = (order_item_id) => {
		if(!this.state.addressModalOpen){
			var address_data = {
				"Name": "",
				"Line1": "",
				"Line2": "",
				"Line3": "",
				"DistrictOrCounty": "",
				"City": "",
				"StateOrProvinceCode": "",
				"CountryCode": "US",
				"PostalCode": "",
				"PhoneNumber": "",
			}
			const active_item = this.props.ebay_order_items.find(item => item.id === order_item_id);
			Object.keys(active_item.amazon_address).forEach(key => {
				if(key !== 'CountryCode'){
					if(active_item.amazon_address[key]){
						address_data[key] = active_item.amazon_address[key];
					}
				}
			});
			this.setState({
				addressModalOpen: !this.state.addressModalOpen,
				address_data: address_data,
				order_item_id: order_item_id,
				ebay_email: active_item.ebay_email,
			});
		} else {
			this.setState({
				addressModalOpen: !this.state.addressModalOpen,
			});
		}
	}

	checkAddress = () => {
		const { address_data, ebay_email } = this.state;
		const required_keys = ["Name", "Line1", "City",	"StateOrProvinceCode",
			"CountryCode", "PostalCode",	"PhoneNumber"]
		var success = true;
		required_keys.forEach(key => {
			if(!address_data[key]){
				success = false;
			}
		})
		if(!ebay_email || !ebay_email.includes('@') || !ebay_email.includes('.')){
			success = false;
		}
		return success;
	}

	updateAddressState = (key, val) => {
		const address_data_limitation = {
			"Name": 50,
			"Line1": 60,
			"Line2": 60,
			"Line3": 60,
			"DistrictOrCounty": 150,
			"City": 50,
			"StateOrProvinceCode": 150,
			"CountryCode": 2,
			"PostalCode": 20,
			"PhoneNumber": 20,
		}
		var address = Object.assign({}, this.state.address_data);
		address[key] = val.substring(0, address_data_limitation[key]).trim();
		this.setState({ address_data: address });
	}

	componentDidMount(){
		const data = {
			page: 1,
			page_size: 10,
		}
		this.props.ebayGetUserOrderItems(data);
	}

  getNextPage = (state) => {
		var sort = "id";
		var sort_order = "desc";
    if(state.sorted.length > 0) {
      sort = state.sorted[0].id;
      sort_order = state.sorted[0].desc ? 'desc' : 'asc';
    }
		const data = {
			page: parseInt(state.page, 10) + 1,
			page_size: state.pageSize,
			sort: sort,
			sort_order: sort_order,
			search_text: this.props.searchText,
		}
		this.props.ebayGetUserOrderItems(data);
  }

	markFulfilled = (id) => {
		const data = { order_item_id: id	}
		this.props.userOrderEbayMarkFulfilled(data);
	}

	generateTableColumns = () => {
		let tableColumns = [
			{
				id: "fulfillment_channel",
				accessor: "fulfillment_channel",
				Header: "Channel",
				headerClassName: "text-left",
				width: 140,
				Cell: props => (
					<React.Fragment>
						<span>{props.original.fulfillment_channel && props.original.fulfillment_channel.includes('AMAZON_') === true ? "FBA" : "FBM"}</span>
						{props.original.fulfillment_channel && props.original.fulfillment_channel.includes('AMAZON_') === false ?
								<Button
									size="sm"
									color="primary"
									className="ml-1"
									onClick={e => openInNewTab("https://www.ebay.com/mys/sold")}
								>eBay Fulfill</Button>
								: null
						}
					</React.Fragment>
				)
			},
			{
				id: "title",
				accessor: "title",
				Header: "Product",
				headerClassName: "text-left",
				width: 200
			},
			{
				id: "sku",
				accessor: "sku",
				Header: "SKU",
				maxWidth: 150,
				headerClassName: "text-left"
			},
			{
				id: "order_id",
				accessor: "order_id",
				Header: "Ebay Order",
				width: 160,
				headerClassName: "text-left",
				Cell: props => (
				<a rel="noopener noreferrer" target="_blank" href={`https://www.ebay.com/sh/ord/details?srn=113&orderid=${props.original.order_id}&source=Orders&ru[…]2F%2Fwww.ebay.com%2Fsh%2Ford%2F%3Ffilter%3Dstatus%3AALL_ORDERS`}>{props.original.order_id}</a>
				)
			},
			{
				id: "created_at",
				accessor: "created_at_str",
				Header: "Sold At",
				width: 180,
				headerClassName: "text-left",
				Cell: props => (
					<span>{props.original.created_at_str}</span>
				)
			},
			{
				id: "amazon_sent_at",
				accessor: "amazon_sent_at_str",
				Header: <span>Amazon<br/>Status</span>,
				maxWidth: 110,
				className: "text-center",
				Cell: props => (
					<div>
						<span>{props.original.is_fulfillment_executed && !props.original.is_fulfillment_error ? 'PROCESSING' : 'FAILED'}</span>
						{props.original.is_fulfillment_error ?
							<div>
								<Button
									size="sm"
									color="primary"
									onClick={e => this.xmlModalOpen(props.original.amazon_fulfillment_response, "Error Data")}
								>View Error</Button>
							</div> : null
						}
					</div>
				)
			},
			{
				id: "price",
				accessor: "price",
				Header: "eBay Price",
				maxWidth: 110,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					<span>{digitСonversion(props.value, "currency",
					this.props.internationalization_config.currency_code)}</span>
				)
			},
			{
				id: "payed",
				accessor: "payed",
				Header: <span>Payment<br/>Status</span>,
				maxWidth: 150,
				className: "text-center",
				Cell: props => (
					<span className={`badge ${props.original.payed ? "badge-success" : "badge-default"}`}>PAID</span>
				)
			},
			{
				id: "order_status",
				accessor: "order_status",
				Header: <span>eBay Order<br/>Status</span>,
				maxWidth: 150,
				className: "text-center",
				Cell: props => (
					<React.Fragment>
						<span>{props.original.order_status}</span>
            {
              !props.original.is_fulfillment_executed
                || props.original.is_fulfillment_error
                || (props.original.order_status !== 'FULFILLED' && !props.original.fulfillment_channel.includes('AMAZON_')) ?
							<div>
								<Button
									size="sm"
									color="primary"
									onClick={e => this.markFulfilled(props.original.id)}
									disabled={this.props.user_marking_order_fulfilled}
								>Mark As Fulfilled</Button>
							</div>	: null
						}
					</React.Fragment>
				)
			},
			{
				id: "qty",
				accessor: "qty",
				Header: "Qty",
				maxWidth: 55,
				className: "text-center"
			},
			{
				id: "tracking_code",
				accessor: "tracking_code",
				Header: <span>FBA ID and Tracking / <br/> MFN Info / Error Msg</span>,
				minWidth: 125,
				Cell: props => (
					<React.Fragment>
            { props.original.tracking_code
                ?
                <div>
                  {props.original.tracking_carrier_code
                    ? <p className="mb-1">Carrier: {props.original.tracking_carrier_code}</p>
                    : null
                  }
                  {props.original.tracking_fulfill_date
                      ? <p className="mb-1">Shipment Date: {momentDateToLocalFormatConversion(props.original.tracking_fulfill_date)}</p>
                    : null
                  }
                  <p className="mb-1">Tracking: <a href={`${props.original.tracking_url}`} target="_blank" rel="noopener noreferrer" >URL</a></p>
                </div>
  	  					: ""
            }
					</React.Fragment>
				)
			},
			{
				id: "created_at",
				accessor: "update_address",
				Header: "Address",
				className: "text-center",
				sortable: false,
				Cell: props => (
					<React.Fragment>
						{!props.original.is_fulfillment_executed && !props.original.is_fulfillment_ready ?
							<div className="text-center">
								<Button
									size="sm"
									color="primary"
									onClick={e => this.toggleAddressModal(props.original.id)}
								>Update</Button>
                <Tooltip
                  tooltipId={`tooltip-${props.original.id}`}
                  tooltipText={`Issue ${props.original.is_fulfillment_ready_message}`}
                />
							</div>
							: null
						}
						<Button
							size="sm"
							color="primary"
							onClick={e => this.addressModalOpen(props.original.ebay_address)}
						>View</Button>
					</React.Fragment>
				)
			},
		];

		return tableColumns;
	}

	addressModalOpen = (data) => {
		this.setState({
			address_modal_open: true,
			address_modal_data: data,
		});
	}

	jsonModalOpen = (data, header="") => {
		this.setState({
			json_modal_open: true,
			json_modal_header: header,
			json_modal_data: data,
		});
	}

	jsonModalClose = () => {
		this.setState({
			json_modal_open: false,
		});
	}

	xmlModalOpen = (data, header="") => {
		const { userData } = this.props;
    var messages = [];
    if(data){
  		messages = data.match(/<Message>(.*?)<\/Message>/g).map(function(val){
	  		return val.replace(/<\/?Message>/g,'');
		  });
    }
		messages = messages.join('\n')
		this.setState({
			xml_modal_open: true,
			xml_modal_header: header,
			xml_modal_data: userData && userData.role === 'admin' ? data : messages,
		});
	}

	xmlModalClose = () => {
		this.setState({
			xml_modal_open: false,
		});
	}

	render() {

		return (
			<React.Fragment>
				<ReactTable
					columns={this.generateTableColumns()}
					defaultPageSize={10}
					getTrProps={(state, rowInfo, column) => {
						if(rowInfo){
							if(rowInfo.original.is_fulfillment_error){
								return {
									style: { background: "#fcdede" }
								};
							}
							if(!rowInfo.original.is_fulfillment_ready){
								return {
									style: { background: "#fcdede" }
								};
							}
						}
						return {};
					}}
					data={this.props.ebay_order_items.data}
					pages={this.props.ebay_order_items.pages}
					manual
					onFetchData={(state, instance) => { this.getNextPage(state) }}
				/>
				<EbayUpdateAddressModal
					isOpen={this.state.addressModalOpen}
					toggle={this.toggleAddressModal}
					address_data={this.state.address_data}
					order_item_id={this.state.order_item_id}
					checkAddress={this.checkAddress}
					updateAddressState={this.updateAddressState}
					ebayEditOrderItemData={this.props.ebayEditOrderItemData}
					ebay_order_item_updating={this.props.ebay_order_item_updating}
					updateEmail={this.updateEmail}
					ebay_email={this.state.ebay_email}
				/>
				<ModalShowJson
					isOpen={this.state.json_modal_open}
					close={this.jsonModalClose}
					header={this.state.json_modal_header}
					data={this.state.json_modal_data}
				/>
				<ModalShowXML
					isOpen={this.state.xml_modal_open}
					close={this.xmlModalClose}
					header={this.state.xml_modal_header}
					data={this.state.xml_modal_data}
				/>
				<Modal isOpen={this.state.address_modal_open}>
					<ModalBody>
						<div className="text-center">
							<h2>CONGRATS ON YOUR EBAY SALE</h2>
							<p className="text-muted">Please fulfill this item to your eBay customer now <strong>if it is a MF product</strong>. No action needed if FBA.</p>
						</div>
						<hr />
						<Form>
							<FormGroup row>
								<Label md="4" className="text-right">Customer Name:</Label>
								<Col>
									<Input
										value={this.state.address_modal_data.fullName ? this.state.address_modal_data.fullName : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">Email:</Label>
								<Col className="d-flex align-items-center">
									<Input
										value={this.state.address_modal_data.email ? this.state.address_modal_data.email : ""}
										readOnly
									/>
									<IconEmail size="20" className="ml-2 text-muted" />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">Phone Number:</Label>
								<Col>
									<Input
										value=""
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">Address 1:</Label>
								<Col>
									<Input
										value={
											this.state.address_modal_data.contactAddress
												&& this.state.address_modal_data.contactAddress.addressLine1 ?
												this.state.address_modal_data.contactAddress.addressLine1 : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">Address 2:</Label>
								<Col>
									<Input
										value={
											this.state.address_modal_data.contactAddress
												&& this.state.address_modal_data.contactAddress.addressLine2 ?
												this.state.address_modal_data.contactAddress.addressLine2 : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">City:</Label>
								<Col>
									<Input
										value={
											this.state.address_modal_data.contactAddress
												&& this.state.address_modal_data.contactAddress.city ?
												this.state.address_modal_data.contactAddress.city : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">State:</Label>
								<Col>
									<Input
										value={
											this.state.address_modal_data.contactAddress
												&& this.state.address_modal_data.contactAddress.stateOrProvince ?
												this.state.address_modal_data.contactAddress.stateOrProvince : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="4" className="text-right">Zipcode:</Label>
								<Col>
									<Input
										value={
											this.state.address_modal_data.contactAddress
												&& this.state.address_modal_data.contactAddress.postalCode ?
												this.state.address_modal_data.contactAddress.postalCode : ""}
										readOnly
									/>
								</Col>
							</FormGroup>
							<div className="text-center">
								<Button
									color="success"
									onClick={ e => this.setState({ address_modal_open: false })}
								>Close</Button>
							</div>
						</Form>
					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	}
}

export default connect(
	state => ({
		internationalization_config: state.Auth.get("internationalization_config"),
		ebay_order_items: state.Ebay.get("ebay_order_items"),
		ebay_order_items_loading: state.Ebay.get("ebay_order_items_loading"),
		ebay_order_item_updating: state.Ebay.get("ebay_order_item_updating"),
		user_marking_order_fulfilled: state.Ebay.get("user_marking_order_fulfilled"),
    userData: state.Auth.get("userData"),
	}),
	{
		ebayGetUserOrderItems,
		ebayEditOrderItemData,
		userOrderEbayMarkFulfilled,
	}
)(EbaySalesTable);
