import React, { Component } from 'react';
import { connect } from "react-redux";
import MetricItem from './MetricItem';
import './style.css';
import historyActions from '../../../../redux/history/actions';
import { digitСonversion } from "../../../../helpers/utility";

const { getBatchHistoryStats } = historyActions;

class Metrics extends Component {

	componentDidMount(){
		this.props.getBatchHistoryStats();
	}

  render() {
	  const { internationalization_config, batchHistoryStats } = this.props;
	let data = []
	if(batchHistoryStats){
		data = [
		  { title: 'TOTAL BATCHES', value: batchHistoryStats.total_batches.toString() },
		  { title: 'TOTAL SKUs', value: batchHistoryStats.total_skus.toString() },
		  { title: 'PRIVATE BATCHES', value: batchHistoryStats.private_batch.toString() },
		  { title: 'LIVE BATCHES', value: batchHistoryStats.live_batch.toString() },
		  { title: 'TOTAL COST', className: 'text-danger',
				value: digitСonversion(
		                batchHistoryStats.total_cost,
						"currency",
		                internationalization_config.currency_code
				      )},
		  { title: 'TOTAL GROSS PROFIT', className: 'text-success',
				value: digitСonversion(
		                batchHistoryStats.total_gross_profit,
						"currency",
		                internationalization_config.currency_code
				      )},
		];
	}
    return (
			<div className="batch-history-metrics">
			{ data.map( (item, idx) => (
				<MetricItem key={idx} title={item.title} value={item.value} className={item?.className} />
			)) }
			</div>
    );
  }
}

export default connect(
  state => {
    return {
      batchHistoryStats: state.History.get('batchHistoryStats'),
      internationalization_config: state.Auth.get("internationalization_config")
    };
  },
  {
    getBatchHistoryStats
  }
)(Metrics);
