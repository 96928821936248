import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import IconClose from 'react-icons/lib/fa/close';
import './style.css';
import {
	momentDateToLocalFormatConversion,
} from "../../../helpers/utility";

class RichMessageBox extends Component {
  state = {
    activeIndex: this.props.news.length - 1,
    animating: false
  }

  next = () => {
    const { news } = this.props;
    if (this.state.animating) return;
    const activeIndex = this.state.activeIndex
    const nextIndex = activeIndex === news.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    const { news } = this.props;
    if (this.state.animating) return;
    const activeIndex = this.state.activeIndex
    const nextIndex = activeIndex === 0 ? news.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex })
  }

  render() {
    const { news } = this.props;

    const slides = news.map(item => (
      <CarouselItem
        key={item.id}
        onExiting={() => this.setState({ animating: true })}
        onExited={() => this.setState({ animating: false })}
      >
        <div className="inner">
          <div className="message-media">
            {item.image && !item.image.includes('youtube.com') ?
              <img
                src={item.image}
                alt=""
                className="img-fluid"
              />
              : null
            }
            {item.image && item.image.includes('youtube.com') ?
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  width="630"
                  height="236"
                  className="embed-responsive-item"
                  src={item.image}
                  allowFullScreen
                ></iframe>
              </div>
              : null
            }
          </div>
          <div className="content">
            <span className="date">{momentDateToLocalFormatConversion(item.created_at * 1000)}</span>
            <h5 className="title">{item.title}</h5>
            <p>{item.description}</p>
            <div className="controls">
              <NavLink to={item.try_button} className="btn btn-success">Try now</NavLink>
              {item.more_button ?
                <a
                  href={item.more_button}
                  className="btn btn-secondary"
                  target="__blank"
                >Read more</a>
                : null
              }
            </div>
          </div>
        </div>
      </CarouselItem>
    ))

    return (
      <div className="rich-message-box">
        <Button
          className="btn-close btn-link"
          onClick={e => this.props.onClose(this.props.news[this.state.activeIndex])}
        ><IconClose /></Button>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
          interval={false}
        >
          {slides}
          {this.state.activeIndex > 0 &&
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          }
          {this.state.activeIndex < news.length-1 &&
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          }
        </Carousel>
      </div>
    )
  }
}

export default RichMessageBox;
