const settingsActions = {
  FETCH_LISTING_DEFAULTS: "FETCH_LISTING_DEFAULTS",
  FETCH_LISTING_DEFAULTS_SUCCESS: "FETCH_LISTING_DEFAULTS_SUCCESS",

  SAVE_LISTING_DEFAULTS: "SAVE_LISTING_DEFAULTS",

  FETCH_PRINTER_DEFAULTS: "FETCH_PRINTER_DEFAULTS",
  FETCH_PRINTER_DEFAULTS_SUCCESS: "FETCH_PRINTER_DEFAULTS_SUCCESS",

  SAVE_PRINTER_DEFAULTS: "SAVE_PRINTER_DEFAULTS",
  SAVE_PRINTER_DEFAULTS_ERROR: "SAVE_PRINTER_DEFAULTS_ERROR",

  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_REQUEST_SUCCESS: "UPDATE_USER_PASSWORD_REQUEST_SUCCESS",
  UPDATE_USER_PASSWORD_REQUEST_FAILURE: "UPDATE_USER_PASSWORD_REQUEST_FAILURE",
  UPDATE_USER_PASSWORD_REQUEST_STATUS: "UPDATE_USER_PASSWORD_REQUEST_STATUS",

  CLOSE_SETTINGS_ALERT: "CLOSE_SETTINGS_ALERT",
  SHOW_SETTINGS_ALERT: "SHOW_SETTINGS_ALERT",

  FETCH_SUPPLIER_LIST: "FETCH_SUPPLIER_LIST",
  FETCH_SUPPLIER_LIST_SUCCESS: "FETCH_SUPPLIER_LIST_SUCCESS",

  ADD_SUPPLIER_TO_LIST: "ADD_SUPPLIER_TO_LIST",
  ADD_SUPPLIER_TO_LIST_SUCCESS: "ADD_SUPPLIER_TO_LIST_SUCCESS",
  ADD_SUPPLIER_TO_LIST_ERROR: "ADD_SUPPLIER_TO_LIST_ERROR",

  DEL_SUPPLIER_FROM_LIST: "DEL_SUPPLIER_FROM_LIST",
  DEL_SUPPLIER_FROM_LIST_SUCCESS: "DEL_SUPPLIER_FROM_LIST_SUCCESS",

  EDIT_SUPPLIER_FROM_LIST: "EDIT_SUPPLIER_FROM_LIST",
  EDIT_SUPPLIER_FROM_LIST_SUCCESS: "EDIT_SUPPLIER_FROM_LIST_SUCCESS",

  UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
  UPLOAD_PROFILE_IMAGE_SUCCESS: "UPLOAD_PROFILE_IMAGE_SUCCESS",
  UPLOAD_PROFILE_IMAGE_ERROR: "UPLOAD_PROFILE_IMAGE_ERROR",
  GET_PROFILE_IMAGE: "GET_PROFILE_IMAGE",
  GET_PROFILE_IMAGE_SUCCESS: "GET_PROFILE_IMAGE_SUCCESS",
  GET_PROFILE_IMAGE_ERROR: "GET_PROFILE_IMAGE_ERROR",
  GET_PROFILE_BILLING_DATA: 'GET_PROFILE_BILLING_DATA',
  GET_PROFILE_BILLING_DATA_SUCCESS: 'GET_PROFILE_BILLING_DATA_SUCCESS',

	SETTINGS_UPDATE_USER_DATA: "SETTINGS_UPDATE_USER_DATA",
	SETTINGS_UPDATE_USER_DATA_SUCCESS: "SETTINGS_UPDATE_USER_DATA_SUCCESS",
	SETTINGS_UPDATE_USER_DATA_ERROR: "SETTINGS_UPDATE_USER_DATA_ERROR",

	SETTINGS_UPDATE_USER_SETTINGS: "SETTINGS_UPDATE_USER_SETTINGS",
	SETTINGS_UPDATE_USER_SETTINGS_SUCCESS: "SETTINGS_UPDATE_USER_SETTINGS_SUCCESS",
	SETTINGS_UPDATE_USER_SETINGS_ERROR: "SETTINGS_UPDATE_USER_SETTINGS_ERROR",

  FETCH_SCOUT_LIST: "FETCH_SCOUT_LIST",
  FETCH_SCOUT_LIST_SUCCESS: "FETCH_SCOUT_LIST_SUCCESS",

  ADD_SCOUT_TO_LIST: "ADD_SCOUT_TO_LIST",
  ADD_SCOUT_TO_LIST_SUCCESS: "ADD_SCOUT_TO_LIST_SUCCESS",
  ADD_SCOUT_TO_LIST_ERROR: "ADD_SCOUT_TO_LIST_ERROR",

  DEL_SCOUT_FROM_LIST: "DEL_SCOUT_FROM_LIST",
  DEL_SCOUT_FROM_LIST_SUCCESS: "DEL_SCOUT_FROM_LIST_SUCCESS",

  EDIT_SCOUT_FROM_LIST: "EDIT_SCOUT_FROM_LIST",
  EDIT_SCOUT_FROM_LIST_SUCCESS: "EDIT_SCOUT_FROM_LIST_SUCCESS",

	LABELS_DATA_GET: "LABELS_DATA_GET",
	LABELS_DATA_GET_SUCCESS: "LABELS_DATA_GET_SUCCESS",
	LABELS_DATA_GET_FAILED: "LABELS_DATA_GET_FAILED",

	EMPLOYEE_ADD: "EMPLOYEE_ADD",
	EMPLOYEE_ADD_SUCCESS: "EMPLOYEE_ADD_SUCCESS",
	EMPLOYEE_ADD_FAILED: "EMPLOYEE_ADD_FAILED",

	EMPLOYEE_GET: "EMPLOYEE_GET",
	EMPLOYEE_GET_SUCCESS: "EMPLOYEE_GET_SUCCESS",
	EMPLOYEE_GET_FAILED: "EMPLOYEE_GET_FAILED",

	EMPLOYEE_UPDATE: "EMPLOYEE_UPDATE",
	EMPLOYEE_UPDATE_SUCCESS: "EMPLOYEE_UPDATE_SUCCESS",
	EMPLOYEE_UPDATE_FAILED: "EMPLOYEE_UPDATE_FAILED",

  SETTINGS_GET_MF_TICKETING: "SETTINGS_GET_MF_TICKETING",
  SETTINGS_GET_MF_TICKETING_SUCCESS: "SETTINGS_GET_MF_TICKETING_SUCCESS",
  SETTINGS_GET_MF_TICKETING_FAILED: "SETTINGS_GET_MF_TICKETING_FAILED",


  SETTINGS_UPDATE_MF_TICKETING: "SETTINGS_UPDATE_MF_TICKETING",
  SETTINGS_UPDATE_MF_TICKETING_SUCCESS: "SETTINGS_UPDATE_MF_TICKETING_SUCCESS",
  SETTINGS_UPDATE_MF_TICKETING_FAILED: "SETTINGS_UPDATE_MF_TICKETING_FAILED",

  GET_LISTING_SALES_RANK_RANGE: "GET_LISTING_SALES_RANK_RANGE",
  GET_LISTING_SALES_RANK_RANGE_SUCCESS: "GET_LISTING_SALES_RANK_RANGE_SUCCESS",
  GET_LISTING_SALES_RANK_RANGE_FAILED: "GET_LISTING_SALES_RANK_RANGE_FAILED",

  ADD_LISTING_SALES_RANK_RANGE: "ADD_LISTING_SALES_RANK_RANGE",
  ADD_LISTING_SALES_RANK_RANGE_SUCCESS: "ADD_LISTING_SALES_RANK_RANGE_SUCCESS",
  ADD_LISTING_SALES_RANK_RANGE_FAILED: "ADD_LISTING_SALES_RANK_RANGE_FAILED",

  UPDATE_LISTING_SALES_RANK_RANGE: "UPDATE_LISTING_SALES_RANK_RANGE",
  UPDATE_LISTING_SALES_RANK_RANGE_SUCCESS: "UPDATE_LISTING_SALES_RANK_RANGE_SUCCESS",
  UPDATE_LISTING_SALES_RANK_RANGE_FAILED: "UPDATE_LISTING_SALES_RANK_RANGE_FAILED",

  DELETE_LISTING_SALES_RANK_RANGE: "DELETE_LISTING_SALES_RANK_RANGE",
  DELETE_LISTING_SALES_RANK_RANGE_SUCCESS: "DELETE_LISTING_SALES_RANK_RANGE_SUCCESS",
  DELETE_LISTING_SALES_RANK_RANGE_FAILED: "DELETE_LISTING_SALES_RANK_RANGE_FAILED",

  settingsUpdateMFTicketing: (data) => ({
		type: settingsActions.SETTINGS_UPDATE_MF_TICKETING,
		data
  }),
  settingsUpdateMFTicketingSuccess: (data) => ({
		type: settingsActions.SETTINGS_UPDATE_MF_TICKETING_SUCCESS,
		data
  }),
  settingsUpdateMFTicketingFailed: (data) => ({
		type: settingsActions.SETTINGS_UPDATE_MF_TICKETING_FAILED,
		data
  }),

  settingsGetMFTicketing: (data) => ({
		type: settingsActions.SETTINGS_GET_MF_TICKETING,
		data
  }),
  settingsGetMFTicketingSuccess: (data) => ({
		type: settingsActions.SETTINGS_GET_MF_TICKETING_SUCCESS,
		data
  }),
  settingsGetMFTicketingFailed: (data) => ({
		type: settingsActions.SETTINGS_GET_MF_TICKETING_FAILED,
		data
  }),

	SKU_TEMPLATE_SAVE: "SKU_TEMPLATE_SAVE",
	SKU_TEMPLATE_SAVE_SUCCESS: "SKU_TEMPLATE_SAVE_SUCCESS",
	SKU_TEMPLATE_SAVE_FAILED: "SKU_TEMPLATE_SAVE_FAILED",

	SKU_TEMPLATE_LOAD: "SKU_TEMPLATE_LOAD",
	SKU_TEMPLATE_LOAD_SUCCESS: "SKU_TEMPLATE_LOAD_SUCCESS",
	SKU_TEMPLATE_LOAD_FAILED: "SKU_TEMPLATE_LOAD_FAILED",

	SKU_TEMPLATE_UPDATE: "SKU_TEMPLATE_UPDATE",
	SKU_TEMPLATE_UPDATE_SUCCESS: "SKU_TEMPLATE_UPDATE_SUCCESS",
	SKU_TEMPLATE_UPDATE_FAILED: "SKU_TEMPLATE_UPDATE_FAILED",

	SKU_TEMPLATE_DELETE: "SKU_TEMPLATE_DELETE",
	SKU_TEMPLATE_DELETE_SUCCESS: "SKU_TEMPLATE_DELETE_SUCCESS",
	SKU_TEMPLATE_DELETE_FAILED: "SKU_TEMPLATE_DELETE_FAILED",

	SKU_TEMPLATE_USE: "SKU_TEMPLATE_USE",
	SKU_TEMPLATE_USE_SUCCESS: "SKU_TEMPLATE_USE_SUCCESS",
	SKU_TEMPLATE_USE_FAILED: "SKU_TEMPLATE_USE_FAILED",

  skuTemplateUse: (data) => ({
		type: settingsActions.SKU_TEMPLATE_USE,
		data
  }),
  skuTemplateUseSuccess: (data) => ({
	  type: settingsActions.SKU_TEMPLATE_USE_SUCCESS,
	  data
  }),
  skuTemplateUseFailed: (data) => ({
		type: settingsActions.SKU_TEMPLATE_USE_FAILED,
		data
  }),


  skuTemplateDelete: (data) => ({
		type: settingsActions.SKU_TEMPLATE_DELETE,
		data
  }),
  skuTemplateDeleteSuccess: (data) => ({
	  type: settingsActions.SKU_TEMPLATE_DELETE_SUCCESS,
	  data
  }),
  skuTemplateDeleteFailed: (data) => ({
		type: settingsActions.SKU_TEMPLATE_DELETE_FAILED,
		data
  }),

  skuTemplateUpdate: (data) => ({
		type: settingsActions.SKU_TEMPLATE_UPDATE,
		data
  }),
  skuTemplateUpdateSuccess: (data) => ({
	  type: settingsActions.SKU_TEMPLATE_UPDATE_SUCCESS,
	  data
  }),
  skuTemplateUpdateFailed: (data) => ({
		type: settingsActions.SKU_TEMPLATE_UPDATE_FAILED,
		data
  }),

  skuTemplateLoad: (data) => ({
		type: settingsActions.SKU_TEMPLATE_LOAD,
		data
  }),
  skuTemplateLoadSuccess: (data) => ({
	  type: settingsActions.SKU_TEMPLATE_LOAD_SUCCESS,
	  data
  }),
  skuTemplateLoadFailed: (data) => ({
		type: settingsActions.SKU_TEMPLATE_LOAD_FAILED,
		data
  }),

  skuTemplateSave: (data) => ({
		type: settingsActions.SKU_TEMPLATE_SAVE,
		data
  }),
  skuTemplateSaveSuccess: (data) => ({
	  type: settingsActions.SKU_TEMPLATE_SAVE_SUCCESS,
	  data
  }),
  skuTemplateSaveFailed: (data) => ({
		type: settingsActions.SKU_TEMPLATE_SAVE_FAILED,
		data
  }),


  updateEmployee: (data) => ({
		type: settingsActions.EMPLOYEE_UPDATE,
		data
  }),
  updateEmployeeSuccess: (data) => ({
	  type: settingsActions.EMPLOYEE_UPDATE_SUCCESS,
	  data
  }),
  updateEmployeeFailed: (data) => ({
		type: settingsActions.EMPLOYEE_UPDATE_FAILED,
		data
  }),

  getEmployee: (data) => ({
		type: settingsActions.EMPLOYEE_GET,
		data
  }),
  getEmployeeSuccess: (data) => ({
	  type: settingsActions.EMPLOYEE_GET_SUCCESS,
	  data
  }),
  getEmployeeFailed: (data) => ({
		type: settingsActions.EMPLOYEE_GET_FAILED,
		data
  }),

  addEmployee: (data) => ({
		type: settingsActions.EMPLOYEE_ADD,
		data
  }),
  addEmployeeSuccess: (data) => ({
	  type: settingsActions.EMPLOYEE_ADD_SUCCESS,
	  data
  }),
  addEmployeeFailed: (data) => ({
		type: settingsActions.EMPLOYEE_ADD_FAILED,
		data
  }),

  labelsDataGet: () => ({
    type: settingsActions.LABELS_DATA_GET,
  }),
  labelsDataGetSuccess: (data) => ({
	  type: settingsActions.LABELS_DATA_GET_SUCCESS,
	  data
  }),
  labelsDataGetFailed: () => ({
    type: settingsActions.LABELS_DATA_GET_FAILED,
  }),

  fetchScoutList: () => ({
    type: settingsActions.FETCH_SCOUT_LIST,
  }),

  fetchScoutListSuccess: scoutList => ({
    type: settingsActions.FETCH_SCOUT_LIST_SUCCESS,
    scoutList
  }),

  addScoutToList: scoutName => ({
    type: settingsActions.ADD_SCOUT_TO_LIST,
     scoutName
  }),

  addScoutToListSuccess: scoutList => ({
    type: settingsActions.ADD_SCOUT_TO_LIST_SUCCESS,
    scoutList
  }),
  addScoutToListError: data => ({
    type: settingsActions.ADD_SCOUT_TO_LIST_ERROR,
   data
  }),

  delScoutFromList: scoutId => ({
    type: settingsActions.DEL_SCOUT_FROM_LIST,
    scoutId
  }),

  delScoutFromListSuccess: scoutId => ({
    type: settingsActions.DEL_SCOUT_FROM_LIST_SUCCESS,
    scoutId
  }),

  editScoutFromList: (scoutId, newScoutName) => ({
    type: settingsActions.EDIT_SCOUT_FROM_LIST,
    scoutId,
    newScoutName
  }),

  editScoutFromListSuccess: (scoutId, newScoutName) => ({
    type: settingsActions.EDIT_SCOUT_FROM_LIST_SUCCESS,
    scoutId,
    newScoutName
  }),

	updateUserDataSettings: (data) => ({
		type: settingsActions.SETTINGS_UPDATE_USER_DATA,
		data,
	}),

	updateUserDataSettingsSuccess: () => ({
		type: settingsActions.SETTINGS_UPDATE_USER_DATA_SUCCESS,
	}),

	updateUserDataSettingsError: () => ({
		type: settingsActions.SETTINGS_UPDATE_USER_DATA_ERROR,
	}),

	updateUserSettings: (data) => ({
		type: settingsActions.SETTINGS_UPDATE_USER_SETTINGS,
		data,
	}),

	updateUserSettingsSuccess: () => ({
		type: settingsActions.SETTINGS_UPDATE_USER_SETTINGS_SUCCESS,
	}),

	updateUserSettingsError: () => ({
		type: settingsActions.SETTINGS_UPDATE_USER_SETTINGS_ERROR,
	}),

  fetchListingDefaults: () => ({
    type: settingsActions.FETCH_LISTING_DEFAULTS
  }),

  fetchListingDefaultsSuccess: listingDefaults => ({
    type: settingsActions.FETCH_LISTING_DEFAULTS_SUCCESS,
    listingDefaults
  }),

  saveListingDefaults: listingDefaults => ({
    type: settingsActions.SAVE_LISTING_DEFAULTS,
    listingDefaults
  }),

  fetchPrinterDefaults: () => ({
    type: settingsActions.FETCH_PRINTER_DEFAULTS,
  }),

  fetchPrinterDefaultsSuccess: printerDefaults => ({
    type: settingsActions.FETCH_PRINTER_DEFAULTS_SUCCESS,
    printerDefaults
  }),

  savePrinterDefaults: printerDefaults => ({
    type: settingsActions.SAVE_PRINTER_DEFAULTS,
    printerDefaults
  }),

  savePrinterDefaultsError: () => ({
    type: settingsActions.SAVE_PRINTER_DEFAULTS_ERROR,
  }),

  updateUserPassworsRequest: (oldPassword, newPassword, newPasswordConfirm) => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST,
    oldPassword,
    newPassword,
    newPasswordConfirm
  }),

  updateUserPassworsRequestSuccess: (successMessage) => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST_SUCCESS,
    successMessage
  }),

  updateUserPassworsRequestFailure: failureMessage => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST_FAILURE,
    failureMessage
  }),

  closeSettingsAlert: () => ({
    type: settingsActions.CLOSE_SETTINGS_ALERT,
  }),

  showSettingsAlert: alert => ({
    type: settingsActions.SHOW_SETTINGS_ALERT,
    alert
  }),

  setUpdateUserPassworsRequestToExecution: () => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST_STATUS,
    status: "execution"
  }),

  setUpdateUserPassworsRequestToComplete: () => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST_STATUS,
    status: "complete"
  }),

  resetUpdateUserPassworsRequestStatus: () => ({
    type: settingsActions.UPDATE_USER_PASSWORD_REQUEST_STATUS,
    status: null
  }),

  fetchSupplierList: () => ({
    type: settingsActions.FETCH_SUPPLIER_LIST,
  }),

  fetchSupplierListSuccess: supplierList => ({
    type: settingsActions.FETCH_SUPPLIER_LIST_SUCCESS,
    supplierList
  }),

  addSupplierToList: supplierName => ({
    type: settingsActions.ADD_SUPPLIER_TO_LIST,
     supplierName
  }),

  addSupplierToListSuccess: supplierList => ({
    type: settingsActions.ADD_SUPPLIER_TO_LIST_SUCCESS,
    supplierList
  }),

  addSupplierToListError: data => ({
    type: settingsActions.ADD_SUPPLIER_TO_LIST_ERROR,
    data
  }),

  delSupplierFromList: supplierId => ({
    type: settingsActions.DEL_SUPPLIER_FROM_LIST,
    supplierId
  }),

  delSupplierFromListSuccess: supplierId => ({
    type: settingsActions.DEL_SUPPLIER_FROM_LIST_SUCCESS,
    supplierId
  }),

  editSupplierFromList: (supplierId, newSupplierName) => ({
    type: settingsActions.EDIT_SUPPLIER_FROM_LIST,
    supplierId,
    newSupplierName
  }),

  editSupplierFromListSuccess: (supplierId, newSupplierName) => ({
    type: settingsActions.EDIT_SUPPLIER_FROM_LIST_SUCCESS,
    supplierId,
    newSupplierName
  }),

  uploadProfileImage: file => ({
    type: settingsActions.UPLOAD_PROFILE_IMAGE,
    file
  }),

  uploadProfileImageSuccess: data => ({
    type: settingsActions.UPLOAD_PROFILE_IMAGE_SUCCESS,
    data
  }),

  getProfileImage: () => ({
    type: settingsActions.GET_PROFILE_IMAGE,
  }),

  getProfileImageSuccess: data => ({
    type: settingsActions.GET_PROFILE_IMAGE_SUCCESS,
    data
  }),

  getProfileBillingData: () => ({
    type: settingsActions.GET_PROFILE_BILLING_DATA,
  }),

  getProfileBillingDataSuccess: data => ({
    type: settingsActions.GET_PROFILE_BILLING_DATA_SUCCESS,
    data
  }),

  getListingSalesRankRange: data => ({
    type: settingsActions.GET_LISTING_SALES_RANK_RANGE,
    data
  }),

  getListingSalesRankRangeSuccess: data => ({
    type: settingsActions.GET_LISTING_SALES_RANK_RANGE_SUCCESS,
    data
  }),

  getListingSalesRankRangeFailed: data => ({
    type: settingsActions.GET_LISTING_SALES_RANK_RANGE_FAILED,
    data
  }),

  addListingSalesRankRange: data => ({
    type: settingsActions.ADD_LISTING_SALES_RANK_RANGE,
    data
  }),

  addListingSalesRankRangeSuccess: data => ({
    type: settingsActions.ADD_LISTING_SALES_RANK_RANGE_SUCCESS,
    data
  }),

  addListingSalesRankRangeFailed: data => ({
    type: settingsActions.ADD_LISTING_SALES_RANK_RANGE_FAILED,
    data
  }),

  updateListingSalesRankRange: data => ({
    type: settingsActions.UPDATE_LISTING_SALES_RANK_RANGE,
    data
  }),

  updateListingSalesRankRangeSuccess: data => ({
    type: settingsActions.UPDATE_LISTING_SALES_RANK_RANGE_SUCCESS,
    data
  }),

  updateListingSalesRankRangeFailed: data => ({
    type: settingsActions.UPDATE_LISTING_SALES_RANK_RANGE_FAILED,
    data
  }),

  deleteListingSalesRankRange: data => ({
    type: settingsActions.DELETE_LISTING_SALES_RANK_RANGE,
    data
  }),

  deleteListingSalesRankRangeSuccess: data => ({
    type: settingsActions.DELETE_LISTING_SALES_RANK_RANGE_SUCCESS,
    data
  }),

  deleteListingSalesRankRangeFailed: data => ({
    type: settingsActions.DELETE_LISTING_SALES_RANK_RANGE_FAILED,
    data
  }),

}

export default settingsActions;
