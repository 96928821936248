import React, { Component } from 'react';
import { connect } from "react-redux";
import sopActions from '../../../redux/sop/actions';
import { EditorState, convertFromRaw, convertToRaw, } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Badge, Button, Container, UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
//import { FaPrint } from 'react-icons/lib/fa/';
import { logoColor } from '../../../assets/images';
import './style.css';

const {
  getSopProcedureByID,
} = sopActions;

class SopView  extends Component {

    componentDidMount = () => {
      if(this.props.match.params.id){
        this.props.getSopProcedureByID({
          procedure_id: parseInt(this.props.match.params.id, 10)
        })
      }
    }

    render() {
        var description = EditorState.createEmpty();
        if(this.props.sop_procedure && this.props.sop_procedure.description){
          description = EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.sop_procedure.description)));
        }

        let tagsDisplayed, tagsDropdown;
        if(this.props.sop_procedure.tags_data) {
          tagsDisplayed = this.props.sop_procedure.tags_data.slice(0,5);
          tagsDropdown = this.props.sop_procedure.tags_data.slice(5);
        }

        return (
          <div className="sop-fullview">
            {this.props.sop_procedure && this.props.sop_procedure.description ?
              <Container>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="title">
                    <span>{this.props.sop_procedure.title}</span>
                      <Button color="link" className="text-muted ml-2" onClick={() => window.print()}>{/*<FaPrint size="16" />*/}</Button>
                  </h2>
                  <span><img src={logoColor} alt="Logo" className="img-fluid" /></span>
                </div>
                <div className="sop-info">
                  <span className="text-muted category">Category: <Badge className="sop-category ml-1">{this.props.sop_procedure.category_data? this.props.sop_procedure.category_data.name : ""}</Badge></span>
                  <div className="ml-3">
                      <span className="text-muted">Tags:</span>
                      <ul className="sop-tag-list">
                        {
                          tagsDisplayed
                            ? tagsDisplayed.map(item => {
                                return <li key={`tag-${item.id}`}><Badge className="sop-tag">#{item.name}</Badge></li>
                              })
                            : null
                        }
                      </ul>
                      {tagsDropdown && tagsDropdown.length > 0 &&
                      <UncontrolledDropdown className="d-inline ml-2">
                        <DropdownToggle caret color="link">more...</DropdownToggle>
                        <DropdownMenu right container="body">
                          <ul className="sop-tag-list">
                            {
                              tagsDropdown
                                ? tagsDropdown.map(item => {
                                    return <li key={`tag-${item.id}`}><Badge className="sop-tag">#{item.name}</Badge></li>
                                  })
                                : null
                            }
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      }
                  </div>
                </div>
                <hr />
                <div
                  className="sop-fulltext"
                  dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(description.getCurrentContent()))}}
                >
                </div>
              </Container>
              : null
            }
          </div>
        )
    }
}
export default connect(
  state => {
    return {
  		sop_procedure: state.Sop.get('sop_procedure'),
    };
  },
  {
    getSopProcedureByID,
  }
)(SopView);
