import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  feedbacks: {},
  feedbacks_loading: false,
});

export default function feedbackReducer(state = initState, action) {
  switch (action.type) {

	  case actions.FEEDBACK_GET_DATA:
			return state
				.set("feedbacks", {})
				.set("feedbacks_loading", true)
	  case actions.FEEDBACK_GET_DATA_SUCCESS:
			return state
				.set("feedbacks", Object.assign({}, action.data.data))
				.set("feedbacks_loading", false)
	  case actions.FEEDBACK_GET_DATA_FAILED:
			return state
				.set("feedbacks", {})
				.set("feedbacks_loading", false)

	default:
		return state;
	}
}
