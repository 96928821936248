import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardTitle
} from "reactstrap";
import { getLabelContent } from "../../../../../shared/components/PrintServiceComponent/mf_ticketing/label_template";
import { mfLabelTypeList } from "../../../../../helpers/print_service/labelsTypeList";
import { DYMO_30334, DYMO_30270 } from "../../../../../helpers/print_service/label_name_constants";

class PrintMFPreview extends Component {
  componentDidMount = () => {
    this.renderPreview();
  };

  componentDidUpdate() {
    this.renderPreview();
  };

  renderPreview = () => {
    const isMac = !!navigator.platform.toUpperCase().match("MAC");
    const isWIN = !!navigator.platform.toUpperCase().match("WIN");
		let container = window.document.getElementById('containerPP');
		const labelFindIndex = mfLabelTypeList.findIndex(el => el.lableName === this.props.printerDefaults.label_type);
    if(isWIN || isMac) {
      var labelType = labelFindIndex !== -1 ? this.props.printerDefaults.label_type : mfLabelTypeList[0].lableName;
      var labelWidth = labelFindIndex !== -1 ? mfLabelTypeList[labelFindIndex].width : Number(this.props.printerDefaults.label_width);
      var labelHeight = labelFindIndex !== -1 ? mfLabelTypeList[labelFindIndex].height : Number(this.props.printerDefaults.label_height);
    } else {
      labelType = (labelFindIndex && labelFindIndex !== -1) ? mfLabelTypeList[labelFindIndex] : mfLabelTypeList[0];
      labelWidth = labelFindIndex !== -1 ? labelType.height : Number(this.props.printerDefaults.label_height);
      labelHeight = labelFindIndex !== -1 ? labelType.width : Number(this.props.printerDefaults.label_width);
    }
		const fontSizeCoefficient = this.props.printerDefaults.font_size_coefficient ? Number(this.props.printerDefaults.font_size_coefficient) : 0.9;
		const barCodeType = this.props.printerDefaults.barcode_type ? this.props.printerDefaults.barcode_type : "CODE39";

		if (!labelType || !labelWidth || !labelHeight || labelFindIndex === -1) {
			return;
		}

		const { row, level, zeros, startAt } = this.props;
		const MFTicketingLabel = {
			fnsku: `${row}${level}-${zeros}${startAt}`,
			name: "Test product: test product",
		};

		if ([DYMO_30334, DYMO_30270].includes(mfLabelTypeList[labelFindIndex].lableName)) {
			labelWidth = mfLabelTypeList[labelFindIndex].width;
			labelHeight = mfLabelTypeList[labelFindIndex].height;
		}

		if (container) {
			container.innerHTML = getLabelContent(MFTicketingLabel,
				{
					width: labelWidth,
					height: labelHeight,
					fontSizeCoefficient: fontSizeCoefficient,
					barCodeType: barCodeType,
					labelType: mfLabelTypeList[labelFindIndex].lableName,
				}
			);
		}
  };

  render() {
    return (
      <Card style={{ height: "100%" }}>
        <CardBody style={{ height: "100%" }}>
          <CardTitle>Print Preview</CardTitle>
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "90%",
                  position: "relative"
                }
                }>
                <div
                  id="containerPP"
                  style={{ border: "1px solid black", borderRadius: "10px", overflow: "hidden" }}
                />
            </div>
        </CardBody>
      </Card>
    );
  }
}

PrintMFPreview.propTypes = {
  printerDefaults: PropTypes.object.isRequired,
};

export default PrintMFPreview;
