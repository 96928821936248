import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import IconArrowRight from 'react-icons/lib/md/chevron-right';
import './style.css';
import { momentDateToLocalFormatConversion } from '../../../helpers/utility';


class EmployeeTrackingTable extends Component {
	render() {
		const columns = [
			{
				dataField: 'name',
				text: '',
				headerStyle: {
					width: '30%'
				},
				formatter: (cell, row) => {
					if(row.entries.length > 0) {
						return <React.Fragment>
							<IconArrowRight size="20" className="icon-arrow-right" />
							<strong>{cell}</strong><span className="ml-2">({row.city}, {row.state})</span>
						</React.Fragment>
					}
					return (
					<React.Fragment>
						<strong style={{ paddingLeft: '20px' }}>{cell}</strong>
						<span className="ml-2">({row.city}, {row.state})</span>
					</React.Fragment>
					)
				}
			},
			{
				dataField: 'df1',
				isDummyField: true,
				text: 'Batch Name',
				headerStyle: {
					width: '20%'
				},
			},
			{
				dataField: 'df2',
				isDummyField: true,
				text: 'Channel',
				headerStyle: {
					width: '15%'
				},
			},
			{
				dataField: 'df3',
				isDummyField: true,
				text: 'Created',
				headerStyle: {
					width: '10%'
				},
			},
			{
				dataField: 'df4',
				isDummyField: true,
				text: 'Items In Batch',
				headerStyle: {
					width: '15%'
				},
			},
			{
				dataField: 'df5',
				isDummyField: true,
				text: 'Status',
				headerStyle: {
					width: '10%'
				},
			},
		]

		const expandRow = {
			renderer: row => {
				return (
				row.entries.length > 0 && (
					<div className="table-responsive">
						<table className="table table-entries" key={`table-${row.employee_id}`}>
							<tbody>
								{row.entries.map((entry, idx) => (
									<tr key={`row-${row.employee_id}-${idx}`}>
										<td style={{ width: "30%" }}>&nbsp;</td>
										<td style={{ width: "20%" }}>{entry.batch_name}</td>
										<td style={{ width: "15%" }}>{entry.channel.includes('AMAZON') ? "FBA" : "MF"}</td>
										<td style={{ width: "10%" }}>{momentDateToLocalFormatConversion(entry.date)}</td>
										<td style={{ width: "15%" }}>{entry.items_count}</td>
										<td style={{ width: "10%" }}>{entry.status}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)
			)},
			parentClassName: 'expanded',
			expanded: [1]
		}

		return (
			<BootstrapTable
				keyField="employee_id"
				columns={columns}
				data={this.props.employees_tracking}
				bordered={false}
				classes="table-employee-tracking"
				expandRow={expandRow}
			/>
		)
	}
}

export default EmployeeTrackingTable;
