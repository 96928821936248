const messageActions = {

	MESSAGE_IN_APP_GET: 'MESSAGE_IN_APP_GET',
	MESSAGE_IN_APP_GET_SUCCESS: 'MESSAGE_IN_APP_GET_SUCCESS',
	MESSAGE_IN_APP_GET_FAILED: 'MESSAGE_IN_APP_GET_FAILED',

	MESSAGE_IN_APP_CREATE: 'MESSAGE_IN_APP_CREATE',
	MESSAGE_IN_APP_CREATE_SUCCESS: 'MESSAGE_IN_APP_CREATE_SUCCESS',
	MESSAGE_IN_APP_CREATE_FAILED: 'MESSAGE_IN_APP_CREATE_FAILED',

	MESSAGE_IN_APP_UPDATE: 'MESSAGE_IN_APP_UPDATE',
	MESSAGE_IN_APP_UPDATE_SUCCESS: 'MESSAGE_IN_APP_UPDATE_SUCCESS',
	MESSAGE_IN_APP_UPDATE_FAILED: 'MESSAGE_IN_APP_UPDATE_FAILED',

	MESSAGE_IN_APP_DELETE: 'MESSAGE_IN_APP_DELETE',
	MESSAGE_IN_APP_DELETE_SUCCESS: 'MESSAGE_IN_APP_DELETE_SUCCESS',
	MESSAGE_IN_APP_DELETE_FAILED: 'MESSAGE_IN_APP_DELETE_FAILED',

	MESSAGE_IN_APP_UPLOAD_IMAGE: 'MESSAGE_IN_APP_UPLOAD_IMAGE',
	MESSAGE_IN_APP_UPLOAD_IMAGE_SUCCESS: 'MESSAGE_IN_APP_UPLOAD_IMAGE_SUCCESS',
	MESSAGE_IN_APP_UPLOAD_IMAGE_FAILED: 'MESSAGE_IN_APP_UPLOAD_IMAGE_FAILED',

	MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR: 'MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR',
	MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR_SUCCESS: 'MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR_SUCCESS',

	MESSAGE_IN_APP_GET_USER: 'MESSAGE_IN_APP_GET_USER',
	MESSAGE_IN_APP_GET_USER_SUCCESS: 'MESSAGE_IN_APP_GET_USER_SUCCESS',
	MESSAGE_IN_APP_GET_USER_FAILED: 'MESSAGE_IN_APP_GET_USER_FAILED',

	MESSAGE_IN_APP_SET_TRACKING: 'MESSAGE_IN_APP_SET_TRACKING',
	MESSAGE_IN_APP_SET_TRACKING_SUCCESS: 'MESSAGE_IN_APP_SET_TRACKING_SUCCESS',
	MESSAGE_IN_APP_SET_TRACKING_FAILED: 'MESSAGE_IN_APP_SET_TRACKING_FAILED',

	messageInAppSetTracking: (data) => ({
		type: messageActions.MESSAGE_IN_APP_SET_TRACKING,
		data
	}),
	messageInAppSetTrackingSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_SET_TRACKING_SUCCESS,
		data
	}),
	messageInAppSetTrackingFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_SET_TRACKING_FAILED,
		data
	}),

	messageInAppGetUser: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET_USER,
		data
	}),
	messageInAppGetUserSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET_USER_SUCCESS,
		data
	}),
	messageInAppGetUserFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET_USER_FAILED,
		data
	}),

	messageInAppUploadImageClear: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR,
		data
	}),
	messageInAppUploadImageClearSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR_SUCCESS,
		data
	}),

	messageInAppUploadImage: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPLOAD_IMAGE,
		data
	}),
	messageInAppUploadImageSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPLOAD_IMAGE_SUCCESS,
		data
	}),
	messageInAppUploadImageFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPLOAD_IMAGE_FAILED,
		data
	}),

	messageInAppGetDelete: (data) => ({
		type: messageActions.MESSAGE_IN_APP_DELETE,
		data
	}),
	messageInAppGetDeleteSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_DELETE_SUCCESS,
		data
	}),
	messageInAppGetDeleteFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_DELETE_FAILED,
		data
	}),

	messageInAppGetUpdate: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPDATE,
		data
	}),
	messageInAppGetUpdateSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPDATE_SUCCESS,
		data
	}),
	messageInAppGetUpdateFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_UPDATE_FAILED,
		data
	}),

	messageInAppGetCreate: (data) => ({
		type: messageActions.MESSAGE_IN_APP_CREATE,
		data
	}),
	messageInAppGetCreateSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_CREATE_SUCCESS,
		data
	}),
	messageInAppGetCreateFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_CREATE_FAILED,
		data
	}),

	messageInAppGet: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET,
		data
	}),
	messageInAppGetSuccess: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET_SUCCESS,
		data
	}),
	messageInAppGetFailed: (data) => ({
		type: messageActions.MESSAGE_IN_APP_GET_FAILED,
		data
	}),

};

export default messageActions;
