const dashboardActions = {

  FETCH_CHART_DATA: 'FETCH_CHART_DATA',
  FETCH_CHART_DATA_SUCCESS: 'FETCH_CHART_DATA_SUCCESS',
  FETCH_CHART_DATA_STATUS: 'FETCH_CHART_DATA_STATUS',

  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
  FETCH_DASHBOARD_DATA_SUCCESS: 'FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_DASHBOARD_DATA_STATUS: 'FETCH_DASHBOARD_DATA_STATUS',

	GET_SALES_STATS: 'GET_SALES_STATS',
	GET_SALES_STATS_SUCCESS: 'GET_SALES_SUCCESS',
	GET_SALES_STATS_ERROR: 'GET_SALES_STATS_ERROR',

  SET_CHART_PERIOD_TYPE: 'SET_CHART_PERIOD_TYPE',
  SET_CHART_END_DATE: 'SET_CHART_END_DATE',

  GET_DASHBOARD_PERIOD_DATA: "GET_DASHBOARD_PERIOD_DATA",
  GET_DASHBOARD_PERIOD_DATA_SUCCESS: "GET_DASHBOARD_PERIOD_DATA_SUCCESS",
  GET_DASHBOARD_PERIOD_DATA_FAILED: "GET_DASHBOARD_PERIOD_DATA_FAILED",

  SEARCH_HELP: "SEARCH_HELP",
  SEARCH_HELP_SUCCESS: "SEARCH_HELP_SUCCESS",
  SEARCH_HELP_FAILED: "SEARCH_HELP_FAILED",

  SEARCH_HELP_SINGLE: "SEARCH_HELP_SINGLE",
  SEARCH_HELP_SINGLE_SUCCESS: "SEARCH_HELP_SINGLE_SUCCESS",
  SEARCH_HELP_SINGLE_FAILED: "SEARCH_HELP_SINGLE_FAILED",

  searchHelpSingle: (data) => ({
    type: dashboardActions.SEARCH_HELP_SINGLE,
    data
  }),
  searchHelpSingleSuccess: (data) => ({
    type: dashboardActions.SEARCH_HELP_SINGLE_SUCCESS,
    data
  }),
  searchHelpSingleFailed: (data) => ({
    type: dashboardActions.SEARCH_HELP_SINGLE_FAILED,
    data
  }),

  searchHelp: (data) => ({
    type: dashboardActions.SEARCH_HELP,
    data
  }),
  searchHelpSuccess: (data) => ({
    type: dashboardActions.SEARCH_HELP_SUCCESS,
    data
  }),
  searchHelpFailed: (data) => ({
    type: dashboardActions.SEARCH_HELP_FAILED,
    data
  }),

  getDashboardPeriodData: (data) => ({
    type: dashboardActions.GET_DASHBOARD_PERIOD_DATA,
    data
  }),
  getDashboardPeriodDataSuccess: (data) => ({
    type: dashboardActions.GET_DASHBOARD_PERIOD_DATA_SUCCESS,
    data
  }),
  getDashboardPeriodDataFailed: (data) => ({
    type: dashboardActions.GET_DASHBOARD_PERIOD_DATA_FAILED,
    data
  }),

  getSalesStats: () => ({
    type: dashboardActions.GET_SALES_STATS,
  }),
  getSalesStatsSuccess: (data) => ({
    type: dashboardActions.GET_SALES_STATS_SUCCESS,
    data
  }),
  getSalesStatsError: () => ({
    type: dashboardActions.GET_SALES_STATS_ERROR,
  }),

  fetchChartData: (chartType, dateTime, period) => ({
    type: dashboardActions.FETCH_CHART_DATA,
    chartType,
    dateTime,
    period
  }),
  fetchChartDataSuccess: (key, value) => ({
    type: dashboardActions.FETCH_CHART_DATA_SUCCESS,
    key, value
  }),
  fetchChartDataStatus: (key, status) => ({
    type: dashboardActions.FETCH_CHART_DATA_STATUS,
    key, status
  }),

  fetchDashboardData: (key, stat, minTimestamp, maxTimestamp) => ({
    type: dashboardActions.FETCH_DASHBOARD_DATA,
    key,
    stat,
    minTimestamp,
    maxTimestamp
  }),
  fetchDashboardDataSuccess: (key, value) => ({
    type: dashboardActions.FETCH_DASHBOARD_DATA_SUCCESS,
    key, value
  }),
  fetchDashboardDataStatus: (key, status) => ({
    type: dashboardActions.FETCH_DASHBOARD_DATA_STATUS,
    key, status
  }),

  setChartPeriodType: (period, chartType) => ({
    type: dashboardActions.SET_CHART_PERIOD_TYPE,
    period,
    chartType
  }),
  setChartEndDate: (date, chartType) => ({
    type: dashboardActions.SET_CHART_END_DATE,
    date,
    chartType
  }),
}

export default dashboardActions

