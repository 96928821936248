import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Input, InputGroup, InputGroupAddon, InputGroupText,
	Modal, ModalBody, ModalFooter, Button, ModalHeader, Table,
	Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import researchActions from '../../../redux/research/actions';
import Select from "react-select";
import "react-table/react-table.css";

const selectOptions = [
	{ value: 'NewItem', label: 'New Item' },
	{ value: 'UsedGood', label: 'Used - G' },
	{ value: 'UsedVeryGood', label: 'Used - VG' },
	{ value: 'UsedAcceptable', label: 'Used - A' },
	{ value: 'UsedLikeNew', label: 'Used - LN' },
	{ value: 'CollectibleGood', label: 'Collectible - G' },
	{ value: 'CollectibleLikeNew', label: 'Collectible - LN' },
	{ value: 'CollectibleAcceptable', label: 'Collectible - A' },
	{ value: 'CollectibleVeryGood', label: 'Collectible - VG' },
	{ value: 'Refurbished', label: 'Refurbished' },
];

const selectOptionsPagination = [
	{ value: '5', label: '5' },
	{ value: '10', label: '10' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
];

const {
	moveToBatch,
	researchMoveToBatchDataSet,
	researchDataUpdate,
} = researchActions;

class IncompleteForBatchModal extends Component {
	state = {
		page_size: 5,
		page_number: 1,
		pagination_option: { value: '5', label: '5' },
	}

	updateValue = (row, key, val) => {
		const data = {
			id: row.id,
			key: key,
			val: val,
		}
		this.props.researchDataUpdate(data);
	}

	onSelectChecked = (option, row) => {
		this.updateValue(row, 'condition_move', option);
		this.updateValue(row, 'condition', option.value);
	}

	onSelectPagination = (option) => {
		if(option){
			this.setState({
				pagination_option: option,
				page_size: parseInt(option.value, 10),
			});

		} else {
			this.setState({
				pagination_option: { value: '5', label: '5' },
				page_size: 5,
			});
		}
	}

	previousPage = () => {
		if(this.state.page_number <= 1){
			this.setState({ page_number: 1 })
		} else {
			this.setState({ page_number: this.state.page_number - 1 })
		}
	}

	nextPage = (data) => {
		const len = data.length;
		const max_page = Math.ceil(len / this.state.page_size);
		if(this.state.page_number >= max_page){
			this.setState({ page_number: max_page })
		} else {
			this.setState({ page_number: this.state.page_number + 1 })
		}
	}

	batchDataPagination = () => {
		const data = this.props.moveToBatchData.slice(
			(this.state.page_number - 1) * this.state.page_size,
			(this.state.page_number - 1) * this.state.page_size + this.state.page_size,
		)
		return data;
	}

	isSendValid = () => {
		let valid = true;
		this.props.moveToBatchData.forEach(item => {
			if(valid){
				if(!item.qty || !item.price){
					valid = false;
				}
			}
		});
		return valid;
	}

	render() {
		const {
			isOpen,
			toggle,
			submit,
			moveToBatchData,
		} = this.props;

		return (
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				className="modal-search-select"
				size="lg"
			>
				<ModalHeader toggle={toggle}>Incomplete Data</ModalHeader>
				<ModalBody>
					<p>Please complete required data for items below before you can add them into a batch.</p>
					<Table className="table-incomplete-items acc-table acc-table-left">
						<thead>
							<tr>
								<th className="head-image">Image</th><th>Title</th><th>Qty</th><th>Condition</th><th>Price</th>
							</tr>
						</thead>
						<tbody>
						{this.batchDataPagination().map((item, idx) => (
							<tr key={`item_data_${idx}`}>
								<td style={{ width: "5%" }}>
									<div className="picture">
										<img src={item.img_url} alt="" />
									</div>
								</td>
								<td className="col-title">
									{item.title ? <span>{item.title.substring(0, 50)}...</span> : "N/A"}
								</td>
								<td style={{ maxWidth: "5rem" }}>
									<Input
										type="number"
										min="1"
										value={item.qty ? item.qty : ""}
										onChange={e => this.updateValue(item, 'qty', parseInt(e.target.value, 10))}
									/>
								</td>
								<td>
								<Select
										options={selectOptions}
										onChange={option => this.onSelectChecked(option, item)}
										value={item.condition_move}
										clearable={false}
									/>
								</td>
								<td style={{ maxWidth: "9rem" }}>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>$</InputGroupText>
										</InputGroupAddon>
										<Input
											type="number"
											value={item.price ? item.price : ""}
											onChange={e => this.updateValue(item, 'price', parseFloat(e.target.value))}
										/>
									</InputGroup>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
					<div className="d-flex justify-content-between">
						<div>
								<Select
										options={selectOptionsPagination}
										onChange={option => this.onSelectPagination(option)}
										value={this.state.pagination_option}
										clearable={false}
									/>
						</div>
						<div>
							<Pagination>
								<PaginationItem>
									<PaginationLink
										previous
										href="#"
										onClick={e => this.previousPage()}
									/>
								</PaginationItem>
								<PaginationItem active>
									<PaginationLink>{this.state.page_number}</PaginationLink>
								</PaginationItem>
								<PaginationItem>
									<PaginationLink
										next
										href="#"
										onClick={e => this.nextPage(moveToBatchData)}
									/>
								</PaginationItem>
							</Pagination>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>Cancel</Button>
					<Button
						color="success"
						onClick={submit}
						disabled={!this.isSendValid()}
					>Update and add to batch</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export default connect(
  state => {
    return {
			moveToBatchData: state.Research.get('moveToBatchData'),
		};
	},
		{
			moveToBatch,
			researchMoveToBatchDataSet,
			researchDataUpdate,
		}

)(IncompleteForBatchModal);
