import actions from "./actions";
import { Map } from "immutable/dist/immutable";
import { ebay_condition_mapping_select } from "../../helpers/ebay/utility";

function add_aditional_fields(item){
  if(item.sales_data
    && item.sales_data.isbns
    && item.sales_data.isbns.length > 0){
      item.ebay_additional_fields.push(
        {
          "desc": {
            "label": "ISBN",
            "value": "isbn",
            "values": [],
            "required": false
          },
          "type": "str",
          "value": item.sales_data.isbns[0],
          "required": false,
          "values": [],
          "aspect_constraint": {
            "aspectRequired": false
          }
        }
      )
  }
  return item;
}

function add_aditional_fields_aspects(item, aspects){
  /*
  const required_fields = {
    author: { key: 'author', value: 'Author'},
    language: { key: 'language', value: 'Language'},
  };
  Object.keys(required_fields).forEach(field => {
    if(item.amazon_product_data
      && item.amazon_product_data.length > 0
      && item.amazon_product_data[0][field]
      && aspects.find(aspect => aspect.desc.label === required_fields[field].value)){
        item.ebay_additional_fields.push(
          {
            "desc": {
              "label": required_fields[field].value,
              "value": required_fields[field].key,
              "values": [],
              "required": false
            },
            "type": "str",
            "value": item.amazon_product_data[0][field],
            "required": false,
            "values": [],
            "aspect_constraint": {
              "aspectRequired": false
            }
          }
        )
    }
  });
  */
  //try to add more if found in additional_aspects_data
  const mapping = {
    eanList: {
      type: "list",
      label: "EAN"
    },
    upcList: {
      type: "list",
      label: "UPC"
    },
    /*
    title: {
      type: "str",
      label: "Book Title"
    },
    author: {
      type: "str",
      label: "Author"
    },
    languages: {
      type: "list",
      label: "Language",
    }
    */
  }
  if(item && item.additional_aspects_data){
    if(!item.ebay_additional_fields){
      item.ebay_additional_fields = [];
    }
    Object.keys(item.additional_aspects_data).forEach(key => {
      if(mapping[key]){
        if(item.additional_aspects_data[key]){
          if(
            mapping[key].type === "list"
            && item.additional_aspects_data[key].length > 0
            && aspects.find(aspect => aspect.desc.label === mapping[key].label)
          ){
            let tmp = Object.assign({}, aspects.find(aspect => aspect.desc.label === mapping[key].label));
            tmp.desc.values = item.additional_aspects_data[key];
            if(key === "languages"){
              tmp.value = ['English'];
            } else {
              tmp.value = item.additional_aspects_data[key][0];
            }
            tmp.values = item.additional_aspects_data[key];
            item.ebay_additional_fields.push(tmp);
          } else if(
            mapping[key].type === "str"
            && aspects.find(aspect => aspect.desc.label === mapping[key].label)
          ){
            let tmp = Object.assign({}, aspects.find(aspect => aspect.desc.label === mapping[key].label));
            tmp.value = item.additional_aspects_data[key];
            item.ebay_additional_fields.push(tmp);
          }
        }
      }
    });
  }
  return item;
}

const policies_mapping = {
	"fulfillment_policy": "ebay_fulfillment_policies",
	"payment_policy": "ebay_payment_policies",
	"return_policy": "ebay_return_policies",
}

const initState = new Map({
	user_auth_processing: false,
	ebay_list_item_data: {},
	ebay_list_item_data_loading: false,
	ebay_fulfillment_policies: [],
	ebay_payment_policies: [],
	ebay_return_policies: [],
	ebay_user_settings: {},
	ebay_user_settings_updating: false,
	ebay_created_item_data: {},
	ebay_created_item_data_executing: false,
	ebay_locations: [],
	ebay_items: {
		data: [],
		pages: [],
	},
  ebay_items_loading: false,
	ebay_create_modal_display: false,
	ebay_order_items: {
		data: [],
		pages: [],
	},
	ebay_order_items_loading: false,
	admin_ebay_pull_orders_message: "",
	admin_ebay_active_orders_list: [],
	admin_ebay_complete_order_message: "",
	ebay_order_item_updating: false,
	user_enabling_ebay: false,
	user_marking_order_fulfilled: false,
  ebay_item_edit_model_display: false,
  ebay_list_item_edit_data: {},
  ebay_item_updating: false,
  ebay_item_aspects: [],
  ebay_item_aspects_loading: false,
  ebay_package_types_valid: [],
  ebay_package_types_valid_loding: false,
  ebay_listing_categories_loading: false,
  //ebay direct listing
  ebay_direct_created_item_data_executing: false,
  ebay_direct_created_item_data_popup: false,
  ebay_direct_created_item_url: null,
  ebay_direct_listing_item: {},
  ebay_direct_search_products: [],
  ebay_direct_search_products_working: false,
  //
  ebay_mf_sales_unfinished: false,
  ebay_marketing_conversion: {},
  ebay_direct_search_query: "",
	ebay_items_count: {},
  ebay_cross_list_images_upload_working: false,
 
});

export default function ebayReducer(state = initState, action) {
  switch (action.type) {

    case actions.EBAY_DIRECT_SEARCH_QUERY_UPDATE_SUCCESS:
      return state
        .set("ebay_direct_search_query", action.data.ebay_direct_search_query)

	  case actions.EBAY_EDIT_ITEM_DATA_EDIT:
			let edit_item_data_edit = Object.assign({}, state.get("ebay_list_item_edit_data"));
			const edit_data_edit = Object.assign([], action.data);
			edit_data_edit.forEach(data => {
				if(data.type === 'array'){
					if(data.action === 'add'){
						edit_item_data_edit[data.key].push(data.value);
					} else if (data.action === 'edit'){

					} else {
						edit_item_data_edit[data.key] = edit_item_data_edit[data.key].filter(item => item !== data.value);
					}
				} else {
					edit_item_data_edit[data.key] = data.value;
				}
			});

			return state
				.set("ebay_list_item_edit_data", edit_item_data_edit)

	  case actions.EBAY_GET_ITEM_EDIT_ASPECTS:
      return state
        .set("ebay_item_aspects", [])
        .set("ebay_item_aspects_loading", true)
    case actions.EBAY_GET_ITEM_EDIT_ASPECTS_SUCCESS:
      let aspects_ebay_list_edit_item_data = state.get('ebay_list_item_edit_data');
      const aspects_edit = action.data.aspects.map(item => {
        var aspect = {
          desc: {
            label: item.localizedAspectName,
            value: item.localizedAspectName.toLowerCase(),
          },
      		type: "str",
          value: "",
          required: item.aspectConstraint.aspectRequired,
          values: item.aspectValues ? item.aspectValues.map( av => {
            return av.localizedValue
          }) : [],
          aspect_constraint: item.aspectConstraint ? item.aspectConstraint : {},
        }
        return aspect;
      });
      aspects_ebay_list_edit_item_data = add_aditional_fields_aspects(aspects_ebay_list_edit_item_data, aspects_edit);
      return state
        .set("ebay_list_item_edit_data", Object.assign(state.get('ebay_list_item_edit_data'), aspects_ebay_list_edit_item_data))
        .set("ebay_item_aspects", Object.assign([], aspects_edit))
        .set("ebay_item_aspects_loading", false)
	  case actions.EBAY_GET_ITEM_EDIT_ASPECTS_FAILED:
      return state
        .set("ebay_item_aspects", [])
        .set("ebay_item_aspects_loading", false)

    case actions.EBAY_MARKETING_CONVERSION:
      return state
        .set("ebay_marketing_conversion", {})
    case actions.EBAY_MARKETING_CONVERSION_SUCCESS:
      return state
        .set("ebay_marketing_conversion", Object.assign({}, action.data.data))
    case actions.EBAY_MARKETING_CONVERSION_FAILED:
      return state
        .set("ebay_marketing_conversion", {})

    case actions.EBAY_LISTING_LOAD_CATEGORIES:
      let categories_list_data_init = Object.assign({}, state.get("ebay_list_item_data"));
      categories_list_data_init.ebay_condition = null;
      categories_list_data_init.category_select = [];
      categories_list_data_init.category_ebay = null;
      return state
        .set("ebay_list_item_data", Object.assign({}, categories_list_data_init))
        .set("ebay_listing_categories_loading", true)
    case actions.EBAY_LISTING_LOAD_CATEGORIES_SUCCESS:
      let categories_list_data = Object.assign({}, state.get("ebay_list_item_data"));
      categories_list_data.ebay_condition = null;
      categories_list_data.category_select = action.data.categories;
      categories_list_data.category_select = categories_list_data.category_select.map(item => {
        item.allowed_conditions = action.data.conditions[parseInt(item.value, 10)];
        return item;
      })
      categories_list_data.category_ebay = null;
      categories_list_data.ebay_category = null;
      return state
        .set("ebay_list_item_data", Object.assign({}, categories_list_data))
        .set("ebay_listing_categories_loading", false)
    case actions.EBAY_LISTING_LOAD_CATEGORIES_FAILED:
      let categories_list_data_fail = Object.assign({}, state.get("ebay_list_item_data"));
      categories_list_data_fail.ebay_condition = null;
      categories_list_data_fail.category_select = [];
      categories_list_data_fail.category_ebay = null;
      return state
        .set("ebay_list_item_data", Object.assign({}, categories_list_data_fail))
        .set("ebay_listing_categories_loading", false)

    case actions.EBAY_DIRECT_LISTING_CLOSE_POPUP_SUCCESS:
      return state
        .set("ebay_direct_created_item_data_popup", false)
        .set("ebay_direct_created_item_url", null)

	  case actions.EBAY_CHECK_MF_SALES_UNFINISHED_SUCCESS:
			return state.set("ebay_mf_sales_unfinished", action.data.unfinished)
    case actions.EBAY_CHECK_MF_SALES_UNFINISHED_FAILED:
			return state.set("ebay_mf_sales_unfinished", false)

    case actions.EBAY_DIRECT_ADDITIONAL_DATA:
      let pricing_item = state.get("ebay_direct_listing_item");
      pricing_item['pricing_data'] = {};
      pricing_item['additional_data'] = {};
			return state
				.set("ebay_direct_listing_item", Object.assign({}, pricing_item))
	  case actions.EBAY_DIRECT_ADDITIONAL_DATA_SUCCESS:
      let pricing_item_success = state.get("ebay_direct_listing_item");
      pricing_item_success['pricing_data'] = {
        ebay: action.data.ebay,
        amazon: action.data.amazon,
      }
      pricing_item_success['additional_data'] = {
        unique_identifiers: {
          ean: action.data.eans,
          upc: action.data.upcs,
          isbn: action.data.isbns,
        }
      }
			return state
				.set("ebay_direct_listing_item", Object.assign({}, pricing_item_success))
    case actions.EBAY_DIRECT_ADDITIONAL_DATA_FAILED:
      let pricing_item_failed = state.get("ebay_direct_listing_item");
      pricing_item_failed['pricing_data'] = {};
      pricing_item_failed['additional_data'] = {};
			return state
				.set("ebay_direct_listing_item", Object.assign({}, pricing_item_failed))

	  case actions.EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_SUCCESS:
      let product_select = {}
      product_select.title = action.data.name;
      product_select.asin1 = action.data.ASIN;
      product_select.image_previews = [action.data.imageUrl];
      product_select.images = [action.data.imageUrl.replace('._SL75_', '')];
      product_select.amazon_dimensions = action.data.amazon_dimensions;
			return state
				.set("ebay_direct_search_products", [])
				.set("ebay_direct_search_products_working", false)
				.set("ebay_direct_listing_item", Object.assign({}, product_select))

	  case actions.EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_SUCCESS:
			return state
				.set("ebay_direct_search_products", [])
				.set("ebay_direct_search_products_working", false)
				.set("ebay_direct_listing_item", {})

	  case actions.EBAY_DIRECT_SEARCH_PRODUCTS:
			return state
				.set("ebay_direct_search_products", [])
				.set("ebay_direct_search_products_working", true)
	  case actions.EBAY_DIRECT_SEARCH_PRODUCTS_SUCCESS:
			return state
				.set("ebay_direct_search_products", Object.assign([], action.data))
				.set("ebay_direct_search_products_working", false)
	  case actions.EBAY_DIRECT_SEARCH_PRODUCTS_FAILED:
			return state
				.set("ebay_direct_search_products", [])
				.set("ebay_direct_search_products_working", false)

	  case actions.EBAY_DIRECT_CREATE_ITEM_DATA:
			return state
				.set("ebay_direct_created_item_data_executing", true)
				.set("ebay_direct_created_item_data_popup", false)
        .set("ebay_direct_created_item_url", null)
	  case actions.EBAY_DIRECT_CREATE_ITEM_DATA_SUCCESS:
			return state
				.set("ebay_direct_listing_item", {})
				.set("ebay_direct_created_item_data_executing", false)
				.set("ebay_direct_created_item_data_popup", true)
        .set("ebay_direct_created_item_url", action.data.item.listing_url)
	  case actions.EBAY_DIRECT_CREATE_ITEM_DATA_FAILED:
			return state
				.set("ebay_direct_created_item_data_executing", false)
				.set("ebay_direct_created_item_data_popup", false)
        .set("ebay_direct_created_item_url", null)

    case actions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES:
      let categories_direct_listing_data_init = Object.assign({}, state.get("ebay_direct_listing_item"));
      categories_direct_listing_data_init.ebay_allowed_conditions = null;
      categories_direct_listing_data_init.ebay_allowed_conditions_select = null;
      categories_direct_listing_data_init.ebay_condition = null;
      categories_direct_listing_data_init.ebay_categories_select = null;
      categories_direct_listing_data_init.ebay_category = null;
      return state.set("ebay_direct_listing_item", Object.assign({}, categories_direct_listing_data_init))
    case actions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES_SUCCESS:
      let categories_direct_listing_data = Object.assign({}, state.get("ebay_direct_listing_item"));
      categories_direct_listing_data.ebay_allowed_conditions = action.data.conditions;
      categories_direct_listing_data.ebay_allowed_conditions_select = null;
      categories_direct_listing_data.ebay_condition = null;
      categories_direct_listing_data.ebay_categories_select = action.data.categories;
      categories_direct_listing_data.ebay_category = null;
      /*
      if(action.data.select_default_category && categories_direct_listing_data.ebay_categories_select.length > 0){
        categories_direct_listing_data.ebay_category = categories_direct_listing_data.ebay_categories_select[0];
      }
      */
      return state.set("ebay_direct_listing_item", Object.assign({}, categories_direct_listing_data))
    case actions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES_FAILED:
      let categories_direct_listing_data_fail = Object.assign({}, state.get("ebay_direct_listing_item"));
      categories_direct_listing_data_fail.ebay_allowed_conditions = null;
      categories_direct_listing_data_fail.ebay_allowed_conditions_select = null;
      categories_direct_listing_data_fail.ebay_condition = null;
      categories_direct_listing_data_fail.ebay_categories_select = null;
      categories_direct_listing_data_fail.ebay_category = null;
      return state.set("ebay_direct_listing_item", Object.assign({}, categories_direct_listing_data_fail))

    case actions.EBAY_DIRECT_LISTING_UPLOAD_IMAGE_SUCCESS:
      let images_direct_listing_data = Object.assign({}, state.get("ebay_direct_listing_item"));
      images_direct_listing_data.images = images_direct_listing_data.images ? images_direct_listing_data.images : [];
      images_direct_listing_data.images.push(action.data.url);
      images_direct_listing_data.image_previews = images_direct_listing_data.image_previews ? images_direct_listing_data.image_previews : [];
      images_direct_listing_data.image_previews.push(action.file.preview);
      return state.set("ebay_direct_listing_item", Object.assign({}, images_direct_listing_data))

    case actions.EBAY_DIRECT_LISTING_ITEM_EDIT_SUCCESS:
      let direct_listing_data = Object.assign({}, state.get("ebay_direct_listing_item"));
      direct_listing_data[action.data.key] = action.data.value;
      return state.set("ebay_direct_listing_item", Object.assign({}, direct_listing_data))

    case actions.EBAY_DIRECT_LISTING_ITEM_INIT_SUCCESS:
      return state.set("ebay_direct_listing_item", {})

	  case actions.EBAY_GET_PACKAGE_TYPES:
      return state
        .set("ebay_package_types_valid", [])
        .set("ebay_package_types_valid_loding", true)
    case actions.EBAY_GET_PACKAGE_TYPES_SUCCESS:
      return state
        .set("ebay_package_types_valid", Object.assign([], action.data.package_types))
        .set("ebay_package_types_valid_loding", false)
	  case actions.EBAY_GET_PACKAGE_TYPES_FAILED:
      return state
        .set("ebay_package_types_valid", [])
        .set("ebay_package_types_valid_loding", false)

	  case actions.EBAY_GET_ITEM_ASPECTS:
      return state
        .set("ebay_item_aspects", [])
        .set("ebay_item_aspects_loading", true)
    case actions.EBAY_GET_ITEM_ASPECTS_SUCCESS:
      let aspects_ebay_list_item_data = state.get('ebay_list_item_data');
      const aspects = action.data.aspects.map(item => {
        var aspect = {
          desc: {
            label: item.localizedAspectName,
            value: item.localizedAspectName.toLowerCase(),
          },
      		type: "str",
          value: "",
          required: item.aspectConstraint.aspectRequired,
          values: item.aspectValues ? item.aspectValues.map( av => {
            return av.localizedValue
          }) : [],
          aspect_constraint: item.aspectConstraint ? item.aspectConstraint : {},
        }
        return aspect;
      });
      let aspects_ebay_direct_list_item_data = state.get('ebay_direct_listing_item');
      if(action.data.additional_aspects_data){
        aspects_ebay_direct_list_item_data.additional_aspects_data = Object.assign({}, action.data.additional_aspects_data);
        if(!aspects_ebay_direct_list_item_data.image_previews){
          aspects_ebay_direct_list_item_data.image_previews = [];
        }
        if(!aspects_ebay_direct_list_item_data.images){
          aspects_ebay_direct_list_item_data.images = [];
        }
        if(aspects_ebay_direct_list_item_data.additional_aspects_data.images){
          aspects_ebay_direct_list_item_data.additional_aspects_data.images.forEach(image => {
            if(!aspects_ebay_direct_list_item_data.image_previews.includes(image)){
              aspects_ebay_direct_list_item_data.image_previews.push(image);
            }
            if(!aspects_ebay_direct_list_item_data.images.includes(image)){
              aspects_ebay_direct_list_item_data.images.push(image);
            }
          })
        }
        if(aspects_ebay_direct_list_item_data.additional_aspects_data.description){
          aspects_ebay_direct_list_item_data.description = aspects_ebay_direct_list_item_data.additional_aspects_data.description;
        }
        aspects_ebay_direct_list_item_data.ebay_additional_fields = [];
        aspects_ebay_direct_list_item_data = add_aditional_fields_aspects(aspects_ebay_direct_list_item_data, aspects);
      }
      aspects_ebay_list_item_data.ebay_additional_fields = [];
      aspects_ebay_list_item_data = add_aditional_fields_aspects(aspects_ebay_list_item_data, aspects);
      return state
        .set("ebay_list_item_data", Object.assign(state.get('ebay_list_item_data'), aspects_ebay_list_item_data))
        .set("ebay_direct_listing_item", Object.assign(state.get('ebay_direct_listing_item'), aspects_ebay_direct_list_item_data))
        .set("ebay_item_aspects", Object.assign([], aspects))
        .set("ebay_item_aspects_loading", false)
	  case actions.EBAY_GET_ITEM_ASPECTS_FAILED:
      return state
        .set("ebay_item_aspects", [])
        .set("ebay_item_aspects_loading", false)

	  case actions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY:
      return state
        .set("ebay_item_updating", true)
	  case actions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_SUCCESS:
			let edit_ebay_items = Object.assign({}, state.get("ebay_items"));
      let edit_ebay_item = edit_ebay_items.data.find(item => item.offer_id === action.data.item_data.offer_id);
      if(edit_ebay_item){
        edit_ebay_item.price = action.data.item_data.price;
        edit_ebay_item.qty = action.data.item_data.qty;
        edit_ebay_item.title = action.data.item_data.title ? action.data.item_data.title : edit_ebay_item.title;
        edit_ebay_item.final_item_json.ebay_condition = action.data.item_data.condition ? action.data.item_data.condition : edit_ebay_item.final_item_json.ebay_condition;
      }
      return state
        .set("ebay_item_updating", false)
        .set("ebay_items", Object.assign({}, edit_ebay_items))
	  case actions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_FAILED:
      return state
        .set("ebay_item_updating", false)


	  case actions.EBAY_EDIT_ITEM_UPDATE_DATA:
			let ebay_list_item_edit_data = Object.assign({}, state.get("ebay_list_item_edit_data"));
			const update_edit_data = Object.assign([], action.data);
			update_edit_data.forEach(data => {
				if(data.type === 'array'){
          if(!Object.keys(ebay_list_item_edit_data).includes(data.key)){
            ebay_list_item_edit_data[data.key] = [];
          }
					if(data.action === 'add'){
						ebay_list_item_edit_data[data.key].push(data.value);
					} else if (data.action === 'edit'){

					} else {
            if(Object.keys(ebay_list_item_edit_data).includes(data.key)){
              ebay_list_item_edit_data[data.key] = ebay_list_item_edit_data[data.key].filter(item => item !== data.value);
            }
					}
        } else if(data.type === 'object') {
          ebay_list_item_edit_data[data.key] = Object.assign({}, data.value);
				} else {
					ebay_list_item_edit_data[data.key] = data.value;
				}
			});

			return state
				.set("ebay_list_item_edit_data", ebay_list_item_edit_data)

    case actions.EBAY_ITEM_EDIT_LOAD_DATA_SUCCESS:
      return state
				.set("ebay_list_item_edit_data", action.data)

    case actions.EBAY_ITEM_EDIT_TOGGLE_SUCCESS:
      return state
				.set("ebay_item_edit_model_display", action.data.ebay_item_edit_model_display)

		case actions.USER_RECALCULATE_EBAY_FEES_SUCCESS:
			let fees_edit_item_data = Object.assign({}, state.get("ebay_list_item_data"));
			//fees_edit_item_data.amazon_offsite_fba_fee = action.data.item.amazon_offsite_fba_fee;
			fees_edit_item_data.ebay_paypal_fee = action.data.item.ebay_paypal_fee;
			return state
				.set("ebay_list_item_data", Object.assign({}, fees_edit_item_data))

	  case actions.USER_SELF_ORDER_EBAY_MARK_FULFILLED:
			return state
				.set("user_marking_order_fulfilled", true)
	  case actions.USER_SELF_ORDER_EBAY_MARK_FULFILLED_SUCCESS:
			var ebay_order_items_data_fulfill = Object.assign({}, state.get("ebay_order_items"));
			ebay_order_items_data_fulfill.data = ebay_order_items_data_fulfill.data.map(item => {
				if(item.id === action.data.order_item_id){
					item.is_fulfillment_ready = true;
					item.is_fulfillment_executed = true;
					item.is_fulfillment_error = false;
					item.order_status = 'FULFILLED';
				}
				return item;
			});
			return state
				.set("ebay_order_items", Object.assign({}, ebay_order_items_data_fulfill))
				.set("user_marking_order_fulfilled", false)
	  case actions.USER_SELF_ORDER_EBAY_MARK_FULFILLED_FAILED:
			return state
				.set("user_marking_order_fulfilled", false)

	  case actions.USER_SELF_ENABLE_EBAY:
			return state
				.set("user_enabling_ebay", true)
	  case actions.USER_SELF_ENABLE_EBAY_SUCCESS:
			return state
				.set("user_enabling_ebay", false)
	  case actions.USER_SELF_ENABLE_EBAY_FAILED:
			return state
				.set("user_enabling_ebay", false)

		case actions.EBAY_EDIT_ORDER_ITEM_DATA:
			return state
				.set("ebay_order_item_updating", true)
		case actions.EBAY_EDIT_ORDER_ITEM_DATA_SUCCESS:
			var ebay_order_items_data = Object.assign({}, state.get("ebay_order_items"));
			ebay_order_items_data.data = ebay_order_items_data.data.map(item => {
				if(item.id === action.data.order.id){
					Object.keys(item).forEach(key => {
						if(Object.keys(action.data.order).includes(key)){
							item[key] = action.data.order[key];
						}
					});
				}
				return item;
			});
			return state
				.set("ebay_order_items", Object.assign({}, ebay_order_items_data))
				.set("ebay_order_item_updating", false)
		case actions.EBAY_EDIT_ORDER_ITEM_DATA_FAILED:
			return state
				.set("ebay_order_item_updating", false)

		case actions.ADMIN_EBAY_ACTIVATE_ORDERS:
			return state
				.set("admin_ebay_complete_order_message", "")
		case actions.ADMIN_EBAY_ACTIVATE_ORDERS_SUCCESS:
			return state
				.set("admin_ebay_complete_order_message", action.data.message)
		case actions.ADMIN_EBAY_ACTIVATE_ORDERS_FAILED:
			return state
				.set("admin_ebay_complete_order_message", "Failed")

		case actions.ADMIN_EBAY_GET_ACTIVE_ORDERS:
			return state
				.set("admin_ebay_active_orders_list", [])
		case actions.ADMIN_EBAY_GET_ACTIVE_ORDERS_SUCCESS:
			let active_orders = [];
			Object.keys(action.data.orders).forEach(key => {
				const d = action.data.orders[key];
				active_orders.push({
					label: `[${key}] ${d.title}`,
					value: `${d.item_id}-${d.transaction_id}`
				})
			});
			return state
				.set("admin_ebay_active_orders_list", active_orders)
		case actions.ADMIN_EBAY_GET_ACTIVE_ORDERS_FAILED:
			return state
				.set("admin_ebay_active_orders_list", [])

		case actions.ADMIN_EBAY_PULL_ORDERS:
			return state
				.set("admin_ebay_pull_orders_message", "")
		case actions.ADMIN_EBAY_PULL_ORDERS_SUCCESS:
			return state
				.set("admin_ebay_pull_orders_message", action.data.message)
		case actions.ADMIN_EBAY_PULL_ORDERS_FAILED:
			return state
				.set("admin_ebay_pull_orders_message", "Failed")

		case actions.EBAY_USER_GET_ORDER_ITEMS:
			return state
				.set("ebay_order_items", {data:[], pages:[]})
				.set("ebay_order_items_loading", true)
		case actions.EBAY_USER_GET_ORDER_ITEMS_SUCCESS:
			return state
				.set("ebay_order_items", action.data.orders)
				.set("ebay_order_items_loading", true)
		case actions.EBAY_USER_GET_ORDER_ITEMS_FAILED:
			return state
				.set("ebay_order_items", {data:[], pages:[]})
				.set("ebay_order_items_loading", true)

		case actions.EBAY_CREATE_MODAL_DISPLAY_SUCCESS:
			return state
				.set("ebay_create_modal_display", !state.get("ebay_create_modal_display"))

		case actions.EBAY_USER_GET_ITEMS:
			return state
				.set("ebay_items", {data:[], pages: []})
				.set("ebay_items_loading", true)
		case actions.EBAY_USER_GET_ITEMS_SUCCESS:
			return state
				.set("ebay_items", action.data.items)
				.set("ebay_items_loading", false)
		case actions.EBAY_USER_GET_ITEMS_FAILED:
			return state
				.set("ebay_items", {data:[], pages: []})
				.set("ebay_items_loading", false)
        
		case actions.EBAY_USER_GET_LOCATION:
			return state
				.set("ebay_locations", [])
				.set("ebay_user_settings_updating", true)
		case actions.EBAY_USER_GET_LOCATION_SUCCESS:
			return state
				.set("ebay_locations", Object.assign([], action.data.locations))
				.set("ebay_user_settings_updating", false)
		case actions.EBAY_USER_GET_LOCATION_FAILED:
			return state
				.set("ebay_locations", [])
				.set("ebay_user_settings_updating", false)

	  case actions.EBAY_CREATE_ITEM_DATA:
			return state
				.set("ebay_created_item_data", {})
				.set("ebay_created_item_data_executing", true)
    case actions.EBAY_CREATE_ITEM_DATA_SUCCESS:
      let create_ebay_items = state.get("ebay_items");
      if(action.data.item.is_relist){
        create_ebay_items.data = create_ebay_items.data.map(item => {
          if(item.sku === action.data.item.seller_sku){
            item = action.data.item;
          }
          return item;
        });
      }
			return state
				.set("ebay_items", Object.assign({}, create_ebay_items))
				.set("ebay_created_item_data", action.data.item)
				.set("ebay_created_item_data_executing", false)
	  case actions.EBAY_CREATE_ITEM_DATA_FAILED:
			return state
				.set("ebay_created_item_data", {})
				.set("ebay_created_item_data_executing", false)

		case actions.EBAY_USER_UPDATE_SETTINGS:
			return state.set("ebay_user_settings_updating", true)
		case actions.EBAY_USER_UPDATE_SETTINGS_SUCCESS:
			return state
				.set("ebay_user_settings", Object.assign({}, action.data.settings))
				.set("ebay_user_settings_updating", false)
		case actions.EBAY_USER_UPDATE_SETTINGS_FAILED:
			return state.set("ebay_user_settings_updating", false)

		case actions.EBAY_USER_GET_SETTINGS:
			return state.set("ebay_user_settings", {})
		case actions.EBAY_USER_GET_SETTINGS_SUCCESS:
			return state.set("ebay_user_settings", Object.assign({}, action.data.settings))
		case actions.EBAY_USER_GET_SETTINGS_FAILED:
			return state.set("ebay_user_settings", {})

		case actions.EBAY_USER_GET_POLICY:
			if(action.data.policy === 'all'){
				return state
					.set("ebay_fulfillment_policies", [])
					.set("ebay_payment_policies", [])
					.set("ebay_return_policies", [])
			} else {
				return state.set(policies_mapping[action.data.policy], [])
			}
		case actions.EBAY_USER_GET_POLICY_SUCCESS:
			if(action.data.policy === 'all'){
				var all_policies = {};
				Object.keys(policies_mapping).forEach(policy => {
					const policies = action.data.policies[policy].map(item => {
						return {label: item.name, value: item.id}
					})
					all_policies[policy] = policies;
				});
				return state
					.set("ebay_fulfillment_policies", Object.assign([], all_policies.fulfillment_policy))
					.set("ebay_payment_policies", Object.assign([], all_policies.payment_policy))
					.set("ebay_return_policies", Object.assign([], all_policies.return_policy))

			} else {
				const policies = action.data.policies.map(item => {
					return {label: item.name, value: item.id}
				})
				return state.set(policies_mapping[action.data.policy], Object.assign([], policies))
			}
		case actions.EBAY_USER_GET_POLICY_FAILED:
			if(action.data.policy === 'all'){
				return state
					.set("ebay_fulfillment_policies", [])
					.set("ebay_payment_policies", [])
					.set("ebay_return_policies", [])
			} else {
				return state.set(policies_mapping[action.data.policy], [])
			}

	  case actions.EBAY_EDIT_ITEM_DATA:
			let edit_item_data = Object.assign({}, state.get("ebay_list_item_data"));
			const edit_data = Object.assign([], action.data);
			edit_data.forEach(data => {
				if(data.type === 'array'){
					if(data.action === 'add'){
						edit_item_data[data.key].push(data.value);
					} else if (data.action === 'edit'){

					} else {
						edit_item_data[data.key] = edit_item_data[data.key].filter(item => item !== data.value);
					}
				} else {
					edit_item_data[data.key] = data.value;
				}
			});

			return state
				.set("ebay_list_item_data", edit_item_data)

	  case actions.EBAY_GET_ITEM_DATA:
			return state
				.set("ebay_list_item_data", {})
				.set("ebay_list_item_data_loading", true)
	  case actions.EBAY_GET_ITEM_DATA_SUCCESS:
				let get_item = Object.assign({}, action.data.item);
        if(!action.data.item.is_relist){
  				get_item.ebay_condition = ebay_condition_mapping_select(get_item.ebay_condition)
        }
        get_item = add_aditional_fields(get_item);
			return state
				.set("ebay_list_item_data", Object.assign({}, get_item))
				.set("ebay_list_item_data_loading", false)
	  case actions.EBAY_GET_ITEM_DATA_FAILED:
			return state
				.set("ebay_list_item_data", {})
				.set("ebay_list_item_data_loading", false)

	  case actions.EBAY_USER_AUTH:
			return state.set("user_auth_processing", true)
		/*
	  case actions.EBAY_USER_AUTH_SUCCESS:
			return state.set("user_auth_processing", false)
	  case actions.EBAY_USER_AUTH_FAILED:
			return state.set("user_auth_processing", false)
		*/

    case actions.EBAY_USER_GET_ITEMS_COUNT:
      return state
        .set("ebay_items_count", {})
    case actions.EBAY_USER_GET_ITEMS_COUNT_SUCCESS:
      return state.set("ebay_items_count", Object.assign(state.get("ebay_items_count"), action.data));
    case actions.EBAY_USER_GET_ITEMS_COUNT_FAILED:
      return state
        .set("ebay_items_count", {})

    case actions.EBAY_CROSS_LIST_IMAGE_UPLOAD:
      return state
        .set("ebay_cross_list_images_upload_working", true)
    case actions.EBAY_CROSS_LIST_IMAGE_UPLOAD_SUCCESS:
      let ebay_cross_list_item_data = Object.assign({}, state.get("ebay_list_item_data"));
      ebay_cross_list_item_data['img_urls'].push(action.data.url);
      return state
        .set("ebay_cross_list_images_upload_working", false);
    case actions.EBAY_CROSS_LIST_IMAGE_UPLOAD_FAILED:
      return state
        .set("ebay_cross_list_images_upload_working", false)

	default:
		return state;
	}
}
