import React, { Component } from 'react';
import { connect } from "react-redux";
import {  Badge, Card, CardBody, DropdownToggle, DropdownMenu, Button, Dropdown } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { subDays } from 'date-fns';
import Select from 'react-select';
import { momentDateToLocalFormatConversion } from '../../../helpers/utility';
import feedbackActions from "../../../redux/feedback/actions";
import ReactTable from "react-table";
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const { getFeedbacks } = feedbackActions;

const ViewHeader = () => (
  <header className="view-header">
    <h1 className="page-title">Feedback Manager</h1>
  </header>
);

const ViewContent = ({ children }) => (
  <div className="view-content view-components">
    <Card>
      <CardBody>{children}</CardBody>
    </Card>
  </div>
);

class FeedbackManager extends Component {
  state = {
    showIntervalDropdown: false,
    range: {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    },
    filters: {
      date_start: parseInt(subDays(new Date(), 7).getTime() / 1000),
      date_end: parseInt(new Date().getTime() / 1000),
      rating: null,
      fulfillment: null,
    },
    rating: null,
    fulfillment: null,
    commentDetailsIdx: []
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Feedback Manager"})
    this.props.getFeedbacks(this.createFiltersData(this.state.filters));
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
    let filters = Object.assign({}, this.state.filters);
    filters.date_start = parseInt(ranges.selection.startDate.getTime() / 1000);
    filters.date_end = parseInt(ranges.selection.endDate.getTime() / 1000);
    this.setState({ filters: filters });
  }

  applyRange = () => {
    this.props.getFeedbacks(this.createFiltersData(this.state.filters));
  }

  updateRating = (e) => {
    let filters = Object.assign({}, this.state.filters);
    filters.rating = e ? e.value : null;
    this.setState({ filters: filters });
    this.props.getFeedbacks(this.createFiltersData(filters));
  }

  updateFufillment = (e) => {
    let filters = Object.assign({}, this.state.filters);
    filters.fulfillment = e ? e.value : null;
    this.setState({ filters: filters });
    this.props.getFeedbacks(this.createFiltersData(filters));
  }

  createFiltersData = (filters) => {
    if(!filters.fulfillment){
      delete filters.fulfillment;
    }
    if(!filters.rating){
      delete filters.rating;
    }
    return filters;
  }

  getNextPage = (state) => {
    let filters = Object.assign({}, this.state.filters);
    filters.page = parseInt(state.page, 10) + 1;
    filters.per_page = state.pageSize;
    this.setState({ filters: filters });
    this.props.getFeedbacks(this.createFiltersData(filters));
  }

  toggleDetails = (idx) => {
    let commentDetailsIdx = this.state.commentDetailsIdx;
    if(commentDetailsIdx.includes(idx)) {
      commentDetailsIdx = commentDetailsIdx.filter(item => item !== idx)
    } else {
      commentDetailsIdx.push(idx)
    }

    this.setState({ commentDetailsIdx })
  }

  generateTableColums = () => {
    let tableColumns = [
      {
        id: "type",
        accessor: "type",
        Header: "Type",
        className: "text-truncate text-left",
        style: { alignSelf: "center" },
        width: 80,
        Cell: props => (
          <Badge color={props.value === "fba" ? "success" : "primary"}>{props.value.toUpperCase()}</Badge>
        ),
      },
      {
        id: "created_at_display",
        accessor: "created_at_display",
        Header: "Received",
        className: "text-truncate text-left",
        style: { alignSelf: "center" },
        width: 150,
        Cell: props => (
          <span>{momentDateToLocalFormatConversion(props.value, true)}</span>
        ),
      },
      {
        id: "rating",
        accessor: "rating",
        Header: "Rating",
        className: "text-truncate text-left",
        style: { alignSelf: "center" },
        width: 150,
      },
      {
        id: "comments",
        accessor: "comments",
        Header: "Comment",
        className: "text-truncate text-left",
        style: { alignSelf: "center", whiteSpace: "pre-wrap" },
        Cell: props => (
          <div style={{ maxWidth: "40rem" }}>
            {props.original.response &&
              this.state.commentDetailsIdx.includes(props.index)
              ? <p className="mb-0 mt-1"><em className="text-muted">{props.value}</em></p>
              : <p className="mb-0 mt-1 text-truncate"><em className="text-muted">{props.value.substr(0, 100)}</em></p>
            }
            <Button size="sm" color="link" onClick={() => this.toggleDetails(props.index)}>Toggle details</Button>
          </div>
        ),
      },
      {
        id: "order_id",
        accessor: "order_id",
        Header: "Order Details",
        className: "text-truncate text-left",
        style: { alignSelf: "center" },
        width: 240,
        Cell: props => (
          <div>
            <p className="mb-1">Order ID: <a href="#link" target="_blank" rel="noopener noreferrer">{props.value}</a></p>
            {/*<p className="mb-0">Purch. Date: <span className="text-muted">{row.purchase_date}</span></p>*/}
          </div>
        ),
      },
      {
        id: "order_status",
        accessor: "order_status",
        Header: "Order Status",
        className: "text-truncate text-left",
        style: { alignSelf: "center" },
        width: 150,
      },
    ]
    return tableColumns;
  }

  render() {
    const {
      range
    } = this.state;

    const expandRow = {
      renderer: row => (
        <div>
          <p className="mb-0">{row.comment}</p>
          {row.response && <p className="mb-0 mt-1"><strong>Response:</strong> <em className="text-muted">{row.response}</em></p>}
        </div>
      ),
    }

    return (
      <div className="view feedback-manager">
        <ViewHeader />
        <ViewContent>
          <div className="d-flex align-items-center mb-3">
            <strong>Filter:</strong>
            <Dropdown isOpen={this.state.showIntervalDropdown} toggle={this.toggleIntervalDropdown} className="ml-3">
              <DropdownToggle caret>
                Date Range: {momentDateToLocalFormatConversion(range.startDate, true)} - {momentDateToLocalFormatConversion(range.endDate, true)}
              </DropdownToggle>
              <DropdownMenu>
                <div className="mt-2">
                  <DateRangePicker
                    months={2}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    showDateDisplay={false}
                    ranges={[this.state.range]}
                    onChange={this.customRangesChange}
                  />
                  <div className="text-right px-3">
                    <Button size="sm" className="mr-2"
                      onClick={() => {
                        this.toggleIntervalDropdown();
                      }}
                    >Cancel</Button>
                    <Button size="sm" color="primary"
                      onClick={() => {
                        this.toggleIntervalDropdown();
                        this.applyRange();
                      }}
                    >Apply</Button>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
            <div style={{ minWidth: "10rem" }} className="ml-3">
              <Select
                placeholder="Select rating..."
                options={[
                  {value:1, label: "1"},{value:2, label: "2"},{value:3, label: "3"},{value:4, label: "4"},{value:5, label: "5"}
                ]}
                onChange={e => this.updateRating(e)}
                value={this.state.filters.rating ? this.state.filters.rating : null}
              />
            </div>
            <div style={{ minWidth: "10rem" }} className="ml-3">
              <Select
                placeholder="Select type..."
                options={[
                  {value:"fba", label: "FBA"},{value:"mf", label: "MF"}
                ]}
                onChange={e => this.updateFufillment(e)}
                value={this.state.filters.fulfillment ? this.state.filters.fulfillment : null}
              />
            </div>
          </div>
            <ReactTable
              data={this.props.feedbacks.items ? this.props.feedbacks.items : []}
              pages={this.props.feedbacks.pages ? this.props.feedbacks.pages : 0}
              columns={this.generateTableColums()}
              defaultPageSize={10}
              loading={this.props.feedbacks_loading}
              manual
              onFetchData={(state, instance) => { this.getNextPage(state) }}
              minRows={0}
            />
        </ViewContent>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
      feedbacks: state.Feedback.get("feedbacks"),
      feedbacks_loading: state.Feedback.get("feedbacks_loading"),
    };
  },
  {
    getFeedbacks,
    userPageviewCapture,
  }
)(FeedbackManager);
