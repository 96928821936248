import React, { Component } from 'react';
import { Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Row, Button, Form } from "reactstrap";

const boxesValues= [];

class DimensionModal extends Component {

  getDimentions = (e) => {
    e.preventDefault();
    var data = {
      for: "",
      width: e.target.width.value,
      Length: e.target.Length.value,
      Height: e.target.Height.value,
    };
    if (this.props.forAllBoxes) {
      data.for = "forAllboxes";
      if (this.props.boxes.length > 0) {
        let boxes = this.props.boxes;
        if (boxesValues.length == 0) {
          for (var i = 0; i < boxes.length; i++) {
            boxesValues.push({
              id: boxes[i].id,
              width: e.target.width.value,
              Length: e.target.Length.value,
              Height: e.target.Height.value,
            });
          }
        } else {
          for (var j = 0; j < boxes.length; j++) {
            const index = boxesValues.findIndex((el) => el.id === boxes[j].id);
            if (index >= 0) {
              boxesValues[index] = {
                id: boxes[j].id,
                width: e.target.width.value,
                Length: e.target.Length.value,
                Height: e.target.Height.value,
              };
            } else {
              boxesValues.push({
                id: boxes[j].id,
                width: e.target.width.value,
                Length: e.target.Length.value,
                Height: e.target.Height.value,
              });
            }
          }
        }
      }
    }
    if (this.props.boxId !== undefined && this.props.boxId !== isNaN) {
      data.for = "single";
      data.boxId = this.props.boxId;

      const index = boxesValues.findIndex((el) => el.id === this.props.boxId);
      if (index >= 0) {
        boxesValues[index] = {
          id: this.props.boxId,
          width: e.target.width.value,
          Length: e.target.Length.value,
          Height: e.target.Height.value,
        };
      } else {
        boxesValues.push({
          id: this.props.boxId,
          width: e.target.width.value,
          Length: e.target.Length.value,
          Height: e.target.Height.value,
        });
      }
    }
    this.props.onSetDimentions(data);
  };

  render() {
    let values = {
      id: 0,
      width: "",
      Length: "",
      Height: "",
    };

    const { isOpen, toggle, forAllBoxes, onSetDimentions, boxId, boxes } =
      this.props;
    let findValue = boxesValues.find((obj) => obj.id == boxId);
    if (findValue !== undefined) {
      values = findValue;
    }
    return (
      <Modal isOpen={isOpen} centered toggle={toggle}>
        <Form onSubmit={(e) => this.getDimentions(e)}>
            <ModalHeader toggle={toggle}>
              <strong>
                Input {forAllBoxes ? "all boxes " : "box "}dimensions here for Amazon:
              </strong>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={4}>
                  <Input
                    type={"text"}
                    name={"width"}
                    placeholder={"Width (inch)"}
                    ref={(node) => (this.inputNode = node)}
                    defaultValue={values.width}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    type={"text"}
                    name={"Length"}
                    placeholder={"Length (inch)"}
                    ref={(node) => (this.inputNode = node)}
                    defaultValue={values.Length}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    type={"text"}
                    name={"Height"}
                    placeholder={"Height (inch)"}
                    ref={(node) => (this.inputNode = node)}
                    defaultValue={values.Height}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" onClick={toggle}>
                Submit
              </Button>
            </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default DimensionModal;
