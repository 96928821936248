import React, { Component } from 'react';
import { connect } from 'react-redux';
import settingsActions from '../../../redux/settings/actions';
import authActions from "../../../redux/auth/actions";
import appActions from "../../../redux/app/actions";
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Button, Col, Row
} from 'reactstrap';
import { FaFacebookSquare as IconFacebook, FaLongArrowLeft as IconBack } from 'react-icons/lib/fa';
import AmazonAuthorization from './AmazonAuthorization';
import Address from '../address';
import CustomSKUSelector from '../../../shared/components/customSKUSelector';
import './style.css';
import addressAction from "../../../redux/address/actions";
import { exampleMSKU } from "../../../assets/images";
import WistiaPlayer from "../../../shared/components/WistiaEmbed";
import {
  printerDymo, printerRollo, printerZebra, printerBrother
} from "../../../assets/images";
import styled, { keyframes } from "styled-components";
import { slideInRight, fadeIn } from "react-animations";

const slideAnimation = keyframes`${slideInRight}`;
const fadeAnimation = keyframes`${fadeIn}`;
const SlideIn = styled.div`
	animation: .2s ${slideAnimation};
`;
const FadeIn = styled.div`
	animation: .2s ${fadeAnimation};
`;

const { fetchAdressList } = addressAction;

const { openMwsAuthorizeModal, getUser, verifyCredential } = authActions;
const { closeSettingsAlert } = settingsActions;

const {
  fetchListingDefaults,
  saveListingDefaults,
  updateUserDataSettings,
  updateUserSettings
} = settingsActions

const { userError } = appActions;
const { updateUserData, userSPAPIAuthStart } = authActions;

const IconArrowRight = () => <span className="icon icon-14 icon-chevron-right icon-arrow"></span>

class Onboarding_v2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      customSKUOpen: false,
      showPrinter: ""
    }
  }

  toggleTab = (tabId) => {
    this.setState({
      activeTab: tabId,
    });
  }

  openCustomSKU = () => {
    this.setState({
      customSKUOpen: true
    })
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.userData) {
      if (
        np.userData.marketplaceId
        && np.userData.sellerId
        && np.userData.sp_api_token) {
        this.setState({ activeTab: 2 });
      } else {
        this.setState({ activeTab: 1 });
        return;
      }
      if (this.props.addressList) {
        if (
          np.userData.marketplaceId
          && np.userData.sellerId
          && np.userData.sp_api_token
          && this.props.addressList.length > 0) {
          this.setState({ activeTab: 3 });
        } else {
          this.setState({ activeTab: 2 });
          return;
        }
        if (this.props.listingDefaults) {
          if (
            np.userData.marketplaceId
            && np.userData.sellerId
            && np.userData.sp_api_token
            && this.props.addressList.length > 0
            && this.props.listingDefaults.sku_prefix) {
            this.setState({ activeTab: 4 });
          } else {
            this.setState({ activeTab: 3 });
            return;
          }
        }
        if (this.props.printerDefaults) {
          if (
            np.userData.marketplaceId
            && np.userData.sellerId
            && np.userData.sp_api_token
            && this.props.addressList.length > 0
            && this.props.listingDefaults.sku_prefix
            && !!this.props.printerDefaults) {
            this.setState({ activeTab: 5 });
            if (this.props.userData && !this.props.userData.settings.is_onboarded) {
              let ud = np.userData;
              ud.settings['is_onboarded'] = true;
              this.props.updateUserData(ud);
              let data = { is_onboarded: ud.settings["is_onboarded"] };
              this.props.updateUserSettings(data);
            }
          } else {
            this.setState({ activeTab: 4 });
            return;
          }
        }
      } else {
        this.props.fetchAdressList();
      }
    }
  }

  showPrinter = (name) => {
    this.setState({
      showPrinter: name
    });
  }

  render() {
    const {
      listingDefaults,
      printerDefaults,
      userData,
      addressList,
    } = this.props;

    const {
      activeTab,
      customSKUOpen,
      showPrinter
    } = this.state;

    return (
      <div className="view">
        <div className="view-header">
          <h2 className="h4 page-title">Welcome to AccelerList</h2>
          <p>Let’s get you started by linking your Amazon account and providing some information.</p>
        </div>
        <div className="view-content onboarding mt-0">
          <div className="d-flex justify-content-between align-items-center mt-5">
            <Nav pills className="nav-pills-step">
              <NavItem>
                <NavLink
                  onClick={() => this.toggleTab(1)}
                  className={
                    userData &&
                      (!userData.marketplaceId ||
                        !userData.sellerId ||
                        !userData.sp_api_token)
                      ? "step-attention"
                      : activeTab >= 1 ? "step-ok" : null
                  }
                >
                  <div>
                    <span className="step">Step 1:</span>
                    <span className="title">AMAZON AUTHORIZATION</span>
                  </div>
                  <IconArrowRight />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => this.toggleTab(2)}
                  className={
                    addressList && !addressList.length > 0
                      ? "step-attention"
                      : activeTab >= 2 ? "step-ok" : null}
                >
                  <div>
                    <span className="step">Step 2:</span>
                    <span className="title">SET SHIPPING ADDRESS</span>
                  </div>
                  <IconArrowRight />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab >= 3}
                  onClick={() => this.toggleTab(3)}
                  className={
                    listingDefaults && !listingDefaults.sku_prefix
                      ? "step-attention"
                      : activeTab >= 3 ? "step-ok" : null}
                >
                  <div>
                    <span className="step">Step 3:</span>
                    <span className="title">SET CUSTOM SKU</span>
                  </div>
                  <IconArrowRight />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => this.toggleTab(4)}
                  className={activeTab >= 4
                      ? "step-ok"
                      : null
                  }
                >
                  <div>
                    <span className="step">Step 4:</span>
                    <span className="title">PRINTER SUPPORT</span>
                  </div>
                  <IconArrowRight />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => this.toggleTab(5)}
                  className={
                    activeTab >= 5 ? "step-ok" : null}
                >
                  <div>
                    <span className="step">Step 5:</span>
                    <span className="title">WATCH PRODUCT DEMO</span>
                  </div>
                  <IconArrowRight />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav pills>
              <NavItem><NavLink href="https://www.facebook.com/groups/accelerlist/" target="_blank" className="p-0"><IconFacebook size="28" color="#3B5998" /></NavLink></NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab} className="card tab-content-step">
            <TabPane tabId={1}>
              <AmazonAuthorization
                amazon_marketplace={
                  this.props.userData
                    ? this.props.userData.marketplaceId
                    : null
                }
                amazon_seller_id={
                  this.props.userData
                    ? this.props.userData.sellerId
                    : null
                }
                updateUserData={this.props.updateUserData}
                userData={this.props.userData}
                verifyCredential={this.props.verifyCredential}
                user_spapi_auth_start_loading={this.props.user_spapi_auth_start_loading}
                userSPAPIAuthStart={this.props.userSPAPIAuthStart}
		            updateUserDataSettings={this.props.updateUserDataSettings}
              />
            </TabPane>
            <TabPane tabId={2}>
              <Address />
            </TabPane>
            <TabPane tabId={3}>
              {customSKUOpen === false &&
                <div className="text-center">
                  <p>It’s now time to create your custom MSKU (Merchant Sku Number). This is very important step that allows you to customize your skus for each
                    product you list.</p>
                  <p>Below is an example MSKU set up that most sellers use. You can customize yours to include multiple data sets by pressing the button below.<br/>
                  It’s crucial you create a unique MSKU configuration so that none of your MSKUs repeat over time which will cause an error in your Seller Central account.</p>
                  <img src={exampleMSKU} alt="Example of MSKU" className="img-fluid mt-2" /><br/>
                  <Button color="success" className="mt-4" onClick={this.openCustomSKU}>Create your custom MSKU now</Button>
                </div>
              }
              <CustomSKUSelector
                isOpen={customSKUOpen}
                listingDefaults={listingDefaults}
                onSaveListingDefaults={this.props.saveListingDefaults}
              />
            </TabPane>
            <TabPane tabId={4}>
              <p>After you watch the demo video on the next step, you are going to want to connect your thermal label printer. This is the last
                step before you can create a batch and start listing products.
              </p>
              <p>Please select the printer you will be connecting and using to print labels:</p>
              <div className="printer-selection mt-5">
                <div className={`printer-item ${showPrinter !== "" ? (showPrinter === "dymo" ? "show" : "hide") : ""}`}>
                  <FadeIn className="printer-info" onClick={() => this.showPrinter("dymo")}>
                    <img src={printerDymo} alt="" />
                    <span className="name">Dymo</span>
                  </FadeIn>
                  <SlideIn className="printer-instruction">
                    <h5>Instruction for Dymo Printer</h5>
                    <ol>
                      <li>Ensure you have downloaded the <a href="https://www.accelerlist.com/printing_drivers" target="_blank" rel="noopener noreferrer">Dymo drivers</a> for your operating system</li>
                      <li>Plug in your Dymo printer and make sure drivers are in place.</li>
                      <li>Reference <a href="https://accelerlist.helpjuice.com/thermal-printing-and-labels/thermal-label-printing-guide" target="_blank" rel="noopener noreferrer">this article</a> to review all the different ways you can print product labels from AccelerList using your new Dymo thermal printer</li>
                      <li>Navigate to Settings &gt; Printer Settings and complete your printer setup with AccelerList</li>
                    </ol>
                  </SlideIn>
                </div>
                <div className={`printer-item ${showPrinter !== "" ? (showPrinter === "rollo" ? "show" : "hide") : ""}`}>
                  <FadeIn className="printer-info" onClick={() => this.showPrinter("rollo")}>
                    <img src={printerRollo} alt="" />
                    <span className="name">Rollo</span>
                  </FadeIn>
                  <SlideIn className="printer-instruction">
                    <h5>Instruction for Rollo Printer</h5>
                    <ol>
                      <li>Ensure you have downloaded the <a href="https://www.accelerlist.com/printing_drivers" target="_blank" rel="noopener noreferrer">Rollo drivers</a> for your operating system</li>
                      <li>Plug in your Rollo printer and make sure drivers are in place.</li>
                      <li>Reference <a href="https://accelerlist.helpjuice.com/thermal-printing-and-labels/thermal-label-printing-guide" target="_blank" rel="noopener noreferrer">this article</a> to review all the different ways you can print product labels from AccelerList using your new Rollo thermal printer</li>
                      <li>Navigate to Settings &gt; Printer Settings and complete your printer setup with AccelerList</li>
                    </ol>
                  </SlideIn>
                </div>
                <div className={`printer-item ${showPrinter !== "" ? (showPrinter === "zebra" ? "show" : "hide") : ""}`}>
                  <FadeIn className="printer-info" onClick={() => this.showPrinter("zebra")}>
                    <img src={printerZebra} alt="" />
                    <span className="name">Zebra</span>
                  </FadeIn>
                  <SlideIn className="printer-instruction">
                    <h5>Instruction for Zebra Printer</h5>
                    <ol>
                      <li>Ensure you have downloaded the <a href="https://www.accelerlist.com/printing_drivers" target="_blank" rel="noopener noreferrer">Zebra drivers</a> for your operating system</li>
                      <li>Plug in your Zebra printer and make sure drivers are in place.</li>
                      <li>Reference <a href="https://accelerlist.helpjuice.com/thermal-printing-and-labels/thermal-label-printing-guide" target="_blank" rel="noopener noreferrer">this article</a> to review all the different ways you can print product labels from AccelerList using your new Zebra thermal printer</li>
                      <li>Navigate to Settings &gt; Printer Settings and complete your printer setup with AccelerList</li>
                    </ol>
                  </SlideIn>
                </div>
                <div className={`printer-item ${showPrinter !== "" ? (showPrinter === "brother" ? "show" : "hide") : ""}`}>
                  <FadeIn className="printer-info" onClick={() => this.showPrinter("brother")}>
                    <img src={printerBrother} alt="" />
                    <span className="name">Brother</span>
                  </FadeIn>
                  <SlideIn className="printer-instruction">
                    <h5>Instruction for Brother Printer</h5>
                    <ol>
                      <li>Ensure you have downloaded the <a href="https://www.accelerlist.com/printing_drivers" target="_blank" rel="noopener noreferrer">Brother drivers</a> for your operating system</li>
                      <li>Plug in your Brother printer and make sure drivers are in place.</li>
                      <li>Reference <a href="https://accelerlist.helpjuice.com/thermal-printing-and-labels/thermal-label-printing-guide" target="_blank" rel="noopener noreferrer">this article</a> to review all the different ways you can print product labels from AccelerList using your new Brother thermal printer</li>
                      <li>Navigate to Settings &gt; Printer Settings and complete your printer setup with AccelerList</li>
                    </ol>
                  </SlideIn>
                </div>
              </div>
              <button
                className={`btn btn-link mt-2 btn-printer-selection ${showPrinter === "" ? "d-none" : ""}`}
                onClick={() => this.showPrinter("")}
              >
                <IconBack /> Back to printer selection
              </button>
            </TabPane>
            <TabPane tabId={5}>
              <div>
                <Row>
                  <Col sm="3">
                    <h5>Please watch the demo video.</h5>
                    <p className="lead">Afterwards, the last step may be setting up your thermal printer in the settings area. Then you're ready to create your first batch.</p>
                    <p>If you need help you can always reach us from the chat widget in the bottom righthand corner.</p>
                  </Col>
                  <Col sm="9">
                    <WistiaPlayer
                      hashedId="26tvge3mjv"
                      playerColor="#00943E"
                      aspectRatio={38}
                    />
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      mwsAuthValues: state.Auth.get("mwsAuthValues"),
      userData: state.Auth.get("userData"),
      listingDefaults: state.Settings.toJS().listingDefaults,
      printerDefaults: state.Settings.get("printerDefaults"),
      currentAlert: state.Settings.get("currentAlert"),
      isUserSettingsUpdating: state.Settings.get("isUserSettingsUpdating"),
      addressList: state.Address.get("addressList"),
      userTags: state.UserTags.get("userTags"),
      user_spapi_auth_start_loading: state.Auth.get("user_spapi_auth_start_loading"),
    }
  },
  {
    fetchListingDefaults,
    closeSettingsAlert,
    openMwsAuthorizeModal,
    saveListingDefaults,
    userError,
    updateUserDataSettings,
    getUser,
    updateUserData,
    updateUserSettings,
    fetchAdressList,
    verifyCredential,
    userSPAPIAuthStart,
  }
)(Onboarding_v2);
