import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions'
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import Papa from 'papaparse'
import fileDownload from 'react-file-download'

import {
	adminUserEnableRepricerAPI,
} from '../../helpers/apis';

import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* ChangeUserPassword() {
    yield takeLatest('CHANGE_USER_PASSWORD', function* (payload) {
        const { data } = payload;
        try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.admin.password, data);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.changeUserPasswordSuccess())
			} else {
				yield put(actions.changeUserPasswordFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.changeUserPasswordFailure());
          logError(error, {
            tags: {
              exceptionType: "CHANGE_USER_PASSWORD_ERROR"
            }
          });
        }
    })
}

export function* SearchUser() {
    yield takeLatest('SEARCH_USER', function* (payload) {
        const { data } = payload;
        try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.admin.search_user, data);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.searchUserSuccess(response.data.data))
			} else {
				yield put(actions.searchUserFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.searchUserFailure());
          logError(error, {
            tags: {
              exceptionType: "SEARCH_USER_ERROR"
            }
          });
        }
    })
}

export function* SearchUsersPerMarketplace() {
    yield takeLatest('SEARCH_USERS_PER_MARKETPLACE', function* (payload) {
        try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.serach_user_marketplaces);
            if (response.status === 200 && !response.error) {
              yield put(actions.searchUsersPerMarketplaceSuccess(response.data.data))
			} else {
				yield put(actions.searchUsersPerMarketplaceFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data users per marketplace api error"));
          yield put(actions.searchUsersPerMarketplaceFailure());
          logError(error, {
            tags: {
              exceptionType: "SEARCH_USERS_PER_MARKETPLACE_ERROR"
            }
          });
        }
    })
}

export function* SearchUsersErrorLogs() {
    yield takeLatest('SEARCH_USERS_ERRORLOGS', function* (payload) {
        try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.search_user_errorlogs);
            if (response.status === 200 && !response.error) {
              yield put(actions.searchUsersErrorlogsSuccess(response.data.data))
			} else {
				yield put(actions.searchUsersErrorlogsFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data users error logs api error"));
          yield put(actions.searchUsersErrorlogsFailure());
          logError(error, {
            tags: {
              exceptionType: "SEARCH_USERS_ERRORLOGS_ERROR"
            }
          });
        }
    })
}

export function* userUpdateAuthToken() {
    yield takeLatest('USER_AUTH_TOKEN_UPDATE', function* (payload) {
        const { data } = payload;
        try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_clearauthtoken, data);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.userTokenUpdateSuccess())
			} else {
				yield put(actions.userTokenUpdateFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.userTokenUpdateFailure());
          logError(error, {
            tags: {
              exceptionType: "ADMIN_USER_UPDATE_TOKEN_ERROR"
            }
          });
        }
    })
}

export function* getBatchStats() {
    yield takeLatest('BATCH_STATS', function* (payload) {
        try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.batch_stats);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.batchStatsSuccess(response.data))
			} else {
				yield put(actions.batchStatsFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.batchStatsFailure());
          logError(error, {
            tags: {
              exceptionType: "ADMIN_BATCH_STATS_ERROR"
            }
          });
        }
    })
}

export function* userEnableRepricer() {
    yield takeLatest('USER_ENABLE_REPRICER', function* (payload) {
        const { data } = payload;
        try {
            const response = yield call(adminUserEnableRepricerAPI, data)
            if (response.status === 200 && !response.data.error) {
              yield put(actions.userEnableRepricerSuccess(data))
			} else {
				yield put(actions.userEnableRepricerFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.userEnableRepricerFailure());
          logError(error, {
            tags: {
              exceptionType: "USER_ENABLE_REPRICER_ERROR"
            }
          });
        }
    })
}

export function* getSBYBStats() {
    yield takeLatest('GET_SBYB_STATS', function* (payload) {
        try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.sbyb.admin_tracking_stats);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.getSBYBStatsSuccess(response.data))
			} else {
				yield put(actions.getSBYBStatsFailure());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.getSBYBStatsFailure());
          logError(error, {
            tags: {
              exceptionType: "GET_SBYB_STATS_ERROR"
            }
          });
        }
    })
}

export function* getRepricerStats() {
    yield takeLatest('GET_REPRICER_STATS', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.repricer.stats);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getRepricerStatsSuccess(response.data))
				} else {
					yield put(actions.getRepricerStatsFailure());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.getRepricerStatsFailure());
        logError(error, {
            tags: {
              exceptionType: "GET_REPRICER_STATS_ERROR"
            }
        });
      }
    })
}

export function* getCategoriesStatsBooks() {
    yield takeLatest('GET_CATEGORIES_STATS_BOOKS', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.stats_categories_books);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getCategoriesStatsBooksSuccess(response.data))
				} else {
					yield put(actions.getCategoriesStatsBooksFailed());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.getCategoriesStatsBooksFailed());
        logError(error, {
            tags: {
              exceptionType: "GET_CATEGORIES_STATS_BOOKS_ERROR"
            }
        });
      }
    })
}

export function* getCategoriesStatsOther() {
    yield takeLatest('GET_CATEGORIES_STATS_OTHER', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.stats_categories_other);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getCategoriesStatsOtherSuccess(response.data))
				} else {
					yield put(actions.getCategoriesStatsOtherFailed());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.getCategoriesStatsOtherFailed());
        logError(error, {
            tags: {
              exceptionType: "GET_CATEGORIES_STATS_OTHER_ERROR"
            }
        });
      }
    })
}

export function* updateUser() {
	yield takeLatest('UPDATE_USER', function* (payload) {
			const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_update, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.updateUserSuccess(response.data.message))
				} else {
					yield put(actions.updateUserFailed(response.data.error));
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.updateUserFailed());
        logError(error, {
            tags: {
              exceptionType: "UPDATE_USER_ERROR"
            }
        });
      }
    })
}

export function* userWhitelist() {
	yield takeLatest('USER_WHITELIST', function* (payload) {
			const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_register_whitelist, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userWhitelistSuccess(response.data.message))
				} else {
					yield put(actions.userWhitelistFailed(response.data.error));
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.userWhitelistFailed());
        logError(error, {
            tags: {
              exceptionType: "USER_WHITELIST_ERROR"
            }
        });
      }
    })
}

export function* userFBCCompare() {
	yield takeLatest('USER_FBC_COMPARE', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_fbc_compare, null);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userFBCCompareSuccess(response.data))
				} else {
					yield put(actions.userFBCCompareFailed());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.userFBCCompareFailed());
        logError(error, {
            tags: {
              exceptionType: "USER_FBC_COMPARE_ERROR"
            }
        });
      }
    })
}

export function* userEnableEbay() {
    yield takeLatest('USER_ENABLE_EBAY', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_enableebay, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userEnableEbaySuccess(data))
				} else {
					yield put(actions.userEnableEbayFailure());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
				yield put(actions.userEnableEbayFailure());
				logError(error, {
					tags: {
						exceptionType: "USER_ENABLE_EBAY_ERROR"
					}
				});
			}
    })
}

export function* getAdminEbayLogs() {
	yield takeLatest('USER_GET_EBAY_LOGS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_logs, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getAdminEbayLogsSuccess(response.data));
				} else {
					yield put(actions.getAdminEbayLogsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [get user logs]"));
				yield put(actions.getAdminEbayLogsFailed());
				logError(error, {
					tags: {
						exceptionType: "USER_GET_EBAY_LOGS_ERROR"
					}
				});
			}
	})
}

export function* getUserSuccessAdmin() {
	yield takeLatest('GET_USER_SUCCESS_ADMIN', function* (payload) {
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_success, null);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getUserSuccessAdminSuccess(response.data))
				} else {
					yield put(actions.getUserSuccessAdminFailed());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.getUserSuccessAdminFailed());
        logError(error, {
            tags: {
              exceptionType: "GET_USER_SUCCESS_ADMIN"
            }
        });
      }
    })
}

export function* getCancelReasonAdmin() {
	yield takeLatest('GET_CANCEL_REASON_ADMIN', function* (payload) {
      const { data } = payload;
      try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.cancel_reason, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getCancelReasonAdminSuccess(response.data.data))
				} else {
					yield put(actions.getCancelReasonAdminFailed());
				}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
        yield put(actions.getCancelReasonAdminFailed());
        logError(error, {
            tags: {
              exceptionType: "GET_CANCEL_REASON_ADMIN"
            }
        });
      }
    })
}

export function* getAdminUsersSegmentation() {
    yield takeLatest('GET_USERS_SEGMENTATION', function* (payload) {
        try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_segmentation);
      if (response.status === 200 && !response.error) {
        yield put(actions.getAdminUsersSegmentationSuccess(response.data.data))
			} else {
				yield put(actions.getAdminUsersSegmentationFailed());
			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data users segmentation api error"));
          yield put(actions.getAdminUsersSegmentationFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_USERS_SEGMENTATION_ERROR"
            }
          });
        }
    })
}

export function* getEbayStats() {
    yield takeLatest('GET_EBAY_STATS', function* (payload) {
      try {
  			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.ebay_stats, payload.data);
        if (response.status === 200 && !response.error) {
          yield put(actions.getEbayStatsSuccess(response.data.data))
			  } else {
				  yield put(actions.getEbayStatsFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data  ebay stats api error"));
        yield put(actions.getEbayStatsFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_EBAY_STATS_ERROR"
          }
        });
      }
    })
}

export function* getEbayUserSales() {
    yield takeLatest('GET_EBAY_USER_SALES', function* (payload) {
      try {
        const response = yield call(backendAPICall, 'get', apiEndpoints.admin.ebay_sales_users, {});
        if (response.status === 200 && !response.error) {
          yield put(actions.getEbayUserSalesSuccess(response.data.data))
			  } else {
				  yield put(actions.getEbayUserSalesFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data ebay user sales api error"));
        yield put(actions.getEbayUserSalesFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_EBAY_USER_SALES_ERROR"
          }
        });
      }
    })
}

export function* getEbayUserSalesDetails() {
    yield takeLatest('GET_EBAY_USER_SALE_DETAILS', function* (payload) {
      try {
        const response = yield call(backendAPICall, 'get', apiEndpoints.admin.ebay_sales_user_details, payload.data);
        if (response.status === 200 && !response.error) {
          yield put(actions.getEbayUserSalesDetailsSuccess(response.data.data))
			  } else {
				  yield put(actions.getEbayUserSalesDetailsFailed());
          yield put(appActions.apiCallFailed(response.error));
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data ebay user sales api error"));
        yield put(actions.getEbayUserSalesDetailsFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_EBAY_USER_SALE_DETAILS_ERROR"
          }
        });
      }
    })
}

export function* getFaqTopics() {
  yield takeLatest('GET_FAQ_TOPICS', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.faq.topics, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getFaqTopicsSuccess(response.data.data))
      } else {
        yield put(actions.getFaqTopicsFailed());
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetch admin data topics api error"));
      yield put(actions.getFaqTopicsFailed());
      logError(error, {
        tags: {
          exceptionType: "GET_FAQ_TOPICS_ERROR"
        }
      });
    }
  })
}

export function* addFaqTopic() {
  yield takeLatest('ADD_FAQ_TOPIC', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.faq.topic, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.addFaqTopicSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Add topic api error"));
      logError(error, {
        tags: {
          exceptionType: "ADD_FAQ_TOPIC_ERROR"
        }
      });
    }
  })
}

export function* updateFaqTopic() {
  yield takeLatest('UPDATE_FAQ_TOPIC', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.faq.topic, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.updateFaqTopicSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Add topic api error"));
      logError(error, {
        tags: {
          exceptionType: "UPDATE_FAQ_TOPIC_ERROR"
        }
      });
    }
  })
}

export function* getFaqArticles() {
  yield takeLatest('GET_FAQ_ARTICLES', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.faq.articles, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getFaqArticlesSuccess(response.data.data))
      } else {
        yield put(actions.getFaqArticlesFailed());
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetch admin data articles api error"));
      yield put(actions.getFaqArticlesFailed());
      logError(error, {
        tags: {
          exceptionType: "GET_FAQ_ARTICLES_ERROR"
        }
      });
    }
  })
}

export function* addFaqArticle() {
  yield takeLatest('ADD_FAQ_ARTICLE', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.faq.article, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.addFaqArticleSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Add article api error"));
      logError(error, {
        tags: {
          exceptionType: "ADD_FAQ_ARTICLE_ERROR"
        }
      });
    }
  })
}

export function* updateFaqArticle() {
  yield takeLatest('UPDATE_FAQ_ARTICLE', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.faq.article, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.updateFaqArticleSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Add article api error"));
      logError(error, {
        tags: {
          exceptionType: "UPDATE_FAQ_ARTICLE_ERROR"
        }
      });
    }
  })
}

export function* deleteFaqArticle() {
  yield takeLatest('DELETE_FAQ_ARTICLE', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'delete', apiEndpoints.faq.article, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.deleteFaqArticleSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Delete article api error"));
      logError(error, {
        tags: {
          exceptionType: "DELETE_FAQ_ARTICLE_ERROR"
        }
      });
    }
  })
}

export function* deleteFaqTopic() {
  yield takeLatest('DELETE_FAQ_TOPIC', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'delete', apiEndpoints.faq.topic, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.deleteFaqTopicSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Delete topic api error"));
      logError(error, {
        tags: {
          exceptionType: "DELETE_FAQ_TOPIC_ERROR"
        }
      });
    }
  })
}

export function* reorderFaqTopics() {
  yield takeLatest('REORDER_FAQ_TOPICS', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.faq.topics_reorder, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.reorderFaqTopicsSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
        yield put(actions.reorderFaqTopicsFailed())
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Reorder topic api error", error));
      logError(error, {
        tags: {
          exceptionType: "REORDER_FAQ_TOPICS_ERROR"
        }
      });
    }
  })
}

export function* getEbayStatsSalesAverages() {
    yield takeLatest('GET_EBAY_STATS_SALES_AVERAGES', function* (payload) {
      try {
  			const response = yield call(backendAPICall, 'get', apiEndpoints.admin.ebay_stats_sales_averages, {});
        if (response.status === 200 && !response.error) {
          yield put(actions.getEbayStatsSalesAveragesSuccess(response.data.data))
			  } else {
				  yield put(actions.getEbayStatsSalesAveragesFailed());
  			}
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fetch admin data ebay stats api error"));
        yield put(actions.getEbayStatsSalesAveragesFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_EBAY_STATS_SALES_AVERAGES_ERROR"
          }
        });
      }
    })
}

export function* getAppSettings() {
  yield takeLatest('GET_APP_SETTINGS', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.admin.app_settings, {});
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getAppSettingsSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
        yield put(actions.getAppSettingsFailed())
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! APP settings api error", error));
      logError(error, {
        tags: {
          exceptionType: "GET_APP_SETTINGS_ERROR"
        }
      });
    }
  })
}

export function* updateAppSettings() {
  yield takeLatest('UPDATE_APP_SETTINGS', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.admin.app_settings, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.updateAppSettingsSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! APP settings api error", error));
      logError(error, {
        tags: {
          exceptionType: "UPDATE_APP_SETTINGS_ERROR"
        }
      });
    }
  })
}

export function* getEbayAveragesTotal() {
  yield takeLatest('GET_EBAY_AVERAGES_TOTAL', function* (payload) {
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.admin.sales_users_averages, payload.data);
      if (response.status === 200 && !response.data.error) {
        yield put(actions.getEbayAveragesTotalSuccess(response.data.data))
      } else {
        yield put(appActions.apiCallFailed(response.data.error));
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fetch admin averages ebay total api error", error));
      logError(error, {
        tags: {
          exceptionType: "GET_EBAY_AVERAGES_TOTAL_ERROR"
        }
      });
    }
  })
}

export function* userEnableSMS() {
    yield takeLatest('USER_ENABLE_SMS', function* (payload) {
        const { data } = payload;
        try {
			      const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_sms_enable, data);
            if (response.status === 200 && !response.data.error) {
              yield put(actions.userEnableSMSSuccess(response.data))
    		  	} else {
		    	  	yield put(actions.userEnableSMSFailure());
              yield put(appActions.apiCallFailed(response.data.error));
      			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.userEnableSMSFailure());
          logError(error, {
            tags: {
              exceptionType: "USER_ENABLE_SMS_ERROR"
            }
          });
        }
    })
}

export function* userSpApiUserCountGet() {
    yield takeLatest('SPAPI_USER_COUNT_GET', function* (payload) {
        const { data } = payload;
        try {
			      const response = yield call(backendAPICall, 'get', apiEndpoints.admin.spapi_user_count, {});
            if (response.status === 200 && !response.data.error) {
              yield put(actions.userSpApiUserCountGetSuccess(response.data))
    		  	} else {
		    	  	yield put(actions.userSpApiUserCountGetFailure());
              yield put(appActions.apiCallFailed(response.data.error));
      			}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Fetch admin data api error"));
          yield put(actions.userSpApiUserCountGetFailure());
          logError(error, {
            tags: {
              exceptionType: "SPAPI_USER_COUNT_GET_ERROR"
            }
          });
        }
    })
}

export function* getAdminSpApiLogs() {
	yield takeLatest('USER_GET_SPAPI_LOGS', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_logs_spapi, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.getAdminSpApiLogsSuccess(response.data));
				} else {
					yield put(actions.getAdminSpApiLogsFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [get user logs]"));
				yield put(actions.getAdminSpApiLogsFailed());
				logError(error, {
					tags: {
						exceptionType: "USER_GET_SPAPI_LOGS_ERROR"
					}
				});
			}
	})
}

export function* userAdminSpApiActivate() {
	yield takeLatest('USER_ACTIVATE_SPAPI', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.admin.user_spapi_activate, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userAdminSpApiActivateSuccess(response.data));
				} else {
					yield put(actions.userAdminSpApiActivateFailed(response.data));
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [activate spapi]"));
				yield put(actions.userAdminSpApiActivateFailed({error: error}));
				logError(error, {
					tags: {
						exceptionType: "USER_ACTIVATE_SPAPI_ERROR"
					}
				});
			}
	})
}

export function* userAdminLogActionExport() {
	yield takeLatest('USER_LOG_ACTION_EXPORT', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.user_log_action_export, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userAdminLogActionExportSuccess());
          const csv = Papa.unparse(JSON.stringify(response.data) , {
            delimiter: ",",
            header: true,
            skipEmptyLines: false
          });
          fileDownload(csv, `user_action_log_${data.username}.csv`);
				} else {
					yield put(actions.userAdminLogActionExportFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [activity log]" + error));
				yield put(actions.userAdminLogActionExportFailed({error: error}));
				logError(error, {
					tags: {
						exceptionType: "USER_LOG_ACTION_EXPORT_ERROR"
					}
				});
			}
	})
}

export function* userAdminAIAnalyzeAnswer() {
	yield takeLatest('AI_ANALYZE_ANSWER', function* (payload) {
		const { data } = payload;
		try {
				const response = yield call(backendAPICall, 'post', apiEndpoints.admin.ai_analyze, data);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.userAdminAIAnalyzeAnswerSuccess(response.data));
				} else {
					yield put(actions.userAdminAIAnalyzeAnswerFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [AI Analyze]" + error));
				yield put(actions.userAdminAIAnalyzeAnswerFailed({error: error}));
				logError(error, {
					tags: {
						exceptionType: "AI_ANALYZE_ANSWER_ERROR"
					}
				});
			}
	})
}

export function* adminUserSplitsAggregateGet() {
	yield takeLatest('ADMIN_USER_SPLITS_AGREGATE_GET', function* (payload) {
		try {
				const response = yield call(backendAPICall, 'get', apiEndpoints.admin.batch_splits, null);
				if (response.status === 200 && !response.data.error) {
					yield put(actions.adminUserSplitsAggregateGetSuccess(response.data));
				} else {
					yield put(actions.adminUserSplitsAggregateGetFailed());
				}
			} catch (error) {
				yield put(appActions.apiCallFailed("Error! Admin api error [Splits] " + error));
				yield put(actions.adminUserSplitsAggregateGetFailed({error: error}));
				logError(error, {
					tags: {
						exceptionType: "ADMIN_USER_SPLITS_AGREGATE_GET_ERROR"
					}
				});
			}
	})
}

export default function* rootSaga() {
    yield all([
      fork(ChangeUserPassword),
      fork(SearchUser),
			fork(SearchUsersPerMarketplace),
			fork(SearchUsersErrorLogs),
			fork(userUpdateAuthToken),
			fork(getBatchStats),
			fork(userEnableRepricer),
			fork(getSBYBStats),
			fork(getRepricerStats),
			fork(getCategoriesStatsBooks),
			fork(getCategoriesStatsOther),
			fork(updateUser),
			fork(userWhitelist),
			fork(userFBCCompare),
			fork(userEnableEbay),
			fork(getAdminEbayLogs),
			fork(getUserSuccessAdmin),
      fork(getCancelReasonAdmin),
      fork(getAdminUsersSegmentation),
      fork(getEbayStats),
      fork(getEbayUserSales),
      fork(getEbayUserSalesDetails),
      fork(getFaqTopics),
      fork(addFaqTopic),
      fork(updateFaqTopic),
      fork(getFaqArticles),
      fork(addFaqArticle),
      fork(updateFaqArticle),
      fork(deleteFaqArticle),
      fork(deleteFaqTopic),
      fork(reorderFaqTopics),
      fork(getEbayStatsSalesAverages),
      fork(getAppSettings),
      fork(updateAppSettings),
      fork(getEbayAveragesTotal),
      fork(userEnableSMS),
      fork(userSpApiUserCountGet),
      fork(getAdminSpApiLogs),
      fork(userAdminSpApiActivate),
      fork(userAdminLogActionExport),
      fork(userAdminAIAnalyzeAnswer),
      fork(adminUserSplitsAggregateGet),
    ])
}
