import React, { Component, useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import sopActions from '../../../redux/sop/actions';
import { EditorState, convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
import { backendAPICall, apiEndpoints } from '../../../helpers/backend/api/client';

const {
  getSopCategories,
  getSopTags,
  saveSopProcedure,
} = sopActions;


// Hooks needed to solve scrolling problem on enter (new line)
const RichEditor = (props) => {
  const [lines, setLines] = useState(0);
  const editorRef = useRef(null);

  const handleEditorChange = function(e) {
    const prevLines = lines;

    if(prevLines !== e.blocks.length) {
      setLines(e.blocks.length);
    }
  };

  const uploadCallback = function(file) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file, file.name);
      const promise = new backendAPICall('post', apiEndpoints.sop.direct_image_upload, data);
      promise.then(response => {
        resolve({ data: { link: response.data.url } });
      })
    })
  };

  const config = {
    image: {
      uploadCallback: uploadCallback,
      uploadEnabled: true,
    }
  }

  useEffect(() => {
    const scrollDiv = editorRef.current.querySelector(".rdw-editor-main");
    scrollDiv.scrollTop = scrollDiv.scrollHeight;
  }, [lines]);

 return (
   <div ref={editorRef}>
     <Editor
       onContentStateChange={(e) => handleEditorChange(e)}
       toolbar={config}
       {...props}
     />
    </div>
 );
}

class SopCreate extends Component {
  state = {
    title: "",
    tags: [],
    category: null,
    description: EditorState.createEmpty(),
  }

  updateDescription = (value) => {
    this.setState({ description: value });
  }

  onTagsChange = (tags) => {
    this.setState({ tags })
  }

  componentDidMount = () => {
    this.props.getSopCategories();
    this.props.getSopTags();
  }

  saveProcedure = () => {
    if(!this.validDataSet()){
      return;
    }
    const { title, category, description, tags } = this.state;
    const data = {
      title: title,
      category: category ? category.value : 0,
      tags: tags.map(item => {return item.value}).join(','),
      description: JSON.stringify(convertToRaw(description.getCurrentContent())),
    }
    this.props.saveSopProcedure(data);
  }

  validDataSet = () => {
    const { title, description } = this.state;
    if(
      !title.trim()
      || !this.state.description.getCurrentContent().getPlainText().trim()
    ) {
      return false;
    }
    return true;
  }

  render() {

    return (
      <div className="sop-create-edit">
        <Card>
          <CardHeader>
            <strong>Create new procedure</strong>
            <NavLink to="/dashboard/sop" className="ml-3"><span className="icon icon-arrow-left bg-primary"></span> Back</NavLink>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  placeholder="Provide procedure title"
                  onChange={e => this.setState({ title: e.target.value })}
                  value={this.state.title}
                />
              </FormGroup>
              <FormGroup row>
                <Col md={6}>
                  <Label>Category</Label>
                  <Select
                    options={
                      this.props.categories.map(item => {
                        return {
                          label: item.name,
                          value: item.id,
                          item: item,
                        }
                      })
                    }
                    onChange={e => this.setState({ category: e })}
                    value={this.state.category}
                  />
                </Col>
                <Col md={6}>
                  <Label>Tags</Label>
                  <CreatableSelect
                    name="tags"
                    value={this.state.tags}
                    onChange={this.onTagsChange}
                    options={
                      this.props.tags.map(item => {
                        return {
                          label: item.name,
                          value: item.id,
                          item: item,
                        }
                      })
                    }
                    multi={true}
                    isValidNewOption={() => false}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label>Content</Label>
                <RichEditor
                  placeholder="Explain your standard operating procedures"
                  onEditorStateChange={this.updateDescription}
                  editorState={this.state.description}
                />
              </FormGroup>
              {/*
              <FormGroup>
                <Label>Upload document (PDF/Word)</Label>
                <Input type="file" />
              </FormGroup>
              */}
            </Form>
            <div className="d-flex justify-content-between mt-4">
              <Button
                color="primary"
                onClick={e => this.saveProcedure()}
                disabled={this.props.save_procedure_working || !this.validDataSet()}
              >SAVE</Button>
              <NavLink to="/dashboard/sop" className="btn btn-cancel">CANCEL</NavLink>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
  		userData: state.Auth.get('userData'),
  		categories: state.Sop.get('categories'),
      tags: state.Sop.get('tags'),
      save_procedure_working: state.Sop.get('save_procedure_working'),
    };
  },
  {
    getSopCategories,
    getSopTags,
    saveSopProcedure,
  }
)(SopCreate);
