import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { openInNewTab, momentDateToLocalFormatConversion } from '../../../helpers/utility';
import IconWarning from "react-icons/lib/md/report";
import './style.css';

class EbayCredsModal extends Component {
  state = {
    isOpen: true,
    userAuthExecuted: false,
  }

	executeAuth = () => {
    this.setState({ userAuthExecuted: true });
    this.props.updateUserDataSettings({ebay_auth: false});
    this.props.ebayUserAuth();
	}

  render() {
    const {userData} = this.props;
    return (
      <Modal isOpen={this.state.isOpen}>
        <ModalHeader>eBay reAuth</ModalHeader>
        <ModalBody>
          <h1 className="h4 mb-3">
            <IconWarning className="text-danger mr-2" />
            <span>eBay reAuth needed</span>
          </h1>
          <p>
            eBay you access granted to AccelerList will expire on <b>{momentDateToLocalFormatConversion(userData?.settings?.ebay_refresh_token_expire * 1000)}</b>. <br/>
            Please reauth so we can continue providing you with great service
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={e => this.executeAuth()}
            disabled={this.state.userAuthExecuted}
          >reAuth</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default EbayCredsModal;
