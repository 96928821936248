import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Col, FormGroup, Input } from 'reactstrap';
import DeleteDialog from '../../../../shared/components/dialogs/DeleteDialog';
import FAQTopic from './FAQTopic';
import { DragDropContext, DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

class ManageTopics extends Component {

	constructor (props) {
		super(props);
		this.state = {
      selected_topic_type: {value: "ebay", label: "eBay"},
      deleteTopicId: null,
      topic_name: "",
      topic_desc: "",
      topic_update_data: {},
		};
  }

  toggleConfirmDelete = (id) => {
    let topicId = this.state.deleteTopicId;
    this.setState({ deleteTopicId: topicId ? null : id })
  }

  deleteTopic = (id) => {
    const data = {
      topic_id: id,
    }
    this.props.deleteFaqTopic(data);
    this.toggleConfirmDelete();
  }

  addTopic = () => {
    if(this.state.selected_topic_type){
      const data = {
        name: this.state.topic_name,
        description: this.state.topic_desc,
        topic_type: this.state.selected_topic_type.value,
      }
      this.props.addFaqTopic(data);
      this.setState({
        topic_name: "",
        topic_desc: "",
      });
    }
  }

  updateTopicData = (id, key, value) => {
    let topic_update_data = this.state.topic_update_data;
    if(!topic_update_data[id]){
      topic_update_data[id] = { topic_id: id }
    }
    topic_update_data[id][key] = value;
    this.setState({ topic_update_data: topic_update_data });
  }

  updateTopic = (id) => {
    let topic_update_data = this.state.topic_update_data;
    let data = topic_update_data[id];
    this.props.updateFaqTopic(data);
    delete topic_update_data[id];
    this.setState({ topic_update_data: topic_update_data });
  }

  reorderTopic = (from, to) => {
  }

  saveTopicOrder = (from, to) => {
    if(from.topic_id !== to.topic_id) {
      const data = {
        topics: [
          { topic_id: from.topic_id },
          { topic_id: to.topic_id },
        ]
      }
      this.props.reorderFaqTopics(data);
    }
  }

  render() {
    let filteredTopics = this.props.admin_faq_topics.filter(item => item.topic_type === this.state.selected_topic_type.value);

    return(
      <div className="manage-faq-topics">
        <FormGroup row>
          <Col md={4}>
            <h5>Choose Category</h5>
            <Select
              options={[
                {value: "ebay", label: "eBay"},
                {value: "profit_reprice", label: "Profit Reprice™"}
              ]}
              value={this.state.selected_topic_type}
              onChange={e => this.setState({ selected_topic_type: e })}
            />
          </Col>
        </FormGroup>

        <hr/>

        <div className="faq-topic">
          <h5>Add/Edit Topics</h5>
          <DragDropContextProvider backend={HTML5Backend}>
          {filteredTopics.length > 0 ?
            filteredTopics.map((item, idx) => (
              <FAQTopic
                index={idx}
                key={`faq-topic${idx}`}
                name={item.name}
                description={item.description}
                topic_type={item.topic_type}
                topic_id={item.id}
                updateTopicData={this.updateTopicData}
                updateTopic={this.updateTopic}
                deleteTopic={() => this.toggleConfirmDelete(item.id)}
                topic_update_data={this.state.topic_update_data}
                reorderTopic={this.reorderTopic}
                saveTopicOrder={this.saveTopicOrder}
                disabled={this.props.admin_faq_reordering_topics}
              />
            )) : <p className="text-muted text-center">No topics found on this category, create new topic on form below.</p>
          }
          </DragDropContextProvider>

          <FormGroup row className="faq-topic-add">
            <Col md={4}>
              <Input
                placeholder="New topic menu name"
                value={this.state.topic_name}
                onChange={e => this.setState({ topic_name: e.target.value })}
              />
            </Col>
            <Col md={6}>
              <Input
                placeholder="New topic title"
                value={this.state.topic_desc}
                onChange={e => this.setState({ topic_desc: e.target.value })}
              />
            </Col>
            <Col md={2}>
              <Button
                color="success"
                onClick={e => this.addTopic()}
                disabled={
                  !this.state.topic_name
                    || !this.state.topic_desc
                    || !this.state.selected_topic_type
                }
              >Save</Button>
            </Col>
          </FormGroup>

          <DeleteDialog
            isOpen={this.state.deleteTopicId !== null}
            close={() => this.toggleConfirmDelete(null)}
            deleteItem={() => this.deleteTopic(this.state.deleteTopicId)}
            dialogTitle="Are you sure want to delete this FAQ topic?"
            dialogText="Deleting this topic WILL DELETE ALL QUESTIONS under it."
            confirmText="Yes, delete topic"
          />
        </div>
      </div>
    )
  }
}

export default DragDropContext(HTML5Backend)(ManageTopics);
