import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import SKUTable from './SKUTable';
import LoadingIndicator from '../../../../../shared/components/LoadingIndicator';
import repricerActions from '../../../../../redux/repricer/actions';
import { digitСonversion } from '../../../../../helpers/utility';
import fileDownload from 'react-file-download';
import Papa from 'papaparse'
import {
  generateRepricerReportSpreadsheetData,
}  from '../../../../../helpers/repricer/utility'


import './style.css';
import RejectionStat from './RejectionStat';

const { userRepricerReportSummary } = repricerActions;

class ReportDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1
    }
  }

	UNSAFE_componentWillMount(){
		this.props.userRepricerReportSummary(this.props.id)
	}

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  generateRepricerReportExport = (data) => {
    let exportableData = generateRepricerReportSpreadsheetData(data);
    let csv = Papa.unparse(JSON.stringify(exportableData) , {
      delimiter: ",",
      header: true,
      skipEmptyLines: false
    })
    fileDownload(csv, 'RepricerReportSpreadsheet.csv');
  }

  render() {
    const { toggleView, repriceReportSummary } = this.props;
    const { activeTab } = this.state;
		const {
			reprice_report_summary,
			reprice_report,
		} = repriceReportSummary;

		var lowestPrice = 9999999999999;
		var highestPrice = 0;
		var avgPrice = 0;

		if(reprice_report){
			if(reprice_report.approve_report.length > 0){
				var sum = 0;
				reprice_report.approve_report.forEach(item => {
					if(item.new_price > highestPrice){highestPrice = item.new_price}
					if(item.new_price < lowestPrice){lowestPrice = item.new_price}
					sum = sum + item.new_price;
				});
				avgPrice = parseFloat(sum / reprice_report.approve_report.length).toFixed(2);
			} else {
				lowestPrice = 0;
			}
		}

    return (

      <Fragment>
		{this.props.repriceReportSummaryLoading
				|| !repriceReportSummary
				|| !reprice_report_summary ? <LoadingIndicator className="mt-0" title="Fetching Reprice Reports Summary..." />
        :
	  <div className="reprice-report-details">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <h5 className="mb-0 mr-2 card-title d-inline-block">Reprice Report Detail</h5> <Button color="link" onClick={toggleView} ><span className="icon icon-arrow-left bg-primary"></span> Back</Button>
          </div>
          <div className="inline-stat-group ">
            <span className="mini-inline-stat mr-3"><span className="icon icon-history"></span> Last Run: {this.props.lastRun}</span>
            <span className="mini-inline-stat mr-3"><span className="icon icon-calendar-add"></span> {reprice_report_summary.num_skus} SKUs Scheduled</span>
            <span className="mini-inline-stat mr-3"><span className="icon icon-adjust"></span> {reprice_report_summary.num_skus_adjusted} SKUs Adjusted</span>
          </div>
        </div>
        <div className="simple-metric-group my-5">
          <div className="simple-metric-item">
            <span className="title">SKUs Repriced</span>
			<span className="value">{reprice_report.approve_report ?
				reprice_report.approve_report.length : 0}</span>
          </div>
          <div className="simple-metric-item">
            <span className="title">SKUs Not Repriced</span>
			<span className="value">{reprice_report.reject_report ?
				reprice_report.reject_report.length : 0}</span>
          </div>
          <div className="simple-metric-item">
            <span className="title">New Avg. Price</span>
              <span className="value">{digitСonversion(avgPrice, "currency", this.props.internationalization_config.currency_code)}</span>
          </div>
          <div className="simple-metric-item">
            <span className="title">Lowest Price</span>
            <span className="value">{digitСonversion(lowestPrice, "currency", this.props.internationalization_config.currency_code)}</span>
          </div>
          <div className="simple-metric-item">
            <span className="title">Highest Price</span>
            <span className="value">{digitСonversion(highestPrice, "currency", this.props.internationalization_config.currency_code)}</span>
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              onClick={() => this.toggleTab(1)}
              className={activeTab === 1 ? 'active' : ''}
            >
              Repriced SKUs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => this.toggleTab(2)}
              className={activeTab === 2 ? 'active' : ''}
            >
							Unadjusted SKUs &nbsp;
              <Badge color="success">
							{ reprice_report_summary
									&& reprice_report_summary.unadjusted_sum ?
										digitСonversion(reprice_report_summary.unadjusted_sum, "currency", this.props.internationalization_config.currency_code)
										: digitСonversion(0, "currency", this.props.internationalization_config.currency_code)
							}
              </Badge>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => this.toggleTab(3)}
              className={activeTab === 3 ? 'active' : ''}
            >
              Rejection Statistic
            </NavLink>
          </NavItem>
          <NavItem>
            <Button
              size="sm"
              className="ml-2 my-1"
              onClick={() => this.generateRepricerReportExport(this.props.repriceReportSummary)}
              disabled={!this.props.repriceReportSummary}
            >
              <span className="icon icon-csv"></span> Download Report
            </Button>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3">
          <TabPane tabId={1}>
			  <SKUTable
          data={
            reprice_report.approve_report.map(item => {
    					item.template_name = reprice_report.template_id_to_name_mapping[item.template_id];
		    			item.event_id = this.props.id;
    					return item;
		    		  }
            )
          }
				  type={ "repriced" }
          insightTemplate={this.props.insightTemplate}
          insightTemplateLoading={this.props.insightTemplateLoading}
          reportInsightRun={this.props.reportInsightRun}
          internationalization_config={this.props.internationalization_config}
			  />
          </TabPane>
          <TabPane tabId={2}>
			  <SKUTable
				  data={ reprice_report.reject_report }
				  type={ "unadjusted" }
          internationalization_config={this.props.internationalization_config}
			  />
          </TabPane>
          <TabPane tabId={3}>
            <RejectionStat
              data={this.props.repriceReportSummary}
            />
          </TabPane>
        </TabContent>
      </div>
	}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
	repriceReportSummary: state.Repricer.get("repriceReportSummary"),
	repriceReportSummaryLoading: state.Repricer.get("repriceReportSummaryLoading"),
	internationalization_config: state.Auth.get("internationalization_config"),
  }),
	{
		userRepricerReportSummary,
	}
)(ReportDetail)
