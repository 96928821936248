import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { milestone100, milestone500, milestone1000, milestone5000, milestone10000 } from './images';
import {
  FaFacebookSquare,
  //FaTwitter
} from 'react-icons/lib/fa';
import { openInNewTab } from '../../../helpers/utility';
import './style.css';

const milestones_data = {
  "100": {
    "path": milestone100,
    "money": "100",
  },
  "500": {
    "path": milestone500,
    "money": "500",
  },
  "1000": {
    "path": milestone1000,
    "money": "1000",
  },
  "5000": {
    "path": milestone5000,
    "money": "5000",
  },
  "10000": {
    "path": milestone10000,
    "money": "10000",
  },
}

class MilestoneModal extends Component {
  state = {
    isOpen: false,
  }

  toggle = () =>  {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["ebay_sales_aggregate_displayed_at"] = this.props.sales;
			this.props.updateUserData(ud);
			let data = {ebay_sales_aggregate_displayed_at: ud.settings["ebay_sales_aggregate_displayed_at"]};
			this.props.updateUserSettings(data);
      this.setState({ isOpen: false })
		}
  }

  selectMilestone = () => {
    let milestone = {
      "path": null,
      "money": null,
    };
    Object.keys(milestones_data).forEach(key => {
      if(this.props.sales > parseInt(key, 10)){
        milestone = milestones_data[key];
      }
    });
    if(milestone.path && parseInt(milestone.money, 10) > this.props.displayed_at){
      if(!this.state.isOpen){
        this.setState({ isOpen: true });
      }
    } else {
      milestone = {
        "path": null,
        "money": null,
      };
    }
    return milestone;
  }

  shareFacebook = (milestone_money) => {
    const image_url = `https://www.accelerlist.com/milestone-${milestone_money}.png`
    const url = `https://www.facebook.com/sharer/sharer.php?u=${image_url}&quote=Brought%20to%20you%20by%20AccelerList!`;
    openInNewTab(url);
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} className="milestone-modal" size="lg">
        <ModalHeader toggle={this.toggle}></ModalHeader>
        <ModalBody>
          <div className="illustration">
            <img
              src={this.selectMilestone()['path']}
              alt=""
              className="img-fluid"
            />
          </div>
          <h1 className="title">YOU DID IT!</h1>
          <p className="subtitle">You have earned ${this.selectMilestone()['money']} in sales. Share your awesome job to others!</p>
          <div className="actions">
            <Button
              className="btn-social btn-facebook"
              onClick={e => this.shareFacebook(this.selectMilestone()['money'])}
            >
              <FaFacebookSquare /> Share on Facebook
            </Button>
            {/*
            <Button className="btn-social btn-twitter">
              <FaTwitter /> Share on Twitter
            </Button>
            */}
          </div>
          <Button
            color="link"
            size="sm"
            onClick={this.toggle}
            className="btn-close"
          >Close</Button>
        </ModalBody>
      </Modal>
    )
  }
}

export default MilestoneModal;
