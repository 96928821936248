import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button, Badge, InputGroup, InputGroupAddon, Input } from "reactstrap";
import "../../../style.css";
import repricerActions from '../../../../../redux/repricer/actions';
import DeleteDialog from '../../../../../shared/components/dialogs/DeleteDialog';
import {
    momentDateTimeToLocalFormatConversion
} from '../../../../../helpers/utility';
import './style.css';

const {
  userLoadWorkingTemplate,
  userDeleteWorkingTemplate,
  templateUpdateName,
  templateAdminTest,
} = repricerActions;

class TemplatesTable extends Component {

  state = {
    deleteModalOpen: false,
    templateToDelete: null,
    editId: "",
    edit_name: "",
  }

  deleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  }

  editClick = (template_id) => {
    this.props.userLoadWorkingTemplate(template_id);
    this.props.toggleEditor();
    this.props.onEdit(true);
  }

  deleteClick = (template_id) => {
    this.setState({
      deleteModalOpen: true,
      templateToDelete: template_id,
    });
  }

  deleteTemplateAction = () => {
    this.props.userDeleteWorkingTemplate(
      {
        data: { template_id: this.state.templateToDelete }
      }
    );
    this.deleteModalClose();
  }

  toggleEdit = (id, name) => {
    let editId = this.state.editId;
    this.setState({
      editId: editId !== id ? id : editId
    });
    if(editId !== id){
      this.setState({ edit_name: name });
    }
  }

  endEdit = (id) => {
    this.setState({
      editId: "",
      edit_name: "",
    });
  }

  onInputName = (name) => {
    this.setState({ edit_name: name });
  }

  saveChanges = (id, template_id) => {
    const data = {
      template_id: template_id,
      name: this.state.edit_name,
    }
    this.props.templateUpdateName(data);
    this.endEdit(id);
  }

  testTemplate = (id) => {
    const data = {
      template_id: id,
    }
    this.props.templateAdminTest(data);
  }

  render() {
    const {
      editId
    } = this.state;

    let tableColumns = [
      {
        id: "name",
        Header: "Template Name",
        accessor: "name",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          <div>
            <span
              style={{ textDecoration: "underline dashed", cursor: "pointer", display: editId === `edit-${props.original.id}` ? "none" : "inline"}}
              onClick={!this.props.templateNameUpdating ?
                  () => this.toggleEdit(`edit-${props.original.id}`, props.original.name)
                  : null
              }
            >
              <span>{props.original.name} <span className="icon icon-edit ml-2"></span></span>
            </span>
            {editId === `edit-${props.original.id}` &&
              <InputGroup id={`edit-${props.original.id}`}>
                <Input
                  autoFocus
                  value={this.state.edit_name ? this.state.edit_name : props.original.name}
                  onChange={(e) => this.onInputName(e.target.value)}
                  disabled={this.props.templateNameUpdating}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    disabled={this.props.templateNameUpdating}
                    size="sm"
                    onClick={() => this.saveChanges(`edit-${props.original.id}`, props.original.id )}><span className="icon icon-check bg-success"></span></Button>
                  <Button
                    disabled={this.props.templateNameUpdating}
                    size="sm"
                    onClick={() => this.endEdit(`edit-${props.original.id}`)}><span className="icon icon-close bg-danger"></span></Button>
                </InputGroupAddon>
              </InputGroup>
            }
          </div>
        )
      },
      {
        id: "created_at",
        Header: "Created",
        accessor: "created_at",
        headerClassName:"text-left",
        className: "text-left",
        Cell: props => (
          momentDateTimeToLocalFormatConversion(props.original.created_at, true)
        ),
      },
      {
        id: "updated_at",
        Header: "Updated",
        accessor: "updated_at",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          momentDateTimeToLocalFormatConversion(props.original.updated_at, true)
        ),
      },
      {
        id: "status",
        Header: "Status",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          props.original.is_active_schedule ?
            <Badge
              color="success"
              disabled={true}
            >ACTIVE</Badge>
          :
            <Badge
              color="default"
              disabled={true}
            >NOT ACTIVE</Badge>
        )
      },
        {
          id: "actions",
          Header: "Actions",
          headerClassName: "text-left",
          className: "text-left",
          accessor: "id",
          sortable: false,
          Cell: props => (
            <React.Fragment>
              <Button
                color="link"
                onClick={e => this.editClick(props.original.id)}
              ><span className="icon icon-edit"></span></Button>
              &nbsp;&nbsp;
              <Button
                color="link"
                onClick={e => this.deleteClick(props.original.id)}
              ><span className="icon icon-trash bg-danger"></span></Button>
              {this.props.admin_login ?
              <React.Fragment>
                &nbsp;&nbsp;
                <Button
                  color="link"
                  onClick={e => this.testTemplate(props.original.id)}
                  disabled={this.props.admin_test_data_loading}
                ><span className="icon icon-play bg-success"></span></Button>
              </React.Fragment> : null
              }
            </React.Fragment>
          )
        },
    ];

    return (
      <React.Fragment>
        {this.props.admin_test_data ?
          <div>
            <pre>
              {JSON.stringify(this.props.admin_test_data, null, 2) }
            </pre>
          </div>
          : null
        }
        <ReactTable
          data={this.props.templates} // should default to []
          columns={tableColumns}
          className="template-table"
          minRows={0}
        />
        <DeleteDialog
          isOpen={this.state.deleteModalOpen}
          close={this.deleteModalClose}
          deleteItem={this.deleteTemplateAction}
          dialogTitle="Are you sure you want to delete this template?"
          dialogText="You will loose reprice template and reprice schedule attached only with this template."
          confirmText="Yes, delete template"
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    templateNameUpdating: state.Repricer.get("templateNameUpdating"),
    admin_login: state.Auth.get("admin_login"),
    admin_test_data: state.Repricer.get("admin_test_data"),
    admin_test_data_loading: state.Repricer.get("admin_test_data_loading"),
  }),
  {
    userLoadWorkingTemplate,
    userDeleteWorkingTemplate,
    templateUpdateName,
    templateAdminTest,
  }
)(TemplatesTable);
