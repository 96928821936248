import React, { Component } from 'react';
import { Button } from 'reactstrap';
import phone from './phone.svg';
import './style.css';
import { connect } from "react-redux";
import authAction from "../../../redux/auth/actions";
import { withRouter } from "react-router-dom";

const {
  userSMSConfirmationResend,
  userSMSConfirm,
} = authAction;

const CODE_LENGTH = new Array(4).fill(0);

class VerifyPhoneComponent extends Component {
  state = {
    value: "",
    focused: false,
    code_sent: false,
  }

  input = React.createRef();

  handleClick = () => {
    this.input.current.focus()
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  handleChange = (e) => {
    const value = e.target.value;

    this.setState((state) => {
      if (state.value.length >= CODE_LENGTH.length) return null;
      return {
        value: (state.value + value).slice(0, CODE_LENGTH.length)
      }
    })
  }

  handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      this.setState((state) => {
        return {
          value: state.value.slice(0, state.value.length - 1)
        }
      })
    }
  }

  confirmSMSCode = () => {
    let username = this.props.username;
    const data = {
      username: username ? username : this.props.match?.params?.username,
      code: this.state.value,
    }
    this.props.userSMSConfirm(data);
  }

  resendMSCode = (email) => {
    let username = this.props.username;
    const data = {
      username: username ? username : this.props.match?.params?.username,
      email: email ? email : null,
    }
    this.props.userSMSConfirmationResend(data);
    this.setState({ code_sent: true });
  }

  render() {
    const { value, focused } = this.state;
    const values = value.split("");
    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
    const hideInput = !(values.length < CODE_LENGTH.length);

    return (
      <form className="verify-phone">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="mr-5">
              <img src={phone} alt="" className="img-phone" />
            </div>
            <div>
              <div>
                <h1 className="title">Verify Phone Number</h1>
                <p className="subtitle">Please wait up to 3 mins for registration code and enter below once received.</p>
                <div className="sms-code-input" onClick={this.handleClick}>
                  <input
                    value=""
                    ref={this.input}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    className="input"
                    style={{
                      width: "133px",
                      top: "0px",
                      bottom: "0px",
                      left: `${selectedIndex * 138}px`,
                      opacity: hideInput ? 0 : 1,
                    }}
                  />
                  {CODE_LENGTH.map((v, index) => {
                    const selected = values.length === index;
                    const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;
                    return (
                      <div className="display" key={index}>
                        {values[index]}
                        {(selected || filled) && focused && <div className="shadows" />}
                      </div>
                    );
                  })}
                </div>
                  <Button
                    color="success"
                    className="mt-4"
                    block
                    onClick={() => this.confirmSMSCode()}
                    disabled={!this.state.value || this.state.value.length !== 4 || this.props.confirmation_sms_working}
                  >Take me to my account!</Button>
                  {!this.state.code_sent ?
                    <p className="text-muted mt-4">
                      Didn't receive the code?
                      <Button
                        className="ml-2"
                        color="link"
                        onClick={() => this.resendMSCode()}
                        disabled={this.props.resend_confirmation_sms_working}
                      >Resend Confirmation Code</Button>
                    </p>
                    :
                    <p className="text-muted mt-4">
                      Code sent. Please enter new code above or
                      <p className="text-muted mt-4">
                        Didn't receive the code?
                        <Button
                          className="ml-2"
                          color="link"
                          onClick={() => this.resendMSCode(true)}
                          disabled={this.props.resend_confirmation_sms_working}
                        >Resend Confirmation Code By Email</Button>
                      </p>
                    </p>
                  }
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      resend_confirmation_sms_working: state.Auth.get("resend_confirmation_sms_working"),
      confirmation_sms_working: state.Auth.get("confirmation_sms_working"),
    }),
    {
      userSMSConfirmationResend,
      userSMSConfirm,
    }
  )(VerifyPhoneComponent)
);
