import React, { Component } from 'react';
import {
  Input, Col, FormGroup, InputGroup, InputGroupAddon,
  Label, Button, UncontrolledCollapse, ListGroup, ListGroupItem
} from 'reactstrap';
//import TagsInput from 'react-tagsinput';
import Select from "react-select";
import CollapsibleFormGroup from './CollapsibleFormGroup';
import IconSearch from "react-icons/lib/md/search";
import IconClose from "react-icons/lib/md/close"
import IconLinkExternal from "react-icons/lib/fa/external-link";
import { digitСonversion } from "../../../helpers/utility";

const identifier_select_options = [
  { label: "UPC", value: "UPC"},
  { label: "EAN", value: "EAN"},
  { label: "MPN", value: "MPN"},
  { label: "ISBN", value: "ISBN"},
];

class General extends Component {
  state = {
    dropdownShow: false,
    isLoading: false,
    showingResult: false,
    identifier_suggestion: null,
  }

  updateQuery = (value) => {
    this.props.ebayDirectSearchQueryUpdate({ebay_direct_search_query: value})
  }

  searchProduct = () => {
    if(!this.props.ebay_direct_search_query){ return }
    this.setState({ identifier_suggestion: null });
    const data = {
      query: this.props.ebay_direct_search_query,
    }
    this.props.ebayDirectSearchProducts(data);
  }

  clearSearch = () => {
    this.props.ebayDirectSearchProductsClear();
    this.updateQuery("");
  }

  productSelect = (item) => {
    this.props.ebayDirectSearchProductsSelect(item);
  }

  productIdentifierSuggestionsList = () => {
    const { ebay_direct_listing_item } = this.props;
    let data = [];
    if(ebay_direct_listing_item.additional_data && ebay_direct_listing_item.additional_data.unique_identifiers){
      if(ebay_direct_listing_item.additional_data.unique_identifiers.ean){
        ebay_direct_listing_item.additional_data.unique_identifiers.ean.forEach(item => {
          data.push({ label: `EAN: ${item}`, value: item, identifier_type: "EAN" });
        });
      }
      if(ebay_direct_listing_item.additional_data.unique_identifiers.upc){
        ebay_direct_listing_item.additional_data.unique_identifiers.upc.forEach(item => {
          data.push({ label: `UPC: ${item}`, value: item, identifier_type: "UPC" });
        });
      }
      if(ebay_direct_listing_item.additional_data.unique_identifiers.isbn){
        ebay_direct_listing_item.additional_data.unique_identifiers.isbn.forEach(item => {
          data.push({ label: `ISBN: ${item}`, value: item, identifier_type: "ISBN" });
        });
      }
    }
    return data;
  }

  productIdentifierSuggestionsListChange = (e) => {
    if(!e){ return }
    const identifier = identifier_select_options.find(item => item.value === e.identifier_type);
    if(identifier){
      this.setState({ identifier_suggestion: e });
      this.props.editData("identifier_type", e ? identifier.value : null);
      this.props.editData("identifier", e.value);
    }
  }

  displayLenghtMax = (max, data, property) => {
    let value = 0;
    if(data && data[property]){
      value = max - data[property].length;
    }
    if(value < 0){
      return <span style={{color: "#ff0000"}}>{Math.abs(value)}</span>;
    } else {
      return <span>{value}</span>;
    }
  }

  render() {
    const {
      ebay_direct_listing_item,
      validateData,
      collapse,
      toggleCollapse,
      ebay_direct_search_products,
      ebay_direct_search_products_working,
    } = this.props;

    return (
      <CollapsibleFormGroup
        title="General"
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      >
        <FormGroup row>
          <Col md={6}>
            <div className="input-has-dropdown append">
              <InputGroup className="input-timer-loading">
                <Input
                  placeholder="Scan or enter a keyword, UPC or ISBN"
                  className="input-timer-loading"
                  onChange={e => this.updateQuery(e.target.value)}
                  value={this.props.ebay_direct_search_query}
                  onKeyDown={e => {
                    if(e.which === 13) {
                      this.searchProduct()
                    }
                  }}
                />
                {ebay_direct_search_products_working && <AnimatedTimer />}
                {ebay_direct_search_products.length > 0 &&
                <Button className="btn-clear px-0 py-2" onClick={this.clearSearch}>
                  <span className="icon icon-12 icon-close icon-clear"></span>
                </Button>
                }
                <InputGroupAddon addonType="append">
                  <Button color="primary"
                    onClick={this.searchProduct}
                  ><span className="icon icon-search bg-white"></span></Button>
                </InputGroupAddon>
              </InputGroup>
              <div className={`dropdown ${ebay_direct_search_products.length > 0 ? "show" : ""}`}>
                <div className="dropdown-menu">
                  <ListGroup className="product-list-group">
                    {ebay_direct_search_products.map((item, idx) => (
                      <ListGroupItem
                        tag="button"
                        action
                        key={`direct-product-${idx}`}
                        onClick={e => this.productSelect(item)}
                      >
                        <div className="product-list-item">
                          <img src={item.imageUrl} className="picture" alt="" />
                          <div>
                            <p className="title">{item.name}</p>
                            <span className="attribute">ASIN: <strong>{item.ASIN}</strong></span>
                            <span className="attribute ml-3">Rank: <strong>{digitСonversion(item.salesrank, "rank")}</strong></span>
                            <span className="attribute ml-3">Category: <strong>{item.category}</strong></span>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
          {ebay_direct_listing_item.asin1 ?
            <div className="py-2">
              ASIN:&nbsp;
              <a
                href={`https://www.amazon.com/dp/${ebay_direct_listing_item.asin1}`}
                target="_blank"
                rel="noopener noreferrer"
              >{ebay_direct_listing_item.asin1} (View product <IconLinkExternal /> )</a>
            </div>
          : null
          }
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <Label>Product Title</Label>
            <Input
              placeholder={`ex: "Tools of Titans, By Tim Ferris"`}
              value={ebay_direct_listing_item.title ? ebay_direct_listing_item.title : ""}
              onChange={e => this.props.editData("title", e.target.value)}
						  invalid={!validateData('title', ebay_direct_listing_item.title)}
            />
						<div>Length: {this.displayLenghtMax(79, ebay_direct_listing_item, "title")}</div>
            <Button
              color="link"
              id="subtitle"
              className="mt-2"
            >Add sub title</Button>
            <UncontrolledCollapse toggler="#subtitle" >
              <Input
                placeholder="Provide sub title"
                value={ebay_direct_listing_item.sub_title ? ebay_direct_listing_item.sub_title : ""}
                onChange={e => this.props.editData("sub_title", e.target.value)}
						    style={!validateData('sub_title', ebay_direct_listing_item.sub_title) ? {border: "1px solid #ff0000"} : null}
              />
						  <div>Length: {this.displayLenghtMax(54, ebay_direct_listing_item, "sub_title")}</div>
            </UncontrolledCollapse>
          </Col>
          <Col md={3}>
            <Label>Product Identifier Suggestions</Label>
            <InputGroup>
              <InputGroupAddon addonType="append" style={{ minWidth: "100%" }}>
                <Select
                  value={ebay_direct_listing_item.asin1 ? this.state.identifier_suggestion : null}
                  options={this.productIdentifierSuggestionsList()}
                  onChange={e => this.productIdentifierSuggestionsListChange(e)}
                  isClearable={false}
                />
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md={3}>
            <Label>Product Identifier</Label>
            <InputGroup>
              <InputGroupAddon addonType="append" style={{ minWidth: "6.5rem" }}>
                <Select
                  value={ebay_direct_listing_item.identifier_type}
                  options={identifier_select_options}
                  onChange={e => this.props.editData("identifier_type", e ? e.value : null)}
						      style={!validateData('identifier_type', ebay_direct_listing_item.identifier_type) ? {border: "1px solid #dc3545"} : null}
                />
              </InputGroupAddon>
              <Input
                value={ebay_direct_listing_item.identifier ? ebay_direct_listing_item.identifier : ""}
                onChange={e => this.props.editData("identifier", e.target.value)}
                invalid={
                  !validateData('identifier', ebay_direct_listing_item.identifier)
                    || !ebay_direct_listing_item.identifier_type
                }
              />
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <Label>Product Description</Label>
            <Input
              type="textarea"
              row="5"
              value={ebay_direct_listing_item.description ? ebay_direct_listing_item.description : ""}
              onChange={e => this.props.editData("description", e.target.value)}
						  invalid={!validateData('description', ebay_direct_listing_item.description)}
            />
          </Col>
          <Col md={6}>
            {/*
            <Label>Product Tags</Label>
            <TagsInput
              value={ebay_direct_listing_item.tags ? ebay_direct_listing_item.tags : []}
              onChange={e => this.props.editData("tags", e)}
            />
            */}
          </Col>
        </FormGroup>
      </CollapsibleFormGroup>
    )
  }
}

const AnimatedTimer = () => (
  <span className="animated-timer timer-small">
    <svg width="36px" height="42px" viewBox="659 385 36 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path d="M683,385 L671,385 L671,389 L683,389 L683,385 Z M691.05,397.77 L693.9,394.92 C693.04,393.89 692.1,392.95 691.07,392.09 L688.22,394.94 C685.15,392.48 681.24,391 676.99,391 C667.04,391 659,399.06 659,409 C659,418.94 667.04,427 676.99,427 C686.94,427 695,418.94 695,409 C695,404.75 693.52,400.85 691.05,397.77 Z M677,423 C669.27,423 663,416.73 663,409 C663,401.27 669.27,395 677,395 C684.73,395 691,401.27 691,409 C691,416.73 684.73,423 677,423 Z" id="Shape" stroke="none" fill="#000000" fillRule="nonzero"></path>
    </svg>
  </span>
);

export default General;
