import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Badge, Modal, ModalBody, Button } from 'reactstrap';
import { ebayLogoShadowed, documentOutlineGif } from '../../../assets/images';
import General from './General';
import UploadImages from './UploadImages';
import ListingProfile from './ListingProfile';
import PricingSKU from './PricingSKU';
//import ProductVariations from './ProductVariations';
//import ReturnProfile from './ReturnProfile';
//import Shipping from './Shipping';
//import PaymentProfile from './PaymentProfile';
import ebayActions from '../../../redux/ebay/actions';
import { ebay_item_data_validation } from "../../../helpers/ebay/utility";
import './style.css';
import PackageDetails from './PackageDetails';
//import BuyerRequirements from './BuyerRequirements';
import { IoIosCheckmarkOutline as IconSuccess, IoIosCloseOutline as IconError } from 'react-icons/lib/io';
import BestOffer from './BestOffer';
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const {
  ebayDirectListingItemInit,
  ebayDirectListingItemEdit,
  ebayDirectListingUploadImage,
  ebayDirectListingLoadCategories,
  ebayDirectCreateItemData,
  ebayGetItemAspects,
  ebayDirectSearchProducts,
  ebayDirectSearchProductsClear,
  ebayDirectSearchProductsSelect,
  ebayDirectAdditionalData,
  ebayGetPackageTypes,
  ebayDirectListingClosePopup,
	ebayUserGetSettings,
  ebayDirectSearchQueryUpdate,
} = ebayActions;

const ViewHeader = () => (
  <header className="view-header">
    <h1 className="page-title">
      List on eBay
      <Badge color="primary" pill> BETA</Badge>
    </h1>
  </header>
);

const ViewContent = ({ children }) => (
  <div className="view-content view-components">
    {children}
  </div>
);

const IMG_MAX_COUNT = 5;
const IMG_MAX_SIZE = 1; // MB
class ListEbay extends Component {
  state = {
    imagePreviews: [],
    collapseGeneral: false,
    collapseUploadImage: false,
    collapseListingProfile: false,
    collapsePricingSKU: false,
    collapseVariations: false,
    collapseReturn: false,
    collapseDomestic: false,
    collapseInternational: false,
    collapseShipping: false,
    collapsePaymentProfile: false,
    collapsePackageDetails: true,
    listingInProgress: true,
    collapseBestOffer: true,
  }

  componentDidMount(){
    this.props.userPageviewCapture({url: window.location.href, al_page: "eBay/List"})
    this.props.ebayDirectSearchProductsClear();
    this.props.ebayGetPackageTypes();
	  this.props.ebayUserGetSettings();

    // demo loading
    setTimeout(() => {
      this.setState({ listingInProgress: false })
    }, 6000)
  }

  toggleCollapse = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }

  editData = (key, value, type=null) => {
    this.props.ebayDirectListingItemEdit({ key, value, type });
  }

	updateEbayByBase = (field, value, base) => {
		const { ebay_direct_listing_item } = this.props;
    let ed = {
      "dimensions": {},
      "weight": {},
      "packageType": null,
    };
    if(ebay_direct_listing_item.ebay_dimensions){
	    ed = Object.assign({}, ebay_direct_listing_item.ebay_dimensions);
    }
    if(base === "packageType"){
      ed[field] = value;
    } else {
      ed[base][field] = value;
      if(!ed[base][field]){
        ed[base][field] = 0;
      }
    }
		this.editData('ebay_dimensions', ed, "object");
	}

  addAdditionalFieldsForDimensions = () => {
    const { ebay_direct_listing_item } = this.props;
    if(ebay_direct_listing_item.amazon_dimensions
        && ebay_direct_listing_item.amazon_dimensions.item){
      let item_dimensions = ebay_direct_listing_item.amazon_dimensions.item;
      item_dimensions["unit"] = 'INCH';
      let item_weight = {
        "unit": 'POUND',
      }
      if(item_dimensions.weight){
        item_weight['value'] = parseFloat(item_dimensions.weight);
      }
      const dimensions = {
        dimensions: item_dimensions,
        weight: item_weight,
      }
			this.editData('ebay_dimensions', dimensions, "object");
    }
  }

  itemDataValidation = (key, value) => {
    return ebay_item_data_validation(key, value, true);
  }

  isValidForListing = () => {
    const keys = ['title', 'sub_title', 'identifier', 'identifier_type',
      'description', 'ebay_category', 'ebay_condition', 'price', 'qty', 'sku',
      'dimensions', 'best_offer',
    ];
    let valid = true;
    keys.forEach(key => {
      if(valid){
        if(key === 'dimensions'){
          if(!this.state.collapsePackageDetails){
            valid = ebay_item_data_validation(key, this.props.ebay_direct_listing_item["ebay_dimensions"], true);
          }
        } else {
          valid = ebay_item_data_validation(key, this.props.ebay_direct_listing_item[key], true);
        }
      }
    });
    return valid;
  }

  listOnEbay = () => {
    this.props.ebayDirectCreateItemData(this.props.ebay_direct_listing_item);
    this.setState({ collapsePackageDetails: true });
  }

  render() {

    console.log('EBAY ITEM DATA:', this.props.ebay_direct_listing_item);

    return (
      <div className="view list-ebay">
        <ViewHeader />
        <ViewContent>
          <Card>
          <CardBody>
          <General
            collapse={this.state.collapseGeneral}
            toggleCollapse={() => this.toggleCollapse("collapseGeneral")}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
            validateData={this.itemDataValidation}
            ebay_direct_search_products={this.props.ebay_direct_search_products}
            ebay_direct_search_products_working={this.props.ebay_direct_search_products_working}
            ebayDirectSearchProducts={this.props.ebayDirectSearchProducts}
            ebayDirectSearchProductsClear={this.props.ebayDirectSearchProductsClear}
            ebayDirectSearchProductsSelect={this.props.ebayDirectSearchProductsSelect}
            ebayDirectSearchQueryUpdate={this.props.ebayDirectSearchQueryUpdate}
            ebay_direct_search_query={this.props.ebay_direct_search_query}
          />
          </CardBody>
          </Card>

          <Card>
          <CardBody>
          <UploadImages
            collapse={this.state.collapseUploadImage}
            toggleCollapse={() => this.toggleCollapse("collapseUploadImage")}
            maxSize={IMG_MAX_SIZE * 1048576}
            maxSizeText={IMG_MAX_SIZE}
            maxCount={IMG_MAX_COUNT}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
            ebayDirectListingUploadImage={this.props.ebayDirectListingUploadImage}
          />
          </CardBody>
          </Card>

          <Card>
          <CardBody>
          <ListingProfile
            collapse={this.state.collapseListingProfile}
            toggleCollapse={() => this.toggleCollapse("collapseListingProfile")}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
            ebayDirectListingLoadCategories={this.props.ebayDirectListingLoadCategories}
            validateData={this.itemDataValidation}
            ebayGetItemAspects={this.props.ebayGetItemAspects}
            ebay_item_aspects={this.props.ebay_item_aspects}
            ebay_item_aspects_loading={this.props.ebay_item_aspects_loading}
          />
          </CardBody>
          </Card>

          <Card>
          <CardBody>
          <PricingSKU
            collapse={this.state.collapsePricingSKU}
            toggleCollapse={() => this.toggleCollapse("collapsePricingSKU")}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
            validateData={this.itemDataValidation}
            internationalization_config={this.props.internationalization_config}
            ebay_user_settings={this.props.ebay_user_settings}
          />
          </CardBody>
          </Card>

          <Card>
          <CardBody>
          <PackageDetails
            collapse={this.state.collapsePackageDetails}
            toggleCollapse={() => this.toggleCollapse("collapsePackageDetails")}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
            validateData={this.itemDataValidation}
            internationalization_config={this.props.internationalization_config}
            ebay_package_types_valid={this.props.ebay_package_types_valid}
            ebay_package_types_valid_loding={this.props.ebay_package_types_valid_loding}
            addAdditionalFieldsForDimensions={this.addAdditionalFieldsForDimensions}
            updateEbayByBase={this.updateEbayByBase}
          />
          </CardBody>
          </Card>

          {/*
          <ProductVariations
            collapse={this.state.collapseVariations}
            toggleCollapse={() => this.toggleCollapse("collapseVariations")}
          />

          <ReturnProfile
            collapse={this.state.collapseReturn}
            toggleCollapse={() => this.toggleCollapse("collapseReturn")}
            toggleDomesticInternational={this.toggleCollapse}
            collapseDomestic={this.state.collapseDomestic}
            collapseInternational={this.state.collapseInternational}
          />

          <Shipping
            collapse={this.state.collapseShipping}
            toggleCollapse={() => this.toggleCollapse("collapseShipping")}
          />

          <PaymentProfile
            collapse={this.state.collapsePaymentProfile}
            toggleCollapse={() => this.toggleCollapse("collapsePaymentProfile")}
          />

          <BuyerRequirements
            collapse={this.state.collapseBuyerRequirements}
            toggleCollapse={() => this.toggleCollapse("collapseBuyerRequirements")}
          />
          */}

          <Card>
          <CardBody>
          <BestOffer
            collapse={this.state.collapseBestOffer}
            toggleCollapse={() => this.toggleCollapse("collapseBestOffer")}
            ebay_direct_listing_item={this.props.ebay_direct_listing_item}
            editData={this.editData}
          />
          </CardBody>
          </Card>

          <div className="text-center mt-4">
            <button
              className="btn btn-list-ebay"
              disabled={!this.isValidForListing() || this.props.ebay_direct_created_item_data_executing}
              onClick={e => this.listOnEbay()}
            >
              Publish to <span className="sr-only">eBay</span> <img src={ebayLogoShadowed} alt="" className="ebay-logo" />
            </button>
          </div>
          <Modal
            isOpen={
              this.props.ebay_direct_created_item_data_executing
              || this.props.ebay_direct_created_item_data_popup
            }
          >
            <ModalBody className="text-center">
              {this.props.ebay_direct_created_item_data_executing ?
                <React.Fragment>
                  <img src={documentOutlineGif} alt="" width="100" />
                  <p className="lead">Listing your product on eBay...</p>
                </React.Fragment>
                : null
              }
              {this.props.ebay_direct_created_item_data_popup ?
                <React.Fragment>
                  <IconSuccess size="60" className="text-success" />
                  <h4>Success!</h4>
                  <p className="lead mb-3">Your product has been successfully listed on eBay.</p>
                  <a
                    href={this.props.ebay_direct_created_item_url}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer noopener"
                  >View on eBay</a>
                  <Button
                    className="ml-2"
                    onClick={e => this.props.ebayDirectListingClosePopup()}
                  >Close</Button>
                </React.Fragment>
                : null
              }
              {/*
              // <React.Fragment>
              //   <IconError size="60" className="text-danger" />
              //   <h4>Something went wrong</h4>
              //   <p className="lead mb-3">An error has occured while listing your product.
              //   Please try again or have a chat with our support team regarding this problem.
              //   Click icon on the bottom right to start.</p>
              //   <Button>OK</Button>
              // </React.Fragment>
              */}
            </ModalBody>
          </Modal>
        </ViewContent>
      </div>
    )
  }
}

export default connect(
  state => ({
		ebay_direct_listing_item: state.Ebay.get("ebay_direct_listing_item"),
		ebay_direct_created_item_data_executing: state.Ebay.get("ebay_direct_created_item_data_executing"),
		ebay_direct_created_item_data_popup: state.Ebay.get("ebay_direct_created_item_data_popup"),
		ebay_direct_created_item_url: state.Ebay.get("ebay_direct_created_item_url"),
		ebay_item_aspects: state.Ebay.get("ebay_item_aspects"),
		ebay_item_aspects_loading: state.Ebay.get("ebay_item_aspects_loading"),
    ebay_direct_search_products: state.Ebay.get("ebay_direct_search_products"),
    ebay_direct_search_products_working: state.Ebay.get("ebay_direct_search_products_working"),
    internationalization_config: state.Auth.get("internationalization_config"),
    ebay_package_types_valid: state.Ebay.get("ebay_package_types_valid"),
    ebay_package_types_valid_loding: state.Ebay.get("ebay_package_types_valid_loding"),
		ebay_user_settings: state.Ebay.get("ebay_user_settings"),
    ebay_direct_search_query: state.Ebay.get("ebay_direct_search_query"),
  }),
	{
    ebayDirectListingItemInit,
    ebayDirectListingItemEdit,
    ebayDirectListingUploadImage,
    ebayDirectListingLoadCategories,
    ebayDirectCreateItemData,
    ebayGetItemAspects,
    ebayDirectSearchProducts,
    ebayDirectSearchProductsClear,
    ebayDirectSearchProductsSelect,
    ebayDirectAdditionalData,
    ebayGetPackageTypes,
    ebayDirectListingClosePopup,
	  ebayUserGetSettings,
    ebayDirectSearchQueryUpdate,
    userPageviewCapture,
	}
)(ListEbay);
