import {
    Map
} from 'immutable/dist/immutable'
import actions from './actions';

const initState = new Map({
	userUN: "",
	showUserData: false,
	showUserDataFailed: false,
	userData: [],
	usersPerMarketplace: [],
	usersErrorLogs: [],
	userTokenUpdateCompleted: false,
	userTokenUpdateFailed: false,
	batchStatsData: [],
	user_enabling_repricer: false,
	sbybStats: {},
	repricerStats: {},
	bookCategories: [],
	otherCategories: [],
	user_updating: false,
	user_update_message: '',
	user_whitelist_message: '',
	is_getting_diff: false,
	fbc_al_diff: [],
	user_enabling_ebay: false,
	admin_ebay_logs: {
		data: [],
		pages: [],
	},
	admin_ebay_logs_loading: false,
  user_success: [],
  usersSegmentationByRole: [],
  ebay_stats: {},
  admin_ebay_user_sales: [],
  admin_ebay_user_sales_loading: false,
  admin_user_sales_details: [],
  admin_faq_topics: [],
  admin_faq_articles: [],
  admin_faq_reordering_topics: false,
  admin_app_settings: {},
  admin_app_settings_loading: false,
  admin_app_settings_message: "",
  admin_ebay_total_averages: {},
  user_enabling_sms: false,
  user_enabling_sms_message: "",
  spapi_user_count: {},
  admin_spapi_logs_loading: false,
  admin_spapi_logs: {},
  user_export_action_log_working: false,
  ai_analyze_answer: null,
  ai_analyze_answer_working: false,
  admin_user_split_shipments: null,
});

export default function adminReducer(state = initState, action) {
  switch (action.type) {

		case actions.ADMIN_USER_SPLITS_AGREGATE_GET:
			return state
				.set("admin_user_split_shipments", null)
		case actions.ADMIN_USER_SPLITS_AGREGATE_GET_SUCCESS:
			return state
				.set("admin_user_split_shipments", action.data)
		case actions.ADMIN_USER_SPLITS_AGREGATE_GET_FAILED:
			return state
				.set("admin_user_split_shipments", null)

		case actions.AI_ANALYZE_ANSWER:
			return state
				.set("ai_analyze_answer", null)
				.set("ai_analyze_answer_working", true)
		case actions.AI_ANALYZE_ANSWER_SUCCESS:
			return state
				.set("ai_analyze_answer", action.data.data)
				.set("ai_analyze_answer_working", false)
		case actions.AI_ANALYZE_ANSWER_FAILED:
			return state
				.set("ai_analyze_answer", null)
				.set("ai_analyze_answer_working", false)

		case actions.USER_LOG_ACTION_EXPORT:
			return state
				.set("user_export_action_log_working", true)
		case actions.USER_LOG_ACTION_EXPORT_SUCCESS:
			return state
				.set("user_export_action_log_working", false)
		case actions.USER_LOG_ACTION_EXPORT_FAILED:
			return state
				.set("user_export_action_log_working", false)

		case actions.USER_ACTIVATE_SPAPI:
			return state
				.set("user_updating", true)
				.set("user_spapi_activate_message", "")
		case actions.USER_ACTIVATE_SPAPI_SUCCESS:
			return state
				.set("user_updating", false)
				.set("user_spapi_activate_message", action.data.message)
		case actions.USER_ACTIVATE_SPAPI_FAILED:
			return state
				.set("user_updating", false)
				.set("user_spapi_activate_message", action.data.error)

		case actions.USER_GET_SPAPI_LOGS:
			return state
				.set("admin_spapi_logs_loading", true)
		case actions.USER_GET_SPAPI_LOGS_SUCCESS:
			return state
				.set("admin_spapi_logs", Object.assign({}, action.data.items))
				.set("admin_spapi_logs_loading", false)
		case actions.USER_GET_SPAPI_LOGS_FAILED:
			return state
				.set("admin_spapi_logs_loading", false)

	  case actions.SPAPI_USER_COUNT_GET:
			return state
				.set("spapi_user_count", {})
	  case actions.SPAPI_USER_COUNT_GET_SUCCESS:
			return state
				.set("spapi_user_count", action.data)
	  case actions.SPAPI_USER_COUNT_GET_FAILURE:
			return state
				.set("spapi_user_count", {})

	  case actions.USER_ENABLE_SMS:
			return state
				.set("user_enabling_sms", true)
				.set("user_enabling_sms_message", "")
	  case actions.USER_ENABLE_SMS_SUCCESS:
			return state
				.set("user_enabling_sms", false)
				.set("user_enabling_sms_message", action.data.msg)
	  case actions.USER_ENABLE_SMS_FAILURE:
			return state
				.set("user_enabling_sms", false)
				.set("user_enabling_sms_message", "")

      case actions.GET_EBAY_AVERAGES_TOTAL:
			  return state
				  .set('admin_ebay_total_averages', {})
      case actions.GET_EBAY_AVERAGES_TOTAL_SUCCESS:
			  return state
				  .set('admin_ebay_total_averages', Object.assign({}, action.data))
      case actions.GET_EBAY_AVERAGES_TOTAL_FAILED:
			  return state
				  .set('admin_ebay_total_averages', {})

      case actions.GET_EBAY_STATS_SALES_AVERAGES_SUCCESS:
        let ebay_stats_averages = state.get("ebay_stats");
        Object.keys(action.data).forEach(key => {
          ebay_stats_averages[key] = action.data[key];
        });
			  return state
				  .set('ebay_stats', Object.assign({}, ebay_stats_averages))

      case actions.UPDATE_APP_SETTINGS:
			  return state
				  .set('admin_app_settings_loading', true)
				  .set('admin_app_settings_message', "")
      case actions.UPDATE_APP_SETTINGS_SUCCESS:
			  return state
				  .set('admin_app_settings', action.data)
				  .set('admin_app_settings_loading', false)
				  .set('admin_app_settings_message', "Update success")
      case actions.UPDATE_APP_SETTINGS_FAILED:
			  return state
				  .set('admin_app_settings', action.data)
				  .set('admin_app_settings_loading', false)
				  .set('admin_app_settings_message', "Update failed")

      case actions.GET_APP_SETTINGS:
			  return state
				  .set('admin_app_settings', {})
      case actions.GET_APP_SETTINGS_SUCCESS:
			  return state
				  .set('admin_app_settings', action.data)
      case actions.GET_APP_SETTINGS_FAILED:
			  return state
				  .set('admin_app_settings', {})

      case actions.REORDER_FAQ_TOPICS:
			  return state
				  .set('admin_faq_reordering_topics', true)
      case actions.REORDER_FAQ_TOPICS_SUCCESS:
        let reorder_faq_topic = state.get("admin_faq_topics");
        action.data.forEach(item => {
          reorder_faq_topic = reorder_faq_topic.map(topic => {
            if(topic.id === item.id){
              topic.order_id = item.order_id;
            }
            return topic;
          });
        });
        reorder_faq_topic.sort((a, b) => (a.order_id > b.order_id) ? 1 : -1);
			  return state
				  .set('admin_faq_reordering_topics', false)
				  .set('admin_faq_topics', Object.assign([], reorder_faq_topic))
      case actions.REORDER_FAQ_TOPICS_FAILED:
			  return state
				  .set('admin_faq_reordering_topics', false)

      case actions.DELETE_FAQ_TOPIC_SUCCESS:
        const delete_faq_topic = state.get("admin_faq_topics")
          .filter(item => item.id !== parseInt(action.data.topic_id, 10));
        const delete_faq_article_topic = state.get("admin_faq_articles")
          .filter(item => !action.data.article_ids.includes(item.id));
			  return state
				  .set('admin_faq_topics', Object.assign([], delete_faq_topic))
				  .set('admin_faq_articles', Object.assign([], delete_faq_article_topic))

      case actions.DELETE_FAQ_ARTICLE_SUCCESS:
        const delete_faq_article = state.get("admin_faq_articles").filter(item => item.id !== action.data.id);
			  return state
				  .set('admin_faq_articles', Object.assign([], delete_faq_article))

      case actions.UPDATE_FAQ_ARTICLE_SUCCESS:
        const update_faq_article = state.get("admin_faq_articles").map(item => {
          if(action.data.id === item.id){
            item = action.data;
          }
          return item;
        });
			  return state
				  .set('admin_faq_articles', Object.assign([], update_faq_article))

      case actions.ADD_FAQ_ARTICLE_SUCCESS:
        let add_faq_article = state.get("admin_faq_articles");
        add_faq_article.push(action.data);
			  return state
				  .set('admin_faq_articles', Object.assign([], add_faq_article))

	    case actions.GET_FAQ_ARTICLES:
			  return state
				  .set('admin_faq_articles', [])
      case actions.GET_FAQ_ARTICLES_SUCCESS:
			  return state
				  .set('admin_faq_articles', Object.assign([], action.data))
	    case actions.GET_FAQ_ARTICLES_FAILED:
			  return state
				  .set('admin_faq_articles', [])

      case actions.UPDATE_FAQ_TOPIC_SUCCESS:
        const update_faq_topic = state.get("admin_faq_topics").map(item => {
          if(action.data.id === item.id){
            item = action.data;
          }
          return item;
        });
			  return state
				  .set('admin_faq_topics', Object.assign([], update_faq_topic))

      case actions.ADD_FAQ_TOPIC_SUCCESS:
        let add_faq_topic = state.get("admin_faq_topics");
        add_faq_topic.push(action.data);
			  return state
				  .set('admin_faq_topics', Object.assign([], add_faq_topic))

	    case actions.GET_FAQ_TOPICS:
			  return state
				  .set('admin_faq_topics', [])
      case actions.GET_FAQ_TOPICS_SUCCESS:
			  return state
				  .set('admin_faq_topics', Object.assign([], action.data))
	    case actions.GET_FAQ_TOPICS_FAILED:
			  return state
				  .set('admin_faq_topics', [])

	    case actions.GET_EBAY_USER_SALE_DETAILS:
			  return state
				  .set('admin_user_sales_details', [])
      case actions.GET_EBAY_USER_SALE_DETAILS_SUCCESS:
			  return state
				  .set('admin_user_sales_details', Object.assign([], action.data))
	    case actions.GET_EBAY_USER_SALE_DETAILS_FAILED:
			  return state
				  .set('admin_user_sales_details', [])

	    case actions.GET_EBAY_USER_SALES:
			  return state
				  .set('admin_ebay_user_sales', [])
				  .set('admin_ebay_user_sales_loading', true)
      case actions.GET_EBAY_USER_SALES_SUCCESS:
			  return state
				  .set('admin_ebay_user_sales', Object.assign([], action.data))
				  .set('admin_ebay_user_sales_loading', false)
	    case actions.GET_EBAY_USER_SALES_FAILED:
			  return state
				  .set('admin_ebay_user_sales', [])
				  .set('admin_ebay_user_sales_loading', false)

      case actions.GET_EBAY_STATS_SUCCESS:
        let get_ebay_stats = state.get("ebay_stats");
        Object.keys(action.data).forEach(key => {
          get_ebay_stats[key] = action.data[key];
        });
			  return state
				  .set('ebay_stats', Object.assign({}, get_ebay_stats))

	    case actions.GET_USERS_SEGMENTATION:
			  return state
				  .set('usersSegmentationByRole', [])
    case actions.GET_USERS_SEGMENTATION_SUCCESS:
			  return state
				  .set('usersSegmentationByRole', Object.assign([], action.data))
	    case actions.GET_USERS_SEGMENTATION_FAILED:
			  return state
				  .set('usersSegmentationByRole', [])

      case actions.GET_USER_SUCCESS_ADMIN:
			  return state.set('user_success', []);
      case actions.GET_USER_SUCCESS_ADMIN_SUCCESS:
        let new_user_success = [];
        Object.keys(action.data.user_success.data).forEach(key => {
          new_user_success.push(action.data.user_success.data[key])
        });
			  return state.set('user_success', Object.assign([], new_user_success));
      case actions.GET_USER_SUCCESS_ADMIN_FAILED:
			  return state.set('user_success', []);


		case actions.GET_CANCEL_REASON_ADMIN:
			return state.set('cancel_reason', []);
		case actions.GET_CANCEL_REASON_ADMIN_SUCCESS:
			return state.set('cancel_reason', action.data);
		case actions.GET_CANCEL_REASON_ADMIN_FAILED:
			return state.set('cancel_reason', []);

		case actions.USER_GET_EBAY_LOGS:
			return state
				.set("admin_ebay_logs_loading", true)
		case actions.USER_GET_EBAY_LOGS_SUCCESS:
			return state
				.set("admin_ebay_logs", Object.assign({}, action.data.items))
				.set("admin_ebay_logs_loading", false)
		case actions.USER_GET_EBAY_LOGS_FAILED:
			return state
				.set("admin_ebay_logs_loading", false)

	  case actions.USER_ENABLE_EBAY:
			return state
				.set("user_enabling_ebay", true)
	  case actions.USER_ENABLE_EBAY_SUCCESS:
			let tmp_user_data_ebay = state.get('userData');
			tmp_user_data_ebay['is_ebay_enabled'] = action.data.is_ebay_enabled;
			return state
				.set("user_enabling_ebay", false)
				.set("userData", Object.assign({}, tmp_user_data_ebay))
	  case actions.USER_ENABLE_EBAY_FAILURE:
			return state
				.set("user_enabling_ebay", false)

		case actions.CHANGE_USER_PASSWORD_SUCCESS:
		  return state.set('showPopupPasswordChanged', true);

		case actions.CHANGE_USER_PASSWORD_FAILURE:
			return state.set('showPopupPasswordChangeFailed', true);

		case actions.CHANGE_USER_PASSWORD_FAILURE_MODAL_CLOSE:
			return state.set('showPopupPasswordChangeFailed', false);

		case actions.CHANGE_USER_PASSWORD_SUCCESS_MODAL_CLOSE:
			return state.set('showPopupPasswordChanged', false);

	  case actions.SEARCH_USER_SUCCESS:
			return state
				.set('userData', action.data)
				.set('showUserData', true);

	  case actions.SEARCH_USER_FAILURE:
			return state
				.set('userData', [])
				.set('showUserDataFailed', true)
				.set('showUserData', false);

      case actions.SEARCH_USER_FAILURE_MODAL_CLOSE:
			return state.set('showUserDataFailed', false);

	  case actions.SEARCH_USERS_PER_MARKETPLACE_SUCESS:
			return state
				.set('usersPerMarketplace', action.data)

	  case actions.SEARCH_USERS_ERRORLOGS_SUCESS:
			return state
				.set('usersErrorLogs', action.data)

	  case actions.USER_AUTH_TOKEN_UPDATE:
			return state
				.set("userTokenUpdateCompleted", false)
				.set("userTokenUpdateFailed", false)

	  case actions.USER_AUTH_TOKEN_UPDATE_SUCCESS:
			return state
				.set("userTokenUpdateCompleted", true)
				.set("userTokenUpdateFailed", false)

	  case actions.USER_AUTH_TOKEN_UPDATE_FAILURE:
			return state
				.set("userTokenUpdateFailed", true)
				.set("userTokenUpdateCompleted", false)

	  case actions.BATCH_STATS:
			return state
				.set("batchStatsData", [])

	  case actions.BATCH_STATS_SUCCESS:
			return state
				.set("batchStatsData", action.data)

	  case actions.BATCH_STATS_FAILURE:
			return state
				.set("batchStatsData", [])

	  case actions.USER_ENABLE_REPRICER:
			return state
				.set("user_enabling_repricer", true)

	  case actions.USER_ENABLE_REPRICER_SUCCESS:
			let tmp_user_data = state.get('userData');
			tmp_user_data['is_repricer_enabled'] = action.data.is_repricer_enabled;
			return state
				.set("user_enabling_repricer", false)
				.set("userData", Object.assign({}, tmp_user_data))

	  case actions.USER_ENABLE_REPRICER_FAILURE:
			return state
				.set("user_enabling_repricer", false)

	  case actions.GET_SBYB_STATS:
			return state
				.set("sbybStats", {})
	  case actions.GET_SBYB_STATS_SUCCESS:
			return state
				.set("sbybStats", action.data)
	  case actions.GET_SBYB_STATS_FAILED:
			return state
				.set("sbybStats", {})

	  case actions.GET_REPRICER_STATS:
			return state
				.set("repricerStats", {})
	  case actions.GET_REPRICER_STATS_SUCCESS:
			return state
				.set("repricerStats", action.data.data)
	  case actions.GET_REPRICER_STATS_FAILED:
			return state
				.set("repricerStats", {})

	  case actions.GET_CATEGORIES_STATS_BOOKS:
			return state
				.set("bookCategories", [])
	  case actions.GET_CATEGORIES_STATS_BOOKS_SUCCESS:
			return state
				.set("bookCategories", Object.assign([], action.data.data.bookCategories))
	  case actions.GET_CATEGORIES_STATS_BOOKS_FAILED:
			return state
				.set("bookCategories", [])

	  case actions.GET_CATEGORIES_STATS_OTHER:
			return state
				.set("otherCategories", [])
	  case actions.GET_CATEGORIES_STATS_OTHER_SUCCESS:
			return state
				.set("otherCategories", Object.assign([], action.data.data.otherCategories))
	  case actions.GET_CATEGORIES_STATS_OTHER_FAILED:
			return state
				.set("otherCategories", [])

	  case actions.UPDATE_USER:
			return state
				.set('user_updating', true)
				.set('user_update_message', '')
	  case actions.UPDATE_USER_SUCCESS:
			return state
				.set('user_updating', false)
				.set('user_update_message', action.data)
	  case actions.UPDATE_USER_FAILED:
			return state
				.set('user_updating', false)
				.set('user_update_message', action.data)

	  case actions.USER_WHITELIST:
			return state
				.set('user_updating', true)
				.set('user_whitelist_message', '')
	  case actions.USER_WHITELIST_SUCCESS:
			return state
				.set('user_updating', false)
				.set('user_whitelist_message', action.data)
	  case actions.USER_WHITELIST_FAILED:
			return state
				.set('user_updating', false)
				.set('user_whitelist_message', action.data)

	  case actions.USER_FBC_COMPARE:
			return state
				.set('fbc_al_diff', [])
				.set('is_getting_diff', true)
	  case actions.USER_FBC_COMPARE_SUCCESS:
			return state
				.set('fbc_al_diff', action.data.emails)
				.set('is_getting_diff', false)
	  case actions.USER_FBC_COMPARE_FAILED:
			return state
				.set('fbc_al_diff', [])
				.set('is_getting_diff', false)

      default:
        return state
    }
}
