import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
	UncontrolledCollapse,
	Alert,
	Badge,
} from "reactstrap";
import {
	Elements,
	StripeProvider, } from 'react-stripe-elements';
import { connect } from "react-redux";
import VisaLogo from "../../../assets/images/visa_logo.gif"
import MasterLogo from "../../../assets/images/master_logo.gif"
import CardLogo from "../../../assets/images/card_logo.gif"
import settingsActions from "../../../redux/settings/actions";
import ebayActions from "../../../redux/ebay/actions";
import membershipActions from "../../../redux/membership/actions";
import moment from 'moment';
import BConfirmationModal from "../../../shared/components/BConfirmationModal";
import { stripePublicKey } from "../../../config/mediaLinks";
import StripeCardReplaceForm from "../../../shared/components/StripeCardReplaceForm";
import repricerActions from "../../../redux/repricer/actions";
import LoadingIndicator from '../../../shared/components/LoadingIndicator';
import { openInNewTab } from '../../../helpers/utility';
import SweetAlert from 'sweetalert2-react';
import { FaChevronDown, FaChevronRight } from 'react-icons/lib/fa';
import CancelReasonModal from "./CancelReasonModal";

const {
	uploadProfileImage,
	getProfileImage,
	getProfileBillingData,
	updateUserDataSettings,
} = settingsActions;

const {
	cancelMembership,
	restartMembership,
  fetchMembership,
} = membershipActions;

const {
	userEnableEbay,
} = ebayActions;

const { userEnableRepricer } = repricerActions;

class UpdateBilling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invoicesToDisplay: [],
			invoiceDisplayPage: 0,
			showPopupCancelSubscription: false,
			showPopupRestartSubscription: false,
			valid_sub: false,
			showPopupCancelRepricerSubscription: false,
			showPopupEnableRepricerSubscription: false,
			showPopupCancelEbaySubscription: false,
			showPopupEnableEbaySubscription: false,
      showTryCardSuccesAlert: false,
      billing_toggle: [],
		}
		this.handleUploadClick = this.handleUploadClick.bind(this);
		this.togglePopupCancelSubscription = this.togglePopupCancelSubscription.bind(this);
		this.togglePopupRestartSubscription = this.togglePopupRestartSubscription.bind(this);
		this.cancelSubscription = this.cancelSubscription.bind(this);
		this.restartSubscription = this.restartSubscription.bind(this);
		this.updateCardData = this.updateCardData.bind(this);
	}

	updateCardData(){


	}

	togglePopupCancelEbaySubscription = () => {
		this.setState({showPopupCancelEbaySubscription: !this.state.showPopupCancelEbaySubscription});
	}

	togglePopupEnableEbaySubscription = () => {
		this.setState({showPopupEnableEbaySubscription: !this.state.showPopupEnableEbaySubscription});
	}

	enableEbaySubscription = () => {
		const data = {
			is_ebay_enabled: true,
		}
		this.props.userEnableEbay(data);
		this.setState({showPopupEnableEbaySubscription: false});
		this.props.getProfileBillingData();
    openInNewTab("https://accelerlist.helpjuice.com/ebay-listing/how-to-cross-list-products-to-ebay")
	}

	cancelEbaySubscription = () => {
		const data = {
			is_ebay_enabled: false,
		}
		this.props.userEnableEbay(data);
		this.setState({showPopupCancelEbaySubscription: false});
		this.props.getProfileBillingData();
	}

	togglePopupCancelRepricerSubscription = () => {
		this.setState({showPopupCancelRepricerSubscription: !this.state.showPopupCancelRepricerSubscription});
	}

	togglePopupEnableRepricerSubscription = () => {
		this.setState({showPopupEnableRepricerSubscription: !this.state.showPopupEnableRepricerSubscription});
	}

	enableRepricerSubscription = () => {
		const data = {
			is_repricer_enabled: true,
		}
		this.props.userEnableRepricer(data);
		this.setState({showPopupEnableRepricerSubscription: false});
		this.props.getProfileBillingData();
	}

	cancelRepricerSubscription = () => {
		const data = {
			is_repricer_enabled: false,
		}
		this.props.userEnableRepricer(data);
		this.setState({showPopupCancelRepricerSubscription: false});
		this.props.getProfileBillingData();
	}

	cancelSubscription(response){
		this.props.cancelMembership(response);
		this.setState({showPopupCancelSubscription: false});
		this.props.getProfileBillingData();
	}

	restartSubscription(){
		this.props.restartMembership();
		this.setState({showPopupRestartSubscription: false});
		this.props.getProfileBillingData();
	}

	togglePopupCancelSubscription(){
		this.setState({showPopupCancelSubscription: !this.state.showPopupCancelSubscription});
	}

	togglePopupRestartSubscription(){
		this.setState({showPopupRestartSubscription: !this.state.showPopupRestartSubscription});
	}

	handleUploadClick(e) {
		this.refs.fileUploader.click();
	}

	uploadOnChange = (e) => {
		const files = Array.from(e.target.files);
		if(files){
			//file size limitation to 100K
			if(files[0].size < 100 * 1024){
				this.props.uploadProfileImage(files[0]);
			}
		}
	}

	componentDidMount(){
		this.props.getProfileImage();
		this.props.getProfileBillingData();
    this.props.fetchMembership();
	}

	/*
	componentWillMount(){
		const { profileBillingDataSet, invoicesPerPage } = this.props;
		this.setState({invoicesToDisplay: profileBillingDataSet['billing_data'].slice(0, invoicesPerPage)});
	}
	*/

    UNSAFE_componentWillReceiveProps(newProps) {
		const {
			profileBillingDataSet,
			is_canceled,
			is_restarted } = newProps;
		const { invoicesPerPage } = this.props;
		const { invoicesToDisplay } = this.state;
		if(invoicesToDisplay && profileBillingDataSet['billing_data']){
			this.setState({invoicesToDisplay: profileBillingDataSet['billing_data'].slice(0, invoicesPerPage)});
		}
		if(is_canceled || is_restarted){
			if(is_canceled){
				this.setState({ valid_sub: false });
			}
			if(is_restarted){
				this.setState({ valid_sub: true });
			}
		} else {
			this.setState({ valid_sub: (profileBillingDataSet['user_data']['active'] === 'true') });
		}

    }

	invoicesNext = () => {
		const { profileBillingDataSet, invoicesPerPage } = this.props;
		const a = this.state.invoiceDisplayPage + 1;
		this.setState({invoicesToDisplay: profileBillingDataSet['billing_data'].slice(a*invoicesPerPage, (a + 1)*invoicesPerPage)});
		this.setState({invoiceDisplayPage: a})
	}

	invoicesPrev = () => {
		const { profileBillingDataSet, invoicesPerPage } = this.props;
		const a = this.state.invoiceDisplayPage - 1;
		this.setState({invoicesToDisplay: profileBillingDataSet['billing_data'].slice(a*invoicesPerPage, (a + 1)*invoicesPerPage)});
		this.setState({invoiceDisplayPage: a})
	}

  displayColapsableInvoices = (invoices) => {
    var data = {};
    invoices.forEach(invoice => {
      let logo = '';
      if(invoice['card'] === 'visa'){
        logo = VisaLogo;
      } else if(invoice['card'] === 'master') {
        logo = MasterLogo;
      } else {
        logo = CardLogo;
      }
      const date = moment(invoice['date']);
      const id = date.format('MMMM')+"_"+date.format('YYYY');
      if(!Object.keys(data).includes(id)){
        data[id] = [];
      }
      data[id].push(
        <React.Fragment>
          <Row>
            <Col xs="1">
              <img
                src={ logo }
                alt="logo"
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <b>Invoice of { moment(invoice['date']).format("MMMM Do, YYYY") } ${ (invoice['amount'] / 100).toFixed(2) }</b>
                </Col>
                <Col xs="3">
                  <div className="text-right">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      href={ invoice['url'].substr(0, invoice['url'].lastIndexOf("/")) }
                    >Download</a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </React.Fragment>
      );
    });
    return Object.keys(data).map(key => {
      return (
        <React.Fragment key={key}>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <Button
                color="link"
                id={key}
                onClick={e => this.setBillingToggle(key)}
              >Invoices of {`${key.replace('_', ' ')}`}</Button>
              <Badge color="danger" className="ml-2">{data[key].length}</Badge>
            </div>
            <Button
              color="link"
              id={key}
              onClick={e => this.setBillingToggle(key)}
            >
              {this.state.billing_toggle.includes(key)
                ? <FaChevronDown />
                : <FaChevronRight />
              }
            </Button>
          </div>
          <UncontrolledCollapse toggler={`#${key}`}>
            {data[key].map(item => { return item; })}
          </UncontrolledCollapse>
        </React.Fragment>
      )
    });
  }

  setBillingToggle = (key) => {
    var bt = this.state.billing_toggle;
    if(bt.includes(key)){
      bt.splice(bt.indexOf(key), 1);
    } else {
      bt.push(key);
    }
    this.setState({ billing_toggle: bt });
  }

	showSubscriptionMessage = (color, message) => {
		const col = { color: color }
		return (
			<p className="font-bold" style={ col }>
				{ message }
			</p>
		);
	}

  displayCardData = () => {
    const { source } = this.props;
    if(!source){ return null; }
    return (
      <Card className="mb-4">
        <CardBody>
          <div className="mb-4">
            <div className={`credit-card ${source.brand}`}>
              <h5 className="credit-card-number">••••&nbsp;&nbsp;••••&nbsp;&nbsp;••••&nbsp;&nbsp;{source.last4}</h5>
              <div className="credit-card-bottom">
                <div className="credit-card-info">
                  <h6 className="credit-card-label">Card Holder Name</h6>
                  <span className="credit-card-value">{source.name}</span>
                </div>
                <div className="credit-card-info">
                  <h6 className="credit-card-label">Expired Date</h6>
                  <span className="credit-card-value">{source.exp_month}/{source.exp_year}</span>
                </div>
              </div>
            </div>
          </div>
          <Button color="link" id="updatePayment">CHANGE YOUR CARD</Button>
          <UncontrolledCollapse toggler="#updatePayment" className="mb-4">
            <StripeProvider apiKey={ stripePublicKey }>
              <Elements>
                <StripeCardReplaceForm />
              </Elements>
            </StripeProvider>
          </UncontrolledCollapse>
        </CardBody>
      </Card>
    )
  }

  render() {
	const { profileBillingDataSet, invoicesPerPage, userData } = this.props;
	const { invoiceDisplayPage } = this.state;
		if(this.props.user_enabling_repricer){
			return (
				<div className="repricer-landing">
					<LoadingIndicator
						title={"Repricer subscription is updating. This can take up to one minute"}
					/>
				</div>
			)
		}
    return (
			<div>
				<Row>
					<Col lg={6}>
						<Card className="mb-3">
							<CardBody>
								<Row>
									<Col lg={6}>
										<div className="d-flex">
											<input
												type="file"
												id="file"
												ref="fileUploader"
												style={{display: "none"}}
												onChange={this.uploadOnChange}
											/>
											<div
												style={{ cursor: "pointer", textAlign: "center" }}
												onClick={this.handleUploadClick}
											>
												<img
													src={this.props.profileImage}
													alt=""
													className="img-circle"
													width="100"
													height="100"
												/>
												<span className="text-primary d-block mt-2">Add Photo</span>
											</div>
											<div className="ml-3">
												<CardTitle>
													{ this.props.profileBillingDataSet['user_data']['businessname'] ? (
															this.props.profileBillingDataSet['user_data']['businessname']
														) : ( this.props.profileBillingDataSet['user_data']['username'] )
													}
												</CardTitle>
												<p>A valued customer since <b>{ moment(this.props.profileBillingDataSet['user_data']['created']).format("MMMM, YYYY") }</b></p>
											</div>
										</div>
									</Col>
									<Col lg={6}>
										<div style={{ maxWidth: 320 }}>
											{this.displayCardData()}
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
            {/*
						<Card>
							<CardBody>
								<Row className="align-items-center">
									<Col lg={6}>
										{this.props.userData && this.props.userData.is_ebay_enabled ?
											<React.Fragment>
												This will cancel <strong>just your ebay subscription</strong>!
											</React.Fragment>
											: null
										}
									</Col>
									<Col lg={6}>
										{this.props.userData && this.props.userData.is_ebay_enabled ?
											<Button
												color="danger"
												className="mb-3"
												disabled={this.props.user_enabling_ebay || this.props.is_canceling || this.props.isUserSettingsUpdating || !this.state.valid_sub}
												onClick={this.togglePopupCancelEbaySubscription}
											>CANCEL EBAY</Button>
											:
											<Button
												color="success"
												className="mb-3"
												disabled={(this.props.userData && this.props.userData.has_active_subscription !== 'true') ||
													this.props.user_enabling_ebay || this.props.is_canceling || this.props.isUserSettingsUpdating || !this.state.valid_sub}
												onClick={this.togglePopupEnableEbaySubscription}
											>ACTIVATE EBAY</Button>
										}
									</Col>
								</Row>
							</CardBody>
						</Card>
            <hr className="my-3" />
            */}
						<Card>
							<CardBody>
								<Row className="align-items-center">
									<Col lg={6}>
										{this.props.userData && this.props.userData.repricerEnabled ?
											<React.Fragment>
												This will cancel <strong>just your repricing subscription</strong> and allow you to reprice with another solution :-(
											</React.Fragment>
											: null
										}
									</Col>
									<Col lg={6}>
										{this.props.userData && this.props.userData.repricerEnabled ?
											<Button
												color="danger"
												disabled={this.props.user_enabling_repricer || this.props.is_canceling || this.props.isUserSettingsUpdating || !this.state.valid_sub}
												onClick={this.togglePopupCancelRepricerSubscription}
											>CANCEL REPRICING</Button>
											:
											<Button
												color="success"
												disabled={(this.props.userData && this.props.userData.has_active_subscription !== 'true') ||
													this.props.user_enabling_repricer || this.props.is_canceling || this.props.isUserSettingsUpdating || !this.state.valid_sub}
												onClick={this.togglePopupEnableRepricerSubscription}
											>ACTIVATE REPRICING</Button>
										}
									</Col>
								</Row>
							</CardBody>
						</Card>
						<hr className="my-3" />
						<Card>
							<CardBody>
								<Row className="align-items-center">
									{ this.state.valid_sub ?
										<Col lg={6}>
											This will cancel <strong>BOTH</strong> your listing subscription <strong>AND</strong> your repricing subscription
										</Col>
										:
										<Col lg={6}>
											This will restart your listing subscription
										</Col>
									}
									<Col lg={6}>
										{ this.state.valid_sub ? (
										<Button
											color="danger"
											onClick={this.togglePopupCancelSubscription}
											disabled={this.props.is_canceling || this.props.user_enabling_repricer || this.props.isUserSettingsUpdating}
										>
											CANCEL ACCELERLIST
										</Button>
										) : (
										<Button
											color="success"
											onClick={this.togglePopupRestartSubscription}
											disabled={this.props.is_restarting || this.props.user_enabling_repricer || this.props.isUserSettingsUpdating}
										>
											RESTART ACCELERLIST
										</Button>
										)
										}

										{this.props.show_cancel_failed ? (
											this.showSubscriptionMessage(
												"red",
												"Canceling subscription failed!")
										) : (null)
										}
										{this.props.show_restart_failed ? (
											this.showSubscriptionMessage(
												"red",
												"Restarting subscription failed!")
										) : (null)
										}
										{this.props.show_canceled ? (
											this.showSubscriptionMessage(
												"green",
												"Canceling subscription success!")
										) : (null)
										}
										{this.props.show_restarted ? (
											this.showSubscriptionMessage(
												"green",
												"Restarting subscription sucsess!")
										) : (null)
										}
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col lg={6}>
						<Card>
							<CardBody>
								<Row>
									<Col>
										<h5 className="card-title">Your Invoices</h5>
									</Col>
									<Col>
										<div className="text-right">
										</div>
									</Col>
								</Row>
								<hr />
                {userData && userData.payment_failed ?
                  <React.Fragment>
                    <Alert color="danger" className="d-flex justify-content-between align-items-center">
                      <strong>Restart subscription, try card on file</strong>
                      <Button
                        color="success"
                        onClick={this.togglePopupCancelSubscription}
                        disabled={this.props.is_canceling || this.props.user_enabling_repricer || this.props.isUserSettingsUpdating}
                      >Try Card</Button>
                    </Alert>
                  </React.Fragment>
                  : null
                }
                {this.displayColapsableInvoices(this.state.invoicesToDisplay)}
								<Row>
									<Col>
										<div className="text-right">
											{
												invoiceDisplayPage > 0 ? (
											<Button
												onClick={() => this.invoicesPrev()}
												color="primary"
											>
													&lt;
											</Button>
											) : (null)
											}
											{
												invoiceDisplayPage * invoicesPerPage + invoicesPerPage < profileBillingDataSet['billing_data'].length ? (
											<Button
												onClick={() => this.invoicesNext()}
												color="primary"
											>
												&gt;
											</Button>
											) : (null)
											}
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{ this.state.showPopupCancelSubscription ? (
					<CancelReasonModal
						isOpen={this.state.showPopupCancelSubscription}
						closeModal={this.togglePopupCancelSubscription}
						modalTitle="Subscription"
						buttonAction={this.cancelSubscription}
						buttonText="CANCEL SUBSCRIPTION"
					/> ) : (null)
				}
				{ this.state.showPopupRestartSubscription ? (
					<BConfirmationModal
						isOpen={this.state.showPopupRestartSubscription}
						closeModal={this.togglePopupRestartSubscription}
						modalTitle="Confirm Restart Subscription"
						modalText="Are you sure want to restart subscription?"
						buttonColor="success"
						buttonAction={this.restartSubscription}
						buttonText="Yes, restart subscription"
						buttonCloseText="No, cancel"
					/> ) : (null)
				}
				{ this.state.showPopupCancelRepricerSubscription ? (
					<BConfirmationModal
						isOpen={this.state.showPopupCancelRepricerSubscription}
						closeModal={this.togglePopupCancelRepricerSubscription}
						modalTitle="Confirm Cancel Repricer Subscription"
						modalText="Are you sure want to cancel your repricer subscription?"
						buttonColor="danger"
						buttonAction={this.cancelRepricerSubscription}
						buttonText="Yes, cancel repricer subscription"
						buttonCloseText="No, keep repricer subscription"
					/> ) : (null)
				}
				{ this.state.showPopupEnableRepricerSubscription ? (
					<BConfirmationModal
						isOpen={this.state.showPopupEnableRepricerSubscription}
						closeModal={this.togglePopupEnableRepricerSubscription}
						modalTitle="Confirm Enable Repricer Subscription"
						modalText="Are you sure want to enable repricer subscription?"
						buttonColor="success"
						buttonAction={this.enableRepricerSubscription}
						buttonText="Yes, enable repricer subscription"
						buttonCloseText="No, cancel"
					/> ) : (null)
				}
				{ this.state.showPopupCancelEbaySubscription ? (
					<BConfirmationModal
						isOpen={this.state.showPopupCancelEbaySubscription}
						closeModal={this.togglePopupCancelEbaySubscription}
						modalTitle="Confirm Cancel eBay Subscription"
						modalText="Are you sure want to cancel your eBay subscription?"
						buttonColor="danger"
						buttonAction={this.cancelEbaySubscription}
						buttonText="Yes, cancel eBay subscription"
						buttonCloseText="No, keep eBay subscription"
					/> ) : (null)
				}
				{ this.state.showPopupEnableEbaySubscription ? (
					<BConfirmationModal
						isOpen={this.state.showPopupEnableEbaySubscription}
						closeModal={this.togglePopupEnableEbaySubscription}
						modalTitle="Confirm Enable eBay Subscription"
						modalText="Are you sure want to enable eBay subscription?"
						buttonColor="success"
						buttonAction={this.enableEbaySubscription}
						buttonText="Yes, enable eBay subscription"
						buttonCloseText="No, cancel"
					/> ) : (null)
				}
			</div>
    );
  }
}

export default connect(
	state => {
		return {
			userData: state.Auth.get('userData'),
			profileImage: state.Settings.get("profileImage"),
			profileBillingDataSet: state.Settings.get("profileBillingDataSet"),
			is_member: state.Settings.get("is_canceled"),
			is_canceled: state.Membership.get("is_canceled"),
			is_restarted: state.Membership.get("is_restarted"),
			is_restarting: state.Membership.get("is_restarting"),
			is_canceling: state.Membership.get("is_canceling"),
			show_cancel_failed: state.Membership.get("show_cancel_failed"),
			show_restart_failed: state.Membership.get("show_restart_failed"),
			show_canceled: state.Membership.get("show_canceled"),
			show_restarted: state.Membership.get("show_restarted"),
			source: state.Membership.get("source"),
			user_enabling_repricer: state.Repricer.get("user_enabling_repricer"),
			isUserSettingsUpdating: state.Settings.get("isUserSettingsUpdating"),
			user_enabling_ebay: state.Repricer.get("user_enabling_ebay"),
		};
	}, {
		uploadProfileImage,
		getProfileImage,
		getProfileBillingData,
		cancelMembership,
		restartMembership,
		userEnableRepricer,
		updateUserDataSettings,
		userEnableEbay,
    fetchMembership,
	}
)(UpdateBilling);
