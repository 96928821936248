import React from "react";
import { Button, ButtonGroup, Label } from "reactstrap";
class ConditionSelector extends React.Component {

  render() {
    const { name, conditions, selectedCondition, onChangeCondition, buttonColor } = this.props;
    return (
      <div className="form-group">
          <Label className="control-label">Select condition</Label>
          <ButtonGroup className="btn-condition-group mb-4">
              {
				  !!conditions && conditions.map(
					(condition, i) =>
						<Button
							key={`condition_notes_${condition.value}_${i}`}
							onClick={() => onChangeCondition(name, condition.value)}
							// type="button"
							//className={`btn btn-outline-${buttonColor} btn-condition ${selectedCondition === condition.value ? 'selected' : ''}`}
              active={selectedCondition === condition.value}
							data-toggle="tooltip" data-title={condition.title}>{condition.label}
						</Button>
				  )
              }
          </ButtonGroup>
      </div>
    );
  }
}

ConditionSelector.propTypes = {
};

export default ConditionSelector;
