import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { amazonLogoSmall } from '../../../../../assets/images';
import {
  MdCheck as IconYes,
  MdClose as IconNo,
  MdArrowForward as IconArrowRight,
	//MdAddCircle as IconAdd,
	//MdRemoveCircle as IconMin,
	//MdClose as IconTimes
} from 'react-icons/lib/md';
import { TiArrowSync as IconLoading } from 'react-icons/lib/ti';
import { digitСonversion } from '../../../../../helpers/utility';

class RepricingInsight extends Component {

  componentDidMount = () => {
		this.props.reportInsightRun(
			{
				sku: this.props.sku,
				event_id: this.props.event_id,
			}
		)
	}

	displayDataCurrency = (data, currency) => {
		return digitСonversion(data, "currency", currency);
	}

	generatePrice = (rr) => {
		var pa = rr.price_calculation_actual;
		var ps = rr.price_calculation_symbol;
		//ps = ps.replace('+', `<IconAdd className="mx-1 text-muted" alt="+" />`);
		return (
			<div className="mb-3">
				{/*
			  ( CO Shipping Price <Badge color="success">$0.00</Badge> <IconAdd className="mx-1 text-muted" alt="+" /> CO Listing Price <Badge color="warning">$3.99</Badge> )<br />
			  <IconMin className="text-muted" alt="-" /> <br />
			  ( Percentage Incremental <Badge color="primary">1.25%</Badge> <IconTimes className="mx-1 text-muted" alt="*" /> ( CO Shipping Price <Badge color="success">$0.00</Badge>
			  <IconAdd className="mx-1 text-muted" alt="+" /> CO Listing Price <Badge color="warning">$3.99</Badge> ) ) <br/>
			  <IconMin className="text-muted" alt="-" /> <br />
			  Inventory Shipping Price <Badge color="danger">$3.99</Badge>
			  */}
			  {pa} <br /> {ps}
			</div>
		)
	}

  render() {

	  const { reject_report } = this.props.insightTemplate;

    return (
      <div className="repricing-insight">
        <div className="content-header">
          <h5>Repricing Insight - SKU: { this.props.sku }</h5>
          <span className="ml-5 mr-4"><img src={amazonLogoSmall} alt="Amazon Logo" /></span>
        </div>
        <div className="content-body">
          <Row>
            <Col lg="5">
              <Table className="text-left">
                <tbody>
                  <tr>
                    <td>Competing offer is buybox</td>
                    <td><IconNo size="22" className="text-danger" /></td>
                  </tr>
                  <tr>
                    <td>Competing offer listing price</td>
                    <td>
                      {reject_report ?
                        this.displayDataCurrency(
                          reject_report.competing_offer_listing_price,
                          reject_report.competing_offer_listing_price_currency,
                        )
                        : null
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Competing offer shipping price</td>
					<td>{reject_report ?
							this.displayDataCurrency(
								reject_report.competing_offer_shipping_price,
								reject_report.competing_offer_listing_price_currency,
							)
							: null
						}
					</td>
                  </tr>
                  <tr>
                    <td>Competing offer sub condition</td>
					<td>{reject_report ?
							reject_report.competing_offer_sub_condition
							: null
						}
					</td>
                  </tr>
                  <tr>
                    <td>Is own offer not in top 20 offer</td>
					<td>{reject_report ?
							reject_report.is_own_offer_not_in_top_20_offer ?
								<IconYes size="22" className="text-success" />
								: <IconNo size="22" className="text-danger" />
							: null
						}
					</td>
                  </tr>
                  <tr>
                    <td>Own offer is buybox before reprice</td>
					<td>{reject_report ?
							reject_report.own_offer_is_buybox_before_reprice ?
								<IconYes size="22" className="text-success" />
								: <IconNo size="22" className="text-danger" />
							: null
						}
					</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg="7">
              <div className="repricing-result">
				  <span className="price">
            {this.props.insightTemplateLoading &&
              <div className="loading">
								<IconLoading className="loading-icon fa-spin" />
								<span>Loading data...</span>
							</div>
            }
					  <span className="text-success">
						{reject_report ?
							this.displayDataCurrency(
								reject_report.old_price,
								reject_report.competing_offer_listing_price_currency,
							)
							: null
						}
					  </span>
					  {reject_report && <IconArrowRight size="26" className="icon-arrow" />}
					  <span className="text-success">
						{reject_report ?
							this.displayDataCurrency(
								reject_report.new_price,
								reject_report.competing_offer_listing_price_currency,
							)
							: null
						}
					  </span>
				  </span>
            {/*<p>On <strong>10/17/2019 at 11:11 AM</strong> a price revision was made on SKU <strong className="sku">{this.props.sku}</strong></p>*/}
              </div>
              <div className="equation">
                <h5 className="mb-3">How do we calculate the price?</h5>
				{ reject_report ? this.generatePrice(reject_report) : null }
              </div>
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default RepricingInsight;
