import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { Card, CardBody, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Navbar } from 'reactstrap';
import MetricItem from "./MetricItem";
import { configuration } from "./dashboard_config";
import './style.css';
import MetricBoxItem from './MetricBoxItem';
import SalesChart from './SalesChart';
import IntervalDropdown from './IntervalDropdown';
import dashboardActions from "../../../redux/main_dashboard/actions";
import inventoryActions from "../../../redux/inventory/actions";
import {
  momentDateToLocalFormatConversion,
  momentDateToISOFormatConversioniWithFormat,
} from "../../../helpers/utility";
import { UnitSoldChart, ASPChart, UnitListedSoldChart, TotalInventoryChart, TurnRateChart } from './DashboardChart';
import RichMessageBox from '../../../shared/components/RichMessageBox';
import messageActions from "../../../redux/message/actions";
import appActions from "../../../redux/app/actions";

const {
  getDashboardPeriodData,
  fetchChartData,
  fetchDashboardData,
  setChartPeriodType,
  setChartEndDate,
} = dashboardActions;

const { getInventoryStats } = inventoryActions;

const {
  messageInAppGetUser,
  messageInAppSetTracking,
} = messageActions;

const { userPageviewCapture } = appActions;

const ViewHeader = () => (
  <header className="view-header">
    <h1 className="page-title">Main Dashboard</h1>
  </header>
);

const ViewContent = ({ children, messages_user, messageOnClose, user_message_closed }) => (
  <div className="view-content view-components">
    {messages_user?.length > 0 && !user_message_closed ?
      <RichMessageBox
        news={messages_user}
        onClose={messageOnClose}
      />
      : null
    }
    {children}
  </div>
);

class MainDashboard extends Component {
  state = {
    selectedInterval: {value: 'today_yesterday_this_month_last_month', label: 'Default'},
    showIntervalDropdown: false,
    currentRange: {startDate: moment().subtract(7, 'days'), endDate: moment()},
    activeTab: "1",
    user_message_closed: false,
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Dashboard"})
    this.props.getDashboardPeriodData({period: "today_yesterday_this_month_last_month"});
    this.props.getInventoryStats({ stats_type: "dashboard" })
    this.props.messageInAppGetUser();

      /*
    configuration.forEach(config => {
      let {key, minTimestamp, maxTimestamp, stat} = config;
      this.props.fetchDashboardData(key, stat, minTimestamp, maxTimestamp);
    });
    */
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    let data = {
      "period": selected.value,
    }
    if(selected.value === 'custom'){
      data.from = this.state.currentRange.startDate.unix();
      data.to = this.state.currentRange.endDate.unix();
    }
    this.props.getDashboardPeriodData(data);
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangeChange = (event, picker) => {
    const currentRange = { startDate: picker.startDate, endDate: picker.endDate }
    this.setState({ currentRange: currentRange });
    const data = {
      "period": "custom",
      "from": currentRange.startDate.unix(),
      "to": currentRange.endDate.unix(),
    }
    this.props.getDashboardPeriodData(data);
  }

  toggleTab = (id) => {
    this.setState({
      activeTab: id
    })
  }

  messageOnClose = (message) => {
    const data = {
      id: message.id,
    }
    this.props.messageInAppSetTracking(data);
    this.setState({user_message_closed: true})
  }

  render() {
    const {
      internationalization_config,
      period_data,
      chartData,
      setChartPeriodType,
      setChartEndDate,
      fetchChartData
    } = this.props;

    const { activeTab } = this.state;

    let start,end,labelCustom;
    if(this.state.selectedInterval.value === "custom") {
      start = moment(this.state.currentRange.startDate).format('MMM D, YYYY');
      end = moment(this.state.currentRange.endDate).format('MMM D, YYYY');
      labelCustom = start + ' - ' + end;
      if (start === end) {
        labelCustom = start;
      }
    }

    let stats = {
      "current_inventory" : 0,
      "average_sales_rank" : 0,
      "ebay_percent_sales": 0.0,
    }
    if(this.props.inventoryStats){
      stats.current_inventory = this.props.inventoryStats.active_count;
      stats.average_sales_rank = this.props.inventoryStats.avg_rank;
      stats.ebay_percent_sales = this.props.inventoryStats.ebay_percent_sales;
    }

    return (
      <div className="view main-dashboard-3">
        <ViewHeader />
        <ViewContent
          messages_user={this.props.messages_user ? this.props.messages_user : []}
          messageOnClose={this.messageOnClose}
          user_message_closed={this.state.user_message_closed}
        >
          <Navbar color="dark" expand="md">
          <Nav navbar>
            <NavItem>
              <NavLink active={activeTab === "1"} onClick={() => this.toggleTab("1")}>Sales</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === "2"} onClick={() => this.toggleTab("2")}>Units Sold</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === "3"} onClick={() => this.toggleTab("3")}>ASP (Avg. Selling Price)</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === "4"} onClick={() => this.toggleTab("4")}>Unit Listed vs. Units Sold</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === "5"} onClick={() => this.toggleTab("5")}>Total Inventory</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === "6"} onClick={() => this.toggleTab("6")}>Sales Thru %</NavLink>
            </NavItem>
          </Nav>
          </Navbar>
          <Card>
          <TabContent activeTab={activeTab} className="px-3 py-4">
            <TabPane tabId="1">
              <Row>
                <Col lg={6} xl={4}>
                  <IntervalDropdown
                    isOpen={this.state.showIntervalDropdown}
                    toggle={this.toggleIntervalDropdown}
                    onSelect={this.selectInterval}
                    selected={this.state.selectedInterval}
                    currentRange={this.state.currentRange}
                    customRangeChange={this.customRangeChange}
                    options={[
                      {value: 'today_yesterday_this_month_last_month', label: 'Default'},
                      {value: 'today_yesterday', label: 'Today vs. Yesterday'},
                      {value: '7days_14days', label: '7 days vs. 14 days'},
                      {value: 'this_month_last_month', label: 'This month vs. Last month'},
                      {value: 'custom', label: "Custom"}
                    ]}
                  />
                  <div className="mt-4">
                    {this.state.selectedInterval.value !== "custom" ?
                      <React.Fragment>
                        {period_data && period_data.today ?
                          <MetricBoxItem
                            id="metric1"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"Today's Sales - " + momentDateToLocalFormatConversion(period_data.today.date_start)}
                            data={period_data.today}
                            className="mb-3 color1"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                        {period_data && period_data.yesterday ?
                          <MetricBoxItem
                            id="metric2"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"Yesterday's Sales - " + momentDateToLocalFormatConversion(period_data.yesterday.date_start)}
                            data={period_data.yesterday}
                            className="mb-3 color2"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                        {period_data && period_data.this_month ?
                          <MetricBoxItem
                            id="metric3"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"This Month's Sales - " + momentDateToISOFormatConversioniWithFormat(period_data.this_month.date_start, "MMMM")}
                            data={period_data.this_month}
                            className="mb-3 color3"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                        {period_data && period_data.last_month ?
                          <MetricBoxItem
                            id="metric4"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"Last Month's Sales - " + momentDateToISOFormatConversioniWithFormat(period_data.last_month.date_start, "MMMM")}
                            data={period_data.last_month}
                            className="mb-3 color4"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                        {period_data && period_data.days_7 ?
                          <MetricBoxItem
                            id="metric3"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"Sales in last 7 days"}
                            data={period_data.days_7}
                            className="mb-3 color3"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                        {period_data && period_data.days_14 ?
                          <MetricBoxItem
                            id="metric4"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={"Sales in last 14 days"}
                            data={period_data.days_14}
                            className="mb-3 color4"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {period_data && period_data.custom ?
                          <MetricBoxItem
                            id="metric1"
                            tooltipText="Tooltip text to help users understand metric meaning"
                            title={this.state.selectedInterval.label + ": " + labelCustom}
                            data={period_data.custom}
                            className="color1"
                            internationalization_config={internationalization_config}
                          />
                          : null
                        }
                      </React.Fragment>
                    }
                  </div>
                </Col>
                <Col lg={6} xl={8}>
                  <div className="dashboard-metrics">
                  {
                    configuration.map(config => {
                      let {key, className, metricName, numberType, tooltipText, isPercentage} = config;
                      let value = stats[key];

                      return (
                        className === 'blue' ?
                        <MetricItem
                          key={key}
                          metricKey={key}
                          metricName={metricName}
                          value={value}
                          numberType={numberType}
                          currencyCode={internationalization_config.currency_code}
                          className={className}
                          tooltipText={tooltipText}
                          isPercentage={isPercentage ? true : false}
                        />
                        : null
                      )
                    })
                  }
                  </div>
                  <div className="mt-4">
                    <SalesChart />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" &&
                <UnitSoldChart
                  chartData={chartData.units_sold}
                  internationalizationConfig={internationalization_config}
                  setPeriodType={setChartPeriodType}
                  setChartEndDate={setChartEndDate}
                  fetchChartData={fetchChartData}
                />
                }
            </TabPane>
            <TabPane tabId="3">
              {activeTab === "3" &&
                <ASPChart
                  chartData={chartData.asp}
                  internationalizationConfig={internationalization_config}
                  setPeriodType={setChartPeriodType}
                  setChartEndDate={setChartEndDate}
                  fetchChartData={fetchChartData}
                />
              }
            </TabPane>
            <TabPane tabId="4">
              {activeTab === "4" &&
                <UnitListedSoldChart
                  chartData={chartData.unit_listed}
                  internationalizationConfig={internationalization_config}
                  setPeriodType={setChartPeriodType}
                  setChartEndDate={setChartEndDate}
                  fetchChartData={fetchChartData}
                />
              }
            </TabPane>
            <TabPane tabId="5">
              {activeTab === "5" &&
                <TotalInventoryChart
                  chartData={chartData.total_inventory}
                  setPeriodType={setChartPeriodType}
                  setChartEndDate={setChartEndDate}
                  fetchChartData={fetchChartData}
                />
              }
            </TabPane>
            <TabPane tabId="6">
            {activeTab === "6" &&
              <TurnRateChart
                chartData={chartData.turn_rate}
                setPeriodType={setChartPeriodType}
                setChartEndDate={setChartEndDate}
                fetchChartData={fetchChartData}
              />
            }
            </TabPane>
          </TabContent>
          </Card>
        </ViewContent>
      </div>
    )
  }
}

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
    period_data: state.Dashboard.get("period_data"),
    period_data_loading: state.Dashboard.get("period_data_loading"),
    inventoryStats: state.Inventory.get("inventoryStats"),
    chartData: state.Dashboard.get('chartData'),
    messages_user: state.Message.get('messages_user'),
  }),
  {
    getDashboardPeriodData,
    getInventoryStats,
    fetchChartData,
    fetchDashboardData,
    setChartPeriodType,
    setChartEndDate,
    messageInAppGetUser,
    messageInAppSetTracking,
    userPageviewCapture,
  }
)(MainDashboard);
