import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ManageFAQ from './ManageFAQ';
import ManageTopics from './ManageTopics';
import adminActions from "../../../../redux/admin/actions";

const {
  getFaqTopics,
  addFaqTopic,
  updateFaqTopic,
  getFaqArticles,
  addFaqArticle,
  updateFaqArticle,
  deleteFaqArticle,
  deleteFaqTopic,
  reorderFaqTopics,
} = adminActions;

class AdminFAQ extends Component {
  state = {
    activeTab: "1"
  }

  componentDidMount(){
    this.props.getFaqTopics();
    this.props.getFaqArticles();
  }


  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  createTopicDropdownByType = (topic_type) => {
    const data = this.props.admin_faq_topics.filter(item => item.topic_type === topic_type);
    return data.map(item => {
      return {
        label: item.name,
        value: item.id,
      }
    });
  }

  render() {
    const {
      activeTab
    } = this.state;

    return (
      <React.Fragment>
        <Card className="card-tab">
          <CardHeader>
          <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => this.toggleTab("1")}
                >
                  eBay
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => this.toggleTab("2")}
                >
                  Profit Reprice™
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "3"}
                  onClick={() => this.toggleTab("3")}
                >
                  Manage Topics
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" &&
              <ManageFAQ
                category="ebay"
                topics={this.createTopicDropdownByType('ebay')}
                data={this.props.admin_faq_articles}
                addFaqArticle={this.props.addFaqArticle}
                updateFaqArticle={this.props.updateFaqArticle}
                deleteFaqArticle={this.props.deleteFaqArticle}
              />}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" &&
              <ManageFAQ
                category="profit_reprice"
                topics={this.createTopicDropdownByType('profit_reprice')}
                data={this.props.admin_faq_articles}
                addFaqArticle={this.props.addFaqArticle}
                updateFaqArticle={this.props.updateFaqArticle}
                deleteFaqArticle={this.props.deleteFaqArticle}
              />}
            </TabPane>
            <TabPane tabId="3">
              {activeTab === "3" &&
              <ManageTopics
                addFaqTopic={this.props.addFaqTopic}
                updateFaqTopic={this.props.updateFaqTopic}
                admin_faq_topics={this.props.admin_faq_topics}
                deleteFaqTopic={this.props.deleteFaqTopic}
                admin_faq_reordering_topics={this.props.admin_faq_reordering_topics}
                reorderFaqTopics={this.props.reorderFaqTopics}
              />}
            </TabPane>
          </TabContent>
        </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
    return {
		  admin_faq_topics: state.Admin.get("admin_faq_topics"),
		  admin_faq_articles: state.Admin.get("admin_faq_articles"),
		  admin_faq_reordering_topics: state.Admin.get("admin_faq_reordering_topics"),
    };
  },
  {
    getFaqTopics,
    addFaqTopic,
    updateFaqTopic,
    getFaqArticles,
    addFaqArticle,
    updateFaqArticle,
    deleteFaqArticle,
    deleteFaqTopic,
    reorderFaqTopics,
  }
)(AdminFAQ);
