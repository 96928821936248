const landingActions = {
	FETCH_INVENTORY_ITEMS_COUNT: "FETCH_INVENTORY_ITEMS_COUNT",
	FETCH_INVENTORY_ITEMS_SUCCESS: "FETCH_INVENTORY_ITEMS_SUCCESS",

	FETCH_INVENTORY_ITEMS_EBAY_COUNT: "FETCH_INVENTORY_ITEMS_EBAY_COUNT",
	FETCH_INVENTORY_ITEMS_EBAY_SUCCESS: "FETCH_INVENTORY_ITEMS_EBAY_SUCCESS",

  FETCH_EBAY_GRAPH_SALES: "FETCH_EBAY_GRAPH_SALES",
  FETCH_EBAY_GRAPH_SALES_SUCCESS: "FETCH_EBAY_GRAPH_SALES_SUCCESS",

  GET_FAQ_LANDING: "GET_FAQ_LANDING",
  GET_FAQ_LANDING_SUCCESS: "GET_FAQ_LANDING_SUCCESS",
  GET_FAQ_LANDING_FAILED: "GET_FAQ_LANDING_FAILED",

  OPTIN_SENDGRID: "OPTIN_SENDGRID",
  OPTIN_SENDGRID_SUCCESS: "OPTIN_SENDGRID_SUCCESS",
  OPTIN_SENDGRID_FAILED: "OPTIN_SENDGRID_FAILED",

  GET_DESKTOP_DOWNLOAD_DATA: "GET_DESKTOP_DOWNLOAD_DATA",
  GET_DESKTOP_DOWNLOAD_DATA_SUCCESS: "GET_DESKTOP_DOWNLOAD_DATA_SUCCESS",
  GET_DESKTOP_DOWNLOAD_DATA_FAILED: "GET_DESKTOP_DOWNLOAD_DATA_FAILED",

  OPTIN_SENDGRID_TOGGLE_POPUP: "OPTIN_SENDGRID_TOGGLE_POPUP",
  OPTIN_SENDGRID_TOGGLE_POPUP_SUCCESS: "OPTIN_SENDGRID_TOGGLE_POPUP_SUCCESS",
  OPTIN_SENDGRID_TOGGLE_POPUP_FAILED: "OPTIN_SENDGRID_TOGGLE_POPUP_FAILED",

  optinSendgridTogglePopup: (data) => ({
		type: landingActions.OPTIN_SENDGRID_TOGGLE_POPUP,
		data
  }),
  optinSendgridTogglePopupSuccess: (data) => ({
		type: landingActions.OPTIN_SENDGRID_TOGGLE_POPUP_SUCCESS,
		data
  }),
  optinSendgridTogglePopupFailed: (data) => ({
		type: landingActions.OPTIN_SENDGRID_TOGGLE_POPUP_FAILED,
		data
  }),

  getDesktopDownloadData: (data) => ({
		type: landingActions.GET_DESKTOP_DOWNLOAD_DATA,
		data
  }),
  getDesktopDownloadDataSuccess: (data) => ({
		type: landingActions.GET_DESKTOP_DOWNLOAD_DATA_SUCCESS,
		data
  }),
  getDesktopDownloadDataFailed: (data) => ({
		type: landingActions.GET_DESKTOP_DOWNLOAD_DATA_FAILED,
		data
  }),

  optinSendgrid: (data) => ({
		type: landingActions.OPTIN_SENDGRID,
		data
  }),
  optinSendgridSuccess: (data) => ({
		type: landingActions.OPTIN_SENDGRID_SUCCESS,
		data
  }),
  optinSendgridFailed: (data) => ({
		type: landingActions.OPTIN_SENDGRID_FAILED,
		data
  }),

  getFaqLanding: (data) => ({
		type: landingActions.GET_FAQ_LANDING,
		data
  }),
  getFaqLandingSuccess: (data) => ({
		type: landingActions.GET_FAQ_LANDING_SUCCESS,
		data
  }),
  getFaqLandingFailed: (data) => ({
		type: landingActions.GET_FAQ_LANDING_FAILED,
		data
  }),

  bFrontItemListedCountEbay: () => ({
    type: landingActions.FETCH_INVENTORY_ITEMS_EBAY_COUNT,
  }),

  bFrontItemListedCountEbaySuccess: count => ({
    type: landingActions.FETCH_INVENTORY_ITEMS_EBAY_SUCCESS,
	count
  }),

  bEbayGraphSales: () => ({
    type: landingActions.FETCH_EBAY_GRAPH_SALES
  }),

  bEbayGraphSalesSuccess: data => ({
    type: landingActions.FETCH_EBAY_GRAPH_SALES_SUCCESS,
    data
  }),

  bFrontItemListedCount: () => ({
    type: landingActions.FETCH_INVENTORY_ITEMS_COUNT,
  }),

  bFrontItemListedCountSuccess: count => ({
    type: landingActions.FETCH_INVENTORY_ITEMS_SUCCESS,
	count
  }),

};

export default landingActions;
