import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from "../../../../helpers/utility";
import {
  Button, Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import "../../style.css";
import inventoryActions from '../../../../redux/inventory/actions';
import {getConditionNumAbbreviations} from '../../../../helpers/inventory/utility';
import { checkIfEmployeeAndAllowed } from '../../../../helpers/utility';

/**
 * Expire inventory table display
 */
class ExpireSoonTable extends Component {
  state = {
    roiModalOpen: false,
		roiAmountType: '$',
		roiAmountNumber: 0,
    roiSKU: '',
    ebayModalOpen: false,
    buyCostModalOpen: false,
		buyCostAmountNumber: 0,
    buyCostSKU: '',
    deleteItemModalOpen: false,
    delete_seller_sku: "",
  }

  toggleDeleteItemModal = () => {
    this.setState({
      deleteItemModalOpen: !this.state.deleteItemModalOpen
    })
  }

  componentDidMount(){
    this.getNextPage({
      channel: "all",
      status: "active",
      page: 0,
      pageSize: 10,
      sorted: [{
        id: "seller_sku",
        sort_order: "asc",
      }],
      search_string: "",
    });
  }


  getNextPage = (state) => {
    this.props.fetchInventoryData(parseInt(state.page, 10) + 1, state.pageSize, state.sorted, this.props.searchText);
  }

	updateState = (target, e) => {
		this.setState({ [target]: e });
	}

  handlePurchaseDate = (date) => {
    //try to handle issue with multiple different formats of data
    //we are gathering here (batch and inventory side)
    if(date.includes('T')){
      date = date.split('T')[0];
      date = `${date} 00:00:00`
    } else if(date.includes(' ')){
      date = date.split(' ')[0];
      date = `${date} 00:00:00`
    }
    return moment(date).format('L')
  }

  deleteItem = () => {
    this.props.inventoryItemDelete({sku: this.state.delete_seller_sku});
    this.setState({"delete_seller_sku": ""})
    this.toggleDeleteItemModal();
  }

  generateTableColums = (showLabelsColumn, userData) => {
    let tableColumns = [
      {
        id: "title",
        accessor: "title",
        Header: "Product",
        headerClassName: "text-left",
        minWidth: 200,
        Cell: props => (
          <div className="d-flex">
            <div>
              <img
                src={`https://www.amazon.com/images/P/${props.original.asin}.01._THUMBZZZ_.jpg`}
                width="25"
                alt={props.original.title}
                className="mr-1"
              />
            </div>
            <div>
              <div className="text-truncate" style={{ minWidth: 150 }}>{props.original.title}</div>
              <div>{`ASIN: ${props.original.asin}`}</div>
            </div>
          </div>
        ),
      },
      {
        id: "sku",
        Header: "SKU",
        accessor: "sku",
        minWidth: 150,
        headerClassName: "text-left",
        style: { alignSelf: "center" }
      },
      {
        id: "exp_date",
        accessor: "exp_date",
        Header: "Expiration",
        headerClassName: "text-left",
        Cell: props => (
          <div>
            {
              props.value
              ? this.handlePurchaseDate(props.value)
              : ""
            }
          </div>
        ),
      },
    ];

	  return tableColumns;
  }

  toggleRoiModal = () => {
    this.setState({
      roiModalOpen: !this.state.roiModalOpen
    })
  }

  toggleRoiModalROI = (n, t, sku) => {
    this.setState({
			roiModalOpen: !this.state.roiModalOpen,
			roiAmountType: t,
			roiAmountNumber: n,
			roiSKU: sku,
    })
  }

	saveRoi = () => {
		let amount = parseFloat(this.state.roiAmountNumber).toFixed(2);
		if(amount < 0){ amount = 0}
		//if(this.state.roiAmountType === '%' && amount > 100){ amount = 100}

		const data = {
			sku: this.state.roiSKU,
			repricer_roi_number: parseFloat(amount),
			repricer_roi_type: this.state.roiAmountType,
		}
		this.props.updateInventory(data);
		this.toggleRoiModal();
  }

  toggleAmountType = (newROI) => {
    this.setState({
      roiAmountType: newROI ? newROI : '$'
    })
  }

  toggleEbayModalLocal = (item) => {
		if(!this.props.ebay_create_modal_display){
			const data = {
				sku: item.seller_sku,
			}
			this.props.ebayGetItemData(data);
			this.props.toggleEbayModal();
		} else {
			this.props.toggleEbayModal();
		}
  }

  toggleBuyCostModal = () => {
    this.setState({
      buyCostModalOpen: !this.state.buyCostModalOpen
    })
  }

  openBuyCostModal = (value, sku, fulfillmentChannel) => {
    this.setState({
			buyCostModalOpen: true,
			buyCostAmountNumber: value ? value : 0,
      buyCostSKU: sku,
      buyCostFulfillmentChannel: fulfillmentChannel
    })
  }

	saveBuyCost = () => {
		let amount = parseFloat(this.state.buyCostAmountNumber).toFixed(2);
		if(amount < 0){ amount = 0}

		const data = {
			sku: this.state.buyCostSKU,
			buy_cost: parseFloat(amount),
      fulfillment_channel: this.state.buyCostFulfillmentChannel
		}
		this.props.updateInventory(data);
		this.toggleBuyCostModal();
  }

  render() {
    const { expireItems, showLabelsColumn, } = this.props;

    return (
      <React.Fragment>
        {expireItems ? (
          <React.Fragment>
            <ReactTable
              data={expireItems.results} // should default to []
              pages={expireItems.pages}
              columns={this.generateTableColums(showLabelsColumn, this.props.userData)}
              defaultPageSize={10}
              loading={this.props.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => { this.getNextPage(state) }}
              minRows={0}
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
		userData: state.Auth.get("userData"),
    expireItems: state.Inventory.get("expireItems"),
  }),
	{
	}
)(ExpireSoonTable);
