import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  ButtonGroup,
  FormGroup,
	InputGroupText,
	Label,
} from 'reactstrap';
import Select from "react-select";
import {checkConditionIsUsedOrNew, checkNumberIsPositive} from '../../../helpers/batch/utility';
import {rangeGraphOptions} from '../../../helpers/settings/graph_options';
import TaxCodeInputForm from "../../../shared/components/taxCodeInputForm";
import Toggle from "../../../shared/components/Toggle";
import './index.css';
import settingsActions from '../../../redux/settings/actions';
import {
	ListPriceRuleOptions,
	ListPriceDirectionRuleOptions,
	ListBatchItemConditions } from "../../../helpers/settings/list_price_data";
import DeleteDialog from "../../../shared/components/dialogs/DeleteDialog";
import SelectPrepCategory from "../../../shared/components/SelectPrepCategory";
import SelectPrepType from "../../../shared/components/SelectPrepType";
import SelectWhoLabelsPreps from "../../../shared/components/SelectWhoLabelsPreps";

class ListingDefaults extends Component {
  constructor (props) {
    super(props);
    const {
      list_price,
      buy_cost,
      quantity,
      min_price,
      max_price,
      tax_code,
      list_price_rule_type,
      list_price_rule_amount,
      price_rule_type,
      price_rule_direction,
		  sku_prefx,
      default_list_price,
      pricing_options,
      grading_options,
      keepa_date_range,
			condition,
    } = props.listingDefaults;

    this.state = {
      list_price: list_price,
      buy_cost: buy_cost,
      quantity: quantity,
      min_price: min_price,
      max_price: max_price,
      tax_code: tax_code,
      list_price_rule_type: list_price_rule_type,
      list_price_rule_amount: list_price_rule_amount,
      price_rule_type: price_rule_type,
      price_rule_direction: price_rule_direction,
      default_list_price: default_list_price,
      keepa_date_range: keepa_date_range,
			sku_prefix: sku_prefx,
		  pricing_options,
		  grading_options,
			condition: condition,
			saveMessageFlashing: false,
			editTemplateId: null,
			editTemplateName: "",
			newTemplateName: "",
			inbound_shipping_estimate: "",
			media_mail_mf_fee_estimate: 0,
			media_mail_mf_fee_estimate_per_lb: 0,
			media_mail_mf_fee_estimate_enabled: false,
			deleteTemplateId: null
    }
  }

	flashSaveMessage(kill=false) {
		//flash message on save
		if(kill){
			this.setState({	saveMessageFlashing: false });
			return;
		}
		setTimeout(() => {
		  this.flashSaveMessage(true);
		}, 2000);
	}

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      list_price: newProps.listingDefaults.list_price,
      buy_cost: newProps.listingDefaults.buy_cost,
      quantity: newProps.listingDefaults.quantity,
      min_price: newProps.listingDefaults.min_price,
      max_price: newProps.listingDefaults.max_price,
      tax_code: newProps.listingDefaults.tax_code,
      list_price_rule_type: newProps.listingDefaults.list_price_rule_type,
      list_price_rule_amount: newProps.listingDefaults.list_price_rule_amount,
      price_rule_type: newProps.listingDefaults.price_rule_type,
      price_rule_direction: newProps.listingDefaults.price_rule_direction,
      default_list_price: newProps.listingDefaults.default_list_price,
      pricing_options: newProps.listingDefaults.pricing_options,
      grading_options: newProps.listingDefaults.grading_options,
      sku_prefix: newProps.listingDefaults.sku_prefix,
		  keepa_date_range: newProps.listingDefaults.keepa_date_range,
		  condition: newProps.listingDefaults.condition,
      prep_types: newProps.listingDefaults.prep_types,
      prep_owner: newProps.listingDefaults.prep_owner,
      prep_category: newProps.listingDefaults.prep_category,
      label_owner: newProps.listingDefaults.label_owner,
    })
		if(newProps.userData && newProps.userData.settings){
	    this.setState({
				inbound_shipping_estimate: newProps.userData.settings.inbound_shipping_estimate,
				media_mail_mf_fee_estimate: newProps.userData.settings.media_mail_mf_fee_estimate,
				media_mail_mf_fee_estimate_per_lb: newProps.userData.settings.media_mail_mf_fee_estimate_per_lb,
				media_mail_mf_fee_estimate_enabled: newProps.userData.settings.media_mail_mf_fee_estimate_enabled,
			})
		}
	}

  UNSAFE_componentWillMount() {
    this.props.fetchListingDefaults();
	}

  saveNewDefaults = () => {
	  this.setState({	saveMessageFlashing: true });
    let defaults = {};
    Object.keys(this.state).forEach(fieldName => {
        defaults[fieldName] = this.state[fieldName];
    })
    this.props.saveListingDefaults(defaults);
	  this.flashSaveMessage();
	}

  handleChangeNumberInput = (e, state_name, is_str=false) => {
    this.setState({
      [state_name]: Number(e.target.value) >= 0
        ? is_str ? e.target.value : Number(e.target.value)
        : is_str ? "" : 0
    })
	}

  handleChangeiMediaMailEnabled = (e) => {
    this.setState({
      media_mail_mf_fee_estimate_enabled: !this.state.media_mail_mf_fee_estimate_enabled,
    })
	}

  handleChangeTaxCodeInput = (e) => {
    this.setState({
      tax_code: e.value
    })
	}

  handlePricingToggle = (e) => {
    const pricingOptions = e.target.checked;
    if (!pricingOptions && this.state.list_price_rule_type === "") {
      this.props.userError("Please set a price rule other than Own Value to disable pricing options");
      return;
    }
    this.setState({pricing_options: pricingOptions})
  }

  updateRule = (name, value) => {
    let { listingDefaults } = this.props;
    let conditionIsUsedOrNew = !!listingDefaults && !!listingDefaults.condition && checkConditionIsUsedOrNew(listingDefaults.condition);
    if(value === 'match_buy_box_price' && !conditionIsUsedOrNew) {
        this.props.userError("Change the condition in Notes tab to Used/New!");
        return;
    }
    if (value !== "fixed_value") {
        this.setState({list_price: ""});
    }
    this.setState({[name]: value});
    !!this.state.list_price_rule_amount && this.setState({list_price_rule_amount: ''});
    !!this.state.price_rule_type && this.setState({price_rule_type: ''});
    !!this.state.price_rule_direction && this.setState({price_rule_direction: ''});

    if (name === "list_price_rule_type" && (value === "" || value === "own-price")) {
      this.setState({
        pricing_options: true
      })
    }
  }

  handleChange = (name, value) => {
    let { userError, listingDefaults } = this.props;
    let conditionIsUsedOrNew = !!listingDefaults && !!listingDefaults.condition && checkConditionIsUsedOrNew(listingDefaults.condition);
    let buyCostIsPositive = !!this.state.buy_cost && checkNumberIsPositive(this.state.buy_cost);
    if(name === "price_rule_direction") {
      if(["higher_than_buy_box", "lower_than_buy_box"].indexOf(value) > -1 && !conditionIsUsedOrNew) {
        userError("Please change the default condition in Notes tab to Used/New to select this auto-price rule!");
        return;
      }
      if(value === 'roi' || value === 'profit_margin') {
        if (!buyCostIsPositive) {
          userError("Please change the buy cost to a number greater than zero, to select this auto-price rule!");
          return;
        }
        this.setState({price_rule_type: null});
      }
    }
    this.setState({[name]: value});
  }

  updateCondition = (name, value) => {
    this.setState({[name]: value});
  }

  getObjectOfGraphOptions = (rangeGraph) => {
    const rangeGraphObject = rangeGraphOptions.find(el => el.numberValue === rangeGraph);
    if (rangeGraphObject) {
      return rangeGraphObject;
    } else {
      return rangeGraphOptions[5];
    }
  }

  mskuToggleChanged = () => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["list_with_new_msku"] = !ud.settings["list_with_new_msku"];
			this.props.updateUserData(ud);
			let data = {list_with_new_msku: ud.settings["list_with_new_msku"]};
			this.props.updateUserSettings(data);
		}
	}

	guidGenerator = () => {
    let S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
	}

	editShippingTemplate = (id, name) => {
		this.setState({
			editTemplateId: id,
			editTemplateName: name,
			newTemplateName: "",
		})
	}

	editShippingTemplateDone = (id, name) => {
		let ud = this.props.userData;
		if(!ud.settings["mf_shipping_templates"]){
			ud.settings["mf_shipping_templates"] = '[]';
		}
		let json = JSON.parse(ud.settings["mf_shipping_templates"]);
		json.filter((item, idx) => item.id === id ? item.name = name : item.name);
		ud.settings["mf_shipping_templates"] = JSON.stringify(json);
		this.props.updateUserData(ud);
		const data_ud = {mf_shipping_templates: ud.settings["mf_shipping_templates"]};
		this.props.updateUserSettings(data_ud);
		this.setState({
			editTemplateId: null,
			editTemplateName: null,
			newTemplateName: "",
		})
	}

	addShippingTemplate = (name) => {
		let ud = this.props.userData;
		if(!ud.settings["mf_shipping_templates"]){
			ud.settings["mf_shipping_templates"] = '[]';
		}
		let json = JSON.parse(ud.settings["mf_shipping_templates"]);
		const data = {
			id: this.guidGenerator(),
			name: name,
		}
		json.push(data);
		ud.settings["mf_shipping_templates"] = JSON.stringify(json);
		this.props.updateUserData(ud);
		const data_ud = {mf_shipping_templates: ud.settings["mf_shipping_templates"]};
		this.props.updateUserSettings(data_ud);
		this.setState({
			editTemplateId: null,
			editTemplateName: null,
			newTemplateName: "",
		})
	}

	confirmDeleteShippingTemplate = (id) => {
		this.setState({ deleteTemplateId: id })
	}

	cancelDeleteShippingTemplate = () => {
		this.setState({ deleteTemplateId: null })
	}

	deleteShippingTemplate = (id) => {
		let ud = this.props.userData;
		if(!ud.settings["mf_shipping_templates"]){
			ud.settings["mf_shipping_templates"] = '[]';
		}
		let json = JSON.parse(ud.settings["mf_shipping_templates"]);
		json = json.filter((item,idx) => item.id !== id);
		ud.settings["mf_shipping_templates"] = JSON.stringify(json);
		this.props.updateUserData(ud);
		const data_ud = {mf_shipping_templates: ud.settings["mf_shipping_templates"]};
		this.props.updateUserSettings(data_ud);
		this.setState({
			editTemplateId: null,
			editTemplateName: null,
			newTemplateName: "",
			deleteTemplateId: null
		})
	}

	saveChangeInboundShipment = () => {
		const val = parseFloat(this.state.inbound_shipping_estimate);
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["inbound_shipping_estimate"] = val;
			this.props.updateUserData(ud);
			const data = {inbound_shipping_estimate: ud.settings["inbound_shipping_estimate"]};
			this.props.updateUserSettings(data);
		}
	}

	saveChangeMediaMail = () => {
		const val = parseFloat(this.state.media_mail_mf_fee_estimate);
		const val_lb = parseFloat(this.state.media_mail_mf_fee_estimate_per_lb);
		const enabled = this.state.media_mail_mf_fee_estimate_enabled ? true : false;
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["media_mail_mf_fee_estimate"] = val;
			ud.settings["media_mail_mf_fee_estimate_per_lb"] = val_lb;
			ud.settings["media_mail_mf_fee_estimate_enabled"] = enabled;
			this.props.updateUserData(ud);
			const data = {
				media_mail_mf_fee_estimate: ud.settings["media_mail_mf_fee_estimate"],
				media_mail_mf_fee_estimate_per_lb: ud.settings["media_mail_mf_fee_estimate_per_lb"],
				media_mail_mf_fee_estimate_enabled: ud.settings["media_mail_mf_fee_estimate_enabled"],
			};
			this.props.updateUserSettings(data);
		}
	}

	render() {
    const {
      list_price,
      buy_cost,
      quantity,
      min_price,
      max_price,
      tax_code,
      list_price_rule_type,
      list_price_rule_amount,
      price_rule_type,
      price_rule_direction,
      default_list_price,
      grading_options,
      pricing_options,
			//keepa_date_range,
			condition,
			editTemplateId,
			editTemplateName,
      newTemplateName,
      prep_owner,
      prep_types,
      prep_category,
      label_owner,
    } = this.state;

		let {internationalConfig} = this.props;

		let shipping_templates = [];
		if(this.props.userData && this.props.userData.settings.mf_shipping_templates){
			shipping_templates = JSON.parse(this.props.userData.settings.mf_shipping_templates);
		}

		return (
      <Fragment>


            <p>
              These are the default settings for listing an items, whenever you create
              a new batch. If you make changes to listing presets INSIDE a specific
              batch, those changes will not propagate to other batches.
              <strong> This is the only place to make global changes to your default listing settings.</strong>
            </p>

			<Row>
				<Col>
					<Card>
						<CardBody>
							<Table>
							  <tbody>
								{["match_buy_box_price", "price"].indexOf(list_price_rule_type) === -1 &&
								  <tr>
									<td className="presetsTableBorderLeft align-middle col-form-label">List Price</td>
									<td className="presetsTableBorderRight">
									  <InputGroup>
										<InputGroupAddon addonType="prepend">{internationalConfig.currency_identifier}</InputGroupAddon>
										<Input
										  type="number"
										  step="0.01"
										  value={list_price || ""}
                      onChange={e => this.handleChangeNumberInput(e, "list_price")}
										/>
									  </InputGroup>
									</td>
								  </tr>
								}
								<tr>
								  <td className="presetsTableBorderLeft align-middle col-form-label">Buy Cost</td>
								  <td className="presetsTableBorderRight" >
									<InputGroup>
									  <InputGroupAddon addonType="prepend">{internationalConfig.currency_identifier}</InputGroupAddon>
									  <Input
                      type="number"
                      step="0.01"
                      value={buy_cost || ""}
                      onChange={e => this.handleChangeNumberInput(e, "buy_cost")}
									  />
									</InputGroup>
								  </td>
								</tr>
								<tr>
								  <td className="presetsTableBorderLeft align-middle col-form-label">Quantity</td>
								  <td className="presetsTableBorderRight" >
									<Input
									  type="number"
									  step="1"
									  value={quantity || ""}
                    onChange={e => this.handleChangeNumberInput(e, "quantity")}
									/>
								  </td>
								</tr>
								<tr>
								  <td className="presetsTableBorderLeft align-middle col-form-label">Min Price</td>
								  <td className="presetsTableBorderRight" >
									<InputGroup>
									  <InputGroupAddon addonType="prepend">{internationalConfig.currency_identifier}</InputGroupAddon>
									  <Input
										type="number"
										step="0.01"
										value={min_price || ""}
                    onChange={e => this.handleChangeNumberInput(e, "min_price")}
									  />
									</InputGroup>
								  </td>
								</tr>
								<tr>
								  <td className="presetsTableBorderLeft align-middle col-form-label">Max Price</td>
								  <td className="presetsTableBorderRight" >
									<InputGroup>
									  <InputGroupAddon addonType="prepend">{internationalConfig.currency_identifier}</InputGroupAddon>
									  <Input
										type="number"
										step="0.01"
										value={max_price || ""}
                    onChange={e => this.handleChangeNumberInput(e, "max_price")}
									  />
									</InputGroup>
								  </td>
								</tr>
								<tr>
								  <td  className="presetsTableBorderLeft align-middle col-form-label">Tax Code</td>
								  <td className="presetsTableBorderRight" >
									<TaxCodeInputForm
									  value={tax_code || ""}
									  onChange={this.handleChangeTaxCodeInput}
									/>
								  </td>
								</tr>
								<tr>
									<td className="presetsTableBorderLeft align-middle col-form-label">Inbound Shipping Estimations</td>
									<td className="presetsTableBorderRight">
										<div className="d-flex align-items-center">
											<div>
												<InputGroup>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>{internationalConfig.currency_identifier}</InputGroupText>
													</InputGroupAddon>
													<Input
														value={this.state.inbound_shipping_estimate}
                            onChange={e => this.handleChangeNumberInput(e, "inbound_shipping_estimate", true)}
														style={{ width: "3.8rem" }}
													/>
													<InputGroupAddon addonType="append">
														<InputGroupText>per pound</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
											</div>
											<div className="ml-1">
												<Button
													onClick={e => this.saveChangeInboundShipment()}
													color="success"
												>Save</Button>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td className="presetsTableBorderLeft align-middle col-form-label">Add Media Mail cost to MF fee calculation?</td>
									<td className="presetsTableBorderRight">
										<div className="d-flex align-items-center">
											<div>
												<InputGroup>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>{internationalConfig.currency_identifier}</InputGroupText>
													</InputGroupAddon>
													<Input
														value={this.state.media_mail_mf_fee_estimate}
                            onChange={e => this.handleChangeNumberInput(e, "media_mail_mf_fee_estimate", true)}
														style={{ width: "3.8rem" }}
													/>
												</InputGroup>
											</div>
											<div>
												+
											</div>
											<div>
												<InputGroup>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>{internationalConfig.currency_identifier}</InputGroupText>
													</InputGroupAddon>
													<Input
														value={this.state.media_mail_mf_fee_estimate_per_lb}
                            onChange={e => this.handleChangeNumberInput(e, "media_mail_mf_fee_estimate_per_lb", true)}
														style={{ width: "3.8rem" }}
													/>
													<InputGroupAddon addonType="append">
														<InputGroupText>per pound</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
											</div>
											<div className="text-center mx-2">
												<Label>
													YES <br/>
													<input
														type="checkbox"
														className="form-control"
														checked={this.state.media_mail_mf_fee_estimate_enabled}
														onChange={e => this.handleChangeiMediaMailEnabled(e)}
													/>
												</Label>
											</div>
											<div>
												<Button
													onClick={e => this.saveChangeMediaMail()}
													color="success"
												>
													Save
												</Button>
											</div>
										</div>
									</td>
								</tr>
							  </tbody>
						  </Table>
							<hr />
							<div className="mt-3">
								<CardTitle>MF Shipping Templates</CardTitle>
								<p className="text-left">Add your MF (Merchant Fulfilled) shipping template names here:</p>
								<FormGroup>
									{shipping_templates.map((template, idx) => (
										<InputGroup key={`template-name-${idx}`} className="mb-1">
											<Input
												disabled={!(editTemplateId === template.id)}
												value={editTemplateId === template.id ? editTemplateName : template.name}
												placeholder="Enter new name"
												onChange={(e) => this.setState({ editTemplateName: e.target.value })}
											/>
											<InputGroupAddon addonType="append">
												{editTemplateId === template.id ? (
													<React.Fragment>
														<Button
															disabled={editTemplateName === ""}
															onClick={() => this.editShippingTemplateDone(template.id, editTemplateName)}
														>
															<span className="icon icon-check bg-success"></span>
														</Button>
														<Button
															onClick={() => this.setState({ editTemplateId: null, editTemplateName: null })}
														>
															<span className="icon icon-close"></span>
														</Button>
													</React.Fragment>
												) : (
													<Button
														onClick={() => this.editShippingTemplate(template.id, template.name)}
														>
															<span className="icon icon-edit"></span>
													</Button>
												)}
													<Button
														onClick={() => this.confirmDeleteShippingTemplate(template.id)}
													>
														<span className="icon icon-trash bg-danger"></span>
													</Button>
											</InputGroupAddon>
										</InputGroup>
									))}
								</FormGroup>
								<FormGroup>
									<InputGroup>
										<Input
											placeholder="Enter new name"
											value={newTemplateName}
											onChange={(e) => this.setState({ newTemplateName: e.target.value })}
										/>
										<InputGroupAddon addonType="append">
											<Button
												color="success"
												disabled={!newTemplateName}
												onClick={() => this.addShippingTemplate(newTemplateName)}
											>
												Save
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</FormGroup>
							</div>
					  </CardBody>
				</Card>
			</Col>
			<Col>
				<Card>
					<CardBody>
						  <FormGroup>

							<CardTitle>Item Options</CardTitle>

						<FormGroup>
						  <Row>
							<Col xs="8" style={{ display: "flex", alignItems: "center" }}>
							  <label className="text-left full-width" style={{ marginBottom: "0 !important" }}>
								Default condition
							  </label>
							</Col>
							<Col xs="4">
							  <Select
								className="text-left"
								name="default_conditions"
								value={ condition }
								clearable={false}
								options={ListBatchItemConditions}
								onChange={ (option) => this.updateCondition('condition', !!option ? option.value : '')}
							  />
							</Col>
						  </Row>
					  </FormGroup>
						<FormGroup row>
							<Col md="6">
								<Label>Default Prep. Category</Label>
								<SelectPrepCategory
									plain_value={prep_category}
                  onChange={(e) => this.setState({"prep_category": e.value})}
								/>
							</Col>
							<Col md="6">
								<Label>Default Prep. Types</Label>
								<SelectPrepType
									value={prep_types}
                  onChange={(e) => this.setState({"prep_types": e.map(item => {return item.value;}).join(",")})}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col md="6">
								<Label>Default Who Labels</Label>
								<SelectWhoLabelsPreps
									value={label_owner}
									onChange={(e) => this.setState({"label_owner": e.value})}
								/>
							</Col>
							<Col md="6">
								<Label>Default Who Preps</Label>
								<SelectWhoLabelsPreps
									value={prep_owner}
									onChange={(e) => this.setState({"prep_owner": e.value})}
								/>
							</Col>
						</FormGroup>
						<hr />
								<CardTitle>List Price Rule</CardTitle>

							  <Select
								className="text-left"
								name="list_price_rule_type"
								value={ list_price_rule_type }
								options={ ListPriceRuleOptions }
								onChange={ (option) => this.updateRule('list_price_rule_type', !!option ? option.value : '')}
							  />
							  {
								(list_price_rule_type === 'lowest_fba_offer') &&
								<div className="child-controls" id="default-price">
								  <br />
								  <FormGroup>
								  <label className="text-left full-width">
									Price When Data is Unavailable:
								  </label>
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
                      className="form-control"
                      placeholder="Default Price"
                      name="default_list_price"
                      type="number"
                      value={default_list_price}
                      onChange={e => this.handleChangeNumberInput(e, "default_list_price", true)}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  {
								(list_price_rule_type === 'lowest_fbm_offer') &&
								<div className="child-controls" id="default-price">
								  <br />
								  <FormGroup>
								  <label className="text-left full-width">
									Price When Data is Unavailable:
								  </label>
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
                      className="form-control"
                      placeholder="Default Price"
                      name="default_list_price"
                      type="number"
                      value={default_list_price}
                      onChange={e => this.handleChangeNumberInput(e, "default_list_price", true)}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  {
								(list_price_rule_type === 'fixed_value') &&
								<div className="child-controls" id="default-price">
								  <FormGroup className="mt-3">
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
                      className="form-control"
                      placeholder="Enter Value"
                      name="list_price"
                      type="number"
                      value={list_price}
                      onChange={e => this.handleChangeNumberInput(e, "list_price", true)}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  {
								(list_price_rule_type === 'match_buy_box_price') &&
								<div className="child-controls" id="default-price">
								  <br />
								  <FormGroup>
								  <label className="text-left full-width">
									Price When Data is Unavailable:
								  </label>
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
                      className="form-control"
                      placeholder="Default Price"
                      name="default_list_price"
                      type="number"
                      value={default_list_price}
                      onChange={e => this.handleChangeNumberInput(e, "default_list_price", true)}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  {
								list_price_rule_type === 'price' && (price_rule_direction === 'roi' || price_rule_direction === 'profit_margin') &&
								<div className="child-controls" id="price">
								  <FormGroup className="mt-3">
									  <Row>
										  <Col md="8">
											  <InputGroup>
												<Input
												  placeholder="Enter Value"
												  type="number"
												  name="list_price_rule_amount"
												  value={ list_price_rule_amount }
												  onChange={(event) => this.handleChange('list_price_rule_amount', event.target.value)}
												  className="form-control"
												/>
												<InputGroupAddon addonType="append">%</InputGroupAddon>
											  </InputGroup>
										  </Col>
									  </Row>
								  </FormGroup>

								  <FormGroup>
									<Select
									  className="text-left"
									  name="price_rule_direction"
									  value={  price_rule_direction }
									  options={ ListPriceDirectionRuleOptions }
									  onChange={ (option) => this.handleChange("price_rule_direction", !!option ? option.value : '')}
									/>
									<br />
									<label className="text-left full-width">
									  Price When Fees are Unavailable:
									</label>
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
                      className="form-control"
                      name="default_list_price"
                      type="number"
                      value={default_list_price}
                      onChange={e => this.handleChangeNumberInput(e, "default_list_price", true)}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  {
								list_price_rule_type === 'price' &&
								price_rule_direction !== 'roi' &&
								price_rule_direction !== 'profit_margin' &&
								<div className="child-controls" id="price">
								  <FormGroup className="mt-3">
										  <InputGroup>
											  <Input
												placeholder="Enter Value"
												type="number"
												name="list_price_rule_amount"
												value={ list_price_rule_amount }
												onChange={(event) => this.handleChange('list_price_rule_amount', event.target.value)}
												className="form-control"
											  />
											<InputGroupAddon addonType="append">
											  <Button
												onClick={ () => this.handleChange("price_rule_type", true)}
												active={price_rule_type === true}>$</Button>
											  <Button
												onClick={ () => this.handleChange("price_rule_type", false)}
												active={price_rule_type === false}>%</Button>
											</InputGroupAddon>
											</InputGroup>
								  </FormGroup>

								  <FormGroup>
									<Select
									  className="text-left"
									  name="price_rule_direction"
									  value={  price_rule_direction }
									  options={ ListPriceDirectionRuleOptions }
									  onChange={ (option) => this.handleChange("price_rule_direction", !!option ? option.value : '')}
									/>
									<br />
									<label className="text-left full-width">
									  Price When Data is Unavailable:
									</label>
									<InputGroup>
									  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
									  <Input
										className="form-control"
										name="default_list_price"
										type="number"
										value={default_list_price}
										onChange={(event) => this.setState({default_list_price: event.target.value})}
									  />
									</InputGroup>
								  </FormGroup>
								</div>
							  }
							  <hr />
						  </FormGroup>

							<CardTitle>Batch Options</CardTitle>

						<Toggle label={"Pricing Options"} checked={pricing_options} onChange={this.handlePricingToggle} />
						<Toggle label={"Grading Options"} checked={grading_options} onChange={(event) => this.setState({grading_options: event.target.checked})} />
						{this.props.userData && this.props.userData.settings ?
							<Toggle label={"Always list with new MSKU"} checked={this.props.userData.settings.list_with_new_msku} onChange={this.mskuToggleChanged} />
							: null
						}
					</CardBody>
				</Card>
			</Col>
		</Row>

			<div className="mt-4 text-center">
				<div>
				<Button
					onClick={this.saveNewDefaults}
					color="success"
					disabled={this.state.saveMessageFlashing}
				>Save new defaults</Button>
				<br />
				{ this.state.saveMessageFlashing
					? (
						<label>
							Listing defaults saved
						</label>
						)
					: (null)
				}
				</div>
      </div>
			<DeleteDialog
				isOpen={this.state.deleteTemplateId !== null}
				close={this.cancelDeleteShippingTemplate}
				deleteItem={() => this.deleteShippingTemplate(this.state.deleteTemplateId)}
				dialogTitle="Are you sure want to delete it?"
				dialogText="You will loose access to this MF shipping template name when creating MF batch."
				confirmText="Yes, delete template name"
			/>
    </Fragment>
		);
	}
}

ListingDefaults.propTypes = {
  fetchListingDefaults: PropTypes.func.isRequired,
  listingDefaults: PropTypes.object.isRequired,
  saveListingDefaults: PropTypes.func.isRequired,
  userError: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    userData: state.Auth.get("userData"),
    listingDefaults: state.Settings.get('listingDefaults'),
    internationalConfig: state.Auth.get("internationalization_config")
})


export default connect(mapStateToProps, settingsActions)(ListingDefaults);
