import React, { Component } from 'react';
import {
  ResponsiveContainer, ComposedChart, XAxis,
  YAxis, CartesianGrid, Tooltip, Legend, Bar, 
} from 'recharts';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import PeriodTypeSelect from './PeriodTypeSelect';
import { digitСonversion } from "../../../../helpers/utility";
import CustomizedLabel from "../CustomizedLabel";
import chart_config from "../chart_config";
import getGradient from "../chart_gradients";

class UnitSoldChart extends Component {
  state = {
    date: null
  }

  componentDidMount() {
    const { chartData } = this.props;
    const chartDate = chartData.date ? moment(chartData.date) : moment();
    this.props.fetchChartData('units_sold', chartDate.toISOString(), this.props.chartData.period_type);
    this.setState({ date: chartDate })
  }

  moneyFormat = (val) => {
    return digitСonversion(val, "currency", this.props.internationalizationConfig.currency_code);
  }

  tooltipFormatter = (value, name) => {
    return name === 'Average Selling Price (ASP)' ? this.moneyFormat(value) : value;
  }

  handleSelectDate = (date) => {
    this.setState({ date: date });
    this.props.setChartEndDate(date.toISOString(), "units_sold");
    this.props.fetchChartData("units_sold", date.toISOString(), this.props.chartData.period_type);
  }

  render() {
    const { chartData,setPeriodType, fetchChartData } = this.props
    return (
      <React.Fragment>
        <div className="mb-4 row" style={{ width: "25rem" }}>
          <div className="col-6">
            <PeriodTypeSelect
              chartData={chartData}
              chartType="units_sold"
              setPeriodType={setPeriodType}
              fetchChartData={fetchChartData}
            />
          </div>
          <div className="col-6">
            <DatePicker selected={this.state.date} onChange={date => this.handleSelectDate(date)} />
          </div>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={250}
        >
          <ComposedChart
            data={chartData.data}
            margin={{
              top: 35, left: 30
            }}
          >
            <defs>
              {getGradient("gradientGreenASP", "green")}
              {getGradient("gradientOrangeSold", "orange")}
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={chart_config.cartesianStrokeColor}
            />
            <XAxis 
              dataKey="date"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={this.moneyFormat}
            />
            <Tooltip
              labelFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
              formatter={this.tooltipFormatter}
              cursor={false}
            />
            <Legend />
            <Bar
              name="Unit Sold"
              yAxisId="left"
              dataKey="units_sold"
              fill="url(#gradientOrangeSold)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel internationalizationConfig={this.props.internationalizationConfig} type="number" />}
            />
            <Bar
              name="Average Selling Price (ASP)"
              yAxisId="right"
              dataKey="asp"
              fill="url(#gradientGreenASP)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel internationalizationConfig={this.props.internationalizationConfig}/>}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

UnitSoldChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  internationalizationConfig: PropTypes.object.isRequired,
  setPeriodType: PropTypes.func.isRequired,
  setChartEndDate: PropTypes.func.isRequired,
  fetchChartData: PropTypes.func.isRequired
}

export default UnitSoldChart;
