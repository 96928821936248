import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardDeck, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import CardBreakdownPopover from './CardBreakdownPopover';
import ProfitAnalyticsChart from './ProfitAnalyticsChart';
import ProfitLossTable from './ProfitLossTable';
import { digitСonversion } from '../../../../../../helpers/utility';
import ExpenseAnalysis from './ExpenseAnalysis';
import PropTypes from "prop-types";
import './style.css';

class Summary extends Component {

  state = {
    activeTab: '1'
  }

  toggleTab = (tabId) => {
    this.setState({
      activeTab: tabId
    });
  }

	formatNumber = (value) => {
		if(this.props.internationalization_config && this.props.internationalization_config.currency_code){
	    return digitСonversion(value, "currency", this.props.internationalization_config.currency_code);
		} else {
			return "$0.00";
		}
  }

  render() {
    const {
      data,
      dataByDate,
      no_data
    } = this.props;

    const fakeData = {
      "dateStart": "Sep 03, 2019",
      "dateEnd": "Oct 30, 2019",
      "grossIncome": 2653.87,
      "cogs": -780.62,
      "expenses": -1054.74,
      "netProfit": 1599.13,
      "totalExpensesWithoutCogs": -274.12,
      "miscExpenses": 0,
			"ebayExpenses": 0,
			"ebayOrders": 0,
    }

    let dataUsed = no_data ? fakeData : data;
		var net = dataUsed.netProfit + dataUsed.miscExpenses;
		net = net + dataUsed.ebayExpenses;
		net = net + dataUsed.ebayOrders;

    return (
      <Fragment>
        <h3 className="h5 text-center">
          Profit Breakdown ({dataUsed.dateStart} - {dataUsed.dateEnd})
        </h3>
        <div className={`demoable-content-wrapper ${no_data ? "demo" : ""}`}>
          <CardDeck className="mt-4 align-items-center demoable-content profit-breakdown">
            <CardBreakdownPopover
              data={dataUsed}
              type="grossIncome"
            />
            <CardBreakdownPopover
              data={dataUsed}
              type="cogs"
            />
            <CardBreakdownPopover
              data={dataUsed}
              type="expenses"
            />
            <Card className="text-center result card-profit-breakdown">
              <CardBody>
								<span className={`value ${net < 0 ? "text-danger" : "text-success"}`}>{this.formatNumber(net)}</span>
                <span className="title">Net</span>
              </CardBody>
            </Card>
          </CardDeck>
          <div className="demo-overlay">
            <p>Currently we don't have enough data to show</p>
          </div>
        </div>

        <Card className="card-tab mt-5">
          <CardHeader>
            <Nav tabs>
              <NavItem>
                <NavLink
                  href="#"
                  active={this.state.activeTab === "1"}
                  onClick={() => this.toggleTab("1")}
                >
                  Profit Analytics Chart
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={this.state.activeTab === "2"}
                  onClick={() => this.toggleTab("2")}
                >
                  Profit &amp; Loss Table
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  active={this.state.activeTab === "3"}
                  onClick={() => this.toggleTab("3")}
                >
                  Expense Analysis
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
								<ProfitAnalyticsChart
									data={dataByDate}
									roi={data.roi}
									dailyNetProfit={data.dailyNetProfit}
									no_data={no_data}
									internationalization_config={this.props.internationalization_config}
								/>
              </TabPane>
              <TabPane tabId="2">
								<ProfitLossTable
									data={dataByDate}
									misc_expenses_mapping={this.props.misc_expenses_mapping}
									ebay_expenses_mapping={this.props.ebay_expenses_mapping}
								/>
              </TabPane>
              <TabPane tabId="3">
								<ExpenseAnalysis
									data={dataByDate}
									misc_expenses_mapping={this.props.misc_expenses_mapping}
									ebay_expenses_mapping={this.props.ebay_expenses_mapping}
								/>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

Summary.propTypes = {
	data: PropTypes.object.isRequired,
	dataByDate: PropTypes.array.isRequired,
	misc_expenses_mapping: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
  }
)(Summary);
