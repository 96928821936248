import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import Papa from "papaparse";
import fileDownload from "react-file-download";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* getSummary() {
    yield takeLatest('GET_SUMMARY', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.summary, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSummarySuccess(response.data.data))
					} else {
						yield put(actions.getSummaryFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales summary api error"));
					yield put(actions.getSummaryFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_SUMMARY_ERROR"
            }
          });
        }
    })
}

export function* getCategorySales() {
    yield takeLatest('GET_CATEGORY_SALES', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.category, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getCategorySalesSuccess(response.data.data))
					} else {
						yield put(actions.getCategorySalesFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales category api error"));
					yield put(actions.getCategorySalesFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_CATEGORY_SALES_ERROR"
            }
          });
        }
    })
}

export function* getProductSales() {
    yield takeLatest('GET_PRODUCT_SALES', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.product, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getProductSalesSuccess(response.data.data))
					} else {
						yield put(actions.getProductSalesFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales product api error"));
					yield put(actions.getProductSalesFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_PRODUCT_SALES_ERROR"
            }
          });
        }
    })
}

export function* getSalesChart() {
    yield takeLatest('GET_SALES_CHART', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.sales_chart, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSalesChartSuccess(response.data.data))
					} else {
						yield put(actions.getSalesChartFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales product api error"));
					yield put(actions.getSalesChartFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_SALES_CHART_ERROR"
            }
          });
        }
    })
}

export function* getSummaryExport() {
    yield takeLatest('GET_SUMMARY_EXPORT', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.summary_export, data);
					if (response.status === 200 && !response.data.error) {
            yield put(actions.getSummaryExportSuccess())
            const tvsItemsData = Papa.unparse(JSON.stringify({
              fields: response.data.headers,
              data: response.data.data
            }), {
              delimiter: "\t",
              header: true,
              skipEmptyLines: false
            })
            fileDownload(`${tvsItemsData}`, response.data.filename);
					} else {
						yield put(actions.getSummaryExportFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales summary export api error"));
					yield put(actions.getSummaryExportFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_SUMMARY_EXPORT_ERROR"
            }
          });
        }
    })
}

export function* getCategoryExport() {
    yield takeLatest('GET_CATEGORY_EXPORT', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.category_export, data);
					if (response.status === 200 && !response.data.error) {
            yield put(actions.getCategoryExportSuccess())
            const tvsItemsData = Papa.unparse(JSON.stringify({
              fields: response.data.headers,
              data: response.data.data
            }), {
              delimiter: "\t",
              header: true,
              skipEmptyLines: false
            })
            fileDownload(`${tvsItemsData}`, response.data.filename);
					} else {
						yield put(actions.getCategoryExportFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales category export api error"));
					yield put(actions.getCategoryExportFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_CATEGORY_EXPORT_ERROR"
            }
          });
        }
    })
}

export function* getProductExport() {
    yield takeLatest('GET_PRODUCT_EXPORT', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sales.product_export, data);
					if (response.status === 200 && !response.data.error) {
            yield put(actions.getProductExportSuccess())
            const tvsItemsData = Papa.unparse(JSON.stringify({
              fields: response.data.headers,
              data: response.data.data
            }), {
              delimiter: "\t",
              header: true,
              skipEmptyLines: false
            })
            fileDownload(`${tvsItemsData}`, response.data.filename);
					} else {
						yield put(actions.getProductExportFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Sales product export api error"));
					yield put(actions.getProductExportFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_PRODUCT_EXPORT_ERROR"
            }
          });
        }
    })
}

export default function* salesSaga() {
	yield all([
		fork(getSummary),
		fork(getCategorySales),
		fork(getProductSales),
		fork(getSalesChart),
		fork(getSummaryExport),
		fork(getCategoryExport),
		fork(getProductExport),
	]);
}
