const sopActions = {

	GET_SOP_CATEGORIES: 'GET_SOP_CATEGORIES',
	GET_SOP_CATEGORIES_SUCCESS: 'GET_SOP_CATEGORIES_SUCCESS',
	GET_SOP_CATEGORIES_FAILED: 'GET_SOP_CATEGORIES_FAILED',

	SAVE_SOP_CATEGORY: 'SAVE_SOP_CATEGORY',
	SAVE_SOP_CATEGORY_SUCCESS: 'SAVE_SOP_CATEGORY_SUCCESS',
	SAVE_SOP_CATEGORY_FAILED: 'SAVE_SOP_CATEGORY_FAILED',

	DELETE_SOP_CATEGORY: 'DELETE_SOP_CATEGORY',
	DELETE_SOP_CATEGORY_SUCCESS: 'DELETE_SOP_CATEGORY_SUCCESS',
	DELETE_SOP_CATEGORY_FAILED: 'DELETE_SOP_CATEGORY_FAILED',

	UPDATE_SOP_CATEGORY: 'UPDATE_SOP_CATEGORY',
	UPDATE_SOP_CATEGORY_SUCCESS: 'UPDATE_SOP_CATEGORY_SUCCESS',
	UPDATE_SOP_CATEGORY_FAILED: 'UPDATE_SOP_CATEGORY_FAILED',

	GET_SOP_TAGS: 'GET_SOP_TAGS',
	GET_SOP_TAGS_SUCCESS: 'GET_SOP_TAGS_SUCCESS',
	GET_SOP_TAGS_FAILED: 'GET_SOP_TAGS_FAILED',

	SAVE_SOP_TAG: 'SAVE_SOP_TAG',
	SAVE_SOP_TAG_SUCCESS: 'SAVE_SOP_TAG_SUCCESS',
	SAVE_SOP_TAG_FAILED: 'SAVE_SOP_TAG_FAILED',

	DELETE_SOP_TAG: 'DELETE_SOP_TAG',
	DELETE_SOP_TAG_SUCCESS: 'DELETE_SOP_TAG_SUCCESS',
	DELETE_SOP_TAG_FAILED: 'DELETE_SOP_TAG_FAILED',

	UPDATE_SOP_TAG: 'UPDATE_SOP_TAG',
	UPDATE_SOP_TAG_SUCCESS: 'UPDATE_SOP_TAG_SUCCESS',
	UPDATE_SOP_TAG_FAILED: 'UPDATE_SOP_TAG_FAILED',

	GET_SOP_PROCEDURES: 'GET_SOP_PROCEDURES',
	GET_SOP_PROCEDURES_SUCCESS: 'GET_SOP_PROCEDURES_SUCCESS',
	GET_SOP_PROCEDURES_FAILED: 'GET_SOP_PROCEDURES_FAILED',

	SAVE_SOP_PROCEDURE: 'SAVE_SOP_PROCEDURE',
	SAVE_SOP_PROCEDURE_SUCCESS: 'SAVE_SOP_PROCEDURE_SUCCESS',
	SAVE_SOP_PROCEDURE_FAILED: 'SAVE_SOP_PROCEDURE_FAILED',

	DELETE_SOP_PROCEDURE: 'DELETE_SOP_PROCEDURE',
	DELETE_SOP_PROCEDURE_SUCCESS: 'DELETE_SOP_PROCEDURE_SUCCESS',
	DELETE_SOP_PROCEDURE_FAILED: 'DELETE_SOP_PROCEDURE_FAILED',

	UPDATE_SOP_PROCEDURE: 'UPDATE_SOP_PROCEDURE',
	UPDATE_SOP_PROCEDURE_SUCCESS: 'UPDATE_SOP_PROCEDURE_SUCCESS',
	UPDATE_SOP_PROCEDURE_FAILED: 'UPDATE_SOP_PROCEDURE_FAILED',

	EXPORT_SOP_PROCEDURE_PDF: 'EXPORT_SOP_PROCEDURE_PDF',
	EXPORT_SOP_PROCEDURE_PDF_SUCCESS: 'EXPORT_SOP_PROCEDURE_PDF_SUCCESS',
	EXPORT_SOP_PROCEDURE_PDF_FAILED: 'EXPORT_SOP_PROCEDURE_PDF_FAILED',

	GET_SOP_PROCEDURE_SINGLE: 'GET_SOP_PROCEDURE_SINGLE',
	GET_SOP_PROCEDURE_SINGLE_SUCCESS: 'GET_SOP_PROCEDURE_SINGLE_SUCCESS',
	GET_SOP_PROCEDURE_SINGLE_FAILED: 'GET_SOP_PROCEDURE_SINGLE_FAILED',

	getSopProcedureByID: (data) => ({
		type: sopActions.GET_SOP_PROCEDURE_SINGLE,
		data
	}),
	getSopProcedureByIDSuccess: (data) => ({
		type: sopActions.GET_SOP_PROCEDURE_SINGLE_SUCCESS,
		data
	}),
	getSopProcedureByIDFailed: (data) => ({
		type: sopActions.GET_SOP_PROCEDURE_SINGLE_FAILED,
		data
	}),

	exportSopProcedurePdf: (data) => ({
		type: sopActions.EXPORT_SOP_PROCEDURE_PDF,
		data
	}),
	exportSopProcedurePdfSuccess: (data) => ({
		type: sopActions.EXPORT_SOP_PROCEDURE_PDF_SUCCESS,
		data
	}),
	exportSopProcedurePdfFailed: (data) => ({
		type: sopActions.EXPORT_SOP_PROCEDURE_PDF_FAILED,
		data
	}),

	updateSopProcedure: (data) => ({
		type: sopActions.UPDATE_SOP_PROCEDURE,
		data
	}),
	updateSopProcedureSuccess: (data) => ({
		type: sopActions.UPDATE_SOP_PROCEDURE_SUCCESS,
		data
	}),
	updateSopProcedureFailed: (data) => ({
		type: sopActions.UPDATE_SOP_PROCEDURE_FAILED,
		data
	}),


	deleteSopProcedure: (data) => ({
		type: sopActions.DELETE_SOP_PROCEDURE,
		data
	}),
	deleteSopProcedureSuccess: (data) => ({
		type: sopActions.DELETE_SOP_PROCEDURE_SUCCESS,
		data
	}),
	deleteSopProcedureFailed: (data) => ({
		type: sopActions.DELETE_SOP_PROCEDURE_FAILED,
		data
	}),

	saveSopProcedure: (data) => ({
		type: sopActions.SAVE_SOP_PROCEDURE,
		data
	}),
	saveSopProcedureSuccess: (data) => ({
		type: sopActions.SAVE_SOP_PROCEDURE_SUCCESS,
		data
	}),
	saveSopProcedureFailed: (data) => ({
		type: sopActions.SAVE_SOP_PROCEDURE_FAILED,
		data
	}),

	getSopProcedures: (data) => ({
		type: sopActions.GET_SOP_PROCEDURES,
		data
	}),
	getSopProceduresSuccess: (data) => ({
		type: sopActions.GET_SOP_PROCEDURES_SUCCESS,
		data
	}),
	getSopProceduresFailed: (data) => ({
		type: sopActions.GET_SOP_PROCEDURES_FAILED,
		data
	}),

	updateSopTag: (data) => ({
		type: sopActions.UPDATE_SOP_TAG,
		data
	}),
	updateSopTagSuccess: (data) => ({
		type: sopActions.UPDATE_SOP_TAG_SUCCESS,
		data
	}),
	updateSopTagFailed: (data) => ({
		type: sopActions.UPDATE_SOP_TAG_FAILED,
		data
	}),


	deleteSopTag: (data) => ({
		type: sopActions.DELETE_SOP_TAG,
		data
	}),
	deleteSopTagSuccess: (data) => ({
		type: sopActions.DELETE_SOP_TAG_SUCCESS,
		data
	}),
	deleteSopTagFailed: (data) => ({
		type: sopActions.DELETE_SOP_TAG_FAILED,
		data
	}),

	saveSopTag: (data) => ({
		type: sopActions.SAVE_SOP_TAG,
		data
	}),
	saveSopTagSuccess: (data) => ({
		type: sopActions.SAVE_SOP_TAG_SUCCESS,
		data
	}),
	saveSopTagFailed: (data) => ({
		type: sopActions.SAVE_SOP_TAG_FAILED,
		data
	}),

	getSopTags: (data) => ({
		type: sopActions.GET_SOP_TAGS,
		data
	}),
	getSopTagsSuccess: (data) => ({
		type: sopActions.GET_SOP_TAGS_SUCCESS,
		data
	}),
	getSopTagsFailed: (data) => ({
		type: sopActions.GET_SOP_TAGS_FAILED,
		data
	}),

	updateSopCategory: (data) => ({
		type: sopActions.UPDATE_SOP_CATEGORY,
		data
	}),
	updateSopCategorySuccess: (data) => ({
		type: sopActions.UPDATE_SOP_CATEGORY_SUCCESS,
		data
	}),
	updateSopCategoryFailed: (data) => ({
		type: sopActions.UPDATE_SOP_CATEGORY_FAILED,
		data
	}),


	deleteSopCategory: (data) => ({
		type: sopActions.DELETE_SOP_CATEGORY,
		data
	}),
	deleteSopCategorySuccess: (data) => ({
		type: sopActions.DELETE_SOP_CATEGORY_SUCCESS,
		data
	}),
	deleteSopCategoryFailed: (data) => ({
		type: sopActions.DELETE_SOP_CATEGORY_FAILED,
		data
	}),

	saveSopCategory: (data) => ({
		type: sopActions.SAVE_SOP_CATEGORY,
		data
	}),
	saveSopCategorySuccess: (data) => ({
		type: sopActions.SAVE_SOP_CATEGORY_SUCCESS,
		data
	}),
	saveSopCategoryFailed: (data) => ({
		type: sopActions.SAVE_SOP_CATEGORY_FAILED,
		data
	}),

	getSopCategories: (data) => ({
		type: sopActions.GET_SOP_CATEGORIES,
		data
	}),
	getSopCategoriesSuccess: (data) => ({
		type: sopActions.GET_SOP_CATEGORIES_SUCCESS,
		data
	}),
	getSopCategoriesFailed: (data) => ({
		type: sopActions.GET_SOP_CATEGORIES_FAILED,
		data
	}),

};

export default sopActions;
