import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input,
	Col, Label } from "reactstrap";
import Select from "react-select";
import PropTypes from 'prop-types';

const states = [
	{label: "Alabama", value: "AL"},
	{label: "Alaska", value: "AK"},
	{label: "Arizona", value: "AZ"},
	{label: "Arkansas", value: "AR"},
	{label: "California", value: "CA"},
	{label: "Colorado", value: "CO"},
	{label: "Connecticut", value: "CT"},
	{label: "Delaware", value: "DE"},
	{label: "Florida", value: "FL"},
	{label: "Georgia", value: "GA"},
	{label: "Hawaii", value: "HI"},
	{label: "Idaho", value: "ID"},
	{label: "Illinois", value: "IL"},
	{label: "Indiana", value: "IN"},
	{label: "Iowa", value: "IA"},
	{label: "Kansas", value: "KS"},
	{label: "Kentucky", value: "KY"},
	{label: "Louisiana", value: "LA"},
	{label: "Maine", value: "ME"},
	{label: "Maryland", value: "MD"},
	{label: "Massachusetts", value: "MA"},
	{label: "Michigan", value: "MI"},
	{label: "Minnesota", value: "MN"},
	{label: "Mississippi", value: "MS"},
	{label: "Missouri", value: "MO"},
	{label: "Montana", value: "MT"},
	{label: "Nebraska", value: "NE"},
	{label: "Nevada", value: "NV"},
	{label: "New", value: "mpshire - NH"},
	{label: "New", value: "rsey - NJ"},
	{label: "New", value: "xico - NM"},
	{label: "New", value: "rk - NY"},
	{label: "North", value: "rolina - NC"},
	{label: "North", value: "kota - ND"},
	{label: "Ohio", value: "OH"},
	{label: "Oklahoma", value: "OK"},
	{label: "Oregon", value: "OR"},
	{label: "Pennsylvania", value: "PA"},
	{label: "Rhode", value: "land - RI"},
	{label: "South", value: "rolina - SC"},
	{label: "South", value: "kota - SD"},
	{label: "Tennessee", value: "TN"},
	{label: "Texas", value: "TX"},
	{label: "Utah", value: "UT"},
	{label: "Vermont", value: "VT"},
	{label: "Virginia", value: "VA"},
	{label: "Washington", value: "WA"},
	{label: "West", value: "rginia - WV"},
	{label: "Wisconsin", value: "WI"},
	{label: "Wyoming", value: "WY"},
]

class EbayUpdateAddressModal extends Component {

	updateAddress = () => {
		const data = {
			order_item_id: this.props.order_item_id,
			data: {
				amazon_address: this.props.address_data,
				ebay_email: this.props.ebay_email,
			}
		}
		this.props.ebayEditOrderItemData(data);
		this.props.toggle();
	}

	render() {
		const {
			isOpen,
			toggle,
			updateAddressState,
			checkAddress,
			address_data,
			updateEmail,
			ebay_email,
		} = this.props;

		return (
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>Update Address</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label>Name</Label>
						<Input
							value={address_data.Name}
							onChange={e => updateAddressState('Name', e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Email</Label>
						<Input
							type="email"
							value={ebay_email}
							onChange={e => updateEmail(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Address Line 1</Label>
						<Input
							value={address_data.Line1}
							onChange={e => updateAddressState('Line1', e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Address Line 2</Label>
						<Input
							value={address_data.Line2}
							onChange={e => updateAddressState('Line2', e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Address Line 3</Label>
						<Input
							value={address_data.Line3}
							onChange={e => updateAddressState('Line3', e.target.value)}
						/>
					</FormGroup>
					<FormGroup row>
						<Col>
							<Label>District/County</Label>
							<Input
								value={address_data.DistrictOrCounty}
								onChange={e => updateAddressState('DistrictOrCounty', e.target.value)}
							/>
						</Col>
						<Col>
							<Label>City</Label>
							<Input
								value={address_data.City}
								onChange={e => updateAddressState('City', e.target.value)}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col>
							<Label>State</Label>
							<Select
								options={states}
								onChange={e => updateAddressState('StateOrProvinceCode', e ? e.value : "")}
								value={states.find(item => item.value === address_data.StateOrProvinceCode)}
							/>
						</Col>
						<Col>
							<Label>Country Code</Label>
							<Input
								value="US"
								disabled={true}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col>
							<Label>Postal Code</Label>
							<Input
								value={address_data.PostalCode}
								onChange={e => updateAddressState('PostalCode', e.target.value)}
							/>
						</Col>
						<Col>
							<Label>Phone Number</Label>
							<Input
								value={address_data.PhoneNumber}
								onChange={e => updateAddressState('PhoneNumber', e.target.value)}
							/>
						</Col>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle}>Cancel</Button>
					<Button
						color="success"
						disabled={!checkAddress() || this.props.ebay_order_item_updating}
						onClick={e => this.updateAddress()}
					>Save Changes</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

EbayUpdateAddressModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	address_data: PropTypes.object.isRequired,
	checkAddress: PropTypes.func.isRequired,
	updateAddressState: PropTypes.func.isRequired,
	ebayEditOrderItemData: PropTypes.func.isRequired,
	ebay_order_item_updating: PropTypes.bool.isRequired,
	order_item_id: PropTypes.number.isRequired,
};

export default EbayUpdateAddressModal;
