import React from "react";
import { Label, FormGroup, TabPane, Input } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import {getConditionOptions, getCategoryOptions, checkConditionIsUsedOrNew} from '../../../../../helpers/batch/utility';
//import SideBarNoteButtonPopover from "../shared/SideBarNoteButtonPopover";
import NoteButtonPopover from "../shared/NoteButtonPopover";
import TooltipAtCustomElement from "../../../../../shared/components/TooltipAtCustomElement";
/*
This component is used display the condition notes pane, where the user the user
can easily select their default condition and default condition note.
It is a child component used by the SideBar.

props field expects:
1) tabId: string corresponding to the tab number i.e. "1", "2"
2) batchListingDefaults data corresponding to defaults the user has selected
3) updateListingDefaultsData function that updates redux store with new form changes
*/

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const NotesTabPane = props => {
  let {
    tabId,
    conditionNotes,
    batchListingDefaults,
    updateListingDefaultsData,
    updateNote,
    userError
  } = props;
  let subcategories = new Set();
  conditionNotes.forEach(note => {
    if (note.category === batchListingDefaults.noteCategory) {
      subcategories.add(note.subcategory);
    }
  });
  subcategories.add("All Subcategories")

  let subCategoryOptions = Array.from(subcategories).map(subcategory => {
    return {
      label: subcategory,
      value: subcategory
    };
  });

  let filteredNotes = conditionNotes.filter(note => {
    let category = batchListingDefaults.noteCategory;
    let matchingCategory = category === note.category || category === "All Categories";
    let subcategory = batchListingDefaults.noteSubcategory;
    let matchingSubcategory = subcategory === note.subcategory || subcategory === "All Subcategories";
    if (!matchingCategory || !matchingSubcategory) {
      return false;
    }
    return true;
  }).sort((a, b) =>
    a.nickname.toLowerCase() > b.nickname.toLowerCase() ? 1 : -1,
  )
  
  const updateCondition = (event, userErrorCall) => {
    if(batchListingDefaults.listPriceRuleType === 'match_buy_box_price' || ["higher_than_buy_box", "lower_than_buy_box"].indexOf(batchListingDefaults.priceRuleDirection) > -1)
    {
      if(!event || !checkConditionIsUsedOrNew(event.value)) {
        userErrorCall("Invalid condition because the auto-price calculation requires a Used/New condition. Please change the auto-price rule first on the workflow tab.");
        return;
      }
    }
    updateListingDefaultsData("condition", event)
  }

  return (
    <TabPane tabId={tabId}>
      <FormGroup>
        <label><strong>Condition</strong></label>&nbsp;
        <TooltipAtCustomElement
          tooltipId="Condition"
          tooltipText="Condition"
          CustomElement={IconTooltip}
          styles={{ maxWidth: "20rem" }}
        />
        <br />
        <Select
          name="form-field-name"
          value={{
            value: batchListingDefaults.condition,
            label: batchListingDefaults.condition
          }}
          options={getConditionOptions().concat([{label: "NoDefault", value: "NoDefault"}])}
          onChange={ (event) => updateCondition(event, userError)}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <strong>Note Category</strong>&nbsp;
          <TooltipAtCustomElement
            tooltipId="NoteCategory"
            tooltipText="Note Category"
            CustomElement={IconTooltip}
            styles={{ maxWidth: "20rem" }}
          />
        </Label>
        <Select
          name="form-field-name"
          value={{
            value: batchListingDefaults.noteCategory,
            label: batchListingDefaults.noteCategory
          }}
          options={getCategoryOptions()}
          onChange={updateListingDefaultsData.bind(this, "noteCategory")}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <strong>Note SubCategory</strong>&nbsp;
          <TooltipAtCustomElement
            tooltipId="NoteSubCategory"
            tooltipText="Note SubCategory"
            CustomElement={IconTooltip}
            styles={{ maxWidth: "20rem" }}
          />
        </Label>
        <Select
          name="form-field-name"
          value={{
            value: batchListingDefaults.noteSubcategory,
            label: batchListingDefaults.noteSubcategory
          }}
          options={subCategoryOptions}
          onChange={updateListingDefaultsData.bind(this, "noteSubcategory")}
        />
      </FormGroup>
      <FormGroup>
        {filteredNotes && filteredNotes.length > 0 ? filteredNotes.map(note => {
          let category = batchListingDefaults.noteCategory;
          let matchingCategory = category === note.category || category === "All Categories";
          let subcategory = batchListingDefaults.noteSubcategory;
          let matchingSubcategory = subcategory === note.subcategory || subcategory === "All Subcategories";
          if (!matchingCategory || !matchingSubcategory) {
            return false;
          }
          return (
            <NoteButtonPopover
              key={note.id}
              content={note.note_text}
              id={String(note.id)}
              buttonText={note.nickname}
              buttonStyle="full-width light-bottom-margin note-btn-popover"
              placement="left"
              style={{ maxWidth: "60rem" }}
              action={() =>
                updateNote(`${note.note_text}`)
              }
            />
          );
        }) : 
          <p>No condition notes snippets found.</p>
        }
      </FormGroup>
      <FormGroup>
        <Label>
          <strong>Default Note</strong>&nbsp;
          <TooltipAtCustomElement
            tooltipId="DefaultNote"
            tooltipText="Default Note"
            CustomElement={IconTooltip}
            styles={{ maxWidth: "20rem" }}
          />
        </Label>
        <br />
        <Input
          type="textarea"
          className="full-width"
          rows="8"
          value={batchListingDefaults.note}
          onChange={updateListingDefaultsData.bind(this, "note")}
        />
      </FormGroup>
    </TabPane>
  );
};

NotesTabPane.propTypes = {
  tabId: PropTypes.string.isRequired,
  updateListingDefaultsData: PropTypes.func.isRequired,
  batchListingDefaults: PropTypes.object.isRequired,
  updateNote: PropTypes.func.isRequired,
  userError: PropTypes.func.isRequired
};

export default NotesTabPane;
