import { delay } from 'redux-saga';
import {
  all,
  takeEvery,
  takeLatest,
  put,
  fork,
  call,
  select,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import assign from 'lodash/assign';
import { logError } from "../../../helpers/utility";
import {
  createShipmentPlansAPI,
  shipmentPlanCreateStatusAPI,
  shipmentCreateStatusAPI
} from "../../../helpers/batch/apis";
import actions from "../actions";
import {
  batchIdSelector,
  batchMetadataSelector,
  productsSelector,
	userDataSelector,
	inboundShipmentPlansSelector,
} from "../selector";

import { setRouteForUserRole } from "../../../helpers/utility";

export function* createShipmentPlans() {
  yield takeEvery(actions.CREATE_SHIPMENT_PLANS, function*(payload) {
    try {
      let { products, params } = payload;
      let data = {
        products
      };
      data = assign(data, params);

      yield put(actions.setCreateShipmentPlansRequestStatusToExecution());
			const response = yield call(createShipmentPlansAPI, data);
      if (!response.status === 200 || response.data.error) {
        yield put(actions.createShipmentPlansFailure());
        yield put(actions.resetCreateShipmentPlansRequestStatus());
        yield put(
          actions.showAlert(
            "Error!",
            "Failed to create inbound shipment plans. Error: " +
              response.data.error
          )
        );
      } else {
        yield put(actions.createShipmentPlansStatus(response.data.job_id));
      }
    } catch (err) {
      yield put(actions.resetCreateShipmentPlansRequestStatus());
      yield put(actions.createShipmentPlansFailure());
      yield put(actions.showAlert("Error!", "Unable to Preview Shipment Plans"));
      logError(err, {
        tags: {
          exceptionType: actions.CREATE_SHIPMENT_PLANS_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* pollShipmentCreationStatus() {
  yield takeEvery(actions.CREATE_SHIPMENT_PLANS_STATUS, function*(payload) {
    try {
      let { jobId } = payload;
      let data = { job_id: jobId };
			const response = yield call(shipmentPlanCreateStatusAPI, data);
      if (!response.status === 200 || response.data.error) {
        if (response.data.error === "Job has not finished processing") {
          yield call(delay, 1000);
          yield put(actions.createShipmentPlansStatus(jobId))
        } else {
          yield put(actions.createShipmentPlansFailure());
          yield put(actions.resetCreateShipmentPlansRequestStatus());
          yield put(
            actions.showAlert(
              "Error!",
              "Failed to retrieve inbound shipment creation status. Error: " +
                response.data.error
            )
          );
        }
      } else {
        const batchMetadata = yield select(batchMetadataSelector);
        const inboundShipmentPlans = yield select(inboundShipmentPlansSelector);
        const newInboundShipmentPlansWithName = response.data.output.inbound_shipment_plans.map(plan => ({
          ...plan,
          Name: batchMetadata.batchName
        }))
        yield put(actions.createShipmentPlansSuccess(newInboundShipmentPlansWithName));
        yield put(actions.setCreateShipmentPlansRequestStatusToComplete());
        yield put(actions.setCurrentFlow('shipment_plans_display'));
				
				const shipment_locations = inboundShipmentPlans.map(item => { return item.DestinationFulfillmentCenterId; })
				const shipment_mileage_data = {
					codes: shipment_locations,
					batch_id: batchMetadata.id,
				}
        yield put(actions.batchShipmentsGetDistances(shipment_mileage_data));
      }
    } catch (err) {
      yield put(actions.resetCreateShipmentPlansRequestStatus());
      yield put(actions.createShipmentPlansFailure());
      yield put(actions.showAlert("Error!", "Failed to retrieve inbound shipment creation status."));
      logError(err, {
        tags: {
          exceptionType: "CREATE_SHIPMENT_PLANS_STATUS_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* createShipmentStatus() {
  yield takeEvery(actions.CREATE_SHIPMENT_STATUS, function*(payload) {
    try {
      let { job_id, batch_id, tracking_id, inboundShipmentPlans } = payload;
			let data = {
				job_id: job_id,
				batch_id: batch_id,
				tracking_id: tracking_id
			};
			const response = yield call(shipmentCreateStatusAPI, data);
      if (!response.status === 200 || response.data.error) {
          yield call(delay, 1000);
					yield put(actions.createShipmentStatus(
						job_id, batch_id, tracking_id, inboundShipmentPlans));
      } else {
				const batchMetadata = yield select(batchMetadataSelector);
				const products = yield select(productsSelector);
        let skus = new Set();

        inboundShipmentPlans.forEach(plan => {
          let items = plan.Items;
          items.forEach(item => {
            skus.add(item.SellerSKU);
          });
        });
        
        const shipmentName = inboundShipmentPlans[0].Name

        let shipmentProducts = products.filter(product =>
          skus.has(product.sku)
        );

        yield put(
          actions.submitProductFeed(shipmentProducts, batchMetadata, false, shipmentName)
        );
        let createdShipmentIds = inboundShipmentPlans.map(
          plan => plan.ShipmentId
        );
        yield put(
          actions.createShipmentSuccess(
            createdShipmentIds,
            response.data.existing_shipments
          )
				);
      }
    } catch (err) {
      yield put(actions.showAlert("Error!", "Failed to retrieve inbound shipment status."));
      logError(err, {
        tags: {
          exceptionType: "CREATE_SHIPMENT_STATUS_ERROR",
        }
      });
    }
  });
}

export function* rejectShipmentPlans() {
  yield takeLatest(actions.REJECT_SHIPMENT_PLANS, function*(payload) {
      let { batchId } = payload;
      yield put(actions.rejectShipmentPlansSuccess());
			const userData = yield select(userDataSelector);
		  yield put(push(setRouteForUserRole("/dashboard/batch/", userData) + batchId));
  });
}

export default function* rootSaga() {
  yield all([
    fork(createShipmentPlans),
    fork(pollShipmentCreationStatus),
    fork(rejectShipmentPlans),
		fork(createShipmentStatus),
  ]);
}
