const researchActions = {

	RESEARCH_DATA_IMPORT: 'RESEARCH_DATA_IMPORT',
	RESEARCH_DATA_IMPORT_SUCCESS: 'RESEARCH_DATA_IMPORT_SUCCESS',
	RESEARCH_DATA_IMPORT_FAILED: 'RESEARCH_DATA_IMPORT_FAILED',

	RESEARCH_DATA_LOAD: 'RESEARCH_DATA_LOAD',
	RESEARCH_DATA_LOAD_SUCCESS: 'RESEARCH_DATA_LOAD_SUCCESS',
	RESEARCH_DATA_LOAD_FAILED: 'RESEARCH_DATA_LOAD_FAILED',

	RESEARCH_LOAD_BATCH_DATA: 'RESEARCH_LOAD_BATCH_DATA',
	RESEARCH_LOAD_BATCH_DATA_SUCCESS: 'RESEARCH_LOAD_BATCH_DATA_SUCCESS',
	RESEARCH_LOAD_BATCH_DATA_FAILED: 'RESEARCH_LOAD_BATCH_DATA_FAILED',

	RESEARCH_MOVE_TO_BATCH: 'RESEARCH_MOVE_TO_BATCH',
	RESEARCH_MOVE_TO_BATCH_SUCCESS: 'RESEARCH_MOVE_TO_BATCH_SUCCESS',
	RESEARCH_MOVE_TO_BATCH_FAILED: 'RESEARCH_MOVE_TO_BATCH_FAILED',

	RESEARCH_DELETE_SELECTED: "RESEARCH_DELETE_SELECTED",
	RESEARCH_DELETE_SELECTED_SUCCESS: "RESEARCH_DELETE_SELECTED_SUCCESS",
	RESEARCH_DELETE_SELECTED_FAILED: "RESEARCH_DELETE_SELECTED_FAILED",

	RESEARCH_MANUAL_SEARCH: "RESEARCH_MANUAL_SEARCH",
	RESEARCH_MANUAL_SEARCH_SUCCESS: "RESEARCH_MANUAL_SEARCH_SUCCESS",
	RESEARCH_MANUAL_SEARCH_FAILED: "RESEARCH_MANUAL_SEARCH_FAILED",

	RESEARCH_MANUAL_SEARCH_RESULTS_DISPLAY_CLOSE: "RESEARCH_MANUAL_SEARCH_RESULTS_DISPLAY_CLOSE",

	RESEARCH_MANUAL_SEARCH_SELECT: "RESEARCH_MANUAL_SEARCH_SELECT",
	RESEARCH_MANUAL_SEARCH_SELECT_SUCCESS: "RESEARCH_MANUAL_SEARCH_SELECT_SUCCESS",
	RESEARCH_MANUAL_SEARCH_SELECT_FAILED: "RESEARCH_MANUAL_SEARCH_SELECT_FAILED",

	RESEARCH_DATA_UPDATE: "RESEARCH_DATA_UPDATE",
	RESEARCH_DATA_UPDATE_SUCCESS: "RESEARCH_DATA_UPDATE_SUCCESS",

	RESEARCH_MOVE_TO_BATCH_DATA_SET: "RESEARCH_MOVE_TO_BATCH_DATA_SET",
	RESEARCH_MOVE_TO_BATCH_DATA_SET_SUCCESS: "RESEARCH_MOVE_TO_BATCH_DATA_SET_SUCCESS",

	RESEARCH_EXPORT: "RESEARCH_EXPORT",
	RESEARCH_EXPORT_SUCCESS: "RESEARCH_EXPORT_SUCCESS",
	RESEARCH_EXPORT_FAILED: "RESEARCH_EXPORT_FAILED",

	RESEARCH_GET_STATUS: "RESEARCH_GET_STATUS",
	RESEARCH_GET_STATUS_SUCCESS: "RESEARCH_GET_STATUS_SUCCESS",
	RESEARCH_GET_STATUS_FAILED: "RESEARCH_GET_STATUS_FAILED",

	RESEARCH_GET_SKU_REPORT: "RESEARCH_GET_SKU_REPORT",
	RESEARCH_GET_SKU_REPORT_SUCCESS: "RESEARCH_GET_SKU_REPORT_SUCCESS",
	RESEARCH_GET_SKU_REPORT_FAILED: "RESEARCH_GET_SKU_REPORT_FAILED",

	RESEARCH_GET_SKU_REPORT_HISTORY: "RESEARCH_GET_SKU_REPORT_HISTORY",
	RESEARCH_GET_SKU_REPORT_HISTORY_SUCCESS: "RESEARCH_GET_SKU_REPORT_HISTORY_SUCCESS",
	RESEARCH_GET_SKU_REPORT_HISTORY_FAILED: "RESEARCH_GET_SKU_REPORT_HISTORY_FAILED",

	researchGetSKUReportHistory: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT_HISTORY,
		data
	}),
	researchGetSKUReportHistorySuccess: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT_HISTORY_SUCCESS,
		data
	}),
	researchGetSKUReportHistoryFailed: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT_HISTORY_FAILED,
		data
	}),

	researchGetSKUReport: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT,
		data
	}),
	researchGetSKUReportSuccess: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT_SUCCESS,
		data
	}),
	researchGetSKUReportFailed: (data) => ({
		type: researchActions.RESEARCH_GET_SKU_REPORT_FAILED,
		data
	}),

	researchGetStatus: (data) => ({
		type: researchActions.RESEARCH_GET_STATUS,
		data
	}),
	researchGetStatusSuccess: (data) => ({
		type: researchActions.RESEARCH_GET_STATUS_SUCCESS,
		data
	}),
	researchGetStatusFailed: (data) => ({
		type: researchActions.RESEARCH_GET_STATUS_FAILED,
		data
	}),

	researchExport: (data) => ({
		type: researchActions.RESEARCH_EXPORT,
		data
	}),
	researchExportSuccess: (data) => ({
		type: researchActions.RESEARCH_EXPORT_SUCCESS,
		data
	}),
	researchExportFailed: (data) => ({
		type: researchActions.RESEARCH_EXPORT_FAILED,
		data
	}),

	researchMoveToBatchDataSet: (data) => ({
		type: researchActions.RESEARCH_MOVE_TO_BATCH_DATA_SET,
		data
	}),
	researchMoveToBatchDataSetSuccess: (data) => ({
		type: researchActions.RESEARCH_MOVE_TO_BATCH_DATA_SET_SUCCESS,
		data
	}),

	researchDataUpdate: (data) => ({
		type: researchActions.RESEARCH_DATA_UPDATE,
		data
	}),
	researchDataUpdateSuccess: (data) => ({
		type: researchActions.RESEARCH_DATA_UPDATE_SUCCESS,
		data
	}),

	researchManualSearchSelect: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_SELECT,
		data
	}),
	researchManualSearchSelectSuccess: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_SELECT_SUCCESS,
		data
	}),
	researchManualSearchSelectFailed: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_SELECT_FAILED,
		data
	}),

	researchManualSearchResultsDisplayClose: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_RESULTS_DISPLAY_CLOSE,
		data
	}),

	researchManualSearch: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH,
		data
	}),
	researchManualSearchSuccess: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_SUCCESS,
		data
	}),
	researchManualSearchFailed: (data) => ({
		type: researchActions.RESEARCH_MANUAL_SEARCH_FAILED,
		data
	}),

	deleteSelectedResearch: (data) => ({
		type: researchActions.RESEARCH_DELETE_SELECTED,
		data
	}),
	deleteSelectedResearchSuccess: (data) => ({
		type: researchActions.RESEARCH_DELETE_SELECTED_SUCCESS,
		data
	}),
	deleteSelectedResearchFailed: (data) => ({
		type: researchActions.RESEARCH_DELETE_SELECTED_FAILED,
		data
	}),

	moveToBatch: (data) => ({
		type: researchActions.RESEARCH_MOVE_TO_BATCH,
		data
	}),
	moveToBatchSuccess: (data) => ({
		type: researchActions.RESEARCH_MOVE_TO_BATCH_SUCCESS,
		data
	}),
	moveToBatchFailed: (data) => ({
		type: researchActions.RESEARCH_MOVE_TO_BATCH_FAILED,
		data
	}),

	loadBatchData: (data) => ({
		type: researchActions.RESEARCH_LOAD_BATCH_DATA,
		data
	}),
	loadBatchDataSuccess: (data) => ({
		type: researchActions.RESEARCH_LOAD_BATCH_DATA_SUCCESS,
		data
	}),
	loadBatchDataFailed: (data) => ({
		type: researchActions.RESEARCH_LOAD_BATCH_DATA_FAILED,
		data
	}),

	loadResearchData: (data) => ({
		type: researchActions.RESEARCH_DATA_LOAD,
		data
	}),
	loadResearchDataSuccess: (data) => ({
		type: researchActions.RESEARCH_DATA_LOAD_SUCCESS,
		data
	}),
	loadResearchDataFailed: (data) => ({
		type: researchActions.RESEARCH_DATA_LOAD_FAILED,
		data
	}),

	uploadResearchData: (file, pricingType, buyCostValue) => ({
		type: researchActions.RESEARCH_DATA_IMPORT,
		file,
		pricingType,
		buyCostValue
	}),
	uploadResearchDataSuccess: (data) => ({
		type: researchActions.RESEARCH_DATA_IMPORT_SUCCESS,
		data
	}),
	uploadResearchDataFailed: (data) => ({
		type: researchActions.RESEARCH_DATA_IMPORT_FAILED,
		data
	}),

};

export default researchActions;
