import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from 'prop-types'

class ModalShowJson extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
			<Modal isOpen={this.props.isOpen}>
				<ModalHeader>{this.props.header ? this.props.header : "Details"}</ModalHeader>
				<ModalBody style={{ overflowY: "auto", maxHeight: "80vh" }}>
					<pre>{JSON.stringify(this.props.data, null, 2)}</pre>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={this.props.close}
					>Close</Button>
				</ModalFooter>
			</Modal>
    );
  }
}

ModalShowJson.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  header: PropTypes.string,
}

export default ModalShowJson;
