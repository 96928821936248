const orderActions = {

	GET_ORDERS: 'GET_ORDERS',
	GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
	GET_ORDERS_FAILED: 'GET_ORDERS_FAILED',

	GET_ORDER: 'GET_ORDER',
	GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
	GET_ORDER_FAILED: 'GET_ORDER_FAILED',

	getOrder: (data) => ({
		type: orderActions.GET_ORDER,
		data
	}),
	getOrderSuccess: (data) => ({
		type: orderActions.GET_ORDER_SUCCESS,
		data
	}),
	getOrderFailed: (data) => ({
		type: orderActions.GET_ORDER_FAILED,
		data
	}),

	getOrders: (data) => ({
		type: orderActions.GET_ORDERS,
		data
	}),
	getOrdersSuccess: (data) => ({
		type: orderActions.GET_ORDERS_SUCCESS,
		data
	}),
	getOrdersFailed: (data) => ({
		type: orderActions.GET_ORDERS_FAILED,
		data
	}),
};

export default orderActions;
