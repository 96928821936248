import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import Form from "./form";
import "../style.css";
import {
  stripeAPIKey
} from '../../../helpers/apiConfig';
import IconQuote from "react-icons/lib/fa/quote-left";
import { logoAccelerList, logoFba, logoAmazon, logoEbay, photoRickie, photoJacob } from "../../../assets/images";

const registrationPlans = [
  {
    plan_name: "default",
    billing_cycle_length: "monthly",
    plan_price: 34,
    trial_days: 14
  },
  {
    plan_name: "monthly",
    billing_cycle_length: "monthly",
    plan_price: 34,
    trial_days: 14
  },
  {
    plan_name: "annual",
    billing_cycle_length: "annual",
    plan_price: 340,
    trial_days: 14
  },
  {
    plan_name: "jimpickins",
    billing_cycle_length: "monthly",
    plan_price: 34,
    trial_days: 21
  }
];

class Register extends Component {
  state = {
    registrationPlanName: "default",
    billing_cycle_length: null,
    plan_price: null,
    trial_days: null
  };

  componentDidMount() {
    const { offer } = this.props.match.params;

    if (offer) {
      window.addEventListener('load', () => {
        let _fprom = window._fprom||[];
        window._fprom = _fprom;
        _fprom.push(["url_tracking", true]);
        if (window.$FPROM) {
          window.$FPROM.trackVisitor({url_tracking: true}, (error) => console.error(error))
        }
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { offer } = this.props.match.params;
    const indexOfSelectedPlan = registrationPlans.findIndex(el => el.plan_name === offer);
    if (
      offer &&
      indexOfSelectedPlan !== -1 &&
      this.state.registrationPlanName === "default"
    ) {
      this.setState({
        ...registrationPlans[indexOfSelectedPlan]
      });
    } else {
      this.setState({
        ...registrationPlans[0]
      });
    }
  }

  adjustTrialDays = (default_trial_days) => {
    if (this.props.location.state && this.props.location.state.cobranded) {
      if(this.props.location.state.cobranded === 'BBB') {
        return 30;
      }
    }
    return default_trial_days;
  }

  render() {
    const { plan_price, trial_days, billing_cycle_length, plan_name } = this.state;
    return (
      <div className="view register-page scroll-visible">
        <div className="left">
          <div className="testimonial">
            <blockquote className="text">
              <IconQuote size="48" className="icon-quote" />
              Honestly, your customer service is on point. I feel how a valued customer should feel, cared for by your business as an individual, not a number. #nowacustomerforlife.
            </blockquote>
            <div className="author">
              <span><img src={photoRickie} width="40" alt="" className="img-circle" /></span>
              <span className="name">Rickie Jacobson</span>
            </div>
          </div>
          <div className="testimonial">
            <blockquote className="text">
              <IconQuote size="48" className="icon-quote" />
              I had no idea you guys had rockstars working for you! Impressive... most impressive (Vader Voice)
            </blockquote>
            <div className="author">
              <span><img src={photoJacob} width="40" alt="" className="img-circle" /></span>
              <span className="name">Jacob Lyman</span>
            </div>
          </div>
          <div className="bottom">
            <strong className="d-block mb-4">26M+ products listed by our sellers since 2016</strong>
            <div className="logos">
              <img src={logoAccelerList} alt="" />
              <img src={logoFba} alt="" />
              <img src={logoAmazon} alt="" className="mb-0" />
              <img src={logoEbay} alt="" className="mb-0" />
            </div>
          </div>
        </div>
        <div className="right">
          <StripeProvider apiKey={stripeAPIKey}>
            <Elements>
              <Form
                billing_cycle_length={billing_cycle_length}
                plan_price={plan_price}
                trial_days={this.adjustTrialDays(trial_days)}
                plan_name={plan_name}
              />
            </Elements>
          </StripeProvider>
        </div>
      </div>
    );
  }
}

export default Register;
