import moment from "moment";

let now = moment.utc()
let thisMonth = moment().local().startOf('month');

export let configuration = [
  {
    stat: "current_inventory",
    key: "current_inventory",
    metricName: "Active Inventory",
    tooltipText: "Current active inventory items available for purchase on Amazon's marketplace.",
    minTimestamp: now.toISOString(),
    maxTimestamp: now.toISOString(),
    className: "blue",
    numberType: "decimal"
  },
  {
    stat: "average_sales_rank",
    key: "average_sales_rank",
    metricName: "Avg. Salesrank",
    tooltipText: "Avg. salesrank of new items listed through AccelerList.",
    minTimestamp: thisMonth.toISOString(),
    maxTimestamp: now.toISOString(),
    className: "blue",
    numberType: "decimal"
  },
  {
    stat: "ebay_percent_sales",
    key: "ebay_percent_sales",
    metricName: "eBay % Sales",
    tooltipText: "% of eBay sales versus Amazon for current month",
    minTimestamp: thisMonth.toISOString(),
    maxTimestamp: now.toISOString(),
    className: "blue",
    numberType: "decimal",
    isPercentage: true
  },
]
