import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import {
  bFrontItemListedCountAPI,
  bFrontItemListedCountEbayAPI,
  getFaqLandingAPI,
  optinSendgridAPI,
  getDesktopDownloadDataAPI,
  bFrontEbaySalesGraphAPI
} from "../../helpers/apis";

import actions from "./actions";

export function* FetchbFrontItemListedCount() {
  yield takeLatest(actions.FETCH_INVENTORY_ITEMS_COUNT, function*() {
    try {
  	  const count = yield call(bFrontItemListedCountAPI);
	  yield put(actions.bFrontItemListedCountSuccess(count.count))
    } catch (error) {
		//if api issue
		yield put(actions.bFrontItemListedCountSuccess(6258954))
    }
  });
}

export function* FetchbFrontItemListedCountEbay() {
  yield takeLatest(actions.FETCH_INVENTORY_ITEMS_EBAY_COUNT, function*() {
    try {
  	  const count = yield call(bFrontItemListedCountEbayAPI);
	  yield put(actions.bFrontItemListedCountEbaySuccess(count))
    } catch (error) {
		//if api issue
		yield put(actions.bFrontItemListedCountEbaySuccess(52843))
    }
  });
}

export function* FetchbFrontEbaySalesGraph() {
  yield takeLatest(actions.FETCH_EBAY_GRAPH_SALES, function*() {
    try {
  	  const data = yield call(bFrontEbaySalesGraphAPI);
	    yield put(actions.bEbayGraphSalesSuccess(data))
    } catch (error) {
		//if api issue
		yield put(actions.bEbayGraphSalesSuccess({}))
    }
  });
}

export function* getFaqLanding() {
  yield takeLatest(actions.GET_FAQ_LANDING, function*(payload) {
    try {
      const response = yield call(getFaqLandingAPI, payload.data.topic_type);
	    yield put(actions.getFaqLandingSuccess(response.data))
    } catch (error) {
		  //if api issue
	    yield put(actions.getFaqLandingFailed())
    }
  });
}

export function* optinSendgrid() {
  yield takeLatest(actions.OPTIN_SENDGRID, function*(payload) {
    try {
      const response = yield call(optinSendgridAPI, payload.data);
	    yield put(actions.optinSendgridSuccess(response.data))
    } catch (error) {
		  //if api issue
	    yield put(actions.optinSendgridFailed())
    }
  });
}

export function* getDesktopDownloadData() {
  yield takeLatest(actions.GET_DESKTOP_DOWNLOAD_DATA, function*(payload) {
    try {
      const response = yield call(getDesktopDownloadDataAPI, {});
	    yield put(actions.getDesktopDownloadDataSuccess(response.data))
    } catch (error) {
		  //if api issue
	    yield put(actions.getDesktopDownloadDataFailed())
    }
  });
}

export function* optinSendgridTogglePopup() {
  yield takeLatest(actions.OPTIN_SENDGRID_TOGGLE_POPUP, function*(payload) {
	  yield put(actions.optinSendgridTogglePopupSuccess(payload.data))
  });
}

export default function* rootSaga() {
  yield all([
    fork(FetchbFrontItemListedCount),
    fork(FetchbFrontItemListedCountEbay),
    fork(getFaqLanding),
    fork(optinSendgrid),
    fork(getDesktopDownloadData),
    fork(optinSendgridTogglePopup),
    fork(FetchbFrontEbaySalesGraph),
  ]);
}
