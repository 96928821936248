import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Row, Col, Button } from 'reactstrap';
import PredefinedList from './PredefinedList';
import repricerActions from '../../../../redux/repricer/actions';

const {
	userGetGlobalList,
	userAddGlobalList,
	userDeleteGlobalList,
	userUpdateGlobalList,
} = repricerActions;

class GlobalList extends Component {

	UNSAFE_componentWillMount(){
		this.props.userGetGlobalList('sku');
		this.props.userGetGlobalList('seller');
	}

  addList = (type) => {
    if(type === 'seller') {
      this.props.userAddGlobalList({
        list_name: `List ${Date.now()}`,
        type: type,
        datas: []
      });
    } else if(type === 'sku') {
      this.props.userAddGlobalList({
        list_name: `List ${Date.now()}`,
        type: type,
        datas: []
      });
    }
  }

  onListChanged = (item, list) => {
	  //dataSeller = dataSeller.filter((item,idx) => idx === index ? item.datas = list : item.datas);
	  item.datas = list;
	  item.listName = item.list_name;
	  this.props.userUpdateGlobalList({data: item});
  }

  onNameChanged = (item, name) => {
	  item.listName = name;
	  this.props.userUpdateGlobalList({data: item});
  }

  onDeleteList = (id) => {
	  this.props.userDeleteGlobalList({data: { globalListId: id }});
  }

  render() {
    let sortedList = [];
    if(!!this.props.predefinedSellerList && this.props.predefinedSellerList) {
      sortedList = this.props.predefinedSellerList;
      sortedList.sort((a, b) => a.list_name.localeCompare(b.list_name))
    }

    return (
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <CardTitle className="mb-0">Predefined Seller List</CardTitle>
                <Button color="primary" onClick={() => this.addList('seller')}>Add Seller List</Button>
              </div>

              {!!sortedList && sortedList.map((item, idx) =>(
                <PredefinedList
                  key={idx}
                  type="seller"
                  data={item}
                  onNameChanged={this.onNameChanged}
                  onDeleteList={this.onDeleteList}
				  onListChanged={(list) => this.onListChanged(item, list)}
                />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <CardTitle className="mb-0">Predefined SKU List</CardTitle>
                <Button color="primary" onClick={() => this.addList('sku')}>Add SKU List</Button>
              </div>

              {!!this.props.predefinedSkuList && this.props.predefinedSkuList.map((item, idx) =>(
                <PredefinedList
                  key={idx}
                  type="sku"
                  data={item}
                  onNameChanged={this.onNameChanged}
                  onDeleteList={this.onDeleteList}
				  onListChanged={(list) => this.onListChanged(item, list)}
                />
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => {
    return {
		userData: state.Auth.get('userData'),
		predefinedSkuList: state.Repricer.get("predefinedSkuList"),
		predefinedSellerList: state.Repricer.get("predefinedSellerList"),
    };
  },
  {
	  userGetGlobalList,
	  userAddGlobalList,
	  userDeleteGlobalList,
	  userUpdateGlobalList,
  }
)(GlobalList);
