import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import {
	getRepricerUserTemplatesAPI,
	getRepricerUserTemplateAPI,
	saveRepricerUserTemplateAPI,
	updateRepricerUserTemplateAPI,
	deleteRepricerUserTemplateAPI,
	saveRepricerUserScheduleAPI,
	getRepricerUserSchedulesAPI,
	getRepricerUserScheduleAPI,
	updateRepricerUserScheduleAPI,
	deleteRepricerUserScheduleAPI,
	getRepricerUserGlobalListAPI,
	saveRepricerUserGlobalListAPI,
	deleteRepricerUserGlobalListAPI,
	updateRepricerUserGlobalListAPI,
	getRepricerUserRepriceEventsAPI,
	getRepricerUserRepriceSummaryAPI,
	getTemplateToSKUMappingAPI,
	getTemplateQueryBySkusAPI,
	getTemplateCheckNameAPI,
	getReportInsightAPI,
	userEnableRepricerAPI,
} from "../../helpers/templates_apis";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import settingsActions from "../settings/actions";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

function handleError(data){
	return appActions.apiCallFailed(data.error);
}

export function* getRepricerUserTemplates() {
  yield takeLatest(actions.USER_LOAD_TEMPLATES, function*() {
    try {
		const response = yield call(getRepricerUserTemplatesAPI);
		if(!response.data.error){
			  yield put(actions.userLoadTemplatesSuccess(response.data));
		} else {
			yield put(actions.userLoadTemplatesFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_LOAD_TEMPLATES_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserTemplate() {
	yield takeLatest(actions.USER_LOAD_WORKING_TEMPLATE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(getRepricerUserTemplateAPI, data);
		if(!response.data.error){
			  yield put(actions.userLoadWorkingTemplateSuccess(response.data));
		} else {
			yield put(actions.userLoadWorkingTemplateFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_LOAD_WORKING_ERROR"
		    }
	      });
    }
  });
}

export function* saveRepricerUserTemplate() {
	yield takeLatest(actions.USER_SAVE_WORKING_TEMPLATE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(saveRepricerUserTemplateAPI, data);
		if(!response.data.error){
			  yield put(actions.userSaveWorkingTemplateSuccess(response.data));
			  yield put(actions.userLoadTemplates());
		} else {
			yield put(actions.userSaveWorkingTemplateFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_SAVE_WORKING_ERROR"
		    }
	      });
    }
  });
}

export function* updateRepricerUserTemplate() {
	yield takeLatest(actions.USER_UPDATE_WORKING_TEMPLATE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(updateRepricerUserTemplateAPI, data);
		if(!response.data.error){
			  yield put(actions.userUpdateWorkingTemplateSuccess(response.data));
			  yield put(actions.userLoadTemplates());
		} else {
			yield put(actions.userUpdateWorkingTemplateFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_UPDATE_WORKING_ERROR"
		    }
	      });
    }
  });
}

export function* deleteRepricerUserTemplate() {
	yield takeLatest(actions.USER_DELETE_WORKING_TEMPLATE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(deleteRepricerUserTemplateAPI, data);
		if(!response.data.error){
			  yield put(actions.userDeleteWorkingTemplateSuccess(response.data));
			  yield put(actions.userLoadTemplates());
		} else {
			yield put(actions.userDeleteWorkingTemplateFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_DELETE_WORKING_ERROR"
		    }
	      });
    }
  });
}

export function* updateWarningText() {
	yield takeLatest(actions.UPDATE_WARNING_TEXT, function*(payload) {
	const { data } = payload;
    try {
		yield put(actions.updateWarningTextExecute(data));
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "UPDATE_WARNING_TEXT_ERROR"
		    }
	      });
    }
  });
}

export function* saveRepricerUserSchedule() {
	yield takeLatest(actions.USER_SAVE_WORKING_SCHEDULE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(saveRepricerUserScheduleAPI, data);
		if(!response.data.error){
			  yield put(actions.userSaveWorkingScheduleSuccess(response.data));
			  yield put(actions.userLoadSchedules());
		} else {
			yield put(actions.userSaveWorkingScheduleFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_SAVE_WORKING_SCHEDULE_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserSchedules() {
  yield takeLatest(actions.USER_LOAD_SCHEDULES, function*() {
    try {
		const response = yield call(getRepricerUserSchedulesAPI);
		if(!response.data.error){
			  yield put(actions.userLoadSchedulesSuccess(response.data));
		} else {
			yield put(actions.userLoadSchedulesFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_LOAD_SCHEDULES_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserSchedule() {
	yield takeLatest(actions.USER_LOAD_WORKING_SCHEDULE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(getRepricerUserScheduleAPI, data);
		if(!response.data.error){
			  yield put(actions.userLoadWorkingScheduleSuccess(response.data));
		} else {
			yield put(actions.userLoadWorkingScheduleFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_LOAD_WORKING_SCHEDULE_ERROR"
		    }
	      });
    }
  });
}

export function* updateRepricerUserSchedule() {
	yield takeLatest(actions.USER_UPDATE_WORKING_SCHEDULE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(updateRepricerUserScheduleAPI, data);
		if(!response.data.error){
			  yield put(actions.userUpdateWorkingScheduleSuccess(response.data));
			  yield put(actions.userLoadSchedules());
		} else {
			yield put(actions.userUpdateWorkingScheduleFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_UPDATE_WORKING__SCHEDULE_ERROR"
		    }
	      });
    }
  });
}

export function* deleteRepricerUserSchedule() {
	yield takeLatest(actions.USER_DELETE_WORKING_SCHEDULE, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(deleteRepricerUserScheduleAPI, data);
		if(!response.data.error){
			  yield put(actions.userDeleteWorkingScheduleSuccess(response.data));
			  yield put(actions.userLoadSchedules());
		} else {
			yield put(actions.userDeleteWorkingScheduleFailed(response.data));
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_DELETE_WORKING_SCHEDULE_ERROR"
		    }
	      });
    }
  });
}

export function* updateScheduleWarningText() {
	yield takeLatest(actions.UPDATE_SCHEDULE_WARNING_TEXT, function*(payload) {
	const { data } = payload;
    try {
		yield put(actions.updateScheduleWarningTextExecute(data));
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "UPDATE_SCHEDULE_WARNING_TEXT_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserGlobalList() {
	yield takeLatest(actions.USER_GET_GLOBAL_LIST, function*(payload) {
    try {
		const response_sku = yield call(getRepricerUserGlobalListAPI, 'sku');
		const response_seller = yield call(getRepricerUserGlobalListAPI, 'seller');
		if(!response_sku.data.error && !response_seller.data.error){
			  yield put(actions.userGetGlobalListSuccess(response_sku.data));
			  yield put(actions.userGetGlobalListSuccess(response_seller.data));
		} else {
			yield put(actions.userGetGlobalListFailed());
			yield put(handleError(response_sku.data));
			yield put(handleError(response_seller.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_GET_GLOBAL_LIST_ERROR"
		    }
	      });
    }
  });
}

export function* saveRepricerUserGlobalList() {
	yield takeLatest(actions.USER_ADD_GLOBAL_LIST, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(saveRepricerUserGlobalListAPI, data);
		if(!response.data.error){
			  yield put(actions.userAddGlobalListSuccess(response.data));
		} else {
			yield put(actions.userAddGlobalListFailed());
			yield put(handleError({error: response.data['external-message'].error}));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_ADD_GLOBAL_LIST_ERROR"
		    }
	      });
    }
  });
}

export function* deleteRepricerUserGlobalList() {
	yield takeLatest(actions.USER_DELETE_GLOBAL_LIST, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(deleteRepricerUserGlobalListAPI, data);
		if(!response.data.error){
			  yield put(actions.userDeleteGlobalListSuccess(response.data));
		} else {
			yield put(actions.userDeleteGlobalListFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_DELETE_GLOBAL_LIST_ERROR"
		    }
	      });
    }
  });
}

export function* updateRepricerUserGlobalList() {
	yield takeLatest(actions.USER_UPDATE_GLOBAL_LIST, function*(payload) {
	const { data } = payload;
    try {
		const response = yield call(updateRepricerUserGlobalListAPI, data);
		if(!response.data.error){
			  yield put(actions.userUpdateGlobalListSuccess(response.data));
		} else {
			yield put(actions.userUpdateGlobalListFailed());
			yield put(handleError({error: response.data['external-message'].error}));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_UPDATE_GLOBAL_LIST_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserRepriceEvents() {
	yield takeLatest(actions.USER_LOAD_REPORTS, function*(payload) {
    try {
		const response = yield call(getRepricerUserRepriceEventsAPI);
		if(!response.data.error){
			  yield put(actions.userLoadReportsSuccess(response.data));
		} else {
			yield put(actions.userLoadReportsFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_LOAD_REPORTS_ERROR"
		    }
	      });
    }
  });
}

export function* getRepricerUserRepriceSummary() {
	yield takeLatest(actions.USER_REPRICER_REPORT_SUMMARY, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(getRepricerUserRepriceSummaryAPI, data);
		if(!response.data.error){
			  yield put(actions.userRepricerReportSummarySuccess(response.data));
		} else {
			yield put(actions.userRepricerReportSummaryFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "USER_REPRICER_REPORT_SUMMARY_ERROR"
		    }
	      });
    }
  });
}

export function* getTemplateToSKUMapping() {
	yield takeLatest(actions.TEMPLATE_TO_SKU_MAPPING, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(getTemplateToSKUMappingAPI, data);
		if(!response.data.error){
			  yield put(actions.templateToSkuMappingSuccess(response.data));
		} else {
			yield put(actions.templateToSkuMappingFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "TEMPLATE_TO_SKU_MAPPING_ERROR"
		    }
	      });
    }
  });
}

export function* getTemplateQueryBySkus() {
	yield takeLatest(actions.TEMPLATE_QUERY_BY_SKUS, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(getTemplateQueryBySkusAPI, data);
		if(!response.data.error){
			  yield put(actions.templateQueryBySkusSuccess(response.data));
		} else {
			yield put(actions.templateQueryBySkusFailed());
			yield put(handleError(response.data));
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "TEMPLATE_QUERY_BY_SKUS_ERROR"
		    }
	      });
    }
  });
}

export function* updateWarning() {
	yield takeLatest(actions.UPDATE_WARNING, function*(payload) {
	const { data } = payload;
    try {
		yield put(actions.updateWarningExecute(data));
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Schedule api error"));
		logError(error, {
			tags: {
	          exceptionType: "UPDATE_WARNING_ERROR"
		    }
	      });
    }
  });
}

export function* templateCheckName() {
	yield takeLatest(actions.TEMPLATE_CHECK_NAME, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(getTemplateCheckNameAPI, data);
		if(!response.data.error){
			  yield put(actions.templateCheckNameSuccess());
		} else {
			yield put(actions.templateCheckNameFailed());
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "TEMPLATE_CHECK_NAME_ERROR"
		    }
	      });
    }
  });
}

export function* reportInsightRun() {
	yield takeLatest(actions.REPORT_INSIGHT_RUN, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(getReportInsightAPI, data);
		if(!response.data.error){
			yield put(actions.reportInsightRunSuccess(response.data.response));
		} else {
			yield put(actions.reportInsightRunFailed());
		}
    } catch (error) {
		yield put(appActions.apiCallFailed("Error! Template api error"));
		logError(error, {
			tags: {
	          exceptionType: "REPORT_INSIGHT_RUN_ERROR"
		    }
	      });
    }
  });
}

export function* userEnableRepricer() {
    yield takeLatest('USER_SELF_ENABLE_REPRICER', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(userEnableRepricerAPI, data)
					if (response.status === 200 && !response.data.error) {
						yield put(settingsActions.updateUserDataSettings(
							{ is_repricer_enabled: data.is_repricer_enabled	}));
						yield put(actions.userEnableRepricerSuccess(data))
					} else {
						yield put(actions.userEnableRepricerFailed());
						if(response.data.type && response.data.type === 'limit'){
							yield put(appActions.apiCallFailed(response.data.error));
						}
						//as below, but just failsafe
						window.location.reload();
					}
     } catch (error) {
          yield put(appActions.apiCallFailed("Error! Repricer api error"));
          yield put(actions.userEnableRepricerFailed());
          logError(error, {
            tags: {
              exceptionType: "USER_SELF_ENABLE_REPRICER_ERROR"
            }
          });
					//try to handle isses on repricer workflow where repricer
					//is enabled and subscription iscreated but timeout prevent
					//frontend update [look like reloading APP should do the trick]
					//as this is action that is performed one time this approach will not
					//break APP workflow
					window.location.reload();
		 }
    })
}

export function* getRepricerDashboardRepricing() {
    yield takeLatest('GET_REPRICER_DASHBOARD_REPRICING', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.repricer.stats_dashboard_repricing, {});
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getRepricerDashboardRepricingSuccess(response.data))
					} else {
						yield put(actions.getRepricerDashboardRepricingFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Repricer api error"));
					yield put(actions.getRepricerDashboardRepricingFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_REPRICER_DASHBOARD_REPRICING_ERROR"
            }
          });
        }
    })
}

export function* templateUpdateName() {
	yield takeLatest(actions.TEMPLATE_UPDATE_NAME, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(backendAPICall, 'post', apiEndpoints.repricer.template_update_name, data);
		if(!response.data.error){
			  yield put(actions.templateUpdateNameSuccess(response.data));
		} else {
			yield put(actions.templateUpdateNameFailed());
			yield put(appActions.apiCallFailed('Error: ' + response.data.error));
		}
    } catch (error) {
			yield put(appActions.apiCallFailed("Error! Template api error"));
			yield put(actions.templateUpdateNameFailed());
			logError(error, {
				tags: {
	        exceptionType: "TEMPLATE_UPDATE_NAME_ERROR"
				}
	    });
    }
  });
}

export function* templateAdminTest() {
	yield takeLatest(actions.TEMPLATE_ADMIN_TEST, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(backendAPICall, 'post', apiEndpoints.repricer.template_test, data);
		if(!response.data.error){
			  yield put(actions.templateAdminTestSuccess(response.data));
		} else {
			yield put(actions.templateAdminTestFailed());
		}
    } catch (error) {
			yield put(appActions.apiCallFailed("Error! Template api error"));
			yield put(actions.templateAdminTestFailed());
			logError(error, {
				tags: {
	        exceptionType: "TEMPLATE_ADMIN_TEST_ERROR"
				}
	    });
    }
  });
}

export function* getReportRepriceMaster() {
	yield takeLatest(actions.GET_REPORT_REPRICE_MASTER, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(backendAPICall, 'get', apiEndpoints.repricer.report_reprice_master, {});
		if(!response.data.error){
			  yield put(actions.getReportRepriceMasterSuccess(response.data));
		} else {
			yield put(actions.getReportRepriceMasterFailed());
			yield put(appActions.apiCallFailed(response.data.error));
		}
    } catch (error) {
			yield put(appActions.apiCallFailed("Error! Template api error"));
			yield put(actions.getReportRepriceMasterFailed());
			logError(error, {
				tags: {
	        exceptionType: "GET_REPORT_REPRICE_MASTER_ERROR"
				}
	    });
    }
  });
}

export default function* repricerSaga() {
	yield all([
		fork(getRepricerUserTemplates),
		fork(getRepricerUserTemplate),
		fork(saveRepricerUserTemplate),
		fork(updateRepricerUserTemplate),
		fork(deleteRepricerUserTemplate),
		fork(updateWarningText),
		fork(saveRepricerUserSchedule),
		fork(getRepricerUserSchedules),
		fork(getRepricerUserSchedule),
		fork(updateRepricerUserSchedule),
		fork(deleteRepricerUserSchedule),
		fork(updateScheduleWarningText),
		fork(getRepricerUserGlobalList),
		fork(saveRepricerUserGlobalList),
		fork(deleteRepricerUserGlobalList),
		fork(updateRepricerUserGlobalList),
		fork(getRepricerUserRepriceEvents),
		fork(getRepricerUserRepriceSummary),
		fork(getTemplateToSKUMapping),
		fork(getTemplateQueryBySkus),
		fork(updateWarning),
		fork(templateCheckName),
		fork(reportInsightRun),
		fork(userEnableRepricer),
		fork(getRepricerDashboardRepricing),
		fork(templateUpdateName),
		fork(templateAdminTest),
		fork(getReportRepriceMaster),
	]);
}
