import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Label, Input, Collapse
} from 'reactstrap';
import InputAmountType from '../../../../../../../shared/components/Form/InputAmountType';
import repricerActions from '../../../../../../../redux/repricer/actions';
import AlertTooltip from '../../../components/AlertTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class Scenario1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repriceAmountType: '$',
		repriceType: 'none',
		repriceTypeCustom: '',
		use_if_you_are_the_buy_box_repricing_strategy: false,
		fix_price_adjustment_if_true_percentage_if_false: false,
		adjustment_amount_on_competing_offer: 0,
		static_method_adjust_up_if_true_down_if_false: false,
		competing_offer_to_reprice_against: "",
		repriceAmount: 0,
		repriceUpDown: "below",
    }
  }

	UNSAFE_componentWillMount(){
		var repriceTypeCustom;
		if(
			this.props.workingTemplate.template_json.offers_targeting_competing_offer_to_reprice_against === "lowest_offer_eligible_for_buy_box"
				&& this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy
				&& !this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice
		){
			repriceTypeCustom = "normal";
		}
		if(
			this.props.workingTemplate.template_json.offers_targeting_competing_offer_to_reprice_against === "lowest_offer_eligible_for_buy_box_higher_than_current_price"
				&& this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy
				&& !this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice
		){
			repriceTypeCustom = "over_current";
		}

		var repriceType;
		if(!this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy){
			repriceType = 'none';
		}
		if(
			this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy
				&& this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice
		){
			repriceType = 'regular';
		}
		if(
			this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy
				&& !this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice
		){
			repriceType = 'custom';
		}

		this.setState({
			repriceAmountType: this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			repriceAmount: this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_adjustment_amount_on_competing_offer,
			repriceTypeCustom: repriceTypeCustom,
			repriceType: repriceType,
			use_if_you_are_the_buy_box_repricing_strategy: this.props.workingTemplate.template_json.pricing_rules_use_if_you_are_the_buy_box_repricing_strategy,
			fix_price_adjustment_if_true_percentage_if_false: this.props.workingTemplate.template_json.pricing_rules_adjustment_fix_price_adjustment_if_true_percentage_if_false,
			adjustment_amount_on_competing_offer: this.props.workingTemplate.template_json.pricing_rules_adjustment_adjustment_amount_on_competing_offer,
			static_method_adjust_up_if_true_down_if_false: this.props.workingTemplate.template_json.pricing_rules_adjustment_static_method_adjust_up_if_true_down_if_false,
			competing_offer_to_reprice_against: this.props.workingTemplate.template_json.offers_targeting_competing_offer_to_reprice_against,
			repriceUpDown: this.props.workingTemplate.template_json.pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_static_method_adjust_up_if_true_down_if_false ? "above" : "below",
		});
	}

	updateRepriceUpDown = (e) => {
		this.setState({ repriceUpDown: e.target.value });
		this.props.workingTemplateUpdateJson(
			{
				data: {
					pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_static_method_adjust_up_if_true_down_if_false: e.target.value === "above" ? true : false,
				}
			})
	}

	updateRepriceAmount = (amount) => {
		this.setState({ repriceAmount: amount });
	}

  toggleRepriceAmountType = () => {
    const currentType = this.state.repriceAmountType;
    this.setState({
      repriceAmountType: currentType === '$' ? '%' : '$'
    });
  }

  toggleRepriceType = (type) => {
	var data = {};
	this.setState({ repriceType: type });
	if(type === "none"){
		data = {
			pricing_rules_use_if_you_are_the_buy_box_repricing_strategy: false,
		}
	}
	if(type === "regular"){
		data = {
			pricing_rules_use_if_you_are_the_buy_box_repricing_strategy: true,
			pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice: true,
		}
	}
	if(type === "custom"){
		data = {
			pricing_rules_use_if_you_are_the_buy_box_repricing_strategy: true,
			pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice: false,
			offers_targeting_competing_offer_to_reprice_against: "",
		}
		this.setState({ repriceTypeCustom: null });
	}

	this.props.workingTemplateUpdateJson({ data: data })
  }

  toggleRepriceTypeCustom = (type) => {
	var data = {};
	this.setState({ repriceTypeCustom: type });
	if(type === "normal"){
		data = {
			pricing_rules_use_if_you_are_the_buy_box_repricing_strategy: true,
			pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice: false,
			offers_targeting_competing_offer_to_reprice_against: "lowest_offer_eligible_for_buy_box",
		}
	}
	if(type === "over_current"){
		data = {
			pricing_rules_use_if_you_are_the_buy_box_repricing_strategy: true,
			pricing_rules_if_you_are_the_buy_box_adjustment_dont_reprice: false,
			offers_targeting_competing_offer_to_reprice_against: "lowest_offer_eligible_for_buy_box_higher_than_current_price",
		}
	}

	this.props.workingTemplateUpdateJson({ data: data })
  }

  render() {
    return (
      <Fragment>
        <FormGroup>
          <FormGroup check className="custom-check radio">
            <Label check>
				<Input
					type="radio"
					name="sc1_repriceType"
					checked={this.state.repriceType === 'none'}
					onChange={() => this.toggleRepriceType('none')}
				/> <span>Do not reprice</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="sc1_repriceType"
					checked={this.state.repriceType === 'regular'}
					onChange={() => this.toggleRepriceType('regular')}
				/> <span>Use regular repricing strategy</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name="sc1_repriceType"
					checked={this.state.repriceType === 'custom'}
					onChange={() => this.toggleRepriceType('custom')}
				/> <span>Use custom repricing strategy</span>
            </Label>
          </FormGroup>
        </FormGroup>

        <Collapse isOpen={this.state.repriceType === 'custom'}>
          <div className="custom-repricing">
            <FormGroup check className="custom-check custom-check-block radio">
              <Label check>
				  <Input
					  type="radio"
					  name="repriceCustomType"
					  checked={this.state.repriceTypeCustom === "over_current"}
					  onChange={() => this.toggleRepriceTypeCustom('over_current')}
				  /> <span>Compete against the lowest eligible Buy Box offer higher than current price</span>
              </Label>
              <Label check>
				  <Input
					  type="radio"
					  name="repriceCustomType"
					  checked={this.state.repriceTypeCustom === "normal"}
					  onChange={() => this.toggleRepriceTypeCustom('normal')}
				  /> <span>Compete against the lowest eligible Buy Box offer</span>
              </Label>
            </FormGroup>

            <FormGroup className="d-flex align-items-center mb-0">
              <span>Againts that offer, reprice </span>
              <InputAmountType
                amountType={this.state.repriceAmountType}
                toggle={this.toggleRepriceAmountType}
				className="ml-2"
				updateAmount={this.updateRepriceAmount}
				updateTemplateJson={this.props.workingTemplateUpdateJson}
				dataInTemplateJson="pricing_rules_if_you_are_the_buy_box_adjustment_adjustment_adjustment_amount_on_competing_offer"
				typeDataInTemplateJson="pricing_rules_if_you_are_the_buy_box_adjustment_fix_price_adjustment_if_true_percentage_if_false"
				value={this.state.repriceAmount ? this.state.repriceAmount.toString() : ""}
				min={0}
              />
			  <Input
				  type="select"
				  className="mx-2 w-auto"
				  onChange={e => this.updateRepriceUpDown(e)}
				  value={this.state.repriceUpDown}
			  >
				<option value="above">above</option>
				<option value="below">below</option>
              </Input>
              <span>the competing offer.</span>
							<AlertTooltip
								target="alert_repriceCustomType"
								message={this.props.templateWarning}
								range={[19, 20]}
							/>
            </FormGroup>
          </div>
        </Collapse>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
  }),
  {
	workingTemplateUpdateJson,
  }
)(Scenario1);
