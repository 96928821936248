import React from "react";
class TableGeneratorLeftArrow extends React.Component {
  
  render() {
    return (
      <svg className="arrow left-arrow" width="93" height="131" viewBox="263 450 93 131" xmlns="http://www.w3.org/2000/svg">
          <path d="M270.48525635 487.10058818c-.60079297 1.05820033-1.61792509 1.88236141-2.44238212 2.81506279-.45521164-.31300778-.91275629-.62458278-1.36796793-.93759056 3.61277787-8.54805225 7.20008664-17.10616903 10.84984749-25.6390157 1.16996425-2.73078758 1.45954887-6.05333785 4.72369391-7.47117915.26772624-.11562688.55611513-.17773998.39217088-.12651437 5.73843117 5.3498335 11.2577287 10.51353145 16.7973862 15.65700697 2.09403637 1.94363482 4.28589157 3.7828197 6.32452166 5.78170675.75468267.74291007 1.1954885 1.80464654 1.7809337 2.72095345-.32174503.28509079-.64226965.5700758-.96268866.85627925-6.7052086-5.60825065-13.41285787-11.21628777-20.6909188-17.30182728 1.0530269 7.77840684 1.96787645 14.77840757 2.95306163 21.767336 1.91196663 13.57726974 5.06487684 26.8369747 10.83572628 39.33039827 7.9638157 17.24001118 20.5003092 30.24304826 37.21040003 39.21122249 6.07582843 3.26232178 12.2384649 6.36460474 18.3954727 9.55712566-1.93707835-.45874416-4.0725399-.68376067-5.98214825-1.46086044-17.11155572-6.95250905-32.85349361-15.87138276-44.2908838-30.98185544-7.68050979-10.15156423-12.53923359-21.60604631-16.02553406-33.73013603-3.54481527-12.3291953-4.84269588-25.00003893-5.76704354-37.72938072-.07230612-1.00914721-.17400116-2.01695291-.26238222-3.02715311-.25573596-.084582-.51136524-.16794466-.76731573-.25496726-3.89013768 6.9937843-7.75183276 14.00352137-11.70394937 20.96338843z" fill="#BAC5B4" fillRule="evenodd"/>
      </svg>
    );
  }
}

TableGeneratorLeftArrow.propTypes = {
};

export default TableGeneratorLeftArrow;
