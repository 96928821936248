export let configuration = [
  {
    stat: "yesterday_sales",
    key: "yesterday_sales",
    metricName: "Yesterday's Sales",
    tooltipText: "Sales on yesterday",
    className: "blue",
    numberType: "decimal"
  },
  {
    stat: "yesterday_reprice_events",
    key: "yesterday_reprice_events",
    metricName: "Yesterday's Reprice Events",
    tooltipText: "Reprice events happened yesterday",
    className: "blue",
    numberType: "decimal"
  },
  {
    stat: "templates_running",
    key: "templates_running",
    metricName: "Templates Running",
    tooltipText: "Currently running templates",
    className: "blue",
    numberType: "decimal"
  }
]