import React, { Component } from 'react';
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, Card, CardBody, TabContent, TabPane } from 'reactstrap';
import EmployeeTrackingTable from './EmployeeTrackingTable';
import employeesActions from "../../../redux/employees/actions";
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const { getEmployeesTrackingData } = employeesActions;

const ViewHeader = () => (
	<header className="view-header">
		<h1 className="page-title">Employee Reporting</h1>
	</header>
);

const ViewContent = ({ children }) => (
	<div className="view-content view-components">
		<Card>
			<CardBody>{children}</CardBody>
		</Card>
	</div>
);

class TeamReporting extends Component {
	state = {
		activeTab: "1"
  }

  componentDidMount() {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Team Reporting"})
    this.props.getEmployeesTrackingData();
  }

	toggleTab = (id) => {
		this.setState({
			activeTab: id
		})
	}

	render() {
		const {
      activeTab,
		} = this.state;

		const {
      employees_tracking,
		} = this.props;

		return (
			<div className="view settings">
				<ViewHeader />
				<ViewContent>
					<Nav pills>
						<NavItem>
							<NavLink
								href="#"
								active={activeTab === "1"}
								onClick={
									// prevent table rerender and collapsing all rows
									() => activeTab !== "1" && this.toggleTab("1")
								}
							>
								Employee Tracking
							</NavLink>
						</NavItem>
            {/*
						<NavItem>
							<NavLink
								href="#"
								active={activeTab === "2"}
								onClick={() => this.toggleTab("2")}
							>
								Payouts
							</NavLink>
            </NavItem>
            */}
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
              <EmployeeTrackingTable
                employees_tracking={employees_tracking}
              />
						</TabPane>
						<TabPane tabId="2">

						</TabPane>
					</TabContent>
				</ViewContent>
			</div>
		)
	}
}

export default connect(
	state => {
		return {
      employees_tracking: state.Employees.get("employees_tracking"),
		};
	},
	{
    getEmployeesTrackingData,
    userPageviewCapture,
	}
)(TeamReporting);
