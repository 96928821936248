import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Input, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { Button } from 'reactstrap';
import fulfillmentActions from "../../../../../redux/fulfillment/actions";
import batchActions from "../../../../../redux/batch/actions";

const {
  batchMetadataUpdate,
} = batchActions;

const {
  fulfillmentShipmentsTrackingGet,
    fulfillmentShipmentsTrackingUpdate,
} = fulfillmentActions;

class InboundTrackingNumbers extends Component {
  state = {
    selectedShipment: null,
    tracking_numbers: {},
  }

  componentDidMount = () => {
    this.props.fulfillmentShipmentsTrackingGet({
      batch: this.props.batchMetadata.id
    })
  }

  setTrackingNumber = (shipment_id, box_id, tracking_id) => {
    var tracking_numbers = {...this.state.tracking_numbers}
    if(!tracking_numbers[shipment_id]){ tracking_numbers[shipment_id] = {} }
    tracking_numbers[shipment_id][box_id] = tracking_id;
    this.setState({tracking_numbers: {...tracking_numbers}})
  }

  saveShipmentTracking = (shipment_id) => {
    const { tracking_numbers } = this.state;

    this.props.fulfillmentShipmentsTrackingUpdate({
      batch_id: this.props.batchMetadata.id,
      shipments:[
        {
          id: shipment_id,
          boxes: Object.keys(tracking_numbers[shipment_id]).map(key => {
            return {
              id: key,
              tracking_id: tracking_numbers[shipment_id][key],
            }
          }),
        }
      ],
    });
  }

  getTrackingId = (boxId, shipmentId) => {
    const { batchMetadata } = this.props;
    var tracking = batchMetadata?.inbound_shipments_data?.find(
      item => item.shipmentId === shipmentId)?.trackingDetails?.spdTrackingDetail?.spdTrackingItems?.find(
        item => item.boxId === boxId);
    if(!tracking){
      tracking = batchMetadata?.inbound_shipments_data?.find(
        item => item.shipmentId === shipmentId)?.trackingDetails?.ltlTrackingDetail?.ltlTrackingItems?.find(
          item => item.boxId === boxId);
    }
    return tracking?.trackingId ? tracking?.trackingId : "";
  }

  render() {
    const {
      onSave,
      batchMetadata,
      shipments_tracking,
      fulfillment_inbound_working,
    } = this.props;
    const { selectedShipment, tracking_numbers } = this.state;

    return (
      <div>
        <div className="d-flex align-items-end justify-content-between gap-2">
          <FormGroup className="mb-0" style={{flex: 1}}>
            <Label>Select Shipment</Label>
            <div style={{maxWidth: 300}}>
              <Select
                options={batchMetadata?.inbound_shipments_data?.map(item => {
                  return {
                    value: item.shipmentId,
                    label: item.name,
                  }
                })}
                value={selectedShipment}
                onChange={selected => this.setState({ selectedShipment: selected })}
                placeholder="Select a shipment..."
                clearable={false}
              />
            </div>
          </FormGroup>
          {batchMetadata?.status === "In Progress" &&
          <Button
            color="success"
            onClick={() => this.props.batchMetadataUpdate({ status: "Completed"})}
          >
            Complete Batch
          </Button>
          }
        </div>

        {selectedShipment && (
          <div className="mt-4">
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Box</th>
                  <th>
                    <span>Tracking Numbers</span>
                    <span className="text-warning ml-1" style={{ fontWeight: "normal", opacity: .7 }}>(UPS tracking numbers are auto-imported)</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {shipments_tracking?.[selectedShipment.value]?.boxes?.map(item => (
                  <tr key={`${selectedShipment.value}-${item.boxId}`}>
                    <td style={{verticalAlign: 'middle'}}>{item.boxId}</td>
                    <td style={{verticalAlign: 'middle'}}>
                      <Input
                        type="text"
                        placeholder="Enter tracking number"
                        value={
                          tracking_numbers?.[selectedShipment.value]?.[item.boxId]
                            ? tracking_numbers[selectedShipment.value][item.boxId]
                            : this.getTrackingId(item.boxId, selectedShipment.value)}
                        onChange={(e) => this.setTrackingNumber(selectedShipment.value, item.boxId, e.target.value)}
                        disabled={this.getTrackingId(item.boxId, selectedShipment.value) ? true : false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {batchMetadata?.inbound_tracking_data_confirmed?.[selectedShipment.value] === true &&
            <Button
              color="success"
              onClick={() => this.saveShipmentTracking(selectedShipment.value)}
              disabled={
                fulfillment_inbound_working
                  || batchMetadata?.inbound_tracking_data_confirmed?.[selectedShipment.value] === true
              }
            >
              Save
            </Button>
            }
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
		userData: state.Auth.get('userData'),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    shipments_tracking: state.Fulfillment.get("shipments_tracking"),
  }),
  {
    fulfillmentShipmentsTrackingGet,
    fulfillmentShipmentsTrackingUpdate,
	  batchMetadataUpdate,
  }
)(InboundTrackingNumbers);
