import React, { Component } from "react";
import { Modal, ModalBody, } from "reactstrap";
import { Elements, StripeProvider, } from 'react-stripe-elements';
import StripeCardReplaceForm from "../StripeCardReplaceForm";
import { stripePublicKey } from "../../../config/mediaLinks";
import PropTypes from 'prop-types';

/**
 * CC Capture Modal
 *
 * @version 1.0.0
 * @author [Tedi]
 */
class CCCaptureModal extends Component {
	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				centered
				size="lg"
				backdropClassName="blurred-backdrop"
				modalClassName="modal-centered"
			>
				<ModalBody>
					<div className="text-center">
						<h2>Oops!</h2>
						<p>It looks like there is a problem with your card info.<br/>We tried billing your card, but were unsuccessful.</p>
					</div>
					<div className="d-flex justify-content-between my-5">
						<div style={{ flex: "0 0 50%" }} className="mr-1">
							<h3 className="text-success h5">Don’t worry, your account is safe.</h3>
							<p>Please update your card info to resolve this problem. Once you have updated your credit card we will attempt recover the last payment owed in addition to any other previous monthly charges owed for the services and features available to you, whether used or un-used.</p>
						</div>
						<div style={{ flex: "0 0 50%" }} className="ml-1">
							<StripeProvider apiKey={ stripePublicKey }>
								<Elements>
									<StripeCardReplaceForm />
								</Elements>
							</StripeProvider>
						</div>
					</div>
					<p className="text-center text-muted mt-5 ">Having problem? Please contact us via chat on the bottom right of the page</p>
				</ModalBody>
			</Modal>
		);
	}
}

CCCaptureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default CCCaptureModal;
