import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { openInNewTab } from '../../helpers/utility';
import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

export function* getSopCategories() {
    yield takeLatest('GET_SOP_CATEGORIES', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sop.category, null);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSopCategoriesSuccess(response.data))
					} else {
						yield put(actions.getSopCategoriesFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP get categories"));
					yield put(actions.getSopCategoriesFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_SOP_CATEGORIES_ERROR"
            }
          });
        }
    })
}

export function* saveSopCategory() {
    yield takeLatest('SAVE_SOP_CATEGORY', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.sop.category, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.saveSopCategorySuccess(response.data))
					} else {
						yield put(actions.saveSopCategoryFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP save category"));
					yield put(actions.saveSopCategoryFailed());
          logError(error, {
            tags: {
              exceptionType: "SAVE_SOP_CATEGORY_ERROR"
            }
          });
        }
    })
}

export function* deleteSopCategory() {
    yield takeLatest('DELETE_SOP_CATEGORY', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'delete', apiEndpoints.sop.category, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.deleteSopCategorySuccess(response.data))
					} else {
						yield put(actions.deleteSopCategoryFailed());
            yield put(appActions.userError(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP delete category"));
					yield put(actions.deleteSopCategoryFailed());
          logError(error, {
            tags: {
              exceptionType: "DELETE_SOP_CATEGORY_ERROR"
            }
          });
        }
    })
}

export function* updateSopCategory() {
    yield takeLatest('UPDATE_SOP_CATEGORY', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'put', apiEndpoints.sop.category, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.updateSopCategorySuccess(response.data))
					} else {
						yield put(actions.updateSopCategoryFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP update category"));
					yield put(actions.updateSopCategoryFailed());
          logError(error, {
            tags: {
              exceptionType: "UPDATE_SOP_CATEGORY_ERROR"
            }
          });
        }
    })
}

export function* getSopTags() {
    yield takeLatest('GET_SOP_TAGS', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sop.tag, null);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSopTagsSuccess(response.data))
					} else {
						yield put(actions.getSopTagsFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP get categories"));
					yield put(actions.getSopTagsFailed());
          logError(error, {
            tags: {
              exceptionType: "GET_SOP_TAGS_ERROR"
            }
          });
        }
    })
}

export function* saveSopTag() {
    yield takeLatest('SAVE_SOP_TAG', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.sop.tag, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.saveSopTagSuccess(response.data))
					} else {
						yield put(actions.saveSopTagFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP save tag"));
					yield put(actions.saveSopTagFailed());
          logError(error, {
            tags: {
              exceptionType: "SAVE_SOP_TAG_ERROR"
            }
          });
        }
    })
}

export function* deleteSopTag() {
    yield takeLatest('DELETE_SOP_TAG', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'delete', apiEndpoints.sop.tag, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.deleteSopTagSuccess(response.data))
					} else {
						yield put(actions.deleteSopTagFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP delete tag"));
					yield put(actions.deleteSopTagFailed());
          logError(error, {
            tags: {
              exceptionType: "DELETE_SOP_TAG_ERROR"
            }
          });
        }
    })
}

export function* updateSopTag() {
    yield takeLatest('UPDATE_SOP_TAG', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'put', apiEndpoints.sop.tag, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.updateSopTagSuccess(response.data))
					} else {
						yield put(actions.updateSopTagFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP update tag"));
					yield put(actions.updateSopTagFailed());
          logError(error, {
            tags: {
              exceptionType: "UPDATE_SOP_TAG_ERROR"
            }
          });
        }
    })
}

export function* getSopProcedures() {
    yield takeLatest('GET_SOP_PROCEDURES', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sop.procedure, null);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSopProceduresSuccess(response.data))
					} else {
						yield put(actions.getSopProceduresFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP get categories"));
					yield put(actions.getSopProceduresFailed());
          logError(error, {
            procedures: {
              exceptionType: "GET_SOP_PROCEDURES_ERROR"
            }
          });
        }
    })
}

export function* saveSopProcedure() {
    yield takeLatest('SAVE_SOP_PROCEDURE', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.sop.procedure, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.saveSopProcedureSuccess(response.data))
	          yield put(push("/dashboard/sop"));
					} else {
						yield put(actions.saveSopProcedureFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP save procedure"));
					yield put(actions.saveSopProcedureFailed());
          logError(error, {
            procedures: {
              exceptionType: "SAVE_SOP_PROCEDURE_ERROR"
            }
          });
        }
    })
}

export function* deleteSopProcedure() {
    yield takeLatest('DELETE_SOP_PROCEDURE', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'delete', apiEndpoints.sop.procedure, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.deleteSopProcedureSuccess(response.data))
					} else {
						yield put(actions.deleteSopProcedureFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP delete procedure"));
					yield put(actions.deleteSopProcedureFailed());
          logError(error, {
            procedures: {
              exceptionType: "DELETE_SOP_PROCEDURE_ERROR"
            }
          });
        }
    })
}

export function* updateSopProcedure() {
    yield takeLatest('UPDATE_SOP_PROCEDURE', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'put', apiEndpoints.sop.procedure, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.updateSopProcedureSuccess(response.data))
	          yield put(push("/dashboard/sop"));
					} else {
						yield put(actions.updateSopProcedureFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP update procedure"));
					yield put(actions.updateSopProcedureFailed());
          logError(error, {
            procedures: {
              exceptionType: "UPDATE_SOP_PROCEDURE_ERROR"
            }
          });
        }
    })
}

export function* exportSopProcedurePdf() {
    yield takeLatest('EXPORT_SOP_PROCEDURE_PDF', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'post', apiEndpoints.sop.procedure_export_pdf, payload.data);
					if (response.status === 200 && !response.data.error) {
				    openInNewTab(response.data.pdf);
						yield put(actions.exportSopProcedurePdfSuccess(response.data))
					} else {
						yield put(actions.exportSopProcedurePdfFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP export procedure pdf"));
					yield put(actions.exportSopProcedurePdfFailed());
          logError(error, {
            procedures: {
              exceptionType: "EXPORT_SOP_PROCEDURE_PDF_ERROR"
            }
          });
        }
    })
}

export function* getSopProcedureByID() {
    yield takeLatest('GET_SOP_PROCEDURE_SINGLE', function* (payload) {
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.sop.procedure_single, payload.data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getSopProcedureByIDSuccess(response.data))
					} else {
						yield put(actions.getSopProcedureByIDFailed());
            yield put(appActions.apiCallFailed(response.data.error));
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! SOP get single procedure"));
					yield put(actions.getSopProcedureByIDFailed());
          logError(error, {
            procedures: {
              exceptionType: "GET_SOP_PROCEDURE_SINGLE_ERROR"
            }
          });
        }
    })
}

export default function* sopSaga() {
	yield all([
		fork(getSopCategories),
		fork(saveSopCategory),
		fork(deleteSopCategory),
		fork(updateSopCategory),
		fork(getSopTags),
		fork(saveSopTag),
		fork(deleteSopTag),
		fork(updateSopTag),
		fork(getSopProcedures),
		fork(saveSopProcedure),
		fork(deleteSopProcedure),
		fork(updateSopProcedure),
		fork(exportSopProcedurePdf),
		fork(getSopProcedureByID),
	]);
}
