import Papa from "papaparse";
import fileDownload from "react-file-download";

/**
 * export batch items csv
 *
 * @param {array} produts Items data set
 * @param {object} meta Expenses meta data
 */
export const generateAllExpensesFileExport = (expenses) => {
  let exportableData = generateAllExpensesSpreadsheetData(expenses);
  let csv = Papa.unparse(JSON.stringify(exportableData), {
    delimiter: ",",
    header: true,
    skipEmptyLines: false,
  });

  fileDownload(csv, "AllExpensesSpreadsheet.csv");
};

function generateAllExpensesSpreadsheetData(expenses) {
  let rows = [];
  expenses.forEach((expense) => {
    let row = {
      "Expense Category": expense.category,
      "Date of Expense": expense.start_date,
      Amount: expense.amount,
      Description: expense.description,
      "Repeat Expense": expense.interval,
    };

    rows.push(row);
  });
  return rows;
}
