import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Input, Label, Row, Form, InputGroup, InputGroupAddon } from "reactstrap";
import Select from 'react-select';
import LoadingIndicator from "../../../../shared/components/LoadingIndicator";
import './style.css';
import SKUChart from "./SKUChart";
import researchActions from '../../../../redux/research/actions';
import moment from "moment";
import { digitСonversion } from "../../../../helpers/utility";
import appActions from "../../../../redux/app/actions";

const {
  researchGetSKUReport,
  researchGetSKUReportHistory,
} = researchActions;

const { userPageviewCapture } = appActions;

class SKUAnalysis extends Component {
  state = {
    sku: '',
    submitted_sku: '',
    display_mode: 'total',
    period: `${moment().subtract(365, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
    cogs: null,
    miscellanous_cost: null,
    inbound_transportation_charge: null,
    aged_inventory_surcharge: null,
    monthly_inventory_storage_fees: null,
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Research/SKU Analysis"})
    this.props.researchGetSKUReportHistory();
  }

  submit = (e) => {
    e.preventDefault()
    if(!this.state.sku){ return };
    this._submit()
  }

  _submit = (sku) => {
    this.setState({
      sku: sku ? sku : this.state.sku,
      submitted_sku: sku ? sku : this.state.sku,
      period: `${moment().subtract(365, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`
    })
    this.props.researchGetSKUReport({
      sku: sku ? sku : this.state.sku,
      period: `${moment().subtract(365, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`
    })
  }

  validateSetFloat = (key, value) => {
    if(value === ""){
      this.setState({[key]: null});
      return;
    }
    if(!isNaN(parseFloat(value))){
      this.setState({[key]: value});
    }
  }

  recalculate = (key) => {
    const { sku_report } = this.props;
    const {
      cogs,
      miscellanous_cost,
      inbound_transportation_charge,
      aged_inventory_surcharge,
      monthly_inventory_storage_fees,
    } = this.state;

    if(key === "total_amazon_charges"){
      return sku_report[key]
        - (parseFloat(inbound_transportation_charge) || 0)
        - (parseFloat(aged_inventory_surcharge) || 0)
        - (parseFloat(monthly_inventory_storage_fees) || 0);
    }

    if(key === "total_off_amazon_cost"){
      return sku_report[key] - (parseFloat(cogs) || 0) - (parseFloat(miscellanous_cost) || 0);
    }

    if(key === "net_proceeds"){
      return sku_report[key]
        - (parseFloat(cogs) || 0)
        - (parseFloat(miscellanous_cost) || 0)
        - (parseFloat(inbound_transportation_charge) || 0)
        - (parseFloat(aged_inventory_surcharge) || 0)
        - (parseFloat(monthly_inventory_storage_fees) || 0);
    }

    if(["cogs", "miscellanous_cost", "inbound_transportation_charge",
        "aged_inventory_surcharge", "monthly_inventory_storage_fees"].includes(key)){
      if(this.state[key]){
        return sku_report[key] - parseFloat(this.state[key]);
      }
    }

    if(this.state[key]){
      return sku_report[key] + parseFloat(this.state[key]);
    }
    return sku_report[key];
  }

  recalculateChart = () => {
    const {
      inbound_transportation_charge,
      aged_inventory_surcharge,
      monthly_inventory_storage_fees,
      cogs,
      miscellanous_cost
    } = this.state;
    const { sku_report } = this.props;
    const value = (
      (parseFloat(inbound_transportation_charge) || 0)
      + (parseFloat(aged_inventory_surcharge) || 0)
      + (parseFloat(monthly_inventory_storage_fees) || 0)
      + (parseFloat(cogs) || 0)
      + (parseFloat(miscellanous_cost) || 0)
    ) / (this.props.sku_report?.net_units_sold || 1)
    return (sku_report?.chart || []).map(item => {
      var i = {...item}
      i.total_cost += value;
      i.net = i.net - value;
      return i;
    })
  }

  removeLastItem = (e, idx) => {
    e.stopPropagation()
    console.log("remove last searches item")
  }

  render() {
    const {
      sku,
      submitted_sku,
      display_mode
    } = this.state;
    const {
      sku_report,
      sku_report_working,
      sku_report_history,
    } = this.props;

    return (
      <div className="view">
        <header className="view-header">
          <h1 className="page-title">SKU Analysis</h1>
        </header>
        <div className="view-content view-sku-analysis">
          <Card>
            <CardBody>
              <p>Analyze product performance and deep dive into the profitability of each product SKU.</p>
              <Row>
                <Col sm={12}>
                  <Form onSubmit={this.submit}>
                  <Label>Enter your product SKU/ASIN</Label>
                  <div className="d-flex gap-2">
                    <Input
                      placeholder="Enter SKU or ASIN then hit Enter"
                      value={sku}
                      onChange={(e) => this.setState({ sku: e.target.value })}
                    />
                    <Button
                      color="success"
                      onClick={this.submit}
                      disabled={!sku}
                    >Submit</Button>
                  </div>
                  <div className="last-searches">
                    {sku_report_history?.map((item, idx) =>
                    <div
                      key={`last-item-${idx}`}
                      className="last-searches-item"
                      role="button"
                      onClick={(e) => {this._submit(item)}}
                    >
                      <span>{item}</span>
                    </div>
                    )}
                  </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {submitted_sku !== '' ?
            sku_report_working ?
            <Card>
              <CardBody>
                <LoadingIndicator title="Fetching result..." className="m-0"  />
              </CardBody>
            </Card>
            :
            Object.keys(sku_report).length ?
              <Card>
                <CardBody>
                  <div className="sku-analysis-result">
                    <h2 className="card-title">Analyze SKU: <span className="text-primary">{this.state.submitted_sku}</span></h2>
                    <Row>
                      <Col sm={7}>
                        <div style={{  maxWidth: 200 }}>
                          <Select
                            options={[
                              {value: `${moment().subtract(365, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`, label: 'Last 12 months' },
                              {value: `${moment().subtract(180, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`, label: 'Last 6 months' },
                              {value: `${moment().subtract(90, "days").format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`, label: 'Last 3 months' }
                            ]}
                            value={this.state.period}
                            clearable={false}
                            searchable={false}
                            onChange={(e) => {
                              this.setState({period: e});
                              this.props.researchGetSKUReport({
                                sku: this.state.sku,
                                period: e.value,
                              })
                            }}
                          />
                        </div>
                        <div className="mt-3">
                          <SKUChart
                            data={this.recalculateChart() || []}
                          />
                        </div>
                      </Col>
                      <Col sm={5}>
                        {/*
                        <ButtonGroup>
                          <Button
                            color={display_mode === 'total' ? 'dark' : 'secondary'}
                            onClick={() => this.setState({ display_mode: 'total' })}
                          >Total</Button>
                          <Button
                            color={display_mode === 'per_unit' ? 'dark' : 'secondary'}
                            onClick={() => this.setState({ display_mode: 'per_unit' })}
                          >Per Unit</Button>
                        </ButtonGroup>
                        */}
                        <div className="sku-analysis-data">
                          <div className="sku-analysis-data-item">
                            <span>Average sales price</span>
                            <span>{digitСonversion(sku_report?.average_unit_sales, "currency", "USD")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>Units sold</span>
                            <span>{digitСonversion(sku_report?.units_sold, "decimal")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>Units returned</span>
                            <span>{digitСonversion(sku_report?.units_returned, "decimal")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <strong>Net units sold</strong>
                            <strong>{digitСonversion(sku_report?.net_units_sold, "decimal")}</strong>
                          </div>
                        </div>

                        <div className="sku-analysis-data">
                          <div className="sku-analysis-data-item">
                            <span>Sales</span>
                            <span>{digitСonversion(sku_report?.sales, "currency", "USD")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <strong>Net Sales</strong>
                            <strong>{digitСonversion(sku_report?.net_sales, "currency", "USD")}</strong>
                          </div>
                        </div>

                        <div className="sku-analysis-data">
                          <div className="sku-analysis-data-item red">
                            <strong>Total Amazon Charges</strong>
                            <strong>{digitСonversion(this.recalculate("total_amazon_charges"), "currency", "USD")}</strong>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>
                              <span>Monthly inventory storage fee</span>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                  size="sm"
                                  type="number"
                                  step="0.01"
                                  value={this.state.monthly_inventory_storage_fees || ""}
                                  onChange={(e) => this.validateSetFloat("monthly_inventory_storage_fees", e.target.value)}
                                  autoFocus
                                />
                              </InputGroup>
                            </span>
                            <span>{digitСonversion(this.recalculate("monthly_inventory_storage_fees"), "currency", "USD")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>
                              <span>Aged inventory surcharge</span>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                  size="sm"
                                  type="number"
                                  step="0.01"
                                  value={this.state.aged_inventory_surcharge || ""}
                                  onChange={(e) => this.validateSetFloat("aged_inventory_surcharge", e.target.value)}
                                />
                              </InputGroup>
                            </span>
                            <span>{digitСonversion(this.recalculate("aged_inventory_surcharge"), "currency", "USD")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>
                              <span>Inbound transportation charge</span>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                  size="sm"
                                  type="number"
                                  step="0.01"
                                  value={this.state.inbound_transportation_charge || ""}
                                  onChange={(e) => this.validateSetFloat("inbound_transportation_charge", e.target.value)}
                                />
                              </InputGroup>
                            </span>
                            <span>{digitСonversion(this.recalculate("inbound_transportation_charge"), "currency", "USD")}</span>
                          </div>
                        </div>
                        <div className="sku-analysis-data">
                          <div className="sku-analysis-data-item red">
                            <strong>Total off-Amazon Cost</strong>
                            <strong>{digitСonversion(this.recalculate("total_off_amazon_cost"), "currency", "USD")}</strong>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>
                              <span>Cost of goods sold x Units sold</span>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                  size="sm"
                                  type="number"
                                  step="0.01"
                                  value={this.state.cogs || ""}
                                  onChange={(e) => this.validateSetFloat("cogs", e.target.value)}
                                />
                              </InputGroup>
                            </span>
                            <span>{digitСonversion(this.recalculate("cogs"), "currency", "USD")}</span>
                          </div>
                          <div className="sku-analysis-data-item">
                            <span>
                              <span>Miscellanous cost x Units sold</span>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                  size="sm"
                                  type="number"
                                  step="0.01"
                                  value={this.state.miscellanous_cost || ""}
                                  onChange={(e) => this.validateSetFloat("miscellanous_cost", e.target.value)}
                                />
                              </InputGroup>
                            </span>
                            <span>{digitСonversion(this.recalculate("miscellanous_cost"), "currency", "USD")}</span>
                          </div>
                        </div>

                        <div className="sku-analysis-data">
                          <div className="sku-analysis-data-item net">
                            <span>Net proceeds</span>
                            <span>{digitСonversion(this.recalculate("net_proceeds"), "currency", "USD")}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              :
              <Card>
                <CardBody>
                  No data available for submitted SKU/ASIN.
                </CardBody>
              </Card>
          : null
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
			sku_report: state.Research.get('sku_report'),
			sku_report_working: state.Research.get('sku_report_working'),
			sku_report_history: state.Research.get('sku_report_history'),
			sku_report_history_working: state.Research.get('sku_report_history_working'),
		};
	},
		{
			researchGetSKUReport,
			researchGetSKUReportHistory,
      userPageviewCapture,
		}
)(SKUAnalysis);
