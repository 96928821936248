import queryString from 'query-string';
import { client } from "../../apiConfig";

/**
 * Backend endpoints list
 */
export const apiEndpoints = {
	admin: {
		search_user: "api/v1/admin/search/user",
		password: "api/v1/admin/password",
		search_user_errorlogs: "api/v1/admin/search/user/errorlogs",
		serach_user_marketplaces: "api/v1/admin/serach/user/marketplaces",
		user_clearauthtoken: "api/v1/admin/user/clearauthtoken",
		batch_stats: "api/v1/admin/batch/stats",
		stats_categories_books: "api/v1/admin/stats/categories/books",
		stats_categories_other: "api/v1/admin/stats/categories/other",
		user_update: "api/v1/admin/user/update",
		user_register_whitelist: "api/v1/admin/user/register/whitelist",
		user_fbc_compare: "api/v1/admin/user/fbc/compare",
		user_enableebay: "api/v1/admin/user/enableebay",
		user_logs: "api/v1/admin/user/logs",
		user_success: "api/v1/admin/user/success",
		cancel_reason: "api/v1/admin/cancel_subscription",
		user_segmentation: "api/v1/admin/user/segmentation",
    ebay_stats: "api/v1/admin/ebay/stats",
    ebay_sales_users: "api/v1/admin/ebay/sales/users",
    ebay_sales_user_details: "api/v1/admin/ebay/sales/user/details",
    ebay_stats_sales_averages: "api/v1/admin/ebay/stats/sales/averages",
    app_settings: "api/v1/admin/app/settings",
    sales_users_averages: "api/v1/admin/ebay/sales/users/averages",
    user_sms_enable: "api/v1/admin/user/sms/enable",
    spapi_user_count: "api/v1/admin/spapi/user/count",
    user_logs_spapi: "api/v1/admin/user/logs/spapi",
    user_spapi_activate: "api/v1/admin/user/spapi/activate",
    user_log_action_export: "api/v1/admin/user/log/action/export",
    ai_analyze: "api/v1/admin/ai/analyze",
    batch_splits: "api/v1/admin/batch/splits",
	},
	sbyb: {
		admin_tracking_stats: "api/v1/sbyb/admin/tracking/stats",
	},
	batch: {
		export_pdf_labels: "api/v1/batch/export/pdf/labels",
		export_30up_labels: "api/v1/batch/export/pdf/30up",
		feed_data: "api/v1/batch/feed/data",
		update_all_listings: "api/v1/batch/update/all/listings",
		shipment_distance: "api/v1/batch/shipment/distance",
	},
	inventory: {
		inventory_item: 'api/v1/inventory_item',
		inventory_item_upload: 'api/v1/inventory_item/upload',
		inventory_item_stranded: '/api/v1/inventory_item/stranded',
    inventory_item_stats: '/api/v1/inventory_item/stats/aggregated',
    export: '/api/v1/inventory_item/export',
    delete: '/api/v1/inventory_item/delete',
    expire: '/api/v1/inventory_item/expire',
    activate: '/api/v1/inventory_item/activate',
	},
	repricer: {
		stats: 'api/v1/repricer/stats',
		stats_dashboard_repricing: "api/v1/repricer/stats/dashboard/repricing",
		template_update_name: "api/v1/template/update/name",
		template_test: "api/v1/template/test",
    report_reprice_master: "api/v1/schedule/report/reprice/master",
	},
	competitor_pricing: {
		pricing_data_sbyb: 'api/v1/competitor_pricing/sbyb',
		pricing_data_profithunt: 'api/v1/competitor_pricing/profithunt',
		projected_profit: 'api/v1/competitor_pricing/projected_profit',
	},
	search: {
		sbyb: 'api/v1/search/sbyb',
    products: 'api/v1/search/products',
    restrictions: 'api/v1/search/restrictions',
    help: 'api/v1/search/help',
    help_single: 'api/v1/search/help/single',
	},
	supplier: {
		accounting_profitability: 'api/v1/supplier/accounting/profitability',
	},
	scout: {
		accounting_profitability: 'api/v1/scout/accounting/profitability',
	},
	research: {
		import: 'api/v1/research/import',
		research: 'api/v1/research',
		batch: 'api/v1/research/batch',
		bulk_delete: 'api/v1/research/bulk/delete',
		manual_search: 'api/v1/research/manual/search',
		manual_search_select: 'api/v1/research/manual/search/select',
		export: 'api/v1/research/export',
    status: 'api/v1/research/status',
    sku: 'api/v1/research/sku',
    sku_history: 'api/v1/research/sku/history',
	},
	ebay: {
		user_creds: 'api/v1/ebay/user/creds',
		list_item: 'api/v1/ebay/list/item',
		user_policy: 'api/v1/ebay/user/policy',
		user_settings: 'api/v1/ebay/user/settings',
		user_location: 'api/v1/ebay/user/location',
		items: "api/v1/ebay/items",
		orders: "api/v1/ebay/orders",
		schedule_orders_pull: "api/v1/ebay/schedule/orders/pull",
		schedule_orders_active_get: "api/v1/ebay/schedule/orders/active/get",
		schedule_order_activate: "api/v1/ebay/schedule/order/activate",
		order_item: "api/v1/ebay/order/item",
		order_item_mark_fulfilled: "api/v1/ebay/order/item/mark/fulfilled",
		calculate_fees: "api/v1/ebay/calculate_fees",
		item: "api/v1/ebay/item",
		items_count: "api/v1/ebay/items/count",
    category_aspects: "/api/v1/ebay/category/aspects",
    package_type_available: "/api/v1/ebay/package/type/available",
    direct_image_upload: "/api/v1/ebay/direct/image/upload",
    direct_category_suggestions: "api/v1/ebay/direct/category/suggestions",
    direct_pricing: "api/v1/ebay/direct/pricing",
    order_check_mf_unfinished: "api/v1/ebay/order/check/mf/unfinished",
    marketing_conversion: "api/v1/ebay/marketing/conversion",
	},
	accounting: {
		export_pdf_expenses: 'api/v1/profit_analytics/export/pdf/expenses',
		export_pdf_pl: 'api/v1/profit_analytics/report/export/pl/pdf',
		report_export_pl_email: 'api/v1/profit_analytics/report/export/pl/email',
    category_profitability: "api/v1/profit_analytics/category/profitability",
	},
	box_contents: {
		manifest_print: 'api/v1/box_contents/manifest/print',
    shipment_job: 'api/v1/box_contents/shipment/job',
    shipment_job_progress: 'api/v1/box_contents/shipment/job/progress',
	},
	user: {
		employee: 'api/v1/user/employee',
    auth_amazon_start: "api/v1/user/auth/amazon/start",
    auth_amazon_lwa: "api/v1/user/auth/amazon/lwa",
    app_event_pageview: "api/v1/user/app/event/pageview",
	},
  employees: {
    tracking: 'api/v1/employees/tracking',
  },
  faq: {
    topic: 'api/v1/faq/topic',
    topics: 'api/v1/faq/topics',
    article: 'api/v1/faq/article',
    articles: 'api/v1/faq/articles',
    landing_data: 'api/v1/faq/landing/data',
    topics_reorder: 'api/v1/faq/topics/reorder',
  },
  feedback: {
    feedbacks: "api/v1/feedback",
  },
  dashboard: {
    data_period: 'api/v1/dashboard/data/period',
  },
  sop: {
    procedure: "api/v1/sop/procedure",
    procedure_single: "api/v1/sop/procedure/single",
    procedure_export_pdf: "api/v1/sop/procedure/export/pdf",
    category: "api/v1/sop/category",
    tag: "api/v1/sop/tag",
    direct_image_upload: "/api/v1/sop/direct/image/upload",
  },
  message: {
    in_app: "api/v1/message/in/app",
    in_app_user: "api/v1/message/in/app/user",
    in_app_user_tracking: "api/v1/message/in/app/user/tracking",
    image_upload: "api/v1/message/image/upload",
  },
  auth: {
    registration_sms_resend: "auth/registration/sms/resend",
    registration_sms_confirm: "auth/registration/sms/confirm",
    registration_sms_direct_send: "auth/registration/sms/direct/send",
    registration_sms_direct_confirm: "auth/registration/sms/direct/confirm",
  },
  settings: {
    sku_template: "api/v1/settings/sku_template",
    sku_template_use: "api/v1/settings/sku_template/use",
  	printer_settings_mf_ticketing: "api/v1/settings/printer_settings/mf_ticketing",
  	sales_rank_range: "api/v1/settings/listing_defaults/sales/rank",
  },
  orders: {
    orders: "api/v1/orders",
    order: "api/v1/orders/order",
  },
  sales: {
    summary: "api/v1/sales/summary",
    summary_export: "api/v1/sales/summary/export",
    category: "api/v1/sales/category",
    category_export: "api/v1/sales/category/export",
    product: "api/v1/sales/product",
    product_export: "api/v1/sales/product/export",
    sales_chart: "api/v1/sales/sales/chart",
  },
  fulfillment: {
    inbound_plan: "api/v1/fulfillment/inbound/plan",
    inbound_operation_status: "api/v1/fulfillment/inbound/operation/status",
    inbound_placement: "api/v1/fulfillment/inbound/placement",
    inbound_packing: "api/v1/fulfillment/inbound/packing",
    inbound_packing_set: "api/v1/fulfillment/inbound/packing/set",
    box: "api/v1/fulfillment/box",
    box_all: "api/v1/fulfillment/box/all",
    box_item: "api/v1/fulfillment/box/item",
    box_item_all: "api/v1/fulfillment/box/item/all",
    inbound_transportation: "api/v1/fulfillment/inbound/transportation",
    inbound_transportation_confirm: "api/v1/fulfillment/inbound/transportation/confirm",
    inbound_delivery_confirm: "api/v1/fulfillment/inbound/delivery/confirm",
    inbound_labels: "api/v1/fulfillment/inbound/labels",
    inbound_shipments_list: "api/v1/fulfillment/inbound/shipments/list",
    inbound_shipments_tracking: "api/v1/fulfillment/inbound/shipments/tracking",
    inbound_plan_items_available: "api/v1/fulfillment/inbound/plan/items/available",
  },
}

/**
 * make api call based on method
 */
function apiCall(method, endpoint, data=null) {
	var api = null;
	switch(method){
		case 'get':
			if(data){
				api = client.get(endpoint + '?' + queryString.stringify(data))
			} else {
				api = client.get(endpoint);
			}
			break;
		case 'post':
			api = client.post(endpoint, data); break;
		case 'put':
			api = client.put(endpoint, data); break;
		case 'delete':
			if(data){
				api = client.delete(endpoint + '?' + queryString.stringify(data))
			} else {
			  api = client.delete(endpoint);
			}
			break;
		default:
			api = null;
	}
	return api;
}

/**
 * Public backend API call method
 */
export function backendAPICall(method, endpoint, data) {
	var api = apiCall(method, endpoint, data);
	return api
		.then(response => { return response; })
		.catch(err => { throw err; });
}
