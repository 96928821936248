import { Button, Card, CardBody, Col, Form, FormGroup, Label } from "reactstrap";
import React, { Component } from "react";
import { accelerPrintIcon } from "../../../../../../assets/images";
import PropTypes from "prop-types";
import { mfLabelTypeList } from "../../../../../../helpers/print_service/labelsTypeList";
import { mf_label_size } from "../../../../../../helpers/print_service/print_systems";
import PrintMFPreview from "../PrintMFPreview";


class PrintMFSettingsView extends Component {
  getTextFromBoolean = value => {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    }
  };

  handleChange = (e) => {
    const { value } = e.target;

    if(value) {
      this.props.savePrinterDefaults({
        ...this.props.printerSettings[value]
      });
    }
  };

  render() {
    const { fnsku, printerSettings, printerDefaults, toggleEditMode, qzTrayConnectionStatus, print, row, level, sequenceLength, startAt, zeros } = this.props;

    const testPrintLabel = {
      testLabel: true,
      fnsku,
      name: "Test product: test product",
    };

    return (
      <>
        <Col xl="6">
					<Card>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Col sm={12}>
                    <Button
                      className="mb-2"
                      onClick={toggleEditMode}
                    >
                      Edit Print Settings
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
              <Form>
                <FormGroup row>
                  <Label for="printerSelect" sm={3}>
                    Active Printer
                  </Label>
                  <Col sm={9}>
                    <select
                      className="form-control m-b"
                      name="printer_name"
                      id="printer_name"
                      value={printerDefaults.printer_name}
                      onChange={this.handleChange}
                    >
                      <option value="">
                        Choose a Printer
                      </option>
                      {printerSettings && Object.values(printerSettings).map(({printer_name}) => {
                        return (
                          <option key={"p-" + printer_name} value={printer_name}>
                            {printer_name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </FormGroup>
                <React.Fragment>
                  <FormGroup row>
                    <Label for="orientation" sm={3}>
                      Orientation:
                    </Label>
                    <Col sm={9}>
                      {printerDefaults.orientation || "-"}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="suggestedLabels" sm={3}>
                      Suggested Labels:
                    </Label>
                    <Col sm={9}>{mf_label_size}</Col>
                  </FormGroup>
                </React.Fragment>
                <FormGroup row>
                  <Label for="printOption" sm={3}>
                    Print while scanning in batch:
                  </Label>
                  <Col sm={9}>
                    {this.getTextFromBoolean(
                      printerDefaults.print_while_scanning
                    ) || "-"}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="printOption" sm={3}>
                    Print while scanning in box contents:
                  </Label>
                  <Col sm={9}>
                    {this.getTextFromBoolean(
                      printerDefaults.print_while_scanning_box_contents
                    ) || "-"}
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Col
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ marginTop: 0 }}
                  >
                    {/* Here showing the Printer Connection Status */}
                    <strong>MF Print connection status</strong>
                  </Col>
                  <Col className="d-flex flex-column justify-content-center align-items-center">
                    <img src={accelerPrintIcon} alt="accelerPrintIcon" width="50" />
                    {qzTrayConnectionStatus ? (
                      <div className="mb-2">
                        <div className="toggle-dot" />
                        <div>DETECTED</div>
                      </div>
                    ) : (
                      <div>Not Connected</div>
                    )}
                  </Col>
                  <Col>
                    <React.Fragment>
                      <Button
                        className="mb-2"
                        onClick={() => {
                          print(testPrintLabel, 1);
                        }}
                        disabled={!qzTrayConnectionStatus}
                      >
                        Print test label
                      </Button>
                    </React.Fragment>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4">
					{qzTrayConnectionStatus && 
						<PrintMFPreview
						printerDefaults={printerDefaults}
						row={row}
						level={level}
						sequenceLength={sequenceLength}
						startAt={startAt}
						zeros={zeros}
						/>
					}
				</Col>
      </>
    )
  }
}

PrintMFSettingsView.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  printerDefaults: PropTypes.object.isRequired,
  qzTrayConnectionStatus:  PropTypes.bool.isRequired
};

export default PrintMFSettingsView;

