import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import ReactTable from "react-table";
import { digitСonversion } from "../../../../helpers/utility";
import Papa from 'papaparse'
import fileDownload from 'react-file-download'
import moment from "moment"

const { getUserSuccessAdmin } = adminActions;

class AdminUserSuccess extends Component {

  state = {
    search: "",
  }

  generateFileExport = () => {
    let exportableData = this.generateDataForExport();
    let csv = Papa.unparse(JSON.stringify(exportableData) , {
      delimiter: ",",
      header: true,
      skipEmptyLines: false
    })
    fileDownload(csv, 'user_success_export.csv');
  }

  generateDataForExport = () => {
		const { user_success } = this.props;

    let headers = [
      "Username",
      "Active?",
      "Email",
      "Seller ID",
      "eBay?",
      "Repricer?",
      "Last Listed",
      "Volume",
      "Batches",
      "Products Listed",
    ];

    let rows = [];
    user_success.forEach(item => {
      let row = {
        "Username": item.username,
        "Active?": item.has_active_subscription === 'true' ? "Yes" : "No",
        "Email": item.email,
        "Seller ID": item.seller_id,
        "eBay?": item.is_ebay_enabled ? "Yes": "No",
        "Repricer?": item.is_repricer_enabled ? "Yes": "No",
        "Last Listed": item.last_listed ? item.last_listed : "-",
        "Volume": item.orders,
        "Batches": item.batches,
        "Products Listed": item.items,
      };

      headers.forEach(header => {
        if (!row[header]) {
          row[header] = "";
        }
      });
      rows.push(row);
    });
    return rows;
  }

	componentDidMount(){
    this.props.getUserSuccessAdmin();
	}

  generateTableColums = (showLabelsColumn) => {
    let tableColumns = [
      {
        id: "username",
        accessor: "username",
        Header: "Username",
        headerClassName: "text-left"
      },
      {
        id: "has_active_subscription",
        accessor: "has_active_subscription",
        Header: "Active?",
        headerClassName: "text-left",
        Cell: props => (
          props.value === 'true' ? "Yes" : "No"
				)
      },
      {
        id: "email",
        accessor: "email",
        Header: "Email",
        headerClassName: "text-left"
      },
      {
        id: "seller_id",
        accessor: "seller_id",
        Header: "Seller ID",
        headerClassName: "text-left"
      },
      {
        id: "is_ebay_enabled",
        accessor: "is_ebay_enabled",
        Header: "eBay?",
        headerClassName: "text-left",
        Cell: props => (
          props.value ? "Yes" : "No"
        )
      },
      {
        id: "is_repricer_enabled",
        accessor: "is_repricer_enabled",
        Header: "Repricer?",
        headerClassName: "text-left",
        Cell: props => (
          props.value ? "Yes" : "No"
        )
      },
      {
        id: "last_listed",
        accessor: "last_listed",
        Header: "Last Listed",
        headerClassName: "text-left",
        Cell: props => (
          <div>{props.value ? moment(props.value).fromNow() : "-"}</div>
        )
      },
      {
        id: "orders",
        accessor: "orders",
        Header: "Volume",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => (
          <div>{digitСonversion(props.value, "currency", "USD")}</div>
        )
      },
      {
        id: "items",
        accessor: "items",
        Header: "Products Listed",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => (
          <div>{digitСonversion(props.value, "decimal", "USD")}</div>
        )
      },
      {
        id: "batches",
        accessor: "batches",
        Header: "Batches",
        headerClassName: "text-right",
        className: "text-right",
        Cell: props => (
          <div>{digitСonversion(props.value, "decimal", "USD")}</div>
        )
      },
    ];
    return tableColumns;
  }

  searchUserData = (data) => {
    if(this.state.search){
      let res = [];
      data.forEach(item => {
        let ss = `${item.seller_id} ${item.is_ebay_enabled} ${item.is_repricer_enabled} ${item.items} ${item.batches} ${item.orders} ${item.has_active_subscription} ${item.username} ${item.email}`;
        if(ss.toLowerCase().includes(this.state.search.toLowerCase())){
          res.push(item);
        }
      })
      return res;
    }
    return data;
  }


  render() {
		const { user_success } = this.props;

    return (
			<React.Fragment>
				<Row>
					<Col lg="12">
						<Card>
							<CardBody>
							  <CardTitle>User Success</CardTitle>
                <div className="d-flex justify-content-between align-items-center mb-3">
					        <div style={{ width: "20rem" }}>
                    <Input value={this.state.search} onChange={e => this.setState({ search: e.target.value })} placeholder="Search username/email..."/>
					        </div>
					        <div>
                    <Button
                      color="primary"
                      onClick={e => this.generateFileExport()}
                    >Download data</Button>
					        </div>
                </div>
                <ReactTable
                  data={this.searchUserData(user_success)}
                  columns={this.generateTableColums()}
                  minRows={1}
                />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</React.Fragment>
    );
  }
}

export default connect(
  state => {
    return {
      user_success: state.Admin.get('user_success'),
		};
	},
		{
      getUserSuccessAdmin,
		}

)(AdminUserSuccess);
