import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  item_count: 0,
  item_count_ebay: 0,
  sales_sum_ebay: 0,
  faq_data: {},
  optin_working: false,
  optin_success: false,
  optin_show_popup: false,
  desktop_download: {},
  dataDaily: {
    "1M": [],
    "1Y": [],
    "5Y": []
  }
});

export default function landingItemsCountReducer(state = initState, action) {
  switch (action.type) {

    case actions.OPTIN_SENDGRID_TOGGLE_POPUP_SUCCESS:
      return state
		    .set("optin_show_popup", action.data)

    case actions.GET_DESKTOP_DOWNLOAD_DATA:
		  return state.set("desktop_download", {});
    case actions.GET_DESKTOP_DOWNLOAD_DATA_SUCCESS:
		  return state.set("desktop_download", Object.assign({}, action.data));
    case actions.GET_DESKTOP_DOWNLOAD_DATA_FAILED:
		  return state.set("desktop_download", {});

    case actions.OPTIN_SENDGRID:
      return state
        .set("optin_working", true)
		    .set("optin_success", false)
		    .set("optin_success", false)
    case actions.OPTIN_SENDGRID_SUCCESS:
      return state
        .set("optin_working", false)
		    .set("optin_success", true)
		    .set("optin_show_popup", true)
    case actions.OPTIN_SENDGRID_FAILED:
      return state
        .set("optin_working", false)
		    .set("optin_success", false)
		    .set("optin_show_popup", false)

    case actions.GET_FAQ_LANDING:
		  return state.set("faq_data", {});
    case actions.GET_FAQ_LANDING_SUCCESS:
		  return state.set("faq_data", Object.assign({}, action.data));
    case actions.GET_FAQ_LANDING_FAILED:
		  return state.set("faq_data", {});


    case actions.FETCH_INVENTORY_ITEMS_SUCCESS: {
		  return state.set("item_count", action.count);
	  }
    case actions.FETCH_INVENTORY_ITEMS_EBAY_SUCCESS: {
      return state
        .set("item_count_ebay", action.count.count)
        .set("sales_sum_ebay", action.count.sales)
    }

    case actions.FETCH_EBAY_GRAPH_SALES_SUCCESS: {
      return state.set("dataDaily", action.data.dataDaily)
    }

  default:
      return state;
  }
}
