import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Col, Alert } from 'reactstrap';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { NavLink } from "react-router-dom";
import repricerActions from '../../../../../../redux/repricer/actions';
import { getNewConditionOptionsForRepricer } from '../../../../../../helpers/repricer/utility';
import AlertTooltip from '../../components/AlertTooltip';
import TemplateTooltip from '../../components/TemplateTooltip';

const {
	workingTemplateUpdateJson,
	userGetGlobalList,
} = repricerActions;

/*
const itemConditions = [
  { value: 'N', label: 'New' },
  { value: 'LN', label: 'Used Like New' },
  { value: 'VG', label: 'Used Very Good' },
  { value: 'G', label: 'Used Good' },
  { value: 'A', label: 'Used Acceptable' },
  { value: 'C-LN', label: 'Collectible Like New' },
  { value: 'C-VG', label: 'Collectible Very Good' },
  { value: 'C-G', label: 'Collectible Good' },
  { value: 'C-A', label: 'Collectible Acceptable' },
];
*/


class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
		itemConditions: [],
		conditions: [],
		skuList: '',
		mskus: [],
		rules: 'whitelist',
		channel_target: "",
		product_salesrank_max: 1,
		product_salesrank_min: 1,
		product_price_min: 1,
		product_price_max: 1,
		inventory_age_min: 1,
		inventory_age_max: 1,
		sku_list_to_exclude_id: null,
		sku_list_to_include_id: null,
		skus_to_exclude: [],
		skus_to_include: [],
    }
  }

	UNSAFE_componentWillMount(){
		this.props.userGetGlobalList('sku');
		if(this.props.workingTemplate.template_json.user_inventory_sku_list_to_exclude_id){
			this.setState({ rules: 'blacklist' });
		}
		this.setState({
			itemConditions: getNewConditionOptionsForRepricer(),
			channel_target: this.props.workingTemplate.template_json.user_inventory_channel_target,
			product_salesrank_max: this.props.workingTemplate.template_json.user_inventory_product_salesrank_max || 1,
			product_salesrank_min: this.props.workingTemplate.template_json.user_inventory_product_salesrank_min || 1,
			product_price_min: this.props.workingTemplate.template_json.user_inventory_product_price_min || 1,
			product_price_max: this.props.workingTemplate.template_json.user_inventory_product_price_max || 1,
			inventory_age_min: this.props.workingTemplate.template_json.user_inventory_inventory_age_min || 1,
			inventory_age_max: this.props.workingTemplate.template_json.user_inventory_inventory_age_max || 1,
			conditions: this.props.workingTemplate.template_json.user_inventory_condition_to_reprice,
			sku_list_to_exclude_id: this.props.workingTemplate.template_json.user_inventory_sku_list_to_exclude_id,
			sku_list_to_include_id: this.props.workingTemplate.template_json.user_inventory_sku_list_to_include_id,
			skus_to_exclude: this.props.workingTemplate.template_json.user_inventory_skus_to_exclude,
			skus_to_include: this.props.workingTemplate.template_json.user_inventory_skus_to_include,
		});
		//handle blacklist/whitelist
		if(this.props.workingTemplate.template_json.user_inventory_skus_to_include
			&& this.props.workingTemplate.template_json.user_inventory_skus_to_include.length > 0){
				this.setState({ rules: 'whitelist'});
		}
		if(this.props.workingTemplate.template_json.user_inventory_skus_to_exclude
			&& this.props.workingTemplate.template_json.user_inventory_skus_to_exclude.length > 0){
				this.setState({ rules: 'blacklist'});
		}
	}

  onSelectCondition = (value) => {
    this.setState({ conditions: value });
	this.props.workingTemplateUpdateJson(
		{data: {user_inventory_condition_to_reprice: value}})
  }

	onInputSKUList = (value) => {
		if(this.state.rules === 'whitelist'){
			this.setState({ sku_list_to_include_id: value ? value.value : null});
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_sku_list_to_include_id: value ? value.value : null}})
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_sku_list_to_exclude_id: null}})
		} else {
			this.setState({ sku_list_to_exclude_id: value ? value.value : null});
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_sku_list_to_exclude_id: value ? value.value : null}})
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_sku_list_to_include_id: null}})
		}
  }


  onInputMSKUS = (value) => {
		if(this.state.rules === 'whitelist'){
			this.setState({ skus_to_include: value ? value : null});
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_skus_to_include: value ? value : null}})
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_skus_to_exclude: null}})
		} else {
			this.setState({ skus_to_exclude: value ? value : null});
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_skus_to_exclude: value ? value : null}})
			this.props.workingTemplateUpdateJson(
				{data: {user_inventory_skus_to_include: null}})
		}
  }

  onRulesChange = (value) => {
    this.setState({
		rules: value,
		sku_list_to_exclude_id: null,
		sku_list_to_include_id: null,
		skus_to_exclude: [],
		skus_to_include: [],
	});
	this.props.workingTemplateUpdateJson(
		{data: {user_inventory_sku_list_to_include_id: null}})
	this.props.workingTemplateUpdateJson(
		{data: {user_inventory_sku_list_to_exclude_id: null}})
	this.props.workingTemplateUpdateJson(
		{data: {user_inventory_skus_to_include: []}})
	this.props.workingTemplateUpdateJson(
		{data: {user_inventory_skus_to_exclude: []}})
  }

	changeCheckFBA = (e) => {
		var val = e;
		switch(this.state.channel_target){
			case "merchant_fulfill":
				val = "both";
				break;
			case "amazon_fulfill":
				val =  ""
				break;
			case "both":
				val = "merchant_fulfill"
				break;
			default:
				val = "amazon_fulfill";
		}
		this.setState({ channel_target: val });
		this.props.workingTemplateUpdateJson(
			{data: {user_inventory_channel_target: val}})
	}

	changeCheckMF = (e) => {
		var val = e;
		switch(this.state.channel_target){
			case "merchant_fulfill":
				val =  ""
				break;
			case "amazon_fulfill":
				val = "both";
				break;
			case "both":
				val = "amazon_fulfill";
				break;
			default:
				val = "merchant_fulfill"
		}
		this.setState({ channel_target: val });
		this.props.workingTemplateUpdateJson(
			{data: {user_inventory_channel_target: val}})
	}

	changeIntegerValue = (e, root, key) => {
		var val = e.target.value;
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_int = parseInt(val.replace(/,/gi, ""), 10);
			if(!val_int){
				val = "";
				val_int = 0;
			} else {
				val = val_int.toString()
			}
			this.setState({ [key]: val_int });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeFloatValue = (e, root, key) => {
		var val = e.target.value;
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_float = parseFloat(val.replace(/,/gi, "")).toFixed(2);
			if(!val_float){
				val = "";
				val_float = 0;
			} else {
				val = val_float.toString()
			}
			this.setState({ [key]: val_float });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeValue = (key, e) => {
		this.setState({ [key]: e.target.value });
	}

	render() {

		const skuListToExclude = this.props.predefinedSkuList.map(item => {
			return {
				value: item.id,
				label: item.list_name,
				datas: item.datas,
			}
		});

		var skuListToExcludeData = null;
		var include_exclude = this.props.workingTemplate.template_json.user_inventory_sku_list_to_exclude_id;
		if(this.state.rules === 'whitelist'){
			include_exclude = this.props.workingTemplate.template_json.user_inventory_sku_list_to_include_id;
		}

		this.props.predefinedSkuList.forEach(item => {
			if(item.id === include_exclude){
				skuListToExcludeData = {
					value: item.id,
					label: item.list_name,
					datas: item.datas,
				}
			}
		});

    return (
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>
							Inventory Targeting
							<TemplateTooltip
								id="t-inventory-targeting"
								text="Inventory targeting"
							/>
						</Label>
            <FormGroup check className="custom-check radio">
              <Label check>
				  <Input
					  type="checkbox"
					  onChange={() => this.changeCheckFBA('amazon_fulfill')}
					  checked={this.state.channel_target === "amazon_fulfill" || this.state.channel_target === "both"}
				  /> <span>FBA Inventory</span>
              </Label>
              <Label check>
				  <Input
					  type="checkbox"
					  onChange={() => this.changeCheckMF("merchant_fulfill")}
					  checked={this.state.channel_target === "merchant_fulfill" || this.state.channel_target === "both"}
				  /> <span>MF Inventory</span>
              </Label>
            </FormGroup>
			<AlertTooltip
				target="alert_channel_target"
				message={this.props.templateWarning}
				range={[8]}
			/>
          </FormGroup>

          <FormGroup>
            <Label>
							Product Salesrank
							<TemplateTooltip
								id="t-product-salesrank"
								text="Product salesrank"
							/>
						</Label>
            <div className="input-number-range">
              <InputGroup>
				<Input
					type="text"
					placeholder="Min"
					min="1"
					onChange={(e) => this.changeIntegerValue(e, "user_inventory", "product_salesrank_min")}
					value={this.state.product_salesrank_min.toLocaleString()}
				/>
			  </InputGroup>
				<hr />
              <InputGroup>
				<Input
					type="text"
					placeholder="Max"
          maxLength="11"
					onChange={(e) => this.changeIntegerValue(e, "user_inventory", "product_salesrank_max")}
					value={this.state.product_salesrank_max.toLocaleString()}
				/>
			  </InputGroup>
            </div>
			<AlertTooltip
				target="alert_product_salesrank"
				message={this.props.templateWarning}
				range={[2, 3, 9]}
			/>
          </FormGroup>

          <FormGroup>
            <Label>
							Inventory Price Range to Target
							<TemplateTooltip
								id="t-inventory-price-range"
								text="Set inventory price min and max to target"
							/>
						</Label>
            <div className="input-number-range">
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
				<Input
					type="text"
					placeholder="Min"
					min="1"
					onBlur={(e) => this.changeFloatValue(e, "user_inventory", "product_price_min")}
					value={this.state.product_price_min.toLocaleString()}
					onChange={e => this.changeValue('product_price_min', e)}
				/>
              </InputGroup>
              <hr />
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
				<Input
					type="text"
					placeholder="Max"
          maxLength="11"
					onBlur={(e) => this.changeFloatValue(e, "user_inventory", "product_price_max")}
					value={this.state.product_price_max.toLocaleString()}
					onChange={e => this.changeValue('product_price_max', e)}
				/>
              </InputGroup>
            </div>
			<AlertTooltip
				target="alert_product_price"
				message={this.props.templateWarning}
				range={[6, 7, 11]}
			/>
          </FormGroup>

          <FormGroup>
            <Label>
							Inventory Age
							<TemplateTooltip
								id="t-inventory-age"
								text="Inventory age"
							/>
						</Label>
            <div className="input-number-range">
              <InputGroup>
				  <Input
					type="text"
					placeholder="Min"
					min="1"
					onChange={(e) => this.changeIntegerValue(e, "user_inventory", "inventory_age_min")}
					value={this.state.inventory_age_min.toLocaleString()}
				  />
                <InputGroupAddon addonType="append">days</InputGroupAddon>
              </InputGroup>
              <hr />
              <InputGroup>
				  <Input
					type="text"
					placeholder="Max"
          maxLength="5"
					onChange={(e) => this.changeIntegerValue(e, "user_inventory", "inventory_age_max")}
					value={this.state.inventory_age_max.toLocaleString()}
				  />
                <InputGroupAddon addonType="append">days</InputGroupAddon>
              </InputGroup>
            </div>
			<AlertTooltip
				target="alert_inventory_age"
				message={this.props.templateWarning}
				range={[4, 5, 10]}
			/>
          </FormGroup>

          <FormGroup>
            <Label>
							Select item conditions to reprice
							<TemplateTooltip
								id="t-item-condition"
								text="Select item conditions to reprice"
							/>
						</Label>
            <Select
              options={this.state.itemConditions}
              value={this.state.conditions}
              multi
              onChange={this.onSelectCondition}
            />
          </FormGroup>
        </Col>

        <Col md={{ size: 5, offset: 2 }}>
          <FormGroup>
            <Label>
							Whitelist/Blacklist
							<TemplateTooltip
								id="t-whitelist-blacklist"
								text="You can only select EITHER whitelist rules OR blacklist rules, but not both."
							/>
						</Label>
            <Alert color="warning">NOTE: You can only select EITHER whitelist rules OR blacklist rules, but not both.</Alert>
            <FormGroup check className="custom-check radio">
              <Label check>
                <Input
                  type="radio"
                  name="rules"
                  checked={this.state.rules === 'whitelist'}
                  onChange={() => this.onRulesChange('whitelist')}
                /> <span>Whitelist</span>
              </Label>
              <Label check>
                <Input
                  type="radio"
                  name="rules"
                  checked={this.state.rules === 'blacklist'}
                  onChange={() => this.onRulesChange('blacklist')}
                /> <span>Blacklist</span>
              </Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>
							SKU List to <u>{this.state.rules === 'whitelist' ? 'Include' : 'Exclude'}</u>
							<TemplateTooltip
								id="t-sku-list"
								text="SKU list to include/exclude"
							/>
						</Label>
            <Select
              options={skuListToExclude}
              value={skuListToExcludeData}
              onChange={this.onInputSKUList}
            />
            <span className="input-help">Set up and edit your global lists in the <a href="/dashboard/repricer/lists">Settings page</a>! Changes there will automatically reflect to all templates using that global list.</span>
          </FormGroup>

          <FormGroup>
            <Label>
							Additonal MSKUs To <u>{this.state.rules === 'whitelist' ? 'Include' : 'Exclude'}</u> (comma separated list)
							<TemplateTooltip
								id="t-additional-msku"
								text="Additional MSKUs to include/exclude"
							/>
						</Label>
            <TagsInput
							value={
								this.state.rules === 'whitelist' ?
									this.state.skus_to_include ? this.state.skus_to_include : []
									: this.state.skus_to_exclude ? this.state.skus_to_exclude : []
							}
              onChange={this.onInputMSKUS}
            />
            <span className="input-help">Use an asterisk (*) to match any character. For example, ABC*DEF will match any MSKU starting in ABC and ending in DEF. You can use an asterisk in the beginning, middle, or end of your wildcard match.)</span>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}


export default connect(
  state => ({
	predefinedSkuList: state.Repricer.get("predefinedSkuList"),
	templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
	workingTemplateUpdateJson,
	userGetGlobalList,
  }
)(Inventory)
