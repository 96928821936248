import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { Tooltip } from "reactstrap";

class TooltipAtCustomElement extends Component {
  static propTypes = {
    tooltipText: string.isRequired,
    tooltipId: string.isRequired,
    CustomElement: func.isRequired,
    placement: string
  };

  static defaultProps = {
    placement: 'top'
  };

  state = {
    showPopover: false
  };

  togglePopover = () => {
    this.setState({
      showPopover: !this.state.showPopover
    })
  }

  render() {
    const { tooltipText, tooltipId, CustomElement, styles, placement } = this.props;
    const { showPopover } = this.state;
    return (
      <span
        id={tooltipId}
        style={{ cursor: "pointer"}}
      >
        <CustomElement />
        <Tooltip
          isOpen={showPopover}
          target={tooltipId}
          style={styles}
          toggle={this.togglePopover}
          placement={placement}
        >
          {tooltipText}
        </Tooltip>
      </span>
    )
  }
}
export default TooltipAtCustomElement
