import React, { Component, useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import {
  FaLongArrowLeft,
  //FaTrash,
  //FaFilePdfO
} from 'react-icons/lib/fa/';
import { backendAPICall, apiEndpoints } from '../../../helpers/backend/api/client';

// Hooks needed to solve scrolling problem on enter (new line)
const RichEditor = (props) => {
  const [lines, setLines] = useState(0);
  const editorRef = useRef(null);

  const handleEditorChange = function(e) {
    const prevLines = lines;

    if(prevLines !== e.blocks.length) {
      setLines(e.blocks.length);
    }
  };

  const uploadCallback = function(file) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file, file.name);
      const promise = new backendAPICall('post', apiEndpoints.sop.direct_image_upload, data);
      promise.then(response => {
        resolve({ data: { link: response.data.url } });
      })
    })
  };

  const config = {
    image: {
      uploadCallback: uploadCallback,
      uploadEnabled: true,
    }
  }

  useEffect(() => {
    const scrollDiv = editorRef.current.querySelector(".rdw-editor-main");
    scrollDiv.scrollTop = scrollDiv.scrollHeight;
  }, [lines]);

 return (
   <div ref={editorRef}>
     <Editor
       onChange={(e) => handleEditorChange(e)}
       toolbar={config}
       {...props}
     />
    </div>
 );
}

class SopEdit extends Component {
  state = {
    title: "",
    tags: [],
    category: null,
    description: null,
  }

  selectCategory = (category_id) => {
    const category = this.props.categories.find(item => item.id === category_id);
    if(category){
      return {
        label: category.name,
        value: category.id,
        item: category,
      }
    }
    return null;
  }

  UNSAFE_componentWillReceiveProps = () => {
    if(this.props.procedure_data.description && !this.state.description){
      var description = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.procedure_data.description)));
      var tags = this.props.procedure_data.tags_data.map(item => {
        return {
          label: item.name,
          value: item.id,
          item: item,
        }
      });
      this.setState({
        description: description,
        tags: tags,
        title: this.props.procedure_data.title,
      });
    }
  }

  componentDidMount = () => {
    if(this.props.procedure_data.description && !this.state.description){
      var description = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.procedure_data.description)));
      var tags = this.props.procedure_data.tags_data.map(item => {
        return {
          label: item.name,
          value: item.id,
          item: item,
        }
      });
      this.setState({
        description: description,
        tags: tags,
        title: this.props.procedure_data.title,
      });
    }
  }

  updateDescription = (value) => {
    this.setState({ description: value });
  }

  onTagsChange = (tags) => {
    this.setState({ tags })
  }

  updateProcedure = () => {
    if(!this.validDataSet()){
      return;
    }
    const { title, category, description, tags } = this.state;
    const data = {
      procedure_id: this.props.procedure_data.id,
      title: title ? title : this.props.procedure_data.title,
      tags: tags.map(item => {return item.value}).join(','),
      category: category ? category.value : this.props.procedure_data.category,
      description: description ? JSON.stringify(convertToRaw(description.getCurrentContent())) : null,
    }
    this.props.updateSopProcedure(data);
  }

  validDataSet = () => {
    const { title, description } = this.state;
    if(
      !title.trim()
      || !description.getCurrentContent().getPlainText().trim()
    ) {
      return false;
    }
    return true;
  }

  render() {
    const {
      tags,
    } = this.state;

    const { procedure_data, categories } = this.props;

    return (
      <div className="sop-create-edit">
        <Card>
          <CardHeader>
            Edit procedure
            <NavLink to="/dashboard/sop" className="ml-2"><FaLongArrowLeft /> Back</NavLink>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  placeholder="Provide procedure title"
                  defaultValue={procedure_data.title}
                  onChange={e => this.setState({title: e.target.value})}
                />
              </FormGroup>
              <FormGroup row>
                <Col md={6}>
                  <Label>Category</Label>
                  <Select
                    options={
                      categories.map(item => {
                        return {
                          label: item.name,
                          value: item.id,
                          item: item,
                        }
                      })
                    }
                    onChange={e => this.setState({ category: e })}
                    value={this.state.category ? this.state.category : this.selectCategory(procedure_data.category)}
                  />
                </Col>
                <Col md={6}>
                  <Label>Tags</Label>
                  <CreatableSelect
                    name="tags"
                    value={tags}
                    onChange={this.onTagsChange}
                    options={
                      this.props.tags
                        ? this.props.tags.map(item => {
                            return {
                              label: item.name,
                              value: item.id,
                              item: item,
                            }
                          })
                        : []
                    }
                    multi={true}
                    isValidNewOption={() => false}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label>Content</Label>
                <RichEditor
                  placeholder="Explain your standard operating procedures"
                  onEditorStateChange={this.updateDescription}
                  editorState={this.state.description}
                />
              </FormGroup>
              {/*
              <FormGroup>
                <Label>Change/delete document (PDF/Word)</Label>
                <div className="uploaded-file">
                  <span className="file"><FaFilePdfO /> <span className="file-name">LTL_Shipments_SOP.pdf</span></span>
                  <Button color="link" className="text-danger"><FaTrash size="18" /></Button>
                </div>
                <Input type="file" />
              </FormGroup>
              */}
            </Form>
            <div className="d-flex justify-content-between mt-4">
              <Button
                color="primary"
                onClick={() => this.updateProcedure()}
                disabled={this.props.update_procedure_working || !this.validDataSet()}
              >SAVE CHANGES</Button>
              <NavLink to="/dashboard/sop" className="btn btn-cancel">CANCEL</NavLink>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default SopEdit;
