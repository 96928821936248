import React, { Component, Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Button,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  FormGroup,
	FormText,
	Alert,
	Label
} from 'reactstrap';
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import map from "lodash/map";
import userTagsActions from "../../../redux/settings/user_tags/actions";
import { generateTemplatedSKU } from '../../../helpers/batch/utility';
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/lib/md";

const { addUserTag, deleteUserTag, getUserTag } = userTagsActions;

//for example SKU
const dummyListingData = {
  "skuNumber": 2,
  "buyCost": 12,
  "supplier": "Walmart",
  "datePurchased": "2019-11-20T01:51:38.254090",
  "expDate": "2020-05-08T01:51:38.254090",
  "price": 23.00,
  "condition": "NewItem",
  "taxCode": "A_GEN_TAX",
  "scout": "Scout",
  "asin": "0062899961",
  "salesrank": 1543,
  "warehouse_location": "D5-1004",
}

class NewCustomSKUSelector extends Component {
	constructor () {
		super();
		this.state = {
			loadedForFirstTime: true,
			customTagsInput: '',
			invalidTagName: false,
			saveMessageFlashing: false,
			userTags: [],
			unusedTags: [],
			SKUTemplate: [],
		}
	}

  UNSAFE_componentWillReceiveProps(newProps) {
		this.updateSKUs(newProps.sku);
	}

	componentDidMount() {
		this.props.getUserTag();
    this.setState({
			unusedTags: [...this.props.customSKUSelectorInitialTags.unusedTags],
			SKUTemplate: [...this.props.customSKUSelectorInitialTags.SKUTemplate],
    });
    this.updateSKUs(this.props.sku);
	}

	updateUserDataTag(tags){
		const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["custom_sku_tags"] = tags;
			this.props.updateUserData(ud);
		}
	}

	updateSKUs = (sku_settings) => {
		const { loadedForFirstTime, unusedTags } = this.state;
    let SKUs = sku_settings.split("-");
    let parsedSKUs = [];
    let preDefinedSKUs = [];
    if(!sku_settings){ return; }
    map(SKUs, sku => {
      if(sku.length > 2) {
        if(sku[0] === "{" && sku[sku.length -1 ] === "}") {
          let value = sku.substring(1, sku.length -1);
          let label = filter(this.props.customSKUSelectorInitialTags.unusedTags, tag => tag.value === value);
          //accomodate custom added tags [text]
          if(label.length > 0){
            label = label[0].label;
          } else {
            label = sku.substring(1, sku.length -1).replace(/-/g, '');
            value = value.replace(/-/g, '');
          }
          parsedSKUs.push({ value, label });
          preDefinedSKUs.push(value);
        } else {
          parsedSKUs.push({ value: sku, label: sku });
        }
      } else {
        parsedSKUs.push({ value: sku, label: sku });
      }
    });
    this.setState({ SKUTemplate: parsedSKUs }, () => {
      const unusedSKUs = filter(unusedTags, tag => !preDefinedSKUs.includes(tag.value));
      this.setState({ unusedTags: unusedSKUs });
    });
	}

	addTagSKUTemplate = (element, user_tag=false) => {
		let unusedBuffArr = this.state.unusedTags;
		let skuTemplateBuffArr = cloneDeep(this.state.SKUTemplate);
		const buffElement = filter(unusedBuffArr, sku => sku.value !== element.value);
		if(user_tag){
			if(skuTemplateBuffArr.find(x => x.value === element.value)){
				return;
			}
		}
		//integer count add at start initialy (as per current settings)
		if(element.value === 'sku_number'){
			skuTemplateBuffArr.unshift(element);
		} else {
			//if integer count is at the end move it to back when adding
			if(skuTemplateBuffArr.length > 0){
				if(skuTemplateBuffArr[skuTemplateBuffArr.length-1].value === 'sku_number'){
					let t = element;
					element = skuTemplateBuffArr.pop();
					skuTemplateBuffArr.push(t);
				}
			}
			skuTemplateBuffArr.push(element);
		}
		this.setState({
			unusedTags: buffElement,
			SKUTemplate: skuTemplateBuffArr
		})
	}


	addCustomTagSKUTemplate = () => {
		let val = this.state.customTagsInput.trim();
		if(this.state.userTags.includes(val)){
			return;
		}
		if (this.state.customTagsInput.trim().length > 0) {
			let buffArr = cloneDeep(this.state.SKUTemplate);
			let label = this.state.customTagsInput.trim();
			let count_at_end = false;
			val = val.replace(/-/g, '');
			val = val.replace(/\|/g, '');
			label = label.replace(/-/g, '');
			label = label.replace(/\|/g, '');
			let element = { value: `${val}`, label: label };
			let t = element;
			if(buffArr.length > 0){
				if(buffArr[buffArr.length-1].value === 'sku_number'){
					element = buffArr.pop();
					buffArr.push(t);
					count_at_end = true;
				}
			}
			buffArr.push(element);
			if(count_at_end){
				this.addToUserTags(t);
			} else {
				this.addToUserTags(element);
			}
			this.setState({
				SKUTemplate: buffArr,
				customTagsInput: ''
			})
		}
	}

	delFromUserTags = (element) => {
		let tags = this.state.userTags;
		var x = tags.indexOf(element.value);
		if(x !== -1){
			tags.splice(x, 1);
		}
		this.props.deleteUserTag(tags.join('|'));
		this.updateUserDataTag(tags.join('|'));
		this.setState({userTags: tags});
	}

	addToUserTags = (element) => {
		let tags = this.state.userTags;
		tags.push(element.value);
		this.props.addUserTag(tags.join('|'));
		this.updateUserDataTag(tags.join('|'));
		this.setState({userTags: tags});
	}

	delSKUTemplateElement = (element) => {
		let unusedBuffArr = this.state.unusedTags;
		let skuTemplateBuffArr = cloneDeep(this.state.SKUTemplate);

		skuTemplateBuffArr = filter(skuTemplateBuffArr, sku => sku.value !== element.value);

		if (this.props.customSKUSelectorInitialTags.unusedTags.some(el => element.value === el.value)) {
			if (unusedBuffArr.some(el => element.value === el.value)) {
				return;
			} else {
				unusedBuffArr.push(element);
			}
		}
		this.setState({
			unusedTags: unusedBuffArr,
			SKUTemplate: skuTemplateBuffArr
		})
	}

	/*
	 * move integer count just to start or end
	 */
	moveIntegerCount = (element) => {
		let skuTemplateBuffArr = cloneDeep(this.state.SKUTemplate);
		if(skuTemplateBuffArr.length > 0){
			if(skuTemplateBuffArr[skuTemplateBuffArr.length-1].value === 'sku_number'){
				const d = skuTemplateBuffArr.pop();
				skuTemplateBuffArr.unshift(d);
			} else if(skuTemplateBuffArr[0].value === 'sku_number'){
				const d = skuTemplateBuffArr.shift();
				skuTemplateBuffArr.push(d)
			}
		}
		this.setState({
			SKUTemplate: skuTemplateBuffArr
		})
	}

	/*
	 * move custom SKU element to right
	 */
	moveSKUElementRight = (element) => {
		let skuTemplateBuffArr = cloneDeep(this.state.SKUTemplate);
		const { value } = element;
		let pos = null;
		if(skuTemplateBuffArr.length > 0){
			skuTemplateBuffArr.forEach((e, i) => {
				if(e.value === value){
					pos = i;
				}
			});
			//if not last already
			if(pos+1 < skuTemplateBuffArr.length){
				const d = skuTemplateBuffArr[pos];
				//if not item count as last
				if(skuTemplateBuffArr[pos+1].value !== 'sku_number'){
					skuTemplateBuffArr[pos] = skuTemplateBuffArr[pos+1]
					skuTemplateBuffArr[pos+1] = d;
				}
			}
		}
		this.setState({
			SKUTemplate: skuTemplateBuffArr
		})
	}

	/*
	 * move custom SKU element to left
	 */
	moveSKUElementLeft = (element) => {
		let skuTemplateBuffArr = cloneDeep(this.state.SKUTemplate);
		const { value } = element;
		let pos = null;
		if(skuTemplateBuffArr.length > 0){
			skuTemplateBuffArr.forEach((e, i) => {
				if(e.value === value){
					pos = i;
				}
			});
			//if not first already
			if(pos > 0){
				const d = skuTemplateBuffArr[pos]
				//if not item count as last
				if(skuTemplateBuffArr[pos-1].value !== 'sku_number'){
					skuTemplateBuffArr[pos] = skuTemplateBuffArr[pos-1]
					skuTemplateBuffArr[pos-1] = d;
				}
			}
		}
		this.setState({
			SKUTemplate: skuTemplateBuffArr
		})
	}

	flashSaveMessage(kill=false) {
		//flash message on save
		if(kill){
			this.setState({	saveMessageFlashing: false });
			return;
		}
		setTimeout(() => {
		  this.flashSaveMessage(true);
		}, 2000);
	}

	generateSKU = () => {
    const { SKUTemplate } = this.state;
    let sku_prefix = "";
    map(SKUTemplate, SKU => {
      //if(SKU.value !== SKU.label) sku_prefix += `{${SKU.value}}-`;
      //else sku_prefix += `${SKU.value}-`;
      sku_prefix += `{${SKU.value}}-`;
    });
    sku_prefix = sku_prefix.substr(0, sku_prefix.length - 1);
    return sku_prefix;
	}

	saveSKUTemplates = () => {
		this.setState({saveMessageFlashing: true});
		const sku_prefix = this.generateSKU();
    this.props.saveSKUInSettings(sku_prefix);
		this.flashSaveMessage();
	}

	changeHandleCustomTagInput = (e) => {
		if (this.props.customSKUSelectorInitialTags.unusedTags.includes(e.target.value) || this.state.SKUTemplate.includes(e.target.value)) {
		  this.setState({
			invalidTagName: true,
			customTagsInput: e.target.value
		  })
		} else {
		  this.setState({
			invalidTagName: false,
			customTagsInput: e.target.value
		  })
		}
	  }

	toggleSetLockSuffixInteger = () => {
			const { userData } = this.props;
			if(userData){
				let ud = userData;
				ud.settings.sku_lock_sufix_activated = !ud.settings.sku_lock_sufix_activated;
				this.props.updateUserData(ud);
				const data = {sku_lock_sufix_activated: ud.settings.sku_lock_sufix_activated}
				this.props.updateUserSettings(data);
			}
	}

	changeSuffixInteger = (type, val=null) => {
			const { userData } = this.props;
			if(userData){
				let ud = userData;
				if(ud.settings.sku_lock_sufix_integer){
					if(type === 'inc'){
						ud.settings.sku_lock_sufix_integer = ud.settings.sku_lock_sufix_integer + 1;
					} else if(type === 'dec') {
						ud.settings.sku_lock_sufix_integer = ud.settings.sku_lock_sufix_integer - 1;
					} else {
						if(!val){
							val = '1';
						}
						ud.settings.sku_lock_sufix_integer = parseInt(val, 10);
					}
				} else {
					ud.settings.sku_lock_sufix_integer = 1;
				}
				this.props.updateUserData(ud);
				const data = {sku_lock_sufix_integer: ud.settings.sku_lock_sufix_integer}
				this.props.updateUserSettings(data);
			}
	}

	render() {
		const {
			customTagsInput,
			unusedTags,
			SKUTemplate,
			invalidTagName,
		} = this.state;
		const {
			isOpen,
		} = this.props;

		return (
			<Fragment>
				{ !!isOpen ? (
					<Row className="custom-sku-selector mt-3">
						<Col md={12}>
							<Card>
								<CardBody>
									<CardTitle>Unused Tags</CardTitle>
									<div className="dashedZone">
										<p>Choose which things you want to include in your custom MSKU!</p>
										<div>
											{
											unusedTags.map( element => {
												return(
												<div
													key={element.value + Math.random()}
													className="unused-items"
												>
													{element.label}
													<button onClick={() => this.addTagSKUTemplate(element)} className="btn btn-link">
													<span className="icon icon-plus bg-primary"></span>
													</button>
												</div>
												)
											})
											}
											{
											this.state.userTags.map( element => {
												return(
												<div
													key={element + Math.random()}
													className="unused-items"
													style={{ placeSelf: "flex-end" }}
												>
													{element}
													<button
														onClick={() => this.delFromUserTags(
															{value: element, label: element})}
														className="btn btn-link">
													<span className="icon icon-minus bg-danger ml-2"></span>
													</button>
													<button
														onClick={() => this.addTagSKUTemplate(
															{value: element, label: element}, true)}
														className="btn btn-link ml-2">
													<span className="icon icon-plus bg-primary"></span>
													</button>
												</div>
												)
											})
											}
										</div>
										<br />

										<FormGroup className="mb-0">
											<InputGroup>
												<Input
													onChange={this.changeHandleCustomTagInput}
													placeholder="Custom Field Text"
													style={{ maxWidth: '200px'}}
													id="TagNameInput"
													value={customTagsInput}
													maxlength="20"
												/>
												<InputGroupAddon addonType="append">
													<Button
														color="info"
														onClick={this.addCustomTagSKUTemplate}
														disabled={invalidTagName}
													>Add</Button>
												</InputGroupAddon>
											</InputGroup>
											<FormText>Max. 20 characters</FormText>
											{
												invalidTagName && (
													<Tooltip placement="left" isOpen={invalidTagName} target="TagNameInput">
													Invalid tag name. Such tag name already exists.
													</Tooltip>
												)
											}
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						</Col>
            <Col md={12}>
							<Card className="my-4">
								<CardBody>
								<CardTitle>Example MSKU</CardTitle>
									<Col xs="12" className="dashedZone">
										<div className="sku-example">
										  {generateTemplatedSKU(dummyListingData, this.generateSKU(), this.props.userData).replace(/{count}/g, '9')}
										</div>
									</Col>
								</CardBody>
							</Card>

							<Card>
								<CardBody>
									<CardTitle>
										<span>Generated MSKU Template</span>
									</CardTitle>
									<Col xs="12" className="dashedZone">
										<p>Note that MSKUs can only be 39 characters long. We will truncate the end of the MSKU if it is too long</p>
										<div>
											{
											SKUTemplate.length === 0 ?
											<div>No tags added to template so far!</div>
											:
											SKUTemplate.map( element => {
												return(
												<div
													key={element.value + Math.random()}
													className="sku-template"
												>
													{(element.value === 'sku_number') ? (
													null) : (
													<button onClick={() => this.moveSKUElementLeft(element)} className="btn btn-link mr-2">
														<span className="icon icon-10 icon-chevron-left"></span>
													</button>
													)}
													{element.label}
													{(element.value === 'sku_number') ? (
														<button onClick={() => this.moveIntegerCount(element)} className="btn btn-link mx-2">
															<span className="icon icon-swap"></span>
														</button>
													) : (null)}
													<button onClick={() => this.delSKUTemplateElement(element)} className="btn btn-link ml-1">
														<span className="icon icon-minus bg-danger"></span>
													</button>
													{(element.value === 'sku_number') ? (
													null) : (
													<button onClick={() => this.moveSKUElementRight(element)} className="btn btn-link ml-2">
														<span className="icon icon-10 icon-chevron-right"></span>
													</button>
													)}
												</div>
												)
											})
											}
										</div>
									</Col>
								</CardBody>
							</Card>
						</Col>
						<Col md={12}>
						{this.props.showAdditionalSettings ?
							<Card>
								<CardBody>
									<CardTitle>Settings</CardTitle>
									<FormGroup>
										<FormGroup check className="custom-check checkbox">
											<Label check>
												<Input
													type="checkbox"
													className="ml-0 mr-1"
													checked={
														this.props.userData
															&& this.props.userData.settings
															&& this.props.userData.settings.sku_lock_sufix_activated}
													onChange={this.toggleSetLockSuffixInteger}
												/>
												<span>Set and lock suffix integer</span>
											</Label>
										</FormGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup style={{ width: "100px" }}>
											<Input
												type="number"
												value={
													this.props.userData
														&& this.props.userData.settings
														&& this.props.userData.settings.sku_lock_sufix_activated ?
														this.props.userData.settings.sku_lock_sufix_integer : 0
												}
												onChange={e => this.changeSuffixInteger("val", e.target.value)}
												className="readonly-white-bg"
											/>
											<InputGroupAddon addonType="append" className="vertical-elements">
												<Button
													disabled={this.props.userData && this.props.userData.settings && !this.props.userData.settings.sku_lock_sufix_activated}
													onClick={() => this.changeSuffixInteger("inc")}
												><MdArrowDropUp size="18" /></Button>
												<Button
													disabled={this.props.userData && this.props.userData.settings && !this.props.userData.settings.sku_lock_sufix_activated}
													onClick={() => this.changeSuffixInteger("dec")}
												><MdArrowDropDown size="18" /></Button>
											</InputGroupAddon>
										</InputGroup>
									</FormGroup>
								</CardBody>
							</Card>
							: null
						}
						</Col>
						<Col md="12">
							<Button
                  onClick={e => this.saveSKUTemplates()}
									color="success"
									disabled={this.state.saveMessageFlashing}
							>Save</Button>
							{ this.state.saveMessageFlashing
								? (
									<strong className="text-success ml-2">
										MSKU template saved
									</strong>
									)
								: (null)
							}
						</Col>
					</Row>
				) : ''}
			</Fragment>
		)
	}
}

export default connect(
  state => ({
		userTags: state.UserTags.get("userTags"),
  }),
  {
    addUserTag,
    getUserTag,
    deleteUserTag,
  }
)(NewCustomSKUSelector);
