import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardGroup } from 'reactstrap';
import LoadingIndicator from '../../../../../shared/components/LoadingIndicator';
import repricerActions from '../../../../../redux/repricer/actions';
import { digitСonversion } from '../../../../../helpers/utility';
import { search } from '../../../../../assets/images';

const { userRepricerReportSummary } = repricerActions;

class ReportSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

	UNSAFE_componentWillMount(){
		this.props.userRepricerReportSummary(this.props.id)
	}

  render() {
	const { viewDetail, repriceReportSummary } = this.props;
	const { reprice_report_summary } = repriceReportSummary;

    return (
      <div className="report-summary">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Quick Report Summary</h5>
          <Button color="link" onClick={() => viewDetail(this.props.id, this.props.lastRun)}>
            <img src={search} alt="Search Icon" width="18" className="mr-1" />
            <span>View Detailed Summary</span>
          </Button>
        </div>

		{this.props.repriceReportSummaryLoading
				|| !repriceReportSummary
				|| !reprice_report_summary ? <LoadingIndicator className="mt-0" title="Fetching Reprice Reports Summary..." />
        :
        <Fragment>
          <div className="report-summary-metrics">
            <div className="summary-metric">
              <span className="title"><span className="icon icon-history"></span> Last Run</span>
              <span className="value">{this.props.lastRun}</span>
            </div>
            <div className="summary-metric">
              <span className="title"><span className="icon icon-calendar-add"></span> SKUs Scheduled</span>
              <span className="value">{digitСonversion(reprice_report_summary.num_skus)}</span>
            </div>
            <div className="summary-metric">
              <span className="title"><span className="icon icon-adjust"></span> SKUs Adjusted</span>
              <span className="value">{reprice_report_summary.num_skus_adjusted}</span>
            </div>
          </div>

          <CardGroup className="report-summary-stat">
            <Card>
              <span className="title">AVG. SALES PRICE</span>
			  <span className="value text-success">{digitСonversion(reprice_report_summary.avg_price, 'currency', this.props.internationalization_config.currency_code)}</span>
            </Card>
            <Card>
              <span className="title">AVG. ADJUSTED AMOUNT</span>
              <span className={`value ${reprice_report_summary.avg_adjustment >= 0 ? "text-success" : "text-danger"}`}>
                {digitСonversion(reprice_report_summary.avg_adjustment, 'currency', this.props.internationalization_config.currency_code)}
              </span>
            </Card>
            <Card>
              <span className="title">LOWEST PRICE</span>
              <span className="value text-success">{digitСonversion(reprice_report_summary.lowest_price, 'currency', this.props.internationalization_config.currency_code)}</span>
            </Card>
            <Card>
              <span className="title">HIGHEST PRICE</span>
              <span className="value text-success">{digitСonversion(repriceReportSummary.reprice_report_summary.highest_price, 'currency', this.props.internationalization_config.currency_code)}</span>
            </Card>
          </CardGroup>
        </Fragment>
        }
      </div>
    );
  }
}

export default connect(
  state => ({
	repriceReportSummary: state.Repricer.get("repriceReportSummary"),
	repriceReportSummaryLoading: state.Repricer.get("repriceReportSummaryLoading"),
	internationalization_config: state.Auth.get("internationalization_config"),
  }),
	{
		userRepricerReportSummary,
	}
)(ReportSummary);
