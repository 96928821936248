import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import TemplateType from './TemplateType';
import Inventory from './Inventory';
import OfferCount from './OfferCount';
import OfferFilters from './OfferFilters';
import PricingStrategy from './PricingStrategy';
import GuardRails from './GuardRails';
import TestTemplate from './TestTemplate';
import './style.css';
import repricerActions from '../../../../../redux/repricer/actions';
import { validateTemplateData } from '../../../../../helpers/repricer/template_utils';
import { templateConvertToBackend } from '../../../../../helpers/repricer/converter/template_converter';
import AlertPill from "../../../../../shared/components/AlertPill";
import IconVideo from "react-icons/lib/md/ondemand-video";

const {
  userSaveWorkingTemplate,
  userUpdateWorkingTemplate,
  updateWarningText,
  updateWarning,
} = repricerActions;

const tutorialVideos = {
  "1": "https://www.youtube.com/watch?v=obRngyjuu3c&list=PLqHzZJdf9cuM-DneWRo1xYQM1ARVC-kyv",
  "2": "https://www.youtube.com/watch?v=eT8FBxBLKFk&list=PLqHzZJdf9cuM-DneWRo1xYQM1ARVC-kyv&index=2",
  "3": "https://www.youtube.com/watch?v=OU6HOVFQnPM&list=PLqHzZJdf9cuM-DneWRo1xYQM1ARVC-kyv&index=5",
  "4": "https://www.youtube.com/watch?v=CLqcoxcSv-w&list=PLqHzZJdf9cuM-DneWRo1xYQM1ARVC-kyv&index=4",
  "5": "https://www.youtube.com/watch?v=ayLpxQPI3Og&list=PLqHzZJdf9cuM-DneWRo1xYQM1ARVC-kyv&index=3",
}

class TemplateEditorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
    step: 1,
    }
  }

  UNSAFE_componentWillReceiveProps(np){
    if(this.props.editorOpen === false && np.editorOpen === true){
      this.setState({ step: 1 });
    }
  }

  onNext = (step=null) => {
    let currentStep = this.state.step;
    let nextStep = (currentStep+1) === 8 ? currentStep : currentStep+1;
    if(step){
      currentStep = this.state.step;
      nextStep = step;
    }
    const validation = validateTemplateData(this.props.workingTemplate, currentStep);
    if(validation.validated){
        this.setState({ step: nextStep });
    }
    this.props.updateWarningText({text: validation.text});
    this.props.updateWarning({data: validation});
  }

  onPrevious = () => {
    let currentStep = this.state.step;
    let prevStep = (currentStep-1) === 0 ? currentStep : currentStep-1;

    this.setState({
      step: prevStep
    });
  }

  saveTemplate = () => {
    const { workingTemplate } = this.props;
    var template_json = templateConvertToBackend(workingTemplate);
    template_json = JSON.stringify(template_json).replace(/"/g, '\'');
    this.props.userSaveWorkingTemplate({ template_json: template_json });
    this.onNext();
  }

  updateTemplate = () => {
    const { workingTemplate } = this.props;
    const template_json = templateConvertToBackend(workingTemplate);
    const template = JSON.stringify(template_json).replace(/"/g, '\'');
    this.props.userUpdateWorkingTemplate(
      {
        template_json: template,
        template_id: this.props.workingTemplate.template_json.template_id,
      }
    );
    this.onNext();
  }

  shouldDisableNext = (step, validation=null) => {
    var disabled = false;
    if(validation===null){
      validation = validateTemplateData(this.props.workingTemplate, step);
    }
    switch (step){
      case 1:
        if(this.props.workingTemplate?.template_json?.template_id){
          disabled = !this.props.workingTemplate?.name || !this.props.workingTemplate?.name?.trim();
        } else {
          disabled = !this.props.templateNameOK || !this.props.workingTemplate?.name || !this.props.workingTemplate?.name?.trim();
        }
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        disabled = !validation.validated;
        break;
      case 6:
        disabled = !validation.validated;
        break;
      case 7:
        break;
      default:
        disabled = false;
    }
    return disabled;
  }

  nextButtonGenerate = (step) => {
    var on_click = this.onNext;
    var label = this.props.workingTemplate?.template_json?.template_id ? "SAVE & PROCEED" : "NEXT";
    const validation = validateTemplateData(this.props.workingTemplate, step);
    switch (step){
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.props.updateWarningText({text: validation.text});
        this.props.updateWarning({data: validation});
        on_click = this.props.workingTemplate?.template_json?.template_id ? this.updateTemplate : this.onNext;
        break;
      case 6:
        this.props.updateWarningText({text: validation.text});
        this.props.updateWarning({data: validation});
        on_click = this.props.workingTemplate?.template_json?.template_id ? this.updateTemplate : this.saveTemplate;
        label = this.props.workingTemplate?.template_json?.template_id ? "SAVE & PROCEED" : "SAVE";
        break;
      case 7:
        on_click = this.props.toggleEditor;
        label="FINISH";
        break;
      default:
        label = this.props.workingTemplate?.template_json?.template_id ? "SAVE & PROCEED" : "NEXT";
    }
    const disabled = this.shouldDisableNext(step, validation);
    return (
      <React.Fragment>
        <Button
          color="success"
          onClick={e => on_click()}
          disabled={disabled || this.props.savingTemplate}
        >{label}</Button>
      </React.Fragment>
    );
  }

  getToCreateSchedule = () => {
    this.props.toggleEditor();
    this.props.updateActiveRepricerTab(5);
  }

  startOverTemplate = () => {
    this.props.startOver();
    this.setState({ step: 1 });
  }

  bcClick = (step) => {
    for (var i = 1; i <= step; i++) {
      //disable click if issue on previous steps
      if(this.shouldDisableNext(i - 1)){ return }
    }
    this.nextButtonGenerate(step);
    this.onNext(step);
    this.setState({ step });
  }

  render() {
    const {
      editorOpen,
      toggleEditor,
      editMode
    } = this.props;

    const {
      step
    } = this.state;

    //console.log('WORKING TEMPLATE DATA:', this.props.workingTemplate);

    return (
      <Modal
        isOpen={editorOpen}
        toggle={toggleEditor}
        className="modal-dialog-template-editor"
      >
        <ModalHeader toggle={toggleEditor}>{editMode ? "Edit" : "Add New"} Repricer Template</ModalHeader>
        <ModalBody>
          <Nav pills className="nav-dot">
            <NavItem active={step > 0} className={step > 1 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(1) }
              >Template Type</NavLink>
            </NavItem>
            <NavItem active={step > 1} className={step > 2 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(2) }
              >Choose Inventory</NavLink>
            </NavItem>
            <NavItem active={step > 2} className={step > 3 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(3) }
              >Offer Count</NavLink>
            </NavItem>
            <NavItem active={step > 3} className={step > 4 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(4) }
              >Set Offer Filters</NavLink>
            </NavItem>
            <NavItem active={step > 4} className={step > 5 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(5) }
              >Set Pricing Strategy</NavLink>
            </NavItem>
            <NavItem active={step > 5} className={step > 6 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(6) }
              >Set Guard Rails</NavLink>
            </NavItem>
            <NavItem active={step > 6} className={step >= 7 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
                onClick={ e => this.bcClick(7) }
              >Save Template</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={step} className="tab-content-repricer-template">
            <a href="https://accelerlist.helpjuice.com/profit-reprice/860591-how-to-set-up-repricing-templates"
              target="_blank"
              rel="noopener noreferrer"
              className="d-block text-center mb-3"
            >
              <span className="icon icon-help-circle"></span> How to set up Repricing templates
            </a>
            <TabPane tabId={1}>
              <ContentHeader
                title="Choose a template type"
                description="Select pre-configured templates below or build your own template."
              />
              { step === 1 && <TemplateType workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={2}>
              <ContentHeader
                title="Choose inventory to target"
                description="Apply to inventory matching the following criteria."
              />
              { step === 2 && <Inventory  workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={3}>
              <ContentHeader
                title="Offer Count"
                description="Product listing has more than 20 offers."
              />
              { step === 3 && <OfferCount workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={4}>
              <ContentHeader
                title="Set your offer filters"
                description="Manage and filter offers for repricing."
              />
              { step === 4 && <OfferFilters  workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={5}>
              <ContentHeader
                title="Set your pricing strategy"
                description="Pricing strategy for all scenarios."
              />
              { step === 5 &&<PricingStrategy  workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={6}>
              <ContentHeader
                title="Set your guard rails"
                description="Optimize and protect your profits."
              />
              { step === 6 && <GuardRails  workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
            <TabPane tabId={7}>
              <ContentHeader
                title="Your template has been saved"
                description='Please click either the "Finish" button or click the "Create Schedule" button to apply a repricing schedule to this template.'
              />
              { step === 7 && <TestTemplate  workingTemplate={this.props.workingTemplate} /> }
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          {step !== 1 && step !== 7 ?
            <Button onClick={this.startOverTemplate}>START OVER</Button>
            : null
          }
          {/*
          {step === 1 ?
            <Button color="primary">QUICK SETUP</Button>
            : null
          }
          */}
          {step !== 1 && step !== 7 ?
          <Button onClick={this.onPrevious}>PREVIOUS</Button>
          : null}
          {step === 7 ?
            <Button
              color="primary"
              onClick={this.getToCreateSchedule}
              disabled={this.props.savingTemplate}
              >CREATE SCHEDULE</Button>
            : null
          }
          { this.props.workingTemplate ? this.nextButtonGenerate(step) : null }
        </ModalFooter>
      </Modal>
    );
  }
}

const ContentHeader = props => (
  <div className="content-header">
    <h2 className="heading">{props.title}</h2>
    <p className="description">{props.description}</p>
  </div>
);

export default connect(
  state => ({
    templateWarningText: state.Repricer.get("templateWarningText"),
    savingTemplate: state.Repricer.get("savingTemplate"),
    templateNameOK: state.Repricer.get("templateNameOK"),
  }),
  {
    userSaveWorkingTemplate,
    userUpdateWorkingTemplate,
    updateWarningText,
    updateWarning,
  }
)(TemplateEditorModal);
