import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import sopActions from '../../../redux/sop/actions';

const {
  getSopCategories,
  saveSopCategory,
  deleteSopCategory,
  updateSopCategory,
  getSopTags,
  saveSopTag,
  deleteSopTag,
  updateSopTag,
} = sopActions;

class SopManage extends Component {
  state = {
    new_category_name: "",
    update_category_names: {},
    shwo_duplicate_tag_name_error: false,
  }

  onTagsChange = (tags, changed) => {
    this.setState({ shwo_duplicate_tag_name_error: false });
    var current_tags = this.props.tags.map(item => { return item.name});
    if(
      tags.includes(changed[0])
      && this.props.tags.find(item => item.name === changed[0])
    ){
      this.setState({ shwo_duplicate_tag_name_error: true });
      return;
    }
    tags.forEach(item => {
      if(!current_tags.includes(item)){
        current_tags.push(item);
        const data = {
          name: item,
        }
        this.props.saveSopTag(data);
      }
    })
    this.props.tags.forEach(item => {
      if(!tags.includes(item.name)){
        const data = {
          tag_id: item.id,
        }
        this.props.deleteSopTag(data);
      }
    })
  }

  componentDidMount = () => {
    this.props.getSopCategories();
    this.props.getSopTags();
  }

  addNewCategory = () => {
    const data = {
      name: this.state.new_category_name,
    }
    this.props.saveSopCategory(data);
    this.setState({ new_category_name: "" });
  }

  deleteCategory = (category_id) => {
    const data = {
      category_id: category_id,
    }
    this.props.deleteSopCategory(data);
  }

  updateCategoryName = (id, value) => {
    var update_val = this.state.update_category_names;
    update_val[id] = value;
    this.setState({update_category_names: update_val});
  }

  updateCategory = (category_id) => {
    if(this.state.update_category_names[category_id]){
      const data = {
        category_id: category_id,
        name: this.state.update_category_names[category_id],
      }
      this.props.updateSopCategory(data);
    }
  }

  render() {
    return (
      <div className="sop-manage">
        <Row>
          <Col md={6}>
            <h3 className="h6 mb-4">Manage Category</h3>
            <div className="categories">
              {this.props.categories.map((item) => (
                item.id === 0 ?
                  <FormGroup key={"uncategorized"} row>
                    <Col sm={8}>
                      <Input defaultValue="Uncategorized" readOnly />
                    </Col>
                  </FormGroup>
                  :
                  <FormGroup key={`category-${item.id}`} row>
                    <Col sm={8}>
                      <Input
                        placeholder="Category name"
                        defaultValue={item.name}
                        onChange={e => this.updateCategoryName(item.id, e.target.value)}
                        value={this.state.update_category_names[item.id]}
                      />
                    </Col>
                    <Col sm={4}>
                      <Button
                        onClick={() => this.updateCategory(item.id)}
                        disabled={this.props.update_category_working}
                      >Update</Button>
                      <Button
                        color="link"
                        className="ml-2"
                        onClick={() => this.deleteCategory(item.id)}
                        disabled={this.props.delete_category_working}
                      ><span className="icon icon-trash bg-danger"></span></Button>
                    </Col>
                  </FormGroup>
              ))}
              <FormGroup row>
                <Col sm={8}>
                  <Input
                    placeholder="New category name"
                    value={this.state.new_category_name}
                    onChange={e => this.setState({ new_category_name: e.target.value })}
                    disabled={this.props.save_category_working}
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    color="primary"
                    onClick={() => this.addNewCategory()}
                    disabled={this.props.save_category_working}
                  >Save</Button>
                </Col>
              </FormGroup>
            </div>
          </Col>
          <Col md={6}>
            <h3 className="h6 mb-4">Manage Tags</h3>
            <TagsInput
              value={this.props.tags.map(item => { return item.name })}
              onChange={this.onTagsChange}
            />
            {this.state.shwo_duplicate_tag_name_error ?
                <div style={{color: "red"}}><b>* Tag already exist</b></div>
              : null
            }
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
  		userData: state.Auth.get('userData'),
  		categories: state.Sop.get('categories'),
  		tags: state.Sop.get('tags'),
      save_category_working: state.Sop.get('save_category_working'),
      delete_category_working: state.Sop.get('delete_category_working'),
      update_category_working: state.Sop.get('update_category_working'),
    };
  },
  {
    getSopCategories,
    saveSopCategory,
    deleteSopCategory,
    updateSopCategory,
    getSopTags,
    saveSopTag,
    deleteSopTag,
    updateSopTag,
  }
)(SopManage);
