import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
	sbyb_item_data: {}
});

export default function sbybReducer(state = initState, action) {
	switch (action.type) {

	  case actions.SEARCH_SBYB_DATA_SUCCESS:
			let sdata = Object.assign({}, state.get("sbyb_item_data"));
			sdata[action.data.isbn] = action.data;
			return state
				.set("sbyb_item_data", Object.assign({}, sdata))

	default:
		return state;
	}
}
