import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Button, Alert, Input } from "reactstrap";
import PropTypes from "prop-types";
import {getProductsBySku} from "../../../../../helpers/batch/utility";
import batchActions from "../../../../../redux/batch/actions";
import IconWarning from "react-icons/lib/md/warning";
import { MdEdit as EditIcon} from "react-icons/lib/md";
import { PulseLoader } from "react-spinners";
import { moverTruck } from "../../../../../assets/images";
import SweetAlert from 'sweetalert2-react';
import { fromJS } from 'immutable';


const {
  createShipment,
  setCurrentFlow,
  setCurrentShipmentPlans,
  updateModalDisplay,
  bulkSendToHoldingArea,
  completeBatch,
  rejectShipmentPlans,
  updateShipmentPlansName
} = batchActions;

class ShipmentPlansTable extends Component {
	completingShipmentID = null;
	state = {
		disabled_buttons: false,
		completingShipments: false,
		completedALLShipments: false,
		intervalID: null,
		createShipmentButtonDisabled: false,
    alertCreateShipment: false,
    shipmentsList: [],
    shipmentNameEdits: []
	}

  componentDidMount() {
    this.generateShipmentTableRows(this.props);
  }

	componentWillUnmount() {
		if(this.state.intervalID){
			clearInterval(this.state.intervalID);
		}
	}

  UNSAFE_componentWillReceiveProps(newProps){
		const { existingShipments } = this.props;
		if(this.state.disabled_buttons
				&& newProps.existingShipments !== existingShipments){
			this.setState({ completingShipmentID: null });
			this.setState({ disabled_buttons: false });
    }
    this.generateShipmentTableRows(newProps);
	}

  handleClickViewItems = shipmentPlans => {
    const { setCurrentShipmentPlans, updateModalDisplay } = this.props;
    if (shipmentPlans.created){
      const plans = [{
        Items: shipmentPlans.items
      }];
      setCurrentShipmentPlans(plans);
    } else {
      setCurrentShipmentPlans(shipmentPlans.plans);
    }
    updateModalDisplay("ship_view_items");
  };

  handleEditShipmentName = (e, idx) => {
    const shipmentName = e.target.value.trim();
    if (shipmentName) {
      let shipmentsListJS = fromJS(this.state.shipmentsList);
      const newShipmentsList = shipmentsListJS
        .setIn([idx, "shipmentName"], shipmentName)
        .updateIn([idx, "plans"], plans => plans.map(plan => plan.set("Name", shipmentName)))
        .toJS()
      this.setState({ shipmentsList: newShipmentsList});
    }
  }

  setShipmentNameEditToggle = (i) => {
    let newShipmentNameEdits = this.state.shipmentNameEdits;
    newShipmentNameEdits[i] = !newShipmentNameEdits[i];
    this.setState({ shipmentNameEdits: newShipmentNameEdits});
  }

	rejectShipmentPlan(){
		const { batchMetadata } = this.props;
		this.setState({ createShipmentButtonDisabled: true });
		this.props.rejectShipmentPlans(batchMetadata.id);
		this.props.setCurrentFlow("created_listings_display");
	}

	completeBatchFull(){
    this.setState({ createShipmentButtonDisabled: true });
    this.props.updateShipmentPlansName(this.state.shipmentsList);
		const intervalID = setInterval(
	      () => this.loopAllShipments(),
		  1000
		);
		this.setState({ intervalID: intervalID });
	}

  generateShipmentTableRows = (props) => {
    const {
      inboundShipmentPlans,
      existingShipments,
		  products
    } = props;

    let planByDestId = {};
    inboundShipmentPlans.forEach(plan => {
      if (!planByDestId[plan.DestinationFulfillmentCenterId]) {
        planByDestId[plan.DestinationFulfillmentCenterId] = {};
        planByDestId[plan.DestinationFulfillmentCenterId][plan.LabelPrepType] = [plan];
      } else {
        if (!planByDestId[plan.DestinationFulfillmentCenterId][plan.LabelPrepType]) {
          planByDestId[plan.DestinationFulfillmentCenterId][plan.LabelPrepType] = [plan];
        } else {
          planByDestId[plan.DestinationFulfillmentCenterId][plan.LabelPrepType].push(plan);
        }
      }
    });

    let rows = [];
    Object.keys(planByDestId).forEach(destId => {
      Object.keys(planByDestId[destId]).forEach(labelPrepType => {
        let plans = planByDestId[destId][labelPrepType];
        let shipmentName = planByDestId[destId][labelPrepType][0].Name
        let num_skus = 0;
        let quantity = 0;
        let shipmentIds = [];
        let items = [];
        let productsInDestId = [];
        let productsBySku = getProductsBySku(products);
        let destination = destId;
        plans.forEach(plan => {
          (plan.Items || []).forEach(item => {
            quantity += Number(item.Quantity);
            let product = Object.assign({}, productsBySku[item.SellerSKU], {
              qty: Number(item.Quantity)
            });
            productsInDestId.push(product);
          });
          num_skus += (plan.Items || []).length;
          shipmentIds.push(plan.ShipmentId);
          items.concat(plan.Items || []);
        });

        rows.push({
          destination,
          num_skus,
          quantity,
          shipmentIds,
          items,
          plans,
          created: false,
          productsInDestId,
          labelPrepType,
          shipmentName
        });
      });
	  });

    existingShipments.forEach(shipment => {
      let quantity = 0;
      shipment.Items.forEach(item => {
        quantity += Number(item.QuantityShipped);
      });
      rows.push({
        destination: shipment.DestinationFulfillmentCenterId,
        shipmentIds: [shipment.ShipmentId],
        num_skus: shipment.Items.length,
        items: shipment.Items,
        quantity: quantity,
        labelPrepType: shipment.LabelPrepType,
        created: true,
        shipmentName: shipment.Name
      });
    });
    this.setState({ shipmentsList: rows });

    let initialShipmentNameEdits = []
    for (let i = 0; i < rows.length; i++) {
      initialShipmentNameEdits.push(false)
    }
    this.setState({shipmentNameEdits: initialShipmentNameEdits})
  }

	loopAllShipments = () => {
    const { shipmentsList } = this.state;
		let newID = null;
		let completing_row = null;
		let currentShipmentFinished = false;
		let newIds = null;
		shipmentsList.forEach(row => {
			if(!row.created){
				if(!this.completingShipmentID){
					newID = row.destination;
					completing_row = row;
					const ids = row.shipmentIds;
					this.completingShipmentID = ids;
				}
			} else {
				if(this.completingShipmentID){
					newIds = this.completingShipmentID.filter(
						id => id !== row.shipmentIds[0]);
					if(newIds.length === this.completingShipmentID.length){
						this.completingShipmentID = newIds;
					} else {
						this.completingShipmentID =  null;
						currentShipmentFinished = true;
					}
				}
			}
		});
		if(!newID
			&& !this.completingShipmentID
			&& currentShipmentFinished === false){
			clearInterval(this.state.intervalID);
			this.completingShipmentID = null;
			this.props.completeBatch();
		} else {
			//if shipment is still completing
			if(completing_row){
				this.props.createShipment(completing_row.plans)
			} else {
				if(currentShipmentFinished){
					this.completingShipmentID = null;
				}
			}
		}
	}

	generateLocationAdderss = (location) => {
		var data = this.props.inboundShipmentPlans.find(item => item.DestinationFulfillmentCenterId === location);
		if(data
				&& data.ShipToAddress
				&& data.ShipToAddress.City
				&& data.ShipToAddress.City.value
				&& data.ShipToAddress.StateOrProvinceCode
				&& data.ShipToAddress.StateOrProvinceCode.value ){
			return data.ShipToAddress.City.value + ', ' + data.ShipToAddress.StateOrProvinceCode.value;
		}
		return "";
	}

  render() {
    return (
		<React.Fragment>
      <Table>
        <thead>
          <tr>
            <th className="align-middle text-center"># SKUs</th>
            <th className="align-middle text-center">Shipment Name</th>
            <th className="align-middle text-center">Quantity Shipped</th>
            <th className="align-middle text-center">Shipment ID(s)</th>
            <th className="align-middle text-center">Destination</th>
            <th className="align-middle text-center">Labeling Preference</th>
            <th className="align-middle text-center">View Items</th>
            <th className="align-middle text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.shipmentsList.map((row, i) => (
            <tr key={i}>
              <td className="align-middle text-center">{row.num_skus}</td>
              <td className="align-middle text-center">
                <span className="editable-cell-input">
                  {
                    this.state.shipmentNameEdits[i] ?
                    <Input
                      value={row.shipmentName}
                      onChange={(e) => this.handleEditShipmentName(e, i)}
                      onBlur={() => this.setShipmentNameEditToggle(i)}
                      onKeyPress={(e) => {
                        if(e.key === 'Enter') this.setShipmentNameEditToggle(i)
                      }}
                    /> :
                    <React.Fragment>
                      <span>{row.shipmentName}</span>
                      <Button color="link" className="text-success ml-1" onClick={() => this.setShipmentNameEditToggle(i)}>
                        <EditIcon size="16" />
                      </Button>
                    </React.Fragment>
                  }

                </span>

              </td>
              <td className="align-middle text-center">{row.quantity}</td>
              <td className="align-middle text-left">
                <ul className="list-unstyled mb-0">
                {row.shipmentIds.map((shipmentId, idx) => (
                  <li key={`shipment_id_${idx}`}>
                    {shipmentId}
										<p className="mb-0">
                      <img src={moverTruck} alt="" className="mr-1" style={{ position: "relative", top: "-1px" }} />
											{this.generateLocationAdderss(row.destination)}
											<span className="text-muted ml-1">
												{this.props.batch_shipments_distances[row.destination]
													? `(${parseInt(this.props.batch_shipments_distances[row.destination], 10)}m)` : null}
											</span>
										</p>
                  </li>
                ))}
                </ul>
              </td>
              <td className="align-middle text-center">{row.destination}</td>
              <td className="align-middle text-center">{row.labelPrepType}</td>
              <td className="align-middle text-center">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handleClickViewItems(row)}
                >
                  View Items
                </Button>
              </td>
              {row.created ? (
                <td className="align-middle text-center">
                    Shipment Created + Feed Submitted
                </td>
              ) : (
                <td className="align-middle text-center">
                    Accept Shipment
                </td>
			  )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{height: "50px"}} />
      <hr />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Button
            color="danger"
            onClick={() => this.rejectShipmentPlan()}
            disabled={this.state.createShipmentButtonDisabled}
          >
            Reject shipment plan and add more items
          </Button>
            <div style={{width: "20px"}} />
          <Button
            color="success"
            onClick={() => this.setState({alertCreateShipment: true})}
            disabled={this.state.createShipmentButtonDisabled}
          >{
            this.state.createShipmentButtonDisabled ?
            (
              <div className='sweet-loading'>
              <PulseLoader
                sizeUnit={"px"}
                size={9}
                color={'white'}
                loading={true}
              />
              </div>
            ) : 'Create Shipments And Complete Batch'
            }
          </Button>

      </div>
      <SweetAlert
        show={this.state.alertCreateShipment}
        title="Are you sure?"
        type="warning"
        html="Once this shipment is created you cannot make any changes to quantity or data in Seller Central. <br/>Please ensure this shipment is created as you think it should be and for correctness."
        showCancelButton
        onConfirm={() => {
          this.completeBatchFull()
          this.setState({ alertCreateShipment: false })
        }}
        confirmButtonText="Yes, sure"
        confirmButtonClass="bg-warning"
        onCancel={() => this.setState({ alertCreateShipment: false })}
      />
		</React.Fragment>
    );
  }
}

ShipmentPlansTable.propTypes = {
  inboundShipmentPlans: PropTypes.array.isRequired,
  existingShipments: PropTypes.array.isRequired,
  batchMetadata: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  acceptAllWarehouses: PropTypes.func,
};

export default connect(
  state => ({
		batch_shipments_distances: state.Batch.get("batch_shipments_distances"),
		batch_shipments_distances_loading: state.Batch.get("batch_shipments_distances_loading"),
    inboundShipmentPlans: state.Batch.get("inboundShipmentPlans"),
    existingShipments: state.Batch.get("existingShipments"),
    batchMetadata: state.Batch.get("batchMetadata"),
    products: state.Batch.get("products")
  }),
  {
    createShipment,
    setCurrentFlow,
    setCurrentShipmentPlans,
    updateModalDisplay,
    bulkSendToHoldingArea,
    completeBatch,
    rejectShipmentPlans,
    updateShipmentPlansName,
  }
)(ShipmentPlansTable);
