import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from 'recharts';
import { digitСonversion } from "../../../../helpers/utility";
import dashboardActions from '../../../../redux/main_dashboard/actions';

import moment from "moment"
import chart_config from "../chart_config";
import CustomizedLabel from "../CustomizedLabel";
import getGradient from "../chart_gradients";

const { getSalesStats } = dashboardActions;

const fakeData = [
  { month: "Oct", month_text: "2019-10-01", month_full: [2019,40,2],  profit: 350, expenses: 150, sales: 550, ebay_sales: 355 },
  { month: "Nov", month_text: "2019-11-01", month_full: [2019,44,5],  profit: 470, expenses: 120, sales: 590, ebay_sales: 405 },
  { month: "Dec", month_text: "2019-12-01", month_full: [2019,48,7],  profit: 550, expenses: 130, sales: 680, ebay_sales: 520 },
  { month: "Jan", month_text: "2020-01-01", month_full: [2020,1,3],  profit: 520, expenses: 110, sales: 630, ebay_sales: 540 },
  { month: "Feb", month_text: "2020-02-01", month_full: [2020,5,6],  profit: 660, expenses: 140, sales: 800, ebay_sales: 680 },
];

class SalesChart extends Component {
  componentDidMount() {
    this.props.getSalesStats();
  }

  moneyFormat = (val) => {
    return digitСonversion(val, "currency", this.props.internationalizationConfig.currency_code);
  }

  tooltipFormatter = (value, name, props) => {
    return this.moneyFormat(value);
  }

  checkIfNewUser = () => {
    const { userData } = this.props;
    if(userData && userData.created_at){
      const current_time = moment();
      const create_time = moment(userData.created_at);
      if(current_time.diff(create_time, 'hours') < 24){
        return true
      }
    }
    return false;
  }

  render() {
  const { salesExpensesData } = this.props;

    return (
			<div className={`demoable-content-wrapper ${ Array.isArray(salesExpensesData) && salesExpensesData.length > 0 ? "" : "demo"}`}>
        <ResponsiveContainer
          width={"100%"}
          height={430}
          className="demoable-content"
        >
          <ComposedChart
						data={ Array.isArray(salesExpensesData) && salesExpensesData.length > 0 ? salesExpensesData : fakeData }
            margin={{
              top: 15, left: 35
            }}
          >
            <defs>
              {getGradient("gradientOrange", "orange")}
              {getGradient("gradientBlue", "blue")}
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={chart_config.cartesianStrokeColor}
            />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
            />
            <YAxis 
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={this.moneyFormat}
            />
            <Tooltip
              cursor={false}
              formatter={this.tooltipFormatter}
            />
            <Legend />
            <Bar
              name="Amazon Sales"
              dataKey="sales"
              barSize={chart_config.barSize}
              fill="url(#gradientOrange)"
              radius={chart_config.barRadius}
              label={<CustomizedLabel internationalizationConfig={this.props.internationalizationConfig}/>}
            />
            <Bar
              name="eBay Sales"
              dataKey="ebay_sales"
              barSize={chart_config.barSize}
              fill="url(#gradientBlue)"
              minPointSize={1}
              label={<CustomizedLabel internationalizationConfig={this.props.internationalizationConfig}/>}
            />
            <Line
              name="Your Expenses"
              dataKey="expenses"
              stroke={chart_config.redColor}
              dot={chart_config.dot}
              type="monotone"
              strokeWidth={chart_config.strokeWidth}
            />
            <Line
              name="Your Profit"
              dataKey="profit"
              stroke={chart_config.greenColor}
              dot={chart_config.dot}
              type="monotone"
              strokeWidth={chart_config.strokeWidth}
            />
          </ComposedChart>
        </ResponsiveContainer>
        {this.checkIfNewUser() ?
          <div className="demo-overlay text-center">
            <p>Pretty soon you'll see how your business is performing but we need to fully sync with your Amazon
              account first. This could take up to 24 hours.
            </p>
          </div>
          : null
        }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      internationalizationConfig: state.Auth.get("internationalization_config"),
      salesExpensesData: state.Dashboard.get("salesExpensesData"),
		  userData: state.Auth.get("userData"),
    }
  },
  {
    getSalesStats,
  }
)(SalesChart);
