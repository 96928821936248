import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane, Label, Card, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import adminActions from "../../../../redux/admin/actions";
import ReasonCategoryChart from './ReasonCategoryChart';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const { getCancelReasonAdmin } = adminActions;

const predefinedRanges = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}

class AdminCancelReason extends Component {
  state = {
    show_full_idx: "",
    activeTab: "1",
    startDate: moment().subtract(365, 'days'),
    endDate: moment(),
    cancel_reason_data: [],
  }

  UNSAFE_componentWillMount(){
    const data = {
      startDate: this.state.startDate.format('MMM D, YYYY'),
      endDate: this.state.endDate.format('MMM D, YYYY')
    }
    this.props.getCancelReasonAdmin(data);
	}

  showFullDetails = (idx) => {
    this.setState({ show_full_idx: idx })
  }

  closeModal = () => {
    this.setState({ show_full_idx: "" })
  }

  generateColumns = () => {
    let tableColumns = [
      {
        id: "username",
        accessor: "username",
        Header: <span>Username</span>,
        headerClassName: "text-left"
      },
      {
        id: "cancellation_date",
        accessor: "cancellation_date",
        Header: <span>Cancellation Date</span>,
        headerClassName: "text-left",
        Cell: props => moment(props.original.cancellation_date).format('MM-DD-YYYY')
      },
      {
        id: "cancel_reason_type",
        accessor: "cancel_reason_type",
        Header: <span>Reason Type</span>,
        headerClassName: "text-left"
      },
      {
        id: "cancel_reason",
        accessor: "cancel_reason",
        Header: <span>Reason Details</span>,
        headerClassName: "text-left",
        className: "text-truncate",
        width: 600,
        Cell: props => (
          <div>
            <div 
              id={`tooltip-detail-${props.index}`} 
              className="text-truncate"
              style={{ textDecoration: "underline" }}
            >{props.original.cancel_reason}</div>
            <UncontrolledTooltip placement="left" target={`tooltip-detail-${props.index}`}>
              {props.original.cancel_reason}
            </UncontrolledTooltip>
          </div>
        )
      },
    ]

    return tableColumns;
  }

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  selectDateRange = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
    const data = {
      startDate: picker.startDate.format('YYYY-MM-DD'),
      endDate: picker.endDate.format('YYYY-MM-DD'),
    }  
    this.props.getCancelReasonAdmin(data);
  }

  render() {
    const {cancel_reason} = this.props;
    let start = this.state.startDate.format('MMM D, YYYY');
    let end = this.state.endDate.format('MMM D, YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }
    
    return (
      <Card className="card-tab">
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => this.toggleTab("1")}
              >Graphs</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => this.toggleTab("2")}
              >Details</NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={this.state.activeTab} className="m-3">
            {this.state.activeTab === "1" &&
            <TabPane tabId="1">
            <div className="d-flex mb-3">
                <div className="mr-2">
                  <Label className="mr-1">Date Range:</Label>
                  <DateRangePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    ranges = {predefinedRanges}
                    onEvent={this.selectDateRange}
                    alwaysShowCalendars
                  >
                    <input type="text" value={label} className="form-control" />
                  </DateRangePicker>
                </div>
              </div>
              <ReasonCategoryChart cancel_reason={cancel_reason}/>
            </TabPane>
            }
            {this.state.activeTab === "2" &&
            <TabPane tabId="2">
              <div className="d-flex mb-3">
                <div className="mr-2">
                  <Label className="mr-1">Date Range:</Label>
                  <DateRangePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    ranges = {predefinedRanges}
                    onEvent={this.selectDateRange}
                    alwaysShowCalendars
                  >
                    <input type="text" value={label} className="form-control" />
                  </DateRangePicker>
                </div>
              </div>
              <ReactTable
                columns={this.generateColumns()}
                data={cancel_reason}
                minRows={0}
              />
            </TabPane>
            }
          </TabContent>
        </CardBody>
      </Card>
    )
  }
}

export default connect(
  state => {
    return {
      cancel_reason: state.Admin.get('cancel_reason'),
		};
	},
		{
      getCancelReasonAdmin,
		}

)(AdminCancelReason);