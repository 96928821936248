import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";

import {
    logoColorMd,
} from '../../../assets/images';

class ThankYou extends Component {
    render() {
        return (
            <div className="view">
                <form className="main-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 offset-md-3">
                                <div className="logo"><img src={logoColorMd} alt="logo" className="img-fluid" /></div>
                                <div className="f-card">
                                    <p className="title">Thank You</p>
									<p className="subtitle">THANK YOU - You are all set to login for the first time to AccelerList and go through our onboarding steps to ensure you can start listing immediately. If you hit any snags, be sure to chat with us in the widget thingy in the bottom right hand corner.</p>
                                    <div className="f-container">
									</div>
									<Link
										to="/signin"
										className="btn btn-block btn-lg btn-success"
									>Click here to log in</Link>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            {moment().format('YYYY')} Copyright <span>AccelerList</span>.
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default ThankYou;
