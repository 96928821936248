import React, { Component } from 'react'
import Select from 'react-select'

export const options = [
  {value: 'ADULT', label: 'ADULT'},
  {value: 'BABY', label: 'BABY'},
  {value: 'FC_PROVIDED', label: 'FC_PROVIDED'},
  {value: 'FRAGILE', label: 'FRAGILE'},
  {value: 'GRANULAR', label: 'GRANULAR'},
  {value: 'HANGER', label: 'HANGER'},
  {value: 'LIQUID', label: 'LIQUID'},
  {value: 'PERFORATED', label: 'PERFORATED'},
  {value: 'SET', label: 'SET'},
  {value: 'SHARP', label: 'SHARP'},
  {value: 'SMALL', label: 'SMALL'},
  {value: 'TEXTILE', label: 'TEXTILE'},
  {value: 'UNKNOWN', label: 'UNKNOWN'},
  {value: 'NONE', label: 'NONE'},
]

class SelectPrepCategory extends Component {
  render() {
    const { onChange, value, plain_value } = this.props

    return (
      <Select
        options={options}
        onChange={selected => onChange(selected)}
        value={
          plain_value
            ? options.find(item => item.value === plain_value)
            : value
              ? options.find(item => item.value === value)
              : null
        }
        classNames={{
          container: () => 'min-w-60',
          menu: () => 'min-w-60',
        }}
      />
    )
  }
}

export default SelectPrepCategory
