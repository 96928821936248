import { validateTemplateDataExecute } from './validator/template_validator';

export function validateTemplateData(template, step){
	return validateTemplateDataExecute(template, step);
}

export function boolToString(val){
	if(val){ return "true"; }
	return "false";
}

export function stringToBool(val){
	if(val === 'true'){ return true; }
	return false;
}

export function stringToInt(val){
	return parseInt(val, 10) || val;
}

export function stringToFloat(val){
	const v = parseFloat(val, 10).toFixed(2);
	if(!!v){
		return parseFloat(v)
	}
	return val;
}


export function templateSetAdjustmentRules(
	data,
	root_node,
	root_node_activate,
	activated,
	use_adjustment,
	adjustment_amount,
	fix_price_adjustment,
	static_method,
	price_guard_activate,
	price_guard_rule_min_price,
	price_guard_fee,
){
	if(data[root_node_activate] === "false"){
		data[root_node] = {}
		return data;
	}
	var default_price_rule = {};
	var adjustment_rule = {}
	var price_guard_rule = {}
	if(use_adjustment){
		adjustment_rule["adjustment_amount_on_your_own_offer"] = stringToFloat(adjustment_amount);
		adjustment_rule["static_method_adjust_up_if_true_down_if_false"] = boolToString(static_method);
		adjustment_rule["fix_price_adjustment_if_true_percentage_if_false"] = boolToString(fix_price_adjustment);
	} else {
		default_price_rule['price'] = stringToFloat(adjustment_amount);
	}
	if(price_guard_activate){
		price_guard_rule['min_price'] = stringToFloat(price_guard_rule_min_price);
		price_guard_rule['default_min_inventory_price_to_buy_cost_plus_fee'] = boolToString(price_guard_fee);
	}
	data[root_node] = {
		"use_adjustment_if_true_use_default_price_if_false": boolToString(use_adjustment),
		"default_price_rule": default_price_rule,
		"adjustment_rule": adjustment_rule,
		"price_guard_activate": boolToString(price_guard_activate),
		"price_guard_rule": price_guard_rule,
	}
	return data;
}
