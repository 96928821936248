import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
} from 'reactstrap';
import CollapsibleFormGroup from './CollapsibleFormGroup';
import Select from "react-select";
import {
  ebay_LengthUnitOfMeasureEnum,
  ebay_PackageTypeEnum,
  ebay_WeightUnitOfMeasureEnum,
} from '../../../helpers/ebay/utility';
import PerfectScrollbar from 'react-perfect-scrollbar';

class PackageDetails extends Component {

  generateSelectData = (data) => {
    return data.map(item => { return { label: item, value: item } });
  }

  displayPackageTypes = () => {
    return (
      <React.Fragment>
        <strong>Recommended package types:</strong>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
            minScrollbarLength: 40
          }}
        >
          <div style={{ maxHeight: "10rem" }}>
          {this.props.ebay_package_types_valid.map((item, idx) => {
            return (
              <span key={`package_type_${idx}`}> {item}, </span>
            )
          })}
        </div>
        </PerfectScrollbar>
      </React.Fragment>
    )
  }

  render() {
    const {
      collapse,
      toggleCollapse,
      ebay_direct_listing_item,
    } = this.props;

    return (
      <CollapsibleFormGroup
        title="Package Details"
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      >
        <p>This info is required for calculated shipping or eBay Managed Returns.</p>
        <p className="alert alert-info">Use only if error with missing dimensions occurred and we need to manually update it.</p>
        <FormGroup row>
          <Label sm={4}><strong>Dimensions</strong></Label>
          <Label sm={4}><strong>Weight</strong></Label>
          <Label sm={4}><strong>Package Type</strong></Label>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>Unit</Label>
          <Col sm={3}>
            <Select
              options={this.generateSelectData(ebay_LengthUnitOfMeasureEnum)}
              value={ebay_direct_listing_item.ebay_dimensions
              && ebay_direct_listing_item.ebay_dimensions.dimensions
              && ebay_direct_listing_item.ebay_dimensions.dimensions.unit ?
                { label: ebay_direct_listing_item.ebay_dimensions.dimensions.unit, value: ebay_direct_listing_item.ebay_dimensions.dimensions.unit}
                : null
              }
              onChange={e => this.props.updateEbayByBase("unit", e ? e.value : null, "dimensions")}
            />
          </Col>
          <Label sm={1}>Unit</Label>
          <Col sm={3}>
            <Select
              options={this.generateSelectData(ebay_WeightUnitOfMeasureEnum)}
              value={ebay_direct_listing_item.ebay_dimensions
              && ebay_direct_listing_item.ebay_dimensions.weight
              && ebay_direct_listing_item.ebay_dimensions.weight.unit ?
                { label: ebay_direct_listing_item.ebay_dimensions.weight.unit, value: ebay_direct_listing_item.ebay_dimensions.weight.unit}
                : null
              }
              onChange={e => this.props.updateEbayByBase("unit", e ? e.value : null, "weight")}
            />
          </Col>
          <Label sm={1}>Package Type</Label>
          <Col sm={3}>
            <Select
              options={this.generateSelectData(ebay_PackageTypeEnum)}
              value={ebay_direct_listing_item.ebay_dimensions
              && ebay_direct_listing_item.ebay_dimensions.packageType ?
                { label: ebay_direct_listing_item.ebay_dimensions.packageType, value: ebay_direct_listing_item.ebay_dimensions.packageType}
                : null
              }
              onChange={e => this.props.updateEbayByBase("packageType", e ? e.value : null, "packageType")}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>Width</Label>
          <Col sm={3}>
            <Input
              type="number"
              value={ebay_direct_listing_item.ebay_dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions.width
                ? ebay_direct_listing_item.ebay_dimensions.dimensions.width : ""}
              className="value"
              onChange={e => this.props.updateEbayByBase("width", parseFloat(e.target.value), "dimensions")}
              style={{ width: 100 }}
            />
          </Col>
          <Label sm={1}>Weight Value</Label>
          <Col sm={3}>
            <Input
              type="number"
              value={ebay_direct_listing_item.ebay_dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions
                && ebay_direct_listing_item.ebay_dimensions.weight.value
                ? ebay_direct_listing_item.ebay_dimensions.weight.value : ""}
              className="value"
              onChange={e => this.props.updateEbayByBase("value", parseFloat(e.target.value), "weight")}
              style={{ width: 100 }}
            />
          </Col>
          <Col sm={1}></Col>
          <Col sm={3}>
            <div style={{ border: "1px solid #f5a5a5", padding: ".2rem .5rem", borderRadius: "2px" }}>
            {this.props.ebay_package_types_valid
              ? this.displayPackageTypes()
              : null
            }
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>Height</Label>
          <Col sm={3}>
            <Input
              type="number"
              value={ebay_direct_listing_item.ebay_dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions.height
                ? ebay_direct_listing_item.ebay_dimensions.dimensions.height : ""}
              className="value"
              onChange={e => this.props.updateEbayByBase("height", parseFloat(e.target.value), "dimensions")}
              style={{ width: 100 }}
            />
          </Col>
          <Col sm={3}>
            {ebay_direct_listing_item
                && ebay_direct_listing_item.amazon_dimensions
                && ebay_direct_listing_item.amazon_dimensions.item ?
                  <Button
                    color="secondary"
                    onClick={e => this.props.addAdditionalFieldsForDimensions()}
                  >Use Amazon default dimensions</Button>
                  : null
            }
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>Length</Label>
          <Col sm={3}>
            <Input
              type="number"
              value={ebay_direct_listing_item.ebay_dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions
                && ebay_direct_listing_item.ebay_dimensions.dimensions.length
                ? ebay_direct_listing_item.ebay_dimensions.dimensions.length : ""}
              className="value"
              onChange={e => this.props.updateEbayByBase("length", parseFloat(e.target.value), "dimensions")}
              style={{ width: 100 }}
            />
          </Col>
        </FormGroup>
      </CollapsibleFormGroup>
    )
  }
}

export default PackageDetails;
