import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
	messages: [],
	messages_user: [],
  message_creating: false,
  uploaded_image: "",
});

export default function messageReducer(state = initState, action) {
	switch (action.type) {

	  case actions.MESSAGE_IN_APP_SET_TRACKING:
			return state
				.set("message_creating", true)
	  case actions.MESSAGE_IN_APP_SET_TRACKING_SUCCESS:
      let tracking_messages = state.get("messages_user");
      tracking_messages = tracking_messages.filter(item => item.id !== action.data.id);
			return state
				.set("messages_user", Object.assign([], tracking_messages))
				.set("message_creating", false)
	  case actions.MESSAGE_IN_APP_SET_TRACKING_FAILED:
			return state
				.set("message_creating", false)

	  case actions.MESSAGE_IN_APP_GET_USER:
			return state
				.set("messages_user", [])
	  case actions.MESSAGE_IN_APP_GET_USER_SUCCESS:
			return state
				.set("messages_user", Object.assign([], action.data))
	  case actions.MESSAGE_IN_APP_GET_USER_FAILED:
			return state
				.set("messages_user", [])

    case actions.MESSAGE_IN_APP_UPLOAD_IMAGE_CLEAR_SUCCESS:
			return state
				.set("uploaded_image", "")

    case actions.MESSAGE_IN_APP_UPLOAD_IMAGE:
			return state
				.set("uploaded_image", "")
    case actions.MESSAGE_IN_APP_UPLOAD_IMAGE_SUCCESS:
			return state
				.set("uploaded_image", action.data.url)
    case actions.MESSAGE_IN_APP_UPLOAD_IMAGE_FAILED:
			return state
				.set("uploaded_image", "")

    case actions.MESSAGE_IN_APP_DELETE:
			return state
				.set("message_creating", true)
    case actions.MESSAGE_IN_APP_DELETE_SUCCESS:
      let delete_messages = state.get("messages");
      delete_messages = delete_messages.filter(item => item.id !== action.data.id);
			return state
				.set("messages", Object.assign([], delete_messages))
				.set("message_creating", false)
    case actions.MESSAGE_IN_APP_DELETE_FAILED:
			return state
				.set("message_creating", false)

    case actions.MESSAGE_IN_APP_UPDATE:
			return state
				.set("message_creating", true)
    case actions.MESSAGE_IN_APP_UPDATE_SUCCESS:
      let update_messages = state.get("messages");
      update_messages = update_messages.map(item => {
        if(item.id === action.data.id){
          return action.data;
        }
        return item;
      });
			return state
				.set("messages", Object.assign([], update_messages))
				.set("message_creating", false)
    case actions.MESSAGE_IN_APP_UPDATE_FAILED:
			return state
				.set("message_creating", false)

    case actions.MESSAGE_IN_APP_CREATE:
			return state
				.set("message_creating", true)
    case actions.MESSAGE_IN_APP_CREATE_SUCCESS:
      let create_messages = state.get("messages");
      create_messages.unshift(action.data);
			return state
				.set("messages", Object.assign([], create_messages))
				.set("message_creating", false)
    case actions.MESSAGE_IN_APP_CREATE_FAILED:
			return state
				.set("message_creating", false)

	  case actions.MESSAGE_IN_APP_GET_SUCCESS:
			return state
				.set("messages", Object.assign([], action.data))

	default:
		return state;
	}
}
