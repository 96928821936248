import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';
import { userEnableEbayAPI } from "../../helpers/templates_apis";
import { logError } from "../../helpers/utility";

export function* getFeedbacks() {
    yield takeLatest('FEEDBACK_GET_DATA', function* (payload) {
      const { data } = payload;
      try {
					const response = yield call(backendAPICall, 'get', apiEndpoints.feedback.feedbacks, data);
					if (response.status === 200 && !response.data.error) {
						yield put(actions.getFeedbacksSuccess(response.data));
					} else {
						yield put(actions.getFeedbacksFailed());
					}
        } catch (error) {
          yield put(appActions.apiCallFailed("Error! Feedback get data error"));
					yield put(actions.getFeedbacksFailed());
          logError(error, {
            tags: {
              exceptionType: "FEEDBACK_GET_DATA"
            }
          });
        }
    })
}

export default function* feedbackSaga() {
	yield all([
    fork(getFeedbacks),
	]);
}
