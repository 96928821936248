const employeesActions = {

	GET_EMPLOYEES_TRACKING: 'GET_EMPLOYEES_TRACKING',
	GET_EMPLOYEES_TRACKING_SUCCESS: 'GET_EMPLOYEES_TRACKING_SUCCESS',
	GET_EMPLOYEES_TRACKING_FAILED: 'GET_EMPLOYEES_TRACKING_FAILED',

	getEmployeesTrackingData: (data) => ({
		type: employeesActions.GET_EMPLOYEES_TRACKING,
		data
	}),
	getEmployeesTrackingDataSuccess: (data) => ({
		type: employeesActions.GET_EMPLOYEES_TRACKING_SUCCESS,
		data
	}),
	getEmployeesTrackingDataFailed: (data) => ({
		type: employeesActions.GET_EMPLOYEES_TRACKING_FAILED,
		data
	}),

};

export default employeesActions;
