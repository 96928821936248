import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
  Input, Button,
  Card, CardBody,
  Row, Col,
  FormGroup, InputGroup, InputGroupAddon
} from 'reactstrap';
import accountingActions from '../../../../../redux/accounting/actions';
import DeleteDialog from "../../../../../shared/components/dialogs/DeleteDialog";

const {
	expenseLoadCategories,
	expenseAddCategory,
	expenseDeleteCategory,
	expenseUpdateCategory,
} = accountingActions;

class ManageExpenseCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newCategoryName: '',
      deleteConfirmation: false,
      delete_category_id: 0,
      delete_category_name: "",
      sorting: "",
      sort: false
    }
  }

	componentDidMount(){
		this.props.expenseLoadCategories();
	}

  setCategoryName = (e) => {
    this.setState({
      newCategoryName: e.target.value
    });
  }

	addCategory = () => {
		const { newCategoryName } = this.state;
	    if(newCategoryName !== '') {
			this.props.expenseAddCategory({categoryName: newCategoryName});
			this.setState({ newCategoryName: '' });
		}
  }

  confirmDelete = (category) => {
    this.setState({
      deleteConfirmation: true,
      delete_category_id: category.id,
      delete_category_name: category.name,
    })
  }

	deleteCategory = (id) => {
		this.props.expenseDeleteCategory({id: id});
	}

  saveCategory = (id) => {
    if(this.state[`cat_${id}`]){
      this.props.expenseUpdateCategory({
        id: id,
        name: this.state[`cat_${id}`],
      });
    }
  }

  updateCategoryName = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  sortCategory = () => {
    this.setState({ sort: true });
    if(this.state.sorting == "asc"){
      this.setState({ sorting: "desc"});
    }else{
      this.setState({ sorting: "asc"});
    }	
  }

  render() {
    const {
		  expenseCategories,
    } = this.props;

    let categoriesData = expenseCategories;
   
    if(this.state.sort){
      if(this.state.sorting === 'desc'){
        categoriesData = expenseCategories.sort((a, b) => (a.name > b.name) ? -1 : 1);
      } else {
        categoriesData = expenseCategories.sort((a, b) => (a.name > b.name) ? 1 : -1);
      }
    }

    return (
      <Fragment>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <Button size="sm" className="mb-3" onClick={this.sortCategory}>Sort <SortingOrder sorting={this.state.sorting} /></Button>
                {categoriesData.map((category) => (
                  <FormGroup key={category.id} className="d-flex">
                    <InputGroup>
                      <Input
                        name={`cat_${category.id}`}
                        onChange={e => this.updateCategoryName(e)}
                        type="text"
                        defaultValue={category.name}
                        disabled={this.props.expense_categories_updating}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          onClick={() => this.saveCategory(category.id)}
                          disabled={this.props.expense_categories_updating}
                        >Save</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <Button color="link" className="ml-2" onClick={e => this.confirmDelete(category)}>
                      <span className="icon icon-trash bg-danger"></span>
                    </Button>
                  </FormGroup>
                ))}
                <FormGroup className="d-flex">
                  <Input
                    type="text"
                    onChange={this.setCategoryName}
                    onKeyPress={(e) => {
                      let code = (e.keyCode ? e.keyCode : e.which);
                      if(code === 13) this.addCategory();
                    }}
                    value={this.state.newCategoryName}
                    placeholder="Enter category name"
                  />
                  <Button
                    color="success"
                    className="ml-2"
                    onClick={this.addCategory}
                  >
                    Add category
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
          <DeleteDialog
            isOpen={this.state.deleteConfirmation}
            close={() => this.setState({ deleteConfirmation: false })}
            deleteItem={() => {
              this.deleteCategory(this.state.delete_category_id);
              this.setState({ deleteConfirmation: false })
            }}
            dialogTitle="Are you sure want to delete this expense category?"
            dialogText={`Deleting category "${this.state.delete_category_name}" will delete ALL expenses under this category.`}
            confirmText="Yes, delete category"
          />
      </Fragment>
    )
  }
}

const SortingOrder = props => (
	<span className={`order ${props.sorting}`}>
		<span style={{ display: props.sorting== 'asc' ? 'none' : '' }} className="dropdown"><span className="caret"></span></span>
		<span style={{ display: props.sorting== 'desc' ? 'none' : '' }} className="dropup"><span className="caret"></span></span>
	</span>
)


export default connect(
  state => {
    return {
      expenseCategories: state.Accounting.get("expenseCategories"),
      expense_categories_updating: state.Accounting.get("expense_categories_updating"),
    };
  },
  {
	  expenseLoadCategories,
	  expenseAddCategory,
	  expenseDeleteCategory,
	  expenseUpdateCategory,
  }
)(ManageExpenseCategory);
