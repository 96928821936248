import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button, Input, ModalFooter } from "reactstrap";
import fulfillmentActions from "../../../redux/fulfillment/actions";
import { MdCheck as IconCheck, MdClose as IconX } from 'react-icons/lib/md';

const {
  fulfillmentBoxCreate,
} = fulfillmentActions;

class CreateNewBoxModal extends Component {
  state = {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  }

  saveBoxData = () => {
    const { length, width, height, weight } = this.state;
    const data = {
      packing_group_id: this.props.packing_group_id,
      batch_id: this.props.batchMetadata?.id,
      weight_value: weight,
      dimensions_length: length,
      dimensions_width: width,
      dimensions_height: height,
      quantity: 0,
    }
    this.props.fulfillmentBoxCreate(data);
  }

  componentDidMount = () => {
    const { boxes } = this.props;
    if(boxes?.length > 0){
      this.setState({
        length: boxes[0].dimensions_length,
        width: boxes[0].dimensions_width,
        height: boxes[0].dimensions_height,
        weight: boxes[0].weight_value,
      })
    }
  }

  spdDimensionsAndGirthCheck = () => {
    const { length, width, height } = this.state;
    if(this.props?.internationalization_config?.weight_identifier === "LBS"){
      return length <= 108 && (length + (2 * width) + (2 * height)) <= 165;
    } else {
      return length <= 108*2.54 && (length + (2 * width) + (2 * height)) <= 165*2.54;
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      fulfillment_inbound_working,
    } = this.props;
    const { length, width, height, weight } = this.state;

    return (
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Add New Box</ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center gap-2">
            <span>L:</span>
            <Input
              type="number"
              min={0}
              style={{ width: 80 }}
              placeholder="in"
              value={length}
              onChange={e => this.setState({length: Number(e.target.value)})}
            />
            <span>W:</span>
            <Input
              type="number"
              min={0}
              style={{ width: 80 }}
              placeholder="in"
              value={width}
              onChange={e => this.setState({width: Number(e.target.value)})}
            />
            <span>H:</span>
            <Input
              type="number"
              min={0}
              style={{ width: 80 }}
              placeholder="in"
              value={height}
              onChange={e => this.setState({height: Number(e.target.value)})}
            />
            <span className="text-nowrap">Est. Weight:</span>
            <Input
              type="number"
              min={0}
              style={{ width: 80 }}
              placeholder="lbs"
              value={weight}
              onChange={e => this.setState({weight: Number(e.target.value)})}
            />
          </div>
          {length > 0 && width > 0 && height > 0 && (
            <div className="mt-3">
              {this.spdDimensionsAndGirthCheck() ? (
                <div className="text-success d-flex align-items-center gap-2">
                  <IconCheck />
                  <span>Box within SPD dimensions and girth thresholds.</span>
                </div>
              ) : (
                <div className="text-danger d-flex align-items-center gap-2">
                  <IconX />
                  <span>Box does not meet SPD dimensions and girth thresholds.</span>
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end gap-3">
          <Button onClick={toggle}>Cancel</Button>
          <Button
            color="primary"
            disabled={length === 0 || width === 0 || height === 0 || weight === 0 || !this.spdDimensionsAndGirthCheck()}
            onClick={() => {
              this.saveBoxData()
              toggle()
            }}
          >Create Box</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    userData: state.Auth.get('userData'),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
    fulfillmentBoxCreate,
  }
)(CreateNewBoxModal);
