import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import authAction from "../../../redux/auth/actions";
import { Alert } from "reactstrap";
import { emailSent, logoColorMd } from "../../../assets/images";
import "../style.css";
import { FaEnvelope } from "react-icons/lib/fa";
import moment from "moment";

const { resetPassword } = authAction;

class ForgotPasssword extends Component {
  state = {
    emailOrMobile: "",
    errorEmailOrMobile: ""
  };

  validateInput = (value) => {
    if(value === "") {
      this.setState({
        errorEmailOrMobile: "Enter your email address or mobile number."
      })

      return false;
    } else {
      this.setState({
        errorEmailOrMobile: ""
      })

      return true;
    }
  }

  handleSubmit = (e, formType) => {
    e.preventDefault();
    if(!this.validateInput(this.state.emailOrMobile)) {
      return;
    }

    this.props.resetPassword(this.state.emailOrMobile);
  };

  handleInputChange = (value) => {
    this.setState({
      emailOrMobile: value
    })
    this.validateInput(value);


  };

  render() {
    return (
      <div className="view view-forgot-password">
        <form className="main-form" onSubmit={this.handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-6 offset-md-3">
                <div className="logo">
                  <img src={logoColorMd} alt="logo" height={25} />
                </div>
                {this.renderForm()}
              </div>
            </div>
            <div className="copyright">
              {moment().format('YYYY')} Copyright <span>AccelerList</span>.
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderForm() {
    const resetMessage = this.props.passwordResetMessage;

    if (resetMessage) {
      return (
        <div className="f-card">
          <div className="text-center my-4">
            <img src={emailSent} alt="" />
          </div>
          <p className="title">Check your email</p>
          <p className="subtitle">
            We have sent password reset instructions to your email.
            Did not receive the email? <strong>Please check your spam folder.</strong>
          </p>
        </div>
      );
    } else {
      return (
        <div className="f-card">
          <p className="title">Forgot Password?</p>
          <p className="subtitle">
            {"Enter your email address or mobile number and we'll send you the instructions for resetting the password."}
          </p>
          <div className="f-container">
            <label htmlFor="#">Use your email or mobile number (with country code)</label>
            <div className="f-row">
              <input
                type="text"
                placeholder="Email address or mobile number"
                name="emailOrMobile"
                value={this.state.emailOrMobile}
                onChange={e => this.handleInputChange(e.target.value)}
              />
              <FaEnvelope />
              {!!this.state.errorEmailOrMobile &&
              <div className="f-row-error">
                <Alert color="warning">{this.state.errorEmailOrMobile}</Alert>
              </div>
              }
            </div>
            <p className="f-text">
              The email OR mobile number you used to sign up for AccelerList.
            </p>
          </div>
          <button
            className="btn btn-block btn-lg btn-success"
            type="submit"
          >
            RESET PASSWORD
          </button>
          <p className="new-account"><Link to="/register">{"Don't have an account?"}</Link></p>
          <Link to="/register" className="btn btn-block btn-lg btn-dark">
            REGISTER
          </Link>
        </div>
      );
    }
  }
}

export default connect(
  state => ({
    passwordResetMessage: state.Auth.get("passwordResetMessage")
  }),
  { resetPassword }
)(ForgotPasssword);
