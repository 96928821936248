import React, { Component } from 'react';
import ReactTable from "react-table";
import RepricingInsight from "./RepricingInsight";
import { digitСonversion } from '../../../../../helpers/utility';
import "react-table/react-table.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class SKUTable extends Component {
	state = {
		showEventId: null,
		showSku: null
	}

	showRepricingInsight = (sku, event_id) => {
		this.setState({
			showEventId: event_id,
			showSku: sku
		})
	}

  render() {
	let tableColumns = [];
	if(this.props.type === "repriced"){
		tableColumns = [
		  {
				id: 'title',
				Header: 'Title',
				accessor: 'title',
				headerClassName: 'text-left',
				width: 300,
				Cell: row => (
					<div className="d-flex">
            <span className="mr-2 text-truncate">
              {row.original.image_url
                ? <img src={row.original.image_url} alt={row.original.title} height={30} />
                : null
              }&nbsp;
              {row.original.title}
            </span>
					</div>
				)
		  },
		  {
				id: 'sku',
				Header: 'SKU',
				accessor: 'seller_sku',
				headerClassName: 'text-left',
		  },
		  {
				id: 'old_price',
				Header: 'Old Price',
				accessor: 'old_price',
				headerClassName: 'text-right',
				className: 'text-right',
				Cell: row => digitСonversion(row.original.old_price, "currency", this.props.internationalization_config.currency_code)
		  },
		  {
				id: 'new_price',
				Header: 'New Price',
				accessor: 'new_price',
				headerClassName: 'text-right',
				className: 'text-right text-primary font-weight-bold',
				Cell: row => digitСonversion(row.original.new_price, "currency", this.props.internationalization_config.currency_code)
		  },
		  {
				id: 'adjustment',
				Header: 'Adj.',
				accessor: 'adjustment',
				headerClassName: 'text-right',
				className: 'text-right',
				Cell: row => <span className={`text-${row.original.adjustment < 0 ? 'danger' : 'success'}`}>{digitСonversion(row.original.adjustment, "currency", this.props.internationalization_config.currency_code)}</span>
		  },
		  {
				id: 'template',
				Header: 'Applied Template',
				accessor: 'template_id',
				headerClassName: 'text-left',
				Cell: row =>
				<React.Fragment>
					<span>{row.original.template_name} <br/>
						<Button
							color="link"
							onClick={() => this.showRepricingInsight(row.original.seller_sku, row.original.event_id)}
						>See Details</Button>
					</span>
				</React.Fragment>
		  },
		];
	} else {
		tableColumns = [
		  {
				id: 'title',
				Header: 'Title',
				accessor: 'title',
				headerClassName: 'text-left',
				width: 400,
				Cell: row => (
					<div className="d-flex">
            <span className="mr-2 text-truncate">
              {row.original.image_url
                ? <img src={row.original.image_url} alt={row.original.title} height={30} />
                : null
              }&nbsp;
              {row.original.title}
            </span>
					</div>
				)
			},
		  {
				id: 'sku',
				Header: 'SKU',
				accessor: 'seller_sku',
				headerClassName: 'text-left',
				className: 'text-left',
				minWidth: 200,
		  },
		  {
				id: 'inventory_age',
				Header: 'Inv. Age Days',
				accessor: 'inventory_age',
				maxWidth: 150,
				headerClassName: "text-right",
				className: "text-right",
		  },
		  {
				id: 'price',
				Header: 'Price',
				accessor: 'price',
				headerClassName: 'text-right',
				className: 'text-right',
				width: 100,
				Cell: row => digitСonversion(row.original.price, "currency", this.props.internationalization_config.currency_code)
		  },
		  {
				id: 'why',
				Header: 'Unchanged Price Reason',
				accessor: 'reject_string',
				headerClassName: 'text-left',
				className: 'nowrap text-danger font-italic',
				minWidth: 280,
		  },
		];
	}

    return (
			<React.Fragment>
				<ReactTable
					columns={tableColumns}
					data={this.props.data}
					defaultPageSize={10}
					minRows={0}
				/>
				<Modal
					isOpen={this.state.showEventId !== null} size="lg"
					toggle={() => this.setState({ showEventId: null })}
				>
					<ModalHeader
						toggle={() => this.setState({ showEventId: null })}
					>Repricing insight</ModalHeader>
					<ModalBody>
            {this.state.showEventId && this.state.showSku &&
						<RepricingInsight
							event_id={this.state.showEventId}
							sku={this.state.showSku}
							insightTemplate={this.props.insightTemplate}
              insightTemplateLoading={this.props.insightTemplateLoading}
							reportInsightRun={this.props.reportInsightRun}
						/>
            }
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => this.setState({ showEventId: null })}
						>Close</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
    );
  }
}

export default SKUTable;
