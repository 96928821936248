import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
//import DatePicker from "react-datepicker";
//import DatePickerCustomInput from "../displays/shared/DatePickerCustomInput";
import PriceTrackersButtonGroup from "../displays/shared/PriceTrackersButtonGroup";
import TaxCodeInputForm from "../../../../shared/components/taxCodeInputForm";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateFloatInput,
  momentDateIsValid,
  momentDateToISOFormatConversion,
  secureProtocolImgURL,
  momentDateToLocalFormatConversion,
  digitСonversion,
} from "../../../../helpers/utility";
import {
  checkPriceLimit
} from "../../../../helpers/batch/utility";
import MaskedInput from 'react-text-mask';
import SelectPrepCategory from "../../../../shared/components/SelectPrepCategory";
import SelectPrepType from "../../../../shared/components/SelectPrepType";
import SelectWhoLabelsPreps from "../../../../shared/components/SelectWhoLabelsPreps";

class EditListingItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingItem: {
        qty: "",
        price: "",
        note: "",
        expDate: moment(),
        buyCost: "",
        supplier: "",
        datePurchased: moment(),
        taxCode: "",
		expDateStyle: {},
		expDateDisplay: "",
		datePurchasedStyle: {},
		datePurchasedDisplay: "",
      }
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      listingItem: Object.assign({}, newProps.listingItem)
    });
	this.setState({expDateStyle: {}});
	this.setState({datePurchasedStyle: {}});
	if(newProps.listingItem && newProps.listingItem.expDate){
		this.setState({expDateDisplay: momentDateToLocalFormatConversion(newProps.listingItem.expDate, false)});
	} else {
		this.setState({expDateDisplay: ""});
	}
	if(newProps.listingItem && newProps.listingItem.datePurchased){
		this.setState({datePurchasedDisplay: momentDateToLocalFormatConversion(newProps.listingItem.datePurchased, false)});
	} else {
		this.setState({datePurchasedDisplay: ""});
	}
  }

  handleChangeField = (field, e, isBlurEvent) => {
    let listingItem = this.state.listingItem;
    if (field === "qty") {
      listingItem.qty = e.target.validity.valid ? e.target.value : listingItem.qty;
      listingItem.qty = Number(listingItem.qty);
      this.setState({
        listingItem
      });
    }
    if (field === "price") {
      const { batchListingDefaults } = this.props;
      listingItem.price = Number(e.target.value);
      this.setState({
        listingItem: checkPriceLimit(listingItem, batchListingDefaults)
      });
    }
    if (field === "note") {
      listingItem.note = e.target.value;
      this.setState({
        listingItem
      });
    }
    if (field === "expDate") {
      if (momentDateIsValid(e)) {
        listingItem.expDate = momentDateToISOFormatConversion(e);
      } else {
        listingItem.expDate = null;
      }
      this.setState({
        listingItem
      });
    }
	if (field === "expDateNew") {
      if (momentDateToISOFormatConversion(moment(e.target.value)) !== 'Invalid date') {
        listingItem.expDate = momentDateToISOFormatConversion(moment(e.target.value));
    this.setState({expDateStyle: {}});
    this.setState({expDateDisplay: e.target.value});
      } else {
		if(e.target.value === ""){
			this.setState({expDateStyle: {}});
			this.setState({expDateDisplay: ""});
		} else {
			this.setState({expDateStyle: {border: "1px solid #ff0000"}});
			this.setState({expDateDisplay: e.target.value});
		}
        listingItem.expDate = null;
      }
	}
    if (field === "buyCost") {
      const validator = validateFloatInput(isBlurEvent, true);
      const value = validator(e);
      listingItem.buyCost = value;
      this.setState({
        listingItem
      });
    }
    if (field === "supplier") {
      listingItem.supplier = e.target.value;
      this.setState({
        listingItem
      });
    }
    if (field === "datePurchased") {
      if (momentDateIsValid(e)) {
        listingItem.datePurchased = momentDateToISOFormatConversion(e);
      } else {
        listingItem.datePurchased = null;
      }
      this.setState({
        listingItem
      });
    }
	if (field === "datePurchasedNew") {
      if (momentDateToISOFormatConversion(moment(e.target.value)) !== 'Invalid date') {
        listingItem.datePurchased = momentDateToISOFormatConversion(moment(e.target.value));
		this.setState({datePurchasedStyle: {}});
		this.setState({datePurchasedDisplay: e.target.value});
      } else {
		if(e.target.value === ""){
			this.setState({datePurchasedStyle: {}});
			this.setState({datePurchasedDisplay: ""});
		} else {
			this.setState({datePurchasedStyle: {border: "1px solid #ff0000"}});
			this.setState({datePurchasedDisplay: e.target.value});
		}
        listingItem.datePurchased = null;
      }
      this.setState({
        listingItem
      });
    }
    if (field === "taxCode") {
      listingItem.taxCode = e.value;
      this.setState({
        listingItem
      });
    }
    if (field === "prep_category") {
      listingItem.prep_category = e.value;
      this.setState({ listingItem });
    }
    if (field === "prep_owner") {
      listingItem.prep_owner = e.value;
      this.setState({ listingItem });
    }
    if (field === "prep_types") {
      listingItem.prep_types = e.map(item => {return item.value}).join(",");
      this.setState({ listingItem });
    }
    if (field === "label_owner") {
      listingItem.label_owner = e.value;
      this.setState({ listingItem });
    }
  };

  updatePrepCategory = (e) => {
    this.handleChangeField("prep_category", e)
  }

  updateLabelOwner = (e) => {
    this.handleChangeField("label_owner", e)
  }

  updatePrepTypes = (e) => {
    this.handleChangeField("prep_types", e)
  }

  updatePrepOwner = (e) => {
    this.handleChangeField("prep_owner", e)
  }

  checkNumFields = (value, allowEmpty) => {
    if (!allowEmpty && (value <= 0 || value === null)) {
      return true;
    } else {
      return false;
    }
  };

  saveChanges = () => {
    this.props.editListingItem(this.state.listingItem);
    this.props.close();
  };

  render() {
    const { isOpen, close, internationalConfig } = this.props;
    const { listingItem } = this.state;
    const {
      imageUrl,
      name,
      salesrank,
      asin,
      qty,
      price,
      note,
      buyCost,
      supplier,
		//expDate,
		//datePurchased,
      taxCode,
    } = listingItem;
    return (
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader>
          Edit Listing
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="1" className="col-align-start">
              <img
                style={{ height: 75 }}
                src={secureProtocolImgURL(imageUrl)}
                alt="Product Listing"
              />
            </Col>
            <Col xs="11">
              <h6 className="mb-3 font-weight-semi-bold">{`${name}`}</h6>
              <div className="d-flex">
                <div>
                  <p className="mb-1">Rank: <strong>{digitСonversion(salesrank, "rank")}</strong></p>
                  <p className="mb-0">ASIN: <strong>{asin}</strong></p>
                </div>
                <div className="pl-5">
                  <PriceTrackersButtonGroup
                    ASIN={asin}
                    itemName={name}
                    amazonUrl={internationalConfig.amazon_url}
                    keepaBaseUrl={internationalConfig.keepa_url}
                    camelCamelCamelBaseUrl={internationalConfig.camelcamelcamel_url}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="5">
              <FormGroup row>
                <Label xs={5}>Quantity</Label>
                <Col xs={7}>
                  <Input
                    type="number"
                    pattern="[0-9]*"
                    onChange={e => this.handleChangeField("qty", e)}
                    defaultValue={qty}
                    className={`${
                      this.checkNumFields(qty) ? "is-invalid" : ""
                    }`}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={5}>List Price</Label>
                <Col xs={7}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                    <Input
                      type="number"
                      onChange={e => this.handleChangeField("price", e)}
                      value={price}
                      className={`${
                        this.checkNumFields(price) ? "is-invalid" : ""
                      }`}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={5}>Condition Note</Label>
                <Col xs={7}>
                  <Input
                    type="textarea"
                    onChange={e => this.handleChangeField("note", e)}
                    defaultValue={note}
                  />
                </Col>
              </FormGroup>
			  {/*
              <Row>
                <Col xs="3" className="col-align-center">
                  <strong className="text-center">Exp Date</strong>
                </Col>
                <Col xs="9">
                  <DatePicker
                    customInput={<DatePickerCustomInput />}
                    isClearable={true}
                    dateFormat="L"
                    selected={
                      momentDateIsValid(expDate)
                      ? moment(expDate)
                      : null
                    }
                    onChange={e => this.handleChangeField("expDate", e)}
                  />
                </Col>
			  </Row>
			  */}
              <FormGroup row>
                <Label xs={5}>Exp Date</Label>
                <Col xs={7}>
                  <MaskedInput
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={false}
                    placeholder="MM/DD/YYYY"
                    onChange={e => this.handleChangeField("expDateNew", e)}
                    style={this.state.expDateStyle}
                    className="form-control"
                    value={this.state.expDateDisplay}
                    showMask={true}
                    keepCharPositions={true}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label>Prep Category</Label>
                <SelectPrepCategory
                  value={this.state.listingItem?.prep_category
                    ? this.state.listingItem?.prep_category
                    : listingItem.prep_category || null
                  }
                  onChange={this.updatePrepCategory}
                />
              </FormGroup>
              <FormGroup>
                <Label>Who Labels?</Label>
                <SelectWhoLabelsPreps
                  value={this.state.listingItem?.label_owner
                    ? this.state.listingItem?.label_owner
                    : listingItem.label_owner || null
                  }
                  onChange={this.updateLabelOwner}
                />
              </FormGroup>
            </Col>
            <Col xs="7" className="pl-5">
              <FormGroup row>
                <Label xs={4}>Buy Cost</Label>
                <Col xs={8}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                    <Input
                      type="number"
                      onChange={e => this.handleChangeField("buyCost", e)}
                      onBlur={e => this.handleChangeField("buyCost", e, true)}
                      defaultValue={buyCost === 0 ? "0" : buyCost || ""}
                      className={`${
                        this.checkNumFields(buyCost, true) ? "is-invalid" : ""
                      }`}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4}>Supplier</Label>
                <Col xs={8}>
                  <Input
                    onChange={e => this.handleChangeField("supplier", e)}
                    defaultValue={supplier}
                  />
                </Col>
              </FormGroup>
			  {/*
              <Row>
                <Col xs="3" className="col-align-center">
                  <strong className="text-center">Date Purchased</strong>
                </Col>
                <Col xs="9">
                  <DatePicker
                    customInput={<DatePickerCustomInput />}
                    isClearable={true}
                    dateFormat="L"
                    selected={
                      momentDateIsValid(datePurchased)
                        ? moment(datePurchased)
                        : null
                    }
                    onChange={e => this.handleChangeField("datePurchased", e)}
                  />
                </Col>
              </Row>
			  */}
              <FormGroup row className="my-4">
                <Label xs={4}>Date Purchased</Label>
                <Col xs={8}>
                  <MaskedInput
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={false}
                    placeholder="MM/DD/YYYY"
                    onChange={e => this.handleChangeField("datePurchasedNew", e)}
                    style={this.state.datePurchasedStyle}
                    className="form-control"
                    value={this.state.datePurchasedDisplay}
                    showMask={true}
                    keepCharPositions={true}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={4}>Tax Code</Label>
                <Col xs={8}>
                  <TaxCodeInputForm
                    onChange={e => this.handleChangeField("taxCode", e)}
                    value={taxCode}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label>Prep Types</Label>
                <SelectPrepType
                  value={this.state.listingItem?.prep_types
                    ? this.state.listingItem?.prep_types
                    : listingItem.prep_types || ""
                  }
                  onChange={this.updatePrepTypes}
                />
              </FormGroup>
              <FormGroup>
                <Label>Who Preps?</Label>
                <SelectWhoLabelsPreps
                  value={this.state.listingItem?.prep_owner
                    ? this.state.listingItem?.prep_owner
                    : listingItem.prep_owner || null
                  }
                  onChange={this.updatePrepOwner}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={this.saveChanges}
            disabled={
              this.checkNumFields(buyCost, true) ||
              this.checkNumFields(price) ||
              this.checkNumFields(qty)
            }
          >
            Save Changes
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

EditListingItemModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  listingItem: PropTypes.object,
  editListingItem: PropTypes.func.isRequired,
  internationalConfig: PropTypes.object.isRequired,
  batchListingDefaults: PropTypes.object.isRequired
};

export default EditListingItemModal;
