import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';
import actions from "./actions";
import appActions from "../app/actions";
import { openInNewTab } from '../../helpers/utility';
import { logError } from "../../helpers/utility";

export function* exportLabelPdf() {
	yield takeLatest(actions.EXPORT_LABEL_PDF, function*(payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.batch.export_pdf_labels, data);
			if(!response.data.error){
				openInNewTab(response.data.pdf);
				yield put(actions.exportLabelPdfSuccess())
			} else {
				yield put(actions.exportLabelPdfFailed())
			}
		} catch (error) {
			yield put(actions.exportLabelPdfFailed())
			yield put(appActions.apiCallFailed("Error! Label generate"));
			logError(error, {tags: { exceptionType: actions.EXPORT_LABEL_PDF, }});
		}
	});
}

export function* exportLabel30Up() {
	yield takeLatest(actions.EXPORT_LABEL_30UP, function*(payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.batch.export_30up_labels, data);
			if(!response.data.error){
				openInNewTab(response.data.pdf);
				yield put(actions.exportLabel30upSuccess())
			} else {
				yield put(actions.exportLabel30upFailed())
			}
		} catch (error) {
			yield put(actions.exportLabel30upFailed())
			yield put(appActions.apiCallFailed("Error! Label 30up generate"));
			logError(error, {tags: { exceptionType: actions.EXPORT_LABEL_30UP, }});
		}
	});
}

export function* exportExpenses() {
	yield takeLatest(actions.EXPORT_EXPENSES, function*(payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.accounting.export_pdf_expenses, data);
			if(!response.data.error){
				openInNewTab(response.data.pdf);
				yield put(actions.exportExpensesSuccess())
			} else {
				yield put(actions.exportExpensesFailed())
			}
		} catch (error) {
			yield put(actions.exportExpensesFailed())
			yield put(appActions.apiCallFailed("Error! Accounting expenses generate"));
			logError(error, {tags: { exceptionType: actions.EXPORT_EXPENSES, }});
		}
	});
}

export function* exportPL() {
	yield takeLatest(actions.EXPORT_PL, function*(payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.accounting.export_pdf_pl, data);
			if(!response.data.error){
				openInNewTab(response.data.pdf);
				yield put(actions.exportPLSuccess())
			} else {
				yield put(actions.exportPLFailed())
			}
		} catch (error) {
			yield put(actions.exportPLFailed())
			yield put(appActions.apiCallFailed("Error! Accounting PL generate"));
			logError(error, {tags: { exceptionType: actions.EXPORT_PL, }});
		}
	});
}

export default function* rootSaga() {
  yield all([
    fork(exportLabelPdf),
		fork(exportLabel30Up),
		fork(exportExpenses),
		fork(exportPL),
  ]);
}
