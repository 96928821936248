import React from 'react';
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/lib/fa';
import appActions from '../../../redux/app/actions';

const {
  appPushErrorMessageList,
  appErrorMessageClose,
} = appActions;

class ErrorModal extends React.Component {
  render() {
    const {
      isOpen,
      toggle,
      errors,
    } = this.props;

    const error_data = errors ? errors : this.props.error_mesages_list;

    return (
      <Modal isOpen={isOpen} toggle={toggle ? toggle : this.props.appErrorMessageClose} size="lg">
        <ModalHeader toggle={toggle}>Error Messages ({errors ? errors?.length : this.props.error_mesages_list?.length})</ModalHeader>
        <ModalBody style={{maxHeight: '70vh', overflowY: 'auto'}} className="scrollable">
          {error_data?.map((error, index) => (
            <div key={index} className="alert alert-danger mb-2 rounded">
              <div className="d-flex align-items-start gap-2">
                <FaExclamationTriangle className="mt-1" />
                <div>
                  <h6 className="alert-heading mb-1">{error?.details}</h6>
                  <p className="mb-0">{error?.message}</p>
                </div>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle ? toggle : this.props.appErrorMessageClose}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
};

export default connect(
  state => ({
    error_message_display: state.App.get("error_message_display"),
    error_mesages_list: state.App.get("error_mesages_list"),
  }),
  {
    appPushErrorMessageList,
    appErrorMessageClose,
  }
)(ErrorModal)
