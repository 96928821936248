import React from "react";
class TableGeneratorLeftArrow extends React.Component {
  
  render() {
    return (
      <svg className="arrow right-arrow" width="144" height="134" viewBox="860 597 144 134" xmlns="http://www.w3.org/2000/svg">
        <path d="M986.79228286 684.52302951c-2.57351506 2.68981493-5.13876991 4.84196757-7.03798685 7.47573555-3.10024624 4.2957468-5.82851794 8.86571537-8.54369021 13.421404-.41093566.68864187.08960857 1.91930326.16919012 2.89964021.76241404-.58279555 1.97218813-1.01773637 2.21694652-1.76955718 2.96473672-9.05079157 9.31290746-15.6785339 15.81088061-22.1663946.97362037-.97173662 2.69059592-1.1733059 4.0274555-1.81155027 1.59902715-.76234495 3.1587988-1.60802956 4.7339298-2.41936195-.07404211-.3509757-.15056913-.6997461-.22422542-1.04928573-3.21760243-1.60891672-6.4775232-3.14033288-9.64688593-4.8391078-11.1451729-5.97475482-21.0408213-13.4075323-27.91869964-24.29539534-.05371414-.08561916-.12914086-.15465047-.198159-.2284758-.99953589-1.08311016-1.59245346-3.5830243-3.50711336-2.25296933-1.95741795 1.35997507.14534099 2.81063401.95963919 3.98338257 8.55472206 12.3510964 20.5511451 20.21647907 34.11974282 26.00193416.53706769.22998521 1.0637287.48429987 1.70807013 1.47640625-.9489178.25426176-1.88623293.68389827-2.84611494.7364569-19.28482333 1.07608425-38.56540327 2.01870765-57.85661951-.00695082-14.30877702-1.5020118-27.84613233-5.58024382-40.52042383-12.29464341-11.68413619-6.1888448-19.88316083-15.86875709-25.70757339-27.56819176-1.92528344-3.8661954-3.17631925-8.06999655-5.10882268-12.12966677.70666914 3.15413408 1.05296013 6.44483634 2.18019816 9.44011947 7.93974953 21.09846331 23.62593604 33.84512444 44.40583714 41.30029506 15.57402884 5.58660466 31.66133092 7.75989353 48.0351859 7.78691784 9.51675308.01799937 19.03497717-1.05529698 28.5507307-1.63248752.58270153-.03612 1.16640657-.03404219 2.19850817-.05825373" fill="#BAC5B4" fillRule="evenodd"/>
      </svg>
    );
  }
}

TableGeneratorLeftArrow.propTypes = {
};

export default TableGeneratorLeftArrow;
