import React, { Component } from 'react';
import { connect } from "react-redux";
import { Badge, Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Popover, UncontrolledTooltip } from "reactstrap";
import ReactTable from "react-table";
import OrderDetailsModal from "./OrderDetailsModal";
import './style.css';
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { subDays } from 'date-fns';
import { momentDateToLocalFormatConversion } from '../../../helpers/utility';
import Select from "react-select"
import orderActions from "../../../redux/order/actions";
import moment from "moment";
import { digitСonversion } from "../../../helpers/utility";
import appActions from "../../../redux/app/actions";

const { userPageviewCapture } = appActions;

const {
  getOrders,
  getOrder,
} = orderActions;

class Orders extends Component {
  state = {
    showOrderId: null,
    showIntervalDropdown: false,
    range: {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    },
    filters: {
      date_start: parseInt(subDays(new Date(), 7).getTime() / 1000),
      date_end: parseInt(new Date().getTime() / 1000),
    },
    order_status: {label: "Shipped", value: "Shipped"},
  }

  generateTableColumns = () => {
    let tableColumns = [
      {
        accessor: "AmazonOrderId",
        Header: "Order ID",
        headerClassName: "text-left",
        Cell: props =>
        <Button
          color="link"
          onClick={() => {
            this.props.getOrder({ id: props.original.AmazonOrderId });
            this.setState({ showOrderId: props.original.AmazonOrderId });
          }}
        >{props.original.AmazonOrderId}</Button>
      },
      {
        accessor: "OrderType",
        Header: "Order Type",
        headerClassName: "text-left",
        maxWidth: 150,
      },
      {
        accessor: "FulfillmentChannel",
        Header: "Channel",
        headerClassName: "text-left",
        maxWidth: 100,
      },
      {
        accessor: "PurchaseDate",
        Header: "Order Date",
        headerClassName: "text-left",
        Cell: props => <span>{moment(props.original.PurchaseDate).format("LL")}</span>
      },
      {
        accessor: "LastUpdateDate",
        Header: "Last Update",
        headerClassName: "text-left",
        Cell: props => <span>{moment(props.original.LastUpdateDate).format("LL")}</span>
      },
      {
        accessor: "OrderStatus",
        Header: "Status",
        headerClassName: "text-left",
        Cell: props => <Badge color="info">{props.original.OrderStatus.toUpperCase()}</Badge>,
        maxWidth: 100,
      },
      {
        accessor: "NumberOfItemsShipped",
        Header: "Shipped",
        headerClassName: "text-left",
        maxWidth: 70,
      },
      {
        accessor: "OrderTotal",
        Header: "Total",
        headerClassName: "text-left",
        Cell: props => <span>{digitСonversion(props.original.OrderTotal?.Amount, "currency")}</span>
      },
      /*
      {
        accessor: "order_comment",
        Header: "Comment",
        headerClassName: "text-left",
        minWidth: 250,
        Cell: props =>
        <div>
          <div
            id={`tooltip-detail-${props.index}`}
            className="text-truncate"
            style={{ textDecoration: "underline" }}
          >{props.original.order_comment}</div>
          <UncontrolledTooltip placement="left" target={`tooltip-detail-${props.index}`}>
            {props.original.order_comment}
          </UncontrolledTooltip>
        </div>
      },
      {
        accessor: "order_rating_id",
        Header: "Feedback Removal",
        headerClassName: "text-left",
        Cell: props => props.original.feedback_removal_requested ?
        <Button color="secondary" disabled>Requested</Button> :
        <Button color="success">Request</Button>
      }
      */
    ]
    return tableColumns
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges, context) => {
    this.setState({
      range: ranges['selection'],
    })
    let filters = Object.assign({}, this.state.filters);
    filters.date_start = parseInt(ranges.selection.startDate.getTime() / 1000);
    filters.date_end = parseInt(ranges.selection.endDate.getTime() / 1000);
    this.setState({ filters: filters });
  }

  applyRange = () => {
    this.getOrders({reset: true});
  }

  getOrders = (data) => {
    const payload = {
      start: data?.start
        ? moment.utc(data.start).format("YYYY-MM-DD")
        : moment.utc(this.state.range.startDate).format("YYYY-MM-DD"),
      end: data?.end
        ? moment.utc(data.end).format("YYYY-MM-DD")
        : moment.utc(this.state.range.endDate).format("YYYY-MM-DD"),
      status: data?.status
        ? data.status.value
        : this.state.order_status.value,
      page_size: 10,
      next: data?.next
        ? data.next
        : null,
    }
    this.props.getOrders({payload: payload, reset: data?.reset});
  }

  componentDidMount = () => {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Orders"})
    this.getOrders();
  }

  render() {
    const {
      showIntervalDropdown,
      range
    } = this.state;

    const {
      orders,
      orders_working
    } = this.props;

    return (
      <div className="view orders">
        <header className="view-header d-flex justify-content-between align-items-center">
          <h1 className="page-title mb-0">Orders</h1>
          <div className="d-flex gap-2">
          <Select
            options={[
              {label: "PendingAvailability", value: "PendingAvailability"},
              {label: "Pending", value: "Pending"},
              {label: "Unshipped", value: "Unshipped"},
              {label: "PartiallyShipped", value: "PartiallyShipped"},
              {label: "Shipped", value: "Shipped"},
              {label: "InvoiceUnconfirmed", value: "InvoiceUnconfirmed"},
              {label: "Canceled", value: "Canceled"},
              {label: "Unfulfillable", value: "Unfulfillable"},
            ]}
            value={this.state.order_status}
            onChange={(e) => {
              this.setState({order_status: e});
              this.getOrders({status: e, reset: true});
            }}
            clearable={false}
            backspaceRemovesValue={false}
            style={{ minWidth: '13rem' }}
          />
          <Dropdown isOpen={showIntervalDropdown} toggle={this.toggleIntervalDropdown} className="ml-3">
            <DropdownToggle caret>
              Date Range: {momentDateToLocalFormatConversion(range.startDate, true)} - {momentDateToLocalFormatConversion(range.endDate, true)}
            </DropdownToggle>
            <DropdownMenu right style={{ minWidth: 890, transition: 'none' }}>
              <div>
                <DateRangePicker
                  months={2}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  direction="horizontal"
                  showDateDisplay={false}
                  ranges={[range]}
                  onChange={this.customRangesChange}
                />
                <div className="text-right px-3">
                  <Button size="sm" className="mr-2"
                    onClick={() => {
                      this.toggleIntervalDropdown();
                    }}
                  >Cancel</Button>
                  <Button size="sm" color="primary"
                    onClick={() => {
                      this.toggleIntervalDropdown();
                      this.applyRange();
                    }}
                  >Apply</Button>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          </div>
        </header>
        <div className="view-content">
          <Card>
            <CardBody>
              <ReactTable
                data={orders?.Orders ? orders?.Orders : []}
                columns={this.generateTableColumns()}
                pageSize={orders?.Orders ? orders?.Orders.length : 0}
                pageIndex={0}
                manualPagination={true}
                loading={orders_working}
                minRows={0}
                showPagination={false}
              />
            </CardBody>
          </Card>
          {orders?.NextToken &&
          <div className="mt-4 text-center">
            <Button
              onClick={() => {this.getOrders({next: orders?.NextToken})}}
              disabled={orders_working}
            >Load more</Button>
          </div>
          }
          {this.state.showOrderId &&
          <OrderDetailsModal
            isOpen={true}
            toggle={() => this.setState({ showOrderId: null })}
            order={this.props.order}
          />
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    orders: state.Order.get('orders'),
    orders_working: state.Order.get('orders_working'),
    order: state.Order.get('order'),
  }),
  {
    getOrders,
    getOrder,
    userPageviewCapture,
  }
)(Orders);
