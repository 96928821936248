import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalBody
} from 'reactstrap'
import PropTypes from 'prop-types'

import './style.css';

class DeleteListingItemModal extends Component {

  deleteItem = () => {
    const {
      isHoldingAreaListing,
      currentEditableListingData,
      deleteListingItem,
      close
    } = this.props;
    deleteListingItem(currentEditableListingData, isHoldingAreaListing);
    close();
  }
  render() {
    const { close, isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} className="delete-dialog" centered toggle={close}>
        <ModalBody className="text-center">
          <div>
            <span className="icon icon-warning bg-danger"></span>
          </div>
          <h5 className="delete-dialog-title">Delete item?</h5>
          <p className="delete-dialog-description">Once deleted, you will have to re-list this item if you want to add it to the batch.</p>
          <div className="text-center"></div>
          <div className="col-align-center gap-5 mt-4">
            <Button onClick={close}>Cancel</Button>
            <Button color="danger" onClick={this.deleteItem}>Delete</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteListingItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentWorkingListingData: PropTypes.object,
  deleteListingItem: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isHoldingAreaListing: PropTypes.bool.isRequired
}

export default DeleteListingItemModal;