import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { amazonLogoSmall } from '../../../assets/images';
import { StyledModal } from "./components";

/**
 * Allert box modal, to be used to display amazon messages with explanations
 * Most of these modals will be triggered related to Amazon API responses
 *
 * @version 1.0.0
 * @author [Tedi Kurniadi]
 */
class AlertBox extends Component {
  render() {
    return (
      <StyledModal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} />
        <ModalBody>
          <div className="message-group">
            <span className="message-label"><img src={amazonLogoSmall} alt="Amazon Logo" /> Amazon says:</span>
            <p className="message text-danger">{ this.props.amazonMessage }</p>
          </div>
          <div className="message-group">
            <span className="message-label">We say:</span>
            <p className="message text-danger">{ this.props.message }</p>
            <div className="articles">
              <h4 className="heading">Helpful article:</h4>
              <ul className="list-unstyled">
				<li>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={ this.props.url }
					>{ this.props.url }</a>
				</li>
              </ul>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.props.toggle}>CLOSE</Button>
        </ModalFooter>
      </StyledModal>
    );
  }
}

AlertBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  amazonMessage: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AlertBox;

