import React, { Component } from 'react';
import { Button, Col, FormGroup, Input } from 'reactstrap';
import { DropTarget, DragSource } from 'react-dnd';
import flow from 'lodash/flow';

const Types = {
  TOPIC: 'topic'
};

const topicSource = {
  beginDrag(props) {
    return {
      topic_id: props.topic_id,
      index: props.index
    }
  },
};

const topicTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return
    }

    props.saveTopicOrder(props, monitor.getItem());
    return { moved: true }
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class FAQTopic extends Component {
  render() {
    const {
      topic_id,
      topic_type,
      name,
      description,
      updateTopicData,
      updateTopic,
      topic_update_data,
      deleteTopic,
      isDragging,
      isOver,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div className={
            `faq-topic-draggable ${isDragging ? "dragging" : ""}
            ${isOver ? "target-active" : ""}
          `}
            style={this.props.disabled ? { "pointer-events": "none", opacity: 0.7 } : null }
          >
            <span className="icon icon-menu-line icon-drag"></span>
            <FormGroup row>
              <Col md={4}>
                <Input
                  placeholder="Topic menu name"
                  value={name}
                  onChange={e => updateTopicData(topic_id, "name", e.target.value)}
                />
              </Col>
              <Col md={4}>
                <Input
                  placeholder="Topic title"
                  value={description}
                  onChange={e => updateTopicData(topic_id, "description", e.target.value)}
                />
              </Col>
              <Col md={2}>
                <Input
                  value={topic_type}
                  disabled={true}
                />
              </Col>
              <Col md={2}>
                <Button
                  color="success"
                  onClick={e => updateTopic(topic_id)}
                  disabled={!topic_update_data[topic_id] || !topic_update_data[topic_id].topic_id}
                >Save</Button>
                <Button color="danger" className="ml-2" onClick={deleteTopic}>Delete</Button>
              </Col>
            </FormGroup>
          </div>
        )
      )
    )
  }
}

export default flow(
  DropTarget(Types.TOPIC, topicTarget, collectTarget),
  DragSource(Types.TOPIC, topicSource, collectSource)
)(FAQTopic);
