import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
  Table, Button, Input, InputGroup, InputGroupAddon, InputGroupText,
  Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import ExpenseRow from './ExpenseRow';
import ExpenseCalendar from './ExpenseCalendar';
import styled from 'styled-components';
import accountingActions from '../../../../../redux/accounting/actions';
import moment from 'moment';
import { generateAllExpensesFileExport } from '../../exportCSV';

const {
	expenseLoadCategories,
	expenseAddCategory,
	expenseLoad,
	expenseAdd,
	expenseDelete,
	expenseUpdate,
	expensesGetDataForPeriod,
} = accountingActions;

const StyledTable = styled(Table)`
	th, td {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

  > tfoot > tr > td {
    border-top: 0;
  }
`;

class ExpenseTable extends Component {

	state = {
		expenses: [],
		startDateCalendar: null,
		endDateCalendar: null,
		searchKeyword: "",
		currentPage: 1,
		pageLength: 5,
		sorting: {key: null, type: null},
		endDateShown: false
	}

	componentDidMount(){
		const data = {
			startDate: moment().startOf('month').toISOString(),
			endDate: moment().add(6, 'M').toISOString(),
		}
		this.setState({startDateCalendar: data.startDate});
		this.setState({endDateCalendar: data.endDate});
		this.props.expenseLoadCategories();
		this.props.expenseLoad();
		this.props.expensesGetDataForPeriod(data);
	}

	getMonthsData = (monthBeingViewed) => {
		let m = monthBeingViewed;
		const data = {
			startDate: m.startOf('month').toISOString(),
			endDate: moment(m.startOf('month').toISOString()).add(6, 'M').toISOString(),
		}
		this.setState({startDateCalendar: data.startDate});
		this.setState({endDateCalendar: data.endDate});
		this.props.expensesGetDataForPeriod(data);
	}

  addExpense = () => {
    let expenses = this.state.expenses;
    expenses.unshift({
      category: '',
      date: new Date(),
      amount: 0,
      description: '',
      repeat: ''
    });
    this.setState({
      expenses: expenses
    });
  }

  deleteUncreatedExpense = (idx) => {
    let expenses = this.state.expenses;
    delete expenses[idx];
    this.setState({ expenses: expenses });
  }

	addExpenseCategory(){

	}

	addExpenseFromRow = (expense) => {
		const { startDateCalendar, endDateCalendar } = this.state;
		this.props.expenseAdd(expense, startDateCalendar, endDateCalendar);
		this.setState(prevState => ({
		  expenses: prevState.expenses.filter((obj,index) => index !== expense.idx)
		}));
	}

	updateExpenseFromRow = (expense) => {
		const { startDateCalendar, endDateCalendar } = this.state;
		this.props.expenseUpdate(expense, startDateCalendar, endDateCalendar);
	}

	deleteExpense(id){
		const { startDateCalendar, endDateCalendar } = this.state;
		this.props.expenseDelete({id: id}, startDateCalendar, endDateCalendar);
	}

	searchExpenseChanged  = (e) => {
		this.setState({
			searchKeyword: e.target.value
		});
	}

	generatePagination = (data) => {
		const {
			currentPage,
			pageLength
		} = this.state;

		let paginationItems = [];

		const pageCount = Math.ceil(data.length/pageLength);

		for (let i = 1; i <= pageCount; i++) {
			paginationItems.push(
        <PaginationItem
          active={i === currentPage}
          key={`pagination_item_${i}`}
        >
          <PaginationLink
            href="#"
            onClick={() => this.goToPage(i)}
          >{i}</PaginationLink>
				</PaginationItem>
			);
		}

		return (
			<Pagination>
				<PaginationItem disabled={currentPage === 1}>
					<PaginationLink previous href="#" onClick={this.previousPage} />
				</PaginationItem>
				{paginationItems}
				<PaginationItem disabled={currentPage === pageCount}>
					<PaginationLink next href="#" onClick={e => this.nextPage(data)} />
				</PaginationItem>
			</Pagination>
		)
	}

	goToPage = (page) => {
		this.setState({
			currentPage: page
		})
	}

	nextPage = (data) => {
		const {
			currentPage,
			pageLength
		} = this.state;

		const pageCount = Math.ceil(data.length/pageLength);

		this.setState({
			currentPage: (currentPage + 1) > pageCount ? currentPage : (currentPage + 1)
		});
	}

	previousPage = () => {
		const {
			currentPage
		} = this.state;

		this.setState({
			currentPage: (currentPage - 1) <= 0 ? currentPage : (currentPage - 1)
		});
	}

	changePageLength = (e) => {
		this.setState({
			pageLength: e.target.value,
			currentPage: 1
		})
	}

	sortTable = (key) => {
		var type = 'desc';
		if(key !== this.state.sorting.key){
			type = 'asc';
		} else {
			if(this.state.sorting.type === 'desc'){
				type = 'asc';
			}
		}
		var field = null;
		if(key === 'amount'){
			field = 'amount';
		} else if (key === 'date'){
			field = 'epoch_start_date';
		} else if (key === 'category'){
			field = 'category';
		}
		this.setState({ sorting: { key, type, field } });
	}

	allExpensesFileExport = () => {
		const { expenseData } = this.props;
	    generateAllExpensesFileExport(expenseData);
	}
	
  render() {
    const {
		expenseCategories,
		expenseData,
		} = this.props;

		const {
			searchKeyword,
			currentPage,
			pageLength,
			sorting,
		} = this.state;

		let full_data = [];
		expenseData.forEach(item => {
			const category = expenseCategories.find(
				category => category.id === item.category_id);
			if(category){
				const ss = [item.description.toString(), item.start_date.toString(),
					item.amount.toString(), category.name];
				item["category"] = category.name;
				if(ss.join(' ').toLowerCase().includes(searchKeyword.toLowerCase())){
					full_data.push(item);
				}
			}
		});

		const start = currentPage === 1 ? 0 : (currentPage*pageLength) - pageLength;
		const end = start + pageLength;

		if(['amount', 'date', 'category'].includes((sorting.key))){
			if(sorting.type === 'desc'){
				full_data = full_data.sort((a, b) => (a[sorting.field] > b[sorting.field]) ? -1 : 1);
			} else {
				full_data = full_data.sort((a, b) => (a[sorting.field] > b[sorting.field]) ? 1 : -1);
			}
		}

		const data = full_data.slice(start,end);

    return (
      <Fragment>
		<ExpenseCalendar
			expenseDates={this.props.expensesCalendarData}
			getMonthsData={this.getMonthsData}
		/>

				<div className="d-flex justify-content-between mb-3">
					<InputGroup className="transparent-addon" style={{ width: "30%" }}>
						<Input placeholder="Search expense" onChange={this.searchExpenseChanged} />
						<InputGroupAddon addonType="append">
							<InputGroupText><span className="icon icon-search"></span></InputGroupText>
						</InputGroupAddon>
					</InputGroup>
					<div className="d-flex gap-2">
						<Button onClick={this.allExpensesFileExport}><span className="icon icon-csv"></span> Export all expenses</Button>
						<Button color="success" onClick={this.addExpense}>Add expense item</Button>
					</div>
				</div>

        <StyledTable className="acc-table acc-table-left mb-2">
          <thead>
            <tr>
              <th>&nbsp;</th>
							<th className="sort" onClick={e => this.sortTable("category")}>
								Expense Category
								<SortingOrder field="category" sorting={sorting} />
							</th>
              <th className="sort" onClick={e => this.sortTable("date")} style={{ width: "11rem" }}>
								Date of Expense
								<SortingOrder field="date" sorting={sorting} />
							</th>
							<th className="sort" onClick={e => this.sortTable("amount")} style={{ width: "6.5rem" }}>
								Amount
								<SortingOrder field="amount" sorting={sorting} />
							</th>
              <th>Description</th>
              <th>Repeat Expense</th>
							<th style={{ width: "9rem" }}>End Date</th>
							<th style={{ width: "7rem" }}></th>
            </tr>
          </thead>
          <tbody>
					{this.state.expenses.map((expense, idx) => (
							<ExpenseRow
								key={idx+'a'}
								data={expense}
								idx={idx}
								deleteExpense={() => this.deleteUncreatedExpense(idx)}
								addExpenseCategory={this.addExpenseCategory}
								addExpense={this.addExpenseFromRow}
								updateExpense={null}
								expenseCategories={expenseCategories.map(item => {
									return {value: item.id, label: item.name}
								})}
								internationalization_config={this.props.internationalization_config}
								showEndDate={(val) => this.setState({ endDateShown: val })}
							/>
						))
					}
					{ data.length > 0 ? (
              data.map((expense, idx) => (
								<ExpenseRow
									key={expense.id}
									idx={idx}
									data={expense}
									deleteExpense={() => this.deleteExpense(expense.id)}
									addExpenseCategory={this.addExpenseCategory}
									addExpense={this.addExpenseFromRow}
									updateExpense={this.updateExpenseFromRow}
									expenseCategories={expenseCategories.map(item => {
										return {value: item.id, label: item.name}
									})}
									internationalization_config={this.props.internationalization_config}
									showEndDate={(val) => this.setState({ endDateShown: val })}
								/>
							))
						) : (
              <tr>
                <td colSpan="8" className="text-center text-muted">There are no expenses to display</td>
							</tr>
						)
          }
          </tbody>
        </StyledTable>
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Input type="select" onChange={this.changePageLength} value={pageLength}>
							<option>5</option>
							<option>10</option>
							<option>15</option>
							<option>50</option>
							<option>100</option>
						</Input>
					</div>
					{expenseData.length > 0 && this.generatePagination(full_data)}
				</div>
      </Fragment>
    )
  }
}

const SortingOrder = props => (
	<span className={`order ${props.sorting.key === props.field ? props.sorting.type : ""}`}>
		<span className="dropdown"><span className="caret"></span></span>
		<span className="dropup"><span className="caret"></span></span>
	</span>
)

export default connect(
  state => {
    return {
      expenseCategories: state.Accounting.get("expenseCategories"),
      expenseData: state.Accounting.get("expenseData"),
			expensesCalendarData: state.Accounting.get("expensesCalendarData"),
			internationalization_config: state.Auth.get("internationalization_config"),
    };
  },
  {
	  expenseLoadCategories,
	  expenseAddCategory,
	  expenseLoad,
	  expenseAdd,
	  expenseDelete,
	  expenseUpdate,
	  expensesGetDataForPeriod,
  }
)(ExpenseTable);

