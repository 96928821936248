import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from "../../../helpers/utility";
import "./style.css";

class ProfitabilityTableMetrics extends Component {
  state = {
      pageSize: 10,
      page: 0,
  }

	render() {
		const {
			data,
			metrics,
			type,
		} = this.props;

		let tableColumns = [
			{
				id: "supplier",
				Header: "Supplier",
				accessor: "supplier",
			},
			{
				id: "units_sold",
				Header: "Units Sold",
				accessor: "units_sold",
				headerClassName: "text-right",
				className: "text-right"
			},
			/*
			{
				id: "units_refunded",
				Header: "Units Refunded",
				accessor: "units_refunded",
				className: "text-right"
			},
			*/
			{
				id: "revenue",
				Header: "Revenue",
				accessor: "revenue",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					digitСonversion(props.original.revenue, "currency", this.props.internationalization_config.currency_code, 2)
				)
			},
			{
				id: "revenue_percentage",
				Header: "% of Revenue",
				accessor: "revenue_percentage",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					digitСonversion(props.original.revenue_percentage, "percent", 2)
				)
			},
			{
				id: "profit",
				Header: "Profit",
				accessor: "profit",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					digitСonversion(props.original.profit, "currency", this.props.internationalization_config.currency_code, 2)
				)
			},
			{
				id: "roi_percentage",
				Header: "ROI %",
				accessor: "roi_percentage",
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (
					digitСonversion(props.original.roi_percentage, "percent", 2)
				)
			},
			{
				id: "on_hand",
				Header: "On Hand",
				accessor: "on_hand",
				headerClassName: "text-right",
				className: "text-right"
			},
		]

		if(type === 'scouts'){
			tableColumns[0] = {
				id: "scout",
				Header: "Scout",
				accessor: "scout",
			}
		} else if (type === 'category') {
			tableColumns[0] = {
				id: "category",
				Header: "Category",
				accessor: "category"
			}
		}

		return (
			<React.Fragment>
				<ReactTable
					data={data}
					columns={tableColumns}
          defaultPageSize={this.state.pageSize}
          pageSize={this.state.pageSize}
          page={this.state.page}
          onPageChange={page => this.setState({page: page})}
          onPageSizeChange={(pageSize, page) => {
            this.setState({ pageSize: pageSize, page: 0 });
					}}
					minRows={0}
				/>
				<div className="profitability-metrics">
					<div className="metric-item">
						<p className="title">Units Sold</p>
						<span className="value">{metrics.units_sold}</span>
					</div>
					{/*
					<div className="metric-item">
						<p className="title">Units Refunded</p>
						<span className="value text-danger">{metrics.units_refunded}</span>
					</div>
					*/}
					<div className="metric-item">
						<p className="title">Revenue</p>
						<span className="value">{digitСonversion(metrics.revenue, "currency", this.props.internationalization_config.currency_code, 2)}</span>
					</div>
					<div className="metric-item">
						<p className="title">Profit</p>
						<span className="value">{digitСonversion(metrics.profit, "currency", this.props.internationalization_config.currency_code, 2)}</span>
					</div>
					<div className="metric-item">
						<p className="title">On Hand</p>
						<span className="value">{metrics.on_hand}</span>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default ProfitabilityTableMetrics;
