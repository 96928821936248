import React from "react";
import { connect } from "react-redux";
import {
  Label, Input, Badge, Button, FormGroup,
  Row, Col, Collapse
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";
import TaxCodeInputForm from "../../../../../shared/components/taxCodeInputForm";
import { validateFloatInput } from "../../../../../helpers/utility";
import FaSpinner from "react-icons/lib/fa/spinner";
import {
  generateTemplatedSKU,
  skuNumberConversion
} from "../../../../../helpers/batch/utility";
import SupplierInputForm from "../sidebar/SupplierInputForm";
import {
  momentDateToISOFormatConversion
} from "../../../../../helpers/utility";
import LoadingDisplay from "../loading_display";
import settingsActions from "../../../../../redux/settings/actions";
const {fetchPrinterDefaults} = settingsActions;
import ScoutInputForm from "../sidebar/ScoutInputForm";
import SelectPrepCategory, { options as optionsPrepCategory } from "../../../../../shared/components/SelectPrepCategory";
import SelectPrepType, { options as optionsPrepType } from "../../../../../shared/components/SelectPrepType";
import SelectWhoLabelsPreps, {options as optionsWhoLabelsPreps} from "../../../../../shared/components/SelectWhoLabelsPreps";
import { FaChevronUp, FaChevronDown } from "react-icons/lib/fa";

/*
This component is used to visualize the listing when it is initialized and there is some default
set for the user, but the user has not elected to add the item to the batch yet. There may be some
final edits before they click it.

props field expects:
1) listing field
2) addItemToBatch function
3) cancel function
*/

class GhostResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSkuDetailsOpen: localStorage.getItem('skuDetailsOpen') === 'true',
    };
  }

  componentDidMount() {
    this.props.fetchPrinterDefaults();
    this.loadPrepData();
  }

  loadPrepData = () => {
    const { listing, batchMetadata } = this.props;
    if(batchMetadata.prep_category){
      this.updateCurrentWorkingListingData(
        "prep_category",
        optionsPrepCategory.find(item => item.value === batchMetadata.prep_category)
      )
    }
    if(batchMetadata.prep_types){
      this.updateCurrentWorkingListingData(
        "prep_types",
        optionsPrepType.find(item => item.value === batchMetadata.prep_types)
      )
    }
    if(batchMetadata.prep_owner){
      this.updateCurrentWorkingListingData(
        "prep_owner",
        optionsWhoLabelsPreps.find(item => item.value === batchMetadata.prep_owner)
      )
    }
    if(batchMetadata.label_owner){
      this.updateCurrentWorkingListingData(
        "label_owner",
        optionsWhoLabelsPreps.find(item => item.value === batchMetadata.label_owner)
      )
    }
  }

  updateFloatInputData(fieldName, event) {
    if (!event.target) {
      return;
    }
    const validator = validateFloatInput(true);
    let price = validator(event);
    this.props.updateCurrentWorkingListingData(fieldName, price);
  }

  updateIntInputData(fieldName, event) {
    if (!event.target) {
      return;
    }
    let parsedValue = parseInt(event.target.value, 10);
    if (!isNaN(parsedValue)) {
      this.props.updateCurrentWorkingListingData(fieldName, parsedValue);
    }
  }

  updateCurrentWorkingListingData(fieldName, event) {
    let selectFieldNames = [
      "taxCode",
      "noteCategory",
      "noteSubcatgory",
      "condition",
      "expDate",
      "datePurchased",
      "prep_types",
      "prep_owner",
      "prep_category",
      "label_owner",
    ];
    if (!event && selectFieldNames.indexOf(fieldName) !== -1) {
      // in this case, the event was null but that's because of react-datepicker behavior
      // when the field is empty - it will make the event null.
      // So, in this case it's still ok, and we propogate the null event.
      this.props.updateCurrentWorkingListingData(fieldName, null);
    } else if (event.value && event.label) {
      // ok then it is a select component.
      this.props.updateCurrentWorkingListingData(fieldName, event.value);
    } else if (event._d) {
      // ok then it is a datepicker component (this will be a moment object.)
      // _d field denotes that there is a current date set that can be formatted.
      this.props.updateCurrentWorkingListingData(
        fieldName,
        momentDateToISOFormatConversion(event)
      );
    } else {
      // otherwise it is a regular input field.
      let value = event.target.value;
      this.props.updateCurrentWorkingListingData(fieldName, value);
    }
  }

  render() {
		let {
			listing,
			cancel,
			suppliers,
			addToBatchStatus,
			currentListingWorkflowOptions,
			batchMetadata,
			userData,
      printerDefaults,
      scouts
		} = this.props;

    if (currentListingWorkflowOptions.speedMode && !currentListingWorkflowOptions.showPricing && !!addToBatchStatus) {
      return (
        <LoadingDisplay loadingContainerClass={"loading-container"} message={"Adding the scanned item in speed mode... hang tight for a minute"} />
      )
    }

		let skus = "";
		if(batchMetadata && batchMetadata.channel.startsWith('AMAZON_')){
	    skus = generateTemplatedSKU(listing);
		} else {
	    skus = generateTemplatedSKU(listing, null, userData, printerDefaults);
		}
    return (
      <div className="card section">
        <h2
          className="section-title d-flex justify-content-between mb-0"
          style={{cursor: 'pointer'}}
          onClick={() => {
            const newIsOpen = !this.state.isSkuDetailsOpen;
            this.setState({isSkuDetailsOpen: newIsOpen});
            localStorage.setItem('skuDetailsOpen', JSON.stringify(newIsOpen));
          }}
        >
          <div className="d-flex align-items-center">
            <span className="icon icon-16 icon-file mr-2"></span>
            <span>SKU Details</span>
          </div>
          {this.state.isSkuDetailsOpen ? <FaChevronDown size={16} className="text-muted" /> : <FaChevronUp size={16} className="text-muted" />}
        </h2>
        <Collapse isOpen={this.state.isSkuDetailsOpen}>
          <div className="section-content d-block">
            <Row className="mx-0">
              <Col className="pl-0">
                <FormGroup row>
                  <Col md="6">
                    <Label>SKU Prefix</Label>
                    <Input
                      className="form-control form-control-sm batch-tab-input"
                      value={
                        listing.shouldUseCustomSkuTemplate
                          ? skus
                          : listing.skuPrefix
                      }
                      invalid={
                        (listing.shouldUseCustomSkuTemplate && skus === "")
                        || (!listing.shouldUseCustomSkuTemplate && listing.skuPrefix === "")
                      }
                      disabled={listing.shouldUseCustomSkuTemplate}
                      onChange={
                        listing.shouldUseCustomSkuTemplate
                          ? null
                          : this.updateCurrentWorkingListingData.bind(
                              this,
                              "skuPrefix"
                            )
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Label>SKU Number</Label>
                    <Input
                      className="form-control form-control-sm batch-tab-input"
                      min="0"
                      type="number"
                      value={skuNumberConversion(listing.skuNumber) || ""}
                      onChange={this.updateCurrentWorkingListingData.bind(
                        this,
                        "skuNumber"
                      )}
                      onBlur={this.updateIntInputData.bind(this, "skuNumber")}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="6">
                    <Label>Exp. Date</Label>
                    <DatePicker
                      selected={listing.expDate ? moment(listing.expDate) : null}
                      onChange={this.updateCurrentWorkingListingData.bind(
                        this,
                        "expDate"
                      )}
                      className="form-control"
                      popperPlacement="top"
                    />
                  </Col>
                  <Col md="6">
                    <Label>Date Purchased</Label>
                    <DatePicker
                      selected={
                        listing.datePurchased ? moment(listing.datePurchased) : null
                      }
                      onChange={this.updateCurrentWorkingListingData.bind(
                        this,
                        "datePurchased"
                      )}
                      className="form-control"
                      popperPlacement="top"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="4">
                    <Label>Quantity</Label>
                    <Input
                      className="form-control form-control-sm batch-tab-input"
                      value={!isNaN(listing.qty) ? listing.qty : ""}
                      invalid={isNaN(listing.qty) || listing.qty === "" || Number(listing.qty) <= 0}
                      onChange={this.updateCurrentWorkingListingData.bind(
                        this,
                        "qty"
                      )}
                      onBlur={this.updateIntInputData.bind(this, "qty")}
                    />
                  </Col>
                  <Col md="8">
                    <Label>Tax Code</Label>
                    <TaxCodeInputForm
                      value={listing.taxCode}
                      onChange={this.updateCurrentWorkingListingData.bind(
                        this,
                        "taxCode"
                      )}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col className="pr-0">
                <FormGroup>
                  <Label>ASIN</Label>
                  <Input
                    className="form-control form-control-sm batch-tab-input"
                    value={listing.asin}
                    disabled
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Supplier</Label>
                  <SupplierInputForm
                    suppliers={suppliers}
                    value={listing.supplier}
                    onChange={this.updateCurrentWorkingListingData.bind(
                      this,
                      "supplier"
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Scout</Label>
                  <ScoutInputForm
                    scouts={scouts}
                    value={listing.scout}
                    onChange={this.updateCurrentWorkingListingData.bind(this, "scout")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="separator" />
            <div className="d-flex justify-content-between align-items-center mx-0">
              <div><Badge color="default">Pending Fulfillment Center Data</Badge></div>
              <div>
                <Button
                  color="success"
                  disabled={!!addToBatchStatus}
                  onClick={() => {
                    this.props.addItemToBatchExtended()
                  }}
                >
                  {
                    !!addToBatchStatus &&
                      <FaSpinner className="fa-spin mr-1" />
                  }
                  ADD TO BATCH
                </Button>
                <Button className="ml-2" onClick={cancel}>
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

GhostResult.propTypes = {
  listing: PropTypes.object.isRequired,
  currentListingWorkflowOptions: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  internationalConfig: PropTypes.object.isRequired,
  addToBatchStatus: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	batchMetadata: PropTypes.object.isRequired,
  printerDefaults: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  printerDefaults: state.Settings.get("printerDefaults"),
});

export default connect(
  mapStateToProps,
  {
    fetchPrinterDefaults
  }
)(GhostResult);
