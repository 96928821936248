import { all, takeLatest, put, fork, call } from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import { logError } from "../../helpers/utility";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';

import Papa from 'papaparse'
import fileDownload from 'react-file-download'

export function* uploadResearchData() {
  yield takeLatest('RESEARCH_DATA_IMPORT', function* (payload) {
    const { file, pricingType, buyCostValue } = payload;
    try {
      const data = new FormData();
      data.append('source', file, file.name);
			data.append('pricing_type', pricingType);
			data.append('buy_cost', buyCostValue);
			const response = yield call(backendAPICall, 'post', apiEndpoints.research.import, data);
      if (response.status === 200 && response.data) {
        yield put(actions.uploadResearchDataSuccess(response.data));
				if(response.data.error){
					yield put(appActions.apiCallFailed(response.data.error));
				}
			} else {
				yield put(actions.uploadResearchDataFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.uploadResearchDataFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_DATA_IMPORT_ERROR"
        }
      });
    }
  })
}

export function* loadResearchData() {
  yield takeLatest('RESEARCH_DATA_LOAD', function* (payload) {
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.research, null);
      if (response.status === 200 && response.data) {
        yield put(actions.loadResearchDataSuccess(response.data));
			} else {
				yield put(actions.loadResearchDataFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.loadResearchDataFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_DATA_LOAD_ERROR"
        }
      });
    }
  })
}

export function* loadBatchData() {
  yield takeLatest('RESEARCH_LOAD_BATCH_DATA', function* (payload) {
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.batch, null);
      if (response.status === 200 && response.data) {
        yield put(actions.loadBatchDataSuccess(response.data));
			} else {
				yield put(actions.loadBatchDataFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.loadBatchDataFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_LOAD_BATCH_DATA_ERROR"
        }
      });
    }
  })
}

export function* moveToBatch() {
  yield takeLatest('RESEARCH_MOVE_TO_BATCH', function* (payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.research.batch, data);
      if (response.status === 200 && response.data) {
				yield put(actions.moveToBatchSuccess(response.data));
			} else {
				yield put(actions.moveToBatchFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.moveToBatchFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_MOVE_TO_BATCH_ERROR"
        }
      });
    }
  })
}
export function* deleteSelectedResearch() {
  yield takeLatest('RESEARCH_DELETE_SELECTED', function* (payload) {
		try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.research.bulk_delete, data);
      if (response.status === 200 && response.data) {
				yield put(actions.deleteSelectedResearchSuccess(response.data));
			} else {
				yield put(actions.deleteSelectedResearchFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.deleteSelectedResearchFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_DELETE_SELECTED_ERROR"
        }
      });
    }
  })
}

export function* researchManualSearch() {
  yield takeLatest('RESEARCH_MANUAL_SEARCH', function* (payload) {
    const { data } = payload;
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.manual_search, data);
      if (response.status === 200 && response.data) {
        yield put(actions.researchManualSearchSuccess(response.data));
			} else {
				yield put(actions.researchManualSearchFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.researchManualSearchFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_MANUAL_SEARCH_ERROR"
        }
      });
    }
  })
}

export function* researchManualSearchResultsDisplayClose() {
  yield takeLatest('RESEARCH_MANUAL_SEARCH_RESULTS_DISPLAY_CLOSE', function* (payload) {
		yield put(actions.researchManualSearchFailed());
  })
}

export function* researchManualSearchSelect() {
  yield takeLatest('RESEARCH_MANUAL_SEARCH_SELECT', function* (payload) {
    const { data } = payload;
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.manual_search_select, data);
      if (response.status === 200 && response.data) {
        yield put(actions.researchManualSearchSelectSuccess(response.data));
			} else {
				yield put(actions.researchManualSearchSelectFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.researchManualSearchSelectFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_MANUAL_SEARCH_SELECT_ERROR"
        }
      });
    }
  })
}

export function* researchDataUpdate() {
  yield takeLatest('RESEARCH_DATA_UPDATE', function* (payload) {
    const { data } = payload;
		yield put(actions.researchDataUpdateSuccess(data));
  })
}

export function* researchMoveToBatchDataSet() {
  yield takeLatest('RESEARCH_MOVE_TO_BATCH_DATA_SET', function* (payload) {
    const { data } = payload;
		yield put(actions.researchMoveToBatchDataSetSuccess(data));
  })
}

export function* researchExport() {
  yield takeLatest('RESEARCH_EXPORT', function* (payload) {
    const { data } = payload;
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.research.export, data);
      if (response.status === 200 && response.data) {
        yield put(actions.researchExportSuccess());
				const csv = Papa.unparse(JSON.stringify(response.data.data) , {
					delimiter: ",",
					header: true,
					skipEmptyLines: false
				});
				fileDownload(csv, 'AL-research-export.tsv');

			} else {
				yield put(actions.researchExportFailed());
			}
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Upload Research api error"));
			yield put(actions.researchExportFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_EXPORT_ERROR"
        }
      });
    }
  })
}

export function* researchGetStatus() {
  yield takeLatest('RESEARCH_GET_STATUS', function* (payload) {
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.status, {});
      if (response.status === 200 && response.data) {
        yield put(actions.researchGetStatusSuccess(response.data));
        if(response.data?.research_stats?.total > 0
          && response.data?.research_stats?.processed > 0
          && response.data?.research_stats?.total === response.data?.research_stats?.processed){
          yield put(actions.loadResearchData());
        }
			} else {
				yield put(actions.researchGetStatusFailed());
			}
    } catch (error) {
			yield put(actions.researchGetStatusFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_GET_STATUS_ERROR"
        }
      });
    }
  })
}

export function* researchGetSKUReport() {
  yield takeLatest('RESEARCH_GET_SKU_REPORT', function* (payload) {
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.research.sku, payload.data);
      if (response.status === 200 && response.data) {
        yield put(actions.researchGetSKUReportSuccess(response.data));
			} else {
				yield put(actions.researchGetSKUReportFailed());
			}
    } catch (error) {
			yield put(actions.researchGetSKUReportFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_GET_SKU_REPORT_ERROR"
        }
      });
    }
  })
}

export function* researchGetSKUReportHistory() {
  yield takeLatest('RESEARCH_GET_SKU_REPORT_HISTORY', function* (payload) {
    try {
			const response = yield call(backendAPICall, 'get', apiEndpoints.research.sku_history, null);
      if (response.status === 200 && response.data) {
        yield put(actions.researchGetSKUReportHistorySuccess(response.data));
			} else {
				yield put(actions.researchGetSKUReportHistoryFailed());
			}
    } catch (error) {
			yield put(actions.researchGetSKUReportHistoryFailed());
      logError(error, {
        tags: {
          exceptionType: "RESEARCH_GET_SKU_REPORT_HISTORY_ERROR"
        }
      });
    }
  })
}

export default function* researchSaga() {
	yield all([
		fork(uploadResearchData),
		fork(loadResearchData),
		fork(loadBatchData),
		fork(moveToBatch),
		fork(deleteSelectedResearch),
		fork(researchManualSearch),
		fork(researchManualSearchResultsDisplayClose),
		fork(researchManualSearchSelect),
		fork(researchDataUpdate),
		fork(researchMoveToBatchDataSet),
		fork(researchExport),
    fork(researchGetStatus),
    fork(researchGetSKUReport),
    fork(researchGetSKUReportHistory),
	]);
}
