const chart_config = {
  cartesianStrokeColor: '#ebeef1',
  axisFontSize: '11px',
  axisFontColor: '#969ca0',
  dot: { r: 3, strokeWidth: 2, fill: '#fff' },
  strokeWidth: 2,
  barSize: 35,
  barRadius: [6, 6, 0, 0],
  dateFormat: 'MM/DD/YYYY',
  orangeColor: '#f2ab41',
  greenColor: 'rgba(0, 148, 62, .85)',
  blueColor: '#1f73d1',
  redColor: 'rgba(231, 84, 84, .85)',
  grayColor: '#3c404c'
}
export default chart_config;