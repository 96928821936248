import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { digitСonversion } from "../../../../helpers/utility";
import chart_config from "../../main_dashboard_v3/chart_config";

class RepricerCostRevenueChart extends Component {
	moneyFormatter = (value) => {
		return digitСonversion(value, "currency", "USD");
	}

	render () {
		const { data } = this.props;
		return (
			<ResponsiveContainer
				width={"100%"}
				height={300}
			>
				<BarChart
					data={data}
					margin={{
						top: 30, left: 20, right: 0
					}}
				>
					<CartesianGrid
						vertical={false}
						stroke={chart_config.cartesianStrokeColor}
					/>
					<XAxis
						dataKey="month"
						tickLine={false}
						axisLine={false}
						stroke={chart_config.axisFontColor}
						style={{
							fontSize: chart_config.axisFontSize
						}}
					/>
					<YAxis
						axisLine={false}
						tickLine={false}
						stroke={chart_config.axisFontColor}
						style={{
							fontSize: chart_config.axisFontSize
						}}
						tickFormatter={this.moneyFormatter}
					/>
					<Tooltip cursor={false} formatter={this.moneyFormatter} />
					<Legend />
					<Bar
						dataKey="cost"
						fill="#FF7158"
						label={{ fontSize: 12, position: "top", formatter: this.moneyFormatter }}
						barSize={chart_config.barSize * 2}
					/>
					<Bar
						dataKey="revenue"
						fill="#00943E"
						label={{ fontSize: 12, position: "top", formatter: this.moneyFormatter }}
						barSize={chart_config.barSize * 2}
					/>
				</BarChart>
			</ResponsiveContainer>
		)
	}
}

export default RepricerCostRevenueChart;
