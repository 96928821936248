import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReportSummary from './ReportSummary';
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../style.css";
import {
    momentDateTimeToLocalFormatConversion
} from '../../../../../helpers/utility';
import './style.css';
import moment from "moment";

class ReportsTable extends Component {
  state = {
    summaryModalOpenIdx: null
  }

  showSummaryModal = (idx) => {
    this.setState({ summaryModalOpenIdx: idx })
  }

  hideSummaryModal = () => {
    this.setState({ summaryModalOpenIdx: null })
  }

  render() {
    const statusMapping = {
      reprice_triggered: {
        label: "Reprice Triggered",
        className: "badge-warning"
      },
      data_not_ready: {
        label: "Data Not Ready",
        className: "badge-warning"
      },
      reprice_completed: {
        label: "Reprice Completed",
        className: "badge-success"
      },
      trigger_failed: {
        label: "Reprice Failed",
        className: "badge-danger"
      },
      no_matching_items: {
        label: "No Matching Items",
        className: "badge-danger"
      },
      submitting_to_amazon: {
        label: "Submitting to Amazon",
        className: "badge-warning"
      }
    };

    let tableColumns = [
      {
        id: "name",
        Header: "Templates",
        accessor: row => row.names.map(item => item),
        headerClassName: "text-left",
        className: "text-left",
        sortMethod: (a, b) => String(a.join("")).localeCompare(b.join("")),
        Cell: props => (
          <React.Fragment>
            {props.original.names ?
              props.original.names.map((item,idx) => {
                return (
					<Button
						key={`template-${idx}`}
						color="link"
            className="template-link"
						onClick={(e) => this.props.toggleView(
							props.original.event_id,
							momentDateTimeToLocalFormatConversion(
				              moment.utc(props.original.created_at).local())
						)}
					>{item}</Button>
                )
              }) : ""
            }
          </React.Fragment>
        )
	    },
	    {
        id: "interval",
        Header: "Schedule",
        accessor: "interval",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          <React.Fragment>
            {props.original.interval === 60 ?
              <b>Hourly</b>
              : <b>Daily</b>
            }
            {props.original.start_timestamp ?
              <React.Fragment>
                <br />
                {moment.utc(props.original.start_timestamp).isBefore(moment.utc()) ? (
                  <span>
                    Starting from{" "}
                    {momentDateTimeToLocalFormatConversion(
                      moment.utc(props.original.start_timestamp).local())
                    }
                  </span>
                ) : (
                  <span>
                    Scheduled to start on{" "}
                    {momentDateTimeToLocalFormatConversion(
                      moment.utc(props.original.start_timestamp).local())
                    }
                  </span>
                )}
              </React.Fragment>
              : null
            }
          </React.Fragment>
        )
      },
      {
        id: "trigger_time",
        Header: "Trigger Time",
        accessor: "trigger_time",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          <React.Fragment>
            {momentDateTimeToLocalFormatConversion(
              moment.utc(props.original.created_at).local())
                }
          </React.Fragment>
        )
	    },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        headerClassName: "text-left",
        className: "text-left",
        Cell: props => (
          <span className={`badge ${statusMapping[props.original.status].className}`}>
            {statusMapping[props.original.status].label}
          </span>
        )
	    },
      {
        id: "is_live",
        Header: "Is Live",
        accessor: row => row.should_submit ? 'LIVE' : 'TEST',
        headerClassName: "text-left",
        className: "text-left",
        sortMethod: (a, b) => String(a).localeCompare(b),
        Cell: props => (
          <React.Fragment>
            {props.original.should_submit ? <strong className="text-success">LIVE</strong> : <strong className="text-muted">TEST</strong>}
          </React.Fragment>
        )
      },
      {
        id: "summary",
        Header: "Summary",
        headerClassName: "text-left",
        sortable: false,
        Cell: props =>
        <React.Fragment>
          <Button color="link" onClick={() => this.showSummaryModal(props.index)}>See Quick Summary</Button>
          <Modal isOpen={this.state.summaryModalOpenIdx === props.index} size="lg" toggle={this.hideSummaryModal} centered>
            <ModalBody>
              <ReportSummary
                id={props.original.event_id}
                viewDetail={this.props.toggleView}
                lastRun={momentDateTimeToLocalFormatConversion(
                      moment.utc(props.original.created_at).local())}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.hideSummaryModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      }
    ];


    return (
      <React.Fragment>
          <ReactTable
            data={this.props.reports} // should default to []
            columns={tableColumns}
            className="template-table"
            minRows={0}
          />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
  }),
	{
	}
)(ReportsTable);
