import React, { Component, Fragment } from 'react';
import {
  InputGroup, InputGroupAddon, Input, Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Moment from 'moment';
import moment from 'moment';
import styled from 'styled-components';

const StyledRow = styled.tr`
  .end-date {
    width: 7.5em;
    padding-left: .4rem;
    padding-right: .4rem;
  }

  .amount {
    width: 7.5em;
  }

  > td:first-child {
    max-width: 24px;
  }

  .new-category {
    font-size: 13px;
  }
`;

/*
const expenseCategories = [
  {
    value: {action: 'add-category'},
    label: <strong className="new-category"><IconAdd className="text-success" /> New Category</strong>
  }
];
*/

const expenseRepeat = [
  { value: 'one-time', label: 'One-time' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
]

class ExpenseRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      date: null,
      endDate: null,
      amount: 0,
      description: '',
      repeat: null,
      createNewCategory: false,
		  newCategoryName: '',
		  expenseAdded: false,
      expenseUpdated: false,
      endDateShown: false
    }
  }

  componentDidMount() {
    const {
		data,
		expenseCategories,
    } = this.props;

    this.setState({
      category: expenseCategories.filter( obj => obj.value === data.category_id)[0],
      date: data.start_date ? Moment(data.start_date) : null,
      amount: data.amount,
      description: data.description,
	    repeat: expenseRepeat.filter( obj => obj.value === data.interval)[0],
      endDate: data.end_date ? Moment(data.end_date) : null,
	    expenseAdded: data.id ? true : false,
	    id: data.id ? data.id : null,
    });
  }

  selectCategory = (selected) => {
    if(selected) {
      if(!selected.value.action) {
        this.setState({
          category: selected
        });
      } else if(selected.value.action && selected.value.action === 'add-category') {
        this.setState({
          createNewCategory: true
        });
      }
	}
	this.setState({ expenseUpdated: true });
  }

  selectDate = (selected) => {
    this.setState({
		  date: selected
    })
	  this.setState({ expenseUpdated: true });
  }

  selectEndDate = (selected) => {
    this.setState({
	    endDate: selected
    })
	  this.setState({ expenseUpdated: true });
  }

  selectRepeat = (selected) => {
    this.setState({
      repeat: selected
    })
    this.setState({ expenseUpdated: true });

    if(selected && selected.value !== 'one-time') {
      this.setState({ endDateShown: true })
      this.props.showEndDate(true);
    } else {
      this.setState({ endDateShown: false })
      this.props.showEndDate(false);
    }
  }

  changeAmount = (e) => {
    this.setState({
      amount: e.target.value
    });
	  this.setState({ expenseUpdated: true });
  }

  changeDescription = (e) => {
    this.setState({
      description: e.target.value
    });
	  this.setState({ expenseUpdated: true });
  }

  newCategoryNameChanged = (e) => {
    this.setState({
      newCategoryName: e.target.value
    });
  }

  createNewCategory = () => {
    const {
		  expenseCategories,
    } = this.props;
    let name = this.state.newCategoryName;
    let newCategory;

    if( name !== '') {
      let categories = expenseCategories;
      newCategory = {
        value: name.replace(/ +/g, ""),
        label: name
      }

      categories.push(newCategory);

      this.setState({
        category: newCategory,
        createNewCategory: false,
        newCategoryName: ''
      });
    }
  }

	addExpenseRow() {
		const {
			category,
			date,
			amount,
			description,
			repeat,
      endDate} = this.state;

    //protect
    if(!category || !category.value || !date || !amount
      || !description || !repeat || !repeat.value){
      return;
    }

		const expense = {
			categoryId: category.value,
			startDate: moment.utc(date).utcOffset(moment().utcOffset()).toISOString(true),
			endDate: endDate ? moment.utc(endDate).utcOffset(moment().utcOffset()).toISOString(true) : '',
			amount: Number(amount),
			description: description,
			interval: repeat.value,
			idx: this.props.idx,
		}
		this.props.addExpense(expense);
		this.setState({ expenseAdded: true });
		this.setState({ expenseUpdated: false });
	}


	updateExpenseRow(){
		const {
			id,
			category,
			date,
			amount,
			description,
			repeat,
			endDate} = this.state;
		const expense = {
			expenseId: id,
			categoryId: category.value,
			startDate: moment.utc(date).utcOffset(moment().utcOffset()).toISOString(true),
			endDate: endDate ? moment.utc(endDate).utcOffset(moment().utcOffset()).toISOString(true) : '',
			amount: Number(amount),
			description: description,
			interval: repeat.value,
			idx: this.props.idx,
		}
		this.props.updateExpense(expense);
		this.setState({ expenseAdded: true });
		this.setState({ expenseUpdated: false });
	}


  render() {
    const {
	    deleteExpense,
      expenseCategories
    } = this.props;

    return (
      <Fragment>
        <StyledRow>
            <td className="text-center">
              <Button
                color="link"
                className="text-danger"
                onClick={deleteExpense}
              >
                <span className="icon icon-trash bg-danger"></span>
              </Button>
            </td>
            <td>
              <Select
                options={expenseCategories}
                onChange={this.selectCategory}
                value={this.state.category}
              />
              {this.state.createNewCategory &&
                <InputGroup size="sm" className="mt-2">
                  <Input
                    type="text"
                    placeholder="Enter category name"
                    value={this.state.newCategoryName}
                    onChange={this.newCategoryNameChanged}
                    onKeyPress={(e) => {
                      let code = (e.keyCode ? e.keyCode : e.which);
                      if(code === 13) this.createNewCategory();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="primary"
                      onClick={this.createNewCategory}
                    >
                      Add
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              }
            </td>
            <td>
              <DatePicker
                selected={this.state.date}
                onChange={this.selectDate}
                maxDate={this.state.endDate !== null ? new Date(this.state.endDate - 1) : null}
                className="date"
                placeholderText="Pick date"
              />
            </td>
            <td>
              <InputGroup className="amount">
								<InputGroupAddon addonType="prepend">{this.props.internationalization_config ? this.props.internationalization_config.currency_identifier : null}</InputGroupAddon>
                <Input
                  value={this.state.amount}
                  onChange={this.changeAmount}
                  //placeholder="Amount"
                  type="number"
                  step="0.01"
                />
              </InputGroup>
            </td>
            <td>
              <Input
                value={this.state.description}
                onChange={this.changeDescription}
                type="text"
              />
            </td>
            <td>
              <Select
                options={expenseRepeat}
                onChange={this.selectRepeat}
                value={this.state.repeat}
              />
            </td>
            <td>
            {this.state.repeat?.value !== 'one-time' &&
              <DatePicker
                selected={this.state.endDate}
                onChange={this.selectEndDate}
                placeholderText="End date (optional)"
                className="end-date"
              />
            }
            </td>
			{this.state.expenseAdded ? (null):(
				<td>
					<Button
						color="success"
						onClick={() => this.addExpenseRow()}
					>Add</Button>
				</td>
			)}
			{this.state.expenseUpdated && this.state.expenseAdded ? (
				<td>
					<Button
						color="success"
						onClick={() => this.updateExpenseRow()}
					>Save</Button>
				</td>
			):(null)}
          </StyledRow>
      </Fragment>
    )
  }
}

ExpenseRow.propTypes = {
  data: PropTypes.object.isRequired,
  deleteExpense: PropTypes.func.isRequired,
  addExpenseCategory: PropTypes.func.isRequired,
  expenseCategories: PropTypes.array.isRequired,
  addExpense: PropTypes.func.isRequired,
  updateExpense: PropTypes.func,
	internationalization_config:  PropTypes.object.isRequired,
}

export default ExpenseRow;
