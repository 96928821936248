import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  ResponsiveContainer, XAxis, YAxis, CartesianGrid,
  Tooltip, BarChart, Bar, Legend
} from 'recharts';
import moment from 'moment';
import { digitСonversion } from "../../../helpers/utility";
import chart_config from "../main_dashboard_v3/chart_config.js";
import CustomizedLabel from "../main_dashboard_v3/CustomizedLabel.js";
import getGradient from "../main_dashboard_v3/chart_gradients.js";
import salesActions from "../../../redux/sales/actions";

const {
  getSalesChart,
} = salesActions;

class SalesChart extends Component {
  state = {

  }

  componentDidMount = () => {
    this.props.getSalesChart({
      time: this.props.time_period?.value,
    });
  }

  componentDidUpdate = (pp) => {
    if(pp.time_period != this.props.time_period){
      this.props.getSalesChart({
        time: this.props.time_period?.value,
      });
    }
  }

  moneyFormat = (val) => {
    return digitСonversion(val, "currency", this.props.internationalizationConfig.currency_code);
  }

  tooltipFormatter = (value, name, props) => {
    return this.moneyFormat(value);
  }

  render() {
    const {
      product_sales_chart_data,
      product_sales_chart_data_working,
      internationalizationConfig,
    } = this.props;

    return (
      <React.Fragment>
        <ResponsiveContainer
          width={"100%"}
          height={250}
        >
          <BarChart
            data={product_sales_chart_data?.items
              ? product_sales_chart_data?.items
              : []
            }
            margin={{
              top: 35, left: 30
            }}
          >
            <defs>
              {getGradient("gradientGreen", "green")}
            </defs>
            <CartesianGrid
              vertical={false}
              stroke={chart_config.cartesianStrokeColor}
            />
            <XAxis
              dataKey="day"
              axisLine={false}
              tickLine={false}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              stroke={chart_config.axisFontColor}
              style={{
                fontSize: chart_config.axisFontSize,
              }}
              tickFormatter={this.moneyFormat}
            />
            <Tooltip
              labelFormatter={(dateString) => moment(dateString).format(chart_config.dateFormat)}
              formatter={this.tooltipFormatter}
              cursor={{fill: 'transparent'}}
            />
            <Legend />
            <Bar
              name="Sales"
              dataKey="net_sales"
              fill="url(#gradientGreen)"
              barSize={chart_config.barSize}
              radius={chart_config.barRadius}
              label={<CustomizedLabel internationalizationConfig={internationalizationConfig}/>}
            />
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

export default connect(
  state => ({
    product_sales_chart_data: state.Sales.get("product_sales_chart_data"),
    product_sales_chart_data_working: state.Sales.get("product_sales_chart_data_working"),
  }),
  {
    getSalesChart,
  }
)(SalesChart);
