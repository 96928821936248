import { getLabelContent, getLabelContentZPL } from "./label_template";
import { mfLabelTypeList } from "../../../../helpers/print_service/labelsTypeList";
import qz from "qz-tray";
import printerDriverConfigPreset from "../../../../helpers/print_service/printer_driver_conf_AL";
import { DYMO_30270, DYMO_30334 } from "../../../../helpers/print_service/label_name_constants";
import { getNextMFLabel } from "../../../../helpers/print_service/utility";


export const MFTicketing = (batchData, printQueue, printerDefaults, updateLabelMFTicketing, clearPrintJobQueue, printJobSuccess, currentJobId, setCurrentJobId, onError) => {
  const isMac = !!navigator.platform.toUpperCase().match("MAC");
  const isWIN = !!navigator.platform.toUpperCase().match("WIN");

  if (!printerDefaults || !printerDefaults.printer_name || !printerDefaults.label_type || !printerDefaults.orientation) {
    onError(null, "Printer conf is not set!");
    clearPrintJobQueue();
    return;
  }

  if (!batchData.is_mf_ticketing_enabled && !printQueue[0].data.testLabel) {
    onError(null, "MF ticketing is not enabled for the channel of current batch. Change your printing settings or change the batch channel.");
    clearPrintJobQueue();
    return;
  }

  if (
    printQueue.length > 0
    && (!printQueue[0].data.hasOwnProperty('fnsku') || !printQueue[0].data.fnsku)
  ) {
    onError(null, "FNSKU is missing, can't print!");
    clearPrintJobQueue();
    return;
  }

  if (printQueue.length > 0 && (printQueue[0].id !== currentJobId)) {
    setCurrentJobId(printQueue[0].id);
    const indexLabelFromList = mfLabelTypeList.findIndex(el => el.lableName === printerDefaults.label_type);
    if(isWIN || isMac) {
      var labelType = indexLabelFromList !== -1 ? printerDefaults.label_type : mfLabelTypeList[0].lableName;
      var labelWidth = indexLabelFromList !== -1 ? mfLabelTypeList[indexLabelFromList].width : Number(printerDefaults.label_width);
      var labelHeight = indexLabelFromList !== -1 ? mfLabelTypeList[indexLabelFromList].height : Number(printerDefaults.label_height);
    } else {
      labelType = mfLabelTypeList[indexLabelFromList];
      labelWidth = indexLabelFromList !== -1 ? labelType.height : Number(printerDefaults.label_height);
      labelHeight = indexLabelFromList !== -1 ? labelType.width : Number(printerDefaults.label_width);
    }
    const fontSizeCoefficient = printerDefaults.font_size_coefficient ? Number(printerDefaults.font_size_coefficient) : 0.9;
    const barCodeType = printerDefaults.barcode_type ? printerDefaults.barcode_type : "CODE39";
    const orientation = printerDefaults.orientation;
    const printerDriverConfigName = printerDefaults.printer_driver_config ? printerDefaults.printer_driver_config : "";
    const printerDriverConfig = printerDriverConfigPreset.hasOwnProperty(printerDriverConfigName)
      ? printerDriverConfigPreset[printerDriverConfigName]
      : null;
      if (labelType && [DYMO_30334, DYMO_30270].includes(labelType.lableName)) {
      labelWidth = labelType.width;
      labelHeight = labelType.height;
    }
    let printCopies = printQueue[0].copies;

    const printerConfig = {
      orientation: orientation,
      copies: 1,
      margins: {top: 0, right: 0, bottom: 0, left: 0},
      units: "in",
      scaleContent: true,
      rasterize: true,
      size: {
        width: orientation === "portrait" ? labelWidth : labelHeight,
        height: orientation === "portrait" ? labelHeight : labelWidth,
      },
      interpolation: "nearest-neighbor",
      colorType: 'grayscale',
      density: printerDriverConfig && printerDriverConfig.density ? printerDriverConfig.density : [203, 300, 600],
      altPrinting: isMac,
    }
    let printData = [];

    switch(!!printerDriverConfig && !!printerDriverConfig.tag ? printerDriverConfig.tag : "") {
      case "zebra":
        printData = getLabelContentZPL(printQueue[0].data, {
          width: labelWidth,
          height: labelHeight,
          fontSizeCoefficient: fontSizeCoefficient,
          barCodeType: barCodeType,
          orientation: orientation,
          density: printerDriverConfig && printerDriverConfig.density ? printerDriverConfig.density : 203
        });
        break;

      case 'rollo':
        const rolloCoefficient = labelType.rolloCoefficient || 1;
        if (mfLabelTypeList[indexLabelFromList].rolloWidth) {
          if (orientation === "portrait") {
            printerConfig.size.width = mfLabelTypeList[indexLabelFromList].rolloWidth
          } else {
            printerConfig.size.height = mfLabelTypeList[indexLabelFromList].rolloWidth
          }
        }
        for(let i = 0; i < printCopies ; i++){
          printData.push({
            type: 'html',
            format: 'plain',
            data: getLabelContent(printQueue[0].data, {
              width: labelWidth,
              height: labelHeight,
              fontSizeCoefficient: fontSizeCoefficient,
              barCodeType: barCodeType,
              printerDriverConfig: printerDriverConfig,
              orientation: orientation,
              rolloCoefficient,
              labelType: labelType.lableName,
            }),
            options: {
              pageWidth: labelWidth,
              pageHeight: labelHeight,
              units: "in",
            },
          })
        }
        break;

      default:
        for(let i = 0; i < printCopies ; i++){
          printData.push({
            type: 'html',
            format: 'plain',
            data: getLabelContent(printQueue[0].data, {
              width: labelWidth,
              height: labelHeight,
              fontSizeCoefficient: fontSizeCoefficient,
              barCodeType: barCodeType,
              printerDriverConfig: printerDriverConfig,
              orientation: orientation,
              labelType
            }),
            options: {
              pageWidth: labelWidth,
              pageHeight: labelHeight,
              units: "in",
            },
          })
          for(let j = 0; j <= 11 ; j++) {
            printData.push({
              type: 'html',
              format: 'plain',
              data: getLabelContent({testLabel: true, fnsku: "", name: ""}, {
                width: labelWidth,
                height: labelHeight,
                fontSizeCoefficient: fontSizeCoefficient,
                barCodeType: barCodeType,
                printerDriverConfig: printerDriverConfig,
                orientation: orientation,
                labelType  
              }),
              options: {
                pageWidth: labelWidth,
                pageHeight: labelHeight,
                units: "in",
              },    
            })
          }
          if(i !== (printCopies - 1)) {
            printQueue[0].data.fnsku = getNextMFLabel(printQueue[0].data.fnsku)
          }
        }
        break;
    }

    const config = qz.configs.create(printerDefaults.printer_name, printerConfig);
    updateLabelMFTicketing(printQueue[0].data.fnsku)
    qz.print(config, printData).then(() => {
      printJobSuccess();
    }).catch((err) => {
      console.log("PRINT ERROR DUE TO QZ / MF SETTINGS =======>", err)
      onError(err, "Error, cannot print. Check printer connection and configuration");
      clearPrintJobQueue();
    });
  }
}
