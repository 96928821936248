import React, { Component, useState } from 'react';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button,
  Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Alert
} from 'reactstrap';
import { openInNewTab } from "../../../../../helpers/utility";
import { label_334, label_336, label_252, printerConnection, windowsIcon, linuxIcon, macIconDark } from "../../../../../assets/images";


const LabelSettingInfo = (props) => {
    switch (props.label) {
      case "30334":
        return ( 
          <div>
            <img src={label_334} alt="30334" />
          </div>
        )
      case "30252": 
        return ( 
          <div>
            <img src={label_252} alt="30252" />
          </div>
        )
      case "30336": 
        return ( 
          <div>
            <img src={label_336} alt="30336" />
          </div>
        )
    } 
}
 
const LabelInfo = () => {
  const [activeLabel, setActiveLabel] = useState('30334')

    let labelSizes = ["30334", "30252", "30336"].map((label, key) => {
      return (
        <Button
          size="sm"
          color={activeLabel === label ? "success" : "light"}
          className="light-right-margin"
          onClick={() => setActiveLabel(label)}
          key={key}
        ><span title={label}>{label}</span></Button>
      )
    })

    return (
      <div className="row">
        <Col sm={8} style={{ marginLeft: "208px"}}>
          {labelSizes}
          <hr />
          <LabelSettingInfo label={activeLabel} />
        </Col>
      </div>
    )
}

class ALPrintOnboarding extends Component {
  state = {
    step: 1
  }

  onNext = () => {
    let currentStep = this.state.step;
    let nextStep = (currentStep+1) === 4 ? currentStep : currentStep+1;
    
    this.setState({
      step: nextStep
    });

  }

  onPrevious = () => {
    let currentStep = this.state.step;
    let prevStep = (currentStep === 2 && this.props.qzTrayConnectionStatus) ? 2 : (currentStep-1) === 0 ? currentStep : currentStep-1;

    this.setState({
      step: prevStep
    });
  }

  redirectToStep = (action) => {
    if(this.props.qzTrayConnectionStatus) {
      action === 'next' && this.onNext();
      action === 'prev' && this.onPrevious();
    } else {
      return
    }
  } 

  componentDidMount = () => {
    if(this.props.qzTrayConnectionStatus) {
      this.onNext();
    }
  }

  render() {
    const {
      isOpen,
      toggle,

      savePrinterDefaults,
      printerDefaults,
      qzAvaliblePrinters,
      qzTrayConnectionStatus,
      setPrinterServiceIsProcessing,
    } = this.props;
    const { step } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-printer-onboarding"
      >
        <ModalHeader toggle={toggle}>Setup AccelerPrint</ModalHeader>
        <ModalBody>
          <Nav pills className="nav-dot">
            <NavItem active={step > 0} className={step > 1 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
              >Install AccelerPrint</NavLink>
            </NavItem>
            <NavItem active={step > 1} className={step > 2 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
              >Printer Settings</NavLink>
            </NavItem>
            <NavItem active={step > 2} className={step > 3 ? 'done' : ''}>
              <NavLink
                style={{cursor:'pointer'}}
              >Print Test Label</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={step}>
            <TabPane tabId={1}>
            { step === 1 && 
              <React.Fragment>
                <div className="mb-5">
                  {!qzTrayConnectionStatus ? <Alert color="danger">Please setup Accelerprint first.</Alert> : null}
                  <h4>Download and install AccelerPrint</h4>
                  <p>Download and use our free custom print solution, AccelerPrint, you will enjoy using the thermal printer of your choice in the browser of your choice.</p>
                </div>
                <Row>
                  <Col>
                    <div className="download-item">
                      <img src={macIconDark} alt="Mac" />
                      <Button
                        className="btn-download"
                        onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/2xa08qcyd81ibmx2y6qucz3o2t841hin")}
                      >Download for Mac</Button>
                      <span className="text-muted">60.4 MB</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="download-item">
                      <img src={windowsIcon} alt="Windows" />
                      <Button
                        className="btn-download"
                        onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/t077tslm2odqikkpih5p7ckmobb1ej4r")}
                      >Download for Windows</Button>
                      <span className="text-muted">56.6 MB</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="download-item">
                      <img src={linuxIcon} alt="Linux" />
                        <Button
                          className="btn-download"
                          onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/b74444bsn2pgbt9iunf568is697smm9z")}
                        >Download for Linux</Button>
                      <span className="text-muted">62.5 MB</span>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            }
            </TabPane>
            <TabPane tabId={2}>
              { step === 2 &&  
                <React.Fragment>
                  <div className="mb-5">
                    <h4>Printer Configuration</h4>
                    <p>Please use below settings as per your label sizes and printer configured.</p>
                  </div>
                  <LabelInfo />
                </React.Fragment>
              }
            </TabPane>
            <TabPane tabId={3}>
              { step === 3 &&  
                <React.Fragment>
                  <div className="mb-5">
                    <h4>Print Test Label</h4>
                    <p>After you save the settings, you will see the printer status as Detected and will be able to print the test label print by clicking on the Label test button. Then you will be ready to list your products in AccelerList and print labels as needed.</p>
                  </div>
                  <div className="text-center">
                    <img src={printerConnection} alt="printerConnnection" className="img-fluid" />
                  </div>
                </React.Fragment>
              }
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          {this.state.step > 1 &&
          <Button onClick={() => this.redirectToStep('prev')}>PREVIOUS</Button>
          }
          <Button 
            color="primary"
            onClick={this.state.step === 3 ? toggle : () => this.redirectToStep('next')} 
          >
            {this.state.step === 3 ? "FINISH" : "NEXT"}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ALPrintOnboarding;