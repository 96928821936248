import React, { Component } from "react";
import PropTypes from "prop-types";
import { generatePackListFileAndExport } from  "../../../../helpers/box_contents/utility";
import SweetAlert from "sweetalert2-react";


class ButtonExportShipmentToTsv extends Component {
    state = {
        warningMessage: ""
    }

    generateFileAndExport = () => {
        const { shipment } = this.props;

        generatePackListFileAndExport(shipment);
    }

    onClickExportButton = () => {
        const { shipment } = this.props;
        const { warning } = shipment;

        if (warning) {
            this.setState({ warningMessage: warning })
        } else {
            this.generateFileAndExport()
        }
    }

    clearWarningMessage = () => {
        this.setState({
            warningMessage: ""
        })
    }

    render() {
        const { warningMessage } = this.state;
        return (
            <React.Fragment>
				
                <button
                    type="button"
                    className="btn btn-sm btn-link btn-export"
                    onClick={this.onClickExportButton}
                    title="Export .tsv"
                >
                    <svg width="26" height="24" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient x1="44.189%" y1="60.828%" x2="90.592%" y2="22.264%" id="a">
                            <stop stop-color="#EEEEEC" offset="0%"/>
                            <stop stop-color="#EEEEEC" offset="59.929%"/>
                            <stop stop-color="#FFF" offset="82.759%"/>
                            <stop stop-color="#D3D7CF" offset="100%"/>
                            </linearGradient>
                            <linearGradient x1="63.89%" y1="25.844%" x2="38.8%" y2="55.412%" id="b">
                            <stop stop-color="#2E3436" offset="0%"/>
                            <stop stop-color="#BABDB6" offset="100%"/>
                            </linearGradient>
                            <linearGradient x1="-8.73%" y1="50%" x2="108.729%" y2="50%" id="c">
                            <stop stop-color="#FFF" offset="0%"/>
                            <stop stop-color="#FFF" stop-opacity="0" offset="100%"/>
                            </linearGradient>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path d="M6.385 21.88h17.428a.338.338 0 00.333-.345V5.055c0-1.501-4.016-5.036-5.41-5.036H6.385a.338.338 0 00-.333.344v21.172c0 .191.149.345.333.345h0z" stroke="#888A85" fill="url(#a)" fillRule="nonzero" transform="translate(1 1)"/>
                            <path fill="#000" fillRule="nonzero" opacity=".17" d="M8.569 9.11h12.827v1.223H8.569zM8.569 11.455h12.212v1.223H8.569zM8.586 13.906h10.981v1.223H8.586zM8.599 16.449h12.827v1.223H8.599z"/>
                            <path d="M19.408.028c1.032-.19 4.875 2.598 4.72 4.873-.794-1.39-2.419-.738-4.508-.829 0 0 .2-3.757-.212-4.044h0z" stroke="#888A85" fill="url(#b)" fillRule="nonzero" transform="translate(1 1)"/>
                            <path d="M20.145 1.285c.697.392 2.252 1.23 2.912 2.31-.811-.39-1.498-.12-2.899-.11 0 0 .083-1.756-.013-2.2z" stroke="url(#c)" opacity=".369" transform="translate(1 1)"/>
                            <path stroke="#5C3566" fill="#755075" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round" d="M1 5.686h18.362v11.06H1z"/>
                            <path d="M4.556 13.894v-4.46h-1.59v-.908h4.26v.908H5.637v4.46H4.556zm3.093-1.747l1.054-.102c.063.354.192.614.385.78.194.166.456.249.785.249.348 0 .61-.074.788-.222.176-.148.265-.32.265-.518a.489.489 0 00-.112-.324c-.074-.09-.204-.167-.39-.233a13.307 13.307 0 00-.866-.234c-.58-.144-.987-.32-1.221-.53a1.397 1.397 0 01-.494-1.08c0-.274.078-.53.232-.768.155-.238.378-.42.67-.544.29-.124.643-.186 1.055-.186.672 0 1.179.147 1.519.443.34.295.518.69.535 1.182l-1.082.048c-.046-.276-.145-.474-.298-.595-.152-.12-.38-.181-.685-.181-.315 0-.561.064-.74.194a.391.391 0 00-.17.333c0 .127.053.235.16.326.136.115.468.234.995.359.526.124.915.253 1.168.386.252.133.45.315.592.545.143.231.214.516.214.855a1.582 1.582 0 01-.98 1.463c-.312.13-.7.196-1.166.196-.678 0-1.198-.157-1.562-.47-.363-.314-.58-.771-.65-1.372zm6.637 1.747L12.37 8.526h1.174L14.9 12.5l1.313-3.973h1.148l-1.92 5.368h-1.155z" fill="#FFF" fillRule="nonzero"/>
                        </g>
                        </svg>
				</button>
				
                <SweetAlert
                    show={warningMessage.length > 0}
                    type={"warning"}
                    title={"Warning!"}
                    text={warningMessage}
                    confirmButtonColor={"#3085d6"}
                    onConfirm={() => {
                        this.generateFileAndExport();
                        this.clearWarningMessage();
                    }}
                    onCancel={this.clearWarningMessage}
                    showCancelButton={true}
                />
            </React.Fragment>
        );
    }
};

ButtonExportShipmentToTsv.propTypes = {
    shipment: PropTypes.object.isRequired
};

export default ButtonExportShipmentToTsv;
