import actions from "./actions";
import { Map } from "immutable/dist/immutable";
import { momentDateToISOFormatConversion } from '../../helpers/utility';
import { utilRepricerTemplateUpdate } from '../../helpers/repricer/utility';
import { templateConvertToFrontend } from '../../helpers/repricer/converter/template_converter';
import moment from 'moment';

const initState = new Map({
	workingTemplate: null,
	templateDefaults: {
		template_json: "",
		priority: null,
		created_at: "",
		updated_at: "",
		user_id: "",
		name: "",
		type: "",
	},
	templateNameOK: false,
	userTemplates: [],
	savingTemplateResponse: {},
	savingTemplate: false,
	deletingTemplate: false,
	templateWarningText: null,
	templateWarning: null,
	workingSchedule: null,
	scheduleDefaults: {
		selectedTemplate: null,
		excludedTemplates: [],
		bootstrapOption: null,
		scheduleType: null,
		liveOption: null,
		scheduleTime: null,
		editing: false,
		id: null,
		daysToExecute: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
	},
	savingScheduleResponse: {},
	savingSchedule: false,
	userSchedules: [],
	schedulerWarningText: null,
	predefinedSkuList: [],
	predefinedSellerList: [],
	repriceReports: [],
	repriceReportSummary: {},
	repriceReportSummaryLoading: false,
	templateToSkuMappingData: {},
	templateBreakdownData: [],
	insightTemplate: {},
	insightTemplateLoading: false,
	user_enabling_repricer: false,
	dashboard_repricing: {},
	templateNameUpdating: false,
	admin_test_data: null,
	admin_test_data_loading: false,
	report_reprice_master_data: null,
	report_reprice_master_data_loading: false,
});

export default function repricerReducer(state = initState, action) {
	switch (action.type) {

		case actions.GET_REPORT_REPRICE_MASTER:
			return state
				.set("report_reprice_master_data", null)
				.set("report_reprice_master_data_loading", true)
		case actions.GET_REPORT_REPRICE_MASTER_SUCCESS:
			return state
				.set("report_reprice_master_data", action.data)
				.set("report_reprice_master_data_loading", false)
		case actions.GET_REPORT_REPRICE_MASTER_FAILED:
			return state
				.set("report_reprice_master_data", null)
				.set("report_reprice_master_data_loading", false)

		case actions.TEMPLATE_ADMIN_TEST:
			return state
				.set("admin_test_data", null)
				.set("admin_test_data_loading", true)
		case actions.TEMPLATE_ADMIN_TEST_SUCCESS:
			return state
				.set("admin_test_data", action.data)
				.set("admin_test_data_loading", false)
		case actions.TEMPLATE_ADMIN_TEST_FAILED:
			return state
				.set("admin_test_data", null)
				.set("admin_test_data_loading", false)

		case actions.WORKING_SCHEDULE_INIT: {
			const initSchedule = Object.assign({}, state.get("scheduleDefaults"));
			return state.set("workingSchedule", Object.assign({}, initSchedule));
		}
		case actions.WORKING_SCHEDULE_UPDATE_PROP: {
			var propScheduleUpdate = Object.assign({}, state.get("workingSchedule"));
			propScheduleUpdate[action.data.prop] = action.data.data;
			return state.set("workingSchedule", Object.assign({}, propScheduleUpdate));
		}
		case actions.USER_SAVE_WORKING_SCHEDULE: {
			return state
				.set("savingSchedule", true)
				.set("savingScheduleResponse", {});
		}

		case actions.USER_SAVE_WORKING_SCHEDULE_FAILED: {

			return state
				.set("savingSchedule", false)
				.set("savingScheduleResponse", action.data)
				.set("schedulerWarningText", action.data.error);
		}

		case actions.USER_SAVE_WORKING_SCHEDULE_SUCCESS: {
			return state
				.set("savingSchedule", false)
				.set("savingScheduleResponse", action.data);
		}

		case actions.USER_LOAD_SCHEDULES: {
			return state
				.set("userSchedules", [])
				.set("userTemplates", []);
		}

		case actions.USER_LOAD_SCHEDULES_FAILED: {
			return state
				.set("userSchedules", [])
				.set("userTemplates", [])
				.set("schedulerWarningText", action.data.error);
		}

		case actions.USER_LOAD_SCHEDULES_SUCCESS: {
			return state
				.set("userSchedules", action.data.data.schedules)
				.set("userTemplates", action.data.data.templates);
		}

		case actions.USER_LOAD_WORKING_SCHEDULE: {
			return state.set("workingSchedule", null);
		}

		case actions.USER_LOAD_WORKING_SCHEDULE_FAILED: {
			return state
				.set("workingSchedule", null)
				.set("schedulerWarningText", action.data.error);

		}

		case actions.USER_LOAD_WORKING_SCHEDULE_SUCCESS: {
			const { schedule } =  action.data;
			var setSchedule = Object.assign({}, state.get("scheduleDefaults"));
			setSchedule['selectedTemplate'] = {
				value: schedule.selectedTemplate.id,
				label: schedule.selectedTemplate.name
			}
			setSchedule['excludedTemplates'] = [];
			schedule.excludedTemplates.forEach(s => {
				setSchedule['excludedTemplates'].push({value: s.id, label: s.name})
			});
			if(schedule.bootstrapOption){
				setSchedule['bootstrapOption'] = { value: 'true', label: "Reprice using a subset of data and limited repricing options"}
			} else {
				setSchedule['bootstrapOption'] = { value: 'false', label: "Don’t reprice that SKU and wait until the full notification is available"}
			}
			if(schedule.liveOption){
				setSchedule['liveOption'] = { value: 'true', label: "Turn reprice schedule live"}
			} else {
				setSchedule['liveOption'] = { value: 'false', label: "Test reprice strategy first before turning live"}
			}
			if(schedule.scheduleType === 60){
				setSchedule['scheduleType'] = { value: 'hourly', label: 'Hourly'}
			} else {
				setSchedule['scheduleType'] = { value: 'daily', label: 'Daily'}
			}
			setSchedule['scheduleTime'] = moment(schedule.scheduleTime);
			setSchedule['editing'] = true;
			setSchedule['id'] = schedule.id;
			setSchedule['daysToExecute'] = [];
			if(schedule.daysToExecute){
				schedule.daysToExecute.forEach(s => {
					setSchedule['daysToExecute'].push(s)
				});
			}
			return state.set("workingSchedule", setSchedule);
		}

		case actions.USER_UPDATE_WORKING_SCHEDULE: {
			return state
				.set("savingSchedule", true)
				.set("savingScheduleResponse", {});
		}

		case actions.USER_UPDATE_WORKING_SCHEDULE_FAILED: {
			return state
				.set("savingSchedule", false)
				.set("savingScheduleResponse", action.data)
				.set("schedulerWarningText", action.data.error);
		}

		case actions.USER_UPDATE_WORKING_SCHEDULE_SUCCESS: {
			return state
				.set("savingSchedule", false)
				.set("savingScheduleResponse", action.data);
		}
		case actions.USER_DELETE_WORKING_SCHEDULE: {
			return state
				.set("deletingSchedule", true)
		}

		case actions.USER_DELETE_WORKING_SCHEDULE_FAILED: {
			return state
				.set("deletingSchedule", false)
				.set("schedulerWarningText", action.data.error);
		}

		case actions.USER_DELETE_WORKING_SCHEDULE_SUCCESS: {
			return state
				.set("deletingSchedule", false)
		}

		case actions.UPDATE_SCHEDULE_WARNING_TEXT_EXECUTE: {
			return state
				.set("schedulerWarningText", action.data.text)
		}

		case actions.WORKING_TEMPLATE_INIT: {
			var initTemplate = state.get("templateDefaults");
			const created_at = momentDateToISOFormatConversion();
			initTemplate.name = action.data.name;
			initTemplate.user_id = action.data.user_id;
			initTemplate.created_at = created_at;
			initTemplate.updated_at = created_at;
			initTemplate.priority = action.data.priority;
			initTemplate.type = "";
			initTemplate.template_json = {
				template_id: 0,
				name: action.data.name,
				type: "custom_template_v8",
			}
      return state
        .set("workingTemplate", Object.assign({}, initTemplate))
        .set("templateWarningText", null)
        .set("templateWarning", null)
		}

		case actions.WORKING_TEMPLATE_UPDATE_NAME: {
			var nameTemplateUpdate = state.get("workingTemplate");
			nameTemplateUpdate.name = action.data.name;
			nameTemplateUpdate = utilRepricerTemplateUpdate(nameTemplateUpdate,	{name: action.data.name});
			return state.set("workingTemplate", Object.assign({}, nameTemplateUpdate));
		}

		case actions.WORKING_TEMPLATE_UPDATE_UPDATED_AT: {
			var atTemplateUpdate = state.get("workingTemplate");
			atTemplateUpdate.updated_at = momentDateToISOFormatConversion();
			return state.set("workingTemplate", Object.assign({}, atTemplateUpdate));
		}

		case actions.WORKING_TEMPLATE_UPDATE_JSON: {
			var jsonTemplateUpdate = state.get("workingTemplate");
			jsonTemplateUpdate = utilRepricerTemplateUpdate(jsonTemplateUpdate,	action.data.data);
			return state.set("workingTemplate", Object.assign({}, jsonTemplateUpdate));
		}

		case actions.WORKING_TEMPLATE_UPDATE_PROP: {
			var propTemplateUpdate = state.get("workingTemplate");
			propTemplateUpdate[action.data.prop] = action.data.data;
			propTemplateUpdate = utilRepricerTemplateUpdate(propTemplateUpdate,	{[action.data.prop]: action.data.data});
			return state.set("workingTemplate", Object.assign({}, propTemplateUpdate));
		}

		case actions.USER_LOAD_TEMPLATES: {
			return state.set("userTemplates", []);
		}

		case actions.USER_LOAD_TEMPLATES_FAILED: {
			return state.set("userTemplates", []);
		}

		case actions.USER_LOAD_TEMPLATES_SUCCESS: {
			return state.set("userTemplates", action.data.templates);
		}

		case actions.USER_LOAD_WORKING_TEMPLATE: {
			return state.set("workingTemplate", null);
		}

		case actions.USER_LOAD_WORKING_TEMPLATE_FAILED: {
			return state.set("workingTemplate", null);
		}

		case actions.USER_LOAD_WORKING_TEMPLATE_SUCCESS: {
			const template = templateConvertToFrontend(action.data.template);
			return state.set("workingTemplate", template);
		}

		case actions.USER_SAVE_WORKING_TEMPLATE: {
			return state
				.set("savingTemplate", true)
				.set("savingTemplateResponse", {});
		}

		case actions.USER_SAVE_WORKING_TEMPLATE_FAILED: {

			return state
				.set("savingTemplate", false)
				.set("savingTemplateResponse", action.data);
		}

		case actions.USER_SAVE_WORKING_TEMPLATE_SUCCESS: {
			return state
				.set("savingTemplate", false)
				.set("savingTemplateResponse", action.data);
		}

		case actions.USER_UPDATE_WORKING_TEMPLATE: {
			return state
				.set("savingTemplate", true)
				.set("savingTemplateResponse", {});
		}

		case actions.USER_UPDATE_WORKING_TEMPLATE_FAILED: {
			return state
				.set("savingTemplate", false)
				.set("savingTemplateResponse", action.data);
		}

		case actions.USER_UPDATE_WORKING_TEMPLATE_SUCCESS: {
			return state
				.set("savingTemplate", false)
				.set("savingTemplateResponse", action.data);
		}

		case actions.USER_DELETE_WORKING_TEMPLATE: {
			return state
				.set("deletingTemplate", true)
		}

		case actions.USER_DELETE_WORKING_TEMPLATE_FAILED: {
			return state
				.set("deletingTemplate", false)
		}

		case actions.USER_DELETE_WORKING_TEMPLATE_SUCCESS: {
			return state
				.set("deletingTemplate", false)
		}

		case actions.UPDATE_WARNING_TEXT_EXECUTE: {
			return state
				.set("templateWarningText", action.data.text)
		}
		case actions.UPDATE_WARNING_EXECUTE: {
			return state
				.set("templateWarning", action.data.data)
		}

		case actions.USER_GET_GLOBAL_LIST: {
			return state
				.set("predefinedSkuList", [])
				.set("predefinedSellerList", [])
		}
		case actions.USER_GET_GLOBAL_LIST_SUCCESS: {
			if(action.data.length > 0){
				if(action.data[0]['list_type'] === 'seller'){
					return state
						.set("predefinedSellerList", action.data)
				} else if(action.data[0]['list_type'] === 'sku') {
					return state
						.set("predefinedSkuList", action.data)
				}
			}
			return state
		}
		case actions.USER_GET_GLOBAL_LIST_FAILED: {
			return state
				.set("predefinedSkuList", [])
				.set("predefinedSellerList", [])
		}

		case actions.USER_ADD_GLOBAL_LIST_SUCCESS: {
			var predefinedSellerListAdd = state.get("predefinedSellerList");
			var predefinedSkuListAdd = state.get("predefinedSkuList");
			if(action.data.global_list){
				if(action.data.global_list.list_type === 'seller'){
					predefinedSellerListAdd.push(action.data.global_list);
				} else if(action.data.global_list.list_type === 'sku') {
					predefinedSkuListAdd.push(action.data.global_list);
				}
			}
			return state
				.set("predefinedSellerList", Object.assign([], predefinedSellerListAdd))
				.set("predefinedSkuList", Object.assign([], predefinedSkuListAdd))
		}

		case actions.USER_DELETE_GLOBAL_LIST_SUCCESS: {
			var predefinedSellerListDelete = state.get("predefinedSellerList");
			var predefinedSkuListDelete = state.get("predefinedSkuList");
			if(action.data.global_list){
				predefinedSellerListDelete = predefinedSellerListDelete.filter((item,idx) => item.id !== action.data.global_list.id);
				predefinedSkuListDelete = predefinedSkuListDelete.filter((item,idx) => item.id !== action.data.global_list.id);
			}
			return state
				.set("predefinedSellerList", Object.assign([], predefinedSellerListDelete))
				.set("predefinedSkuList", Object.assign([], predefinedSkuListDelete))
		}

		case actions.USER_UPDATE_GLOBAL_LIST_SUCCESS: {
			var predefinedSellerListUpdate = state.get("predefinedSellerList");
			var predefinedSkuListUpdate = state.get("predefinedSkuList");
			if(action.data.global_list){
				predefinedSellerListUpdate = predefinedSellerListUpdate.map((item) => {
					if(item.id === action.data.global_list.id){
						item.list_name = action.data.global_list.list_name;
						item.datas = action.data.global_list.datas;
					}
					return item;
				});
				predefinedSkuListUpdate = predefinedSkuListUpdate.map((item) => {
					if(item.id === action.data.global_list.id){
						item.list_name = action.data.global_list.list_name;
						item.datas = action.data.global_list.datas;
					}
					return item;
				});
			}
			return state
				.set("predefinedSellerList", Object.assign([], predefinedSellerListUpdate))
				.set("predefinedSkuList", Object.assign([], predefinedSkuListUpdate))
		}

		case actions.USER_LOAD_REPORTS_SUCCESS: {
			return state
				.set("repriceReports", Object.assign([], action.data.data))
		}

		case actions.USER_REPRICER_REPORT_SUMMARY: {
			return state
				.set("repriceReportSummary", {})
				.set("repriceReportSummaryLoading", true)
		}
		case actions.USER_REPRICER_REPORT_SUMMARY_FAILED: {
			return state
				.set("repriceReportSummary", {})
				.set("repriceReportSummaryLoading", false)
		}
		case actions.USER_REPRICER_REPORT_SUMMARY_SUCCESS: {
			return state
				.set("repriceReportSummary", Object.assign({}, action.data))
				.set("repriceReportSummaryLoading", false)
		}

		case actions.TEMPLATE_TO_SKU_MAPPING:
			return state
				.set("templateToSkuMappingData", {})
		case actions.TEMPLATE_TO_SKU_MAPPING_SUCCESS:
			return state
				.set("templateToSkuMappingData", Object.assign({}, action.data))
		case actions.TEMPLATE_TO_SKU_MAPPING_FAILED:
			return state
				.set("templateToSkuMappingData", {})

		case actions.TEMPLATE_QUERY_BY_SKUS:
			return state
				.set("templateBreakdownData", [])
		case actions.TEMPLATE_QUERY_BY_SKUS_SUCCESS:
			return state
				.set("templateBreakdownData", Object.assign([], action.data.data))
		case actions.TEMPLATE_QUERY_BY_SKUS_FAILED:
			return state
				.set("templateBreakdownData", [])

		case actions.TEMPLATE_CHECK_NAME:
			return state
				.set("templateNameOK", false)
		case actions.TEMPLATE_CHECK_NAME_SUCCESS:
			return state
				.set("templateNameOK", true)
		case actions.TEMPLATE_CHECK_NAME_FAILED:
			return state
				.set("templateNameOK", false)

		case actions.TEMPLATE_UPDATE_NAME:
			return state
				.set("templateNameUpdating", true)
		case actions.TEMPLATE_UPDATE_NAME_SUCCESS:
			var u_user_templates = Object.assign([], state.get("userTemplates"));
			const un_user_templates = u_user_templates.map(template => {
				if(template.id === action.data.template_id){
					template.name = action.data.name;
				}
				return template;
			})
			return state
				.set("templateNameUpdating", false)
				.set("userTemplates", un_user_templates)

		case actions.TEMPLATE_UPDATE_NAME_FAILED:
			return state
				.set("templateNameUpdating", false)

		case actions.REPORT_INSIGHT_RUN:
			return state
				.set("insightTemplate", {})
				.set("insightTemplateLoading", true)
		case actions.REPORT_INSIGHT_RUN_SUCCESS:
			return state
				.set("insightTemplate", Object.assign({}, JSON.parse(action.data)))
				.set("insightTemplateLoading", false)
		case actions.REPORT_INSIGHT_RUN_FAILED:
			return state
				.set("insightTemplate", {})
				.set("insightTemplateLoading", false)

	  case actions.USER_SELF_ENABLE_REPRICER:
			return state
				.set("user_enabling_repricer", true)

	  case actions.USER_SELF_ENABLE_REPRICER_SUCCESS:
			return state
				.set("user_enabling_repricer", false)

	  case actions.USER_SELF_ENABLE_REPRICER_FAILED:
			return state
				.set("user_enabling_repricer", false)

	  case actions.GET_REPRICER_DASHBOARD_REPRICING:
			return state
				.set("dashboard_repricing", {})
	  case actions.GET_REPRICER_DASHBOARD_REPRICING_SUCCESS:
			return state
				.set("dashboard_repricing", Object.assign({}, action.data))
	  case actions.GET_REPRICER_DASHBOARD_REPRICING_FAILED:
			return state
				.set("dashboard_repricing", {})


	default:
		return state;
	}
}
