import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  Row,
  Col
} from "reactstrap";
import settingsActions from '../../../../../redux/settings/actions';
import authActions from '../../../../../redux/auth/actions';
import Select from 'react-select'

const { updateUserData } = authActions;
const {
	labelsDataGet,
	updateUserSettings,
} = settingsActions;

class SinglePrintSettingPage extends Component {

	UNSAFE_componentWillMount(){
		this.props.labelsDataGet();
	}

	selectLabel = (v) => {
		const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["label_size"] = v.value;
			this.props.updateUserData(ud);
			let data = {label_size: ud.settings["label_size"]};
			this.props.updateUserSettings(data);
		}
	}

  render() {

    return (
      <React.Fragment>
        <p>Please select default label sizes for printer type below.</p>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Dymo Printers</Label>
			  <Select
				options={this.props.labelsData.dymo}
				value={this.props.userData ?
						this.props.labelsData.dymo.find(({ id }) => id === this.props.userData.settings.label_size)
						: null
				}
				onChange={v => this.selectLabel(!!v ? v : '')}
			  />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Zebra Printers</Label>
			  <Select
				options={this.props.labelsData.zebra}
				value={this.props.userData ?
						this.props.labelsData.zebra.find(({ id }) => id === this.props.userData.settings.label_size)
						: null
				}
				onChange={v => this.selectLabel(!!v ? v : '')}
			  />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Rollo Printers</Label>
			  <Select
				options={this.props.labelsData.rollo}
				value={this.props.userData ?
						this.props.labelsData.rollo.find(({ id }) => id === this.props.userData.settings.label_size)
						: null
				}
				onChange={v => this.selectLabel(!!v ? v : '')}
			  />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Brother Printers</Label>
			  <Select
				options={this.props.labelsData.brother}
				value={this.props.userData ?
						this.props.labelsData.brother.find(({ id }) => id === this.props.userData.settings.label_size)
						: null
				}
				onChange={v => this.selectLabel(!!v ? v : '')}
			  />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(state => {
  return {
    labelsData: state.Settings.get("labelsData"),
    userData: state.Auth.get("userData"),
  };
}, {
		labelsDataGet,
		updateUserData,
		updateUserSettings,
	}
)(SinglePrintSettingPage);
