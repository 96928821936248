import React, { Component } from 'react';
import { connect } from "react-redux";
import TransactionUploader from './TransactionUploader';
import AvailableReports from "./AvailableReports";
import accountingActions from "../../../../redux/accounting/actions";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from 'reactstrap';

const { clearUploadReportJobData } = accountingActions;

const ViewHeader = () => (
  <header className="view-header">
    <h1 className="page-title">Profit &amp; Loss Report</h1>
  </header>
);

const ViewContent = ({children}) => (
  <div className="view-content view-components">
      {children}
  </div>
);

class IncomeReport extends Component {
  componentDidMount() {
    this.props.clearUploadReportJobData();
  }

  render() {
     return (
      <div className="view">
        <ViewHeader />
        <ViewContent>
          <Card className="mb-5 pb-3">
            <CardBody>
              <Row>
                <Col>
                  <TransactionUploader  />
                </Col>
              </Row>

              <hr className="my-5" />

              <AvailableReports />
            </CardBody>
          </Card>
        </ViewContent>
    </div>
    )
  }
}

IncomeReport.propTypes = {
  clearUploadReportJobData: PropTypes.func.isRequired,
};

export default connect( null,{ clearUploadReportJobData })(IncomeReport);
