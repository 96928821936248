import React from "react";
import { Popover, PopoverBody, Input, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";

class NoteButtonPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  opentoggle = () => {
    const { popoverOpen } = this.state;
    if (!popoverOpen){
      this.setState({
        popoverOpen: true
      });
    }
  }
  closetoggle = () => {
    this.setState({
      popoverOpen: false
    });
  }

  render() {
    const { content, id, action, placement, buttonText, buttonStyle, style } = this.props;
    return (
      <FormGroup check>
        <Label
          check
          id={"Popover-" + id}
          onMouseOver={this.opentoggle}
          onMouseOut={this.closetoggle}
        >
        <Input
          type="radio"
          name="sidebar-condition-notes"
          onClick={action}
        />
          {buttonText}
        </Label>

        <Popover
          placement={placement ? placement : "auto"}
          isOpen={this.state.popoverOpen}
          target={"Popover-" + id}
          modifiers={{ preventOverflow: { enabled: false }, hide: {enabled: false} }}
          style={style}
        >
          <PopoverBody className="p-0">{content || "N/A"}</PopoverBody>
        </Popover>
      </FormGroup>
    )     
  }
}

NoteButtonPopover.propTypes = {
  placement: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string.isRequired
};
export default NoteButtonPopover;
