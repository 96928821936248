import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"

class PackingGroupItemsModal extends Component {
  render() {
    const { isOpen, toggle, shipment_working, shipment_data } = this.props

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Packing Group Items</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>ASIN</th>
                <th>FNSKU</th>
                <th>MSKU</th>
                <th>Qty</th>
                <th>Who Labels</th>
              </tr>
            </thead>
            <tbody>
              {shipment_working
                ? null
                : shipment_data?.packing_group_items?.map(item => (
                    <tr key={`group-item-${item.asin}`}>
                      <td>{item.asin}</td>
                      <td>{item.fnsku}</td>
                      <td>{item.msku}</td>
                      <td>{item.quantity}</td>
                      <td>{item.labelOwner}</td>
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PackingGroupItemsModal