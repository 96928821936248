import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './style.css';
import {
  momentDateToLocalFormatConversion,
} from "../../../helpers/utility";

class MessagePreviewModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      data
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-message-preview">
        <ModalHeader toggle={toggle}>Message Preview</ModalHeader>
        <ModalBody>
          <div className="rich-message-box">
            <div className="inner">
              <div className="message-media">
                {data.image && !data.image.includes('youtube.com') ?
                  <img
                    src={data.image}
                    alt=""
                    className="img-fluid"
                  />
                  : null
                }
                {data.image && data.image.includes('youtube.com') ?
                  <div className="embed-responsive embed-responsive-21by9">
                    <iframe
                      width="630"
                      height="236"
                      className="embed-responsive-item"
                      src={data.image}
                      allowFullScreen
                    ></iframe>
                  </div>
                  : null
                }
              </div>
              <div className="content">
                <span className="date">{momentDateToLocalFormatConversion(data.created_at * 1000)}</span>
                <h5 className="title">{data.title}</h5>
                <p>{data.description}</p>
                <div className="controls">
                  <NavLink to={data.try_button} className="btn btn-success">Try now</NavLink>
                  {data.more_button ?
                    <a
                      href={data.more_button}
                      className="btn btn-secondary"
                      target="__blank"
                    >Read more</a>
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close Preview</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default MessagePreviewModal;