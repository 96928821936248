import React, { Component } from "react";
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Collapse, Row, Col } from "reactstrap";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import InputAmountType from '../../../../../../shared/components/Form/InputAmountType';
//import IconHelp from "react-icons/lib/md/help";
import repricerActions from '../../../../../../redux/repricer/actions';
import Select from 'react-select';
import AlertTooltip from '../../components/AlertTooltip';
import TemplateTooltip from '../../components/TemplateTooltip';
import { stringToBool } from "../../../../../../helpers/repricer/template_utils";

const { workingTemplateUpdateJson } = repricerActions;

const repriceAgainstSelectOptions = [
	{value: "lowest", label: "Lowest"},
	{value: "buybox", label: "Buy Box"}
];

const targetChannelSelectOptions = [
	{value: "fba", label: "FBA"},
	{value: "mf", label: "MF"}
];

const inBuyBoxMethodOptions = [
	{value: "true", label: "above"},
	{value: "false", label: "below"},
	{value: "0", label: "match"},
];

const outBuyBoxMethodOptions = [
	{value: "true", label: "above"},
	{value: "false", label: "below"},
	{value: "0", label: "match"},
];

class OfferCount extends Component {
	state = {
		strategy: "dont_reprice",
		//target_lowest_or_buybox: {value: "lowest", label: "Lowest"},
		//target_channel: {value: "fba", label: "FBA"},
		target_lowest_or_buybox: null,
		target_channel: null,
		min_num_offer: 0,
		own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false: true,
		own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
		own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: true,
		own_inventory_in_buybox_dont_reprice: 'YES',
		in_buybox_reprice_amount_type: '$',
		in_buybox_reprice_amount: 0,
		in_buybox_up_down: null,
		not_in_buybox_reprice_amount_type: '$',
		not_in_buybox_reprice_amount: 0,
		not_in_buybox_up_down: null,

		// reprice used againts new (uan)
		uan_is_activated: 'YES',
		uan_own_inventory_in_buybox_dont_reprice: 'YES',
		uan_in_buybox_reprice_amount_type: '$',
		uan_in_buybox_reprice_amount: 0,
		uan_in_buybox_up_down: null,
		uan_not_in_buybox_reprice_amount_type: '$',
		uan_not_in_buybox_reprice_amount: 0,
		uan_not_in_buybox_up_down: null,
		uan_reprice_against: null,
		uan_target_channel: null,
		uan_min_num_offer: 0,
	}

	repriceAgainstSelectAction = (val) => {
		this.setState({ uan_reprice_against: val });
		if(!val){
			val = {value: null}
		}
		if(val.value === 'buybox'){
			this.setState({ uan_target_channel: null });
			this.props.workingTemplateUpdateJson(
				{data: {reprice_used_aginst_new_target_channel: null}})
		}
		this.props.workingTemplateUpdateJson(
			{data: {reprice_used_aginst_new_reprice_against: val.value}})
	}

	targetChannelSelectAction = (val) => {
		this.setState({ uan_target_channel: val });
		if(!val){
			val = {value: null}
		}
		this.props.workingTemplateUpdateJson(
			{data: {reprice_used_aginst_new_target_channel: val.value}})

	}

	mapLowestBuyBox = (option) => {
		var res = null;
		repriceAgainstSelectOptions.forEach(item => {
			if(item.value === option){
				res = item;
			}
		})
		return res;
	}

	mapTargetChannel = (option) => {
		var res = null;
		targetChannelSelectOptions.forEach(item => {
			if(item.value === option){
				res = item;
			}
		})
		return res;
	}

	UNSAFE_componentWillMount(){
		const { template_json } = this.props.workingTemplate;
		if(!template_json.offers_count_own_inventory_in_buybox_dont_reprice){
			this.setState({ own_inventory_in_buybox_dont_reprice: 'NO' });
		}
		var in_buybox_reprice_amount = template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer;
		var	in_buybox_up_down = template_json.offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false ? {value: "true", label: "above"} : {value: "false", label: "below"};
		var not_in_buybox_reprice_amount = template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer;
		var	not_in_buybox_up_down = template_json.offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false ? {value: "true", label: "above"} : {value: "false", label: "below"};

		this.setState({
			strategy: template_json.offers_count_strategy ? template_json.offers_count_strategy : "dont_reprice",
			target_lowest_or_buybox: this.mapLowestBuyBox(template_json.offers_count_target_lowest_or_buybox),
			target_channel: this.mapTargetChannel(template_json.offers_count_target_channel),
			min_num_offer: template_json.offers_count_min_num_offer ? template_json.offers_count_min_num_offer : 0,
			own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer:
				template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer ?
					template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer
					:	0,
			own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false:
				template_json.offers_count_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false ?
					template_json.offers_count_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false
					: true,
			own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false:
				template_json.offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false ?
					template_json.offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false
					: false,
			in_buybox_reprice_amount: in_buybox_reprice_amount,
			in_buybox_reprice_amount_type: template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			in_buybox_up_down: in_buybox_up_down,
			not_in_buybox_reprice_amount: not_in_buybox_reprice_amount,
			not_in_buybox_reprice_amount_type: template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			not_in_buybox_up_down: not_in_buybox_up_down,
		})
    if(!template_json.offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer){
      this.setState({ in_buybox_up_down: {value: "0", label: "match"} });
      this.props.workingTemplateUpdateJson(
        {data: {offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: '0'}})
    }
    if(!template_json.offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer){
      this.setState({ not_in_buybox_up_down: {value: "0", label: "match"} });
      this.props.workingTemplateUpdateJson(
        {data: {offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: '0'}})
    }

		if(!template_json.reprice_used_aginst_new_uan_is_activated){
			this.setState({ uan_is_activated: 'NO' });
		} else {
			this.setState({
				uan_is_activated: 'YES',
				uan_reprice_against: template_json.reprice_used_aginst_new_reprice_against ? repriceAgainstSelectOptions.find(item => item.value === template_json.reprice_used_aginst_new_reprice_against) : null,
				uan_target_channel: template_json.reprice_used_aginst_new_target_channel ? targetChannelSelectOptions.find(item => item.value === template_json.reprice_used_aginst_new_target_channel) : null,
				uan_min_num_offer: template_json.reprice_used_aginst_new_uan_min_num_offer ? template_json.reprice_used_aginst_new_uan_min_num_offer : 0,
				uan_own_inventory_in_buybox_dont_reprice: template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_dont_reprice ? "YES": "NO",
				uan_in_buybox_reprice_amount: template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer,
				uan_in_buybox_reprice_amount_type: template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
				uan_in_buybox_up_down: template_json.reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false ? {value: "true", label: "above"} : {value: "false", label: "below"},
				uan_not_in_buybox_reprice_amount: template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer,
				uan_not_in_buybox_reprice_amount_type: template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
				uan_not_in_buybox_up_down: template_json.reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false ? {value: "true", label: "above"} : {value: "false", label: "below"},
			});
			if(!template_json.reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer){
				this.setState({ uan_in_buybox_up_down: {value: "0", label: "match"} });
				this.props.workingTemplateUpdateJson(
					{data: {reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: '0'}})
			}
			if(!template_json.reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer){
				this.setState({ uan_not_in_buybox_up_down: {value: "0", label: "match"} });
				this.props.workingTemplateUpdateJson(
					{data: {reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: '0'}})
			}
		}
	}

	toggleStrategy = (name) => {
		this.setState({ strategy: name });
		this.props.workingTemplateUpdateJson(
			{data: {offers_count_strategy: name }})
	}

  onSelectLowestOrBuyBox = (value) => {
		if(!value){
			value = { value: null }
		}
		this.setState({ target_lowest_or_buybox: this.mapLowestBuyBox(value.value) });
		this.props.workingTemplateUpdateJson(
			{data: {offers_count_target_lowest_or_buybox: value.value}})
  }

  onSelectTargetChannel = (value) => {
		if(!value){
			value = { value: null }
		}
		this.setState({ target_channel: this.mapTargetChannel(value.value) });
		this.props.workingTemplateUpdateJson(
			{data: {offers_count_target_channel: value.value}})
  }

	onSliderChange = (value) => {
		this.setState({	min_num_offer: value });
		this.props.workingTemplateUpdateJson(
			{data: {offers_count_min_num_offer: value}})
	}

	updateUANInBoxRepriceAmount = (amount) => {
		this.setState({ uan_in_buybox_reprice_amount: amount });
	}

	updateInBoxRepriceAmount = (amount) => {
		this.setState({ in_buybox_reprice_amount: amount });
	}

	toggleUANInBoxRepriceAmountType = () => {
		this.setState({ uan_in_buybox_reprice_amount_type: this.state.uan_in_buybox_reprice_amount_type === '$' ? '%' : '$' });
	}

	toggleInBoxRepriceAmountType = () => {
		this.setState({ in_buybox_reprice_amount_type: this.state.in_buybox_reprice_amount_type === '$' ? '%' : '$' });
	}

	updateUANNotInBoxRepriceAmount = (amount) => {
		this.setState({ uan_not_in_buybox_reprice_amount: amount });
	}

	updateNotInBoxRepriceAmount = (amount) => {
		this.setState({ not_in_buybox_reprice_amount: amount });
	}

	toggleUANNotInBoxRepriceAmountType = () => {
		this.setState({ uan_not_in_buybox_reprice_amount_type: this.state.uan_not_in_buybox_reprice_amount_type === '$' ? '%' : '$' });
	}

	toggleNotInBoxRepriceAmountType = () => {
		this.setState({ not_in_buybox_reprice_amount_type: this.state.not_in_buybox_reprice_amount_type === '$' ? '%' : '$' });
	}

	changeIntegerValue = (e, root, key) => {
		var val = e;
		if(e.target && e.target.value){
			val = e.target.value;
		}
		val = val.toString();
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: parseInt(val, 10) }});
			return;
		} else {
			var val_int = parseInt(val.replace(/,/gi, ""), 10);
			if(!val_int){
				val = "";
				val_int = 0;
			} else {
				val = val_int.toString()
			}
			this.setState({ [key]: val_int });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: parseInt(val, 10) }});
		}
	}

	changeFloatValue = (e, root, key) => {
		var val = e.target.value;
		if(val === ''){
			this.setState({ [key]: 0 });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
			return;
		} else {
			var val_float = parseFloat(val.replace(/,/gi, "")).toFixed(2);
			if(!val_float){
				val = "";
				val_float = 0;
			} else {
				val = val_float.toString()
			}
			this.setState({ [key]: val_float });
			const json_key = root + "_" + key;
			this.props.workingTemplateUpdateJson({data: {[json_key]: val }});
		}
	}

	changeValue = (key, value, root='', json=false) => {
		value = this.state[key];
		this.setState({ [key]: value === 'YES' ? 'NO' : 'YES' });
		if(json === true){
			if(key === 'own_inventory_in_buybox_dont_reprice'){
				const json_key = root + '_' + key;
				this.props.workingTemplateUpdateJson({data: {[json_key]: value === 'YES' ? false : true }});
			}
			if(key === 'uan_own_inventory_in_buybox_dont_reprice'){
				const json_key = root + '_' + key;
				this.props.workingTemplateUpdateJson({data: {[json_key]: value === 'YES' ? false : true }});
				if(value === 'YES'){
					this.props.workingTemplateUpdateJson({
						data: {
							reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
							reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false: true,
							reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: "true",
						}
					});
					this.setState({
						uan_in_buybox_reprice_amount: 0,
						uan_in_buybox_reprice_amount_type: '$',
						uan_in_buybox_up_down: {value: "true", label: "above"},
					});
				}
			}
		}
	}

	updateUANNotInBoxUpDown = (e) => {
		this.setState({ uan_not_in_buybox_up_down: e });
		if(!e){
			e = {value: null}
		}
		var data = {
			reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false : e.value,
		}
		this.props.workingTemplateUpdateJson({ data: data	})
		if(e.value === '0'){
			data = {
				reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
			}
			this.setState({ uan_not_in_buybox_reprice_amount: 0 });
			this.props.workingTemplateUpdateJson({ data: data	})
		}
	}

	updateUANInBoxUpDown = (e) => {
		this.setState({ uan_in_buybox_up_down: e });
		if(!e){
			e = {value: null}
		}
		var data = {
			reprice_used_aginst_new_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false : e.value,
		}
		this.props.workingTemplateUpdateJson({ data: data	})

		if(e.value === '0'){
			data = {
				reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
			}
			this.setState({ uan_in_buybox_reprice_amount: 0 });
			this.props.workingTemplateUpdateJson({ data: data	})
		}
	}

	updateInBoxUpDown = (e) => {
		this.setState({ in_buybox_up_down: e });
		if(!e){
			e = {value: null}
		}
		var data = {
			offers_count_own_inventory_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false : stringToBool(e.value),
		}
		this.props.workingTemplateUpdateJson({ data: data	})

		if(e.value === '0'){
			data = {
				offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
			}
			this.setState({ in_buybox_reprice_amount: 0 });
			this.props.workingTemplateUpdateJson({ data: data	})
		}
	}

	updateNotInBoxUpDown = (e) => {
		this.setState({ not_in_buybox_up_down: e });
		if(!e){
			e = {value: null}
		}
		var data = {
			offers_count_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false : stringToBool(e.value),
		}
		this.props.workingTemplateUpdateJson({ data: data	})

		if(e.value === '0'){
			data = {
				offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
			}
			this.setState({ not_in_buybox_reprice_amount: 0 });
			this.props.workingTemplateUpdateJson({ data: data	})
		}
	}

	toggleUsedAgaintsNew = (value) => {
		this.setState({ uan_is_activated: value });
		const data = {
			reprice_used_aginst_new_uan_is_activated: value === 'YES' ? true : false,
		}
		this.props.workingTemplateUpdateJson({ data: data });
		if(value === 'YES'){
			this.props.workingTemplateUpdateJson({
				data: {
					reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer: 0,
					reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false: true,
					reprice_used_aginst_new_own_inventory_not_in_buybox_adjustment_static_method_adjust_up_if_true_down_if_false: "true",
				}
			});
			this.setState({
				uan_not_in_buybox_reprice_amount: 0,
				uan_not_in_buybox_reprice_amount_type: '$',
				uan_not_in_buybox_up_down: {value: "true", label: "above"},
			});
		}
	}


	render() {
		return (
			<div className="step-offer-count">
				<Row>
					<Col md={7}>
						<FormGroup className="mb-5">
							<h5 className="mb-3 h6">
								Compare used against new
								<TemplateTooltip
									id="t-compare-used-new"
									text="Compare used againts new"
								/>
							</h5>
							<FormGroup check className="custom-check radio">
								<Label check className="d-block">
									<Input
										type="radio"
										name="activate_uan"
										checked={this.state.uan_is_activated === "NO"}
										onChange={() => this.toggleUsedAgaintsNew("NO")}
									/> <span>Deactivate</span>
								</Label>
								<Label check className="d-block">
									<Input
										type="radio"
										name="activate_uan"
										checked={this.state.uan_is_activated === "YES"}
										onChange={() => this.toggleUsedAgaintsNew("YES")}
									/> <span>Activate</span>
								</Label>
							</FormGroup>
							<Collapse isOpen={this.state.uan_is_activated === "YES"}>
								<div className="collapse-inner">
									<FormGroup className="d-flex">
										<Label>Reprice against</Label>
										<Select
											className="d-inline-block ml-2"
											style={{ maxWidth: "8rem" }}
											options={repriceAgainstSelectOptions}
											value={this.state.uan_reprice_against}
											onChange={e => this.repriceAgainstSelectAction(e)}
										/>
										<AlertTooltip
											target="alert_reprice_againts"
											message={this.props.templateWarning}
											range={[38]}
										/>
									</FormGroup>
									<FormGroup className="d-flex">
										{this.state.uan_reprice_against
													&& this.state.uan_reprice_against.value !== "buybox" ?
											<React.Fragment>
												<Label>Target channel</Label>
												<Select
													className="d-inline-block ml-2"
													style={{ maxWidth: "8rem" }}
													options={targetChannelSelectOptions}
													value={this.state.uan_target_channel}
													onChange={e => this.targetChannelSelectAction(e)}
												/>
												<AlertTooltip
													target="alert_target_channel"
													message={this.props.templateWarning}
													range={[39]}
												/>
											</React.Fragment>
											: null
										}
									</FormGroup>
									<FormGroup className="mb-5">
										<FormGroup className="d-flex">
											<Label>Minimum number of offers</Label>
											<Input
												className="ml-2"
												type="text"
												pattern="\d*"
												maxLength="5"
												onChange={(e) => this.changeIntegerValue(e, 'reprice_used_aginst_new', 'uan_min_num_offer')}
												value={this.state.uan_min_num_offer}
												style={{ maxWidth: "8rem" }}
											/>
										</FormGroup>
										<Slider
											min={0}
											max={100}
											marks={{0:0, 10:10, 20:20, 30:30, 40:40, 50:50, 60:60, 70:70, 80:80, 90:90, 100:100}}
											value={this.state.uan_min_num_offer}
											onChange={(e) => this.changeIntegerValue(e, 'reprice_used_aginst_new', 'uan_min_num_offer')}
										/>
									</FormGroup>
									<FormGroup>
										<Label>What adjustment when your inventory <u>already in Buy Box</u></Label>
										<FormGroup check className="custom-check radio">
											<Label check>
												<Input
													type="radio"
													name="uan_adj_already_in_buybox"
													checked={this.state.uan_own_inventory_in_buybox_dont_reprice === 'YES'}
													onChange={() => this.changeValue("uan_own_inventory_in_buybox_dont_reprice", 'YES', 'reprice_used_aginst_new', true)}
												/> <span>Don't reprice</span>
											</Label>
											<Label check>
												<Input
													type="radio"
													name="uan_adj_already_in_buybox"
													checked={this.state.uan_own_inventory_in_buybox_dont_reprice === 'NO'}
													onChange={() => this.changeValue("uan_own_inventory_in_buybox_dont_reprice", 'NO', 'reprice_used_aginst_new', true)}
												/> <span>Set Adjustment</span>
											</Label>
										</FormGroup>
										<Collapse isOpen={this.state.uan_own_inventory_in_buybox_dont_reprice === 'NO'}>
											<div className="collapse-inner collapse-adjustment">
												<FormGroup className="d-flex align-items-center mb-0">
													<span>Reprice </span>
													<InputAmountType
														amountType={this.state.uan_in_buybox_reprice_amount_type}
														toggle={this.toggleUANInBoxRepriceAmountType}
														updateAmount={this.updateUANInBoxRepriceAmount}
														updateTemplateJson={this.props.workingTemplateUpdateJson}
														dataInTemplateJson="reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer"
														typeDataInTemplateJson="reprice_used_aginst_new_uan_own_inventory_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false"
														className="ml-2"
														value={this.state.uan_in_buybox_reprice_amount}
														min={0}
														disabled={this.state.uan_in_buybox_up_down && this.state.uan_in_buybox_up_down.value === "0"}
													/>
													<div style={{ flex: "1 0 auto" }}>
														<Select
															className="mx-2 w-auto"
															options={inBuyBoxMethodOptions}
															onChange={e => this.updateUANInBoxUpDown(e)}
															value={this.state.uan_in_buybox_up_down}
														/>
													</div>
													<span>the competing offer.</span>
													<AlertTooltip
														target="alert_in_buy_box"
														message={this.props.templateWarning}
														range={[40, 41, 42]}
													/>
												</FormGroup>
											</div>
										</Collapse>
									</FormGroup>
									<FormGroup>
										<Label>What adjustment when your inventory <u>is not already in Buy Box</u></Label>
										<div className="collapse-inner collapse-adjustment">
											<FormGroup className="d-flex align-items-center mb-0">
												<span>Reprice </span>
												<InputAmountType
													amountType={this.state.uan_not_in_buybox_reprice_amount_type}
													toggle={this.toggleUANNotInBoxRepriceAmountType}
													updateAmount={this.updateUANNotInBoxRepriceAmount}
													updateTemplateJson={this.props.workingTemplateUpdateJson}
													dataInTemplateJson="reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer"
													typeDataInTemplateJson="reprice_used_aginst_new_uan_own_inventory_not_in_buybox_adjustment_fix_price_adjustment_if_true_percentage_if_false"
													className="ml-2"
													value={this.state.uan_not_in_buybox_reprice_amount}
													min={0}
													disabled={this.state.uan_not_in_buybox_up_down && this.state.uan_not_in_buybox_up_down.value === "0"}
												/>
												<div style={{ flex: "1 0 auto" }}>
													<Select
														className="mx-2 w-auto"
														options={outBuyBoxMethodOptions}
														onChange={e => this.updateUANNotInBoxUpDown(e)}
														value={this.state.uan_not_in_buybox_up_down}
													/>
												</div>
												<span>the competing offer.</span>
												<AlertTooltip
													target="alert_not_in_buy_box"
													message={this.props.templateWarning}
													range={[43, 44, 45]}
												/>
											</FormGroup>
										</div>
									</FormGroup>
								</div>
							</Collapse>
						</FormGroup>

						<FormGroup>
							<h5 className="mb-3 h6">
								If product listing has more than 20 offers:
								<TemplateTooltip
									id="t-if-has-20offers"
									text="Condition for if product listing has more than 20 offers"
								/>
							</h5>
							<FormGroup check className="custom-check radio">
								<Label check className="d-block">
									<Input
										type="radio"
										name="strategy"
										checked={this.state.strategy === "dont_reprice"}
										onChange={() => this.toggleStrategy("dont_reprice")}
									/> <span>Don't reprice</span>
								</Label>
								<Label check className="d-block">
									<Input
										type="radio"
										name="strategy"
										checked={this.state.strategy === "reprice_against_top_20"}
										onChange={() => this.toggleStrategy("reprice_against_top_20")}
									/> <span>Reprice against the top 20 offers</span>
								</Label>
								<Label check className="d-block">
									<Input
										type="radio"
										name="strategy"
										checked={this.state.strategy === "reprice_against_lowest_or_buybox"}
										onChange={() => this.toggleStrategy("reprice_against_lowest_or_buybox")}
									/> <span>Reprice against the lowest FBA/MF by main condition or Buy Box by main condition</span>
								</Label>
							</FormGroup>
							<Collapse isOpen={this.state.strategy === "reprice_against_lowest_or_buybox"}>
								<div className="collapse-inner">
									<FormGroup className="d-flex">
										<Label>Reprice against</Label>
										<Select
											className="d-inline-block ml-2"
											style={{ maxWidth: "8rem" }}
											options={repriceAgainstSelectOptions}
											value={this.mapLowestBuyBox(this.props.workingTemplate.template_json.offers_count_target_lowest_or_buybox)}
											onChange={this.onSelectLowestOrBuyBox}
										/>
										<AlertTooltip
											target="alert_reprice_againts"
											message={this.props.templateWarning}
											range={[34]}
										/>
									</FormGroup>
									{this.props.workingTemplate.template_json.offers_count_target_lowest_or_buybox !== 'buybox' ?
										<FormGroup className="d-flex">
											<Label>Target channel</Label>
											<Select
												className="d-inline-block ml-2"
												style={{ maxWidth: "8rem" }}
												options={targetChannelSelectOptions}
												value={this.mapTargetChannel(this.props.workingTemplate.template_json.offers_count_target_channel)}
												onChange={this.onSelectTargetChannel}
											/>
											<AlertTooltip
												target="alert_target_channel"
												message={this.props.templateWarning}
												range={[35]}
											/>
										</FormGroup>
										: null
									}
									<FormGroup className="mb-5">
										<FormGroup className="d-flex">
											<Label>Minimum number of offers</Label>
											<Input
												className="ml-2"
												type="text"
												pattern="\d*"
												maxLength="5"
												onChange={(e) => this.changeIntegerValue(e, 'offers_count', 'min_num_offer')}
												value={this.state.min_num_offer}
												style={{ maxWidth: "8rem" }}
											/>
										</FormGroup>
										<Slider
											min={0}
											max={100}
											marks={{0:0, 10:10, 20:20, 30:30, 40:40, 50:50, 60:60, 70:70, 80:80, 90:90, 100:100}}
											onChange={(e) => this.changeIntegerValue(e, 'offers_count', 'min_num_offer')}
											value={this.state.min_num_offer}
										/>
									</FormGroup>
									<FormGroup>
										<Label>What adjustment when your inventory <u>already in Buy Box</u></Label>
										<FormGroup check className="custom-check radio">
											<Label check>
												<Input
													type="radio"
													name="adj_already_in_buybox"
													checked={this.state.own_inventory_in_buybox_dont_reprice === 'YES'}
													onChange={() => this.changeValue("own_inventory_in_buybox_dont_reprice", 'YES', 'offers_count', true)}
												/> <span>Don't reprice</span>
											</Label>
											<Label check>
												<Input
													type="radio"
													name="adj_already_in_buybox"
													checked={this.state.own_inventory_in_buybox_dont_reprice === 'NO'}
													onChange={() => this.changeValue("own_inventory_in_buybox_dont_reprice", 'NO', 'offers_count', true)}
												/> <span>Set Adjustment</span>
											</Label>
										</FormGroup>
										<Collapse isOpen={this.state.own_inventory_in_buybox_dont_reprice === 'NO'}>
											<div className="collapse-inner collapse-adjustment">
												<FormGroup className="d-flex align-items-center mb-0">
													<span>Reprice </span>
													<InputAmountType
														amountType={this.state.in_buybox_reprice_amount_type}
														toggle={this.toggleInBoxRepriceAmountType}
														updateAmount={this.updateInBoxRepriceAmount}
														updateTemplateJson={this.props.workingTemplateUpdateJson}
														dataInTemplateJson="offers_count_own_inventory_in_buybox_adjustment_adjustment_amount_on_competing_offer"
														typeDataInTemplateJson="offers_count_own_inventory_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false"
														className="ml-2"
														value={this.state.in_buybox_reprice_amount}
														min={0}
														disabled={this.state.in_buybox_up_down && this.state.in_buybox_up_down.value === "0"}
													/>
													<div style={{ flex: "1 0 auto" }}>
														<Select
															className="mx-2 w-auto"
															options={inBuyBoxMethodOptions}
															onChange={e => this.updateInBoxUpDown(e)}
														  value={this.state.in_buybox_up_down}
														/>
													</div>
													<span>the competing offer.</span>
													<AlertTooltip
														target="alert_in_buy_box"
														message={this.props.templateWarning}
														range={[36]}
													/>
												</FormGroup>
											</div>
										</Collapse>
									</FormGroup>
									<FormGroup>
									<Label>What adjustment when your inventory <u>is not already in Buy Box</u></Label>
										<div className="collapse-inner collapse-adjustment">
											<FormGroup className="d-flex align-items-center mb-0">
												<span>Reprice </span>
													<InputAmountType
														amountType={this.state.not_in_buybox_reprice_amount_type}
														toggle={this.toggleNotInBoxRepriceAmountType}
														updateAmount={this.updateNotInBoxRepriceAmount}
														updateTemplateJson={this.props.workingTemplateUpdateJson}
														dataInTemplateJson="offers_count_own_inventory_not_in_buybox_adjustment_adjustment_amount_on_competing_offer"
														typeDataInTemplateJson="offers_count_own_inventory_not_in_buybox_adjustment_adjustment_fix_price_adjustment_if_true_percentage_if_false"
														className="ml-2"
														value={this.state.not_in_buybox_reprice_amount}
														min={0}
														disabled={this.state.not_in_buybox_up_down && this.state.not_in_buybox_up_down.value === "0"}
													/>
													<div style={{ flex: "1 0 auto" }}>
														<Select
															className="mx-2 w-auto"
															options={inBuyBoxMethodOptions}
														  onChange={e => this.updateNotInBoxUpDown(e)}
														  value={this.state.not_in_buybox_up_down}
														/>
													</div>
												<span>the competing offer.</span>
												<AlertTooltip
													target="alert_not_in_buy_box"
													message={this.props.templateWarning}
													range={[37]}
												/>
											</FormGroup>
										</div>
								</FormGroup>
								</div>
							</Collapse>
						</FormGroup>
					</Col>
					<Col md={5}>
						<div className="help-textbox">
							<div className="content">
								<p>To obtain the most accurate price information for each product listing, we ask Amazon to send us price information for that ASIN whenever
									that ASIN has a competitive pricing change, however Amazon limits this pricing data to the top 20 offers or listings for any product.
								</p>
								<p>This means that if the particular ASIN or listing that you are interested in has more pricing data than the top 20 offers (meaning that
									a lot of sellers are selling this particular product), then we will not be able to apply all of our normal repricing filters such as
									seller ID, feedback rating, shipping times, etc. to your template.  However, we do have the lowest price for each channel, so we can give
									our sellers that option to use instead.
								</p>
								<p className="mb-0">
									Any software offering data outside of these parameters is violating Amazon’s TOS (Terms of Service) via your account and could lead to
									account suspension. We like to error on the side of caution and play within the rules.
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}

export default connect(
  state => ({
		templateWarning: state.Repricer.get("templateWarning"),
  }),
  {
		workingTemplateUpdateJson,
  }
)(OfferCount)
