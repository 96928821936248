import { templateConvertFrontendToBackend } from './v8/frontenttobackend';
import { templateConvertBackendToFrontend } from './v8/backendtofrontend';

export function templateConvertToBackend(template){
	return templateConvertFrontendToBackend(template);
}

export function templateConvertToFrontend(template){
	return templateConvertBackendToFrontend(template);
}
