const feedbackActions = {

	FEEDBACK_GET_DATA: "FEEDBACK_GET_DATA",
	FEEDBACK_GET_DATA_SUCCESS: "FEEDBACK_GET_DATA_SUCCESS",
	FEEDBACK_GET_DATA_FAILED: "FEEDBACK_GET_DATA_FAILED",

	getFeedbacks: (data) => ({
		type: feedbackActions.FEEDBACK_GET_DATA,
		data
	}),
	getFeedbacksSuccess: (data) => ({
		type: feedbackActions.FEEDBACK_GET_DATA_SUCCESS,
		data
	}),
	getFeedbacksFailed: (data) => ({
		type: feedbackActions.FEEDBACK_GET_DATA_FAILED,
		data
	}),
};

export default feedbackActions;
