const ebayActions = {

	EBAY_USER_AUTH: 'EBAY_USER_AUTH',
	EBAY_USER_AUTH_SUCCESS: 'EBAY_USER_AUTH_SUCCESS',
	EBAY_USER_AUTH_FAILED: 'EBAY_USER_AUTH_FAILED',

	EBAY_GET_ITEM_DATA: "EBAY_GET_ITEM_DATA",
	EBAY_GET_ITEM_DATA_SUCCESS: "EBAY_GET_ITEM_DATA_SUCCESS",
	EBAY_GET_ITEM_DATA_FAILED: "EBAY_GET_ITEM_DATA_FAILED",

	EBAY_EDIT_ITEM_DATA: "EBAY_EDIT_ITEM_DATA",
	EBAY_EDIT_ITEM_DATA_SUCCESS: "EBAY_EDIT_ITEM_DATA_SUCCESS",
	EBAY_EDIT_ITEM_DATA_FAILED: "EBAY_EDIT_ITEM_DATA_FAILED",

	EBAY_CREATE_ITEM_DATA: "EBAY_CREATE_ITEM_DATA",
	EBAY_CREATE_ITEM_DATA_SUCCESS: "EBAY_CREATE_ITEM_DATA_SUCCESS",
	EBAY_CREATE_ITEM_DATA_FAILED: "EBAY_CREATE_ITEM_DATA_FAILED",

	EBAY_USER_GET_POLICY: "EBAY_USER_GET_POLICY",
	EBAY_USER_GET_POLICY_SUCCESS: "EBAY_USER_GET_POLICY_SUCCESS",
	EBAY_USER_GET_POLICY_FAILED: "EBAY_USER_GET_POLICY_FAILED",

	EBAY_USER_GET_SETTINGS: "EBAY_USER_GET_SETTINGS",
	EBAY_USER_GET_SETTINGS_SUCCESS: "EBAY_USER_GET_SETTINGS_SUCCESS",
	EBAY_USER_GET_SETTINGS_FAILED: "EBAY_USER_GET_SETTINGS_FAILED",

	EBAY_USER_UPDATE_SETTINGS: "EBAY_USER_UPDATE_SETTINGS",
	EBAY_USER_UPDATE_SETTINGS_SUCCESS: "EBAY_USER_UPDATE_SETTINGS_SUCCESS",
	EBAY_USER_UPDATE_SETTINGS_FAILED: "EBAY_USER_UPDATE_SETTINGS_FAILED",

	EBAY_USER_GET_LOCATION: "EBAY_USER_GET_LOCATION",
	EBAY_USER_GET_LOCATION_SUCCESS: "EBAY_USER_GET_LOCATION_SUCCESS",
	EBAY_USER_GET_LOCATION_FAILED: "EBAY_USER_GET_LOCATION_FAILED",

	EBAY_USER_GET_ITEMS: "EBAY_USER_GET_ITEMS",
	EBAY_USER_GET_ITEMS_SUCCESS: "EBAY_USER_GET_ITEMS_SUCCESS",
	EBAY_USER_GET_ITEMS_FAILED: "EBAY_USER_GET_ITEMS_FAILED",

	EBAY_CREATE_MODAL_DISPLAY: "EBAY_CREATE_MODAL_DISPLAY",
	EBAY_CREATE_MODAL_DISPLAY_SUCCESS: "EBAY_CREATE_MODAL_DISPLAY_SUCCESS",

	EBAY_USER_GET_ORDER_ITEMS: "EBAY_USER_GET_ORDER_ITEMS",
	EBAY_USER_GET_ORDER_ITEMS_SUCCESS: "EBAY_USER_GET_ORDER_ITEMS_SUCCESS",
	EBAY_USER_GET_ORDER_ITEMS_FAILED: "EBAY_USER_GET_ORDER_ITEMS_FAILED",

	ADMIN_EBAY_PULL_ORDERS: "ADMIN_EBAY_PULL_ORDERS",
	ADMIN_EBAY_PULL_ORDERS_SUCCESS: "ADMIN_EBAY_PULL_ORDERS_SUCCESS",
	ADMIN_EBAY_PULL_ORDERS_FAILED: "ADMIN_EBAY_PULL_ORDERS_FAILED",

	ADMIN_EBAY_GET_ACTIVE_ORDERS: "ADMIN_EBAY_GET_ACTIVE_ORDERS",
	ADMIN_EBAY_GET_ACTIVE_ORDERS_SUCCESS: "ADMIN_EBAY_GET_ACTIVE_ORDERS_SUCCESS",
	ADMIN_EBAY_GET_ACTIVE_ORDERS_FAILED: "ADMIN_EBAY_GET_ACTIVE_ORDERS_FAILED",

	ADMIN_EBAY_ACTIVATE_ORDERS: "ADMIN_EBAY_ACTIVATE_ORDERS",
	ADMIN_EBAY_ACTIVATE_ORDERS_SUCCESS: "ADMIN_EBAY_ACTIVATE_ORDERS_SUCCESS",
	ADMIN_EBAY_ACTIVATE_ORDERS_FAILED: "ADMIN_EBAY_ACTIVATE_ORDERS_FAILED",

	EBAY_EDIT_ORDER_ITEM_DATA: "EBAY_EDIT_ORDER_ITEM_DATA",
	EBAY_EDIT_ORDER_ITEM_DATA_SUCCESS: "EBAY_EDIT_ORDER_ITEM_DATA_SUCCESS",
	EBAY_EDIT_ORDER_ITEM_DATA_FAILED: "EBAY_EDIT_ORDER_ITEM_DATA_FAILED",

	USER_SELF_ENABLE_EBAY: "USER_SELF_ENABLE_EBAY",
	USER_SELF_ENABLE_EBAY_SUCCESS: "USER_SELF_ENABLE_EBAY_SUCCESS",
	USER_SELF_ENABLE_EBAY_FAILED: "USER_SELF_ENABLE_EBAY_FAILED",

	USER_SELF_ORDER_EBAY_MARK_FULFILLED: "USER_SELF_ORDER_EBAY_MARK_FULFILLED",
	USER_SELF_ORDER_EBAY_MARK_FULFILLED_SUCCESS: "USER_SELF_ORDER_EBAY_MARK_FULFILLED_SUCCESS",
	USER_SELF_ORDER_EBAY_MARK_FULFILLED_FAILED: "USER_SELF_ORDER_EBAY_MARK_FULFILLED_FAILED",

	USER_RECALCULATE_EBAY_FEES: "USER_RECALCULATE_EBAY_FEES",
	USER_RECALCULATE_EBAY_FEES_SUCCESS: "USER_RECALCULATE_EBAY_FEES_SUCCESS",
	USER_RECALCULATE_EBAY_FEES_FAILED: "USER_RECALCULATE_EBAY_FEES_FAILED",

  EBAY_ITEM_EDIT_TOGGLE: "EBAY_ITEM_EDIT_TOGGLE",
  EBAY_ITEM_EDIT_TOGGLE_SUCCESS: "EBAY_ITEM_EDIT_TOGGLE_SUCCESS",
  EBAY_ITEM_EDIT_TOGGLE_FAILED: "EBAY_ITEM_EDIT_TOGGLE_FAILED",

  EBAY_ITEM_EDIT_LOAD_DATA: "EBAY_ITEM_EDIT_LOAD_DATA",
  EBAY_ITEM_EDIT_LOAD_DATA_SUCCESS: "EBAY_ITEM_EDIT_LOAD_DATA_SUCCESS",
  EBAY_ITEM_EDIT_LOAD_DATA_FAILED: "EBAY_ITEM_EDIT_LOAD_DATA_FAILED",

	EBAY_EDIT_ITEM_UPDATE_DATA: "EBAY_EDIT_ITEM_UPDATE_DATA",
	EBAY_EDIT_ITEM_UPDATE_DATA_SUCCESS: "EBAY_EDIT_ITEM_UPDATE_DATA_SUCCESS",
	EBAY_EDIT_ITEM_UPDATE_DATA_FAILED: "EBAY_EDIT_ITEM_UPDATE_DATA_FAILED",

	EBAY_EDIT_ITEM_UPDATE_DATA_EBAY: "EBAY_EDIT_ITEM_UPDATE_DATA_EBAY",
	EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_SUCCESS: "EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_SUCCESS",
	EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_FAILED: "EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_FAILED",

	EBAY_GET_ITEM_ASPECTS: "EBAY_GET_ITEM_ASPECTS",
	EBAY_GET_ITEM_ASPECTS_SUCCESS: "EBAY_GET_ITEM_ASPECTS_SUCCESS",
	EBAY_GET_ITEM_ASPECTS_FAILED: "EBAY_GET_ITEM_ASPECTS_FAILED",

	EBAY_GET_PACKAGE_TYPES: "EBAY_GET_PACKAGE_TYPES",
	EBAY_GET_PACKAGE_TYPES_SUCCESS: "EBAY_GET_PACKAGE_TYPES_SUCCESS",
	EBAY_GET_PACKAGE_TYPES_FAILED: "EBAY_GET_PACKAGE_TYPES_FAILED",

  EBAY_DIRECT_LISTING_ITEM_INIT: "EBAY_DIRECT_LISTING_ITEM_INIT",
  EBAY_DIRECT_LISTING_ITEM_INIT_SUCCESS: "EBAY_DIRECT_LISTING_ITEM_INIT_SUCCESS",

  EBAY_DIRECT_LISTING_ITEM_EDIT: "EBAY_DIRECT_LISTING_ITEM_EDIT",
  EBAY_DIRECT_LISTING_ITEM_EDIT_SUCCESS: "EBAY_DIRECT_LISTING_ITEM_EDIT_SUCCESS",

  EBAY_DIRECT_LISTING_UPLOAD_IMAGE: "EBAY_DIRECT_LISTING_UPLOAD_IMAGE",
  EBAY_DIRECT_LISTING_UPLOAD_IMAGE_SUCCESS: "EBAY_DIRECT_LISTING_UPLOAD_IMAGE_SUCCESS",
  EBAY_DIRECT_LISTING_UPLOAD_IMAGE_FAILED: "EBAY_DIRECT_LISTING_UPLOAD_IMAGE_FAILED",

  EBAY_DIRECT_LISTING_LOAD_CATEGORIES: "EBAY_DIRECT_LISTING_LOAD_CATEGORIES",
  EBAY_DIRECT_LISTING_LOAD_CATEGORIES_SUCCESS: "EBAY_DIRECT_LISTING_LOAD_CATEGORIES_SUCCESS",
  EBAY_DIRECT_LISTING_LOAD_CATEGORIES_FAILED: "EBAY_DIRECT_LISTING_LOAD_CATEGORIES_FAILED",

	EBAY_DIRECT_CREATE_ITEM_DATA: "EBAY_DIRECT_CREATE_ITEM_DATA",
	EBAY_DIRECT_CREATE_ITEM_DATA_SUCCESS: "EBAY_DIRECT_CREATE_ITEM_DATA_SUCCESS",
	EBAY_DIRECT_CREATE_ITEM_DATA_FAILED: "EBAY_DIRECT_CREATE_ITEM_DATA_FAILED",

	EBAY_DIRECT_SEARCH_PRODUCTS: "EBAY_DIRECT_SEARCH_PRODUCTS",
	EBAY_DIRECT_SEARCH_PRODUCTS_SUCCESS: "EBAY_DIRECT_SEARCH_PRODUCTS_SUCCESS",
	EBAY_DIRECT_SEARCH_PRODUCTS_FAILED: "EBAY_DIRECT_SEARCH_PRODUCTS_FAILED",

	EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR: "EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR",
	EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_SUCCESS: "EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_SUCCESS",
	EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_FAILED: "EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_FAILED",

	EBAY_DIRECT_SEARCH_PRODUCTS_SELECT: "EBAY_DIRECT_SEARCH_PRODUCTS_SELECT",
	EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_SUCCESS: "EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_SUCCESS",
	EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_FAILED: "EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_FAILED",

	EBAY_DIRECT_ADDITIONAL_DATA: "EBAY_DIRECT_ADDITIONAL_DATA",
	EBAY_DIRECT_ADDITIONAL_DATA_SUCCESS: "EBAY_DIRECT_ADDITIONAL_DATA_SUCCESS",
	EBAY_DIRECT_ADDITIONAL_DATA_FAILED: "EBAY_DIRECT_ADDITIONAL_DATA_FAILED",

	EBAY_CHECK_MF_SALES_UNFINISHED: "EBAY_CHECK_MF_SALES_UNFINISHED",
	EBAY_CHECK_MF_SALES_UNFINISHED_SUCCESS: "EBAY_CHECK_MF_SALES_UNFINISHED_SUCCESS",
	EBAY_CHECK_MF_SALES_UNFINISHED_FAILED: "EBAY_CHECK_MF_SALES_UNFINISHED_FAILED",

  EBAY_DIRECT_LISTING_CLOSE_POPUP: "EBAY_DIRECT_LISTING_CLOSE_POPUP",
  EBAY_DIRECT_LISTING_CLOSE_POPUP_SUCCESS: "EBAY_DIRECT_LISTING_CLOSE_POPUP_SUCCESS",

  EBAY_LISTING_LOAD_CATEGORIES: "EBAY_LISTING_LOAD_CATEGORIES",
  EBAY_LISTING_LOAD_CATEGORIES_SUCCESS: "EBAY_LISTING_LOAD_CATEGORIES_SUCCESS",
  EBAY_LISTING_LOAD_CATEGORIES_FAILED: "EBAY_LISTING_LOAD_CATEGORIES_FAILED",

	EBAY_GET_ITEM_EDIT_ASPECTS: "EBAY_GET_ITEM_EDIT_ASPECTS",
	EBAY_GET_ITEM_EDIT_ASPECTS_SUCCESS: "EBAY_GET_ITEM_EDIT_ASPECTS_SUCCESS",
	EBAY_GET_ITEM_EDIT_ASPECTS_FAILED: "EBAY_GET_ITEM_EDIT_ASPECTS_FAILED",

	EBAY_EDIT_ITEM_DATA_EDIT: "EBAY_EDIT_ITEM_DATA_EDIT",
	EBAY_EDIT_ITEM_DATA_EDIT_SUCCESS: "EBAY_EDIT_ITEM_DATA_EDIT_SUCCESS",
	EBAY_EDIT_ITEM_DATA_EDIT_FAILED: "EBAY_EDIT_ITEM_DATA_EDIT_FAILED",

  EBAY_MARKETING_CONVERSION: "EBAY_MARKETING_CONVERSION",
  EBAY_MARKETING_CONVERSION_SUCCESS: "EBAY_MARKETING_CONVERSION_SUCCESS",
  EBAY_MARKETING_CONVERSION_FAILED: "EBAY_MARKETING_CONVERSION_FAILED",

  EBAY_DIRECT_SEARCH_QUERY_UPDATE: "EBAY_DIRECT_SEARCH_QUERY_UPDATE",
  EBAY_DIRECT_SEARCH_QUERY_UPDATE_SUCCESS: "EBAY_DIRECT_SEARCH_QUERY_UPDATE_SUCCESS",

  EBAY_USER_GET_ITEMS_COUNT: "EBAY_USER_GET_ITEMS_COUNT",
  EBAY_USER_GET_ITEMS_COUNT_SUCCESS: "EBAY_USER_GET_ITEMS_COUNT_SUCCESS",
  EBAY_USER_GET_ITEMS_COUNT_FAILED: "EBAY_USER_GET_ITEMS_COUNT_FAILED",

  EBAY_CROSS_LIST_IMAGE_UPLOAD: "EBAY_CROSS_LIST_IMAGE_UPLOAD",
  EBAY_CROSS_LIST_IMAGE_UPLOAD_SUCCESS: "EBAY_CROSS_LIST_IMAGE_UPLOAD_SUCCESS",
  EBAY_CROSS_LIST_IMAGE_UPLOAD_FAILED: "EBAY_CROSS_LIST_IMAGE_UPLOAD_FAILED",

	ebayDirectSearchQueryUpdate: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_QUERY_UPDATE,
		data
	}),
	ebayDirectSearchQueryUpdateSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_QUERY_UPDATE_SUCCESS,
		data
	}),

	ebayMarketingConversionGet: (data) => ({
		type: ebayActions.EBAY_MARKETING_CONVERSION,
		data
	}),
	ebayMarketingConversionGetSuccess: (data, file) => ({
		type: ebayActions.EBAY_MARKETING_CONVERSION_SUCCESS,
    data,
    file
	}),
	ebayMarketingConversionGetFailed: (data) => ({
		type: ebayActions.EBAY_MARKETING_CONVERSION_FAILED,
		data
	}),
	/*
	 * edit item data
	 * data will need to be array in format below
	 *
	 * const data = [{
	 *   key: "img_urls",
	 *	 value: val,
	 *	 type: "array",
	 *	 action: 'delete',
	 * }]
	 *
	 * key - key to change
	 * value - value to set
	 * type - type of property ['array', and other]
	 * action - action to execute, related to certain type
	 *
	**/
	ebayEditItemDataItem: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA_EDIT,
		data
	}),
	ebayEditItemDataItemSuccess: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA_EDIT_SUCCESS,
		data
	}),
	ebayEditItemDataItemFailed: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA_EDIT_FAILED,
		data
	}),

	ebayGetItemEditAspects: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_EDIT_ASPECTS,
		data
	}),
	ebayGetItemEditAspectsSuccess: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_EDIT_ASPECTS_SUCCESS,
		data
	}),
	ebayGetItemEditAspectsFailed: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_EDIT_ASPECTS_FAILED,
		data
	}),

	ebayListingLoadCategories: (data) => ({
		type: ebayActions.EBAY_LISTING_LOAD_CATEGORIES,
		data
	}),
	ebayListingLoadCategoriesSuccess: (data, file) => ({
		type: ebayActions.EBAY_LISTING_LOAD_CATEGORIES_SUCCESS,
    data,
    file
	}),
	ebayListingLoadCategoriesFailed: (data) => ({
		type: ebayActions.EBAY_LISTING_LOAD_CATEGORIES_FAILED,
		data
	}),

	ebayDirectListingClosePopup: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_CLOSE_POPUP,
		data
	}),
	ebayDirectListingClosePopupSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_CLOSE_POPUP_SUCCESS,
		data
	}),

	checkMFSalesUnfinished: (data) => ({
		type: ebayActions.EBAY_CHECK_MF_SALES_UNFINISHED,
		data
	}),
	checkMFSalesUnfinishedSuccess: (data) => ({
		type: ebayActions.EBAY_CHECK_MF_SALES_UNFINISHED_SUCCESS,
		data
	}),
	checkMFSalesUnfinishedFailed: (data) => ({
		type: ebayActions.EBAY_CHECK_MF_SALES_UNFINISHED_FAILED,
		data
	}),

	ebayDirectAdditionalData: (data) => ({
		type: ebayActions.EBAY_DIRECT_ADDITIONAL_DATA,
		data
	}),
	ebayDirectAdditionalDataSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_ADDITIONAL_DATA_SUCCESS,
		data
	}),
	ebayDirectAdditionalDataFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_ADDITIONAL_DATA_FAILED,
		data
	}),

	ebayDirectSearchProductsSelect: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_SELECT,
		data
	}),
	ebayDirectSearchProductsSelectSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_SUCCESS,
		data
	}),
	ebayDirectSearchProductsSelectFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_SELECT_FAILED,
		data
	}),

	ebayDirectSearchProductsClear: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR,
		data
	}),
	ebayDirectSearchProductsClearSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_SUCCESS,
		data
	}),
	ebayDirectSearchProductsClearFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_CLEAR_FAILED,
		data
	}),

	ebayDirectSearchProducts: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS,
		data
	}),
	ebayDirectSearchProductsSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_SUCCESS,
		data
	}),
	ebayDirectSearchProductsFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_SEARCH_PRODUCTS_FAILED,
		data
	}),

	ebayDirectCreateItemData: (data) => ({
		type: ebayActions.EBAY_DIRECT_CREATE_ITEM_DATA,
		data
	}),
	ebayDirectCreateItemDataSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_CREATE_ITEM_DATA_SUCCESS,
		data
	}),
	ebayDirectCreateItemDataFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_CREATE_ITEM_DATA_FAILED,
		data
	}),

	ebayDirectListingLoadCategories: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES,
		data
	}),
	ebayDirectListingLoadCategoriesSuccess: (data, file) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES_SUCCESS,
    data,
    file
	}),
	ebayDirectListingLoadCategoriesFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_LOAD_CATEGORIES_FAILED,
		data
	}),

	ebayDirectListingUploadImage: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_UPLOAD_IMAGE,
		data
	}),
	ebayDirectListingUploadImageSuccess: (data, file) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_UPLOAD_IMAGE_SUCCESS,
    data,
    file
	}),
	ebayDirectListingUploadImageFailed: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_UPLOAD_IMAGE_FAILED,
		data
	}),

	ebayDirectListingItemEdit: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_ITEM_EDIT,
		data
	}),
	ebayDirectListingItemEditSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_ITEM_EDIT_SUCCESS,
		data
	}),

	ebayDirectListingItemInit: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_ITEM_INIT,
		data
	}),
	ebayDirectListingItemInitSuccess: (data) => ({
		type: ebayActions.EBAY_DIRECT_LISTING_ITEM_INIT_SUCCESS,
		data
	}),

	ebayGetPackageTypes: (data) => ({
		type: ebayActions.EBAY_GET_PACKAGE_TYPES,
		data
	}),
	ebayGetPackageTypesSuccess: (data) => ({
		type: ebayActions.EBAY_GET_PACKAGE_TYPES_SUCCESS,
		data
	}),
	ebayGetPackageTypesFailed: (data) => ({
		type: ebayActions.EBAY_GET_PACKAGE_TYPES_FAILED,
		data
	}),

	ebayGetItemAspects: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_ASPECTS,
		data
	}),
	ebayGetItemAspectsSuccess: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_ASPECTS_SUCCESS,
		data
	}),
	ebayGetItemAspectsFailed: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_ASPECTS_FAILED,
		data
	}),

	ebayEditItemUpdateDataEbay: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY,
		data
	}),
	ebayEditItemUpdateDataEbaySuccess: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_SUCCESS,
		data
	}),
	ebayEditItemUpdateDataEbayFailed: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA_EBAY_FAILED,
		data
	}),

	/*
	 * edit item data
	 * data will need to be array in format below
	 *
	 * const data = [{
	 *   key: "img_urls",
	 *	 value: val,
	 *	 type: "array",
	 *	 action: 'delete',
	 * }]
	 *
	 * key - key to change
	 * value - value to set
	 * type - type of property ['array', and other]
	 * action - action to execute, related to certain type
	 *
	**/
	ebayEditItemUpdateData: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA,
		data
	}),
	ebayEditItemUpdateDataSuccess: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA_SUCCESS,
		data
	}),
	ebayEditItemUpdateDataFailed: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_UPDATE_DATA_FAILED,
		data
	}),

	ebayItemEditLoadData: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_LOAD_DATA,
		data
	}),
	ebayItemEditLoadDataSuccess: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_LOAD_DATA_SUCCESS,
		data
	}),
	ebayItemEditLoadDataFailed: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_LOAD_DATA_FAILED,
		data
	}),

	ebayItemEditToggle: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_TOGGLE,
		data
	}),
	ebayItemEditToggleSuccess: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_TOGGLE_SUCCESS,
		data
	}),
	ebayItemEditToggleFailed: (data) => ({
		type: ebayActions.EBAY_ITEM_EDIT_TOGGLE_FAILED,
		data
	}),

	userRecalculateEbayFees: (data) => ({
		type: ebayActions.USER_RECALCULATE_EBAY_FEES,
		data
	}),
	userRecalculateEbayFeesSuccess: (data) => ({
		type: ebayActions.USER_RECALCULATE_EBAY_FEES_SUCCESS,
		data
	}),
	userRecalculateEbayFeesFailed: (data) => ({
		type: ebayActions.USER_RECALCULATE_EBAY_FEES_FAILED,
		data
	}),

	userOrderEbayMarkFulfilled: (data) => ({
		type: ebayActions.USER_SELF_ORDER_EBAY_MARK_FULFILLED,
		data
	}),
	userOrderEbayMarkFulfilledSuccess: (data) => ({
		type: ebayActions.USER_SELF_ORDER_EBAY_MARK_FULFILLED_SUCCESS,
		data
	}),
	userOrderEbayMarkFulfilledFailed: (data) => ({
		type: ebayActions.USER_SELF_ORDER_EBAY_MARK_FULFILLED_FAILED,
		data
	}),

	userEnableEbay: (data) => ({
		type: ebayActions.USER_SELF_ENABLE_EBAY,
		data
	}),
	userEnableEbaySuccess: (data) => ({
		type: ebayActions.USER_SELF_ENABLE_EBAY_SUCCESS,
		data
	}),
	userEnableEbayFailed: (data) => ({
		type: ebayActions.USER_SELF_ENABLE_EBAY_FAILED,
		data
	}),

	ebayEditOrderItemData: (data) => ({
		type: ebayActions.EBAY_EDIT_ORDER_ITEM_DATA,
		data
	}),
	ebayEditOrderItemDataSuccess: (data) => ({
		type: ebayActions.EBAY_EDIT_ORDER_ITEM_DATA_SUCCESS,
		data
	}),
	ebayEditOrderItemDataFailed: (data) => ({
		type: ebayActions.EBAY_EDIT_ORDER_ITEM_DATA_FAILED,
		data
	}),

	adminEbayActivateOrders: (data) => ({
		type: ebayActions.ADMIN_EBAY_ACTIVATE_ORDERS,
		data
	}),
	adminEbayActivateOrdersSuccess: (data) => ({
		type: ebayActions.ADMIN_EBAY_ACTIVATE_ORDERS_SUCCESS,
		data
	}),
	adminEbayActivateOrdersFailed: (data) => ({
		type: ebayActions.ADMIN_EBAY_ACTIVATE_ORDERS_FAILED,
		data
	}),

	adminEbayGetActiveOrders: (data) => ({
		type: ebayActions.ADMIN_EBAY_GET_ACTIVE_ORDERS,
		data
	}),
	adminEbayGetActiveOrdersSuccess: (data) => ({
		type: ebayActions.ADMIN_EBAY_GET_ACTIVE_ORDERS_SUCCESS,
		data
	}),
	adminEbayGetActiveOrdersFailed: (data) => ({
		type: ebayActions.ADMIN_EBAY_GET_ACTIVE_ORDERS_FAILED,
		data
	}),

	adminEbayPullOrders: (data) => ({
		type: ebayActions.ADMIN_EBAY_PULL_ORDERS,
		data
	}),
	adminEbayPullOrdersSuccess: (data) => ({
		type: ebayActions.ADMIN_EBAY_PULL_ORDERS_SUCCESS,
		data
	}),
	adminEbayPullOrdersFailed: (data) => ({
		type: ebayActions.ADMIN_EBAY_PULL_ORDERS_FAILED,
		data
	}),

	ebayGetUserOrderItems: (data) => ({
		type: ebayActions.EBAY_USER_GET_ORDER_ITEMS,
		data
	}),
	ebayGetUserOrderItemsSuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_ORDER_ITEMS_SUCCESS,
		data
	}),
	ebayGetUserOrderItemsFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_ORDER_ITEMS_FAILED,
		data
	}),

	toggleEbayModal: (data) => ({
		type: ebayActions.EBAY_CREATE_MODAL_DISPLAY,
		data
	}),
	toggleEbayModalSuccess: (data) => ({
		type: ebayActions.EBAY_CREATE_MODAL_DISPLAY_SUCCESS,
		data
	}),

	ebayGetItems: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS,
		data
	}),
	ebayGetItemsSuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS_SUCCESS,
		data
	}),
	ebayGetItemsFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS_FAILED,
		data
	}),

	ebayUserGetLocation: (data) => ({
		type: ebayActions.EBAY_USER_GET_LOCATION,
		data
	}),
	ebayUserGetLocationSuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_LOCATION_SUCCESS,
		data
	}),
	ebayUserGetLocationFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_LOCATION_FAILED,
		data
	}),

	ebayCreateItemData: (data) => ({
		type: ebayActions.EBAY_CREATE_ITEM_DATA,
		data
	}),
	ebayCreateItemDataSuccess: (data) => ({
		type: ebayActions.EBAY_CREATE_ITEM_DATA_SUCCESS,
		data
	}),
	ebayCreateItemDataFailed: (data) => ({
		type: ebayActions.EBAY_CREATE_ITEM_DATA_FAILED,
		data
	}),

	ebayUserUpdateSettings: (data) => ({
		type: ebayActions.EBAY_USER_UPDATE_SETTINGS,
		data
	}),
	ebayUserUpdateSettingsSuccess: (data) => ({
		type: ebayActions.EBAY_USER_UPDATE_SETTINGS_SUCCESS,
		data
	}),
	ebayUserUpdateSettingsFailed: (data) => ({
		type: ebayActions.EBAY_USER_UPDATE_SETTINGS_FAILED,
		data
	}),

	ebayUserGetSettings: (data) => ({
		type: ebayActions.EBAY_USER_GET_SETTINGS,
		data
	}),
	ebayUserGetSettingsSuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_SETTINGS_SUCCESS,
		data
	}),
	ebayUserGetSettingsFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_SETTINGS_FAILED,
		data
	}),

	ebayUserGetPolicy: (data) => ({
		type: ebayActions.EBAY_USER_GET_POLICY,
		data
	}),
	ebayUserGetPolicySuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_POLICY_SUCCESS,
		data
	}),
	ebayUserGetPolicyFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_POLICY_FAILED,
		data
	}),

	/*
	 * edit item data
	 * data will need to be array in format below
	 *
	 * const data = [{
	 *   key: "img_urls",
	 *	 value: val,
	 *	 type: "array",
	 *	 action: 'delete',
	 * }]
	 *
	 * key - key to change
	 * value - value to set
	 * type - type of property ['array', and other]
	 * action - action to execute, related to certain type
	 *
	**/
	ebayEditItemData: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA,
		data
	}),
	ebayEditItemDataSuccess: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA_SUCCESS,
		data
	}),
	ebayEditItemDataFailed: (data) => ({
		type: ebayActions.EBAY_EDIT_ITEM_DATA_FAILED,
		data
	}),

	ebayGetItemData: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_DATA,
		data
	}),
	ebayGetItemDataSuccess: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_DATA_SUCCESS,
		data
	}),
	ebayGetItemDataFailed: (data) => ({
		type: ebayActions.EBAY_GET_ITEM_DATA_FAILED,
		data
	}),

	ebayUserAuth: (data) => ({
		type: ebayActions.EBAY_USER_AUTH,
		data
	}),
	ebayUserAuthSuccess: (data) => ({
		type: ebayActions.EBAY_USER_AUTH_SUCCESS,
		data
	}),
	ebayUserAuthFailed: (data) => ({
		type: ebayActions.EBAY_USER_AUTH_FAILED,
		data
	}),

	ebayGetItemsCount: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS_COUNT,
		data
	}),
	ebayGetItemsCountSuccess: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS_COUNT_SUCCESS,
		data
	}),
	ebayGetItemsCountFailed: (data) => ({
		type: ebayActions.EBAY_USER_GET_ITEMS_COUNT_FAILED,
		data
	}),

	ebayCrossListImageUpload: (data) => ({
		type: ebayActions.EBAY_CROSS_LIST_IMAGE_UPLOAD,
		data
	}),
	ebayCrossListImageUploadSuccess: (data) => ({
		type: ebayActions.EBAY_CROSS_LIST_IMAGE_UPLOAD_SUCCESS,
        data
	}),
	ebayCrossListImageUploadFailed: (data) => ({
		type: ebayActions.EBAY_CROSS_LIST_IMAGE_UPLOAD_FAILED,
		data
	}),

};

export default ebayActions;
