import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import IconBarcode from "react-icons/lib/fa/qrcode";
//import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import fulfillmentActions from "../../../redux/fulfillment/actions";
import moment from "moment";
import IconEdit from "react-icons/lib/md/edit";
import DeleteDialog from "../dialogs/DeleteDialog";
import batchActions from "../../../redux/batch/actions";
import printerActions from "../../../redux/print_service/actions";
import IconPrint from "react-icons/lib/fa/print";

const {
  fulfillmentBoxCreate,
  fulfillmentBoxAddItem,
  fulfillmentBoxDelete,
  fulfillmentBoxDeleteItem,
  fulfillmentBoxUpdate,
  fulfillmentClearBoxItemAdded,
  fulfillmentBoxAddItemAll,
  fulfillmentClearBoxItemAddedAll,
} = fulfillmentActions;

const {
	batchMetadataUpdate,
} = batchActions;

const {
	print,
} = printerActions;

class BoxContentModal extends Component {
  state = {
    search_query: "",
    search_query_count: 1,
    editingQuantity: {},
    deleteItemData: null,
    deleteBoxData: null,
    box_dimensions_update: {},
  }

  updateBoxDimensions = (key, value) => {
    var { box_dimensions_update } = this.state;
    box_dimensions_update[key] = value;
    this.setState({ box_dimensions_update: box_dimensions_update })
  }

  saveBoxData = () => {
    const { length, width, height, weight } = this.state;
    const data = {
      packing_group_id: this.props.packing_group_id,
      batch_id: this.props.batchMetadata?.id,
      weight_value: weight,
      dimensions_length: length,
      dimensions_width: width,
      dimensions_height: height,
      quantity: 0,
    }
    this.props.fulfillmentBoxCreate(data);
  }

  updateBoxData = (id) => {
    var data = {...this.state.box_dimensions_update};
    data.batch_id = this.props.batchMetadata?.id;
    data.id = id;
    this.props.fulfillmentBoxUpdate(data);
  }

  addItemToBox = () => {
    const { batchMetadata, active_box } = this.props;
    this.props.fulfillmentBoxAddItem({
      fulfillment_box_id: active_box.id,
      asin: this.state.search_query,
      batch_id: batchMetadata.id,
      init_quantity: this.state.search_query_count || null,
      packing_group_id: this.props.packing_group_id,
    });
  }

  fulfillmentBoxAddItemAll = () => {
    const { batchMetadata, active_box } = this.props;
    this.props.fulfillmentBoxAddItemAll({
      fulfillment_box_id: active_box.id,
      packing_group_id: this.props.packing_group_id,
      batch_id: batchMetadata.id,
    })
  }

  componentDidUpdate = (pp) => {
    if(this.props.batchMetadata?.printWhileScan
        && pp?.box_item_added?.id !== this.props.box_item_added?.id
        && this.props.box_item_added?.print_quantity
    ){
      this.props.print(
        {
          fnsku: this.getMSKUFNSKU(this.props.box_item_added.msku, this.props.active_box?.packing_group_id),
          expDate: this.props.box_item_added.expiration,
          name: this.props.box_item_added.batch_data?.name,
          condition: this.props.box_item_added.batch_data?.condition,
        },
        this.props.box_item_added.print_quantity,
      );
      this.props.fulfillmentClearBoxItemAdded();
    }

    if(this.props.batchMetadata?.printWhileScan
        && pp?.box_items_added !== this.props.box_items_added
      && this.props.box_items_added?.length > 0
    ){
      var promise = Promise.resolve();
      this.props.box_items_added.sort((a, b) => {return a?.batch_data?.id > b?.batch_data?.id}).forEach(box_item_added => {
        promise = promise.then(() => {
          this.props.print(
            {
              fnsku: this.getMSKUFNSKU(box_item_added.msku, this.props.active_box?.packing_group_id),
              expDate: box_item_added.expiration,
              name: box_item_added.batch_data?.name,
              condition: box_item_added.batch_data?.condition,
            },
            box_item_added.print_quantity,
          );
          return new Promise(function(resolve) {
            setTimeout(resolve, 1000);
          });
        });
      });
      this.props.fulfillmentClearBoxItemAddedAll();
    }
  }

  getMSKUqty = (msku, packing_group_id) => {
    const { batchMetadata } = this.props;
    var qty = 0;
    batchMetadata?.inbound_packing_groups_data[packing_group_id].items?.forEach(item => {
      if(item.msku === msku){ qty += item.quantity }
    })
    return qty;
  }

  getMSKUFNSKU = (msku, packing_group_id) => {
    const { batchMetadata } = this.props;
    const item = batchMetadata?.inbound_packing_groups_data[packing_group_id].items?.find(
            item => item.msku === msku)
    return item?.fnsku;
  }

  calculateQtyInAllBoxes = (msku, packing_group_id) => {
    const { boxes } = this.props;
    var qty = 0;
    boxes?.forEach(box => {
      box?.items?.forEach(item => {
        if(item.msku === msku){ qty += item.quantity }
      })
    })
    return qty;
  }

  calculateQtyForItem = (msku, packing_group_id) => {
    const qtyInAllBoxes = this.calculateQtyInAllBoxes(msku);
    const mskuQty = this.getMSKUqty(msku, packing_group_id);
    return <span className={qtyInAllBoxes >= mskuQty ? 'text-success' : 'text-danger'}>{`${qtyInAllBoxes}/${mskuQty}`}</span>;
  }

  updateItemQTY = (item) => {
    const { batchMetadata, active_box } = this.props;
    const { editingQuantity } = this.state;
    this.props.fulfillmentBoxAddItem({
      fulfillment_box_id: active_box.id,
      asin: item?.batch_data?.asin,
      batch_id: batchMetadata.id,
      new_quantity: editingQuantity[item.id],
    });
  }

  render() {
    const {
      isOpen,
      toggle,
      active_box,
      active_box_idx,
      fulfillment_inbound_working,
      batchMetadata,
    } = this.props

    const {
      editingQuantity,
      deleteItemData,
      deleteBoxData,
      box_dimensions_update,
    } = this.state;

    return (
      <Modal isOpen={isOpen} centered size="lg" style={{ maxWidth:'1200px' }}>
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center gap-4">
            <span>{`Add Item to Box ${active_box_idx + 1}`}</span>
            <FormGroup check className="custom-check checkbox mb-0">
              <Label check>
                <Input
                  type="checkbox"
                  checked={batchMetadata?.printWhileScan ? true : false}
							    onChange={(e) => this.props.batchMetadataUpdate({printWhileScan: e.target.checked})}
                /> <span>AutoPrint</span>
              </Label>
            </FormGroup>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center gap-2 mb-4">
            <div className="d-flex align-items-center gap-2">
              <IconBarcode size="16" />
              <Input
                type="number"
                min={1}
                style={{ width: 40 }}
                value={this.state.search_query_count}
                onChange={e => this.setState({search_query_count: e.target.value.trim()})}
              />
              <Input
                type="text"
                style={{ width: 320 }}
                placeholder="Scan item or search by ASIN/SKU..."
                value={this.state.search_query}
                onChange={e => this.setState({search_query: e.target.value.trim()})}
              />
              <Button
                color="primary"
                onClick={() => this.addItemToBox()}
                disabled={fulfillment_inbound_working || this.state.search_query.length === 0}
              >Add Item</Button>
              <Button
                color="success"
                onClick={() => this.fulfillmentBoxAddItemAll()}
                disabled={fulfillment_inbound_working}
              >Add All</Button>
            </div>
            {/* <div className="d-flex gap-4">
              <div>
                <span>Dimensions (in): </span>
                <span>{active_box?.dimensions_length} x {active_box?.dimensions_width} x {active_box?.dimensions_height}</span>
              </div>
              <div>
                <span>Weight: </span>
                <span>{active_box?.weight_value} lbs</span>
              </div>
            </div> */}
            <div className="d-flex align-items-center gap-2">
              <span>L:</span>
              <Input
                type="number"
                min={0}
                style={{ width: 80 }}
                placeholder="in"
                value={box_dimensions_update?.dimensions_length
                  ? box_dimensions_update?.dimensions_length
                  : active_box?.dimensions_length
                }
                onChange={e => this.updateBoxDimensions("dimensions_length", Number(e.target.value))}
              />
              <span>W:</span>
              <Input
                type="number"
                min={0}
                style={{ width: 80 }}
                placeholder="in"
                value={box_dimensions_update?.dimensions_width
                  ? box_dimensions_update?.dimensions_width
                  : active_box?.dimensions_width
                }
                onChange={e => this.updateBoxDimensions("dimensions_width", Number(e.target.value))}
              />
              <span>H:</span>
              <Input
                type="number"
                min={0}
                style={{ width: 80 }}
                placeholder="in"
                value={box_dimensions_update?.dimensions_height
                  ? box_dimensions_update?.dimensions_height
                  : active_box?.dimensions_height
                }
                onChange={e => this.updateBoxDimensions("dimensions_height", Number(e.target.value))}
              />
              <span>Est. W:</span>
              <Input
                type="number"
                min={0}
                style={{ width: 80 }}
                placeholder="lbs"
                value={box_dimensions_update?.weight_value
                  ? box_dimensions_update?.weight_value
                  : active_box?.weight_value
                }
                onChange={e => this.updateBoxDimensions("weight_value", Number(e.target.value))}
              />
              <Button
                color="primary"
                onClick={() => this.updateBoxData(active_box.id)}
                disabled={fulfillment_inbound_working}
              >Save Changes</Button>
            </div>
          </div>
          <Table>
            <thead>
              <tr>
                <th>QTY In Box</th>
                <th>Expire</th>
                <th>Left To Pack</th>
                <th>Title/SKU/Condition</th>
                <th>Print</th>
                <th>FNSKU</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {active_box?.items.map((item, idx) => (
              <tr key={`product-added-${item.id}`}>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Input
                      type="number"
                      style={{ width: 80 }}
                      min={1}
                      value={this.state.editingQuantity?.[item.id] ?? item.quantity}
                      readOnly={!this.state.editingQuantity?.[item.id] === undefined}
                      disabled={
                        this.state.editingQuantity?.[item.id] === undefined ||
                          fulfillment_inbound_working
                      }
                      onChange={e => {
                        const newEditingQuantity = {...this.state.editingQuantity};
                        newEditingQuantity[item.id] = parseInt(e.target.value);
                        this.setState({editingQuantity: newEditingQuantity});
                      }}
                    />
                    {this.state.editingQuantity?.[item.id] === undefined ? (
                      <IconEdit
                        role="button"
                        onClick={() => {
                          const newEditingQuantity = {...this.state.editingQuantity};
                          newEditingQuantity[item.id] = item.quantity;
                          this.setState({editingQuantity: newEditingQuantity});
                        }}
                      />
                    ) : (
                      <div className="d-flex align-items-center gap-2">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.updateItemQTY(item);
                            // then update the input mode to readonly
                            const newEditingQuantity = {...this.state.editingQuantity};
                            delete newEditingQuantity[item.id];
                            this.setState({editingQuantity: newEditingQuantity});
                          }}
                          disabled={
                            fulfillment_inbound_working
                              || !this.state.editingQuantity?.[item.id]
                              || this.state.editingQuantity?.[item.id] === undefined
                          }
                        >
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={() => {
                            const newEditingQuantity = {...this.state.editingQuantity};
                            delete newEditingQuantity[item.id];
                            this.setState({editingQuantity: newEditingQuantity});
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  {/*
                  <DatePicker
                    selected={item.expiration ? item.expiration : null}
                    //onChange={selected => this.updateAddedProducts(idx, 'expiration_date', selected)}
                  />
                  */}
                  {item.expiration ? moment(item.expiration).format("LL") : "N/A"}
                </td>
                <td>{this.calculateQtyForItem(item.msku, active_box?.packing_group_id)}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div style={{ width: 56 }}>
                      <img
                        src={item?.batch_data?.image_url
                          ? item?.batch_data?.image_url
                          : `https://www.amazon.com/images/P/${item?.batch_data?.asin}.01._THUMBZZZ_.jpg`
                        }
                        alt=""
                        height={48}
                      />
                    </div>
                    <div>
                      <span className="text-truncate">{item?.batch_data?.name?.length > 60 ? `${item?.batch_data?.name.substring(0, 60)}...` : item?.batch_data?.name}</span>
                      <br />
                      <small>SKU: {item.msku} / {item?.batch_data?.condition}</small>
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    role="button"
                    onClick={() => {
                      this.props.print(
                        {
                          fnsku: this.getMSKUFNSKU(item.msku, active_box?.packing_group_id),
                          expDate: item.expiration,
                          name: item?.batch_data?.name,
                          condition: item?.batch_data?.condition,
                        },
                        item.quantity
                      );
                    }}
                  ><IconPrint size="16" /></span>
                </td>
                <td>{this.getMSKUFNSKU(item.msku, active_box?.packing_group_id)}</td>
                <td>
                  <span
                    className="text-danger"
                    role="button"
                    style={{ fontSize: 20 }}
                    onClick={() => this.setState({ deleteItemData: {
                      box: active_box?.id,
                      id: item.id,
                    } })}
                    disabled={fulfillment_inbound_working}
                  >&times;</span>
                  {deleteItemData && (
                    <DeleteDialog
                      isOpen={true}
                      close={() => this.setState({ deleteItemData: null })}
                      dialogTitle="Are you sure want to delete this item?"
                      confirmText="Yes, delete item"
                      deleteItem={() => {
                        this.props.fulfillmentBoxDeleteItem({
                          box: deleteItemData.box,
                          id: deleteItemData.id,
                        })
                        this.setState({ deleteItemData: null })
                      }}
                    />
                  )}
                </td>
              </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between gap-3">
          <Button
            color="danger"
            onClick={() => this.setState({ deleteBoxData: {
              id: active_box?.id,
              batch: this.props.batchMetadata.id,
            } })}
          >Delete Box</Button>
          {deleteBoxData && (
            <DeleteDialog
              isOpen={true}
              close={() => this.setState({ deleteBoxData: null })}
              dialogTitle="Are you sure want to delete this box?"
              confirmText="Yes, delete box"
              deleteItem={() => {
                this.props.fulfillmentBoxDelete({
                  id: deleteBoxData.id,
                  batch: deleteBoxData.batch,
                })
                toggle()
                this.setState({ deleteBoxData: null })
              }}
            />
          )}
          <Button onClick={toggle}>Done</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect(
  state => ({
		userData: state.Auth.get('userData'),
    batchMetadata: state.Batch.get("batchMetadata"),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    box_item_added: state.Fulfillment.get("box_item_added"),
    box_items_added: state.Fulfillment.get("box_items_added"),
  }),
  {
    fulfillmentBoxCreate,
    fulfillmentBoxAddItem,
    fulfillmentBoxDelete,
    fulfillmentBoxDeleteItem,
    fulfillmentBoxUpdate,
    print,
	  batchMetadataUpdate,
    fulfillmentClearBoxItemAdded,
    fulfillmentBoxAddItemAll,
    fulfillmentClearBoxItemAddedAll,
  }
)(BoxContentModal);
