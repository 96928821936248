import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, ModalBody, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import IconSearch from "react-icons/lib/fa/search";
import IconClose from "react-icons/lib/fa/close";
import IconLink from "react-icons/lib/fa/external-link";
import './style.css';
import dashboardActions from "../../../redux/main_dashboard/actions";
import ReactHtmlParser from 'react-html-parser';

const {
  searchHelp,
  searchHelpSingle,
} = dashboardActions;

class SearchModal extends Component {
  state = {
    activeArticle: null,
    searchDone: false,
    query: "",
  }

  toggleArticle = (id) => {
    this.setState({ activeArticle: id })
    this.props.searchHelpSingle({ id: id })
  }

  closeModal = () => {
    this.setState({
      activeArticle: null,
      searchDone: false,
      query: "",
    })
    this.props.toggle();
  }

  search = () => {
    if(this.props.help_data_loading){ return; }
    const data = {
      query: this.state.query
    }
    this.props.searchHelp(data);
    this.setState({ searchDone: true });
  }

  render() {
    const {
      isOpen,
      toggle,
      help_data,
      help_data_loading,
      help_data_single,
      help_data_single_loading,
    } = this.props;

    return (
      <Modal
        className={`search-modal ${this.state.searchDone ? 'search-done' : ''}`}
        isOpen={isOpen}
        toggle={() => this.closeModal()}
      >
        <ModalBody>
          <div className="search-modal-top">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <IconSearch />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Type your question here..."
                onChange={(e) => {
                  this.setState({
                    query: e.target.value,
                    searchDone: false,
                  })
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.search();
                  }
                }}
                disabled={help_data_loading}
              />
            </InputGroup>
            <IconClose
              className="icon-close"
              onClick={this.closeModal}
            />
          </div>
          {help_data.length && this.state.searchDone ?
          <div className="search-modal-result">
            <div className={`articles ${this.state.activeArticle !== null ? 'active' : ''}`}>
              {
                help_data.map(item => (
                <div
                  className={`article-item ${this.state.activeArticle === item.id ? 'active' : ''}`}
                  key={item.id}
                  onClick={() => this.toggleArticle(item.id)}
                  disabled={help_data_single_loading}
                >
                  <div>
                    <h6 className="title">{item.name}</h6>
                    <p>{item.answer_sample}</p>
                  </div>
                  <a href={item.url} target="_blank" rel="noopener noreferrer"><IconLink /></a>
                </div>
                ))
              }
            </div>
            {this.state.activeArticle !== null && help_data_single &&
            <div className="article-details">
              <h2 className="title">{help_data_single.name}</h2>
                {ReactHtmlParser(help_data_single.answer?.body)}
            </div>
            }
          </div>
          : null
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    help_data: state.Dashboard.get('help_data'),
    help_data_loading: state.Dashboard.get('help_data_loading'),
    help_data_single: state.Dashboard.get('help_data_single'),
    help_data_single_loading: state.Dashboard.get('help_data_single_loading'),
  }),
  {
    searchHelp,
    searchHelpSingle,
  }
)(SearchModal);
