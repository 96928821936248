import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from "../../../../helpers/utility";
import {
  Button, Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import EbayListingModal from "./EbayListingModal";
import "../../style.css";
import inventoryActions from '../../../../redux/inventory/actions';
import ebayActions from '../../../../redux/ebay/actions';
import {getConditionNumAbbreviations} from '../../../../helpers/inventory/utility';
import {ebay_generate_item_url} from '../../../../helpers/ebay/utility';
import { checkIfEmployeeAndAllowed } from '../../../../helpers/utility';
import DeleteDialog from "../../../../shared/components/dialogs/DeleteDialog";

const {
  updateInventory,
  inventoryItemDelete,
  inventoryItemActivate
} = inventoryActions;
const {
	ebayGetItemData,
	ebayEditItemData,
	ebayCreateItemData,
	toggleEbayModal,
  userRecalculateEbayFees,
  ebayGetItemAspects,
  ebayGetPackageTypes,
  ebayListingLoadCategories,
} = ebayActions;

/**
 * Inventory table display
 */
class InventoryTable extends Component {
  state = {
    roiModalOpen: false,
		roiAmountType: '$',
		roiAmountNumber: 0,
    roiSKU: '',
    roiItem: null,
    ebayModalOpen: false,
    buyCostModalOpen: false,
		buyCostAmountNumber: 0,
    buyCostSKU: '',
    deleteItemModalOpen: false,
    delete_seller_sku: "",
    activateItemModalOpen: false,
    activate_seller_sku: "",
  }

  toggleDeleteItemModal = () => {
    this.setState({
      deleteItemModalOpen: !this.state.deleteItemModalOpen
    })
  }

  toggleActivateItemModal = () => {
    this.setState({
      activateItemModalOpen: !this.state.activateItemModalOpen
    })
  }

  componentDidMount(){
    if(
      this.props.userData
        && this.props.userData.is_ebay_enabled
        && this.props.userData.ebay_auth
        && checkIfEmployeeAndAllowed('EBAY_CROSS', this.props.userData)
    ){
      this.props.ebayGetPackageTypes();
    }
  }


  getNextPage = (state) => {
    this.props.fetchInventoryData(parseInt(state.page, 10) + 1, state.pageSize, state.sorted);
  }

	updateState = (target, e) => {
		this.setState({ [target]: e });
	}

  handlePurchaseDate = (date) => {
    //try to handle issue with multiple different formats of data
    //we are gathering here (batch and inventory side)
    if(date.includes('T')){
      date = date.split('T')[0];
      date = `${date} 00:00:00`
    } else if(date.includes(' ')){
      date = date.split(' ')[0];
      date = `${date} 00:00:00`
    }
    return moment(date).format('L')
  }

  deleteItem = () => {
    this.props.inventoryItemDelete({sku: this.state.delete_seller_sku});
    this.setState({"delete_seller_sku": ""})
    this.toggleDeleteItemModal();
  }

  activateItem = () => {
    this.props.inventoryItemActivate({sku: this.state.activate_seller_sku});
    this.setState({"activate_seller_sku": ""})
    this.toggleActivateItemModal();
  }

  generateTableColums = (showLabelsColumn, userData) => {
    let tableColumns = [
      {
        id: "item_name",
        accessor: "item_name",
        Header: "Product",
        headerClassName: "text-left",
        minWidth: 200,
        Cell: props => (
          <div className="d-flex">
            <div>
              <img
                //src={props.original.image_url ? props.original.image_url : "http://placehold.it/50x75"}
                src={`https://www.amazon.com/images/P/${props.original.asin1}.01._THUMBZZZ_.jpg`}
                width="25"
                alt={props.original.item_name}
                className="mr-1"
              />
            </div>
            <div>
              <div className="text-truncate" style={{ minWidth: 150 }}>{props.original.item_name}</div>
              <div>{`ASIN: ${props.original.asin1}`}</div>
            </div>
          </div>
        ),
      },
      {
        id: "seller_sku",
        Header: "SKU",
        accessor: "seller_sku",
        minWidth: 150,
        headerClassName: "text-left",
        style: { alignSelf: "center" }
      },
      {
        id: "quantity",
        Header: "Qty",
        accessor: "quantity",
        headerClassName: "text-right",
        className: "text-right",
        minWidth: 35
      },
      {
        id: "price",
        Header: "Price",
        accessor: "price",
        style: { alignSelf: "center" },
        headerClassName: "text-right",
        className: "text-right",
        minWidth: 70,
        Cell: props => (
          <div>{digitСonversion(props.value, "currency",
          this.props.internationalization_config.currency_code)}</div>
        )
      },
      {
        id: "buy_cost",
        Header: "Buy Cost",
        accessor: "buy_cost",
        className: "text-center",
        maxWidth: 90,
        sortable: false,
        Cell: props => (
          <div>
            <div>
              <InputGroup
                size="sm"
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    {digitСonversion(props.value, "currency",
                      this.props.internationalization_config.currency_code)}
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={
                      () => this.openBuyCostModal(
                        props.value ? props.value : 0,
                        props.original.seller_sku,
                        props.original.fulfillment_channel
                      )
                    }
                    disabled={this.props.is_inventory_updating}
                  >
                    <span className="icon icon-edit bg-primary"></span>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        )
      },
      {
        id: "sales_rank",
        Header: "Rank",
        accessor: "sales_rank",
        className: "text-center",
        style: { alignSelf: "center" },
        maxWidth: 75,
        Cell: props => (
          <div>{props.value ? digitСonversion(props.value, "rank") : ""}</div>
        )
      },
      {
        id: "item_condition",
        Header: "Condition",
        accessor: "item_condition",
        maxWidth: 95,
        Cell: props => (
          <div>{props.value ? getConditionNumAbbreviations(props.value) : ""}</div>
        )
      },
      {
        id: "ebay_listing",
				Header: "eBay Listing",
				headerClassName: "text-left",
        sortable: false,
        minWidth: 120,
        show: this.props.userData && this.props.userData.is_ebay_enabled && this.props.userData.ebay_auth,
				Cell: props => (
					!props.original.ebay_listing_id && props.original.quantity ?
						<Button
              size="sm"
							color="success"
							onClick={e => this.toggleEbayModalLocal(props.original)}
							disabled={this.props.ebay_list_item_data_loading}
						>Edit and List</Button>
          :
          <React.Fragment>
						<a
							href={ebay_generate_item_url(props.original.ebay_listing_id)}
							target="_blank"
							rel="noopener noreferrer"
						>
							{props.original.ebay_listing_id}
						</a>
            {/*
            {props.original.ebay_listing_id &&
              <Button size="sm" color="link" className="ml-2">
                <EditIcon size="16" style={{ verticalAlign: "baseline" }} />
              </Button>
            }
            */}
          </React.Fragment>
				)
      },
      {
        id: "roi",
        Header: "ROI",
        accessor: "roi",
        minWidth: 130,
        headerClassName: "text-left",
				sortable: false,
        Cell: props => (
					<InputGroup
						size="sm"
						className={`input-group-roi ${props.original.repricer_roi_number > 0 ? '' : 'unlocked'}`}
					>
            <InputGroupAddon addonType="prepend">
							<InputGroupText>
								{props.original.repricer_roi_number > 0 ? <span className="icon icon-lock"></span> : <span className="icon icon-unlock"></span> }
							</InputGroupText>
            </InputGroupAddon>
						{ props.original.repricer_roi_number > 0 ?
							<Input value={props.original.repricer_roi_type === '$' ? `$${props.original.repricer_roi_number}` : `${props.original.repricer_roi_number}%`} readOnly />
							: <Input value="" readOnly />
						}
            <InputGroupAddon addonType="append">
							<Button
								onClick={
									() => this.toggleRoiModalROI(
										props.original.repricer_roi_number,
										props.original.repricer_roi_type,
                    props.original.seller_sku,
                    props.original,
									)
								}
								disabled={this.props.is_inventory_updating}
							>
								<span className="icon icon-edit bg-primary"></span>
							</Button>
            </InputGroupAddon>
          </InputGroup>
        )
      },
      {
        id: "supplier",
        Header: "Supplier",
        accessor: "supplier",
        headerClassName: "text-left"
      },
      {
        id: "purchased_date",
        accessor: "purchased_date",
        Header: "Purchased On",
        headerClassName: "text-left",
        Cell: props => (
          <div>
            {
              props.value
              ? this.handlePurchaseDate(props.value)
              : ""
            }
          </div>
        ),
      },
    ];

    /*
     * as per travis suggestion https://app.clickup.com/t/t79cbh
     *
    if(this.props.userData && this.props.userData.repricerEnabled){
      tableColumns.push(
        {
        id: "repriced_price",
        Header: "Repriced Price",
        accessor: "repriced_price",
        className: "text-center",
        style: { alignSelf: "center" },
        Cell: props => (
          <div>
            {props.value ?
              digitСonversion(props.value, "currency",
                this.props.internationalization_config.currency_code)
              : ""
            }
          </div>
        )
        },
      )
      tableColumns.push(
        {
        id: "repriced_ts",
        Header: "Repriced TS",
        accessor: "repriced_ts",
        className: "text-center",
        minWidth: 150,
        Cell: props => (
          <div>
          {
            props.value
            ? momentDateTimeToLocalFormatConversion(props.value, true)
            : ""
          }
          </div>
        ),
        },
      )
    }
    */

    if (showLabelsColumn){
      tableColumns.push(
        {
          id: "labels",
          Header: "Labels",
          className: "text-center",
					sortable: false,
          Cell: props => (
            <Button color="primary" onClick={() => this.props.print(props.original)}>
              <span className="icon icon-print"></span> Print
            </Button>
          )
        },
      );
    }
    if (userData
        && userData.is_ebay_enabled
        && userData.ebay_auth
        && !checkIfEmployeeAndAllowed('EBAY_CROSS', userData)) {
          const index = tableColumns.findIndex(x => x.id === "ebay_listing");
          if (index !== undefined) {
            tableColumns.splice(index, 1);
          }
    }

    if(userData?.sp_api_token){
      tableColumns.push(
        {
          id: "actions",
          Header: "Actions",
          className: "text-center",
          maxWidth: 50,
          Cell: props => (
            <div>
              {props.original.closed &&
                <Button
                  color="link"
                  className="text-danger"
                  onClick={() => {
                    this.toggleActivateItemModal();
                    this.setState({"activate_seller_sku": props.original.seller_sku});
                  }}
                  disabled={this.props.item_activate_working}
                >
                  <span className="icon icon-edit bg-success"></span>
                </Button>
              }
              &nbsp;
              {!props.original.closed &&
                <Button
                  color="link"
                  className="text-danger"
                  onClick={() => {
                    this.toggleDeleteItemModal();
                    this.setState({"delete_seller_sku": props.original.seller_sku});
                  }}
                  disabled={this.props.item_delete_working}
                >
                  <span className="icon icon-trash bg-danger"></span>
                </Button>
              }
            </div>
          ),
        }
      )
    }

	  return tableColumns;
  }

  toggleRoiModal = () => {
    this.setState({
      roiModalOpen: !this.state.roiModalOpen
    })
  }

  toggleRoiModalROI = (n, t, sku, item) => {
    this.setState({
			roiModalOpen: !this.state.roiModalOpen,
			roiAmountType: t,
			roiAmountNumber: n,
			roiSKU: sku,
      roiItem: item,
    })
  }

	saveRoi = () => {
		let amount = parseFloat(this.state.roiAmountNumber).toFixed(2);
		if(amount < 0){ amount = 0}
		//if(this.state.roiAmountType === '%' && amount > 100){ amount = 100}

		const data = {
			sku: this.state.roiSKU,
			repricer_roi_number: parseFloat(amount),
			repricer_roi_type: this.state.roiAmountType,
      fulfillment_channel: this.state.roiItem.fulfillment_channel,
		}
		this.props.updateInventory(data);
		this.toggleRoiModal();
  }

  toggleAmountType = (newROI) => {
    this.setState({
      roiAmountType: newROI ? newROI : '$'
    })
  }

  toggleEbayModalLocal = (item) => {
		if(!this.props.ebay_create_modal_display){
			const data = {
				sku: item.seller_sku,
			}
			this.props.ebayGetItemData(data);
			this.props.toggleEbayModal();
		} else {
			this.props.toggleEbayModal();
		}
  }

  toggleBuyCostModal = () => {
    this.setState({
      buyCostModalOpen: !this.state.buyCostModalOpen
    })
  }

  openBuyCostModal = (value, sku, fulfillmentChannel) => {
    this.setState({
			buyCostModalOpen: true,
			buyCostAmountNumber: value ? value : 0,
      buyCostSKU: sku,
      buyCostFulfillmentChannel: fulfillmentChannel
    })
  }

	saveBuyCost = () => {
		let amount = parseFloat(this.state.buyCostAmountNumber).toFixed(2);
		if(amount < 0){ amount = 0}

		const data = {
			sku: this.state.buyCostSKU,
			buy_cost: parseFloat(amount),
      fulfillment_channel: this.state.buyCostFulfillmentChannel
		}
		this.props.updateInventory(data);
		this.toggleBuyCostModal();
  }

  render() {
    const { inventoryItems, showLabelsColumn, } = this.props;

    return (
      <React.Fragment>
        {inventoryItems ? (
          <React.Fragment>
            <ReactTable
              data={inventoryItems.results} // should default to []
              pages={inventoryItems.pages}
              columns={this.generateTableColums(showLabelsColumn, this.props.userData)}
              defaultPageSize={10}
              loading={this.props.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => { this.getNextPage(state) }}
              minRows={0}
            />
            <Modal isOpen={this.state.roiModalOpen} size="sm" toggle={this.toggleRoiModal}>
              <ModalHeader>Adjust ROI</ModalHeader>
              <ModalBody>
                <p>Adjust your ROI</p>
                <InputGroup>
									<Input
										type="number"
										value={this.state.roiAmountNumber}
										onChange={(e) => this.updateState('roiAmountNumber', e.target.value)}
									/>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      onClick={e => this.toggleAmountType('$')}
                      active={this.state.roiAmountType === '$'}
                    >$</Button>
                    <Button
                      onClick={e => this.toggleAmountType('%')}
                      active={this.state.roiAmountType === '%'}
                    >%</Button>
                  </InputGroupAddon>
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <Button onClick={this.toggleRoiModal}>Close</Button>
                <Button color="success" onClick={this.saveRoi}>Save</Button>
              </ModalFooter>
            </Modal>
            {this.state.buyCostModalOpen &&
              <Modal
                isOpen={this.state.buyCostModalOpen}
                size="sm"
                toggle={this.toggleBuyCostModal}
              >
                <ModalHeader>Adjust Buy Cost</ModalHeader>
                <ModalBody>
                  <p>Adjust Buy Cost</p>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                      >{this.props.internationalization_config.currency_identifier}</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      value={this.state.buyCostAmountNumber}
                      onChange={(e) => this.updateState('buyCostAmountNumber', e.target.value)}
                    />
                  </InputGroup>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={this.toggleBuyCostModal}>Close</Button>
                  <Button color="success" onClick={this.saveBuyCost}>Save</Button>
                </ModalFooter>
              </Modal>
            }
            <EbayListingModal
              isOpen={this.props.ebay_create_modal_display}
              toggle={this.toggleEbayModalLocal}
              internationalizationConfig={this.props.internationalization_config}
							ebay_list_item_data={this.props.ebay_list_item_data}
							ebay_list_item_data_loading={this.props.ebay_list_item_data_loading}
							ebay_created_item_data_executing={this.props.ebay_created_item_data_executing}
							ebayEditItemData={this.props.ebayEditItemData}
							ebayCreateItemData={this.props.ebayCreateItemData}
							userRecalculateEbayFees={this.props.userRecalculateEbayFees}
              ebayGetItemAspects={this.props.ebayGetItemAspects}
              ebay_item_aspects={this.props.ebay_item_aspects}
              ebay_item_aspects_loading={this.props.ebay_item_aspects_loading}
              ebay_package_types_valid={this.props.ebay_package_types_valid}
              ebay_package_types_valid_loding={this.props.ebay_package_types_valid_loding}
              ebayListingLoadCategories={this.props.ebayListingLoadCategories}
              ebay_listing_categories_loading={this.props.ebay_listing_categories_loading}
            />
            <DeleteDialog
              isOpen={this.state.deleteItemModalOpen}
              close={this.toggleDeleteItemModal}
              dialogTitle="Are you sure want to delete this item?"
              dialogText={`Item with SKU: ${this.state.delete_seller_sku} will be deleted from your inventory.`}
              confirmText="Yes, delete item."
              deleteItem={this.deleteItem}
            />
            <DeleteDialog
              isOpen={this.state.activateItemModalOpen}
              close={this.toggleActivateItemModal}
              dialogTitle="Are you sure want to activate this item?"
              dialogText={`Item with SKU: ${this.state.activate_seller_sku} will be activated.`}
              confirmText="Yes, activate item."
              deleteItem={this.activateItem}
              cancelText="No"
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
InventoryTable.propTypes = {
  inventoryItems: PropTypes.object,
  changeInventoryTableFieldData: PropTypes.func.isRequired,
  showLabelsColumn: PropTypes.bool,
  print: PropTypes.func,
  fetchInventoryData: PropTypes.func,
  loading: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
    is_inventory_updating: state.Inventory.get("is_inventory_updating"),
		userData: state.Auth.get("userData"),
		ebay_list_item_data: state.Ebay.get("ebay_list_item_data"),
		ebay_list_item_data_loading: state.Ebay.get("ebay_list_item_data_loading"),
		ebay_create_modal_display: state.Ebay.get("ebay_create_modal_display"),
		ebay_created_item_data_executing: state.Ebay.get("ebay_created_item_data_executing"),
		ebay_item_aspects_loading: state.Ebay.get("ebay_item_aspects_loading"),
		ebay_item_aspects: state.Ebay.get("ebay_item_aspects"),
		ebay_package_types_valid: state.Ebay.get("ebay_package_types_valid"),
		ebay_package_types_valid_loding: state.Ebay.get("ebay_package_types_valid_loding"),
    ebay_listing_categories_loading: state.Ebay.get("ebay_listing_categories_loading"),
    item_delete_working: state.Ebay.get("item_delete_working"),
    ebay_direct_listing_item: state.Ebay.get("ebay_direct_listing_item"),
    item_activate_working: state.Ebay.get("item_activate_working"),
  }),
	{
		updateInventory,
		ebayGetItemData,
		ebayEditItemData,
		ebayCreateItemData,
		toggleEbayModal,
    userRecalculateEbayFees,
    ebayGetItemAspects,
    ebayGetPackageTypes,
    ebayListingLoadCategories,
    inventoryItemDelete,
    inventoryItemActivate,
	}
)(InventoryTable);
