import React, { Component } from "react";
import { Card, CardBody, Row, CardTitle, Col } from "reactstrap";
import { connect } from "react-redux";
import adminActions from "../../../../redux/admin/actions";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	Legend,
} from 'recharts';
import chart_config from "../../main_dashboard_v3/chart_config";

const { batchStats } = adminActions;

/**
 * Stats about number of created batches per week
 *
 * @version 1.0.0
 * @author [bojan]
 */
class AdminBatchCreatedChart extends Component {

	UNSAFE_componentWillMount(){
		this.props.batchStats();
	}

	numberFormatter = (value) => {
		return value.toLocaleString();
	}

	render() {
  	const { batchStatsData } = this.props;

    return (
		<Card>
			<CardBody>
				<CardTitle>Batches Completed In Last 3 Months</CardTitle>
				<Row>
					<Col>
					{batchStatsData ? (
						<ResponsiveContainer width='100%' aspect={4.0/1.0}>
							<BarChart
								data={batchStatsData["created_batches_per_week"]}
								margin={{top: 20, right: 30, left: 20, bottom: 5,}}
							>
								<CartesianGrid
									vertical={false}
									stroke={chart_config.cartesianStrokeColor}
								/>
								<XAxis
									dataKey="week"
									axisLine={false}
									tickLine={false}
									stroke={chart_config.axisFontColor}
									style={{
										fontSize: chart_config.axisFontSize
									}}
								/>
								<YAxis
									yAxisId="left"
									axisLine={false}
									tickLine={false}
									stroke={chart_config.axisFontColor}
									style={{
										fontSize: chart_config.axisFontSize
									}}
									tickFormatter={this.numberFormatter}
								/>
								<YAxis
									yAxisId="right"
									orientation="right"
									axisLine={false}
									tickLine={false}
									stroke={chart_config.axisFontColor}
									style={{
										fontSize: chart_config.axisFontSize
									}}
									tickFormatter={this.numberFormatter}
								/>
								<Tooltip 
									cursor={false} 
									wrapperStyle={{ color: '#fff', backgroundColor: '#000', borderColor: '#000' }} 
									formatter={this.numberFormatter} 
								/>
								<Legend />
								<Bar name="Product Count" yAxisId="left" barSize={15} dataKey="item_count" fill="#d8d8d8" minPointSize={2} />
								<Bar name="Products Repriced" yAxisId="left" barSize={15} dataKey="product_repriced_count" fill="#f79c06" minPointSize={2} />
								<Bar name="Batch Count" yAxisId="right" barSize={15} dataKey="count" fill="#82ca9d" minPointSize={2} />
								<Bar name="MF Batch Count" yAxisId="right" barSize={15} dataKey="batch_mf_count" fill="#ffcc16" minPointSize={2} />
								<Bar name="eBay Product Count" yAxisId="right" barSize={15} dataKey="ebay_count" fill="#0064d2" minPointSize={2} />
							</BarChart>
						</ResponsiveContainer>
						) : (null)
					}
					</Col>
				</Row>
			</CardBody>
		</Card>
    );
  }
}

export default connect(
  state => {
    return {
		batchStatsData: state.Admin.get('batchStatsData'),
    };
  },
  {
	batchStats,
  }
)(AdminBatchCreatedChart);
