export const fakeData = {
  "aggregated_report": {
    "mfOrderCount": 0,
    "roi": 55.93476190270811,
    "shippingCredits": 659.3000000000001,
    "lostInboundTotal": 0,
    "mfSellingFees": 0,
    "otherTransactionFeeRefunds": 0,
    "totalRefunds": 36,
    "fbaDisposalFees": -1.35,
    "customerReturnQty": 5,
    "costOfGoodsReturned": 0,
    "costOfGoodsDelivered": 0,
    "fbaOrderRevenue": 14908.72999999986,
    "customerReturnTotal": 468.61000000000007,
    "totalTransactionFees": -2897.900000000013,
    "chargebackTotal": 0,
    "dateStart": "Apr 14, 2015",
    "dateEndYear": 2015,
    "longTermStorageFees": 0,
    "totalInventoryCredits": 560.6400000000001,
    "dateStartYear": 2015,
    "inboundShippingFees": -1715.4199999999992,
    "miscExpenses": 505,
    "otherTotal": 7.75,
    "rowsMissingBuyCostOrSupplier": [
      {
        "sku": "1003582933",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368504",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584059",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "2V-D2UM-AZA4",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451461",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "IM-QXL0-GHDK",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002683130",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224602",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002508891",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427841",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003042837",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555441",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002640897",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "N5-P205-IIGF",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "EV-9ABP-RBQS",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486085",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368491",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486087",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "BC-1OZ2-5E2L",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002487639",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603121",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0E-9QJC-OR2J",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002517307",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555963",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003031644",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003782977",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461635",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543362",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603069",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003769502",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002477435",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603305",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002460781",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469489",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436752",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "UJ-D7KB-D7PC",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006181",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002410628",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "VT-OXQU-G4U3",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428575",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003752446",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663175",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543423",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537045",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715310",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451854",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491112",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914221",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003012196",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491118",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555815",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003012151",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491054",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "QI-OU4E-18AR",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605316",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "LR-W5IH-VZ3Q",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "6B-7HIR-DB3U",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554611",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605318",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516934",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "U5-Y1TM-6S14",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451720",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224778",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "VT-VBVF-G5H2",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564767",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469762",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554639",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461223",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663826",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "7Z-YTH1-MO80",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551115",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584338",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242249",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003144959",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "UM-HEX2-WTFX",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "BM-2BOB-S7VJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605225",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186990",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "LR-Y2TL-VZB3",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565184",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003321678",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187347",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543302",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224909",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186682",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914689",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564951",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002527593",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WP-4R1Z-CMAZ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "4C-JF55-H4NB",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0Z-JVS3-YEQ3",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "Q0-OJJ2-B6KS",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "Q3-WEFG-USKQ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224761",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491385",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486287",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002640919",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451934",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "O8-WXGA-I7U3",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003083223",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451252",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002650652",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584246",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427883",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360987",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002728450",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "22-KX18-Y40Z",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555567",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "DE-4MUF-L6HT",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469587",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003433729",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002342482",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003054337",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "LR-BU0U-VZD7",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486210",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486198",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486195",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "KA-A1E7-PU20",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002490990",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002477460",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242450",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108088",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419763",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564837",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537000",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427813",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "22-6UPZ-Y46N",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "CM-W7I0-8B04",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002352467",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486072",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002913194",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914265",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555856",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003782784",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002641882",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1A-FIFO-L8J9",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565330",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1004188486",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "D0-4BWW-ER22",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603337",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550898",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002527541",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470062",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603300",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006171",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543365",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550940",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002517053",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002410672",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003120525",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003321746",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565204",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "L3-Q3XT-2PQY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "H8-Q28U-TY0M",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469726",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491128",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "D3-7CDF-YCVW",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436835",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "A2-FPOE-2GYW",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360801",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469729",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "6I-FGHK-GJ6A",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002630500",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469507",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470057",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335394",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516904",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002380433",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003782844",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003032713",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002380438",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003144982",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451688",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469897",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003032308",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003120626",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428568",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002490977",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002622658",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605294",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003575331",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "TY-VKTW-3JU9",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564756",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002342682",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663348",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003083268",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "OT-5UUJ-RVCC",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002546974",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555520",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470112",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003769510",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470103",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "G5-IF79-U8YJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "UJ-C745-D7S0",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003306179",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002380447",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "9G-24UY-ST11",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003305855",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003867924",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003474493",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436807",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663869",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335401",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002640868",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451552",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002593691",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "13-WXO2-I0QN",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582826",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187296",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584105",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715391",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "9K-GLOK-CF62",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002640923",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003107999",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427853",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427852",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "CF-9GH1-53BV",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002400174",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002487604",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "DB-UZ9O-1KSP",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603094",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427913",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584293",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715408",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555898",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555952",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537079",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555956",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600945",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242317",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "MN-F23X-SGSN",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003054342",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002477426",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368011",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002901287",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002367903",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "NU-KQVE-BS0E",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "2K-AU9I-O5PS",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451523",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003321707",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914544",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360848",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003121151",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449269",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461824",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "U8-PFTC-QDWT",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715306",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451489",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427824",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "DB-XZ8G-1KYC",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491164",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600980",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600981",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600983",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002901596",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491169",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555773",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "NQ-4FQF-S5SC",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603201",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451943",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002477452",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543300",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914602",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491053",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006079",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428524",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "7Z-A1A5-MO81",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451860",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550915",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564717",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003460860",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461783",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449318",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550859",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "7S-4XVW-JGI8",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "7E-BU6I-D0OJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663934",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002535575",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419784",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003144880",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003042826",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002547014",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "G5-K28K-U8RJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "6M-U83I-056G",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003121107",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003269615",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108163",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187406",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436648",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486110",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108169",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368494",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "UM-X6B6-WTLL",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108154",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186913",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "F9-6ODS-XRAF",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427899",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605319",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491065",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187394",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002450930",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427896",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451247",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551173",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1004215779",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584252",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242297",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "3G-3HQ8-KN52",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360732",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491305",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "P0-XECC-V321",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003868315",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002664013",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002517033",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187326",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002664018",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555487",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224477",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002901629",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "7Z-0Y0K-MOAU",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486099",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002583982",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451569",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451565",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564919",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003769270",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663758",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451445",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582851",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WS-BYGF-W81P",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419777",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537010",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584173",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427867",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715459",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003107946",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486068",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486064",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555864",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486062",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002913187",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002574663",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "OQ-TX0B-89EL",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0L-SLJ7-RYZD",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368272",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "AX-IMW5-YYE0",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002517322",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "CT-GK75-BJ4A",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582626",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002477416",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1004249962",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003867892",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565224",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002731327",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002641871",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002901575",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564696",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368594",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715530",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451176",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428956",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914325",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491133",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491135",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491134",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600950",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006209",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663902",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002535558",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "04-9TA1-1XD5",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "TU-0BJJ-JYB3",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "O1-MH7I-EZYO",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003782870",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516859",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002914638",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470205",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002400356",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461729",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002400216",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002490968",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451613",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002461374",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428577",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002509113",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002973312",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "W0-USX7-JCWK",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335313",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564744",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335318",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003752488",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663807",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002630285",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002973137",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002547048",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "I8-CDHD-A1L6",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555695",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003575488",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469560",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002575187",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554744",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006075",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003474215",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "HF-45OJ-X650",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003493697",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002556020",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002487667",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242003",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451014",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224433",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584364",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335219",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451408",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663436",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002487592",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "RD-FIUI-XPQ8",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451379",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "SR-M8EI-K8NB",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002361202",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715413",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335445",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555949",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002361206",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002361208",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003433740",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491351",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "8H-ZKKL-CPZE",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003269915",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550870",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486239",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491294",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537126",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605238",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603049",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600960",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002641993",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "W7-JN1F-MKFE",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224574",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582967",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451537",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551261",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449184",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564792",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449365",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451302",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451872",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564812",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451306",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436887",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003270087",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486051",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "H1-2K69-QR20",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486059",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1004226541",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603034",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "8R-V6X6-ZJOC",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002641861",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "HQ-VTKS-JZTP",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "LR-828C-VZAY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002731256",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002527527",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470329",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003752312",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002973297",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002509012",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006152",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002410699",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002728858",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002820315",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "26-YQPN-HQ1I",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451398",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564707",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002601005",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469708",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002601000",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002564702",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469705",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002367915",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002601009",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "C8-7Q4H-1VKN",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1A-VSRW-L8AO",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663927",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003031989",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469043",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554704",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "9Y-XU2O-IV15",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "LD-P78C-PJEI",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002973151",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537843",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516966",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555590",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470149",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451056",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003590959",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436657",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003012301",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428582",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "UM-04SU-WTFY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002728558",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "B5-UZUD-269F",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186923",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419779",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428544",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555765",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715515",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555429",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554713",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002450920",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002535633",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002461265",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002487656",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584266",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551164",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224492",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551169",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428992",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006240",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469893",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486278",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582856",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "GU-QM4E-NIL0",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003321584",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663845",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187091",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461055",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451574",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555580",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108146",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "C4-GF61-I9MY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419780",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419786",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002536996",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WL-9ARO-T0D9",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537143",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002536999",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451188",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486097",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002832909",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427861",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491035",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603137",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002508116",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002630110",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002604999",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461022",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555978",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0L-0X3S-RYXU",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002367921",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565351",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565219",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565210",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600934",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "13-B66X-I0QR",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584528",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1A-7W2H-LA7A",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "O1-GM01-EZWO",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002820356",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002833003",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002428455",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449201",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486284",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002630211",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "2D-T4Z3-KXUY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1004145237",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002832870",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002729500",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "9K-7AT8-CFAB",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003474502",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555801",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002460768",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491102",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002461367",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555756",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002550984",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003433788",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "BM-09R4-S7OL",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427914",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002728593",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003867859",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554620",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "AN-U4C4-C4GY",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "F2-JAY7-UJNM",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451098",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WS-D6A0-W8B5",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451167",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543283",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486082",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "HQ-18B6-JZY7",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469812",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "RO-M3RK-KJI4",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003321610",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003042488",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451916",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0I-3CKH-8D0X",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "QI-NUVK-181X",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224455",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555508",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551125",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002642016",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663368",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543319",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003940093",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554752",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470130",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461617",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003752324",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003461579",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003867904",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002508896",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003591024",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486133",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224852",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002461312",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663356",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516474",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186978",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002546864",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "KV-HZ0V-ZHVN",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003224524",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002461031",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002683146",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368255",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003242310",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002833010",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WE-WTVU-PSAT",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "2V-W07A-AZLJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002527550",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002535606",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537106",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555470",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003335190",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605026",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002551195",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "8A-NIHX-9I1Y",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002361230",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360618",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486221",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003187220",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002925962",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427878",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486184",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002517194",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002360658",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600878",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600970",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "MD-TKPL-5MYO",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002342241",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003591244",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003727775",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "3R-KAJN-7GY6",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436768",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584158",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108097",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002641972",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584019",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "IF-CNQL-D9DQ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451867",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605261",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "4Q-0FR6-NKBJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451862",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003241926",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002728618",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002419758",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002352373",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715473",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491060",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555843",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "6E-IGZD-WX75",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002832931",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003032447",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002575403",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543413",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002527538",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451923",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469603",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002451881",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470022",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470257",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002622541",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002603222",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003769473",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002470310",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "P4-Q0A3-EP7K",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002436718",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "8A-E68O-9HSL",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "L3-D22R-2PLE",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002543329",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002554824",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584306",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003006144",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003449333",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002537823",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108006",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516892",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469733",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "WI-AHEY-9EJA",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002491390",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715355",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486190",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003460849",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003306359",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002663528",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "41-HGEK-UAQP",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368507",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469515",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584309",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368609",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002469697",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "4N-FG1I-3YJA",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002565338",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002650499",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003582689",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003108141",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "BQ-TIXK-BTT8",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003591346",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003473986",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "H5-IV1X-ACBO",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605281",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002516737",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "41-HJ42-UATT",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1003186837",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002715503",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002729011",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "5T-QNLP-N9DN",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002605068",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002584273",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002600958",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002975857",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002575289",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002555532",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "9N-8PB7-W181",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "6I-IIIQ-GJ8Q",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "N1-24GE-YWJJ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002913139",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002604914",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486264",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486261",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0P-J0H8-BKX6",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "5Q-AEQB-3NOQ",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002427838",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368524",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "QP-IYI4-4G91",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002368499",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "1002486035",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "0S-D2Q1-V6VH",
        "supplier": null,
        "buy_cost": 0
      },
      {
        "sku": "5M-H559-K1TM",
        "supplier": null,
        "buy_cost": 0
      }
    ],
    "fbaOrderCount": 1054,
    "damagedWarehouseTotal": 9.600000000000001,
    "giftWrapCreditRefunds": 0,
    "dateEnd": "Dec 31, 2015",
    "dateEndMonth": 12,
    "totalMiscellaneous": 443.68,
    "fbaTransactionFeeRefunds": 16.830000000000002,
    "mfLabelPurchases": 0,
    "promotionalRebateRefunds": 2.19,
    "grossIncome": 14472.429999999858,
    "lostWarehouseQty": 9,
    "netProfit": 5191.34999999986,
    "shippingCreditRefunds": -19.02,
    "mfOrderRevenue": 0,
    "totalRefundCost": -1440.6200000000001,
    "dailyNetProfit": 19.890229885056936,
    "totalServiceFees": -1567.3499999999992,
    "promotionalRebates": -230.20000000000005,
    "guaranteeClaimsTotal": 0,
    "fbaTransactionFees": -2914.7300000000128,
    "costOfGoodsAdjusted": 0,
    "sellingFeeRefunds": 204.80999999999995,
    "damagedWarehouseQty": 4,
    "subscriptionFees": 0,
    "fbaSellingFees": -3155.199999999986,
    "inventoryStorageFees": -148.67000000000002,
    "otherInventoryFees": 0,
    "otherTransactionFees": 0,
    "fbaLabelingPrepFees": 0,
    "otherServiceFees": -1567.3499999999992,
    "lostInboundQty": 0,
    "totalInventoryFees": -150.02,
    "cogs": 0,
    "totalShippingFees": -1715.4199999999992,
    "totalExpenses": -9281.079999999998,
    "totalSellingFees": -2950.3899999999862,
    "giftWrapCredits": 31.41000000000001,
    "fbaReturnFees": 0,
    "totalExpensesWithoutCogs": -9281.079999999998,
    "otherQty": 2,
    "dateStartMonth": 4,
    "mfLabelRefunds": 0,
    "lostWarehouseTotal": 74.68
  },
  "upload_ids": [
    71
  ],
  "reports_by_supplier": {
    "Fees, Adjustments, and Transfers": {
      "roi": -100.85615490606766,
      "netProfit": -3462.1799999999985,
      "totalExpenses": -3432.7899999999986,
      "grossIncome": -29.39
    },
    "N/A": {
      "roi": 147.9668415895905,
      "netProfit": 8653.52999999986,
      "totalExpenses": -5848.289999999999,
      "grossIncome": 14501.81999999986
    }
  },
  "misc_expenses_mapping": {
    "miscExpense_test2": {
      "name": "test2"
    },
    "miscExpense_test": {
      "name": "test"
    }
  },
  "reports_by_supplier_and_date": {
    "Fees, Adjustments, and Transfers": {
      "2015-11-18": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.15,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-18",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -0.15,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0005747126436781609,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.15,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.15,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.15,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-18": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-18",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -13.1,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.050191570881226055,
        "totalServiceFees": -13.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -13.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -13.1,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-19": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -64.58,
        "otherTotal": 0,
        "label": "2015-07-19",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -64.58,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.24743295019157088,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -64.58,
        "totalExpenses": -64.58,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -64.58,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-16": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -91.5,
        "otherTotal": 0,
        "label": "2015-07-16",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -114.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.4386973180076628,
        "totalServiceFees": -23,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -23,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -91.5,
        "totalExpenses": -114.5,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -114.5,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-17": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-17",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06973180076628352,
        "totalServiceFees": -18.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -18.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -64.2,
        "otherTotal": 0,
        "label": "2015-07-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -149.9,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.5743295019157089,
        "totalServiceFees": -85.7,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -85.7,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -64.2,
        "totalExpenses": -149.9,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -149.9,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-15": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -31.240000000000002,
        "otherTotal": 0,
        "label": "2015-07-15",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -31.240000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.11969348659003833,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -31.240000000000002,
        "totalExpenses": -31.240000000000002,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -31.240000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-12": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -63.82000000000001,
        "otherTotal": 0,
        "label": "2015-07-12",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -63.82000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.24452107279693489,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -63.82000000000001,
        "totalExpenses": -63.82000000000001,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -63.82000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-13": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -33.91,
        "otherTotal": 0,
        "label": "2015-07-13",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -33.91,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.12992337164750956,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -33.91,
        "totalExpenses": -33.91,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -33.91,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-17": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -48.07,
        "otherTotal": 0,
        "label": "2015-11-17",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -48.07,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.18417624521072798,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -48.07,
        "totalExpenses": -48.07,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -48.07,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-11": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -10.21,
        "otherTotal": 0,
        "label": "2015-07-11",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -10.590000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.040574712643678165,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -0.38,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.38,
        "cogs": 0,
        "totalShippingFees": -10.21,
        "totalExpenses": -10.590000000000002,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.590000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-10": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -11.24,
        "otherTotal": 0,
        "label": "2015-09-10",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -15.440000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.05915708812260537,
        "totalServiceFees": -4.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -4.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -11.24,
        "totalExpenses": -15.440000000000001,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -15.440000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-20": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -17.64,
        "otherTotal": 0,
        "label": "2015-12-20",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.64,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06758620689655173,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -17.64,
        "totalExpenses": -17.64,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.64,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-23": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.4,
        "otherTotal": 0,
        "label": "2015-12-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -14.4,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.05517241379310345,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.4,
        "totalExpenses": -14.4,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -14.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-22": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.15,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-22",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -0.15,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0005747126436781609,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.15,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.15,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.15,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-14": {
        "mfOrderCount": 0,
        "roi": "INF",
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": 0,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 0,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 0,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-16": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-16",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.9,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.018773946360153258,
        "totalServiceFees": -4.9,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -4.9,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.9,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.9,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-17": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -18.56,
        "otherTotal": 0,
        "label": "2015-09-17",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.56,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07111111111111111,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -18.56,
        "totalExpenses": -18.56,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.56,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-06-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.30268199233716475,
        "totalServiceFees": -79,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -79,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -79,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -79,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-08": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -10.81,
        "otherTotal": 0,
        "label": "2015-07-08",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -10.81,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.041417624521072796,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -10.81,
        "totalExpenses": -10.81,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.81,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-05": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -21.020000000000003,
        "otherTotal": 0,
        "label": "2015-07-05",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -21.020000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.08053639846743296,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -21.020000000000003,
        "totalExpenses": -21.020000000000003,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.020000000000003,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-04": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -42.3,
        "otherTotal": 0,
        "label": "2015-07-04",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -42.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.16206896551724137,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -42.3,
        "totalExpenses": -42.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -42.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-07": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -38.94,
        "otherTotal": 0,
        "label": "2015-07-07",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -38.94,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14919540229885056,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -38.94,
        "totalExpenses": -38.94,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -38.94,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-27": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.15,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-27",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -0.15,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0005747126436781609,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.15,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.15,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.15,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-01": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -15.85,
        "otherTotal": 0,
        "label": "2015-07-01",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -15.85,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.060727969348659004,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -15.85,
        "totalExpenses": -15.85,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -15.85,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -24.59,
        "otherTotal": 0,
        "label": "2015-07-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -24.59,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.09421455938697318,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -24.59,
        "totalExpenses": -24.59,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.59,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -59.12,
        "otherTotal": 0,
        "label": "2015-12-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -86.92,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.33302681992337163,
        "totalServiceFees": -27.8,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -27.8,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -59.12,
        "totalExpenses": -86.92,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -86.92,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-06": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-06",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -27.06,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10367816091954023,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -27.06,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -27.06,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -27.06,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -27.06,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-05": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-05",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06628352490421456,
        "totalServiceFees": -17.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -17.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-03": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.15,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -6.62,
        "otherTotal": 0,
        "label": "2015-09-03",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -6.7700000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.025938697318007665,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.15,
        "cogs": 0,
        "totalShippingFees": -6.62,
        "totalExpenses": -6.7700000000000005,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -6.7700000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -8.49,
        "otherTotal": 0,
        "label": "2015-09-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -49.080000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.18804597701149428,
        "totalServiceFees": -40.59,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -40.59,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -8.49,
        "totalExpenses": -49.080000000000005,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -49.080000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-01": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -37.63,
        "otherTotal": 0,
        "label": "2015-09-01",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -37.63,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14417624521072797,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -37.63,
        "totalExpenses": -37.63,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.63,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -55.1,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.2111111111111111,
        "totalServiceFees": -55.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -55.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -55.1,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -55.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-04-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-04-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.30268199233716475,
        "totalServiceFees": -79,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -79,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -79,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -79,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-19": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -16.119999999999997,
        "otherTotal": 0,
        "label": "2015-12-19",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -16.119999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06176245210727968,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -16.119999999999997,
        "totalExpenses": -16.119999999999997,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -16.119999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-08": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-08",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -10.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.04061302681992337,
        "totalServiceFees": -10.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -10.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -19.73,
        "otherTotal": 0,
        "label": "2015-07-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -28.53,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10931034482758621,
        "totalServiceFees": -8.8,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -8.8,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -19.73,
        "totalExpenses": -28.53,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.53,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-31": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -4.89,
        "otherTotal": 0,
        "label": "2015-07-31",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.89,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.018735632183908044,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -4.89,
        "totalExpenses": -4.89,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.89,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -60,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.22988505747126436,
        "totalServiceFees": -60,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -60,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -60,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -60,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-17": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -37.050000000000004,
        "otherTotal": 0,
        "label": "2015-08-17",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -37.050000000000004,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14195402298850576,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -37.050000000000004,
        "totalExpenses": -37.050000000000004,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.050000000000004,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-12": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.3,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -82.01,
        "otherTotal": 0,
        "label": "2015-11-12",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -82.31,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.3153639846743295,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.3,
        "cogs": 0,
        "totalShippingFees": -82.01,
        "totalExpenses": -82.31,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -82.31,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -39.99,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1532183908045977,
        "totalServiceFees": -39.99,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -39.99,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.99,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.99,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.38,
        "otherTotal": 0,
        "label": "2015-10-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -54.370000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.20831417624521076,
        "totalServiceFees": -39.99,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -39.99,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.38,
        "totalExpenses": -54.370000000000005,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -54.370000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-07": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-07",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -41.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1582375478927203,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -41.3,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -41.3,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -41.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -41.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-16": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -11.9,
        "otherTotal": 0,
        "label": "2015-12-16",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -11.9,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.04559386973180077,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -11.9,
        "totalExpenses": -11.9,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -11.9,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-10": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.49,
        "otherTotal": 0,
        "label": "2015-07-10",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -37.19,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14249042145593868,
        "totalServiceFees": -22.7,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -22.7,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.49,
        "totalExpenses": -37.19,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.19,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-19": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -6.21,
        "otherTotal": 0,
        "label": "2015-06-19",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -6.21,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.02379310344827586,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -6.21,
        "totalExpenses": -6.21,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -6.21,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-18": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -4.77,
        "otherTotal": 0,
        "label": "2015-06-18",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.77,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.018275862068965514,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -4.77,
        "totalExpenses": -4.77,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.77,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-24": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-24",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -73.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.28045977011494255,
        "totalServiceFees": -73.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -73.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -73.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -73.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-22": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -8.6,
        "otherTotal": 0,
        "label": "2015-10-22",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -8.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.03295019157088123,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -8.6,
        "totalExpenses": -8.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -8.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-21": {
        "mfOrderCount": 0,
        "roi": "INF",
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-21",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": 0,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 0,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 0,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-20": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-20",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -9.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.035632183908045977,
        "totalServiceFees": -9.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -9.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-27": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -57.17,
        "otherTotal": 0,
        "label": "2015-07-27",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -76.17,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.29183908045977014,
        "totalServiceFees": -19.000000000000004,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -19.000000000000004,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -57.17,
        "totalExpenses": -76.17,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -76.17,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-26": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -44.66,
        "otherTotal": 0,
        "label": "2015-07-26",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -44.66,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1711111111111111,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -44.66,
        "totalExpenses": -44.66,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -44.66,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-25": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -24.46,
        "otherTotal": 0,
        "label": "2015-07-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -30.060000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.11517241379310346,
        "totalServiceFees": -5.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -5.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -24.46,
        "totalExpenses": -30.060000000000002,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -30.060000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-24": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-24",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.1,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.015708812260536397,
        "totalServiceFees": -4.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -4.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.1,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-23": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.27,
        "otherTotal": 0,
        "label": "2015-07-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.97,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07268199233716474,
        "totalServiceFees": -4.7,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -4.7,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.27,
        "totalExpenses": -18.97,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.97,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-22": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -33.42,
        "otherTotal": 0,
        "label": "2015-07-22",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -40.52,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.15524904214559387,
        "totalServiceFees": -7.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -7.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -33.42,
        "totalExpenses": -40.52,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -40.52,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-21": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -22.439999999999998,
        "otherTotal": 0,
        "label": "2015-07-21",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -24.339999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0932567049808429,
        "totalServiceFees": -1.9,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -1.9,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -22.439999999999998,
        "totalExpenses": -24.339999999999996,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.339999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-20": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-20",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -23.1,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.08850574712643679,
        "totalServiceFees": -23.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -23.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.1,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-29": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -21.7,
        "otherTotal": 0,
        "label": "2015-07-29",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -21.7,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.08314176245210728,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -21.7,
        "totalExpenses": -21.7,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.7,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-28": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.83,
        "otherTotal": 0,
        "label": "2015-07-28",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.43,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07061302681992337,
        "totalServiceFees": -3.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -3.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.83,
        "totalExpenses": -18.43,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.43,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-25": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -21.959999999999997,
        "otherTotal": 0,
        "label": "2015-06-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -21.959999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.08413793103448275,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -21.959999999999997,
        "totalExpenses": -21.959999999999997,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.959999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-26": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -6.24,
        "otherTotal": 0,
        "label": "2015-06-26",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -6.24,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.023908045977011495,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -6.24,
        "totalExpenses": -6.24,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -6.24,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-27": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -46.6,
        "otherTotal": 0,
        "label": "2015-06-27",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -46.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.178544061302682,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -46.6,
        "totalExpenses": -46.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -46.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-20": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -17.08,
        "otherTotal": 0,
        "label": "2015-06-20",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.08,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06544061302681992,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -17.08,
        "totalExpenses": -17.08,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.08,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-21": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -9.91,
        "otherTotal": 0,
        "label": "2015-06-21",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -9.91,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.03796934865900383,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -9.91,
        "totalExpenses": -9.91,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.91,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-23": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -5.81,
        "otherTotal": 0,
        "label": "2015-06-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -5.81,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.02226053639846743,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -5.81,
        "totalExpenses": -5.81,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -5.81,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-28": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -37.519999999999996,
        "otherTotal": 0,
        "label": "2015-06-28",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -37.519999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14375478927203064,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -37.519999999999996,
        "totalExpenses": -37.519999999999996,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.519999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-29": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -7.91,
        "otherTotal": 0,
        "label": "2015-06-29",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -7.91,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.030306513409961686,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -7.91,
        "totalExpenses": -7.91,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -7.91,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-28": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -13.17,
        "otherTotal": 0,
        "label": "2015-08-28",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -40.27,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.15429118773946363,
        "totalServiceFees": -27.1,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -27.1,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -13.17,
        "totalExpenses": -40.27,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -40.27,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-23": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -3.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.012260536398467433,
        "totalServiceFees": -3.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -3.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -3.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -3.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-20": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-20",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -27.4,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10498084291187738,
        "totalServiceFees": -27.4,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -27.4,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -27.4,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -27.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-21": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-21",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -37.4,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.14329501915708812,
        "totalServiceFees": -37.4,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -37.4,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -37.4,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-26": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-26",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -50.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.19386973180076628,
        "totalServiceFees": -50.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -50.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -50.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -50.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-27": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -4.28,
        "otherTotal": 0,
        "label": "2015-08-27",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.28,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.016398467432950193,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -4.28,
        "totalExpenses": -4.28,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.28,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-24": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-24",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.900000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07241379310344828,
        "totalServiceFees": -18.900000000000002,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -18.900000000000002,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.900000000000002,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.900000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-25": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -7.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.027969348659003832,
        "totalServiceFees": -7.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -7.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -7.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -7.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-13": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -13.32,
        "otherTotal": 0,
        "label": "2015-10-13",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -13.32,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.05103448275862069,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -13.32,
        "totalExpenses": -13.32,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.32,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -4.26,
        "otherTotal": 0,
        "label": "2015-06-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -4.26,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.01632183908045977,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -4.26,
        "totalExpenses": -4.26,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.26,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-13": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -22.45,
        "otherTotal": 0,
        "label": "2015-09-13",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -22.45,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.08601532567049808,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -22.45,
        "totalExpenses": -22.45,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -22.45,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-06": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -24.46,
        "otherTotal": 0,
        "label": "2015-08-06",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -24.46,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.09371647509578544,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -24.46,
        "totalExpenses": -24.46,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.46,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-04-25": {
        "mfOrderCount": 0,
        "roi": "INF",
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-04-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": 0,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 0,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 0,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-27": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -13.32,
        "otherTotal": 0,
        "label": "2015-12-27",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -13.32,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.05103448275862069,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -13.32,
        "totalExpenses": -13.32,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.32,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-31": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-31",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06590038314176246,
        "totalServiceFees": -17.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -17.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -7.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.028735632183908046,
        "totalServiceFees": -7.5,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -7.5,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -7.5,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -7.5,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-26": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": -0.44999999999999996,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-26",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -0.44999999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0017241379310344825,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -0.44999999999999996,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.44999999999999996,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.44999999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -31.700000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.12145593869731802,
        "totalServiceFees": -31.700000000000003,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -31.700000000000003,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -31.700000000000003,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -31.700000000000003,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-08": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-08",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -32,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.12260536398467432,
        "totalServiceFees": -32,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -32,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -32,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -32,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-09": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -25.240000000000002,
        "otherTotal": 0,
        "label": "2015-08-09",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -26.540000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10168582375478928,
        "totalServiceFees": -1.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -1.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -25.240000000000002,
        "totalExpenses": -26.540000000000003,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.540000000000003,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-11": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -16.29,
        "otherTotal": 0,
        "label": "2015-10-11",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -16.29,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06241379310344827,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -16.29,
        "totalExpenses": -16.29,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -16.29,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-04": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-04",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -86.79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.33252873563218394,
        "totalServiceFees": -86.79,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -86.79,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -86.79,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -86.79,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-05": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -19.05,
        "otherTotal": 0,
        "label": "2015-08-05",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -55.45,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.2124521072796935,
        "totalServiceFees": -36.400000000000006,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -36.400000000000006,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -19.05,
        "totalExpenses": -55.45,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -55.45,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-05-23": {
        "mfOrderCount": 0,
        "roi": "INF",
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-05-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": 0,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 0,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 0,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-07": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -32.57,
        "otherTotal": 0,
        "label": "2015-08-07",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -54.010000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.20693486590038315,
        "totalServiceFees": -11.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -9.84,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -11.6,
        "lostInboundQty": 0,
        "totalInventoryFees": -9.84,
        "cogs": 0,
        "totalShippingFees": -32.57,
        "totalExpenses": -54.010000000000005,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -54.010000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-01": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-01",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -40.7,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.15593869731800766,
        "totalServiceFees": -40.7,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -40.7,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -40.7,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -40.7,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -69.66000000000001,
        "otherTotal": 0,
        "label": "2015-08-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -72.96000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.2795402298850575,
        "totalServiceFees": -3.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -3.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -69.66000000000001,
        "totalExpenses": -72.96000000000001,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -72.96000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-03": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-03",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -20.7,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0793103448275862,
        "totalServiceFees": -20.7,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -20.7,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.7,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.7,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-30": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -5.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.021455938697318006,
        "totalServiceFees": -5.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -5.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -5.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -5.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-13": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-13",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -0.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0011494252873563218,
        "totalServiceFees": -0.3,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -0.3,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-12": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -33.11,
        "otherTotal": 0,
        "label": "2015-08-12",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -49.209999999999994,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.18854406130268198,
        "totalServiceFees": -16.099999999999998,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -16.099999999999998,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -33.11,
        "totalExpenses": -49.209999999999994,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -49.209999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-11": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-11",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -18.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06973180076628352,
        "totalServiceFees": -18.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -18.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-06": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-06",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -29.46,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1128735632183908,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -29.46,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -29.46,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -29.46,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -29.46,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-01": {
        "mfOrderCount": 0,
        "roi": -226.02915951972557,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": -29.39,
        "dateStartYear": 2015,
        "inboundShippingFees": -23.32,
        "otherTotal": -29.39,
        "label": "2015-10-01",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -29.39,
        "lostWarehouseQty": 0,
        "netProfit": -52.71,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.20195402298850576,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -23.32,
        "totalExpenses": -23.32,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.32,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -83.9,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.32145593869731803,
        "totalServiceFees": -83.9,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -83.9,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -83.9,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -83.9,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-05-14": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-05-14",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.30268199233716475,
        "totalServiceFees": -79,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -79,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -79,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -79,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-19": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-19",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -28.2,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10804597701149425,
        "totalServiceFees": -28.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -28.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.2,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.2,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-09": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -20.06,
        "otherTotal": 0,
        "label": "2015-10-09",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -20.06,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07685823754789271,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -20.06,
        "totalExpenses": -20.06,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.06,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-02": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-02",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -39.99,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1532183908045977,
        "totalServiceFees": -39.99,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -39.99,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.99,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.99,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-06": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-06",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -40.63,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.1556704980842912,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": -40.63,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": -40.63,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -40.63,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -40.63,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-04": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-04",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.8,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.0681992337164751,
        "totalServiceFees": -17.8,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -17.8,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.8,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.8,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-10": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-10",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -20.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.07892720306513411,
        "totalServiceFees": -20.6,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -20.6,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.6,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.6,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-30": {
        "mfOrderCount": 0,
        "roi": "INF",
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-30",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": 0,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 0,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 0,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-25": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -9.3,
        "otherTotal": 0,
        "label": "2015-09-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -9.3,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.035632183908045977,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -9.3,
        "totalExpenses": -9.3,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-24": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -15.870000000000001,
        "otherTotal": 0,
        "label": "2015-09-24",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -17.67,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.06770114942528736,
        "totalServiceFees": -1.8,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -1.8,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -15.870000000000001,
        "totalExpenses": -17.67,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.67,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-23": {
        "mfOrderCount": 0,
        "roi": -100,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": -14.82,
        "otherTotal": 0,
        "label": "2015-09-23",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 0,
        "lostWarehouseQty": 0,
        "netProfit": -28.02,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.10735632183908046,
        "totalServiceFees": -13.2,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": -13.2,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": -14.82,
        "totalExpenses": -28.02,
        "totalSellingFees": 0,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.02,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      }
    },
    "N/A": {
      "2015-11-19": {
        "mfOrderCount": 0,
        "roi": 107.73277866144615,
        "shippingCredits": 7.48,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 158.80999999999997,
        "customerReturnTotal": 0,
        "totalTransactionFees": -45.93000000000002,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003474215",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CT-GK75-BJ4A",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470057",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555470",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470112",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449318",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461223",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543365",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-19",
        "fbaOrderCount": 19,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 10.97,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 169.77999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 88.04999999999995,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.33735632183908026,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -45.93000000000002,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -35.8,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -81.73000000000002,
        "totalSellingFees": -35.8,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -81.73000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-18": {
        "mfOrderCount": 0,
        "roi": 61.16384647131656,
        "shippingCredits": 14.33,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 2,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 90.1,
        "customerReturnTotal": 0,
        "totalTransactionFees": -27.740000000000002,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003433729",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321746",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003031989",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461824",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537079",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914325",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003433788",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469560",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187091",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436752",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108097",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-18",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 11.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 78.1,
        "lostWarehouseQty": 0,
        "netProfit": 29.64,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -23.98,
        "dailyNetProfit": 0.1135632183908046,
        "totalServiceFees": 0,
        "promotionalRebates": -2.35,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -27.740000000000002,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 4.95,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -25.669999999999995,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -48.459999999999994,
        "totalSellingFees": -20.719999999999995,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -48.459999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-18": {
        "mfOrderCount": 0,
        "roi": 83.56756756756756,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 16.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002400174",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "4C-JF55-H4NB",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-18",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 16.98,
        "lostWarehouseQty": 0,
        "netProfit": 7.73,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.029616858237547894,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.25,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.25,
        "totalSellingFees": -5.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.25,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-25": {
        "mfOrderCount": 0,
        "roi": 138.07754817738572,
        "shippingCredits": 13,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 91.53,
        "customerReturnTotal": 0,
        "totalTransactionFees": -22.58,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002630110",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003591244",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547014",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321584",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491065",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555487",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-25",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 11.01,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 102.54,
        "lostWarehouseQty": 0,
        "netProfit": 59.47000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.22785440613026825,
        "totalServiceFees": 0,
        "promotionalRebates": -1.99,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -22.58,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.49,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -43.06999999999999,
        "totalSellingFees": -20.49,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -43.06999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-16": {
        "mfOrderCount": 0,
        "roi": 41.60756501182033,
        "shippingCredits": 3.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 7.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.91,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002368011",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-16",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 11.98,
        "lostWarehouseQty": 0,
        "netProfit": 3.5199999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.013486590038314174,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.91,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.55,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -8.46,
        "totalSellingFees": -2.55,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -8.46,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-17": {
        "mfOrderCount": 0,
        "roi": 97.88388497015731,
        "shippingCredits": 8.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 77.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.379999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "2K-AU9I-O5PS",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "4N-FG1I-3YJA",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "BC-1OZ2-5E2L",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-17",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.5,
        "fbaTransactionFeeRefunds": 3.99,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 36.46999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 18.039999999999992,
        "shippingCreditRefunds": -3.99,
        "mfOrderRevenue": 0,
        "totalRefundCost": -44.99,
        "dailyNetProfit": 0.06911877394636012,
        "totalServiceFees": 0,
        "promotionalRebates": -1.49,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -14.37,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 5.4,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.43,
        "totalSellingFees": -8.049999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.43,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-13": {
        "mfOrderCount": 0,
        "roi": 325.18491124260345,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 114.96999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.09,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002352373",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002728593",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451860",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-13",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 114.96999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 87.92999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.33689655172413785,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.09,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.95,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -27.04,
        "totalSellingFees": -19.95,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -27.04,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-15": {
        "mfOrderCount": 0,
        "roi": 104.87288135593225,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 19.340000000000003,
        "customerReturnTotal": 0,
        "totalTransactionFees": -3.84,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002400356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "O1-GM01-EZWO",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-15",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 19.340000000000003,
        "lostWarehouseQty": 0,
        "netProfit": 9.900000000000004,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.037931034482758634,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -3.84,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.6,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.44,
        "totalSellingFees": -5.6,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.44,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-12": {
        "mfOrderCount": 0,
        "roi": 37.62114537444931,
        "shippingCredits": 3.35,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 12.27,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.8100000000000005,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "O8-WXGA-I7U3",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "B5-UZUD-269F",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-12",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.35,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 15.62,
        "lostWarehouseQty": 0,
        "netProfit": 4.269999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.016360153256704972,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.8100000000000005,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -4.54,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -11.350000000000001,
        "totalSellingFees": -4.54,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -11.350000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-13": {
        "mfOrderCount": 0,
        "roi": 119.29872247893447,
        "shippingCredits": 5.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 76.07,
        "customerReturnTotal": 0,
        "totalTransactionFees": -22.509999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "KA-A1E7-PU20",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "U5-Y1TM-6S14",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "DB-XZ8G-1KYC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "BC-1OZ2-5E2L",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-13",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.61,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 80.67999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 43.88999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.16816091954022985,
        "totalServiceFees": 0,
        "promotionalRebates": -1.37,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -22.509999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.28,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -36.79,
        "totalSellingFees": -14.28,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -36.79,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-17": {
        "mfOrderCount": 0,
        "roi": 97.1442885771543,
        "shippingCredits": 1.23,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 78.7,
        "customerReturnTotal": 0,
        "totalTransactionFees": -19.46,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002973297",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428575",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002410699",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003433740",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550898",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547048",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368594",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-17",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 78.7,
        "lostWarehouseQty": 0,
        "netProfit": 38.78,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.14858237547892722,
        "totalServiceFees": 0,
        "promotionalRebates": -1.23,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -19.46,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.46,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.92,
        "totalSellingFees": -20.46,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.92,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-11": {
        "mfOrderCount": 0,
        "roi": 287.5773745997866,
        "shippingCredits": 3.4400000000000004,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 181.58,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.91,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "9K-GLOK-CF62",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HJ42-UATT",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002342241",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-11",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 181.58,
        "lostWarehouseQty": 0,
        "netProfit": 134.73000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.5162068965517242,
        "totalServiceFees": 0,
        "promotionalRebates": -3.4400000000000004,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.91,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -29.94,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -46.85,
        "totalSellingFees": -29.94,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -46.85,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-27": {
        "mfOrderCount": 0,
        "roi": 184.08085912823756,
        "shippingCredits": 1.3,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 44.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.13,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002550870",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449184",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-27",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 44.97,
        "lostWarehouseQty": 0,
        "netProfit": 29.14,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11164750957854407,
        "totalServiceFees": 0,
        "promotionalRebates": -1.3,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.13,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.7,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -15.829999999999998,
        "totalSellingFees": -9.7,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -15.829999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-20": {
        "mfOrderCount": 0,
        "roi": 159.25563173359447,
        "shippingCredits": 1.53,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 1,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 13.98,
        "customerReturnTotal": 12.49,
        "totalTransactionFees": -7.01,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 12.49,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-20",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.47,
        "lostWarehouseQty": 0,
        "netProfit": 16.259999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.062298850574712634,
        "totalServiceFees": 0,
        "promotionalRebates": -1.53,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.01,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.2,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.21,
        "totalSellingFees": -3.2,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.21,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-10": {
        "mfOrderCount": 0,
        "roi": 167.47387203670658,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 104.92999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -18.08,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451574",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002913194",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002913187",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551195",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-10",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 104.92999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 65.69999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.25172413793103443,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -18.08,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -21.15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.23,
        "totalSellingFees": -21.15,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.23,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-11": {
        "mfOrderCount": 0,
        "roi": 158.6261980830671,
        "shippingCredits": 8.7,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 48.57,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.82,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002731327",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551164",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555765",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-11",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 48.57,
        "lostWarehouseQty": 0,
        "netProfit": 29.79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11413793103448276,
        "totalServiceFees": 0,
        "promotionalRebates": -8.7,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.82,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.96,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.78,
        "totalSellingFees": -8.96,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.78,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-12": {
        "mfOrderCount": 0,
        "roi": 18.364928909952596,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 34.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.59,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002428582",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663368",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-12",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 9.989999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 1.549999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -24.99,
        "dailyNetProfit": 0.005938697318007659,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.59,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 3,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -6.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -8.44,
        "totalSellingFees": -3.8499999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -8.44,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-13": {
        "mfOrderCount": 0,
        "roi": 170.84112149532714,
        "shippingCredits": 2.27,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 57.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.3,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002630110",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469897",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451537",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600950",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-13",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 57.96,
        "lostWarehouseQty": 0,
        "netProfit": 36.56,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.14007662835249043,
        "totalServiceFees": 0,
        "promotionalRebates": -2.27,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.3,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.1,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -21.4,
        "totalSellingFees": -14.1,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-14": {
        "mfOrderCount": 0,
        "roi": 149.89578991246353,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 59.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.59,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555815",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663826",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002832870",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564756",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-14",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 59.95,
        "lostWarehouseQty": 0,
        "netProfit": 35.96,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.13777777777777778,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.59,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.990000000000002,
        "totalSellingFees": -14.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.990000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-15": {
        "mfOrderCount": 0,
        "roi": 103.65535248041773,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 110.18999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -14.629999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002603034",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002380447",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002640919",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584252",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002901575",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451379",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1A-7W2H-LA7A",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-15",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 70.19999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 35.72999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -39.99,
        "dailyNetProfit": 0.1368965517241379,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -14.629999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 4.8,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -24.64,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.47,
        "totalSellingFees": -19.84,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.47,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-16": {
        "mfOrderCount": 0,
        "roi": 106.17501764290758,
        "shippingCredits": 6.6000000000000005,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 53.45,
        "customerReturnTotal": 0,
        "totalTransactionFees": -13.57,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002715391",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516737",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002913139",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469729",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603049",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-16",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 58.43000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 30.090000000000007,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1152873563218391,
        "totalServiceFees": 0,
        "promotionalRebates": -1.62,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -13.57,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.77,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.34,
        "totalSellingFees": -14.77,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.34,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-17": {
        "mfOrderCount": 0,
        "roi": 224.63332079729224,
        "shippingCredits": 21.4,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 130.82,
        "customerReturnTotal": 0,
        "totalTransactionFees": -37.4,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "RD-FIUI-XPQ8",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470130",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002820315",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002360801",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002901629",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "QP-IYI4-4G91",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5M-H559-K1TM",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 48.43,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-17",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 18.38,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 172.64,
        "lostWarehouseQty": 2,
        "netProfit": 119.46,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -24.99,
        "dailyNetProfit": 0.45770114942528733,
        "totalServiceFees": 0,
        "promotionalRebates": -3.02,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -37.4,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 3,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -18.779999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -53.17999999999999,
        "totalSellingFees": -15.779999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -53.17999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 48.43
      },
      "2015-09-18": {
        "mfOrderCount": 0,
        "roi": 360.64018968583287,
        "shippingCredits": 6.35,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 543.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -36.160000000000004,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002731327",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469043",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427896",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-18",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 543.97,
        "lostWarehouseQty": 0,
        "netProfit": 425.88,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 1.6317241379310345,
        "totalServiceFees": 0,
        "promotionalRebates": -6.35,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -36.160000000000004,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -81.92999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -118.09,
        "totalSellingFees": -81.92999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -118.09,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-19": {
        "mfOrderCount": 0,
        "roi": 96.04477611940301,
        "shippingCredits": 2.08,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 26.270000000000003,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.41,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002603137",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603305",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715515",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-19",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.270000000000003,
        "lostWarehouseQty": 0,
        "netProfit": 12.870000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.04931034482758622,
        "totalServiceFees": 0,
        "promotionalRebates": -2.08,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.41,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -7.99,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -13.4,
        "totalSellingFees": -7.99,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-23": {
        "mfOrderCount": 0,
        "roi": 136.94602896007018,
        "shippingCredits": 5.48,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 102.51999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -25.71,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002360848",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "HQ-18B6-JZY7",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NQ-4FQF-S5SC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565224",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469726",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461783",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582851",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-23",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 5.48,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 107.99999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 62.41999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2391570881226053,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -25.71,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.87,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -45.58,
        "totalSellingFees": -19.87,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -45.58,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-09": {
        "mfOrderCount": 0,
        "roi": 64.7491211249601,
        "shippingCredits": 8.9,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 48.57000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -15.649999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "BQ-TIXK-BTT8",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "I8-CDHD-A1L6",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "MN-F23X-SGSN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "VT-OXQU-G4U3",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "N1-24GE-YWJJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "6M-U83I-056G",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "G5-IF79-U8YJ",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-09",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.9800000000000004,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 51.55000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 20.260000000000012,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07762452107279698,
        "totalServiceFees": 0,
        "promotionalRebates": -5.92,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -15.649999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -15.64,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -31.29,
        "totalSellingFees": -15.64,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -31.29,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-08": {
        "mfOrderCount": 0,
        "roi": 98.0857042500878,
        "shippingCredits": 7.300000000000001,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 105.49,
        "customerReturnTotal": 0,
        "totalTransactionFees": -28.96,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "C8-7Q4H-1VKN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "4Q-0FR6-NKBJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-BU0U-VZD7",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "2V-W07A-AZLJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "Q3-WEFG-USKQ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0Z-JVS3-YEQ3",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "P4-Q0A3-EP7K",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9K-7AT8-CFAB",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5Q-AEQB-3NOQ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "WS-D6A0-W8B5",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-08",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 7.300000000000001,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 112.78999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 55.849999999999994,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21398467432950188,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -28.96,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -27.98,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -56.94,
        "totalSellingFees": -27.98,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -56.94,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-05": {
        "mfOrderCount": 0,
        "roi": 259.956338687042,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 230.84,
        "customerReturnTotal": 0,
        "totalTransactionFees": -24.11,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "04-9TA1-1XD5",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0E-9QJC-OR2J",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "EV-9ABP-RBQS",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "W7-JN1F-MKFE",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "13-B66X-I0QR",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-05",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 230.84,
        "lostWarehouseQty": 0,
        "netProfit": 166.71,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.638735632183908,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -24.11,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -40.02,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -64.13,
        "totalSellingFees": -40.02,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -64.13,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-04": {
        "mfOrderCount": 0,
        "roi": 52.93276108726753,
        "shippingCredits": 2.4299999999999997,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 42.76,
        "customerReturnTotal": 0,
        "totalTransactionFees": -18.509999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "F9-6ODS-XRAF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "AN-U4C4-C4GY",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "QI-NUVK-181X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-W5IH-VZ3Q",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H8-Q28U-TY0M",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-04",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 42.76,
        "lostWarehouseQty": 0,
        "netProfit": 14.8,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05670498084291188,
        "totalServiceFees": 0,
        "promotionalRebates": -2.4299999999999997,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -18.509999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -27.959999999999997,
        "totalSellingFees": -9.45,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -27.959999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-07": {
        "mfOrderCount": 0,
        "roi": 54.28491149199218,
        "shippingCredits": 3.2,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 54.91000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.14,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "6B-7HIR-DB3U",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "WP-4R1Z-CMAZ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "GU-QM4E-NIL0",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "U8-PFTC-QDWT",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "UM-04SU-WTFY",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9Y-XU2O-IV15",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "A2-FPOE-2GYW",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "22-KX18-Y40Z",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IF-CNQL-D9DQ",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-07",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 54.91000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 19.320000000000014,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07402298850574718,
        "totalServiceFees": 0,
        "promotionalRebates": -3.2,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.14,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.449999999999996,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.589999999999996,
        "totalSellingFees": -19.449999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.589999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-06": {
        "mfOrderCount": 0,
        "roi": 65.99279977845471,
        "shippingCredits": 9.97,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 49.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -23.410000000000004,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "P0-XECC-V321",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NU-KQVE-BS0E",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "DE-4MUF-L6HT",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "6I-FGHK-GJ6A",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-06",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 9.97,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 59.94,
        "lostWarehouseQty": 0,
        "netProfit": 23.83,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.09130268199233715,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -23.410000000000004,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.7,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -36.11,
        "totalSellingFees": -12.7,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -36.11,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-01": {
        "mfOrderCount": 0,
        "roi": 7.066795740561475,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 11.06,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.62,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "WE-WTVU-PSAT",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LD-P78C-PJEI",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "SR-M8EI-K8NB",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-01",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 11.06,
        "lostWarehouseQty": 0,
        "netProfit": 0.7300000000000004,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.002796934865900385,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.62,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.71,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.33,
        "totalSellingFees": -5.71,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.33,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-21": {
        "mfOrderCount": 0,
        "roi": 86.25410733844467,
        "shippingCredits": 1.04,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 32.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -8.71,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663902",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003474502",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461022",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-21",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.04,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 34.01,
        "lostWarehouseQty": 0,
        "netProfit": 15.749999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.060344827586206885,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -8.71,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.55,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.26,
        "totalSellingFees": -9.55,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.26,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-03": {
        "mfOrderCount": 0,
        "roi": -10.479041916167654,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 2.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "WS-BYGF-W81P",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-03",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 2.99,
        "lostWarehouseQty": 0,
        "netProfit": -0.34999999999999964,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": -0.001340996168582374,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -1.8,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -3.34,
        "totalSellingFees": -1.8,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -3.34,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-02": {
        "mfOrderCount": 0,
        "roi": 81.54882154882156,
        "shippingCredits": 4.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 21.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -8.85,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "L3-D22R-2PLE",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "L3-Q3XT-2PQY",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-02",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.96,
        "lostWarehouseQty": 0,
        "netProfit": 12.110000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.046398467432950195,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -8.85,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -6,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -14.85,
        "totalSellingFees": -6,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -14.85,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-29": {
        "mfOrderCount": 0,
        "roi": 138.9918887601391,
        "shippingCredits": 1.37,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 82.5,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.43,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002487604",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003493697",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NQ-4FQF-S5SC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002575289",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451398",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517194",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-29",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 82.5,
        "lostWarehouseQty": 0,
        "netProfit": 47.980000000000004,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1838314176245211,
        "totalServiceFees": 0,
        "promotionalRebates": -1.37,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.43,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -18.09,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.519999999999996,
        "totalSellingFees": -18.09,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.519999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-07": {
        "mfOrderCount": 0,
        "roi": 78.68763557483734,
        "shippingCredits": 2.06,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 32.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -8.09,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002487639",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002664013",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "7Z-YTH1-MO80",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715413",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-07",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 32.95,
        "lostWarehouseQty": 0,
        "netProfit": 14.510000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.055593869731800784,
        "totalServiceFees": 0,
        "promotionalRebates": -2.06,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -8.09,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -10.35,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.439999999999998,
        "totalSellingFees": -10.35,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.439999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-06": {
        "mfOrderCount": 0,
        "roi": 119.64426877470356,
        "shippingCredits": 5.18,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 53.25,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.91,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002428544",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663758",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603094",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603300",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-06",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.32,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 55.57,
        "lostWarehouseQty": 0,
        "netProfit": 30.27,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11597701149425287,
        "totalServiceFees": 0,
        "promotionalRebates": -2.86,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.91,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.39,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -25.3,
        "totalSellingFees": -13.39,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -25.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-05": {
        "mfOrderCount": 0,
        "roi": 213.35135135135138,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 57.970000000000006,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.1,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002820356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517307",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564919",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-05",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 57.970000000000006,
        "lostWarehouseQty": 0,
        "netProfit": 39.470000000000006,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.15122605363984676,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.1,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -18.5,
        "totalSellingFees": -11.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -18.5,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-04": {
        "mfOrderCount": 0,
        "roi": 90.21701687909064,
        "shippingCredits": 2.67,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 55.22000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.540000000000001,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002470257",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451247",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555856",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "6E-IGZD-WX75",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427883",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663869",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5T-QNLP-N9DN",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-04",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 55.22000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 26.190000000000012,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.10034482758620694,
        "totalServiceFees": 0,
        "promotionalRebates": -2.67,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.540000000000001,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -16.49,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -29.03,
        "totalSellingFees": -16.49,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -29.03,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-03": {
        "mfOrderCount": 0,
        "roi": 202.35042735042734,
        "shippingCredits": 7.4,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 84.9,
        "customerReturnTotal": 0,
        "totalTransactionFees": -8.59,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002470329",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "8R-V6X6-ZJOC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469697",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555843",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663927",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-03",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 84.9,
        "lostWarehouseQty": 0,
        "netProfit": 56.82000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21770114942528737,
        "totalServiceFees": 0,
        "promotionalRebates": -7.4,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -8.59,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.490000000000002,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.080000000000002,
        "totalSellingFees": -19.490000000000002,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.080000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-02": {
        "mfOrderCount": 0,
        "roi": 2253.529411764706,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 6.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002600983",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002361202",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-02",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -40.01,
        "lostWarehouseQty": 0,
        "netProfit": -38.309999999999995,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -47,
        "dailyNetProfit": -0.14678160919540229,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 5.64,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 1.6999999999999997,
        "totalSellingFees": 3.2399999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 1.6999999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-01": {
        "mfOrderCount": 0,
        "roi": 61.80555555555555,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 6.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.92,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002603337",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-01",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 6.99,
        "lostWarehouseQty": 0,
        "netProfit": 2.67,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.010229885057471265,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.92,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.32,
        "totalSellingFees": -2.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.32,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-13": {
        "mfOrderCount": 0,
        "roi": 65.74490148634636,
        "shippingCredits": 4.49,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 45.81,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.33,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "VT-VBVF-G5H2",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564702",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002527550",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486051",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-13",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.14,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 47.95,
        "lostWarehouseQty": 0,
        "netProfit": 19.020000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07287356321839082,
        "totalServiceFees": 0,
        "promotionalRebates": -2.35,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.33,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.6,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.93,
        "totalSellingFees": -12.6,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.93,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-30": {
        "mfOrderCount": 0,
        "roi": 135.26522593320237,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 47.9,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.120000000000001,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002973312",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002728618",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600960",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547048",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-30",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 47.9,
        "lostWarehouseQty": 0,
        "netProfit": 27.54,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.10551724137931034,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.120000000000001,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.239999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.36,
        "totalSellingFees": -11.239999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.36,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-18": {
        "mfOrderCount": 0,
        "roi": 102.42792109256449,
        "shippingCredits": 3.9400000000000004,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 26.68,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.13,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003335190",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "7Z-A1A5-MO81",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469603",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-18",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.68,
        "lostWarehouseQty": 0,
        "netProfit": 13.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05172413793103448,
        "totalServiceFees": 0,
        "promotionalRebates": -3.9400000000000004,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.13,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.05,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -13.18,
        "totalSellingFees": -8.05,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.18,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-19": {
        "mfOrderCount": 0,
        "roi": 225.72932330827066,
        "shippingCredits": 2.32,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 210.79999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -30.269999999999996,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002630285",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427914",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461617",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "VT-VBVF-G5H2",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605026",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-19",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 5.8100000000000005,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 216.60999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 150.10999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.5751340996168581,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -30.269999999999996,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -36.230000000000004,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -66.5,
        "totalSellingFees": -36.230000000000004,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -66.5,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-09": {
        "mfOrderCount": 0,
        "roi": 226.6655686403691,
        "shippingCredits": 8.530000000000001,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 396.6700000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -51.949999999999996,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002605068",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603069",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663348",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "BM-2BOB-S7VJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436887",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002820315",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "UM-HEX2-WTFX",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "C4-GF61-I9MY",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491128",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470205",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469705",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715355",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002575403",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486059",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-09",
        "fbaOrderCount": 23,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 396.6700000000001,
        "lostWarehouseQty": 0,
        "netProfit": 275.2400000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 1.0545593869731806,
        "totalServiceFees": 0,
        "promotionalRebates": -8.530000000000001,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -51.949999999999996,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -69.47999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -121.42999999999998,
        "totalSellingFees": -69.47999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -121.42999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-08": {
        "mfOrderCount": 0,
        "roi": 233.32705350414474,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 176.93,
        "customerReturnTotal": 0,
        "totalTransactionFees": -25.949999999999996,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002731327",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002622658",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517053",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-08",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 176.93,
        "lostWarehouseQty": 0,
        "netProfit": 123.85000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4745210727969349,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -25.949999999999996,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -27.13,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -53.08,
        "totalSellingFees": -27.13,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -53.08,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-05": {
        "mfOrderCount": 0,
        "roi": 178.32916815423064,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 77.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.26,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003107999",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555952",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IM-QXL0-GHDK",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108146",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-05",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 77.96,
        "lostWarehouseQty": 0,
        "netProfit": 49.949999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.19137931034482758,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.26,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -15.749999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.009999999999998,
        "totalSellingFees": -15.749999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.009999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-04": {
        "mfOrderCount": 0,
        "roi": 314.5947505047592,
        "shippingCredits": 5.58,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 139.24,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.08,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002360732",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427852",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108006",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368524",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0.51,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-04",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 143.74,
        "lostWarehouseQty": 1,
        "netProfit": 109.07000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4178927203065134,
        "totalServiceFees": 0,
        "promotionalRebates": -1.59,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.08,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.59,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.67,
        "totalSellingFees": -23.59,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.67,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0.51
      },
      "2015-10-07": {
        "mfOrderCount": 0,
        "roi": 147.54754754754745,
        "shippingCredits": 7.51,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 94.92999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -21.660000000000004,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002833003",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003107946",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-07",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.9899999999999998,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 98.91999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 58.95999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.22590038314176236,
        "totalServiceFees": 0,
        "promotionalRebates": -3.52,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -21.660000000000004,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -18.3,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.96000000000001,
        "totalSellingFees": -18.3,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.96000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-30": {
        "mfOrderCount": 0,
        "roi": 59.82077984984257,
        "shippingCredits": 4.48,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 61.51000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.86,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002490990",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "MD-TKPL-5MYO",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469507",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491305",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427883",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002380438",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "L3-D22R-2PLE",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491169",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IF-CNQL-D9DQ",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-30",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.48,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 65.99000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 24.700000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.09463601532567051,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.86,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.430000000000003,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -41.290000000000006,
        "totalSellingFees": -20.430000000000003,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -41.290000000000006,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-31": {
        "mfOrderCount": 0,
        "roi": 114.67475879240587,
        "shippingCredits": 4.85,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 135.95999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -32.69,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002436807",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CM-W7I0-8B04",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486221",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486210",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IF-CNQL-D9DQ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486190",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-31",
        "fbaOrderCount": 16,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.9899999999999998,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 137.95,
        "lostWarehouseQty": 0,
        "netProfit": 73.69,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2823371647509578,
        "totalServiceFees": 0,
        "promotionalRebates": -2.86,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -32.69,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.57,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -64.25999999999999,
        "totalSellingFees": -31.57,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -64.25999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-30": {
        "mfOrderCount": 0,
        "roi": 137.850803550012,
        "shippingCredits": 1.87,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 99.16,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.01,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451302",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003752324",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582933",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461635",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003144880",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CT-GK75-BJ4A",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491035",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451398",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914544",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-30",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 99.16,
        "lostWarehouseQty": 0,
        "netProfit": 57.47,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.22019157088122604,
        "totalServiceFees": 0,
        "promotionalRebates": -1.87,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.01,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -25.679999999999996,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -41.69,
        "totalSellingFees": -25.679999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -41.69,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-10": {
        "mfOrderCount": 0,
        "roi": 147.5698924731183,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 57.56,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.21,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003306359",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547048",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003042837",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554744",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551261",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-10",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 57.56,
        "lostWarehouseQty": 0,
        "netProfit": 34.31,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.131455938697318,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.21,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.040000000000001,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.25,
        "totalSellingFees": -14.040000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.25,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-01": {
        "mfOrderCount": 0,
        "roi": 92.43046055631557,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 42.2,
        "customerReturnTotal": 0,
        "totalTransactionFees": -8.84,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451881",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550984",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584158",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002367921",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564767",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-01",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 42.2,
        "lostWarehouseQty": 0,
        "netProfit": 20.270000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07766283524904216,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -8.84,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.09,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -21.93,
        "totalSellingFees": -13.09,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.93,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-14": {
        "mfOrderCount": 0,
        "roi": 65.04854368932041,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 13.600000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.34,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "Q0-OJJ2-B6KS",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-14",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 13.600000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 5.360000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.020536398467432954,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.34,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.9,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -8.24,
        "totalSellingFees": -3.9,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -8.24,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-14": {
        "mfOrderCount": 0,
        "roi": 206.57850686307327,
        "shippingCredits": 4,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 180.89000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.440000000000005,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368507",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187394",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003591346",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555956",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003269915",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002641972",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543319",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449201",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461729",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-14",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.26,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 183.15,
        "lostWarehouseQty": 0,
        "netProfit": 123.41,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4728352490421456,
        "totalServiceFees": 0,
        "promotionalRebates": -1.74,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.440000000000005,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -39.300000000000004,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -59.74000000000001,
        "totalSellingFees": -39.300000000000004,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -59.74000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-09": {
        "mfOrderCount": 0,
        "roi": 196.83486238532095,
        "shippingCredits": 2.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 78.16999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.87,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002543302",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CT-GK75-BJ4A",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436835",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565210",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491164",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428455",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-09",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": -3.4699999999999998,
        "fbaTransactionFeeRefunds": 6.46,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 64.70999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 42.909999999999975,
        "shippingCreditRefunds": -6.46,
        "mfOrderRevenue": 0,
        "totalRefundCost": -9.99,
        "dailyNetProfit": 0.16440613026819914,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.33,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 1.2,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.130000000000003,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -21.800000000000004,
        "totalSellingFees": -15.930000000000003,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.800000000000004,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-12": {
        "mfOrderCount": 0,
        "roi": 96.68134100914322,
        "shippingCredits": 11.16,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 103.69999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -32.6,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003006075",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451167",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486062",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H8-Q28U-TY0M",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547048",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491065",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427841",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003042826",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108088",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003335318",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-12",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 12.46,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 116.16,
        "lostWarehouseQty": 0,
        "netProfit": 57.099999999999994,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21877394636015324,
        "totalServiceFees": 0,
        "promotionalRebates": -2.19,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -32.6,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -26.46,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -59.06,
        "totalSellingFees": -26.46,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -59.06,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-03": {
        "mfOrderCount": 0,
        "roi": 359.3440916189484,
        "shippingCredits": 3.26,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 47.84,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.209999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "3G-3HQ8-KN52",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002460768",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 37.14,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 37.14,
        "label": "2015-10-03",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.26,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 88.24000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 69.03,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.26448275862068965,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.209999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -19.21,
        "totalSellingFees": -8,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -19.21,
        "otherQty": 2,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-15": {
        "mfOrderCount": 0,
        "roi": 102.34776228906823,
        "shippingCredits": 5.65,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 112.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -28.009999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003461055",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600878",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242310",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002352467",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003032447",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003335401",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914638",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451445",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003575331",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554639",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550859",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-15",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 5.65,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 110.32,
        "lostWarehouseQty": 0,
        "netProfit": 55.8,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -8.29,
        "dailyNetProfit": 0.21379310344827585,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -28.009999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.59,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -29.1,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -54.519999999999996,
        "totalSellingFees": -26.51,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -54.519999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-03": {
        "mfOrderCount": 0,
        "roi": 156.825453252755,
        "shippingCredits": 2.73,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 142.39,
        "customerReturnTotal": 0,
        "totalTransactionFees": -25.44,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002715306",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003591346",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368494",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002604999",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003474493",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003575488",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003752312",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224477",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582826",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-03",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.1,
        "fbaTransactionFeeRefunds": 1.39,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 144.48999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 88.22999999999998,
        "shippingCreditRefunds": -1.39,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.33804597701149414,
        "totalServiceFees": 0,
        "promotionalRebates": -2.73,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -26.830000000000002,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -30.82,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -56.260000000000005,
        "totalSellingFees": -30.82,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -56.260000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-15": {
        "mfOrderCount": 0,
        "roi": 217.17687074829934,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 55.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002574663",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224433",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914602",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-15",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 55.95,
        "lostWarehouseQty": 0,
        "netProfit": 38.31,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1467816091954023,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.1,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.64,
        "totalSellingFees": -11.1,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.64,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-01": {
        "mfOrderCount": 0,
        "roi": 176.90709409320277,
        "shippingCredits": 1.39,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 91.9,
        "customerReturnTotal": 0,
        "totalTransactionFees": -18.369999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451056",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663436",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449333",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NQ-4FQF-S5SC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582826",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-01",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.39,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 93.29,
        "lostWarehouseQty": 0,
        "netProfit": 59.60000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.22835249042145597,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -18.369999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -15.319999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -33.69,
        "totalSellingFees": -15.319999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -33.69,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-02": {
        "mfOrderCount": 0,
        "roi": 108.71391076115484,
        "shippingCredits": 1.59,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 39.76,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.68,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002641882",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554611",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451098",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555520",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-02",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 39.76,
        "lostWarehouseQty": 0,
        "netProfit": 20.709999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07934865900383141,
        "totalServiceFees": 0,
        "promotionalRebates": -1.59,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.68,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.370000000000001,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -19.05,
        "totalSellingFees": -11.370000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -19.05,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-07": {
        "mfOrderCount": 0,
        "roi": 108.44975214060388,
        "shippingCredits": 4.86,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 92.51,
        "customerReturnTotal": 0,
        "totalTransactionFees": -21.6,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002450930",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003769270",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419786",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419779",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0L-SLJ7-RYZD",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550859",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002642016",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-07",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 92.51,
        "lostWarehouseQty": 0,
        "netProfit": 48.13,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.18440613026819924,
        "totalServiceFees": 0,
        "promotionalRebates": -4.86,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -21.6,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -22.78,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -44.38,
        "totalSellingFees": -22.78,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -44.38,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-06": {
        "mfOrderCount": 0,
        "roi": 120.37164487267718,
        "shippingCredits": 12.89,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 119.49,
        "customerReturnTotal": 0,
        "totalTransactionFees": -29.389999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451306",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603305",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003461579",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428455",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003006079",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002975857",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003769473",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605294",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-06",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 8.59,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 128.07999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 69.95999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2680459770114942,
        "totalServiceFees": 0,
        "promotionalRebates": -4.300000000000001,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -29.389999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -28.730000000000004,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -58.120000000000005,
        "totalSellingFees": -28.730000000000004,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -58.120000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-05": {
        "mfOrderCount": 0,
        "roi": 108.8956472153583,
        "shippingCredits": 14.97,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 96.91999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -28.86,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003187347",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002535575",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003083223",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242317",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003032713",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491053",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-05",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 12.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 109.89999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 57.28999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21950191570881222,
        "totalServiceFees": 0,
        "promotionalRebates": -1.99,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -28.86,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.75,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -52.61,
        "totalSellingFees": -23.75,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -52.61,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-14": {
        "mfOrderCount": 0,
        "roi": 224.293228139382,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 98.65,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.97,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002664018",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003083268",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451943",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600958",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-14",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 98.65,
        "lostWarehouseQty": 0,
        "netProfit": 68.23,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2614176245210728,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.97,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -30.42,
        "totalSellingFees": -20.45,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -30.42,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-17": {
        "mfOrderCount": 0,
        "roi": 117.79832418376195,
        "shippingCredits": 2.86,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 75.38000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.78,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002833010",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "OQ-TX0B-89EL",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003782844",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002601000",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543329",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-17",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 75.38000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 40.77000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.15620689655172418,
        "totalServiceFees": 0,
        "promotionalRebates": -2.86,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.78,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.83,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.61,
        "totalSellingFees": -17.83,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.61,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-10": {
        "mfOrderCount": 0,
        "roi": 270.09819967266776,
        "shippingCredits": 10.110000000000001,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 218.16,
        "customerReturnTotal": 0,
        "totalTransactionFees": -30.11,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "N5-P205-IIGF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "DB-UZ9O-1KSP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "22-6UPZ-Y46N",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NQ-4FQF-S5SC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "UJ-D7KB-D7PC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H5-IV1X-ACBO",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0P-J0H8-BKX6",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-10",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 7.970000000000001,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 226.13,
        "lostWarehouseQty": 0,
        "netProfit": 165.03,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.6322988505747127,
        "totalServiceFees": 0,
        "promotionalRebates": -2.14,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -30.11,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -30.990000000000002,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -61.1,
        "totalSellingFees": -30.990000000000002,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -61.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-29": {
        "mfOrderCount": 0,
        "roi": 183.00970873786406,
        "shippingCredits": 9.96,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 69.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.17,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002477416",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486082",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224455",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003269615",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-29",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 69.96,
        "lostWarehouseQty": 0,
        "netProfit": 45.239999999999995,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1733333333333333,
        "totalServiceFees": 0,
        "promotionalRebates": -9.96,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.17,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.549999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -24.72,
        "totalSellingFees": -14.549999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.72,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-28": {
        "mfOrderCount": 0,
        "roi": 160.0896860986547,
        "shippingCredits": 1.91,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 115.99999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.889999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "F9-6ODS-XRAF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565338",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551115",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002574663",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108169",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012301",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550859",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-28",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 115.99999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 71.39999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.27356321839080455,
        "totalServiceFees": 0,
        "promotionalRebates": -1.91,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.889999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.71,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -44.599999999999994,
        "totalSellingFees": -23.71,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -44.599999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-27": {
        "mfOrderCount": 0,
        "roi": 81.85644661054498,
        "shippingCredits": 5.84,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 76.25,
        "customerReturnTotal": 0,
        "totalTransactionFees": -26.019999999999996,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "F9-6ODS-XRAF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242249",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002593691",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012151",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224852",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003054342",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451613",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-27",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 5.84,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 82.09,
        "lostWarehouseQty": 0,
        "netProfit": 36.95,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.14157088122605366,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -26.019999999999996,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.12,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -45.14,
        "totalSellingFees": -19.12,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -45.14,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-26": {
        "mfOrderCount": 0,
        "roi": 96.20071684587818,
        "shippingCredits": 1.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 27.370000000000005,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.79,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003335394",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436752",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002630500",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-26",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 27.370000000000005,
        "lostWarehouseQty": 0,
        "netProfit": 13.420000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05141762452107282,
        "totalServiceFees": 0,
        "promotionalRebates": -1.99,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.79,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.16,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -13.95,
        "totalSellingFees": -8.16,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.95,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-25": {
        "mfOrderCount": 0,
        "roi": 69.49563530552865,
        "shippingCredits": 1.66,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 34.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.719999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002564717",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003121107",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242297",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187220",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-25",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 34.95,
        "lostWarehouseQty": 0,
        "netProfit": 14.330000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.054904214559386995,
        "totalServiceFees": 0,
        "promotionalRebates": -1.66,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.719999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -10.899999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.619999999999997,
        "totalSellingFees": -10.899999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.619999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-16": {
        "mfOrderCount": 0,
        "roi": 46.488469601677195,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 57.940000000000005,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.829999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663436",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451872",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427853",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451014",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5T-QNLP-N9DN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012301",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-16",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 27.950000000000006,
        "lostWarehouseQty": 0,
        "netProfit": 8.870000000000008,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -29.99,
        "dailyNetProfit": 0.033984674329501946,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.829999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 3.6,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -19.08,
        "totalSellingFees": -9.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -19.08,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-23": {
        "mfOrderCount": 0,
        "roi": 136.49789029535867,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 52.56,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.41,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003108163",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469893",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419784",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427824",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-23",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.49,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 56.050000000000004,
        "lostWarehouseQty": 0,
        "netProfit": 32.35000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.12394636015325673,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.41,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.29,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.7,
        "totalSellingFees": -13.29,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.7,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-22": {
        "mfOrderCount": 0,
        "roi": 124.87169364390054,
        "shippingCredits": 1.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 54.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.33,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002914265",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224909",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470205",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321678",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565204",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-22",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 56.96,
        "lostWarehouseQty": 0,
        "netProfit": 31.630000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.12118773946360155,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.33,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -25.33,
        "totalSellingFees": -15,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -25.33,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-21": {
        "mfOrderCount": 0,
        "roi": 151.69378500933587,
        "shippingCredits": 3.37,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 92.92,
        "customerReturnTotal": 0,
        "totalTransactionFees": -15.440000000000001,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663436",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003186682",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368255",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003241926",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003305855",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003186990",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002536999",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-21",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.4400000000000002,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 94.36,
        "lostWarehouseQty": 0,
        "netProfit": 56.870000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21789272030651344,
        "totalServiceFees": 0,
        "promotionalRebates": -1.93,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -15.440000000000001,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -22.049999999999997,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -37.489999999999995,
        "totalSellingFees": -22.049999999999997,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.489999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-20": {
        "mfOrderCount": 0,
        "roi": 106.60098522167485,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 20.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.75,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "UM-X6B6-WTLL",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-20",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 20.97,
        "lostWarehouseQty": 0,
        "netProfit": 10.819999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.041455938697318,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.75,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.15,
        "totalSellingFees": -3.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.15,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-12": {
        "mfOrderCount": 0,
        "roi": 102.93348823700268,
        "shippingCredits": 1.53,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 69.87,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.979999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002450920",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003769502",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "2D-T4Z3-KXUY",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564696",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-12",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 69.87,
        "lostWarehouseQty": 0,
        "netProfit": 35.44000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.13578544061302686,
        "totalServiceFees": 0,
        "promotionalRebates": -1.53,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.979999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -16.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.42999999999999,
        "totalSellingFees": -16.45,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.42999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-27": {
        "mfOrderCount": 0,
        "roi": 212.8708245736977,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 133.93999999999997,
        "customerReturnTotal": 0,
        "totalTransactionFees": -19.409999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002460768",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451854",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461374",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428577",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451688",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-27",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 133.93999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 91.12999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.34915708812260526,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -19.409999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -42.809999999999995,
        "totalSellingFees": -23.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -42.809999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-26": {
        "mfOrderCount": 0,
        "roi": 82.08333333333331,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 17.48,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.28,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002427899",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486110",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-26",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 17.48,
        "lostWarehouseQty": 0,
        "netProfit": 7.879999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.03019157088122605,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.28,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.32,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.600000000000001,
        "totalSellingFees": -5.32,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.600000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-24": {
        "mfOrderCount": 0,
        "roi": 98.06615776081424,
        "shippingCredits": 1.33,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 82.83,
        "customerReturnTotal": 0,
        "totalTransactionFees": -22.760000000000005,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451862",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002367903",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002360618",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427878",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002460768",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "KA-A1E7-PU20",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-24",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 77.84,
        "lostWarehouseQty": 0,
        "netProfit": 38.54,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -4.99,
        "dailyNetProfit": 0.14766283524904214,
        "totalServiceFees": 0,
        "promotionalRebates": -1.33,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -22.760000000000005,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0.8,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.34,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.300000000000004,
        "totalSellingFees": -16.54,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.300000000000004,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-23": {
        "mfOrderCount": 0,
        "roi": 86.14903957417268,
        "shippingCredits": 24.39,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 136.48000000000002,
        "customerReturnTotal": 0,
        "totalTransactionFees": -66.32,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002460768",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419758",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-Y2TL-VZB3",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002460781",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-23",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 24.39,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 160.87,
        "lostWarehouseQty": 0,
        "netProfit": 74.45000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.28524904214559393,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -66.32,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.099999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -86.41999999999999,
        "totalSellingFees": -20.099999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -86.41999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-22": {
        "mfOrderCount": 0,
        "roi": 261.04083845319843,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 199.8,
        "customerReturnTotal": 0,
        "totalTransactionFees": -19.97,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461367",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451934",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "O1-MH7I-EZYO",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "BM-09R4-S7OL",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1A-VSRW-L8AO",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-22",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 199.8,
        "lostWarehouseQty": 0,
        "netProfit": 144.46,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.5534865900383142,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -19.97,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -35.37,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -55.339999999999996,
        "totalSellingFees": -35.37,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -55.339999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-21": {
        "mfOrderCount": 0,
        "roi": 1.8749999999999878,
        "shippingCredits": 2.53,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 3.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.45,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002368272",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-21",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.53,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 6.52,
        "lostWarehouseQty": 0,
        "netProfit": 0.11999999999999922,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.00045977011494252574,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.45,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -1.95,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -6.4,
        "totalSellingFees": -1.95,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -6.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-20": {
        "mfOrderCount": 0,
        "roi": 70.49455155071249,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 40.68,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.5,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "QI-OU4E-18AR",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436648",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427913",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IF-CNQL-D9DQ",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-20",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 40.68,
        "lostWarehouseQty": 0,
        "netProfit": 16.82,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.06444444444444444,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.5,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.360000000000001,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.86,
        "totalSellingFees": -11.360000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.86,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-29": {
        "mfOrderCount": 0,
        "roi": 96.31027253668762,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 90.14999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -24.459999999999994,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451565",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002477435",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486264",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "D0-4BWW-ER22",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491351",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491060",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486184",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-29",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.49,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 93.63999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 45.93999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.17601532567049802,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -24.459999999999994,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.24,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -47.69999999999999,
        "totalSellingFees": -23.24,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -47.69999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-07-28": {
        "mfOrderCount": 0,
        "roi": 207.84007352941174,
        "shippingCredits": 15.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 318.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -52.45,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461312",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486085",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002477460",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486261",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470062",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "WI-AHEY-9EJA",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002360658",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419777",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428992",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491102",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-07-28",
        "fbaOrderCount": 12,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 15.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 334.93,
        "lostWarehouseQty": 0,
        "netProfit": 226.13,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.8663984674329501,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -52.45,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -56.35000000000001,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -108.80000000000001,
        "totalSellingFees": -56.35000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -108.80000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-28": {
        "mfOrderCount": 0,
        "roi": 124.6466930469192,
        "shippingCredits": 4.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 74.5,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.45,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003582626",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002728858",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486099",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582689",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002729011",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491390",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-28",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 79.48,
        "lostWarehouseQty": 0,
        "netProfit": 44.10000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.16896551724137934,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.45,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.93,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.379999999999995,
        "totalSellingFees": -17.93,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.379999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-29": {
        "mfOrderCount": 0,
        "roi": 35.96730245231608,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 4.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -2.67,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "KA-A1E7-PU20",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-06-29",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 4.99,
        "lostWarehouseQty": 0,
        "netProfit": 1.3200000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.005057471264367818,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -2.67,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -1,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -3.67,
        "totalSellingFees": -1,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -3.67,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-28": {
        "mfOrderCount": 0,
        "roi": 121.74721189591078,
        "shippingCredits": 1.15,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 1,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 29.46,
        "customerReturnTotal": 6.33,
        "totalTransactionFees": -7.42,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002470103",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565330",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517033",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600980",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 6.33,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-28",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 35.79,
        "lostWarehouseQty": 0,
        "netProfit": 19.65,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07528735632183907,
        "totalServiceFees": 0,
        "promotionalRebates": -1.15,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.42,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.719999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -16.14,
        "totalSellingFees": -8.719999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -16.14,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-29": {
        "mfOrderCount": 0,
        "roi": 140.7549807759525,
        "shippingCredits": 6.25,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 62.56,
        "customerReturnTotal": 0,
        "totalTransactionFees": -15.169999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "7Z-0Y0K-MOAU",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002683146",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002361202",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "26-YQPN-HQ1I",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 1.34,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-29",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 1.34,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 68.88000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 40.27000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.15429118773946365,
        "totalServiceFees": 0,
        "promotionalRebates": -1.27,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -15.169999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 2,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.44,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.61,
        "totalSellingFees": -13.44,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.61,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-22": {
        "mfOrderCount": 0,
        "roi": 69.67522658610275,
        "shippingCredits": 4.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 39.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -15.93,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "8H-ZKKL-CPZE",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564812",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-22",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 44.93000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 18.450000000000006,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07068965517241382,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -15.93,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -10.55,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -26.48,
        "totalSellingFees": -10.55,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.48,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-23": {
        "mfOrderCount": 0,
        "roi": 227.06077015643808,
        "shippingCredits": 9.38,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 209.75,
        "customerReturnTotal": 0,
        "totalTransactionFees": -29.61,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002650652",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451923",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002622541",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "AX-IMW5-YYE0",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486239",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470130",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555567",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-23",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 7.680000000000001,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 217.43,
        "lostWarehouseQty": 0,
        "netProfit": 150.95000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.578352490421456,
        "totalServiceFees": 0,
        "promotionalRebates": -1.7,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -29.61,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -36.87,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -66.47999999999999,
        "totalSellingFees": -36.87,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -66.47999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-20": {
        "mfOrderCount": 0,
        "roi": 104.68545216251641,
        "shippingCredits": 1.59,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 62.470000000000006,
        "customerReturnTotal": 0,
        "totalTransactionFees": -13.05,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "3R-KAJN-7GY6",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002527538",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663348",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603121",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002490977",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516904",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555532",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-20",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 62.470000000000006,
        "lostWarehouseQty": 0,
        "netProfit": 31.950000000000006,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1224137931034483,
        "totalServiceFees": 0,
        "promotionalRebates": -1.59,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -13.05,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.47,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -30.52,
        "totalSellingFees": -17.47,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -30.52,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-21": {
        "mfOrderCount": 0,
        "roi": 114.86973947895791,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 1,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 51.28,
        "customerReturnTotal": 2.33,
        "totalTransactionFees": -14.559999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "8A-E68O-9HSL",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491135",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1A-7W2H-LA7A",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543362",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 2.33,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-21",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 53.61,
        "lostWarehouseQty": 0,
        "netProfit": 28.66,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.10980842911877395,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -14.559999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -10.39,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -24.95,
        "totalSellingFees": -10.39,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.95,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-26": {
        "mfOrderCount": 0,
        "roi": 3737.7906976744166,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 12.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.21,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486087",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002622541",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-26",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -66.00999999999999,
        "lostWarehouseQty": 0,
        "netProfit": -64.28999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -78.99,
        "dailyNetProfit": -0.24632183908045974,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.21,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 9.48,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.55,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 1.7200000000000006,
        "totalSellingFees": 5.930000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 1.7200000000000006,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-27": {
        "mfOrderCount": 0,
        "roi": -594.908350305499,
        "shippingCredits": 1.49,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 3,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 19.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.95,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002535606",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564837",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517053",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600980",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002460768",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-27",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 2.19,
        "grossIncome": -24.300000000000004,
        "lostWarehouseQty": 0,
        "netProfit": -29.210000000000004,
        "shippingCreditRefunds": -2.19,
        "mfOrderRevenue": 0,
        "totalRefundCost": -44.27,
        "dailyNetProfit": -0.11191570881226055,
        "totalServiceFees": 0,
        "promotionalRebates": -1.49,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.95,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 7.989999999999999,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.95,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.910000000000001,
        "totalSellingFees": 2.039999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.910000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-24": {
        "mfOrderCount": 0,
        "roi": 79.70389805097449,
        "shippingCredits": 2.35,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 95.88999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -24.11,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516934",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486287",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CM-W7I0-8B04",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002641861",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IM-QXL0-GHDK",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470022",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436768",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551173",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "CF-9GH1-53BV",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-24",
        "fbaOrderCount": 14,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 95.88999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 42.52999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.16295019157088117,
        "totalServiceFees": 0,
        "promotionalRebates": -2.35,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -24.11,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -29.25,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -53.36,
        "totalSellingFees": -29.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -53.36,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-25": {
        "mfOrderCount": 0,
        "roi": 91.0309838738902,
        "shippingCredits": 6.3,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 124.11999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -34.25,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663348",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516892",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002630211",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451720",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-25",
        "fbaOrderCount": 14,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 6.3,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 105.42999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 50.239999999999995,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -24.99,
        "dailyNetProfit": 0.19249042145593867,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -34.25,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 3,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -23.939999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -55.19,
        "totalSellingFees": -20.939999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -55.19,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-12": {
        "mfOrderCount": 0,
        "roi": 1095.8370239149694,
        "shippingCredits": 1.63,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 94.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -13.78,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002550940",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003121151",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584059",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002361206",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003042488",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003186923",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224524",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469043",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-12",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -405.03000000000003,
        "lostWarehouseQty": 0,
        "netProfit": -371.16,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -499.99,
        "dailyNetProfit": -1.4220689655172414,
        "totalServiceFees": 0,
        "promotionalRebates": -1.63,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -13.78,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 70,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -22.350000000000005,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 33.86999999999999,
        "totalSellingFees": 47.64999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 33.86999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-13": {
        "mfOrderCount": 0,
        "roi": 149.79020979020976,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 71.43999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.879999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003006144",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "WL-9ARO-T0D9",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187296",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715473",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108154",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-13",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 71.43999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 42.83999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1641379310344827,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.879999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.72,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -28.599999999999998,
        "totalSellingFees": -17.72,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -28.599999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-21": {
        "mfOrderCount": 0,
        "roi": 132.63018322082934,
        "shippingCredits": 5.1,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 189.50000000000003,
        "customerReturnTotal": 0,
        "totalTransactionFees": -41.260000000000005,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003752488",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715459",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IM-QXL0-GHDK",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449269",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003120525",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546864",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002508896",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003054337",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003006209",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547014",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543423",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003335313",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H1-2K69-QR20",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003449365",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-21",
        "fbaOrderCount": 16,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.49,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 192.99000000000004,
        "lostWarehouseQty": 0,
        "netProfit": 110.03000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.42157088122605374,
        "totalServiceFees": 0,
        "promotionalRebates": -5.1,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -41.260000000000005,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -41.699999999999996,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -82.96000000000001,
        "totalSellingFees": -41.699999999999996,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -82.96000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-04": {
        "mfOrderCount": 0,
        "roi": 185.92828685258965,
        "shippingCredits": 3.56,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 175.86,
        "customerReturnTotal": 0,
        "totalTransactionFees": -31.560000000000002,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002605068",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555773",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "2V-D2UM-AZA4",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451523",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003782870",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914221",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-04",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.56,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 179.42000000000002,
        "lostWarehouseQty": 0,
        "netProfit": 116.67000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4470114942528736,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -31.560000000000002,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.19,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -62.75,
        "totalSellingFees": -31.19,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -62.75,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-20": {
        "mfOrderCount": 0,
        "roi": 230.3507880020335,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 64.97999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.22,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002546864",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451916",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224778",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-20",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 64.97999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 45.30999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.17360153256704977,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.22,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -19.669999999999998,
        "totalSellingFees": -12.45,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -19.669999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-06-30": {
        "mfOrderCount": 0,
        "roi": 37.7253814147018,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 9.93,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.21,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "QI-NUVK-181X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-W5IH-VZ3Q",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-06-30",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 9.93,
        "lostWarehouseQty": 0,
        "netProfit": 2.7199999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.010421455938697317,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.21,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -7.21,
        "totalSellingFees": -2,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -7.21,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-23": {
        "mfOrderCount": 0,
        "roi": 69.06504915289688,
        "shippingCredits": 16.48,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 66.22,
        "customerReturnTotal": 0,
        "totalTransactionFees": -30.560000000000002,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003867859",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002565219",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224574",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002975857",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003782844",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555695",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-23",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 14.61,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 80.83,
        "lostWarehouseQty": 0,
        "netProfit": 33.019999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1265134099616858,
        "totalServiceFees": 0,
        "promotionalRebates": -1.87,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -30.560000000000002,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.25,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -47.81,
        "totalSellingFees": -17.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -47.81,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-22": {
        "mfOrderCount": 0,
        "roi": 216.69214599656027,
        "shippingCredits": 10.01,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 317.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -50.45,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003727775",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003460860",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547014",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002604914",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002487667",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002728450",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543413",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-22",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 13.5,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 331.45,
        "lostWarehouseQty": 0,
        "netProfit": 226.79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.868927203065134,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -50.45,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -54.21,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -104.66,
        "totalSellingFees": -54.21,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -104.66,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-08": {
        "mfOrderCount": 0,
        "roi": -64.7272727272727,
        "shippingCredits": 4.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 3,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 59.93000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.24,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003590959",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002914689",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582967",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584364",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "2V-D2UM-AZA4",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002604999",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-08",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 8.730000000000004,
        "lostWarehouseQty": 0,
        "netProfit": -16.019999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -56.18000000000001,
        "dailyNetProfit": -0.06137931034482757,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.24,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 7.140000000000001,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.65,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -24.75,
        "totalSellingFees": -7.51,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -24.75,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-25": {
        "mfOrderCount": 0,
        "roi": 708.4680851063831,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 0,
        "customerReturnTotal": 0,
        "totalTransactionFees": 0,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002604914",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-25",
        "fbaOrderCount": 0,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -189.99,
        "lostWarehouseQty": 0,
        "netProfit": -166.49,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -189.99,
        "dailyNetProfit": -0.6378927203065134,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": 0,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 23.5,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": 0,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": 23.5,
        "totalSellingFees": 23.5,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": 23.5,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-24": {
        "mfOrderCount": 0,
        "roi": 181.08651911468814,
        "shippingCredits": 8.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 130.72,
        "customerReturnTotal": 0,
        "totalTransactionFees": -26.83,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1004188486",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003867924",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002527541",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-24",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 8.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 139.7,
        "lostWarehouseQty": 0,
        "netProfit": 90,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.3448275862068966,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -26.83,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -22.869999999999997,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -49.699999999999996,
        "totalSellingFees": -22.869999999999997,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -49.699999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-27": {
        "mfOrderCount": 0,
        "roi": 192.7769643728648,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 59.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.49,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1004215779",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-27",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 59.99,
        "lostWarehouseQty": 0,
        "netProfit": 39.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.15134099616858238,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.49,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.490000000000002,
        "totalSellingFees": -9,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.490000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-31": {
        "mfOrderCount": 0,
        "roi": 67.68856447688567,
        "shippingCredits": 1.93,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 34.46,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.719999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663368",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368504",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002641871",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486133",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "7E-BU6I-D0OJ",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-31",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 34.46,
        "lostWarehouseQty": 0,
        "netProfit": 13.910000000000004,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05329501915708814,
        "totalServiceFees": 0,
        "promotionalRebates": -1.93,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.719999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -10.83,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.549999999999997,
        "totalSellingFees": -10.83,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.549999999999997,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-30": {
        "mfOrderCount": 0,
        "roi": 282.9710144927536,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 116.27,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.219999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002731256",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491118",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537843",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-30",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 116.27,
        "lostWarehouseQty": 0,
        "netProfit": 85.91,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.32915708812260536,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.219999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.14,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -30.36,
        "totalSellingFees": -20.14,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -30.36,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-26": {
        "mfOrderCount": 0,
        "roi": 315.35181236673776,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 11.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003867892",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491164",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 7.49,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-26",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 7.49,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 19.48,
        "lostWarehouseQty": 0,
        "netProfit": 14.790000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05666666666666667,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 1,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.6899999999999995,
        "totalSellingFees": -3.15,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.6899999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-29": {
        "mfOrderCount": 0,
        "roi": 191.92439862542952,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 16.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.92,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003335219",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-29",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 16.99,
        "lostWarehouseQty": 0,
        "netProfit": 11.169999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.042796934865900374,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.92,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.9,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -5.82,
        "totalSellingFees": -3.9,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -5.82,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-18": {
        "mfOrderCount": 0,
        "roi": 290.90429738992884,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 148.27,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.99,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002508116",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003270087",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002833003",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-18",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 148.27,
        "lostWarehouseQty": 0,
        "netProfit": 110.34,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4227586206896552,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.99,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -24.939999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -37.93,
        "totalSellingFees": -24.939999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -37.93,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-28": {
        "mfOrderCount": 0,
        "roi": 127.4478330658106,
        "shippingCredits": 11.77,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 118.54999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -28.32,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1004226541",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486284",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003867904",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461265",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1004145237",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003940093",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555963",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003868315",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-28",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 8.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 127.52999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 71.46,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.27379310344827584,
        "totalServiceFees": 0,
        "promotionalRebates": -2.79,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -28.32,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -27.749999999999996,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -56.06999999999999,
        "totalSellingFees": -27.749999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -56.06999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-19": {
        "mfOrderCount": 0,
        "roi": 76.9877675840979,
        "shippingCredits": 1.47,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 67.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.979999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002584528",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003306179",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551125",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555898",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242003",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002973137",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605281",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516859",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-19",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.47,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 69.45,
        "lostWarehouseQty": 0,
        "netProfit": 30.210000000000008,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11574712643678164,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.979999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -21.259999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -39.239999999999995,
        "totalSellingFees": -21.259999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -39.239999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-16": {
        "mfOrderCount": 0,
        "roi": 148.1207289293849,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 58.12,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.88,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003769510",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537823",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003782784",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582626",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-16",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 43.56999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 26.00999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -14.55,
        "dailyNetProfit": 0.09965517241379307,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.88,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 1.74,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.420000000000002,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.560000000000002,
        "totalSellingFees": -9.680000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.560000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-24": {
        "mfOrderCount": 0,
        "roi": 109.79910391675094,
        "shippingCredits": 20.560000000000002,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 132.36999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -38.02,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003242450",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002583982",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427861",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "IM-QXL0-GHDK",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486133",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486068",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564744",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002509113",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-24",
        "fbaOrderCount": 12,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 12.790000000000003,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 145.15999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 75.96999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.29107279693486576,
        "totalServiceFees": 0,
        "promotionalRebates": -7.77,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -38.02,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.169999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -69.19,
        "totalSellingFees": -31.169999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -69.19,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-16": {
        "mfOrderCount": 0,
        "roi": 205.14304082288652,
        "shippingCredits": 1.21,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 94.92999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.01,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002555429",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012151",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002601005",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9G-24UY-ST11",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002601009",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-16",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 94.92999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 63.81999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.24452107279693483,
        "totalServiceFees": 0,
        "promotionalRebates": -1.21,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.01,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -19.1,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -31.11,
        "totalSellingFees": -19.1,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -31.11,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-17": {
        "mfOrderCount": 0,
        "roi": 8426.624737945494,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 1,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 9.99,
        "customerReturnTotal": 396.73,
        "totalTransactionFees": -1.92,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002410628",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469043",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 396.73,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-17",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 406.72,
        "lostWarehouseQty": 0,
        "netProfit": 401.95000000000005,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 1.5400383141762455,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.92,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.77,
        "totalSellingFees": -2.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.77,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-14": {
        "mfOrderCount": 0,
        "roi": 138.92848930788688,
        "shippingCredits": 4.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 98.92999999999999,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.549999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003006171",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187406",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002973151",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003032308",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469489",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012196",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663845",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-14",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 103.91,
        "lostWarehouseQty": 0,
        "netProfit": 60.42,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.23149425287356323,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.549999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -22.939999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -43.489999999999995,
        "totalSellingFees": -22.939999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -43.489999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-15": {
        "mfOrderCount": 0,
        "roi": 178.50467289719617,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 98.33999999999997,
        "customerReturnTotal": 0,
        "totalTransactionFees": -13.799999999999999,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "RO-M3RK-KJI4",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555949",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584293",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003012151",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224602",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003006240",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-15",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 98.33999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 63.02999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2414942528735631,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -13.799999999999999,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -21.51,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.31,
        "totalSellingFees": -21.51,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.31,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-08": {
        "mfOrderCount": 0,
        "roi": 112.02830188679245,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 17.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -3.08,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002516966",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470149",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-08",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 17.98,
        "lostWarehouseQty": 0,
        "netProfit": 9.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.03639846743295019,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -3.08,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -8.48,
        "totalSellingFees": -5.4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -8.48,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-09": {
        "mfOrderCount": 0,
        "roi": 120.94847566411121,
        "shippingCredits": 6.300000000000001,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 187.61999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -22.839999999999996,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002565184",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451569",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "13-WXO2-I0QN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600934",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584309",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491060",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469708",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555580",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469705",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584019",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461374",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-09",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 6.300000000000001,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 123.92999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 67.83999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -69.99,
        "dailyNetProfit": 0.2599233716475095,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -22.839999999999996,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 8.4,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -41.65,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -56.089999999999996,
        "totalSellingFees": -33.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -56.089999999999996,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-10": {
        "mfOrderCount": 0,
        "roi": 166.4979970482817,
        "shippingCredits": 8.56,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 141.41,
        "customerReturnTotal": 0,
        "totalTransactionFees": -25.159999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002360848",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224761",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002574663",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002535558",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451489",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002925962",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "7S-4XVW-JGI8",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003186913",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-10",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 126.4,
        "lostWarehouseQty": 0,
        "netProfit": 78.97,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -19.99,
        "dailyNetProfit": 0.30256704980842913,
        "totalServiceFees": 0,
        "promotionalRebates": -3.58,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -25.159999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 4.35,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -26.62,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -47.43,
        "totalSellingFees": -22.270000000000003,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -47.43,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-11": {
        "mfOrderCount": 0,
        "roi": 195.38904899135443,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 29.979999999999997,
        "customerReturnTotal": 0,
        "totalTransactionFees": -3.21,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002715408",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427867",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0.77,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-11",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0.77,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 30.749999999999996,
        "lostWarehouseQty": 0,
        "netProfit": 20.339999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07793103448275861,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -3.21,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 1,
        "subscriptionFees": 0,
        "fbaSellingFees": -7.199999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.41,
        "totalSellingFees": -7.199999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.41,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-04": {
        "mfOrderCount": 0,
        "roi": 122.96325102080503,
        "shippingCredits": 4.359999999999999,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 130.86,
        "customerReturnTotal": 0,
        "totalTransactionFees": -21.57,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002543283",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "4Q-0FR6-NKBJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469515",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491035",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517053",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002487656",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419780",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469762",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002575187",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554824",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002360618",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-04",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 114.67000000000002,
        "lostWarehouseQty": 0,
        "netProfit": 63.24000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -16.19,
        "dailyNetProfit": 0.24229885057471273,
        "totalServiceFees": 0,
        "promotionalRebates": -4.359999999999999,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -21.57,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 1.94,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.799999999999997,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -51.42999999999999,
        "totalSellingFees": -29.859999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -51.42999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-05": {
        "mfOrderCount": 0,
        "roi": 79.58633093525181,
        "shippingCredits": 6.82,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 117.84,
        "customerReturnTotal": 0,
        "totalTransactionFees": -34.25,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451188",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002477426",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543362",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002490968",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486072",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555864",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564707",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554704",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427883",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "TY-VKTW-3JU9",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555801",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "L3-D22R-2PLE",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-05",
        "fbaOrderCount": 16,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.9800000000000004,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 119.82000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 53.10000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.203448275862069,
        "totalServiceFees": 0,
        "promotionalRebates": -4.84,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -34.25,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -32.470000000000006,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -66.72,
        "totalSellingFees": -32.470000000000006,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -66.72,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-06": {
        "mfOrderCount": 0,
        "roi": 132.54221816522139,
        "shippingCredits": 2.1,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 50.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.96,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002584273",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491112",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "W0-USX7-JCWK",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584338",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "KA-A1E7-PU20",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-06",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 50.95,
        "lostWarehouseQty": 0,
        "netProfit": 29.040000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11126436781609196,
        "totalServiceFees": 0,
        "promotionalRebates": -2.1,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.96,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.95,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -21.91,
        "totalSellingFees": -11.95,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.91,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-07": {
        "mfOrderCount": 0,
        "roi": 117.64285714285714,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 30.47,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.38,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002537143",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584309",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584173",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-07",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 30.47,
        "lostWarehouseQty": 0,
        "netProfit": 16.47,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.06310344827586206,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.38,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.62,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -14,
        "totalSellingFees": -8.62,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -14,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-01": {
        "mfOrderCount": 0,
        "roi": 36.643835616438366,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 15.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.68,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-01",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 15.96,
        "lostWarehouseQty": 0,
        "netProfit": 4.280000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.016398467432950196,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.68,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -11.68,
        "totalSellingFees": -4,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -11.68,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-02": {
        "mfOrderCount": 0,
        "roi": 92.91270527225585,
        "shippingCredits": 4.3999999999999995,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 63.150000000000006,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.13,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451867",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "8A-NIHX-9I1Y",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "TU-0BJJ-JYB3",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002380433",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554620",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554713",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537000",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "QI-NUVK-181X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0.32,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-02",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.49,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 66.96,
        "lostWarehouseQty": 1,
        "netProfit": 32.25,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1235632183908046,
        "totalServiceFees": 0,
        "promotionalRebates": -4.3999999999999995,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.13,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.58,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -34.709999999999994,
        "totalSellingFees": -17.58,
        "giftWrapCredits": 3.49,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -34.709999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0.32
      },
      "2015-08-03": {
        "mfOrderCount": 0,
        "roi": 124.96838301716353,
        "shippingCredits": 3.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 120.53,
        "customerReturnTotal": 0,
        "totalTransactionFees": -28.369999999999997,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002490968",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "HF-45OJ-X650",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "OT-5UUJ-RVCC",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1A-FIFO-L8J9",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0L-SLJ7-RYZD",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428524",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555590",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491133",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-03",
        "fbaOrderCount": 11,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 124.52,
        "lostWarehouseQty": 0,
        "netProfit": 69.17,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.2650191570881226,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -28.369999999999997,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -26.98,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -55.349999999999994,
        "totalSellingFees": -26.98,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -55.349999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-10": {
        "mfOrderCount": 0,
        "roi": 136.4417705199828,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 55.019999999999996,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.81,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002546864",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003473986",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451176",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-10",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 55.019999999999996,
        "lostWarehouseQty": 0,
        "netProfit": 31.749999999999996,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.12164750957854405,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.81,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.459999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.27,
        "totalSellingFees": -11.459999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.27,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-26": {
        "mfOrderCount": 0,
        "roi": 109.43396226415096,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 9.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.92,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002832909",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-26",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 9.99,
        "lostWarehouseQty": 0,
        "netProfit": 5.220000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.020000000000000004,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.92,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.77,
        "totalSellingFees": -2.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.77,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-22": {
        "mfOrderCount": 0,
        "roi": 109.84102952308861,
        "shippingCredits": 3.41,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 55.440000000000005,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003120626",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003144959",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003006181",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003187326",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486195",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002367915",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-22",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 55.440000000000005,
        "lostWarehouseQty": 0,
        "netProfit": 29.020000000000007,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.11118773946360155,
        "totalServiceFees": 0,
        "promotionalRebates": -3.41,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -16.419999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -26.419999999999998,
        "totalSellingFees": -16.419999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.419999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-30": {
        "mfOrderCount": 0,
        "roi": 153.30337078651687,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 56.36,
        "customerReturnTotal": 0,
        "totalTransactionFees": -11.1,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584246",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451552",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H8-Q28U-TY0M",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-30",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 56.36,
        "lostWarehouseQty": 0,
        "netProfit": 34.11,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1306896551724138,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -11.1,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -22.25,
        "totalSellingFees": -11.15,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -22.25,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-11": {
        "mfOrderCount": 0,
        "roi": 176.732249786142,
        "shippingCredits": 2.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 129.4,
        "customerReturnTotal": 0,
        "totalTransactionFees": -21.840000000000003,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002584306",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003335445",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002342482",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003582856",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002527527",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5T-QNLP-N9DN",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-11",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 129.4,
        "lostWarehouseQty": 0,
        "netProfit": 82.64,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.3166283524904215,
        "totalServiceFees": 0,
        "promotionalRebates": -2.99,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -21.840000000000003,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -24.919999999999998,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -46.760000000000005,
        "totalSellingFees": -24.919999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -46.760000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-13": {
        "mfOrderCount": 0,
        "roi": 169.18586789554536,
        "shippingCredits": 9.010000000000002,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 166.23000000000002,
        "customerReturnTotal": 0,
        "totalTransactionFees": -33.239999999999995,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002436657",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "41-HGEK-UAQP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603201",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H8-Q28U-TY0M",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491060",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002640923",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-13",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 9.010000000000002,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 175.24,
        "lostWarehouseQty": 0,
        "netProfit": 110.14000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.42199233716475104,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -33.239999999999995,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.86,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -65.1,
        "totalSellingFees": -31.86,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -65.1,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-12": {
        "mfOrderCount": 0,
        "roi": 73.09644670050766,
        "shippingCredits": 8.04,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 60.92,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.509999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002490990",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605316",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "G5-K28K-U8RJ",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "KV-HZ0V-ZHVN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002419763",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600945",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491054",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543362",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-12",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 6.749999999999999,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 61.38,
        "lostWarehouseQty": 0,
        "netProfit": 25.92000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -6.29,
        "dailyNetProfit": 0.09931034482758624,
        "totalServiceFees": 0,
        "promotionalRebates": -1.29,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.509999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.29,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -17.24,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.459999999999994,
        "totalSellingFees": -14.95,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.459999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-11": {
        "mfOrderCount": 0,
        "roi": 58.155676747645,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 31.9,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.97,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002469705",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002517322",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600970",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-W5IH-VZ3Q",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-11",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 31.9,
        "lostWarehouseQty": 0,
        "netProfit": 11.729999999999997,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.04494252873563217,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.97,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.2,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.17,
        "totalSellingFees": -9.2,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.17,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-06": {
        "mfOrderCount": 0,
        "roi": 165.5552415936705,
        "shippingCredits": 6.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 85.85,
        "customerReturnTotal": 0,
        "totalTransactionFees": -17.11,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "UJ-C745-D7S0",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002361208",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715503",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486097",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108097",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486035",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368499",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 3.14,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-06",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 93.97999999999999,
        "lostWarehouseQty": 2,
        "netProfit": 58.58999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.22448275862068962,
        "totalServiceFees": 0,
        "promotionalRebates": -1.99,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -17.11,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -18.28,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.39,
        "totalSellingFees": -18.28,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.39,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 3.14
      },
      "2015-08-17": {
        "mfOrderCount": 0,
        "roi": 145.33451571583407,
        "shippingCredits": 12.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 236.01000000000002,
        "customerReturnTotal": 0,
        "totalTransactionFees": -60.68,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002650499",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002361230",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486064",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002543300",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368609",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002640897",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-17",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 12.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 248.99,
        "lostWarehouseQty": 0,
        "netProfit": 147.5,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.5651340996168582,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -60.68,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -40.81,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -101.49000000000001,
        "totalSellingFees": -40.81,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -101.49000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-16": {
        "mfOrderCount": 0,
        "roi": 163.15179606025495,
        "shippingCredits": 12.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 145.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -31.65,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002486278",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002508891",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605225",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5T-QNLP-N9DN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491294",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491134",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002640868",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-16",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 12.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 158.97,
        "lostWarehouseQty": 0,
        "netProfit": 98.56,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.3776245210727969,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -31.65,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -28.76,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -60.41,
        "totalSellingFees": -28.76,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -60.41,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-15": {
        "mfOrderCount": 0,
        "roi": 209.64155645006707,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 160.22,
        "customerReturnTotal": 0,
        "totalTransactionFees": -21.38,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002509012",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555756",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "HQ-VTKS-JZTP",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0L-0X3S-RYXU",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002486198",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555441",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "KA-A1E7-PU20",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 1.32,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-15",
        "fbaOrderCount": 7,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 161.54,
        "lostWarehouseQty": 1,
        "netProfit": 109.36999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.4190421455938697,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -21.38,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -30.79,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -52.17,
        "totalSellingFees": -30.79,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -52.17,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 1.32
      },
      "2015-08-14": {
        "mfOrderCount": 0,
        "roi": 83.96567299006324,
        "shippingCredits": 2.19,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 40.730000000000004,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.38,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002584266",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600980",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002509012",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555508",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-14",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 40.730000000000004,
        "lostWarehouseQty": 0,
        "netProfit": 18.590000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07122605363984676,
        "totalServiceFees": 0,
        "promotionalRebates": -2.19,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.38,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -11.760000000000002,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -22.14,
        "totalSellingFees": -11.760000000000002,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -22.14,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-19": {
        "mfOrderCount": 0,
        "roi": 182.89623717217785,
        "shippingCredits": 2.06,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 99.23999999999998,
        "customerReturnTotal": 0,
        "totalTransactionFees": -14.79,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002477452",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "D3-7CDF-YCVW",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002436718",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002527593",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "LR-828C-VZAY",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-19",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 99.23999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 64.15999999999998,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.24582375478927196,
        "totalServiceFees": 0,
        "promotionalRebates": -2.06,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -14.79,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.29,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.08,
        "totalSellingFees": -20.29,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.08,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-18": {
        "mfOrderCount": 0,
        "roi": 159.86523842432626,
        "shippingCredits": 12.14,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 153.89000000000001,
        "customerReturnTotal": 0,
        "totalTransactionFees": -29.369999999999994,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002491385",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002535633",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603222",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600981",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002536996",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "9N-8PB7-W181",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555508",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-18",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 6.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 150.41000000000003,
        "lostWarehouseQty": 0,
        "netProfit": 92.53000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -10.47,
        "dailyNetProfit": 0.35452107279693496,
        "totalServiceFees": 0,
        "promotionalRebates": -5.15,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -29.369999999999994,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.92,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -31.43,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -57.879999999999995,
        "totalSellingFees": -28.509999999999998,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -57.879999999999995,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-09": {
        "mfOrderCount": 0,
        "roi": 185.62043795620434,
        "shippingCredits": 2.32,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 75.94,
        "customerReturnTotal": 0,
        "totalTransactionFees": -14.66,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002342682",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003108141",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-09",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.32,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 78.25999999999999,
        "lostWarehouseQty": 0,
        "netProfit": 50.85999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.19486590038314172,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -14.66,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.74,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -27.4,
        "totalSellingFees": -12.74,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -27.4,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-10-08": {
        "mfOrderCount": 0,
        "roi": 67.3758865248227,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 25.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.16,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002715310",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "F9-6ODS-XRAF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537126",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564792",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-10-08",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 25.96,
        "lostWarehouseQty": 0,
        "netProfit": 10.450000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.04003831417624522,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.16,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.35,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -15.51,
        "totalSellingFees": -8.35,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -15.51,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-08": {
        "mfOrderCount": 0,
        "roi": 1283.143507972665,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 1,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 9.99,
        "customerReturnTotal": 50.73,
        "totalTransactionFees": -1.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663807",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002622658",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 50.73,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-08",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 60.72,
        "lostWarehouseQty": 0,
        "netProfit": 56.33,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.21582375478927202,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -4.390000000000001,
        "totalSellingFees": -2.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -4.390000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-09": {
        "mfOrderCount": 0,
        "roi": 197.57174392935988,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 26.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.3,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "NQ-4FQF-S5SC",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-09",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.96,
        "lostWarehouseQty": 0,
        "netProfit": 17.900000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.0685823754789272,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.3,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.76,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.059999999999999,
        "totalSellingFees": -2.76,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.059999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-11": {
        "mfOrderCount": 0,
        "roi": 98.15512048192772,
        "shippingCredits": 2.98,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 57.940000000000005,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.350000000000001,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003006152",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605318",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002683130",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002728558",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003186978",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605261",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550859",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-11",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 2.98,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 52.63000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 26.070000000000007,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -8.29,
        "dailyNetProfit": 0.0998850574712644,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.350000000000001,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.59,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -16.8,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -26.560000000000002,
        "totalSellingFees": -14.21,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.560000000000002,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-02": {
        "mfOrderCount": 0,
        "roi": 175.06509112757865,
        "shippingCredits": 11.480000000000002,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 132.36,
        "customerReturnTotal": 0,
        "totalTransactionFees": -24.66,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003144982",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002574663",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428956",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002546974",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002547048",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "F2-JAY7-UJNM",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321707",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-02",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.980000000000002,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 137.34,
        "lostWarehouseQty": 0,
        "netProfit": 87.41000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.334904214559387,
        "totalServiceFees": 0,
        "promotionalRebates": -6.5,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -24.66,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -25.269999999999996,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -49.92999999999999,
        "totalSellingFees": -25.269999999999996,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -49.92999999999999,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-03": {
        "mfOrderCount": 0,
        "roi": 97.83509903270384,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 42.95,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.46,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "F9-6ODS-XRAF",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428568",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002729500",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002368491",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663528",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-03",
        "fbaOrderCount": 5,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 42.95,
        "lostWarehouseQty": 0,
        "netProfit": 21.240000000000002,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.0813793103448276,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.46,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.25,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -21.71,
        "totalSellingFees": -12.25,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -21.71,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-01": {
        "mfOrderCount": 0,
        "roi": 381.1357074109721,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 49.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.54,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002605319",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-01",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 49.99,
        "lostWarehouseQty": 0,
        "netProfit": 39.6,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.15172413793103448,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.54,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -8.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.39,
        "totalSellingFees": -8.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.39,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-06": {
        "mfOrderCount": 0,
        "roi": 210.8118361153263,
        "shippingCredits": 10.18,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 2,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 111.91,
        "customerReturnTotal": 0,
        "totalTransactionFees": -12.72,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002360987",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002554752",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537010",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003224492",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002428568",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002600960",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-06",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 81.93,
        "lostWarehouseQty": 0,
        "netProfit": 55.57000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -29.979999999999997,
        "dailyNetProfit": 0.21291187739463605,
        "totalServiceFees": 0,
        "promotionalRebates": -10.18,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -12.72,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 7.199999999999999,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.84,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -26.36,
        "totalSellingFees": -13.64,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.36,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-07": {
        "mfOrderCount": 0,
        "roi": 81.79682912507339,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 30.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -9.18,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1003186837",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002555978",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-07",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 30.96,
        "lostWarehouseQty": 0,
        "netProfit": 13.93,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.05337164750957854,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -9.18,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -7.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -17.03,
        "totalSellingFees": -7.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -17.03,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-04": {
        "mfOrderCount": 0,
        "roi": 126.26062322946179,
        "shippingCredits": 5.93,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 73.94,
        "customerReturnTotal": 0,
        "totalTransactionFees": -20.15,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002508896",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002832931",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469587",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0L-SLJ7-RYZD",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003031644",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-04",
        "fbaOrderCount": 6,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 5.93,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 79.87,
        "lostWarehouseQty": 0,
        "netProfit": 44.57000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.17076628352490425,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -20.15,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -15.149999999999999,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -35.3,
        "totalSellingFees": -15.149999999999999,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -35.3,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-11-05": {
        "mfOrderCount": 0,
        "roi": 163.30767436435733,
        "shippingCredits": 3.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 108.89,
        "customerReturnTotal": 0,
        "totalTransactionFees": -22.47,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321610",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451014",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002537045",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-11-05",
        "fbaOrderCount": 10,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 3.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 112.88,
        "lostWarehouseQty": 0,
        "netProfit": 70.00999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.26823754789272025,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -22.47,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -20.400000000000002,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -42.870000000000005,
        "totalSellingFees": -20.400000000000002,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -42.870000000000005,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-08-10": {
        "mfOrderCount": 0,
        "roi": 200.17361111111111,
        "shippingCredits": 1.2,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 55.959999999999994,
        "customerReturnTotal": 0,
        "totalTransactionFees": -10.59,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002537106",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002451461",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002564951",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0S-D2Q1-V6VH",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 12,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-08-10",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 1.2,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 69.16,
        "lostWarehouseQty": 1,
        "netProfit": 46.12,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.17670498084291186,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -10.59,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -12.45,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -23.04,
        "totalSellingFees": -12.45,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -23.04,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 12
      },
      "2015-12-30": {
        "mfOrderCount": 0,
        "roi": 187.1647509578544,
        "shippingCredits": 1.02,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 29.979999999999997,
        "customerReturnTotal": 0,
        "totalTransactionFees": -4.59,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002487592",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-30",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 29.979999999999997,
        "lostWarehouseQty": 0,
        "netProfit": 19.54,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.07486590038314175,
        "totalServiceFees": 0,
        "promotionalRebates": -1.02,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -4.59,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -5.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -10.44,
        "totalSellingFees": -5.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -10.44,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-31": {
        "mfOrderCount": 0,
        "roi": 204.43524541691312,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 51.480000000000004,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.84,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002546864",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1004249962",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003460849",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-31",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 51.480000000000004,
        "lostWarehouseQty": 0,
        "netProfit": 34.57000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1324521072796935,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.84,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.07,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -16.91,
        "totalSellingFees": -9.07,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -16.91,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-29": {
        "mfOrderCount": 0,
        "roi": 82.6600609756098,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 67.92,
        "customerReturnTotal": 0,
        "totalTransactionFees": -16.439999999999998,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002516474",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002469733",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002491065",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002400216",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-29",
        "fbaOrderCount": 8,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 47.93000000000001,
        "lostWarehouseQty": 0,
        "netProfit": 21.69000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -19.99,
        "dailyNetProfit": 0.08310344827586211,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -16.439999999999998,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 4.35,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -14.15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -26.24,
        "totalSellingFees": -9.8,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -26.24,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-28": {
        "mfOrderCount": 0,
        "roi": -5413.541666666671,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 18.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -5.17,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002427813",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "H8-Q28U-TY0M",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002622658",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-28",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": -51.00999999999999,
        "lostWarehouseQty": 0,
        "netProfit": -51.96999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -69.99,
        "dailyNetProfit": -0.19911877394636013,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -5.17,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 8.4,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -4.1899999999999995,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -0.9599999999999991,
        "totalSellingFees": 4.210000000000001,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -0.9599999999999991,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-12-02": {
        "mfOrderCount": 0,
        "roi": 155.70057581573903,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 148.21000000000004,
        "customerReturnTotal": 0,
        "totalTransactionFees": -25.709999999999994,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002461031",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003752446",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "2V-D2UM-AZA4",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003782977",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003591024",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002470310",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002605238",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "0I-3CKH-8D0X",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1003321610",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-12-02",
        "fbaOrderCount": 9,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 133.22000000000003,
        "lostWarehouseQty": 0,
        "netProfit": 81.12000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -14.99,
        "dailyNetProfit": 0.31080459770114954,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -25.709999999999994,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.25,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -28.64,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -52.099999999999994,
        "totalSellingFees": -26.39,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -52.099999999999994,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-25": {
        "mfOrderCount": 0,
        "roi": 839.5973154362416,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 18.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": 1.3600000000000003,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002901596",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715530",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-25",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": -4.99,
        "fbaTransactionFeeRefunds": 4.99,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 13.999999999999998,
        "lostWarehouseQty": 0,
        "netProfit": 12.509999999999998,
        "shippingCreditRefunds": -4.99,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.047931034482758615,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -3.63,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -2.85,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -1.4899999999999998,
        "totalSellingFees": -2.85,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -1.4899999999999998,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-24": {
        "mfOrderCount": 0,
        "roi": 70.30276046304542,
        "shippingCredits": 4.99,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 33.26,
        "customerReturnTotal": 0,
        "totalTransactionFees": -13.32,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002565351",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "5T-QNLP-N9DN",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002715530",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002641993",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-24",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 4.99,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 38.25,
        "lostWarehouseQty": 0,
        "netProfit": 15.79,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.060498084291187734,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -13.32,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -9.14,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -22.46,
        "totalSellingFees": -9.14,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -22.46,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-27": {
        "mfOrderCount": 0,
        "roi": 75.61950439648281,
        "shippingCredits": 3.14,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 21.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.51,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451252",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "6I-IIIQ-GJ8Q",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-27",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 21.97,
        "lostWarehouseQty": 0,
        "netProfit": 9.459999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.036245210727969344,
        "totalServiceFees": 0,
        "promotionalRebates": -3.14,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.51,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -6,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -12.51,
        "totalSellingFees": -6,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -12.51,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-26": {
        "mfOrderCount": 0,
        "roi": 103.39366515837102,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 26.97,
        "customerReturnTotal": 0,
        "totalTransactionFees": -6.51,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002556020",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663175",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002901287",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-26",
        "fbaOrderCount": 3,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 26.97,
        "lostWarehouseQty": 0,
        "netProfit": 13.709999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.0525287356321839,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -6.51,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -6.75,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -13.26,
        "totalSellingFees": -6.75,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -13.26,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-21": {
        "mfOrderCount": 0,
        "roi": 144.3735035913807,
        "shippingCredits": 1.54,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 1,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 27.950000000000003,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.42,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002451408",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002550915",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663368",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002603305",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002427838",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 8.96,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-21",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 30.620000000000005,
        "lostWarehouseQty": 1,
        "netProfit": 18.090000000000003,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": -6.29,
        "dailyNetProfit": 0.06931034482758622,
        "totalServiceFees": 0,
        "promotionalRebates": -1.54,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.42,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 2.29,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -7.4,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -12.530000000000001,
        "totalSellingFees": -5.11,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -12.530000000000001,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 8.96
      },
      "2015-09-20": {
        "mfOrderCount": 0,
        "roi": 139.12591050988556,
        "shippingCredits": 1.59,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 22.98,
        "customerReturnTotal": 0,
        "totalTransactionFees": -3.46,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002469812",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002663934",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-20",
        "fbaOrderCount": 2,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 22.98,
        "lostWarehouseQty": 0,
        "netProfit": 13.370000000000001,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.051226053639846746,
        "totalServiceFees": 0,
        "promotionalRebates": -1.59,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -3.46,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -6.15,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -9.61,
        "totalSellingFees": -6.15,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -9.61,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-23": {
        "mfOrderCount": 0,
        "roi": 182.01058201058203,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 15.99,
        "customerReturnTotal": 0,
        "totalTransactionFees": -1.92,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002410672",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-23",
        "fbaOrderCount": 1,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 15.99,
        "lostWarehouseQty": 0,
        "netProfit": 10.32,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.039540229885057475,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -1.92,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -3.75,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -5.67,
        "totalSellingFees": -3.75,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -5.67,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      },
      "2015-09-22": {
        "mfOrderCount": 0,
        "roi": 230.74340527577934,
        "shippingCredits": 0,
        "lostInboundTotal": 0,
        "mfSellingFees": 0,
        "otherTransactionFeeRefunds": 0,
        "totalRefunds": 0,
        "fbaDisposalFees": 0,
        "customerReturnQty": 0,
        "costOfGoodsReturned": 0,
        "costOfGoodsDelivered": 0,
        "fbaOrderRevenue": 68.96,
        "customerReturnTotal": 0,
        "totalTransactionFees": -7.8,
        "chargebackTotal": 0,
        "rowsMissingBuyCostOrSupplier": [
          {
            "sku": "1002663356",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002551169",
            "supplier": null,
            "buy_cost": 0
          },
          {
            "sku": "1002584105",
            "supplier": null,
            "buy_cost": 0
          }
        ],
        "dateStart": "Apr 14, 2015",
        "dateEndYear": 2015,
        "longTermStorageFees": 0,
        "totalInventoryCredits": 0,
        "dateStartYear": 2015,
        "inboundShippingFees": 0,
        "otherTotal": 0,
        "label": "2015-09-22",
        "fbaOrderCount": 4,
        "damagedWarehouseTotal": 0,
        "giftWrapCreditRefunds": 0,
        "dateEnd": "Dec 31, 2015",
        "dateEndMonth": 12,
        "totalMiscellaneous": 0,
        "fbaTransactionFeeRefunds": 0,
        "mfLabelPurchases": 0,
        "promotionalRebateRefunds": 0,
        "grossIncome": 68.96,
        "lostWarehouseQty": 0,
        "netProfit": 48.10999999999999,
        "shippingCreditRefunds": 0,
        "mfOrderRevenue": 0,
        "totalRefundCost": 0,
        "dailyNetProfit": 0.1843295019157088,
        "totalServiceFees": 0,
        "promotionalRebates": 0,
        "guaranteeClaimsTotal": 0,
        "fbaTransactionFees": -7.8,
        "costOfGoodsAdjusted": 0,
        "sellingFeeRefunds": 0,
        "damagedWarehouseQty": 0,
        "subscriptionFees": 0,
        "fbaSellingFees": -13.05,
        "inventoryStorageFees": 0,
        "otherInventoryFees": 0,
        "otherTransactionFees": 0,
        "fbaLabelingPrepFees": 0,
        "otherServiceFees": 0,
        "lostInboundQty": 0,
        "totalInventoryFees": 0,
        "cogs": 0,
        "totalShippingFees": 0,
        "totalExpenses": -20.85,
        "totalSellingFees": -13.05,
        "giftWrapCredits": 0,
        "fbaReturnFees": 0,
        "totalExpensesWithoutCogs": -20.85,
        "otherQty": 0,
        "dateStartMonth": 4,
        "mfLabelRefunds": 0,
        "lostWarehouseTotal": 0
      }
    }
  },
  "incurred_expenses": [
    {
      "date_incurred": "2015-05-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-06-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-07-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-08-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-09-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-10-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-11-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-12-06",
      "amount": 3,
      "interval": "monthly",
      "id": 33,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-07-15",
      "amount": 101,
      "interval": "one-time",
      "id": 35,
      "expense_type": "test"
    },
    {
      "date_incurred": "2015-04-14",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-04-21",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-04-28",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-05-05",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-05-12",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-05-19",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-05-26",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-06-02",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-06-09",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-06-16",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-06-23",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-06-30",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-07-07",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-07-14",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-07-21",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-07-28",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-08-04",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-08-11",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-08-18",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-08-25",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-09-01",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-09-08",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-09-15",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-09-22",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-09-29",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-10-06",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-10-13",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-10-20",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-10-27",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-11-03",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-11-10",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-11-17",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-11-24",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-12-01",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-12-08",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-12-15",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-12-22",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    },
    {
      "date_incurred": "2015-12-29",
      "amount": 10,
      "interval": "weekly",
      "id": 36,
      "expense_type": "test2"
    }
  ],
  "reports_by_date": {
    "2015-11-19": {
      "mfOrderCount": 0,
      "roi": 107.73277866144615,
      "shippingCredits": 7.48,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 158.80999999999997,
      "customerReturnTotal": 0,
      "totalTransactionFees": -45.93000000000002,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003474215",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CT-GK75-BJ4A",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470057",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555470",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470112",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449318",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461223",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543365",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-19",
      "fbaOrderCount": 19,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 10.97,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 169.77999999999997,
      "lostWarehouseQty": 0,
      "netProfit": 32.96428571428564,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.33735632183908026,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -45.93000000000002,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -35.8,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -136.81571428571425,
      "totalSellingFees": -35.8,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -81.73000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-19T00:00:00+01:00"
    },
    "2015-11-18": {
      "mfOrderCount": 0,
      "roi": 60.66652952067477,
      "shippingCredits": 14.33,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 2,
      "fbaDisposalFees": -0.15,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 90.1,
      "customerReturnTotal": 0,
      "totalTransactionFees": -27.740000000000002,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003433729",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321746",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003031989",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461824",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537079",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914325",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003433788",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469560",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187091",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436752",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108097",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-18",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 11.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 78.1,
      "lostWarehouseQty": 0,
      "netProfit": -25.595714285714276,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -23.98,
      "dailyNetProfit": 0.11298850574712645,
      "totalServiceFees": 0,
      "promotionalRebates": -2.35,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -27.740000000000002,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 4.95,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -25.669999999999995,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.15,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -103.69571428571436,
      "totalSellingFees": -20.719999999999995,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -48.60999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-18T00:00:00+01:00"
    },
    "2015-07-18": {
      "mfOrderCount": 0,
      "roi": -24.026845637583897,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 16.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002400174",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "4C-JF55-H4NB",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-18",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 16.98,
      "lostWarehouseQty": 0,
      "netProfit": -60.45571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.020574712643678165,
      "totalServiceFees": -13.1,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.25,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -13.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -77.43571428571435,
      "totalSellingFees": -5.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -22.35,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-18T00:00:00+02:00"
    },
    "2015-07-19": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -64.58,
      "otherTotal": 0,
      "label": "2015-07-19",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -119.66571428571432,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.24743295019157088,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -64.58,
      "totalExpenses": -119.66571428571432,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -64.58,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-19T00:00:00+02:00"
    },
    "2015-07-16": {
      "mfOrderCount": 0,
      "roi": -90.25699414443721,
      "shippingCredits": 3.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 7.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.91,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002368011",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -91.5,
      "otherTotal": 0,
      "label": "2015-07-16",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 11.98,
      "lostWarehouseQty": 0,
      "netProfit": -166.06571428571394,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.42521072796934867,
      "totalServiceFees": -23,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.91,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.55,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -23,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -91.5,
      "totalExpenses": -178.0457142857138,
      "totalSellingFees": -2.55,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -122.96000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-16T00:00:00+02:00"
    },
    "2015-07-17": {
      "mfOrderCount": 0,
      "roi": -0.436800436800447,
      "shippingCredits": 8.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 77.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.379999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "2K-AU9I-O5PS",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "4N-FG1I-3YJA",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "BC-1OZ2-5E2L",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-17",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.5,
      "fbaTransactionFeeRefunds": 3.99,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 36.46999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -55.24571428571431,
      "shippingCreditRefunds": -3.99,
      "mfOrderRevenue": 0,
      "totalRefundCost": -44.99,
      "dailyNetProfit": -0.0006130268199233858,
      "totalServiceFees": -18.2,
      "promotionalRebates": -1.49,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -14.37,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 5.4,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -18.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -91.71571428571437,
      "totalSellingFees": -8.049999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -36.629999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-17T00:00:00+02:00"
    },
    "2015-11-13": {
      "mfOrderCount": 0,
      "roi": 325.18491124260345,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 114.96999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.09,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002352373",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002728593",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451860",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-13",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 114.96999999999998,
      "lostWarehouseQty": 0,
      "netProfit": 32.84428571428566,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.33689655172413785,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.09,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.95,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -82.12571428571437,
      "totalSellingFees": -19.95,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -27.04,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-13T00:00:00+01:00"
    },
    "2015-07-15": {
      "mfOrderCount": 0,
      "roi": -52.45821042281218,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 101,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 19.340000000000003,
      "customerReturnTotal": 0,
      "totalTransactionFees": -3.84,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002400356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "O1-GM01-EZWO",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -31.240000000000002,
      "miscExpenses": 101,
      "otherTotal": 0,
      "label": "2015-07-15",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 19.340000000000003,
      "lostWarehouseQty": 0,
      "netProfit": -177.4257142857139,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.08176245210727968,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -3.84,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.6,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -31.240000000000002,
      "totalExpenses": -196.76571428571384,
      "totalSellingFees": -5.6,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -40.68,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-15T00:00:00+02:00"
    },
    "2015-07-12": {
      "mfOrderCount": 0,
      "roi": -79.2204336836504,
      "shippingCredits": 3.35,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 12.27,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.8100000000000005,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "O8-WXGA-I7U3",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "B5-UZUD-269F",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -63.82000000000001,
      "otherTotal": 0,
      "label": "2015-07-12",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.35,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 15.62,
      "lostWarehouseQty": 0,
      "netProfit": -114.63571428571439,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.22816091954022996,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.8100000000000005,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -4.54,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -63.82000000000001,
      "totalExpenses": -130.2557142857143,
      "totalSellingFees": -4.54,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -75.17000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-12T00:00:00+02:00"
    },
    "2015-07-13": {
      "mfOrderCount": 0,
      "roi": 14.115983026874124,
      "shippingCredits": 5.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 76.07,
      "customerReturnTotal": 0,
      "totalTransactionFees": -22.509999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "KA-A1E7-PU20",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "U5-Y1TM-6S14",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "DB-XZ8G-1KYC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "BC-1OZ2-5E2L",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -33.91,
      "otherTotal": 0,
      "label": "2015-07-13",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.61,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 80.67999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -45.105714285714285,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.038237547892720325,
      "totalServiceFees": 0,
      "promotionalRebates": -1.37,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -22.509999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.28,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -33.91,
      "totalExpenses": -125.7857142857143,
      "totalSellingFees": -14.28,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -70.69999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-13T00:00:00+02:00"
    },
    "2015-11-17": {
      "mfOrderCount": 0,
      "roi": -10.558017956585982,
      "shippingCredits": 1.23,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 78.7,
      "customerReturnTotal": 0,
      "totalTransactionFees": -19.46,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002973297",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428575",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002410699",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003433740",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550898",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547048",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368594",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -48.07,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-11-17",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 78.7,
      "lostWarehouseQty": 0,
      "netProfit": -64.37571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.03559386973180079,
      "totalServiceFees": 0,
      "promotionalRebates": -1.23,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -19.46,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.46,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -48.07,
      "totalExpenses": -143.07571428571418,
      "totalSellingFees": -20.46,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -87.99000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-17T00:00:00+01:00"
    },
    "2015-07-11": {
      "mfOrderCount": 0,
      "roi": 216.12116991643452,
      "shippingCredits": 3.4400000000000004,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 181.58,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.91,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "9K-GLOK-CF62",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HJ42-UATT",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002342241",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -10.21,
      "otherTotal": 0,
      "label": "2015-07-11",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 181.58,
      "lostWarehouseQty": 0,
      "netProfit": 69.0542857142857,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.475632183908046,
      "totalServiceFees": 0,
      "promotionalRebates": -3.4400000000000004,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.91,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -29.94,
      "inventoryStorageFees": -0.38,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.38,
      "cogs": 0,
      "totalShippingFees": -10.21,
      "totalExpenses": -112.52571428571437,
      "totalSellingFees": -29.94,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -57.440000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-11T00:00:00+02:00"
    },
    "2015-11-27": {
      "mfOrderCount": 0,
      "roi": 181.41426783479352,
      "shippingCredits": 1.3,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": -0.15,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 44.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.13,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002550870",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449184",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-27",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 44.97,
      "lostWarehouseQty": 0,
      "netProfit": -26.095714285714276,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11107279693486591,
      "totalServiceFees": 0,
      "promotionalRebates": -1.3,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.13,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.7,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.15,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -71.06571428571434,
      "totalSellingFees": -9.7,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -15.979999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-27T00:00:00+01:00"
    },
    "2015-11-20": {
      "mfOrderCount": 0,
      "roi": 159.25563173359447,
      "shippingCredits": 1.53,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 1,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 13.98,
      "customerReturnTotal": 12.49,
      "totalTransactionFees": -7.01,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 12.49,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-20",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.47,
      "lostWarehouseQty": 0,
      "netProfit": -38.825714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.062298850574712634,
      "totalServiceFees": 0,
      "promotionalRebates": -1.53,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.01,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.2,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -65.29571428571431,
      "totalSellingFees": -3.2,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -10.21,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-20T00:00:00+01:00"
    },
    "2015-09-10": {
      "mfOrderCount": 0,
      "roi": 91.93341869398203,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 104.92999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -18.08,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451574",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002913194",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002913187",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551195",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -11.24,
      "otherTotal": 0,
      "label": "2015-09-10",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 104.92999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -4.825714285714331,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.19256704980842904,
      "totalServiceFees": -4.2,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -18.08,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -21.15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -4.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -11.24,
      "totalExpenses": -109.75571428571438,
      "totalSellingFees": -21.15,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -54.67,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-10T00:00:00+02:00"
    },
    "2015-09-11": {
      "mfOrderCount": 0,
      "roi": 158.6261980830671,
      "shippingCredits": 8.7,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 48.57,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.82,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002731327",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551164",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555765",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-11",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 48.57,
      "lostWarehouseQty": 0,
      "netProfit": -25.29571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11413793103448276,
      "totalServiceFees": 0,
      "promotionalRebates": -8.7,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.82,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.96,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -73.86571428571435,
      "totalSellingFees": -8.96,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -18.78,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-11T00:00:00+02:00"
    },
    "2015-09-12": {
      "mfOrderCount": 0,
      "roi": 18.364928909952596,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 34.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.59,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002428582",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663368",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-12",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 9.989999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -53.535714285714306,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -24.99,
      "dailyNetProfit": 0.005938697318007659,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.59,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 3,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -6.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -63.525714285714315,
      "totalSellingFees": -3.8499999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -8.44,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-12T00:00:00+02:00"
    },
    "2015-09-13": {
      "mfOrderCount": 0,
      "roi": 32.17787913340937,
      "shippingCredits": 2.27,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 57.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.3,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002630110",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469897",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451537",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600950",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -22.45,
      "otherTotal": 0,
      "label": "2015-09-13",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 57.96,
      "lostWarehouseQty": 0,
      "netProfit": -40.975714285714275,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.054061302681992364,
      "totalServiceFees": 0,
      "promotionalRebates": -2.27,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.3,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.1,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -22.45,
      "totalExpenses": -98.93571428571437,
      "totalSellingFees": -14.1,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -43.849999999999994,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-13T00:00:00+02:00"
    },
    "2015-09-14": {
      "mfOrderCount": 0,
      "roi": 149.89578991246353,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 59.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.59,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555815",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663826",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002832870",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564756",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-14",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 59.95,
      "lostWarehouseQty": 0,
      "netProfit": -19.125714285714285,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.13777777777777778,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.59,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -79.07571428571435,
      "totalSellingFees": -14.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -23.990000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-14T00:00:00+02:00"
    },
    "2015-09-15": {
      "mfOrderCount": 0,
      "roi": 103.65535248041773,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 110.18999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -14.629999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002603034",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002380447",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002640919",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584252",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002901575",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451379",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1A-7W2H-LA7A",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-09-15",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 70.19999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -19.355714285714296,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -39.99,
      "dailyNetProfit": 0.1368965517241379,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -14.629999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 4.8,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -24.64,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -89.55571428571437,
      "totalSellingFees": -19.84,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.47,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-15T00:00:00+02:00"
    },
    "2015-09-16": {
      "mfOrderCount": 0,
      "roi": 75.78219013237066,
      "shippingCredits": 6.6000000000000005,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 53.45,
      "customerReturnTotal": 0,
      "totalTransactionFees": -13.57,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002715391",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516737",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002913139",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469729",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603049",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-16",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 58.43000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -29.895714285714273,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.09651340996168584,
      "totalServiceFees": -4.9,
      "promotionalRebates": -1.62,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -13.57,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.77,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -4.9,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -88.32571428571437,
      "totalSellingFees": -14.77,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -33.24,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-16T00:00:00+02:00"
    },
    "2015-09-17": {
      "mfOrderCount": 0,
      "roi": 140.6467800390298,
      "shippingCredits": 21.4,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 130.82,
      "customerReturnTotal": 0,
      "totalTransactionFees": -37.4,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "RD-FIUI-XPQ8",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470130",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002820315",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002360801",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002901629",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "QP-IYI4-4G91",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5M-H559-K1TM",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 48.43,
      "dateStartYear": 2015,
      "inboundShippingFees": -18.56,
      "otherTotal": 0,
      "label": "2015-09-17",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 18.38,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 172.64,
      "lostWarehouseQty": 2,
      "netProfit": 45.814285714285674,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -24.99,
      "dailyNetProfit": 0.3865900383141762,
      "totalServiceFees": 0,
      "promotionalRebates": -3.02,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -37.4,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 3,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -18.779999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -18.56,
      "totalExpenses": -126.82571428571431,
      "totalSellingFees": -15.779999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -71.74,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 48.43,
      "date": "2015-09-17T00:00:00+02:00"
    },
    "2015-09-18": {
      "mfOrderCount": 0,
      "roi": 360.64018968583287,
      "shippingCredits": 6.35,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 543.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -36.160000000000004,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002731327",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469043",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427896",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-18",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 543.97,
      "lostWarehouseQty": 0,
      "netProfit": 370.7942857142849,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 1.6317241379310345,
      "totalServiceFees": 0,
      "promotionalRebates": -6.35,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -36.160000000000004,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -81.92999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -173.17571428571387,
      "totalSellingFees": -81.92999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -118.09,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-18T00:00:00+02:00"
    },
    "2015-09-19": {
      "mfOrderCount": 0,
      "roi": 96.04477611940301,
      "shippingCredits": 2.08,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 26.270000000000003,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.41,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002603137",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603305",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715515",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-19",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.270000000000003,
      "lostWarehouseQty": 0,
      "netProfit": -42.215714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.04931034482758622,
      "totalServiceFees": 0,
      "promotionalRebates": -2.08,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.41,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -7.99,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -68.48571428571431,
      "totalSellingFees": -7.99,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -13.4,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-19T00:00:00+02:00"
    },
    "2015-11-23": {
      "mfOrderCount": 0,
      "roi": 136.94602896007018,
      "shippingCredits": 5.48,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 102.51999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -25.71,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002360848",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "HQ-18B6-JZY7",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NQ-4FQF-S5SC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565224",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469726",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461783",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582851",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-23",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 5.48,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 107.99999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 7.334285714285665,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2391570881226053,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -25.71,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.87,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -100.66571428571437,
      "totalSellingFees": -19.87,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -45.58,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-23T00:00:00+01:00"
    },
    "2015-06-14": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-06-14",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -134.08571428571426,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.30268199233716475,
      "totalServiceFees": -79,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -79,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -134.08571428571426,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -79,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-14T00:00:00+02:00"
    },
    "2015-10-27": {
      "mfOrderCount": 0,
      "roi": 81.85644661054498,
      "shippingCredits": 5.84,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 76.25,
      "customerReturnTotal": 0,
      "totalTransactionFees": -26.019999999999996,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "F9-6ODS-XRAF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242249",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002593691",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012151",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224852",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003054342",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451613",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-10-27",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 5.84,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 82.09,
      "lostWarehouseQty": 0,
      "netProfit": -18.135714285714283,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.14157088122605366,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -26.019999999999996,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.12,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -100.22571428571437,
      "totalSellingFees": -19.12,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -45.14,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-27T00:00:00+01:00"
    },
    "2015-07-09": {
      "mfOrderCount": 0,
      "roi": 64.7491211249601,
      "shippingCredits": 8.9,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 48.57000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -15.649999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "BQ-TIXK-BTT8",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "I8-CDHD-A1L6",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "MN-F23X-SGSN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "VT-OXQU-G4U3",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "N1-24GE-YWJJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "6M-U83I-056G",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "G5-IF79-U8YJ",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-09",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.9800000000000004,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 51.55000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -34.82571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.07762452107279698,
      "totalServiceFees": 0,
      "promotionalRebates": -5.92,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -15.649999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -15.64,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -86.37571428571437,
      "totalSellingFees": -15.64,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -31.29,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-09T00:00:00+02:00"
    },
    "2015-07-08": {
      "mfOrderCount": 0,
      "roi": 66.47970479704796,
      "shippingCredits": 7.300000000000001,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 105.49,
      "customerReturnTotal": 0,
      "totalTransactionFees": -28.96,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "C8-7Q4H-1VKN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "4Q-0FR6-NKBJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-BU0U-VZD7",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "2V-W07A-AZLJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "Q3-WEFG-USKQ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0Z-JVS3-YEQ3",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "P4-Q0A3-EP7K",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9K-7AT8-CFAB",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5Q-AEQB-3NOQ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "WS-D6A0-W8B5",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -10.81,
      "otherTotal": 0,
      "label": "2015-07-08",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 7.300000000000001,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 112.78999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -10.045714285714308,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1725670498084291,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -28.96,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -27.98,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -10.81,
      "totalExpenses": -122.83571428571432,
      "totalSellingFees": -27.98,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -67.75,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-08T00:00:00+02:00"
    },
    "2015-06-18": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -4.77,
      "otherTotal": 0,
      "label": "2015-06-18",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -59.855714285714306,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.018275862068965514,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -4.77,
      "totalExpenses": -59.855714285714306,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -4.77,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-18T00:00:00+02:00"
    },
    "2015-07-05": {
      "mfOrderCount": 0,
      "roi": 171.0980622431004,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 230.84,
      "customerReturnTotal": 0,
      "totalTransactionFees": -24.11,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "04-9TA1-1XD5",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0E-9QJC-OR2J",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "EV-9ABP-RBQS",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "W7-JN1F-MKFE",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "13-B66X-I0QR",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -21.020000000000003,
      "otherTotal": 0,
      "label": "2015-07-05",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 230.84,
      "lostWarehouseQty": 0,
      "netProfit": 90.60428571428584,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.5581992337164751,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -24.11,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -40.02,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -21.020000000000003,
      "totalExpenses": -140.2357142857142,
      "totalSellingFees": -40.02,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -85.15,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-05T00:00:00+02:00"
    },
    "2015-07-04": {
      "mfOrderCount": 0,
      "roi": -39.140335895246224,
      "shippingCredits": 2.4299999999999997,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 42.76,
      "customerReturnTotal": 0,
      "totalTransactionFees": -18.509999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "F9-6ODS-XRAF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "AN-U4C4-C4GY",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "QI-NUVK-181X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-W5IH-VZ3Q",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H8-Q28U-TY0M",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -42.3,
      "otherTotal": 0,
      "label": "2015-07-04",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 42.76,
      "lostWarehouseQty": 0,
      "netProfit": -82.58571428571436,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.10536398467432948,
      "totalServiceFees": 0,
      "promotionalRebates": -2.4299999999999997,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -18.509999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -42.3,
      "totalExpenses": -125.34571428571431,
      "totalSellingFees": -9.45,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -70.25999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-04T00:00:00+02:00"
    },
    "2015-07-07": {
      "mfOrderCount": 0,
      "roi": -26.324969810814423,
      "shippingCredits": 3.2,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 54.91000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.14,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "6B-7HIR-DB3U",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "WP-4R1Z-CMAZ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "GU-QM4E-NIL0",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "U8-PFTC-QDWT",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "UM-04SU-WTFY",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9Y-XU2O-IV15",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "A2-FPOE-2GYW",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "22-KX18-Y40Z",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IF-CNQL-D9DQ",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -38.94,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-07-07",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 54.91000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -74.70571428571434,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.07517241379310341,
      "totalServiceFees": 0,
      "promotionalRebates": -3.2,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.14,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.449999999999996,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -38.94,
      "totalExpenses": -129.6157142857143,
      "totalSellingFees": -19.449999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -74.53,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-07T00:00:00+02:00"
    },
    "2015-07-06": {
      "mfOrderCount": 0,
      "roi": 65.99279977845471,
      "shippingCredits": 9.97,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 49.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -23.410000000000004,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "P0-XECC-V321",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NU-KQVE-BS0E",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "DE-4MUF-L6HT",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "6I-FGHK-GJ6A",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 3,
      "otherTotal": 0,
      "label": "2015-07-06",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 9.97,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 59.94,
      "lostWarehouseQty": 0,
      "netProfit": -31.25571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.09130268199233715,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -23.410000000000004,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.7,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -91.19571428571437,
      "totalSellingFees": -12.7,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -36.11,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-06T00:00:00+02:00"
    },
    "2015-07-01": {
      "mfOrderCount": 0,
      "roi": -57.75401069518716,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 11.06,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.62,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "WE-WTVU-PSAT",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LD-P78C-PJEI",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "SR-M8EI-K8NB",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -15.85,
      "otherTotal": 0,
      "label": "2015-07-01",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 11.06,
      "lostWarehouseQty": 0,
      "netProfit": -70.20571428571432,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.05793103448275862,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.62,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.71,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -15.85,
      "totalExpenses": -81.26571428571435,
      "totalSellingFees": -5.71,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.18,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-01T00:00:00+02:00"
    },
    "2015-11-21": {
      "mfOrderCount": 0,
      "roi": 86.25410733844467,
      "shippingCredits": 1.04,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 32.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -8.71,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663902",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003474502",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461022",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-21",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.04,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 34.01,
      "lostWarehouseQty": 0,
      "netProfit": -39.33571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.060344827586206885,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -8.71,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.55,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -73.34571428571434,
      "totalSellingFees": -9.55,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -18.26,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-21T00:00:00+01:00"
    },
    "2015-07-03": {
      "mfOrderCount": 0,
      "roi": -10.479041916167654,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 2.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "WS-BYGF-W81P",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-03",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 2.99,
      "lostWarehouseQty": 0,
      "netProfit": -55.435714285714305,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.001340996168582374,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -1.8,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -58.42571428571431,
      "totalSellingFees": -1.8,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -3.34,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-03T00:00:00+02:00"
    },
    "2015-07-02": {
      "mfOrderCount": 0,
      "roi": -31.64300202839756,
      "shippingCredits": 4.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 21.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -8.85,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "L3-D22R-2PLE",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "L3-Q3XT-2PQY",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -24.59,
      "otherTotal": 0,
      "label": "2015-07-02",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.96,
      "lostWarehouseQty": 0,
      "netProfit": -67.56571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.04781609195402298,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -8.85,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -6,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -24.59,
      "totalExpenses": -94.52571428571437,
      "totalSellingFees": -6,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -39.44,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-02T00:00:00+02:00"
    },
    "2015-10-24": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-24",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -128.2857142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.28045977011494255,
      "totalServiceFees": -73.2,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -73.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -128.2857142857143,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -73.2,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-24T00:00:00+02:00"
    },
    "2015-11-29": {
      "mfOrderCount": 0,
      "roi": 138.9918887601391,
      "shippingCredits": 1.37,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 82.5,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.43,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002487604",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003493697",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NQ-4FQF-S5SC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002575289",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451398",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517194",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-29",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 82.5,
      "lostWarehouseQty": 0,
      "netProfit": -7.1057142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1838314176245211,
      "totalServiceFees": 0,
      "promotionalRebates": -1.37,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.43,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -18.09,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -89.60571428571437,
      "totalSellingFees": -18.09,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.519999999999996,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-29T00:00:00+01:00"
    },
    "2015-09-07": {
      "mfOrderCount": 0,
      "roi": 78.68763557483734,
      "shippingCredits": 2.06,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 32.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -8.09,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002487639",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002664013",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "7Z-YTH1-MO80",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715413",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-07",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 32.95,
      "lostWarehouseQty": 0,
      "netProfit": -40.57571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.055593869731800784,
      "totalServiceFees": 0,
      "promotionalRebates": -2.06,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -8.09,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -10.35,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -73.52571428571434,
      "totalSellingFees": -10.35,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -18.439999999999998,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-07T00:00:00+02:00"
    },
    "2015-09-06": {
      "mfOrderCount": 0,
      "roi": 6.130634071810545,
      "shippingCredits": 5.18,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 53.25,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.91,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002428544",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663758",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603094",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603300",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 3,
      "otherTotal": 0,
      "label": "2015-09-06",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.32,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 55.57,
      "lostWarehouseQty": 0,
      "netProfit": -51.875714285714295,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.012298850574712647,
      "totalServiceFees": 0,
      "promotionalRebates": -2.86,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.91,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.39,
      "inventoryStorageFees": -27.06,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -27.06,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -107.44571428571437,
      "totalSellingFees": -13.39,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -52.36,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-06T00:00:00+02:00"
    },
    "2015-09-05": {
      "mfOrderCount": 0,
      "roi": 61.92737430167601,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 57.970000000000006,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.1,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002820356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517307",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564919",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-05",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 57.970000000000006,
      "lostWarehouseQty": 0,
      "netProfit": -32.91571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.08494252873563221,
      "totalServiceFees": -17.3,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.1,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -17.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -90.88571428571437,
      "totalSellingFees": -11.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -35.8,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-05T00:00:00+02:00"
    },
    "2015-09-04": {
      "mfOrderCount": 0,
      "roi": 90.21701687909064,
      "shippingCredits": 2.67,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 55.22000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.540000000000001,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002470257",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451247",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555856",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "6E-IGZD-WX75",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427883",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663869",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5T-QNLP-N9DN",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-04",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 55.22000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -28.895714285714266,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.10034482758620694,
      "totalServiceFees": 0,
      "promotionalRebates": -2.67,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.540000000000001,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -16.49,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -84.11571428571436,
      "totalSellingFees": -16.49,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -29.03,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-04T00:00:00+02:00"
    },
    "2015-09-03": {
      "mfOrderCount": 0,
      "roi": 143.6154949784792,
      "shippingCredits": 7.4,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": -0.15,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 84.9,
      "customerReturnTotal": 0,
      "totalTransactionFees": -8.59,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002470329",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "8R-V6X6-ZJOC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469697",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555843",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663927",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -6.62,
      "otherTotal": 0,
      "label": "2015-09-03",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 84.9,
      "lostWarehouseQty": 0,
      "netProfit": -5.035714285714303,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1917624521072797,
      "totalServiceFees": 0,
      "promotionalRebates": -7.4,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -8.59,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.490000000000002,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.15,
      "cogs": 0,
      "totalShippingFees": -6.62,
      "totalExpenses": -89.93571428571437,
      "totalSellingFees": -19.490000000000002,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.85,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-03T00:00:00+02:00"
    },
    "2015-09-02": {
      "mfOrderCount": 0,
      "roi": -184.4449134655973,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 6.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002600983",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002361202",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -8.49,
      "otherTotal": 0,
      "label": "2015-09-02",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": -40.01,
      "lostWarehouseQty": 0,
      "netProfit": -142.47571428571416,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -47,
      "dailyNetProfit": -0.33482758620689657,
      "totalServiceFees": -40.59,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 5.64,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -40.59,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -8.49,
      "totalExpenses": -102.46571428571437,
      "totalSellingFees": 3.2399999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -47.38,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-02T00:00:00+02:00"
    },
    "2015-09-01": {
      "mfOrderCount": 0,
      "roi": -83.3373063170441,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 6.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.92,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002603337",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -37.63,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-09-01",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 6.99,
      "lostWarehouseQty": 0,
      "netProfit": -90.04571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.1339463601532567,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.92,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -37.63,
      "totalExpenses": -97.03571428571438,
      "totalSellingFees": -2.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -41.95,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-01T00:00:00+02:00"
    },
    "2015-12-13": {
      "mfOrderCount": 0,
      "roi": 65.74490148634636,
      "shippingCredits": 4.49,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 45.81,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.33,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "VT-VBVF-G5H2",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564702",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002527550",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486051",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-13",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.14,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 47.95,
      "lostWarehouseQty": 0,
      "netProfit": -36.065714285714286,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.07287356321839082,
      "totalServiceFees": 0,
      "promotionalRebates": -2.35,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.33,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.6,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -84.01571428571437,
      "totalSellingFees": -12.6,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -28.93,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-13T00:00:00+01:00"
    },
    "2015-10-30": {
      "mfOrderCount": 0,
      "roi": -36.52266101245694,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 47.9,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.120000000000001,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002973312",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002728618",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600960",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547048",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-30",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 47.9,
      "lostWarehouseQty": 0,
      "netProfit": -82.64571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.10559386973180081,
      "totalServiceFees": -55.1,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.120000000000001,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.239999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -55.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -130.5457142857143,
      "totalSellingFees": -11.239999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -75.46000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-30T00:00:00+01:00"
    },
    "2015-04-14": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-04-14",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -134.08571428571426,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.30268199233716475,
      "totalServiceFees": -79,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -79,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -134.08571428571426,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -79,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-04-14T00:00:00+02:00"
    },
    "2015-12-18": {
      "mfOrderCount": 0,
      "roi": 102.42792109256449,
      "shippingCredits": 3.9400000000000004,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 26.68,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.13,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003335190",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "7Z-A1A5-MO81",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469603",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-18",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.68,
      "lostWarehouseQty": 0,
      "netProfit": -41.58571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.05172413793103448,
      "totalServiceFees": 0,
      "promotionalRebates": -3.9400000000000004,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.13,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.05,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -68.26571428571431,
      "totalSellingFees": -8.05,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -13.18,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-18T00:00:00+01:00"
    },
    "2015-12-19": {
      "mfOrderCount": 0,
      "roi": 162.17622851609775,
      "shippingCredits": 2.32,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 210.79999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -30.269999999999996,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002630285",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427914",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461617",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "VT-VBVF-G5H2",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605026",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -16.119999999999997,
      "otherTotal": 0,
      "label": "2015-12-19",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 5.8100000000000005,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 216.60999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 78.9042857142857,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.5133716475095784,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -30.269999999999996,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -36.230000000000004,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -16.119999999999997,
      "totalExpenses": -137.70571428571424,
      "totalSellingFees": -36.230000000000004,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -82.62,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-19T00:00:00+01:00"
    },
    "2015-09-09": {
      "mfOrderCount": 0,
      "roi": 226.6655686403691,
      "shippingCredits": 8.530000000000001,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 396.6700000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -51.949999999999996,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002605068",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603069",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663348",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "BM-2BOB-S7VJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436887",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002820315",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "UM-HEX2-WTFX",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "C4-GF61-I9MY",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491128",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470205",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469705",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715355",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002575403",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486059",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-09",
      "fbaOrderCount": 23,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 396.6700000000001,
      "lostWarehouseQty": 0,
      "netProfit": 220.15428571428578,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 1.0545593869731806,
      "totalServiceFees": 0,
      "promotionalRebates": -8.530000000000001,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -51.949999999999996,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -69.47999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -176.5157142857138,
      "totalSellingFees": -69.47999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -121.42999999999998,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-09T00:00:00+02:00"
    },
    "2015-09-08": {
      "mfOrderCount": 0,
      "roi": 177.8423366834171,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 176.93,
      "customerReturnTotal": 0,
      "totalTransactionFees": -25.949999999999996,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002731327",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002622658",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517053",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-09-08",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 176.93,
      "lostWarehouseQty": 0,
      "netProfit": 58.16428571428568,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4339080459770115,
      "totalServiceFees": -10.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -25.949999999999996,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -27.13,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -10.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -118.76571428571434,
      "totalSellingFees": -27.13,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -63.68,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-08T00:00:00+02:00"
    },
    "2015-10-05": {
      "mfOrderCount": 0,
      "roi": 178.32916815423064,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 77.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.26,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003107999",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555952",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IM-QXL0-GHDK",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108146",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-05",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 77.96,
      "lostWarehouseQty": 0,
      "netProfit": -5.135714285714312,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.19137931034482758,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.26,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -15.749999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -83.09571428571437,
      "totalSellingFees": -15.749999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -28.009999999999998,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-05T00:00:00+02:00"
    },
    "2015-10-04": {
      "mfOrderCount": 0,
      "roi": 314.5947505047592,
      "shippingCredits": 5.58,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 139.24,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.08,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002360732",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427852",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108006",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368524",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0.51,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-04",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 143.74,
      "lostWarehouseQty": 1,
      "netProfit": 53.98428571428569,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4178927203065134,
      "totalServiceFees": 0,
      "promotionalRebates": -1.59,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.08,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.59,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -89.75571428571438,
      "totalSellingFees": -23.59,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.67,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0.51,
      "date": "2015-10-04T00:00:00+02:00"
    },
    "2015-10-07": {
      "mfOrderCount": 0,
      "roi": 147.54754754754745,
      "shippingCredits": 7.51,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 94.92999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -21.660000000000004,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002833003",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003107946",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-07",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.9899999999999998,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 98.91999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 3.8742857142856604,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.22590038314176236,
      "totalServiceFees": 0,
      "promotionalRebates": -3.52,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -21.660000000000004,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -18.3,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -95.04571428571438,
      "totalSellingFees": -18.3,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -39.96000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-07T00:00:00+02:00"
    },
    "2015-07-30": {
      "mfOrderCount": 0,
      "roi": -5.485534230879401,
      "shippingCredits": 4.48,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 61.51000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.86,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002490990",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "MD-TKPL-5MYO",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469507",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491305",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427883",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002380438",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "L3-D22R-2PLE",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491169",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IF-CNQL-D9DQ",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -19.73,
      "otherTotal": 0,
      "label": "2015-07-30",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.48,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 65.99000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -58.9157142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.014674329501915702,
      "totalServiceFees": -8.8,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.86,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.430000000000003,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -8.8,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -19.73,
      "totalExpenses": -124.90571428571432,
      "totalSellingFees": -20.430000000000003,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -69.82000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-30T00:00:00+02:00"
    },
    "2015-07-31": {
      "mfOrderCount": 0,
      "roi": 99.4938539407086,
      "shippingCredits": 4.85,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 135.95999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -32.69,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002436807",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CM-W7I0-8B04",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486221",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486210",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IF-CNQL-D9DQ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486190",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -4.89,
      "otherTotal": 0,
      "label": "2015-07-31",
      "fbaOrderCount": 16,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.9899999999999998,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 137.95,
      "lostWarehouseQty": 0,
      "netProfit": 13.714285714285722,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2636015325670498,
      "totalServiceFees": 0,
      "promotionalRebates": -2.86,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -32.69,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.57,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -4.89,
      "totalExpenses": -124.23571428571431,
      "totalSellingFees": -31.57,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -69.14999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-31T00:00:00+02:00"
    },
    "2015-11-30": {
      "mfOrderCount": 0,
      "roi": -2.4879535844232485,
      "shippingCredits": 1.87,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 99.16,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.01,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451302",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003752324",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582933",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461635",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003144880",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CT-GK75-BJ4A",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491035",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451398",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914544",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-30",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 99.16,
      "lostWarehouseQty": 0,
      "netProfit": -57.615714285714304,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.009693486590038318,
      "totalServiceFees": -60,
      "promotionalRebates": -1.87,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.01,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -25.679999999999996,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -60,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -156.77571428571403,
      "totalSellingFees": -25.679999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -101.69,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-30T00:00:00+01:00"
    },
    "2015-11-10": {
      "mfOrderCount": 0,
      "roi": 147.5698924731183,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 57.56,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.21,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003306359",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547048",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003042837",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554744",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551261",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-11-10",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 57.56,
      "lostWarehouseQty": 0,
      "netProfit": -20.77571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.131455938697318,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.21,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.040000000000001,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -78.33571428571435,
      "totalSellingFees": -14.040000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -23.25,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-10T00:00:00+01:00"
    },
    "2015-10-01": {
      "mfOrderCount": 0,
      "roi": -71.69060773480662,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 42.2,
      "customerReturnTotal": 0,
      "totalTransactionFees": -8.84,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451881",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550984",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584158",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002367921",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564767",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": -29.39,
      "dateStartYear": 2015,
      "inboundShippingFees": -23.32,
      "otherTotal": -29.39,
      "label": "2015-10-01",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 12.810000000000002,
      "lostWarehouseQty": 0,
      "netProfit": -87.52571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.1242911877394636,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -8.84,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.09,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -23.32,
      "totalExpenses": -100.33571428571437,
      "totalSellingFees": -13.09,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -45.25,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-01T00:00:00+02:00"
    },
    "2015-07-14": {
      "mfOrderCount": 0,
      "roi": -91.40002529404325,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 13.600000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.34,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "Q0-OJJ2-B6KS",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -64.2,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-07-14",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 13.600000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -199.62571428571377,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.5537931034482758,
      "totalServiceFees": -85.7,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.34,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.9,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -85.7,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -64.2,
      "totalExpenses": -213.22571428571376,
      "totalSellingFees": -3.9,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -158.14,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-14T00:00:00+02:00"
    },
    "2015-12-14": {
      "mfOrderCount": 0,
      "roi": 24.88067639438157,
      "shippingCredits": 4,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 180.89000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.440000000000005,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368507",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187394",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003591346",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555956",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003269915",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002641972",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543319",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449201",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461729",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -59.12,
      "otherTotal": 0,
      "label": "2015-12-14",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.26,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 183.15,
      "lostWarehouseQty": 0,
      "netProfit": -18.595714285714276,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.13980842911877397,
      "totalServiceFees": -27.8,
      "promotionalRebates": -1.74,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.440000000000005,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -39.300000000000004,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -27.8,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -59.12,
      "totalExpenses": -201.74571428571377,
      "totalSellingFees": -39.300000000000004,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -146.66,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-14T00:00:00+01:00"
    },
    "2015-12-09": {
      "mfOrderCount": 0,
      "roi": 196.83486238532095,
      "shippingCredits": 2.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 78.16999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.87,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002543302",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CT-GK75-BJ4A",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436835",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565210",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491164",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428455",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-09",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": -3.4699999999999998,
      "fbaTransactionFeeRefunds": 6.46,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 64.70999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -12.175714285714323,
      "shippingCreditRefunds": -6.46,
      "mfOrderRevenue": 0,
      "totalRefundCost": -9.99,
      "dailyNetProfit": 0.16440613026819914,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.33,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 1.2,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.130000000000003,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -76.88571428571436,
      "totalSellingFees": -15.930000000000003,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -21.800000000000004,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-09T00:00:00+01:00"
    },
    "2015-11-12": {
      "mfOrderCount": 0,
      "roi": -17.832637759071943,
      "shippingCredits": 11.16,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": -0.3,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 103.69999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -32.6,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003006075",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451167",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486062",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H8-Q28U-TY0M",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547048",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491065",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427841",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003042826",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108088",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003335318",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -82.01,
      "otherTotal": 0,
      "label": "2015-11-12",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 12.46,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 116.16,
      "lostWarehouseQty": 0,
      "netProfit": -80.29571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.09659003831417627,
      "totalServiceFees": 0,
      "promotionalRebates": -2.19,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -32.6,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -26.46,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.3,
      "cogs": 0,
      "totalShippingFees": -82.01,
      "totalExpenses": -196.45571428571378,
      "totalSellingFees": -26.46,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -141.37,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-12T00:00:00+01:00"
    },
    "2015-10-03": {
      "mfOrderCount": 0,
      "roi": 359.3440916189484,
      "shippingCredits": 3.26,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 47.84,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.209999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "3G-3HQ8-KN52",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002460768",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 37.14,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 37.14,
      "label": "2015-10-03",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.26,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 88.24000000000001,
      "lostWarehouseQty": 0,
      "netProfit": 13.944285714285726,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.26448275862068965,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.209999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -74.29571428571434,
      "totalSellingFees": -8,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -19.21,
      "otherQty": 2,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-03T00:00:00+02:00"
    },
    "2015-12-15": {
      "mfOrderCount": 0,
      "roi": 102.34776228906823,
      "shippingCredits": 5.65,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 112.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -28.009999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003461055",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600878",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242310",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002352467",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003032447",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003335401",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914638",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451445",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003575331",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554639",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550859",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-12-15",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 5.65,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 110.32,
      "lostWarehouseQty": 0,
      "netProfit": 0.7142857142856818,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -8.29,
      "dailyNetProfit": 0.21379310344827585,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -28.009999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.59,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -29.1,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -109.60571428571437,
      "totalSellingFees": -26.51,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -54.519999999999996,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-15T00:00:00+01:00"
    },
    "2015-12-03": {
      "mfOrderCount": 0,
      "roi": 156.825453252755,
      "shippingCredits": 2.73,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 142.39,
      "customerReturnTotal": 0,
      "totalTransactionFees": -25.44,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002715306",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003591346",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368494",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002604999",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003474493",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003575488",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003752312",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224477",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582826",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-03",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.1,
      "fbaTransactionFeeRefunds": 1.39,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 144.48999999999998,
      "lostWarehouseQty": 0,
      "netProfit": 33.14428571428566,
      "shippingCreditRefunds": -1.39,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.33804597701149414,
      "totalServiceFees": 0,
      "promotionalRebates": -2.73,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -26.830000000000002,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -30.82,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -111.34571428571438,
      "totalSellingFees": -30.82,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -56.260000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-03T00:00:00+01:00"
    },
    "2015-11-15": {
      "mfOrderCount": 0,
      "roi": 217.17687074829934,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 55.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002574663",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224433",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914602",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-15",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 55.95,
      "lostWarehouseQty": 0,
      "netProfit": -16.775714285714287,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1467816091954023,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.1,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -72.72571428571433,
      "totalSellingFees": -11.1,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -17.64,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-15T00:00:00+01:00"
    },
    "2015-12-01": {
      "mfOrderCount": 0,
      "roi": 176.90709409320277,
      "shippingCredits": 1.39,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 91.9,
      "customerReturnTotal": 0,
      "totalTransactionFees": -18.369999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451056",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663436",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449333",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NQ-4FQF-S5SC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582826",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-12-01",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.39,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 93.29,
      "lostWarehouseQty": 0,
      "netProfit": 4.514285714285689,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.22835249042145597,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -18.369999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -15.319999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -88.77571428571437,
      "totalSellingFees": -15.319999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -33.69,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-01T00:00:00+01:00"
    },
    "2015-10-02": {
      "mfOrderCount": 0,
      "roi": -45.84581857804413,
      "shippingCredits": 1.59,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 39.76,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.68,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002641882",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554611",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451098",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555520",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.38,
      "otherTotal": 0,
      "label": "2015-10-02",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 39.76,
      "lostWarehouseQty": 0,
      "netProfit": -88.74571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.12896551724137933,
      "totalServiceFees": -39.99,
      "promotionalRebates": -1.59,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.68,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.370000000000001,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -39.99,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.38,
      "totalExpenses": -128.5057142857143,
      "totalSellingFees": -11.370000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -73.42,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-02T00:00:00+02:00"
    },
    "2015-12-07": {
      "mfOrderCount": 0,
      "roi": 7.971521942110176,
      "shippingCredits": 4.86,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 92.51,
      "customerReturnTotal": 0,
      "totalTransactionFees": -21.6,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002450930",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003769270",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419786",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419779",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0L-SLJ7-RYZD",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550859",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002642016",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-07",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 92.51,
      "lostWarehouseQty": 0,
      "netProfit": -48.25571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.02616858237547892,
      "totalServiceFees": 0,
      "promotionalRebates": -4.86,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -21.6,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -22.78,
      "inventoryStorageFees": -41.3,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -41.3,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -140.7657142857142,
      "totalSellingFees": -22.78,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -85.68,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-07T00:00:00+01:00"
    },
    "2015-12-06": {
      "mfOrderCount": 0,
      "roi": 120.37164487267718,
      "shippingCredits": 12.89,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 119.49,
      "customerReturnTotal": 0,
      "totalTransactionFees": -29.389999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451306",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603305",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003461579",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428455",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003006079",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002975857",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003769473",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605294",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 3,
      "otherTotal": 0,
      "label": "2015-12-06",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 8.59,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 128.07999999999998,
      "lostWarehouseQty": 0,
      "netProfit": 14.874285714285703,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2680459770114942,
      "totalServiceFees": 0,
      "promotionalRebates": -4.300000000000001,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -29.389999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -28.730000000000004,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -113.20571428571438,
      "totalSellingFees": -28.730000000000004,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -58.120000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-06T00:00:00+01:00"
    },
    "2015-12-05": {
      "mfOrderCount": 0,
      "roi": 108.8956472153583,
      "shippingCredits": 14.97,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 96.91999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -28.86,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003187347",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002535575",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003083223",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242317",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003032713",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491053",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-05",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 12.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 109.89999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 2.2042857142856747,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.21950191570881222,
      "totalServiceFees": 0,
      "promotionalRebates": -1.99,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -28.86,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.75,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -107.69571428571437,
      "totalSellingFees": -23.75,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -52.61,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-05T00:00:00+01:00"
    },
    "2015-11-14": {
      "mfOrderCount": 0,
      "roi": 224.293228139382,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 98.65,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.97,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002664018",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003083268",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451943",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600958",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-14",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 98.65,
      "lostWarehouseQty": 0,
      "netProfit": 13.144285714285733,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2614176245210728,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.97,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -85.50571428571438,
      "totalSellingFees": -20.45,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -30.42,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-14T00:00:00+01:00"
    },
    "2015-12-17": {
      "mfOrderCount": 0,
      "roi": 117.79832418376195,
      "shippingCredits": 2.86,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 75.38000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.78,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002833010",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "OQ-TX0B-89EL",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003782844",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002601000",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543329",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-17",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 75.38000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -14.315714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.15620689655172418,
      "totalServiceFees": 0,
      "promotionalRebates": -2.86,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.78,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.83,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -89.69571428571437,
      "totalSellingFees": -17.83,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.61,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-17T00:00:00+01:00"
    },
    "2015-07-10": {
      "mfOrderCount": 0,
      "roi": 130.06409604232374,
      "shippingCredits": 10.110000000000001,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 218.16,
      "customerReturnTotal": 0,
      "totalTransactionFees": -30.11,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "N5-P205-IIGF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "DB-UZ9O-1KSP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "22-6UPZ-Y46N",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NQ-4FQF-S5SC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "UJ-D7KB-D7PC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H5-IV1X-ACBO",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0P-J0H8-BKX6",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.49,
      "otherTotal": 0,
      "label": "2015-07-10",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 7.970000000000001,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 226.13,
      "lostWarehouseQty": 0,
      "netProfit": 72.75428571428569,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.489808429118774,
      "totalServiceFees": -22.7,
      "promotionalRebates": -2.14,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -30.11,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -30.990000000000002,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -22.7,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.49,
      "totalExpenses": -153.37571428571405,
      "totalSellingFees": -30.990000000000002,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -98.28999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-10T00:00:00+02:00"
    },
    "2015-10-29": {
      "mfOrderCount": 0,
      "roi": 183.00970873786406,
      "shippingCredits": 9.96,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 69.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.17,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002477416",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486082",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224455",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003269615",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-29",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 69.96,
      "lostWarehouseQty": 0,
      "netProfit": -9.845714285714305,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1733333333333333,
      "totalServiceFees": 0,
      "promotionalRebates": -9.96,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.17,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.549999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -79.80571428571436,
      "totalSellingFees": -14.549999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -24.72,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-29T00:00:00+01:00"
    },
    "2015-10-28": {
      "mfOrderCount": 0,
      "roi": 160.0896860986547,
      "shippingCredits": 1.91,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 115.99999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.889999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "F9-6ODS-XRAF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565338",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551115",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002574663",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108169",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012301",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550859",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-28",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 115.99999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 16.314285714285713,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.27356321839080455,
      "totalServiceFees": 0,
      "promotionalRebates": -1.91,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.889999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.71,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -99.68571428571437,
      "totalSellingFees": -23.71,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -44.599999999999994,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-28T00:00:00+01:00"
    },
    "2015-06-19": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -6.21,
      "otherTotal": 0,
      "label": "2015-06-19",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -61.29571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.02379310344827586,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -6.21,
      "totalExpenses": -61.29571428571431,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -6.21,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-19T00:00:00+02:00"
    },
    "2015-10-26": {
      "mfOrderCount": 0,
      "roi": 96.20071684587818,
      "shippingCredits": 1.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 27.370000000000005,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.79,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003335394",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436752",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002630500",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-26",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 27.370000000000005,
      "lostWarehouseQty": 0,
      "netProfit": -41.66571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.05141762452107282,
      "totalServiceFees": 0,
      "promotionalRebates": -1.99,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.79,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.16,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -69.0357142857143,
      "totalSellingFees": -8.16,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -13.95,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-26T00:00:00+01:00"
    },
    "2015-10-25": {
      "mfOrderCount": 0,
      "roi": 69.49563530552865,
      "shippingCredits": 1.66,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 34.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.719999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002564717",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003121107",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242297",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187220",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-25",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 34.95,
      "lostWarehouseQty": 0,
      "netProfit": -40.75571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.054904214559386995,
      "totalServiceFees": 0,
      "promotionalRebates": -1.66,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.719999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -10.899999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -75.70571428571434,
      "totalSellingFees": -10.899999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -20.619999999999997,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-25T00:00:00+02:00"
    },
    "2015-11-16": {
      "mfOrderCount": 0,
      "roi": 46.488469601677195,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 57.940000000000005,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.829999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663436",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451872",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427853",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451014",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5T-QNLP-N9DN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012301",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-16",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 27.950000000000006,
      "lostWarehouseQty": 0,
      "netProfit": -46.21571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -29.99,
      "dailyNetProfit": 0.033984674329501946,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.829999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 3.6,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -74.16571428571434,
      "totalSellingFees": -9.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -19.08,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-16T00:00:00+01:00"
    },
    "2015-10-23": {
      "mfOrderCount": 0,
      "roi": 136.49789029535867,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 52.56,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.41,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003108163",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469893",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419784",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427824",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-23",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.49,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 56.050000000000004,
      "lostWarehouseQty": 0,
      "netProfit": -22.73571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.12394636015325673,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.41,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.29,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -78.78571428571435,
      "totalSellingFees": -13.29,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -23.7,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-23T00:00:00+02:00"
    },
    "2015-10-22": {
      "mfOrderCount": 0,
      "roi": 67.87503684055409,
      "shippingCredits": 1.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 54.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.33,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002914265",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224909",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470205",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321678",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565204",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -8.6,
      "otherTotal": 0,
      "label": "2015-10-22",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 56.96,
      "lostWarehouseQty": 0,
      "netProfit": -32.05571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.08823754789272031,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.33,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -8.6,
      "totalExpenses": -89.01571428571437,
      "totalSellingFees": -15,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -33.93,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-22T00:00:00+02:00"
    },
    "2015-10-21": {
      "mfOrderCount": 0,
      "roi": 151.69378500933587,
      "shippingCredits": 3.37,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 92.92,
      "customerReturnTotal": 0,
      "totalTransactionFees": -15.440000000000001,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663436",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003186682",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368255",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003241926",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003305855",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003186990",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002536999",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-21",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.4400000000000002,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 94.36,
      "lostWarehouseQty": 0,
      "netProfit": 1.784285714285691,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.21789272030651344,
      "totalServiceFees": 0,
      "promotionalRebates": -1.93,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -15.440000000000001,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -22.049999999999997,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -92.57571428571437,
      "totalSellingFees": -22.049999999999997,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.489999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-21T00:00:00+02:00"
    },
    "2015-10-20": {
      "mfOrderCount": 0,
      "roi": 7.8149100257069195,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 20.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.75,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "UM-X6B6-WTLL",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-10-20",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 20.97,
      "lostWarehouseQty": 0,
      "netProfit": -53.56571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.005823754789272015,
      "totalServiceFees": -9.3,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.75,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -9.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -74.53571428571435,
      "totalSellingFees": -3.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -19.450000000000003,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-20T00:00:00+02:00"
    },
    "2015-12-12": {
      "mfOrderCount": 0,
      "roi": 102.93348823700268,
      "shippingCredits": 1.53,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 69.87,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.979999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002450920",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003769502",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "2D-T4Z3-KXUY",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564696",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-12",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 69.87,
      "lostWarehouseQty": 0,
      "netProfit": -19.64571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.13578544061302686,
      "totalServiceFees": 0,
      "promotionalRebates": -1.53,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.979999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -16.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -89.51571428571437,
      "totalSellingFees": -16.45,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -34.42999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-12T00:00:00+01:00"
    },
    "2015-11-25": {
      "mfOrderCount": 0,
      "roi": 138.07754817738572,
      "shippingCredits": 13,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 91.53,
      "customerReturnTotal": 0,
      "totalTransactionFees": -22.58,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002630110",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003591244",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547014",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321584",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491065",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555487",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-25",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 11.01,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 102.54,
      "lostWarehouseQty": 0,
      "netProfit": 4.384285714285694,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.22785440613026825,
      "totalServiceFees": 0,
      "promotionalRebates": -1.99,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -22.58,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.49,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -98.15571428571437,
      "totalSellingFees": -20.49,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -43.06999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-25T00:00:00+01:00"
    },
    "2015-07-27": {
      "mfOrderCount": 0,
      "roi": 12.57354177172631,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 133.93999999999997,
      "customerReturnTotal": 0,
      "totalTransactionFees": -19.409999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002460768",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451854",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461374",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428577",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451688",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -57.17,
      "otherTotal": 0,
      "label": "2015-07-27",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 133.93999999999997,
      "lostWarehouseQty": 0,
      "netProfit": -40.12571428571432,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.05731800766283512,
      "totalServiceFees": -19.000000000000004,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -19.409999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -19.000000000000004,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -57.17,
      "totalExpenses": -174.06571428571385,
      "totalSellingFees": -23.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -118.98,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-27T00:00:00+02:00"
    },
    "2015-07-26": {
      "mfOrderCount": 0,
      "roi": -67.78474014006635,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 17.48,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.28,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002427899",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486110",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -44.66,
      "otherTotal": 0,
      "label": "2015-07-26",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 17.48,
      "lostWarehouseQty": 0,
      "netProfit": -91.86571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.14091954022988507,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.28,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.32,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -44.66,
      "totalExpenses": -109.34571428571437,
      "totalSellingFees": -5.32,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -54.26,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-26T00:00:00+02:00"
    },
    "2015-07-25": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -24.46,
      "otherTotal": 0,
      "label": "2015-07-25",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -85.14571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.11517241379310346,
      "totalServiceFees": -5.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -5.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -24.46,
      "totalExpenses": -85.14571428571438,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -30.060000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-25T00:00:00+02:00"
    },
    "2015-07-24": {
      "mfOrderCount": 0,
      "roi": 79.3548387096774,
      "shippingCredits": 1.33,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 82.83,
      "customerReturnTotal": 0,
      "totalTransactionFees": -22.760000000000005,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451862",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002367903",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002360618",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427878",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002460768",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "KA-A1E7-PU20",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-24",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 77.84,
      "lostWarehouseQty": 0,
      "netProfit": -20.645714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -4.99,
      "dailyNetProfit": 0.13195402298850573,
      "totalServiceFees": -4.1,
      "promotionalRebates": -1.33,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -22.760000000000005,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0.8,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.34,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -4.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -98.48571428571438,
      "totalSellingFees": -16.54,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -43.400000000000006,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-24T00:00:00+02:00"
    },
    "2015-07-23": {
      "mfOrderCount": 0,
      "roi": 52.6425657083215,
      "shippingCredits": 24.39,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 136.48000000000002,
      "customerReturnTotal": 0,
      "totalTransactionFees": -66.32,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002460768",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419758",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-Y2TL-VZB3",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002460781",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.27,
      "otherTotal": 0,
      "label": "2015-07-23",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 24.39,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 160.87,
      "lostWarehouseQty": 0,
      "netProfit": 0.3942857142857066,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2125670498084292,
      "totalServiceFees": -4.7,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -66.32,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.099999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -4.7,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.27,
      "totalExpenses": -160.475714285714,
      "totalSellingFees": -20.099999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -105.38999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-23T00:00:00+02:00"
    },
    "2015-07-22": {
      "mfOrderCount": 0,
      "roi": 108.4289588983935,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 199.8,
      "customerReturnTotal": 0,
      "totalTransactionFees": -19.97,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461367",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451934",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "O1-MH7I-EZYO",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "BM-09R4-S7OL",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1A-VSRW-L8AO",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -33.42,
      "otherTotal": 0,
      "label": "2015-07-22",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 199.8,
      "lostWarehouseQty": 0,
      "netProfit": 48.854285714285695,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.39823754789272037,
      "totalServiceFees": -7.1,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -19.97,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -35.37,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -7.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -33.42,
      "totalExpenses": -150.94571428571408,
      "totalSellingFees": -35.37,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -95.86,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-22T00:00:00+02:00"
    },
    "2015-07-21": {
      "mfOrderCount": 0,
      "roi": -78.78985035783995,
      "shippingCredits": 2.53,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 3.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.45,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002368272",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -22.439999999999998,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-07-21",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.53,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 6.52,
      "lostWarehouseQty": 0,
      "netProfit": -79.30571428571436,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.09279693486590038,
      "totalServiceFees": -1.9,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.45,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -1.95,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -1.9,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -22.439999999999998,
      "totalExpenses": -85.82571428571437,
      "totalSellingFees": -1.95,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -30.74,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-21T00:00:00+02:00"
    },
    "2015-07-20": {
      "mfOrderCount": 0,
      "roi": -13.373083475298127,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 40.68,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.5,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "QI-OU4E-18AR",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436648",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427913",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IF-CNQL-D9DQ",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-07-20",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 40.68,
      "lostWarehouseQty": 0,
      "netProfit": -61.36571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.02406130268199234,
      "totalServiceFees": -23.1,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.5,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.360000000000001,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -23.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -102.04571428571437,
      "totalSellingFees": -11.360000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -46.96,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-20T00:00:00+02:00"
    },
    "2015-07-29": {
      "mfOrderCount": 0,
      "roi": 34.92795389048989,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 90.14999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -24.459999999999994,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451565",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002477435",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486264",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "D0-4BWW-ER22",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491351",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491060",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486184",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -21.7,
      "otherTotal": 0,
      "label": "2015-07-29",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.49,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 93.63999999999997,
      "lostWarehouseQty": 0,
      "netProfit": -30.845714285714298,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.09287356321839073,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -24.459999999999994,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.24,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -21.7,
      "totalExpenses": -124.48571428571431,
      "totalSellingFees": -23.24,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -69.39999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-29T00:00:00+02:00"
    },
    "2015-07-28": {
      "mfOrderCount": 0,
      "roi": 163.2476617150043,
      "shippingCredits": 15.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 318.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -52.45,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461312",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486085",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002477460",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486261",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470062",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "WI-AHEY-9EJA",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002360658",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419777",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428992",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491102",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.83,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-07-28",
      "fbaOrderCount": 12,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 15.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 334.93,
      "lostWarehouseQty": 0,
      "netProfit": 152.6142857142862,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.7957854406130268,
      "totalServiceFees": -3.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -52.45,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -56.35000000000001,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -3.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.83,
      "totalExpenses": -182.3157142857138,
      "totalSellingFees": -56.35000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -127.23,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-07-28T00:00:00+02:00"
    },
    "2015-11-28": {
      "mfOrderCount": 0,
      "roi": 124.6466930469192,
      "shippingCredits": 4.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 74.5,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.45,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003582626",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002728858",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486099",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582689",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002729011",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491390",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-28",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 79.48,
      "lostWarehouseQty": 0,
      "netProfit": -10.985714285714288,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.16896551724137934,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.45,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.93,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -90.46571428571437,
      "totalSellingFees": -17.93,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -35.379999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-28T00:00:00+01:00"
    },
    "2015-06-25": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -21.959999999999997,
      "otherTotal": 0,
      "label": "2015-06-25",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -77.04571428571434,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.08413793103448275,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -21.959999999999997,
      "totalExpenses": -77.04571428571434,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -21.959999999999997,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-25T00:00:00+02:00"
    },
    "2015-06-26": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -6.24,
      "otherTotal": 0,
      "label": "2015-06-26",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -61.32571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.023908045977011495,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -6.24,
      "totalExpenses": -61.32571428571431,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -6.24,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-26T00:00:00+02:00"
    },
    "2015-06-27": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -46.6,
      "otherTotal": 0,
      "label": "2015-06-27",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -101.68571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.178544061302682,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -46.6,
      "totalExpenses": -101.68571428571437,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -46.6,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-27T00:00:00+02:00"
    },
    "2015-06-20": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -17.08,
      "otherTotal": 0,
      "label": "2015-06-20",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -72.16571428571433,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.06544061302681992,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -17.08,
      "totalExpenses": -72.16571428571433,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -17.08,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-20T00:00:00+02:00"
    },
    "2015-06-21": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -9.91,
      "otherTotal": 0,
      "label": "2015-06-21",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -64.99571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.03796934865900383,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -9.91,
      "totalExpenses": -64.99571428571431,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -9.91,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-21T00:00:00+02:00"
    },
    "2015-06-23": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -5.81,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-06-23",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -60.895714285714305,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.02226053639846743,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -5.81,
      "totalExpenses": -60.895714285714305,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -5.81,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-23T00:00:00+02:00"
    },
    "2015-06-28": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -37.519999999999996,
      "otherTotal": 0,
      "label": "2015-06-28",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -92.60571428571437,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.14375478927203064,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -37.519999999999996,
      "totalExpenses": -92.60571428571437,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.519999999999996,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-28T00:00:00+02:00"
    },
    "2015-06-29": {
      "mfOrderCount": 0,
      "roi": -56.90846286701209,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 4.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -2.67,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "KA-A1E7-PU20",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -7.91,
      "otherTotal": 0,
      "label": "2015-06-29",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 4.99,
      "lostWarehouseQty": 0,
      "netProfit": -61.67571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.02524904214559387,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -2.67,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -1,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -7.91,
      "totalExpenses": -66.66571428571432,
      "totalSellingFees": -1,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -11.58,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-29T00:00:00+02:00"
    },
    "2015-08-28": {
      "mfOrderCount": 0,
      "roi": -36.553802517284176,
      "shippingCredits": 1.15,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 1,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 29.46,
      "customerReturnTotal": 6.33,
      "totalTransactionFees": -7.42,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002470103",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565330",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517033",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600980",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 6.33,
      "dateStartYear": 2015,
      "inboundShippingFees": -13.17,
      "otherTotal": 0,
      "label": "2015-08-28",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 35.79,
      "lostWarehouseQty": 0,
      "netProfit": -75.70571428571435,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.07900383141762454,
      "totalServiceFees": -27.1,
      "promotionalRebates": -1.15,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.42,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.719999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -27.1,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -13.17,
      "totalExpenses": -111.49571428571437,
      "totalSellingFees": -8.719999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -56.410000000000004,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-28T00:00:00+02:00"
    },
    "2015-08-29": {
      "mfOrderCount": 0,
      "roi": 140.7549807759525,
      "shippingCredits": 6.25,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 62.56,
      "customerReturnTotal": 0,
      "totalTransactionFees": -15.169999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "7Z-0Y0K-MOAU",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002683146",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002361202",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "26-YQPN-HQ1I",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 1.34,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-29",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 1.34,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 68.88000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -14.815714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.15429118773946365,
      "totalServiceFees": 0,
      "promotionalRebates": -1.27,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -15.169999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 2,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.44,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -83.69571428571436,
      "totalSellingFees": -13.44,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -28.61,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-29T00:00:00+02:00"
    },
    "2015-08-22": {
      "mfOrderCount": 0,
      "roi": 69.67522658610275,
      "shippingCredits": 4.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 39.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -15.93,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "8H-ZKKL-CPZE",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564812",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-22",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 44.93000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -36.635714285714286,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.07068965517241382,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -15.93,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -10.55,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -81.56571428571436,
      "totalSellingFees": -10.55,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.48,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-22T00:00:00+02:00"
    },
    "2015-08-23": {
      "mfOrderCount": 0,
      "roi": 212.04075774971298,
      "shippingCredits": 9.38,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 209.75,
      "customerReturnTotal": 0,
      "totalTransactionFees": -29.61,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002650652",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451923",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002622541",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "AX-IMW5-YYE0",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486239",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470130",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555567",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-23",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 7.680000000000001,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 217.43,
      "lostWarehouseQty": 0,
      "netProfit": 92.66428571428587,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.5660919540229885,
      "totalServiceFees": -3.2,
      "promotionalRebates": -1.7,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -29.61,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -36.87,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -3.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -124.76571428571431,
      "totalSellingFees": -36.87,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -69.67999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-23T00:00:00+02:00"
    },
    "2015-08-20": {
      "mfOrderCount": 0,
      "roi": 7.855662983425421,
      "shippingCredits": 1.59,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 62.470000000000006,
      "customerReturnTotal": 0,
      "totalTransactionFees": -13.05,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "3R-KAJN-7GY6",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002527538",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663348",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603121",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002490977",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516904",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555532",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-20",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 62.470000000000006,
      "lostWarehouseQty": 0,
      "netProfit": -50.53571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.017432950191570897,
      "totalServiceFees": -27.4,
      "promotionalRebates": -1.59,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -13.05,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.47,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -27.4,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -113.00571428571438,
      "totalSellingFees": -17.47,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -57.92,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-20T00:00:00+02:00"
    },
    "2015-08-21": {
      "mfOrderCount": 0,
      "roi": -14.017642341619881,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 1,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 51.28,
      "customerReturnTotal": 2.33,
      "totalTransactionFees": -14.559999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "8A-E68O-9HSL",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491135",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1A-7W2H-LA7A",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543362",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 2.33,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-21",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 53.61,
      "lostWarehouseQty": 0,
      "netProfit": -63.825714285714305,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.033486590038314154,
      "totalServiceFees": -37.4,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -14.559999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -10.39,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -37.4,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -117.43571428571437,
      "totalSellingFees": -10.39,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -62.349999999999994,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-21T00:00:00+02:00"
    },
    "2015-08-26": {
      "mfOrderCount": 0,
      "roi": -235.04500818330604,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 12.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.21,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486087",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002622541",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-26",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": -66.00999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -169.97571428571388,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -78.99,
      "dailyNetProfit": -0.440191570881226,
      "totalServiceFees": -50.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.21,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 9.48,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.55,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -50.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -103.96571428571437,
      "totalSellingFees": 5.930000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -48.88,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-26T00:00:00+02:00"
    },
    "2015-08-27": {
      "mfOrderCount": 0,
      "roi": -364.417845484222,
      "shippingCredits": 1.49,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 3,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 19.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.95,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002535606",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564837",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517053",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600980",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002460768",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -4.28,
      "otherTotal": 0,
      "label": "2015-08-27",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 2.19,
      "grossIncome": -24.300000000000004,
      "lostWarehouseQty": 0,
      "netProfit": -88.57571428571438,
      "shippingCreditRefunds": -2.19,
      "mfOrderRevenue": 0,
      "totalRefundCost": -44.27,
      "dailyNetProfit": -0.12831417624521077,
      "totalServiceFees": 0,
      "promotionalRebates": -1.49,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.95,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 7.989999999999999,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.95,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -4.28,
      "totalExpenses": -64.27571428571432,
      "totalSellingFees": 2.039999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -9.190000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-27T00:00:00+02:00"
    },
    "2015-08-24": {
      "mfOrderCount": 0,
      "roi": 32.701356213672824,
      "shippingCredits": 2.35,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 95.88999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -24.11,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516934",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486287",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CM-W7I0-8B04",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002641861",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IM-QXL0-GHDK",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470022",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436768",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551173",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "CF-9GH1-53BV",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-24",
      "fbaOrderCount": 14,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 95.88999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -31.455714285714297,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.09053639846743287,
      "totalServiceFees": -18.900000000000002,
      "promotionalRebates": -2.35,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -24.11,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -29.25,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -18.900000000000002,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -127.34571428571432,
      "totalSellingFees": -29.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -72.26,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-24T00:00:00+02:00"
    },
    "2015-08-25": {
      "mfOrderCount": 0,
      "roi": 68.71499439910386,
      "shippingCredits": 6.3,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 124.11999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -34.25,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663348",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516892",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002630211",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451720",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-08-25",
      "fbaOrderCount": 14,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 6.3,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 105.42999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -12.1457142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -24.99,
      "dailyNetProfit": 0.16452107279693487,
      "totalServiceFees": -7.3,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -34.25,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 3,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -23.939999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -7.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -117.57571428571437,
      "totalSellingFees": -20.939999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -62.489999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-25T00:00:00+02:00"
    },
    "2015-10-12": {
      "mfOrderCount": 0,
      "roi": 1095.8370239149694,
      "shippingCredits": 1.63,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 94.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -13.78,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002550940",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003121151",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584059",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002361206",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003042488",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003186923",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224524",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469043",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-12",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": -405.03000000000003,
      "lostWarehouseQty": 0,
      "netProfit": -426.2457142857151,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -499.99,
      "dailyNetProfit": -1.4220689655172414,
      "totalServiceFees": 0,
      "promotionalRebates": -1.63,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -13.78,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 70,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -22.350000000000005,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -21.215714285714288,
      "totalSellingFees": 47.64999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": 33.86999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-12T00:00:00+02:00"
    },
    "2015-10-13": {
      "mfOrderCount": 0,
      "roi": 70.41984732824423,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 71.43999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.879999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003006144",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "WL-9ARO-T0D9",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187296",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715473",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108154",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -13.32,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-10-13",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 71.43999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -25.565714285714296,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.113103448275862,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.879999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.72,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -13.32,
      "totalExpenses": -97.00571428571438,
      "totalSellingFees": -17.72,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -41.92,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-13T00:00:00+02:00"
    },
    "2015-12-21": {
      "mfOrderCount": 0,
      "roi": 132.63018322082934,
      "shippingCredits": 5.1,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 189.50000000000003,
      "customerReturnTotal": 0,
      "totalTransactionFees": -41.260000000000005,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003752488",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715459",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IM-QXL0-GHDK",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449269",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003120525",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546864",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002508896",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003054337",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003006209",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547014",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543423",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003335313",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H1-2K69-QR20",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003449365",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-21",
      "fbaOrderCount": 16,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.49,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 192.99000000000004,
      "lostWarehouseQty": 0,
      "netProfit": 54.94428571428571,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.42157088122605374,
      "totalServiceFees": 0,
      "promotionalRebates": -5.1,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -41.260000000000005,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -41.699999999999996,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -138.0457142857142,
      "totalSellingFees": -41.699999999999996,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -82.96000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-21T00:00:00+01:00"
    },
    "2015-12-04": {
      "mfOrderCount": 0,
      "roi": 185.92828685258965,
      "shippingCredits": 3.56,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 175.86,
      "customerReturnTotal": 0,
      "totalTransactionFees": -31.560000000000002,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002605068",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555773",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "2V-D2UM-AZA4",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451523",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003782870",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914221",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-04",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.56,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 179.42000000000002,
      "lostWarehouseQty": 0,
      "netProfit": 61.5842857142857,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4470114942528736,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -31.560000000000002,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.19,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -117.83571428571437,
      "totalSellingFees": -31.19,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -62.75,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-04T00:00:00+01:00"
    },
    "2015-12-20": {
      "mfOrderCount": 0,
      "roi": 74.16242294291071,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 64.97999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.22,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002546864",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451916",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224778",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -17.64,
      "otherTotal": 0,
      "label": "2015-12-20",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 64.97999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -27.41571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.10601532567049804,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.22,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -17.64,
      "totalExpenses": -92.39571428571438,
      "totalSellingFees": -12.45,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.31,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-20T00:00:00+01:00"
    },
    "2015-06-30": {
      "mfOrderCount": 0,
      "roi": -13.426329555361807,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 9.93,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.21,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "QI-NUVK-181X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-W5IH-VZ3Q",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -4.26,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-06-30",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 9.93,
      "lostWarehouseQty": 0,
      "netProfit": -56.62571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.0059003831417624485,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.21,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -4.26,
      "totalExpenses": -66.55571428571432,
      "totalSellingFees": -2,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -11.469999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-06-30T00:00:00+02:00"
    },
    "2015-12-23": {
      "mfOrderCount": 0,
      "roi": 29.93087927985854,
      "shippingCredits": 16.48,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 66.22,
      "customerReturnTotal": 0,
      "totalTransactionFees": -30.560000000000002,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003867859",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002565219",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224574",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002975857",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003782844",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555695",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.4,
      "otherTotal": 0,
      "label": "2015-12-23",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 14.61,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 80.83,
      "lostWarehouseQty": 0,
      "netProfit": -36.46571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.07134099616858236,
      "totalServiceFees": 0,
      "promotionalRebates": -1.87,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -30.560000000000002,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.25,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.4,
      "totalExpenses": -117.29571428571437,
      "totalSellingFees": -17.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -62.21,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-23T00:00:00+01:00"
    },
    "2015-12-22": {
      "mfOrderCount": 0,
      "roi": 216.23890850109723,
      "shippingCredits": 10.01,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": -0.15,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 317.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -50.45,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003727775",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003460860",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547014",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002604914",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002487667",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002728450",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543413",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-12-22",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 13.5,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 331.45,
      "lostWarehouseQty": 0,
      "netProfit": 171.5542857142862,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.8683524904214559,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -50.45,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -54.21,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.15,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -159.895714285714,
      "totalSellingFees": -54.21,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -104.81,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-22T00:00:00+01:00"
    },
    "2015-12-08": {
      "mfOrderCount": 0,
      "roi": -64.7272727272727,
      "shippingCredits": 4.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 3,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 59.93000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.24,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003590959",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002914689",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582967",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584364",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "2V-D2UM-AZA4",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002604999",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-12-08",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 8.730000000000004,
      "lostWarehouseQty": 0,
      "netProfit": -71.10571428571433,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -56.18000000000001,
      "dailyNetProfit": -0.06137931034482757,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.24,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 7.140000000000001,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.65,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -79.83571428571436,
      "totalSellingFees": -7.51,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -24.75,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-08T00:00:00+01:00"
    },
    "2015-12-25": {
      "mfOrderCount": 0,
      "roi": 708.4680851063831,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002604914",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-25",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": -189.99,
      "lostWarehouseQty": 0,
      "netProfit": -221.5757142857138,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -189.99,
      "dailyNetProfit": -0.6378927203065134,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 23.5,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -31.58571428571428,
      "totalSellingFees": 23.5,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": 23.5,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-25T00:00:00+01:00"
    },
    "2015-08-06": {
      "mfOrderCount": 0,
      "roi": 9.87707569549277,
      "shippingCredits": 2.1,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 50.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.96,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002584273",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491112",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "W0-USX7-JCWK",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584338",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "KA-A1E7-PU20",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -24.46,
      "miscExpenses": 3,
      "otherTotal": 0,
      "label": "2015-08-06",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 50.95,
      "lostWarehouseQty": 0,
      "netProfit": -50.5057142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.017547892720306508,
      "totalServiceFees": 0,
      "promotionalRebates": -2.1,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.96,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.95,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -24.46,
      "totalExpenses": -101.45571428571438,
      "totalSellingFees": -11.95,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -46.370000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-06T00:00:00+02:00"
    },
    "2015-12-24": {
      "mfOrderCount": 0,
      "roi": 181.08651911468814,
      "shippingCredits": 8.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 130.72,
      "customerReturnTotal": 0,
      "totalTransactionFees": -26.83,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1004188486",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003867924",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002527541",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-24",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 8.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 139.7,
      "lostWarehouseQty": 0,
      "netProfit": 34.91428571428568,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.3448275862068966,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -26.83,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -22.869999999999997,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -104.78571428571436,
      "totalSellingFees": -22.869999999999997,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -49.699999999999996,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-24T00:00:00+01:00"
    },
    "2015-04-25": {
      "mfOrderCount": 0,
      "roi": "INF",
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-04-25",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -55.0857142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -55.0857142857143,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": 0,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-04-25T00:00:00+02:00"
    },
    "2015-12-27": {
      "mfOrderCount": 0,
      "roi": 77.4327122153209,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 59.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.49,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1004215779",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -13.32,
      "otherTotal": 0,
      "label": "2015-12-27",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 59.99,
      "lostWarehouseQty": 0,
      "netProfit": -28.90571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.10030651340996169,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.49,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -13.32,
      "totalExpenses": -88.89571428571438,
      "totalSellingFees": -9,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -33.81,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-27T00:00:00+01:00"
    },
    "2015-08-31": {
      "mfOrderCount": 0,
      "roi": -8.715231788079468,
      "shippingCredits": 1.93,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 34.46,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.719999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663368",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368504",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002641871",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486133",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "7E-BU6I-D0OJ",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-31",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 34.46,
      "lostWarehouseQty": 0,
      "netProfit": -58.37571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.012605363984674326,
      "totalServiceFees": -17.2,
      "promotionalRebates": -1.93,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.719999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -10.83,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -17.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -92.83571428571437,
      "totalSellingFees": -10.83,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.75,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-31T00:00:00+02:00"
    },
    "2015-08-30": {
      "mfOrderCount": 0,
      "roi": 207.10512414157424,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 116.27,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.219999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002731256",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491118",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537843",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-30",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 116.27,
      "lostWarehouseQty": 0,
      "netProfit": 23.324285714285704,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.3004214559386973,
      "totalServiceFees": -7.5,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.219999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.14,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -7.5,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -92.94571428571437,
      "totalSellingFees": -20.14,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.86,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-30T00:00:00+02:00"
    },
    "2015-12-26": {
      "mfOrderCount": 0,
      "roi": 278.9883268482491,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": -0.44999999999999996,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 11.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003867892",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491164",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 7.49,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-26",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 7.49,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 19.48,
      "lostWarehouseQty": 0,
      "netProfit": -40.745714285714286,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.054942528735632185,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 1,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -0.44999999999999996,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -60.225714285714304,
      "totalSellingFees": -3.15,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -5.14,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-26T00:00:00+01:00"
    },
    "2015-12-29": {
      "mfOrderCount": 0,
      "roi": 191.92439862542952,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 16.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.92,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003335219",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-12-29",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 16.99,
      "lostWarehouseQty": 0,
      "netProfit": -43.91571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.042796934865900374,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.92,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.9,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -60.90571428571431,
      "totalSellingFees": -3.9,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -5.82,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-29T00:00:00+01:00"
    },
    "2015-10-18": {
      "mfOrderCount": 0,
      "roi": 290.90429738992884,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 148.27,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.99,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002508116",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003270087",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002833003",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-18",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 148.27,
      "lostWarehouseQty": 0,
      "netProfit": 55.254285714285686,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4227586206896552,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.99,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -24.939999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -93.01571428571437,
      "totalSellingFees": -24.939999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -37.93,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-18T00:00:00+02:00"
    },
    "2015-12-28": {
      "mfOrderCount": 0,
      "roi": 127.4478330658106,
      "shippingCredits": 11.77,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 118.54999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -28.32,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1004226541",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486284",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003867904",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461265",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1004145237",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003940093",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555963",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003868315",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-28",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 8.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 127.52999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 16.374285714285715,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.27379310344827584,
      "totalServiceFees": 0,
      "promotionalRebates": -2.79,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -28.32,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -27.749999999999996,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -111.15571428571437,
      "totalSellingFees": -27.749999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -56.06999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-28T00:00:00+01:00"
    },
    "2015-10-19": {
      "mfOrderCount": 0,
      "roi": 76.9877675840979,
      "shippingCredits": 1.47,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 67.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.979999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002584528",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003306179",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551125",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555898",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242003",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002973137",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605281",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516859",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-19",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.47,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 69.45,
      "lostWarehouseQty": 0,
      "netProfit": -24.87571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11574712643678164,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.979999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -21.259999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -94.32571428571437,
      "totalSellingFees": -21.259999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -39.239999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-19T00:00:00+02:00"
    },
    "2015-12-16": {
      "mfOrderCount": 0,
      "roi": 47.89545145960622,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 58.12,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.88,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003769510",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537823",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003782784",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582626",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -11.9,
      "otherTotal": 0,
      "label": "2015-12-16",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 43.56999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -40.97571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -14.55,
      "dailyNetProfit": 0.05406130268199231,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.88,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 1.74,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.420000000000002,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -11.9,
      "totalExpenses": -84.54571428571437,
      "totalSellingFees": -9.680000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -29.46,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-16T00:00:00+01:00"
    },
    "2015-11-24": {
      "mfOrderCount": 0,
      "roi": 109.79910391675094,
      "shippingCredits": 20.560000000000002,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 132.36999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -38.02,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003242450",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002583982",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427861",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "IM-QXL0-GHDK",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486133",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486068",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564744",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002509113",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-11-24",
      "fbaOrderCount": 12,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 12.790000000000003,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 145.15999999999997,
      "lostWarehouseQty": 0,
      "netProfit": 20.88428571428568,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.29107279693486576,
      "totalServiceFees": 0,
      "promotionalRebates": -7.77,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -38.02,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.169999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -124.27571428571432,
      "totalSellingFees": -31.169999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -69.19,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-24T00:00:00+01:00"
    },
    "2015-10-16": {
      "mfOrderCount": 0,
      "roi": 205.14304082288652,
      "shippingCredits": 1.21,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 94.92999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.01,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002555429",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012151",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002601005",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9G-24UY-ST11",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002601009",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-16",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 94.92999999999999,
      "lostWarehouseQty": 0,
      "netProfit": 8.734285714285669,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.24452107279693483,
      "totalServiceFees": 0,
      "promotionalRebates": -1.21,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.01,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -19.1,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -86.19571428571437,
      "totalSellingFees": -19.1,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -31.11,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-16T00:00:00+02:00"
    },
    "2015-10-17": {
      "mfOrderCount": 0,
      "roi": 8426.624737945494,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 1,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 9.99,
      "customerReturnTotal": 396.73,
      "totalTransactionFees": -1.92,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002410628",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469043",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 396.73,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-17",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 406.72,
      "lostWarehouseQty": 0,
      "netProfit": 346.86428571428496,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 1.5400383141762455,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.92,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -59.855714285714306,
      "totalSellingFees": -2.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -4.77,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-17T00:00:00+02:00"
    },
    "2015-10-14": {
      "mfOrderCount": 0,
      "roi": 38.1965686926453,
      "shippingCredits": 4.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 98.92999999999999,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.549999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003006171",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187406",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002973151",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003032308",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469489",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012196",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663845",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-14",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 103.91,
      "lostWarehouseQty": 0,
      "netProfit": -26.36571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11003831417624521,
      "totalServiceFees": -31.700000000000003,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.549999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -22.939999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -31.700000000000003,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -130.2757142857143,
      "totalSellingFees": -22.939999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -75.19,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-14T00:00:00+02:00"
    },
    "2015-10-15": {
      "mfOrderCount": 0,
      "roi": 178.50467289719617,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 98.33999999999997,
      "customerReturnTotal": 0,
      "totalTransactionFees": -13.799999999999999,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "RO-M3RK-KJI4",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555949",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584293",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003012151",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224602",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003006240",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-15",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 98.33999999999997,
      "lostWarehouseQty": 0,
      "netProfit": 7.944285714285648,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.2414942528735631,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -13.799999999999999,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -21.51,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -90.39571428571438,
      "totalSellingFees": -21.51,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -35.31,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-15T00:00:00+02:00"
    },
    "2015-08-08": {
      "mfOrderCount": 0,
      "roi": -55.58300395256918,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 17.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -3.08,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002516966",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470149",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-08",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 17.98,
      "lostWarehouseQty": 0,
      "netProfit": -77.58571428571435,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.08620689655172416,
      "totalServiceFees": -32,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -3.08,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -32,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -95.56571428571438,
      "totalSellingFees": -5.4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -40.480000000000004,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-08T00:00:00+02:00"
    },
    "2015-08-09": {
      "mfOrderCount": 0,
      "roi": 49.98184678688126,
      "shippingCredits": 6.300000000000001,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 187.61999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -22.839999999999996,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002565184",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451569",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "13-WXO2-I0QN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600934",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584309",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491060",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469708",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555580",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469705",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584019",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461374",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -25.240000000000002,
      "otherTotal": 0,
      "label": "2015-08-09",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 6.300000000000001,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 123.92999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -13.78571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -69.99,
      "dailyNetProfit": 0.15823754789272024,
      "totalServiceFees": -1.3,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -22.839999999999996,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 8.4,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -41.65,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -1.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -25.240000000000002,
      "totalExpenses": -137.71571428571423,
      "totalSellingFees": -33.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -82.63,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-09T00:00:00+02:00"
    },
    "2015-10-10": {
      "mfOrderCount": 0,
      "roi": 166.4979970482817,
      "shippingCredits": 8.56,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 141.41,
      "customerReturnTotal": 0,
      "totalTransactionFees": -25.159999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002360848",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224761",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002574663",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002535558",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451489",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002925962",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "7S-4XVW-JGI8",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003186913",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-10",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 126.4,
      "lostWarehouseQty": 0,
      "netProfit": 23.884285714285703,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -19.99,
      "dailyNetProfit": 0.30256704980842913,
      "totalServiceFees": 0,
      "promotionalRebates": -3.58,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -25.159999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 4.35,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -26.62,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -102.51571428571437,
      "totalSellingFees": -22.270000000000003,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -47.43,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-10T00:00:00+02:00"
    },
    "2015-10-11": {
      "mfOrderCount": 0,
      "roi": 15.168539325842687,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 29.979999999999997,
      "customerReturnTotal": 0,
      "totalTransactionFees": -3.21,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002715408",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427867",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0.77,
      "dateStartYear": 2015,
      "inboundShippingFees": -16.29,
      "otherTotal": 0,
      "label": "2015-10-11",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0.77,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 30.749999999999996,
      "lostWarehouseQty": 0,
      "netProfit": -51.0357142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.015517241379310334,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -3.21,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 1,
      "subscriptionFees": 0,
      "fbaSellingFees": -7.199999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -16.29,
      "totalExpenses": -81.78571428571436,
      "totalSellingFees": -7.199999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.7,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-11T00:00:00+02:00"
    },
    "2015-08-04": {
      "mfOrderCount": 0,
      "roi": -17.038055274200538,
      "shippingCredits": 4.359999999999999,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 130.86,
      "customerReturnTotal": 0,
      "totalTransactionFees": -21.57,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002543283",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "4Q-0FR6-NKBJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469515",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491035",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517053",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002487656",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419780",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469762",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002575187",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554824",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002360618",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-08-04",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 114.67000000000002,
      "lostWarehouseQty": 0,
      "netProfit": -78.63571428571433,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -16.19,
      "dailyNetProfit": -0.0902298850574712,
      "totalServiceFees": -86.79,
      "promotionalRebates": -4.359999999999999,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -21.57,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 1.94,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.799999999999997,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -86.79,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -193.30571428571378,
      "totalSellingFees": -29.859999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -138.22,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-04T00:00:00+02:00"
    },
    "2015-08-05": {
      "mfOrderCount": 0,
      "roi": -1.9235491528198363,
      "shippingCredits": 6.82,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 117.84,
      "customerReturnTotal": 0,
      "totalTransactionFees": -34.25,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451188",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002477426",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543362",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002490968",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486072",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555864",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564707",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554704",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427883",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "TY-VKTW-3JU9",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555801",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "L3-D22R-2PLE",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -19.05,
      "otherTotal": 0,
      "label": "2015-08-05",
      "fbaOrderCount": 16,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.9800000000000004,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 119.82000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -57.4357142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.0090038314176245,
      "totalServiceFees": -36.400000000000006,
      "promotionalRebates": -4.84,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -34.25,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -32.470000000000006,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -36.400000000000006,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -19.05,
      "totalExpenses": -177.25571428571382,
      "totalSellingFees": -32.470000000000006,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -122.17,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-05T00:00:00+02:00"
    },
    "2015-05-23": {
      "mfOrderCount": 0,
      "roi": "INF",
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-05-23",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -55.0857142857143,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -55.0857142857143,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": 0,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-05-23T00:00:00+02:00"
    },
    "2015-08-07": {
      "mfOrderCount": 0,
      "roi": -55.19776503455375,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 30.47,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.38,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002537143",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584309",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584173",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -32.57,
      "otherTotal": 0,
      "label": "2015-08-07",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 30.47,
      "lostWarehouseQty": 0,
      "netProfit": -92.62571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.1438314176245211,
      "totalServiceFees": -11.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.38,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.62,
      "inventoryStorageFees": -9.84,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -11.6,
      "lostInboundQty": 0,
      "totalInventoryFees": -9.84,
      "cogs": 0,
      "totalShippingFees": -32.57,
      "totalExpenses": -123.09571428571432,
      "totalSellingFees": -8.62,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -68.01,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-07T00:00:00+02:00"
    },
    "2015-08-01": {
      "mfOrderCount": 0,
      "roi": -69.5303550973654,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 15.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.68,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-01",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 15.96,
      "lostWarehouseQty": 0,
      "netProfit": -91.50571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.1395402298850575,
      "totalServiceFees": -40.7,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.68,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -40.7,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -107.46571428571437,
      "totalSellingFees": -4,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -52.38,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-01T00:00:00+02:00"
    },
    "2015-08-02": {
      "mfOrderCount": 0,
      "roi": -37.80997492337699,
      "shippingCredits": 4.3999999999999995,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 63.150000000000006,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.13,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451867",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "8A-NIHX-9I1Y",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "TU-0BJJ-JYB3",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002380433",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554620",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554713",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537000",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "QI-NUVK-181X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0.32,
      "dateStartYear": 2015,
      "inboundShippingFees": -69.66000000000001,
      "otherTotal": 0,
      "label": "2015-08-02",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.49,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 66.96,
      "lostWarehouseQty": 1,
      "netProfit": -95.79571428571438,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.15597701149425292,
      "totalServiceFees": -3.3,
      "promotionalRebates": -4.3999999999999995,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.13,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.58,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -3.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -69.66000000000001,
      "totalExpenses": -162.75571428571396,
      "totalSellingFees": -17.58,
      "giftWrapCredits": 3.49,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -107.67,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0.32,
      "date": "2015-08-02T00:00:00+02:00"
    },
    "2015-08-03": {
      "mfOrderCount": 0,
      "roi": 63.734385272846815,
      "shippingCredits": 3.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 120.53,
      "customerReturnTotal": 0,
      "totalTransactionFees": -28.369999999999997,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002490968",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "HF-45OJ-X650",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "OT-5UUJ-RVCC",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1A-FIFO-L8J9",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0L-SLJ7-RYZD",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428524",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555590",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491133",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-03",
      "fbaOrderCount": 11,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 124.52,
      "lostWarehouseQty": 0,
      "netProfit": -6.615714285714303,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1857088122605364,
      "totalServiceFees": -20.7,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -28.369999999999997,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -26.98,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -20.7,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -131.13571428571427,
      "totalSellingFees": -26.98,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -76.05,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-03T00:00:00+02:00"
    },
    "2015-12-10": {
      "mfOrderCount": 0,
      "roi": 136.4417705199828,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 55.019999999999996,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.81,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002546864",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003473986",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451176",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-10",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 55.019999999999996,
      "lostWarehouseQty": 0,
      "netProfit": -23.335714285714282,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.12164750957854405,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.81,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.459999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -78.35571428571436,
      "totalSellingFees": -11.459999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -23.27,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-10T00:00:00+01:00"
    },
    "2015-11-26": {
      "mfOrderCount": 0,
      "roi": 109.43396226415096,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 9.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.92,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002832909",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-26",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 9.99,
      "lostWarehouseQty": 0,
      "netProfit": -49.86571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.020000000000000004,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.92,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -59.855714285714306,
      "totalSellingFees": -2.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -4.77,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-26T00:00:00+01:00"
    },
    "2015-11-22": {
      "mfOrderCount": 0,
      "roi": 109.84102952308861,
      "shippingCredits": 3.41,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 55.440000000000005,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003120626",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003144959",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003006181",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003187326",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486195",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002367915",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-22",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 55.440000000000005,
      "lostWarehouseQty": 0,
      "netProfit": -26.06571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11118773946360155,
      "totalServiceFees": 0,
      "promotionalRebates": -3.41,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -16.419999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -81.50571428571436,
      "totalSellingFees": -16.419999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.419999999999998,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-22T00:00:00+01:00"
    },
    "2015-09-30": {
      "mfOrderCount": 0,
      "roi": 102.36983842010771,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 56.36,
      "customerReturnTotal": 0,
      "totalTransactionFees": -11.1,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584246",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451552",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H8-Q28U-TY0M",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-30",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 56.36,
      "lostWarehouseQty": 0,
      "netProfit": -26.57571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.10923371647509578,
      "totalServiceFees": -5.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -11.1,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -5.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -82.93571428571437,
      "totalSellingFees": -11.15,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -27.85,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-30T00:00:00+02:00"
    },
    "2015-12-11": {
      "mfOrderCount": 0,
      "roi": 176.732249786142,
      "shippingCredits": 2.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 129.4,
      "customerReturnTotal": 0,
      "totalTransactionFees": -21.840000000000003,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002584306",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003335445",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002342482",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003582856",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002527527",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5T-QNLP-N9DN",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-11",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 129.4,
      "lostWarehouseQty": 0,
      "netProfit": 27.554285714285697,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.3166283524904215,
      "totalServiceFees": 0,
      "promotionalRebates": -2.99,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -21.840000000000003,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -24.919999999999998,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -101.84571428571438,
      "totalSellingFees": -24.919999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -46.760000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-11T00:00:00+01:00"
    },
    "2015-08-13": {
      "mfOrderCount": 0,
      "roi": 167.9510703363915,
      "shippingCredits": 9.010000000000002,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 166.23000000000002,
      "customerReturnTotal": 0,
      "totalTransactionFees": -33.239999999999995,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002436657",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "41-HGEK-UAQP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603201",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H8-Q28U-TY0M",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491060",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002640923",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-13",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 9.010000000000002,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 175.24,
      "lostWarehouseQty": 0,
      "netProfit": 54.7542857142857,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4208429118773947,
      "totalServiceFees": -0.3,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -33.239999999999995,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.86,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -0.3,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -120.48571428571431,
      "totalSellingFees": -31.86,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -65.39999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-13T00:00:00+02:00"
    },
    "2015-08-12": {
      "mfOrderCount": 0,
      "roi": -27.506791071217656,
      "shippingCredits": 8.04,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 60.92,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.509999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002490990",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605316",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "G5-K28K-U8RJ",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "KV-HZ0V-ZHVN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002419763",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600945",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491054",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543362",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -33.11,
      "otherTotal": 0,
      "label": "2015-08-12",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 6.749999999999999,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 61.38,
      "lostWarehouseQty": 0,
      "netProfit": -78.37571428571434,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -6.29,
      "dailyNetProfit": -0.08923371647509573,
      "totalServiceFees": -16.099999999999998,
      "promotionalRebates": -1.29,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.509999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.29,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -17.24,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -16.099999999999998,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -33.11,
      "totalExpenses": -139.7557142857142,
      "totalSellingFees": -14.95,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -84.66999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-12T00:00:00+02:00"
    },
    "2015-08-11": {
      "mfOrderCount": 0,
      "roi": -16.862131873859802,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 31.9,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.97,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002469705",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002517322",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600970",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-W5IH-VZ3Q",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-08-11",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 31.9,
      "lostWarehouseQty": 0,
      "netProfit": -61.555714285714316,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.024789272030651364,
      "totalServiceFees": -18.2,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.97,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.2,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -18.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -93.45571428571438,
      "totalSellingFees": -9.2,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -38.370000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-11T00:00:00+02:00"
    },
    "2015-10-06": {
      "mfOrderCount": 0,
      "roi": 44.91904394757131,
      "shippingCredits": 6.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 85.85,
      "customerReturnTotal": 0,
      "totalTransactionFees": -17.11,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "UJ-C745-D7S0",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002361208",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715503",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486097",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108097",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486035",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368499",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 3.14,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 13,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-10-06",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 93.97999999999999,
      "lostWarehouseQty": 2,
      "netProfit": -25.955714285714283,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11160919540229883,
      "totalServiceFees": 0,
      "promotionalRebates": -1.99,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -17.11,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -18.28,
      "inventoryStorageFees": -29.46,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -29.46,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -119.93571428571431,
      "totalSellingFees": -18.28,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -64.85,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 3.14,
      "date": "2015-10-06T00:00:00+02:00"
    },
    "2015-08-17": {
      "mfOrderCount": 0,
      "roi": 79.72426735960731,
      "shippingCredits": 12.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 236.01000000000002,
      "customerReturnTotal": 0,
      "totalTransactionFees": -60.68,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002650499",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002361230",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486064",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002543300",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368609",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002640897",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -37.050000000000004,
      "otherTotal": 0,
      "label": "2015-08-17",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 12.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 248.99,
      "lostWarehouseQty": 0,
      "netProfit": 55.36428571428567,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.42318007662835244,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -60.68,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -40.81,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -37.050000000000004,
      "totalExpenses": -193.6257142857138,
      "totalSellingFees": -40.81,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -138.54000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-17T00:00:00+02:00"
    },
    "2015-08-16": {
      "mfOrderCount": 0,
      "roi": 163.15179606025495,
      "shippingCredits": 12.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 145.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -31.65,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002486278",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002508891",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605225",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5T-QNLP-N9DN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491294",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491134",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002640868",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-16",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 12.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 158.97,
      "lostWarehouseQty": 0,
      "netProfit": 43.474285714285685,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.3776245210727969,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -31.65,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -28.76,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -115.49571428571437,
      "totalSellingFees": -28.76,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -60.41,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-16T00:00:00+02:00"
    },
    "2015-08-15": {
      "mfOrderCount": 0,
      "roi": 209.64155645006707,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 160.22,
      "customerReturnTotal": 0,
      "totalTransactionFees": -21.38,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002509012",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555756",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "HQ-VTKS-JZTP",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0L-0X3S-RYXU",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002486198",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555441",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "KA-A1E7-PU20",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 1.32,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-15",
      "fbaOrderCount": 7,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 161.54,
      "lostWarehouseQty": 1,
      "netProfit": 54.28428571428567,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.4190421455938697,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -21.38,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -30.79,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -107.25571428571438,
      "totalSellingFees": -30.79,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -52.17,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 1.32,
      "date": "2015-08-15T00:00:00+02:00"
    },
    "2015-08-14": {
      "mfOrderCount": 0,
      "roi": -61.58996605054696,
      "shippingCredits": 2.19,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 40.730000000000004,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.38,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002584266",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600980",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002509012",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555508",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-14",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 40.730000000000004,
      "lostWarehouseQty": 0,
      "netProfit": -120.39571428571432,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.2502298850574713,
      "totalServiceFees": -83.9,
      "promotionalRebates": -2.19,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.38,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -11.760000000000002,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -83.9,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -161.125714285714,
      "totalSellingFees": -11.760000000000002,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -106.04,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-14T00:00:00+02:00"
    },
    "2015-05-14": {
      "mfOrderCount": 0,
      "roi": -100,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 0,
      "customerReturnTotal": 0,
      "totalTransactionFees": 0,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-05-14",
      "fbaOrderCount": 0,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 0,
      "lostWarehouseQty": 0,
      "netProfit": -134.08571428571426,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.30268199233716475,
      "totalServiceFees": -79,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": 0,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": 0,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -79,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -134.08571428571426,
      "totalSellingFees": 0,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -79,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-05-14T00:00:00+02:00"
    },
    "2015-08-19": {
      "mfOrderCount": 0,
      "roi": 56.82680151706697,
      "shippingCredits": 2.06,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 99.23999999999998,
      "customerReturnTotal": 0,
      "totalTransactionFees": -14.79,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002477452",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "D3-7CDF-YCVW",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002436718",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002527593",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "LR-828C-VZAY",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-19",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 99.23999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -19.125714285714306,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1377777777777777,
      "totalServiceFees": -28.2,
      "promotionalRebates": -2.06,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -14.79,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.29,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -28.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -118.36571428571438,
      "totalSellingFees": -20.29,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -63.28,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-19T00:00:00+02:00"
    },
    "2015-08-18": {
      "mfOrderCount": 0,
      "roi": 159.86523842432626,
      "shippingCredits": 12.14,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 153.89000000000001,
      "customerReturnTotal": 0,
      "totalTransactionFees": -29.369999999999994,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002491385",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002535633",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603222",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600981",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002536996",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "9N-8PB7-W181",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555508",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-08-18",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 6.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 150.41000000000003,
      "lostWarehouseQty": 0,
      "netProfit": 37.44428571428571,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -10.47,
      "dailyNetProfit": 0.35452107279693496,
      "totalServiceFees": 0,
      "promotionalRebates": -5.15,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -29.369999999999994,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.92,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -31.43,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -112.96571428571437,
      "totalSellingFees": -28.509999999999998,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -57.879999999999995,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-08-18T00:00:00+02:00"
    },
    "2015-10-09": {
      "mfOrderCount": 0,
      "roi": 64.89675516224189,
      "shippingCredits": 2.32,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 75.94,
      "customerReturnTotal": 0,
      "totalTransactionFees": -14.66,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002342682",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003108141",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -20.06,
      "otherTotal": 0,
      "label": "2015-10-09",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.32,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 78.25999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -24.28571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.11800766283524904,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -14.66,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.74,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -20.06,
      "totalExpenses": -102.54571428571437,
      "totalSellingFees": -12.74,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -47.459999999999994,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-09T00:00:00+02:00"
    },
    "2015-10-08": {
      "mfOrderCount": 0,
      "roi": 67.3758865248227,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 25.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.16,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002715310",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "F9-6ODS-XRAF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537126",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564792",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-10-08",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 25.96,
      "lostWarehouseQty": 0,
      "netProfit": -44.635714285714286,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.04003831417624522,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.16,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.35,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -70.59571428571432,
      "totalSellingFees": -8.35,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -15.51,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-10-08T00:00:00+02:00"
    },
    "2015-11-08": {
      "mfOrderCount": 0,
      "roi": 1283.143507972665,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 1,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 9.99,
      "customerReturnTotal": 50.73,
      "totalTransactionFees": -1.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663807",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002622658",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 50.73,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-08",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 60.72,
      "lostWarehouseQty": 0,
      "netProfit": 1.244285714285683,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.21582375478927202,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -59.47571428571431,
      "totalSellingFees": -2.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -4.390000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-08T00:00:00+01:00"
    },
    "2015-11-09": {
      "mfOrderCount": 0,
      "roi": 197.57174392935988,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 26.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.3,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "NQ-4FQF-S5SC",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-09",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.96,
      "lostWarehouseQty": 0,
      "netProfit": -37.18571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.0685823754789272,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.3,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.76,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -64.1457142857143,
      "totalSellingFees": -2.76,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -9.059999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-09T00:00:00+01:00"
    },
    "2015-11-11": {
      "mfOrderCount": 0,
      "roi": 98.15512048192772,
      "shippingCredits": 2.98,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 57.940000000000005,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.350000000000001,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003006152",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605318",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002683130",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002728558",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003186978",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605261",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550859",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-11",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 2.98,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 52.63000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -29.01571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -8.29,
      "dailyNetProfit": 0.0998850574712644,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.350000000000001,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.59,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -16.8,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -81.64571428571436,
      "totalSellingFees": -14.21,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.560000000000002,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-11T00:00:00+01:00"
    },
    "2015-11-02": {
      "mfOrderCount": 0,
      "roi": 52.735765124555186,
      "shippingCredits": 11.480000000000002,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 132.36,
      "customerReturnTotal": 0,
      "totalTransactionFees": -24.66,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003144982",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002574663",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428956",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002546974",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002547048",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "F2-JAY7-UJNM",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321707",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-02",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.980000000000002,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 137.34,
      "lostWarehouseQty": 0,
      "netProfit": -7.665714285714288,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.18168582375478934,
      "totalServiceFees": -39.99,
      "promotionalRebates": -6.5,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -24.66,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -25.269999999999996,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -39.99,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -145.00571428571413,
      "totalSellingFees": -25.269999999999996,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -89.91999999999999,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-02T00:00:00+01:00"
    },
    "2015-11-03": {
      "mfOrderCount": 0,
      "roi": 97.83509903270384,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 42.95,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.46,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "F9-6ODS-XRAF",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428568",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002729500",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002368491",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663528",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-11-03",
      "fbaOrderCount": 5,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 42.95,
      "lostWarehouseQty": 0,
      "netProfit": -33.84571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.0813793103448276,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.46,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.25,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -76.79571428571435,
      "totalSellingFees": -12.25,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -21.71,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-03T00:00:00+01:00"
    },
    "2015-11-01": {
      "mfOrderCount": 0,
      "roi": 381.1357074109721,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 49.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.54,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002605319",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-01",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 49.99,
      "lostWarehouseQty": 0,
      "netProfit": -15.485714285714291,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.15172413793103448,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.54,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -8.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -65.47571428571432,
      "totalSellingFees": -8.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -10.39,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-01T00:00:00+01:00"
    },
    "2015-11-06": {
      "mfOrderCount": 0,
      "roi": 22.301836094939535,
      "shippingCredits": 10.18,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "miscExpense_test": 3,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 2,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 111.91,
      "customerReturnTotal": 0,
      "totalTransactionFees": -12.72,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002360987",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002554752",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537010",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003224492",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002428568",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002600960",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 3,
      "otherTotal": 0,
      "label": "2015-11-06",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 81.93,
      "lostWarehouseQty": 0,
      "netProfit": -40.145714285714284,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -29.979999999999997,
      "dailyNetProfit": 0.05724137931034482,
      "totalServiceFees": 0,
      "promotionalRebates": -10.18,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -12.72,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 7.199999999999999,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.84,
      "inventoryStorageFees": -40.63,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": -40.63,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -122.07571428571433,
      "totalSellingFees": -13.64,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -66.99000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-06T00:00:00+01:00"
    },
    "2015-11-07": {
      "mfOrderCount": 0,
      "roi": 81.79682912507339,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 30.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -9.18,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1003186837",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002555978",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-07",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 30.96,
      "lostWarehouseQty": 0,
      "netProfit": -41.15571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.05337164750957854,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -9.18,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -7.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -72.11571428571433,
      "totalSellingFees": -7.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -17.03,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-07T00:00:00+01:00"
    },
    "2015-11-04": {
      "mfOrderCount": 0,
      "roi": 50.41431261770247,
      "shippingCredits": 5.93,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 73.94,
      "customerReturnTotal": 0,
      "totalTransactionFees": -20.15,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002508896",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002832931",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469587",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0L-SLJ7-RYZD",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003031644",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-04",
      "fbaOrderCount": 6,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 5.93,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 79.87,
      "lostWarehouseQty": 0,
      "netProfit": -28.315714285714268,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.10256704980842916,
      "totalServiceFees": -17.8,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -20.15,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -15.149999999999999,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -17.8,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -108.18571428571437,
      "totalSellingFees": -15.149999999999999,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -53.099999999999994,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-04T00:00:00+01:00"
    },
    "2015-11-05": {
      "mfOrderCount": 0,
      "roi": 163.30767436435733,
      "shippingCredits": 3.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 108.89,
      "customerReturnTotal": 0,
      "totalTransactionFees": -22.47,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321610",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451014",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002537045",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-11-05",
      "fbaOrderCount": 10,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 3.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 112.88,
      "lostWarehouseQty": 0,
      "netProfit": 14.924285714285714,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.26823754789272025,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -22.47,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -20.400000000000002,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -97.95571428571438,
      "totalSellingFees": -20.400000000000002,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -42.870000000000005,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-11-05T00:00:00+01:00"
    },
    "2015-08-10": {
      "mfOrderCount": 0,
      "roi": 58.47846012832263,
      "shippingCredits": 1.2,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 55.959999999999994,
      "customerReturnTotal": 0,
      "totalTransactionFees": -10.59,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002537106",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002451461",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002564951",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0S-D2Q1-V6VH",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 12,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-08-10",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 1.2,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 69.16,
      "lostWarehouseQty": 1,
      "netProfit": -29.565714285714282,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.09777777777777777,
      "totalServiceFees": -20.6,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -10.59,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -12.45,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -20.6,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -98.72571428571437,
      "totalSellingFees": -12.45,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -43.64,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 12,
      "date": "2015-08-10T00:00:00+02:00"
    },
    "2015-12-30": {
      "mfOrderCount": 0,
      "roi": 187.1647509578544,
      "shippingCredits": 1.02,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 29.979999999999997,
      "customerReturnTotal": 0,
      "totalTransactionFees": -4.59,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002487592",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-30",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 29.979999999999997,
      "lostWarehouseQty": 0,
      "netProfit": -35.54571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.07486590038314175,
      "totalServiceFees": 0,
      "promotionalRebates": -1.02,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -4.59,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -5.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -65.52571428571432,
      "totalSellingFees": -5.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -10.44,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-30T00:00:00+01:00"
    },
    "2015-12-31": {
      "mfOrderCount": 0,
      "roi": 204.43524541691312,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 51.480000000000004,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.84,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002546864",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1004249962",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003460849",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-31",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 51.480000000000004,
      "lostWarehouseQty": 0,
      "netProfit": -20.515714285714274,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1324521072796935,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.84,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.07,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -71.99571428571433,
      "totalSellingFees": -9.07,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -16.91,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-31T00:00:00+01:00"
    },
    "2015-09-29": {
      "mfOrderCount": 0,
      "roi": 82.6600609756098,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 67.92,
      "customerReturnTotal": 0,
      "totalTransactionFees": -16.439999999999998,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002516474",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002469733",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002491065",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002400216",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-09-29",
      "fbaOrderCount": 8,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 47.93000000000001,
      "lostWarehouseQty": 0,
      "netProfit": -33.39571428571427,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -19.99,
      "dailyNetProfit": 0.08310344827586211,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -16.439999999999998,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 4.35,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -14.15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -81.32571428571435,
      "totalSellingFees": -9.8,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -26.24,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-29T00:00:00+02:00"
    },
    "2015-09-28": {
      "mfOrderCount": 0,
      "roi": -5413.541666666671,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 18.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -5.17,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002427813",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "H8-Q28U-TY0M",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002622658",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-28",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": -51.00999999999999,
      "lostWarehouseQty": 0,
      "netProfit": -107.05571428571436,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -69.99,
      "dailyNetProfit": -0.19911877394636013,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -5.17,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 8.4,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -4.1899999999999995,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -56.045714285714304,
      "totalSellingFees": 4.210000000000001,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -0.9599999999999991,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-28T00:00:00+02:00"
    },
    "2015-12-02": {
      "mfOrderCount": 0,
      "roi": 44.662829840373576,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 148.21000000000004,
      "customerReturnTotal": 0,
      "totalTransactionFees": -25.709999999999994,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002461031",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003752446",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "2V-D2UM-AZA4",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003782977",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003591024",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002470310",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002605238",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "0I-3CKH-8D0X",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1003321610",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-12-02",
      "fbaOrderCount": 9,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 133.22000000000003,
      "lostWarehouseQty": 0,
      "netProfit": -13.955714285714269,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -14.99,
      "dailyNetProfit": 0.1575862068965518,
      "totalServiceFees": -39.99,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -25.709999999999994,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.25,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -28.64,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -39.99,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -147.17571428571412,
      "totalSellingFees": -26.39,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -92.09,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-12-02T00:00:00+01:00"
    },
    "2015-09-25": {
      "mfOrderCount": 0,
      "roi": 29.749768303985142,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 18.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": 1.3600000000000003,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002901596",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715530",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -9.3,
      "otherTotal": 0,
      "label": "2015-09-25",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": -4.99,
      "fbaTransactionFeeRefunds": 4.99,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 13.999999999999998,
      "lostWarehouseQty": 0,
      "netProfit": -51.8757142857143,
      "shippingCreditRefunds": -4.99,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.012298850574712633,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -3.63,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -2.85,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -9.3,
      "totalExpenses": -65.87571428571431,
      "totalSellingFees": -2.85,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -10.790000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-25T00:00:00+02:00"
    },
    "2015-09-24": {
      "mfOrderCount": 0,
      "roi": -4.684774482930482,
      "shippingCredits": 4.99,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 33.26,
      "customerReturnTotal": 0,
      "totalTransactionFees": -13.32,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002565351",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "5T-QNLP-N9DN",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002715530",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002641993",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -15.870000000000001,
      "otherTotal": 0,
      "label": "2015-09-24",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 4.99,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 38.25,
      "lostWarehouseQty": 0,
      "netProfit": -56.96571428571431,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.007203065134099627,
      "totalServiceFees": -1.8,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -13.32,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -9.14,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -1.8,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -15.870000000000001,
      "totalExpenses": -95.21571428571437,
      "totalSellingFees": -9.14,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -40.13,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-24T00:00:00+02:00"
    },
    "2015-09-27": {
      "mfOrderCount": 0,
      "roi": 75.61950439648281,
      "shippingCredits": 3.14,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 21.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.51,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451252",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "6I-IIIQ-GJ8Q",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-27",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 21.97,
      "lostWarehouseQty": 0,
      "netProfit": -45.62571428571429,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.036245210727969344,
      "totalServiceFees": 0,
      "promotionalRebates": -3.14,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.51,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -6,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -67.59571428571431,
      "totalSellingFees": -6,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -12.51,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-27T00:00:00+02:00"
    },
    "2015-09-26": {
      "mfOrderCount": 0,
      "roi": 103.39366515837102,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 26.97,
      "customerReturnTotal": 0,
      "totalTransactionFees": -6.51,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002556020",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663175",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002901287",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-26",
      "fbaOrderCount": 3,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 26.97,
      "lostWarehouseQty": 0,
      "netProfit": -41.37571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.0525287356321839,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -6.51,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -6.75,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -68.34571428571431,
      "totalSellingFees": -6.75,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -13.26,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-26T00:00:00+02:00"
    },
    "2015-09-21": {
      "mfOrderCount": 0,
      "roi": 144.3735035913807,
      "shippingCredits": 1.54,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 1,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 27.950000000000003,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.42,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002451408",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002550915",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663368",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002603305",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002427838",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 8.96,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-21",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 30.620000000000005,
      "lostWarehouseQty": 1,
      "netProfit": -36.995714285714286,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": -6.29,
      "dailyNetProfit": 0.06931034482758622,
      "totalServiceFees": 0,
      "promotionalRebates": -1.54,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.42,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 2.29,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -7.4,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -67.61571428571432,
      "totalSellingFees": -5.11,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -12.530000000000001,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 8.96,
      "date": "2015-09-21T00:00:00+02:00"
    },
    "2015-09-20": {
      "mfOrderCount": 0,
      "roi": 139.12591050988556,
      "shippingCredits": 1.59,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 22.98,
      "customerReturnTotal": 0,
      "totalTransactionFees": -3.46,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002469812",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002663934",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "otherTotal": 0,
      "label": "2015-09-20",
      "fbaOrderCount": 2,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 22.98,
      "lostWarehouseQty": 0,
      "netProfit": -41.71571428571428,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.051226053639846746,
      "totalServiceFees": 0,
      "promotionalRebates": -1.59,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -3.46,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -6.15,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -64.6957142857143,
      "totalSellingFees": -6.15,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -9.61,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-20T00:00:00+02:00"
    },
    "2015-09-23": {
      "mfOrderCount": 0,
      "roi": -52.53784505788067,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 15.99,
      "customerReturnTotal": 0,
      "totalTransactionFees": -1.92,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002410672",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": -14.82,
      "otherTotal": 0,
      "label": "2015-09-23",
      "fbaOrderCount": 1,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 15.99,
      "lostWarehouseQty": 0,
      "netProfit": -72.78571428571433,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": -0.06781609195402297,
      "totalServiceFees": -13.2,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -1.92,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -3.75,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": -13.2,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": -14.82,
      "totalExpenses": -88.77571428571437,
      "totalSellingFees": -3.75,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -33.69,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-23T00:00:00+02:00"
    },
    "2015-09-22": {
      "mfOrderCount": 0,
      "roi": 230.74340527577934,
      "shippingCredits": 0,
      "lostInboundTotal": 0,
      "mfSellingFees": 0,
      "otherTransactionFeeRefunds": 0,
      "totalRefunds": 0,
      "fbaDisposalFees": 0,
      "customerReturnQty": 0,
      "costOfGoodsReturned": 0,
      "costOfGoodsDelivered": 0,
      "fbaOrderRevenue": 68.96,
      "customerReturnTotal": 0,
      "totalTransactionFees": -7.8,
      "chargebackTotal": 0,
      "rowsMissingBuyCostOrSupplier": [
        {
          "sku": "1002663356",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002551169",
          "supplier": null,
          "buy_cost": 0
        },
        {
          "sku": "1002584105",
          "supplier": null,
          "buy_cost": 0
        }
      ],
      "dateStart": "Apr 14, 2015",
      "dateEndYear": 2015,
      "longTermStorageFees": 0,
      "totalInventoryCredits": 0,
      "dateStartYear": 2015,
      "inboundShippingFees": 0,
      "miscExpenses": 10,
      "miscExpense_test2": 10,
      "otherTotal": 0,
      "label": "2015-09-22",
      "fbaOrderCount": 4,
      "damagedWarehouseTotal": 0,
      "giftWrapCreditRefunds": 0,
      "dateEnd": "Dec 31, 2015",
      "dateEndMonth": 12,
      "totalMiscellaneous": 0,
      "fbaTransactionFeeRefunds": 0,
      "mfLabelPurchases": 0,
      "promotionalRebateRefunds": 0,
      "grossIncome": 68.96,
      "lostWarehouseQty": 0,
      "netProfit": -6.975714285714312,
      "shippingCreditRefunds": 0,
      "mfOrderRevenue": 0,
      "totalRefundCost": 0,
      "dailyNetProfit": 0.1843295019157088,
      "totalServiceFees": 0,
      "promotionalRebates": 0,
      "guaranteeClaimsTotal": 0,
      "fbaTransactionFees": -7.8,
      "costOfGoodsAdjusted": 0,
      "sellingFeeRefunds": 0,
      "damagedWarehouseQty": 0,
      "subscriptionFees": 0,
      "fbaSellingFees": -13.05,
      "inventoryStorageFees": 0,
      "otherInventoryFees": 0,
      "otherTransactionFees": 0,
      "fbaLabelingPrepFees": 0,
      "otherServiceFees": 0,
      "lostInboundQty": 0,
      "totalInventoryFees": 0,
      "cogs": 0,
      "totalShippingFees": 0,
      "totalExpenses": -75.93571428571434,
      "totalSellingFees": -13.05,
      "giftWrapCredits": 0,
      "fbaReturnFees": 0,
      "totalExpensesWithoutCogs": -20.85,
      "otherQty": 0,
      "dateStartMonth": 4,
      "mfLabelRefunds": 0,
      "lostWarehouseTotal": 0,
      "date": "2015-09-22T00:00:00+02:00"
    }
  },
  "rows_by_NA_supplier": [
    [
      "Jun 29, 2015 8:43:56 PM PDT",
      "5929520841",
      "Order",
      "105-8422086-2902647",
      "KA-A1E7-PU20",
      "Snakes and Ladders",
      "1",
      "amazon.com",
      "Amazon",
      "FOOTHILL RANCH",
      "CA",
      "92610-1809",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Jun 30, 2015 2:52:41 PM PDT",
      "5929520841",
      "Order",
      "104-4951257-3674602",
      "QI-NUVK-181X",
      "Marvel Avengers 100 piece Puzzle",
      "1",
      "amazon.com",
      "Amazon",
      "IRVINE",
      "CA",
      "92604-8626",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jun 30, 2015 11:29:02 PM PDT",
      "5929520841",
      "Order",
      "102-3632691-2730618",
      "LR-W5IH-VZ3Q",
      "Splash-n-Swim 48 inch Inflatable Ride On Pool Toy Whale Orca 4+",
      "1",
      "amazon.com",
      "Amazon",
      "Laurel",
      "MD",
      "20723",
      "",
      "5.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.40"
    ],
    [
      "Jul 1, 2015 3:11:56 PM PDT",
      "5929520841",
      "Order",
      "002-2296549-2703451",
      "LD-P78C-PJEI",
      "The Cat in the Hat Comes Back",
      "1",
      "amazon.com",
      "Amazon",
      "PEMBROKE PINES",
      "FL",
      "33025-1050",
      "",
      "5.58",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.19",
      "-1.54",
      "0",
      "0",
      "1.85"
    ],
    [
      "Jul 1, 2015 8:21:44 PM PDT",
      "5929520841",
      "Order",
      "105-4884503-9163424",
      "SR-M8EI-K8NB",
      "The Cat in the Hat",
      "1",
      "amazon.com",
      "Amazon",
      "eugene",
      "OR",
      "97405",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.54",
      "0",
      "0",
      "-0.35"
    ],
    [
      "Jul 1, 2015 11:25:45 PM PDT",
      "5929520841",
      "Order",
      "109-3426366-6214658",
      "WE-WTVU-PSAT",
      "The Berenstain Bears and the Spooky Old Tree",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT LOUIS",
      "MO",
      "63123-7741",
      "",
      "2.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.72",
      "-1.54",
      "0",
      "0",
      "-0.77"
    ],
    [
      "Jul 2, 2015 12:44:47 PM PDT",
      "5929520841",
      "Order",
      "102-3479301-4941859",
      "L3-D22R-2PLE",
      "Marvin K. Mooney Will You Please Go Now!  (Bright and Early Books for Beginning Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "Arlington",
      "TX",
      "76001",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 2, 2015 3:06:39 PM PDT",
      "5929520841",
      "Order",
      "106-9045171-5483455",
      "L3-Q3XT-2PQY",
      "The South Was Right!",
      "1",
      "amazon.com",
      "Amazon",
      "ASHFORD",
      "AL",
      "36312-6442",
      "",
      "15.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-7.31",
      "0",
      "0",
      "9.91"
    ],
    [
      "Jul 3, 2015 9:39:22 AM PDT",
      "5929520841",
      "Order",
      "104-9640736-2015440",
      "WS-BYGF-W81P",
      "There's a Wocket in My Pocket (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "HORSESHOE BEND",
      "AR",
      "72512-2703",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.54",
      "0",
      "0",
      "-0.35"
    ],
    [
      "Jul 4, 2015 2:06:17 AM PDT",
      "5929520841",
      "Order",
      "103-9576793-5765856",
      "QI-NUVK-181X",
      "Marvel Avengers 100 piece Puzzle",
      "1",
      "amazon.com",
      "Amazon",
      "Honolulu",
      "HI",
      "96813",
      "",
      "3.99",
      "0",
      "1.05",
      "0",
      "0",
      "0",
      "-1.05",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 4, 2015 12:38:12 PM PDT",
      "5929520841",
      "Order",
      "108-4961575-0169021",
      "LR-W5IH-VZ3Q",
      "Splash-n-Swim 48 inch Inflatable Ride On Pool Toy Whale Orca 4+",
      "1",
      "amazon.com",
      "Amazon",
      "Stoughton",
      "MA",
      "02072",
      "",
      "5.94",
      "0",
      "1.38",
      "0",
      "0",
      "0",
      "-1.38",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.40"
    ],
    [
      "Jul 4, 2015 12:56:51 PM PDT",
      "5929520841",
      "Order",
      "110-6483567-1320215",
      "F9-6ODS-XRAF",
      "Kids Animal Split Ring - Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "SAN ANTONIO",
      "TX",
      "78218-6023",
      "",
      "4.92",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "1.38"
    ],
    [
      "Jul 4, 2015 3:32:03 PM PDT",
      "5929520841",
      "Order",
      "104-3492856-3337044",
      "H8-Q28U-TY0M",
      "Marvel Avengers Assemble 50 Piece Tower Puzzle (3 assorted puzzles, Designs Vary)",
      "1",
      "amazon.com",
      "Amazon",
      "TOWNSEND",
      "MA",
      "01469-1374",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-3.63",
      "0",
      "0",
      "-0.64"
    ],
    [
      "Jul 4, 2015 4:22:09 PM PDT",
      "5929520841",
      "Order",
      "115-0273893-9556220",
      "QI-NUVK-181X",
      "Marvel Avengers 100 piece Puzzle",
      "1",
      "amazon.com",
      "Amazon",
      "HAYS",
      "KS",
      "67601-1530",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 4, 2015 7:05:11 PM PDT",
      "5936487761",
      "Order",
      "115-4093564-5107468",
      "LR-W5IH-VZ3Q",
      "Splash-n-Swim 48 inch Inflatable Ride On Pool Toy Whale Orca 4+",
      "1",
      "amazon.com",
      "Amazon",
      "CLEVELAND",
      "TN",
      "37312-7422",
      "",
      "5.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.40"
    ],
    [
      "Jul 4, 2015 7:42:14 PM PDT",
      "5936487761",
      "Order",
      "002-4042884-4951424",
      "AN-U4C4-C4GY",
      "Strengths Based Leadership: Great Leaders, Teams, and Why People Follow",
      "1",
      "amazon.com",
      "Amazon",
      "MEMPHIS",
      "TN",
      "38117-1943",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-1.92",
      "0",
      "0",
      "8.62"
    ],
    [
      "Jul 5, 2015 12:45:38 PM PDT",
      "5936487761",
      "Order",
      "105-9680052-2085838",
      "0E-9QJC-OR2J",
      "Down and Out in Paris and London",
      "1",
      "amazon.com",
      "Amazon",
      "West Point",
      "NY",
      "10997",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Jul 5, 2015 12:49:28 PM PDT",
      "5936487761",
      "Order",
      "102-5570057-7505803",
      "EV-9ABP-RBQS",
      "Unbroken: A World War II Story of Survival, Resilience, and Redemption",
      "1",
      "amazon.com",
      "Amazon",
      "West Bloomfield",
      "MI",
      "48324-1112",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Jul 5, 2015 3:06:59 PM PDT",
      "5936487761",
      "Order",
      "111-5598310-4055429",
      "13-B66X-I0QR",
      "Vera Wang by Wedgwood Grosgrain 9-Inch Rim Soup Plate",
      "3",
      "amazon.com",
      "Amazon",
      "BEVERLY",
      "MA",
      "01915-3851",
      "",
      "103.44",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-31.02",
      "-7.89",
      "0",
      "0",
      "64.53"
    ],
    [
      "Jul 5, 2015 3:06:59 PM PDT",
      "5936487761",
      "Order",
      "111-5598310-4055429",
      "13-B66X-I0QR",
      "Vera Wang by Wedgwood Grosgrain 9-Inch Rim Soup Plate",
      "2",
      "amazon.com",
      "Amazon",
      "BEVERLY",
      "MA",
      "01915-3851",
      "",
      "68.96",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.26",
      "0",
      "0",
      "63.70"
    ],
    [
      "Jul 5, 2015 3:06:59 PM PDT",
      "5936487761",
      "Order",
      "111-5598310-4055429",
      "13-B66X-I0QR",
      "Vera Wang by Wedgwood Grosgrain 9-Inch Rim Soup Plate",
      "1",
      "amazon.com",
      "Amazon",
      "BEVERLY",
      "MA",
      "01915-3851",
      "",
      "34.48",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.63",
      "0",
      "0",
      "30.85"
    ],
    [
      "Jul 5, 2015 8:42:22 PM PDT",
      "5936487761",
      "Order",
      "102-6703820-0441018",
      "04-9TA1-1XD5",
      "The New Adventures of Curious George",
      "1",
      "amazon.com",
      "Amazon",
      "INDIO",
      "CA",
      "92201-7082",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-2.33",
      "0",
      "0",
      "-0.29"
    ],
    [
      "Jul 5, 2015 10:55:21 PM PDT",
      "5936487761",
      "Order",
      "002-5885250-4845036",
      "W7-JN1F-MKFE",
      "Oh, Say Can You Say?",
      "1",
      "amazon.com",
      "Amazon",
      "BELLINGHAM",
      "WA",
      "98225-4402",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 6, 2015 12:54:20 AM PDT",
      "5936487761",
      "Order",
      "105-7942964-9543462",
      "NU-KQVE-BS0E",
      "The Mailroom: Hollywood History from the Bottom Up",
      "1",
      "amazon.com",
      "Amazon",
      "Englewood Cliffs",
      "NJ",
      "07632-2728",
      "",
      "19.99",
      "0",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-6.91",
      "0",
      "0",
      "13.72"
    ],
    [
      "Jul 6, 2015 10:13:08 AM PDT",
      "5936487761",
      "Order",
      "115-1103458-4096269",
      "DE-4MUF-L6HT",
      "SPLASH-N-SWIM MUD MASTER Tire Pool Swim Ring",
      "4",
      "amazon.com",
      "Amazon",
      "FARGO",
      "ND",
      "58103-6274",
      "",
      "19.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.00",
      "-7.68",
      "0",
      "0",
      "7.32"
    ],
    [
      "Jul 6, 2015 11:16:43 AM PDT",
      "5936487761",
      "Order",
      "102-4672575-0545858",
      "6I-FGHK-GJ6A",
      "Super Brain: Unleashing the Explosive Power of Your Mind to Maximize Health, Happiness, and Spiritual Well-Being",
      "1",
      "amazon.com",
      "Amazon",
      "WHEATFIELD",
      "IN",
      "46392-9663",
      "",
      "7.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-6.90",
      "0",
      "0",
      "3.52"
    ],
    [
      "Jul 6, 2015 11:25:37 PM PDT",
      "5936487761",
      "Order",
      "104-6806513-4238621",
      "P0-XECC-V321",
      "The Digging-Est Dog (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "SEATTLE",
      "WA",
      "98105-2164",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.73"
    ],
    [
      "Jul 7, 2015 1:17:44 AM PDT",
      "5936487761",
      "Order",
      "114-0028730-8678671",
      "GU-QM4E-NIL0",
      "Click, Clack, Moo: Cows That Type",
      "1",
      "amazon.com",
      "Amazon",
      "OAKDALE",
      "CA",
      "95361-8969",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 7, 2015 10:41:12 AM PDT",
      "5936487761",
      "Order",
      "106-0865074-6201840",
      "6B-7HIR-DB3U",
      "I'll Teach My Dog 100 Words",
      "1",
      "amazon.com",
      "Amazon",
      "Kenvil",
      "NJ",
      "07847-2563",
      "",
      "2.99",
      "0",
      "1.61",
      "0",
      "0",
      "0",
      "-1.61",
      "0",
      "0",
      "-1.80",
      "-1.67",
      "0",
      "0",
      "-0.48"
    ],
    [
      "Jul 7, 2015 1:20:06 PM PDT",
      "5936487761",
      "Order",
      "103-6710645-5721016",
      "IF-CNQL-D9DQ",
      "Crafter's Square Glitter Glue - 10 tubes(5 colors)",
      "1",
      "amazon.com",
      "Amazon",
      "Suffolk",
      "VA",
      "23432-1835",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Jul 7, 2015 2:59:10 PM PDT",
      "5936487761",
      "Order",
      "110-5598938-8874614",
      "22-KX18-Y40Z",
      "The Discoverers",
      "1",
      "amazon.com",
      "Amazon",
      "Washington",
      "DC",
      "20003",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Jul 7, 2015 3:41:54 PM PDT",
      "5936487761",
      "Order",
      "112-3325440-2120239",
      "UM-04SU-WTFY",
      "I Can Read With My Eyes Shut! (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Hanover",
      "NH",
      "03755-3402",
      "",
      "4.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Jul 7, 2015 5:53:46 PM PDT",
      "5936487761",
      "Order",
      "002-3641241-3305029",
      "A2-FPOE-2GYW",
      "Blind Faith: Our Misplaced Trust in the Stock Market and Smarter, Safer Ways to Invest",
      "1",
      "amazon.com",
      "Amazon",
      "UPPER SADDLE RIVER",
      "NJ",
      "07458-2366",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.67",
      "0",
      "0",
      "0.37"
    ],
    [
      "Jul 7, 2015 6:06:33 PM PDT",
      "5936487761",
      "Order",
      "002-9076465-1837805",
      "U8-PFTC-QDWT",
      "Food Rules: An Eater's Manual",
      "1",
      "amazon.com",
      "Amazon",
      "Jamaica Plain",
      "MA",
      "02130",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.67",
      "0",
      "0",
      "0.37"
    ],
    [
      "Jul 7, 2015 6:07:19 PM PDT",
      "5936487761",
      "Order",
      "103-2906305-7146618",
      "9Y-XU2O-IV15",
      "The Girlfriends' Guide to Pregnancy",
      "1",
      "amazon.com",
      "Amazon",
      "TACOMA",
      "WA",
      "98406-6906",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.67",
      "0",
      "0",
      "2.92"
    ],
    [
      "Jul 7, 2015 6:17:19 PM PDT",
      "5936487761",
      "Order",
      "102-0942765-1369025",
      "WP-4R1Z-CMAZ",
      "Don't Let's Go to the Dogs Tonight: An African Childhood",
      "1",
      "amazon.com",
      "Amazon",
      "LIVERMORE",
      "CA",
      "94550-2386",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Jul 8, 2015 11:21:24 AM PDT",
      "5936487761",
      "Order",
      "002-9959475-2639411",
      "2V-W07A-AZLJ",
      "Don't Know Much About the Civil War",
      "1",
      "amazon.com",
      "Amazon",
      "STARKVILLE",
      "MS",
      "39759-2752",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-2.33",
      "0",
      "0",
      "-1.14"
    ],
    [
      "Jul 8, 2015 2:26:53 PM PDT",
      "5936487761",
      "Order",
      "110-5996568-1173832",
      "4Q-0FR6-NKBJ",
      "Wilton Armetale Belle Mont 12-1/2 inch Egg Tray",
      "1",
      "amazon.com",
      "Amazon",
      "PEARLAND",
      "TX",
      "77584-4303",
      "",
      "47.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.05",
      "-4.41",
      "0",
      "0",
      "35.54"
    ],
    [
      "Jul 8, 2015 3:19:31 PM PDT",
      "5936487761",
      "Order",
      "113-4819064-3545039",
      "C8-7Q4H-1VKN",
      "A Basic Guide to Interpreting the Bible: Playing by the Rules",
      "1",
      "amazon.com",
      "Amazon",
      "GLEN ALLEN",
      "VIRGINIA",
      "23060-6219",
      "",
      "9.13",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.72",
      "-1.54",
      "0",
      "0",
      "4.87"
    ],
    [
      "Jul 8, 2015 3:19:34 PM PDT",
      "5936487761",
      "Order",
      "113-6229993-7962621",
      "P4-Q0A3-EP7K",
      "How Do Dinosaurs Eat Their Food?",
      "1",
      "amazon.com",
      "Amazon",
      "Aroda",
      "Va",
      "22709",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.67",
      "0",
      "0",
      "0.37"
    ],
    [
      "Jul 8, 2015 3:21:24 PM PDT",
      "5936487761",
      "Order",
      "107-3933236-7768263",
      "9K-7AT8-CFAB",
      "I'm a Big Brother",
      "1",
      "amazon.com",
      "Amazon",
      "LEVITTOWN",
      "PA",
      "19054-2315",
      "",
      "2.99",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-3.86",
      "0",
      "0",
      "-0.35"
    ],
    [
      "Jul 8, 2015 3:29:33 PM PDT",
      "5936487761",
      "Order",
      "114-2014437-0561005",
      "WS-D6A0-W8B5",
      "Five Little Monkeys Storybook Treasury (A Five Little Monkeys Story)",
      "1",
      "amazon.com",
      "Amazon",
      "Fredericksburg",
      "VA",
      "22408-8815",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-2.33",
      "0",
      "0",
      "2.26"
    ],
    [
      "Jul 8, 2015 5:46:17 PM PDT",
      "5936487761",
      "Order",
      "102-0197536-3290650",
      "LR-BU0U-VZD7",
      "Six by Seuss: A Treasury of Dr. Seuss Classics",
      "1",
      "amazon.com",
      "Amazon",
      "TORRANCE",
      "CA",
      "90504-1224",
      "",
      "9.23",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.73",
      "-2.33",
      "0",
      "0",
      "4.17"
    ],
    [
      "Jul 8, 2015 5:47:39 PM PDT",
      "5936487761",
      "Order",
      "104-4739032-3452253",
      "Q3-WEFG-USKQ",
      "David Gets In Trouble",
      "1",
      "amazon.com",
      "Amazon",
      "PORT SAINT LUCIE",
      "FLORIDA",
      "34953-7420",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Jul 8, 2015 6:20:32 PM PDT",
      "5936487761",
      "Order",
      "002-2875650-2442639",
      "5Q-AEQB-3NOQ",
      "Uncover a Dog (Uncover Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Lexington",
      "KY",
      "40515",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.92",
      "0",
      "0",
      "0.12"
    ],
    [
      "Jul 8, 2015 8:33:27 PM PDT",
      "5936487761",
      "Order",
      "109-9203351-7893800",
      "0Z-JVS3-YEQ3",
      "Skunk Works: A Personal Memoir of My Years at Lockheed",
      "1",
      "amazon.com",
      "Amazon",
      "FORT SMITH",
      "AR",
      "72903-7045",
      "",
      "9.19",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.73",
      "-6.65",
      "0",
      "0",
      "4.79"
    ],
    [
      "Jul 9, 2015 11:29:37 AM PDT",
      "5936487761",
      "Order",
      "110-2505113-4689007",
      "MN-F23X-SGSN",
      "Owl Moon",
      "1",
      "amazon.com",
      "Amazon",
      "MAMMOTH",
      "AZ",
      "85618-0361",
      "",
      "7.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "-3.99",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Jul 9, 2015 3:09:23 PM PDT",
      "5936487761",
      "Order",
      "104-2551277-1655445",
      "BQ-TIXK-BTT8",
      "Hello, He Lied: And Other Truths from the Hollywood Trenches",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NEW YORK",
      "10003-8451",
      "",
      "3.99",
      "0",
      "2.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-4.90",
      "0",
      "0",
      "0.12"
    ],
    [
      "Jul 9, 2015 3:25:41 PM PDT",
      "5936487761",
      "Order",
      "111-6110474-6427456",
      "I8-CDHD-A1L6",
      "My Story, My Song - Mother-Daughter Reflections on Life and Faith",
      "1",
      "amazon.com",
      "Amazon",
      "KENTWOOD",
      "MI",
      "49508-6419",
      "",
      "5.99",
      "0",
      "1.93",
      "0",
      "0",
      "0",
      "-1.93",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Jul 9, 2015 3:26:38 PM PDT",
      "5936487761",
      "Order",
      "102-9087513-0607467",
      "6M-U83I-056G",
      "Crayola Metallic Markers, 8 Count",
      "1",
      "amazon.com",
      "Amazon",
      "Pullman",
      "WA",
      "99163",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "1.45"
    ],
    [
      "Jul 9, 2015 5:44:08 PM PDT",
      "5936487761",
      "Order",
      "102-7503741-0497066",
      "VT-OXQU-G4U3",
      "Lean Thinking : Banish Waste and Create Wealth in Your Corporation",
      "1",
      "amazon.com",
      "Amazon",
      "TORRANCE",
      "CA",
      "90504-3409",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.73"
    ],
    [
      "Jul 9, 2015 6:03:47 PM PDT",
      "5936487761",
      "Order",
      "105-6774189-3557063",
      "N1-24GE-YWJJ",
      "Parts",
      "1",
      "amazon.com",
      "Amazon",
      "OAK HARBOR",
      "WA",
      "98277-4137",
      "",
      "9.63",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.79",
      "-1.54",
      "0",
      "0",
      "5.30"
    ],
    [
      "Jul 9, 2015 11:14:55 PM PDT",
      "5936487761",
      "Order",
      "115-6775029-7574646",
      "G5-IF79-U8YJ",
      "1-2-3 Magic: Effective Discipline for Children 212",
      "1",
      "amazon.com",
      "Amazon",
      "ATLANTA",
      "GA",
      "30317-2832",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Jul 10, 2015 5:28:04 AM PDT",
      "5936487761",
      "Order",
      "109-6586563-5317869",
      "0P-J0H8-BKX6",
      "Dominion: The Power of Man, the Suffering of Animals, and the Call to Mercy",
      "1",
      "amazon.com",
      "Amazon",
      "mt sterling",
      "ky",
      "40353",
      "",
      "3.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-6.90",
      "0",
      "0",
      "0.12"
    ],
    [
      "Jul 10, 2015 1:08:06 PM PDT",
      "5936487761",
      "Order",
      "113-4325837-1292263",
      "DB-UZ9O-1KSP",
      "Freedom from Fear: The American People in Depression and War, 1929-1945 (Oxford History of the United States)",
      "1",
      "amazon.com",
      "Amazon",
      "FOSTER CITY",
      "CA",
      "94404-2775",
      "",
      "11.95",
      "0",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.14",
      "-5.73",
      "0",
      "0",
      "6.07"
    ],
    [
      "Jul 10, 2015 1:09:41 PM PDT",
      "5936487761",
      "Order",
      "114-6466976-1841065",
      "NQ-4FQF-S5SC",
      "Portable Speaker with Usb/micro Sd Slot and Bluetooth Wireless Technology",
      "3",
      "amazon.com",
      "Amazon",
      "MILWAUKEE",
      "WI",
      "53223-2103",
      "",
      "101.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.16",
      "-6.01",
      "0",
      "0",
      "87.80"
    ],
    [
      "Jul 10, 2015 3:26:22 PM PDT",
      "5936487761",
      "Order",
      "106-8320059-7083453",
      "UJ-D7KB-D7PC",
      "Wedgwood Vera Wang Gilded Weave 5-Piece Place Setting",
      "1",
      "amazon.com",
      "Amazon",
      "Houston",
      "TX",
      "77046",
      "",
      "69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.50",
      "-4.80",
      "0",
      "0",
      "54.69"
    ],
    [
      "Jul 10, 2015 5:41:02 PM PDT",
      "5936487761",
      "Order",
      "113-2759197-1567411",
      "H5-IV1X-ACBO",
      "Making Ideas Happen: Overcoming the Obstacles Between Vision and Reality",
      "1",
      "amazon.com",
      "Amazon",
      "San Rafael",
      "CA",
      "94901",
      "",
      "9.28",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.74",
      "-1.67",
      "0",
      "0",
      "4.87"
    ],
    [
      "Jul 10, 2015 6:10:33 PM PDT",
      "5936487761",
      "Order",
      "113-9291534-4187444",
      "22-6UPZ-Y46N",
      "Funko POP Star Wars (Bobble): Jabba the Hutt",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CA",
      "95126-2608",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-2.67",
      "0",
      "0",
      "8.37"
    ],
    [
      "Jul 10, 2015 9:22:00 PM PDT",
      "5936487761",
      "Order",
      "108-8109524-3200221",
      "N5-P205-IIGF",
      "The Informant: A True Story",
      "1",
      "amazon.com",
      "Amazon",
      "CHULA VISTA",
      "CA",
      "91911",
      "",
      "7.99",
      "0",
      "2.14",
      "0",
      "0",
      "0",
      "-2.14",
      "0",
      "0",
      "-2.55",
      "-2.33",
      "0",
      "0",
      "3.11"
    ],
    [
      "Jul 11, 2015 4:45:00 AM PDT",
      "5936487761",
      "Order",
      "115-5066935-5720252",
      "41-HJ42-UATT",
      "The One Minute Manager Meets the Monkey",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT AUGUSTINE",
      "FL",
      "32080-5112",
      "",
      "3.49",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-1.87",
      "-1.54",
      "0",
      "0",
      "0.08"
    ],
    [
      "Jul 11, 2015 4:36:01 PM PDT",
      "5936487761",
      "Order",
      "105-8637962-1697816",
      "1002342241",
      "Gordon Ramsay China Maze Blue Oven To Tableware 7 Piece Set",
      "1",
      "amazon.com",
      "Amazon",
      "Middlesex",
      "NJ",
      "08846",
      "",
      "171.10",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-25.67",
      "-13.83",
      "0",
      "0",
      "131.60"
    ],
    [
      "Jul 11, 2015 11:26:27 PM PDT",
      "5936487761",
      "Order",
      "104-2036833-5742649",
      "9K-GLOK-CF62",
      "Happy Birthday to You!",
      "1",
      "amazon.com",
      "Amazon",
      "NORCROSS",
      "GA",
      "30071-3078",
      "",
      "6.99",
      "0",
      "1.85",
      "0",
      "0",
      "0",
      "-1.85",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Jul 12, 2015 9:37:54 AM PDT",
      "5936487761",
      "Order",
      "115-8330044-4761029",
      "B5-UZUD-269F",
      "Arthur's Pet Business (An Arthur Adventure)",
      "1",
      "amazon.com",
      "Amazon",
      "ALAMOGORDO",
      "NM",
      "88310-8021",
      "",
      "3.29",
      "0",
      "1.79",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.84",
      "-3.33",
      "0",
      "0",
      "-0.09"
    ],
    [
      "Jul 12, 2015 11:10:57 PM PDT",
      "5936487761",
      "Order",
      "112-8352007-7849002",
      "O8-WXGA-I7U3",
      "Dr. Dean Ornish's Program for Reversing Heart Disease",
      "1",
      "amazon.com",
      "Amazon",
      "NORCROSS",
      "GEORGIA",
      "30092-4300",
      "",
      "8.98",
      "0",
      "1.56",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-3.48",
      "0",
      "0",
      "4.36"
    ],
    [
      "Jul 13, 2015 1:15:56 AM PDT",
      "5936487761",
      "Order",
      "106-2253493-2649837",
      "BC-1OZ2-5E2L",
      "Wilton Armetale Reggae Chip and Dip Server, Round, 10-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "BELLPORT",
      "NY",
      "11713-2917",
      "",
      "44.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.75",
      "-7.62",
      "0",
      "0",
      "34.61"
    ],
    [
      "Jul 13, 2015 11:11:22 AM PDT",
      "5936487761",
      "Order",
      "112-7303043-5376214",
      "U5-Y1TM-6S14",
      "The Maker's Diet",
      "1",
      "amazon.com",
      "Amazon",
      "ZEBULON",
      "NC",
      "27597-2130",
      "",
      "2.75",
      "0",
      "1.37",
      "0",
      "0",
      "0",
      "-1.37",
      "0",
      "0",
      "-1.76",
      "-1.92",
      "0",
      "0",
      "-0.93"
    ],
    [
      "Jul 13, 2015 12:50:34 PM PDT",
      "5936487761",
      "Order",
      "107-3537575-9434640",
      "KA-A1E7-PU20",
      "Snakes and Ladders",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NEW YORK",
      "11234-3704",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Jul 13, 2015 3:20:10 PM PDT",
      "5936487761",
      "Order",
      "114-0376042-5058637",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "2",
      "amazon.com",
      "Amazon",
      "BRONSON",
      "FL",
      "32621-6231",
      "",
      "6.58",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "0.24"
    ],
    [
      "Jul 13, 2015 3:46:43 PM PDT",
      "5936487761",
      "Order",
      "109-7668661-3637025",
      "KA-A1E7-PU20",
      "Snakes and Ladders",
      "1",
      "amazon.com",
      "Amazon",
      "CORAL SPRINGS",
      "FLORIDA",
      "33065-6228",
      "",
      "4.99",
      "0",
      "0.62",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-3.29",
      "0",
      "0",
      "1.32"
    ],
    [
      "Jul 13, 2015 7:20:48 PM PDT",
      "5936487761",
      "Order",
      "102-8465212-0074630",
      "DB-XZ8G-1KYC",
      "Funko POP Star Wars : Biker Scout Action Figure",
      "1",
      "amazon.com",
      "Amazon",
      "Hillsboro Beach",
      "FL",
      "33062-2101",
      "",
      "11.77",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.77",
      "-2.67",
      "0",
      "0",
      "7.33"
    ],
    [
      "Jul 14, 2015 7:02:42 AM PDT",
      "5936487761",
      "Order",
      "114-2300785-8796254",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "Okolona",
      "MS",
      "38860",
      "",
      "3.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.38"
    ],
    [
      "Jul 14, 2015 3:54:39 PM PDT",
      "5936487761",
      "Order",
      "107-1579628-6719413",
      "Q0-OJJ2-B6KS",
      "Native Speaker",
      "1",
      "amazon.com",
      "Amazon",
      "Cedar Park",
      "TX",
      "78613",
      "",
      "10.31",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.90",
      "-1.67",
      "0",
      "0",
      "5.74"
    ],
    [
      "Jul 15, 2015 1:03:11 PM PDT",
      "5936487761",
      "Order",
      "109-6144852-9476251",
      "1002400356",
      "Graphic Guide to Frame Construction: Details for Builders and Designers (For Pros By Pros)",
      "1",
      "amazon.com",
      "Amazon",
      "MOORHEAD",
      "MN",
      "56560-5668",
      "",
      "16.35",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.80",
      "-1.92",
      "0",
      "0",
      "10.63"
    ],
    [
      "Jul 15, 2015 6:19:38 PM PDT",
      "5936487761",
      "Order",
      "114-4656528-3497864",
      "O1-GM01-EZWO",
      "Come on, People: On the Path from Victims to Victors",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT LOUIS",
      "MO",
      "63104-2522",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.73"
    ],
    [
      "Jul 16, 2015 2:31:40 PM PDT",
      "5936487761",
      "Order",
      "116-2988605-0837031",
      "1002368011",
      "Where Men Hide",
      "1",
      "amazon.com",
      "Amazon",
      "ALEXANDRIA",
      "VA",
      "22301-1345",
      "",
      "7.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-5.91",
      "0",
      "0",
      "3.52"
    ],
    [
      "Jul 17, 2015 4:53:30 PM PDT",
      "5936487761",
      "Order",
      "002-7783299-0284265",
      "2K-AU9I-O5PS",
      "The Situational Leader (4th Edition) (1992)",
      "1",
      "amazon.com",
      "Amazon",
      "MABANK",
      "TX",
      "75156-6816",
      "",
      "29.99",
      "0",
      "7.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-9.03",
      "0",
      "0",
      "22.60"
    ],
    [
      "Jul 17, 2015 5:38:01 PM PDT",
      "5936487761",
      "Refund",
      "106-2253493-2649837",
      "BC-1OZ2-5E2L",
      "Wilton Armetale Reggae Chip and Dip Server, Round, 10-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "BELLPORT",
      "NY",
      "11713-2917",
      "",
      "-44.99",
      "0",
      "-3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "5.40",
      "3.99",
      "0",
      "0",
      "-39.59"
    ],
    [
      "Jul 17, 2015 7:11:16 PM PDT",
      "5936487761",
      "Order",
      "114-6192074-1865057",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "BISMARCK",
      "ND",
      "58501-1631",
      "",
      "3.99",
      "0",
      "1.49",
      "0",
      "0",
      "0",
      "-1.49",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 17, 2015 7:29:26 PM PDT",
      "5936487761",
      "Order",
      "103-2196994-7347439",
      "4N-FG1I-3YJA",
      "Maleficent Maleficent: 11.5 Dark Beauty Maleficent Doll Toy, Kids, Play, Children",
      "1",
      "amazon.com",
      "Amazon",
      "MATAWAN",
      "NJ",
      "07747-3809",
      "",
      "43.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.60",
      "-2.67",
      "0",
      "0",
      "34.71"
    ],
    [
      "Jul 18, 2015 2:20:46 AM PDT",
      "5936487761",
      "Order",
      "103-4334811-4806653",
      "4C-JF55-H4NB",
      "Driving Mr. Albert: A Trip Across America with Einstein's Brain",
      "1",
      "amazon.com",
      "Amazon",
      "NEEDHAM HEIGHTS",
      "MA",
      "02494-1524",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.67",
      "0",
      "0",
      "0.37"
    ],
    [
      "Jul 18, 2015 5:37:40 PM PDT",
      "5943930681",
      "Order",
      "105-0044263-6330656",
      "1002400174",
      "Ten Steps to Building College Reading Skills",
      "1",
      "amazon.com",
      "Amazon",
      "VIENNA",
      "VA",
      "22181-5443",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-2.33",
      "0",
      "0",
      "7.36"
    ],
    [
      "Jul 20, 2015 2:24:48 AM PDT",
      "5943930681",
      "Order",
      "114-9488657-0697021",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "Encinitas",
      "CA",
      "92024",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 20, 2015 7:54:24 AM PDT",
      "5943930681",
      "Order",
      "116-0925500-5418666",
      "IF-CNQL-D9DQ",
      "Crafter's Square Glitter Glue - 10 tubes(5 colors)",
      "1",
      "amazon.com",
      "Amazon",
      "Dayton",
      "OH",
      "45405-2716",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Jul 20, 2015 2:48:34 PM PDT",
      "5943930681",
      "Order",
      "116-1487872-0969819",
      "IF-CNQL-D9DQ",
      "Crafter's Square Glitter Glue - 10 tubes(5 colors)",
      "1",
      "amazon.com",
      "Amazon",
      "MOUNTAINSIDE",
      "NJ",
      "07092-1961",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Jul 20, 2015 3:40:35 PM PDT",
      "5943930681",
      "Order",
      "105-2474713-4639431",
      "1002427913",
      "Oh, The Places You'll Go!",
      "1",
      "amazon.com",
      "Amazon",
      "VIRGINIA BEACH",
      "VA",
      "23464-1880",
      "",
      "10.79",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.97",
      "-1.54",
      "0",
      "0",
      "6.28"
    ],
    [
      "Jul 20, 2015 6:26:45 PM PDT",
      "5943930681",
      "Order",
      "002-3969000-1868211",
      "QI-OU4E-18AR",
      "Principle-Centered Leadership",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "TEXAS",
      "76018-1067",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Jul 20, 2015 11:50:45 PM PDT",
      "5943930681",
      "Order",
      "108-9357275-8177808",
      "1002436648",
      "Officer Buckle & Gloria (Caldecott Medal Book)",
      "1",
      "amazon.com",
      "Amazon",
      "FOSTER CITY",
      "CA",
      "94404-2785",
      "",
      "11.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.14",
      "-1.54",
      "0",
      "0",
      "7.25"
    ],
    [
      "Jul 21, 2015 7:25:11 PM PDT",
      "5943930681",
      "Order",
      "110-5942294-3778610",
      "1002368272",
      "MLA Handbook for Writers of Research Papers, Fifth Edition",
      "1",
      "amazon.com",
      "Amazon",
      "Westminster",
      "MD",
      "21157",
      "",
      "3.99",
      "0",
      "2.53",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-4.45",
      "0",
      "0",
      "0.12"
    ],
    [
      "Jul 22, 2015 12:48:03 AM PDT",
      "5943930681",
      "Order",
      "116-8299382-0750605",
      "1002461367",
      "Capresso 464.05 CoffeeTeam GS 10-Cup Digital Coffeemaker with Conical Burr Grinder",
      "1",
      "amazon.com",
      "Amazon",
      "DOWNEY",
      "CA",
      "90241-3331",
      "",
      "169.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-25.50",
      "-13.05",
      "0",
      "0",
      "131.44"
    ],
    [
      "Jul 22, 2015 1:10:31 AM PDT",
      "5943930681",
      "Order",
      "108-5430216-4750634",
      "1A-VSRW-L8AO",
      "Extra Innings: More Baseball Between the Numbers from the Team at Baseball Prospectus",
      "1",
      "amazon.com",
      "Amazon",
      "UNIVERSITY CITY",
      "MO",
      "63130-3230",
      "",
      "4.01",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-1.92",
      "0",
      "0",
      "0.14"
    ],
    [
      "Jul 22, 2015 1:13:44 PM PDT",
      "5943930681",
      "Order",
      "111-4797284-6505023",
      "1002451934",
      "Thumbelina",
      "1",
      "amazon.com",
      "Amazon",
      "PEORIA",
      "AZ",
      "85383-1621",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Jul 22, 2015 6:25:55 PM PDT",
      "5943930681",
      "Order",
      "104-8085736-6369002",
      "O1-MH7I-EZYO",
      "Throes of Democracy: The American Civil War Era 1829-1877",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NEW YORK",
      "10022-6030",
      "",
      "8.82",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "-1.92",
      "0",
      "0",
      "4.23"
    ],
    [
      "Jul 22, 2015 10:48:50 PM PDT",
      "5943930681",
      "Order",
      "113-6997530-6507450",
      "BM-09R4-S7OL",
      "The Bears' Vacation",
      "1",
      "amazon.com",
      "Amazon",
      "ALBUQUERQUE",
      "NM",
      "87111-2651",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 23, 2015 3:14:18 PM PDT",
      "5943930681",
      "Order",
      "103-0722021-7837802",
      "LR-Y2TL-VZB3",
      "Survival Kit for Overseas Living, 4th ed.: For Americans Planning to Live and Work Abroad",
      "1",
      "amazon.com",
      "Amazon",
      "Bourbonnais",
      "IL",
      "60914-1040",
      "",
      "7.99",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-3.86",
      "0",
      "0",
      "3.90"
    ],
    [
      "Jul 23, 2015 3:27:07 PM PDT",
      "5943930681",
      "Order",
      "103-8468100-2745807",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "San Antonio",
      "Texas",
      "78232",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.03",
      "0",
      "0",
      "15.96"
    ],
    [
      "Jul 23, 2015 3:32:42 PM PDT",
      "5943930681",
      "Order",
      "104-5907968-4837053",
      "1002419758",
      "Where the Wild Things Are",
      "1",
      "amazon.com",
      "Amazon",
      "WILMINGTON",
      "NC",
      "28409-8008",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Jul 23, 2015 7:13:35 PM PDT",
      "5943930681",
      "Order",
      "114-8039421-5459455",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10014-1405",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 23, 2015 7:14:17 PM PDT",
      "5943930681",
      "Order",
      "104-6803846-0465042",
      "1002460781",
      "Plasticolor 003724R01 Star Wars Hoth Scene Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "WEST COVINA",
      "CA",
      "91791-2376",
      "",
      "18.30",
      "0",
      "6.70",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-12.73",
      "0",
      "0",
      "10.07"
    ],
    [
      "Jul 23, 2015 11:10:48 PM PDT",
      "5943930681",
      "Order",
      "105-4457730-9738601",
      "1002460781",
      "Plasticolor 003724R01 Star Wars Hoth Scene Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "EL PASO",
      "TEXAS",
      "79938-2704",
      "",
      "18.30",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-6.03",
      "0",
      "0",
      "10.07"
    ],
    [
      "Jul 23, 2015 11:14:33 PM PDT",
      "5943930681",
      "Order",
      "106-9164357-4142626",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "SHAFTER",
      "CA",
      "93263-9735",
      "",
      "18.30",
      "0",
      "11.01",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-17.04",
      "0",
      "0",
      "10.07"
    ],
    [
      "Jul 23, 2015 11:18:50 PM PDT",
      "5943930681",
      "Order",
      "110-7994238-1696226",
      "1002460781",
      "Plasticolor 003724R01 Star Wars Hoth Scene Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "Prineville",
      "OR",
      "97754",
      "",
      "18.32",
      "0",
      "4.36",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-10.39",
      "0",
      "0",
      "10.09"
    ],
    [
      "Jul 23, 2015 11:19:25 PM PDT",
      "5943930681",
      "Order",
      "110-3345201-9302620",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "GRANTS PASS",
      "OR",
      "97527-4261",
      "",
      "18.30",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-6.03",
      "0",
      "0",
      "10.07"
    ],
    [
      "Jul 24, 2015 2:34:34 AM PDT",
      "5943930681",
      "Order",
      "111-3061474-8600261",
      "1002451862",
      "The Velveteen Rabbit",
      "1",
      "amazon.com",
      "Amazon",
      "JUPITER",
      "FLORIDA",
      "33458-6630",
      "",
      "8.71",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.66",
      "-1.54",
      "0",
      "0",
      "4.51"
    ],
    [
      "Jul 24, 2015 2:36:08 AM PDT",
      "5943930681",
      "Order",
      "108-5877333-4993843",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "Gibsonville",
      "NC",
      "27249",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "1.64"
    ],
    [
      "Jul 24, 2015 12:34:36 PM PDT",
      "5943930681",
      "Order",
      "106-9556975-4256248",
      "1002427878",
      "When Pigasso Met Mootisse",
      "1",
      "amazon.com",
      "Amazon",
      "AVONDALE",
      "AZ",
      "85392-6618",
      "",
      "10.99",
      "0",
      "1.33",
      "0",
      "0",
      "0",
      "-1.33",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Jul 24, 2015 1:17:16 PM PDT",
      "5943930681",
      "Order",
      "111-0927074-9627410",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "AMHERST",
      "NEW YORK",
      "14228-2716",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.03",
      "0",
      "0",
      "15.96"
    ],
    [
      "Jul 24, 2015 3:17:57 PM PDT",
      "5943930681",
      "Order",
      "113-0589046-9772258",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "MARIETTA",
      "GA",
      "30066-4798",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "1.64"
    ],
    [
      "Jul 24, 2015 5:47:57 PM PDT",
      "5943930681",
      "Order",
      "115-6570924-0022653",
      "1002360618",
      "Swiss Grill SGSB Stainless Steel Smoker Box",
      "1",
      "amazon.com",
      "Amazon",
      "San Jose",
      "CA",
      "95120",
      "",
      "16.19",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.43",
      "-2.67",
      "0",
      "0",
      "11.09"
    ],
    [
      "Jul 24, 2015 6:01:52 PM PDT",
      "5943930681",
      "Refund",
      "107-3537575-9434640",
      "KA-A1E7-PU20",
      "Snakes and Ladders",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NEW YORK",
      "11234-3704",
      "",
      "-4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.80",
      "0",
      "0",
      "0",
      "-4.19"
    ],
    [
      "Jul 24, 2015 6:02:21 PM PDT",
      "5943930681",
      "Order",
      "107-3859760-6962642",
      "1002367903",
      "Where Men Hide",
      "1",
      "amazon.com",
      "Amazon",
      "LEHI",
      "UT",
      "84043-8002",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Jul 26, 2015 6:15:35 PM PDT",
      "5943930681",
      "Order",
      "113-9948605-7962635",
      "1002427899",
      "Ripley's Believe It Or Not! Planet Eccentric",
      "1",
      "amazon.com",
      "Amazon",
      "Yonkers",
      "NY",
      "10710-3140",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-2.74",
      "0",
      "0",
      "4.40"
    ],
    [
      "Jul 26, 2015 11:35:28 PM PDT",
      "5943930681",
      "Order",
      "116-0709744-7759440",
      "1002486110",
      "The Giver (Giver Quartet)",
      "1",
      "amazon.com",
      "Amazon",
      "Menlo Park",
      "CA",
      "94025",
      "",
      "7.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.47",
      "-1.54",
      "0",
      "0",
      "3.48"
    ],
    [
      "Jul 27, 2015 2:23:49 AM PDT",
      "5943930681",
      "Order",
      "109-3351936-2435434",
      "1002428577",
      "Pirates",
      "1",
      "amazon.com",
      "Amazon",
      "NEWMARKET",
      "NH",
      "03857-1851",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-2.33",
      "0",
      "0",
      "13.31"
    ],
    [
      "Jul 27, 2015 5:56:33 AM PDT",
      "5943930681",
      "Order",
      "116-4210734-7016242",
      "1002461374",
      "Super Gulliver Tomato Strainer with Suction Base",
      "1",
      "amazon.com",
      "Amazon",
      "MARION",
      "KY",
      "42064-5924",
      "",
      "69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.50",
      "-7.59",
      "0",
      "0",
      "51.90"
    ],
    [
      "Jul 27, 2015 11:56:30 AM PDT",
      "5943930681",
      "Order",
      "105-5376891-4864226",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "ROSLINDALE",
      "MASSACHUSETTS",
      "02131-1615",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.03",
      "0",
      "0",
      "15.96"
    ],
    [
      "Jul 27, 2015 11:17:32 PM PDT",
      "5943930681",
      "Order",
      "111-4625971-9792240",
      "1002451688",
      "World War II for Kids: A History with 21 Activities (For Kids series)",
      "1",
      "amazon.com",
      "Amazon",
      "CUTLER",
      "ME",
      "04626-3120",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Jul 27, 2015 11:36:43 PM PDT",
      "5943930681",
      "Order",
      "102-0422081-1464224",
      "1002451854",
      "The Tale of Peter Rabbit (Peter Rabbit Naturally Better)",
      "1",
      "amazon.com",
      "Amazon",
      "College Station",
      "TX",
      "77845",
      "",
      "6.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.04"
    ],
    [
      "Jul 28, 2015 1:04:19 AM PDT",
      "5943930681",
      "Order",
      "112-6621703-6290620",
      "WI-AHEY-9EJA",
      "Sleepypod Atom - Strawberry Red - In Cabin Airline Approved pet carrier",
      "1",
      "amazon.com",
      "Amazon",
      "West Palm Beach",
      "FL",
      "33401",
      "",
      "79.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-12.00",
      "-4.41",
      "0",
      "0",
      "63.58"
    ],
    [
      "Jul 28, 2015 2:17:52 AM PDT",
      "5943930681",
      "Order",
      "113-3097381-8821842",
      "1002428992",
      "Yoko",
      "1",
      "amazon.com",
      "Amazon",
      "SARASOTA",
      "FL",
      "34238-2602",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-1.54",
      "0",
      "0",
      "18.35"
    ],
    [
      "Jul 28, 2015 12:17:56 PM PDT",
      "5943930681",
      "Order",
      "112-5914858-5753057",
      "1002486261",
      "Seeker of Knowledge: The Man Who Deciphered Egyptian Hieroglyphs",
      "1",
      "amazon.com",
      "Amazon",
      "JACKSONVILLE",
      "FL",
      "32257-5059",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 28, 2015 1:16:20 PM PDT",
      "5943930681",
      "Order",
      "103-7805666-7198651",
      "1002470062",
      "The Cook's Illustrated Guide To Grilling And Barbecue",
      "1",
      "amazon.com",
      "Amazon",
      "YONKERS",
      "NEW YORK",
      "10703-2414",
      "",
      "10.69",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.95",
      "-2.33",
      "0",
      "0",
      "5.41"
    ],
    [
      "Jul 28, 2015 3:16:23 PM PDT",
      "5943930681",
      "Order",
      "002-2617963-6480239",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "THOMSON",
      "GA",
      "30824",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 28, 2015 3:44:47 PM PDT",
      "5943930681",
      "Order",
      "110-8910598-6710662",
      "1002477460",
      "Lauren Ralph Lauren \"Club Porcelain\" Dinner Plates - Set of 4 - White",
      "1",
      "amazon.com",
      "Amazon",
      "SOUTHAMPTON",
      "NEW YORK",
      "11968-4868",
      "",
      "38.99",
      "0",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-11.70",
      "-20.95",
      "0",
      "0",
      "14.33"
    ],
    [
      "Jul 28, 2015 3:44:47 PM PDT",
      "5943930681",
      "Order",
      "110-8910598-6710662",
      "1002477460",
      "Lauren Ralph Lauren \"Club Porcelain\" Dinner Plates - Set of 4 - White",
      "1",
      "amazon.com",
      "Amazon",
      "SOUTHAMPTON",
      "NEW YORK",
      "11968-4868",
      "",
      "38.99",
      "0",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.97",
      "0",
      "0",
      "41.01"
    ],
    [
      "Jul 28, 2015 5:29:44 PM PDT",
      "5943930681",
      "Order",
      "116-2782426-7653851",
      "1002360658",
      "Nambé Titan Bottle Opener",
      "1",
      "amazon.com",
      "Amazon",
      "San Jose",
      "CA",
      "95119",
      "",
      "25.85",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.88",
      "-3.63",
      "0",
      "0",
      "18.34"
    ],
    [
      "Jul 28, 2015 6:19:37 PM PDT",
      "5943930681",
      "Order",
      "107-0114259-1066679",
      "1002419777",
      "What Do You Do With a Tail Like This? (Caldecott Honor Book)",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60630-4314",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Jul 28, 2015 6:56:33 PM PDT",
      "5943930681",
      "Order",
      "107-4264417-7577051",
      "1002461312",
      "Oneida Capello 20 Piece Service for 4",
      "1",
      "amazon.com",
      "Amazon",
      "GREAT FALLS",
      "MT",
      "59404",
      "",
      "69.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.49",
      "-4.41",
      "0",
      "0",
      "55.05"
    ],
    [
      "Jul 28, 2015 7:14:41 PM PDT",
      "5943930681",
      "Order",
      "108-4187059-1673839",
      "1002486085",
      "The Gashlycrumb Tinies",
      "1",
      "amazon.com",
      "Amazon",
      "SUFFOLK",
      "VA",
      "23434-4951",
      "",
      "5.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.24",
      "-1.54",
      "0",
      "0",
      "2.16"
    ],
    [
      "Jul 28, 2015 7:43:19 PM PDT",
      "5943930681",
      "Order",
      "110-3221682-3865860",
      "1002491102",
      "In a People House (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "APPLETON",
      "WI",
      "54914-3827",
      "",
      "6.59",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.34",
      "-1.54",
      "0",
      "0",
      "2.71"
    ],
    [
      "Jul 29, 2015 12:56:36 AM PDT",
      "5943930681",
      "Order",
      "105-7671682-1985830",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "3",
      "amazon.com",
      "Amazon",
      "Englewood",
      "Colorado",
      "80112",
      "",
      "11.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.01",
      "0",
      "0",
      "2.96"
    ],
    [
      "Jul 29, 2015 3:18:23 AM PDT",
      "5943930681",
      "Order",
      "105-2247740-4070641",
      "1002477435",
      "Marquis by Waterford Vintage Stainless Steel Shaker",
      "1",
      "amazon.com",
      "Amazon",
      "DENVER",
      "CO",
      "80202-2304",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-6.16",
      "0",
      "0",
      "21.12"
    ],
    [
      "Jul 29, 2015 12:20:41 PM PDT",
      "5943930681",
      "Order",
      "111-0376145-7319429",
      "1002451565",
      "Babar the King (Babar Books (Random House))",
      "1",
      "amazon.com",
      "Amazon",
      "Bronx",
      "NY",
      "10461",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Jul 29, 2015 2:19:29 PM PDT",
      "5943930681",
      "Order",
      "114-7226268-0781022",
      "1002491351",
      "The Early Bird",
      "1",
      "amazon.com",
      "Amazon",
      "Seaside",
      "CA",
      "93955",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Jul 29, 2015 4:39:16 PM PDT",
      "5943930681",
      "Order",
      "106-2653581-8441042",
      "1002486184",
      "Mr. Lincoln's Whiskers",
      "1",
      "amazon.com",
      "Amazon",
      "Longmont",
      "CO",
      "80501-6553",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 29, 2015 6:51:26 PM PDT",
      "5943930681",
      "Order",
      "106-3150223-2360228",
      "D0-4BWW-ER22",
      "Where's My Mummy?",
      "1",
      "amazon.com",
      "Amazon",
      "SALEM",
      "IL",
      "62881-5604",
      "",
      "0.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-1.54",
      "0",
      "0",
      "-2.05"
    ],
    [
      "Jul 29, 2015 11:22:21 PM PDT",
      "5943930681",
      "Order",
      "102-0634992-1678658",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "Portland",
      "OR",
      "97219",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 29, 2015 11:30:54 PM PDT",
      "5943930681",
      "Order",
      "106-1841931-7057027",
      "1002491060",
      "A Fish Out of Water (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "WARWICK",
      "RHODE ISLAND",
      "02886-3261",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Jul 29, 2015 11:32:01 PM PDT",
      "5943930681",
      "Order",
      "106-6829544-6058628",
      "1002486264",
      "Amelia to Zora: Twenty-Six Women Who Changed the World",
      "1",
      "amazon.com",
      "Amazon",
      "STUDIO CITY",
      "CA",
      "91604-1505",
      "",
      "6.25",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.42"
    ],
    [
      "Jul 30, 2015 9:25:33 AM PDT",
      "5943930681",
      "Order",
      "105-7026187-4479402",
      "1002427883",
      "Great Day for Up (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "TEXARKANA",
      "TEXAS",
      "75503-2733",
      "",
      "8.29",
      "0",
      "1.15",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-2.69",
      "0",
      "0",
      "4.16"
    ],
    [
      "Jul 30, 2015 10:15:02 AM PDT",
      "5943930681",
      "Order",
      "108-9626685-6663405",
      "L3-D22R-2PLE",
      "Marvin K. Mooney Will You Please Go Now!  (Bright and Early Books for Beginning Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "DPO",
      "AE",
      "09823-0176",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Jul 30, 2015 12:41:32 PM PDT",
      "5943930681",
      "Order",
      "116-9775269-6941037",
      "1002469507",
      "Look and Find: Minnie Mouse",
      "1",
      "amazon.com",
      "Amazon",
      "WORTHINGTON",
      "OH",
      "43085-3091",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Jul 30, 2015 1:37:13 PM PDT",
      "5943930681",
      "Order",
      "102-4003872-4234637",
      "IF-CNQL-D9DQ",
      "Crafter's Square Glitter Glue - 10 tubes(5 colors)",
      "1",
      "amazon.com",
      "Amazon",
      "PARRISH",
      "FLORIDA",
      "34219-8608",
      "",
      "3.99",
      "0",
      "3.33",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-5.87",
      "0",
      "0",
      "0.45"
    ],
    [
      "Jul 30, 2015 2:32:37 PM PDT",
      "5943930681",
      "Order",
      "002-8479486-5533017",
      "MD-TKPL-5MYO",
      "Go Put Your Strengths to Work: 6 Powerful Steps to Achieve Outstanding Performance",
      "1",
      "amazon.com",
      "Amazon",
      "MILPITAS",
      "CA",
      "95035-2434",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Jul 30, 2015 3:21:19 PM PDT",
      "5943930681",
      "Order",
      "112-3152094-6657059",
      "1002491305",
      "The Digging-Est Dog (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "JOHNSTON",
      "RI",
      "02919-5059",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Jul 30, 2015 3:41:10 PM PDT",
      "5943930681",
      "Order",
      "116-8788217-4319468",
      "1002380438",
      "English, Yes! Transitional: Learning English Through Literature",
      "1",
      "amazon.com",
      "Amazon",
      "Arlington",
      "VA",
      "22207",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.37"
    ],
    [
      "Jul 30, 2015 7:16:52 PM PDT",
      "5943930681",
      "Order",
      "111-2415994-5408216",
      "1002490990",
      "Robert the Rose Horse",
      "1",
      "amazon.com",
      "Amazon",
      "THURMONT",
      "MD",
      "21788-1820",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Jul 30, 2015 7:19:56 PM PDT",
      "5943930681",
      "Order",
      "109-2258849-7208252",
      "1002491169",
      "Belly Button Book (Boynton on Board)",
      "1",
      "amazon.com",
      "Amazon",
      "PERRY HALL",
      "MD",
      "21128-9508",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Jul 31, 2015 8:13:54 AM PDT",
      "5943930681",
      "Order",
      "106-1710406-3781007",
      "1002436807",
      "Big Plans",
      "1",
      "amazon.com",
      "Amazon",
      "Smithville",
      "TN",
      "37166",
      "",
      "69.99",
      "0",
      "2.86",
      "0",
      "0",
      "0",
      "-2.86",
      "0",
      "0",
      "-11.85",
      "-1.92",
      "0",
      "0",
      "56.22"
    ],
    [
      "Jul 31, 2015 10:15:18 AM PDT",
      "5943930681",
      "Order",
      "112-3361573-9905816",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "EWA BEACH",
      "HI",
      "96706-6192",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Jul 31, 2015 3:16:19 PM PDT",
      "5943930681",
      "Order",
      "110-2431896-9157012",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "6",
      "amazon.com",
      "Amazon",
      "BRIGHTWATERS",
      "NY",
      "11718-1901",
      "",
      "23.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.00",
      "-11.02",
      "0",
      "0",
      "6.92"
    ],
    [
      "Jul 31, 2015 6:44:42 PM PDT",
      "5943930681",
      "Order",
      "107-8339173-1365812",
      "IF-CNQL-D9DQ",
      "Crafter's Square Glitter Glue - 10 tubes(5 colors)",
      "1",
      "amazon.com",
      "Amazon",
      "WILLOW PARK",
      "TX",
      "76087-7724",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Jul 31, 2015 7:08:43 PM PDT",
      "5943930681",
      "Order",
      "108-7295534-7625066",
      "1002486190",
      "Lifetimes: The Beautiful Way to Explain Death to Children",
      "1",
      "amazon.com",
      "Amazon",
      "Columbia",
      "Maryland",
      "21046",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Jul 31, 2015 7:24:32 PM PDT",
      "5943930681",
      "Order",
      "106-3587978-4534661",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "3",
      "amazon.com",
      "Amazon",
      "Peoria",
      "IL",
      "61604",
      "",
      "11.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.01",
      "0",
      "0",
      "2.96"
    ],
    [
      "Jul 31, 2015 11:11:32 PM PDT",
      "5943930681",
      "Order",
      "116-5376350-1302615",
      "CM-W7I0-8B04",
      "The Last Minute (The Sam Capra series)",
      "1",
      "amazon.com",
      "Amazon",
      "Bowie",
      "MD",
      "20720-3375",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Jul 31, 2015 11:25:15 PM PDT",
      "5943930681",
      "Order",
      "105-3848351-9858601",
      "1002486221",
      "My Buddy (An Owlet Book)",
      "1",
      "amazon.com",
      "Amazon",
      "CARDIFF BY THE SEA",
      "CA",
      "92007-1840",
      "",
      "6.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-3.53",
      "0",
      "0",
      "3.05"
    ],
    [
      "Jul 31, 2015 11:29:22 PM PDT",
      "5943930681",
      "Order",
      "102-9537024-8023454",
      "1002486210",
      "The Emperor's Egg: Read and Wonder",
      "1",
      "amazon.com",
      "Amazon",
      "DOVER",
      "NH",
      "03820-4227",
      "",
      "6.10",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.27",
      "-1.54",
      "0",
      "0",
      "2.29"
    ],
    [
      "Aug 1, 2015 3:50:34 PM PDT",
      "5943930681",
      "Order",
      "107-2786624-5310607",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "3",
      "amazon.com",
      "Amazon",
      "COLORADO SPRINGS",
      "CO",
      "80903-2651",
      "",
      "11.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.00",
      "-5.01",
      "0",
      "0",
      "2.96"
    ],
    [
      "Aug 1, 2015 3:50:34 PM PDT",
      "5943930681",
      "Order",
      "107-2786624-5310607",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "COLORADO SPRINGS",
      "CO",
      "80903-2651",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 2, 2015 2:23:10 AM PDT",
      "5951020731",
      "Order",
      "002-7429289-1997844",
      "1002554620",
      "Dance, Sing, Remember: A Celebration of Jewish Holidays",
      "1",
      "amazon.com",
      "Amazon",
      "WINSTON SALEM",
      "NC",
      "27103-3545",
      "",
      "12.97",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-5.41",
      "0",
      "0",
      "7.75"
    ],
    [
      "Aug 2, 2015 2:28:14 AM PDT",
      "5951020731",
      "Order",
      "110-8512242-2248257",
      "1002554713",
      "The Butter Battle Book: (New York Times Notable Book of the Year) (Classic Seuss)",
      "1",
      "amazon.com",
      "Amazon",
      "OVIEDO",
      "FL",
      "32765-7487",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Aug 2, 2015 11:03:02 AM PDT",
      "5951020731",
      "Order",
      "103-2571057-0730651",
      "1002451867",
      "Little Red Riding Hood",
      "1",
      "amazon.com",
      "Amazon",
      "SPRINGFIELD",
      "IL",
      "62704-2678",
      "",
      "7.23",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.43",
      "-1.54",
      "0",
      "0",
      "3.26"
    ],
    [
      "Aug 2, 2015 11:05:08 AM PDT",
      "5951020731",
      "Order",
      "115-8372774-1653866",
      "TU-0BJJ-JYB3",
      "HDX 5 Piece Plier Set",
      "1",
      "amazon.com",
      "Amazon",
      "AKRON",
      "OHIO",
      "44306-3856",
      "",
      "12.99",
      "0",
      "3.01",
      "0",
      "0",
      "0",
      "-3.01",
      "0",
      "0",
      "-1.95",
      "-4.02",
      "0",
      "0",
      "7.02"
    ],
    [
      "Aug 2, 2015 12:28:52 PM PDT",
      "5951020731",
      "Adjustment",
      "",
      "QI-NUVK-181X",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.32",
      "0.32"
    ],
    [
      "Aug 2, 2015 1:37:59 PM PDT",
      "5951020731",
      "Order",
      "106-8672595-8921037",
      "8A-NIHX-9I1Y",
      "When to Speak Up and When To Shut Up",
      "1",
      "amazon.com",
      "Amazon",
      "BEAUMONT",
      "TX",
      "77705-4502",
      "",
      "4.99",
      "0",
      "1.39",
      "0",
      "0",
      "0",
      "-1.39",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 2, 2015 2:37:45 PM PDT",
      "5951020731",
      "Order",
      "103-2703220-8487413",
      "1002537000",
      "Common Core Practice Writing to Texts Grade 2",
      "1",
      "amazon.com",
      "Amazon",
      "Claremont",
      "CA",
      "91711",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 2, 2015 8:21:35 PM PDT",
      "5951020731",
      "Order",
      "104-6659940-9745007",
      "1002380433",
      "Hrafnkel's Saga and Other Icelandic Stories (Penguin Classics)",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CALIFORNIA",
      "90024-5490",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Aug 3, 2015 1:43:38 AM PDT",
      "5951020731",
      "Order",
      "114-7333331-8495447",
      "0L-SLJ7-RYZD",
      "Scented Bath Salt, 17.6-oz. Jars (Lavender Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "Beaverton",
      "OR",
      "97005-2503",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-3.63",
      "0",
      "0",
      "4.86"
    ],
    [
      "Aug 3, 2015 2:09:26 AM PDT",
      "5951020731",
      "Order",
      "103-6371082-4884201",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "CARY",
      "NC",
      "27518-8663",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "1.64"
    ],
    [
      "Aug 3, 2015 2:10:13 AM PDT",
      "5951020731",
      "Order",
      "113-6766055-0912253",
      "1002428524",
      "Walter the Farting Dog",
      "1",
      "amazon.com",
      "Amazon",
      "Germantown",
      "TN",
      "38138",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 3, 2015 1:14:37 PM PDT",
      "5951020731",
      "Order",
      "107-8042184-0715442",
      "1002490968",
      "Put Me in the Zoo  (I can read it all by myself' Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11234-6806",
      "",
      "6.49",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-5.53",
      "0",
      "0",
      "2.63"
    ],
    [
      "Aug 3, 2015 1:14:55 PM PDT",
      "5951020731",
      "Order",
      "116-4591814-6570649",
      "HF-45OJ-X650",
      "Dragonfly Porcelain Sushi Plate Sauce Dish Set #BH79-N",
      "2",
      "amazon.com",
      "Amazon",
      "CARY",
      "IL",
      "60013-3213",
      "",
      "55.58",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.34",
      "-7.04",
      "0",
      "0",
      "40.20"
    ],
    [
      "Aug 3, 2015 5:04:43 PM PDT",
      "5951020731",
      "Order",
      "105-4723342-2100235",
      "OT-5UUJ-RVCC",
      "Good News About Sex and Marriage: Answers to Your Honest Questions About Catholic Teaching",
      "1",
      "amazon.com",
      "Amazon",
      "PINEVILLE",
      "LA",
      "71360-7758",
      "",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.54",
      "0",
      "0",
      "-0.35"
    ],
    [
      "Aug 3, 2015 5:39:58 PM PDT",
      "5951020731",
      "Order",
      "107-0553632-6437864",
      "1A-FIFO-L8J9",
      "Operating Instructions: A Journal of My Son's First Year",
      "1",
      "amazon.com",
      "Amazon",
      "SKOKIE",
      "IL",
      "60077-1021",
      "",
      "11.23",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.03",
      "-1.67",
      "0",
      "0",
      "6.53"
    ],
    [
      "Aug 3, 2015 7:17:12 PM PDT",
      "5951020731",
      "Order",
      "104-0327407-0923452",
      "1002555590",
      "NICHOLAS CRICKET",
      "1",
      "amazon.com",
      "Amazon",
      "FALLS CHURCH",
      "VA",
      "22044-1805",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 3, 2015 7:19:12 PM PDT",
      "5951020731",
      "Order",
      "116-5173116-8892204",
      "1002491133",
      "How Do Dinosaurs Say Happy Birthday?",
      "1",
      "amazon.com",
      "Amazon",
      "BELLE CENTER",
      "OH",
      "43310-9643",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Aug 4, 2015 2:28:26 AM PDT",
      "5951020731",
      "Order",
      "107-2770564-1327450",
      "1002487656",
      "Legends of Valor (The Enchanted World Series)",
      "1",
      "amazon.com",
      "Amazon",
      "LINCOLN",
      "NE",
      "68521-5803",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Aug 4, 2015 4:24:18 AM PDT",
      "5951020731",
      "Order",
      "104-2233838-7041855",
      "1002554824",
      "The Seed & the Giant Saguaro",
      "1",
      "amazon.com",
      "Amazon",
      "HANAHAN",
      "SC",
      "29410-8618",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Aug 4, 2015 4:47:45 AM PDT",
      "5951020731",
      "Order",
      "116-8183205-0029009",
      "1002517053",
      "Wacky Wednesday (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "MOKENA",
      "IL",
      "60448-1405",
      "",
      "7.99",
      "0",
      "1.25",
      "0",
      "0",
      "0",
      "-1.25",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 4, 2015 1:22:13 PM PDT",
      "5951020731",
      "Order",
      "116-8779003-0124260",
      "1002469762",
      "Leapfrog Tag Activity Storybook Go Diego Go!: Underwater Mystery",
      "1",
      "amazon.com",
      "Amazon",
      "VAIL",
      "COLORADO",
      "81657-3713",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-2.54",
      "0",
      "0",
      "9.35"
    ],
    [
      "Aug 4, 2015 3:12:22 PM PDT",
      "5951020731",
      "Order",
      "112-8759891-5279438",
      "1002419780",
      "Alexander and the Terrible, Horrible, No Good, Very Bad Day",
      "1",
      "amazon.com",
      "Amazon",
      "JOURDANTON",
      "TX",
      "78026-4028",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Aug 4, 2015 3:23:54 PM PDT",
      "5951020731",
      "Order",
      "109-3514840-3665802",
      "1002543283",
      "I Survived:  The Shark Attacks of 1916",
      "1",
      "amazon.com",
      "Amazon",
      "BULVERDE",
      "TX",
      "78163-6227",
      "",
      "4.98",
      "0",
      "3.11",
      "0",
      "0",
      "0",
      "-3.11",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.34"
    ],
    [
      "Aug 4, 2015 7:12:19 PM PDT",
      "5951020731",
      "Order",
      "108-2833195-5977060",
      "1002469515",
      "Today I Feel Silly: And Other Moods That Make My Day",
      "1",
      "amazon.com",
      "Amazon",
      "MIami",
      "FL",
      "33133",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 4, 2015 7:17:22 PM PDT",
      "5951020731",
      "Order",
      "112-9496169-9298614",
      "1002491035",
      "The Tooth Book (Bright & Early Board Books(TM))",
      "1",
      "amazon.com",
      "Amazon",
      "Knightstown",
      "IN",
      "46148",
      "",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-1.54",
      "0",
      "0",
      "-0.76"
    ],
    [
      "Aug 4, 2015 7:17:22 PM PDT",
      "5951020731",
      "Order",
      "112-9496169-9298614",
      "1002491035",
      "The Tooth Book (Bright & Early Board Books(TM))",
      "1",
      "amazon.com",
      "Amazon",
      "Knightstown",
      "IN",
      "46148",
      "",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.54",
      "0",
      "0",
      "3.44"
    ],
    [
      "Aug 4, 2015 8:25:44 PM PDT",
      "5951020731",
      "Order",
      "112-0954604-7516203",
      "4Q-0FR6-NKBJ",
      "Wilton Armetale Belle Mont 12-1/2 inch Egg Tray",
      "1",
      "amazon.com",
      "Amazon",
      "Breaux Bridge",
      "LA",
      "70517",
      "",
      "47.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.05",
      "-4.41",
      "0",
      "0",
      "35.54"
    ],
    [
      "Aug 4, 2015 9:41:37 PM PDT",
      "5951020731",
      "Refund",
      "115-6570924-0022653",
      "1002360618",
      "Swiss Grill SGSB Stainless Steel Smoker Box",
      "1",
      "amazon.com",
      "Amazon",
      "San Jose",
      "CA",
      "95120",
      "",
      "-16.19",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.94",
      "0",
      "0",
      "0",
      "-14.25"
    ],
    [
      "Aug 4, 2015 11:19:57 PM PDT",
      "5951020731",
      "Order",
      "104-8789287-2120211",
      "1002575187",
      "One Came Home",
      "1",
      "amazon.com",
      "Amazon",
      "Riverton",
      "UT",
      "84096",
      "",
      "6.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.04"
    ],
    [
      "Aug 5, 2015 1:15:45 AM PDT",
      "5951020731",
      "Order",
      "115-5044764-0384227",
      "1002477426",
      "Chef'n Veggie Chop Hand-Powered Food Chopper, Arugula",
      "1",
      "amazon.com",
      "Amazon",
      "Eighty Four",
      "Pa",
      "15330",
      "",
      "18.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-3.63",
      "0",
      "0",
      "12.51"
    ],
    [
      "Aug 5, 2015 2:21:19 AM PDT",
      "5951020731",
      "Order",
      "103-4545965-4998611",
      "1002554704",
      "The Sneetches and Other Stories",
      "1",
      "amazon.com",
      "Amazon",
      "LITHIA",
      "FL",
      "33547-1700",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Aug 5, 2015 2:26:27 AM PDT",
      "5951020731",
      "Order",
      "102-8876390-7240235",
      "1002564707",
      "Hip Cat",
      "1",
      "amazon.com",
      "Amazon",
      "Spring Grove",
      "PA",
      "17362-7560",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Aug 5, 2015 2:30:05 AM PDT",
      "5951020731",
      "Order",
      "111-1212241-7245033",
      "1002427883",
      "Great Day for Up (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "Sebring",
      "FL",
      "33872",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 5, 2015 3:28:32 AM PDT",
      "5951020731",
      "Order",
      "107-2345505-6390643",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "GREEN RIVER",
      "WY",
      "82935-5957",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Aug 5, 2015 9:19:39 AM PDT",
      "5951020731",
      "Order",
      "110-4600459-4641868",
      "1002555801",
      "Sunflower House",
      "1",
      "amazon.com",
      "Amazon",
      "Tipton",
      "MI",
      "49287",
      "",
      "6.99",
      "0",
      "1.49",
      "0",
      "0",
      "0",
      "-1.49",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Aug 5, 2015 11:11:40 AM PDT",
      "5951020731",
      "Order",
      "002-1111732-6280218",
      "1002486072",
      "Warriors Super Edition: Yellowfang's Secret",
      "1",
      "amazon.com",
      "Amazon",
      "SYRACUSE",
      "IN",
      "46567-9063",
      "",
      "7.29",
      "0",
      "1.29",
      "0",
      "0",
      "0",
      "-1.29",
      "0",
      "0",
      "-2.44",
      "-1.67",
      "0",
      "0",
      "3.18"
    ],
    [
      "Aug 5, 2015 11:12:35 AM PDT",
      "5951020731",
      "Order",
      "103-1978925-6769836",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "WILMINGTON",
      "DE",
      "19803-3719",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "3.64"
    ],
    [
      "Aug 5, 2015 5:54:10 PM PDT",
      "5951020731",
      "Order",
      "114-1984454-0505804",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "DOWNINGTOWN",
      "PA",
      "19335-3166",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Aug 5, 2015 7:06:10 PM PDT",
      "5951020731",
      "Order",
      "115-7831197-8956249",
      "1002490968",
      "Put Me in the Zoo  (I can read it all by myself' Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Alpine",
      "Utah",
      "84004",
      "",
      "6.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-1.54",
      "0",
      "0",
      "2.63"
    ],
    [
      "Aug 5, 2015 7:11:16 PM PDT",
      "5951020731",
      "Order",
      "102-2748763-4522653",
      "L3-D22R-2PLE",
      "Marvin K. Mooney Will You Please Go Now!  (Bright and Early Books for Beginning Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20016-3065",
      "",
      "8.29",
      "0",
      "1.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-3.52",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 5, 2015 7:26:12 PM PDT",
      "5951020731",
      "Order",
      "106-1002855-3905020",
      "1002555864",
      "Even More Parts",
      "1",
      "amazon.com",
      "Amazon",
      "trail city",
      "sd",
      "57657",
      "",
      "6.29",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Aug 5, 2015 8:42:23 PM PDT",
      "5951020731",
      "Order",
      "114-7605312-0213065",
      "TY-VKTW-3JU9",
      "6\" Premium Glowstick Necklace with Rope",
      "1",
      "amazon.com",
      "Amazon",
      "SAND CITY",
      "CA",
      "93955-3026",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.05",
      "-2.54",
      "0",
      "0",
      "3.40"
    ],
    [
      "Aug 5, 2015 9:35:13 PM PDT",
      "5951020731",
      "Order",
      "002-6182942-3904210",
      "1002451188",
      "The Silver Slippers (Magic Charm Book)",
      "1",
      "amazon.com",
      "Amazon",
      "Concord",
      "MA",
      "01742",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 5, 2015 11:14:11 PM PDT",
      "5951020731",
      "Order",
      "116-1258109-5633800",
      "1002543362",
      "There's A Boy in the Girls' Bathroom",
      "1",
      "amazon.com",
      "Amazon",
      "TUCSON",
      "AZ",
      "85747-6023",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.67",
      "0",
      "0",
      "2.33"
    ],
    [
      "Aug 6, 2015 12:39:31 PM PDT",
      "5951020731",
      "Order",
      "116-4887490-0357849",
      "KA-A1E7-PU20",
      "Snakes and Ladders",
      "1",
      "amazon.com",
      "Amazon",
      "ELMHURST",
      "NEW YORK",
      "11373-2264",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 6, 2015 3:23:47 PM PDT",
      "5951020731",
      "Order",
      "115-4873993-5027404",
      "1002491112",
      "I Want to Be Somebody New! (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "Grand Blanc",
      "Michigan",
      "48439",
      "",
      "6.99",
      "0",
      "2.10",
      "0",
      "0",
      "0",
      "-2.10",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Aug 6, 2015 3:37:04 PM PDT",
      "5951020731",
      "Order",
      "103-6081463-6919402",
      "W0-USX7-JCWK",
      "Revell Star Wars Snowspeeder Snaptite Model Kit",
      "1",
      "amazon.com",
      "Amazon",
      "PRAIRIEVILLE",
      "LA",
      "70769-3735",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-2.67",
      "0",
      "0",
      "14.32"
    ],
    [
      "Aug 6, 2015 7:02:00 PM PDT",
      "5951020731",
      "Order",
      "105-6841097-3193868",
      "1002584273",
      "Maze: Solve the World's Most Challenging Puzzle",
      "1",
      "amazon.com",
      "Amazon",
      "WAUKESHA",
      "WI",
      "53186-2011",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 6, 2015 11:30:45 PM PDT",
      "5951020731",
      "Order",
      "002-9165175-8879430",
      "1002584338",
      "I Like Myself!",
      "1",
      "amazon.com",
      "Amazon",
      "Fresno",
      "CA",
      "93710",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Aug 7, 2015 12:06:53 AM PDT",
      "5951020731",
      "Order",
      "115-3485666-9701067",
      "1002584309",
      "The Jolly Postman",
      "1",
      "amazon.com",
      "Amazon",
      "Renton",
      "WA",
      "98055",
      "",
      "10.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-1.92",
      "0",
      "0",
      "5.65"
    ],
    [
      "Aug 7, 2015 2:22:06 AM PDT",
      "5951020731",
      "Order",
      "112-6463145-4896222",
      "1002584173",
      "It's Hard to Be Five: Learning How to Work My Control Panel",
      "1",
      "amazon.com",
      "Amazon",
      "KISSIMMEE",
      "FL",
      "34746-6158",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 7, 2015 3:15:50 PM PDT",
      "5951020731",
      "Order",
      "103-5056282-5547448",
      "1002537143",
      "Sterling Point Books®: Pearl Harbor Attack",
      "1",
      "amazon.com",
      "Amazon",
      "Mansfield",
      "TX",
      "76063",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 8, 2015 2:20:09 AM PDT",
      "5951020731",
      "Order",
      "105-9922460-1213052",
      "1002470149",
      "Stella Strawberry:  The Fruit of Love (Volume 1)",
      "1",
      "amazon.com",
      "Amazon",
      "CINCINNATI",
      "OH",
      "45230-1113",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 8, 2015 3:11:49 PM PDT",
      "5951020731",
      "Order",
      "106-3008069-6078606",
      "1002516966",
      "6 Sticks",
      "1",
      "amazon.com",
      "Amazon",
      "DANVILLE",
      "VA",
      "24541-3316",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 9, 2015 2:13:30 AM PDT",
      "5951020731",
      "Order",
      "107-8248885-7417824",
      "13-WXO2-I0QN",
      "Beyond Numeracy",
      "1",
      "amazon.com",
      "Amazon",
      "Gainesville",
      "FL",
      "32608",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Aug 9, 2015 5:45:04 AM PDT",
      "5951020731",
      "Order",
      "106-1554951-9910642",
      "1002565184",
      "Owen Foote, Mighty Scientist",
      "1",
      "amazon.com",
      "Amazon",
      "ENGLEWOOD",
      "NJ",
      "07631-2142",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Aug 9, 2015 5:06:03 PM PDT",
      "5951020731",
      "Refund",
      "116-4210734-7016242",
      "1002461374",
      "Super Gulliver Tomato Strainer with Suction Base",
      "1",
      "amazon.com",
      "Amazon",
      "MARION",
      "KY",
      "42064-5924",
      "",
      "-69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "8.40",
      "0",
      "0",
      "0",
      "-61.59"
    ],
    [
      "Aug 9, 2015 5:41:40 PM PDT",
      "5951020731",
      "Order",
      "110-4857739-4556242",
      "1002469708",
      "Night of the Moonjellies",
      "1",
      "amazon.com",
      "Amazon",
      "KILLEEN",
      "TX",
      "76542-5371",
      "",
      "43.91",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.94",
      "-6.52",
      "0",
      "0",
      "34.43"
    ],
    [
      "Aug 9, 2015 7:02:04 PM PDT",
      "5951020731",
      "Order",
      "110-9396312-1959459",
      "1002555580",
      "I Heard Said the Bird",
      "1",
      "amazon.com",
      "Amazon",
      "Noblesville",
      "IN",
      "46062",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 9, 2015 7:10:43 PM PDT",
      "5951020731",
      "Order",
      "111-8922980-4398636",
      "1002584309",
      "The Jolly Postman",
      "1",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20009-6523",
      "",
      "10.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-1.92",
      "0",
      "0",
      "5.65"
    ],
    [
      "Aug 9, 2015 7:14:44 PM PDT",
      "5951020731",
      "Order",
      "116-8736769-1945045",
      "1002584019",
      "Joshua's Surprise: A Tomas the Tortoise Adventure (Las Vegas Review-Journal Book)",
      "1",
      "amazon.com",
      "Amazon",
      "HIGHLAND BEACH",
      "FL",
      "33487-3322",
      "",
      "20.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.50",
      "-1.92",
      "0",
      "0",
      "14.57"
    ],
    [
      "Aug 9, 2015 7:24:27 PM PDT",
      "5951020731",
      "Order",
      "115-1218071-3644201",
      "1002469705",
      "Disney's Winnie the Pooh's A to ZZzz",
      "1",
      "amazon.com",
      "Amazon",
      "Lockport",
      "IL",
      "60441",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Aug 9, 2015 9:52:55 PM PDT",
      "5951020731",
      "Order",
      "115-9340131-9176217",
      "1002600934",
      "What Baby Needs (Sears Children Library)",
      "1",
      "amazon.com",
      "Amazon",
      "Olympia",
      "WA",
      "98512",
      "",
      "10.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.89",
      "-1.54",
      "0",
      "0",
      "5.86"
    ],
    [
      "Aug 9, 2015 10:20:28 PM PDT",
      "5951020731",
      "Order",
      "105-5011875-2848268",
      "1002491060",
      "A Fish Out of Water (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Everett",
      "Washington",
      "98206",
      "",
      "7.99",
      "0",
      "1.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-2.86",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 9, 2015 11:34:46 PM PDT",
      "5951020731",
      "Order",
      "103-8825629-3775426",
      "1002451569",
      "The Baby Blue Cat and the Whole Batch of Cookies",
      "1",
      "amazon.com",
      "Amazon",
      "LAGUNA BEACH",
      "CA",
      "92651-6706",
      "",
      "64.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-11.10",
      "-1.54",
      "0",
      "0",
      "52.35"
    ],
    [
      "Aug 10, 2015 4:08:06 AM PDT",
      "5951020731",
      "Order",
      "109-5281547-5517046",
      "1002537106",
      "The Cul-de-sac Kids  Books 1-6 (Boxed Set)",
      "1",
      "amazon.com",
      "Amazon",
      "OMAHA",
      "NE",
      "68130-1201",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 10, 2015 11:42:36 AM PDT",
      "5951020731",
      "Order",
      "102-8566407-3421846",
      "1002564951",
      "Something Might Happen",
      "1",
      "amazon.com",
      "Amazon",
      "Eek",
      "AK",
      "99578",
      "",
      "4.99",
      "0",
      "1.20",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-3.12",
      "0",
      "0",
      "0.97"
    ],
    [
      "Aug 10, 2015 2:43:13 PM PDT",
      "5951020731",
      "Adjustment",
      "",
      "0S-D2Q1-V6VH",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "12.00",
      "12.00"
    ],
    [
      "Aug 10, 2015 7:23:25 PM PDT",
      "5951020731",
      "Order",
      "114-4403033-4544239",
      "1002451461",
      "This Land Is Your Land",
      "1",
      "amazon.com",
      "Amazon",
      "POTOMAC",
      "MARYLAND",
      "20854-3806",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Aug 10, 2015 11:26:08 PM PDT",
      "5951020731",
      "Order",
      "116-1722569-2959435",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "GROTON",
      "CT",
      "06340-6102",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-3.63",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 11, 2015 2:13:06 AM PDT",
      "5951020731",
      "Order",
      "109-3127108-7657801",
      "1002469705",
      "Disney's Winnie the Pooh's A to ZZzz",
      "1",
      "amazon.com",
      "Amazon",
      "Pittsford",
      "Ny",
      "14534",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Aug 11, 2015 1:16:53 PM PDT",
      "5951020731",
      "Order",
      "115-3945384-2071443",
      "1002517322",
      "Today I Feel Silly: And Other Moods That Make My Day",
      "1",
      "amazon.com",
      "Amazon",
      "Everett",
      "WA",
      "98201",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Aug 11, 2015 2:10:36 PM PDT",
      "5951020731",
      "Order",
      "102-5797761-9312244",
      "1002600970",
      "The Tale of Three Trees: A Traditional Folktale",
      "1",
      "amazon.com",
      "Amazon",
      "Gilbert",
      "AZ",
      "85234",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 11, 2015 7:32:23 PM PDT",
      "5951020731",
      "Order",
      "104-3290233-9299460",
      "LR-W5IH-VZ3Q",
      "Splash-n-Swim 48 inch Inflatable Ride On Pool Toy Whale Orca 4+",
      "1",
      "amazon.com",
      "Amazon",
      "Albuquerque",
      "NM",
      "87109",
      "",
      "5.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.40"
    ],
    [
      "Aug 11, 2015 10:43:19 PM PDT",
      "5951020731",
      "Order",
      "104-3113106-8847436",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "Hidden Valley Lake",
      "CA",
      "95467",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 12, 2015 2:31:38 AM PDT",
      "5951020731",
      "Order",
      "108-0035071-8387414",
      "KV-HZ0V-ZHVN",
      "My Point...And I Do Have One",
      "1",
      "amazon.com",
      "Amazon",
      "SARASOTA",
      "FL",
      "34238-2602",
      "",
      "9.05",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.71",
      "-1.54",
      "0",
      "0",
      "4.80"
    ],
    [
      "Aug 12, 2015 12:04:30 PM PDT",
      "5951020731",
      "Order",
      "002-2976974-0495460",
      "1002600945",
      "The Magic School Bus and the Electric Field Trip",
      "1",
      "amazon.com",
      "Amazon",
      "Elizabeth",
      "IL",
      "61028",
      "",
      "6.99",
      "0",
      "1.29",
      "0",
      "0",
      "0",
      "-1.29",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Aug 12, 2015 1:18:20 PM PDT",
      "5951020731",
      "Order",
      "114-1602152-4476212",
      "1002490990",
      "Robert the Rose Horse",
      "1",
      "amazon.com",
      "Amazon",
      "Rowlett",
      "TX",
      "75088",
      "",
      "7.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-5.53",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 12, 2015 5:29:44 PM PDT",
      "5951020731",
      "Refund",
      "116-1258109-5633800",
      "1002543362",
      "There's A Boy in the Girls' Bathroom",
      "1",
      "amazon.com",
      "Amazon",
      "TUCSON",
      "AZ",
      "85747-6023",
      "",
      "-6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.29",
      "0",
      "0",
      "0",
      "-4.00"
    ],
    [
      "Aug 12, 2015 6:22:42 PM PDT",
      "5951020731",
      "Order",
      "114-9536166-7806604",
      "1002419763",
      "The Cat in the Hat Beginner Book Dictionary (I Can Read It All by Myself Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Gooding",
      "Id",
      "83330",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 12, 2015 7:28:11 PM PDT",
      "5951020731",
      "Order",
      "110-6021299-5843458",
      "1002491054",
      "The Best Nest (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11205-1738",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 12, 2015 11:18:29 PM PDT",
      "5951020731",
      "Order",
      "116-4250226-6402643",
      "1002605316",
      "The Namesake: A Novel",
      "1",
      "amazon.com",
      "Amazon",
      "EAST AMHERST",
      "NEW YORK",
      "14051-1265",
      "",
      "7.29",
      "0",
      "2.76",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-4.43",
      "0",
      "0",
      "3.18"
    ],
    [
      "Aug 12, 2015 11:18:44 PM PDT",
      "5951020731",
      "Order",
      "106-1942775-6566626",
      "G5-K28K-U8RJ",
      "Dr. Seuss Ten Apples Up On Top Board Book",
      "1",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20003-1963",
      "",
      "11.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.70",
      "-3.63",
      "0",
      "0",
      "5.99"
    ],
    [
      "Aug 13, 2015 1:28:00 AM PDT",
      "5951020731",
      "Order",
      "110-8275753-1158650",
      "H8-Q28U-TY0M",
      "Marvel Avengers Assemble 50 Piece Tower Puzzle (3 assorted puzzles, Designs Vary)",
      "1",
      "amazon.com",
      "Amazon",
      "NORTH CANTON",
      "OH",
      "44720-7233",
      "",
      "8.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.27",
      "-3.63",
      "0",
      "0",
      "3.59"
    ],
    [
      "Aug 13, 2015 2:02:20 AM PDT",
      "5951020731",
      "Order",
      "115-9143420-2559461",
      "1002491060",
      "A Fish Out of Water (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Indiana",
      "PA",
      "15701",
      "",
      "7.99",
      "0",
      "1.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-3.03",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 13, 2015 12:15:20 PM PDT",
      "5951020731",
      "Order",
      "108-8327236-4952239",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Castleton",
      "VT",
      "05735",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-3.63",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 13, 2015 7:23:27 PM PDT",
      "5951020731",
      "Order",
      "106-1453813-6336247",
      "1002603201",
      "Frindle",
      "1",
      "amazon.com",
      "Amazon",
      "Palmyra",
      "VA",
      "22963-2220",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 13, 2015 8:02:57 PM PDT",
      "5951020731",
      "Order",
      "105-9172833-0189032",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "JUNEAU",
      "AK",
      "99801-7422",
      "",
      "27.99",
      "0",
      "2.54",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-6.17",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 13, 2015 11:11:30 PM PDT",
      "5951020731",
      "Order",
      "108-7313700-2253063",
      "1002436657",
      "No, David!",
      "1",
      "amazon.com",
      "Amazon",
      "WHITTIER",
      "CA",
      "90604-3522",
      "",
      "8.92",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.69",
      "-6.90",
      "0",
      "0",
      "4.31"
    ],
    [
      "Aug 13, 2015 11:13:35 PM PDT",
      "5951020731",
      "Order",
      "102-3214925-1346642",
      "1002640923",
      "Concepts of Programming Languages (10th Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "GLOUCESTER",
      "VA",
      "23061-3249",
      "",
      "69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-11.85",
      "-2.33",
      "0",
      "0",
      "55.81"
    ],
    [
      "Aug 13, 2015 11:29:41 PM PDT",
      "5951020731",
      "Order",
      "107-2927312-0198629",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "3",
      "amazon.com",
      "Amazon",
      "SANTA FE",
      "NM",
      "87508-4813",
      "",
      "9.87",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.01",
      "0",
      "0",
      "0.86"
    ],
    [
      "Aug 14, 2015 12:03:57 AM PDT",
      "5951020731",
      "Order",
      "105-2107469-3264230",
      "1002555508",
      "The Hello, Goodbye Window",
      "1",
      "amazon.com",
      "Amazon",
      "BROWNSBURG",
      "IN",
      "46112-2158",
      "",
      "10.47",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-1.92",
      "0",
      "0",
      "5.63"
    ],
    [
      "Aug 14, 2015 12:08:50 AM PDT",
      "5951020731",
      "Order",
      "103-2298287-3817854",
      "1002600980",
      "I Love You, Mommy",
      "1",
      "amazon.com",
      "Amazon",
      "MARTINEZ",
      "CA",
      "94553-3370",
      "",
      "11.29",
      "0",
      "2.19",
      "0",
      "0",
      "0",
      "-2.19",
      "0",
      "0",
      "-3.04",
      "-1.92",
      "0",
      "0",
      "6.33"
    ],
    [
      "Aug 14, 2015 2:39:16 AM PDT",
      "5951020731",
      "Order",
      "104-1891524-4196258",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OR",
      "97213-5432",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 14, 2015 3:34:29 PM PDT",
      "5951020731",
      "Order",
      "114-9052909-3048233",
      "1002584266",
      "First Thousand Words In Spanish (Spanish Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "tucson",
      "az",
      "85704",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Aug 14, 2015 4:13:02 PM PDT",
      "5951020731",
      "Order",
      "112-2732648-4032206",
      "1002509012",
      "What to Expect When You're Expecting, 4th Edition",
      "1",
      "amazon.com",
      "Amazon",
      "HOCKLEY",
      "TX",
      "77447-7571",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-2.33",
      "0",
      "0",
      "3.11"
    ],
    [
      "Aug 15, 2015 2:15:04 AM PDT",
      "5951020731",
      "Order",
      "115-6628371-4020269",
      "1002555441",
      "A Child's Christmas at St. Nicholas Circle",
      "1",
      "amazon.com",
      "Amazon",
      "Franklin",
      "TN",
      "37067",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.92",
      "0",
      "0",
      "2.93"
    ],
    [
      "Aug 15, 2015 5:11:31 AM PDT",
      "5951020731",
      "Adjustment",
      "",
      "KA-A1E7-PU20",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.32",
      "1.32"
    ],
    [
      "Aug 15, 2015 8:40:03 AM PDT",
      "5951020731",
      "Order",
      "116-9813213-4099469",
      "HQ-VTKS-JZTP",
      "Scrabble Deluxe Wooden Edition with Rotating Game Board",
      "1",
      "amazon.com",
      "Amazon",
      "PLACENTIA",
      "CA",
      "92870-4820",
      "",
      "89.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-13.50",
      "-8.37",
      "0",
      "0",
      "68.12"
    ],
    [
      "Aug 15, 2015 3:14:29 PM PDT",
      "5951020731",
      "Order",
      "110-9639151-3655404",
      "1002509012",
      "What to Expect When You're Expecting, 4th Edition",
      "1",
      "amazon.com",
      "Amazon",
      "TOLEDO",
      "OH",
      "43615-6719",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-2.33",
      "0",
      "0",
      "3.11"
    ],
    [
      "Aug 15, 2015 3:29:56 PM PDT",
      "5951020731",
      "Order",
      "110-6744856-4938619",
      "1002555756",
      "The Fabulous Feud Of Gilbert And Sullivan",
      "1",
      "amazon.com",
      "Amazon",
      "SPRING GREEN",
      "WI",
      "53588-9256",
      "",
      "6.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.66"
    ],
    [
      "Aug 15, 2015 7:21:58 PM PDT",
      "5958684411",
      "Order",
      "109-0761367-7032213",
      "1002486198",
      "I Love You Stinky Face by Mccourt, Lisa (BRDBK Edition) [Boardbook(2004)]",
      "1",
      "amazon.com",
      "Amazon",
      "SUNNYSIDE",
      "NY",
      "11104-3006",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 15, 2015 11:19:51 PM PDT",
      "5958684411",
      "Order",
      "108-3523454-8120262",
      "0L-0X3S-RYXU",
      "The Cat in the Hat",
      "1",
      "amazon.com",
      "Amazon",
      "POOLER",
      "GA",
      "31322-8403",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Aug 15, 2015 11:43:28 PM PDT",
      "5958684411",
      "Order",
      "104-4096942-4042604",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Renton",
      "WA",
      "98055-5945",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-3.63",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 16, 2015 2:17:08 AM PDT",
      "5958684411",
      "Order",
      "107-3367157-7453814",
      "1002491294",
      "Snug House, Bug House (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "GEORGETOWN",
      "KY",
      "40324-1457",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.54",
      "0",
      "0",
      "7.30"
    ],
    [
      "Aug 16, 2015 2:23:27 AM PDT",
      "5958684411",
      "Order",
      "002-7087659-6296242",
      "1002605225",
      "Edge Chronicles 1: Beyond the Deepwoods (The Edge Chronicles)",
      "1",
      "amazon.com",
      "Amazon",
      "Saugus",
      "MA",
      "01906-1641",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Aug 16, 2015 3:31:06 PM PDT",
      "5958684411",
      "Order",
      "107-3454038-9437013",
      "1002508891",
      "Nova Deruta Raffaellesco Italian Oval Serving Plate Platter Dish",
      "1",
      "amazon.com",
      "Amazon",
      "CONROE",
      "TX",
      "77302-3090",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.50",
      "-3.63",
      "0",
      "0",
      "38.86"
    ],
    [
      "Aug 16, 2015 6:16:47 PM PDT",
      "5958684411",
      "Order",
      "102-2692136-0880223",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10016-5217",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-3.63",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 16, 2015 8:25:47 PM PDT",
      "5958684411",
      "Order",
      "103-3559224-6589063",
      "1002491134",
      "The Berenstain Bears and the Spooky Old Tree",
      "1",
      "amazon.com",
      "Amazon",
      "NORTH ANDOVER",
      "MA",
      "01845-2615",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 16, 2015 11:16:32 PM PDT",
      "5958684411",
      "Order",
      "104-5166079-1365062",
      "1002486278",
      "Double the Ducks (MathStart 1)",
      "1",
      "amazon.com",
      "Amazon",
      "Kennewick",
      "WA",
      "99338-1305",
      "",
      "5.70",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.21",
      "-1.54",
      "0",
      "0",
      "1.95"
    ],
    [
      "Aug 16, 2015 11:21:49 PM PDT",
      "5958684411",
      "Order",
      "108-0265844-0589814",
      "1002640868",
      "Elementary Linear Algebra",
      "1",
      "amazon.com",
      "Amazon",
      "WINTERS",
      "CA",
      "95694-9019",
      "",
      "29.05",
      "0",
      "12.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.71",
      "-15.31",
      "0",
      "0",
      "21.01"
    ],
    [
      "Aug 16, 2015 11:56:45 PM PDT",
      "5958684411",
      "Order",
      "104-3364288-9954667",
      "5T-QNLP-N9DN",
      "Pocket Puzzle Word Find Game (Large Print)",
      "1",
      "amazon.com",
      "Amazon",
      "CHOWCHILLA",
      "CA",
      "93610-8004",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.45"
    ],
    [
      "Aug 17, 2015 12:26:23 AM PDT",
      "5958684411",
      "Order",
      "002-3012662-9961810",
      "1002361230",
      "Parker & Bailey Cooktop Cleaner 12oz",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90024-5047",
      "",
      "13.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.95",
      "-3.63",
      "0",
      "0",
      "7.42"
    ],
    [
      "Aug 17, 2015 2:15:57 AM PDT",
      "5958684411",
      "Order",
      "108-0326889-8673036",
      "1002543300",
      "The Indian in the Cupboard",
      "1",
      "amazon.com",
      "Amazon",
      "Fort Scott",
      "KS",
      "66701",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Aug 17, 2015 5:49:18 AM PDT",
      "5958684411",
      "Order",
      "108-1846207-1521049",
      "1002650499",
      "Bentley II Outdoor Tarnished Bronze Oscillating Ceiling Fan with Wall Control",
      "1",
      "amazon.com",
      "Amazon",
      "LINCOLN",
      "NE",
      "68506-5164",
      "",
      "186.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-28.05",
      "-35.82",
      "0",
      "0",
      "123.12"
    ],
    [
      "Aug 17, 2015 1:10:49 PM PDT",
      "5958684411",
      "Order",
      "107-4634271-4002607",
      "1002640897",
      "Starting Out with Java: From Control Structures through Objects (4th Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "SAN FRANCISCO",
      "CA",
      "94121-3315",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-2.74",
      "0",
      "0",
      "4.40"
    ],
    [
      "Aug 17, 2015 5:48:27 PM PDT",
      "5958684411",
      "Order",
      "115-6178886-8576241",
      "1002368609",
      "The Secret Life of Families: Making Decisions About Secrets: When Keeping Secrets Can Harm You, When Keeping Secrets Can Heal You-And How to Know the",
      "1",
      "amazon.com",
      "Amazon",
      "SEATTLE",
      "WASHINGTON",
      "98101-1957",
      "",
      "8.54",
      "0",
      "12.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.63",
      "-14.90",
      "0",
      "0",
      "3.99"
    ],
    [
      "Aug 17, 2015 11:08:12 PM PDT",
      "5958684411",
      "Order",
      "114-4537429-1650628",
      "1002486064",
      "The Revenge of the Shadow King (Grey Griffins #1)",
      "1",
      "amazon.com",
      "Amazon",
      "Pierre",
      "SD",
      "57501-2438",
      "",
      "7.50",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.48",
      "-1.92",
      "0",
      "0",
      "3.10"
    ],
    [
      "Aug 18, 2015 12:52:37 AM PDT",
      "5958684411",
      "Order",
      "112-8100330-0340254",
      "1002603222",
      "The Royal Diaries:  Victoria, May Blossom of Britannia, England 1829",
      "1",
      "amazon.com",
      "Amazon",
      "Saint Joseph",
      "MO",
      "64506",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.67",
      "0",
      "0",
      "7.17"
    ],
    [
      "Aug 18, 2015 2:21:04 AM PDT",
      "5958684411",
      "Order",
      "112-1822758-5109050",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "SAN FRANCISCO",
      "CA",
      "94114-2923",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 18, 2015 2:22:15 AM PDT",
      "5958684411",
      "Order",
      "111-6603705-3605008",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA BARBARA",
      "CA",
      "93103-1820",
      "",
      "24.99",
      "0",
      "1.70",
      "0",
      "0",
      "0",
      "-1.70",
      "0",
      "0",
      "-7.50",
      "-1.67",
      "0",
      "0",
      "15.82"
    ],
    [
      "Aug 18, 2015 2:22:15 AM PDT",
      "5958684411",
      "Order",
      "111-6603705-3605008",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA BARBARA",
      "CA",
      "93103-1820",
      "",
      "24.99",
      "0",
      "1.71",
      "0",
      "0",
      "0",
      "-1.71",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "22.32"
    ],
    [
      "Aug 18, 2015 2:32:34 AM PDT",
      "5958684411",
      "Order",
      "112-9582798-6250655",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Wilmington",
      "NC",
      "28411",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-3.63",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 18, 2015 11:40:59 AM PDT",
      "5958684411",
      "Order",
      "106-3106200-9109002",
      "1002536996",
      "Tree House Mystery (The Boxcar Children Mysteries #14)",
      "1",
      "amazon.com",
      "Amazon",
      "SPOKANE",
      "WA",
      "99208-4641",
      "",
      "4.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.99",
      "-1.54",
      "0",
      "0",
      "0.76"
    ],
    [
      "Aug 18, 2015 3:26:46 PM PDT",
      "5958684411",
      "Order",
      "002-4859305-5994612",
      "1002535633",
      "Barron's SAT 25th Edition",
      "1",
      "amazon.com",
      "Amazon",
      "NEW ORLEANS",
      "LOUISIANA",
      "70124-2709",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-2.74",
      "0",
      "0",
      "6.95"
    ],
    [
      "Aug 18, 2015 3:35:38 PM PDT",
      "5958684411",
      "Order",
      "103-7642227-0746608",
      "1002491385",
      "The Shape of Me and Other Stuff (Bright & Early Books)",
      "1",
      "amazon.com",
      "Amazon",
      "SKIATOOK",
      "OK",
      "74070-2304",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.54",
      "0",
      "0",
      "3.31"
    ],
    [
      "Aug 18, 2015 5:38:57 PM PDT",
      "5958684411",
      "Order",
      "108-8096025-9997859",
      "9N-8PB7-W181",
      "NIV Women's Devotional Bible",
      "1",
      "amazon.com",
      "Amazon",
      "SURPRISE",
      "AZ",
      "85374-1805",
      "",
      "23.88",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-1.74",
      "0",
      "0",
      "-4.93",
      "-2.33",
      "0",
      "0",
      "16.62"
    ],
    [
      "Aug 18, 2015 7:22:43 PM PDT",
      "5958684411",
      "Refund",
      "105-2107469-3264230",
      "1002555508",
      "The Hello, Goodbye Window",
      "1",
      "amazon.com",
      "Amazon",
      "BROWNSBURG",
      "IN",
      "46112-2158",
      "",
      "-10.47",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.92",
      "0",
      "0",
      "0",
      "-7.55"
    ],
    [
      "Aug 18, 2015 11:48:10 PM PDT",
      "5958684411",
      "Order",
      "109-8254141-3255430",
      "1002600981",
      "Who Took the Cookies from the Cookie Jar?",
      "1",
      "amazon.com",
      "Amazon",
      "PALMER",
      "AK",
      "99645-8739",
      "",
      "10.49",
      "0",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-8.91",
      "0",
      "0",
      "5.65"
    ],
    [
      "Aug 19, 2015 1:14:39 PM PDT",
      "5958684411",
      "Order",
      "108-8509308-2276256",
      "1002436718",
      "5 Minute Fairy Stories",
      "1",
      "amazon.com",
      "Amazon",
      "Longmont",
      "CO",
      "80503",
      "",
      "23.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.95",
      "-2.74",
      "0",
      "0",
      "16.30"
    ],
    [
      "Aug 19, 2015 1:16:05 PM PDT",
      "5958684411",
      "Order",
      "103-3191073-2892249",
      "1002477452",
      "Mikasa French Countryside Steak Knives (Set of 4)",
      "1",
      "amazon.com",
      "Amazon",
      "Sacramento",
      "CA",
      "95864",
      "",
      "20.39",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.12",
      "-2.63",
      "0",
      "0",
      "11.64"
    ],
    [
      "Aug 19, 2015 1:16:05 PM PDT",
      "5958684411",
      "Order",
      "103-3191073-2892249",
      "1002477452",
      "Mikasa French Countryside Steak Knives (Set of 4)",
      "1",
      "amazon.com",
      "Amazon",
      "Sacramento",
      "CA",
      "95864",
      "",
      "20.39",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.63",
      "0",
      "0",
      "16.76"
    ],
    [
      "Aug 19, 2015 10:43:21 PM PDT",
      "5958684411",
      "Order",
      "104-6946590-9670642",
      "LR-828C-VZAY",
      "Growing Up (Plume)",
      "1",
      "amazon.com",
      "Amazon",
      "MORAGA",
      "CA",
      "94556-1941",
      "",
      "8.49",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.62",
      "-1.54",
      "0",
      "0",
      "4.33"
    ],
    [
      "Aug 19, 2015 11:26:23 PM PDT",
      "5958684411",
      "Order",
      "110-1269773-9520260",
      "D3-7CDF-YCVW",
      "Lindbergh",
      "1",
      "amazon.com",
      "Amazon",
      "Las Cruces",
      "NM",
      "88005",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-2.33",
      "0",
      "0",
      "3.96"
    ],
    [
      "Aug 19, 2015 11:30:16 PM PDT",
      "5958684411",
      "Order",
      "105-7990244-1684225",
      "1002527593",
      "Coming Home: A True Story of Josh Gibson, Baseball's Greatest Home Run Hitter",
      "1",
      "amazon.com",
      "Amazon",
      "Redmond",
      "WA",
      "98052",
      "",
      "16.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.90",
      "-1.92",
      "0",
      "0",
      "11.17"
    ],
    [
      "Aug 20, 2015 2:12:57 AM PDT",
      "5958684411",
      "Order",
      "105-6541488-2430622",
      "1002527538",
      "Math Potatoes: Mind-stretching Brain Food",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT CLOUD",
      "FLORIDA",
      "34772-9388",
      "",
      "10.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-1.92",
      "0",
      "0",
      "5.65"
    ],
    [
      "Aug 20, 2015 2:26:23 AM PDT",
      "5958684411",
      "Order",
      "113-0753889-7074660",
      "1002490977",
      "The Tooth Book (Bright and Early Books for Beginning Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "Zionsville",
      "IN",
      "46077-8222",
      "",
      "6.79",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.37",
      "-1.54",
      "0",
      "0",
      "2.88"
    ],
    [
      "Aug 20, 2015 2:26:44 AM PDT",
      "5958684411",
      "Order",
      "115-9749842-5120248",
      "1002603121",
      "FamilyFun Birthday Cakes: 50 Cute and Easy Party Treats",
      "1",
      "amazon.com",
      "Amazon",
      "CONWAY",
      "ARKANSAS",
      "72032-8223",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 20, 2015 11:24:22 AM PDT",
      "5958684411",
      "Order",
      "113-5416192-7389047",
      "3R-KAJN-7GY6",
      "Wood: Craft, Culture, History",
      "1",
      "amazon.com",
      "Amazon",
      "Rochester",
      "NY",
      "14609-5902",
      "",
      "10.93",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.99",
      "-1.92",
      "0",
      "0",
      "6.02"
    ],
    [
      "Aug 20, 2015 2:07:49 PM PDT",
      "5958684411",
      "Order",
      "110-8692614-1309850",
      "1002516904",
      "Marvin K. Mooney Will You Please Go Now!  (Bright and Early Books for Beginning Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "TRACY",
      "CA",
      "95377-7101",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 20, 2015 2:54:43 PM PDT",
      "5958684411",
      "Order",
      "103-4736952-6803462",
      "1002555532",
      "Dear Mrs. LaRue: Letters from Obedience School (LaRue Books)",
      "1",
      "amazon.com",
      "Amazon",
      "BAYTOWN",
      "TX",
      "77520-3824",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Aug 20, 2015 11:13:26 PM PDT",
      "5958684411",
      "Order",
      "116-1804553-2096228",
      "1002663348",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "ROSWELL",
      "GA",
      "30075-4019",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Aug 21, 2015 7:30:22 AM PDT",
      "5958684411",
      "Adjustment",
      "116-1258109-5633800",
      "1002543362",
      "FBA Inventory Reimbursement - Customer Return",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.33",
      "2.33"
    ],
    [
      "Aug 21, 2015 11:06:30 AM PDT",
      "5958684411",
      "Order",
      "115-0054591-8454640",
      "1A-7W2H-LA7A",
      "Ridgid 22 In. Pro Organizer, Black Toolbox",
      "1",
      "amazon.com",
      "Amazon",
      "Alexandria",
      "VA",
      "22303",
      "",
      "39.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.00",
      "-11.10",
      "0",
      "0",
      "22.89"
    ],
    [
      "Aug 21, 2015 12:48:34 PM PDT",
      "5958684411",
      "Order",
      "103-3788373-8758601",
      "8A-E68O-9HSL",
      "The Interrogator: An Education",
      "1",
      "amazon.com",
      "Amazon",
      "DUXBURY",
      "MA",
      "02332-5230",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Aug 21, 2015 3:17:16 PM PDT",
      "5958684411",
      "Order",
      "106-7045602-9896201",
      "1002491135",
      "I Wish That I Had Duck Feet (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Chandler",
      "Arizona",
      "85249",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Aug 22, 2015 12:59:14 PM PDT",
      "5958684411",
      "Order",
      "108-3942648-1126605",
      "1002564812",
      "The Cats in Krasinski Square",
      "1",
      "amazon.com",
      "Amazon",
      "OREM",
      "UT",
      "84058-5317",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 22, 2015 2:28:25 PM PDT",
      "5958684411",
      "Order",
      "111-2657827-2571401",
      "8H-ZKKL-CPZE",
      "Ice Bound: A Doctor's Incredible Battle for Survival at the South Pole",
      "1",
      "amazon.com",
      "Amazon",
      "IRVINE",
      "CA",
      "92618-8815",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Aug 22, 2015 2:41:35 PM PDT",
      "5958684411",
      "Order",
      "107-3360774-2047413",
      "1002400216",
      "Print Reading for Construction: Residential and Commercial Set",
      "1",
      "amazon.com",
      "Amazon",
      "Lodi",
      "CA",
      "92540",
      "",
      "19.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-8.13",
      "0",
      "0",
      "12.49"
    ],
    [
      "Aug 22, 2015 7:18:51 PM PDT",
      "5958684411",
      "Order",
      "002-4121525-6677028",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20005-3807",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "3.64"
    ],
    [
      "Aug 23, 2015 8:09:46 AM PDT",
      "5958684411",
      "Order",
      "110-6839384-3189049",
      "1002650652",
      "Moonrays 95433 300-Watt Power Pack with Sensor and Weather Shield for Low Voltage Landscape Lighting",
      "1",
      "amazon.com",
      "Amazon",
      "Oxford",
      "GA",
      "30054-2907",
      "",
      "79.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-12.00",
      "-8.70",
      "0",
      "0",
      "59.29"
    ],
    [
      "Aug 23, 2015 11:56:36 AM PDT",
      "5958684411",
      "Order",
      "111-7323372-0497030",
      "1002451923",
      "Twin to Twin",
      "1",
      "amazon.com",
      "Amazon",
      "THORNTON",
      "CO",
      "80602-8461",
      "",
      "12.81",
      "0",
      "1.70",
      "0",
      "0",
      "0",
      "-1.70",
      "0",
      "0",
      "-3.27",
      "-1.54",
      "0",
      "0",
      "8.00"
    ],
    [
      "Aug 23, 2015 1:40:47 PM PDT",
      "5958684411",
      "Order",
      "110-7930424-9541847",
      "1002486239",
      "Maps and Globes (Reading Rainbow Book)",
      "1",
      "amazon.com",
      "Amazon",
      "Campbellsburg",
      "IN",
      "47108-7922",
      "",
      "4.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-6.52",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 23, 2015 2:33:04 PM PDT",
      "5958684411",
      "Order",
      "109-5812309-6809006",
      "AX-IMW5-YYE0",
      "Jumbo Word-Find Pad Set of 2 (See Seller Comments for Volumes) by Kappa",
      "1",
      "amazon.com",
      "Amazon",
      "JACKSON",
      "MI",
      "49201-8268",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-2.67",
      "0",
      "0",
      "10.07"
    ],
    [
      "Aug 23, 2015 3:20:47 PM PDT",
      "5958684411",
      "Order",
      "103-5224130-9676231",
      "1002622541",
      "Honeywell  RTH7600D Touchscreen 7-Day Programmable Thermostat",
      "1",
      "amazon.com",
      "Amazon",
      "austin",
      "TX",
      "78739",
      "",
      "78.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-11.85",
      "-4.02",
      "0",
      "0",
      "63.12"
    ],
    [
      "Aug 23, 2015 3:24:55 PM PDT",
      "5958684411",
      "Order",
      "103-9624211-4718615",
      "1002555567",
      "Mama Cat Has Three Kittens",
      "1",
      "amazon.com",
      "Amazon",
      "JENKS",
      "OKLAHOMA",
      "74037-3682",
      "",
      "10.99",
      "0",
      "2.70",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-4.62",
      "0",
      "0",
      "6.07"
    ],
    [
      "Aug 23, 2015 3:28:47 PM PDT",
      "5958684411",
      "Order",
      "107-8621155-5024237",
      "1002470130",
      "Ten Apples Up on Top!",
      "1",
      "amazon.com",
      "Amazon",
      "HOUSTON",
      "TX",
      "77064-7149",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Aug 24, 2015 1:07:16 AM PDT",
      "5958684411",
      "Order",
      "107-1165417-5127433",
      "1002641861",
      "The Filmmaker's Handbook: A Comprehensive Guide for the Digital Age",
      "1",
      "amazon.com",
      "Amazon",
      "BOULDER",
      "CO",
      "80302-5214",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-2.33",
      "0",
      "0",
      "2.26"
    ],
    [
      "Aug 24, 2015 4:43:06 AM PDT",
      "5958684411",
      "Order",
      "108-7105923-1948228",
      "1002486287",
      "We had a picnic this Sunday past",
      "1",
      "amazon.com",
      "Amazon",
      "TUCSON",
      "AZ",
      "85704-1628",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Aug 24, 2015 4:50:26 AM PDT",
      "5958684411",
      "Order",
      "115-7326983-9603408",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA BARBARA",
      "CA",
      "93103-1820",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    [
      "Aug 24, 2015 4:50:26 AM PDT",
      "5958684411",
      "Order",
      "115-7326983-9603408",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA BARBARA",
      "CA",
      "93103-1820",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    [
      "Aug 24, 2015 2:21:47 PM PDT",
      "5958684411",
      "Order",
      "110-0810797-4711429",
      "1002400216",
      "Print Reading for Construction: Residential and Commercial Set",
      "1",
      "amazon.com",
      "Amazon",
      "AVONDALE",
      "ARIZONA",
      "85392-6578",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-3.15",
      "0",
      "0",
      "12.49"
    ],
    [
      "Aug 24, 2015 2:30:46 PM PDT",
      "5958684411",
      "Order",
      "103-2755253-5643403",
      "IM-QXL0-GHDK",
      "Scented Bath Salt, 17.6-oz. Jars (Rose Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "LENORE",
      "ID",
      "83541-5023",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-3.63",
      "0",
      "0",
      "4.86"
    ],
    [
      "Aug 24, 2015 7:11:51 PM PDT",
      "5958684411",
      "Order",
      "111-8903096-4905820",
      "CF-9GH1-53BV",
      "The Kids Are All Right: A Memoir",
      "1",
      "amazon.com",
      "Amazon",
      "Rockville",
      "MD",
      "20852",
      "",
      "2.99",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.73"
    ],
    [
      "Aug 24, 2015 7:14:51 PM PDT",
      "5958684411",
      "Order",
      "104-4854318-0722662",
      "1002516934",
      "Barnyard Dance! (Boynton on Board)",
      "1",
      "amazon.com",
      "Amazon",
      "Yonkers",
      "NY",
      "10701-5363",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 24, 2015 7:35:00 PM PDT",
      "5958684411",
      "Order",
      "107-1039787-1315445",
      "1002436768",
      "The Boy Who Wouldn't Share",
      "1",
      "amazon.com",
      "Amazon",
      "Oak Lawn",
      "IL",
      "60453",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Aug 24, 2015 8:56:55 PM PDT",
      "5958684411",
      "Order",
      "114-9190302-6001842",
      "1002470022",
      "The Giver (Giver Quartet)",
      "1",
      "amazon.com",
      "Amazon",
      "UNION CITY",
      "CA",
      "94587-1311",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.20",
      "-1.54",
      "0",
      "0",
      "-1.75"
    ],
    [
      "Aug 24, 2015 8:56:55 PM PDT",
      "5958684411",
      "Order",
      "114-9190302-6001842",
      "1002470022",
      "The Giver (Giver Quartet)",
      "1",
      "amazon.com",
      "Amazon",
      "UNION CITY",
      "CA",
      "94587-1311",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.54",
      "0",
      "0",
      "5.45"
    ],
    [
      "Aug 24, 2015 8:56:55 PM PDT",
      "5958684411",
      "Order",
      "114-9190302-6001842",
      "1002470022",
      "The Giver (Giver Quartet)",
      "1",
      "amazon.com",
      "Amazon",
      "UNION CITY",
      "CA",
      "94587-1311",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.54",
      "0",
      "0",
      "5.45"
    ],
    [
      "Aug 24, 2015 11:54:27 PM PDT",
      "5958684411",
      "Order",
      "116-5451810-0464201",
      "CM-W7I0-8B04",
      "The Last Minute (The Sam Capra series)",
      "1",
      "amazon.com",
      "Amazon",
      "CAMARILLO",
      "CA",
      "93012-9401",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.80",
      "-1.92",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Aug 24, 2015 11:57:28 PM PDT",
      "5958684411",
      "Order",
      "115-8849421-7344246",
      "1002551173",
      "Count with Maisy",
      "1",
      "amazon.com",
      "Amazon",
      "ELK GROVE VILLAGE",
      "IL",
      "60007-5512",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 25, 2015 12:27:40 AM PDT",
      "5958684411",
      "Order",
      "115-3932797-4469044",
      "1002451720",
      "I Already Know I Love You",
      "1",
      "amazon.com",
      "Amazon",
      "MAPLE VALLEY",
      "WA",
      "98038-6418",
      "",
      "10.25",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.89",
      "-1.54",
      "0",
      "0",
      "5.82"
    ],
    [
      "Aug 25, 2015 2:34:23 AM PDT",
      "5958684411",
      "Order",
      "105-8475472-2199420",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "3",
      "amazon.com",
      "Amazon",
      "COLUMBIA",
      "SC",
      "29206-2855",
      "",
      "14.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.01",
      "0",
      "0",
      "5.96"
    ],
    [
      "Aug 25, 2015 2:36:45 AM PDT",
      "5958684411",
      "Order",
      "113-2442893-6554647",
      "1002630211",
      "Guess How Much I Love You",
      "1",
      "amazon.com",
      "Amazon",
      "CHARLESTON",
      "SC",
      "29403-4313",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Aug 25, 2015 3:18:57 PM PDT",
      "5958684411",
      "Order",
      "116-0192328-6945842",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "MOUNT ARLINGTON",
      "NJ",
      "07856-1207",
      "",
      "27.99",
      "0",
      "6.30",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-9.93",
      "0",
      "0",
      "20.16"
    ],
    [
      "Aug 25, 2015 6:36:33 PM PDT",
      "5958684411",
      "Order",
      "106-1445153-8444249",
      "1002516892",
      "And to Think That I Saw It on Mulberry Street",
      "1",
      "amazon.com",
      "Amazon",
      "SIMI VALLEY",
      "CA",
      "93065-2233",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 25, 2015 9:42:38 PM PDT",
      "5958684411",
      "Refund",
      "111-6603705-3605008",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA BARBARA",
      "CA",
      "93103-1820",
      "",
      "-24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3.00",
      "0",
      "0",
      "0",
      "-21.99"
    ],
    [
      "Aug 25, 2015 11:07:34 PM PDT",
      "5958684411",
      "Order",
      "107-4914964-2009048",
      "1002663348",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "5",
      "amazon.com",
      "Amazon",
      "SANTA CRUZ",
      "CA",
      "95065",
      "",
      "49.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.50",
      "-9.35",
      "0",
      "0",
      "33.10"
    ],
    [
      "Aug 25, 2015 11:38:22 PM PDT",
      "5958684411",
      "Order",
      "105-8740254-9255436",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "KAYSVILLE",
      "UTAH",
      "84037-9712",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-1.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 25, 2015 11:38:22 PM PDT",
      "5958684411",
      "Order",
      "105-8740254-9255436",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "KAYSVILLE",
      "UTAH",
      "84037-9712",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "2.32"
    ],
    [
      "Aug 26, 2015 12:25:16 AM PDT",
      "5958684411",
      "Order",
      "104-9610501-5147447",
      "1002486087",
      "The Epiplectic Bicycle",
      "1",
      "amazon.com",
      "Amazon",
      "STATE COLLEGE",
      "PA",
      "16803-2040",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 26, 2015 10:56:46 AM PDT",
      "5958684411",
      "Refund",
      "103-5224130-9676231",
      "1002622541",
      "Honeywell  RTH7600D Touchscreen 7-Day Programmable Thermostat",
      "1",
      "amazon.com",
      "Amazon",
      "austin",
      "TX",
      "78739",
      "",
      "-78.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "9.48",
      "0",
      "0",
      "0",
      "-69.51"
    ],
    [
      "Aug 26, 2015 7:26:09 PM PDT",
      "5958684411",
      "Order",
      "110-8414332-1521017",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "Medford",
      "NJ",
      "08055-9182",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 27, 2015 12:02:06 AM PDT",
      "5958684411",
      "Refund",
      "111-0927074-9627410",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "AMHERST",
      "NEW YORK",
      "14228-2716",
      "",
      "-24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.40",
      "0",
      "0",
      "0",
      "-22.59"
    ],
    [
      "Aug 27, 2015 2:30:48 AM PDT",
      "5958684411",
      "Refund",
      "103-2298287-3817854",
      "1002600980",
      "I Love You, Mommy",
      "1",
      "amazon.com",
      "Amazon",
      "MARTINEZ",
      "CA",
      "94553-3370",
      "",
      "-11.29",
      "0",
      "-2.19",
      "0",
      "0",
      "0",
      "2.19",
      "0",
      "0",
      "3.04",
      "0",
      "0",
      "0",
      "-8.25"
    ],
    [
      "Aug 27, 2015 2:31:36 AM PDT",
      "5958684411",
      "Order",
      "107-7239124-5739409",
      "1002535606",
      "The Official SAT Study Guide Second Edition",
      "1",
      "amazon.com",
      "Amazon",
      "GARDEN GROVE",
      "CA",
      "92844-1736",
      "",
      "9.99",
      "0",
      "1.49",
      "0",
      "0",
      "0",
      "-1.49",
      "0",
      "0",
      "-2.85",
      "-2.74",
      "0",
      "0",
      "4.40"
    ],
    [
      "Aug 27, 2015 1:15:39 PM PDT",
      "5958684411",
      "Order",
      "116-7260450-1899453",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "MOUNTAIN VIEW",
      "CA",
      "94043-3331",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 27, 2015 3:39:50 PM PDT",
      "5958684411",
      "Refund",
      "116-8183205-0029009",
      "1002517053",
      "Wacky Wednesday (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "MOKENA",
      "IL",
      "60448-1405",
      "",
      "-7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.55",
      "0",
      "0",
      "0",
      "-5.44"
    ],
    [
      "Aug 27, 2015 7:31:34 PM PDT",
      "5958684411",
      "Order",
      "109-3438935-8621866",
      "1002564837",
      "In the Small, Small Pond",
      "1",
      "amazon.com",
      "Amazon",
      "RESTON",
      "VA",
      "20191-3605",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 28, 2015 2:15:47 AM PDT",
      "5958684411",
      "Order",
      "111-0498087-0300223",
      "1002565330",
      "The King's Chessboard (Picture Puffins)",
      "1",
      "amazon.com",
      "Amazon",
      "EASLEY",
      "SC",
      "29640-3011",
      "",
      "6.49",
      "0",
      "1.15",
      "0",
      "0",
      "0",
      "-1.15",
      "0",
      "0",
      "-2.32",
      "-1.54",
      "0",
      "0",
      "2.63"
    ],
    [
      "Aug 28, 2015 12:52:48 PM PDT",
      "5958684411",
      "Order",
      "114-2553108-5326652",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "DUXBURY",
      "MA",
      "02332",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Aug 28, 2015 3:13:17 PM PDT",
      "5958684411",
      "Order",
      "111-6788521-2732245",
      "1002517033",
      "Ten Apples Up on Top!",
      "1",
      "amazon.com",
      "Amazon",
      "HERRIMAN",
      "UT",
      "84096-6969",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Aug 28, 2015 7:14:20 PM PDT",
      "5958684411",
      "Order",
      "105-4509127-8503407",
      "1002470103",
      "Balzac and the Little Chinese Seamstress: A Novel",
      "1",
      "amazon.com",
      "Amazon",
      "BALTIMORE",
      "MD",
      "21218-2008",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Aug 28, 2015 9:34:18 PM PDT",
      "5958684411",
      "Adjustment",
      "103-2298287-3817854",
      "1002600980",
      "FBA Inventory Reimbursement - Customer Return",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "6.33",
      "6.33"
    ],
    [
      "Aug 29, 2015 12:20:34 AM PDT",
      "5958684411",
      "Order",
      "102-1416824-7651445",
      "1002683146",
      "E-Z Anatomy and Physiology (Barron's E-Z Series)",
      "1",
      "amazon.com",
      "Amazon",
      "Huntington Beach",
      "CA",
      "92648-6627",
      "",
      "12.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-7.31",
      "0",
      "0",
      "7.36"
    ],
    [
      "Aug 29, 2015 11:14:16 AM PDT",
      "5958684411",
      "Adjustment",
      "",
      "0I-3CKH-8D0X",
      "FBA Inventory Reimbursement - Damaged:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.67",
      "0.67"
    ],
    [
      "Aug 29, 2015 1:39:37 PM PDT",
      "5958684411",
      "Adjustment",
      "",
      "0I-3CKH-8D0X",
      "FBA Inventory Reimbursement - Damaged:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.67",
      "0.67"
    ],
    [
      "Aug 29, 2015 2:56:49 PM PDT",
      "5958684411",
      "Order",
      "104-2600118-9057020",
      "1002361202",
      "Wilton Armetale Gourmet Grillware Tray, Small, Rectangular, 8-1/4-Inch by 10-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "BUFFALO",
      "NEW YORK",
      "14216-3315",
      "",
      "47.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.05",
      "-4.02",
      "0",
      "0",
      "35.93"
    ],
    [
      "Aug 29, 2015 3:16:50 PM PDT",
      "5958684411",
      "Order",
      "114-6210868-6079435",
      "7Z-0Y0K-MOAU",
      "DHEA Breakthrough",
      "1",
      "amazon.com",
      "Amazon",
      "Locust Valley",
      "NY",
      "11560-1807",
      "",
      "1.58",
      "0",
      "1.27",
      "0",
      "0",
      "0",
      "-1.27",
      "0",
      "0",
      "-1.59",
      "-1.92",
      "0",
      "0",
      "-1.93"
    ],
    [
      "Aug 29, 2015 4:40:53 PM PDT",
      "5966642711",
      "Order",
      "114-5495475-5025818",
      "26-YQPN-HQ1I",
      "The Associated Press Style Book And Libel Manual: With Appendixes On Photo Captions, Filing The Wire",
      "1",
      "amazon.com",
      "Amazon",
      "HUMBLE",
      "TX",
      "77346-2735",
      "",
      "0.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-1.92",
      "0",
      "0",
      "-2.43"
    ],
    [
      "Aug 30, 2015 2:13:10 AM PDT",
      "5966642711",
      "Order",
      "109-7008507-4321852",
      "1002731256",
      "Wagner 0529010 Flexio 590 Indoor/Outdoor Hand-held Sprayer Kit",
      "1",
      "amazon.com",
      "Amazon",
      "LONDON",
      "OH",
      "43140-1456",
      "",
      "99.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-15.00",
      "-7.14",
      "0",
      "0",
      "77.85"
    ],
    [
      "Aug 30, 2015 2:24:10 AM PDT",
      "5966642711",
      "Order",
      "113-4814124-9381038",
      "1002537843",
      "The web files",
      "1",
      "amazon.com",
      "Amazon",
      "PROSPERITY",
      "SC",
      "29127-7709",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Aug 30, 2015 2:47:03 PM PDT",
      "5966642711",
      "Order",
      "104-1988155-5367444",
      "1002491118",
      "The Ear Book (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "SAN TAN VALLEY",
      "AZ",
      "85143-4585",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Aug 31, 2015 12:49:30 PM PDT",
      "5966642711",
      "Order",
      "103-3948339-5537025",
      "1002486133",
      "Warm Bodies: A Novel (The Warm Bodies Series)",
      "1",
      "amazon.com",
      "Amazon",
      "Wahiawa",
      "Hi",
      "96786",
      "",
      "11.50",
      "0",
      "1.93",
      "0",
      "0",
      "0",
      "-1.93",
      "0",
      "0",
      "-3.08",
      "-1.67",
      "0",
      "0",
      "6.75"
    ],
    [
      "Aug 31, 2015 3:29:14 PM PDT",
      "5966642711",
      "Order",
      "111-6993419-9147421",
      "1002641871",
      "The Dragonriders of Pern: Dragonflight, Dragonquest, and The White Dragon (Pern: The Dragonriders of Pern)",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "TX",
      "76010-1908",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Aug 31, 2015 6:17:00 PM PDT",
      "5966642711",
      "Order",
      "104-6911052-6545829",
      "7E-BU6I-D0OJ",
      "Harvest of Violence: The Maya Indians and the Guatemalan Crisis",
      "1",
      "amazon.com",
      "Amazon",
      "EL PASO",
      "TX",
      "79912-6511",
      "",
      "0.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-1.92",
      "0",
      "0",
      "-2.43"
    ],
    [
      "Aug 31, 2015 8:29:45 PM PDT",
      "5966642711",
      "Order",
      "113-5534101-1846651",
      "1002368504",
      "Utilitarianism (Barnes & Noble Library of Essential Reading)",
      "1",
      "amazon.com",
      "Amazon",
      "Saint Gabriel",
      "LA",
      "70776",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Aug 31, 2015 11:13:59 PM PDT",
      "5966642711",
      "Order",
      "111-5561492-0759422",
      "1002663368",
      "Digz Women's Longs Cuff Grip Gloves (Medium)",
      "1",
      "amazon.com",
      "Amazon",
      "Florence",
      "AZ",
      "85132-7100",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Sep 1, 2015 11:43:12 PM PDT",
      "5966642711",
      "Order",
      "111-4702885-0941035",
      "1002603337",
      "The Vile Village (A Series of Unfortunate Events, No. 7)",
      "1",
      "amazon.com",
      "Amazon",
      "BOTHELL",
      "WA",
      "98012-5409",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Sep 2, 2015 12:59:49 PM PDT",
      "5966642711",
      "Order",
      "111-5005951-8834660",
      "1002600983",
      "The Boy of a Thousand Faces",
      "1",
      "amazon.com",
      "Amazon",
      "LACKAWANNA",
      "NY",
      "14218-3105",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Sep 2, 2015 3:14:06 PM PDT",
      "5966642711",
      "Refund",
      "104-2600118-9057020",
      "1002361202",
      "Wilton Armetale Gourmet Grillware Tray, Small, Rectangular, 8-1/4-Inch by 10-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "BUFFALO",
      "NEW YORK",
      "14216-3315",
      "",
      "-47.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "5.64",
      "0",
      "0",
      "0",
      "-41.36"
    ],
    [
      "Sep 3, 2015 2:10:49 AM PDT",
      "5966642711",
      "Order",
      "002-3707330-6202666",
      "1002663927",
      "Pecos Bill",
      "1",
      "amazon.com",
      "Amazon",
      "ALEXANDRIA",
      "LA",
      "71302-9634",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 3, 2015 8:09:28 AM PDT",
      "5966642711",
      "Order",
      "002-3928236-1786617",
      "1002470329",
      "Little School",
      "1",
      "amazon.com",
      "Amazon",
      "Oxnard",
      "CA",
      "93031",
      "",
      "49.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "-3.99",
      "0",
      "0",
      "-8.85",
      "-1.54",
      "0",
      "0",
      "39.60"
    ],
    [
      "Sep 3, 2015 8:48:49 AM PDT",
      "5966642711",
      "Order",
      "111-4724197-8094647",
      "1002469697",
      "Bill Nye the Science Guy's Big Blue Ocean",
      "1",
      "amazon.com",
      "Amazon",
      "Garretson",
      "SD",
      "57030",
      "",
      "9.99",
      "0",
      "1.67",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 3, 2015 12:43:12 PM PDT",
      "5966642711",
      "Order",
      "111-5864942-6162620",
      "1002555843",
      "Happily Ever After",
      "1",
      "amazon.com",
      "Amazon",
      "SAN FRANCISCO",
      "CA",
      "94102-7702",
      "",
      "7.97",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-1.74",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.88"
    ],
    [
      "Sep 3, 2015 11:23:22 PM PDT",
      "5966642711",
      "Order",
      "116-4820932-5461055",
      "8R-V6X6-ZJOC",
      "Mid-Course Correction: Toward a Sustainable Enterprise: The Interface Model",
      "1",
      "amazon.com",
      "Amazon",
      "MANASSAS",
      "VA",
      "20110-4555",
      "",
      "10.96",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.99",
      "-1.67",
      "0",
      "0",
      "6.30"
    ],
    [
      "Sep 4, 2015 12:04:15 AM PDT",
      "5966642711",
      "Order",
      "102-7417281-2298632",
      "1002663869",
      "Across the Wide Dark Sea: The Mayflower Journey",
      "1",
      "amazon.com",
      "Amazon",
      "Lafayette",
      "CO",
      "80026-9055",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 4, 2015 2:17:05 AM PDT",
      "5966642711",
      "Order",
      "106-1505656-5617825",
      "1002427883",
      "Great Day for Up (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "SPARTA",
      "TN",
      "38583-7719",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Sep 4, 2015 2:20:46 AM PDT",
      "5966642711",
      "Order",
      "111-1193442-6928251",
      "1002451247",
      "My Two Book (My First Steps to Math, My Two Book)",
      "1",
      "amazon.com",
      "Amazon",
      "SAVANNAH",
      "GA",
      "31406-8903",
      "",
      "9.99",
      "0",
      "1.08",
      "0",
      "0",
      "0",
      "-1.08",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 4, 2015 3:36:48 AM PDT",
      "5966642711",
      "Order",
      "113-6686875-5357868",
      "1002470257",
      "Trouble with Mister",
      "1",
      "amazon.com",
      "Amazon",
      "OMAHA",
      "NE",
      "68134-5836",
      "",
      "5.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 4, 2015 12:43:20 PM PDT",
      "5966642711",
      "Order",
      "105-1588217-7009829",
      "6E-IGZD-WX75",
      "Storms of My Grandchildren: The Truth About the Coming Climate Catastrophe and Our Last Chance to Save Humanity",
      "1",
      "amazon.com",
      "Amazon",
      "CAMBRIDGE",
      "MA",
      "02138-2901",
      "",
      "8.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.36"
    ],
    [
      "Sep 4, 2015 1:04:27 PM PDT",
      "5966642711",
      "Order",
      "113-5594964-6416243",
      "5T-QNLP-N9DN",
      "Pocket Puzzle Word Find Game (Large Print)",
      "1",
      "amazon.com",
      "Amazon",
      "HONOLULU",
      "HI",
      "96820-0080",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.45"
    ],
    [
      "Sep 4, 2015 3:24:06 PM PDT",
      "5966642711",
      "Order",
      "116-2771464-4195425",
      "1002555856",
      "The Warthog's Tail",
      "1",
      "amazon.com",
      "Amazon",
      "AUSTIN",
      "TX",
      "78717-4696",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 5, 2015 12:47:44 AM PDT",
      "5966642711",
      "Order",
      "116-3631705-2599461",
      "1002820356",
      "Dimple Self-chilling Beer Glasses | Magnetic Freezer Insert Keeps Glass & Drinks Cold | Silicone Insulated Hand-grip, Hand-blown Glass & Stainless Ste",
      "1",
      "amazon.com",
      "Amazon",
      "STONEHAM",
      "MA",
      "02180-3602",
      "",
      "46.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.05",
      "-4.02",
      "0",
      "0",
      "35.92"
    ],
    [
      "Sep 5, 2015 2:23:01 AM PDT",
      "5966642711",
      "Order",
      "114-7135295-7987463",
      "1002517307",
      "Just the Way You Are (Sesame Street (Dalmatian Press))",
      "1",
      "amazon.com",
      "Amazon",
      "Jackson",
      "TN",
      "38305",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Sep 5, 2015 3:27:05 PM PDT",
      "5966642711",
      "Order",
      "111-3136185-4821005",
      "1002564919",
      "Piper",
      "1",
      "amazon.com",
      "Amazon",
      "FLINT",
      "MI",
      "48502-2030",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 6, 2015 3:09:49 PM PDT",
      "5966642711",
      "Order",
      "110-6221075-3093052",
      "1002603094",
      "The Dragonology Handbook: A Practical Course in Dragons (Ologies)",
      "1",
      "amazon.com",
      "Amazon",
      "KILGORE",
      "TX",
      "75662-7062",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Sep 6, 2015 7:09:40 PM PDT",
      "5966642711",
      "Order",
      "107-2996674-9043464",
      "1002663758",
      "Sunflower House",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT LOUIS",
      "MO",
      "63118-4308",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 6, 2015 8:07:24 PM PDT",
      "5966642711",
      "Order",
      "002-9028856-7745018",
      "1002428544",
      "And Here's to You!",
      "1",
      "amazon.com",
      "Amazon",
      "GLENWOOD SPRINGS",
      "CO",
      "81601-3938",
      "",
      "9.99",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-4.24",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 6, 2015 8:15:45 PM PDT",
      "5966642711",
      "Order",
      "112-6882890-1570609",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Fall City",
      "WA",
      "98024-8733",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 6, 2015 8:20:56 PM PDT",
      "5966642711",
      "Order",
      "116-0367484-4645816",
      "1002603300",
      "Handbook for Hot Witches: Dame Darcy's Illustrated Guide to Magic, Love, and Creativity",
      "1",
      "amazon.com",
      "Amazon",
      "HUNTINGTON BEACH",
      "CA",
      "92649",
      "",
      "16.99",
      "0",
      "2.86",
      "0",
      "0",
      "0",
      "-2.86",
      "0",
      "0",
      "-3.90",
      "-1.92",
      "0",
      "0",
      "11.17"
    ],
    [
      "Sep 7, 2015 3:21:44 AM PDT",
      "5966642711",
      "Order",
      "105-9926970-2392223",
      "7Z-YTH1-MO80",
      "Americas: The Changing Face of Latin America and the Caribbean",
      "1",
      "amazon.com",
      "Amazon",
      "Cambridge",
      "Massachusetts",
      "02138",
      "",
      "10.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-2.33",
      "0",
      "0",
      "5.65"
    ],
    [
      "Sep 7, 2015 8:01:31 AM PDT",
      "5966642711",
      "Order",
      "107-2577451-8217000",
      "1002664013",
      "Cendrillon : A Caribbean Cinderella",
      "1",
      "amazon.com",
      "Amazon",
      "Pine Bush",
      "NY",
      "12566",
      "",
      "5.99",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 7, 2015 2:37:05 PM PDT",
      "5966642711",
      "Order",
      "110-1825824-8967448",
      "1002487639",
      "Night Creatures (Enchanted World)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW ORLEANS",
      "LA",
      "70130-4612",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 7, 2015 8:51:02 PM PDT",
      "5966642711",
      "Order",
      "113-4453520-3446656",
      "1002715413",
      "Germany: A New History",
      "1",
      "amazon.com",
      "Amazon",
      "PROVO",
      "UT",
      "84604-2597",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 8, 2015 5:03:47 AM PDT",
      "5966642711",
      "Order",
      "114-5622881-2275457",
      "1002622658",
      "Wagner 0529002 Paint Ready Sprayer",
      "1",
      "amazon.com",
      "Amazon",
      "hamilton",
      "ohio",
      "45011",
      "",
      "69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.50",
      "-8.37",
      "0",
      "0",
      "51.12"
    ],
    [
      "Sep 8, 2015 7:21:32 PM PDT",
      "5966642711",
      "Order",
      "116-6745688-1377022",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "DALLAS",
      "TEXAS",
      "75209-5003",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Sep 8, 2015 7:28:13 PM PDT",
      "5966642711",
      "Order",
      "002-3236335-7264218",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "Park City",
      "UT",
      "84060-7379",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.50",
      "-1.67",
      "0",
      "0",
      "15.82"
    ],
    [
      "Sep 8, 2015 7:28:13 PM PDT",
      "5966642711",
      "Order",
      "002-3236335-7264218",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "Park City",
      "UT",
      "84060-7379",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "22.32"
    ],
    [
      "Sep 8, 2015 11:25:54 PM PDT",
      "5966642711",
      "Order",
      "114-6854788-8617809",
      "1002731327",
      "Makita GA4030K 4-Inch Angle Grinder",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ALAMOS",
      "NM",
      "87544-3406",
      "",
      "33.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.08",
      "-6.36",
      "0",
      "0",
      "23.55"
    ],
    [
      "Sep 8, 2015 11:28:57 PM PDT",
      "5966642711",
      "Order",
      "106-3627889-2030603",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "FALL CITY",
      "WA",
      "98024-9307",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 8, 2015 11:37:13 PM PDT",
      "5966642711",
      "Order",
      "002-4550781-8711445",
      "1002517053",
      "Wacky Wednesday (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "MUKILTEO",
      "WA",
      "98275-4905",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Sep 9, 2015 12:19:12 AM PDT",
      "5966642711",
      "Order",
      "115-0229435-0212238",
      "1002436887",
      "The Black and White Rainbow",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CA",
      "95125-2095",
      "",
      "9.99",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 9, 2015 2:19:25 AM PDT",
      "5966642711",
      "Order",
      "107-4507928-7499445",
      "1002575403",
      "This Is the Way",
      "1",
      "amazon.com",
      "Amazon",
      "NEWTOWN SQUARE",
      "PA",
      "19073-3961",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Sep 9, 2015 4:45:51 AM PDT",
      "5966642711",
      "Order",
      "113-6024978-5251430",
      "UM-HEX2-WTFX",
      "Hooper Humperdink...? Not Him! (Bright and Early Book, #22)",
      "1",
      "amazon.com",
      "Amazon",
      "Costa Mesa",
      "CA",
      "92627",
      "",
      "2.99",
      "0",
      "2.70",
      "0",
      "0",
      "0",
      "-2.70",
      "0",
      "0",
      "-1.80",
      "-1.54",
      "0",
      "0",
      "-0.35"
    ],
    [
      "Sep 9, 2015 7:08:24 AM PDT",
      "5966642711",
      "Order",
      "103-0342798-5185007",
      "1002603069",
      "Babies Are a Bit of Heaven",
      "1",
      "amazon.com",
      "Amazon",
      "DAVIS",
      "CA",
      "95616-7503",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Sep 9, 2015 11:24:10 AM PDT",
      "5966642711",
      "Order",
      "112-0513362-7892220",
      "1002605068",
      "WowWee Alive 11 Inch Tall Baby Animal Pet Plush with Movement and Sounds - PANDA CUB with Moving Mouth, Soft Fur and Blinking Eyes Plus Adoption Certi",
      "1",
      "amazon.com",
      "Amazon",
      "LA MESA",
      "CA",
      "91941-5659",
      "",
      "129.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-19.50",
      "-7.98",
      "0",
      "0",
      "102.51"
    ],
    [
      "Sep 9, 2015 12:45:07 PM PDT",
      "5966642711",
      "Order",
      "107-2555384-5009047",
      "1002491128",
      "And to Think That I Saw It on Mulberry Street",
      "1",
      "amazon.com",
      "Amazon",
      "GLEN HEAD",
      "NY",
      "11545-2901",
      "",
      "11.62",
      "0",
      "1.89",
      "0",
      "0",
      "0",
      "-1.89",
      "0",
      "0",
      "-3.09",
      "-1.54",
      "0",
      "0",
      "6.99"
    ],
    [
      "Sep 9, 2015 12:46:20 PM PDT",
      "5966642711",
      "Order",
      "102-3988421-1714611",
      "1002486059",
      "The Story of Doctor Dolittle",
      "1",
      "amazon.com",
      "Amazon",
      "Kentland",
      "IN",
      "47951",
      "",
      "5.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 9, 2015 3:20:20 PM PDT",
      "5966642711",
      "Order",
      "108-2400638-0171431",
      "1002470205",
      "The Tale of Despereaux: Being the Story of a Mouse, a Princess, Some Soup and a Spool of Thread",
      "1",
      "amazon.com",
      "Amazon",
      "KIRKWOOD",
      "MO",
      "63122-4160",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.67",
      "0",
      "0",
      "1.22"
    ],
    [
      "Sep 9, 2015 4:44:28 PM PDT",
      "5966642711",
      "Order",
      "104-5375557-9334640",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Steamboat Springs",
      "CO",
      "80487",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-3.63",
      "0",
      "0",
      "17.61"
    ],
    [
      "Sep 9, 2015 5:57:29 PM PDT",
      "5966642711",
      "Order",
      "113-3954828-2022643",
      "C4-GF61-I9MY",
      "Natural Speaker, The (6th Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "sylmar",
      "california",
      "91342",
      "",
      "10.50",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.93",
      "-1.54",
      "0",
      "0",
      "6.03"
    ],
    [
      "Sep 9, 2015 6:37:11 PM PDT",
      "5966642711",
      "Order",
      "112-9747300-3297017",
      "BM-2BOB-S7VJ",
      "Hard Power: The New Politics of National Security",
      "1",
      "amazon.com",
      "Amazon",
      "CHINO HILLS",
      "CA",
      "91709-8781",
      "",
      "1.75",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.61",
      "-1.92",
      "0",
      "0",
      "-1.78"
    ],
    [
      "Sep 9, 2015 7:26:00 PM PDT",
      "5966642711",
      "Order",
      "115-2827952-1621866",
      "1002469705",
      "Disney's Winnie the Pooh's A to ZZzz",
      "1",
      "amazon.com",
      "Amazon",
      "Los Angeles",
      "CA",
      "90049",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 9, 2015 7:31:07 PM PDT",
      "5966642711",
      "Order",
      "002-9547126-4985059",
      "1002663348",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "9",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20020-6117",
      "",
      "89.91",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-13.50",
      "-16.03",
      "0",
      "0",
      "60.38"
    ],
    [
      "Sep 9, 2015 10:28:08 PM PDT",
      "5966642711",
      "Order",
      "115-5111174-4063469",
      "1002715355",
      "The Associated Press Stylebook and Libel Manual",
      "1",
      "amazon.com",
      "Amazon",
      "Faifield",
      "Connecticut",
      "06824",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Sep 9, 2015 11:49:06 PM PDT",
      "5966642711",
      "Order",
      "113-4915288-0094606",
      "1002820315",
      "3 speed USB Turntable vinyl Record Player with Built-in Stereo Speakers and Dust Cover + convert your vinyl collection to digital with included softwa",
      "1",
      "amazon.com",
      "Amazon",
      "Dover",
      "NH",
      "03820-3501",
      "",
      "69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.60",
      "-5.97",
      "0",
      "0",
      "58.42"
    ],
    [
      "Sep 10, 2015 1:37:56 PM PDT",
      "5966642711",
      "Order",
      "104-2926596-8817022",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "RUTLAND",
      "VT",
      "05701-9176",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-3.63",
      "0",
      "0",
      "17.61"
    ],
    [
      "Sep 10, 2015 3:46:08 PM PDT",
      "5966642711",
      "Order",
      "107-0190718-4382652",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "AUSTIN",
      "TEXAS",
      "78745-4104",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 10, 2015 6:28:49 PM PDT",
      "5966642711",
      "Order",
      "102-2463709-2480222",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Los Angeles",
      "CA",
      "90024",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-3.63",
      "0",
      "0",
      "17.61"
    ],
    [
      "Sep 10, 2015 7:08:42 PM PDT",
      "5966642711",
      "Order",
      "103-9435253-7957830",
      "1002551195",
      "Please Try to Remember the First of Octember! (Beginner Books)",
      "1",
      "amazon.com",
      "Amazon",
      "LAKE ISABELLA",
      "MI",
      "48893-9671",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Sep 10, 2015 7:34:06 PM PDT",
      "5966642711",
      "Order",
      "111-5725732-9498605",
      "1002913194",
      "World History in Documents: A Comparative Reader",
      "1",
      "amazon.com",
      "Amazon",
      "ASTORIA",
      "NEW YORK",
      "11106-2144",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-2.33",
      "0",
      "0",
      "9.06"
    ],
    [
      "Sep 10, 2015 11:23:56 PM PDT",
      "5966642711",
      "Order",
      "108-2723793-6477055",
      "1002913187",
      "Successful Manager's Handbook : Development Suggestions for Today's Managers",
      "1",
      "amazon.com",
      "Amazon",
      "FOUNTAIN VALLEY",
      "CA",
      "92708-1375",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-2.74",
      "0",
      "0",
      "7.80"
    ],
    [
      "Sep 10, 2015 11:32:54 PM PDT",
      "5966642711",
      "Order",
      "109-2113986-3321812",
      "1002451574",
      "Jabberwocky",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ALAMOS",
      "NM",
      "87544-2603",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Sep 11, 2015 12:12:35 AM PDT",
      "5966642711",
      "Order",
      "114-0652674-7641052",
      "1002555765",
      "The English Roses",
      "1",
      "amazon.com",
      "Amazon",
      "DENVER",
      "CO",
      "80203-3252",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.92",
      "0",
      "0",
      "2.93"
    ],
    [
      "Sep 11, 2015 2:38:31 AM PDT",
      "5966642711",
      "Order",
      "103-6915394-7416226",
      "1002551164",
      "Lyle, Lyle, Crocodile (Lyle the Crocodile)",
      "1",
      "amazon.com",
      "Amazon",
      "Malvern",
      "PA",
      "19355",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.54",
      "0",
      "0",
      "3.31"
    ],
    [
      "Sep 11, 2015 7:38:55 AM PDT",
      "5966642711",
      "Order",
      "113-1129423-2087428",
      "1002731327",
      "Makita GA4030K 4-Inch Angle Grinder",
      "1",
      "amazon.com",
      "Amazon",
      "MEDLEY",
      "FLORIDA",
      "33198-1044",
      "",
      "33.99",
      "0",
      "8.70",
      "0",
      "0",
      "0",
      "-8.70",
      "0",
      "0",
      "-4.08",
      "-6.36",
      "0",
      "0",
      "23.55"
    ],
    [
      "Sep 12, 2015 1:37:14 AM PDT",
      "5966642711",
      "Order",
      "103-3212284-9063407",
      "1002663368",
      "Digz Women's Longs Cuff Grip Gloves (Medium)",
      "1",
      "amazon.com",
      "Amazon",
      "LEXINGTON",
      "KY",
      "40511-8818",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Sep 12, 2015 2:20:16 AM PDT",
      "5966642711",
      "Order",
      "104-7243060-8576219",
      "1002428582",
      "Chicken Chickens",
      "1",
      "amazon.com",
      "Amazon",
      "WENDELL",
      "NC",
      "27591-7024",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-1.92",
      "0",
      "0",
      "22.22"
    ],
    [
      "Sep 12, 2015 9:43:42 AM PDT",
      "5966642711",
      "Refund",
      "104-2926596-8817022",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "RUTLAND",
      "VT",
      "05701-9176",
      "",
      "-24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3.00",
      "0",
      "0",
      "0",
      "-21.99"
    ],
    [
      "Sep 13, 2015 9:52:04 AM PDT",
      "5975005761",
      "Order",
      "109-5039146-3581051",
      "1002630110",
      "The Puppy Who Wanted a Boy",
      "1",
      "amazon.com",
      "Amazon",
      "Golconda",
      "IL",
      "62938",
      "",
      "13.99",
      "0",
      "2.27",
      "0",
      "0",
      "0",
      "-2.27",
      "0",
      "0",
      "-3.45",
      "-1.54",
      "0",
      "0",
      "9.00"
    ],
    [
      "Sep 13, 2015 3:44:59 PM PDT",
      "5975005761",
      "Order",
      "115-2780109-1101847",
      "1002600950",
      "Where the Buffalo Roam",
      "1",
      "amazon.com",
      "Amazon",
      "yukon",
      "ok",
      "73099",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Sep 13, 2015 5:42:27 PM PDT",
      "5975005761",
      "Order",
      "113-0092219-4278623",
      "1002451537",
      "Halloween (Collector's Edition with CD)",
      "1",
      "amazon.com",
      "Amazon",
      "KALAMAZOO",
      "MI",
      "49007-4740",
      "",
      "18.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-1.92",
      "0",
      "0",
      "12.87"
    ],
    [
      "Sep 13, 2015 11:30:54 PM PDT",
      "5975005761",
      "Order",
      "111-9799629-1518607",
      "1002469897",
      "Ginger and Petunia",
      "1",
      "amazon.com",
      "Amazon",
      "San Mateo",
      "CA",
      "94401-1546",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 14, 2015 12:05:32 AM PDT",
      "5975005761",
      "Order",
      "115-8861384-4645817",
      "1002564756",
      "Supposes",
      "1",
      "amazon.com",
      "Amazon",
      "HILLSBORO",
      "OR",
      "97123-6183",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Sep 14, 2015 2:37:15 AM PDT",
      "5975005761",
      "Order",
      "113-2884321-9444226",
      "1002832870",
      "The Fashion System",
      "1",
      "amazon.com",
      "Amazon",
      "SAVANNAH",
      "GA",
      "31401-9341",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 14, 2015 2:40:55 AM PDT",
      "5975005761",
      "Order",
      "116-6283255-7228231",
      "1002663826",
      "If You Give a Moose a Muffin",
      "1",
      "amazon.com",
      "Amazon",
      "APEX",
      "NC",
      "27502-1317",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 14, 2015 6:22:53 PM PDT",
      "5975005761",
      "Order",
      "105-1084931-2617068",
      "1002555815",
      "How Are You Peeling?",
      "1",
      "amazon.com",
      "Amazon",
      "SPRING LAKE PARK",
      "MN",
      "55432-2738",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 14, 2015 11:33:37 PM PDT",
      "5975005761",
      "Order",
      "104-0699403-5887445",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SAN PEDRO",
      "CA",
      "90731-6433",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-2.67",
      "0",
      "0",
      "18.57"
    ],
    [
      "Sep 15, 2015 1:27:41 PM PDT",
      "5975005761",
      "Order",
      "107-3832788-7535458",
      "1002640919",
      "Data Structures and Other Objects Using Java (3rd Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "Cupertino",
      "CA",
      "95014-5219",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.85",
      "-2.74",
      "0",
      "0",
      "38.40"
    ],
    [
      "Sep 15, 2015 2:21:03 PM PDT",
      "5975005761",
      "Order",
      "114-6822849-0490612",
      "1002584252",
      "Grandfather Tang's Story",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA MARIA",
      "CA",
      "93455-4511",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 15, 2015 3:21:05 PM PDT",
      "5975005761",
      "Order",
      "105-5983435-5789006",
      "1002603034",
      "Disney Fairies Pixie Hollow Tales- Step Into Reading 4 Early Readers",
      "1",
      "amazon.com",
      "Amazon",
      "NORTH KINGSTOWN",
      "RI",
      "02852-6827",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Sep 15, 2015 5:38:14 PM PDT",
      "5975005761",
      "Order",
      "002-2118106-5872213",
      "1002380447",
      "The Longman Anthology of British Literature (The Restoration and the Eighteenth Century)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NEW YORK",
      "10027-8385",
      "",
      "9.25",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.74",
      "-1.92",
      "0",
      "0",
      "4.59"
    ],
    [
      "Sep 15, 2015 7:29:20 PM PDT",
      "5975005761",
      "Order",
      "105-0124363-0777861",
      "1002451379",
      "Bear and Bunny Grow Tomatoes",
      "1",
      "amazon.com",
      "Amazon",
      "Nazareth",
      "PA",
      "18064",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 15, 2015 8:47:48 PM PDT",
      "5975005761",
      "Refund",
      "115-0054591-8454640",
      "1A-7W2H-LA7A",
      "Ridgid 22 In. Pro Organizer, Black Toolbox",
      "1",
      "amazon.com",
      "Amazon",
      "Alexandria",
      "VA",
      "22303",
      "",
      "-39.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "4.80",
      "0",
      "0",
      "0",
      "-35.19"
    ],
    [
      "Sep 15, 2015 11:37:18 PM PDT",
      "5975005761",
      "Order",
      "106-5041841-4833003",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Amherst",
      "MA",
      "01002",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 15, 2015 11:42:55 PM PDT",
      "5975005761",
      "Order",
      "106-4448875-1739465",
      "1002901575",
      "Cracking the SAT Biology E/M Subject Test, 2013-2014 Edition (College Test Preparation)",
      "1",
      "amazon.com",
      "Amazon",
      "Thousand Oaks",
      "CA",
      "91360-1824",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Sep 16, 2015 9:21:20 AM PDT",
      "5975005761",
      "Order",
      "106-5396288-6616261",
      "1002516737",
      "Rainbow Fish and the Big Blue Whale",
      "1",
      "amazon.com",
      "Amazon",
      "NEW FAIRFIELD",
      "CT",
      "06812-4718",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 16, 2015 5:29:09 PM PDT",
      "5975005761",
      "Order",
      "108-1780754-3307422",
      "1002913139",
      "Where Dead Voices Gather",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10023-3201",
      "",
      "24.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-6.90",
      "0",
      "0",
      "17.97"
    ],
    [
      "Sep 16, 2015 7:20:26 PM PDT",
      "5975005761",
      "Order",
      "113-3048980-4177061",
      "1002469729",
      "Can You Find Jesus? Introducing Your Child to the Gospel (Search & Learn Books)",
      "1",
      "amazon.com",
      "Amazon",
      "LAUREL",
      "MD",
      "20707-3621",
      "",
      "11.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.07",
      "-1.92",
      "0",
      "0",
      "6.50"
    ],
    [
      "Sep 16, 2015 7:36:34 PM PDT",
      "5975005761",
      "Order",
      "115-3021200-7537028",
      "1002715391",
      "The Girl with the Dragon Tattoo (Millennium Series)",
      "1",
      "amazon.com",
      "Amazon",
      "Bloomington",
      "Minnesota",
      "55431",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Sep 16, 2015 11:19:04 PM PDT",
      "5975005761",
      "Order",
      "106-7162496-4907417",
      "1002603049",
      "The Legend of Spookley the Square Pumpkin with CD",
      "1",
      "amazon.com",
      "Amazon",
      "Pequot lakes",
      "MInnesota",
      "56472",
      "",
      "4.99",
      "0",
      "1.62",
      "0",
      "0",
      "0",
      "-1.62",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Sep 17, 2015 2:10:34 AM PDT",
      "5975005761",
      "Adjustment",
      "",
      "QP-IYI4-4G91",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "29.39",
      "29.39"
    ],
    [
      "Sep 17, 2015 2:10:37 AM PDT",
      "5975005761",
      "Adjustment",
      "",
      "5M-H559-K1TM",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "19.04",
      "19.04"
    ],
    [
      "Sep 17, 2015 10:25:04 AM PDT",
      "5975005761",
      "Order",
      "116-2469248-4546616",
      "1002360801",
      "Cuisinart CGS-134T 3-Piece Grilling Tool Set with Grill Glove, Teal and Stainless",
      "1",
      "amazon.com",
      "Amazon",
      "englewood",
      "CO",
      "80113",
      "",
      "17.38",
      "0",
      "3.02",
      "0",
      "0",
      "0",
      "-3.02",
      "0",
      "0",
      "-2.61",
      "-6.03",
      "0",
      "0",
      "8.74"
    ],
    [
      "Sep 17, 2015 1:16:56 PM PDT",
      "5975005761",
      "Order",
      "116-8887518-0029012",
      "1002820315",
      "3 speed USB Turntable vinyl Record Player with Built-in Stereo Speakers and Dust Cover + convert your vinyl collection to digital with included softwa",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CA",
      "95118-1743",
      "",
      "69.99",
      "0",
      "16.39",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.60",
      "-22.36",
      "0",
      "0",
      "58.42"
    ],
    [
      "Sep 17, 2015 4:44:25 PM PDT",
      "5975005761",
      "Order",
      "111-5332994-5416263",
      "RD-FIUI-XPQ8",
      "Give Me the World",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT PAUL",
      "MN",
      "55101-2938",
      "",
      "11.47",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.07",
      "-3.91",
      "0",
      "0",
      "6.48"
    ],
    [
      "Sep 17, 2015 6:14:26 PM PDT",
      "5975005761",
      "Refund",
      "104-5375557-9334640",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Steamboat Springs",
      "CO",
      "80487",
      "",
      "-24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3.00",
      "0",
      "0",
      "0",
      "-21.99"
    ],
    [
      "Sep 17, 2015 6:30:12 PM PDT",
      "5975005761",
      "Order",
      "105-2234571-0224214",
      "1002901629",
      "Calculus",
      "1",
      "amazon.com",
      "Amazon",
      "SARASOTA",
      "FL",
      "34238-2602",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-3.56",
      "0",
      "0",
      "16.33"
    ],
    [
      "Sep 17, 2015 7:20:37 PM PDT",
      "5975005761",
      "Order",
      "109-0072840-0261022",
      "1002470130",
      "Ten Apples Up on Top!",
      "1",
      "amazon.com",
      "Amazon",
      "LANSDALE",
      "PA",
      "19446-3982",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Sep 18, 2015 12:39:38 AM PDT",
      "5975005761",
      "Order",
      "111-0004456-5313804",
      "1002469043",
      "Royal Doulton Pure Platinum 43-piece Dinnerware Set, Service for 8",
      "1",
      "amazon.com",
      "Amazon",
      "YORK",
      "MAINE",
      "03909-5118",
      "",
      "499.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-75.00",
      "-28.26",
      "0",
      "0",
      "396.73"
    ],
    [
      "Sep 18, 2015 5:48:37 PM PDT",
      "5975005761",
      "Order",
      "102-8666778-2752217",
      "1002731327",
      "Makita GA4030K 4-Inch Angle Grinder",
      "1",
      "amazon.com",
      "Amazon",
      "MILILANI",
      "HI",
      "96789-4848",
      "",
      "33.99",
      "0",
      "6.35",
      "0",
      "0",
      "0",
      "-6.35",
      "0",
      "0",
      "-4.08",
      "-6.36",
      "0",
      "0",
      "23.55"
    ],
    [
      "Sep 18, 2015 7:22:31 PM PDT",
      "5975005761",
      "Order",
      "105-9889068-2211463",
      "1002427896",
      "From the Doghouse: Poems to Chew On",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60657-5207",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 19, 2015 7:37:54 PM PDT",
      "5975005761",
      "Order",
      "110-5958526-9411443",
      "1002715515",
      "Mary Frank: Encounters",
      "1",
      "amazon.com",
      "Amazon",
      "FALLS CHURCH",
      "VIRGINIA",
      "22042-3847",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-2.33",
      "0",
      "0",
      "9.06"
    ],
    [
      "Sep 19, 2015 11:02:43 PM PDT",
      "5975005761",
      "Order",
      "104-8583892-9317841",
      "1002603305",
      "The Key to the Indian (An Avon Camelot Book)",
      "1",
      "amazon.com",
      "Amazon",
      "WOODBURY",
      "MN",
      "55129-2506",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Sep 19, 2015 11:52:35 PM PDT",
      "5975005761",
      "Order",
      "110-8659280-0609021",
      "1002603137",
      "Zathura The Movie Deluxe Storybook",
      "1",
      "amazon.com",
      "Amazon",
      "GLADSTONE",
      "MISSOURI",
      "64118-2633",
      "",
      "4.99",
      "0",
      "2.08",
      "0",
      "0",
      "0",
      "-2.08",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Sep 20, 2015 7:49:09 AM PDT",
      "5975005761",
      "Order",
      "106-7078235-6539444",
      "1002663934",
      "Leonardo da Vinci",
      "1",
      "amazon.com",
      "Amazon",
      "Ithaca",
      "NY",
      "14850-9582",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Sep 20, 2015 10:44:39 AM PDT",
      "5975005761",
      "Order",
      "109-2284164-9285046",
      "1002469812",
      "The Three Little Wolves and the Big Bad Pig",
      "1",
      "amazon.com",
      "Amazon",
      "WAKEFIELD",
      "RI",
      "02879-2400",
      "",
      "9.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 21, 2015 2:26:25 AM PDT",
      "5975005761",
      "Order",
      "108-4711657-1299430",
      "1002663368",
      "Digz Women's Longs Cuff Grip Gloves (Medium)",
      "2",
      "amazon.com",
      "Amazon",
      "SPRINGFIELD",
      "MASSACHUSETTS",
      "01119-1428",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "3.64"
    ],
    [
      "Sep 21, 2015 10:34:11 AM PDT",
      "5975005761",
      "Order",
      "113-3561828-2401834",
      "1002550915",
      "Pirates of the Caribbean: Dead Man's Chest - Junior Novelization",
      "1",
      "amazon.com",
      "Amazon",
      "CARNEYS POINT",
      "NJ",
      "08069-2227",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.89"
    ],
    [
      "Sep 21, 2015 3:26:28 PM PDT",
      "5975005761",
      "Adjustment",
      "",
      "1002427838",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "8.96",
      "8.96"
    ],
    [
      "Sep 21, 2015 6:38:30 PM PDT",
      "5975005761",
      "Refund",
      "104-8583892-9317841",
      "1002603305",
      "The Key to the Indian (An Avon Camelot Book)",
      "1",
      "amazon.com",
      "Amazon",
      "WOODBURY",
      "MN",
      "55129-2506",
      "",
      "-6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.29",
      "0",
      "0",
      "0",
      "-4.00"
    ],
    [
      "Sep 21, 2015 7:52:15 PM PDT",
      "5975005761",
      "Order",
      "110-0046880-7520279",
      "1002451408",
      "I Spy Fantasy: A Book of Picture Riddles",
      "1",
      "amazon.com",
      "Amazon",
      "New Castle",
      "DE",
      "19720",
      "",
      "9.99",
      "0",
      "1.54",
      "0",
      "0",
      "0",
      "-1.54",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Sep 22, 2015 11:39:08 PM PDT",
      "5975005761",
      "Order",
      "116-4306974-2662607",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "Columbus",
      "Nebraska",
      "68601",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.50",
      "-1.67",
      "0",
      "0",
      "15.82"
    ],
    [
      "Sep 22, 2015 11:39:08 PM PDT",
      "5975005761",
      "Order",
      "116-4306974-2662607",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "Columbus",
      "Nebraska",
      "68601",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "22.32"
    ],
    [
      "Sep 22, 2015 11:39:43 PM PDT",
      "5975005761",
      "Order",
      "103-8114697-2696224",
      "1002551169",
      "Dude Diary 3.0 Write Stuff, Draw Random Things, Destroy If Needed",
      "1",
      "amazon.com",
      "Amazon",
      "Escondido",
      "Ca",
      "92025",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Sep 22, 2015 11:40:28 PM PDT",
      "5975005761",
      "Order",
      "111-1642852-0701807",
      "1002584105",
      "EyeLike Numbers",
      "1",
      "amazon.com",
      "Amazon",
      "Carmichael",
      "CA",
      "95608",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Sep 23, 2015 10:36:43 PM PDT",
      "5975005761",
      "Order",
      "104-0329731-3872254",
      "1002410672",
      "The Eye of the Elephant: An Epic Adventure in the African Wilderness",
      "1",
      "amazon.com",
      "Amazon",
      "Westfield",
      "New Jersey",
      "07090",
      "",
      "15.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-1.92",
      "0",
      "0",
      "10.32"
    ],
    [
      "Sep 24, 2015 7:18:26 PM PDT",
      "5975005761",
      "Order",
      "107-0203302-1929818",
      "1002715530",
      "The American Dream: The 50s (Our American Century)",
      "1",
      "amazon.com",
      "Amazon",
      "SCHENECTADY",
      "NY",
      "12302-4714",
      "",
      "14.99",
      "0",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-7.32",
      "0",
      "0",
      "9.06"
    ],
    [
      "Sep 24, 2015 7:20:05 PM PDT",
      "5975005761",
      "Order",
      "102-2425678-3040238",
      "1002565351",
      "I Hate English! (A Blue Ribbon Book)",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11215-5473",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Sep 24, 2015 7:24:59 PM PDT",
      "5975005761",
      "Order",
      "116-2028847-5343415",
      "1002641993",
      "The Wall Street Journal Guide to Wine New and Improved",
      "1",
      "amazon.com",
      "Amazon",
      "University Park",
      "Maryland",
      "20782",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.92",
      "0",
      "0",
      "2.93"
    ],
    [
      "Sep 24, 2015 11:14:55 PM PDT",
      "5975005761",
      "Order",
      "108-0312883-4964234",
      "5T-QNLP-N9DN",
      "Pocket Puzzle Word Find Game (Large Print)",
      "1",
      "amazon.com",
      "Amazon",
      "BIRMINGHAM",
      "AL",
      "35211-1724",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.45"
    ],
    [
      "Sep 25, 2015 1:08:45 PM PDT",
      "5975005761",
      "Refund",
      "107-0203302-1929818",
      "1002715530",
      "The American Dream: The 50s (Our American Century)",
      "1",
      "amazon.com",
      "Amazon",
      "SCHENECTADY",
      "NY",
      "12302-4714",
      "",
      "0",
      "0",
      "-4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "4.99",
      "0",
      "0",
      "0"
    ],
    [
      "Sep 25, 2015 7:41:28 PM PDT",
      "5975005761",
      "Order",
      "107-1974755-0252206",
      "1002901596",
      "Mattel Uno Deluxe 43427",
      "1",
      "amazon.com",
      "Amazon",
      "Wellington",
      "FL",
      "33449",
      "",
      "18.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-3.63",
      "0",
      "0",
      "12.51"
    ],
    [
      "Sep 26, 2015 2:30:51 AM PDT",
      "5975005761",
      "Order",
      "112-7509417-9876253",
      "1002556020",
      "What about Daisy?",
      "1",
      "amazon.com",
      "Amazon",
      "LEXINGTON",
      "NC",
      "27292-8527",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Sep 26, 2015 3:20:51 PM PDT",
      "5975005761",
      "Order",
      "111-9459132-7021865",
      "1002901287",
      "Food Politics: How the Food Industry Influences Nutrition, and Health, Revised and Expanded Edition (California Studies in Food and Culture)",
      "1",
      "amazon.com",
      "Amazon",
      "UNION CITY",
      "CALIFORNIA",
      "94587-4911",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 26, 2015 8:35:49 PM PDT",
      "5982839951",
      "Order",
      "107-4539499-8360255",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "CHESTERTOWN",
      "MD",
      "21620-2383",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 27, 2015 4:37:57 AM PDT",
      "5982839951",
      "Order",
      "002-0434821-9601863",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "PHOENIX",
      "AZ",
      "85023-6305",
      "",
      "9.99",
      "0",
      "3.14",
      "0",
      "0",
      "0",
      "-3.14",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Sep 27, 2015 7:29:36 PM PDT",
      "5982839951",
      "Order",
      "105-5868258-9293033",
      "1002451252",
      "It's Christmas, David!",
      "1",
      "amazon.com",
      "Amazon",
      "maspeth",
      "NY",
      "11378",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Sep 27, 2015 7:54:21 PM PDT",
      "5982839951",
      "Order",
      "109-5527129-2014639",
      "6I-IIIQ-GJ8Q",
      "Starcraft Expansion Set: Brood War (Prima's Official Strategy Guide)",
      "1",
      "amazon.com",
      "Amazon",
      "CREST HILL",
      "IL",
      "60403-2584",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Sep 28, 2015 12:46:56 PM PDT",
      "5982839951",
      "Order",
      "112-5919516-0565031",
      "H8-Q28U-TY0M",
      "Marvel Avengers Assemble 50 Piece Tower Puzzle (3 assorted puzzles, Designs Vary)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10128-4087",
      "",
      "8.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.27",
      "-3.63",
      "0",
      "0",
      "3.59"
    ],
    [
      "Sep 28, 2015 5:06:41 PM PDT",
      "5982839951",
      "Refund",
      "114-5622881-2275457",
      "1002622658",
      "Wagner 0529002 Paint Ready Sprayer",
      "1",
      "amazon.com",
      "Amazon",
      "hamilton",
      "ohio",
      "45011",
      "",
      "-69.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "8.40",
      "0",
      "0",
      "0",
      "-61.59"
    ],
    [
      "Sep 28, 2015 11:40:16 PM PDT",
      "5982839951",
      "Order",
      "114-6655103-4585854",
      "1002427813",
      "Water Come Down (Day You Were Baptized)",
      "1",
      "amazon.com",
      "Amazon",
      "BIG PINE",
      "CA",
      "93513-2006",
      "",
      "10.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.92",
      "-1.54",
      "0",
      "0",
      "6.03"
    ],
    [
      "Sep 29, 2015 12:24:09 AM PDT",
      "5982839951",
      "Order",
      "111-3177596-1541809",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Mill Valley",
      "CA",
      "94941",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-3.63",
      "0",
      "0",
      "17.61"
    ],
    [
      "Sep 29, 2015 2:15:59 AM PDT",
      "5982839951",
      "Order",
      "108-3108847-1845826",
      "1002469733",
      "Can You Find It, Too?: Search and Discover More Than 150 Details in 20 Works of Art",
      "1",
      "amazon.com",
      "Amazon",
      "NASHVILLE",
      "TN",
      "37209-4742",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Sep 29, 2015 2:47:09 PM PDT",
      "5982839951",
      "Refund",
      "110-0810797-4711429",
      "1002400216",
      "Print Reading for Construction: Residential and Commercial Set",
      "1",
      "amazon.com",
      "Amazon",
      "AVONDALE",
      "ARIZONA",
      "85392-6578",
      "",
      "-19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "4.35",
      "0",
      "0",
      "0",
      "-15.64"
    ],
    [
      "Sep 29, 2015 6:27:17 PM PDT",
      "5982839951",
      "Order",
      "106-7018792-8693858",
      "1002491065",
      "There's a Wocket in My Pocket! (Dr. Seuss's Book of Ridiculous Rhymes)",
      "1",
      "amazon.com",
      "Amazon",
      "Cardiff-by-the-Sea",
      "CA",
      "92007",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Sep 29, 2015 7:27:36 PM PDT",
      "5982839951",
      "Order",
      "111-1455722-5881865",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "3",
      "amazon.com",
      "Amazon",
      "Englewood",
      "NJ",
      "07631-3736",
      "",
      "14.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.00",
      "-5.01",
      "0",
      "0",
      "4.96"
    ],
    [
      "Sep 29, 2015 7:27:36 PM PDT",
      "5982839951",
      "Order",
      "111-1455722-5881865",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "Englewood",
      "NJ",
      "07631-3736",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.34",
      "0",
      "0",
      "5.64"
    ],
    [
      "Sep 30, 2015 4:46:20 PM PDT",
      "5982839951",
      "Order",
      "103-6329901-6191401",
      "1002516474",
      "Brio Wood Airplane Baby Toy",
      "1",
      "amazon.com",
      "Amazon",
      "Hilmar",
      "CA",
      "95324-9628",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-3.63",
      "0",
      "0",
      "17.61"
    ],
    [
      "Sep 30, 2015 7:13:31 PM PDT",
      "5982839951",
      "Order",
      "002-8209297-6222630",
      "1002451552",
      "My Mama's Little Ranch on the Pampas",
      "1",
      "amazon.com",
      "Amazon",
      "New York",
      "NY",
      "10025",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Sep 30, 2015 10:07:26 PM PDT",
      "5982839951",
      "Order",
      "114-3010666-2541836",
      "H8-Q28U-TY0M",
      "Marvel Avengers Assemble 50 Piece Tower Puzzle (3 assorted puzzles, Designs Vary)",
      "1",
      "amazon.com",
      "Amazon",
      "CASTLE ROCK",
      "CO",
      "80104-7580",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.24",
      "-3.63",
      "0",
      "0",
      "3.42"
    ],
    [
      "Sep 30, 2015 11:59:53 PM PDT",
      "5982839951",
      "Order",
      "116-8551655-6140208",
      "1002584246",
      "Blueberry Girl",
      "1",
      "amazon.com",
      "Amazon",
      "MONTROSE",
      "CO",
      "81401-6353",
      "",
      "13.09",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.31",
      "-1.92",
      "0",
      "0",
      "7.86"
    ],
    [
      "Oct 1, 2015 12:02:33 AM PDT",
      "5982839951",
      "Order",
      "106-9574398-0958643",
      "1002584158",
      "The Soccer Mom from Outer Space",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CALIFORNIA",
      "95130-1844",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Oct 1, 2015 2:28:44 AM PDT",
      "5982839951",
      "Order",
      "102-3390481-5392234",
      "1002564767",
      "Distant Feathers",
      "1",
      "amazon.com",
      "Amazon",
      "ATLANTA",
      "GA",
      "30306-3625",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Oct 1, 2015 2:34:40 AM PDT",
      "5982839951",
      "Order",
      "116-8603698-4825837",
      "1002451881",
      "My Little People School Bus : a Lift-the Flap Playbook",
      "1",
      "amazon.com",
      "Amazon",
      "CHAPEL HILL",
      "NC",
      "27517-8130",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 1, 2015 4:55:53 AM PDT",
      "5982839951",
      "Order",
      "104-9095606-9415438",
      "1002367921",
      "Unguarded Gates: A History of America's Immigration Crisis",
      "1",
      "amazon.com",
      "Amazon",
      "Fountain Valley",
      "CA",
      "92708-1941",
      "",
      "4.25",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.99",
      "-1.92",
      "0",
      "0",
      "0.34"
    ],
    [
      "Oct 1, 2015 3:36:14 PM PDT",
      "5982839951",
      "Order",
      "106-3791098-4043439",
      "1002550984",
      "The Ugly Pumpkin",
      "1",
      "amazon.com",
      "Amazon",
      "PLANO",
      "TEXAS",
      "75025-2631",
      "",
      "5.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.19"
    ],
    [
      "Oct 2, 2015 2:20:57 AM PDT",
      "5982839951",
      "Order",
      "114-1245435-6256243",
      "1002554611",
      "Block Party Today!",
      "1",
      "amazon.com",
      "Amazon",
      "ROSLINDALE",
      "MA",
      "02131-3454",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Oct 2, 2015 11:40:12 AM PDT",
      "5982839951",
      "Order",
      "112-6591380-1672264",
      "1002451098",
      "If Pigs Could Fly: And Other Deep Thoughts (Bruce Lansky's Poems)",
      "1",
      "amazon.com",
      "Amazon",
      "EAST MACHIAS",
      "MAINE",
      "04630-0231",
      "",
      "9.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 2, 2015 3:52:16 PM PDT",
      "5982839951",
      "Order",
      "110-2267841-3764227",
      "1002641882",
      "Baseball on the Brain",
      "1",
      "amazon.com",
      "Amazon",
      "STILLWATER",
      "OK",
      "74075-2611",
      "",
      "6.79",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.37",
      "-1.92",
      "0",
      "0",
      "2.50"
    ],
    [
      "Oct 2, 2015 11:36:53 PM PDT",
      "5982839951",
      "Order",
      "002-6191108-5866666",
      "1002555520",
      "Mirette on the Highwire (Caldecott Medal Book)",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JUAN",
      "PR",
      "00923-2200",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Oct 3, 2015 8:57:24 AM PDT",
      "5982839951",
      "Adjustment",
      "111-6603705-3605008",
      "1002663356",
      "FBA Inventory Reimbursement - Customer Service Issue",
      "2",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "37.14",
      "37.14"
    ],
    [
      "Oct 3, 2015 1:15:55 PM PDT",
      "5982839951",
      "Order",
      "107-3677930-2798621",
      "1002460768",
      "Plasticolor 003692R01 Star Trek Bridge Accordion Bubble Sunshade",
      "1",
      "amazon.com",
      "Amazon",
      "Jupiter",
      "Florida",
      "33458",
      "",
      "17.43",
      "0",
      "3.26",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.09",
      "-9.29",
      "0",
      "0",
      "9.31"
    ],
    [
      "Oct 3, 2015 2:37:05 PM PDT",
      "5982839951",
      "Order",
      "002-2817720-2578656",
      "3G-3HQ8-KN52",
      "\"I Wish I'd Said That!\": How to Talk Your Way Out of Trouble and Into Success",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA MARIA",
      "CALIFORNIA",
      "93454-7295",
      "",
      "30.41",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.91",
      "-1.92",
      "0",
      "0",
      "22.58"
    ],
    [
      "Oct 4, 2015 1:40:36 AM PDT",
      "5982839951",
      "Order",
      "107-6033956-6231418",
      "1002360732",
      "Wedgwood Palladian Oval Platter, 13.75-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10014-2784",
      "",
      "114.26",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-17.14",
      "-3.63",
      "0",
      "0",
      "93.49"
    ],
    [
      "Oct 4, 2015 8:28:30 AM PDT",
      "5982839951",
      "Order",
      "114-5177516-4525050",
      "1002427852",
      "The Little Seahorse",
      "1",
      "amazon.com",
      "Amazon",
      "RIXEYVILLE",
      "VA",
      "22737-2544",
      "",
      "17.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-4.05",
      "-1.92",
      "0",
      "0",
      "12.02"
    ],
    [
      "Oct 4, 2015 11:08:48 AM PDT",
      "5982839951",
      "Adjustment",
      "",
      "1002368524",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.51",
      "0.51"
    ],
    [
      "Oct 4, 2015 11:51:37 PM PDT",
      "5982839951",
      "Order",
      "111-1314935-0824224",
      "1003108006",
      "Great Jobs for Film Majors",
      "1",
      "amazon.com",
      "Amazon",
      "Moore",
      "Oklahoma",
      "73160",
      "",
      "6.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-5.53",
      "0",
      "0",
      "3.05"
    ],
    [
      "Oct 5, 2015 2:33:43 AM PDT",
      "5982839951",
      "Order",
      "113-5123062-4333068",
      "1002555952",
      "When Mama Comes Home Tonight",
      "1",
      "amazon.com",
      "Amazon",
      "INDIANAPOLIS",
      "IN",
      "46226-2190",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Oct 5, 2015 6:17:42 PM PDT",
      "5982839951",
      "Order",
      "115-5654755-3226610",
      "IM-QXL0-GHDK",
      "Scented Bath Salt, 17.6-oz. Jars (Rose Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "Quilcene",
      "WA",
      "98376-9671",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-3.63",
      "0",
      "0",
      "4.86"
    ],
    [
      "Oct 5, 2015 7:26:18 PM PDT",
      "5982839951",
      "Order",
      "109-9086678-7779416",
      "1003108146",
      "Physics for Scientists and Engineers: Extended Version",
      "1",
      "amazon.com",
      "Amazon",
      "ADA",
      "MI",
      "49301-7844",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.85",
      "-4.79",
      "0",
      "0",
      "36.35"
    ],
    [
      "Oct 5, 2015 9:22:40 PM PDT",
      "5982839951",
      "Order",
      "110-7895393-5858642",
      "1003107999",
      "Writing Short Films: Structure and Content for Screenwriters",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90016-2717",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 6, 2015 12:48:56 AM PDT",
      "5982839951",
      "Order",
      "114-2371784-9391429",
      "1002486097",
      "Halloween Party (Fear Street, No. 8)",
      "1",
      "amazon.com",
      "Amazon",
      "FALL RIVER",
      "MA",
      "02721-1338",
      "",
      "6.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-1.54",
      "0",
      "0",
      "2.63"
    ],
    [
      "Oct 6, 2015 10:04:09 AM PDT",
      "5982839951",
      "Order",
      "114-0626716-8278619",
      "UJ-C745-D7S0",
      "Quantum Man: Richard Feynman's Life in Science (Great Discoveries)",
      "1",
      "amazon.com",
      "Amazon",
      "Upton",
      "NY",
      "11973-0445",
      "",
      "8.38",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-2.61",
      "-1.92",
      "0",
      "0",
      "3.85"
    ],
    [
      "Oct 6, 2015 1:41:14 PM PDT",
      "5982839951",
      "Order",
      "104-7662124-8349009",
      "1003108097",
      "Linear Algebra and Its Applications (3rd Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "worcester",
      "Massachusetts",
      "01605",
      "",
      "13.99",
      "0",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-7.32",
      "0",
      "0",
      "8.21"
    ],
    [
      "Oct 6, 2015 9:13:21 PM PDT",
      "5982839951",
      "Adjustment",
      "",
      "1002368499",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.81",
      "1.81"
    ],
    [
      "Oct 6, 2015 10:58:56 PM PDT",
      "5982839951",
      "Adjustment",
      "",
      "1002486035",
      "FBA Inventory Reimbursement - Lost:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.33",
      "1.33"
    ],
    [
      "Oct 6, 2015 11:09:27 PM PDT",
      "5982839951",
      "Order",
      "116-4028924-0187428",
      "1002361208",
      "Wilton Armetale Gourmet Grillware Wok, 10-3/4-Inch by 14-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NEW YORK",
      "11222-4325",
      "",
      "47.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-7.05",
      "-4.41",
      "0",
      "0",
      "35.54"
    ],
    [
      "Oct 6, 2015 11:20:56 PM PDT",
      "5982839951",
      "Order",
      "102-0652773-4477040",
      "1002715503",
      "The Four-Day Win: End Your Diet War and Achieve Thinner Peace",
      "1",
      "amazon.com",
      "Amazon",
      "APTOS",
      "CA",
      "95003-3665",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 7, 2015 1:46:52 AM PDT",
      "5982839951",
      "Order",
      "107-9412595-2025860",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Southaven",
      "MS",
      "38672",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 7, 2015 11:10:38 AM PDT",
      "5982839951",
      "Order",
      "110-7297628-7133014",
      "1002400216",
      "Print Reading for Construction: Residential and Commercial Set",
      "1",
      "amazon.com",
      "Amazon",
      "brooklyn",
      "new york",
      "11222",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-3.15",
      "0",
      "0",
      "12.49"
    ],
    [
      "Oct 7, 2015 1:27:31 PM PDT",
      "5982839951",
      "Order",
      "111-2545482-7857832",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "PLACITAS",
      "NM",
      "87043",
      "",
      "9.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-6.66",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 7, 2015 1:32:36 PM PDT",
      "5982839951",
      "Order",
      "108-6868012-7069864",
      "1003107946",
      "The 50th Law",
      "1",
      "amazon.com",
      "Amazon",
      "VALLEJO",
      "CA",
      "94590-3003",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 7, 2015 3:42:54 PM PDT",
      "5982839951",
      "Order",
      "115-5057822-1949826",
      "1002663356",
      "Digz Garden Gloves Spandex Women Large",
      "1",
      "amazon.com",
      "Amazon",
      "SAGINAW",
      "MI",
      "48603-1676",
      "",
      "24.99",
      "0",
      "1.96",
      "0",
      "0",
      "0",
      "-1.96",
      "0",
      "0",
      "-3.75",
      "-2.67",
      "0",
      "0",
      "18.57"
    ],
    [
      "Oct 7, 2015 4:48:27 PM PDT",
      "5982839951",
      "Order",
      "002-6095026-9474635",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "Austin",
      "tx",
      "78731",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 7, 2015 11:28:42 PM PDT",
      "5982839951",
      "Order",
      "110-2383666-5136254",
      "1002833003",
      "1776",
      "1",
      "amazon.com",
      "Amazon",
      "DECORAH",
      "IOWA",
      "52101-7440",
      "",
      "9.99",
      "0",
      "1.56",
      "0",
      "0",
      "0",
      "-1.56",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 8, 2015 12:18:34 AM PDT",
      "5982839951",
      "Order",
      "114-5203678-7001800",
      "1002715310",
      "Breakthrough Prayer: The Power of Connecting with the Heart of God",
      "1",
      "amazon.com",
      "Amazon",
      "LAUREL",
      "MD",
      "20723-1757",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 8, 2015 1:27:57 PM PDT",
      "5982839951",
      "Order",
      "103-7788770-9491416",
      "F9-6ODS-XRAF",
      "Kids Animal Split Ring - Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "Bossier City",
      "LA",
      "71111-6258",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Oct 8, 2015 3:44:27 PM PDT",
      "5982839951",
      "Order",
      "115-8311667-1639401",
      "1002564792",
      "ALPHA PETS: BRADLEY AND THE GREAT SWAMP MYSTERY (AlphaPets)",
      "1",
      "amazon.com",
      "Amazon",
      "WEST MELBOURNE",
      "FL",
      "32904-7327",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 8, 2015 7:48:46 PM PDT",
      "5982839951",
      "Order",
      "106-2371703-4977819",
      "1002537126",
      "Rosie's Nutcracker Dreams (Ballet Slippers)",
      "1",
      "amazon.com",
      "Amazon",
      "GRAND BLANC",
      "MI",
      "48439-9752",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 9, 2015 1:44:26 AM PDT",
      "5982839951",
      "Order",
      "108-5879031-9047407",
      "1002342682",
      "Theo Klein Bosch Breakfast Toy Playset",
      "1",
      "amazon.com",
      "Amazon",
      "FORT MILL",
      "SC",
      "29708-8939",
      "",
      "55.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.39",
      "-8.37",
      "0",
      "0",
      "39.19"
    ],
    [
      "Oct 9, 2015 4:41:44 PM PDT",
      "5982839951",
      "Order",
      "102-9857181-6431465",
      "1003108141",
      "Timeless Voices, Timeless Themes: World Masterpieces, Student Edition (Prentice Hall Literature)",
      "1",
      "amazon.com",
      "Amazon",
      "MIAMI",
      "FLORIDA",
      "33182-1200",
      "",
      "19.99",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-6.29",
      "0",
      "0",
      "11.67"
    ],
    [
      "Oct 10, 2015 1:28:49 PM PDT",
      "5982839951",
      "Refund",
      "110-7297628-7133014",
      "1002400216",
      "Print Reading for Construction: Residential and Commercial Set",
      "1",
      "amazon.com",
      "Amazon",
      "brooklyn",
      "new york",
      "11222",
      "",
      "-19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "4.35",
      "0",
      "0",
      "0",
      "-15.64"
    ],
    [
      "Oct 10, 2015 2:41:23 PM PDT",
      "5982839951",
      "Order",
      "111-0322631-5592259",
      "7S-4XVW-JGI8",
      "Star Wars: Titanium Imperial Shuttle",
      "1",
      "amazon.com",
      "Amazon",
      "ATTLEBORO",
      "MA",
      "02703-3627",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-2.67",
      "0",
      "0",
      "18.57"
    ],
    [
      "Oct 10, 2015 3:33:11 PM PDT",
      "5982839951",
      "Order",
      "109-1054527-5457857",
      "1003224761",
      "Strong Fathers, Strong Daughters: 10 Secrets Every Father Should Know",
      "1",
      "amazon.com",
      "Amazon",
      "ALBUQUERQUE",
      "NM",
      "87106-2219",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Oct 10, 2015 3:35:31 PM PDT",
      "5982839951",
      "Order",
      "111-3055412-2933857",
      "1003186913",
      "National Geographic Dinosaurs",
      "1",
      "amazon.com",
      "Amazon",
      "EVERETT",
      "WA",
      "98203-1729",
      "",
      "29.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-5.85",
      "-2.33",
      "0",
      "0",
      "21.81"
    ],
    [
      "Oct 10, 2015 3:40:13 PM PDT",
      "5982839951",
      "Order",
      "105-2139340-0422645",
      "1002574663",
      "Estes A816E Mini Heli",
      "1",
      "amazon.com",
      "Amazon",
      "OGDEN",
      "UT",
      "84403-3061",
      "",
      "37.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.70",
      "-2.67",
      "0",
      "0",
      "29.61"
    ],
    [
      "Oct 10, 2015 4:28:53 PM PDT",
      "5990307381",
      "Order",
      "103-3025597-8709861",
      "1002925962",
      "Starfrit Manual Food Processor",
      "1",
      "amazon.com",
      "Amazon",
      "COLORADO SPRINGS",
      "COLORADO",
      "80911-2530",
      "",
      "16.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-4.02",
      "0",
      "0",
      "10.42"
    ],
    [
      "Oct 10, 2015 4:39:21 PM PDT",
      "5990307381",
      "Order",
      "107-2760434-5549013",
      "1002451489",
      "All Families Are Special",
      "1",
      "amazon.com",
      "Amazon",
      "CAMBRIDGE",
      "MA",
      "02138-4351",
      "",
      "10.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Oct 10, 2015 5:48:34 PM PDT",
      "5990307381",
      "Order",
      "113-2498949-2412230",
      "1002360848",
      "Boston Warehouse Earthenware Mason Jar Mug, BBQ Design",
      "1",
      "amazon.com",
      "Amazon",
      "PASADENA",
      "CA",
      "91105-2505",
      "",
      "7.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.12",
      "-2.67",
      "0",
      "0",
      "3.70"
    ],
    [
      "Oct 10, 2015 9:30:27 PM PDT",
      "5990307381",
      "Order",
      "113-5719954-3464265",
      "1002535558",
      "Houghton Mifflin Mathmatics California: Student Edition Level 3 2002",
      "1",
      "amazon.com",
      "Amazon",
      "LAGUNA NIGUEL",
      "CALIFORNIA",
      "92677-2086",
      "",
      "5.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-7.72",
      "0",
      "0",
      "1.00"
    ],
    [
      "Oct 11, 2015 10:45:17 AM PDT",
      "5990307381",
      "Adjustment",
      "",
      "0I-3CKH-8D0X",
      "FBA Inventory Reimbursement - Damaged:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0.77",
      "0.77"
    ],
    [
      "Oct 11, 2015 3:19:48 PM PDT",
      "5990307381",
      "Order",
      "104-1114115-2071464",
      "1002715408",
      "Classy: Exceptional Advice for the Extremely Modern Lady",
      "1",
      "amazon.com",
      "Amazon",
      "WEST CHESTER",
      "PA",
      "19382-6405",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Oct 11, 2015 11:50:23 PM PDT",
      "5990307381",
      "Order",
      "107-6185920-8530643",
      "1002427867",
      "Young Cornrows Callin Out the Moon",
      "1",
      "amazon.com",
      "Amazon",
      "ARDEN HILLS",
      "MN",
      "55112-3779",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Oct 12, 2015 12:22:09 AM PDT",
      "5990307381",
      "Order",
      "106-8275678-3256262",
      "1003042488",
      "The Ant and the Grasshopper: An Aesop's Fable",
      "1",
      "amazon.com",
      "Amazon",
      "IDAHO FALLS",
      "IDAHO",
      "83406-4515",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Oct 12, 2015 12:31:20 AM PDT",
      "5990307381",
      "Order",
      "110-8859841-2781854",
      "1002584059",
      "Disney's Pocahontas (Big Golden Book)",
      "1",
      "amazon.com",
      "Amazon",
      "Fairfax",
      "VA",
      "22030",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Oct 12, 2015 1:01:55 AM PDT",
      "5990307381",
      "Order",
      "110-4960572-7063451",
      "1002361206",
      "Wilton Armetale Gourmet Grillware Bowl, Medium, Round, 11-3/4-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "ASHBY",
      "NE",
      "69333-9727",
      "",
      "42.03",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.30",
      "-4.41",
      "0",
      "0",
      "31.32"
    ],
    [
      "Oct 12, 2015 1:15:42 PM PDT",
      "5990307381",
      "Refund",
      "111-0004456-5313804",
      "1002469043",
      "Royal Doulton Pure Platinum 43-piece Dinnerware Set, Service for 8",
      "1",
      "amazon.com",
      "Amazon",
      "YORK",
      "MAINE",
      "03909-5118",
      "",
      "-499.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "70.00",
      "0",
      "0",
      "0",
      "-429.99"
    ],
    [
      "Oct 12, 2015 5:11:27 PM PDT",
      "5990307381",
      "Order",
      "106-4112767-2715437",
      "1002550940",
      "Pirates of the Caribbean: The Curse of the Black Pearl (The Junior Novelization)",
      "1",
      "amazon.com",
      "Amazon",
      "SENOIA",
      "GA",
      "30276",
      "",
      "7.99",
      "0",
      "1.63",
      "0",
      "0",
      "0",
      "-1.63",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Oct 12, 2015 6:01:48 PM PDT",
      "5990307381",
      "Order",
      "113-4368779-3773010",
      "1003121151",
      "Ox-Cart Man",
      "1",
      "amazon.com",
      "Amazon",
      "Wellington",
      "KS",
      "67152-8082",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Oct 12, 2015 8:23:56 PM PDT",
      "5990307381",
      "Order",
      "102-9000857-5885862",
      "1003186923",
      "Buford Bear's Bad News Blues (The Wonder Woods Series)",
      "1",
      "amazon.com",
      "Amazon",
      "Kilgore",
      "TX",
      "75662",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 12, 2015 10:51:26 PM PDT",
      "5991482151",
      "Order",
      "116-5663653-4084254",
      "1003224524",
      "Not Becoming My Mother: and Other Things She Taught Me Along the Way",
      "1",
      "amazon.com",
      "Amazon",
      "HAVERHILL",
      "MA",
      "01835-7554",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.46"
    ],
    [
      "Oct 13, 2015 5:36:17 AM PDT",
      "5991482151",
      "Order",
      "109-3710052-7843407",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90066-6437",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Oct 13, 2015 2:46:52 PM PDT",
      "5991482151",
      "Order",
      "108-0508308-5267406",
      "1002715473",
      "The Alloy of Law: A Mistborn Novel",
      "1",
      "amazon.com",
      "Amazon",
      "OAK HARBOR",
      "WA",
      "98277-2531",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Oct 13, 2015 4:26:02 PM PDT",
      "5991482151",
      "Order",
      "106-0813590-0253004",
      "1003006144",
      "Touching Spirit Bear",
      "1",
      "amazon.com",
      "Amazon",
      "COLBY",
      "KS",
      "67701-1901",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 13, 2015 6:36:13 PM PDT",
      "5991482151",
      "Order",
      "103-3317325-4681017",
      "WL-9ARO-T0D9",
      "The Right Decision Every Time: How to Reach Perfect Clarity on Tough Decisions",
      "1",
      "amazon.com",
      "Amazon",
      "BELLEVUE",
      "WASHINGTON",
      "98004-8363",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Oct 13, 2015 7:04:29 PM PDT",
      "5991482151",
      "Order",
      "104-0459013-5421055",
      "1003187296",
      "Going to Sleep on the Farm",
      "1",
      "amazon.com",
      "Amazon",
      "West Palm Beach",
      "Florida",
      "33401",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-1.54",
      "0",
      "0",
      "18.35"
    ],
    [
      "Oct 13, 2015 11:49:50 PM PDT",
      "5991482151",
      "Order",
      "115-3228428-3486664",
      "1003108154",
      "How to Eat Fried Worms",
      "1",
      "amazon.com",
      "Amazon",
      "BISMARCK",
      "ND",
      "58504-4118",
      "",
      "5.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.17",
      "-1.54",
      "0",
      "0",
      "1.78"
    ],
    [
      "Oct 14, 2015 12:15:23 AM PDT",
      "5991482151",
      "Order",
      "108-2948457-4817014",
      "1003012196",
      "Quercetti Qubo First Blocks",
      "1",
      "amazon.com",
      "Amazon",
      "SCOTTSDALE",
      "AZ",
      "85255-9009",
      "",
      "34.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.25",
      "-5.19",
      "0",
      "0",
      "24.55"
    ],
    [
      "Oct 14, 2015 3:25:05 AM PDT",
      "5991482151",
      "Order",
      "102-4066882-4254637",
      "1002973151",
      "The Little Soul and the Sun",
      "1",
      "amazon.com",
      "Amazon",
      "SAN DIEGO",
      "CA",
      "92110",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 14, 2015 6:43:30 PM PDT",
      "5991482151",
      "Order",
      "107-6975628-9448233",
      "1002663845",
      "Limu: The Blue Turtle",
      "1",
      "amazon.com",
      "Amazon",
      "Kent",
      "WA",
      "98030",
      "",
      "9.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.77",
      "-1.54",
      "0",
      "0",
      "5.18"
    ],
    [
      "Oct 14, 2015 6:56:23 PM PDT",
      "5991482151",
      "Order",
      "110-3760071-7302620",
      "1003187406",
      "The Big Box (Jump at the Sun)",
      "1",
      "amazon.com",
      "Amazon",
      "New York",
      "NY",
      "10005",
      "",
      "19.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-6.90",
      "0",
      "0",
      "13.72"
    ],
    [
      "Oct 14, 2015 7:18:07 PM PDT",
      "5991482151",
      "Order",
      "105-7755883-4707420",
      "1002469489",
      "The Gift of a Memory: A Keepsake to Commemorate the Loss of a Loved One (Marianne Richmond)",
      "1",
      "amazon.com",
      "Amazon",
      "Dobbs Ferry",
      "NY",
      "10522-2182",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Oct 14, 2015 7:21:12 PM PDT",
      "5991482151",
      "Order",
      "115-2842823-1425842",
      "1003032308",
      "No Talking",
      "1",
      "amazon.com",
      "Amazon",
      "New York",
      "NY",
      "10007-1097",
      "",
      "5.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.17",
      "-1.54",
      "0",
      "0",
      "1.78"
    ],
    [
      "Oct 14, 2015 10:47:45 PM PDT",
      "5991482151",
      "Order",
      "108-5723178-6401019",
      "1003006171",
      "Dragonsong (Harper Hall Trilogy, Book 1)",
      "1",
      "amazon.com",
      "Amazon",
      "Renton",
      "WA",
      "98059-4331",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Oct 15, 2015 2:18:23 AM PDT",
      "5991482151",
      "Order",
      "106-8220447-3846625",
      "1003012151",
      "Brio Pull Along Fish",
      "1",
      "amazon.com",
      "Amazon",
      "JACKSON",
      "TN",
      "38301-4330",
      "",
      "14.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-2.67",
      "0",
      "0",
      "10.06"
    ],
    [
      "Oct 15, 2015 3:20:35 AM PDT",
      "5991482151",
      "Order",
      "104-3255683-0403444",
      "1003224602",
      "You Are Special (Max Lucado's Wemmicks)",
      "1",
      "amazon.com",
      "Amazon",
      "TRINITY",
      "FL",
      "34655-5060",
      "",
      "12.41",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.21",
      "-1.54",
      "0",
      "0",
      "7.66"
    ],
    [
      "Oct 15, 2015 3:25:33 PM PDT",
      "5991482151",
      "Order",
      "111-9301312-1440266",
      "1002584293",
      "Oh My Baby, Little One",
      "1",
      "amazon.com",
      "Amazon",
      "ANN ARBOR",
      "MI",
      "48104-1563",
      "",
      "30.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.00",
      "-1.92",
      "0",
      "0",
      "23.07"
    ],
    [
      "Oct 15, 2015 6:08:10 PM PDT",
      "5991482151",
      "Order",
      "102-7261491-9552220",
      "1002555949",
      "I Know a Shy Fellow Who Swallowed a Cello",
      "1",
      "amazon.com",
      "Amazon",
      "Payson",
      "UT",
      "84651-9627",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-1.54",
      "0",
      "0",
      "9.00"
    ],
    [
      "Oct 15, 2015 7:24:27 PM PDT",
      "5991482151",
      "Order",
      "116-0557191-5467404",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "NY",
      "NY",
      "10023",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 15, 2015 11:28:22 PM PDT",
      "5991482151",
      "Order",
      "107-8857795-5925045",
      "1003006240",
      "Harper Collins Publishers Bridge To Terabithia",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OREGON",
      "97203-5798",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 15, 2015 11:41:21 PM PDT",
      "5991482151",
      "Order",
      "111-3401615-5814655",
      "RO-M3RK-KJI4",
      "My House Is Killing Me!: The Home Guide for Families with Allergies and Asthma",
      "1",
      "amazon.com",
      "Amazon",
      "Marietta",
      "GA",
      "30068",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Oct 16, 2015 2:47:33 AM PDT",
      "5991482151",
      "Order",
      "112-3383092-9328261",
      "1002601009",
      "I Am Really a Princess",
      "1",
      "amazon.com",
      "Amazon",
      "Pensacola",
      "FL",
      "32507-3474",
      "",
      "52.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-9.30",
      "-1.92",
      "0",
      "0",
      "41.77"
    ],
    [
      "Oct 16, 2015 4:26:47 PM PDT",
      "5991482151",
      "Order",
      "103-5149759-1206650",
      "1002555429",
      "Measle and the Wrathmonk",
      "1",
      "amazon.com",
      "Amazon",
      "Houston",
      "TX",
      "77095",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Oct 16, 2015 4:28:10 PM PDT",
      "5991482151",
      "Order",
      "109-8614057-9268208",
      "1002601005",
      "Cinderella",
      "1",
      "amazon.com",
      "Amazon",
      "SAN ANTONIO",
      "TX",
      "78249-1789",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Oct 16, 2015 7:19:51 PM PDT",
      "5991482151",
      "Order",
      "109-1695903-1908229",
      "1003012151",
      "Brio Pull Along Fish",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "VA",
      "22205-1140",
      "",
      "14.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-2.67",
      "0",
      "0",
      "10.06"
    ],
    [
      "Oct 16, 2015 11:27:57 PM PDT",
      "5991482151",
      "Order",
      "115-7953820-8797844",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "MIAMI",
      "FL",
      "33126-6007",
      "",
      "3.99",
      "0",
      "0.61",
      "0",
      "0",
      "0",
      "-0.61",
      "0",
      "0",
      "-2.00",
      "-2.67",
      "0",
      "0",
      "-0.68"
    ],
    [
      "Oct 16, 2015 11:27:57 PM PDT",
      "5991482151",
      "Order",
      "115-7953820-8797844",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "MIAMI",
      "FL",
      "33126-6007",
      "",
      "3.99",
      "0",
      "0.60",
      "0",
      "0",
      "0",
      "-0.60",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "2.32"
    ],
    [
      "Oct 17, 2015 2:32:49 PM PDT",
      "5991482151",
      "Order",
      "109-5789894-8331444",
      "1002410628",
      "Right Choices",
      "1",
      "amazon.com",
      "Amazon",
      "LITTLE ROCK",
      "AR",
      "72210-4789",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 17, 2015 11:01:23 PM PDT",
      "5991482151",
      "Adjustment",
      "111-0004456-5313804",
      "1002469043",
      "FBA Inventory Reimbursement - Customer Return",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "396.73",
      "396.73"
    ],
    [
      "Oct 18, 2015 3:34:30 PM PDT",
      "5991482151",
      "Order",
      "113-7786368-9672255",
      "1002833003",
      "1776",
      "1",
      "amazon.com",
      "Amazon",
      "TEXARKANA",
      "AR",
      "71854-8207",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 18, 2015 5:16:50 PM PDT",
      "5991482151",
      "Order",
      "102-4167937-0801852",
      "1003270087",
      "Killing Lincoln: The Shocking Assassination that Changed America Forever",
      "1",
      "amazon.com",
      "Amazon",
      "DOVER",
      "NJ",
      "07801-2234",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.92",
      "0",
      "0",
      "3.78"
    ],
    [
      "Oct 18, 2015 10:40:48 PM PDT",
      "5991482151",
      "Order",
      "106-6225688-1357869",
      "1002508116",
      "ALL CLAD 8QT STOCKPOT WITH LID B3 NONSTICK",
      "1",
      "amazon.com",
      "Amazon",
      "VAN NUYS",
      "CA",
      "91406-5715",
      "",
      "129.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-19.50",
      "-9.15",
      "0",
      "0",
      "101.34"
    ],
    [
      "Oct 19, 2015 12:06:43 AM PDT",
      "5991482151",
      "Order",
      "002-6468699-2258620",
      "1003306179",
      "The 48 Laws of Power",
      "1",
      "amazon.com",
      "Amazon",
      "LEADVILLE",
      "CO",
      "80461-9020",
      "",
      "12.85",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.28",
      "-1.92",
      "0",
      "0",
      "7.65"
    ],
    [
      "Oct 19, 2015 12:07:59 AM PDT",
      "5991482151",
      "Order",
      "116-4556286-2971428",
      "1002584528",
      "The Lonely Doll (Sandpiper Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Philo",
      "CA",
      "95466",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 19, 2015 12:12:30 AM PDT",
      "5991482151",
      "Order",
      "103-6800582-3076264",
      "1003242003",
      "The Young Bond Series:Book 5: By Royal Command (A James Bond Adventure) (James Bond Adventure, A)",
      "1",
      "amazon.com",
      "Amazon",
      "FORSYTH",
      "IL",
      "62535-9671",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 19, 2015 11:28:56 AM PDT",
      "5991482151",
      "Order",
      "108-7177872-2624215",
      "1002973137",
      "Father and Son: A Nativity Story",
      "1",
      "amazon.com",
      "Amazon",
      "SCOTIA",
      "NEW YORK",
      "12302-2599",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Oct 19, 2015 12:06:19 PM PDT",
      "5991482151",
      "Order",
      "104-5808905-2987459",
      "1002551125",
      "The Tigger Movie",
      "1",
      "amazon.com",
      "Amazon",
      "Weno",
      "Chuuk",
      "96942",
      "",
      "5.99",
      "0",
      "1.47",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-3.01",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 19, 2015 4:37:15 PM PDT",
      "5991482151",
      "Order",
      "108-6267760-6455441",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "SAN FRANCISCO",
      "CA",
      "94114-2923",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Oct 19, 2015 9:39:04 PM PDT",
      "5991482151",
      "Order",
      "112-0993256-4457065",
      "1002516859",
      "The Cat in the Hat",
      "1",
      "amazon.com",
      "Amazon",
      "Franklinton",
      "NC",
      "27525",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Oct 19, 2015 11:49:43 PM PDT",
      "5991482151",
      "Order",
      "107-3473901-8057003",
      "1002605281",
      "Emily The Strange",
      "1",
      "amazon.com",
      "Amazon",
      "San Diego",
      "CA",
      "92128",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 19, 2015 11:52:27 PM PDT",
      "5991482151",
      "Order",
      "114-0826917-7647460",
      "1002555898",
      "The Rooster's Gift",
      "1",
      "amazon.com",
      "Amazon",
      "WATSONVILLE",
      "CA",
      "95076-9725",
      "",
      "7.20",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.43",
      "-1.92",
      "0",
      "0",
      "2.85"
    ],
    [
      "Oct 20, 2015 2:15:22 PM PDT",
      "5995276191",
      "Order",
      "102-1152671-8973809",
      "UM-X6B6-WTLL",
      "Norcom Inc 76696-6 5 in. X 8 in. White Legal Pad 50 Page 3 Count",
      "1",
      "amazon.com",
      "Amazon",
      "CHARLOTTE",
      "NC",
      "28278-8530",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "4.05"
    ],
    [
      "Oct 20, 2015 2:15:22 PM PDT",
      "5995276191",
      "Order",
      "102-1152671-8973809",
      "UM-X6B6-WTLL",
      "Norcom Inc 76696-6 5 in. X 8 in. White Legal Pad 50 Page 3 Count",
      "1",
      "amazon.com",
      "Amazon",
      "CHARLOTTE",
      "NC",
      "28278-8530",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.54",
      "0",
      "0",
      "5.45"
    ],
    [
      "Oct 20, 2015 5:02:02 PM PDT",
      "5995276191",
      "Order",
      "114-7414898-0938657",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90019-2607",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Oct 21, 2015 6:26:13 AM PDT",
      "5995276191",
      "Order",
      "002-2792991-3940226",
      "1002368255",
      "Unguarded Gates: A History of America's Immigration Crisis",
      "1",
      "amazon.com",
      "Amazon",
      "Ontario",
      "California",
      "91762",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Oct 21, 2015 11:45:37 AM PDT",
      "5995276191",
      "Order",
      "104-7738771-3198655",
      "1003305855",
      "Managerial Economics (Book Only)",
      "1",
      "amazon.com",
      "Amazon",
      "EDINA",
      "MINNESOTA",
      "55435-4417",
      "",
      "42.99",
      "0",
      "1.93",
      "0",
      "0",
      "0",
      "-1.93",
      "0",
      "0",
      "-7.80",
      "-2.74",
      "0",
      "0",
      "32.45"
    ],
    [
      "Oct 21, 2015 4:57:02 PM PDT",
      "5995276191",
      "Order",
      "111-2914480-3371467",
      "1002663436",
      "Digz Garden Glove Nitrile Med Knit Latex Free",
      "1",
      "amazon.com",
      "Amazon",
      "RUSSELLS POINT",
      "OH",
      "43348-9581",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 21, 2015 4:59:29 PM PDT",
      "5995276191",
      "Order",
      "114-0923956-5272213",
      "1003186990",
      "American Family of the Pilgrim Period Paper Dolls (Dover Paper Dolls)",
      "1",
      "amazon.com",
      "Amazon",
      "MIAMI",
      "FL",
      "33183-2151",
      "",
      "9.99",
      "0",
      "1.44",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-2.98",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 21, 2015 7:56:36 PM PDT",
      "5995276191",
      "Order",
      "108-2459416-0615421",
      "1002536999",
      "The Boxcar Children Surprise Island #2 Gertrue Chandler Warner",
      "1",
      "amazon.com",
      "Amazon",
      "Jackson Hts",
      "New York",
      "11372",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Oct 21, 2015 8:47:12 PM PDT",
      "5995276191",
      "Order",
      "104-6030739-2789058",
      "1003186682",
      "Winter's Gift",
      "1",
      "amazon.com",
      "Amazon",
      "New Windsor",
      "NY",
      "12553-7651",
      "",
      "8.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.36"
    ],
    [
      "Oct 21, 2015 11:24:31 PM PDT",
      "5995276191",
      "Order",
      "106-8869389-8005056",
      "1003241926",
      "Savvy",
      "1",
      "amazon.com",
      "Amazon",
      "Friendswood",
      "TX",
      "77546",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.67",
      "0",
      "0",
      "2.92"
    ],
    [
      "Oct 22, 2015 3:07:04 AM PDT",
      "5995276191",
      "Order",
      "116-3152042-3834634",
      "1002565204",
      "Glasses for D.W. (I Can Read It All By Myself Beginner Books) 1996 Edition",
      "1",
      "amazon.com",
      "Amazon",
      "CARY",
      "NC",
      "27519",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Oct 22, 2015 3:23:32 AM PDT",
      "5995276191",
      "Order",
      "107-3404115-4990646",
      "1002914265",
      "One Sleepy Baby (Harper Growing Tree)",
      "1",
      "amazon.com",
      "Amazon",
      "Morrisville",
      "NC",
      "27560",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 22, 2015 4:10:02 AM PDT",
      "5995276191",
      "Order",
      "110-4382997-3788262",
      "1002470205",
      "The Tale of Despereaux: Being the Story of a Mouse, a Princess, Some Soup and a Spool of Thread",
      "1",
      "amazon.com",
      "Amazon",
      "SUN CITY CENTER",
      "FL",
      "33573-6709",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.67",
      "0",
      "0",
      "1.22"
    ],
    [
      "Oct 22, 2015 8:58:06 AM PDT",
      "5995276191",
      "Order",
      "109-9128791-1623433",
      "1003224909",
      "The Beauty Myth: How Images of Beauty Are Used Against Women",
      "1",
      "amazon.com",
      "Amazon",
      "Massillon",
      "Ohio",
      "44646",
      "",
      "9.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-3.66",
      "0",
      "0",
      "5.47"
    ],
    [
      "Oct 22, 2015 12:47:19 PM PDT",
      "5995276191",
      "Order",
      "106-5370482-9981810",
      "1003321678",
      "Ready, Fire, Aim: Zero to $100 Million in No Time Flat",
      "1",
      "amazon.com",
      "Amazon",
      "Waco",
      "Texas",
      "76715",
      "",
      "23.01",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.80",
      "-1.92",
      "0",
      "0",
      "16.29"
    ],
    [
      "Oct 23, 2015 3:20:44 AM PDT",
      "5995276191",
      "Order",
      "114-6151865-4309000",
      "1002469893",
      "The Pilgrims at Plymouth (Landmark Books)",
      "1",
      "amazon.com",
      "Amazon",
      "WATSONVILLE",
      "CA",
      "95076-6126",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 23, 2015 4:00:12 PM PDT",
      "5995276191",
      "Order",
      "113-6571167-5361022",
      "1002427824",
      "Rusty's Red Vacation",
      "1",
      "amazon.com",
      "Amazon",
      "Thompsons Station",
      "TN",
      "37179",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Oct 23, 2015 4:27:26 PM PDT",
      "5995276191",
      "Order",
      "108-2181046-6847431",
      "1003108163",
      "Mrs. Piggle-Wiggle's farm",
      "1",
      "amazon.com",
      "Amazon",
      "Newcastle",
      "WA",
      "98056-1412",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 23, 2015 4:40:34 PM PDT",
      "5995276191",
      "Order",
      "106-0970717-0573043",
      "1002419784",
      "Swimmy",
      "1",
      "amazon.com",
      "Amazon",
      "SOLANA BEACH",
      "CA",
      "92075-1110",
      "",
      "12.59",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-3.24",
      "-5.41",
      "0",
      "0",
      "7.43"
    ],
    [
      "Oct 25, 2015 12:35:39 AM PDT",
      "5995276191",
      "Order",
      "106-5906562-9705067",
      "1003242297",
      "Can You Find It Inside?: Search and Discover for Young Art Lovers",
      "1",
      "amazon.com",
      "Amazon",
      "Lexington",
      "MA",
      "02420",
      "",
      "5.99",
      "0",
      "1.66",
      "0",
      "0",
      "0",
      "-1.66",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Oct 25, 2015 2:51:24 AM PDT",
      "5995276191",
      "Order",
      "108-6828451-3890643",
      "1002564717",
      "Freckleface Strawberry and the Dodgeball Bully",
      "1",
      "amazon.com",
      "Amazon",
      "NEWMARKET",
      "NH",
      "03857-1800",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.92",
      "0",
      "0",
      "0.97"
    ],
    [
      "Oct 25, 2015 3:13:28 PM PDT",
      "5995276191",
      "Order",
      "109-5297969-9690637",
      "1003187220",
      "Horrorgami: Spooky Paper Folding for Children with Other",
      "1",
      "amazon.com",
      "Amazon",
      "PACHECO",
      "CA",
      "94553-5118",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Oct 25, 2015 3:17:17 PM PDT",
      "5995276191",
      "Order",
      "108-9209362-0701002",
      "1003121107",
      "Eloise Wilkin Stories (Little Golden Book Treasury)",
      "1",
      "amazon.com",
      "Amazon",
      "Plano",
      "TX",
      "75093-7909",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.37"
    ],
    [
      "Oct 25, 2015 11:59:15 PM PDT",
      "5995276191",
      "Order",
      "106-8787888-6665860",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "ELLICOTT CITY",
      "MD",
      "21042-7830",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Oct 26, 2015 3:31:01 AM PDT",
      "5995276191",
      "Order",
      "002-6472597-8684253",
      "1003335394",
      "Yes Please",
      "1",
      "amazon.com",
      "Amazon",
      "Milwaukee",
      "WI",
      "53211",
      "",
      "12.39",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-3.21",
      "-1.92",
      "0",
      "0",
      "7.26"
    ],
    [
      "Oct 26, 2015 8:11:45 PM PDT",
      "5995276191",
      "Order",
      "110-4030062-4009815",
      "1002436752",
      "Nutcracker",
      "1",
      "amazon.com",
      "Amazon",
      "HOLYOKE",
      "MA",
      "01040-1334",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-2.33",
      "0",
      "0",
      "4.81"
    ],
    [
      "Oct 26, 2015 9:14:57 PM PDT",
      "5995276191",
      "Order",
      "116-8696709-6381066",
      "1002630500",
      "The Shy Scarecrow (Hello Reader!, Level 1)",
      "1",
      "amazon.com",
      "Amazon",
      "HARRISON",
      "NJ",
      "07029-2692",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Oct 27, 2015 12:34:11 AM PDT",
      "5995276191",
      "Order",
      "105-2226063-0495425",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "MALIBU",
      "CA",
      "90265-4868",
      "",
      "3.14",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.53"
    ],
    [
      "Oct 27, 2015 12:39:08 AM PDT",
      "5995276191",
      "Order",
      "109-4497771-3545058",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "PHOENIX",
      "AZ",
      "85050-6938",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Oct 27, 2015 1:58:33 AM PDT",
      "5995276191",
      "Order",
      "102-3702532-6801816",
      "1002451613",
      "Meet the Gang!: A Moving Pictures Book",
      "1",
      "amazon.com",
      "Amazon",
      "PARKER",
      "CO",
      "80134-9367",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Oct 27, 2015 6:39:02 AM PDT",
      "5995276191",
      "Order",
      "103-5706878-4778653",
      "1003224852",
      "The Lost Continent: Travels in Small-Town America",
      "1",
      "amazon.com",
      "Amazon",
      "WILMINGTON",
      "DE",
      "19804-9002",
      "",
      "9.99",
      "0",
      "1.85",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-3.77",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 27, 2015 2:53:03 PM PDT",
      "5995276191",
      "Order",
      "106-1027819-6567416",
      "1003012151",
      "Brio Pull Along Fish",
      "1",
      "amazon.com",
      "Amazon",
      "Boca Raton",
      "FL",
      "33432",
      "",
      "14.88",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.23",
      "-2.67",
      "0",
      "0",
      "9.98"
    ],
    [
      "Oct 27, 2015 4:31:52 PM PDT",
      "5995276191",
      "Order",
      "002-6205815-1689825",
      "1003242249",
      "Little Blue Truck Board Book",
      "1",
      "amazon.com",
      "Amazon",
      "SOUTHINGTON",
      "CT",
      "06489-1738",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 27, 2015 4:48:59 PM PDT",
      "5995276191",
      "Order",
      "103-5019927-3729844",
      "1002593691",
      "The Wellspring: Poems",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA MONICA",
      "CA",
      "90404-3721",
      "",
      "7.29",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-5.53",
      "0",
      "0",
      "3.31"
    ],
    [
      "Oct 27, 2015 11:50:41 PM PDT",
      "5995276191",
      "Order",
      "107-4902253-2873008",
      "F9-6ODS-XRAF",
      "Kids Animal Split Ring - Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "Phoenix",
      "Arizona",
      "85083",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Oct 27, 2015 11:55:41 PM PDT",
      "5995276191",
      "Order",
      "114-3720323-3258610",
      "1003054342",
      "Juvenile Justice in America (4th Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "Colorado Springs",
      "CO",
      "80909",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-2.33",
      "0",
      "0",
      "4.81"
    ],
    [
      "Oct 28, 2015 4:29:43 AM PDT",
      "5995276191",
      "Order",
      "116-0350201-3282617",
      "1003108169",
      "Someone has to set a bad example: An Anne Taintor Collection",
      "1",
      "amazon.com",
      "Amazon",
      "SOUTH ELGIN",
      "IL",
      "60177-3721",
      "",
      "9.99",
      "0",
      "1.91",
      "0",
      "0",
      "0",
      "-1.91",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 28, 2015 9:50:27 AM PDT",
      "5995276191",
      "Order",
      "002-4756333-8377853",
      "1002565338",
      "My Name Is Brain Brian (Apple Paperbacks)",
      "1",
      "amazon.com",
      "Amazon",
      "LOUISIANA",
      "MO",
      "63353-2751",
      "",
      "5.79",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.22",
      "-1.54",
      "0",
      "0",
      "2.03"
    ],
    [
      "Oct 28, 2015 4:32:36 PM PDT",
      "5995276191",
      "Order",
      "114-8074468-9957834",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "NEW YORK",
      "NY",
      "10022-6502",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "3.64"
    ],
    [
      "Oct 28, 2015 4:57:37 PM PDT",
      "5995276191",
      "Order",
      "109-1920669-5253811",
      "1003012301",
      "Royal Doulton 40000446 Gordon Ramsay Steamer Basket, 8-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "TX",
      "76013-2105",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.50",
      "-4.80",
      "0",
      "0",
      "20.69"
    ],
    [
      "Oct 28, 2015 5:13:34 PM PDT",
      "5995276191",
      "Order",
      "103-9347988-9409024",
      "1002574663",
      "Estes A816E Mini Heli",
      "1",
      "amazon.com",
      "Amazon",
      "OKLAHOMA CITY",
      "OKLAHOMA",
      "73112-5507",
      "",
      "37.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.70",
      "-2.67",
      "0",
      "0",
      "29.61"
    ],
    [
      "Oct 28, 2015 5:26:15 PM PDT",
      "5995276191",
      "Order",
      "116-4666752-5878667",
      "1002551115",
      "Lambert The Sheepish Lion",
      "1",
      "amazon.com",
      "Amazon",
      "Weymouth",
      "MA",
      "02188",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Oct 28, 2015 8:33:48 PM PDT",
      "5995276191",
      "Order",
      "108-7922149-4491428",
      "1002550859",
      "The Country Bunny and the Little Gold Shoes",
      "1",
      "amazon.com",
      "Amazon",
      "Fredericksburg",
      "VA",
      "22405",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Oct 28, 2015 11:13:31 PM PDT",
      "5995276191",
      "Order",
      "102-6398335-0301828",
      "F9-6ODS-XRAF",
      "Kids Animal Split Ring - Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "LAKELAND",
      "GEORGIA",
      "31635-2113",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Oct 29, 2015 1:41:34 PM PDT",
      "5995276191",
      "Order",
      "108-1332961-5873045",
      "1002477416",
      "Home Essentials Hammered 13oz DOF Set of 4",
      "1",
      "amazon.com",
      "Amazon",
      "BRANCHVILLE",
      "NJ",
      "07826",
      "",
      "19.99",
      "0",
      "5.28",
      "0",
      "0",
      "0",
      "-5.28",
      "0",
      "0",
      "-3.00",
      "-4.41",
      "0",
      "0",
      "12.58"
    ],
    [
      "Oct 29, 2015 4:22:40 PM PDT",
      "5995276191",
      "Order",
      "113-2160942-1869840",
      "1003224455",
      "The Rembrandt Affair (Gabriel Allon)",
      "1",
      "amazon.com",
      "Amazon",
      "Washington Depot",
      "CT",
      "06794",
      "",
      "9.99",
      "0",
      "2.19",
      "0",
      "0",
      "0",
      "-2.19",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 29, 2015 11:08:06 PM PDT",
      "5995276191",
      "Order",
      "111-8827484-2446612",
      "1002486082",
      "The Wind in the Willows (Everyman's Library Children's Classics)",
      "1",
      "amazon.com",
      "Amazon",
      "Alamosa",
      "CO",
      "81101",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Oct 29, 2015 11:50:55 PM PDT",
      "5995276191",
      "Order",
      "114-1309668-8517067",
      "1003269615",
      "The Expectant Father: Facts, Tips, and Advice for Dads-to-Be (New Father Series)",
      "1",
      "amazon.com",
      "Amazon",
      "Natick",
      "MA",
      "01760",
      "",
      "29.99",
      "0",
      "2.49",
      "0",
      "0",
      "0",
      "-2.49",
      "0",
      "0",
      "-5.85",
      "-1.92",
      "0",
      "0",
      "22.22"
    ],
    [
      "Oct 30, 2015 2:36:41 AM PDT",
      "5995276191",
      "Order",
      "107-2379779-1925041",
      "1002973312",
      "P Is For Palmetto: A South Carolina Alphabet (Discover America State By State Alphabet Series)",
      "1",
      "amazon.com",
      "Amazon",
      "BLUFFTON",
      "SC",
      "29910-9505",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Oct 30, 2015 3:02:58 AM PDT",
      "5995276191",
      "Order",
      "107-0836433-9062624",
      "1002728618",
      "Managerial Economics and Organizational Architecture",
      "1",
      "amazon.com",
      "Amazon",
      "PEORIA",
      "IL",
      "61606-1904",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-2.74",
      "0",
      "0",
      "6.95"
    ],
    [
      "Oct 30, 2015 3:16:46 AM PDT",
      "5995276191",
      "Order",
      "002-2488602-7281069",
      "1002600960",
      "The Patchwork Path: A Quilt Map to Freedom",
      "1",
      "amazon.com",
      "Amazon",
      "Marietta",
      "Georgia",
      "30064",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Oct 30, 2015 4:25:53 PM PDT",
      "5995276191",
      "Order",
      "108-7376565-7085029",
      "1002547048",
      "Hot Wings F-16 Falcon",
      "1",
      "amazon.com",
      "Amazon",
      "NEW ORLEANS",
      "LA",
      "70130-5762",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.34",
      "-2.54",
      "0",
      "0",
      "5.05"
    ],
    [
      "Nov 1, 2015 5:18:02 PM PST",
      "5995276191",
      "Order",
      "104-3076158-4458635",
      "1002605319",
      "The Night of Wishes",
      "1",
      "amazon.com",
      "Amazon",
      "WOODLAND HILLS",
      "CA",
      "91367-2806",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.85",
      "-1.54",
      "0",
      "0",
      "39.60"
    ],
    [
      "Nov 2, 2015 3:54:11 AM PST",
      "5995276191",
      "Order",
      "115-1488007-2234637",
      "1002547048",
      "Hot Wings F-16 Falcon",
      "1",
      "amazon.com",
      "Amazon",
      "Duluth",
      "GA",
      "30096",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.34",
      "-2.54",
      "0",
      "0",
      "5.05"
    ],
    [
      "Nov 2, 2015 8:20:12 AM PST",
      "5995276191",
      "Order",
      "107-5973651-5624214",
      "1002574663",
      "Estes A816E Mini Heli",
      "1",
      "amazon.com",
      "Amazon",
      "Homewood",
      "Alabama",
      "35209",
      "",
      "37.98",
      "0",
      "4.44",
      "0",
      "0",
      "0",
      "-4.44",
      "0",
      "0",
      "-5.70",
      "-2.67",
      "0",
      "0",
      "29.61"
    ],
    [
      "Nov 2, 2015 2:31:54 PM PST",
      "5995276191",
      "Order",
      "104-6500502-0046655",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "New Concord",
      "OH",
      "43762",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Nov 2, 2015 5:24:05 PM PST",
      "5995276191",
      "Order",
      "112-6235266-9841048",
      "1003321707",
      "Becoming the Parent You Want to Be: A Sourcebook of Strategies for the First Five Years",
      "1",
      "amazon.com",
      "Amazon",
      "BROWNSVILLE",
      "OR",
      "97327-2147",
      "",
      "12.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-7.31",
      "0",
      "0",
      "7.36"
    ],
    [
      "Nov 2, 2015 7:58:33 PM PST",
      "5995276191",
      "Order",
      "110-3268723-4170618",
      "F2-JAY7-UJNM",
      "Talk to the Snail: Ten Commandments for Understanding the French",
      "1",
      "amazon.com",
      "Amazon",
      "CENTERVILLE",
      "OH",
      "45459-2422",
      "",
      "9.51",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.78",
      "-1.67",
      "0",
      "0",
      "5.06"
    ],
    [
      "Nov 2, 2015 8:37:35 PM PST",
      "5995276191",
      "Order",
      "113-7373420-6335452",
      "1003144982",
      "The Time-Crunched Triathlete: Race-Winning Fitness in 8 Hours a Week (The Time-Crunched Athlete)",
      "1",
      "amazon.com",
      "Amazon",
      "Reston",
      "VA",
      "20190",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Nov 2, 2015 8:59:58 PM PST",
      "5995276191",
      "Order",
      "002-1191374-6895423",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "2",
      "amazon.com",
      "Amazon",
      "Chino",
      "CA",
      "91710",
      "",
      "19.98",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-3.00",
      "-4.34",
      "0",
      "0",
      "12.64"
    ],
    [
      "Nov 2, 2015 10:39:52 PM PST",
      "5995276191",
      "Order",
      "107-8294990-7443409",
      "1002428956",
      "Disney's Little Einsteins: Butterfly Suits (Disney's Little Einsteins Mission)",
      "1",
      "amazon.com",
      "Amazon",
      "LITTLETON",
      "MA",
      "01460-1537",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Nov 3, 2015 12:22:56 AM PST",
      "5995276191",
      "Order",
      "103-8277454-9608211",
      "1002428568",
      "Madlenka's Dog",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90025-2545",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 3, 2015 8:23:16 PM PST",
      "6002136031",
      "Order",
      "107-1406505-7054642",
      "F9-6ODS-XRAF",
      "Kids Animal Split Ring - Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "MEDINA",
      "WA",
      "98039-4801",
      "",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "0.45"
    ],
    [
      "Nov 3, 2015 11:23:26 PM PST",
      "6002136031",
      "Order",
      "104-7829342-4834627",
      "1002368491",
      "Candide and Other Stories (Oxford World's Classics)",
      "1",
      "amazon.com",
      "Amazon",
      "Panama City",
      "FL",
      "32404-7939",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Nov 3, 2015 11:34:36 PM PST",
      "6002136031",
      "Order",
      "116-7327381-3151406",
      "1002729500",
      "NLT Thirst Quencher New Testament",
      "1",
      "amazon.com",
      "Amazon",
      "MINOT",
      "ND",
      "58701-3913",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-1.54",
      "0",
      "0",
      "9.00"
    ],
    [
      "Nov 3, 2015 11:57:33 PM PST",
      "6002136031",
      "Order",
      "106-2454503-9263428",
      "1002663528",
      "Christmas Star Angel",
      "1",
      "amazon.com",
      "Amazon",
      "OAKLAND",
      "CA",
      "94602-1335",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 4, 2015 2:48:19 AM PST",
      "6002136031",
      "Order",
      "104-9942528-3796203",
      "1002832931",
      "Twilight",
      "1",
      "amazon.com",
      "Amazon",
      "WEST CHESTER",
      "OH",
      "45069-2771",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 4, 2015 1:46:57 PM PST",
      "6002136031",
      "Order",
      "105-6713765-9475465",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Redwood City",
      "CA",
      "94062-2227",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Nov 4, 2015 5:34:21 PM PST",
      "6002136031",
      "Order",
      "106-8685615-2433001",
      "0L-SLJ7-RYZD",
      "Scented Bath Salt, 17.6-oz. Jars (Lavender Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "MONKEY ISLAND",
      "OK",
      "74331-8703",
      "",
      "9.99",
      "0",
      "5.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-9.56",
      "0",
      "0",
      "4.86"
    ],
    [
      "Nov 4, 2015 7:08:30 PM PST",
      "6002136031",
      "Order",
      "105-6592256-2522635",
      "1002469587",
      "H is for Home Run: A Baseball Alphabet (Sports Alphabet)",
      "1",
      "amazon.com",
      "Amazon",
      "IRMO",
      "SC",
      "29063-8325",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Nov 4, 2015 8:00:07 PM PST",
      "6002136031",
      "Order",
      "114-9347446-6917840",
      "1002508896",
      "Jive Wire Universal Selfie Stick",
      "1",
      "amazon.com",
      "Amazon",
      "LEESBURG",
      "VA",
      "20176-3863",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-2.54",
      "0",
      "0",
      "18.70"
    ],
    [
      "Nov 4, 2015 11:50:50 PM PST",
      "6002136031",
      "Order",
      "110-2656052-8052206",
      "1003031644",
      "Rent A Third Grader (Little apple)",
      "1",
      "amazon.com",
      "Amazon",
      "Stockbridge",
      "GA",
      "30281",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 5, 2015 2:25:07 PM PST",
      "6002136031",
      "Order",
      "114-6004904-8999419",
      "1003321610",
      "Bending Toward Justice: The Voting Rights Act and the Transformation of American Democracy",
      "1",
      "amazon.com",
      "Amazon",
      "LUBBOCK",
      "TEXAS",
      "79403-3496",
      "",
      "14.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-5.91",
      "0",
      "0",
      "9.47"
    ],
    [
      "Nov 5, 2015 8:10:46 PM PST",
      "6002136031",
      "Order",
      "002-4587646-8748242",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "7",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20019-3002",
      "",
      "69.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.50",
      "-12.69",
      "0",
      "0",
      "46.74"
    ],
    [
      "Nov 5, 2015 8:54:18 PM PST",
      "6002136031",
      "Order",
      "002-0734484-6449831",
      "1002451014",
      "The Berenstains' B Book (Bright & Early Books)",
      "1",
      "amazon.com",
      "Amazon",
      "RICHMOND",
      "VA",
      "23238-3537",
      "",
      "18.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-1.54",
      "0",
      "0",
      "13.24"
    ],
    [
      "Nov 5, 2015 11:17:15 PM PST",
      "6002136031",
      "Order",
      "103-8419476-2942648",
      "1002537045",
      "The Way Baseball Works",
      "1",
      "amazon.com",
      "Amazon",
      "Hardin",
      "IL",
      "62047",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-2.33",
      "0",
      "0",
      "0.56"
    ],
    [
      "Nov 6, 2015 12:32:58 PM PST",
      "6002136031",
      "Order",
      "102-2737579-8634662",
      "1002360987",
      "Fitz and Floyd Vista Bella Large Platter",
      "1",
      "amazon.com",
      "Amazon",
      "WOBURN",
      "MA",
      "01801-6600",
      "",
      "84.00",
      "0",
      "8.44",
      "0",
      "0",
      "0",
      "-8.44",
      "0",
      "0",
      "-12.60",
      "-7.59",
      "0",
      "0",
      "63.81"
    ],
    [
      "Nov 6, 2015 12:56:28 PM PST",
      "6002136031",
      "Order",
      "116-1127889-0453010",
      "1003224492",
      "The Secret Warriors: A Men at War Novel",
      "1",
      "amazon.com",
      "Amazon",
      "Nashua",
      "NH",
      "03060",
      "",
      "6.93",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-1.74",
      "0",
      "0",
      "-2.39",
      "-1.67",
      "0",
      "0",
      "2.87"
    ],
    [
      "Nov 6, 2015 12:57:21 PM PST",
      "6002136031",
      "Refund",
      "103-8277454-9608211",
      "1002428568",
      "Madlenka's Dog",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90025-2545",
      "",
      "-9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.85",
      "0",
      "0",
      "0",
      "-7.14"
    ],
    [
      "Nov 6, 2015 3:52:39 PM PST",
      "6002136031",
      "Order",
      "002-9804195-4516269",
      "1002537010",
      "Holt McDougal Mathematics: Assessment Readiness Workbook Grade 8",
      "1",
      "amazon.com",
      "Amazon",
      "HOHENWALD",
      "TN",
      "38462-2128",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Nov 6, 2015 4:03:51 PM PST",
      "6002136031",
      "Order",
      "103-8226621-7919432",
      "1002554752",
      "Chicka Chicka Boom Boom (Chicka Chicka Book, A)",
      "1",
      "amazon.com",
      "Amazon",
      "Jersey Shore",
      "PA",
      "17740",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Nov 6, 2015 5:57:27 PM PST",
      "6002136031",
      "Refund",
      "002-2488602-7281069",
      "1002600960",
      "The Patchwork Path: A Quilt Map to Freedom",
      "1",
      "amazon.com",
      "Amazon",
      "Marietta",
      "Georgia",
      "30064",
      "",
      "-19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "4.35",
      "0",
      "0",
      "0",
      "-15.64"
    ],
    [
      "Nov 7, 2015 2:02:29 AM PST",
      "6002136031",
      "Order",
      "109-0254697-9045864",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "NOBLESVILLE",
      "IN",
      "46062-7665",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 7, 2015 2:04:05 AM PST",
      "6002136031",
      "Order",
      "104-1825498-1857823",
      "1002555978",
      "The Snow Tree",
      "1",
      "amazon.com",
      "Amazon",
      "Williamson",
      "New York",
      "14589",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 7, 2015 6:05:02 PM PST",
      "6002136031",
      "Order",
      "106-0117452-5931419",
      "1003186837",
      "Mandy",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OR",
      "97203-6629",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Nov 7, 2015 6:24:51 PM PST",
      "6002136031",
      "Order",
      "107-7649410-0982606",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "LAS VEGAS",
      "NEVADA",
      "89102-5002",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 8, 2015 3:35:08 PM PST",
      "6002136031",
      "Order",
      "107-9820350-9551463",
      "1002663807",
      "Walt Disney's Alice in Wonderland (Big Golden Book)",
      "1",
      "amazon.com",
      "Amazon",
      "TOMBALL",
      "TX",
      "77377-7275",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 8, 2015 10:55:13 PM PST",
      "6002136031",
      "Adjustment",
      "114-5622881-2275457",
      "1002622658",
      "FBA Inventory Reimbursement - Customer Return",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "50.73",
      "50.73"
    ],
    [
      "Nov 9, 2015 12:40:43 AM PST",
      "6002136031",
      "Order",
      "110-8471009-0905034",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "Denver",
      "CO",
      "80210-2618",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 9, 2015 10:42:20 PM PST",
      "6002136031",
      "Order",
      "104-6769877-0952209",
      "NQ-4FQF-S5SC",
      "Craig Electronics Portable Speaker with USB/Micro SD Slot and Bluetooth Wireless Technology",
      "1",
      "amazon.com",
      "Amazon",
      "San Antonio",
      "Texas",
      "78258",
      "",
      "21.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.76",
      "-3.63",
      "0",
      "0",
      "16.58"
    ],
    [
      "Nov 10, 2015 12:32:20 AM PST",
      "6002136031",
      "Order",
      "116-4637794-7209843",
      "1002551261",
      "I love you so much",
      "1",
      "amazon.com",
      "Amazon",
      "Fayetteville",
      "GA",
      "30215",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Nov 10, 2015 12:57:20 AM PST",
      "6002136031",
      "Order",
      "114-6704758-4628219",
      "1002554744",
      "Shoe-la-la!",
      "1",
      "amazon.com",
      "Amazon",
      "GREAT FALLS",
      "VIRGINIA",
      "22066-2937",
      "",
      "5.66",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.20",
      "-1.67",
      "0",
      "0",
      "1.79"
    ],
    [
      "Nov 10, 2015 1:00:55 AM PST",
      "6002136031",
      "Order",
      "002-9422371-4115443",
      "1002547048",
      "Hot Wings F-16 Falcon",
      "1",
      "amazon.com",
      "Amazon",
      "BORGER",
      "TX",
      "79007-8500",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.34",
      "-2.54",
      "0",
      "0",
      "5.05"
    ],
    [
      "Nov 10, 2015 9:14:35 AM PST",
      "6002136031",
      "Order",
      "107-4064488-0686607",
      "1003042837",
      "Kid's Cookies: Scrumptious Recipes for Bakers Ages 9 to 13 (William-Sonoma Kitchen Library)",
      "1",
      "amazon.com",
      "Amazon",
      "SILVERTON",
      "OR",
      "97381-0432",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Nov 10, 2015 11:25:38 PM PST",
      "6002136031",
      "Order",
      "103-0059943-6903478",
      "1003306359",
      "The Photographer's Guide to Marketing and Self-Promotion",
      "1",
      "amazon.com",
      "Amazon",
      "DALY CITY",
      "CA",
      "94015-1189",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Nov 11, 2015 3:04:46 AM PST",
      "6002136031",
      "Order",
      "104-5277714-9541851",
      "1002728558",
      "A Smart Girl's Guide to Boys",
      "1",
      "amazon.com",
      "Amazon",
      "HOPKINSVILLE",
      "KY",
      "42240-7200",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Nov 11, 2015 3:42:49 AM PST",
      "6002136031",
      "Order",
      "109-3580140-4697047",
      "1002683130",
      "My Job Went to India: 52 Ways to Save Your Job (Pragmatic Programmers)",
      "1",
      "amazon.com",
      "Amazon",
      "PROVO",
      "UT",
      "84604-6416",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Nov 11, 2015 3:46:56 AM PST",
      "6002136031",
      "Order",
      "002-9392502-6685028",
      "1003006152",
      "Number the Stars (Yearling Newbery)",
      "1",
      "amazon.com",
      "Amazon",
      "Gilber",
      "AZ",
      "85297",
      "",
      "7.99",
      "0",
      "2.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-4.52",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 11, 2015 8:57:06 AM PST",
      "6002136031",
      "Refund",
      "108-7922149-4491428",
      "1002550859",
      "The Country Bunny and the Little Gold Shoes",
      "1",
      "amazon.com",
      "Amazon",
      "Fredericksburg",
      "VA",
      "22405",
      "",
      "-8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.59",
      "0",
      "0",
      "0",
      "-5.70"
    ],
    [
      "Nov 11, 2015 4:40:40 PM PST",
      "6002136031",
      "Order",
      "110-2095857-8940234",
      "1003186978",
      "Eye Wonder: Ocean",
      "1",
      "amazon.com",
      "Amazon",
      "Woodstock",
      "GA",
      "30189",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.54",
      "0",
      "0",
      "7.30"
    ],
    [
      "Nov 11, 2015 8:03:33 PM PST",
      "6002136031",
      "Order",
      "105-9535420-5056246",
      "1002605261",
      "The Wide Window (A Series of Unfortunate Events)",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "VA",
      "22203-1971",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.67",
      "0",
      "0",
      "2.92"
    ],
    [
      "Nov 11, 2015 8:07:57 PM PST",
      "6002136031",
      "Order",
      "109-0713598-7129067",
      "1002605318",
      "The Timekeeper (Pirates of the Caribbean: Jack Sparrow #8)",
      "1",
      "amazon.com",
      "Amazon",
      "Lake grove",
      "Ny",
      "11755",
      "",
      "15.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.75",
      "-1.54",
      "0",
      "0",
      "10.70"
    ],
    [
      "Nov 12, 2015 12:03:16 AM PST",
      "6002136031",
      "Order",
      "103-8716928-0660238",
      "1003042826",
      "Hot Toddies: Mulled Wine, Buttered Rum, Spiced Cider, and Other Soul-Warming Winter Drinks",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90049-3402",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-5.41",
      "0",
      "0",
      "1.82"
    ],
    [
      "Nov 12, 2015 12:05:19 AM PST",
      "6002136031",
      "Order",
      "107-2195699-4581015",
      "1003108088",
      "Houghton Mifflin Spelling and Vocabulary: Student Book (consumable) Grade 5 2004",
      "1",
      "amazon.com",
      "Amazon",
      "CORTE MADERA",
      "CALIFORNIA",
      "94925-1053",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Nov 12, 2015 1:54:16 AM PST",
      "6002136031",
      "Order",
      "102-6792223-6282636",
      "1002491065",
      "There's a Wocket in My Pocket! (Dr. Seuss's Book of Ridiculous Rhymes)",
      "1",
      "amazon.com",
      "Amazon",
      "CAMBY",
      "IN",
      "46113-8371",
      "",
      "3.77",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.92",
      "-1.54",
      "0",
      "0",
      "0.31"
    ],
    [
      "Nov 12, 2015 2:25:12 AM PST",
      "6002136031",
      "Order",
      "109-5078213-4313007",
      "1003335318",
      "Speak",
      "1",
      "amazon.com",
      "Amazon",
      "COLUMBIA",
      "SC",
      "29229-7530",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.67",
      "0",
      "0",
      "7.17"
    ],
    [
      "Nov 12, 2015 3:24:04 AM PST",
      "6002136031",
      "Order",
      "108-2816049-2543424",
      "1002427841",
      "The Last Straw",
      "1",
      "amazon.com",
      "Amazon",
      "YUBA CITY",
      "CA",
      "95991-3813",
      "",
      "19.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-6.90",
      "0",
      "0",
      "13.72"
    ],
    [
      "Nov 12, 2015 3:25:31 AM PST",
      "6002136031",
      "Order",
      "115-4463620-5876243",
      "1002547048",
      "Hot Wings F-16 Falcon",
      "1",
      "amazon.com",
      "Amazon",
      "Ball",
      "LA",
      "71405-3302",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.34",
      "-2.54",
      "0",
      "0",
      "5.05"
    ],
    [
      "Nov 12, 2015 4:37:28 PM PST",
      "6002136031",
      "Order",
      "115-0943037-9001031",
      "1002451167",
      "It's Not Easy Being a Bunny (Beginner Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "League City",
      "Texas",
      "77573",
      "",
      "9.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-5.53",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 12, 2015 4:53:58 PM PST",
      "6002136031",
      "Order",
      "106-9184443-1629838",
      "H8-Q28U-TY0M",
      "Marvel Avengers Assemble 50 Piece Tower Puzzle (3 assorted puzzles, Designs Vary)",
      "1",
      "amazon.com",
      "Amazon",
      "PAHOA",
      "HI",
      "96778-9337",
      "",
      "6.07",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-3.63",
      "0",
      "0",
      "1.44"
    ],
    [
      "Nov 12, 2015 8:04:51 PM PST",
      "6002136031",
      "Order",
      "105-0450014-2241861",
      "1003006075",
      "Island of the Blue Dolphins",
      "1",
      "amazon.com",
      "Amazon",
      "FRONT ROYAL",
      "VIRGINIA",
      "22630-2557",
      "",
      "7.99",
      "0",
      "2.19",
      "0",
      "0",
      "0",
      "-2.19",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 12, 2015 11:42:12 PM PST",
      "6002136031",
      "Order",
      "106-3217332-8244243",
      "1002486062",
      "Theodore Boone: the Abduction",
      "1",
      "amazon.com",
      "Amazon",
      "WASKOM",
      "TX",
      "75692-5416",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.37"
    ],
    [
      "Nov 13, 2015 7:30:08 PM PST",
      "6002136031",
      "Order",
      "112-1744183-3289852",
      "1002451860",
      "Frosty the Snowman",
      "1",
      "amazon.com",
      "Amazon",
      "BOLINGBROOK",
      "IL",
      "60440-1917",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 13, 2015 7:42:37 PM PST",
      "6002136031",
      "Order",
      "107-9400560-3637040",
      "1002352373",
      "Wedgwood Gilded Weave Platinum Oval Platter 13.75\"",
      "1",
      "amazon.com",
      "Amazon",
      "AUSTIN",
      "TX",
      "78723-2706",
      "",
      "99.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-15.00",
      "-3.63",
      "0",
      "0",
      "81.36"
    ],
    [
      "Nov 13, 2015 7:46:33 PM PST",
      "6002136031",
      "Order",
      "107-1193039-1853027",
      "1002728593",
      "Basic Organizational Behavior",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "VA",
      "22203-1657",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.92",
      "0",
      "0",
      "2.67"
    ],
    [
      "Nov 14, 2015 12:34:30 AM PST",
      "6002136031",
      "Order",
      "113-1277770-3801819",
      "1002664018",
      "Look Find Wolverine and Xmen (Look and Find Book) (Look and Find (Publications International))",
      "1",
      "amazon.com",
      "Amazon",
      "BIRMINGHAM",
      "AL",
      "35209-6221",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Nov 14, 2015 5:58:26 PM PST",
      "6002136031",
      "Order",
      "102-8583418-8961051",
      "1002600958",
      "Santa's Reindeer",
      "1",
      "amazon.com",
      "Amazon",
      "KALISPELL",
      "MT",
      "59901-6871",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.55",
      "-1.92",
      "0",
      "0",
      "20.52"
    ],
    [
      "Nov 14, 2015 6:17:31 PM PST",
      "6002136031",
      "Order",
      "113-6687800-4784262",
      "1002451943",
      "God Gave Us Christmas",
      "1",
      "amazon.com",
      "Amazon",
      "DuPont",
      "WA",
      "98327",
      "",
      "7.69",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.50",
      "-1.54",
      "0",
      "0",
      "3.65"
    ],
    [
      "Nov 14, 2015 7:28:52 PM PST",
      "6002136031",
      "Order",
      "110-6805368-8109021",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "HACKENSACK",
      "NJ",
      "07601-4812",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 14, 2015 9:31:53 PM PST",
      "6002136031",
      "Order",
      "110-2539721-3505046",
      "1003083268",
      "The Illustrated Story of Copyright",
      "1",
      "amazon.com",
      "Amazon",
      "ALEXANDRIA",
      "VA",
      "22314-6415",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.85",
      "-1.92",
      "0",
      "0",
      "39.22"
    ],
    [
      "Nov 15, 2015 2:06:03 AM PST",
      "6002136031",
      "Order",
      "105-8610870-6997012",
      "1002914602",
      "Justin's Just Joking (AlphaPets)",
      "1",
      "amazon.com",
      "Amazon",
      "BELLEVUE",
      "NE",
      "68123-3753",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Nov 15, 2015 3:30:36 PM PST",
      "6002136031",
      "Order",
      "106-6253796-6569024",
      "1002574663",
      "Estes A816E Mini Heli",
      "1",
      "amazon.com",
      "Amazon",
      "GEORGETOWN",
      "TX",
      "78633-1938",
      "",
      "37.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.70",
      "-2.67",
      "0",
      "0",
      "29.60"
    ],
    [
      "Nov 15, 2015 11:19:38 PM PST",
      "6002136031",
      "Order",
      "107-9881341-6631411",
      "1003224433",
      "Diana: A Tribute in Photographs",
      "1",
      "amazon.com",
      "Amazon",
      "Windsor",
      "CO",
      "80550",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-2.33",
      "0",
      "0",
      "6.51"
    ],
    [
      "Nov 16, 2015 12:13:17 AM PST",
      "6002136031",
      "Order",
      "102-3148598-6365019",
      "5T-QNLP-N9DN",
      "Pocket Puzzle Word Find Game (Large Print)",
      "1",
      "amazon.com",
      "Amazon",
      "OAK HARBOR",
      "WA",
      "98277-4491",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.45"
    ],
    [
      "Nov 16, 2015 1:25:57 AM PST",
      "6002136031",
      "Order",
      "104-1882347-5105865",
      "1002451872",
      "The Chicken Sisters",
      "1",
      "amazon.com",
      "Amazon",
      "WINCHENDON",
      "MA",
      "01475-1041",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Nov 16, 2015 8:27:36 AM PST",
      "6002136031",
      "Order",
      "108-7852081-2381036",
      "1002451014",
      "The Berenstains' B Book (Bright & Early Books)",
      "1",
      "amazon.com",
      "Amazon",
      "Church Hill",
      "TN",
      "37642",
      "",
      "18.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-1.54",
      "0",
      "0",
      "13.24"
    ],
    [
      "Nov 16, 2015 6:22:35 PM PST",
      "6002136031",
      "Refund",
      "109-1920669-5253811",
      "1003012301",
      "Royal Doulton 40000446 Gordon Ramsay Steamer Basket, 8-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "ARLINGTON",
      "TX",
      "76013-2105",
      "",
      "-29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3.60",
      "0",
      "0",
      "0",
      "-26.39"
    ],
    [
      "Nov 16, 2015 9:48:57 PM PST",
      "6002136031",
      "Order",
      "111-3239136-0281816",
      "1002663436",
      "Digz Garden Glove Nitrile Med Knit Latex Free",
      "1",
      "amazon.com",
      "Amazon",
      "RUSSELLS POINT",
      "OH",
      "43348-9581",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Nov 16, 2015 10:29:52 PM PST",
      "6002136031",
      "Order",
      "106-7158289-0217840",
      "1002427853",
      "The Legend of the Three Trees: The Classic Story of Following Your Dreams",
      "1",
      "amazon.com",
      "Amazon",
      "WEST TERRE HAUTE",
      "INDIANA",
      "47885-1514",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 17, 2015 12:56:24 AM PST",
      "6002136031",
      "Order",
      "111-3359073-3546623",
      "1002368594",
      "Doing Philosophy: An Introduction Through Thought Experiments",
      "1",
      "amazon.com",
      "Amazon",
      "MINNETONKA",
      "MN",
      "55305-4507",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.69",
      "-2.33",
      "0",
      "0",
      "3.91"
    ],
    [
      "Nov 17, 2015 4:10:13 AM PST",
      "6002136031",
      "Order",
      "108-0765528-2458638",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "RALEIGH",
      "NORTH CAROLINA",
      "27615-1820",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Nov 17, 2015 8:38:35 AM PST",
      "6002136031",
      "Order",
      "107-1475380-5419416",
      "1002547048",
      "Hot Wings F-16 Falcon",
      "1",
      "amazon.com",
      "Amazon",
      "Decatur",
      "GA",
      "30033",
      "",
      "8.93",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.34",
      "-2.54",
      "0",
      "0",
      "5.05"
    ],
    [
      "Nov 17, 2015 1:11:03 PM PST",
      "6009371431",
      "Order",
      "115-4853334-6101849",
      "1002550898",
      "Chicken Poop for the Soul: Stories to Harden the Heart and Dampen the Spirit",
      "1",
      "amazon.com",
      "Amazon",
      "LANSING",
      "MICHIGAN",
      "48911-6024",
      "",
      "5.39",
      "0",
      "1.23",
      "0",
      "0",
      "0",
      "-1.23",
      "0",
      "0",
      "-2.16",
      "-1.54",
      "0",
      "0",
      "1.69"
    ],
    [
      "Nov 17, 2015 3:50:58 PM PST",
      "6009371431",
      "Order",
      "111-5589458-4555445",
      "1002973297",
      "Waddle, Waddle, Quack, Quack, Quack",
      "1",
      "amazon.com",
      "Amazon",
      "HEMPSTEAD",
      "TEXAS",
      "77445-7254",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Nov 17, 2015 4:23:28 PM PST",
      "6009371431",
      "Order",
      "002-7147195-7357052",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "San Antonio",
      "Texas",
      "78240",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Nov 17, 2015 6:19:45 PM PST",
      "6009371431",
      "Order",
      "111-2577854-3748264",
      "1002410699",
      "Over Time: My Life as a Sportswriter",
      "1",
      "amazon.com",
      "Amazon",
      "DENVER",
      "CO",
      "80216-4919",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Nov 17, 2015 11:36:03 PM PST",
      "6009371431",
      "Order",
      "108-2684712-7995438",
      "1003433740",
      "Einstein: His Life and Universe",
      "1",
      "amazon.com",
      "Amazon",
      "ENGLEWOOD",
      "NJ",
      "07631-2918",
      "",
      "13.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.37",
      "-2.33",
      "0",
      "0",
      "7.79"
    ],
    [
      "Nov 17, 2015 11:55:57 PM PST",
      "6009371431",
      "Order",
      "116-9428618-1221004",
      "1002428575",
      "Dragons (Luxury Lift-the-Flap Learners)",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OR",
      "97224-7143",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Nov 18, 2015 12:27:05 AM PST",
      "6009371431",
      "Order",
      "116-6657734-0165046",
      "1003433788",
      "Into Thin Air: A Personal Account of the Mount Everest Disaster",
      "1",
      "amazon.com",
      "Amazon",
      "Redondo Beach",
      "CA",
      "90278-3311",
      "",
      "13.99",
      "0",
      "11.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-13.90",
      "0",
      "0",
      "8.62"
    ],
    [
      "Nov 18, 2015 2:27:13 AM PST",
      "6009371431",
      "Order",
      "110-3578492-2624208",
      "1003031989",
      "Cal Ripken, Jr.s All Stars Wild Pitch",
      "1",
      "amazon.com",
      "Amazon",
      "Carneys Point",
      "NJ",
      "08069-2218",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Nov 18, 2015 2:28:56 AM PST",
      "6009371431",
      "Order",
      "002-0906498-4283434",
      "1002469560",
      "Can You See What I See?: The Night Before Christmas: Picture Puzzles to Search and Solve",
      "1",
      "amazon.com",
      "Amazon",
      "CINCINNATI",
      "OH",
      "45238-4436",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 18, 2015 9:40:27 AM PST",
      "6009371431",
      "Order",
      "111-4983923-9309027",
      "1003433729",
      "My Beloved World",
      "1",
      "amazon.com",
      "Amazon",
      "WEST GREENWICH",
      "RI",
      "02817-1574",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 18, 2015 10:42:09 AM PST",
      "6009371431",
      "Refund",
      "104-7662124-8349009",
      "1003108097",
      "Linear Algebra and Its Applications (3rd Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "worcester",
      "Massachusetts",
      "01605",
      "",
      "-13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.10",
      "0",
      "0",
      "0",
      "-11.89"
    ],
    [
      "Nov 18, 2015 2:16:33 PM PST",
      "6009371431",
      "Refund",
      "110-4030062-4009815",
      "1002436752",
      "Nutcracker",
      "1",
      "amazon.com",
      "Amazon",
      "HOLYOKE",
      "MA",
      "01040-1334",
      "",
      "-9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.85",
      "0",
      "0",
      "0",
      "-7.14"
    ],
    [
      "Nov 18, 2015 8:37:05 PM PST",
      "6009371431",
      "Order",
      "104-5197589-4017810",
      "1003461824",
      "Pirates: Robbers of the High Seas",
      "1",
      "amazon.com",
      "Amazon",
      "Howell",
      "NJ",
      "07731",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 18, 2015 8:37:09 PM PST",
      "6009371431",
      "Order",
      "107-2904623-4197860",
      "1002537079",
      "Into All the World: Four Stories of Pioneer Missionaries (Heroes of the Faith (Barbour Paperback))",
      "1",
      "amazon.com",
      "Amazon",
      "ALTA LOMA",
      "CA",
      "91701-8594",
      "",
      "4.29",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "-1.99",
      "-1.54",
      "0",
      "0",
      "0.76"
    ],
    [
      "Nov 18, 2015 8:45:07 PM PST",
      "6009371431",
      "Order",
      "112-6749792-5014653",
      "1002914325",
      "What about Heaven? (Little Blessings)",
      "1",
      "amazon.com",
      "Amazon",
      "Genoa",
      "IL",
      "60135",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Nov 18, 2015 10:02:38 PM PST",
      "6009371431",
      "Order",
      "002-9994277-7820253",
      "1003187091",
      "Welcome to Josefina's World: 1824 (American Girl)",
      "1",
      "amazon.com",
      "Amazon",
      "TUCSON",
      "AZ",
      "85718-1031",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Nov 18, 2015 11:46:51 PM PST",
      "6009371431",
      "Order",
      "102-5968675-5877054",
      "1003321746",
      "Contrarian Investment Strategies - The Classic Edition",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90019-3525",
      "",
      "8.88",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.68",
      "-1.92",
      "0",
      "0",
      "4.28"
    ],
    [
      "Nov 19, 2015 12:35:44 AM PST",
      "6009371431",
      "Order",
      "103-9230291-4450653",
      "1003474215",
      "Season of the Witch: Enchantment, Terror and Deliverance in the City of Love",
      "1",
      "amazon.com",
      "Amazon",
      "walnut creek",
      "CA",
      "94595",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Nov 19, 2015 4:57:22 AM PST",
      "6009371431",
      "Order",
      "114-0366722-1133059",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Big Bend",
      "WI",
      "53103",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Nov 19, 2015 4:14:39 PM PST",
      "6009371431",
      "Order",
      "106-6938066-5281800",
      "1003449318",
      "Yes, Virginia, There Is A Santa Claus: The Classic Edition",
      "1",
      "amazon.com",
      "Amazon",
      "Dallas",
      "Tx",
      "75220",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 19, 2015 7:15:24 PM PST",
      "6009371431",
      "Order",
      "112-1850663-5424231",
      "1003461223",
      "Good Dog, Carl",
      "1",
      "amazon.com",
      "Amazon",
      "DAYTON",
      "MARYLAND",
      "21036-1033",
      "",
      "9.99",
      "0",
      "7.48",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-9.02",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 19, 2015 7:17:33 PM PST",
      "6009371431",
      "Order",
      "106-0478596-0489067",
      "1002470112",
      "Just Tasting (Mini Appetizers, Soups and Salads for Casual Entertaining) Quick and Easy Recipes",
      "1",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20011-4446",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 19, 2015 7:19:04 PM PST",
      "6009371431",
      "Order",
      "108-4157203-7891406",
      "1002543365",
      "The Kid Who Ran For President",
      "1",
      "amazon.com",
      "Amazon",
      "Edgewood",
      "Maryland",
      "21040",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Nov 19, 2015 7:28:02 PM PST",
      "6009371431",
      "Order",
      "115-5228072-6645803",
      "1002470057",
      "The Mousehole Cat",
      "1",
      "amazon.com",
      "Amazon",
      "Annapolis",
      "MD",
      "21403",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-5.03",
      "0",
      "0",
      "3.05"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-10.00",
      "-1.67",
      "0",
      "0",
      "-6.68"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "2.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 7:29:05 PM PST",
      "6009371431",
      "Order",
      "116-8371554-7911417",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "River Vale",
      "NJ",
      "07675",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Nov 19, 2015 8:40:28 PM PST",
      "6009371431",
      "Order",
      "107-0705427-4833839",
      "CT-GK75-BJ4A",
      "Nambe Curvo Spatula Bowl Scraper",
      "1",
      "amazon.com",
      "Amazon",
      "Manhattan Beach",
      "CA",
      "90266",
      "",
      "17.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-2.67",
      "0",
      "0",
      "12.62"
    ],
    [
      "Nov 19, 2015 11:37:37 PM PST",
      "6009371431",
      "Order",
      "109-2836189-6773064",
      "1002555470",
      "Engelbert the Elephant",
      "1",
      "amazon.com",
      "Amazon",
      "OMAHA",
      "NE",
      "68106-2522",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Nov 20, 2015 1:05:04 PM PST",
      "6009371431",
      "Order",
      "109-2628425-1251436",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "Sudbury",
      "MA",
      "01776",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Nov 20, 2015 3:44:18 PM PST",
      "6009371431",
      "Order",
      "103-5018056-1874613",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "2",
      "amazon.com",
      "Amazon",
      "ORD",
      "NE",
      "68862-9375",
      "",
      "6.00",
      "0",
      "1.53",
      "0",
      "0",
      "0",
      "-1.53",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "-0.34"
    ],
    [
      "Nov 20, 2015 9:06:12 PM PST",
      "6009371431",
      "Adjustment",
      "110-7297628-7133014",
      "1002400216",
      "FBA Inventory Reimbursement - Customer Return",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "12.49",
      "12.49"
    ],
    [
      "Nov 21, 2015 9:57:53 AM PST",
      "6009371431",
      "Order",
      "110-3396562-0472266",
      "1003461022",
      "How to Write and Decode Top-Secret Messages (Codemaster, No. 1)",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OREGON",
      "97239-1091",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Nov 21, 2015 2:38:16 PM PST",
      "6009371431",
      "Order",
      "114-2755782-0553826",
      "1002663902",
      "A Duck So Small",
      "1",
      "amazon.com",
      "Amazon",
      "Los Angeles",
      "California",
      "90004",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Nov 21, 2015 5:43:05 PM PST",
      "6009371431",
      "Order",
      "116-0047206-9722639",
      "1003474502",
      "The Legacy of Heorot",
      "1",
      "amazon.com",
      "Amazon",
      "HUDSON",
      "WI",
      "54016-3019",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Nov 21, 2015 11:24:36 PM PST",
      "6009371431",
      "Order",
      "108-0379571-8976234",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "Palm Bay",
      "FL",
      "32909",
      "",
      "3.00",
      "0",
      "1.04",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-3.71",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Nov 22, 2015 2:28:54 AM PST",
      "6009371431",
      "Order",
      "105-5756043-7310651",
      "1003120626",
      "Mare in the Meadow (Animal Ark Series #31)",
      "1",
      "amazon.com",
      "Amazon",
      "Waltham",
      "MA",
      "02452",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Nov 22, 2015 8:29:59 AM PST",
      "6009371431",
      "Order",
      "115-2976426-5493862",
      "1002486195",
      "The Tremendous Tree Book (Reading Rainbow Book)",
      "1",
      "amazon.com",
      "Amazon",
      "FLORENCE",
      "MS",
      "39073-8601",
      "",
      "8.99",
      "0",
      "1.67",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Nov 22, 2015 1:12:19 PM PST",
      "6009371431",
      "Order",
      "109-5749262-5485023",
      "1003144959",
      "The Investor's Quotient: The Psychology of Successful Investing in Commodities & Stocks",
      "1",
      "amazon.com",
      "Amazon",
      "Cottonwood Heights",
      "Utah",
      "84121",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Nov 22, 2015 6:51:26 PM PST",
      "6009371431",
      "Order",
      "106-6106848-4149840",
      "1003187326",
      "Aunt Chip and the Great Triple Creek Dam Affair",
      "1",
      "amazon.com",
      "Amazon",
      "IRMO",
      "SC",
      "29063-8177",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Nov 22, 2015 7:48:03 PM PST",
      "6009371431",
      "Order",
      "106-3956220-1153029",
      "1003006181",
      "The Twenty-One Balloons",
      "1",
      "amazon.com",
      "Amazon",
      "VIENNA",
      "VA",
      "22181-3151",
      "",
      "6.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-1.54",
      "0",
      "0",
      "2.63"
    ],
    [
      "Nov 22, 2015 11:02:18 PM PST",
      "6009371431",
      "Order",
      "113-1174322-5615460",
      "1002367915",
      "Narrative of the Life of Frederick Douglass",
      "1",
      "amazon.com",
      "Amazon",
      "ORANGE",
      "TX",
      "77632-8912",
      "",
      "7.99",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-1.74",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 23, 2015 3:29:27 AM PST",
      "6009371431",
      "Order",
      "114-2604644-2889863",
      "1003582851",
      "LeapFrog  Tag Kid Classic Storybook Olivia",
      "1",
      "amazon.com",
      "Amazon",
      "GREENVILLE",
      "SC",
      "29609-6961",
      "",
      "9.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.39",
      "-2.54",
      "0",
      "0",
      "5.36"
    ],
    [
      "Nov 23, 2015 8:23:48 AM PST",
      "6009371431",
      "Order",
      "105-0915589-7955456",
      "1002469726",
      "Nobody's Diggier Than a Dog",
      "1",
      "amazon.com",
      "Amazon",
      "Woodbine",
      "NJ",
      "08270-9649",
      "",
      "22.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.80",
      "-1.92",
      "0",
      "0",
      "16.27"
    ],
    [
      "Nov 23, 2015 8:24:09 AM PST",
      "6009371431",
      "Order",
      "111-2622214-8817807",
      "1002565224",
      "Baby Tamer",
      "1",
      "amazon.com",
      "Amazon",
      "CHERRY HILL",
      "NJ",
      "08002-3040",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Nov 23, 2015 11:56:24 AM PST",
      "6009371431",
      "Order",
      "105-7378123-9360266",
      "1002360848",
      "Boston Warehouse Earthenware Mason Jar Mug, BBQ Design",
      "1",
      "amazon.com",
      "Amazon",
      "Damascus",
      "OR",
      "97089",
      "",
      "6.40",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-2.67",
      "0",
      "0",
      "1.73"
    ],
    [
      "Nov 23, 2015 11:56:24 AM PST",
      "6009371431",
      "Order",
      "105-7378123-9360266",
      "1002360848",
      "Boston Warehouse Earthenware Mason Jar Mug, BBQ Design",
      "1",
      "amazon.com",
      "Amazon",
      "Damascus",
      "OR",
      "97089",
      "",
      "6.40",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "4.73"
    ],
    [
      "Nov 23, 2015 4:11:19 PM PST",
      "6009371431",
      "Order",
      "104-0489649-5871433",
      "NQ-4FQF-S5SC",
      "Craig Electronics Portable Speaker with USB/Micro SD Slot and Bluetooth Wireless Technology",
      "1",
      "amazon.com",
      "Amazon",
      "RIVERSIDE",
      "CA",
      "92505-3224",
      "",
      "21.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.76",
      "-3.63",
      "0",
      "0",
      "16.58"
    ],
    [
      "Nov 23, 2015 4:12:55 PM PST",
      "6009371431",
      "Order",
      "102-8844361-7586669",
      "1003461783",
      "Firestar's Quest (Warriors Super Edition)",
      "1",
      "amazon.com",
      "Amazon",
      "FISHTAIL",
      "MT",
      "59028-0001",
      "",
      "7.49",
      "0",
      "1.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.47",
      "-3.41",
      "0",
      "0",
      "3.10"
    ],
    [
      "Nov 23, 2015 8:31:25 PM PST",
      "6009371431",
      "Order",
      "116-6420470-2801834",
      "HQ-18B6-JZY7",
      "It Was On Fire When I Lay Down On It by Robert Fulghum - Hardcover - First Edition, 2nd Printing 1989",
      "1",
      "amazon.com",
      "Amazon",
      "PORTLAND",
      "OR",
      "97213-2933",
      "",
      "9.99",
      "0",
      "3.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-5.66",
      "0",
      "0",
      "5.47"
    ],
    [
      "Nov 23, 2015 10:10:02 PM PST",
      "6009371431",
      "Order",
      "104-9178696-7453061",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "MARICOPA",
      "AZ",
      "85138-2400",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Nov 24, 2015 12:06:20 AM PST",
      "6009371431",
      "Order",
      "112-0494566-6481004",
      "1002486068",
      "Airman",
      "1",
      "amazon.com",
      "Amazon",
      "VALLEY",
      "WA",
      "99181-9674",
      "",
      "6.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-1.92",
      "0",
      "0",
      "2.25"
    ],
    [
      "Nov 24, 2015 12:13:08 AM PST",
      "6009371431",
      "Order",
      "112-9427476-6748265",
      "1003242450",
      "Spot the Differences Book 2: Art Masterpiece Mysteries (Dover Children's Activity Books)",
      "1",
      "amazon.com",
      "Amazon",
      "IRVINE",
      "CA",
      "92620-3258",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Nov 24, 2015 12:29:47 AM PST",
      "6009371431",
      "Order",
      "105-1327124-2191466",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "BOISE",
      "ID",
      "83713-0533",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Nov 24, 2015 1:09:48 AM PST",
      "6009371431",
      "Order",
      "107-4523539-5445040",
      "1002427861",
      "Ship of Dreams",
      "1",
      "amazon.com",
      "Amazon",
      "Stoughton",
      "MA",
      "02072",
      "",
      "19.99",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "-8.70",
      "-1.92",
      "0",
      "0",
      "9.37"
    ],
    [
      "Nov 24, 2015 1:09:48 AM PST",
      "6009371431",
      "Order",
      "107-4523539-5445040",
      "1002427861",
      "Ship of Dreams",
      "1",
      "amazon.com",
      "Amazon",
      "Stoughton",
      "MA",
      "02072",
      "",
      "19.99",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "0",
      "-1.92",
      "0",
      "0",
      "18.07"
    ],
    [
      "Nov 24, 2015 2:47:43 AM PST",
      "6009371431",
      "Order",
      "115-2570516-9391430",
      "1002486133",
      "Warm Bodies: A Novel (The Warm Bodies Series)",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60645-3442",
      "",
      "6.41",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.31",
      "-3.99",
      "0",
      "0",
      "2.43"
    ],
    [
      "Nov 24, 2015 5:38:09 AM PST",
      "6009371431",
      "Order",
      "109-2702273-7850645",
      "IM-QXL0-GHDK",
      "Scented Bath Salt, 17.6-oz. Jars (Rose Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "SUNRISE",
      "FL",
      "33322-2822",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-3.63",
      "0",
      "0",
      "4.86"
    ],
    [
      "Nov 24, 2015 9:54:03 AM PST",
      "6009371431",
      "Order",
      "113-9859225-3329062",
      "1002583982",
      "A Straw for Two (Companion To: The Ink Drinker)",
      "1",
      "amazon.com",
      "Amazon",
      "Vacaville",
      "CA",
      "95687",
      "",
      "23.99",
      "0",
      "10.47",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.95",
      "-12.01",
      "0",
      "0",
      "17.50"
    ],
    [
      "Nov 24, 2015 11:29:49 AM PST",
      "6009371431",
      "Order",
      "115-8276558-6271456",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "Mesa",
      "az",
      "85206",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 24, 2015 12:59:44 PM PST",
      "6009371431",
      "Order",
      "105-8294134-0565005",
      "1002564744",
      "The Great Blue House",
      "1",
      "amazon.com",
      "Amazon",
      "Cincinnati",
      "Ohio",
      "45209",
      "",
      "9.57",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.79",
      "-1.54",
      "0",
      "0",
      "5.24"
    ],
    [
      "Nov 24, 2015 4:28:49 PM PST",
      "6009371431",
      "Order",
      "108-0480036-5275419",
      "1002509113",
      "Disney's Tarzan (Disney's Wonderful World of Reading)",
      "1",
      "amazon.com",
      "Amazon",
      "FORT LAUDERDALE",
      "FL",
      "33301-0014",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 24, 2015 7:55:26 PM PST",
      "6009371431",
      "Order",
      "115-9552177-5024237",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "WHITESTONE",
      "NY",
      "11357",
      "",
      "4.99",
      "0",
      "3.07",
      "0",
      "0",
      "0",
      "-3.07",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 25, 2015 1:26:58 AM PST",
      "6009371431",
      "Order",
      "105-9061213-3010607",
      "1003321584",
      "The Witching Hour",
      "1",
      "amazon.com",
      "Amazon",
      "BANGOR",
      "ME",
      "04401-5611",
      "",
      "17.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-4.05",
      "-2.74",
      "0",
      "0",
      "11.20"
    ],
    [
      "Nov 25, 2015 2:28:23 AM PST",
      "6009371431",
      "Order",
      "102-0768109-1218666",
      "1002491065",
      "There's a Wocket in My Pocket! (Dr. Seuss's Book of Ridiculous Rhymes)",
      "1",
      "amazon.com",
      "Amazon",
      "TAUNTON",
      "MA",
      "02780-6809",
      "",
      "3.77",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.92",
      "-1.54",
      "0",
      "0",
      "0.31"
    ],
    [
      "Nov 25, 2015 2:48:49 AM PST",
      "6009371431",
      "Order",
      "112-4738196-4254622",
      "1002630110",
      "The Puppy Who Wanted a Boy",
      "1",
      "amazon.com",
      "Amazon",
      "CHARLOTTE",
      "NC",
      "28210-4889",
      "",
      "11.89",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.13",
      "-1.54",
      "0",
      "0",
      "7.22"
    ],
    [
      "Nov 25, 2015 10:28:02 AM PST",
      "6009371431",
      "Order",
      "114-0163597-5180268",
      "1003591244",
      "Scary Stories: More Tales to Chill Your Bones Edition: First",
      "1",
      "amazon.com",
      "Amazon",
      "OAK PARK",
      "CALIFORNIA",
      "91377-5807",
      "",
      "24.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-6.52",
      "0",
      "0",
      "18.35"
    ],
    [
      "Nov 25, 2015 8:34:36 PM PST",
      "6009371431",
      "Order",
      "115-7309679-6098604",
      "1002547014",
      "Hot Wings Apache AH-64 (With military Markings)",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NEW YORK",
      "11239-1810",
      "",
      "9.90",
      "0",
      "6.03",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.49",
      "-8.70",
      "0",
      "0",
      "5.74"
    ],
    [
      "Nov 25, 2015 11:39:14 PM PST",
      "6009371431",
      "Order",
      "107-2712230-7257055",
      "1002555487",
      "Cajun Through and Through",
      "1",
      "amazon.com",
      "Amazon",
      "GALLIANO",
      "LOUISIANA",
      "70354-3502",
      "",
      "22.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.80",
      "-1.54",
      "0",
      "0",
      "16.65"
    ],
    [
      "Nov 26, 2015 9:16:54 PM PST",
      "6009371431",
      "Order",
      "106-9356620-6453007",
      "1002832909",
      "Genius of the System",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60613-5625",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 27, 2015 2:22:00 PM PST",
      "6009371431",
      "Order",
      "109-8719111-4186668",
      "1003449184",
      "The Fourth Wise Man",
      "1",
      "amazon.com",
      "Amazon",
      "COLLEGE STATION",
      "TX",
      "77845-4477",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-1.92",
      "0",
      "0",
      "22.22"
    ],
    [
      "Nov 27, 2015 3:45:33 PM PST",
      "6009371431",
      "Order",
      "002-4230626-9825804",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "BOSTON",
      "MA",
      "02121-4114",
      "",
      "4.99",
      "0",
      "1.30",
      "0",
      "0",
      "0",
      "-1.30",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 27, 2015 6:36:31 PM PST",
      "6009371431",
      "Order",
      "104-5501464-1817031",
      "1002550870",
      "The Very Busy Day",
      "1",
      "amazon.com",
      "Amazon",
      "Waukesha",
      "WI",
      "53186",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 28, 2015 1:27:19 AM PST",
      "6009371431",
      "Order",
      "109-1933819-2719463",
      "1002491390",
      "The Hair Book (Bright & Early Book ; Be 24)",
      "1",
      "amazon.com",
      "Amazon",
      "HUNTERSVILLE",
      "NC",
      "28078-9736",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Nov 28, 2015 1:49:15 AM PST",
      "6009371431",
      "Order",
      "107-8775422-0015426",
      "1002486099",
      "A Wrinkle in Time (The Time Quartet)",
      "1",
      "amazon.com",
      "Amazon",
      "St Petersburg",
      "Florida",
      "33715",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 28, 2015 3:10:43 AM PST",
      "6009371431",
      "Order",
      "102-1776294-6476248",
      "1003582689",
      "Blue's Perfect Present (Blue's Clues / Nick Jr. Book Club)",
      "1",
      "amazon.com",
      "Amazon",
      "EAST TAUNTON",
      "MA",
      "02718-1045",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 28, 2015 5:01:35 AM PST",
      "6009371431",
      "Order",
      "103-6523894-7195429",
      "1002728858",
      "This Book Will Save Your Life",
      "1",
      "amazon.com",
      "Amazon",
      "Austin",
      "TX",
      "78757",
      "",
      "9.99",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-6.90",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 28, 2015 11:45:15 AM PST",
      "6009371431",
      "Order",
      "105-6362782-9496257",
      "1002729011",
      "Breaking Into Acting For Dummies",
      "1",
      "amazon.com",
      "Amazon",
      "Covina",
      "Ca",
      "91723",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 28, 2015 2:17:00 PM PST",
      "6009371431",
      "Order",
      "107-1438778-5051405",
      "1003582626",
      "Baby Jesus, Jonah, Joseph, & Noah Shaped Bible Board Books",
      "1",
      "amazon.com",
      "Amazon",
      "Thomasville",
      "North Carolina",
      "27360",
      "",
      "14.55",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.18",
      "-3.63",
      "0",
      "0",
      "8.74"
    ],
    [
      "Nov 29, 2015 2:21:21 AM PST",
      "6009371431",
      "Order",
      "113-9951074-6186645",
      "NQ-4FQF-S5SC",
      "Craig Electronics Portable Speaker with USB/Micro SD Slot and Bluetooth Wireless Technology",
      "1",
      "amazon.com",
      "Amazon",
      "DILLSBURG",
      "PA",
      "17019-9544",
      "",
      "21.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.76",
      "-3.63",
      "0",
      "0",
      "16.58"
    ],
    [
      "Nov 29, 2015 8:36:08 AM PST",
      "6009371431",
      "Order",
      "112-3268302-8536213",
      "1002451398",
      "I Spy Christmas:  A Book of Picture Riddles",
      "1",
      "amazon.com",
      "Amazon",
      "ATTICA",
      "MI",
      "48412-9211",
      "",
      "9.99",
      "0",
      "1.37",
      "0",
      "0",
      "0",
      "-1.37",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 29, 2015 11:27:00 AM PST",
      "6009371431",
      "Order",
      "112-5720291-8825837",
      "1003493697",
      "The Clone Wars (Star Wars)",
      "1",
      "amazon.com",
      "Amazon",
      "SANDUSKY",
      "OH",
      "44870-5842",
      "",
      "9.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.74",
      "-1.54",
      "0",
      "0",
      "5.01"
    ],
    [
      "Nov 29, 2015 5:23:20 PM PST",
      "6009371431",
      "Order",
      "103-6382300-8569059",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "marysville",
      "wa",
      "98271",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-1.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Nov 29, 2015 5:23:20 PM PST",
      "6009371431",
      "Order",
      "103-6382300-8569059",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "marysville",
      "wa",
      "98271",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "2.32"
    ],
    [
      "Nov 29, 2015 10:30:26 PM PST",
      "6009371431",
      "Order",
      "104-7573449-4449053",
      "1002517194",
      "Cars (Disney's Wonderful World of Reading)",
      "1",
      "amazon.com",
      "Amazon",
      "HURRICANE",
      "UT",
      "84737-1904",
      "",
      "6.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Nov 29, 2015 10:31:06 PM PST",
      "6009371431",
      "Order",
      "104-0768186-7161030",
      "1002487604",
      "Magical Justice (Enchanted World Series)",
      "1",
      "amazon.com",
      "Amazon",
      "SUNNYVALE",
      "CA",
      "94087-2072",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Nov 29, 2015 11:48:58 PM PST",
      "6009371431",
      "Order",
      "105-6278380-0218629",
      "1002575289",
      "The Bravest Dog Ever: The True Story of Balto (Step-Into-Reading)",
      "1",
      "amazon.com",
      "Amazon",
      "NEWPORT COAST",
      "CA",
      "92657-1061",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Nov 30, 2015 12:35:19 AM PST",
      "6009371431",
      "Order",
      "112-8595704-7134668",
      "1002491035",
      "The Tooth Book (Bright & Early Board Books(TM))",
      "1",
      "amazon.com",
      "Amazon",
      "WESTON",
      "ID",
      "83286-5176",
      "",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.34"
    ],
    [
      "Nov 30, 2015 2:22:21 AM PST",
      "6009371431",
      "Order",
      "109-0764752-0675462",
      "1003144880",
      "Putting Food By",
      "1",
      "amazon.com",
      "Amazon",
      "Chattanooga",
      "TN",
      "37410",
      "",
      "6.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.39",
      "-1.67",
      "0",
      "0",
      "2.89"
    ],
    [
      "Nov 30, 2015 8:27:03 AM PST",
      "6009371431",
      "Order",
      "108-9288081-7720250",
      "1003582933",
      "Time for Bed",
      "1",
      "amazon.com",
      "Amazon",
      "COLUMBUS",
      "TX",
      "78934-2816",
      "",
      "9.99",
      "0",
      "1.87",
      "0",
      "0",
      "0",
      "-1.87",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 30, 2015 9:19:54 AM PST",
      "6009371431",
      "Order",
      "104-1137075-7989039",
      "1003461635",
      "SAMANTHA LEARNS A LESSON - THE AMERICAN GIRLS COLLECTION #2",
      "1",
      "amazon.com",
      "Amazon",
      "Sylva",
      "NC",
      "28779",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Nov 30, 2015 4:55:28 PM PST",
      "6009371431",
      "Order",
      "104-7117315-2792203",
      "1002451398",
      "I Spy Christmas:  A Book of Picture Riddles",
      "1",
      "amazon.com",
      "Amazon",
      "STERLINGTON",
      "LA",
      "71280-3504",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Nov 30, 2015 5:03:20 PM PST",
      "6009371431",
      "Order",
      "106-0808580-5425802",
      "1003752324",
      "The Maze of Bones (39 Clues, No. 1)",
      "1",
      "amazon.com",
      "Amazon",
      "McGehee",
      "Arkansas",
      "71654",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.67",
      "0",
      "0",
      "3.18"
    ],
    [
      "Nov 30, 2015 5:35:20 PM PST",
      "6009371431",
      "Order",
      "114-2989395-4325822",
      "1002914544",
      "Hop-Skip-Jump-A-Roo Zoo (Magic Castle Readers)",
      "1",
      "amazon.com",
      "Amazon",
      "EAGAN",
      "MN",
      "55123-1036",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Nov 30, 2015 6:23:40 PM PST",
      "6009371431",
      "Order",
      "107-3001540-7783457",
      "1002451302",
      "The Magic Dreidels: A Hanukkah Story",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90041-3204",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-1.92",
      "0",
      "0",
      "17.97"
    ],
    [
      "Nov 30, 2015 7:33:57 PM PST",
      "6009371431",
      "Order",
      "112-3095202-2821811",
      "CT-GK75-BJ4A",
      "Nambe Curvo Spatula Bowl Scraper",
      "1",
      "amazon.com",
      "Amazon",
      "DENVER",
      "CO",
      "80203-2057",
      "",
      "17.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-2.67",
      "0",
      "0",
      "12.62"
    ],
    [
      "Dec 1, 2015 1:23:59 AM PST",
      "6009371431",
      "Order",
      "110-9002541-6100211",
      "NQ-4FQF-S5SC",
      "Craig Electronics Portable Speaker with USB/Micro SD Slot and Bluetooth Wireless Technology",
      "1",
      "amazon.com",
      "Amazon",
      "SANDWICH",
      "MA",
      "02563-2012",
      "",
      "21.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.76",
      "-3.63",
      "0",
      "0",
      "16.58"
    ],
    [
      "Dec 1, 2015 4:41:07 AM PST",
      "6009371431",
      "Order",
      "110-5669203-9088258",
      "1002663436",
      "Digz Garden Glove Nitrile Med Knit Latex Free",
      "1",
      "amazon.com",
      "Amazon",
      "LITITZ",
      "PA",
      "17543-8020",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 1, 2015 12:35:03 PM PST",
      "6016084211",
      "Order",
      "111-9115525-1668247",
      "1003449333",
      "The Christmas Miracle of Jonathan Toomey",
      "1",
      "amazon.com",
      "Amazon",
      "CASTLE ROCK",
      "CO",
      "80109-7978",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 1, 2015 1:07:30 PM PST",
      "6016084211",
      "Order",
      "111-2190877-8753848",
      "1002451056",
      "The Snowbelly Family of Chillyville Inn",
      "1",
      "amazon.com",
      "Amazon",
      "MOORPARK",
      "CA",
      "93021-3004",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 1, 2015 8:50:19 PM PST",
      "6016084211",
      "Order",
      "106-0048557-2337873",
      "1003582826",
      "Dinner at the Panda Palace",
      "1",
      "amazon.com",
      "Amazon",
      "Winston Salem",
      "NC",
      "27107",
      "",
      "14.99",
      "0",
      "1.39",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-2.93",
      "0",
      "0",
      "9.85"
    ],
    [
      "Dec 1, 2015 8:58:50 PM PST",
      "6016084211",
      "Order",
      "110-9002541-6100211",
      "NQ-4FQF-S5SC",
      "Craig Electronics Portable Speaker with USB/Micro SD Slot and Bluetooth Wireless Technology",
      "1",
      "amazon.com",
      "Amazon",
      "SANDWICH",
      "MA",
      "02563-2012",
      "",
      "21.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.76",
      "-2.63",
      "0",
      "0",
      "17.58"
    ],
    [
      "Dec 1, 2015 9:55:44 PM PST",
      "6016084211",
      "Order",
      "102-1011698-9046606",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "Mount Dora",
      "Fl",
      "32757-9627",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Dec 2, 2015 12:56:51 AM PST",
      "6016084211",
      "Order",
      "106-8277474-2763418",
      "1003591024",
      "Penny (Breyer Stablemates)",
      "1",
      "amazon.com",
      "Amazon",
      "SARLES",
      "NORTH DAKOTA",
      "58372-9651",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 2, 2015 1:03:29 AM PST",
      "6016084211",
      "Order",
      "110-4439997-4230659",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "HILO",
      "HI",
      "96721",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 2, 2015 2:39:44 AM PST",
      "6016084211",
      "Order",
      "112-3002328-6448233",
      "1003782977",
      "Cuisinart CI670-30BG Chef's Classic Enameled Cast Iron 7-Quart Round Covered Casserole, Provencal Blue",
      "1",
      "amazon.com",
      "Amazon",
      "CLARKSVILLE",
      "TN",
      "37043-6861",
      "",
      "83.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-12.49",
      "-9.87",
      "0",
      "0",
      "60.93"
    ],
    [
      "Dec 2, 2015 8:27:04 AM PST",
      "6016084211",
      "Order",
      "116-3127046-3904233",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "2",
      "amazon.com",
      "Amazon",
      "Cape May Point",
      "NJ",
      "08212",
      "",
      "9.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "3.64"
    ],
    [
      "Dec 2, 2015 10:29:28 AM PST",
      "6016084211",
      "Order",
      "103-2121918-6347408",
      "1002470310",
      "Day of Rhymes",
      "1",
      "amazon.com",
      "Amazon",
      "TRUSSVILLE",
      "AL",
      "35173-1878",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 2, 2015 10:44:51 AM PST",
      "6016084211",
      "Refund",
      "114-6004904-8999419",
      "1003321610",
      "Bending Toward Justice: The Voting Rights Act and the Transformation of American Democracy",
      "1",
      "amazon.com",
      "Amazon",
      "LUBBOCK",
      "TEXAS",
      "79403-3496",
      "",
      "-14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.25",
      "0",
      "0",
      "7.50",
      "-5.24"
    ],
    [
      "Dec 2, 2015 3:54:05 PM PST",
      "6016084211",
      "Order",
      "114-1955307-7631458",
      "2V-D2UM-AZA4",
      "Checkers Folding Game Board 11 3/8\" X 11 3/8\"",
      "1",
      "amazon.com",
      "Amazon",
      "FAIR OAKS",
      "CA",
      "95628-6744",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 2, 2015 8:01:01 PM PST",
      "6016084211",
      "Order",
      "110-0341642-1561849",
      "1003752446",
      "Silver (Pirates of the Caribbean: Jack Sparrow #6)",
      "1",
      "amazon.com",
      "Amazon",
      "Omaha",
      "NE",
      "68130",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 2, 2015 8:03:46 PM PST",
      "6016084211",
      "Order",
      "115-0427517-5172219",
      "1002605238",
      "Number the Stars",
      "1",
      "amazon.com",
      "Amazon",
      "Longview",
      "WA",
      "98632-9571",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Dec 3, 2015 1:06:00 AM PST",
      "6016084211",
      "Refund",
      "106-0048557-2337873",
      "1003582826",
      "Dinner at the Panda Palace",
      "1",
      "amazon.com",
      "Amazon",
      "Winston Salem",
      "NC",
      "27107",
      "",
      "0",
      "0",
      "-1.39",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.39",
      "0",
      "0",
      "0"
    ],
    [
      "Dec 3, 2015 2:29:14 AM PST",
      "6016084211",
      "Order",
      "112-7138009-8156268",
      "1003575488",
      "Ordinary Men: Reserve Police Battalion 101 and the Final Solution in Poland",
      "1",
      "amazon.com",
      "Amazon",
      "WINSTON SALEM",
      "NC",
      "27109-6290",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 3, 2015 2:36:50 AM PST",
      "6016084211",
      "Order",
      "109-3708207-7489854",
      "1002715306",
      "The Apostle (Scot Harvath, Book 8)",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT LOUIS",
      "MO",
      "63123-5314",
      "",
      "9.99",
      "0",
      "1.14",
      "0",
      "0",
      "0",
      "-1.14",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 3, 2015 4:28:45 AM PST",
      "6016084211",
      "Order",
      "116-5479829-8365865",
      "1003591346",
      "Barbie: Princess and the Pauper Jr. Chapter Book",
      "1",
      "amazon.com",
      "Amazon",
      "CORTLAND",
      "OH",
      "44410-1835",
      "",
      "9.99",
      "0",
      "1.59",
      "0",
      "0",
      "0",
      "-1.59",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 3, 2015 6:29:41 AM PST",
      "6016084211",
      "Order",
      "108-6933539-9826626",
      "1003752312",
      "Food Truck Road Trip--A Cookbook: More Than 100 Recipes Collected  from the Best Street Food Vendors Coast to Coast",
      "1",
      "amazon.com",
      "Amazon",
      "los angeles",
      "ca",
      "90020",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Dec 3, 2015 9:12:17 AM PST",
      "6016084211",
      "Order",
      "115-6002443-5161050",
      "1002604999",
      "27 Piece Cookware and Dinnerware Set",
      "1",
      "amazon.com",
      "Amazon",
      "NEWBURGH",
      "NY",
      "12550-3139",
      "",
      "46.20",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.93",
      "-5.19",
      "0",
      "0",
      "34.08"
    ],
    [
      "Dec 3, 2015 6:04:28 PM PST",
      "6016084211",
      "Order",
      "002-5937718-2483461",
      "1003224477",
      "Doctor Dealer: The Rise and Fall of an All-American Boy and His Multimillion-Dollar Cocaine Empire",
      "1",
      "amazon.com",
      "Amazon",
      "Martin",
      "Michigan",
      "49070",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Dec 3, 2015 7:24:41 PM PST",
      "6016084211",
      "Order",
      "115-4838530-7783459",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "Mendham",
      "NJ",
      "07945",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Dec 3, 2015 8:22:44 PM PST",
      "6016084211",
      "Order",
      "109-1776284-8461835",
      "1002368494",
      "The Intersystem Model: Integrating Theory and Practice",
      "1",
      "amazon.com",
      "Amazon",
      "SANDY",
      "UT",
      "84093-5405",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Dec 3, 2015 8:30:02 PM PST",
      "6016084211",
      "Order",
      "108-3335579-9764205",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "MIAMI BEACH",
      "FL",
      "33139-6614",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Dec 3, 2015 10:27:59 PM PST",
      "6016084211",
      "Order",
      "109-2543015-6584242",
      "1003474493",
      "An Irish Doctor in Peace and at War: An Irish Country Novel (Irish Country Books)",
      "1",
      "amazon.com",
      "Amazon",
      "DUNSTABLE",
      "MA",
      "01827-1123",
      "",
      "12.29",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-3.19",
      "-5.41",
      "0",
      "0",
      "7.18"
    ],
    [
      "Dec 4, 2015 2:24:57 AM PST",
      "6016084211",
      "Order",
      "112-4588643-0628202",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "CLAWSON",
      "MI",
      "48017-2903",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 4, 2015 10:06:18 AM PST",
      "6016084211",
      "Order",
      "114-5072989-3317867",
      "1002451523",
      "Amazing Weather Facts and Trivia (Amazing Facts & Trivia)",
      "1",
      "amazon.com",
      "Amazon",
      "Eureka",
      "CA",
      "95502",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Dec 4, 2015 2:26:06 PM PST",
      "6016084211",
      "Order",
      "112-5944780-3589862",
      "1002605068",
      "WowWee Alive 11 Inch Tall Baby Animal Pet Plush with Movement and Sounds - PANDA CUB with Moving Mouth, Soft Fur and Blinking Eyes Plus Adoption Certi",
      "1",
      "amazon.com",
      "Amazon",
      "Lexington",
      "Kentucky",
      "40503",
      "",
      "99.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-15.00",
      "-7.98",
      "0",
      "0",
      "77.01"
    ],
    [
      "Dec 4, 2015 4:08:45 PM PST",
      "6016084211",
      "Order",
      "107-6731803-2198602",
      "1002555773",
      "My Dog Never Says Please (Picture Puffin Books)",
      "1",
      "amazon.com",
      "Amazon",
      "CHULA VISTA",
      "CA",
      "91913-2823",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 4, 2015 5:38:21 PM PST",
      "6016084211",
      "Order",
      "002-1748715-4016229",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Lincoln",
      "CA",
      "95648",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 4, 2015 6:30:37 PM PST",
      "6016084211",
      "Order",
      "115-6349646-2969001",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Corvallis",
      "OR",
      "97330-9548",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 4, 2015 7:29:30 PM PST",
      "6016084211",
      "Order",
      "102-1354002-7949053",
      "1002914221",
      "I Bought My Love/Tabby Cat",
      "1",
      "amazon.com",
      "Amazon",
      "WASHINGTON",
      "DC",
      "20008-5517",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Dec 4, 2015 10:08:34 PM PST",
      "6016084211",
      "Order",
      "106-5345062-7853863",
      "1003782870",
      "The Sharper Image Essential Santoku Knife Set",
      "1",
      "amazon.com",
      "Amazon",
      "HOOVER",
      "AL",
      "35244-4513",
      "",
      "12.95",
      "0",
      "3.56",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.94",
      "-6.23",
      "0",
      "0",
      "8.34"
    ],
    [
      "Dec 4, 2015 10:50:50 PM PST",
      "6016084211",
      "Order",
      "002-3745716-0467412",
      "2V-D2UM-AZA4",
      "Checkers Folding Game Board 11 3/8\" X 11 3/8\"",
      "1",
      "amazon.com",
      "Amazon",
      "lake forest",
      "CA",
      "92630",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-1.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 4, 2015 10:50:50 PM PST",
      "6016084211",
      "Order",
      "002-3745716-0467412",
      "2V-D2UM-AZA4",
      "Checkers Folding Game Board 11 3/8\" X 11 3/8\"",
      "1",
      "amazon.com",
      "Amazon",
      "lake forest",
      "CA",
      "92630",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.67",
      "0",
      "0",
      "2.32"
    ],
    [
      "Dec 5, 2015 5:46:51 AM PST",
      "6016084211",
      "Order",
      "110-2553723-2942632",
      "1002491053",
      "Snow Bugs (Bright & Early Books(R))",
      "1",
      "amazon.com",
      "Amazon",
      "PAPILLION",
      "NE",
      "68133-4816",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 5, 2015 11:22:48 AM PST",
      "6016084211",
      "Order",
      "002-5340697-6322621",
      "1003083223",
      "Shortest Distance Between You and a Published Book",
      "1",
      "amazon.com",
      "Amazon",
      "Los Angeles",
      "CA",
      "90046-2001",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.67",
      "0",
      "0",
      "4.62"
    ],
    [
      "Dec 5, 2015 11:37:38 AM PST",
      "6016084211",
      "Order",
      "106-8747000-4033864",
      "1003032713",
      "Powerpuff Girls Chapter Book #04: Blossoming Out (Powerpuff Girls, Chaper Book)",
      "1",
      "amazon.com",
      "Amazon",
      "SAINT GEORGE",
      "UT",
      "84770-6159",
      "",
      "12.99",
      "0",
      "12.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-14.52",
      "0",
      "0",
      "8.15"
    ],
    [
      "Dec 5, 2015 3:12:53 PM PST",
      "6016084211",
      "Order",
      "105-3214681-1843465",
      "1003187347",
      "Madagascar: Movie Storybook",
      "1",
      "amazon.com",
      "Amazon",
      "TOMS RIVER",
      "NJ",
      "08757-5910",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-1.92",
      "0",
      "0",
      "22.22"
    ],
    [
      "Dec 5, 2015 7:25:57 PM PST",
      "6016084211",
      "Order",
      "113-9426135-9993053",
      "1003242317",
      "My First Superman Book: Touch and Feel (DC Super Heroes)",
      "1",
      "amazon.com",
      "Amazon",
      "NILES",
      "IL",
      "60714-2204",
      "",
      "8.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-1.54",
      "0",
      "0",
      "4.75"
    ],
    [
      "Dec 5, 2015 8:16:59 PM PST",
      "6016084211",
      "Order",
      "107-9902356-5935422",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "BUFFALO",
      "NY",
      "14213-1920",
      "",
      "2.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.69"
    ],
    [
      "Dec 5, 2015 9:28:09 PM PST",
      "6016084211",
      "Order",
      "102-9964870-6953048",
      "1002535575",
      "Holt Literature and Language Arts California: Universal Access Interactive Reader Grade 9",
      "1",
      "amazon.com",
      "Amazon",
      "Fremont",
      "CA",
      "94539",
      "",
      "9.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-2.85",
      "-2.33",
      "0",
      "0",
      "4.81"
    ],
    [
      "Dec 5, 2015 10:36:15 PM PST",
      "6016084211",
      "Order",
      "112-0158637-3269861",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "CEDAR RAPIDS",
      "IA",
      "52404-3371",
      "",
      "3.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Dec 6, 2015 2:11:16 AM PST",
      "6016084211",
      "Order",
      "103-5951418-9419457",
      "1003769473",
      "Knut: How One Little Polar Bear Captivated the World",
      "1",
      "amazon.com",
      "Amazon",
      "St Paul",
      "MN",
      "55107-4012",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 6, 2015 3:46:48 AM PST",
      "6016084211",
      "Order",
      "109-9573056-8244250",
      "1002603305",
      "The Key to the Indian (An Avon Camelot Book)",
      "1",
      "amazon.com",
      "Amazon",
      "REYNOLDSBURG",
      "OH",
      "43068-8570",
      "",
      "6.29",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.29",
      "-1.54",
      "0",
      "0",
      "2.46"
    ],
    [
      "Dec 6, 2015 6:52:33 AM PST",
      "6016084211",
      "Order",
      "110-6257083-9841826",
      "1003006079",
      "Misty the Abandoned Kitten (Holly Webb Animal Stories)",
      "1",
      "amazon.com",
      "Amazon",
      "Lindon",
      "UT",
      "84042",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Dec 6, 2015 9:22:48 AM PST",
      "6016084211",
      "Order",
      "111-9686571-6698617",
      "1003461579",
      "The Face (Fear Street, No. 35)",
      "1",
      "amazon.com",
      "Amazon",
      "WILBER",
      "NE",
      "68465-2568",
      "",
      "14.99",
      "0",
      "2.24",
      "0",
      "0",
      "0",
      "-2.24",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Dec 6, 2015 11:47:03 AM PST",
      "6016084211",
      "Order",
      "113-0803988-9602651",
      "1002975857",
      "Discovering The Inca Ice Maiden",
      "1",
      "amazon.com",
      "Amazon",
      "WINDSOR",
      "IL",
      "61957-1429",
      "",
      "14.99",
      "0",
      "2.13",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-4.05",
      "0",
      "0",
      "9.47"
    ],
    [
      "Dec 6, 2015 3:58:08 PM PST",
      "6016084211",
      "Order",
      "112-4435267-2277864",
      "1002451306",
      "Meet the Monsters",
      "1",
      "amazon.com",
      "Amazon",
      "Wimberley",
      "Texas",
      "78676",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.54",
      "0",
      "0",
      "8.15"
    ],
    [
      "Dec 6, 2015 6:38:52 PM PST",
      "6016084211",
      "Order",
      "105-4211478-8277025",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "2",
      "amazon.com",
      "Amazon",
      "SEDONA",
      "AZ",
      "86351",
      "",
      "19.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-4.34",
      "0",
      "0",
      "12.64"
    ],
    [
      "Dec 6, 2015 8:33:21 PM PST",
      "6016084211",
      "Order",
      "114-5366730-4226643",
      "1003769473",
      "Knut: How One Little Polar Bear Captivated the World",
      "1",
      "amazon.com",
      "Amazon",
      "YANTIC",
      "CONNECTICUT",
      "06389",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 6, 2015 9:45:12 PM PST",
      "6016084211",
      "Order",
      "109-1252267-4720258",
      "1002605294",
      "The Game Of Sunken Places",
      "1",
      "amazon.com",
      "Amazon",
      "New York",
      "NY",
      "10033-1755",
      "",
      "12.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.19",
      "-1.67",
      "0",
      "0",
      "7.43"
    ],
    [
      "Dec 6, 2015 10:32:28 PM PST",
      "6016084211",
      "Order",
      "116-6427393-0792203",
      "1002428455",
      "The Wizard of Oz - You Are in a Story Book",
      "1",
      "amazon.com",
      "Amazon",
      "FRAMINGHAM",
      "MA",
      "01701-3616",
      "",
      "9.99",
      "0",
      "6.46",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-10.09",
      "0",
      "0",
      "4.86"
    ],
    [
      "Dec 7, 2015 1:06:18 AM PST",
      "6016084211",
      "Order",
      "002-7283585-8009833",
      "1002419786",
      "If You Give a Moose a Muffin",
      "1",
      "amazon.com",
      "Amazon",
      "KYLE",
      "TX",
      "78640-5993",
      "",
      "8.99",
      "0",
      "1.95",
      "0",
      "0",
      "0",
      "-1.95",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.37"
    ],
    [
      "Dec 7, 2015 1:53:21 AM PST",
      "6016084211",
      "Order",
      "114-0005916-1991405",
      "1002450930",
      "Ordinary Baby, Extraordinary Gift",
      "1",
      "amazon.com",
      "Amazon",
      "JEANNETTE",
      "PA",
      "15644-1936",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 7, 2015 6:06:02 AM PST",
      "6016084211",
      "Order",
      "107-6383646-9075433",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "LA PUENTE",
      "CA",
      "91746-2539",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 7, 2015 12:05:50 PM PST",
      "6016084211",
      "Order",
      "106-5670593-1916212",
      "1002550859",
      "The Country Bunny and the Little Gold Shoes",
      "1",
      "amazon.com",
      "Amazon",
      "Howard beach",
      "Ny",
      "11414",
      "",
      "8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.59",
      "-1.54",
      "0",
      "0",
      "4.16"
    ],
    [
      "Dec 7, 2015 6:44:32 PM PST",
      "6016084211",
      "Order",
      "002-7688961-8890606",
      "1003769270",
      "Love You Forever",
      "1",
      "amazon.com",
      "Amazon",
      "Santa Monica",
      "CA",
      "90404",
      "",
      "9.99",
      "0",
      "1.99",
      "0",
      "0",
      "0",
      "-1.99",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 7, 2015 6:55:10 PM PST",
      "6016084211",
      "Order",
      "112-0965715-1869846",
      "9G-24UY-ST11",
      "Turbo Wheels [4x4 Mini Racers] Die-cast",
      "1",
      "amazon.com",
      "Amazon",
      "OAK LAWN",
      "IL",
      "60453-2627",
      "",
      "3.00",
      "0",
      "0.92",
      "0",
      "0",
      "0",
      "-0.92",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.67"
    ],
    [
      "Dec 7, 2015 9:05:26 PM PST",
      "6016084211",
      "Order",
      "105-1498202-1140242",
      "1002642016",
      "Common Phrases: And Where They Come From",
      "1",
      "amazon.com",
      "Amazon",
      "WOODBURY",
      "MN",
      "55129-7721",
      "",
      "12.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.19",
      "-1.54",
      "0",
      "0",
      "7.56"
    ],
    [
      "Dec 7, 2015 9:40:00 PM PST",
      "6016084211",
      "Order",
      "116-8503415-0657846",
      "1002419779",
      "Art Dog",
      "1",
      "amazon.com",
      "Amazon",
      "GREENBELT",
      "MD",
      "20770-1773",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.54",
      "0",
      "0",
      "9.85"
    ],
    [
      "Dec 7, 2015 10:52:33 PM PST",
      "6016084211",
      "Order",
      "116-6111235-3441829",
      "0L-SLJ7-RYZD",
      "Scented Bath Salt, 17.6-oz. Jars (Lavender Scented)",
      "2",
      "amazon.com",
      "Amazon",
      "VIDALIA",
      "LA",
      "71373-2901",
      "",
      "19.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.26",
      "0",
      "0",
      "10.72"
    ],
    [
      "Dec 8, 2015 12:33:21 AM PST",
      "6016084211",
      "Order",
      "106-4564650-2086600",
      "1003582967",
      "Boo: The Life of the World's Cutest Dog",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA FE",
      "NM",
      "87505-5445",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Dec 8, 2015 3:26:28 AM PST",
      "6016084211",
      "Order",
      "110-8501255-5378601",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "BURNSVILLE",
      "MN",
      "55306-6198",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 8, 2015 7:40:31 AM PST",
      "6016084211",
      "Order",
      "104-6867693-7646636",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "CARLSBAD",
      "CA",
      "92009-9251",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "1.32"
    ],
    [
      "Dec 8, 2015 9:35:13 AM PST",
      "6016084211",
      "Order",
      "106-7351835-7160208",
      "1002914689",
      "Weekly Reader Children's Book Club Presents The puppy who wanted a boy",
      "1",
      "amazon.com",
      "Amazon",
      "AMESBURY",
      "MA",
      "01913-2113",
      "",
      "12.98",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-6.52",
      "0",
      "0",
      "8.14"
    ],
    [
      "Dec 8, 2015 3:08:10 PM PST",
      "6016084211",
      "Order",
      "116-5574145-6430665",
      "1002584364",
      "Extraordinary Chickens",
      "1",
      "amazon.com",
      "Amazon",
      "NACOGDOCHES",
      "TX",
      "75961-4636",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-1.92",
      "0",
      "0",
      "8.62"
    ],
    [
      "Dec 8, 2015 3:22:54 PM PST",
      "6016084211",
      "Refund",
      "115-6002443-5161050",
      "1002604999",
      "27 Piece Cookware and Dinnerware Set",
      "1",
      "amazon.com",
      "Amazon",
      "NEWBURGH",
      "NY",
      "12550-3139",
      "",
      "-46.20",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "5.54",
      "0",
      "0",
      "0",
      "-40.66"
    ],
    [
      "Dec 8, 2015 5:21:04 PM PST",
      "6016084211",
      "Refund",
      "002-3745716-0467412",
      "2V-D2UM-AZA4",
      "Checkers Folding Game Board 11 3/8\" X 11 3/8\"",
      "1",
      "amazon.com",
      "Amazon",
      "lake forest",
      "CA",
      "92630",
      "",
      "-4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.60",
      "0",
      "0",
      "0",
      "-3.39"
    ],
    [
      "Dec 8, 2015 5:21:04 PM PST",
      "6016084211",
      "Refund",
      "002-3745716-0467412",
      "2V-D2UM-AZA4",
      "Checkers Folding Game Board 11 3/8\" X 11 3/8\"",
      "1",
      "amazon.com",
      "Amazon",
      "lake forest",
      "CA",
      "92630",
      "",
      "-4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.99"
    ],
    [
      "Dec 8, 2015 10:32:59 PM PST",
      "6016084211",
      "Order",
      "107-5278543-7136214",
      "1003590959",
      "Child's Introduction to Ballet: The Stories, Music, and Magic of Classical Dance",
      "1",
      "amazon.com",
      "Amazon",
      "CYPRESS",
      "TX",
      "77429",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 9, 2015 12:31:09 AM PST",
      "6016084211",
      "Order",
      "116-6659386-5301020",
      "1002565210",
      "The Cookie-Store Cat",
      "1",
      "amazon.com",
      "Amazon",
      "Bainbridge Island",
      "WA",
      "98110-3210",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-1.54",
      "0",
      "0",
      "22.60"
    ],
    [
      "Dec 9, 2015 1:22:40 PM PST",
      "6016084211",
      "Order",
      "116-3762158-6777840",
      "CT-GK75-BJ4A",
      "Nambe Curvo Spatula Bowl Scraper",
      "1",
      "amazon.com",
      "Amazon",
      "ROSEVILLE",
      "CA",
      "95747-9637",
      "",
      "17.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.70",
      "-2.67",
      "0",
      "0",
      "12.62"
    ],
    [
      "Dec 9, 2015 2:04:22 PM PST",
      "6016084211",
      "Order",
      "116-6604794-5953063",
      "1002436835",
      "The Polar Express",
      "1",
      "amazon.com",
      "Amazon",
      "GIRARD",
      "KS",
      "66743-1148",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.92",
      "0",
      "0",
      "3.52"
    ],
    [
      "Dec 9, 2015 3:00:12 PM PST",
      "6016084211",
      "Order",
      "002-6584056-9308229",
      "1002543302",
      "The Kid Who Became President",
      "1",
      "amazon.com",
      "Amazon",
      "WHITE PLAINS",
      "NEW YORK",
      "10604-3602",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Dec 9, 2015 6:21:59 PM PST",
      "6016084211",
      "Order",
      "107-0349199-2273076",
      "1002491164",
      "Winnie the Pooh and Tigger Too (Disney's Wonderful World of Reading)",
      "1",
      "amazon.com",
      "Amazon",
      "Newtown Square",
      "PA",
      "19073-2924",
      "",
      "12.21",
      "0",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.18",
      "-4.53",
      "0",
      "0",
      "7.49"
    ],
    [
      "Dec 9, 2015 6:41:05 PM PST",
      "6016084211",
      "Refund",
      "116-6427393-0792203",
      "1002428455",
      "The Wizard of Oz - You Are in a Story Book",
      "1",
      "amazon.com",
      "Amazon",
      "FRAMINGHAM",
      "MA",
      "01701-3616",
      "",
      "-9.99",
      "0",
      "-6.46",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.20",
      "6.46",
      "0",
      "0",
      "-8.79"
    ],
    [
      "Dec 10, 2015 6:57:31 AM PST",
      "6016084211",
      "Order",
      "002-0053996-5312260",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "Grand Blanc",
      "MI",
      "48439",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Dec 10, 2015 6:57:31 AM PST",
      "6016084211",
      "Order",
      "002-0053996-5312260",
      "1002461031",
      "Kids Marvel Accident Kit with Band Aids, Stickers & Tissues",
      "1",
      "amazon.com",
      "Amazon",
      "Grand Blanc",
      "MI",
      "48439",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Dec 10, 2015 10:03:37 AM PST",
      "6016084211",
      "Order",
      "105-1388126-1113008",
      "1002546864",
      "Ambi Peter Penguin Bath Toy",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA CLARA",
      "CA",
      "95051-2561",
      "",
      "22.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-3.63",
      "0",
      "0",
      "15.07"
    ],
    [
      "Dec 10, 2015 2:26:35 PM PST",
      "6016084211",
      "Order",
      "116-4136024-4598650",
      "1002451176",
      "Ship of Dreams",
      "1",
      "amazon.com",
      "Amazon",
      "SARASOTA",
      "FL",
      "34239-6266",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Dec 10, 2015 11:41:18 PM PST",
      "6016084211",
      "Order",
      "002-1856333-5024258",
      "1003473986",
      "Confessions of an Economic Hit Man",
      "1",
      "amazon.com",
      "Amazon",
      "LAS VEGAS",
      "NV",
      "89102-4262",
      "",
      "10.05",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.86",
      "-1.92",
      "0",
      "0",
      "5.27"
    ],
    [
      "Dec 11, 2015 1:18:59 AM PST",
      "6016084211",
      "Order",
      "107-1367544-3167448",
      "1003335445",
      "Something Rotten",
      "1",
      "amazon.com",
      "Amazon",
      "Marshallltown",
      "IA",
      "50158",
      "",
      "9.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.77",
      "-1.92",
      "0",
      "0",
      "4.80"
    ],
    [
      "Dec 11, 2015 2:04:23 AM PST",
      "6016084211",
      "Order",
      "115-8682553-7407452",
      "5T-QNLP-N9DN",
      "Pocket Puzzle Word Find Game (Large Print)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW KENSINGTON",
      "PA",
      "15068-4701",
      "",
      "5.99",
      "0",
      "0.93",
      "0",
      "0",
      "0",
      "-0.93",
      "0",
      "0",
      "-1.00",
      "-2.54",
      "0",
      "0",
      "2.45"
    ],
    [
      "Dec 11, 2015 4:33:54 AM PST",
      "6016084211",
      "Order",
      "115-3080488-4572251",
      "1003582856",
      "Don't Forget Me, Santa Claus",
      "1",
      "amazon.com",
      "Amazon",
      "Lexington",
      "KY",
      "40513",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 11, 2015 11:17:01 AM PST",
      "6016084211",
      "Order",
      "112-0743776-2840268",
      "1002527527",
      "Aloha is...",
      "1",
      "amazon.com",
      "Amazon",
      "HONOMU",
      "HI",
      "96728-0061",
      "",
      "9.99",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 11, 2015 2:56:34 PM PST",
      "6016084211",
      "Order",
      "002-4330975-9894652",
      "1002342482",
      "Wilton Armetale Gourmet Grillware Divided Serving Tray, 8-1/2-Inch by 17-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "HIGHLAND",
      "CA",
      "92346-3992",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-9.00",
      "-3.02",
      "0",
      "0",
      "17.97"
    ],
    [
      "Dec 11, 2015 2:56:34 PM PST",
      "6016084211",
      "Order",
      "002-4330975-9894652",
      "1002342482",
      "Wilton Armetale Gourmet Grillware Divided Serving Tray, 8-1/2-Inch by 17-Inch",
      "1",
      "amazon.com",
      "Amazon",
      "HIGHLAND",
      "CA",
      "92346-3992",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.02",
      "0",
      "0",
      "25.97"
    ],
    [
      "Dec 11, 2015 5:49:39 PM PST",
      "6016084211",
      "Order",
      "115-7221064-2717065",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "Mendham",
      "NJ",
      "07945",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Dec 11, 2015 7:26:25 PM PST",
      "6016084211",
      "Order",
      "113-7355841-7844251",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Wheaton",
      "IL",
      "60187",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 11, 2015 9:19:06 PM PST",
      "6016084211",
      "Order",
      "106-3946905-5587403",
      "1002584306",
      "Quack!",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11238-6108",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.92",
      "0",
      "0",
      "1.82"
    ],
    [
      "Dec 12, 2015 12:46:18 AM PST",
      "6016084211",
      "Order",
      "109-8100929-6713051",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "SPOKANE",
      "WA",
      "99208-9305",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Dec 12, 2015 3:00:39 PM PST",
      "6016084211",
      "Order",
      "116-7091394-7723416",
      "2D-T4Z3-KXUY",
      "What Type of Leader Are You? Using the Enneagram System to Identify and Grow Your Leadership Strengths and Achieve Maximum Success",
      "1",
      "amazon.com",
      "Amazon",
      "Sunland",
      "CA",
      "91040-3238",
      "",
      "15.01",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.49"
    ],
    [
      "Dec 12, 2015 3:31:59 PM PST",
      "6016084211",
      "Order",
      "103-7586151-4302631",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "LAMAR",
      "MO",
      "64759-9232",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 12, 2015 4:08:35 PM PST",
      "6016084211",
      "Order",
      "109-2532352-4783435",
      "1002546974",
      "Playmobil Circus Clown with Flower",
      "1",
      "amazon.com",
      "Amazon",
      "BEVERLY",
      "MA",
      "01915-4814",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.11"
    ],
    [
      "Dec 12, 2015 4:17:16 PM PST",
      "6016084211",
      "Order",
      "109-4953121-2232219",
      "1003769502",
      "The kids' fun-filled question & answer book",
      "1",
      "amazon.com",
      "Amazon",
      "INDEPENDENCE",
      "KANSAS",
      "67301-1629",
      "",
      "8.98",
      "0",
      "1.53",
      "0",
      "0",
      "0",
      "-1.53",
      "0",
      "0",
      "-2.70",
      "-1.92",
      "0",
      "0",
      "4.36"
    ],
    [
      "Dec 12, 2015 7:31:50 PM PST",
      "6016084211",
      "Order",
      "106-0364229-4013853",
      "1002450920",
      "How Do Dinosaurs Say Good Night?",
      "1",
      "amazon.com",
      "Amazon",
      "PLAINFIELD",
      "IL",
      "60544-5600",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Dec 12, 2015 7:38:04 PM PST",
      "6016084211",
      "Order",
      "105-0572872-5196211",
      "1002564696",
      "Geraldine's Big Snow",
      "1",
      "amazon.com",
      "Amazon",
      "MALVERNE",
      "NY",
      "11565-1845",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-1.54",
      "0",
      "0",
      "1.35"
    ],
    [
      "Dec 12, 2015 11:27:53 PM PST",
      "6016084211",
      "Order",
      "106-1281633-8580260",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "ATLANTA",
      "GA",
      "30327-2034",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 13, 2015 2:50:32 AM PST",
      "6016084211",
      "Order",
      "104-5597798-4980247",
      "1002486051",
      "Swindle",
      "1",
      "amazon.com",
      "Amazon",
      "HUBER HEIGHTS",
      "OH",
      "45424-4055",
      "",
      "5.99",
      "0",
      "2.35",
      "0",
      "0",
      "0",
      "-2.35",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Dec 13, 2015 1:54:15 PM PST",
      "6016084211",
      "Order",
      "105-6879350-8173064",
      "VT-VBVF-G5H2",
      "(12ct) Luxurious 100% Cotton Terry Washcloths",
      "1",
      "amazon.com",
      "Amazon",
      "AUSTIN",
      "TX",
      "78752-3949",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.12"
    ],
    [
      "Dec 13, 2015 3:50:57 PM PST",
      "6016084211",
      "Order",
      "114-7954456-3659401",
      "1002527550",
      "Royal Champions: An Enchanted Stables Storybook (Disney Princess (Disney Press Unnumbered))",
      "1",
      "amazon.com",
      "Amazon",
      "ALBUQUERQUE",
      "NM",
      "87114-6101",
      "",
      "17.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.05",
      "-1.92",
      "0",
      "0",
      "12.02"
    ],
    [
      "Dec 13, 2015 7:28:33 PM PST",
      "6016084211",
      "Order",
      "105-9011585-8312235",
      "1002564702",
      "Greetings from Sandy Beach (Public Television Storytime Books)",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60622-3817",
      "",
      "4.99",
      "0",
      "2.14",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.10",
      "-4.06",
      "0",
      "0",
      "0.97"
    ],
    [
      "Dec 13, 2015 10:53:09 PM PST",
      "6016084211",
      "Order",
      "107-2986474-1730627",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "3",
      "amazon.com",
      "Amazon",
      "MADISON",
      "WI",
      "53703-3205",
      "",
      "8.85",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-6.01",
      "0",
      "0",
      "-0.16"
    ],
    [
      "Dec 14, 2015 1:16:58 AM PST",
      "6016084211",
      "Order",
      "106-3239959-1233817",
      "1002543319",
      "Trillion: the Three-Headed Lion (Beast Quest, No. 12)",
      "1",
      "amazon.com",
      "Amazon",
      "San Jose",
      "CA",
      "95130-2141",
      "",
      "19.99",
      "0",
      "2.26",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-3.80",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 14, 2015 2:58:42 AM PST",
      "6016084211",
      "Order",
      "105-0961061-3397820",
      "1003461729",
      "Emma All Stirred Up! (Cupcake Diaries)",
      "1",
      "amazon.com",
      "Amazon",
      "ATLANTA",
      "GA",
      "30312-5332",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 14, 2015 5:05:23 AM PST",
      "6016084211",
      "Order",
      "105-5553669-8284252",
      "1002641972",
      "The Beatrix Potter Knitting Book",
      "1",
      "amazon.com",
      "Amazon",
      "newbern",
      "alabama",
      "36765",
      "",
      "19.31",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-1.74",
      "0",
      "0",
      "-4.25",
      "-1.92",
      "0",
      "0",
      "13.14"
    ],
    [
      "Dec 14, 2015 7:41:09 AM PST",
      "6016084211",
      "Order",
      "114-9187987-2485028",
      "1003591346",
      "Barbie: Princess and the Pauper Jr. Chapter Book",
      "1",
      "amazon.com",
      "Amazon",
      "Guaynabo",
      "PR",
      "00969",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 14, 2015 10:50:06 AM PST",
      "6016084211",
      "Order",
      "108-2539973-7358651",
      "1002368507",
      "In Quest of the Sacred Baboon",
      "1",
      "amazon.com",
      "Amazon",
      "Oakland",
      "CA",
      "94619-2331",
      "",
      "13.94",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.44",
      "-1.92",
      "0",
      "0",
      "8.58"
    ],
    [
      "Dec 14, 2015 1:08:24 PM PST",
      "6016084211",
      "Order",
      "112-7422693-4567413",
      "1003449201",
      "Max & Molly: Max & Molly's Summer",
      "1",
      "amazon.com",
      "Amazon",
      "NEWPORT",
      "RI",
      "02840-3658",
      "",
      "14.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-1.92",
      "0",
      "0",
      "9.47"
    ],
    [
      "Dec 14, 2015 1:24:10 PM PST",
      "6016084211",
      "Order",
      "105-5220180-7120253",
      "1002555956",
      "How Many Ways Can You Cut a Pie? (Magic Castle Readers) by Moncure, Jane Belk (1988) Library Binding",
      "1",
      "amazon.com",
      "Amazon",
      "BURBANK",
      "CA",
      "91505-3872",
      "",
      "25.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.25",
      "-1.54",
      "0",
      "0",
      "19.20"
    ],
    [
      "Dec 14, 2015 2:58:11 PM PST",
      "6016084211",
      "Order",
      "108-4788508-5522602",
      "1003187394",
      "The Lonesome Pine",
      "1",
      "amazon.com",
      "Amazon",
      "SAN PEDRO",
      "CA",
      "90732-2901",
      "",
      "49.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-8.85",
      "-1.92",
      "0",
      "0",
      "39.22"
    ],
    [
      "Dec 14, 2015 6:52:21 PM PST",
      "6016084211",
      "Order",
      "103-2281497-3850616",
      "1003269915",
      "Moving On: Creating Your House of Belonging with Simple Abundance",
      "1",
      "amazon.com",
      "Amazon",
      "CABOOL",
      "MO",
      "65689-9721",
      "",
      "10.71",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.96",
      "-1.67",
      "0",
      "0",
      "6.08"
    ],
    [
      "Dec 14, 2015 10:50:34 PM PST",
      "6016084211",
      "Order",
      "114-3796301-3854651",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "JUNCTION CITY",
      "OR",
      "97448-1309",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 15, 2015 1:40:32 AM PST",
      "6016084211",
      "Refund",
      "106-5670593-1916212",
      "1002550859",
      "The Country Bunny and the Little Gold Shoes",
      "1",
      "amazon.com",
      "Amazon",
      "Howard beach",
      "Ny",
      "11414",
      "",
      "-8.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2.59",
      "0",
      "0",
      "0",
      "-5.70"
    ],
    [
      "Dec 15, 2015 2:25:20 AM PST",
      "6016084211",
      "Order",
      "106-9342732-6577862",
      "1002554639",
      "Dee Gruenig's Rubber Stamp Treasury: Original Ideas for Creative Stationery, Party Paper & Gift Wraps",
      "1",
      "amazon.com",
      "Amazon",
      "HAMILTON",
      "MT",
      "59840-2010",
      "",
      "7.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.44",
      "-1.92",
      "0",
      "0",
      "2.93"
    ],
    [
      "Dec 15, 2015 2:33:23 AM PST",
      "6016084211",
      "Order",
      "116-4548299-2838668",
      "1002914638",
      "The After-Christmas Tree",
      "1",
      "amazon.com",
      "Amazon",
      "Kinston",
      "NC",
      "28501",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.67",
      "0",
      "0",
      "2.07"
    ],
    [
      "Dec 15, 2015 3:26:57 AM PST",
      "6016084211",
      "Order",
      "104-6831394-1083457",
      "1003032447",
      "Fairly Odd Jokes",
      "1",
      "amazon.com",
      "Amazon",
      "BROCKTON",
      "MA",
      "02301-7041",
      "",
      "6.97",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.03"
    ],
    [
      "Dec 15, 2015 3:43:42 AM PST",
      "6016084211",
      "Order",
      "106-7741544-4800204",
      "1002600878",
      "Higby Throws a Fit",
      "1",
      "amazon.com",
      "Amazon",
      "PENSACOLA",
      "FL",
      "32526-5091",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Dec 15, 2015 3:56:37 AM PST",
      "6016084211",
      "Order",
      "108-3121514-8801868",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "TRAFFORD",
      "PA",
      "15085-2824",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 15, 2015 5:52:16 AM PST",
      "6016084211",
      "Order",
      "116-7780870-2078648",
      "1003461055",
      "Castles (Usborne Beginners)",
      "1",
      "amazon.com",
      "Amazon",
      "CAMAS",
      "WA",
      "98607-4211",
      "",
      "7.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.89"
    ],
    [
      "Dec 15, 2015 11:30:09 AM PST",
      "6024405531",
      "Order",
      "115-5792938-5977851",
      "1002352467",
      "Japanese Porcelain Dragonfly Plate, Sauce Dish, and Chopstick Set- 6pcs",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90017-2624",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.50",
      "-4.02",
      "0",
      "0",
      "21.47"
    ],
    [
      "Dec 15, 2015 1:43:30 PM PST",
      "6024405531",
      "Order",
      "116-2524920-5825853",
      "1002451445",
      "The Tailor of Gloucester",
      "1",
      "amazon.com",
      "Amazon",
      "San Antonio",
      "TX",
      "78216",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 15, 2015 2:24:08 PM PST",
      "6024405531",
      "Order",
      "114-3791938-7958625",
      "1003242310",
      "Tomie's Little Mother Goose",
      "1",
      "amazon.com",
      "Amazon",
      "MCARTHUR",
      "CALIFORNIA",
      "96056-8714",
      "",
      "6.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.32",
      "-1.67",
      "0",
      "0",
      "2.50"
    ],
    [
      "Dec 15, 2015 5:15:31 PM PST",
      "6024405531",
      "Order",
      "002-1626925-1913830",
      "1003575331",
      "How Great Generals Win",
      "1",
      "amazon.com",
      "Amazon",
      "NEW BRIGHTON",
      "MN",
      "55112-6416",
      "",
      "11.29",
      "0",
      "5.65",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.04",
      "-7.57",
      "0",
      "0",
      "6.33"
    ],
    [
      "Dec 15, 2015 9:36:28 PM PST",
      "6024405531",
      "Order",
      "110-4279525-3548241",
      "1003335401",
      "Washington's Crossing (Pivotal Moments in American History)",
      "1",
      "amazon.com",
      "Amazon",
      "BOUNTIFUL",
      "UT",
      "84010-2542",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-2.33",
      "0",
      "0",
      "7.36"
    ],
    [
      "Dec 16, 2015 12:40:23 AM PST",
      "6024405531",
      "Order",
      "116-0628485-0064253",
      "1003782784",
      "Home Essentials 322 Set of 4&#44; Metro Solar 16 Oz Highball Glasses",
      "1",
      "amazon.com",
      "Amazon",
      "FARGO",
      "ND",
      "58103-2332",
      "",
      "27.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.20",
      "-4.80",
      "0",
      "0",
      "18.99"
    ],
    [
      "Dec 16, 2015 3:49:48 AM PST",
      "6024405531",
      "Order",
      "116-1317632-2945050",
      "1002537823",
      "Tchaikovsky (Famous Children Series)",
      "1",
      "amazon.com",
      "Amazon",
      "PASADENA",
      "CA",
      "91106-2623",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 16, 2015 4:16:23 AM PST",
      "6024405531",
      "Order",
      "113-4549555-0843435",
      "1003769510",
      "Dog Food (New York Times Best Illustrated Books (Awards))",
      "1",
      "amazon.com",
      "Amazon",
      "WESTPORT",
      "CT",
      "06880-3119",
      "",
      "20.14",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.37",
      "-1.54",
      "0",
      "0",
      "14.23"
    ],
    [
      "Dec 16, 2015 7:53:32 PM PST",
      "6024405531",
      "Refund",
      "107-1438778-5051405",
      "1003582626",
      "Baby Jesus, Jonah, Joseph, & Noah Shaped Bible Board Books",
      "1",
      "amazon.com",
      "Amazon",
      "Thomasville",
      "North Carolina",
      "27360",
      "",
      "-14.55",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1.74",
      "0",
      "0",
      "0",
      "-12.81"
    ],
    [
      "Dec 17, 2015 12:03:55 PM PST",
      "6024405531",
      "Order",
      "109-9219726-8941001",
      "1002601000",
      "Tailor of Gloucester",
      "1",
      "amazon.com",
      "Amazon",
      "GARDINER",
      "NY",
      "12525-5414",
      "",
      "9.99",
      "0",
      "2.86",
      "0",
      "0",
      "0",
      "-2.86",
      "0",
      "0",
      "-2.85",
      "-1.54",
      "0",
      "0",
      "5.60"
    ],
    [
      "Dec 17, 2015 3:49:29 PM PST",
      "6024405531",
      "Order",
      "115-7442073-1839413",
      "1003782844",
      "Barbuzzo Mason Beer Stein, Clear",
      "1",
      "amazon.com",
      "Amazon",
      "PUTNAM VALLEY",
      "NY",
      "10579-1509",
      "",
      "19.05",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.86",
      "-4.41",
      "0",
      "0",
      "11.78"
    ],
    [
      "Dec 17, 2015 7:05:36 PM PST",
      "6024405531",
      "Order",
      "107-1058485-6634650",
      "1002833010",
      "BATTLE A Visual Journey Through 5,000 Years of Combat",
      "1",
      "amazon.com",
      "Amazon",
      "EL PASO",
      "TEXAS",
      "79936-7881",
      "",
      "20.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.50",
      "-3.15",
      "0",
      "0",
      "13.34"
    ],
    [
      "Dec 17, 2015 8:56:09 PM PST",
      "6024405531",
      "Order",
      "002-1911361-0217030",
      "OQ-TX0B-89EL",
      "Life at the Marmont: The Inside Story of Hollywood's Legendary Hotel of the Stars--Chateau Marmont",
      "1",
      "amazon.com",
      "Amazon",
      "DENVER",
      "COLORADO",
      "80202-1937",
      "",
      "9.46",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.77",
      "-1.67",
      "0",
      "0",
      "5.02"
    ],
    [
      "Dec 17, 2015 9:21:34 PM PST",
      "6024405531",
      "Order",
      "103-4083031-1553842",
      "1002543329",
      "The Hork-Bajir Chronicles (Animorphs)",
      "1",
      "amazon.com",
      "Amazon",
      "VALLEY VILLAGE",
      "CA",
      "91607-3709",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Dec 17, 2015 11:49:46 PM PST",
      "6024405531",
      "Order",
      "002-1982396-5355436",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "2",
      "amazon.com",
      "Amazon",
      "GLENCOE",
      "OK",
      "74032-1538",
      "",
      "5.90",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-4.34",
      "0",
      "0",
      "-0.44"
    ],
    [
      "Dec 18, 2015 9:44:06 AM PST",
      "6024405531",
      "Order",
      "108-2802720-6180255",
      "7Z-A1A5-MO81",
      "The Pull of the Moon",
      "1",
      "amazon.com",
      "Amazon",
      "MANITOWOC",
      "WI",
      "54220-4539",
      "",
      "8.88",
      "0",
      "1.24",
      "0",
      "0",
      "0",
      "-1.24",
      "0",
      "0",
      "-2.68",
      "-1.67",
      "0",
      "0",
      "4.53"
    ],
    [
      "Dec 18, 2015 2:34:52 PM PST",
      "6024405531",
      "Order",
      "111-2009483-2314661",
      "1003335190",
      "Texas Hold'em For Dummies",
      "1",
      "amazon.com",
      "Amazon",
      "BATON ROUGE",
      "LA",
      "70816-8514",
      "",
      "10.99",
      "0",
      "2.70",
      "0",
      "0",
      "0",
      "-2.70",
      "0",
      "0",
      "-3.00",
      "-1.54",
      "0",
      "0",
      "6.45"
    ],
    [
      "Dec 18, 2015 10:54:40 PM PST",
      "6024405531",
      "Order",
      "115-9171208-4665044",
      "1002469603",
      "One Direction: The Ultimate Fan Book",
      "1",
      "amazon.com",
      "Amazon",
      "appleton",
      "Wi",
      "54915",
      "",
      "6.81",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.37",
      "-1.92",
      "0",
      "0",
      "2.52"
    ],
    [
      "Dec 19, 2015 2:51:05 AM PST",
      "6024405531",
      "Order",
      "111-5979880-4826632",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "SPRING HILL",
      "TN",
      "37174-2468",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 19, 2015 11:20:52 AM PST",
      "6024405531",
      "Order",
      "116-7145820-6093845",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "MIDDLEBURGH",
      "NY",
      "12122-5106",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 19, 2015 2:19:21 PM PST",
      "6024405531",
      "Order",
      "115-5585856-4618664",
      "VT-VBVF-G5H2",
      "(12ct) Luxurious 100% Cotton Terry Washcloths",
      "1",
      "amazon.com",
      "Amazon",
      "SEBRING",
      "FL",
      "33872-2034",
      "",
      "7.99",
      "0",
      "2.32",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.20",
      "-4.99",
      "0",
      "0",
      "4.12"
    ],
    [
      "Dec 19, 2015 3:26:53 PM PST",
      "6024405531",
      "Order",
      "114-4717734-4673855",
      "1002427914",
      "Native American Night Before Christmas",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60657-6206",
      "",
      "99.99",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-16.35",
      "-5.41",
      "0",
      "0",
      "81.72"
    ],
    [
      "Dec 19, 2015 6:08:58 PM PST",
      "6024405531",
      "Order",
      "113-5510225-0893843",
      "1003461617",
      "College Weekend (Fear Street)",
      "1",
      "amazon.com",
      "Amazon",
      "FAIRFIELD",
      "CT",
      "06824-7845",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.67",
      "0",
      "0",
      "5.47"
    ],
    [
      "Dec 19, 2015 11:46:13 PM PST",
      "6024405531",
      "Order",
      "112-3785308-9688248",
      "1002605026",
      "Smithsonian Dinosaur Set- 15 In. T-rex with Sound Effects, Parasauolophus, Ceratosaurus, Raptor, Tree",
      "1",
      "amazon.com",
      "Amazon",
      "ELIZABETH CITY",
      "NC",
      "27909-6911",
      "",
      "29.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.49",
      "-4.97",
      "0",
      "0",
      "20.49"
    ],
    [
      "Dec 19, 2015 11:46:13 PM PST",
      "6024405531",
      "Order",
      "112-3785308-9688248",
      "1002605026",
      "Smithsonian Dinosaur Set- 15 In. T-rex with Sound Effects, Parasauolophus, Ceratosaurus, Raptor, Tree",
      "1",
      "amazon.com",
      "Amazon",
      "ELIZABETH CITY",
      "NC",
      "27909-6911",
      "",
      "29.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.49",
      "-5.97",
      "0",
      "0",
      "19.49"
    ],
    [
      "Dec 19, 2015 11:48:18 PM PST",
      "6024405531",
      "Order",
      "002-5350520-3523451",
      "1002630285",
      "Knights in Armor (Living History)",
      "1",
      "amazon.com",
      "Amazon",
      "VILAS",
      "NC",
      "28692-9250",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Dec 20, 2015 1:02:38 PM PST",
      "6024405531",
      "Order",
      "113-6027796-8309013",
      "1002451916",
      "The Ghost of Nicholas Greebe",
      "1",
      "amazon.com",
      "Amazon",
      "MESA",
      "ARIZONA",
      "85201-5507",
      "",
      "29.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.85",
      "-1.92",
      "0",
      "0",
      "22.22"
    ],
    [
      "Dec 20, 2015 4:11:00 PM PST",
      "6024405531",
      "Order",
      "116-2900474-1986660",
      "1002546864",
      "Ambi Peter Penguin Bath Toy",
      "1",
      "amazon.com",
      "Amazon",
      "CHESAPEAKE",
      "VA",
      "23322-4610",
      "",
      "22.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-3.63",
      "0",
      "0",
      "15.07"
    ],
    [
      "Dec 20, 2015 6:22:45 PM PST",
      "6024405531",
      "Order",
      "112-7670242-2750619",
      "1003224778",
      "Start at the End: How Companies Can Grow Bigger and Faster by Reversing Their Business Plan",
      "1",
      "amazon.com",
      "Amazon",
      "CHARLOTTE",
      "NC",
      "28277-4608",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.67",
      "0",
      "0",
      "8.02"
    ],
    [
      "Dec 21, 2015 12:26:10 AM PST",
      "6024405531",
      "Order",
      "115-2326600-1849850",
      "1003006209",
      "Bella's Gift (Bella Sara, Book 1)",
      "1",
      "amazon.com",
      "Amazon",
      "COLUMBUS",
      "IN",
      "47203-1217",
      "",
      "5.99",
      "0",
      "1.53",
      "0",
      "0",
      "0",
      "-1.53",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 21, 2015 12:34:24 AM PST",
      "6024405531",
      "Order",
      "109-0462245-4407411",
      "1003054337",
      "Too Big to Fail: The Inside Story of How Wall Street and Washington Fought to Save the Financial System---and Themselves",
      "1",
      "amazon.com",
      "Amazon",
      "NOVI",
      "MI",
      "48374-1832",
      "",
      "13.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.45",
      "-2.33",
      "0",
      "0",
      "8.21"
    ],
    [
      "Dec 21, 2015 1:03:05 AM PST",
      "6024405531",
      "Order",
      "112-0224132-3417052",
      "1003120525",
      "Prince Chuckie (Rugrats Chapter Books)",
      "1",
      "amazon.com",
      "Amazon",
      "MEMPHIS",
      "TN",
      "38134-7503",
      "",
      "6.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.40",
      "-1.54",
      "0",
      "0",
      "3.05"
    ],
    [
      "Dec 21, 2015 8:23:25 AM PST",
      "6024405531",
      "Order",
      "105-9278291-5817835",
      "1003449365",
      "Cat Heaven",
      "1",
      "amazon.com",
      "Amazon",
      "new haven, ct",
      "Connecticut",
      "06515",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.85",
      "-1.92",
      "0",
      "0",
      "5.22"
    ],
    [
      "Dec 21, 2015 10:01:20 AM PST",
      "6024405531",
      "Order",
      "105-1760362-6373829",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11215-6557",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 21, 2015 1:12:22 PM PST",
      "6024405531",
      "Order",
      "106-4322218-0302602",
      "1003449269",
      "Poingo Storybook: Toy Story",
      "1",
      "amazon.com",
      "Amazon",
      "BUFFALO",
      "MN",
      "55313-2748",
      "",
      "12.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-1.92",
      "0",
      "0",
      "7.77"
    ],
    [
      "Dec 21, 2015 1:34:48 PM PST",
      "6024405531",
      "Order",
      "103-8391664-0717818",
      "1002546864",
      "Ambi Peter Penguin Bath Toy",
      "1",
      "amazon.com",
      "Amazon",
      "AUSTIN",
      "TX",
      "78751-4316",
      "",
      "22.00",
      "0",
      "0",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-7.12",
      "0",
      "0",
      "15.07"
    ],
    [
      "Dec 21, 2015 2:29:50 PM PST",
      "6024405531",
      "Order",
      "106-6830395-8371463",
      "1002543423",
      "R-T, Margaret, and the Rats of NIMH",
      "1",
      "amazon.com",
      "Amazon",
      "NORMANDY PARK",
      "WA",
      "98166-3715",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 21, 2015 4:25:01 PM PST",
      "6024405531",
      "Order",
      "107-0093581-0798649",
      "1003335313",
      "Ten Stupid Things Women Do to Mess Up Their Lives",
      "1",
      "amazon.com",
      "Amazon",
      "Laguna Niguel",
      "CA",
      "92677",
      "",
      "8.99",
      "0",
      "1.51",
      "0",
      "0",
      "0",
      "-1.51",
      "0",
      "0",
      "-2.70",
      "-1.67",
      "0",
      "0",
      "4.62"
    ],
    [
      "Dec 21, 2015 4:46:49 PM PST",
      "6024405531",
      "Order",
      "112-1306794-6449858",
      "1003752488",
      "My Life as a Cowboy Cowpie (The Incredible Worlds of Wally McDoogle #19)",
      "1",
      "amazon.com",
      "Amazon",
      "RAMSEY",
      "MN",
      "55303-6063",
      "",
      "4.88",
      "0",
      "2.06",
      "0",
      "0",
      "0",
      "-2.06",
      "0",
      "0",
      "-2.08",
      "-1.54",
      "0",
      "0",
      "1.26"
    ],
    [
      "Dec 21, 2015 7:18:53 PM PST",
      "6024405531",
      "Order",
      "103-3049785-5298639",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "fairfield",
      "CT",
      "06825",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 21, 2015 7:41:10 PM PST",
      "6024405531",
      "Order",
      "108-3663079-3221828",
      "H1-2K69-QR20",
      "Il Mulino Gourmet Steak Knives (Set of 4)",
      "1",
      "amazon.com",
      "Amazon",
      "CRYSTAL FALLS",
      "MI",
      "49920-1124",
      "",
      "26.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.05",
      "-3.63",
      "0",
      "0",
      "19.31"
    ],
    [
      "Dec 21, 2015 9:27:27 PM PST",
      "6024405531",
      "Order",
      "102-9545798-8716214",
      "1002508896",
      "Jive Wire Universal Selfie Stick",
      "1",
      "amazon.com",
      "Amazon",
      "BELLEVUE",
      "IOWA",
      "52031-1345",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-2.54",
      "0",
      "0",
      "14.45"
    ],
    [
      "Dec 21, 2015 11:05:04 PM PST",
      "6024405531",
      "Order",
      "105-2332690-1841848",
      "1002547014",
      "Hot Wings Apache AH-64 (With military Markings)",
      "1",
      "amazon.com",
      "Amazon",
      "Trabuco Canyon",
      "CA",
      "92679-1142",
      "",
      "9.83",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.47",
      "-2.67",
      "0",
      "0",
      "5.69"
    ],
    [
      "Dec 21, 2015 11:17:55 PM PST",
      "6024405531",
      "Order",
      "104-0961097-8549040",
      "1002715459",
      "The Wind in the Willows",
      "1",
      "amazon.com",
      "Amazon",
      "COLORADO SPRINGS",
      "CO",
      "80908-3910",
      "",
      "24.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-2.33",
      "0",
      "0",
      "17.56"
    ],
    [
      "Dec 21, 2015 11:29:03 PM PST",
      "6024405531",
      "Order",
      "107-0374855-1076247",
      "IM-QXL0-GHDK",
      "Scented Bath Salt, 17.6-oz. Jars (Rose Scented)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW PORT RICHEY",
      "FL",
      "34655-4146",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-3.63",
      "0",
      "0",
      "4.86"
    ],
    [
      "Dec 22, 2015 2:20:30 AM PST",
      "6024405531",
      "Order",
      "109-3426178-3162611",
      "1002547014",
      "Hot Wings Apache AH-64 (With military Markings)",
      "1",
      "amazon.com",
      "Amazon",
      "GAINESVILLE",
      "FL",
      "32609-8603",
      "",
      "9.83",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.47",
      "-2.67",
      "0",
      "0",
      "5.69"
    ],
    [
      "Dec 22, 2015 2:32:27 AM PST",
      "6024405531",
      "Order",
      "116-8734303-7304253",
      "1002543413",
      "Zathura: The Movie (Junior Novel)",
      "1",
      "amazon.com",
      "Amazon",
      "New Market",
      "AL",
      "35761",
      "",
      "7.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.55",
      "-1.54",
      "0",
      "0",
      "3.90"
    ],
    [
      "Dec 22, 2015 7:21:25 AM PST",
      "6024405531",
      "Order",
      "002-1461788-7162646",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "BOWLING GREEN",
      "KENTUCKY",
      "42104-8593",
      "",
      "9.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 22, 2015 9:27:09 AM PST",
      "6024405531",
      "Order",
      "115-9311432-4269025",
      "1002487667",
      "Spells and Bindings (Enchanted World Series)",
      "1",
      "amazon.com",
      "Amazon",
      "WICHITA FALLS",
      "TX",
      "76308-4202",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.92",
      "0",
      "0",
      "6.92"
    ],
    [
      "Dec 22, 2015 10:26:48 AM PST",
      "6024405531",
      "Order",
      "110-2916737-1413020",
      "1003460860",
      "Missing (Fear Street, No. 4)",
      "1",
      "amazon.com",
      "Amazon",
      "SANTA ANA",
      "CA",
      "92706-1404",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 22, 2015 12:45:54 PM PST",
      "6024405531",
      "Order",
      "104-3254600-2329832",
      "1003727775",
      "Marquis by Waterford Vintage Stemless Wine Glass, Red, Set of 4",
      "1",
      "amazon.com",
      "Amazon",
      "SAN ANTONIO",
      "TX",
      "78259-2447",
      "",
      "39.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-6.00",
      "-4.41",
      "0",
      "0",
      "29.58"
    ],
    [
      "Dec 22, 2015 1:45:27 PM PST",
      "6024405531",
      "Order",
      "002-6099021-6900263",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Orange",
      "CA",
      "92869",
      "",
      "9.99",
      "0",
      "10.01",
      "0",
      "3.49",
      "0",
      "0",
      "0",
      "0",
      "-1.50",
      "-16.17",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 22, 2015 1:46:46 PM PST",
      "6024405531",
      "Order",
      "110-3899018-5501864",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "BROOKLYN",
      "NY",
      "11214-2016",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 22, 2015 6:58:15 PM PST",
      "6024405531",
      "Order",
      "108-4572904-7823411",
      "1002728450",
      "Jinx On The Divide (Divide Trilogy)",
      "1",
      "amazon.com",
      "Amazon",
      "LOS ANGELES",
      "CA",
      "90031-1132",
      "",
      "12.29",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.19",
      "-1.92",
      "0",
      "0",
      "7.18"
    ],
    [
      "Dec 22, 2015 9:07:21 PM PST",
      "6024405531",
      "Order",
      "110-8092796-0573868",
      "1002604914",
      "Redi-Twist 3-Stage Reverse Osmosis Drinking Water Filtration System",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CA",
      "95135-1120",
      "",
      "189.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-28.50",
      "-12.27",
      "0",
      "0",
      "149.22"
    ],
    [
      "Dec 22, 2015 11:27:58 PM PST",
      "6024405531",
      "Order",
      "113-0768456-7907460",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "WINCHESTER",
      "CA",
      "92596-8415",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 23, 2015 1:02:28 AM PST",
      "6024405531",
      "Order",
      "107-4672817-5443469",
      "1002975857",
      "Discovering The Inca Ice Maiden",
      "1",
      "amazon.com",
      "Amazon",
      "FAIRFIELD",
      "CA",
      "94534-7997",
      "",
      "14.98",
      "0",
      "4.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.60",
      "-6.90",
      "0",
      "0",
      "9.46"
    ],
    [
      "Dec 23, 2015 1:50:25 AM PST",
      "6024405531",
      "Order",
      "002-9969331-1993035",
      "1003224574",
      "Wolfgang Puck Makes It Easy: Delicious Recipes for Your Home Kitchen",
      "1",
      "amazon.com",
      "Amazon",
      "Gainesville",
      "FL",
      "32607-1452",
      "",
      "11.78",
      "0",
      "1.87",
      "0",
      "0",
      "0",
      "-1.87",
      "0",
      "0",
      "-3.12",
      "-2.33",
      "0",
      "0",
      "6.33"
    ],
    [
      "Dec 23, 2015 2:52:08 AM PST",
      "6024405531",
      "Order",
      "108-3597100-6794635",
      "1003782844",
      "Barbuzzo Mason Beer Stein, Clear",
      "1",
      "amazon.com",
      "Amazon",
      "NORTHGLENN",
      "CO",
      "80234-3342",
      "",
      "19.05",
      "0",
      "5.36",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.86",
      "-9.77",
      "0",
      "0",
      "11.78"
    ],
    [
      "Dec 23, 2015 11:17:00 AM PST",
      "6024405531",
      "Order",
      "110-6869016-6613008",
      "1002565219",
      "Snowshoe Thompson (An I Can Read Book)",
      "1",
      "amazon.com",
      "Amazon",
      "NEW CANAAN",
      "CT",
      "06840-5511",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 23, 2015 3:42:43 PM PST",
      "6024405531",
      "Order",
      "107-7400093-4421829",
      "1003867859",
      "The Resistance",
      "1",
      "amazon.com",
      "Amazon",
      "STAATSBURG",
      "NY",
      "12580",
      "",
      "5.98",
      "0",
      "2.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-4.53",
      "0",
      "0",
      "2.19"
    ],
    [
      "Dec 23, 2015 7:41:21 PM PST",
      "6024405531",
      "Order",
      "114-2184086-0547433",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "scottsdale",
      "Arizona",
      "85257",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 23, 2015 8:07:59 PM PST",
      "6024405531",
      "Order",
      "108-4105055-4841847",
      "1002555695",
      "Swan Fake (Cinderella Cleaners #6)",
      "1",
      "amazon.com",
      "Amazon",
      "WESTERVILLE",
      "OHIO",
      "43081-8937",
      "",
      "5.49",
      "0",
      "1.28",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.17",
      "-2.82",
      "0",
      "0",
      "1.78"
    ],
    [
      "Dec 24, 2015 2:18:16 AM PST",
      "6024405531",
      "Order",
      "107-9078582-6625019",
      "1002527541",
      "Santa's Snow Cat",
      "1",
      "amazon.com",
      "Amazon",
      "WARRINGTON",
      "PA",
      "18976-1703",
      "",
      "22.80",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.77",
      "-1.92",
      "0",
      "0",
      "16.11"
    ],
    [
      "Dec 24, 2015 8:30:15 AM PST",
      "6024405531",
      "Order",
      "104-3679561-9913032",
      "1003867924",
      "Possibilities",
      "1",
      "amazon.com",
      "Amazon",
      "Santa Isable",
      "PUERTO RICO",
      "00757",
      "",
      "24.99",
      "0",
      "8.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-5.10",
      "-10.52",
      "0",
      "0",
      "18.35"
    ],
    [
      "Dec 24, 2015 9:45:24 AM PST",
      "6024405531",
      "Order",
      "108-5430070-1674603",
      "1004188486",
      "Lauren Ralph Lauren \"Club Porcelain\" Salad Plates - Set of 4 - White",
      "2",
      "amazon.com",
      "Amazon",
      "MENDHAM",
      "NJ",
      "07945-3058",
      "",
      "79.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-12.00",
      "-11.72",
      "0",
      "0",
      "56.26"
    ],
    [
      "Dec 24, 2015 10:05:53 AM PST",
      "6024405531",
      "Order",
      "116-2027237-1576219",
      "41-HGEK-UAQP",
      "Yardley Moisturizing Bar Naturally, Natural Oatmeal and Almond - 4.25 Oz",
      "1",
      "amazon.com",
      "Amazon",
      "GLENVILLE",
      "NY",
      "12302-4528",
      "",
      "2.95",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.00",
      "-2.67",
      "0",
      "0",
      "-0.72"
    ],
    [
      "Dec 25, 2015 12:26:02 PM PST",
      "6024405531",
      "Refund",
      "110-8092796-0573868",
      "1002604914",
      "Redi-Twist 3-Stage Reverse Osmosis Drinking Water Filtration System",
      "1",
      "amazon.com",
      "Amazon",
      "SAN JOSE",
      "CA",
      "95135-1120",
      "",
      "-189.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "23.50",
      "0",
      "0",
      "0",
      "-166.49"
    ],
    [
      "Dec 26, 2015 11:44:56 AM PST",
      "6024405531",
      "Adjustment",
      "",
      "1002491164",
      "FBA Inventory Reimbursement - Damaged:Warehouse",
      "1",
      "",
      "",
      "",
      "",
      "",
      "",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "7.49",
      "7.49"
    ],
    [
      "Dec 26, 2015 1:40:41 PM PST",
      "6024405531",
      "Order",
      "105-6681699-4313008",
      "1003867892",
      "Addicted",
      "1",
      "amazon.com",
      "Amazon",
      "Kent",
      "WA",
      "98042-4528",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.54",
      "0",
      "0",
      "7.30"
    ],
    [
      "Dec 27, 2015 7:30:27 PM PST",
      "6024405531",
      "Order",
      "113-4227459-2773059",
      "1004215779",
      "SentrySafe X055 Security Safe, 0.5 Cubic Feet, Black",
      "1",
      "amazon.com",
      "Amazon",
      "GREENSBURG",
      "PA",
      "15601-8949",
      "",
      "59.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-9.00",
      "-11.49",
      "0",
      "0",
      "39.50"
    ],
    [
      "Dec 28, 2015 1:24:10 AM PST",
      "6024405531",
      "Order",
      "110-6002975-2241807",
      "1003868315",
      "Love, Lucy",
      "1",
      "amazon.com",
      "Amazon",
      "Ferndale",
      "WASHINGTON",
      "98248",
      "",
      "10.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.00",
      "-1.92",
      "0",
      "0",
      "6.07"
    ],
    [
      "Dec 28, 2015 5:21:33 AM PST",
      "6024405531",
      "Order",
      "107-4097822-8185021",
      "1002555963",
      "John and Tom (Vermont Folklife Center Children's Book Series)",
      "1",
      "amazon.com",
      "Amazon",
      "MONTPELIER",
      "VERMONT",
      "05602-8549",
      "",
      "11.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.15",
      "-1.54",
      "0",
      "0",
      "7.30"
    ],
    [
      "Dec 28, 2015 7:47:17 AM PST",
      "6024405531",
      "Order",
      "106-9665853-4273035",
      "1002461265",
      "Vitamin D Combo Pack",
      "1",
      "amazon.com",
      "Amazon",
      "Avon",
      "Indiana",
      "46123",
      "",
      "7.99",
      "0",
      "2.79",
      "0",
      "0",
      "0",
      "-2.79",
      "0",
      "0",
      "-1.20",
      "-2.67",
      "0",
      "0",
      "4.12"
    ],
    [
      "Dec 28, 2015 8:47:54 AM PST",
      "6024405531",
      "Order",
      "103-7249921-5393802",
      "1003867904",
      "My Everything",
      "1",
      "amazon.com",
      "Amazon",
      "Mahopac",
      "NY",
      "10541-3824",
      "",
      "19.99",
      "0",
      "8.98",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-10.52",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 28, 2015 1:56:52 PM PST",
      "6024405531",
      "Order",
      "104-3556178-8479404",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "GLENDALE",
      "CA",
      "91203-1927",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.00",
      "-2.67",
      "0",
      "0",
      "0.32"
    ],
    [
      "Dec 28, 2015 1:56:52 PM PST",
      "6024405531",
      "Order",
      "104-3556178-8479404",
      "0I-3CKH-8D0X",
      "Ultra Soft Facial Tissues, White Unscented",
      "1",
      "amazon.com",
      "Amazon",
      "GLENDALE",
      "CA",
      "91203-1927",
      "",
      "4.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-1.67",
      "0",
      "0",
      "3.32"
    ],
    [
      "Dec 28, 2015 7:36:20 PM PST",
      "6024405531",
      "Order",
      "113-4768427-5303429",
      "1002486284",
      "The End",
      "1",
      "amazon.com",
      "Amazon",
      "BAYFIELD",
      "WI",
      "54814-3406",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.54",
      "0",
      "0",
      "14.10"
    ],
    [
      "Dec 28, 2015 9:33:34 PM PST",
      "6024405531",
      "Order",
      "112-9533185-1273012",
      "1003940093",
      "Frindle",
      "1",
      "amazon.com",
      "Amazon",
      "CHICAGO",
      "IL",
      "60608-3350",
      "",
      "5.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.25",
      "-1.54",
      "0",
      "0",
      "2.20"
    ],
    [
      "Dec 28, 2015 11:14:15 PM PST",
      "6024405531",
      "Order",
      "105-7307870-8951401",
      "1004145237",
      "Princeton Readings in Political Thought",
      "1",
      "amazon.com",
      "Amazon",
      "PIERRE",
      "SD",
      "57501-3549",
      "",
      "22.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.80",
      "-2.33",
      "0",
      "0",
      "15.86"
    ],
    [
      "Dec 28, 2015 11:30:57 PM PST",
      "6024405531",
      "Order",
      "112-6018183-3819415",
      "1004226541",
      "The Ten Faces of Innovation: IDEO's Strategies for Defeating the Devil's Advocate and Driving Creativity Throughout Your Organization",
      "1",
      "amazon.com",
      "Amazon",
      "GILBERT",
      "AZ",
      "85298-0712",
      "",
      "8.64",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.65",
      "-1.92",
      "0",
      "0",
      "4.07"
    ],
    [
      "Dec 29, 2015 2:25:02 AM PST",
      "6024405531",
      "Order",
      "109-3943067-3899457",
      "1003335219",
      "River Marked (Mercy Thompson, Book 6)",
      "1",
      "amazon.com",
      "Amazon",
      "ATHENS",
      "GA",
      "30606-6609",
      "",
      "16.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.90",
      "-1.92",
      "0",
      "0",
      "11.17"
    ],
    [
      "Dec 30, 2015 2:29:16 AM PST",
      "6032232991",
      "Order",
      "112-3783550-1354600",
      "1002663175",
      "Womens Medium-large Pink Purple Nitril Coated Garden Gloves for Light or Heavy Gardening Outdoors (3 Pack)",
      "1",
      "amazon.com",
      "Amazon",
      "Cordova",
      "TN",
      "38016",
      "",
      "9.99",
      "0",
      "1.02",
      "0",
      "0",
      "0",
      "-1.02",
      "0",
      "0",
      "-1.50",
      "-2.67",
      "0",
      "0",
      "5.82"
    ],
    [
      "Dec 30, 2015 9:19:53 PM PST",
      "6032232991",
      "Order",
      "108-6782335-9677014",
      "1002487592",
      "The Book of Beginnings (Enchanted World Series)",
      "1",
      "amazon.com",
      "Amazon",
      "MC LEAN",
      "VA",
      "22102-3995",
      "",
      "19.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-4.35",
      "-1.92",
      "0",
      "0",
      "13.72"
    ],
    [
      "Dec 31, 2015 10:34:58 AM PST",
      "6032232991",
      "Order",
      "112-4371796-2989861",
      "1003460849",
      "The Wrong Number (Fear Street, No. 5)",
      "1",
      "amazon.com",
      "Amazon",
      "CORONA",
      "CA",
      "92881-0717",
      "",
      "7.49",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-2.47",
      "-1.54",
      "0",
      "0",
      "3.48"
    ],
    [
      "Dec 31, 2015 2:28:36 PM PST",
      "6032232991",
      "Order",
      "002-9866736-4771427",
      "1002546864",
      "Ambi Peter Penguin Bath Toy",
      "1",
      "amazon.com",
      "Amazon",
      "ROCHESTER",
      "NY",
      "14608-1087",
      "",
      "22.00",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-3.63",
      "0",
      "0",
      "15.07"
    ],
    [
      "Dec 31, 2015 3:38:49 PM PST",
      "6032232991",
      "Order",
      "108-8031622-4129026",
      "1004249962",
      "Melissa & Doug Let's Play House! Fridge Groceries",
      "1",
      "amazon.com",
      "Amazon",
      "METAIRIE",
      "LA",
      "70002-3812",
      "",
      "21.99",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "-3.30",
      "-2.67",
      "0",
      "0",
      "16.02"
    ]
  ]
}
