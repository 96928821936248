import React from "react";
import { connect } from "react-redux";
import ConditionNotesFlow from "./ConditionNotesFlow";
import { Card, CardBody, Col, Input } from "reactstrap";
import conditionNoteAction from "../../../redux/condition_notes/actions";
import PropTypes from 'prop-types';
import TooltipAtCustomElement from "../../../shared/components/TooltipAtCustomElement";
import "./style.css";
import DeleteDialog from "../../../shared/components/dialogs/DeleteDialog";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const { fetchConditionNoteList, deleteConditionNote, updateLocalConditionPriority, syncLocalConditionNotePriority } = conditionNoteAction;

class ManageConditionNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            removeId: null,
            sorting: "",
            sort: false
        };
    }
    componentDidMount() {
        this.props.fetchConditionNoteList();
    }
    getUniqueParamByKey(key) {
        let list = [...new Set(this.props.conditionNoteList.filter(note => {
            return (note[key] !== "All Categories") || key !== "category"
        }).map((note) => {
            return note[key]
        }))];
        if (key === "category") {
          list.unshift("All Categories");
        }
        list.sort();
        if (!this.state.selectedCategory && key === "category") {
          this.setState({
            selectedCategory: list[0]
          })
        }
        return list;
    }
    removeNote = id => {
        this.props.deleteConditionNote(id);
        this.setState({ removeId: null })
    }
    renderConditionNotes() {
        const {
            conditionNoteList,
            conditionNoteLocalPriorityList,
            editNote,
            updateLocalConditionPriority
        } = this.props;

        const {
            selectedCategory,
            selectedSubCategory
        } = this.state;

        if (!conditionNoteList) {
            return (
                <CardBody>
                    <p>Loading...</p>
                </CardBody>
            )
        }
        if (conditionNoteList.length === 0) {
            return (
                <CardBody>
                    <p>No condition notes found</p>
                </CardBody>
            )
        }

        const filteredconditionNoteList = conditionNoteList.sort((note1, note2) => {
            return (note1.priority - note2.priority);
        }).filter((note) => {
            return (!selectedCategory || selectedCategory === "All Categories"
                || note.category === selectedCategory) && (
                    !selectedSubCategory ||
                    note.subcategory === selectedSubCategory
                )
        }).map(note => {
            const updatedPriority = conditionNoteLocalPriorityList.find(notePriority => {
                return notePriority.id === note.id;
            }) || {
                priority: note.priority
            };
            return {
                ...note,
                ...updatedPriority
            }
        })

        return (
        <React.Fragment>
          <ConditionNotesFlow
            filteredconditionNoteList={filteredconditionNoteList}
            onConfirmRemove={id => this.setState({ removeId: id })}
            editNote={editNote}
            updateLocalConditionPriority={updateLocalConditionPriority}
            syncLocalConditionNotePriority={this.syncLocalConditionNotePriority}
            sort={this.state.sort}
            sorting={this.state.sorting}
          />
          <DeleteDialog
            isOpen={this.state.removeId !== null}
            dialogTitle="Are you sure want to delete this condition note?"
            dialogText="You will loose the note and won't be able to access it when creating batch."
            confirmText="Yes, delete condition note"
            deleteItem={() => this.removeNote(this.state.removeId)}
            close={() => this.setState({ removeId: null })}
          />
        </React.Fragment>
        )
    }
    syncLocalConditionNotePriority = () => {
        this.props.syncLocalConditionNotePriority(this.props.conditionNoteLocalPriorityList)
    }

    onSort = () => {
        this.setState({ sort: true });
        if(this.state.sorting == "asc"){
          this.setState({ sorting: "desc"});
        }else{
          this.setState({ sorting: "asc"});
        }	
      }
    
    render() {
        const {
            conditionNoteList,
            disableForm
        } = this.props;
   
        return (
            <Card className="mb-4">
                <CardBody>
                    <div className="row">
                        <Col sm={4} className="section-title d-flex align-items-center">
                            <h6 onClick={this.onSort} role="button" className="mb-0">Manage Condition Notes <SortingOrder sorting={this.state.sorting} /></h6>&nbsp;
                            <TooltipAtCustomElement
                                tooltipId="ManageConditionNotes"
                                tooltipText="Click title to sort condition notes list"
                                CustomElement={IconTooltip}
                                styles={{ maxWidth: "20rem" }}
                            />
                        </Col>
                        {conditionNoteList && (<Col sm={4} style={{ display: "flex"}}>
                            <Input
                                type="select"
                                disabled={disableForm}
                                name="selected_category"
                                id="selected_category"
                                onChange={(e) => {
                                    this.setState({
                                        selectedCategory: e.target.value
                                    })
                                }} >
                                {this.getUniqueParamByKey('category').map(category => {
                                    return (
                                        <option key={category} value={category}>{category}</option>
                                    )
                                })}
                            </Input>
                        </Col>)}
                        {conditionNoteList && (<Col sm={4}>
                            <Input
                                type="select"
                                disabled={disableForm}
                                name="selected_subcategory"
                                id="selected_subcategory"
                                onChange={(e) => {
                                    this.setState({
                                        selectedSubCategory: e.target.value
                                    })
                                }} >
                                <option value="">All (Sub Category)</option>
                                {this.getUniqueParamByKey('subcategory').map(category => {
                                    return (
                                        <option key={category} value={category}>{category}</option>
                                    )
                                })}
                            </Input>
                        </Col>)}
                    </div>
                </CardBody>
                {this.renderConditionNotes()}
            </Card>
        )
    }
}

const SortingOrder = props => (
	<span className={`order ${props.sorting}`}>
		<span style={{ display: props.sorting== 'asc' ? 'none' : '' }} className="dropdown"><span className="caret"></span></span>
		<span style={{ display: props.sorting== 'desc' ? 'none' : '' }} className="dropup"><span className="caret"></span></span>
	</span>
)


ManageConditionNotes.propTypes = {
    editNote: PropTypes.func,
};

export default connect(
    state => ({
        ...state.ConditionNotes.toJS()
    }),
    { fetchConditionNoteList, deleteConditionNote, updateLocalConditionPriority, syncLocalConditionNotePriority }
)(ManageConditionNotes);