import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Label, Input, Collapse, InputGroup, InputGroupAddon,
} from 'reactstrap';
import InputAmountType from '../../../../../../../shared/components/Form/InputAmountType';
import repricerActions from '../../../../../../../redux/repricer/actions';
import AlertTooltip from '../../../components/AlertTooltip';
import TemplateTooltip from '../../../components/TemplateTooltip';

const { workingTemplateUpdateJson } = repricerActions;

class BackupScenario3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repriceAmountType: '$',
      repriceType: 'none',
		repriceAmount: 0,
		repriceUpDown: "below",
		repriceUsing: "adjustment",
		price_guard_activate: false,
		price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee: false,
		price_guard_rule_min_price: 1,
    }
  }

	UNSAFE_componentWillMount(){
		var repriceType;
		if(!this.props.workingTemplate.template_json.reprice_rule_for_failed_reprice_general_failed_activate){
			repriceType = 'none';
		}
		if(this.props.workingTemplate.template_json.reprice_rule_for_failed_reprice_general_failed_activate){
			repriceType = 'custom';
		}
		this.setState({
			repriceAmountType: this.props.workingTemplate.template_json.general_failed_rule_fix_price_adjustment_if_true_percentage_if_false ? '$' : '%',
			repriceAmount: this.props.workingTemplate.template_json.general_failed_rule_adjustment_amount_on_your_own_offer,
			repriceType: repriceType,
			repriceUpDown: this.props.workingTemplate.template_json.general_failed_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false ? "above" : "below",
			repriceUsing: this.props.workingTemplate.template_json.general_failed_rule_use_adjustment_if_true_use_default_price_if_false ? "adjustment" : "default_price",
			price_guard_activate: this.props.workingTemplate.template_json.general_failed_rule_price_guard_activate ? true : false,
			price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee: this.props.workingTemplate.template_json.general_failed_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee? true: false,
			price_guard_rule_min_price: this.props.workingTemplate.template_json.general_failed_rule_price_guard_rule_min_price,
		});
	}

  toggleRepriceAmountType = () => {
    const currentType = this.state.repriceAmountType;
    this.setState({
      repriceAmountType: currentType === '$' ? '%' : '$'
    });
  }

  toggleRepriceType = (type) => {
	var data = {};
	this.setState({ repriceType: type });
	if(type === "none"){
		data = {
			reprice_rule_for_failed_reprice_general_failed_activate: false,
		}
	}
	if(type === "custom"){
		data = {
			reprice_rule_for_failed_reprice_general_failed_activate: true,
		}
	}

	this.props.workingTemplateUpdateJson({ data: data })
  }
	updateRepriceUsing = (e) => {
		this.setState({ repriceUsing: e.target.value });
		this.props.workingTemplateUpdateJson(
			{
				data: {
					general_failed_rule_use_adjustment_if_true_use_default_price_if_false: e.target.value === "adjustment" ? true : false,
				}
			})
		if(e.target.value !== "adjustment"){
			this.setState({ repriceAmountType: '$' });
		}
	}

	updateRepriceUpDown = (e) => {
		this.setState({ repriceUpDown: e.target.value });
		this.props.workingTemplateUpdateJson(
			{
				data: {
					general_failed_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false: e.target.value === "above" ? true : false,
				}
			})
	}

	updateRepriceAmount = (amount) => {
		this.setState({ repriceAmount: amount });
	}

	changePriceGuardActivate = (e) => {
		this.setState({ price_guard_activate: !this.state.price_guard_activate});
		this.props.workingTemplateUpdateJson(
			{
				data: {
					general_failed_rule_price_guard_activate: !this.state.price_guard_activate
				}
			})
	}

	changePriceGuardMinPrice = (e) => {
		this.setState({ price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee: !this.state.price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee});
		this.props.workingTemplateUpdateJson(
			{
				data: {
					general_failed_rule_price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee: !this.state.price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee
				}
			})
	}

	updateMinPrice = (e) => {
		this.setState({ price_guard_rule_min_price: e.target.value });
		this.props.workingTemplateUpdateJson(
			{
				data: {
					general_failed_rule_price_guard_rule_min_price: e.target.value
				}
			})

	}

  render() {
    const {
      id
    } = this.props;

    return (
      <Fragment>
        <FormGroup>
          <FormGroup check className="custom-check radio">
            <Label check>
				<Input
					type="radio"
					name={`${id}_repriceType`}
					checked={this.state.repriceType === 'none'}
					onChange={() => this.toggleRepriceType('none')}
				/> <span>Do not reprice</span>
            </Label>
            <Label check>
				<Input
					type="radio"
					name={`${id}_repriceType`}
					checked={this.state.repriceType === 'custom'}
					onChange={() => this.toggleRepriceType('custom')}
				/> <span>Use custom repricing strategy</span>
            </Label>
          </FormGroup>
        </FormGroup>

        <Collapse isOpen={this.state.repriceType === 'custom'}>
          <div className="custom-repricing">
            <FormGroup className="d-flex align-items-center mb-0">
              <span>Reprice using a </span>
			  <Input
				  type="select"
				  className="mx-2 w-auto"
				  onChange={e => this.updateRepriceUsing(e)}
				  value={this.state.repriceUsing}
			  >
				  <option value="adjustment">adjustment</option>
				  <option value="default_price">default price</option>
              </Input>
              <span>of</span>
              <InputAmountType
                amountType={this.state.repriceAmountType}
                toggle={this.toggleRepriceAmountType}
                className="ml-2"
				updateAmount={this.updateRepriceAmount}
				updateTemplateJson={this.props.workingTemplateUpdateJson}
				dataInTemplateJson="general_failed_rule_adjustment_amount_on_your_own_offer"
				typeDataInTemplateJson="general_failed_rule_fix_price_adjustment_if_true_percentage_if_false"
				value={this.state.repriceAmount ? this.state.repriceAmount.toString() : ""}
				displayOnlyOneType={!this.props.workingTemplate.template_json.general_failed_rule_adjustment_rule_static_method_adjust_up_if_true_down_if_false}
				displayOnlyOneTypeOption="$"
				min={0}
              />
			  {this.props.workingTemplate.template_json.general_failed_rule_use_adjustment_if_true_use_default_price_if_false ?
			  <Input
				  type="select"
				  className="mx-2 w-auto"
				  onChange={e => this.updateRepriceUpDown(e)}
				  value={this.state.repriceUpDown}
			  >
				<option value="above">above your original price</option>
				<option value="below">below your original price</option>
              </Input>
				: null
				}
							<AlertTooltip
								target="alert_bs3_custom_repricing"
								message={this.props.templateWarning}
								range={[24, 32]}
							/>
            </FormGroup>
			<br />
            <FormGroup check className="custom-check checkbox">
              <Label check>
				  <Input
					  type="checkbox"
					  checked={this.state.price_guard_activate}
					  onChange={e => this.changePriceGuardActivate(e)}
				  /> <span>Price guard activate</span>
							<TemplateTooltip
								id="t-strategy-backup-sc3-price-guard"
								text="If price guard should active"
							/>
              </Label>
            </FormGroup>
			{this.state.price_guard_activate ?
			<React.Fragment>
            <FormGroup>
			  <Label>Min price: </Label>
	              <InputGroup className="input-group-inline ml-2" style={{ width: '10rem' }}>
					<InputGroupAddon addonType="prepend">$</InputGroupAddon>
					  <Input
						  type="number"
						  onChange={e => this.updateMinPrice(e)}
						  value={this.state.price_guard_rule_min_price}
					  />
				</InputGroup>
				<TemplateTooltip
					id="t-strategy-backup-sc3-guard-min"
					text="Minimum price"
				/>
			</FormGroup>
            <FormGroup check className="custom-check checkbox">
				<Label check>
					<Input
						type="checkbox"
						checked={this.state.price_guard_rule_default_min_inventory_price_to_buy_cost_plus_fee}
						onChange={e => this.changePriceGuardMinPrice(e)}
					/> <span>Default min inventory price to buy cost plus fee</span>
					<TemplateTooltip
						id="t-strategy-backup-sc3-guard-default"
						text="Default min inventory price to buy cost plus fee"
					/>
				</Label>
			</FormGroup>
			</React.Fragment>
			: null
			}

          </div>
        </Collapse>

      </Fragment>

	);
  }
}

export default connect(
  state => ({
	  //workingTemplate: state.Repricer.get("workingTemplate"),
  }),
  {
	workingTemplateUpdateJson,
  }
)(BackupScenario3);
