import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
  summary_data: null,
  summary_data_working: false,
  category_sales_data: null,
  category_sales_data_working: false,
  product_sales_data: null,
  product_sales_data_working: false,
  product_sales_chart_data: null,
  product_sales_chart_data_working: false,
  summary_data_export_working: false,
  category_data_export_working: false,
  product_data_export_working: false,
});

export default function salesReducer(state = initState, action) {
	switch (action.type) {

	  case actions.GET_PRODUCT_EXPORT:
			return state
        .set("product_data_export_working", true)
	  case actions.GET_PRODUCT_EXPORT_SUCCESS:
			return state
        .set("product_data_export_working", false)
	  case actions.GET_PRODUCT_EXPORT_FAILED:
			return state
        .set("product_data_export_working", false)

	  case actions.GET_CATEGORY_EXPORT:
			return state
        .set("category_data_export_working", true)
	  case actions.GET_CATEGORY_EXPORT_SUCCESS:
			return state
        .set("category_data_export_working", false)
	  case actions.GET_CATEGORY_EXPORT_FAILED:
			return state
        .set("category_data_export_working", false)

	  case actions.GET_SUMMARY_EXPORT:
			return state
        .set("summary_data_export_working", true)
	  case actions.GET_SUMMARY_EXPORT_SUCCESS:
			return state
        .set("summary_data_export_working", false)
	  case actions.GET_SUMMARY_EXPORT_FAILED:
			return state
        .set("summary_data_export_working", false)

	  case actions.GET_SALES_CHART:
			return state
        .set("product_sales_chart_data_working", true)
	  case actions.GET_SALES_CHART_SUCCESS:
			return state
				.set("product_sales_chart_data", {...action.data})
        .set("product_sales_chart_data_working", false)
	  case actions.GET_SALES_CHART_FAILED:
			return state
				.set("product_sales_chart_data", null)
        .set("product_sales_chart_data_working", false)

	  case actions.GET_PRODUCT_SALES:
			return state
        .set("product_sales_data_working", true)
	  case actions.GET_PRODUCT_SALES_SUCCESS:
			return state
				.set("product_sales_data", {...action.data})
        .set("product_sales_data_working", false)
	  case actions.GET_PRODUCT_SALES_FAILED:
			return state
				.set("product_sales_data", null)
        .set("product_sales_data_working", false)

	  case actions.GET_CATEGORY_SALES:
			return state
        .set("category_sales_data_working", true)
	  case actions.GET_CATEGORY_SALES_SUCCESS:
			return state
				.set("category_sales_data", {...action.data})
        .set("category_sales_data_working", false)
	  case actions.GET_CATEGORY_SALES_FAILED:
			return state
				.set("category_sales_data", null)
        .set("category_sales_data_working", false)

	  case actions.GET_SUMMARY:
			return state
        .set("summary_data_working", true)
	  case actions.GET_SUMMARY_SUCCESS:
			return state
				.set("summary_data", {...action.data})
        .set("summary_data_working", false)
	  case actions.GET_SUMMARY_FAILED:
			return state
				.set("summary_data", null)
        .set("summary_data_working", false)

	default:
		return state;
	}
}
