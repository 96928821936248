const inventoryActions = {
  FETCH_INVENTORY_ITEMS: "FETCH_INVENTORY_ITEMS",
  FETCH_INVENTORY_ITEMS_SUCCESS: "FETCH_INVENTORY_ITEMS_SUCCESS",

  CHANGE_INVENTORY_TABLE_FIELD_DATA: "CHANGE_INVENTORY_TABLE_FIELD_DATA",
  CHANGE_INVENTORY_TABLE_FIELD_DATA_SUCCESS: "CHANGE_INVENTORY_TABLE_FIELD_DATA_SUCCESS",

  UPLOAD_INVENTORY_ITEMS_FILE: "UPLOAD_INVENTORY_ITEMS_FILE",

  SET_UPLOAD_INVENTORY_ITEMS_FILE_JOB_ID: "SET_UPLOAD_INVENTORY_ITEMS_FILE_JOB_ID",

  FETCH_STRANDED_ITEMS: "FETCH_STRANDED_ITEMS",
  FETCH_STRANDED_ITEMS_SUCCESS: "FETCH_STRANDED_ITEMS_SUCCESS",
  FETCH_STRANDED_ITEMS_EXPORT_SUCCESS: "FETCH_STRANDED_ITEMS_EXPORT_SUCCESS",

	GET_INVENTORY_STATS: "GET_INVENTORY_STATS",
	GET_INVENTORY_STATS_SUCCESS: "GET_INVENTORY_STATS_SUCCESS",
	GET_INVENTORY_STATS_FAILED: "GET_INVENTORY_STATS_FAILED",

	UPDATE_INVENTORY: "UPDATE_INVENTORY",
	UPDATE_INVENTORY_SUCCESS: "UPDATE_INVENTORY_SUCCESS",
	UPDATE_INVENTORY_FAILED: "UPDATE_INVENTORY_FAILED",

	UPDATE_INVENTORY_FIELD: "UPDATE_INVENTORY_FIELD",
	UPDATE_INVENTORY_FIELD_SUCCESS: "UPDATE_INVENTORY_FIELD_SUCCESS",

  INVENTORY_DOWNLOAD: "INVENTORY_DOWNLOAD",
  INVENTORY_DOWNLOAD_SUCCESS: "INVENTORY_DOWNLOAD_SUCCESS",
  INVENTORY_DOWNLOAD_FAILED: "INVENTORY_DOWNLOAD_FAILED",

  INVENTORY_ITEM_DELETE: "INVENTORY_ITEM_DELETE",
  INVENTORY_ITEM_DELETE_SUCCESS: "INVENTORY_ITEM_DELETE_SUCCESS",
  INVENTORY_ITEM_DELETE_FAILED: "INVENTORY_ITEM_DELETE_FAILED",

  INVENTORY_ITEM_EXPIRE_GET: "INVENTORY_ITEM_EXPIRE_GET",
  INVENTORY_ITEM_EXPIRE_GET_SUCCESS: "INVENTORY_ITEM_EXPIRE_GET_SUCCESS",
  INVENTORY_ITEM_EXPIRE_GET_FAILED: "INVENTORY_ITEM_EXPIRE_GET_FAILED",

  INVENTORY_ITEM_ACTIVATE: "INVENTORY_ITEM_ACTIVATE",
  INVENTORY_ITEM_ACTIVATE_SUCCESS: "INVENTORY_ITEM_ACTIVATE_SUCCESS",
  INVENTORY_ITEM_ACTIVATE_FAILED: "INVENTORY_ITEM_ACTIVATE_FAILED",

  INVENTORY_DOWNLOAD_CHECK: "INVENTORY_DOWNLOAD_CHECK",
  INVENTORY_DOWNLOAD_CHECK_SUCCESS: "INVENTORY_DOWNLOAD_CHECK_SUCCESS",
  INVENTORY_DOWNLOAD_CHECK_FAILED: "INVENTORY_DOWNLOAD_CHECK_FAILED",

  invantoryDownloadCheck: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD_CHECK,
		data
  }),
  invantoryDownloadCheckSuccess: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD_CHECK_SUCCESS,
		data
  }),
  invantoryDownloadCheckFailed: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD_CHECK_FAILED,
		data
  }),

  inventoryItemActivate: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_ACTIVATE,
		data
  }),
  inventoryItemActivateSuccess: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_ACTIVATE_SUCCESS,
		data
  }),
  inventoryItemActivateFailed: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_ACTIVATE_FAILED,
		data
  }),

  inventoryItemExpireGet: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_EXPIRE_GET,
		data
  }),
  inventoryItemExpireGetSuccess: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_EXPIRE_GET_SUCCESS,
		data
  }),
  inventoryItemExpireGetFailed: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_EXPIRE_GET_FAILED,
		data
  }),

  inventoryItemDelete: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_DELETE,
		data
  }),
  inventoryItemDeleteSuccess: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_DELETE_SUCCESS,
		data
  }),
  inventoryItemDeleteFailed: (data) => ({
		type: inventoryActions.INVENTORY_ITEM_DELETE_FAILED,
		data
  }),

  invantoryDownload: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD,
		data
  }),
  invantoryDownloadSuccess: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD_SUCCESS,
		data
  }),
  invantoryDownloadFailed: (data) => ({
		type: inventoryActions.INVENTORY_DOWNLOAD_FAILED,
		data
  }),

  updateInventoryField: (data) => ({
		type: inventoryActions.UPDATE_INVENTORY_FIELD,
		data
  }),
  updateInventoryFieldSuccess: (data) => ({
		type: inventoryActions.UPDATE_INVENTORY_FIELD_SUCCESS,
		data
  }),

  updateInventory: (data) => ({
		type: inventoryActions.UPDATE_INVENTORY,
		data
  }),
  updateInventorySuccess: (data) => ({
		type: inventoryActions.UPDATE_INVENTORY_SUCCESS,
		data
  }),
  updateInventoryFailed: () => ({
		type: inventoryActions.UPDATE_INVENTORY_FAILED,
  }),


  getInventoryStats: (data) => ({
    type: inventoryActions.GET_INVENTORY_STATS,
    data
  }),
  getInventoryStatsSuccess: (data) => ({
		type: inventoryActions.GET_INVENTORY_STATS_SUCCESS,
		data
  }),
  getInventoryStatsFailed: () => ({
	  type: inventoryActions.GET_INVENTORY_STATS_FAILED,
  }),


  fetchStrandedItems: (channel) => ({
	  type: inventoryActions.FETCH_STRANDED_ITEMS,
	  channel
  }),

  fetchStrandedItemsSuccess: strandedItems => ({
    type: inventoryActions.FETCH_STRANDED_ITEMS_SUCCESS,
    strandedItems
  }),

  fetchStrandedItemsExportSuccess: strandedItems => ({
    type: inventoryActions.FETCH_STRANDED_ITEMS_EXPORT_SUCCESS,
    strandedItems
  }),

	fetchInventoryItems: (channel, status, page, per_page, sort, sort_order,
		search_string) => ({
    type: inventoryActions.FETCH_INVENTORY_ITEMS,
    channel,
    status,
    page,
    per_page,
    sort,
	sort_order,
	search_string,
  }),

  fetchInventoryItemsSuccess: inventoryItems => ({
    type: inventoryActions.FETCH_INVENTORY_ITEMS_SUCCESS,
    inventoryItems
  }),

  setUploadInventoryItemsFileJobId: jobId => ({
    type: inventoryActions.SET_UPLOAD_INVENTORY_ITEMS_FILE_JOB_ID,
    jobId
  }),

  uploadInventoryItemsFile: inventoryItemsData => ({
    type: inventoryActions.UPLOAD_INVENTORY_ITEMS_FILE,
    inventoryItemsData
  }),

  changeInventoryTableFieldData: newTableRowData => ({
    type: inventoryActions.CHANGE_INVENTORY_TABLE_FIELD_DATA,
    newTableRowData
  }),

  changeInventoryTableFieldDataSuccess: newTableRowData => ({
    type: inventoryActions.CHANGE_INVENTORY_TABLE_FIELD_DATA_SUCCESS,
    newTableRowData
  }),

};

export default inventoryActions;
