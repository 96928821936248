export const boxContentManagerVideoWistiaID = "g5jwdhr457";
export const HeadwayAccount = "Jlw5o7";
export const frontChatUserIdentity = "fef4eb07437a75adbc9c934cc67e7bb6";
export const wootricAccountToken = "NPS-b03b54db";
export const incomeReportUrl = '/dashboard/accounting/income_report/view';
export const incomeReportUrlUpload = '/dashboard/accounting/income_report';
export const featureRequestsURL = "https://accelerlist.repo-log.com/";
//export const featureRequestsURL = "https://accelerlist.upvoty.com/b/feature-requests-accelerlist/?filterSort=top";
export const accelerlistHelpDocsURL = "https://accelerlist.helpjuice.com/";
export const creatingShippingTemplatesHelpDocsURL = "https://accelerlist.helpjuice.com/143962-merchant-fulfilled-listing/creating-shipping-templates";
export const customSkuHelpDocsURL = "https://accelerlist.helpjuice.com/143971-creating-a-batch/creating-custom-skus";
export const stripePublicKey = 'pk_live_X7oiEfIKSOgrcFxqldQ5aOY6';
//export const stripePublicKey = 'pk_test_yM5H8yrzg8dzukLn4ypJI44J';
export const desktopAppDownloadURLWIN = 'https://rennbrookventuresllc.app.box.com/s/4pbni8v47ftzr305y44av83h4qau5wub/file/762015809847';
export const desktopAppDownloadURLMAC = 'https://rennbrookventuresllc.app.box.com/s/26ni7ebw0oc4fwgwdlrxjidt5211le6p';
