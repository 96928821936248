import actions from "./actions";
import { Map } from "immutable/dist/immutable";

const initState = new Map({
	printLabelPdfStarted: false,
	printLabel30upStarted: false,
	printAccountingExpenses: false,
	printPLExpenses: false,
});

export default function exportReducer(state = initState, action) {
  switch (action.type) {
    case actions.EXPORT_PL:
			return state.set("printPLExpenses", true);
    case actions.EXPORT_PL_SUCCESS:
			return state.set("printPLExpenses", false);
    case actions.EXPORT_PL_FAILED:
		  return state.set("printPLExpenses", false);

    case actions.EXPORT_LABEL_PDF:
			return state.set("printLabelPdfStarted", true);
    case actions.EXPORT_LABEL_PDF_SUCCESS:
			return state.set("printLabelPdfStarted", false);
    case actions.EXPORT_LABEL_PDF_FAILED:
		  return state.set("printLabelPdfStarted", false);

    case actions.EXPORT_LABEL_30UP:
			return state.set("printLabel30upStarted", true);
    case actions.EXPORT_LABEL_30UP_SUCCESS:
			return state.set("printLabel30upStarted", false);
    case actions.EXPORT_LABEL_30UP_FAILED:
		  return state.set("printLabel30upStarted", false);

    case actions.EXPORT_EXPENSES:
			return state.set("printAccountingExpenses", true);
    case actions.EXPORT_EXPENSES_SUCCESS:
			return state.set("printAccountingExpenses", false);
    case actions.EXPORT_EXPENSES_FAILED:
		  return state.set("printAccountingExpenses", false);

  default:
      return state;
  }
}
