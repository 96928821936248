import {momentDateToISOFormatConversioniWithFormat} from '../utility';
import {EbayCustomSKUSelectorInitialTags} from '../settings/custom_sku_selector_initial_tags';

export const ebay_LengthUnitOfMeasureEnum = [ "INCH", "FEET", "CENTIMETER", "METER" ];

export const ebay_PackageTypeEnum = [
  "LETTER", "BULKY_GOODS", "CARAVAN", "CARS", "EUROPALLET",
  "EXPANDABLE_TOUGH_BAGS", "EXTRA_LARGE_PACK", "FURNITURE",
  "INDUSTRY_VEHICLES", "LARGE_CANADA_POSTBOX",
  "LARGE_CANADA_POST_BUBBLE_MAILER", "LARGE_ENVELOPE", "MAILING_BOX",
  "MEDIUM_CANADA_POST_BOX", "MEDIUM_CANADA_POST_BUBBLE_MAILER",
  "MOTORBIKES", "ONE_WAY_PALLET", "PACKAGE_THICK_ENVELOPE", "PADDED_BAGS",
  "PARCEL_OR_PADDED_ENVELOPE", "ROLL", "SMALL_CANADA_POST_BOX",
  "SMALL_CANADA_POST_BUBBLE_MAILER", "TOUGH_BAGS", "UPS_LETTER",
  "USPS_FLAT_RATE_ENVELOPE", "USPS_LARGE_PACK", "VERY_LARGE_PACK",
  "WINE_PAK"];

export const ebay_WeightUnitOfMeasureEnum = [ "POUND", "KILOGRAM", "OUNCE", "GRAM" ];

export const ebay_condition_mapping = {
	"NewItem": "NEW",
	"UsedLikeNew": "LIKE_NEW",
	"UsedVeryGood": "USED_VERY_GOOD",
	"UsedGood": "USED_GOOD",
	"UsedAcceptable": "USED_ACCEPTABLE",
	"CollectibleLikeNew": "USED_EXCELLENT",
	"CollectibleVeryGood": "USED_VERY_GOOD",
	"CollectibleGood": "USED_GOOD",
	"CollectibleAcceptable": "USED_ACCEPTABLE",
	"NotUsed": "NEW_OTHER",
	"Refurbished": "SELLER_REFURBISHED",
}

export const ebay_condition_short = {
	"NEW": "N",
	"LIKE_NEW": "LN",
	"USED_VERY_GOOD": "U-LN",
	"USED_GOOD": "U-G",
	"USED_ACCEPTABLE": "U-A",
	"USED_EXCELLENT": "U-E",
	"USED_VERY_GOOD": "U-VG",
	"USED_GOOD": "U-G",
	"USED_ACCEPTABLE": "U-A",
	"NEW_OTHER": "N-O",
	"SELLER_REFURBISHED": "SR",
}

/*
 * based on https://developer.ebay.com/api-docs/sell/inventory/types/slr:ConditionEnum
 */
export const ebay_condition_descriptions_by_number = {
  "1000": "This condition indicates that the inventory item is a brand-new, unopened item in its original packaging",
  "1500": "This condition indicates that the inventory item is a new, unused item, but it may be missing the original packaging or perhaps not sealed. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "1750": "This condition indicates that the inventory item is a new, unused item, but it has defects. This item condition is typically applicable to clothing or shoes that may have scuffs, hanging threads, missing buttons, etc. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "2000": "This condition indicates that the inventory item is in pristine, like-new condition and has been inspected, cleaned and refurbished by the manufacturer or a manufacturer-approved vendor. The item will be in new packaging with original or new accessories",
  "2500": "This condition indicates that the inventory item has been restored to working order by the eBay seller or a third party. This means the item was inspected, cleaned, and repaired to full working order and is in excellent condition. This item may or may not be in original packaging. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "2750": "This condition indicates that the inventory item is in 'like-new' condition. In other words, the item has been opened, but very lightly used if used at all. This condition typically applies to books or DVDs",
  "3000": "This condition indicates that the inventory item is used but in excellent condition. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "4000": "This condition indicates that the inventory item is used but in very good condition. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "5000": "This condition indicates that the inventory item is used but in good condition. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "6000": "This condition indicates that the inventory item is in acceptable condition. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition",
  "7000": "This condition indicates that the inventory item is not fully functioning as originally designed. A buyer will generally buy an item in this condition knowing that the item will need to be repaired, or a buyer will buy that item just to use one or more of the parts/components that comprise the item. When a seller specifies this condition for an item, that seller should also provide a more detailed explanation of the item's condition"
}

export function get_ebay_condition_short(condition){
  let cond = ebay_condition_short[condition];
  if(!cond){
    cond = condition;
  }
  return cond;
}

export function ebay_condition_mapping_for_select(allowed=null){
	const data = ebay_condition_mapping;
	if(!allowed || typeof allowed === 'object'){
		return Object.keys(data).map(item => {
			return {value: data[item], label: item}
		});
	} else {
		var select_data = [];
		Object.keys(data).forEach(item => {
			if(allowed.includes(item)){
				select_data.push({value: data[item], label: item})
			}
		});
		return select_data;
	}
}

export function ebay_generate_item_url(listing_id){
	let url = 'https://www.ebay.com/itm/';
	return url + listing_id;
}

export function ebay_condition_mapping_select(a_cat){
	let res = null;
	const data = ebay_condition_mapping;
	Object.keys(data).forEach(item => {
		if(item === a_cat){
			res = {value: data[item], label: item};
		}
	});
	return res;
}

export function ebay_item_data_validation(key, value, direct_listing=false){
	try{
		if(key === 'title'){
      if(!value){
        if(direct_listing){
          return false;
        } else {
          value = ""
        }
      }
			if(value.length > 79){
				return false;
			}
		}
		if(key === 'sub_title'){
      if(!value){
        value = ""
      }
			if(value.length > 54){
				return false;
			}
		}
    if(key === 'dimensions'){
      if(!value){ return false; }
      const required_keys_object = {
        "dimensions": ["height", "length", "unit", "width"],
        "weight": ["unit", "value"],
      }
      // check if structure is there
      for(var i=0; i<Object.keys(required_keys_object).length; i++){
        var main_key = Object.keys(required_keys_object)[i];
        if(!Object.keys(value).includes(main_key)){
          return false;
        }
        for(var b=0; b<required_keys_object[main_key].length; b++){
          var sub_key = required_keys_object[main_key][b];
          if(!Object.keys(value[main_key]).includes(sub_key)){
            return false;
          }
          if(!value[main_key][sub_key]){
            return false;
          }
        }
      }
      if(!Object.keys(value).includes("packageType")){
        return false;
      }
      if(!value.packageType){
        return false;
      }
		}
		if(key === 'identifier'){
      if(!value){ return false; }
		}
		if(key === 'identifier_type'){
      if(!value){ return false; }
		}
		if(key === 'description'){
      if(!value?.trim()){ return false; }
		}
		if(key === 'ebay_category'){
      if(!value){ return false; }
		}
		if(key === 'ebay_condition'){
      if(!value){ return false; }
		}
		if(key === 'qty'){
      if(!value){ return false; }
		}
		if(key === 'sku'){
      if(!value){ return false; }
		}
    if(key === 'price'){
      if(!value){ value = 0 }
      if(!value){
        return false;
      }
    }
    if(key === 'description'){
			if(!value){ value = "" }
      if(!value){
				return false;
			}

    }
    if(key === 'category'){
      if(!value){
				return false;
			}
    }
    if(key === 'condition'){
      if(!value){
				return false;
			}
    }
    if(key === 'quantity'){
      if(!value){ value = 0 }
      if(!value){
        return false;
      }
    }
    if(key === 'images'){
      if(!value){ value = 0 }
      if(!value || !Array.isArray(value) || value.length === 0){
        return false;
      }
    }
    if(key === 'best_offer'){
      if(value && value.best_offer_enabled){
        if(value.best_offer_auto_accept){
          if(!value){ value = 0 }
          if(!value){
            return false;
          }
        }
        if(value.best_offer_auto_decline){
          if(!value){ value = 0 }
          if(!value){
            return false;
          }
        }
        if(value.best_offer_auto_accept < value.best_offer_auto_decline){
          return false;
        }
      }
    }
	} catch (err) {
		console.error('ebay_item_data_validation', err);
	}
	return true;
}

/**
 * mapping to endpoint to list item
 **/
export function ebay_direct_list_mapping_for_send(item){
  item.seller_sku = item.sku;
  item.ebay_qty = item.qty;
  item.ebay_price = item.price;
  item.item_name = item.title;
  item.item_subtitle = item.sub_title;
  item.ebay_description = item.description;
  item.img_urls = item.images;
  item.category_ebay = item.ebay_category;
  item.fulfillment_channel = 'DEFAULT';
  if(!item.asin1){
    item.asin1 = "";
  }
  //add aspects
  if(!item.ebay_additional_fields){
    item.ebay_additional_fields = [];
  }
  item.ebay_additional_fields.push(
    {
      "desc": {
          "label": item.identifier_type,
      },
      "value": item.identifier,
    },
  )
  item.is_manually_listed = true;
  return item;
}

/**
 * generate custom SKU
 */
export function generateEbayCustomSKU(template, item){
  if(!template || !item){ return ""; }
  template = template.split('-');
  let sku = template.map(element => {
    if(element[0] === "{" && element[element.length -1 ] === "}"){
      element = element.substring(1, element.length -1);

      if(!EbayCustomSKUSelectorInitialTags.unusedTags.find(item => item.value === element)){
        return element;
      }

      if(element === 'listing_date_mmddyy'){
        return momentDateToISOFormatConversioniWithFormat(null, "MMDDYY");
      } else if(element === 'condition'){
        if(item.ebay_condition){
          return get_ebay_condition_short(item.ebay_condition.value);
        }
      } else {
        return item[element];
      }
    } else {
      return element;
    }
  });
  return sku.join('-');
}
