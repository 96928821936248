import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { iconWarning } from '../../../../../assets/images';
import PropTypes from "prop-types";

/**
 * Alert tooltip for repricer templates
 */
class AlertTooltip extends Component {
	render () {
		return (
			<React.Fragment>
				{this.props.message
					&& !this.props.message.validated
					&& this.props.range.includes(this.props.message.message_id) ?
				  <div className="alert-tooltip">
					<span id={this.props.target}><img src={iconWarning} alt="warning" /></span>
					<UncontrolledTooltip
						target={this.props.target}
						innerClassName="alert-tooltip-inner"
					 >{this.props.message.text}</UncontrolledTooltip>
				  </div>
				: (null)
				}
			</React.Fragment>
		)
	}
}

AlertTooltip.propTypes = {
	message: PropTypes.object,
	range: PropTypes.array.isRequired,
	target: PropTypes.string.isRequired,
};

export default AlertTooltip;
